import { useQuery } from 'react-query';

import api from '@root/api';

const getApiKeys = async () => {
  const { results } = await api.apiKeys.list({});

  return results;
};

export default function useApiKeys() {
  return useQuery(['apiKeys.list'], () => getApiKeys());
}
