import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const processBulk = async ({
  productIds,
}) => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.processBulk({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
    productIds: productIds.join(','),
  });

  return products;
};

export default function useProcessBulk() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ productIds }) => processBulk({ productIds }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['bigCommerce.getCurrentBulkJob'], data);
      },
    },
  );
}
