import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Select from 'react-select';
import clsx from 'clsx';

// TODO: Refactoring — remove inline styles.
const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: '0.875rem',
    color: theme.palette.grey.pure,
  },
  select: {
    position: 'relative',
    zIndex: 2000,
    border: '1px solid #aaaaaa !important',
  },
}));

const colorStyles = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: '15px',
    backgroundColor: (isSelected && '#00042960') || (isFocused && '#00042910') || '#fff',
    color: isSelected ? '#fff' : '#333333',
  }),
};

const DEPRECATEDSingleSelect = ({
  isClearable,
  isSearchable,
  isDisabled,
  handleChange,
  placeholder,
  defaultValue,
  options,
  label,
  components,
  value,
  required,
  className,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      {label && <FormLabel className={classes.formLabel}>{label}</FormLabel>}
      <Select
        className={clsx(className, classes.dropdown)}
        classNamePrefix="select"
        placeholder={placeholder}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        onChange={handleChange}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...colorStyles }}
        components={components}
        value={value}
        required={required}
        disabled={disabled}
      />
    </>
  );
};

export default DEPRECATEDSingleSelect;
