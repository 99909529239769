import { HttpClient } from "../HttpClient";
import { HandlingOptions } from "../HandlingOptions";
import { RequestOptions } from "../RequestOptions";

export class AiTemplate {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public getCommunityTemplates(options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/aiTemplates/community-templates", {}, options);
  }

  public getTemplates(
    data: unknown,
    options?: HandlingOptions
  ): Promise<Record<string, unknown>> {
    return this.client.get("/aiTemplates", data, options);
  }

  public getById(
    id: string,
    options?: HandlingOptions
  ): Promise<Record<string, unknown>> {
    return this.client.get(`/aiTemplates/${id}`, {}, options);
  }

  public createOrUpdate(data: unknown, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post("/aiTemplates", data, options);
  }

  public createOrUpdateCustom(data: unknown, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post("/aiTemplates/custom", data, options);
  }

  public adminUpdateTemplates(id: string, data: unknown, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/super-update`, data, options);
  }

  public approveTemplate(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/approve`, {}, options);
  }

  public addCommunityTemplateToLibrary(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/add-to-library`, {}, options);
  }

  public shareTemplateToCommunity(
    id: string,
    data: unknown,
    options: RequestOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/share-to-community`, data, options);
  }

  public removeTemplateFromCommunity(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/remove-from-community`, {}, options);
  }

  public removeTemplateFromLibrary(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/remove-from-library`, {}, options);
  }

  public del(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.del(`/aiTemplates/${id}`, {}, options);
  }

  public createQualityDiscriminator(id: string, options: RequestOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/aiTemplates/${id}/quality-discriminator`, {}, options);
  }
}
