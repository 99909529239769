import React from 'react';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';
import InputsIcon from '@material-ui/icons/SaveAlt';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import FileInputFields from '@root/views/File/components/FileInputFields';
import GenerateButtonWithCap from '@root/views/File/components/GenerateButtonWithCap';
import PromptSettings from '@root/components/PromptSettings';
import SourceGeneration from '@root/views/File/components/SourceGeneration';
import {
  getBlockEditorInputsOpenState,
  getBlockEditorSettingsOpenState,
  setBlockEditorInputsOpenState,
  setBlockEditorSettingsOpenState,
} from '@root/services/localStorage.service';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import { compareFileData, isNewFile } from '../blockEditor.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 308,
    padding: theme.spacing(0, 0, 0, 4.5),
    boxSizing: 'content-box',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  buttons: {
  },
  inputButton: {
    margin: theme.spacing(0, 1, 0, 0),
    paddingTop: 3,
    paddingBottom: 3,
  },
  buttonActive: {
    backgroundColor: `${theme.palette.primary.faded} !important`,
  },
  inputIcon: {
    transform: 'rotate(180deg)',
  },
  inputs: {
    backgroundColor: theme.palette.colors.white,
    padding: theme.spacing(2, 2, 3),
    margin: theme.spacing(3, 0, 0, 0),
    borderRadius: theme.border.radius.lg,
  },
  generateButtonWrap: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  settings: {
    backgroundColor: theme.palette.colors.white,
    padding: theme.spacing(2),
    margin: theme.spacing(3, 0, 0, 0),
    borderRadius: theme.border.radius.lg,
  },
  sourceGenerationWrap: {
    margin: theme.spacing(3.5, 0, 0, 0),
  },
  field: {
    '&:first-child': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const Settings = ({
  template,
  company,
  file,
  fileData,
  fileSettings,
  updateFile,
  canManageDocuments,
  canEditFile,
  documentsExist,
  onDataChange,
  onSettingsChange,
  generate,
  generateDisabled,
  customDisableTooltipContent,
}) => {
  const fileDataRef = React.useRef();
  const fileRef = React.useRef(file);

  const isNew = React.useMemo(() => isNewFile(file), [file]);

  const [inputsOpen, setInputsOpen] = React.useState(
    isNew
      ? true
      : getBlockEditorInputsOpenState() === 'true',
  );
  const [settingsOpen, setSettingsOpen] = React.useState(
    isNew
      ? false
      : getBlockEditorSettingsOpenState() === 'true',
  );

  const classes = useStyles();

  let fields = template.fieldsByByPlan
    ? template.fieldsByByPlan[company.billing.currentLimitId] || template.fields
    : template.fields;

  fields = fields.map((field) => {
    const { creditCostByContentLength } = template;

    if (!creditCostByContentLength) {
      return field;
    }
    if (creditCostByContentLength.fieldKey !== field.name) {
      return field;
    }

    return {
      ...field,
      creditCosts: creditCostByContentLength.thresholds,
    };
  });

  const { setOnboardingState, featureTourActive } = onboardingContext.useOnboarding();

  const onInputsClick = () => {
    setBlockEditorInputsOpenState(!inputsOpen);
    setInputsOpen(!inputsOpen);
  };
  const onSettingsClick = () => {
    setBlockEditorSettingsOpenState(!settingsOpen);
    setSettingsOpen(!settingsOpen);
  };

  const getDynamicCreditsRange = () => {
    if (!template.creditCostByContentLength) {
      return null;
    }
    return [
      template.creditCostByContentLength.thresholds[0].value,
      template.creditCostByContentLength.thresholds.slice(-1)[0].value,
    ];
  };

  const dynamicCreditsRange = getDynamicCreditsRange();

  React.useEffect(() => {
    fileDataRef.current = fileData;

    const intervalId = setInterval(async () => {
      const isDataEqual = compareFileData(fileRef.current, fileDataRef.current);
      if (!isDataEqual) {
        fileRef.current = await updateFile({
          fileId: file._id, data: { data: fileDataRef.current },
        });
      }
    }, 5000);

    if (featureTourActive) {
      setOnboardingState({ run: true, stepIndex: 2 });
    }

    return async () => {
      clearInterval(intervalId);
      const isDataEqual = compareFileData(file.data, fileDataRef.current);
      if (!isDataEqual) {
        await updateFile({ fileId: file._id, data: { data: fileDataRef.current } });
      }
    };
  }, []);

  const onDataChangeLocal = (e) => {
    const newFileData = onDataChange(e);
    fileDataRef.current = newFileData;
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button
          startIcon={<InputsIcon className={classes.inputIcon} />}
          variant="outlined"
          className={clsx(classes.inputButton, {
            [classes.buttonActive]: inputsOpen,
          })}
          onClick={onInputsClick}
        >
          Inputs
        </Button>
        <Button
          startIcon={<SettingsIcon />}
          variant="outlined"
          className={clsx(classes.inputButton, {
            [classes.buttonActive]: settingsOpen,
          })}
          onClick={onSettingsClick}
          id={featureTourActive ? 'feature-tour-step-5' : undefined}
        >
          Settings
        </Button>
      </div>
      {inputsOpen && (
        <div
          className={classes.inputs}
          id={featureTourActive ? 'feature-tour-step-3' : undefined}
        >
          {file.campaignDocumentId && (
            <div className={classes.sourceGenerationWrap}>
              <SourceGeneration documentId={file.campaignDocumentId} />
            </div>
          )}
          <FileInputFields
            data={fileData}
            templateType={template.templateType}
            fields={fields}
            showLanguage={false}
            isFFSE
            onDataChange={onDataChangeLocal}
            disabled={!canEditFile}
            fieldClassName={classes.field}
            short
          />
          <div className={classes.generateButtonWrap}>
            <GenerateButtonWithCap
              showInfo={!!dynamicCreditsRange}
              dynamicCreditsRange={dynamicCreditsRange}
              canManageDocuments={canManageDocuments}
              documentsExist={documentsExist}
              generateHandler={generate}
              disabled={generateDisabled}
              customDisableTooltipContent={customDisableTooltipContent}
            />
          </div>
        </div>
      )}
      {settingsOpen && (
        <div className={classes.settings}>
          <PromptSettings
            values={{
              ...fileSettings.aiOptions,
              language: fileSettings.language,
              outputLanguage: fileSettings.outputLanguage,
            }}
            templateType={template.templateType}
            onChange={onSettingsChange}
            showAli={!template.aliDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default Settings;
