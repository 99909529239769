import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    gap: '20px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4, 4, 4),
    minWidth: '18rem',
    height: '18rem',
    borderRadius: theme.border.radius.default,
    alignContent: 'center',
    fontFamily: "'Inter', sans-serif",
    outline: 'none',
  },
  bold: {
    fontWeight: '700',
  },
  header: {
    fontWeight: '700',
    fontSize: '18px',
  },
  innerContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    width: '350px',
  },
  image: {
    height: '100%',
  },
}));
