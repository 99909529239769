import React from 'react';

import MainLayout from '@root/views/Layout';
import TeamManagementLayout, { ACTIVE_TAB } from '@root/views/Team/TeamManagementLayout';
import TeamMembersManagement from '@root/views/Team/TeamMembersManagement';

const TeamMembers = () => {
  return (
    <MainLayout>
      <TeamManagementLayout activeTab={ACTIVE_TAB.MEMBERS}>
        <TeamMembersManagement />
      </TeamManagementLayout>
    </MainLayout>
  );
};

export default TeamMembers;
