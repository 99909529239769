import React from 'react';

import RightPart from './RightPart';
import LeftPart from './LeftPart';
import useStyles from './NavbarNew.styles';

const NavbarNew = () => {
  const classes = useStyles();

  return (
    <div className={classes.navbarContainer}>
      <LeftPart />

      <RightPart />
    </div>
  );
};

export default NavbarNew;
