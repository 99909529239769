import React, { useEffect } from 'react';
import ReactJoyride, { ACTIONS, EVENTS } from 'react-joyride';
import { useHistory } from 'react-router-dom';

import onboardingContext from '@root/resources/onboarding/onboarding.context';
import OnboardingTooltip from '@root/components/tooltips/OnboardingTooltip';
import { colors } from '@root/material.theme';
import config from '@root/config';

const isDev = config.env === 'development';

const OnboardingTour = () => {
  const {
    run, steps, stepIndex, setOnboardingState,
    skipFeatureTour, closeReminder, skipWalkthroughTour,
    featureTourActive, reminderActive, walkthroughActive,
  } = onboardingContext.useOnboarding();

  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        setOnboardingState({ run: false, stepIndex: 0 });
      }
    });
  }, []);

  return (
    <ReactJoyride
      callback={(joyrideState) => {
        // will be removed after ONB-3 feature
        if (isDev) {
          // eslint-disable-next-line no-console
          console.log('joyrideState', joyrideState);
        }
        const { index, action, type, size } = joyrideState;
        const isAfterLastStep = type === EVENTS.STEP_AFTER && (index + 1 === size);
        if ([ACTIONS.CLOSE, ACTIONS.SKIP].includes(action) || isAfterLastStep) {
          if (featureTourActive) {
            skipFeatureTour();
          } else if (reminderActive) {
            closeReminder();
          } else if (walkthroughActive) {
            skipWalkthroughTour();
          }
        } else if (type === EVENTS.STEP_AFTER) {
          setOnboardingState({ stepIndex: index + 1 });
        } else if (type === EVENTS.TOUR_END) {
          setOnboardingState({ run: false, stepIndex: 0 });
        }
      }}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableOverlayClose
      spotlightClicks={!walkthroughActive}
      disableScrolling
      hideCloseButton
      hideBackButton
      showSkipButton
      showProgress
      spotlightPadding={0}
      locale={{
        last: 'Finish',
        skip: 'Skip tour',
      }}
      styles={{
        options: {
          arrowColor: colors.primaryLight,
          overlayColor: 'rgba(0, 0, 0, 0.7)',
        },
      }}
      tooltipComponent={OnboardingTooltip}
    />
  );
};

export default OnboardingTour;
