const genericOptions = {
  actionTitle: 'Generate',
  sidebarTitle: 'Generated Content',
  getText: () => '',
};

export const selectTitleMap = {
  freeFormEditor: 'Add more',
  freeFormEditorInstruct: 'Request content',
};

const actionMap = {
  freeFormEditor: {
    templateType: 'freeFormEditor',
    actionTitle: 'Add more',
    sidebarTitle: 'New Paragraph',
    limit: 1200,
    getData: (text) => {
      return {
        exampleParagraph: text,
      };
    },
    getText: (data) => {
      return data?.exampleParagraph || '';
    },
    aliDisabled: true,
  },
  freeFormEditorInstruct: {
    templateType: 'freeFormEditorInstruct',
    actionTitle: 'Request content',
    sidebarTitle: 'Requested content',
    limit: 1200,
    getData: (text) => {
      return {
        exampleParagraph: text,
      };
    },
    getText: (data) => {
      return data?.exampleParagraph || '';
    },
    aliDisabled: true,
  },
  contentRewriter: {
    templateType: 'contentRewriter',
    actionTitle: 'Rewrite',
    sidebarTitle: 'Rewritten Paragraph',
    limit: 1200,
    getData: (text) => {
      return {
        inputParagraph: text,
      };
    },
    getText: (data) => {
      return data?.inputParagraph || '';
    },
  },
  contentImprover: {
    templateType: 'contentImprover',
    actionTitle: 'Improve',
    sidebarTitle: 'Enhanced Paragraph',
    limit: 1200,
    getData: (text) => {
      return {
        draft: text,
      };
    },
    getText: (data) => {
      return data?.draft || '';
    },
  },
  blogIdea: {
    templateType: 'blogIdea',
    ...genericOptions,
    actionTitle: 'Generate Blog Ideas',
  },
  blogIntro: {
    templateType: 'blogIntro',
    ...genericOptions,
    actionTitle: 'Generate Blog Intro',
  },
  blogOutline: {
    templateType: 'blogOutline',
    ...genericOptions,
    actionTitle: 'Generate Blog Outline',
  },
  contentExpander: {
    templateType: 'contentExpander',
    ...genericOptions,
    actionTitle: 'Expand',
  },
  productDescription: {
    templateType: 'productDescription',
    ...genericOptions,
    actionTitle: 'Generate Product Description',
  },
};

export default actionMap;
