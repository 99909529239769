import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flex: 1,
  },
  main: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    width: '100%',
    fontFamily: '"Inter", sans-serif',
    minHeight: 0,
    flex: 1,
  },
  mobileError: {
    position: 'absolute',
    display: 'none',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    zIndex: 1005,
  },
  mobileErrorText: {
    width: '320px',
    display: 'flex',
    justifyContent: 'center',
    color: '#535ca5',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 'bold',
    fontSize: '35px',
  },
});
