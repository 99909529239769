import React from 'react';
import type { ReactNode, ComponentType, Attributes } from 'react';
import { useField, FieldConfig } from 'formik';

const FormField = ({ children, component, validate, ...props }: any) => {
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const componentProps = {
    ...field,
    ...props,
    error: touched ? error : undefined,
  } as Attributes;

  return (
    React.createElement(
      component,
      componentProps,
      children,
    )
  );
};

export default FormField;
