import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import {
  FormField,
  InputField,
  SelectField,
  CheckboxField,
} from '@root/components/form';
import Button from '@root/components/buttons/Button';
import useFolders from '@root/resources/folder/useFolders';

import useStyles from './GeneralInfoForm.styles';

const validationSchema = Yup.object().shape({
  name: Yup
    .string()
    .max(120, 'Input text is too long')
    .required('Field is required'),
  projectId: Yup
    .string()
    .required('Field is required'),
});

const GeneralInfoForm = ({ onSubmit, formRef, title: formTitle, values: initialValues }) => {
  const classes = useStyles();
  const { data: folders = [] } = useFolders({});

  const projectOptions = React.useMemo(() => {
    return folders
      .filter(({ parentFolderId }) => {
        return !parentFolderId;
      })
      .map(({ title, _id }) => {
        return {
          value: _id,
          label: title,
        };
      });
  }, [folders]);

  const hasFolders = (projectId) => {
    if (!projectId) {
      return false;
    }

    return folders.find(({ parentFolderId }) => parentFolderId === projectId);
  };

  const getFoldersOptions = (projectId) => {
    return folders
      .filter(({ parentFolderId }) => parentFolderId === projectId)
      .map(({ title, _id }) => {
        return {
          value: _id,
          label: title,
        };
      });
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues || {}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
      >
        {({ values }) => (
          <div className={classes.wrapper}>
            <div className={classes.title}>
              {formTitle}
            </div>
            <div className={classes.description}>
              Please provide general information about your template.
            </div>

            <Form className={classes.form}>
              <FormField
                name="name"
                label="Name"
                component={InputField}
                required
              />
              <FormField
                name="projectId"
                label="Project"
                component={SelectField}
                options={projectOptions}
                required
              />
              {hasFolders(values.projectId)
                  && (
                    <FormField
                      name="folderId"
                      label="Folder"
                      component={SelectField}
                      options={getFoldersOptions(values.projectId)}
                    />
                  )}

              <FormField
                name="autoDownloadCSV"
                label="Automatically download results as a CSV"
                component={CheckboxField}
                required
              />
            </Form>

            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  if (!hasFolders(values.projectId)) {
                    delete values.folderId;
                  }
                  onSubmit(values);
                }}
                disabled={!values.name || !values.projectId}
              >
                Next Step
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>

  );
};

export default GeneralInfoForm;
