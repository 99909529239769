import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import useModalState from '@root/utils/hooks/useModalState';
import Modal from '@root/components/modals/Modal';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import fileResource from '@root/resources/file';
import templatesContext from '@root/resources/templates/templates.context';

import WelcomeModal from '../WelcomeModal';

import CreateIcon from './Create.icon';
import RewriteIcon from './Rewrite.icon';
import useStyles from './InitialModal.styles';

const InitialModal = () => {
  const [isOpen, , close] = useModalState(true);
  const [isWelcomeOpen, openWelcome, closeWelcome] = useModalState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createFile } = fileResource.useCreateFile();
  const { getTemplate } = templatesContext.useTemplates();
  const { skipWalkthroughTour } = onboardingContext.useOnboarding();

  const history = useHistory();
  const classes = useStyles();

  const handleCreate = () => {
    close();
    openWelcome();
  };

  const handleRewrite = async () => {
    setIsLoading(true);
    const newFile = await createFile({
      title: 'New Rewriter File',
      templateType: 'contentRewriter',
    });
    const contentRewriteTemplate = getTemplate('contentRewriter');
    await skipWalkthroughTour();
    history.push(`/${contentRewriteTemplate.slug}/${newFile._id}`);
  };

  if (isWelcomeOpen) {
    return <WelcomeModal onClose={closeWelcome} />;
  }

  if (!isOpen) {
    return null;
  }

  const buttonsProps = {
    variant: 'outlined',
    disabled: isLoading,
    classes: {
      root: classes.button,
      label: classes.buttonLabel,
    },
  };

  return (
    <Modal maxWidth="lg">
      <div className={classes.modalRoot}>
        <span className={classes.icon}>✨</span>
        <h2 className={classes.title}>
          Let the magic begin!
        </h2>
        <div className={classes.content}>
          <p>
            We can&apos;t wait to see all the amazing content you&apos;ll create!
          </p>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleCreate}
            {...buttonsProps}
          >
            <CreateIcon />
            I want to create copy
          </Button>
          <Button
            onClick={handleRewrite}
            {...buttonsProps}
          >
            {isLoading ? <CircularProgress color="inherit" size={86} /> : <RewriteIcon />}
            I want to rewrite copy I already have
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InitialModal;
