import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M8.667 10c0-.736.597-1.333 1.333-1.333h4c.736 0 1.333.597 1.333 1.333v4c0 .736-.597 1.333-1.333 1.333h-4A1.333 1.333 0 0 1 8.667 14v-4ZM14 10h-4v4h4v-4ZM5.333 9.333c0-.368.299-.666.667-.666h.667a.667.667 0 1 1 0 1.333H6a.667.667 0 0 1-.667-.667ZM5.333 14.667c0-.368.299-.667.667-.667h.667a.667.667 0 0 1 0 1.333H6a.667.667 0 0 1-.667-.666ZM9.333 5.333c.369 0 .667.299.667.667v.667a.667.667 0 0 1-1.333 0V6c0-.368.298-.667.666-.667ZM14.667 5.333c.368 0 .666.299.666.667v.667a.667.667 0 1 1-1.333 0V6c0-.368.299-.667.667-.667ZM16.667 9.333c0-.368.298-.666.666-.666H18A.667.667 0 1 1 18 10h-.667a.667.667 0 0 1-.666-.667ZM16.667 14.667c0-.368.298-.667.666-.667H18a.667.667 0 0 1 0 1.333h-.667a.667.667 0 0 1-.666-.666ZM9.333 16.667c.369 0 .667.298.667.666V18a.667.667 0 0 1-1.333 0v-.667c0-.368.298-.666.666-.666ZM14.667 16.667c.368 0 .666.298.666.666V18A.667.667 0 1 1 14 18v-.667c0-.368.299-.666.667-.666Z" />
    </svg>
  );
};
