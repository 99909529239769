import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  promoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
      marginRight: 16,
    },
  },
  promoCode: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    paddingLeft: 8,
    fontSize: 12,
    color: theme.palette.colors.primary,
    backgroundColor: theme.palette.colors.primaryFaded,
    borderRadius: 4,
    '& > button': {
      padding: 0,
      color: theme.palette.primary.main,
      '& svg': {
        fontSize: 18,
      },
    },
  },
  promoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  promoDescription: {
    fontSize: 12,
    color: theme.palette.colors.grey,
  },
  discount: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.colors.grey,
  },
  button: {
    backgroundColor: theme.palette.colors.white,
    alignSelf: 'flex-start',
  },
}));

export default useStyles;
