import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconBell } from '@tabler/icons-react';

import useWorkflowStats from '@root/resources/file/useWorkflowStats';

import useStyles from './NotificationBell.styles';

const COUNTER_BADGE_LIMIT = 9;

const NotificationBell = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    data: stats, isLoading: isLoadingWorkflowStats,
  } = useWorkflowStats();

  const sidebarBadgeValue = React.useMemo(() => {
    if (stats?.workflowStats > COUNTER_BADGE_LIMIT) {
      return `${COUNTER_BADGE_LIMIT}+`;
    }

    return stats?.workflowStats;
  }, [stats?.workflowStats]);

  const showNotificationCounter = !isLoadingWorkflowStats && sidebarBadgeValue !== 0;

  const handleNotificationBellClick = () => {
    history.push('/templates/workflow/?filters=true');
  };

  return (
    <div onClick={handleNotificationBellClick} className={classes.notificationContainer}>
      {showNotificationCounter && (
        <div className={classes.notificationCounter}>
          {sidebarBadgeValue}
        </div>
      )}
      <IconBell className={classes.notificationIcon} />
    </div>
  );
};

export default NotificationBell;
