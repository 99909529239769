import React, { useEffect, useMemo, useState } from 'react';
import { IconShare } from '@tabler/icons-react';
import { Button } from '@material-ui/core';

import { selectionTypes, useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import { SHARE_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals';
import AuthContext from '@root/resources/auth/auth.context';
import useFolders from '@root/resources/folder/useFolders';
import CreateButtonNew from '@root/views/Dashboard/components/CreateButtonNew/CreateButtonNew';

import FiltersSelect from '../FiltersSelect';
import SearchFilesInput from '../SearchFilesInput';

import useStyles from './DashNavNew.styles';

const DashNavNew = () => {
  const classes = useStyles();

  const [showFilters, setShowFilters] = useState(false);

  const { state: { filters, selected: { type, title } }, dispatch } = useDashboard();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { currentUser } = AuthContext.useAuth();

  const { data: folders } = useFolders();

  const { folderId } = filters;
  const selectedFolder = folderId && folders?.find((f) => f.id === folderId);

  const permissions = currentUser.permissions.getFolderPermissions(selectedFolder);

  const { canViewFolderMembers } = permissions;

  const userFolderRoles = currentUser.roles.getFolderRoles(selectedFolder);

  useEffect(() => {
    const newShowFilters = filters.templateTypes.length
    || filters.workflowStatuses.length;

    if (newShowFilters && newShowFilters !== showFilters) {
      setShowFilters(newShowFilters);
    }
  }, [filters]);

  const {
    parentFolder, canShareFolder, selectedTitle,
  } = useMemo(() => {
    const selectedParentFolder = folders?.find((p) => p._id === selectedFolder?.parentFolderId);

    const selectedFolderTitle = selectedFolder?.title;

    return {
      parentFolder: selectedParentFolder,
      canShareFolder: !selectedFolder?.home,
      selectedTitle: selectedFolderTitle,
    };
  }, [folderId, folders, type]);

  const showShareButton = (type === selectionTypes.PROJECT || type === selectionTypes.FOLDER)
    && canShareFolder;

  const handleSelectFolder = () => dispatch({
    type: actions.SELECT_FOLDER,
    payload:
      {
        title: parentFolder?.title,
        folderId: parentFolder?._id,
      },
  });

  const handleShareClick = () => {
    if (!canViewFolderMembers) {
      return;
    }

    modalsDispatch({
      type: modalsActions.OPEN_MODAL,
      payload: {
        modalId: SHARE_FOLDER_MODAL_ID,
        modalContent: {
          folder: selectedFolder,
        },
      },
    });
  };

  const parentFolderTitle = parentFolder?.home ? 'Home' : parentFolder?.title;
  const folderTitle = selectedTitle || title;

  return (
    <div className={classes.container}>
      <div className={classes.selectedTitle}>
        {parentFolderTitle && (
          <div>
            <span
              onClick={handleSelectFolder}
              className={classes.parentFolderLink}
            >
              {parentFolderTitle}
            </span>
            {' '}
            /
            {' '}
            {folderTitle}
          </div>
        )}

        {!parentFolderTitle && (
          <span>
            {folderTitle}
          </span>
        )}

        <div className={classes.verticalDivider} />

        <Button
          variant="text"
          startIcon={<IconShare />}
          classes={{
            root: classes.shareButton,
            label: classes.shareButtonLabel,
            startIcon: classes.shareButtonStartIcon,
            disabled: classes.disabledShareButton,
          }}
          onClick={handleShareClick}
          disabled={!showShareButton}
        >
          Share
        </Button>
      </div>

      <div className={classes.controlsContainer}>
        <CreateButtonNew
          disabled={userFolderRoles.viewer}
        />

        <div className={classes.filtersContainer}>
          <SearchFilesInput />

          <FiltersSelect />
        </div>
      </div>

      <div className={classes.divider} />
    </div>
  );
};

export default DashNavNew;
