import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

const updateBulkDescription = async ({
  productCode,
  description,
}) => {
  const products = await api.akeneo.updateBulkDescription({
    productCode,
    description,
  });

  return products;
};

export default function useUpdateBulkProductDescription() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ productCode, description }) => updateBulkDescription({ productCode, description }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['akeneo.getCurrentBulkJob']);
      },
    },
  );
}
