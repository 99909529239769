import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  folderTreeContainer: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.colors.neutrals200}`,
    background: 'white',
    position: 'relative',
    backgroundColor: theme.palette.colors.white,

    // TODO: think of refactor
    maxWidth: '270px',
    minWidth: '270px',

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '2fr 1fr',
    gap: '24px',
    padding: '16px 0px 24px',

  },

  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

}));

export default useStyles;
