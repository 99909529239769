import React from 'react';

import Button from '@root/components/buttons/Button';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import fileService from '@root/resources/file/file.service';
import useFiles from '@root/resources/file/useFiles';
import TemplatesContext from '@root/resources/templates/templates.context';
import Modal from '@root/components/modals/Modal';

import useStyles from './ExportToCSVModal.styles';

const ExportToCSVModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { templates } = TemplatesContext.useTemplates();
  const { state: { modalContent } } = useGlobalModals();

  const folderId = modalContent?.folder?._id;
  const title = modalContent?.folder?.title;

  const { data: filesData } = useFiles({ folderIds: [folderId] });

  const handleExport = async () => {
    await fileService.exportCSV(filesData.files, folderId, title, templates);
    closeModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      title="Export CSV"
      subtitle={`Export files from ${title}.`}
      classNames={{ paper: classes.paper }}
    >
      <div className={classes.buttonsContainer}>
        <Button variant="text" onClick={closeModal}>
          Discard
        </Button>

        <Button
          type="button"
          onClick={handleExport}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ExportToCSVModal;
