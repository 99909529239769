import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useCreateFile from '@root/resources/file/useCreateFile';
import authContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import quickStartData from './quickStart.data';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  item: {
    padding: theme.spacing(7, 3, 1.5),
    flex: '1 1 0px',
    backgroundColor: theme.palette.colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.border.radius.lg,
    cursor: 'pointer',
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: '16px',
    margin: theme.spacing(2.5, 0),
  },
  itemDescription: {
    fontSize: '14px',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'initial',
  },
}));

const QuickStart = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    currentUser,
  } = authContext.useAuth();

  const {
    mutateAsync: createFile,
  } = useCreateFile();

  const onAction = React.useCallback((handler) => () => {
    handler({ createFile, history });
  }, [createFile, history]);

  return (
    <div className={classes.root}>
      {quickStartData.map((item) => {
        const disabled = item.disabled({ currentUser });

        const content = (
          <div
            key={item.title}
            className={clsx(classes.item, { [classes.disabled]: disabled })}
            onClick={!disabled ? onAction(item.onAction) : null}
          >
            <img src={item.icon} alt={item.title} />
            <div className={classes.itemTitle}>{item.title}</div>
            <div className={classes.itemDescription}>{item.description}</div>
          </div>
        );

        if (disabled) {
          return (
            <SimpleTooltip key={item.title} content="Feature is not available on your plan">
              {content}
            </SimpleTooltip>
          );
        }

        return content;
      })}
    </div>
  );
};

export default QuickStart;
