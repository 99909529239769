import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormHelperText, FormControl, FormLabel, RadioGroup } from '@material-ui/core';

import Radio from '@root/components/Radio';

const RadioButtonField = ({
  name,
  label,
  error,
  value,
  options,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          name={name}
          value={value}
          onChange={onChange}
          {...props}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              disabled={disabled || option.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText error={!!error}>
        {error}
      </FormHelperText>
    </div>
  );
};

RadioButtonField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.bool,
  ]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
};

RadioButtonField.defaultProps = {
  label: null,
  name: undefined,
  error: undefined,
  options: [],
  disabled: false,
};

export default RadioButtonField;
