import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Projects = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 3H5C3.9 3 3 3.9 3 5V12C3 13.1 3.9 14 5 14H19C20.1 14 21 13.1 21 12V5C21 3.9 20.1 3 19 3ZM19 9H15C15 10.62 13.62 12 12 12C10.38 12 9 10.62 9 9H5V5H19V9ZM15 16H21V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V16H9C9 17.66 10.34 19 12 19C13.66 19 15 17.66 15 16Z" fill="black" />
    </SvgIcon>
  );
};

export default Projects;
