import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useFiles from '@root/resources/file/useFiles';
import useFolders from '@root/resources/folder/useFolders';
import { useDashboard } from '@root/views/Dashboard/dashboardContext';
import { getChildFolders } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';
import FilesEmpty from '@root/views/Dashboard/components/FilesEmpty';
import DashNavNew from '@root/views/Dashboard/components/FolderContentNew/DashNavNew/DashNavNew';
import DashListViewNew from '@root/views/Dashboard/components/FolderContentNew/DashListViewNew';
import PaginationNew from '@root/views/Dashboard/components/FolderContentNew/PaginationNew';

import useStyles from './FolderContentNew.styles';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE_ITEMS_NUMBER = 25;

const FolderContent = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_ITEMS_NUMBER);

  const { state: { filters } } = useDashboard();
  const { currentUser: user } = AuthContext.useAuth();
  const isWorkflow = pathname.includes('workflow');

  const paginationEnabled = user.featureToggles.dashboardPagination;

  const fileFilters = useMemo(() => ({
    ...filters,
    page,
    ...(paginationEnabled && { perPage }),
    ...(filters.folderId && !filters.folderIds && { folderIds: [filters.folderId] }),
  }), [filters, paginationEnabled, page]);

  const {
    isLoading: isLoadingFiles,
    data: filesData,
    isFetching,
    refetch: refetchFiles,
  } = useFiles(fileFilters);
  const {
    isLoading: isLoadingFolders,
    data: foldersData,
    isFetching: isFetchingFolders,
  } = useFolders();

  const files = filesData?.files || [];
  const folders = foldersData || [];

  const folder = filters.folderId && folders.find((f) => f._id === filters.folderId);
  const isOwner = user.roles.getFolderRoles(folder).owner;
  const subfolders = getChildFolders(filters.folderId, folders);

  useEffect(() => {
    if (page !== DEFAULT_PAGE) {
      setPage(DEFAULT_PAGE);
    }
  }, [folder?._id, filters]);

  // refetch files when on 'workflow' page
  useEffect(() => {
    if (isWorkflow) {
      refetchFiles(fileFilters);
    }
  }, [isWorkflow, fileFilters]);

  const isLoading = isLoadingFiles || isLoadingFolders || isFetching || isFetchingFolders;
  const isTableVisible = !!files?.length || !!subfolders?.length;

  const showFilesEmpty = (!isLoading && files.length === 0) && (subfolders.length === 0);

  return (
    <div className={classes.folderContentContainer}>
      <div className={classes.folderContentWrapper}>
        <DashNavNew />

        {showFilesEmpty && <FilesEmpty />}

        {isTableVisible && (
          <DashListViewNew
            folder={folder}
            files={files}
            subfolders={subfolders}
            isLoading={isLoading}
            isDraggable={isOwner}
          />
        )}

        {paginationEnabled && filesData && (files.length >= 25 || page !== 1) && (
          <PaginationNew
            currentPage={page}
            pageCount={filesData.pageCount}
            onPageChange={(newPage) => setPage(newPage)}
            perPage={perPage}
            onPerPageChange={(newPerPage) => setPerPage(newPerPage)}
          />
        )}
      </div>
    </div>
  );
};

export default FolderContent;
