import React from 'react';
import clsx from 'clsx';
import {
  AllInbox, ArrowDropDown, BookmarkBorderSharp, FileCopy, AssignmentTurnedIn, Bookmark,
} from '@material-ui/icons';

import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import AuthContext from '@root/resources/auth/auth.context';
import useFolders from '@root/resources/folder/useFolders';
import useWorkflowStats from '@root/resources/file/useWorkflowStats';
import { getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';
import { selectionTypes } from '@root/views/Dashboard/dashboardContext';
import CollapseButton from '@root/components/CollapseButton';

import RootFolderListItem from './RootFolderListItem';
import SpecialListItem from './SpecialListItem';
import SidebarBadge from './SidebarBadge/SidebarBadge';
import NewProjectButton from './NewProjectButton';
import useStyles from './FolderSidebar.styles';

const FolderSidebar = () => {
  const { currentUser } = AuthContext.useAuth();
  const hasTeamPermissions = currentUser.permissions.teams;
  const [isCollapsed, setCollapsed] = React.useState(false);
  const {
    data: folders, isLoading: isLoadingFolders,
  } = useFolders();
  const {
    data: stats, isLoading: isLoadingWorkflowStats,
  } = useWorkflowStats();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;
  const removeWorkflowQueue = currentUser.featureToggles.workflowToTeamMembers;

  const { collapsibleSidebar } = currentExternalSource.toggles;

  // Filter home folder for old files view
  const rootFolders = !isLoadingFolders
    ? getRootFolders(folders, currentUser)?.filter((rootFolder) => !rootFolder.home)
    : [];
  const sharedRootFolders = !isLoadingFolders
    ? getSharedRootFolders(folders, currentUser)?.filter((rootFolder) => !rootFolder.home)
    : [];

  const classes = useStyles();

  const toggleCollapse = React.useCallback(() => {
    return setCollapsed(!isCollapsed);
  }, [isCollapsed, setCollapsed]);

  const sidebarBadgeValue = React.useMemo(() => {
    if (stats?.workflowStats > 99) {
      return '99+';
    }

    return stats?.workflowStats;
  }, [stats?.workflowStats]);

  return (
    <div className={clsx(classes.root, {
      [classes.rootCollapsed]: isCollapsed,
      [classes.newRoot]: newDesign,
    })}
    >
      {collapsibleSidebar && (
        <CollapseButton
          onClick={toggleCollapse}
          className={classes.collapseButton}
          rotated={!isCollapsed}
        />
      )}
      {!isCollapsed
        && (
          <div className={classes.container}>
            <div className={clsx(classes.customGroup, { [classes.newCustomGroup]: newDesign })}>
              <SpecialListItem
                title="All Files"
                itemType={selectionTypes.ALL_FILES}
                Icon={FileCopy}
              />
              {currentUser.permissions.workflowQueue
            && hasTeamPermissions
            && !removeWorkflowQueue
            && (
              <SpecialListItem
                title="My workflow items"
                itemType={selectionTypes.WORKFLOW}
                Icon={AssignmentTurnedIn}
                additionalPayload={{
                  shouldApplyFilters: sidebarBadgeValue,
                }}
              >
                {!isLoadingWorkflowStats && sidebarBadgeValue !== 0 && (
                  <SidebarBadge value={sidebarBadgeValue} />
                )}
              </SpecialListItem>
            )}
              <SpecialListItem
                title="Bookmarked"
                itemType={selectionTypes.BOOKMARKS}
                Icon={newDesign ? Bookmark : BookmarkBorderSharp}
              />
            </div>
            <div className={clsx(
              classes.overflow,
              {
                [classes.newProjectsContainer]: newDesign,
              },
            )}
            >
              <div className={clsx(classes.headerRow, { [classes.newHeaderRow]: newDesign })}>
                <div className={classes.iconContainer}>
                  <AllInbox className={clsx(
                    classes.icon,
                    {
                      [classes.newIcon]: newDesign,
                    },
                  )}
                  />
                </div>
                <span
                  className={clsx(classes.headerText, { [classes.newHeaderText]: newDesign })}
                >
                  Projects
                </span>
              </div>
              <div className={clsx(classes.foldersGroup, { [classes.newFoldersGroup]: newDesign })}>
                <SpecialListItem
                  title="Uncategorized"
                  itemType={selectionTypes.UNCATEGORIZED}
                  Icon={ArrowDropDown}
                  rotatableIcon
                  smallIcon
                  noPadding
                />
                {rootFolders
                  .map((f) => (
                    <RootFolderListItem
                      folder={f}
                      key={`folder-sub-list-${f._id}`}
                      isDraggable
                    />
                  ))}
              </div>
              {sharedRootFolders.length > 0 && (
                <>
                  <div className={classes.headerRow}>
                    <div className={classes.iconContainer}>
                      <AllInbox className={clsx(
                        classes.icon,
                        {
                          [classes.newIcon]: newDesign,
                        },
                      )}
                      />
                    </div>
                    <span
                      className={clsx(classes.headerText, { [classes.newHeaderText]: newDesign })}
                    >
                      Shared with me
                    </span>
                  </div>
                  <div className={clsx(
                    classes.foldersGroup,
                    { [classes.newFoldersGroup]: newDesign },
                  )}
                  >
                    {sharedRootFolders.filter((f) => f.isSharedUncategorized).length > 0 && (
                      <SpecialListItem
                        title="Uncategorized"
                        itemType={selectionTypes.SHARED_UNCATEGORIZED}
                        Icon={ArrowDropDown}
                        smallIcon
                        rotatableIcon
                        noPadding
                      />
                    )}
                    {sharedRootFolders
                      .filter((f) => !f.isSharedUncategorized)
                      .map((f) => (
                        <RootFolderListItem
                          folder={f}
                          key={`folder-sub-list-${f._id}`}
                          isDraggable={false}
                        />
                      ))}
                  </div>
                </>
              )}
              <NewProjectButton />
            </div>
          </div>
        )}
    </div>
  );
};

export default FolderSidebar;
