import React from 'react';
import PropTypes from 'prop-types';
import { StringUtil } from '@copysmith/utils';

import BadgeIcon from './BadgeIcon';

const WordCountRow = ({
  showChar,
  value: initialValue,
  limit,
  title,
}) => {
  const value = initialValue.replace(/(<([^>]+)>)/gi, '');
  let isTooLong = false;
  let limitText = null;
  if (showChar && limit) {
    isTooLong = value.length > limit;
    limitText = `${value.length}/${limit} char`;
  } else {
    limitText = `${value.length} char`;

    if (!showChar) {
      const wordsCount = StringUtil.countWordsString(value);
      limitText = `${wordsCount} words`;
    }
  }

  return (
    <BadgeIcon
      text={`${title}: ${limitText}`}
      isTooLong={isTooLong}
    />
  );
};

WordCountRow.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showChar: PropTypes.bool,
  limit: PropTypes.number,
};

WordCountRow.defaultProps = {
  limit: 0,
  showChar: false,
};

export default WordCountRow;
