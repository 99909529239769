import useFlagImageDocument from './useFlagImageDocument';
import useLikeImageDocument from './useLikeImageDocument';
import useSaveImageDocument from './useSaveImageDocument';
import useLatestAIImages from './useLatestAIImages';

export default {
  useFlagImageDocument,
  useLikeImageDocument,
  useSaveImageDocument,
  useLatestAIImages,
};
