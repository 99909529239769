import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    fontFamily: 'Inter, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  header: {
    backgroundColor: theme.palette.colors.lightGrey,
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    padding: '1rem 2.5rem',
    width: '100%',
    height: '88px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '95%',
    minWidth: '44rem',
    height: '85%',
    minHeight: '40rem',
    alignContent: 'center',
    outline: 'none',
    position: 'relative',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dropContainer: {
    height: '75%',
    padding: '15px',
    border: '3.5px solid lightgrey',
    borderStyle: 'dashed',
    marginLeft: '25px',
    marginTop: '12px',
  },
  button: {
    marginLeft: '1rem',
  },
  tableStyle: {
    fontSize: '12px',
  },
  fileModalHeader: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '29px',
    textAlign: 'left',
    color: theme.palette.primary.main,
    margin: '1.25rem 2rem 1.75rem 2.5rem',
  },
  fileModalTitle: {
    position: 'absolute',
    top: 0,
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: '2.5rem',
    whiteSpace: 'nowrap',
  },
  fileModalSubtitle: {
    position: 'absolute',
    top: '1.5rem',
    marginLeft: '2.5rem',
    width: '160px',
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.colors.darkGrey,
  },
  fileModalFooter: {
    position: 'absolute',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    alignItems: 'center',
    backgroundColor: theme.palette.colors.lightGrey,
    border: `1px solid ${theme.palette.colors.grey}`,
    bottom: 0,
    padding: '1rem 2rem',
    width: '100%',
    height: '4.5rem',
  },
  generatorInput: {
    height: '40px',
    margin: '20px 0',
    borderRadius: '4px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    width: '100%',
    border: '0.5px solid #aaaaaa',
    backgroundColor: 'white',
  },
  topModalWrap: {
    marginLeft: 0,
    marginTop: '30px',
  },
  modalText: {
    marginLeft: '30px',
    color: theme.palette.colors.darkGrey,
    fontSize: '16px',
    fontWeight: 600,
  },
  modalCloseButton: {
    position: 'absolute',
    fontSize: '2.325rem',
    top: '0.825rem',
    right: '2rem',
    cursor: 'pointer',
    color: theme.palette.colors.grey,
  },
  offersWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '1rem',
    height: 'calc(100% - 11rem)',
    overflow: 'auto',
  },
  colorBlack: {
    color: '#000000',
  },
  rowWrap: {
    paddingTop: '4rem',
    paddingLeft: '4rem',
    height: '100%',
    margin: 0,
  },
  aligned: {
    textAlign: 'center',
    margin: '0',
    cursor: 'pointer',
  },
  rowText: {
    width: '100%',
    color: theme.palette.grey.pure,
    fontSize: '1.4rem',
  },
  bold: {
    fontWeight: '600',
  },
  generatorsWrapper: {
    marginBottom: '1.5rem',
  },
  generatorsTitleBox: {
    position: 'relative',
  },
  generatorsContentBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '230px',
  },
  generatorsItem: {
    width: '260px',
    height: '136px',
    border: `1px solid ${theme.palette.colors.grey}`,
    borderRadius: '4px',
    padding: '1rem',
    margin: '0 0.5rem 1rem 0.5rem',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
  },
  generatorsItemSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  templatesDescription: {
    fontWeight: 400,
    color: theme.palette.colors.darkGrey,
  },
  tableWrap: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '25px',
  },
  tableFieldWrap: {
    position: 'relative',
    maxHeight: '35px',
    whiteSpace: 'nowrap',
    maxWidth: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableKeywordMark: {
    color: '#F35A74',
    position: 'relative',
    left: '-0.1rem',
  },
  tablePlaceholder: {
    maxHeight: '35px',
    whiteSpace: 'nowrap',
    maxWidth: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  halfOpacity: {
    opacity: '0.5',
  },
  columnSpacing: {
    padding: '0rem 2.5rem',
  },
  columnTitle: {
    fontSize: '24px',
  },
  fileFormatText: {
    fontSize: '16px',
    color: theme.palette.grey.pure,
  },
  downloadButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  genericTableText: {
    fontSize: '16px',
    lineHeight: '2',
    fontStyle: 'italic',
  },
  csvErrorColor: {
    color: '#F35A7F',
  },
  colWrap: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

export default useStyles;
