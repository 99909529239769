import { makeStyles, lighten } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.colors.primary}`,
    color: theme.palette.colors.primary,
    backgroundColor: lighten(theme.palette.colors.primary, 0.95),
    textAlign: 'center',
    '&:hover': {
      backgroundColor: lighten(theme.palette.colors.primary, 0.9),
    },
  },
  disabled: {
    cursor: 'default',
    border: `1px dashed ${theme.palette.colors.mediumDarkGrey}`,
    backgroundColor: theme.palette.colors.mediumLightGrey,
    color: theme.palette.colors.mediumDarkGrey,
    '& *': {
      cursor: 'default',
    },
  },
  iconBox: {
    width: 80,
    height: 80,
    backgroundColor: lighten(theme.palette.colors.primary, 0.85),
    borderRadius: 8,
    marginBottom: 16,
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      fontSize: 72,
    },
  },
}));

export default useStyles;
