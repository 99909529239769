import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      fill="currentColor"
    >
      <path d="M3.333 1c0-.368.299-.667.667-.667h7.334a.667.667 0 1 1 0 1.334H4A.667.667 0 0 1 3.333 1ZM3.333 5c0-.368.299-.667.667-.667h7.334a.667.667 0 1 1 0 1.334H4A.667.667 0 0 1 3.333 5ZM3.333 9c0-.368.299-.666.667-.666h7.334a.667.667 0 1 1 0 1.333H4A.667.667 0 0 1 3.333 9ZM1.333.333C1.701.333 2 .632 2 1v.007a.667.667 0 0 1-1.333 0V1c0-.368.298-.667.666-.667ZM1.333 4.333c.368 0 .667.299.667.667v.007a.667.667 0 0 1-1.333 0V5c0-.368.298-.667.666-.667ZM1.333 8.334c.368 0 .667.298.667.666v.007a.667.667 0 0 1-1.333 0V9c0-.368.298-.666.666-.666Z" />
    </svg>
  );
};
