import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import authContext from '@root/resources/auth/auth.context';

import useStyles from './QualityTracking.styles';
import { defaultObj, getQualityMap } from './QualityUtils';

const QualityTracking = ({
  qualitySettings,
  creditCostsThresholds = [],
  length,
  short,
  className,
}) => {
  const {
    fairThreshold,
    greatThreshold,
    excessThreshold,
    limit,
  } = qualitySettings;
  const location = useLocation();
  const [quality, setQuality] = useState(defaultObj);
  const [showQuality, setShowQuality] = useState(false);
  const { currentCompany: company, currentUser } = authContext.useAuth();
  const qualityMap = React.useMemo(() => getQualityMap(qualitySettings), [qualitySettings]);

  const classes = useStyles(quality);

  const checkQuality = () => {
    if (length > limit) {
      setQuality(qualityMap.Limit);
      setShowQuality(true);
      return;
    }

    if (length >= excessThreshold) {
      setQuality(qualityMap.Long);
      return;
    }

    if (length >= greatThreshold) {
      setQuality(qualityMap.Great);
      return;
    }

    if (length >= fairThreshold) {
      setQuality(qualityMap.Fair);
      return;
    }

    if (length <= fairThreshold) {
      setQuality(qualityMap.Weak);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (length > 0) {
        checkQuality(length);
        setShowQuality(true);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [length]);

  const isInEditor = location.pathname.includes('documents');

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.infoSection}>
        {/* TODO: find more elegant way to display/disable it on editor */}
        {!isInEditor && (
          <div className={classes.contentLength}>
            {limit ? `${length}/${limit}` : length}
          </div>
        )}
      </div>
      <div>
        <div className={classes.barContainer}>
          <div className={classes.leftBar} />
          <div className={classes.midBar} />
          <div className={classes.rightBar} />
        </div>
        <div className={classes.descriptionContainer}>
          {showQuality ? (
            <>
              <div className={classes.qualityBadge}>
                {quality.id}
              </div>
              <div className={classes.qualityDescription}>
                {quality.description}
              </div>
            </>
          ) : (
            <div className={classes.emptyContainer} />
          )}
        </div>
      </div>
    </div>
  );
};

export default QualityTracking;
