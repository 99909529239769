import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormHelperText, makeStyles } from '@material-ui/core';

import Switch from '@root/components/Switch';
import FieldLabel from '@root/components/form/FieldLabel';

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ checked }) => (checked && theme.palette.primary.main),
    fontWeight: 500,
  },
}));

const SwitchField = ({
  name,
  label,
  labelTooltip,
  labelPlacement,
  error,
  value,
  disabled,
  ...props
}) => {
  const classes = useStyles({ checked: value });

  const switchComponent = (
    <Switch
      id={name}
      name={name}
      checked={value}
      {...props}
    />
  );

  const labelComponent = (
    <FieldLabel
      name={name}
      label={label}
      labelTooltip={labelTooltip}
      className={classes.label}
      isControl
    />
  );

  return (
    <div>
      <FormControlLabel
        control={switchComponent}
        label={labelComponent}
        labelPlacement={labelPlacement}
        checked={value}
        disabled={disabled}
      />
      <FormHelperText error={!!error}>
        {error}
      </FormHelperText>
    </div>
  );
};

SwitchField.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  labelTooltip: PropTypes.string,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
};

SwitchField.defaultProps = {
  label: null,
  name: undefined,
  error: undefined,
  labelTooltip: undefined,
  labelPlacement: 'end',
  disabled: false,
};

export default SwitchField;
