import React from 'react';
import {
  Button, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import ProfileLayout from '@root/views/Profile/components/ProfileLayout';
import TemplatesContext from '@root/resources/templates/templates.context';
import aiResource from '@root/resources/ai';
import uiService from '@root/services/uiNotification.service';
import useTeamMembers from '@root/resources/user/useTeamMembers';

import NewRuleDialog from './components/NewRuleDialog';

const useStyles = makeStyles((theme) => ({
  newRuleWrap: {
    textAlign: 'right',
  },
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
}));

const CopyPersonalization = () => {
  const classes = useStyles();

  const { templates } = TemplatesContext.useTemplates();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const { mutateAsync: createPersonalizationRule } = aiResource.usePersonalize();
  const onNewRule = async (data) => {
    await createPersonalizationRule(data);

    uiService.showSuccessMessage('Personalization rule created.');

    closeDialog();
  };
  const { mutateAsync: removePersonalizationRule } = aiResource.useRemovePersonalization();
  const onRemove = (id) => async () => {
    removePersonalizationRule({ id });
  };

  const { data: personalizationRules = [] } = aiResource.usePersonalizationRules();
  const { data: { results: teamMembers } = { results: [] } } = useTeamMembers();

  const rows = React.useMemo(() => personalizationRules.map((pr) => {
    const user = teamMembers.find((u) => u._id === pr.userId);

    return (
      <TableRow key={pr._id}>
        <TableCell>{`${templates[pr.templateType].title}${pr.tone ? ` (${pr.tone})` : ''}`}</TableCell>
        <TableCell>{user && user.displayName}</TableCell>
        <TableCell padding="none">
          <IconButton onClick={onRemove(pr._id)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }), [personalizationRules, teamMembers]);

  return (
    <ProfileLayout>
      <div>
        <div className={classes.newRuleWrap}>
          <Button onClick={openDialog}>New personalization rule</Button>
        </div>
        <NewRuleDialog
          open={dialogOpen}
          onNewRule={onNewRule}
          closeDialog={closeDialog}
        />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>User</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </ProfileLayout>
  );
};

export default CopyPersonalization;
