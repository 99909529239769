import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import lightbulb from '@root/assets/lightbulb.svg';
import Drawer from '@root/components/shared/drawer/Drawer';

const useStyles = makeStyles((theme) => ({
  drawerCloseContainer: {
    position: 'absolute',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    top: '0',
    height: '50px',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 2,
  },
  examplesWrap: {
    fontWeight: 600,
    color: 'black',
    cursor: 'pointer',
  },
  sectionContainer: {
    margin: '2rem 0',
  },
  extraMarginBottom: { marginBottom: '4rem' },
  container: {
    overflowY: 'scroll',
    height: 'calc(100% - 50px)',
    marginTop: '50px',
    padding: '0 30px 30px 30px',
  },
  outputContainer: {
    pointerEvents: 'none',
  },
  tableHeader: {
    fontSize: '0.8rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#777',
    height: '100%',
    verticalAlign: 'top',
  },
}));

const Tip = ({ children }) => (
  <div className="d-flex align-items-center my-3">
    <img
      className="mr-2"
      alt="examples"
      src={lightbulb}
      height={16}
    />
    <p style={{ color: '#828282' }} className="m-0">
      <b style={{ color: '#525252' }}>Tip! </b>
      {' '}
      {children}
    </p>
  </div>
);

const ExampleInput = ({
  title,
  description,
  tips,
  allFields,
  setDrawerState,
  onClose,
  drawerState,
  children,
}) => {
  const sampleFields = React.useMemo(() => {
    const res = [];
    allFields.forEach((f) => {
      if (f.example) {
        res.push({ title: f.label, value: f.example });
      }
    });
    return res;
  }, [allFields]);

  const classes = useStyles();
  return (
    <Drawer anchor="right" open={drawerState}>
      <div className={classes.drawerCloseContainer}>
        <div
          className={classes.examplesWrap}
          onClick={() => (onClose ? onClose() : setDrawerState(false))}
        >
          <FontAwesomeIcon icon="arrow-right" className="mr-2" />
          <span>Close</span>
        </div>
        <a href="https://copysmith.ai/blog">
          <span className="mr-2">More examples</span>
          <FontAwesomeIcon icon="external-link-alt" />
        </a>
      </div>
      <div className={classes.container}>
        <div className={classes.sectionContainer}>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="font-weight-bold mb-4 text-left p-0">
              {title}
              {' '}
              Example
            </h4>
          </div>
          <p className="font-weight-bold mb-2">Input</p>
          <table>
            <tbody>
              {sampleFields
                  && sampleFields.map((input, i) => {
                    const paddingBottom = sampleFields.length === i + 1
                      ? '0'
                      : '10px';
                    return (
                      <tr key={input.title}>
                        <th
                          className={classes.tableHeader}
                          style={{ padding: `0 30px ${paddingBottom} 0` }}
                        >
                          {input.title}
                        </th>
                        <td
                          style={{
                            verticalAlign: 'top',
                            padding: `0 0 ${paddingBottom} 0`,
                          }}
                        >
                          {input.value}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <div className={classes.sectionContainer}>
          <p className="font-weight-bold mb-2">Output</p>
          <div className={classes.outputContainer}>
            {children}
          </div>
        </div>
        {description && (
          <div className={classes.sectionContainer}>
            <p className="font-weight-bold mb-2">About this template</p>
            <p>{description}</p>
          </div>
        )}
        <Divider />
        <div className={clsx(classes.sectionContainer, classes.extraMarginBottom)}>
          <p>
            Have a good example you think could help others?
            {' '}
            <br />
            {' '}
            <a
              href="https://form.typeform.com/to/omJE2dAV"
              target="blank"
            >
              Share it with us here!
            </a>
          </p>
        </div>

        {tips && (
          <div className={classes.sectionContainer}>
            {tips.map((tip) => <Tip key={tip}>{tip}</Tip>)}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ExampleInput;
