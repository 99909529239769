import { getBigCommerceState, setBigCommerceState } from './localStorage.service';

const state = getBigCommerceState();

const validateUrl = () => {
  const { location: { ancestorOrigins = {} } = {} } = (window?.top[1] || {});
  const urlsArr = Array.from(ancestorOrigins);

  return urlsArr.some((url) => url.endsWith('mybigcommerce.com'));
};

const validateJwtPayload = (payload) => {
  if (!validateUrl()) {
    state.isInvalidUrl = true;
    return;
  }

  const decodedPayload = atob(payload.split('.')[1]);
  const payloadObject = JSON.parse(decodedPayload || {});

  if (payloadObject.sub?.startsWith('stores/')) {
    state.payload = payloadObject;
    state.isBigCommerce = true;

    setBigCommerceState(state);
  }
};

const isBigCommerce = () => {
  try {
    if (!validateUrl()) {
      return false;
    }

    return !!state.isBigCommerce;
  } catch (error) {
    return false;
  }
};

const getPayload = () => state.payload;

const isInvalidUrl = () => state.isInvalidUrl;

const bigCommerceService = {
  validateJwtPayload,
  isBigCommerce,
  getPayload,
  isInvalidUrl,
};

export default bigCommerceService;
