import React from 'react';
import {
  Backdrop, Fade, Modal, Button,
} from '@material-ui/core';

import useStyles from './ShopifySuccessModal.styles';

const ShopifySuccessModal = ({
  modalOpen,
  image,
  productName,
  exit,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    exit();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={classes.paper}>
          <div className={classes.root}>
            {image
            && (
              <img
                src={image.src ? image.src : ''}
                alt={image.alt ? image.alt : 'alt'}
                className={classes.image}
              />
            )}
            <div className={classes.innerContent}>
              <div className={classes.header}>
                Exported -
                {' '}
                {productName}
                {' '}
                Product Description
              </div>
              <div>
                Your Shopify product description has been updated.
              </div>
              <Button onClick={handleClose}>
                Return to Generator
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ShopifySuccessModal;
