/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  TextField,
} from '@material-ui/core';

const StringField = ({
  formik,
  name,
  className,
  helperText,
  ...otherProps
}) => {
  // Get deep value, e.x. uiOptions.type
  const parts = name.split('.');
  let value = formik.values;
  parts.forEach((p) => {
    value = value[p] ? value[p] : '';
  });

  return (
    <TextField
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      size="small"
      fullWidth
      id={name}
      name={name}
      label={name}
      value={value}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={(formik.touched[name] && formik.errors[name]) || helperText}
      variant="outlined"
      className={className}
      {...otherProps}
    />
  );
};

export default StringField;
