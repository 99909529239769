import React from 'react';

import billingService from '@root/resources/billing/billing.service';
import useBillingInfo from '@root/resources/billing/useBillingInfo';

export default function useProration(newPlanId) {
  const { data: billingInfo } = useBillingInfo();

  const [proration, setProration] = React.useState(null);

  const prorateInvoice = async () => {
    if (!billingInfo) {
      return;
    }

    if (billingInfo.planName === 'free') {
      setProration({});
      return;
    }

    const response = await billingService.getProration(newPlanId);
    setProration(response.plan);
    if (response.error) {
      setProration({});
    }
  };

  React.useEffect(() => {
    prorateInvoice();
  }, [billingInfo]);

  return proration;
}
