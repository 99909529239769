import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { IconInfoCircle } from '@tabler/icons-react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.colors.neutrals600,
    margin: ({ margin }) => `0 ${margin}px`,
  },
  iconWrapper: {
    lineHeight: 0,
  },
}));

const TooltipIcon = ({ className = '', content, margin, tooltipProps }) => {
  const classes = useStyles({ margin });

  if (!content) {
    return null;
  }

  return (
    <SimpleTooltip
      content={content}
      {...tooltipProps}
    >
      <span className={classes.iconWrapper}>
        <IconInfoCircle
          size={16}
          className={clsx(classes.icon, className)}
        />
      </span>
    </SimpleTooltip>
  );
};

export default TooltipIcon;
