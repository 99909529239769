import React from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import TruncatedText from '@root/components/TruncatedText';
import billingResource from '@root/resources/billing';
import Button from '@root/components/buttons/Button';
import useCardAlert from '@root/views/Layout/components/Navbar/hooks/useCardAlert';
import { formatCredits, lowCreditsLimit } from '@root/resources/billing/billingInfo.helpers';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

import OrganizationDropdown from './OrganizationDropdown';
import useStyles from './LeftPart.styles';

const LeftPart = () => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();

  const { data: { limit: creditsLeft, enterpriseDisplayLimit } = {} } = billingResource.useLimits();
  const {
    data: {
      priceId,
      trialEndOn,
      cardStatus,
    } = {},
  } = billingResource.useBillingInfo();

  const isNewLeftSidebar = currentUser.featureToggles.newLeftSidebar;
  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;
  const trialEndDate = trialEndOn && DateTime.fromSeconds(trialEndOn);
  const daysLeft = trialEndDate && Math.round(trialEndDate.diffNow().as('days'));
  const showDaysLeft = priceId === 'free' && trialEndOn;
  const showCredits = currentUser?.showCredits && (enterpriseDisplayLimit !== null
    ? Number.isInteger(enterpriseDisplayLimit) : Number.isInteger(creditsLeft));

  const cardAlert = useCardAlert({ cardStatus, currentUser });

  const { currentExternalSource } = externalSourcesContext.useExternalSource();
  const { toggles: { redirectToAppTopBar } } = currentExternalSource;

  return (
    <div className={classes.container}>
      {!isNewLeftSidebar && <OrganizationDropdown />}

      {cardAlert || (
        <div>
          {!isNewLeftSidebar && <TruncatedText className={classes.userEmail}>{currentUser?.email}</TruncatedText>}

          {showDaysLeft && (
            <div className={classes.trialBlock}>
              <TruncatedText className={clsx(classes.trialText, { [classes.red]: daysLeft <= 3 })}>
                {daysLeft <= 0
                  ? 'Trial plan - Expired'
                  : `Trial Plan - ${daysLeft} day${daysLeft > 1 ? 's' : ''} left`}
              </TruncatedText>

              <div className={classes.buttonsContainer}>
                <Link
                  to="/profile/plans"
                  target={redirectToAppTopBar && '_blank'}
                >
                  <Button
                    variant="link"
                    className={classes.trialButton}
                  >
                    Subscribe
                  </Button>
                </Link>
              </div>
            </div>
          )}

          {showCredits && (
            <div className={classes.trialBlock}>
              <TruncatedText className={clsx(
                classes.totalCredits,
                { [classes.lowCredits]: creditsLeft <= lowCreditsLimit },
              )}
              >
                Total Words:
                {' '}
                <SimpleTooltip
                  content={creditsLeft > lowCreditsLimit && creditsLeft}
                  placement="bottom"
                >
                  <span className={classes.creditsNumber}>
                    {formatCredits(
                      enterpriseDisplayLimit !== null ? enterpriseDisplayLimit
                        : creditsLeft,
                    )}

                  </span>
                </SimpleTooltip>
              </TruncatedText>

              {creditsLeft <= lowCreditsLimit && isAdminOrOwner && !currentUser.onSelfServeEnterprisePlan && (
                <div className={classes.buttonsContainer}>
                  <Link
                    to="/addons"
                    target={redirectToAppTopBar && '_blank'}
                  >
                    <Button
                      variant="link"
                      className={classes.trialButton}
                    >
                      Add more words
                    </Button>
                  </Link>

                  <span>or</span>

                  <Link
                    to="/profile/plans"
                    target={redirectToAppTopBar && '_blank'}
                  >
                    <Button
                      variant="link"
                      className={classes.trialButton}
                    >
                      Upgrade
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LeftPart;
