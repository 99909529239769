import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 316px)',
    gridTemplateRows: 'max-content max-content',
    justifyContent: 'center',
    gap: '24px',
  },

  cardContentViewContainer: {
    flex: 1,
    overflowX: 'auto',
    paddingTop: '24px',

    display: 'grid',
    gap: '24px',
    gridTemplateColumns: 'repeat(auto-fill, 316px)',

    gridAutoRows: '210px',
    justifyContent: 'center',
  },

  divider: {
    gridColumn: '1/-1',

    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,
  },
}));

export default useStyles;
