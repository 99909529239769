import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(() => ({
  circle: {
    height: 16,
    width: 16,
    background: '#4ED71E',
    border: '1px solid #FFFFFF',
    position: 'absolute',
    top: 43,
    left: 45,
    borderRadius: '50%',
  },
  icon: {
    position: 'absolute',
    right: 1,
    width: 12,
    top: -5,
    color: '#fff',
  },
}));

const PublishedIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.circle}>
      <CheckIcon className={classes.icon} />
    </div>
  );
};

export default PublishedIcon;
