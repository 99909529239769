import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Folder } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  filesCount: {
    display: 'inline-block',
    position: 'relative',
    color: '#fff',
    width: '20px',
    height: '16px',
    margin: '0 3px',
    verticalAlign: 'text-top',
  },
  iconOverlapText: {
    display: 'flex',
    position: 'absolute',
    zIndex: 10,
    fontSize: '10px',
    margin: '0 auto',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  folderIcon: {
    position: 'absolute',
    width: '28px',
    height: '24px',
    top: '-4px',
    left: '-4px',
    color: theme.palette.primary.main,
    fontSize: '24px',
  },
  isGrey: {
    color: '#000',
  },
}));

const FolderIconWithNumber = ({ numberAsString = '', isGrey = false }) => {
  const classes = useStyles();

  return (
    <span className={classes.filesCount}>
      <Folder className={clsx(classes.folderIcon, {
        [classes.isGrey]: isGrey,
      })}
      />
      <span className={classes.iconOverlapText}>{numberAsString}</span>
    </span>
  );
};

export default FolderIconWithNumber;
