import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const getDocuments = async () => {
  const documents = await api.googleDocs.getDocuments();
  if (documents.isBadRequest) {
    return null;
  }
  return documents
    .map((document) => ({ label: document.name, value: document.id }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export default function useDocuments() {
  return useQuery('googleDocs.getDocuments',
    () => getDocuments());
}
