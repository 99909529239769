import React from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import { FormHelperText } from '@material-ui/core';

import { FieldLabel } from '@root/components/form';

import useStyles from './CardField.styles';

const CardField = ({ component: Component, onFieldComplete, label, ...props }) => {
  const classes = useStyles();
  const [field, { error }, { setError }] = useField(props);

  const options = {
    classes: {
      // base needs error class if field is touched and empty
      base: clsx(classes.input, error && classes.inputError),
      // complete:,
      // empty:,
      focus: classes.inputFocus,
      invalid: classes.inputError,
    },
    showIcon: true,
  };

  const { name } = field;

  const handleChange = (event) => {
    if (event.empty) {
      setError('Required.');
    } else {
      setError(event.error?.message);
    }
    onFieldComplete((fields) => ({ ...fields, [name]: event.complete }));
  };

  return (
    <div>
      <FieldLabel label={label} required />
      <Component
        onChange={handleChange}
        options={options}
      />
      {!!error && (
        <FormHelperText
          error={!!error}
        >
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default CardField;
