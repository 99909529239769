import { useMutation } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const updateProductDescription = async ({
  productId,
  description,
}) => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.updateProductDescription({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
    productId,
    description,
  });

  return products;
};

export default function useUpdateProductDescription() {
  return useMutation(
    ({ productId, description }) => updateProductDescription({ productId, description }),
  );
}
