import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paginationLink: {
    cursor: 'pointer',
    marginRight: '4px',
    padding: '2px 8px',
  },
  paginationItem: {
    marginRight: '4px',
    userSelect: 'none',
  },
  paginationTextLink: {
    userSelect: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default',
    color: theme.palette.grey.pure,
  },
  paginationWrap: {
    display: 'flex',
    marginTop: '12px',
    width: '100%',
    justifyContent: 'center',
    paddingRight: '144px',
  },
  pageLinksWrap: {
    margin: '0 32px',
  },
  activePaginationLink: {
    background: 'rgba(68,61,246,0.1)',
    color: '#443DF6',
    borderRadius: '4px',
  },
  itemPerPageWrap: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '12px',
    paddingRight: '64px',
  },
  perPageSelect: {
    height: '32px',
    overflow: 'hidden',
    marginLeft: '12px',
  },
}));

export default useStyles;
