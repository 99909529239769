import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: '2rem 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontSize: '1.45rem',
    fontWeight: '600',
    fontFamily: '"Inter", sans-serif',
  },
}));

export default useStyles;
