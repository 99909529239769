import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  filterList: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: ({ length }) => `repeat(${length}, 130px [col-start])`,
    maxWidth: '100%',
    overflowX: 'auto',
  },
  inlineFilterList: {
    display: 'flex',
    gap: 4,
  },
});

export default useStyles;
