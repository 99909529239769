import React from 'react';

import techImg from '@root/assets/engineImages/technology.png';
import DocumentView from '@root/components/DocumentView';

export default {
  _id: 'FT-TECH',
  title: 'Technology',
  description: 'It’s hard to stay current in the ever-changing world of tech, so let Ali do it for you.  Establish your brand as a thought leader in the tech space.',
  longerDescription: 'Because technology is always changing, it’s a challenge for marketers to remain up to date on the latest trends.  Stay ahead of the curve in your marketing content and speak with confidence about tech.',
  imageSrc: techImg,
  bigImg: techImg,
  sampleGenerations: [
    <div>
      <h2 style={{ fontWeight: 500, fontSize: '24px' }}>
        iPhone 13 from Secondhand Gadgets
      </h2>
      An amazing smart phone for an unbelievable price.
      Incredibly capable and durable, this iPhone 13 is ready to roll
      out of the box and into your life. It features a 6.1-inch Liquid Retina display,
      Apple A15 Bionic chip, 4GB of RAM, brilliant 12MP dual camera system,
      ultrafast Face ID and more.
    </div>,
    <div>
      Is the iPhone 13 Really Better than the iPhone 12?
      Apple has just announced its new iPhone 13,
      and you want to know if it’s better than the iPhone 12.
      This is the blog post for you. We’ll look at what’s new, what’s different,
      and give our first impressions of Apple’s latest smartphone.
    </div>,
    {
      content:
  <DocumentView
    key="333"
    noMargin
    transparentBackground
    fileData={{
      companyName: '',
    }}
    document={{
      _id: '1',
      templateType: 'googleAd',
      content: {
        headline1: 'Incredibly Powerful',
        headline2: 'The New iPhone 13',
        headline3: '4GB of RAM, 128GB',
        description1: 'The iPhone 13 from Copysmith.',
        description2: 'Get an incredible deal on a professional grade iPhone 13.',
      },
      liked: false,
      editing: false,
      votes: null,
    }}
    saveEditor={false}
    options={{ showFooter: false, hideWordsBadges: true, canEdit: false }}
    onCopy={() => {}}
  />,
      noPadding: true,
    },
  ],
};
