import React from 'react';
import { makeStyles, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  slogan: {
    ...theme.typography.h6,
  },
  headline: {
    ...theme.typography.h6,
  },
  subheading: {
    ...theme.typography.subtitle2,
  },
  preview: {
    width: '100%',
  },
}));

const PreviewButton = ({
  documentId,
}) => {
  const classes = useStyles();

  return (
    <Link
      size="small"
      variant="outlined"
      color="primary"
      className={classes.preview}
      href={`/preview/?document=${documentId}`}
      target="_blank"
    >
      {'Open in new page '}
      <FontAwesomeIcon
        className={classes.previewIcon}
        icon={['fas', 'paper-plane']}
      />
    </Link>
  );
};

export default PreviewButton;
