import React from 'react';
import { makeStyles } from '@material-ui/core';

import useGetProducts from '@root/resources/akeneo/useGetProducts';
import useUpdateProductDescription from '@root/resources/akeneo/useUpdateProductDescription';
import useGetChannels from '@root/resources/akeneo/useGetChannels';
import { SelectField } from '@root/components/form';
import AkeneoDescriptionFieldSelect from '@root/components/AkeneoDescriptionFieldSelect/AkeneoDescriptionFieldSelect';

import PublishToPimModal from '../PublishToPim/PublishToPimModal';

const findSameItems = (arrays) => {
  if (arrays.length <= 1) {
    return arrays[0] || [];
  }

  const firstArray = arrays[0];
  const remainingArrays = arrays.slice(1);

  return firstArray.filter((string) => {
    return remainingArrays.every((array) => array.includes(string));
  });
};

const useStyles = makeStyles((theme) => ({
  channelSelect: {
    width: 'calc(70% - 8px)',
  },
  localeSelect: {
    width: 'calc(30% - 8px)',
  },
  selectWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const CustomElement = ({
  channels,
  onChannelChange,
  selectedChannels,
  onLocaleChange,
  selectedLocale,
  onFieldChange,
  field,
}) => {
  const classes = useStyles();

  const locales = findSameItems(
    channels.filter(
      (channel) => selectedChannels.includes(channel.code),
    ).map((channel) => channel.locales),
  );

  return (
    <>
      <div className={classes.selectWrap}>
        <SelectField
          classNames={{ root: classes.channelSelect }}
          multiple
          onChange={onChannelChange}
          options={channels.map((channel) => ({
            value: channel.code,
            label: channel.code,
          }))}
          value={selectedChannels}
          placeholder="Select channels"
        />
        <SelectField
          classNames={{ root: classes.localeSelect }}
          onChange={onLocaleChange}
          options={locales.map((locale) => ({
            value: locale,
            label: locale,
          }))}
          value={selectedLocale}
          placeholder="Select locale"
          disabled={selectedChannels.length === 0}
        />
      </div>
      <div>
        <AkeneoDescriptionFieldSelect
          onChange={onFieldChange}
          value={field}
          className={classes.fieldSelect}
        />
      </div>
    </>
  );
};

const PublishToAkeneoModal = ({
  unmount,
  text,
}) => {
  const { mutateAsync: updateProductDescription } = useUpdateProductDescription();
  const { data: { data: channels = [] } = {} } = useGetChannels();

  const [selectedChannels, setSelectedChannels] = React.useState([]);
  const [selectedLocale, setSelectedLocale] = React.useState(null);
  const [selectedField, setSelectedField] = React.useState(null);

  const onUpdateProductDescription = ({
    productId: productCode,
    description,
  }) => {
    return updateProductDescription({
      productCode,
      description,
      locale: selectedLocale,
      channels: selectedChannels,
      field: selectedField,
    });
  };

  const validate = (product) => {
    if (!product.values[selectedField]) {
      return 'This product has no description field.';
    }

    return null;
  };

  return (
    <PublishToPimModal
      useGetProducts={useGetProducts}
      updateProductDescription={onUpdateProductDescription}
      title="Publish to Akeneo"
      unmount={unmount}
      text={text}
      disabled={selectedChannels.length === 0 || !selectedLocale || !selectedField}
      customElement={(
        <CustomElement
          channels={channels}
          onChannelChange={(arg) => setSelectedChannels(Array.isArray(arg) ? arg : arg.target.value)}
          selectedChannels={selectedChannels}
          selectedLocale={selectedLocale}
          onLocaleChange={(arg) => setSelectedLocale(arg.target.value)}
          field={selectedField}
          onFieldChange={(arg) => setSelectedField(arg.target.value)}
        />
      )}
      mapProduct={(product) => {
        const price = product.values?.price ? product.values?.price[0]?.data.find((p) => p.currency === 'USD') : null;
        return {
          id: product.code,
          image: null,
          sku: null,
          name: product.code,
          price: price?.amount,
        };
      }}
      validate={validate}
    />
  );
};

export default PublishToAkeneoModal;
