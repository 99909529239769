import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface UpdateOptions extends RequestOptions {
  firstName: string;
  surname: string;
  email: string;
  lastCheckedNews: number;
}

export class User {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async getCurrentUser(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/users/currentUser", data, options);

    return result;
  }

  public async update(id: string, data: UpdateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/users/${id}`, data, options);

    return result;
  }

  public async getCompanyMembers(options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.get("/users", {}, options);
    
    return result;
  }

  public async getAllCompanyMembers(options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.get("/users?withDeactivated=true", {}, options);

    return result;
  }

  public async deactivateCompanyMember(userId: string, options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.post(`/users/company/deactivate/${userId}`, {}, options);

    return result;
  }

  public async reactivateCompanyMember(userId: string, options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.post(`/users/company/reactivate/${userId}`, {}, options);

    return result;
  }

  public async removeCompanyMember(removeUserId:string, options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.del(`/users/company/${removeUserId}`, {}, options);
    
    return result;
  }

  public async completeOnboardingStep(data: {item: string, abandonedStepIndex: number}, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/users/onboarding", data, options);

    return result;
  }

  public async getOnboardingReward(options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/users/onboarding/reward", {}, options);

    return result;
  }
}