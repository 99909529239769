import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
    >
      <path fill="#848DD3" d="M10.8 20a9.7 9.7 0 0 0 9.7-9.7V0C9.454 0 .5 8.954.5 20h10.3Z" />
      <path fill="#DD2828" d="M20.5 29.923a9.7 9.7 0 0 1 9.7-9.7h10.3c0 11.045-8.954 20-20 20v-10.3Z" />
      <path fill="#F9E99B" d="M10.8 20.222a9.7 9.7 0 0 1 9.7 9.7v10.3c-11.046 0-20-8.954-20-20h10.3Z" />
      <path fill="#000" d="M38.332 7.044c-.7.425-1.403.52-2.082.636-1.04.176-1.675.245-2.853.634-.824.27-1.611.641-2.343 1.106a8.936 8.936 0 0 1 2.655-1.656c1.198-.474 1.917-.584 2.97-.947.764-.263 1.312-.524 1.807-.966.226-1.31 1.104-3.037 1.104-3.037-10.202 0-19.09 9.206-19.09 19.408 0 0 .39-1.853 1.21-3.166.497-.798 1.345-1.92 2.413-2.763 1.097-.866 2.873-1.746 3.855-2.017 2.477-.681 4.175-.457 5.744-.9 1.349-.38 3.046-1.254 3.856-2.993.5-1.073.605-2.03.754-3.339Z" />
    </svg>
  );
};

export default Logo;
