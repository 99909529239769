export const templatesAvailability = {
  adIdeas: ['adHeadline', 'carousel', 'facebookAd', 'googleAd', 'instagramAd', 'linkedinAds', 'socialMediaCaptions', 'painBenefitSolution', 'beforeAfterBridge'],
  adHeadline: ['adIdeas', 'carousel', 'facebookAd', 'googleAd', 'instagramAd', 'linkedinAds', 'socialMediaCaptions', 'painBenefitSolution', 'beforeAfterBridge'],
  instagramProductDescription: ['amazonProductDescription'],
  amazonProductDescription: ['instagramProductDescription'],
  productDescription: ['instagramProductDescription', 'amazonProductDescription'],
  linkedinAds: ['carousel'],
  googleAd: ['socialMediaCaptions', 'carousel', 'facebookAd', 'instagramAd', 'linkedinAds'],
  instagramAd: ['socialMediaCaptions', 'carousel', 'facebookAd', 'linkedinAds'],
  facebookAd: ['socialMediaCaptions', 'carousel', 'instagramAd', 'linkedinAds'],
};

export const campaignAvailableTemplates = ['adIdeas', 'adHeadline', 'instagramProductDescription', 'amazonProductDescription', 'productDescription', 'linkedinAds', 'googleAd', 'instagramAd', 'facebookAd'];

export const freeFormAvailableTemplates = ['blogIdea', 'faqIdeas', 'valueProposition', 'articleSummary', 'beforeAfterBridge', 'blogIntro', 'blogOutline', 'contentExpander', 'contentImprover', 'contentRewriter', 'contentOptimizer', 'nicheContentExpander', 'painBenefitSolution', 'pitchYourself'];

export const moreLikeThisAvailableTemplates = ['productDescription', 'googleAd', 'instagramAd', 'facebookAd', 'blogIdea', 'faqIdeas', 'salesEmail', 'seoMetatag', 'tagline', 'valueProposition'];

export const createAvailableTemplates = [...new Set([
  ...campaignAvailableTemplates, ...freeFormAvailableTemplates, ...moreLikeThisAvailableTemplates,
])];
