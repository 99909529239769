import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import { IconSearch } from '@tabler/icons-react';

import FieldLabel from '@root/components/form/FieldLabel';
import { colors } from '@root/material.theme';

const SearchField = ({
  value, onChange, label, labelTooltip, fullWidth, placeholder, debounce, classNames,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedOnChange = useDebouncedCallback(
    (newSearchQuery) => {
      onChange(newSearchQuery);
    },
    500,
  );

  const handleChange = (e) => {
    if (debounce) {
      debouncedOnChange(e.target.value);
      setInputValue(e.target.value);
    } else {
      onChange(e.target.value);
      setInputValue(e.target.value);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={classNames?.container}>
      <FieldLabel
        label={label}
        labelTooltip={labelTooltip}
      />
      <OutlinedInput
        value={inputValue}
        onChange={handleChange}
        fullWidth={fullWidth}
        startAdornment={(
          <IconSearch
            height={24}
            width={24}
            color={colors.neutrals300}
          />
        )}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  fullWidth: PropTypes.bool,
  debounce: PropTypes.bool,
};

SearchField.defaultProps = {
  value: '',
  onChange: () => {},
  placeholder: null,
  label: null,
  labelTooltip: null,
  fullWidth: true,
  debounce: false,
};

export default SearchField;
