import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  navbarContainer: {
    zIndex: 99,
    minHeight: '56px',
    padding: '6px 24px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.colors.neutrals200}`,
  },
  trialBlock: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.black,
    gap: 8,
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
  },
}));
