import React from 'react';

const Visa = () => {
  return (
    <svg
      width="40"
      height="30"
      viewBox="-822 823.1 56.7 56.7"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#1435cb" d="m-797.8 845.3-3.3 15.2h4l3.2-15.2zM-803.6 845.3l-4.1 10.3-1.7-8.8c-.2-1-1-1.5-1.8-1.5h-6.7l-.1.4c1.4.3 2.9.8 3.9 1.3.6.3.7.6.9 1.3l3.1 12.1h4.2l6.4-15.2h-4.1zM-772.5 845.3h-3.4c-.8 0-1.4.4-1.7 1.1l-5.9 14.1h4.1l.8-2.3h5l.5 2.3h3.6l-3-15.2zm-4.8 9.8 2.1-5.7 1.2 5.7h-3.3zM-788.7 849.5c0-.5.5-1.1 1.7-1.3.6-.1 2.1-.1 3.9.7l.7-3.2c-.9-.3-2.2-.7-3.7-.7-3.9 0-6.6 2.1-6.6 5 0 2.2 2 3.4 3.4 4.1 1.5.7 2 1.2 2 1.9 0 1-1.2 1.5-2.4 1.5-2 0-3.1-.5-4-1l-.7 3.3c.9.4 2.6.8 4.4.8 4.1 0 6.8-2 6.8-5.2 0-3.8-5.6-4.1-5.5-5.9z" />
    </svg>
  );
};

export default Visa;
