import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <g
        fill="#993C00"
        fillRule="evenodd"
        clipPath="url(#a)"
        clipRule="evenodd"
      >
        <path d="M12.256 7.324c-1.312-.053-2.593.32-3.604 1.037-1.009.716-1.673 1.72-1.894 2.813-.221 1.09.009 2.221.661 3.186a.667.667 0 0 1 .08.584l-.498 1.495 1.994-.424a.667.667 0 0 1 .43.052 5.998 5.998 0 0 0 3.76.47c1.28-.253 2.396-.908 3.15-1.823.752-.913 1.096-2.023.988-3.126-.108-1.103-.665-2.152-1.592-2.943-.93-.793-2.163-1.268-3.475-1.32ZM5.963 18a.664.664 0 0 0 .187-.016l2.905-.618a7.345 7.345 0 0 0 4.39.48c1.558-.308 2.954-1.113 3.919-2.283.966-1.172 1.43-2.631 1.286-4.104-.144-1.471-.883-2.829-2.054-3.828-1.169-.997-2.693-1.573-4.286-1.638-1.593-.065-3.167.386-4.43 1.282s-2.135 2.184-2.43 3.635a5.484 5.484 0 0 0 .684 3.914l-.763 2.289a.664.664 0 0 0 .358.83.663.663 0 0 0 .234.057Z" />
        <path d="M15.221 10.964a.667.667 0 0 1-.185.924l-2 1.333a.667.667 0 0 1-.84-.083l-.948-.947-1.545 1.03a.667.667 0 0 1-.74-1.109l2-1.333a.667.667 0 0 1 .842.083l.947.947 1.545-1.03a.667.667 0 0 1 .924.185Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h16v16H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};
