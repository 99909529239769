import { useQuery } from 'react-query';

import api from '@root/api';

const getCurrentBulkJob = async () => {
  const currentBulkJob = await api.akeneo.getCurrentBulkJob();

  return currentBulkJob;
};

export default function useGetCurrentBulkJob() {
  return useQuery(['akeneo.getCurrentBulkJob'], () => getCurrentBulkJob(), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
