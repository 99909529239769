import React from 'react';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './StatusBadge.styles';

const StatusBadge = ({
  state, tooltipText,
}) => {
  const classes = useStyles();

  if (state === 'inProgress') {
    return (
      <span className={clsx(classes.badge, classes.yellow)}>
        In Progress
      </span>
    );
  }

  if (state === 'canceling') {
    return (
      <span className={clsx(classes.badge, classes.yellow)}>
        Canceling
      </span>
    );
  }

  if (state === 'canceled') {
    return (
      <Tooltip
        disableHoverListener={!tooltipText}
        title="Not all of your rows were successfully processed."
      >
        <div>
          <span className={clsx(classes.badge, classes.red)}>
            Canceled
          </span>
        </div>
      </Tooltip>
    );
  }

  if (state === 'failed') {
    return (
      <Tooltip
        disableHoverListener={!tooltipText}
        title={tooltipText}
      >
        <div>
          <span className={clsx(classes.badge, classes.red)}>
            Failed
          </span>
        </div>
      </Tooltip>
    );
  }

  if (state === 'pending') {
    return (
      <Tooltip
        disableHoverListener={!tooltipText}
        title={tooltipText}
      >
        <div>
          <span className={clsx(classes.badge, classes.blue)}>
            Pending
          </span>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      disableHoverListener={!tooltipText}
      title={tooltipText}
    >
      <div>
        <span className={clsx(classes.badge, classes.green)}>
          Completed
        </span>
      </div>
    </Tooltip>
  );
};

export default StatusBadge;
