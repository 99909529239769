import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  planBadge: {
    display: 'grid',
    placeItems: 'center',
    width: 'max-content',

    textTransform: 'none',

    borderRadius: '9px',
    padding: '0 8px',
    margin: '0 8px',
    marginTop: '4px',
    marginBottom: '12px',
  },
  planText: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));
