import React from 'react';
import { useParams } from 'react-router-dom';

import useStyles from './MadeForYouCopy.styles';

const MadeForYouCopy = () => {
  const { bucket } = useParams();
  const classes = useStyles();

  if (bucket && bucket !== 'made-for-you') {
    return null;
  }

  return (
    <div className={classes.content}>
      <p>
        This section is&nbsp;
        <b>Made for you</b>
        ! Full of&nbsp;
        <b>your favorited templates</b>
        &nbsp;and&nbsp;
        <b>templates we think you&#39;ll enjoy.</b>
      </p>
      <p>
        Happy Copysmithing!
      </p>
    </div>
  );
};

export default MadeForYouCopy;
