import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useUpdateFile() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ fileId, data }) => api.files.update(fileId, data),
    {
      onSuccess: (response, variables) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const file = response;

        const { data: { folderId } } = variables;

        queryClient.setQueryData(['files.getById', file._id], () => file);
        queryClient.invalidateQueries('files.workflowStats');
        queryClient.getQueryCache().findAll('files.list')
          .forEach(({ queryKey: key, state }) => {
            if (!state.data) return;
            if (
              key[1]?.folderId === folderId
              && state.data.files.find(({ _id }) => _id === file._id)
            ) {
              return;
            }
            if (folderId) { // file folder changed
              if (key[1]?.folderId === folderId) {
                queryClient.setQueryData(
                  key,
                  () => ({
                    ...state.data,
                    files: [...state.data.files, file],
                  }),
                );
                return;
              }

              queryClient.setQueryData(
                key,
                () => ({
                  ...state.data,
                  files: state.data.files.filter((f) => f._id !== file._id),
                }),
              );
              return;
            }

            queryClient.setQueryData(
              key,
              () => ({
                ...state.data,
                files: state.data.files.map((f) => (f._id === file._id ? file : f)),
              }),
            );
          });
      },
    },
  );
}
