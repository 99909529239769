import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Divider,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import theme from '@root/material.theme';

import sections from '../constants';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 240,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

  listItemStyle: {
    cursor: 'pointer',
    paddingLeft: '24px',
    '&:hover': {
      opacity: 0.6,
    },
  },
  selected: {
    background: theme.palette.colors.lightGrey,
  },
}));

const SideBar = ({ setSelected, selected }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <List>
        <ListItem
          className={classes.listItemStyle}
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
          <ListItemText primary="Copysmith" />
        </ListItem>
        {sections.map((section) => (
          <>
            <Divider />
            <List>
              {Object.values(section.properties).map((subSection) => {
                const isSelected = (selected.id === subSection.id);
                return (
                  <ListItem
                    className={clsx(classes.listItemStyle, {
                      [classes.selected]: isSelected,
                    })}
                    onClick={() => setSelected(subSection.id)}
                    key={subSection.id}
                  >
                    <ListItemIcon>
                      {subSection.icon ? <subSection.icon /> : subSection.iconImg}

                    </ListItemIcon>
                    <ListItemText secondary={subSection.name} />
                  </ListItem>
                );
              })}
            </List>
          </>
        ))}

      </List>
    </Drawer>
  );
};

export default SideBar;
