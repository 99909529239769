import React from 'react';
import { ThemeProvider } from '@material-ui/core';

import MainLayout from '@root/views/Layout';
import FolderContentNew from '@root/views/Dashboard/components/FolderContentNew';
import FolderSidebarNew from '@root/views/Dashboard/components/FolderSidebarNew';
import AuthContext from '@root/resources/auth/auth.context';
import FolderSidebar from '@root/views/Dashboard/components/FolderSidebar';
import FolderContent from '@root/views/Dashboard/components/FolderContent';
import newMaterialTheme from '@root/newMaterial.theme';

import { DashboardProvider } from './dashboardContext';
import DashboardModals from './components/Modals/DashboardModals';
import PageLoadListener from './components/PageLoadListener';
import RedirectListener from './components/RedirectListener';
import useStyles from './Dashboard.styles';

const Dashboard = () => {
  const { currentUser } = AuthContext.useAuth();

  const { newFilesPage } = currentUser.featureToggles;

  const classes = useStyles({ newFilesPage });

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <DashboardProvider>
        <MainLayout>
          <div className={classes.dashboard}>
            {!newFilesPage && (
              <>
                <FolderSidebar />
                <FolderContent />
              </>
            )}

            {newFilesPage && (
              <>
                <FolderSidebarNew />
                <FolderContentNew />
              </>
            )}

            <DashboardModals />
            <PageLoadListener />
            <RedirectListener />
          </div>
        </MainLayout>
      </DashboardProvider>
    </ThemeProvider>
  );
};

export default Dashboard;
