import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  createNewButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.neutrals600,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 12px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
  header: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '39px',
  },
  subHeader: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '2rem',
    textAlign: 'center',
    maxWidth: '430px',
  },
}));

export default useStyles;
