import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  select: {
    width: 230,
    marginLeft: 10,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    fontSize: '14px',
    fontWeight: 'normal',
  },
  emailInput: {
    flex: 1,
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '280px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
}));
