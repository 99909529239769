import { useQuery } from 'react-query';

import api from '@root/api';

const getFolders = async () => {
  const { results: allFolders } = await api.folders.list();

  return allFolders.map((f) => {
    const folderName = f.home
      ? 'Other'
      : f.title;
    return {
      _id: f._id,
      id: f._id,
      title: folderName,
      isSharedUncategorized: f.isSharedUncategorized,
      home: f.home,
      liked: f.liked,
      enablePrefill: f.enablePrefill,
      presetSettings: f.presetSettings,
      parentFolderId: f.parentFolderId,
      folderName,
      unifiedAcl: f.unifiedAcl,
      filesCount: f.filesCount,
      createdOn: f.createdOn,
    };
  });
};

export default function useFolders() {
  return useQuery(
    ['folders.list'],
    () => getFolders(),
    { keepPreviousData: true },
  );
}
