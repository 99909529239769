const industrySegmentOptions = [
  {
    label: 'Agency',
    value: 'Agency',
  },
  {
    label: 'eCommerce',
    value: 'eCommerce',
  },
  {
    label: 'SaaS',
    value: 'SaaS',
  },
  {
    label: 'Freelancer',
    value: 'Freelancer',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default industrySegmentOptions;
