import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Add } from '@material-ui/icons';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import AuthContext from '@root/resources/auth/auth.context';

import { NEW_PROJECT_MODAL_ID } from '../Modals/NewProjectModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '0.25rem',
    alignItems: 'center',
    padding: '0.5rem',
    overflow: 'hidden',
    color: '#aaa',
  },
  newRoot: {
    color: theme.palette.colors.primary,
    fontWeight: '600',
  },
  plusIcon: {
    marginRight: '0.4rem',
    fontSize: '1.0rem',
  },
  newPlusIcon: {
    fontSize: '22px',
    marginRight: '4px',
  },
  iconContainer: {
    display: 'flex',
  },
  text: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
  },
}));

const NewProjectButton = () => {
  const { dispatch } = useGlobalModals();

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  const handleCreateNewProject = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: NEW_PROJECT_MODAL_ID,
      },
    });
  };

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.newRoot]: newDesign })}
      onClick={handleCreateNewProject}
    >
      <div className={classes.iconContainer}>
        <Add className={clsx(classes.plusIcon, { [classes.newPlusIcon]: newDesign })} />
      </div>
      <span className={clsx(classes.text)}>
        {newDesign ? 'Add project' : 'New project' }
      </span>
    </div>
  );
};

export default NewProjectButton;
