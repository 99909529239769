import React, { useState } from 'react';

import Button from '@root/components/buttons/Button';

import InputButton from './components/InputButton';
import TextInput from './components/TextInput';
import useStyles from './Inputs.styles';

const formatInput = (input) => input.toLowerCase().trim();

const Inputs = ({ inputs, onChange, onPaste, onError, disabled }) => {
  const classes = useStyles();

  const [isAdding, setIsAdding] = useState(false);

  const isAddDisabled = disabled || inputs.length >= 5;

  const handleAdd = (newInput) => {
    onChange([...inputs, formatInput(newInput)]);
    setIsAdding(false);
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleInputClick = (text) => {
    onPaste(text);
  };

  const handleInputChange = (current, newInput) => {
    onChange(inputs.map((inp) => (inp === current ? formatInput(newInput) : inp)));
  };

  const handleInputDelete = (text) => {
    onChange(inputs.filter((i) => i !== text));
  };

  return (
    <div className={classes.inputsRoot}>
      {inputs.map((text) => (
        <InputButton
          key={text}
          text={text}
          onClick={handleInputClick}
          onChange={handleInputChange}
          onDelete={handleInputDelete}
          onError={onError}
          inputs={inputs}
          disabled={disabled}
        />
      ))}
      {isAdding && (
        <TextInput
          inputs={inputs}
          onSave={handleAdd}
          onError={onError}
          onClose={() => setIsAdding(false)}
        />
      )}
      {!disabled && (
        <Button
          variant="link"
          size="small"
          onClick={handleAddClick}
          disabled={isAddDisabled}
          className={classes.addButton}
        >
          Add a new input
        </Button>
      )}

    </div>

  );
};

export default Inputs;
