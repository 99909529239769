import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  product: {
    display: 'flex',
    backgroundColor: theme.palette.colors.neutrals50,
    height: 64,
    alignItems: 'center',
    padding: '7px 15px 7px 7px',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.colors.neutrals50}`,
    gap: theme.spacing(1),

    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  selected: {
    border: `1px solid ${theme.palette.colors.neutrals500}`,
  },
  productImage: {
    width: 48,
    height: 48,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
    backgroundColor: theme.palette.colors.white,
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  productInfoItem: {
    display: 'flex',
    width: 266,
  },
  productInfoItemNoSku: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productKey: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  productValue: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productPriceValue: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    backgroundColor: theme.palette.colors.neutrals100,
    borderRadius: theme.shape.borderRadius,
    padding: '2px 4px',
    maxWidth: 80,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  productPrice: {
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
}));

export default useStyles;
