/* eslint-disable no-new */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IconGripVertical, IconPlus } from '@tabler/icons-react';
import EditorJS from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import Underline from '@editorjs/underline';
import DragDrop from 'editorjs-drag-drop';
import Undo from 'editorjs-undo';
// import LinkTool from '@editorjs/link';
// import SimpleImage from '@editorjs/simple-image';
// import Table from '@editorjs/table';
// import Warning from '@editorjs/warning';
// import Image from '@editorjs/image';
// import Raw from '@editorjs/raw'
// import Quote from '@editorjs/quote'
// import Embed from '@editorjs/embed';
// import Code from '@editorjs/code';

import WordCount from '@root/views/Editor/tools/WordCount/WordCount';
import ImproveWriting from '@root/views/Editor/tools/ImproveWriting/ImproveWriting';
import CopyBlock from '@root/views/Editor/tools/CopyBlock';
import CustomDelete from '@root/views/Editor/tools/CustomDelete';
import PublishToBigCommerce from '@root/views/Editor/tools/PublishToBigCommerce';
import PublishToAkeneo from '@root/views/Editor/tools/PublishToAkeneo';
import TemplatesContext from '@root/resources/templates/templates.context';
import config from '@root/config';
import { isWindows } from '@root/utils/browser.utils';
import authContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import useIntegrations from '@root/resources/integrations/useIntegrations';

import Shortcuts from '../Shortcuts';
import { CustomImage, ModalImage } from '../../tools/CustomImage';

import useStyles from './EditorJS.styles';

const isDev = config.env === 'development';
const EDITOR_HOLDER_ID = 'editorjs';
const undoConfig = {
  shortcuts: {
    undo: 'CMD+Z',
    redo: isWindows ? 'CMD+Y' : 'CMD+SHIFT+Z',
  },
};

const renderIcon = (Component, selector) => {
  const element = document.querySelector(selector);

  if (element) {
    ReactDOM.render(<Component />, element);
  }
};

const Editor = ({
  formRef, editorRef, initialData, onChange, readOnly, showShortcuts,
  onAddImageClick, documentId,
}) => {
  const { templatesList } = TemplatesContext.useTemplates();
  const { currentExternalSource: { toggles } } = ExternalSourcesContext.useExternalSource();
  const classes = useStyles();
  const { currentUser } = authContext.useAuth();
  const withImages = currentUser.featureToggles.editorImageTool;
  const { data = [], isLoading: isIntegrationsLoading } = useIntegrations();

  const tunes = ['copy', 'delete'];
  if (toggles.showPublishToBigCommerce) {
    tunes.push('publishToBigCommerce');
  }

  const akeneoIntegration = data.find((integration) => integration.type === 'akeneo' && integration.status === 'active');
  if (!toggles.showPublishToBigCommerce && akeneoIntegration) {
    tunes.push('publishToBigAkeneo');
  }

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITOR_HOLDER_ID,
      logLevel: isDev ? 'VERBOSE' : 'WARN',
      data: initialData,
      // the way to set order of tools
      inlineToolbar: ['wordCount', 'improveWriting', 'bold', 'italic', 'link', 'marker', 'inlineCode', 'underline'],
      tunes,
      onReady: () => {
        if (isDev) {
          console.log('Editor is ready 🚀'); // eslint-disable-line no-console
        }
        editorRef.current = editor;
        const undo = new Undo({ editor, config: undoConfig });

        if (initialData) {
          undo.initialize(initialData);
        }

        new DragDrop(editor);

        // change icons for toolbar-actions
        renderIcon(IconPlus, '.ce-toolbar__plus');
        renderIcon(IconGripVertical, '.ce-toolbar__settings-btn');
      },
      onChange,
      autofocus: true,
      readOnly,
      tools: {
        // block tools
        header: {
          class: Header,
          inlineToolbar: true,
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        checklist: {
          class: CheckList,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        image: withImages && { // using to handle drop and paste events
          class: CustomImage,
          config: {
            documentId,
          },
        },
        modalImage: withImages && { // using to display image modal
          class: ModalImage,
          config: {
            onClick: onAddImageClick,
          },
        },
        delimiter: Delimiter,

        // inline tools
        // bold (internal)
        // italic (internal)
        // link (internal)
        marker: Marker,
        inlineCode: InlineCode,
        underline: Underline,
        improveWriting: {
          class: ImproveWriting,
          config: {
            templatesList,
            formRef,
          },
        },
        wordCount: WordCount,

        // block tunes
        copy: {
          class: CopyBlock,
        },
        delete: {
          class: CustomDelete,
        },
        publishToBigCommerce: {
          class: PublishToBigCommerce,
        },
        publishToBigAkeneo: {
          class: PublishToAkeneo,
        },

        // unused for now
        // code: {
        //   class: Code,
        //   inlineToolbar: true,
        // },
        // embed: Embed,
        // table: Table,
        // warning: Warning,
        // raw: Raw,
        // quote: Quote,
        // simpleImage: SimpleImage,
        // linkTool: LinkTool,
      },
    });
  };

  useEffect(() => {
    if (isIntegrationsLoading) {
      return () => {};
    }

    if (!editorRef.current) {
      initEditor();
    }
    return () => {
      if (isDev) {
        console.log('Editor was destroyed 💥'); // eslint-disable-line no-console
      }
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [readOnly, akeneoIntegration, isIntegrationsLoading]);

  return (
    <>
      <div
        id={EDITOR_HOLDER_ID}
        className={classes.editor}
      />
      <Shortcuts triggered={showShortcuts} />
    </>
  );
};

export default Editor;
