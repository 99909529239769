import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';

import {
  FolderOptionsKebab, BookmarkFolder,
} from '@root/views/Dashboard/components/IconButtons';
import ItemTypes from '@root/utils/dndItemTypes';
import useUpdateFile from '@root/resources/file/useUpdateFile';

import { actions, useDashboard } from '../../dashboardContext';

import useStyles from './FolderCard.styles';

const FolderCard = ({
  folder, isDraggable,
}) => {
  const {
    title,
    _id,
    liked,
  } = folder;

  const { dispatch } = useDashboard();
  const { mutateAsync: updateFile } = useUpdateFile();
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  const ref = useRef(null);

  const drag = useDrag({
    type: ItemTypes.FOLDER_CARD,
    item: { folderId: _id },
  })[1];

  const moveFileHere = async (fileId) => {
    await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.FILE_CARD,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: async (item) => {
      await moveFileHere(item.fileId);
    },
  });

  const handleSelectFolder = () => {
    dispatch({ type: actions.SELECT_FOLDER, payload: { title, folderId: _id } });
  };

  if (isDraggable) {
    drag(ref);
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.isDraggingOver]: isOver,
      })}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleSelectFolder}
      ref={ref}
    >
      <div
        className={classes.container}
        ref={drop}
      >
        <div className={classes.genType}>
          Folder
        </div>
        <div
          className={clsx(classes.title, { [classes.lightPurple]: hover })}
        >
          {title}
        </div>

        <div className={classes.footerContainer}>
          <div className={classes.footer} />
        </div>
        <div className={classes.moreOptions}>
          <FolderOptionsKebab variant="card" folder={folder} />
        </div>
        <div className={clsx(classes.topRight, classes.bookmarkIcon)}>
          <BookmarkFolder folderId={_id} liked={liked} />
        </div>
      </div>
    </div>
  );
};

export default FolderCard;
