import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  tablesWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableWrap: {
    width: 'calc(50% - 8px)',
  },
  exampleTextWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  createButton: {
    marginBottom: theme.spacing(2),
  },
  head: {
    marginBottom: theme.spacing(2),
  },
}));
