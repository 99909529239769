import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => (
      props.disabled
        ? theme.palette.colors.greyOffcolor
        : theme.palette.colors.lightGreen
    ),
    color: 'white',
    fontSize: '16px',
    borderRadius: '50%',
    width: '30px',
    minWidth: '30px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    margin: 'auto 18px auto 0',
    fontWeight: 'bold',
  },
}));

const UserIcon = ({
  name,
  disabled,
}) => {
  const classes = useStyles({ disabled });
  const firstLetter = name[0].toUpperCase();

  return (
    <div className={classes.root}>
      {firstLetter}
    </div>
  );
};

export default UserIcon;
