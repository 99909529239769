/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import Notification from '@root/components/shared/alerts/Notification';

import ProfileLayout from '../components/ProfileLayout';

import useStyles from './Referrals.styles';

const Referrals = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  // necessary for consistent renders
  const [showGrowSurf, setShowGrowSurf] = useState(false);

  const classes = useStyles();

  const handleCopyLinkClicked = (event) => {
    if (event.target.innerText === 'Copy Link') {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2500);
    }
  };

  useEffect(() => {
    setShowGrowSurf(true);
  }, []);

  return (
    <ProfileLayout>
      <div className={classes.root}>
        <div className={classes.notificationContainer}>
          <Notification
            open={linkCopied}
            type="success"
            text="Referral link successfully copied to clipboard!"
          />
        </div>
        {showGrowSurf ? (
          <div
            data-grsf-block-form
            onClick={handleCopyLinkClicked}
          />
        ) : null}
      </div>
    </ProfileLayout>
  );
};

export default Referrals;
