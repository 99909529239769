import { makeStyles, lighten, darken } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: ({ noPadding }) => noPadding && 0,
    '& a': {
      color: theme.palette.colors?.primary,
      '&:hover': {
        color: lighten(theme.palette.colors?.primary, 0.2),
        backgroundColor: 'transparent',
      },
      '&:active': {
        color: darken(theme.palette.colors?.primary, 0.2),
      },
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    marginBottom: ({ withSubtitle }) => (withSubtitle ? 0 : '16px'),
  },
  title: {
    fontFamily: 'Inter,sans-serif',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '39px',
  },
  closeButtonInTitle: {
    position: 'static',
    marginLeft: 'auto',
  },
  subtitle: {
    fontSize: '14px',
    maxWidth: 'calc(100% - 32px)',
    color: theme.palette.colors.neutrals400,
    marginBottom: '16px',
  },
  content: {
    padding: ({ noPadding }) => noPadding && 0,
  },
}));
