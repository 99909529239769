import { useQuery } from 'react-query';

import api from '@root/api';

const getExamples = async () => {
  const examples = await api.documentExample.list();

  return examples;
};

export default function useDocumentExamples() {
  return useQuery(
    ['documentExample.list'],
    () => getExamples(),
    {
      refetchOnMount: 'always',
    },
  );
}
