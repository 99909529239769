import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Link as UiLink } from '@material-ui/core';

import { FormField, InputField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import billingResource from '@root/resources/billing';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import hubspotService from '@root/services/hubspot.service';
import aiResource from '@root/resources/ai';

import ButtonWithTooltip from '../ButtonWithTooltip';

import useStyles from './StartForm.styles';

const validationSchema = Yup.object().shape({
  topic: Yup
    .string()
    .min(20, 'Input text is too short')
    .required('Field is required'),
});

const StartForm = ({ onSubmit, onSkip, formRef, topic }) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: { limit: creditsLimit, limitMode } = {} } = billingResource.useLimits();
  const { data: templateCost = 0 } = aiResource.useTemplateCost({ templateType: 'blogKickstarter_auto' });

  const isOutOfCredits = creditsLimit <= 0 && creditsLimit !== null;
  const isNotEnoughToSkip = creditsLimit < templateCost && creditsLimit !== null;

  const initialValues = {
    topic: topic || '',
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className={classes.startFormRoot}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          Blog Kickstarter
        </div>
        <div className={classes.description}>
          Tell us in 2-5 words what you want to write about.
          We&apos;ll use this to create an over all theme, outline, and then a blog.
          You&apos;ll be able to edit inputs along the way to ensure a high
          quality output in the end.
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, isValid }) => (
            <Form className={classes.form}>
              <FormField
                name="topic"
                label="What do you want to write about?"
                component={InputField}
                required
              />
              <div className={classes.buttons}>
                <ButtonWithTooltip
                  type="submit"
                  disabled={isOutOfCredits}
                  notEnoughCredits={isOutOfCredits}
                >
                  Start
                </ButtonWithTooltip>
                {values.topic ? (
                  <ButtonWithTooltip
                    variant="text"
                    onClick={() => onSkip(values.topic)}
                    disabled={isNotEnoughToSkip || !isValid}
                    notEnoughCredits={isNotEnoughToSkip}
                    disableRipple
                  >
                    Skip to Blog
                  </ButtonWithTooltip>
                ) : (
                  <Button
                    onClick={handleCancel}
                    variant="text"
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </Form>
          )}

        </Formik>
      </div>
    </div>

  );
};

export default StartForm;
