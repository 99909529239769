import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.colors.neutrals600,
    width: 534,
    position: 'fixed',
    bottom: theme.spacing(2),
    borderRadius: theme.border.radius.default,
    left: '50%',
    transform: 'translateX(-50%)',
    color: theme.palette.colors.white,
    fontSize: '14px',
  },
  wrap: {
    height: 0,
  },
  cancelButton: {
    color: theme.palette.colors.white,
  },
}));

const BottomToolbar = ({
  cancel = {},
  next = {},
  text,
}) => {
  const classes = useStyles();

  const nextButton = (
    <Button
      disabled={next.disabled}
      onClick={next.action}
      variant="contained"
      color="primary"
    >
      {next.label}
    </Button>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.root}>
        <Button onClick={cancel.action} variant="text" className={classes.cancelButton}>
          {cancel.label}
        </Button>
        {text}
        {next.tooltip ? (
          <SimpleTooltip content={next.tooltip}>
            <div>
              {nextButton}
            </div>
          </SimpleTooltip>
        ) : nextButton}
      </div>
    </div>
  );
};

export default BottomToolbar;
