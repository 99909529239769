import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fileRow: {
    height: 56,
    cursor: 'pointer',

    backgroundColor: theme.palette.colors.white,
    overflow: 'hidden',
    borderRadius: '6px',

    '& td:first-child': {
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
    },
    '& td:last-child': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    '&:hover': {
      outline: `1px solid ${theme.palette.colors.neutrals200}`,
      outlineOffset: '-1px',
    },
  },

  cell: {
    border: 'none',
    padding: '4px 8px',
  },

  textOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  rowDisabled: {
    cursor: 'default',
    '& *': {
      color: theme.palette.colors.grey,
      cursor: 'default',
    },
    '&&:hover': {
      '& td': {
        backgroundColor: 'white',
      },
    },
  },

  draggingPlaceholder: {
    background: '#EBF1FD',
    border: '2px dashed darkblue',
    boxSizing: 'border-box',
    '& td': {
      fontSize: 0,
      height: '53px',
    },
  },

  fixedWithoutBorder: {
    borderRight: 'none',
    boxShadow: 'none',
  },

  checkbox: {
    color: theme.palette.colors.neutrals200,
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
    '&$checked': {
      color: theme.palette.colors.neutrals600,
    },
    '&$indeterminate': {
      color: theme.palette.colors.neutrals600,
    },
  },

  checked: {
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },
  indeterminate: {
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },

  stickyHeader: {
    top: '0',
    zIndex: '5',
    position: 'sticky',
    backgroundColor: theme.palette.colors.neutrals50,
  },

  controls: {
    width: 48,
  },

  isDraggingOver: {
    border: '3px solid #88A9FF',
    borderLeftWidth: '4px',
  },
}));

export default useStyles;
