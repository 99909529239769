import PublishToPim from '../PublishToPim';

import Modal from './PublishToBigCommerceModal';

export default class PublishToBigCommerce extends PublishToPim {
  constructor({ api, block }) {
    super({ api, block });
  }

  // eslint-disable-next-line class-methods-use-this
  getPimInfo() {
    return {
      modal: Modal,
      pimName: 'BigCommerce',
      menuKey: 'publishToBigCommerce',
      menuLabel: 'Send to BigCommerce',
      menuIcon: '<svg viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#34313f"><path d="M33.72 36.432h8.073c2.296 0 3.75-1.263 3.75-3.3 0-1.913-1.454-3.3-3.75-3.3H33.72c-.268 0-.497.23-.497.46v5.663c.038.268.23.46.497.46zm0 13.048h8.34c2.564 0 4.094-1.3 4.094-3.597 0-2-1.454-3.597-4.094-3.597h-8.34c-.268 0-.497.23-.497.46v6.237c.038.306.23.497.497.497zM63.257.16l-23.875 23.8h3.903c6.084 0 9.68 3.826 9.68 7.997 0 3.3-2.22 5.7-4.6 6.772-.383.153-.383.7.038.842 2.755 1.07 4.706 3.94 4.706 7.308 0 4.744-3.176 8.532-9.336 8.532H26.87c-.268 0-.497-.23-.497-.46V36.93L.164 63.023c-.344.344-.115.957.383.957h63.016c.23 0 .42-.2.42-.42V.505c.115-.42-.42-.65-.727-.344z"/></svg>',
    };
  }
}
