import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  startFormRoot: {
    maxWidth: 500,
    flex: 1,
    display: 'flex',
  },
  wrapper: {
    alignSelf: 'center',
  },
  title: {
    fontSize: 22,
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: 10,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 48,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 32,
  },
}));

export default useStyles;
