import React from 'react';
import {
  Backdrop, Fade, Modal,
} from '@material-ui/core';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import CloseButton from '@root/components/buttons/CloseButton';
import Button from '@root/components/buttons/Button';
import useDeleteFile from '@root/resources/file/useDeleteFile';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

import useStyles from './BulkDeleteFileModal.styles';

const InnerContent = ({ closeModal, modalOpen }) => {
  const classes = useStyles();
  const { mutateAsync: deleteFile } = useDeleteFile();
  const { state: { modalContent } } = useGlobalModals();
  const {
    dispatch,
  } = useDashboard();

  if (!modalContent || !modalOpen) {
    return null;
  }

  const { selectedFileIds } = modalContent;

  const unselectFileIds = () => dispatch({
    type: actions.SET_SELECTED_FILES,
    payload: { selectedFileIds: [] },
  });

  const handleDelete = async () => {
    await Promise.all(selectedFileIds.map((fileId) => deleteFile(fileId)));
    unselectFileIds();
    closeModal();
  };

  return (
    <div className={classes.modalBox}>
      <div className={classes.modalHeader}>
        Delete
        {' '}
        {selectedFileIds.length}
        {' '}
        file(s)?
      </div>
      <div className={classes.modalText}>
        This action cannot be undone.
      </div>

      <div className={classes.button}>
        <Button type="button" onClick={handleDelete}>Confirm</Button>
      </div>

      <CloseButton
        onClick={closeModal}
        top="1rem"
        right="1rem"
      >
        X
      </CloseButton>
    </div>
  );
};

const BulkDeleteFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen} className={classes.fullHeight}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '450px',
            margin: '0 auto',
            outline: 0,
          }}
          // For some reason I can't refactor the above styles to the MUI format
        >
          <InnerContent closeModal={closeModal} modalOpen={modalOpen} />
        </div>
      </Fade>
    </Modal>
  );
};

export default BulkDeleteFileModal;
