import React from 'react';
import clsx from 'clsx';
import { Checkbox, TableHead, TableRow, TableCell } from '@material-ui/core';

import useStyles from './TableHeader.style';
import tableStyles from './table.styles';

const ProductTableHeader = ({ selectAll, allSelected = false }) => {
  const classes = useStyles();
  const tableClasses = tableStyles();

  return (
    <TableHead classes={{ root: classes.thead }}>
      <TableRow>
        <TableCell
          classes={{
            root: clsx(classes.controls, tableClasses.newCell),
          }}
          padding="checkbox"
        >
          <Checkbox
            checked={allSelected}
            onClick={selectAll}
          />
        </TableCell>
        <TableCell classes={{
          root: clsx(classes.id, tableClasses.newCell),
        }}
        >
          ID
        </TableCell>
        <TableCell classes={{
          root: clsx(classes.title, tableClasses.newCell),
        }}
        >
          Title
        </TableCell>
        <TableCell classes={{
          root: clsx(classes.lastUpdated, tableClasses.newCell),
        }}
        >
          Last Updated
        </TableCell>
        <TableCell classes={{
          root: clsx(classes.status, tableClasses.newCell),
        }}
        >
          Status
        </TableCell>
        <TableCell
          classes={{
            root: clsx(classes.controls, tableClasses.newCell),
          }}
        />
      </TableRow>
    </TableHead>
  );
};

export default ProductTableHeader;
