import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

export const getPlanItems = (
  { isFullList = true }: { isFullList?: boolean } = {},
): { [key: string]: string } => ({
  credits: 'Words',
  additionalUsers: 'Additional Users',
  plagiarismChecks: 'Plagiarism Checks',
  sharing: 'Project & File Sharing',
  teamCollaboration: 'Team Collaboration',
  ...(isFullList && { longFormBlogGenerations: 'Long Form Blog Generations (Beta)' }),
  chromeExtension: 'Chrome Extension',
  ...(isFullList && { accessToFreeTemplates: 'Access to 35+ Free Templates' }),
  ...(isFullList && { languages: 'Copy in 60+ Languages' }),
  emailSupport: 'Email Support',
  chatSupport: 'In-App Chat Support',
  thirdPartyIntegrations: 'Third Party Integrations',
  ...(isFullList && { customTemplates: 'Custom Templates' }),
  accountManager: 'Account Manager',
  accountWhiteLabeling: 'Account White Labeling',
  bulkDataImportExport: 'Bulk Data Import & Export',
});

type PlanItemValue = string | boolean | number | null;
export const planItemsValuesByRank: { [rank: number]: { [key: string]: PlanItemValue } } = {
  103: {
    credits: '20000*',
    additionalUsers: true,
    plagiarismChecks: 20,
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: null,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  150: {
    credits: '20000*',
    additionalUsers: true,
    plagiarismChecks: 20,
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: null,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  151: {
    credits: 'Unlimited',
    additionalUsers: true,
    plagiarismChecks: 20,
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: null,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  204: {
    credits: '80000*',
    additionalUsers: true,
    plagiarismChecks: 100,
    sharing: true,
    teamCollaboration: true,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  250: {
    credits: 'Unlimited',
    additionalUsers: true,
    plagiarismChecks: 100,
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: null,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  300: {
    credits: 'Unlimited',
    additionalUsers: true,
    plagiarismChecks: 500,
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: 500,
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: null,
    accountManager: null,
    accountWhiteLabeling: null,
    bulkDataImportExport: null,
  },
  500: {
    credits: 'Unlimited',
    additionalUsers: 'Contact us',
    plagiarismChecks: 'Unlimited',
    sharing: true,
    teamCollaboration: true,
    longFormBlogGenerations: 'Unlimited',
    chromeExtension: true,
    accessToFreeTemplates: true,
    languages: true,
    emailSupport: true,
    chatSupport: true,
    thirdPartyIntegrations: true,
    customTemplates: true,
    accountManager: true,
    accountWhiteLabeling: true,
    bulkDataImportExport: true,
  },
};

const checkMarkIcon = <FontAwesomeIcon icon="check" />;

export const getPlanItemContent = (rank: number, rowKey: string) => {
  const cell = planItemsValuesByRank[rank][rowKey];
  if (cell === true) {
    return checkMarkIcon;
  }
  return cell;
};

export const formatCurrency = (amount: number | string): string | number => {
  if (typeof amount === 'number') {
    if (amount <= 0) {
      return '0.00';
    }

    return `$${amount.toFixed(2)}`;
  }
  return amount;
};

export const formatAmount = (amount: number | string): string | number => {
  if (typeof amount === 'number') {
    return `${(Math.abs(amount) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
  }
  return amount;
};

export const lowCreditsLimit = 1000;
export const formatCredits = (credits: number): string => {
  if (credits <= lowCreditsLimit) {
    return `${credits}`;
  }

  const rounder = 1000;
  const dividedCredits = credits / rounder;
  return Number.isInteger(dividedCredits) ? `${dividedCredits * rounder}` : `${Math.floor(dividedCredits) * rounder}+`;
};

/**
 *  Format date from unix time seconds.
 *
 * @param {number} unixTime seconds since epoch 1970 UTC
 * @returns date string in the format MM/DD/YYYY
 */
export const getDate = (unixTime: number): string => {
  return DateTime.fromSeconds(unixTime).toLocaleString();
};

export const PLANS_IDS = {
  free: ['free'],
  ltds: ['lifetime_deal'],
  march2023StarterMonthly: ['march-2023-starter-monthly'],
  march2023StarterAnnual: ['march-2023-starter-annual'],
  march2023Pros: ['march-2023-pro-monthly', 'march-2023-pro-annual'],
  starters: ['january-6-2022-starter-monthly', 'january-6-2022-starter-annual', 'OCT-22-STARTERannual-2monthsfree'],
  pros: ['january-6-2022-pro-monthly', 'january-6-2022-pro-annual', 'OCT-22-PROannual-2monthsfree'],
  legacyStarters: ['starter-monthly', 'starter-annual', 'price_1HfpH8IoMzeBXuM50YpqFla9', 'price_1I5tcrIoMzeBXuM5cOsBtUd8'],
  legacyPros: ['pro-monthly', 'pro-annual', 'price_1HfpFHIoMzeBXuM5pfCDoGzY', 'price_1I5uijIoMzeBXuM5BSyJCUTp', 'price_1I3SWYIoMzeBXuM5fMhPsBbk', 'price_1IFpxlIoMzeBXuM52yCGBOku', 'price_1IFpzlIoMzeBXuM5DpU1PdIQ'],
  legacyTeams: ['teams-monthly', 'teams-annual'],
  legacyBasics: ['price_1IPWYnIoMzeBXuM53eyItPl9', 'price_1IPWYGIoMzeBXuM59H0yz6pw'],
  legacyGrowths: ['price_1IPZa1IoMzeBXuM5MQWE0Wgz', 'price_1IPZa1IoMzeBXuM5cHOW39P0'],
  legacyEarlyAdopters: ['price_1I6cEBIoMzeBXuM53jYknkO7', 'price_1I3RPjIoMzeBXuM5kR2QxJ00', 'price_1HvY8WIoMzeBXuM5PT3tCbX7'],
  selfServeEnterprises: ['self-serve-enterprise-monthly-0', 'self-serve-enterprise-monthly-1', 'price_1MCg6aIoMzeBXuM5ztEmigur', 'price_1MCg4pIoMzeBXuM5nKZDirfC', 'price_1MCg6aIoMzeBXuM50mjemedu', 'price_1MCg4pIoMzeBXuM5g5CaM2bf'],
};

export const PLANS_NAMES_LIST = [
  { name: 'Free', ids: PLANS_IDS.free },
  { name: 'Lifetime Deal', ids: PLANS_IDS.ltds },
  { name: 'Starter', ids: PLANS_IDS.starters },
  { name: 'Professional', ids: PLANS_IDS.pros },
  { name: 'Starter', ids: PLANS_IDS.legacyStarters },
  { name: 'Professional', ids: PLANS_IDS.legacyPros },
  { name: 'Teams', ids: PLANS_IDS.legacyTeams },
  { name: 'Basics', ids: PLANS_IDS.legacyBasics },
  { name: 'Growths', ids: PLANS_IDS.legacyGrowths },
  { name: 'Early Adopters', ids: PLANS_IDS.legacyEarlyAdopters },
];

type User = Record<string, unknown> & { featureToggles: { [toggle: string]: boolean }; };
type Plan = {
  _id: string;
  rank: number;
  tier: number;
  period: 'yearly' | 'monthly';
  name?: string;
  legacy?: boolean;
  service?: boolean;
  internalName?: string;
  rolloverSupported?: boolean;
};
export const filterActivePlans = (
  planArray: Plan[],
  currentUser: User | undefined,
  currentPlan: Plan | undefined,
): Plan[] => {
  if (!planArray) {
    return [];
  }

  // If feature toggle to enable new annual plan pricing is on, return the new annual plans
  // If the user is on a january-6-2022 annual plan,
  // we want to filter out the October version of the annual plan

  if (currentUser?.featureToggles?.oct2022AnnualPriceUpdates) {
    if (currentPlan?._id === 'january-6-2022-starter-annual') {
      return planArray.filter(
        (plan) => plan._id !== 'OCT-22-STARTERannual-2monthsfree' && plan._id !== 'january-6-2022-pro-annual',
      );
    }
    if (currentPlan?._id === 'january-6-2022-pro-annual') {
      return planArray.filter(
        (plan) => plan._id !== 'january-6-2022-starter-annual' && plan._id !== 'OCT-22-PROannual-2monthsfree',
      );
    }

    return planArray.filter((plan) => plan._id !== 'january-6-2022-starter-annual' && plan._id !== 'january-6-2022-pro-annual');
  }

  return planArray.filter((plan) => plan._id !== 'OCT-22-STARTERannual-2monthsfree' && plan._id !== 'OCT-22-PROannual-2monthsfree');
};

export const getMonoPeriod = (plans: Plan[]): 'yearly' | 'monthly' | null => {
  if (!plans) {
    return null;
  }

  const periods = plans.filter((p) => p.rank < 500).map((plan) => plan.period);
  const uniquePeriods = [...new Set(periods)];

  if (uniquePeriods.length === 1) {
    return uniquePeriods[0];
  }

  return null;
};

export const UNLIMITED_CREDITS_RANKS = [200, 201, 202, 300, 500];
export const WORDS_MAX_CAP = 999999999;
