import React from 'react';
import { Typography } from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import hubspotService from '@root/services/hubspot.service';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import billingResource from '@root/resources/billing';
import authContext from '@root/resources/auth/auth.context';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';
import { CANCEL_SUBSCRIPTION_MODAL_ID } from '@root/views/Dashboard/components/Modals/CancelSubscriptionModal';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

import useSectionsStyles from '../Sections.styles';

import useStyles from './CancelSubscription.styles';

const ACTIVE_SUBSCRIPTION_STATUS = 'customer';

const CancelSubscription = () => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const { currentExternalSource } = externalSourcesContext.useExternalSource();
  const { showExternalSourceTooltip, disableCancelSubscription } = currentExternalSource.toggles;
  const { currentUser: user, currentCompany: company } = authContext.useAuth();
  const { data: userBillingInfo = {} } = billingResource.useBillingInfo();
  const { data: { currentPlan } = {} } = billingResource.usePricing();
  const { onOpenModal } = useGlobalModals();

  const isSuspended = company.suspendStart;
  const canRenew = userBillingInfo?.cancelAtPeriodEnd;
  const isActivePlan = userBillingInfo.status === ACTIVE_SUBSCRIPTION_STATUS;
  const isTeamOwner = user.roles.owner;
  const isCurrentEnterprise = currentPlan?.rank === 500;

  const getHootsuiteText = React.useCallback(({ link }) => (
    <>
      Please open your
      {' '}
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        Copysmith web app
      </a>
      {' '}
      to manage your subscription.
    </>
  ), []);

  const handleOpenModal = () => onOpenModal(CANCEL_SUBSCRIPTION_MODAL_ID);

  if (!isActivePlan || canRenew || isSuspended || user.onSelfServeEnterprisePlan) {
    return null;
  }

  return (
    <div className={sectionClasses.sectionContainer}>
      <div className={sectionClasses.sectionTitleContainer}>
        <Typography variant="h4" className={sectionClasses.sectionTitle}>
          Cancel subscription
        </Typography>
      </div>

      <div className={sectionClasses.wideCard}>
        <div className={classes.textContent}>
          We&apos;ll hold your past generations here for you, but you
          will no longer receive words for new generations nor
          have access to your files as a free trial user.&nbsp;

          <span className={classes.warning}>
            Cancelling or downgrading your team subscription will also
            remove all members and affect their access to Copysmith.
          </span>
        </div>

        <SimpleTooltip
          content={showExternalSourceTooltip && <ExternalSourceText getText={getHootsuiteText} />}
          interactive
          wrapped
        >
          <Button
            color="secondary"
            variant={isCurrentEnterprise ? 'outlined' : 'contained'}
            disabled={!isTeamOwner || disableCancelSubscription}
            onClick={isCurrentEnterprise
              ? hubspotService.show
              : handleOpenModal}
            className={classes.cancelButton}
          >
            {isCurrentEnterprise ? 'Contact us' : 'Cancel subscription'}
          </Button>
        </SimpleTooltip>
      </div>
    </div>
  );
};

export default CancelSubscription;
