import React, { createContext, useState } from 'react';

import api from '@root/api';
import authContext from '@root/resources/auth/auth.context';
import useModalState from '@root/utils/hooks/useModalState';
import {
  getOnboardingRewardModalState,
  setOnboardingRewardModalState,
} from '@root/services/localStorage.service';

import { featureSteps, reminderStep, getWalkthroughSteps } from './constants';

const initialState = {
  run: false,
  stepIndex: 0,
  steps: featureSteps,
  featureTourActive: false,
  reminderActive: false,
  walkthroughActive: false,
};

const OnboardingContext = createContext();

const OnboardingProvider = ({ children }) => {
  const { currentUser, fetchAuthData } = authContext.useAuth();

  const [state, setState] = useState(initialState);
  const isCongratsInitiallyOpen = currentUser?.onboarding?.completed
    && !currentUser.onboarding.skipped
    && getOnboardingRewardModalState() === 'true';
  const [
    isCongratsOpen, openCongratsModal, closeCongratsModal,
  ] = useModalState(isCongratsInitiallyOpen);

  const walkthroughSteps = getWalkthroughSteps(currentUser);

  const setOnboardingState = (values) => {
    setState((prevState) => ({ ...prevState, ...values }));
  };

  const startFeatureTour = () => {
    setOnboardingState({ run: true, stepIndex: 0, steps: featureSteps, featureTourActive: true });
  };

  const startWalkthroughTour = () => {
    setOnboardingState({
      run: true, stepIndex: 0, steps: walkthroughSteps, walkthroughActive: true,
    });
  };

  const dropState = () => {
    setOnboardingState(initialState);
  };

  const nextStep = () => {
    setOnboardingState({ stepIndex: state.stepIndex + 1 });
  };

  const endFeatureTour = () => {
    dropState();
  };

  const showReminder = () => {
    setOnboardingState({
      run: true, reminderActive: true, stepIndex: 0, steps: [reminderStep],
    });
  };

  const closeReminder = () => {
    dropState();
  };

  const completeOnboardingItem = async (itemKey) => {
    await api.user.completeOnboardingStep({
      item: itemKey,
      abandonedStep: state.stepIndex,
    });
    const response = await fetchAuthData();
    return response;
  };

  const closeChecklist = () => {
    completeOnboardingItem();
    showReminder();
  };

  const skipFeatureTour = async () => {
    endFeatureTour();
    setTimeout(() => {
      showReminder();
    }, 300);
  };

  const skipWalkthroughTour = async () => {
    dropState();
    return completeOnboardingItem('walkthrough');
  };

  const refetchOnboarding = async (itemKey) => {
    const incompleteOnboardingItem = !currentUser.onboarding?.completed
      && currentUser.onboarding?.items?.[itemKey]
      && !currentUser.onboarding.items[itemKey].completed;

    if (incompleteOnboardingItem) {
      const newUser = await fetchAuthData();
      if (newUser.onboarding.completed) {
        openCongratsModal();
        setOnboardingRewardModalState(true);
      }
    }
  };

  const closeCongrats = () => {
    closeCongratsModal();
    setOnboardingRewardModalState(false);
  };

  return (
    <OnboardingContext.Provider value={{
      ...state,

      dropState,

      startFeatureTour,
      startWalkthroughTour,

      nextStep,

      closeReminder,
      endFeatureTour,

      skipFeatureTour,
      skipWalkthroughTour,

      setOnboardingState,
      refetchOnboarding,
      completeOnboardingItem,
      closeChecklist,

      isCongratsOpen,
      closeCongrats,
    }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

function useOnboarding() {
  const context = React.useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }
  return context;
}

export default { OnboardingProvider, useOnboarding };
