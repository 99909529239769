import React from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button, Grid, Link, IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import useQuery from '@root/common/useQuery';
import aiTemplateResource from '@root/resources/aiTemplate';

import Fields from './Fields';
import EditFieldModal from './EditFieldModal';
import useStyles from './TemplateViewTab.styles';
import CustomTemplateView from './CustomTemplateView';

const validationSchema = yup.object({
  templateType: yup
    .string('Type template type')
    .required('templateType is required'),
  title: yup
    .string('Type title')
    .required('title is required'),
});

const defaultField = {
  name: '',
  label: '',
  required: true,
  description: '',
  showOnUi: true,

  uiOptions: {
    type: 'string',
    helper: '',
  },

  min: 0,
  max: 80,
};

const TemplateView = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const copyFromId = query.get('copyFromId');
  const { data: copyFromTemplate } = aiTemplateResource.useAiTemplate(copyFromId);
  const params = useParams();
  const { data: editTemplate } = aiTemplateResource.useAiTemplate(params.id);
  const { mutateAsync: createOrUpdate } = aiTemplateResource.useCreateOrUpdateAiTemplate();
  const [editField, setEditField] = React.useState(null);

  const [initialData, setInitialData] = React.useState({
    title: '',
    description: '',
    templateType: '',
    status: 'draft',
    creditCost: 1,
    bulkAllowed: false,
    slug: '',
    group: 'Other',
    fields: [],
  });

  const createOrUpdateTemplate = async (values) => {
    const { isBadRequest, _id } = await createOrUpdate(values);
    if (isBadRequest) {
      return;
    }
    history.push(`/super-admin/templates/${_id}/experiment`);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema,
    onSubmit: createOrUpdateTemplate,
  });

  const deleteField = (fieldToDelete) => {
    const currentData = formik.values;
    const fields = currentData.fields.filter((f) => f.name !== fieldToDelete.name);

    setInitialData({
      ...currentData,
      fields,
    });
  };

  const onUpdateField = (newField) => {
    let updated = false;

    const currentData = formik.values;
    const fields = currentData.fields.map((f) => {
      if (f.name === newField.name) {
        updated = true;
        return newField;
      }

      return {
        ...f,
      };
    });
    if (!updated) {
      fields.push(newField);
    }

    setInitialData({
      ...currentData,
      fields,
    });
  };

  React.useEffect(() => {
    if (copyFromTemplate) {
      const title = copyFromTemplate.title.startsWith('Copy of ')
        ? copyFromTemplate.title
        : `Copy of ${copyFromTemplate.title}`;

      const data = {
        ...copyFromTemplate,
        title,
        templateType: '',
        status: 'draft',
        public: false,
        canPersonalize: false,
        bulkAllowed: false,
      };
      delete data._id;
      delete data.userId;
      setInitialData(data);
    }
  }, [copyFromTemplate]);

  React.useEffect(() => {
    if (!editTemplate) {
      return;
    }
    const d = {
      ...editTemplate,
    };

    setInitialData(d);
  }, [editTemplate]);

  const filesCount = formik.values.filesCount || 0;
  const documentsCount = formik.values.documentsCount || 0;

  const isExampleObject = typeof formik?.values?.example?.content !== 'string';

  const isCustomTemplate = editTemplate && editTemplate.buckets.some((b) => b.bucket === 'custom'); // TEMP

  if (isCustomTemplate) {
    return (
      <CustomTemplateView
        templateValues={editTemplate}
      />
    );
  }
  return (
    <div>
      {editField && (
        <EditFieldModal
          field={editField}
          onClose={() => setEditField(null)}
          updateField={onUpdateField}
        />
      )}
      <div className={classes.root}>
        <div className={classes.description}>
          <div>
            {`Documents created: ${documentsCount}, Files created: ${filesCount}`}
          </div>
          <div>
            Once template is live — you can not be able to
            change Template Fields and templateType.
            Make sure to experiment enough before setting it live!
          </div>
          <div>
            Switch to the Run Experiments tab after editing template to see some results.
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item container>
              <Grid
                className={classes.cell}
                sm={6}
                xs={12}
                item
              >
                <Fields.StringField
                  formik={formik}
                  name="title"
                  className={classes.input}
                  helperText="Template title."
                />
              </Grid>
              <Grid
                className={classes.cell}
                sm={6}
                xs={12}
                item
              >
                <Fields.StringField
                  formik={formik}
                  name="description"
                  className={classes.input}
                  helperText="Template description"
                  multiline
                  minRows={2}
                  maxRows={4}
                />
              </Grid>
            </Grid>
            <Grid item container>
              {!isCustomTemplate && (
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="templateType"
                    className={classes.input}
                    helperText="Required, unique template type. Once created, can not be changed. No spaces."
                  />
                </Grid>
              )}
              {!isCustomTemplate && (
                <Grid
                  className={classes.cell}
                  sm={2}
                  xs={6}
                  item
                >
                  <Fields.StringSelect
                    formik={formik}
                    name="status"
                    label="Status"
                    className=""
                    helperText="Set template status. If live then it will be available for company. If you want to enable template for all users, check public as well."
                    options={['draft', 'submitted', 'live', 'deleted']}
                  />
                </Grid>
              )}
              {!isCustomTemplate && (
                <Grid
                  className={classes.cell}
                  sm={2}
                  xs={4}
                  item
                >
                  <Fields.CheckboxField
                    label="Can personalize?"
                    formik={formik}
                    name="canPersonalize"
                    className={classes.checkbox}
                    helperText="When checked, user can add personalized items."
                  />
                </Grid>
              )}
              <Grid
                className={classes.cell}
                sm={2}
                xs={4}
                item
              >
                <Fields.CheckboxField
                  label="Public?"
                  formik={formik}
                  name="public"
                  className={classes.checkbox}
                  helperText="When checked, visible to all Copysmith users. Otherwise, can be enabled for some companies."
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                className={classes.cell}
                sm={6}
                xs={12}
                item
              >
                <Fields.StringField
                  formik={formik}
                  name="slug"
                  className={classes.input}
                  helperText="Slug. Url friendly name, e.x. product-description."
                />
              </Grid>
              <Grid
                className={classes.cell}
                sm={2}
                xs={4}
                item
              >
                <Fields.CheckboxField
                  label="Bulk allowed?"
                  formik={formik}
                  name="bulkAllowed"
                  className={classes.checkbox}
                  helperText="Please, make sure to coordinate with dev team, before enabling allowing bulk generations."
                />
              </Grid>
              <Grid
                className={classes.cell}
                sm={2}
                xs={4}
                item
              >
                <Fields.CheckboxField
                  label="Hidden on UI?"
                  formik={formik}
                  name="hiddenOnUi"
                  className={classes.checkbox}
                  helperText="Hide template from UI, used internally. E.x. when user click 'Like this', we use hidden on ui template."
                />
              </Grid>
              <Grid
                className={classes.cell}
                sm={2}
                xs={4}
                item
              >
                <Fields.CheckboxField
                  label="Is beta?"
                  formik={formik}
                  name="isBeta"
                  className={classes.checkbox}
                  helperText="Shows beta label on UI."
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                className={classes.cell}
                sm={6}
                xs={12}
                item
              >
                <Fields.StringField
                  formik={formik}
                  name="creditCost"
                  className={classes.input}
                  helperText="Required. Credit cost for every generate click."
                />
              </Grid>
              {!isCustomTemplate && (
                <Grid
                  className={classes.cell}
                  sm={2}
                  xs={4}
                  item
                >
                  <Fields.CheckboxField
                    label="Example Submitting Allowed?"
                    formik={formik}
                    name="exampleSubmittingAllowed"
                    className={classes.checkbox}
                    helperText="Can a user submit good/bad examples of generations."
                  />
                </Grid>
              )}
              {!isCustomTemplate && (
                <Grid
                  className={classes.cell}
                  sm={2}
                  xs={4}
                  item
                >
                  <Fields.CheckboxField
                    label="Block Editor"
                    formik={formik}
                    name="useBlockEditor"
                    className={classes.checkbox}
                    helperText="Block Editor usage."
                  />
                </Grid>
              )}
              {!isCustomTemplate && (
                <Grid item container>
                  <Grid
                    className={classes.cell}
                    sm={6}
                    xs={12}
                    item
                  >
                    <Fields.StringField
                      formik={formik}
                      disabled={isExampleObject}
                      name="example.content"
                      className={classes.input}
                      helperText="Result content example (copy here after running experiments)"
                      multiline
                      minRows={2}
                      maxRows={4}
                    />
                    {isExampleObject && (
                      <span className={classes.error}>
                        Example is an object and can only be edited directly in database for now.
                      </span>
                    )}
                  </Grid>
                  <Grid>
                    <Fields.StringArrayField
                      formik={formik}
                      name="chain"
                      className={classes.input}
                      helperText="Template types separate by comma. Used to run complex, multi step templates. e.x. googleAd"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container>
                <Grid
                  item
                  container
                  sm={6}
                  xs={12}
                >
                  <div className={classes.fieldSectionTitle}>
                    <div className={classes.subtitle}>Template fields</div>
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="text"
                      onClick={() => setEditField(defaultField)}
                    >
                      Add new field
                    </Button>
                  </div>
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringSelect
                    formik={formik}
                    name="group"
                    label="Group"
                    className=""
                    options={['Quick Access', 'Brainstorming', 'Ad', 'Branding', 'Product', 'eCommerce', 'Writing', 'Other']}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.description}>
                    <div>Fields are key things we send to the Open AI and show on UI.</div>
                    <div>Keys order is important (affects UI and Open AI)</div>
                    <div>Last key is what we ask Open AI to return, should be hidden on UI.</div>
                  </div>
                </Grid>
                <Grid item container>
                  <ul>
                    {formik.values.fields.map((f, index) => {
                      return (
                        <li style={{ display: 'flex' }}>
                          <div className={classes.fieldViewWrap}>
                            <div>
                              <span className={classes.fieldIndex}>{`${index + 1}. `}</span>
                              <span>{`${f.label} (${f.name})`}</span>
                            </div>
                            <div>
                              <IconButton
                                size="small"
                                color="primary"
                                variant="text"
                                onClick={() => deleteField(f)}
                              >
                                <Delete />
                              </IconButton>
                              <Button
                                size="small"
                                component={Link}
                                color="primary"
                                variant="text"
                                onClick={() => setEditField(f)}
                              >
                                Edit
                              </Button>
                            </div>
                          </div>
                          <div>{f.description}</div>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringSelect
                    formik={formik}
                    name="promptEngine"
                    label="Prompt engine"
                    className=""
                    options={['davinci', 'ada', 'davinci-instruct-beta']}
                    helperText="Advanced setting. Use davinci by default"
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    label="Sensitivity threshold"
                    formik={formik}
                    name="sensitivityThreshold"
                    className={classes.input}
                    helperText="Advanced setting. 1, 2 or 3. Use 2 by default."
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="aiOptions.temperature"
                    label="Temperature"
                    className={classes.input}
                    helperText="Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer."
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    label="max_tokens"
                    formik={formik}
                    name="aiOptions.max_tokens"
                    type="number"
                    className={classes.input}
                    helperText="The maximum number of tokens to generate in the completion.

                The token count of your prompt plus max_tokens cannot exceed the model's context length. Most models have a context length of 2048 tokens (except davinci-codex, which supports 4096)."
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="aiOptions.best_of"
                    label="best_of"
                    className={classes.input}
                    helperText="Generates best_of completions server-side and returns the 'best' (the one with the lowest log probability per token)"
                    type="number"
                    min={0}
                    max={100}
                    step={1}
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    label="n"
                    formik={formik}
                    name="aiOptions.n"
                    className={classes.input}
                    type="number"
                    helperText="How many completions to generate for each prompt."
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="aiOptions.presence_penalty"
                    label="presence_penalty"
                    className={classes.input}
                    helperText="Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics."
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    label="frequency_penalty"
                    formik={formik}
                    name="aiOptions.frequency_penalty"
                    className={classes.input}
                    helperText="Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim."
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.submitButtonContainer}>
            <RouterLink
              size="small"
              className={classes.cancelButton}
              to="/super-admin/templates"
            >
              Cancel
            </RouterLink>
            <Button
              size="small"
              type="submit"
              disabled={!formik.isValid}
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TemplateView;
