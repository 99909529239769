import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    border: '2px solid transparent',
    borderRadius: 4,
    padding: '8px 16px',
    position: 'relative',
    backgroundColor: theme.palette.colors.white,
    cursor: 'pointer',
    '& svg': {
      display: 'none',
    },
    '&:hover': {
      borderColor: theme.palette.colors.mediumDarkGrey,
      backgroundColor: theme.palette.colors.lightGrey,
      '& svg': {
        display: 'unset',
        color: theme.palette.colors.mediumDarkGrey,
      },
    },
  },
  selected: {
    borderColor: theme.palette.colors.primary,
    '& svg': {
      display: 'unset',
      color: theme.palette.colors.primary,
    },
    '&:hover': {
      borderColor: theme.palette.colors.primary,
      backgroundColor: theme.palette.colors.lightGrey,
      '& svg': {
        display: 'unset',
        color: theme.palette.colors.primary,
      },
    },
  },
  charCount: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    color: theme.palette.colors.mediumDarkGrey,
    borderRadius: 3,
    padding: '4px 8px',
    width: 'fit-content',
    marginTop: 12,
  },
  checkIcon: {
    position: 'absolute',
    right: -12,
    top: -12,
  },
}));

export default useStyles;
