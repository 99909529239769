import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { GetAppSharp } from '@material-ui/icons';
import React from 'react';
import { useQueryClient } from 'react-query';

import api from '@root/api';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';
import { downloadAsFile } from '@root/utils/Export';

const downloadFails = async ({ id, fileName }) => {
  const response = await api.bulkGeneration.getFailedRows({ id });

  if (response.isBadRequest) {
    return;
  }

  downloadAsFile(`${response}`, `${fileName.slice(0, fileName.length - 4)}_fails.csv`);
};

const BulkGenerationReportDialog = () => {
  const {
    state: { showReportModal, currentJob },
    dispatch,
  } = BulkGenerationsContext.useBulkGenerations();

  const queryClient = useQueryClient();

  const closeDialog = () => {
    queryClient.invalidateQueries('billing.getLimits');
    dispatch({
      type: BulkGenerationsContext.actions.TOGGLE_REPORT_MODAL,
      payload: {
        isOpen: false,
      },
    });
  };

  if (!currentJob?.bulkGeneration) {
    return null;
  }

  const title = currentJob.bulkGeneration.state === 'done'
    ? 'Bulk generation completed successfully'
    : 'Bulk generation failed';

  return (
    <Dialog
      fullWidth
      open={showReportModal}
      onClose={closeDialog}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Total rows:
          &nbsp;
          {currentJob.bulkGeneration.result.totalRows}
        </DialogContentText>
        <DialogContentText>
          Successful rows:
          &nbsp;
          {currentJob.bulkGeneration.result.successfulRows}
        </DialogContentText>
        {currentJob.bulkGeneration.result.failedRows > 0 && (
          <DialogContentText>
            Failed rows:
            &nbsp;
            {currentJob.bulkGeneration.result.failedRows}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {currentJob.bulkGeneration.result.failedRows > 0 && (
          <Button
            onClick={() => downloadFails({
              id: currentJob.bulkGeneration._id,
              fileName: currentJob.bulkGeneration.fileName,
            })}
            startIcon={<GetAppSharp />}
            variant="text"
            color="primary"
          >
            Download error log
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={closeDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkGenerationReportDialog;
