import React from 'react';
import { IconStack2 } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';

import Button from '@root/components/buttons/Button';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';

import useStyles from './TemplateCard.styles';

const AkeneoCard = () => {
  const classes = useStyles();

  const history = useHistory();

  const handleCreateClick = () => {
    history.push('/akeneo-bulk-copy/new');
  };

  return (
    <div
      className={classes.card}
      onClick={handleCreateClick}
    >
      <div>
        <div className={classes.topActions}>
          <div className={classes.icon}>
            <IconStack2 />
          </div>
        </div>

        <div style={{ flexGrow: 0 }}>
          <h2 className={classes.title}>
            Akeneo Bulk Copy
          </h2>
          <TruncatedText
            multiline
            maxLines={2}
            className={classes.description}
            style={{ cursor: 'unset' }}
            withoutTooltip
          >
            Generate your product descriptions with just one click!
            Generate compelling content for multiple products in Akeneo and take your marketing to the next level!
          </TruncatedText>
        </div>
      </div>

      <div className={classes.bottomActions}>
        <Button
          className={classes.createButton}
          id="create-button"
          size="small"
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default AkeneoCard;
