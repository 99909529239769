import React from 'react';

import onboardingContext from '@root/resources/onboarding/onboarding.context';
import authContext from '@root/resources/auth/auth.context';

import OnboardingTour from './components/OnboardingTour';
import ChecklistModal from './components/ChecklistModal';
import CongratulationsModal from './components/CongratulationsModal';

const Onboarding = () => {
  const { currentUser, currentCompany } = authContext.useAuth();
  const {
    isCongratsOpen, closeCongrats, featureTourActive, reminderActive,
  } = onboardingContext.useOnboarding();

  if (currentUser?.featureToggles?.newEditor) {
    return null;
  }

  const showTour = currentUser?.onboarding
    && (!currentUser.onboarding.completed || featureTourActive || reminderActive);
  const isFinishedRegistration = currentCompany?.onboarding?.industrySegment;
  const showChecklist = currentUser?.onboarding
    && !currentUser.onboarding.completed
    && isFinishedRegistration;
  const showCongrats = currentUser && isCongratsOpen && !featureTourActive;

  return (
    <>
      {showTour && <OnboardingTour />}
      {showChecklist && <ChecklistModal />}
      {showCongrats && (
        <CongratulationsModal
          onClose={closeCongrats}
        />
      )}
    </>
  );
};

export default Onboarding;
