import React from 'react';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import useStyles from './BulkCopyModal.styles';
import BulkCopyForm from './BulkCopyForm';

export const BULK_COPY_MODAL = 'BULK_COPY_MODAL';

const BulkCopyModal = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch, state: { modalId, modalContent } } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  return (
    <Modal
      title="Bulk Copy"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalId === BULK_COPY_MODAL}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modalPaper }}
    >
      <BulkCopyForm catalogId={modalContent?.catalogId} selectedItemIds={modalContent?.selectedItemIds} />
    </Modal>
  );
};

export default BulkCopyModal;
