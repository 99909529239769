import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return ({
    savedCard: {
      '& > div:not(:last-of-type)': {
        marginBottom: 16,
      },
    },
    button: {
      marginTop: 8,
    },
  });
});

export default useStyles;
