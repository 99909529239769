/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import config from '@root/config';
import CreateAndExplore from '@root/views/CreateAndExplore';
import TemplatesDashboardNew from '@root/views/TemplatesDashboardNew/TemplatesDashboardNew';

import {
  EditProfile,
  Plans,
  Billing,
  Referrals,
  CopyPersonalization,
  TeamDetails,
  TeamMembers,
  SelfServeEnterprise,
} from './views/Profile';
import NotFound from './views/ErrorPages/NotFound';
import Dashboard from './views/Dashboard';
import BrandStyle from './views/BrandStyle/BrandStyle';
import LogOut from './views/Authentication/LogOut';
import UserMgmt from './views/Authentication/UserMgmt';
import OauthRedirect from './views/OauthRedirect/OauthRedirect';
import NewBulkGeneration from './views/BulkCopy/NewBulkGeneration';
import BulkGenerationsHistory from './views/BulkCopy/BulkGenerationsHistory';
import BulkGenerationWizard from './views/BulkCopy/BulkGenerationWizard';
import BulkGenerationFiles from './views/BulkCopy/BulkGenerationFiles';
import Checkout from './views/Checkout';
import SuperAdminTemplatesList from './views/SuperAdmin/TemplatesList';
import SuperAdminTemplatePage from './views/SuperAdmin/TemplatePage';
import DocumentEditor from './views/DocumentEditor/DocumentEditor';
import Editor from './views/Editor';
import BlogKickstarter from './views/BlogKickstarter';
import TemplateBuilder from './views/TemplateBuilder';
import AddCompanyInfo from './views/AddCompanyInfo';
import Rewards from './views/Rewards/Rewards';
import Community from './views/Community';
import Usage from './views/Usage';
import UsageV2 from './views/UsageV2';
import FileViewProxy from './utils/FileViewProxy';
import NewFileRoute from './utils/NewFileRoute';
import DocusignCallbackPage from './utils/DocusignCallbackPage';
import SelfServeEnterpriseCheckoutRedirect from './utils/SelfServeEnterpriseCheckoutRedirect';
import Catalog from './views/Catalog';
import TemplatesDashboard from './views/TemplatesDashboard/TemplatesDashboard';
import BigCommerceBulkCopy from './views/BigCommerceBulkCopy';
import AkeneoConnect from './views/AkeneoConnect/AkeneoConnect';
import AkeneoConnectCallback from './views/AkeneoConnect/AkeneoConnectCallback';
import AkeneoBulkCopy from './views/AkeneoBulkCopy';
import HomePageRoute from './utils/HomePageRoute';
import HomePage from './views/Home/Home';
import NewEditorRoute from './utils/NewEditorRoute';
import BigCommerceIncognitoWarn from './utils/BigCommerceIncognitoWarn';

const PrivateRoute = ({ ...rest }) => {
  const { currentUser } = AuthContext.useAuth();
  const isLoggedIn = !!currentUser;
  if (!isLoggedIn) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
    );
  }

  return (
    <Route
      key={rest.path}
      {...rest}
    />
  );
};

const [
  LoginPage,
  SingleSignOnPage,
  // SignUpPage,
  ExpiredTokenPage,
  CheckEmailPage,
  ShareGenerationPage,
  FineTunes,
  AcceptTeamInvite,
  Addons,
] = [
  React.lazy(() => import('./views/Authentication/LogInPage')),
  React.lazy(() => import('./views/Authentication/SingleSignOnPage')),
  // React.lazy(() => import('./views/Authentication/SignUpPage')),
  React.lazy(() => import('./views/Authentication/ExpiredTokenPage')),
  React.lazy(() => import('./views/Authentication/CheckEmailPage')),
  React.lazy(() => import('./views/Share/ShareGeneration')),
  React.lazy(() => import('./views/FineTunesEngines/FineTunes')),
  React.lazy(() => import('./views/AcceptInvite/AcceptTeamInvite')),
  React.lazy(() => import('./views/Addons')),
].map((Component) => (props) => (
  <Suspense fallback={<div />}>
    <Component {...props} />
  </Suspense>
));

const publicRoutes = [
  <Route
    key="login-popup"
    path="/login/:isPopUpWindow"
    component={LoginPage}
  />,
  <Route key="login" path="/login" component={LoginPage} />,
  <Route key="single-sign-on" path="/single-sign-on" component={SingleSignOnPage} />,
  <Route key="logout" path="/logout" component={LogOut} />,
  // <Route key="signup" path="/signup" component={SignUpPage} />,
  <Route key="accept-invite" path="/accept-invite" render={AcceptTeamInvite} />,
  <Route key="share-page" path="/share/:type/:fileId" component={ShareGenerationPage} />,
  <Route key="oauth-redirect" path="/oauth-redirect" component={OauthRedirect} />,
  <Route key="user-mgmt" path="/usermgmt/:mode" component={UserMgmt} />,
  <Route key="expired-token" path="/expired" component={ExpiredTokenPage} />,
  <Route key="check-email" path="/check-email" component={CheckEmailPage} />,
  <Route key="akeneo-connect" path="/akeneo-connect" component={AkeneoConnect} />,
  <Route key="akeneo-connect-callback" path="/akeneo-connect-callback" component={AkeneoConnectCallback} />,
];

const Routes = () => {
  const isProduction = config.env === 'production';
  const { currentUser } = AuthContext.useAuth();

  const isCreateAndExploreAvailable = currentUser?.featureToggles?.createAndExplore;
  const isDashboardV2Available = currentUser?.featureToggles?.dashboardV2;

  return (
    <Switch>
      <Route key="home" path="/" exact>
        <Redirect
          to={`${isCreateAndExploreAvailable ? '/templates' : '/create'}`}
        />
      </Route>
      {publicRoutes}
      <PrivateRoute path="/editor/new" component={NewEditorRoute} />
      {/* Show new editor instead of long form page */}
      <PrivateRoute
        key="free-form-smart-editor"
        path="/free-form-smart-editor/:fileId"
        render={(props) => (
          <Redirect
            to={{
              pathname: `/documents/${props.match.params.fileId}/first`,
              state: props.location.state,
            }}
          />
        )}
      />
      <PrivateRoute path="/documents/:fileId/:id" component={currentUser?.featureToggles?.newEditor ? Editor : DocumentEditor} />

      <PrivateRoute
        exact={false}
        path="/home"
        component={HomePage}
      />

      <PrivateRoute
        path="/create/:bucket?"
        component={HomePageRoute}
      />

      <PrivateRoute path="/catalog" component={Catalog} />

      <PrivateRoute path="/templates/:locationType?/:locationId?" component={Dashboard} />
      <PrivateRoute path="/addons" component={Addons} />

      <PrivateRoute path="/add-company-info" component={AddCompanyInfo} />
      <PrivateRoute path="/profile/edit" component={EditProfile} />
      <PrivateRoute path="/profile/plans" exact component={Plans} />
      <PrivateRoute path="/profile/plans/enterprise" component={SelfServeEnterprise} />
      <PrivateRoute path="/profile/billing" component={Billing} />
      <PrivateRoute path="/profile/referrals" component={Referrals} />
      <PrivateRoute path="/community" component={isCreateAndExploreAvailable ? CreateAndExplore : Community} />

      <PrivateRoute
        path="/profile/team"
        component={TeamDetails}
      />

      <PrivateRoute
        path="/profile/team-members"
        component={TeamMembers}
      />

      <PrivateRoute path="/profile/copy-personalization" component={CopyPersonalization} />
      <PrivateRoute path="/profile">
        <Redirect
          to="/profile/edit"
        />
      </PrivateRoute>
      <PrivateRoute path="/enterprise-checkout" component={SelfServeEnterpriseCheckoutRedirect} />
      <PrivateRoute path="/checkout" component={Checkout} />
      <PrivateRoute path="/engines" component={FineTunes} />
      <PrivateRoute path="/order/success/:sessionId" component={Dashboard} />
      {!isProduction && <Route path="/brand" component={BrandStyle} /> }
      <PrivateRoute path="/bulk-copy/new" component={NewBulkGeneration} />
      <Redirect exact from="/bulk-copy/new/reset" to="/bulk-copy/new" />
      <PrivateRoute path="/bulk-copy/create/:templateType" component={BulkGenerationWizard} />
      <PrivateRoute path="/bulk-copy/history" component={BulkGenerationsHistory} />
      <PrivateRoute path="/bulk-copy/:id" component={BulkGenerationFiles} />
      <PrivateRoute path="/bulk-copy">
        <Redirect
          to="/bulk-copy/new"
        />
      </PrivateRoute>
      <PrivateRoute path="/blog-kickstarter" component={BlogKickstarter} />
      {currentUser?.featureToggles?.templateBuilder
        && currentUser?.permissions?.manageTemplateBuilder && (
          <PrivateRoute path="/template-builder/:templateId?" component={TemplateBuilder} />
      )}

      <PrivateRoute path="/new-file" component={NewFileRoute} />
      <PrivateRoute path="/docusign-callback" component={DocusignCallbackPage} />

      <PrivateRoute path="/super-admin/templates/list/:tab?" component={SuperAdminTemplatesList} />
      <PrivateRoute path="/super-admin/templates/:id/:tab" component={SuperAdminTemplatePage} />
      <PrivateRoute path="/super-admin/templates/:id" component={SuperAdminTemplatePage} />
      <PrivateRoute path="/super-admin/templates">
        <Redirect
          to="/super-admin/templates/list"
        />
      </PrivateRoute>
      {/* {currentUser && !currentUser.onFreePlan && currentUser.featureToggles.rewardsPage && <PrivateRoute path="/rewards" component={Rewards} /> } */}
      {currentUser?.isUsageDashboardEnabled && (
        <PrivateRoute
          path="/usage"
          component={isDashboardV2Available ? UsageV2 : Usage}
        />
      ) }

      <PrivateRoute path="/bigCommerce-bulk-copy/new" component={BigCommerceBulkCopy} />
      <PrivateRoute path="/akeneo-bulk-copy/new" component={AkeneoBulkCopy} />

      <Route path="/bigCommerce-incognito-warn" component={BigCommerceIncognitoWarn} />

      <PrivateRoute key="generic-template-page" path="/:slug/:id" component={FileViewProxy} />

      <Route path="/not-found" component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
