import React, { memo, useCallback, useEffect, useRef } from 'react';

const ScrollFade = ({ withoutTop = false, withoutBottom = false, topFadePercent = 20, bottomFadePercent = 80 }) => {
  const rootRef = useRef(null);

  const easeIn = useCallback((t, alpha) => t ** alpha, []);

  const getMask = useCallback((bottomOpacity, topOpacity) => (
    `linear-gradient(rgba(255,255,255, ${withoutTop ? 1 : topOpacity}), black ${topFadePercent}%, black ${bottomFadePercent}%, rgba(255,255,255,${bottomOpacity}))`
  ), [withoutTop]);

  const onScroll = useCallback(() => {
    const scrollElement = rootRef.current?.parentElement;
    if (scrollElement) {
      const { offsetHeight: elementHeight, scrollHeight: elementScrollHeight, scrollTop } = scrollElement;
      const bottomOpacity = withoutBottom ? 1 : easeIn(scrollTop / (elementHeight - elementScrollHeight), 10);
      const topOpacity = withoutTop ? 1 : easeIn(
        (scrollTop - (elementScrollHeight - elementHeight)) / (elementHeight - elementScrollHeight), 10,
      );
      const mask = getMask(bottomOpacity, topOpacity);

      scrollElement.style.mask = mask;
      scrollElement.style.webkitMask = mask;
    }
  }, [rootRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const scrollElement = rootRef.current?.parentElement;

    if (scrollElement) {
      const { offsetHeight, scrollHeight } = scrollElement;

      if (offsetHeight !== scrollHeight) {
        const mask = getMask(0);
        scrollElement.style.mask = mask;
        scrollElement.style.webkitMask = mask;
      }

      onScroll();

      scrollElement.addEventListener('scroll', onScroll);
      return () => scrollElement.removeEventListener('scroll', onScroll);
    }
  }, [rootRef, onScroll]);

  return (
    <div style={{ position: 'absolute' }} ref={rootRef} />
  );
};

export default memo(ScrollFade);
