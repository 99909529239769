import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './TruncatedText.styles';

const TruncatedText = ({ children, variant, multiline, maxLines, withoutTooltip, ...rest }) => {
  const classes = useStyles({ maxLines });

  const [isEllipsisActive, setEllipsisActive] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      const offsetCheck = multiline
        ? textRef.current?.offsetHeight < textRef.current?.scrollHeight
        : textRef.current?.offsetWidth < textRef.current?.scrollWidth;

      setEllipsisActive(offsetCheck);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [textRef, isEllipsisActive]);

  return (
    <SimpleTooltip
      interactive
      content={(!withoutTooltip && isEllipsisActive) ? children : ''}
    >
      <Typography
        variant={variant}
        noWrap={!multiline}
        ref={textRef}
        className={clsx(rest.className)}
        classes={{ root: clsx({ [classes.multiline]: !!multiline }) }}
        title={null}
        {...rest}
      >
        <>
          {children}
          {/* ↓ this div prevents additional tooltip in Safari */}
          <div />
        </>
      </Typography>
    </SimpleTooltip>
  );
};

TruncatedText.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
  ]),
  multiline: PropTypes.bool,
  withoutTooltip: PropTypes.bool,
  maxLines: PropTypes.number,
  children: PropTypes.node,
};

TruncatedText.defaultProps = {
  variant: 'body2',
  multiline: false,
  maxLines: 2,
  children: '',
};

export default TruncatedText;
