import { HttpClient } from "../HttpClient";
import { HandlingOptions } from "../HandlingOptions";

export class Template {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public getTemplates(
    useCache?: boolean,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/templates", { token, useCache }, options);
  }
}
