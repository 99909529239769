import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 16,
    padding: '24px 32px',
  },
  select: {
    width: 224,
  },
  params: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& div': {
      width: 224,
    },
    '& > svg': {
      margin: theme.spacing(0, 1),
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '39px',
    fontWeight: 600,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  label: {
    fontWeight: 504,
    color: theme.palette.colors.darkGrey,
  },
  buttons: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: 8,
    marginTop: 40,
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
