import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import CloseButton from '@root/components/buttons/CloseButton';
import { SearchField } from '@root/components/form';
import uiNotificationService from '@root/services/uiNotification.service';

import useStyles from './PublishToPim.styles';
import Product from './PublishToPim.product';

const PublishToPimModal = ({
  unmount,
  text,
  updateProductDescription,
  useGetProducts,
  title,
  mapProduct,
  customElement,
  disabled,
  validate = () => '',
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [keyword, setKeyword] = React.useState('');
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const {
    data: { data: products = [] } = {},
    refetch: reloadProducts,
    isFetching: isProductsLoading,
  } = useGetProducts({
    keyword,
    include: ['images'],
  });

  const handleClose = () => {
    setOpen(false);

    // for smooth closing animation
    setTimeout(() => {
      unmount();
    }, 500);
  };

  const onProductSelect = (productId) => {
    setSelectedProductId(productId);
  };

  const onSearch = (value) => {
    setIsLoading(true);
    setKeyword(value);
    setSelectedProductId(null);
  };

  const handlePublish = async () => {
    const selectedProduct = products.find((product) => product.code === selectedProductId);

    const validationError = validate(selectedProduct);

    if (validationError) {
      uiNotificationService.showErrorMessage(validationError);
      return;
    }

    await updateProductDescription({
      productId: selectedProductId,
      description: text,
    });

    uiNotificationService.showSuccessMessage('Product description updated');

    handleClose();
  };

  React.useEffect(() => {
    reloadProducts();
  }, [keyword]);

  React.useEffect(() => {
    setIsLoading(isProductsLoading);
  }, [isProductsLoading]);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
    >
      <CloseButton
        onClick={handleClose}
      />
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {customElement && (
          <div className={classes.customElementWrap}>
            {customElement}
          </div>
        )}
        <SearchField
          classes={{}}
          placeholder="Search for a product name"
          onChange={onSearch}
          debounce
        />
        <div className={classes.productsList}>
          {isLoading ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : products.map(mapProduct).map((product) => (
            <Product
              key={product.id}
              onSelect={onProductSelect}
              selected={selectedProductId === product.id}
              product={product}
            />
          ))}
        </div>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="text"
          >
            Discard
          </Button>
          <Button
            disabled={!selectedProductId || disabled}
            onClick={handlePublish}
          >
            Apply
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PublishToPimModal;
