import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';

import FilterItem from './FilterItem';
import buckets from './filters.buckets.constant';
import useStyles from './Filters.styles';

const getCurrentBucketDefault = (urlParamBucket) => {
  return urlParamBucket;
};

const setCurrentBucketDefault = () => {};

const Filters = ({
  items = buckets.map(({ id }) => id),
  styling = 'icons',
  getCurrentBucket = getCurrentBucketDefault,
  setCurrentBucket = setCurrentBucketDefault,
}) => {
  const { bucket } = useParams();
  const { currentUser } = AuthContext.useAuth();

  const currentBucket = getCurrentBucket(bucket);

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const {
    toggles,
  } = currentExternalSource;

  const { permissions, featureToggles } = currentUser;
  const isCreateAndExploreAvailable = featureToggles?.createAndExplore;

  const hideCustomBucket = isCreateAndExploreAvailable
   || toggles.hideCustomBucket
  || !permissions.viewTemplateBuilder
  || !featureToggles.templateBuilder;

  const bucketList = hideCustomBucket
    ? buckets.filter(({ id }) => id !== 'custom')
    : buckets;

  const classes = useStyles({ length: bucketList.length });

  return (
    <div className={clsx({
      [classes.filterList]: styling === 'icons',
      [classes.inlineFilterList]: styling === 'inline',
    })}
    >
      {bucketList
        .filter(({ id }) => items.includes(id))
        .map((b) => (
          <FilterItem
            styling={styling}
            title={b.title}
            isActive={(!currentBucket && b.id === 'made-for-you') || currentBucket === b.id}
            onClick={() => {
              setCurrentBucket(b);
            }}
            id={b.id}
            icon={b.icon}
            active={bucket === b.id}
            key={`filter-item-${b.id}`}
          />
        ))}
    </div>
  );
};

export default Filters;
