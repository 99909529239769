import { IconButton } from '@material-ui/core';
import { IconChalkboard, IconPlus } from '@tabler/icons-react';
import React from 'react';
import clsx from 'clsx';

import RootFolderListItemNew from '@root/views/Dashboard/components/FolderSidebarNew/RootFolderListItemNew/RootFolderListItemNew';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import { NEW_PROJECT_MODAL_ID } from '@root/views/Dashboard/components/Modals';
import { useDashboard, actions, selectionTypes } from '@root/views/Dashboard/dashboardContext';
import { getChildFolders } from '@root/views/Dashboard/helpers';
import useFolders from '@root/resources/folder/useFolders';
import ScrollFade from '@root/components/ScrollFade';

import useRootStyles from '../FolderSidebarNew.styles';

import useStyles from './MyWorkspaceSection.styles';

const MyWorkspaceSection = ({ rootFolders }) => {
  const classes = useStyles();
  const rootClasses = useRootStyles();

  const { dispatch: modalsDispatch } = useGlobalModals();
  const {
    state: {
      selected: { type: selectedType },
    }, dispatch,
  } = useDashboard();

  const { data: folders } = useFolders();

  const handlePlusButtonClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL,
      payload: {
        modalId: NEW_PROJECT_MODAL_ID,
      },
    });
  };

  const handleMyWorkspaceClick = () => {
    dispatch({
      type: actions.SELECT_MY_FILES,
      payload: { folderIds: rootFolders.flatMap((f) => {
        const childFolders = getChildFolders(f._id, folders);

        return [...childFolders.map((cf) => cf._id), f._id];
      }) },
    });
  };

  const isActive = selectedType === selectionTypes.MY_FILES;

  // rename home folder and put in in front
  const foldersToDisplay = rootFolders
    .map((f) => (f?.home ? { ...f, title: 'Other' } : f))
    .sort((a, b) => (a.home && -1) || (b.home && 1) || 0);

  return (
    <div
      className={rootClasses.sectionContainer}
    >
      <div className={classes.headerWrapper}>
        <div
          className={clsx(classes.header, { [classes.activeHeader]: isActive })}
          onClick={handleMyWorkspaceClick}
        >
          <div className={classes.titleContainer}>
            <IconChalkboard className={classes.titleIcon} />
            <span>My Workspace</span>
          </div>

          <IconButton
            className={classes.actionButton}
            classes={{}}
            onClick={handlePlusButtonClick}
          >
            <IconPlus width={16} height={16} strokeWidth={3} />
          </IconButton>
        </div>
      </div>

      <div className={classes.divider} />

      <div className={classes.foldersContainer}>
        <ScrollFade />

        {foldersToDisplay.map((f) => (
          <RootFolderListItemNew
            folder={f}
            key={`folder-sub-list-${f._id}`}
            isDraggable
          />
        ))}
      </div>
    </div>
  );
};

export default MyWorkspaceSection;
