import React from 'react';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import AuthLayout from '@root/views/Layout/AuthLayout';
import bigCommerceService from '@root/services/bigCommerce.service';

const BigCommerceIncognitoWarn = () => {
  if (!bigCommerceService.isInvalidUrl()) {
    return (
      <Redirect to="/create" />
    );
  }

  return (
    <AuthLayout checkBigCommerceUrl={false}>
      <Typography variant="h2">
        Incognito Mode Detected
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="body1">
        Looks like you are using incognito/private browsing mode. Please switch to regular browsing mode to continue.
      </Typography>
    </AuthLayout>
  );
};

export default BigCommerceIncognitoWarn;
