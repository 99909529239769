import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { IconBookmark } from '@tabler/icons-react';

import useLikeFile from '@root/resources/file/useLikeFile';
import { useDashboard } from '@root/views/Dashboard/dashboardContext';

import useStyles from './BookmarkFileButton.styles';

// disabled if 'template' itself disabled
const BookmarkFile = ({ isDisabled, fileId, liked }) => {
  const classes = useStyles();

  const { state: { filters } } = useDashboard();
  const { isLoading, mutateAsync: bookmarkFile } = useLikeFile();

  const [localLikedFlag, setLocalLikedFlag] = useState(liked);

  const handleLikeFile = async (event) => {
    event.stopPropagation();

    const response = await bookmarkFile({ fileId, filters });

    if (response && !response.isBadRequest) {
      setLocalLikedFlag(response.liked);
    }
  };

  return (
    <div>
      {isLoading && <CircularProgress size={24} />}

      {!isLoading && (
        <IconBookmark
          className={clsx(
            classes.bookmarkIcon,
            {
              [classes.bookmarkedIcon]: localLikedFlag,
              [classes.disabled]: isDisabled,
            },
          )}
          onClick={handleLikeFile}
        />
      )}
    </div>
  );
};

export default BookmarkFile;
