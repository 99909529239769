import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { InputField, FormField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import useUpdateCatalog from '@root/resources/catalog/useUpdateCatalog';
import uiNotificationService from '@root/services/uiNotification.service';

import useStyles from './RenameCatalogForm.styles';

const RenameCatalogForm = () => {
  const classes = useStyles();
  const { id: catalogId } = useParams();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { isLoading, mutateAsync: updateCatalog } = useUpdateCatalog();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const renameCatalog = async (values) => {
    const response = await updateCatalog({
      ...values,
      id: catalogId,
    });

    if (response.isBadRequest) {
      return;
    }

    uiNotificationService.showSuccessMessage('Catalog name updated.');
    closeModal();
  };

  const renameCatalogSchema = Yup.object({
    title: Yup.string().required('Catalog name is required').max(100, 'Catalog name must be 100 characters or less.'),
  });

  return (
    <Formik
      initialValues={{
        title: '',
      }}
      onSubmit={renameCatalog}
      validationSchema={renameCatalogSchema}
    >
      {({ isValid }) => {
        return (
          <Form>
            <FormField
              name="title"
              component={InputField}
              label="New catalog name"
              required
              autoFocus
            />
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={closeModal}>Discard</Button>
              <Button type="submit" disabled={!isValid || isLoading}>Rename</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RenameCatalogForm;
