import { makeStyles } from '@material-ui/core';

import background from './background.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.primary,
    marginTop: '25px',
    padding: '7px 25px',
    textAlign: 'center',
    '&:hover': { backgroundColor: theme.palette.colors.white },
  },
  card: {
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '36px',
    height: '100%',
  },
  text: {
    color: theme.palette.colors.white,
  },
}));

export default useStyles;
