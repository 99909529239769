import clsx from 'clsx';
import { Formik, Form } from 'formik';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import config from '@config';
import Button from '@root/components/buttons/Button/Button';
import { FormField, InputField } from '@root/components/form';
import referralResource from '@root/resources/referral';
import uiNotificationService from '@root/services/uiNotification.service';
import { validations } from '@root/utils/validation/formValidations';
import authContext from '@root/resources/auth/auth.context';

import useStyles from './ReferralForm.styles';

const referralSchema = Yup.object({
  refereeEmail: validations.email,
});

const ReferralForm = ({ referralLinkCode }) => {
  const classes = useStyles();
  const referralLink = `${config.appUrl}/signup?referralType=link&referralCode=`;
  const { currentUser } = authContext.useAuth();
  const rewardWordsEnabled = currentUser.featureToggles.rewardWords;

  const { mutateAsync: createReferral } = referralResource.useCreateReferral();

  const submitReferralHandler = async (data) => {
    const requestData = {
      refereeEmail: data.refereeEmail,
    };
    try {
      const createRequest = await createReferral(requestData);

      if (!createRequest.isBadRequest) {
        uiNotificationService.showSuccessMessage(`Referral email has been sent to ${requestData.refereeEmail}`);
      }
    } catch (e) {
      uiNotificationService.showErrorMessage(e.message);
    }
  };

  const onCopy = () => {
    uiNotificationService.showSuccessMessage('Copied referral link to clipboard.');
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ referralLinkCode, refereeEmail: '' }}
      validationSchema={referralSchema}
      onSubmit={submitReferralHandler}
    >
      {({ isSubmitting }) => {
        return (
          <div className={classes.card}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.title} variant="h6">
                    Refer a friend and everyone gets
                    {' '}
                    {rewardWordsEnabled ? '200 words' : '$5 off'}
                    !
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Your referrals get
                    {' '}
                    {rewardWordsEnabled ? '200 words' : '$5 off'}
                    {' '}
                    when they subscribe to any plan.
                    Then, you get
                    {' '}
                    {rewardWordsEnabled ? '200 words' : '$5'}
                    {' '}
                    for each customer
                    who subscribes with your email invite or referral link.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={clsx(classes.refereeEmailFields)}
                >
                  <Grid item xs={9}>
                    <FormField
                      name="refereeEmail"
                      label="Refer by Email"
                      component={InputField}
                      placeholder="Enter an email to send an invite"
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.submitRefereeEmail}>
                      <Button
                        disabled={isSubmitting}
                        fullWidth
                        type="submit"
                      >
                        Send Invite
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    name="referralLinkCode"
                    label="Share your Referral Link"
                    component={InputField}
                    endAdornment={referralLinkCode
                      ? (
                        <CopyToClipboard
                          text={`${referralLink}${referralLinkCode}`}
                          onCopy={onCopy}
                          className={classes.copy}
                        >
                          <FileCopy />
                        </CopyToClipboard>
                      ) : <CircularProgress className={classes.loading} size={12} />}
                    readOnly
                    value={referralLinkCode ? `${referralLink}${referralLinkCode}` : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    <div className={classes.disclaimer}>
                      *&nbsp;
                      {rewardWordsEnabled ? '200 words' : '$5'}
                      &nbsp;reward is added to your account two weeks after each new
                      referral subscribes to a paid plan.
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ReferralForm;
