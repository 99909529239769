import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
  },
  subtitle: {
    width: 435,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    margin: theme.spacing(1, 0, 2, 0),
  },
}));

const Success = ({
  onBack,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Generations was successfully published!
      </div>
      <div className={classes.subtitle}>
        It&apos;s time to write something amazing! Let your creativity flow and
        create something that you&apos;re proud of.
      </div>
      <Button onClick={onBack} variant="contained" color="primary">
        Back to product list
      </Button>
    </div>
  );
};

export default Success;
