import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    margin: '20px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  perPageContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  perPageSelect: {
    width: '60px',
    padding: '5px 5px',
    borderColor: theme.palette.primary.main,
    // color: theme.palette.primary.main,
    // fontWeight: 600,
  },
  // TODO: Adjust root component border & icon according the Figma design
  // perPageSelectIcon: {
  //   color: theme.palette.primary.main,
  // },
}));

export default useStyles;
