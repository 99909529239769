import React from 'react';

import useStyles from './PlanInfo.styles';

const PlanInfo = ({ period, plan }) => {
  const classes = useStyles();
  const isMonthly = period === 'monthly';
  const { name, nickname, amount, description, oldPrice, data: { limits } } = plan;
  const price = amount / 100;
  const priceMonthly = isMonthly ? price : Math.round(price / 12);
  const hasPrice = !!amount;

  return (
    <div className={classes.planInfoRoot}>
      <div className={classes.name}>
        {nickname || name}
      </div>
      <div className={classes.price}>
        {!hasPrice
          ? 'Contact Us'
          : (
            <>
              {(!isMonthly && oldPrice) && <span className={classes.oldPrice}>{`$${oldPrice}`}</span>}
              {`$${priceMonthly} /mo`}
            </>
          )}
      </div>
      <div className={classes.secondaryText}>
        {hasPrice && (!isMonthly && `$${price} billed yearly`)}
        {!hasPrice && <>&nbsp;</>}
      </div>
      <div className={classes.description}>
        {description}
      </div>
    </div>
  );
};

export default PlanInfo;
