import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useApproveTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    (id) => api.aiTemplate.approveTemplate(id),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries('aiTemplates.list');
        queryClient.invalidateQueries(['aiTemplates.getById', response._id]);

        return response;
      },
    },
  );
}
