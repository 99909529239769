import React from 'react';
import { useQueryClient } from 'react-query';

export default function useInvalidateLimits() {
  const queryClient = useQueryClient();

  const invalidateLimits = React.useCallback((delay = 3000) => {
    setTimeout(() => {
      queryClient.invalidateQueries(['billing.getLimits', { }]);
    }, delay);
  }, []);

  return { invalidateLimits };
}
