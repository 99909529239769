import { useQuery, useQueryClient } from 'react-query';

import api from '@root/api';

const getProducts = async ({
  keyword,
  include,
  next,
  prevProducts,
  category,
}) => {
  const products = await api.akeneo.getProducts({
    search: keyword,
    include,
    next,
    category,
  });

  if (next) {
    return {
      ...products,
      data: [
        ...prevProducts.data,
        ...products.data,
      ],
    };
  }

  return products;
};

export default function useGetProducts({
  page = 1,
  keyword = '',
  include = [],
  category = '',
  next = null,
}) {
  const queryCLient = useQueryClient();
  const prevProducts = queryCLient.getQueryData(['akeneo.getProducts']) || { data: [] };

  return useQuery(['akeneo.getProducts'], () => getProducts({
    page,
    keyword,
    include,
    category,
    next,
    prevProducts,
  }), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
