import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.colors.white,
    position: 'absolute',
    zIndex: 10000,
  },
  spinner: {
    color: theme.palette.colors.darkGrey,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    marginTop: 20,
  },
}));
