import React from 'react';

import useCompanyInfo from '@root/resources/company/useCompanyInfo';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import useBillingInfo from '@root/resources/billing/useBillingInfo';
import dateUtils from '@root/utils/date.util';

import useStyles from './BillingDates.style';

const BillingDates = () => {
  const { data: billingInfoData = { addons: {} } } = useBillingInfo();
  const { data: company } = useCompanyInfo();
  const { data: { results: teamMembers = [] } = {} } = useTeamMembers();
  const {
    cancelAtPeriodEnd, currentPeriodEndOn,
    upcomingInvoicePrice, upcomingAddonsInvoice, accountBalance,
  } = billingInfoData;

  const paidMembers = company?.billing.members
    ? teamMembers.length - company.billing.members : 0;
  const paidMembersPrice = company?.billing.memberPrice && paidMembers > 0
    ? (paidMembers * company?.billing.memberPrice) / 100 : 0;
  const fullUpcomingInvoicePrice = upcomingInvoicePrice + paidMembersPrice;

  const classes = useStyles();

  const isNextPlanChargeVisible = (
    (!!fullUpcomingInvoicePrice || fullUpcomingInvoicePrice === 0) && !cancelAtPeriodEnd
  );

  if (!isNextPlanChargeVisible && !upcomingAddonsInvoice && !accountBalance) {
    return null;
  }

  return (
    <div
      className={classes.dateBlurbRoot}
    >
      {isNextPlanChargeVisible && (
        <div>
          <span>
            Next subscription plan charge:
            {' '}
            <b>{`$${fullUpcomingInvoicePrice}`}</b>
            {' '}
            on
          </span>
          {' '}
          <b>{dateUtils.formatDate(currentPeriodEndOn, 'LLL d, yyyy')}</b>
          .
        </div>
      )}

      {!!upcomingAddonsInvoice && (
        <div>
          <span>
            Next add-on charge:
            {' '}
            <b>{`$${upcomingAddonsInvoice.amount}`}</b>
            {' '}
            on
          </span>
          {' '}
          <b>{dateUtils.formatDate(upcomingAddonsInvoice.date, 'LLL d, yyyy')}</b>
          .
        </div>
      )}

      {!!accountBalance && (
        <div>
          <span>
            Account Credit:
            {' '}
            <b>{`$${accountBalance}`}</b>
          </span>
        </div>
      )}
    </div>
  );
};

export default BillingDates;
