import React from 'react';
import { IconButton } from '@material-ui/core';
import PersonalizeIcon from '@material-ui/icons/ThumbUpAltOutlined';
import clsx from 'clsx';

const PersonalizeButton = ({ onPersonalize, usedForPersonalization, disabled, classes }) => {
  return (
    <IconButton
      disableRipple
      size="small"
      className={clsx(
        { [classes.active]: usedForPersonalization },
        classes.personalizeButton,
      )}
      onClick={onPersonalize}
      disabled={disabled}
    >
      <PersonalizeIcon />
    </IconButton>
  );
};

export default PersonalizeButton;
