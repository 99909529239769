import React, { useRef, useState } from 'react';
import { FormHelperText } from '@material-ui/core';

import { InputField } from '@root/components/form';

import { PROMPT_MAX_LENGTH } from '../constants';

import Inputs from './Inputs';

const PromptField = ({
  inputs, onInputsChange, value, name, onBlur, onChange, disabled, ...props
}) => {
  const inputRef = useRef(null);

  const [cursor, setCursor] = useState(null);
  const [error, setError] = useState(null);

  const handlePromptChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange(name)(e);
  };

  const handlePromptBlur = (e) => {
    setCursor(e.target.selectionStart);
    onBlur(name)(e);
  };

  const handlePasteInput = (text) => {
    const newInput = `${value.slice(0, cursor)}{${text}}${value.slice(cursor)}`;
    if (newInput.length <= PROMPT_MAX_LENGTH) {
      onChange(name)(newInput);
    }
  };

  return (
    <div>
      <InputField
        ref={inputRef}
        value={value}
        onChange={handlePromptChange}
        onBlur={handlePromptBlur}
        disabled={disabled}
        {...props}
      />
      <Inputs
        inputs={inputs}
        onChange={onInputsChange}
        onPaste={handlePasteInput}
        onError={setError}
        disabled={disabled}
      />
      <FormHelperText error={!!error}>
        {error}
      </FormHelperText>
    </div>
  );
};

export default PromptField;
