import { ChartOptions } from 'chart.js';
import { DateTime } from 'luxon';

import { newColors } from '@root/newMaterial.theme';

const ranges = {
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  LAST_30: 'last30',
  LAST_60: 'last60',
  LAST_90: 'last90',
};

const dateRangeOptions = [
  {
    label: 'This week',
    value: ranges.THIS_WEEK,
  },
  {
    label: 'Last week',
    value: ranges.LAST_WEEK,
  },
  {
    label: 'Last 30 days',
    value: ranges.LAST_30,
  },
  {
    label: 'Last 60 days',
    value: ranges.LAST_60,
  },
  {
    label: 'Last 90 days',
    value: ranges.LAST_90,
  },
];

const dateRangeOptionToSettings: { [key: string] : { startDate: number, endDate: number } } = {
  [ranges.THIS_WEEK]: {
    startDate: DateTime.now().minus({ weeks: 1 }).toSeconds(),
    endDate: DateTime.now().toSeconds(),
  },
  [ranges.LAST_WEEK]: {
    startDate: DateTime.now().minus({ weeks: 2 }).toSeconds(),
    endDate: DateTime.now().minus({ weeks: 1 }).toSeconds(),
  },
  [ranges.LAST_30]: {
    startDate: DateTime.now().minus({ days: 30 }).toSeconds(),
    endDate: DateTime.now().toSeconds(),
  },
  [ranges.LAST_60]: {
    startDate: DateTime.now().minus({ days: 60 }).toSeconds(),
    endDate: DateTime.now().toSeconds(),
  },
  [ranges.LAST_90]: {
    startDate: DateTime.now().minus({ days: 90 }).toSeconds(),
    endDate: DateTime.now().toSeconds(),
  },
};

const chartOptions: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  hover: {
    mode: 'index',
    intersect: false,
  },
  elements: {
    line: {
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      backgroundColor: newColors.neutrals600,
      titleColor: newColors.white,
      bodyColor: newColors.neutrals300,
      titleFont: {
        family: 'Inter, sans-serif',
        size: 14,
      },
      bodyFont: {
        family: 'Inter, sans-serif',
        size: 12,
      },
      displayColors: false,
      boxWidth: 500,
      callbacks: {
        title: (tooltipItem) => {
          return `Words: ${tooltipItem[0]?.raw as string || '0'}`;
        },
        label: (tooltipItem) => {
          return tooltipItem.label;
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 7,
        maxRotation: 0,
        align: 'start',
        font: {
          family: 'Inter, sans-serif',
          size: 14,
        },
        color: '#9F9EAB',
      },
    },
    y: {
      display: true,
      stacked: true,
      grid: {
        color: '#F7F7F8',
        drawBorder: false,
      },
      ticks: {
        display: false,
        stepSize: 250,
      },
    },
  },
};

export {
  dateRangeOptions,
  dateRangeOptionToSettings,
  chartOptions,
};
