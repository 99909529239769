import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  formPrompt: {
    fontSize: 14,
    marginBottom: 16,
  },
  input: {
    marginBottom: 24,
    width: '100%',
  },
  inputFocused: {
    '& .MuiInputAdornment-root svg path': {
      fill: 'black',
    },
  },
  submitButton: {
    alignSelf: 'center',
  },
  loadingRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingPrompt: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    lineHeight: '22px',
    marginBottom: 24,
    alignItems: 'center',
  },
  loader: {
    width: 136,
    height: 136,
    '& svg path': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
    },
  },
  fraseItems: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    gap: 8,
  },
  fraseItem: {
    border: '1px solid #C4C4C4',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    gap: 12,
    background: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  fraseItemPartial: {
    border: '1px solid rgba(255, 217, 101, 1)',
  },
  fraseItemFull: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  fraseItemTooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.colors.white,
    borderColor: theme.palette.primary.main,
    opacity: 0.5,
  },
  fraseItemTooltipArrow: {
    color: theme.palette.primary.main,
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
  },
  fraseItemCount: {
    borderRadius: 2,
    padding: '7px 0',
    fontWeight: 500,
    fontSize: 14,
    minWidth: 64,
    textAlign: 'center',
    background: '#F8F8F8',
    color: theme.palette.grey.main,
  },
  fraseItemCountPartial: {
    background: theme.palette.colors.yellow,
    color: 'rgba(168, 126, 0, 1)',
  },
  fraseItemCountFull: {
    background: theme.palette.primary.main,
    color: theme.palette.colors.white,
  },
  fraseItemText: {
    display: 'flex',
    flexDirection: 'column',
  },
  fraseItemTextTitle: {
    lineHeight: '17px',
  },
  fraseItemTextDesc: {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey.main,
  },
  searchInput: {
    marginBottom: 16,
    width: '100%',
  },
  frasePhraseRoot: {
    display: 'flex',
    padding: '6px 8px',
    background: 'rgba(82, 58, 231, 0.1)',
    borderRadius: 4,
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginBottom: 24,
    justifyContent: 'space-between',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  frasePhraseText: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  frasePhraseClose: {
    cursor: 'pointer',
  },
  progress: {
    flexGrow: 2,
    display: 'flex',
    gap: 1,
    borderRadius: 10,
    height: 8,
    overflow: 'hidden',
  },
  progressBar: {
    width: '20%',
    background: theme.palette.colors.mediumLightGrey,
  },
  progressBarActive: {
    background: '#298000',
  },
  progressRoot: {
    width: '100%',
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  progressText: {
    fontSize: 12,
    width: 32,
  },
  fraseTitle: {
    fontWeight: 500,
    marginBottom: 16,
    color: theme.palette.colors.darkGrey,
    fontSize: 14,
  },
}));

export default useStyles;
