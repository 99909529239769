import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: theme.palette.colors.error,
  },

  mappingContainer: {
    flex: 1,
    maxHeight: '100%',
    maxWidth: 587,
    padding: '24px 32px',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  titleContainer: {
    marginBottom: 32,
  },

  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '39px',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals400,
  },

  columnsContainer: {
    overflow: 'auto',
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(166px, 1fr))',
    gridAutoRows: 'max-content',
    gap: 12,

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  column: {
    cursor: 'pointer',
    padding: '8px 16px 16px',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.neutrals50,

    '&:hover': {
      backgroundColor: '#D3D0FB40',
      outline: `1px solid ${theme.palette.colors.purple400}`,
      outlineOffset: '-1px',
    },
  },
  selected: {
    backgroundColor: '#D3D0FB40',
    outline: `1px solid ${theme.palette.colors.purple400}`,
    outlineOffset: '-1px',
  },
  columnHeader: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '39px',
    marginBottom: 2,
  },
  valuesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,

  },
  columnValue: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals400,
  },
  buttonsContainer: {
    marginTop: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 16,
  },
}));

export default useStyles;
