import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import TruncatedText from '@root/components/TruncatedText';
import authContext from '@root/resources/auth/auth.context';
import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import TemplateStatusBadge from '@root/views/CreateAndExplore/Content/CardContentView/CustomTemplateCard/TemplateStatusBadge';
import {
  GradientSvg1,
  GradientSvg2,
  GradientSvg3,
  GradientSvg4,
  GradientSvg5,
  GradientSvg6,
  GradientSvg7,
} from '@root/assets/cardGradients';
import { idToDecimalSum } from '@root/utils/formatString.util';
import { TEMPLATE_CREATE_MODAL_ID } from '@root/views/TemplatesDashboard/components/modals';

import CustomTemplateKebabMenu from './CustomTemplateKebabMenu';
import useStyles from './CustomTemplateCard.styles';

const backgrounds = [GradientSvg1, GradientSvg2, GradientSvg3, GradientSvg4, GradientSvg5, GradientSvg6, GradientSvg7];

const CustomTemplateCard = ({ template }) => {
  const classes = useStyles();
  const history = useHistory();

  const { dispatch } = useGlobalModals();

  const { currentUser: user } = authContext.useAuth();

  const { _id, status, fromCommunity } = template;
  const canManageTemplateBuilder = user.permissions.manageTemplateBuilder;

  const handleCreateClick = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: template,
      },
    });
  };

  const handleEditClick = () => {
    history.push(`/template-builder/${_id}`);
  };

  const background = useMemo(
    () => {
      if (template?.fromCommunity) {
        return backgrounds[idToDecimalSum(template?._id) % 7];
      }
      return '';
    }, [template],
  );

  return (
    <div
      className={classes.cardContainer}
      style={{ backgroundImage: `${fromCommunity ? `url(${background})` : 'unset'}` }}
    >
      <header className={classes.cardHeader}>
        <TemplateStatusBadge template={template} />

        <CustomTemplateKebabMenu template={template} />
      </header>

      <section className={classes.templateInfo}>
        <TruncatedText
          className={classes.templateName}
        >
          {template?.title}
        </TruncatedText>

        <TruncatedText
          multiline
          maxLines={2}
          className={classes.templateDescription}
        >
          {template?.description}
        </TruncatedText>
      </section>

      <footer className={classes.cardFooter}>
        <div className={classes.actionButtons}>
          {status === 'live' && (
            <Button
              size="small"
              onClick={handleCreateClick}
              className={clsx({ [classes.communityButton]: fromCommunity })}
            >
              Create
            </Button>
          )}

          {status === 'submitted' && (
            <Button
              size="small"
              variant="text"
              onClick={handleEditClick}
              className={classes.linkButton}
            >
              View
            </Button>
          )}

          {status !== 'submitted' && status !== 'live' && canManageTemplateBuilder && (
            <Button
              size="small"
              variant="text"
              onClick={handleEditClick}
              className={classes.linkButton}
            >
              Edit
            </Button>
          )}
        </div>
      </footer>
    </div>
  );
};

export default CustomTemplateCard;
