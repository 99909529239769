import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Prompt } from 'react-router-dom';

import aiResource from '@root/resources/ai';
import documentResource from '@root/resources/document';
import MainLayout from '@root/views/Layout';
import fileResource from '@root/resources/file';
import Loading from '@root/components/Loading/Loading';
import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import WizardLayout from '@root/views/Layout/WizardLayout';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import authContext from '@root/resources/auth/auth.context';

import StartForm from './components/StartForm';
import GenerationCard from './components/GenerationCard';
import HintBlock from './components/HintBlock';
import ExpandedOutlines from './components/ExpandedOutlines';
import useStyles from './BlogKickstarter.styles';

const steps = ['Start', 'Choose your idea', 'Create your outline', 'Kick-start your blog'];
const promptMessage = 'Are you sure you want to leave? All progress will be lost';

const BlogKickstarter = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { currentUser } = authContext.useAuth();

  const [step, setStep] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);

  const [topic, setTopic] = useState(null);

  const [ideas, setIdeas] = useState(null);
  const [selectedIdea, setSelectedIdea] = useState(null);

  const [outlines, setOutlines] = useState(null);
  const [selectedOutline, setSelectedOutline] = useState(null);
  const [expandedOutlines, setExpandedOutlines] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);

  const classes = useStyles({ step });

  const {
    mutateAsync: generate, isLoading: isGenerating,
  } = aiResource.useGenerateBlogKickstarter();
  const {
    mutateAsync: createFile, isLoading: isFileCreating,
  } = fileResource.useCreateFile();
  const {
    mutateAsync: createDocument, isLoading: isDocCreating,
  } = documentResource.useCreateDocument();

  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const isLoading = isGenerating || isFileCreating || isDocCreating;
  const hasSelectedItem = (step === 1 && selectedIdea) || (step === 2 && selectedOutline);
  const isNextStepAvailable = (step === 0 && ideas)
    || (step === 1 && outlines)
    || (step === 2 && expandedOutlines);

  const generateIdeas = async (value) => {
    const { results } = await generate({
      step: 0,
      data: {
        topic: value,
      },
    });
    return results;
  };

  const generateOutlines = async () => {
    const { results } = await generate({
      step: 1,
      data: {
        title: selectedIdea.title,
        intro: selectedIdea.intro,
      },
    });
    return results;
  };

  const expandOutlines = async () => {
    const { results } = await generate({
      step: 2,
      data: {
        topic,
        outlines: selectedOutline,
      },
    });

    const sectionsObject = results
      .map((r) => r.outline)
      .reduce((acc, curr) => { acc[curr] = 0; return acc; }, {});

    setExpandedOutlines(results);
    setSelectedSections(sectionsObject);

    setStep(3);
  };

  const handleSubmit = async (values) => {
    const results = await generateIdeas(values.topic);
    if (results) {
      setIdeas(results);
      setShowPrompt(true);
      setOutlines(null);
      setExpandedOutlines(null);
      setStep(1);
      refetchOnboarding('generateContent');
    }
  };

  const handleContinue = async () => {
    if (step === 1) {
      const results = await generateOutlines();
      if (results) {
        setOutlines(results);
        setExpandedOutlines(null);
        setStep(2);
      }
    } else if (step === 2) {
      expandOutlines();
    }
  };

  const generateMore = async () => {
    if (step === 1) {
      const results = await generateIdeas(topic);
      if (results) {
        setIdeas((currResults) => [...currResults, ...results]);
      }
    } else if (step === 2) {
      const results = await generateOutlines();
      if (results) {
        setOutlines((currResults) => [...currResults, ...results]);
      }
    }
  };

  const handleAutoBlog = async (autoTopic) => {
    const fileData = history.location.state || {};
    const { fileId, documentId } = await generate({
      step: 'auto',
      data: {
        topic: autoTopic,
        fileData,
      },
    });
    if (fileId && documentId) {
      history.push(`/documents/${fileId}/${documentId}`);
    }
  };

  const exportBlog = async (checkedOutlines) => {
    const fileData = history.location.state || {};
    const fileTitle = fileData.title || selectedIdea.title;

    const outlinesToBlog = expandedOutlines
      .filter((outline) => checkedOutlines.includes(outline.outline));
    const newFile = await createFile({
      templateType: 'freeFormEditor',
      title: fileTitle,
      folderId: fileData.folderId,
    });
    let content;
    if (currentUser.featureToggles.newEditor) {
      content = {
        blocks: [
          { type: 'header', data: { text: selectedIdea.title, level: 3 } },
          { type: 'paragraph', data: { text: selectedIdea.intro } },
          ...outlinesToBlog.flatMap((outline) => [
            { type: 'header', data: { text: outline.outline, level: 5 } },
            { type: 'paragraph', data: { text: outline.sections[selectedSections[outline.outline]] } },
          ]),
        ],
      };
    } else {
      content = `
        <h3>${selectedIdea.title}</h3>
        <p>${selectedIdea.intro}</p>
        <br>
        ${outlinesToBlog.map((outline) => `
          <h5>${outline.outline}</h5>
          <p>${outline.sections[selectedSections[outline.outline]]}</p>
        `).join('<br>')}
      `;
    }

    const newDoc = await createDocument({
      fileId: newFile._id,
      content,
    });

    setShowPrompt(false);
    history.push(`/documents/${newFile._id}/${newDoc._id}`);
  };

  useBeforeUnload({
    when: showPrompt,
    message: promptMessage,
  });

  const formTopic = formRef.current?.values?.topic;
  useEffect(() => {
    if (formTopic) {
      setTopic(formTopic);
    }
  }, [formTopic]);

  if (isLoading) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }

  return (
    <WizardLayout
      steps={steps}
      step={step}
      onStepChange={setStep}
      nextAvailable={isNextStepAvailable}
      prevAvailable={step > 0}
    >
      <div className={classes.blogKickstarterRoot}>
        {step === 0 && (
          <StartForm
            onSubmit={handleSubmit}
            onSkip={handleAutoBlog}
            formRef={formRef}
            topic={topic}
          />
        )}

        {step === 1 && (
          <div className={classes.generationsContainer}>
            {ideas.map((idea) => {
              const content = (
                <div>
                  <b>{idea.title}</b>
                  <div>{idea.intro}</div>
                </div>
              );
              const selected = selectedIdea === idea;
              const contentLength = idea.title.length + idea.intro.length;
              return (
                <GenerationCard
                  key={idea.title + idea.intro}
                  content={content}
                  contentLength={contentLength}
                  selected={selected}
                  onClick={() => setSelectedIdea(selected ? null : idea)}
                />
              );
            })}
          </div>
        )}

        {step === 2 && (
          <div className={classes.generationsContainer}>
            {outlines.map((outline) => {
              const selected = selectedOutline === outline;
              const content = outline.map((o) => `• ${o}`).join('\n');
              const contentLength = content.length;

              return (
                <GenerationCard
                  key={content}
                  content={content}
                  contentLength={contentLength}
                  selected={selected}
                  onClick={() => setSelectedOutline(selected ? null : outline)}
                />
              );
            })}
          </div>
        )}

        {step === 3 && (
          <ExpandedOutlines
            outlines={expandedOutlines}
            selectedSections={selectedSections}
            onSectionSelect={setSelectedSections}
            onContinue={exportBlog}
          />
        )}
      </div>

      {(step === 1 || step === 2) && (
        <HintBlock
          onGenerateMore={generateMore}
          onContinue={handleContinue}
          hasSelected={hasSelectedItem}
          itemName={step === 1 ? 'Idea' : 'Outline'}
        />
      )}

      {showPrompt && <Prompt message={promptMessage} />}
    </WizardLayout>
  );
};

export default BlogKickstarter;
