import React from 'react';
import { jsPDF } from 'jspdf';

import {
  downloadAsFile,
  makeFileName,
  getDateString,
  escapeCSV,
} from '@root/utils/Export';
import { downloadAsDocX } from '@root/utils/Export/functions';

const useCheckedExport = ({
  checkedDocumentsIds,
  documents,
  config,
  localFile,
}) => {
  const downloadSelectedAsCsv = React.useCallback(() => {
    const docsToCsv = checkedDocumentsIds.length > 0
      ? checkedDocumentsIds.map((docId) => documents.find((d) => d.id === docId))
      : documents;
    const csvContent = config.getCsvContent
      ? config.getCsvContent(docsToCsv)
      : docsToCsv.reduce((csv, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${csv}"${escapeCSV(ad)}"\r\n`;
      }, '');

    downloadAsFile(csvContent, makeFileName(localFile.title, config.templateType), 'text/csv');
  }, [checkedDocumentsIds, config, documents, localFile]);

  const downloadSelectedAsDocx = React.useCallback(() => {
    const docsToText = checkedDocumentsIds.length > 0
      ? checkedDocumentsIds.map((docId) => documents.find((d) => d.id === docId))
      : documents;
    const txtContent = config.getPdfContent
      ? config.getPdfContent(docsToText)
      : docsToText.reduce((txt, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${txt}"${ad.trim()}"\r\n\n`;
      }, '');
    downloadAsDocX(txtContent, `Copysmith_${config.templateType}_${localFile.title}_${getDateString()}.docx`);
  }, [checkedDocumentsIds, config, documents, localFile]);

  const downloadSelectedAsPdf = React.useCallback(() => {
    const docsToPdf = checkedDocumentsIds.length > 0
      ? checkedDocumentsIds.map((docId) => documents.find((d) => d.id === docId))
      : documents;
    const pdfContent = config.getPdfContent
      ? config.getPdfContent(docsToPdf)
      : docsToPdf.reduce((pdf, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${pdf}"${ad}"\r\n\n`;
      }, '');

    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(`${localFile.title}\n\n`, 10, 18);

    doc.setFontSize(12);
    const splitText = doc.splitTextToSize(
      pdfContent.replace(/(<([^>]+)>)/gi, ''),
      180,
    );
    let line = 34;

    for (
      let counter = 0, { length } = splitText;
      counter < length;
      counter += 1
    ) {
      if (counter % 50 === 0 && counter >= 50) {
        doc.addPage();
        line = 15;
      }

      doc.text(splitText[counter], 10, line);
      line += 5;
    }

    doc.save(`Copysmith_${config.templateType}_${localFile.title}_${getDateString()}.pdf`);
  }, [checkedDocumentsIds, config, documents, localFile]);

  const downloadSelectedAsTxt = React.useCallback(() => {
    const docsToText = checkedDocumentsIds.length > 0
      ? checkedDocumentsIds.map((docId) => documents.find((d) => d.id === docId))
      : documents;

    const txtContent = config.getTxtContent
      ? config.getTxtContent(docsToText)
      : docsToText.reduce((txt, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${txt}"${ad.trim()}"\r\n\n`;
      }, '');

    const dateString = getDateString();
    const fileName = `Copysmith_${config.templateType}_${localFile.title}_${dateString}.txt`;

    downloadAsFile(txtContent, fileName);
  }, [checkedDocumentsIds, config, documents, localFile]);

  return {
    downloadSelectedAsCsv,
    downloadSelectedAsDocx,
    downloadSelectedAsPdf,
    downloadSelectedAsTxt,
  };
};

export default useCheckedExport;
