import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import { Copy } from '@root/components/SVGIcon';
import { successToast } from '@root/views/Authentication/components/SharedComponents/SharedComponents';
import { escapeText } from '@root/utils/text.utils';

import CarouselSlideDotIndicator from '../CarouselSlideDotIndicator';

import useStyles from './CarouselSidebar.styles';

const mod = (x, m) => ((x % m) + m) % m;

const CarouselSidebar = ({ title, options, onButtonClick, limitedAccess }) => {
  const classes = useStyles();
  const [generationIndex, setGenerationIndex] = useState(0);
  const handleCopy = () => {
    navigator.clipboard
      .writeText(escapeText(options[generationIndex]))
      .then(() => {
        successToast('Copied to Clipboard!');
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>

      </div>
      <div className={classes.text} onClick={handleCopy}>
        <div
          variant="text"
          className={classes.copyText}
        >
          Click to copy
          <Copy
            className={classes.copyIcon}
          />
        </div>
        {ReactHtmlParser(options[generationIndex])}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.navButton}
          variant="text"
          onClick={() => setGenerationIndex(
            mod(generationIndex - 1, options.length),
          )}
        >
          ← Previous
        </Button>
        {options.length <= 5 && (
          <div className={classes.dotIndicatorContainer}>
            { options.map((option, index) => (
              <CarouselSlideDotIndicator selected={index === generationIndex} />
            )) }
          </div>
        )}
        {options.length > 5 && (
          <div className={classes.dotIndicatorContainer}>
            {generationIndex > 3 && <CarouselSlideDotIndicator small />}
            <CarouselSlideDotIndicator selected={generationIndex === 0} />
            <CarouselSlideDotIndicator selected={generationIndex === 1} />
            <CarouselSlideDotIndicator selected={generationIndex === 2} />
            <CarouselSlideDotIndicator selected={generationIndex > 2} />
            {generationIndex < options.length && <CarouselSlideDotIndicator small />}
          </div>
        )}
        <Button
          className={classes.navButton}
          variant="text"
          onClick={() => setGenerationIndex(
            mod(generationIndex + 1, options.length),
          )}
        >
          Next →
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          className={classes.addButton}
          onClick={() => onButtonClick(escapeText(options[generationIndex]))}
          disabled={limitedAccess}
        >
          Add This Paragraph at Cursor
        </Button>
      </div>
    </div>
  );
};

export default CarouselSidebar;
