import { useMutation } from 'react-query';

import api from '@root/api';

export default function useUploadFile() {
  return useMutation(async ({ file, id }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    const shouldDisableNotification = (errors) => {
      const fileErrors = errors?.filter((e) => e.file) || [];
      return fileErrors.length > 0;
    };

    const response = await api.catalog.upload({ id, data: formData }, {
      disableFirstErrorNotification: shouldDisableNotification,
    });

    return response;
  });
}
