export default {
  title: 'Landing Page',
  description: 'An entire landing page from a few inputs. It’s that simple.',
  input: [
    {
      title: 'Company Name',
      value: 'glo',
    },
    {
      title: 'Product Keywords',
      value: 'Fitness, Yoga, Meditation',
    },
    {
      title: 'Description',
      value:
        'Glo sells online yoga classes that you can enjoy from the comfort of your home.',
    },
  ],
  output: {
    templateType: 'landingPage',
    content: {
      slogan: "Let's design your unique practice.",
      heading: 'Online yoga, meditation, Pilates, & fitness classes.',
      subheading: 'Glo is designed to help you feel better in body and mind.',
      benefit1: 'Practice together live.',
      benefit1_description:
        'Share in the amplified energy of a live class on glo. Join your favorite teachers with members from around the world to explore of-the-moment themes and the latest workouts. Stay motivated with a steady lineup of live classes that you can schedule in advance.',
      benefit2: 'Practice wherever you want, whenever you need.',
      benefit2_description:
        'With over 4,000 classes on demand, you can experience the benefits of your practice at home or on the go, on any device. You can even download your favorite classes so they’re always available to you.',
      benefit3: 'Expertise meets encouragement.',
      benefit3_description:
        'Our world-class teachers provide the perfect mix of knowledge and support to help you progress in mind, body, and heart. Everyone will find an instructor that they are comfortable around.e',
    },
    liked: false,
    _id: '1',
    editing: false,
    votes: null,
  },
  companyName: 'candles',
  fileData: { companyName: 'candles' },
};
