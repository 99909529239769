import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import documentResource from '@root/resources/document';
import TemplatesContext from '@root/resources/templates/templates.context';
import { getDefaultClipboardItem } from '@root/resources/file/file.helpers';

import useStyles from './SourceGeneration.styles';

const SourceGeneration = ({ documentId }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const classes = useStyles();
  const { getTemplate } = TemplatesContext.useTemplates();

  const { data: document } = documentResource.useDocument(documentId, false);

  if (!document) {
    return null;
  }
  const template = document ? getTemplate(document.templateType) : undefined;
  const content = template.getClipboardItem
    ? template.getClipboardItem(document)
    : getDefaultClipboardItem(document);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title} onClick={toggleCollapsed}>
        <span>Source generation</span>
        <ArrowDropDownIcon className={clsx({ [classes.rotated]: !isCollapsed })} />
      </div>
      {!isCollapsed && (
        <div className={classes.content}>
          {ReactHtmlParser(content)}
        </div>
      )}
    </div>
  );
};

SourceGeneration.propTypes = {
  documentId: PropTypes.string.isRequired,
};

export default SourceGeneration;
