import React from 'react';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';

const SaveModal = ({ onClose, onSave, onDiscard }) => {
  const handleConfirm = () => {
    onSave();
  };

  return (
    <ConfirmationModal
      title="Save changes before leaving?"
      onConfirm={handleConfirm}
      onCancel={onDiscard}
      cancelButtonText="Discard"
      confirmButtonText="Save"
      onClose={onClose}
      maxWidth="md"
    >
      <p>Do you want to save changes in file before exit?</p>
    </ConfirmationModal>
  );
};

export default SaveModal;
