import React from 'react';
import clsx from 'clsx';

import useStyles from './PeriodToggle.styles';

const PeriodToggle = ({ onChange, period, pricingData }) => {
  const classes = useStyles();

  const isMonoPeriod = React.useMemo(() => {
    if (!pricingData) {
      return false;
    }

    const plansWithoutEnterprise = pricingData.filter((plan) => plan.rank < 500);

    const monthlyPlans = plansWithoutEnterprise.filter((plan) => plan.period === 'monthly');

    return monthlyPlans.length === plansWithoutEnterprise.length;
  }, [pricingData]);

  if (isMonoPeriod) {
    return null;
  }

  const isMonthly = period === 'monthly';
  const isYearly = period === 'yearly';

  return (
    <div className={classes.periodToggleRoot}>
      <div
        className={clsx(classes.periodButton, isMonthly && classes.selected)}
        onClick={() => onChange('monthly')}
      >
        Monthly
      </div>
      <div
        className={clsx(classes.periodButton, isYearly && classes.selected)}
        onClick={() => onChange('yearly')}
      >
        Yearly
      </div>
    </div>
  );
};

export default PeriodToggle;
