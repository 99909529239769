import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    maxWidth: 332,
    height: 240,
    border: `1px solid ${theme.palette.colors.mediumDarkGrey}`,
    borderRadius: '10px',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.colors.lightGrey,
    },
  },
  topActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    fontFamily: '"Inter", sans-serif',
  },
  bottomActions: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
  },
  csvDownload: {
    marginLeft: '0.8rem',
    fontSize: '0.75rem',
    cursor: 'pointer',
    color: `${theme.palette.colors.grey}`,
    '&:hover': {
      color: `${theme.palette.colors.darkGrey}`,
    },
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    fontFamily: '"Inter", sans-serif',
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
  },
  description: {
    fontSize: '0.9rem',
    flexGrow: '1',
    fontFamily: '"Inter", sans-serif',
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
    maxHeight: 46,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  iconButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
