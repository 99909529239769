import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  spinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      height: 120,
      width: 120,
    },
  },
  spinnerWrapper: { height: '100vh', width: '100vw' },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinAnimation: {
    animation: '$spin 1.5s linear infinite;',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));
