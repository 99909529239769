import config from '@config';

const toISODate = (date) => {
  if (!date) {
    return null;
  }
  return (new Date(date * 1000)).toISOString();
};

const cleanUpEmptyFields = (object) => {
  const clonedObject = { ...object };
  Object.keys(clonedObject).forEach((key) => {
    if (clonedObject[key] === null || clonedObject[key] === undefined) {
      delete clonedObject[key];
    }
  });
  return clonedObject;
};

const initPendo = async (user, company, billingInfo) => {
  if (!config.pendoEnabled) {
    return;
  }
  await (async () => {
    while (!window.pendo) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  })();

  // use fields with prefix "new" to prevent pendo bug
  const visitor = cleanUpEmptyFields({
    id: user._id,
    newUserId: user._id,

    newEmail: user.email,
    newFirstName: user.firstName,
    newRole: user.workspaces[0].roles[0],

    newCreatedOn: toISODate(user.createdOn),
    newStartOn: toISODate(user.createdOn),
  });

  const account = cleanUpEmptyFields({
    id: company._id,
    newCompanyId: company._id,

    newCreatedOn: toISODate(company.createdOn),

    newPlanName: billingInfo.planName,
    newBillingPeriod: billingInfo.period,
    newBillingStatus: company.billing.status,
    newBillingEmail: company.billing.email,
    newLifetimeDiscount: !!company.billing.lifetimeDiscount,
    newCancelOn: toISODate(company.billing.cancelOn),
  });

  window.pendo.initialize({
    visitor,
    account,
  });
};

export default initPendo;
