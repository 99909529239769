import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  statusLabel: {
    marginRight: 4,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export default useStyles;
