import React, { useRef, useState } from 'react';
import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { useDrop } from 'react-dnd';
import { IconBriefcase, IconChevronDown, IconShare } from '@tabler/icons-react';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { FolderOptionsKebab } from '@root/views/Dashboard/components/IconButtons';
import useFolders from '@root/resources/folder/useFolders';
import { getChildFolders, isFolderShared } from '@root/views/Dashboard/helpers';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import useUpdateFolder from '@root/resources/folder/useUpdateFolder';
import uiNotificationService from '@root/services/uiNotification.service';
import AuthContext from '@root/resources/auth/auth.context';
import ItemTypes from '@root/utils/dndItemTypes';
import ChildFolderListItemNew from '@root/views/Dashboard/components/FolderSidebarNew/ChildFolderListItemNew/ChildFolderListItemNew';
import TruncatedText from '@root/components/TruncatedText';

import useStyles from './RootFolderListItemNew.styles';

const RootFolderListItemNew = ({
  folder, isDraggable = false, special = false,
}) => {
  const classes = useStyles();

  const [hover, setHover] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const { data: folders } = useFolders();
  const { currentUser } = AuthContext.useAuth();
  const {
    state: {
      filters: { folderId: currentFolderId },
    }, dispatch,
  } = useDashboard();
  const { mutateAsync: updateFile } = useUpdateFile();
  const { mutateAsync: updateFolder } = useUpdateFolder();
  const ref = useRef(null);

  const childFolders = getChildFolders(folder._id, folders);

  const active = folder.id === currentFolderId;
  const isShared = isFolderShared(folder, currentUser);

  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  const moveFileHere = async (fileId) => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move file to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`File has been moved to ${folder.title}`);
    }
  };

  const moveFolderHere = async (folderId) => {
    const { isBadRequest } = await updateFolder({
      folderId,
      update: {
        parentFolderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move folder to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`Folder has been moved to ${folder.title}`);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    // do not accept any files or folder if folder is 'home'
    accept: folder?.home ? [] : [ItemTypes.FILE_CARD, ItemTypes.FOLDER_CARD],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item, monitor) => {
      const itemType = monitor.getItemType();
      if (itemType === ItemTypes.FILE_CARD) {
        moveFileHere(item.fileId);
      } else if (itemType === ItemTypes.FOLDER_CARD) {
        moveFolderHere(item.folderId);
      }
    },
  });

  const handleSelectFolder = () => {
    if (childFolders.length) {
      setCollapsed(false);
    }
    dispatch({
      type: actions.SELECT_PROJECT,
      payload:
        {
          title: folder.title,
          folderId: folder._id,
          ...(special && { homeFolderId: folder._id }),
        },
    });
  };

  const handleArrowClick = (e) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  if (isDraggable) {
    drop(ref);
  }

  return (
    <div className={classes.rootFolderItemContainer}>
      <div
        className={clsx(
          classes.rootFolderRowContainer,
          {
            [classes.activeRootFolderRow]: (active),
            [classes.useDrop]: canDrop && isDraggable,
            [classes.isDraggingOver]: isOver && isDraggable,
            [classes.newFolderTitle]: newDesign,
          },
        )}
        onClick={handleSelectFolder}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={ref}
      >
        <IconBriefcase className={classes.projectIcon} />

        <TruncatedText className={classes.title}>{folder.title}</TruncatedText>

        {isShared && <IconShare className={classes.sharedIcon} />}

        <div className={clsx(classes.actionsContainer, { [classes.hidden]: !hover })}>
          {!folder?.home && (
            <FolderOptionsKebab
              variant="sidebar"
              folder={folder}
            />
          )}

          {!!childFolders.length && (
            <IconChevronDown
              onClick={handleArrowClick}
              className={clsx(classes.chevronIcon, { [classes.expandedChevronDownIcon]: !collapsed })}
            />
          )}
        </div>
      </div>

      <Collapse in={!collapsed}>
        <div className={classes.childFoldersContainer}>
          {childFolders.map((f) => (
            <ChildFolderListItemNew
              isDraggable={isDraggable}
              key={f._id}
              folder={f}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default RootFolderListItemNew;
