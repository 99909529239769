import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';

import Footer from '@root/components/Footer';

import useStyles from './ErrorPages.styles';
import ErrorDisplay from './components/ErrorDisplay';

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.contentWrapper}>
      <Helmet>
        <title>Copysmith - Your AI Copywriter</title>
      </Helmet>
      <ErrorDisplay
        title="404 Page Not Found"
        name="Sorry! We're not sure how we ended up here, either. Try heading back to home base."
      />
      <div className={classes.buttonWrap}>
        <Button onClick={() => history.push('/templates')}>Back to Dashboard</Button>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
