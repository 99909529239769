import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';

const Notification = ({
  title, text, type, open, icon, styles, variant,
}) => (
  <Collapse in={open}>
    <Alert
      severity={type}
      icon={icon}
      variant={variant}
      style={styles}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {text}
    </Alert>
  </Collapse>
);

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
};

Notification.defaultProps = {
  title: '',
  variant: 'standard',
};

export default Notification;
