import { useQuery } from 'react-query';

import api from '@root/api';

export default function useGetCatalog(data) {
  return useQuery(
    ['catalog', data.id],
    () => api.catalog.get(data), {
      staleTime: 30 * 1000,
    },
  );
}
