import React, { useRef, useState } from 'react';
import { People } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { IconButton, makeStyles, Popover } from '@material-ui/core';

import Avatar from '@root/components/Avatar';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import AuthContext from '@root/resources/auth/auth.context';
import useFolder from '@root/resources/folder/useFolder';
import useFile from '@root/resources/file/useFile';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import { SHARE_FOLDER_MODAL_ID } from './Modals';

const SHARE_GROUP_TYPES = {
  folder: 'folder',
  file: 'file',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rootIcon: {
    marginRight: theme.spacing(1.5),
  },
  rootAvatar: {
    marginRight: theme.spacing(0.5),
  },
  remainingAvatars: {
    borderRadius: '50%',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: `${theme.spacing(2)}px`,
    fontWeight: 500,
    marginRight: theme.spacing(2),
    backgroundColor: grey[400],
    textAlign: 'center',
  },
  featurePreview: {
    display: 'flex',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
  popperContent: {
    padding: theme.spacing(1),
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    ...theme.typography.body2,
  },
}));

const TeamsPreview = ({ member, classes }) => {
  const anchorEl = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };
  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <div className={classes.root}>
      <div
        ref={anchorEl}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        className={classes.featurePreview}
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <IconButton
          disabled
          className={classes.rootIcon}
          size="small"
        >
          <People />
        </IconButton>
      </div>
      <Avatar
        popperEnabled
        key={member.userId}
        className={classes.rootAvatar}
        name={member.displayName}
        email={member.email}
        userId={member._id}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        anchorEl={anchorEl.current}
        classes={{
          paper: classes.popoverContent,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={openedPopover}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        <div className={classes.popperContent}>
          <div>
            Sharing and collaboration features are available with the Teams plan.
            <div>
              Click
              <Link to="/profile/plans">
                {' here '}
              </Link>
              to view plan types and upgrade.
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const InnerContent = ({
  dispatch,
  classes,
  item,
  itemType,
  teamMembers,
  isFetchingMembers,
  disabled,
  showFeaturePreview,
  visibleAvatarsNumber,
  withTooltips,
  onClick,
}) => {
  const { currentUser } = AuthContext.useAuth();

  let permissions;

  if (itemType === SHARE_GROUP_TYPES.folder) {
    permissions = currentUser.permissions.getFolderPermissions(item);
  }
  if (itemType === SHARE_GROUP_TYPES.file) {
    permissions = currentUser.permissions.getFilePermissions(item);
  }

  const { canViewFolderMembers } = permissions;

  const teamsEnabled = currentUser.permissions.teams;

  let sharableType = itemType === SHARE_GROUP_TYPES.file && 'file';

  if (!sharableType) {
    sharableType = item.parentFolderId ? 'folder' : 'project';
  }

  const tooltipContent = `Share ${sharableType} with team members`;

  const onButtonClick = () => {
    onClick();
    if (!canViewFolderMembers) {
      return;
    }
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: SHARE_FOLDER_MODAL_ID,
        modalContent: {
          ...(itemType === SHARE_GROUP_TYPES.folder ? { folder: item } : { file: item }),
        },
      },
    });
  };

  const enrichedAcl = React.useMemo(() => (!item ? [] : item.unifiedAcl.map((r) => ({
    ...r,
    teamMember: teamMembers?.find((t) => t._id === r.userId),
  })).filter((r) => !!r.teamMember)), [item, teamMembers]);

  const previewAvatars = React.useMemo(() => {
    if (isFetchingMembers) {
      return [];
    }

    const visibleAvatars = enrichedAcl.slice(0, visibleAvatarsNumber);
    const remainingAvatars = enrichedAcl.slice(visibleAvatarsNumber);

    const avatars = visibleAvatars.map((r) => (
      <Avatar
        key={r.userId}
        className={classes.rootAvatar}
        name={r.teamMember.displayName}
        email={r.teamMember.email}
        userId={r.teamMember._id}
        popperEnabled={withTooltips}
      />
    ));
    if (remainingAvatars.length > 0) {
      const avatar = <div className={classes.remainingAvatars}>{`+${remainingAvatars.length}`}</div>;
      avatars.push(withTooltips
        ? (
          <SimpleTooltip
            key="remainingAvatars"
            content={<>{remainingAvatars.map((ra) => <div>{ra.teamMember.displayName}</div>)}</>}
          >
            {avatar}
          </SimpleTooltip>
        ) : avatar);
    }

    return avatars;
  }, [enrichedAcl]);

  if (!teamsEnabled && showFeaturePreview
    && !currentUser.onTeamPlan && !currentUser.onEnterprisePlan) {
    const [member] = teamMembers;
    return (
      <TeamsPreview classes={classes} member={member} />
    );
  }

  if (!item || isFetchingMembers
    || (!teamsEnabled && !currentUser.onTeamPlan && !currentUser.onEnterprisePlan)) {
    return null;
  }

  const button = (
    <IconButton
      disabled={disabled}
      className={classes.rootIcon}
      onClick={onButtonClick}
      size="small"
    >
      <People />
    </IconButton>
  );
  const content = withTooltips ? (
    <>
      <SimpleTooltip content={tooltipContent}>
        {button}
      </SimpleTooltip>
      {previewAvatars}
    </>
  ) : (
    <>
      {button}
      {previewAvatars}
    </>
  );

  return (
    <div className={classes.root}>
      {content}
    </div>
  );
};

const ShareGroup = ({
  itemId,
  type = SHARE_GROUP_TYPES.folder,
  visibleAvatarsNumber = 5,
  showFeaturePreview,
  withTooltips = true,
  onClick = () => {},
}) => {
  const { dispatch } = useGlobalModals();
  const classes = useStyles();
  const useItem = type === SHARE_GROUP_TYPES.folder ? useFolder : useFile;
  const { data: item } = useItem(itemId);

  const { isFetching: isFetchingMembers, data: membersData } = useTeamMembers();
  const teamMembers = membersData?.results || [];

  if (!item || isFetchingMembers || teamMembers.length === 0) {
    return null;
  }

  return (
    <InnerContent
      visibleAvatarsNumber={visibleAvatarsNumber}
      dispatch={dispatch}
      classes={classes}
      item={item}
      itemType={type}
      teamMembers={teamMembers}
      isFetchingMembers={isFetchingMembers}
      disabled={false}
      showFeaturePreview={showFeaturePreview}
      withTooltips={withTooltips}
      onClick={onClick}
    />
  );
};

export { SHARE_GROUP_TYPES };
export default ShareGroup;
