import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import copy from 'clipboard-copy';

import Modal from '@root/components/modals/Modal';
import api from '@root/api';
import authContext from '@root/resources/auth/auth.context';
import uiNotificationService from '@root/services/uiNotification.service';

import CongratulationsLogo from './CongratulationsLogo';
import RewardLogo from './RewardLogo';
import useStyles from './CongratulationsModal.styles';

const CongratulationsModal = ({ onClose }) => {
  const classes = useStyles();

  const [promoCode, setPromoCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = authContext.useAuth();

  const shouldFetchReward = currentUser?.onboarding?.completed
    && currentUser.roles.owner
    && currentUser.onFreePlan;

  const copyCode = async () => {
    await copy(promoCode);
    uiNotificationService.showSuccessMessage('Copied to clipboard!');
  };

  const getOnboardingReward = async () => {
    setIsLoading(true);
    const { code, isBadRequest } = await api.user.getOnboardingReward();
    if (!isBadRequest) {
      setPromoCode(code);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldFetchReward) {
      getOnboardingReward();
    }
  }, []);

  if (isLoading) {
    return null;
  }

  const congratulationsModalContent = (
    <>
      <CongratulationsLogo />
      <div>
        You have completed your onboarding! Now you are ready to use Copysmith like a pro!
      </div>
      <Button
        size="large"
        onClick={onClose}
      >
        Got it!
      </Button>
    </>
  );

  const rewardContent = (
    <>
      <RewardLogo />
      <div>
        <p>You&apos;re a super explorer!</p>
        <p>
          Use the coupon code&nbsp;
          <span className={classes.promoCode}>
            {promoCode}
          </span>
          &nbsp;to get $15 towards your subscription.
        </p>
        <p className={classes.promoCodeInfo}>
          This one-time code will give you $15 off your first purchase of either
          a yearly or monthly Copysmith subscription on the Starter or Professional plan.
          This code is active for 30 days starting today.
        </p>
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={copyCode}
        >
          Copy coupon code
        </Button>
        <Button
          variant="text"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      maxWidth="xs"
      onClose={onClose}
    >
      <div className={classes.modalRoot}>
        <h2 className={classes.title}>
          Congratulations!
        </h2>
        {promoCode ? rewardContent : congratulationsModalContent }
      </div>
    </Modal>
  );
};

export default CongratulationsModal;
