import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  welcomeModalRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 600,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '39px',
    marginTop: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 4,
    maxWidth: 450,
  },
  videoWrapper: {
    marginTop: 32,
    width: 300,
  },
  buttons: {
    marginTop: 48,
    display: 'flex',
    gap: 16,
  },
}));

export default useStyles;
