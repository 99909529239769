import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useDeleteCatalog() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.catalog.delete(data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries('catalogs.search');
        queryClient.invalidateQueries('billing.getLimits');
      },
    },
  );
}
