import React from 'react';

import useStyles from './ProductStatus.styles';

const formatStatus = (status) => {
  if (!status) {
    return 'Unknown';
  }

  return status.replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => { return str.toUpperCase(); });
};

const ProductStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <div className={classes.status}>
      {formatStatus(status)}
    </div>
  );
};

export default ProductStatus;
