import React from 'react';
import { IconEdit } from '@tabler/icons-react';
import { IconButton } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import { RENAME_CATALOG_MODAL } from '../RenameCatalogModal';

import useStyles from './RenameCatalogButton.styles';

const RenameCatalogButton = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleRenameCatalogClick = async () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL,
      payload: { modalId: RENAME_CATALOG_MODAL },
    });
  };

  return (
    <SimpleTooltip
      content="Rename catalog"
      wrapped
      placement="bottom"
    >
      <IconButton
        className={classes.renameCatalogButton}
        onClick={handleRenameCatalogClick}
      >
        <IconEdit className={classes.startIcon} />
      </IconButton>
    </SimpleTooltip>
  );
};

export default RenameCatalogButton;
