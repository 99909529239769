import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import useCreateSelfServeEnterprisePipeline from '@root/resources/billing/useCreateSelfServeEnterprisePipeline';
import Loading from '@root/components/Loading/Loading';
import WizardLayout from '@root/views/Layout/WizardLayout';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';

import TermsOfPlan from './components/TermsOfPlan';
import DocumentSign from './components/DocumentSign';
import useStyles from './SelfServeEnterprise.styles';

const steps = ['Select Plan', 'Terms of Plan', 'Contract & Payment'];

const withProvider = (Component) => (props) => (
  <SubscriptionsContext.SubscriptionsProvider>
    <Component {...props} />
  </SubscriptionsContext.SubscriptionsProvider>
);

const SelfServeEnterprise = withProvider(() => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const { plan: initialPlan } = location.state || {};

  const [plan, setPlan] = React.useState(initialPlan);

  const {
    allPricing,
  } = SubscriptionsContext.useSubscriptions();

  const [activeStep, setActiveStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [documentUrl, setDocumentUrl] = React.useState(null);
  const [pipelineData, setPipelineData] = React.useState({});
  const [proratedWords, setProratedWords] = React.useState(null);

  const { mutateAsync: createSelfServeEnterprisePipeline } = useCreateSelfServeEnterprisePipeline();

  const createPipeline = async (data) => {
    setIsLoading(true);

    setPipelineData(data);

    const resp = await createSelfServeEnterprisePipeline(data);

    const newPlan = allPricing.find((p) => p._id === resp.planId);
    setPlan(newPlan);

    setActiveStep(2);
    setDocumentUrl(resp.url);

    setProratedWords(resp.proratedWords);

    setIsLoading(false);
  };

  const getElement = () => {
    switch (activeStep) {
      case 1:
        return <TermsOfPlan plan={plan} onSubmit={createPipeline} />;
      case 2:
        return (
          <DocumentSign
            pipelineData={pipelineData}
            plan={plan}
            documentUrl={documentUrl}
            proratedWords={proratedWords}
          />
        );
      default:
        return null;
    }
  };

  const onStepChange = (cb) => {
    const newStep = cb(activeStep);
    if (newStep === 0) {
      history.replace('/profile/plans');
      return;
    }
    setActiveStep(newStep);
  };

  if (!plan) {
    history.replace('/');
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <WizardLayout
      steps={steps}
      step={activeStep}
      nextAvailable={false}
      prevAvailable
      onStepChange={onStepChange}
      classes={{ main: classes.root }}
    >
      {getElement()}
    </WizardLayout>
  );
});

export default SelfServeEnterprise;
