// no explicit key, since addSpacing is used flexibly
/* eslint-disable react/no-array-index-key */
import React from 'react';

/**
 * Adds top and bottom margins, and 100% width (default: 10) to every direct child element
 *
 * @param {Object} props - config oject
 * @param {number} props.spacing - ads
 * @param {JSX.Element} props.children - children
 */
const AddSpacing = ({ spacing = 10, children }) => (
  <>
    {children.map((child, key) => (
      <div
        key={key}
        style={{
          marginBottom: spacing,
          marginTop: spacing,
          width: '100%',
        }}
      >
        {child}
      </div>
    ))}
  </>
);

export default AddSpacing;
