import { colors } from '@root/material.theme';

const customStyles = {
  container: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    width: 190,
  }),
  control: (provided, state) => ({
    ...provided,
    background: '#FFFFFF',
    padding: '0px 4px 0 16px',
    boxShadow: 'none',
    cursor: 'pointer',
    border: state.isFocused ? `1px solid ${colors.primary}` : `1px solid ${colors.grey}`,
    '&:hover': {
      border: state.isFocused ? `1px solid ${colors.primary}` : `1px solid ${colors.grey}`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: '#000000',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    background: 'transparent',
    color: state.isSelected ? colors.primary : '#000000',
    '&:hover': {
      background: colors.mediumLightGrey,
    },
    '&:active': {
      background: colors.mediumLightGrey,
    },
  }),
};

export default customStyles;
