import { useQuery, useQueryClient } from 'react-query';

import api from '@root/api';

const getFiles = async (filters) => {
  const { results: allFiles, pageCount } = await api.files.list(filters);

  const files = allFiles.map((f) => {
    return {
      _id: f._id,
      templateType: f.templateType,

      // TODO: remove the mapping
      id: f._id,
      folder: f.folderId,
      title: f.title,
      dateCreated: f.createdOn,
      generationType: f.templateType,
      liked: f.liked,
      lastModified: f.updatedOn,
      generationSize: f.generationSize,
      ...f,
    };
  })
    .filter((f) => !!f);

  return { files, pageCount };
};

const processFiles = async (filters, queryClient) => {
  const { files, pageCount } = await getFiles(filters);

  files.forEach((f) => queryClient.setQueryData(['files.getById', f._id], () => f));

  return { files, pageCount };
};

export default function useFiles(filters) {
  const queryClient = useQueryClient();

  const queryKey = ['files.list', filters];

  return useQuery(
    queryKey,
    () => processFiles(filters, queryClient),
    { keepPreviousData: true },
  );
}
