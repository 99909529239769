import React from 'react';

import { KeywordInputAtom } from '@root/components/Fields';
import AuthContext from '@root/resources/auth/auth.context';

const KeywordsField = ({
  field: {
    name,
    required,
  },
  onChange,
  fieldValue,
  templateType,
  disabled,
}) => {
  const { currentUser, currentCompany } = AuthContext.useAuth();

  const hasFrase = name !== 'negativeKeywordArray';
  const isFraseOn = currentUser.permissions.frase;

  return (
    <KeywordInputAtom
      array={fieldValue}
      setArray={onChange}
      required={required}
      hasFrase={hasFrase}
      isFraseOn={isFraseOn}
      showFraseDefault={currentCompany.billing.status === 'trial'}
      templateType={templateType}
      disabled={disabled}
    />
  );
};

export default KeywordsField;
