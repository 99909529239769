import React from 'react';
import { useParams } from 'react-router-dom';

import useGetCatalogItem from '@root/resources/catalogItem/useGetCatalogItem';

import useStyles from './CatalogItem.styles';

const CatalogItem = () => {
  const { productId: id } = useParams();
  const classes = useStyles();
  const { isLoading, data: listing } = useGetCatalogItem({ id });

  return (
    <div className={classes.page}>
      <div>
        <h1 className={classes.title}>
          Product
          { isLoading ? '' : ` ${listing?.catalogItem.status}`}
        </h1>
      </div>

      <div className={classes.listings}>
        <div>
          <h2 className={classes.sectionTitle}>Product List Details</h2>
          <div>
            <div>Title</div>
            <h3>
              {
                listing && listing?.catalogItem.data?.title
                  ? listing.catalogItem.data.title
                  : 'No Title Provided'
              }
            </h3>
          </div>
          <div>
            <div>Description</div>
            <div>
              {
                listing && listing?.catalogItem.data?.description
                  ? listing.catalogItem.data.description
                  : 'No Description Provided'
              }
            </div>
          </div>
          <div>
            <div>Bullet Points</div>
            <div>
              {
                listing && listing?.catalogItem?.data?.bulletPoints
                  ? listing.catalogItem.data.bulletPoints
                  : 'No Bullet Points Provided'
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogItem;
