import React from 'react';
import { IconDownload } from '@tabler/icons-react';
import { IconButton } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import {
  downloadAsFile,
} from '@root/utils/Export';
import useGetCSV from '@root/resources/catalog/useGetCSV';

import useStyles from './DownloadCSVButton.styles';

const DownloadCSVButton = ({ catalogId, title }) => {
  const classes = useStyles();

  const { mutateAsync: getCSV } = useGetCSV();

  const handleDownloadCSVClick = async () => {
    const response = await getCSV({ id: catalogId });
    if (response.isBadRequest) {
      return;
    }

    downloadAsFile(`${response}`, `${title}.csv`);
  };

  return (
    <SimpleTooltip
      content="Download catalog as CSV"
      wrapped
      placement="bottom"
    >
      <IconButton
        className={classes.downloadCSVButton}
        onClick={handleDownloadCSVClick}
      >
        <IconDownload className={classes.startIcon} />
      </IconButton>
    </SimpleTooltip>
  );
};

export default DownloadCSVButton;
