import React from 'react';

import Button from '@root/components/buttons/Button';
import Modal from '@root/components/modals/Modal';
import { Logo } from '@root/components/SVGIcon';
import useScript from '@root/utils/hooks/useScript';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import useStyles from './WelcomeModal.styles';

const WelcomeModal = ({ onClose }) => {
  const classes = useStyles();
  const { startWalkthroughTour } = onboardingContext.useOnboarding();

  useScript('https://fast.wistia.com/embed/medias/9sd0waq09r.jsonp');
  useScript('https://fast.wistia.com/assets/external/E-v1.js');

  const handleGetStarted = () => {
    startWalkthroughTour();
    onClose();
  };

  return (
    <Modal maxWidth="md">
      <div className={classes.welcomeModalRoot}>
        <Logo />
        <h2 className={classes.title}>
          Welcome to Copysmith!
        </h2>
        <div className={classes.content}>
          <div>
            Watch our quick introductory video to find some best practices on creating
            content using Copysmith! We can&apos;t wait to see what you create!
            You can always reach out for help or review our
            {' '}
            <a
              href="https://describely.notion.site/Getting-Started-with-Copysmith-ec633254d6ac4c44a5f398cabe1ff5f3?pvs=25"
              target="_blank"
              rel="noreferrer"
            >
              Knowledge&nbsp;Base
            </a>
            {' '}
            to see if what business problems we can solve.
          </div>
          <div className={classes.videoWrapper}>
            <div className="wistia_embed wistia_async_9sd0waq09r videoFoam=true" />
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleGetStarted}
            size="large"
          >
            Get started
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
