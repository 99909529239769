import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    justifyContent: 'center',
  },
  iframe: {
    width: '50%',
    border: '1px solid #000000',
    marginRight: theme.spacing(4),
  },
  planDetailsWrap: {
    width: 400,
  },
}));

export default useStyles;
