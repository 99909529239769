import React, { useRef } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

import ProgressButton from '@root/components/ProgressButton';
import fileResource from '@root/resources/file';
import { InputField, FormField } from '@root/components/form';

import googleAdFileResource from '../resources';

import GoogleAdKeywords from './GoogleAdKeywords';

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: '20rem',
    minHeight: '10rem',
  },
  header: {
    fontSize: '1.125rem',
    marginBottom: '1rem',
  },
  inputHeader: {
    fontSize: '0.875rem',
    color: theme.palette.colors.darkGrey,
  },
  input: {
    marginBottom: '0.875rem',
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Ad Group Name is required'),
  keywords: yup
    .string()
    .required('Keywords are required'),
});

const NewGoogleAdGroupDialog = ({
  fileId,
  campaignId,
  customerId,
  managerCustomerId,
  onClose,
}) => {
  const classes = useStyles();
  const { mutateAsync: createAdGroup, isLoading } = googleAdFileResource.useCreateAdGroup();
  const { data: file } = fileResource.useFile(fileId);

  const submitHandler = async (e) => {
    const adGroupId = await createAdGroup({
      customerId,
      managerCustomerId,
      campaignId: campaignId.toString(),
      name: e.name,
      keywords: e.keywords.split('\n'),
    });

    if (adGroupId.isBadRequest) {
      return;
    }

    onClose(adGroupId);
  };

  const formRef = useRef();
  const formik = formRef.current || {};

  const updateKeywords = (newKeywords) => {
    formik.setValues({ name: formik.values.name, keywords: newKeywords });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitle}>
          Set up an ad group
        </div>
      </DialogTitle>
      <Formik
        initialValues={{
          name: (file && file.title) || 'Search-1',
          keywords: '',
        }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        innerRef={formRef}
      >
        {({ values, isValid }) => {
          return (
            <div>
              <DialogContent className={classes.paper}>
                <Form>
                  <div className={classes.container}>
                    <div className={classes.input}>
                      <span className={classes.inputHeader}>Ad group type</span>
                      <div>
                        Standard
                      </div>
                    </div>
                    <div className={classes.input}>
                      <FormField
                        name="name"
                        component={InputField}
                        label="Ad group name"
                        placeholder="Search-1"
                      />
                    </div>
                    <div className={classes.input}>
                      <span className={classes.inputHeader}>Keywords</span>
                      <div>
                        <span className={classes.body}>
                          Find relevant keywords by describing
                          what you’re advertising in this ad group
                        </span>
                        <GoogleAdKeywords
                          updateKeywords={updateKeywords}
                          keywords={values.keywords}
                          customerId={customerId}
                          managerCustomerId={managerCustomerId}
                        />
                        <br />
                        <FormField
                          name="keywords"
                          component={InputField}
                          label="Keywords are words or phrases that are used to match your ads with the terms people are searching for"
                          placeholder="Enter or paste keywords, one word or phrase per line"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="text"
                  onClick={onClose}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <ProgressButton
                  size="small"
                  type="submit"
                  disabled={!isValid}
                  isLoading={isLoading}
                >
                  Create ad group
                </ProgressButton>
              </DialogActions>
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default NewGoogleAdGroupDialog;
