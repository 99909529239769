import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0 0.25rem',
  },
  inlineItem: {
    margin: 0,
  },
  icon: {
    backgroundColor: 'rgba(250, 250, 250, 0.3)',
    borderRadius: '8px',
    marginBottom: '1.35rem',
    width: 64,
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 0.6)',
      color: theme.palette.colors.white,
    },
  },
  title: {
    color: 'white',
    fontSize: '0.75rem',
    marginBottom: '0.85rem',
    '&:hover, .active': {
      fontWeight: 'bold',
      color: 'white',
      textDecoration: 'none',
    },
  },
  inlineTitle: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.colors.primary,
    cursor: 'pointer',
    padding: '9px 12px',
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.colors.primaryFaded,
      color: theme.palette.colors.primary,
    },
  },
  inlineTitleActive: {
    background: theme.palette.colors.primaryFaded,
    color: theme.palette.colors.primary,
  },
  iconActive: {
    filter: 'invert(19%) sepia(82%) saturate(3757%) hue-rotate(246deg) brightness(92%) contrast(97%) opacity(100%)',
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid white',
  },
  itemActive: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  titleActive: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
