import React from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import { FormHelperText } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import FieldLabel from '@root/components/form/FieldLabel';

import useStyles from './PhoneNumberField.styles';

const PhoneNumberField = ({
  label, name, error, labelTooltip, placeholder,
  required, classNames = {},
  value, onChange, onBlur,
  ...props
}) => {
  const classes = useStyles();
  const [, , { setValue }] = useField('countryCode');

  const handleChange = (v, country, e, formattedValue) => {
    if (country?.countryCode) {
      setValue(country.countryCode.toUpperCase());
    }
    return name ? onChange(name)(formattedValue) : onChange(formattedValue);
  };

  return (
    <div className={classNames.root}>
      <FieldLabel
        label={label}
        name={name}
        labelTooltip={labelTooltip}
        required={required}
      />
      <PhoneInput
        country="us"
        id={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur && onBlur(name)}
        value={value}
        inputClass={clsx(classes.input, error && 'error')}
        dropdownClass={classes.dropdown}
        {...props}
      />
      {error && (
        <FormHelperText
          error={!!error}
        >
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default PhoneNumberField;
