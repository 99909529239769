import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },
  editRow: {
    backgroundColor: 'white',
    marginTop: '-0.5rem',
    border: `1px solid ${theme.palette.colors.grey}`,
    borderTopWidth: 0,
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  aSide: {
    flexGrow: 1,
    padding: '1rem',
  },
  actionBar: {
    borderTop: `1px solid ${theme.palette.colors.grey}`,
    padding: '1rem',
    margin: '0 1rem',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  formGroup: {
    position: 'relative',
    marginBottom: '1rem',
  },
}));

export default useStyles;
