import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import FeatureModal from '@root/components/modals/FeatureModal';
import { FEATURES } from '@root/views/Addons/addonsConstants';
import useModalState from '@root/utils/hooks/useModalState';
import useBillingInfo from '@root/resources/billing/useBillingInfo';
import AuthContext from '@root/resources/auth/auth.context';

const useStyles = makeStyles((theme) => ({
  creditsButton: {
    fontSize: 14,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  creditsModalContent: {
    marginBottom: 22,
  },
  hint: {
    maxWidth: 180,
    fontSize: 14,
    color: theme.palette.colors.grey,
    textAlign: 'center',
  },
}));

const AddCreditsButton = () => {
  const classes = useStyles();
  const { data: billingInfoData = { addons: {} } } = useBillingInfo();
  const { currentUser } = AuthContext.useAuth();
  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;

  const [featureType, setFeatureType] = useState(FEATURES.additionalCredits.type);
  const [isAddCreditsOpen, openAddCredits, closeAddCredits] = useModalState(false);

  const handleClose = () => {
    closeAddCredits();
    setFeatureType(FEATURES.additionalCredits.type);
  };

  if (!isAdminOrOwner) {
    return (
      <span className={classes.hint}>Contact your administrator to add more words.</span>
    );
  }

  return (
    <>
      <Button
        variant="link"
        onClick={openAddCredits}
      >
        Add more words
      </Button>

      {isAddCreditsOpen && (
        <FeatureModal
          type={featureType}
          currentQuantity={billingInfoData.addons[featureType]}
          onClose={handleClose}
          title="Add More Words"
        />
      )}
    </>
  );
};

export default AddCreditsButton;
