"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryit = exports.parallel = void 0;
var radash_1 = require("radash");
Object.defineProperty(exports, "parallel", { enumerable: true, get: function () { return radash_1.parallel; } });
/**
 * A helper to try an async function without forking
 * the control flow. Returns an error first callback _like_
 * array response as [Error, result]
 */
const tryit = (func) => {
    return async (...args) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [null, await func(...args)];
        }
        catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [err, null];
        }
    };
};
exports.tryit = tryit;
