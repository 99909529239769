import React, { useState } from 'react';
import {
  Menu, MenuItem, Button, makeStyles,
} from '@material-ui/core';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import clsx from 'clsx';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const defaultAnchorOrigin = {
  vertical: 'center',
  horizontal: 'right',
};
const defaultTransformOrigin = {
  vertical: -32,
  horizontal: 'right',
};

const useStylesMenuItem = makeStyles({
  disabledRoot: {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
});

const MenuButton = ({
  button,
  openOnHover,
  menuItems,
  text,
  className,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = !!anchorEl;
  const menuItemClasses = useStylesMenuItem();

  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const wrapOnClick = (handler) => {
    if (!handler) {
      return null;
    }

    return () => {
      handler();
      handleClose();
    };
  };

  return (
    <div
      onClick={stopEventPropagation}
      onMouseEnter={stopEventPropagation}
      onMouseLeave={stopEventPropagation}
      className={className}
    >
      {button ? (
        React.cloneElement(button, {
          onClick: handleOpen,
          ...openOnHover && {
            onMouseOver: handleOpen,
          },
        })
      ) : (
        <Button
          size="small"
          color="primary"
          onClick={handleOpen}
          endIcon={isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          {text}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={isOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
        MenuListProps={openOnHover && { onMouseLeave: handleClose }}
      >
        {menuItems && menuItems.map(({
          text: itemText, onClick, tooltip, disabled,
        }) => {
          const item = (
            <MenuItem
              onClick={wrapOnClick(disabled ? null : onClick)}
              key={itemText}
              classes={{ root: clsx({ [menuItemClasses.disabledRoot]: disabled }) }}
            >
              {itemText}
            </MenuItem>
          );
          if (tooltip) {
            return (
              <SimpleTooltip
                key={itemText}
                content={tooltip}
              >
                {item}
              </SimpleTooltip>
            );
          }
          return item;
        })}
        {children && children.map((child) => {
          return (
            child && React.cloneElement(child, { onClick: wrapOnClick(child.props.onClick) })
          );
        })}

      </Menu>
    </div>
  );
};

export default MenuButton;
