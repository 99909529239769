import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  engine: {
    fontSize: 12,
    color: theme.palette.colors.primary,
    backgroundColor: theme.palette.colors.primaryFaded,
    padding: '4px 8px',
    borderRadius: 4,
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.colors.white,
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
}));

export default useStyles;
