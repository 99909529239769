import React from 'react';
import { Form, Formik } from 'formik';

import { SwitchField, FormField } from '@root/components/form';
import Button from '@root/components/buttons/Button';

import useStyles from './ProductFieldSelectionForm.styles';

const ProductFieldSelectionForm = ({ fieldsSelected, onSubmit }) => {
  const classes = useStyles();

  const submitFieldSelection = async (values) => {
    await onSubmit(values);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          What fields do you have already to import?
        </div>
        <Formik
          enableReinitialize
          initialValues={fieldsSelected}
          onSubmit={submitFieldSelection}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className={classes.info}>
                  We require a Product Title and a Unique Identifier (sku)
                  but if you have any additional columns in you file that you&lsquo;d
                  like to import you can select them below.
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="description"
                    label="Description"
                    component={SwitchField}
                  />
                </div>

                <div className={classes.formGroup}>
                  <FormField
                    name="descriptionKeywords"
                    label="Description Keywords"
                    component={SwitchField}
                  />
                </div>

                <div className={classes.formGroup}>
                  <FormField
                    name="bulletPoints"
                    label="Bullet Points"
                    component={SwitchField}
                  />
                </div>

                <div className={classes.formGroup}>
                  <FormField
                    name="bulletPointsKeywords"
                    label="Bullet Points Keywords"
                    component={SwitchField}
                  />
                </div>

                <div className={classes.formGroup}>
                  <FormField
                    name="titleKeywords"
                    label="Title Keywords"
                    component={SwitchField}
                  />
                </div>

                <div className={classes.actions}>
                  <Button
                    type="submit"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ProductFieldSelectionForm;
