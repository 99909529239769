import { useQuery } from 'react-query';

import api from '@root/api';

const getTemplateCost = async (data) => {
  const { cost } = await api.ai.getTemplateCost(data);

  return cost;
};

export default function useTemplateCost(data, { enabled } = { enabled: true }) {
  return useQuery(
    ['template.cost', data.templateType],
    () => getTemplateCost(data),
    { keepPreviousData: true, enabled },
  );
}
