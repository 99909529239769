import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  FlagOutlined,
  Flag,
} from '@material-ui/icons';
import clsx from 'clsx';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import FooterButton from './FooterButton';

const text = {
  tooltip: {
    disabled: 'You do not have editing permissions for this shared file.',
    enabled: 'Add to team workflow queue',
  },
};

const useStyles = makeStyles((theme) => ({
  flag: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  flagged: {
    color: theme.palette.secondary.main,
  },
  flaggedDisabled: {
    '&:hover': {
      background: 'none',
      color: theme.palette.secondary.main,
    },
  },
  disabled: {
    '&:hover': {
      background: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

const FlagButton = ({
  isEnabled,
  flagged,
  onClick,
}) => {
  const classes = useStyles();

  if (!flagged) {
    const tooltipContent = isEnabled
      ? text.tooltip.enabled
      : text.tooltip.disabled;

    return (
      <SimpleTooltip
        wrapped
        content={tooltipContent}
      >
        <FooterButton
          Icon={FlagOutlined}
          onClick={isEnabled && onClick}
          className={clsx(classes.flag, !isEnabled && classes.disabled)}
        />
      </SimpleTooltip>
    );
  }

  if (flagged && isEnabled) {
    return (
      <FooterButton
        Icon={Flag}
        onClick={isEnabled && onClick}
        className={clsx(classes.flag, classes.flagged)}
      />
    );
  }

  return (
    <SimpleTooltip
      wrapped
      content={text.tooltip.disabled}
    >
      <FooterButton
        Icon={Flag}
        className={clsx(classes.flag, classes.flaggedDisabled)}
      />
    </SimpleTooltip>
  );
};

export default FlagButton;
