import React from 'react';
import { CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';

import billingResource from '@root/resources/billing';

import ButtonWithTooltip from '../ButtonWithTooltip';

import useStyles from './HintBlock.styles';

const HintBlock = ({ onGenerateMore, onContinue, hasSelected, itemName }) => {
  const classes = useStyles();

  const { data: { limit: creditsLimit } = {} } = billingResource.useLimits();
  const isOutOfCredits = creditsLimit <= 0 && creditsLimit !== null;

  if (hasSelected) {
    return (
      <div className={clsx(classes.hintBlockRoot, hasSelected && classes.selected)}>
        <div className={classes.content}>
          <CheckCircle fontSize="small" />
          <span>{`${itemName} selected`}</span>
        </div>
        <div className={classes.buttonsBlock}>
          <ButtonWithTooltip
            onClick={onGenerateMore}
            className={classes.generateButtonSelected}
            size="small"
            disabled={isOutOfCredits}
            notEnoughCredits={isOutOfCredits}
          >
            Generate More
          </ButtonWithTooltip>
          {hasSelected && (
            <ButtonWithTooltip
              onClick={onContinue}
              className={classes.continueButtonSelected}
              size="small"
              disabled={isOutOfCredits}
              notEnoughCredits={isOutOfCredits}
            >
              Continue
            </ButtonWithTooltip>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.hintBlockRoot}>
      Select only one idea to move forward or explore more options.
      <div className={classes.buttonsBlock}>
        <ButtonWithTooltip
          onClick={onGenerateMore}
          disabled={isOutOfCredits}
          notEnoughCredits={isOutOfCredits}
        >
          Generate More
        </ButtonWithTooltip>
      </div>
    </div>
  );
};

export default HintBlock;
