import React from 'react';
import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './NewsDrawer.styles';

const NewIcon = () => {
  const classes = useStyles();

  return <div className={classes.newIconContainer}> NEW </div>;
};

const oneday = 1000 * 60 * 60 * 24;

const isRecent = (dateString) => {
  const postDate = new Date(dateString).getTime();
  const nowDate = new Date().getTime();
  const dateDiff = (nowDate - postDate) / oneday;
  return dateDiff <= 7;
};

const NewsDrawer = ({ news, isOpen, setIsOpen }) => {
  const classes = useStyles();

  return (
    <Drawer
      onClose={() => setIsOpen(false)}
      anchor="right"
      open={isOpen}
      variant="temporary"
      transitionDuration={{ enter: 200, exit: 100 }}
      classes={{ paper: classes.paper }}
    >
      <div>
        <div className={classes.panelHeader}>
          <div className={classes.panelTitle}>
            What&apos;s new at Copysmith?
          </div>
          <div
            className={classes.closeButton}
            onClick={() => setIsOpen(false)}
          >
            <FontAwesomeIcon style={{ widthL: '100px' }} icon={faTimes} />
          </div>
        </div>
        <div>
          {news?.map((x) => (
            <div className={classes.newsTile} key={x.id}>
              <div className={classes.newTileItem}>
                {isRecent(x.date) ? <NewIcon /> : null}
                <span className={classes.newTileItemDate}>
                  {` ${new Date(x.date * 1000).toDateString()}`}
                </span>
              </div>
              <div className={classes.newsTileTitle}>{x.title}</div>
              <div>{x.content}</div>
              {x.link ? (
                <div className={classes.buttonContainer}>
                  <Button onClick={() => window.open(x.link, '_blank')}>
                    Read more
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => window.location.assign(
              'https://www.copysmith.ai/blog',
            )}
          >
            Read more on the Copysmith Blog
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default NewsDrawer;
