import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  templateFormRoot: {
    display: 'flex',

  },
  wrapper: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400,
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: 22,
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: 10,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 32,
    whiteSpace: 'pre-line',
    '& a': {
      color: theme.palette.colors.primary,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: 8,
    marginTop: 32,
    width: '100%',
    maxWidth: 500,
  },
}));

export default useStyles;
