import { colors } from '@root/material.theme';

const defaultQuality = {
  id: 'default',
  leftColor: colors.mediumLightGrey,
  middleColor: colors.mediumLightGrey,
  rightColor: colors.mediumLightGrey,
};

const getQuality = (option, config) => {
  if (!option || !config) return defaultQuality;
  const qualityOptions = {
    Weak: {
      id: 'Weak',
      description: 'Select more words for the best quality descriptions.',
      leftColor: colors.secondary,
      middleColor: colors.mediumLightGrey,
      rightColor: colors.mediumLightGrey,
      qualityBgColor: 'rgba(241,67,109,0.15)',
      qualityColor: 'rgba(241,67,109,1)',
    },
    Fair: {
      id: 'Fair',
      description: 'Select more words for better generations.',
      leftColor: '#F8C134',
      middleColor: '#F8C134',
      rightColor: colors.mediumLightGrey,
      qualityBgColor: 'rgba(248,193,52,0.15)',
      qualityColor: '#D09600',
    },

    Great: {
      id: 'Great',
      description: 'Great length',
      leftColor: '#46DC00',
      middleColor: '#46DC00',
      rightColor: '#46DC00',
      qualityBgColor: 'rgba(68,214,0,0.15)',
      qualityColor: '#44D600',
    },
    Long: {
      id: 'Character Limit',
      description: `The input text is too long to ${config.label} copy. Select fewer than ${config.excessThreshold} characters to continue.`,
      leftColor: colors.secondary,
      middleColor: colors.secondary,
      rightColor: colors.secondary,
      qualityBgColor: 'rgba(241,67,109,0.15)',
      qualityColor: 'rgba(241,67,109,1)',
    },
    default: {
      id: 'None',
      leftColor: colors.mediumLightGrey,
      middleColor: colors.mediumLightGrey,
      rightColor: colors.mediumLightGrey,
      qualityBgColor: colors.grey,
      qualityColor: '#000000',
      description: 'Highlight text in your piece to start generating content!',
    },
  };

  return qualityOptions[option] || defaultQuality;
};

const checkQuality = (textLength, config) => {
  let option;
  switch (true) {
    case textLength === 0:
      option = 'default';
      break;
    case textLength < config.fairThreshold:
      option = 'Weak';
      break;
    case textLength >= config.fairThreshold && textLength < config.greatThreshold:
      option = 'Fair';
      break;
    case textLength >= config.greatThreshold && textLength <= config.excessThreshold:
      option = 'Great';
      break;
    case textLength > config.excessThreshold:
      option = 'Long';
      break;
    default:
      break;
  }
  return getQuality(option, config);
};

export default checkQuality;
