import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    '&&': {
      width: '100%',
      '&:hover': {
        border: `1px solid ${theme.palette.colors.mediumDarkGrey}`,
      },
      '&:focus': {
        border: `1px solid ${theme.palette.colors.primary}`,
        boxShadow: 'none',
      },
      '&.error': {
        border: `1px solid ${theme.palette.colors.error}`,
      },
    },
  },
  dropdown: {
    '&&': {
      border: `1px solid ${theme.palette.colors.grey}`,
      boxShadow: 'none',
      borderRadius: 4,
      top: -214,
      height: 200,
    },
  },
  optional: {
    fontWeight: 400,
    color: theme.palette.colors.grey,
  },
  required: {
    color: theme.palette.colors.pink400,
  },
}));

export default useStyles;
