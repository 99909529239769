import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    expandedOutlinesRoot: {
      display: 'flex',
      flex: 1,
      minHeight: '100px',
    },
    title: {
      fontSize: 22,
      fontWeight: 600,
      marginBottom: 16,
    },
    description: {
      fontSize: 12,
      color: theme.palette.colors.mediumDarkGrey,
      marginBottom: 24,
    },
    leftSide: {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '32px 32px 0',
    },
    rightSide: {
      backgroundColor: theme.palette.colors.white,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: 32,
      flex: 1,
      borderRadius: 4,
      border: `1px solid ${theme.palette.colors.mediumLightGrey}`,
      overflowY: 'scroll',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    outlines: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    currentOutlineText: {
      fontSize: 18,
      fontWeight: 600,
    },
  });
});

export default useStyles;
