import React from 'react';
import clsx from 'clsx';

import AddTitleAtom from './AddTitleAtom';
import QualityTracking from './QualityTracking';
import useStyles from './DEPRECATEDTextAreaInputAtom.styles';

const DEPRECATEDTextAreaInputAtom = ({
  type = 'text',
  maxLength,
  minLength,
  propObject,
  title,
  style,
  qualitySettings,
  creditCostsThresholds,
  enableEnterKey = false,
  required = false,
  isSmall = false,
  disabled,
  short,
}) => {
  const classes = useStyles();
  const {
    name, onChange, onBlur, placeholder, value = '',
  } = propObject;

  const showQualityTracking = qualitySettings || creditCostsThresholds;

  return (
    <div className={clsx({
      [classes.rootWithQualityTracker]: showQualityTracking,
    })}
    >
      <AddTitleAtom
        required={required}
        title={title}
        style={style}
      >
        <textarea
          name={name}
          className={clsx(classes.generatorInput, {
            [classes.generatorInputSmall]: isSmall,
            [classes.inputWithQualityTracker]: showQualityTracking,
          })}
          type={type || 'text'}
          maxLength={maxLength}
          minLength={minLength}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          wrap={isSmall ? 'off' : 'soft'}
          onKeyPress={(event) => {
            const code = event.which || event.charCode || event.keyCode;
            if (!enableEnterKey && code === 13 && type !== 'textarea') {
              event.preventDefault();
            }
            return code !== 13;
          }}
          disabled={disabled}
        />
      </AddTitleAtom>
      {showQualityTracking && (
        <QualityTracking
          qualitySettings={qualitySettings}
          creditCostsThresholds={creditCostsThresholds}
          length={value.length}
          short={short}
        />
      )}
    </div>
  );
};

export default DEPRECATEDTextAreaInputAtom;
