import React from 'react';
import {
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import pluralize from 'pluralize';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import useCancelJob from '@root/resources/bulkGenerate/useCancelJob';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';

import useStyles from './ProgressBar.styles';

const getText = (job) => {
  if (job.bulkGeneration.state === 'inProgress') {
    return 'Bulk generation in progress.';
  }

  if (job.bulkGeneration.state === 'pending') {
    return `Bulk generation is queued. Start in ${job.minutesToStart ? `${job.minutesToStart} ${pluralize('minute', job.minutesToStart)}` : 'a few seconds'}.`;
  }

  return '';
};

const ProgressBar = ({ job, classNames = {} }) => {
  const classes = useStyles();

  const { mutateAsync: cancelJob } = useCancelJob();
  const { state } = BulkGenerationsContext.useBulkGenerations();

  if (!job) {
    return null;
  }

  const { bulkGeneration: generation } = job;

  if (!generation.rowsCount) {
    return null;
  }
  if (!['pending', 'inProgress'].includes(generation.state)) {
    return null;
  }

  const onCancelJob = async () => {
    await cancelJob({ id: job.bulkGeneration._id });
  };

  const percent = Math.round((generation.result.totalRows / generation.rowsCount) * 100);
  const isCancelProcessing = state.jobToCancel?._id === generation._id;
  return (
    <div className={clsx(classes.progressWrap, classNames.progressWrap)}>
      <div className={classes.progressText}>
        <span>
          {getText(job)}
          &nbsp;
        </span>
        <SimpleTooltip
          content="Click here to cancel an active bulk copy job."
          wrapped
          placement="bottom"
        >
          <Button
            variant="outlined"
            size="small"
            onClick={onCancelJob}
            disabled={isCancelProcessing}
          >
            {isCancelProcessing ? 'Canceling...' : 'Cancel Job'}
          </Button>
        </SimpleTooltip>
      </div>
      <div className={classes.percentBar}>
        <div
          style={{ width: generation.state === 'inProgress' && `${percent}%` }}
          className={clsx({
            [classes.percentBarInner]: generation.state === 'inProgress',
            [classes.percentBarInnerSuccess]: generation.state === 'done',
            [classes.percentBarInnerFailed]: generation.state === 'failed',
            [classes.percentBarInnerCanceled]: generation.state === 'canceled',
          })}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
