import React from 'react';
import { IconPlus } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import { ADD_PRODUCT_MODAL } from '../AddProductModal';

import useStyles from './AddProductButton.styles';

const AddProductButton = ({ productsRemaining }) => {
  const classes = useStyles();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleCreateClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: ADD_PRODUCT_MODAL },
    });
  };

  const getTooltipContent = () => {
    if (productsRemaining <= 0) {
      return 'You do not have any products remaining.';
    }

    return '';
  };

  return (
    <SimpleTooltip
      content={getTooltipContent()}
      wrapped
      placement="bottom"
    >
      <Button
        startIcon={<IconPlus className={classes.startIcon} />}
        className={classes.createNewButton}
        classes={{ startIcon: classes.startIcon }}
        onClick={handleCreateClick}
        disabled={productsRemaining <= 0}
      >
        Add Product
      </Button>
    </SimpleTooltip>
  );
};

export default AddProductButton;
