import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  star: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  gold: {
    color: '#FFD965',
  },
}));

export default useStyles;
