import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  editor: {
    overflowY: 'auto',
    paddingTop: 32,

    '& .cdx-loader': {
      border: 'none',
    },

    '& .ce-block': {
      marginBottom: 24,
      '& .cdx-block': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    '& .ce-conversion-toolbar__label': {
      display: 'none',
    },

    // popovers
    '& .cdx-search-field': {
      display: 'none',
    },
    '& .ce-popover': {
      borderRadius: 6,
      width: 'auto',
      minWidth: 152,
    },
    '& .ce-popover__items': {
      margin: 0,
    },
    '& .ce-popover__item': {
      borderRadius: 4,
      '&:hover': {
        backgroundColor: theme.palette.colors.neutrals50,
      },
    },
    '& .ce-popover__item[data-item-name="move-up"], .ce-popover__item[data-item-name="move-down"]': {
      display: 'none',
    },
    '& .ce-popover__item-icon': {
      boxShadow: 'none',
      background: 'none',
      '& svg': {
        color: theme.palette.colors.neutrals400,
        width: 24,
        height: 24,
      },
    },
    '& .ce-popover__item-label': {
      overflow: 'visible',
    },

    '& .ce-toolbar__actions': {
      paddingRight: 16,
    },

    '& .ce-toolbar__plus, .ce-toolbar__settings-btn': {
      color: theme.palette.colors.neutrals400,

      '&:hover': {
        color: theme.palette.colors.neutrals600,
      },
    },

    '& .ce-toolbar__settings-btn': {
      marginLeft: 4,
    },

    // toolbar
    '& .ce-inline-toolbar': {
      padding: '4px 8px',
      boxShadow: 'none',
      borderColor: theme.palette.colors.neutrals200,
      '& svg': {
        color: theme.palette.colors.neutrals400,
      },
    },
    '& .ce-inline-toolbar__dropdown': {
      margin: 0,
      padding: 0,
      paddingRight: 4,
      borderRight: `1px solid ${theme.palette.colors.neutrals200}`,
      '& .ce-inline-toolbar__dropdown-arrow svg': {
        color: theme.palette.colors.neutrals200,
        width: 14,
        height: 14,
      },
    },
    '& .ce-inline-toolbar__toggler-and-button-wrapper': {
      padding: 0,
    },
    '& .ce-inline-tool': {
      padding: 0,
      margin: '0 4px',
      borderRadius: 4,
      '&:hover': {
        margin: '-2px 2px',
        padding: '0 2px',
        backgroundColor: theme.palette.colors.neutrals50,
      },
      '& svg': {
        width: 24,
        height: 24,
      },
    },
  },
}));

export default useStyles;
