import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch } from '@material-ui/core';

const Switch = ({
  checked,
  onChange,
  onClick,
  ...props
}) => {
  return (
    <MuiSwitch
      color="primary"
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      {...props}
    />
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Switch.defaultProps = {
  onChange: () => {},
  onClick: () => {},
};

export default Switch;
