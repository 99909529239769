import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import templatesContext from '@root/resources/templates/templates.context';
import BlockEditor from '@root/views/BlockEditor';
import File from '@root/views/File/GenericFilePage';
import useFile from '@root/resources/file/useFile';
import Loading from '@root/components/Loading/Loading';
import WorkflowQueueContext from '@root/resources/file/workflowQueue.context';
import uiNotificationService from '@root/services/uiNotification.service';

const FileViewProxy = (props) => {
  const history = useHistory();

  const { slug, id: fileId } = useParams();
  const { templatesList } = templatesContext.useTemplates({ withHidden: true });

  const template = React.useMemo(() => {
    return templatesList.find((t) => t.slug === slug);
  });

  const { data: file, isLoading } = useFile(fileId);

  if (!isLoading && !file) {
    history.replace('/create');
    uiNotificationService.showErrorMessage('File not found');
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  const View = template?.useBlockEditor && !file.useLegacyEditor ? BlockEditor : File;

  return (
    <WorkflowQueueContext.WorkflowQueueProvider file={file}>
      <View {...props} />
    </WorkflowQueueContext.WorkflowQueueProvider>
  );
};

export default FileViewProxy;
