import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import aiTemplateResource from '@root/resources/aiTemplate';
import { useCreateDocumentExample, useDocumentExamples, useRemoveDocumentExample } from '@root/resources/documentExample';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import uiNotificationService from '@root/services/uiNotification.service';

import useStyles from './QualityDiscriminatorTab.styles';

const CreateExampleModal = ({
  templateConfig,
  type,
  create,
  cancel,
}) => {
  const { templateType, title } = templateConfig;
  const [content, setContent] = React.useState({});

  const onChange = (field) => (e) => {
    setContent({
      ...content,
      [field]: e.target.value,
    });
  };

  const createDocumentExample = async () => {
    const response = await create({
      templateType,
      type,
      content,
    });

    if (!response || response.isBadRequest) {
      return;
    }

    cancel();
  };

  return (
    <Dialog open onClose={cancel}>
      <DialogTitle>
        {`New ${type} example for ${title}`}
      </DialogTitle>
      <DialogContent>
        {templateConfig.fields.map((f) => {
          if (f.name === 'negativeKeywordArray') {
            return null;
          }

          return (
            <TextField
              label={f.label}
              variant="outlined"
              multiline={f.uiOptions?.type === 'multilineString'}
              rows={5}
              maxRows={5}
              fullWidth
              value={content[f.label]}
              onChange={onChange(f.label)}
              margin="dense"
            />
          );
        })}
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button onClick={createDocumentExample}>
            Create
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ExamplesTable = ({
  classes,
  examples,
  title,
  removeExample,
}) => {
  const exampleContentMap = React.useMemo(() => {
    const map = {};

    examples.forEach((example) => {
      const content = Object.entries(example.content)
        .reduce((c, [key, value]) => `${c}\n${key}: ${value}`, '').trim().replace(new RegExp('\\r?\\n', 'g'), '<br />');
      map[example._id] = content;
    });

    return map;
  }, [examples]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              {`${title} (${examples.length} total)`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {examples.map((example) => {
            const remove = removeExample(example._id);
            return (
              <TableRow key={example._id}>
                <TableCell className={classes.exampleTextWrap}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: exampleContentMap[example._id] }} />
                  <div>
                    <IconButton
                      size="small"
                      variant="contained"
                      onClick={remove}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const QualityDiscriminatorTab = () => {
  const classes = useStyles();
  const params = useParams();

  const { data: templateConfig = {} } = aiTemplateResource.useAiTemplate(params.id);
  const { data: examples = [] } = useDocumentExamples();

  const {
    mutateAsync: createQualityDiscriminator,
    isLoading: createQualityDiscriminatorIsLoading,
  } = aiTemplateResource.useCreateQualityDiscriminator();
  const {
    mutateAsync: createOrUpdateAiTemplate,
  } = aiTemplateResource.useCreateOrUpdateAiTemplate();
  const { mutateAsync: createDocumentExample } = useCreateDocumentExample();
  const { mutateAsync: removeDocumentExample } = useRemoveDocumentExample();

  const { templateType } = templateConfig;

  const { goodExamples, badExamples } = React.useMemo(() => {
    return {
      goodExamples: examples.filter((e) => e.templateType === templateType && e.type === 'good'),
      badExamples: examples.filter((e) => e.templateType === templateType && e.type === 'bad'),
    };
  }, [examples, templateConfig]);

  const [newExampleType, setNewExampleType] = React.useState(null);

  const createGoodExample = () => setNewExampleType('good');
  const createBadExample = () => setNewExampleType('bad');
  const cancelExampleCreation = () => setNewExampleType(null);

  const removeExample = (id) => () => {
    removeDocumentExample({ id });
  };

  const [showDiscriminatorModal, setShowDiscriminatorModal] = React.useState(false);

  const toggleDiscriminatorModal = () => setShowDiscriminatorModal(!showDiscriminatorModal);

  const createDiscriminator = () => {
    createQualityDiscriminator(templateConfig._id);
    setShowDiscriminatorModal(false);
  };

  const toggleDiscriminator = async () => {
    await createOrUpdateAiTemplate({
      ...templateConfig,
      qualityDiscriminatorEnabled: !templateConfig.qualityDiscriminatorEnabled,
    });

    uiNotificationService.showSuccessMessage(`Discriminator ${!templateConfig.qualityDiscriminatorEnabled ? 'enabled' : 'disabled'}.`);
  };

  return (
    <div>
      {newExampleType && (
        <CreateExampleModal
          templateConfig={templateConfig}
          type={newExampleType}
          create={createDocumentExample}
          cancel={cancelExampleCreation}
        />
      )}
      <ConfirmationModal
        title="Config action"
        text={`Do you really want to create a Quality Discriminator based on ${goodExamples.length} good and ${badExamples.length} bad examples (recommended 1000 bad and 1000 good examples)?`}
        open={showDiscriminatorModal}
        onClose={toggleDiscriminatorModal}
        onCancel={toggleDiscriminatorModal}
        onConfirm={createDiscriminator}
      >
        {createQualityDiscriminatorIsLoading && (
          <CircularProgress value="" />
        )}
      </ConfirmationModal>
      <div className={classes.head}>
        <Button
          disabled={!!templateConfig.qualityDiscriminatorModelId}
          onClick={toggleDiscriminatorModal}
        >
          Create discriminator model
        </Button>
        {templateConfig.qualityDiscriminatorFineTuneId && (
          <>
            <div>
              {`OpenAI fune tune id: ${templateConfig.qualityDiscriminatorFineTuneId}`}
            </div>
            <div>
              {`OpenAI fune tune status: ${templateConfig.qualityDiscriminatorFineTuneStatus}`}
            </div>
            <div>
              {`OpenAI model id: ${templateConfig.qualityDiscriminatorModelId || 'pending...'}`}
            </div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={templateConfig.qualityDiscriminatorEnabled}
                  disabled={!templateConfig.qualityDiscriminatorModelId}
                  onClick={toggleDiscriminator}
                />
              )}
              label="Use quality discriminator"
            />
          </>
        )}
      </div>
      <div className={classes.tablesWrap}>
        <div className={classes.tableWrap}>
          <Button
            onClick={createGoodExample}
            variant="outlined"
            className={classes.createButton}
          >
            Create new good example
          </Button>
          <ExamplesTable
            title="Good Examples"
            examples={goodExamples}
            classes={classes}
            removeExample={removeExample}
          />
        </div>
        <div className={classes.tableWrap}>
          <Button
            onClick={createBadExample}
            variant="outlined"
            className={classes.createButton}
          >
            Create new bad example
          </Button>
          <ExamplesTable
            title="Bad Examples"
            examples={badExamples}
            classes={classes}
            removeExample={removeExample}
          />
        </div>
      </div>
    </div>
  );
};

export default QualityDiscriminatorTab;
