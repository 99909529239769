import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  loader: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    zIndex: '10000',
  },
});

export default useStyles;
