import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface LikeOptions extends RequestOptions {
  ai: {
    liked: boolean | null;
  }
}

export class DocumentImage {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async flag(documentId: string, documentImageId: string, data: LikeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/documents/${documentId}/image/${documentImageId}/flag`, data, options);

    return result;
  }

  public async like(documentId: string, documentImageId: string, data: LikeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/documents/${documentId}/image/${documentImageId}/like`, data, options);

    return result;
  }

  public async save(documentId: string, documentImageId: string, data: LikeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/documents/${documentId}/image/${documentImageId}/save`, data, options);

    return result;
  }

  public async getLatestAiImages(documentId: string, data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/documents/${documentId}/image/latestAiImages`, data, options);

    return result;
  }
}