import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { IconArrowUpRight } from '@tabler/icons-react';

import TruncatedText from '@root/components/TruncatedText';
import Button from '@root/components/buttons/Button';
import PlanStatusBadge from '@root/views/Profile/Billing/components/Sections/AccountSection/PlanStatusBadge';
import AuthContext from '@root/resources/auth/auth.context';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import useSectionsStyles from '../Sections.styles';

import useStyles from './AccountSection.styles';

const PlanWidget = ({ plan, billingData }) => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const { currentUser } = AuthContext.useAuth();
  const history = useHistory();

  const isOwner = currentUser.roles.owner;
  const isCurrentEnterprise = plan?.rank === 500 || currentUser.onSelfServeEnterprisePlan;
  const isLTD = plan && PLANS_IDS.ltds.includes(plan._id) && !plan.productId;
  const isOnTrial = currentUser.onFreePlan;

  return (
    <div className={clsx(sectionClasses.cardContainer, classes.accountCard)}>
      <header className={classes.header}>
        <div className={classes.titleContainer}>
          <TruncatedText className={classes.title}>
            {plan?.name || plan?.internalName}
          </TruncatedText>

          <PlanStatusBadge
            plan={plan}
            billingData={billingData}
          />
        </div>

        {!billingData?.cancelAtPeriodEnd && (
          <span className={classes.price}>
            $
            {billingData?.upcomingInvoicePrice || 0}

            {!isLTD && (
              <span className={classes.billingPeriod}>
                /
                {billingData?.billing.substring(0, 2)}
              </span>
            )}
          </span>
        )}
      </header>

      <span className={classes.description}>{plan?.description || '-'}</span>

      <footer className={classes.footer}>
        <Button
          onClick={() => {
            history.push('/profile/plans');
          }}
          variant="outlined"
          endIcon={<IconArrowUpRight />}
          disabled={!isOwner || isCurrentEnterprise}
        >
          {isOnTrial ? 'Subscribe' : 'Upgrade plan'}
        </Button>
      </footer>
    </div>
  );
};

export default PlanWidget;
