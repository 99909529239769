import React, { useState } from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { BookmarkBorderSharp, BookmarkSharp } from '@material-ui/icons';
import clsx from 'clsx';

import useLikeFile from '@root/resources/file/useLikeFile';
import { useDashboard } from '@root/views/Dashboard/dashboardContext';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.colors.grey,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'relative',
    top: '-2px',
  },
  liked: {
    color: theme.palette.primary.main,
  },
  bookmarkWrap: {
    position: 'relative',
  },
  loadingWrap: {
    position: 'absolute',
    right: '-7px',
    bottom: '-7px',
  },
  buttonWrap: {
    marginLeft: '-3px',
    width: '40px',
    height: '40px',
  },
}));

const BookmarkFile = ({ isDisabled, fileId, liked }) => {
  const { state: { filters } } = useDashboard();
  const classes = useStyles();
  const { isLoading, mutateAsync: toggleLikeFile } = useLikeFile();
  const [localLikedFlag, setlocalLikedFlag] = useState(liked);

  const handleLikeFile = async (event) => {
    event.stopPropagation();
    const response = await toggleLikeFile({ fileId, filters });
    if (response && !response.isBadRequest) {
      setlocalLikedFlag(response.liked);
    }
  };

  const BookmarkIcon = localLikedFlag ? BookmarkSharp : BookmarkBorderSharp;
  return (
    <div className={classes.bookmarkWrap}>
      <IconButton
        onClick={!isDisabled && handleLikeFile}
        className={classes.buttonWrap}
      >
        <BookmarkIcon
          className={clsx(classes.icon, {
            [classes.liked]: localLikedFlag,
          })}
        />
      </IconButton>
      <div className={classes.loadingWrap}>
        {isLoading && <CircularProgress size={10} />}
      </div>
    </div>
  );
};

export default BookmarkFile;
