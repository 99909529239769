import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  childFolderListItemContainer: {
    padding: '4px 8px',
    marginLeft: '2.125rem',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },

  childFolderListItemActive: {
    backgroundColor: theme.palette.colors.neutrals50,
  },

  folderItemWrapper: {
    borderRadius: '6px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    flex: 1,
  },

  folderIcon: {
    flexShrink: 0,
    color: theme.palette.colors.neutrals400,
  },

  sharedIcon: {
    flexShrink: 0,
    color: theme.palette.colors.neutrals200,
  },

  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  actionsContainer: {
    marginLeft: 'auto',
    flexShrink: 0,

    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: theme.palette.colors.neutrals400,
  },

  hidden: {
    display: 'none',
  },

  useDrop: {
    borderRadius: '6px',
    boxSizing: 'border-box',
    outline: '1px solid #666',
  },
  isDraggingOver: {
    background: '#ddd',
    borderColor: 'transparent',
    color: '#000',
  },
}));

export default useStyles;
