import React from 'react';
import { Info as InfoIcon } from '@material-ui/icons';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';
import { getDate, PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import config from '@root/config';

import useStyles from './PlanWarning.styles';

const PlanWarning = () => {
  const {
    setRenewSuspendedAccountModalOpen,
    currentPlan,
  } = SubscriptionsContext.useSubscriptions();

  const classes = useStyles();
  const { currentCompany: company, currentUser } = AuthContext.useAuth();
  const legacyIds = [
    ...PLANS_IDS.legacyStarters,
    ...PLANS_IDS.legacyPros,
    ...PLANS_IDS.legacyTeams,
    ...PLANS_IDS.legacyBasics,
    ...PLANS_IDS.legacyGrowths,
    ...PLANS_IDS.legacyEarlyAdopters,
  ];
  const jan2022StarterAndProIds = [
    ...PLANS_IDS.starters,
    ...PLANS_IDS.pros,
  ];

  const isLegacyPlan = legacyIds.includes(currentPlan._id);
  const isJan2022StarterOrPro = jan2022StarterAndProIds.includes(currentPlan._id);
  const isSuspended = company.suspendStart;
  const legacyAnnual = currentPlan?.period === 'yearly' && [151, 250].includes(currentPlan?.rank);

  return (
    <>
      {isJan2022StarterOrPro && (
        <div className={clsx(classes.jan2022StarterOrProWarning, classes.legacy)}>
          <div>
            <InfoIcon fontSize="small" color="inherit" />
            You&apos;re currently subscribed to a discontinued version of your selected plan type.
          </div>
        </div>
      )}
      {legacyAnnual && (
        <div className={clsx(classes.warningRoot, classes.legacy)}>
          <div>
            <InfoIcon fontSize="small" color="inherit" />
            You&apos;re currently subscribed to annual
            {' '}
            {currentPlan.name}
            {' '}
            plan.
            Please contact our team with any questions about plan type.
          </div>
          <a
            className={classes.linkButton}
            href={`mailto:${config.contactSupportEmail}`}
          >
            Contact our team
          </a>
        </div>
      )}
      {isLegacyPlan && (
        <div className={clsx(classes.warningRoot, classes.legacy)}>
          <div>
            <InfoIcon fontSize="small" color="inherit" />
            You&apos;re currently subscribed to a discontinued version of your selected plan type.
            Please contact our team with any questions about legacy plan types.
          </div>
          <a
            className={classes.linkButton}
            href={`mailto:${config.contactSupportEmail}`}
          >
            Contact our team
          </a>
        </div>
      )}
      {isSuspended && (
        <div className={clsx(classes.warningRoot, classes.suspended)}>
          <div>
            <InfoIcon fontSize="small" color="inherit" />
            {`Plan (${currentPlan.name}) paused until ${getDate(company.suspendEnd)}. `}
            Reactivate to continue your progress
          </div>
          {currentUser.roles.owner && (
            <span
              className={classes.linkButton}
              onClick={() => setRenewSuspendedAccountModalOpen(true)}
            >
              Reactivate subscription
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default PlanWarning;
