import { useQuery } from 'react-query';

import api from '@root/api';

const getPersonalizationRules = async (filters) => {
  const { results: allRules } = await api.ai.listPersonalizationRules(filters);

  return allRules;
};

export default function usePersonalizationRules(filters, { enabled } = { enabled: true }) {
  return useQuery(
    ['personalizationRules.list'],
    () => getPersonalizationRules(filters),
    { keepPreviousData: true, enabled },
  );
}
