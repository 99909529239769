import React from 'react';
import { IconPlus } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import { CREATE_CATALOG_MODAL } from '../CreateModal';

import useStyles from './AddCatalogButton.styles';

const AddCatalogButton = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleCreateClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: CREATE_CATALOG_MODAL },
    });
  };

  return (
    <Button
      startIcon={<IconPlus className={classes.startIcon} />}
      className={classes.createNewButton}
      classes={{ startIcon: classes.startIcon }}
      onClick={handleCreateClick}
    >
      Add New Catalog
    </Button>
  );
};

export default AddCatalogButton;
