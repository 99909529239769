import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tooltip: {
    background: 'none',
    border: 'none',
    transition: 'none !important',
    opacity: 1,
  },
  popper: {
    zIndex: 0,
  },
  action: {
    '& svg': {
      color: theme.palette.colors.primary,
      fill: theme.palette.colors.primary,
    },
  },
  hiddenAction: {
    '& svg': {
      color: theme.palette.colors.primary,
      fill: theme.palette.colors.primary,
    },
  },
  redColor: {
    color: theme.palette.colors.darkRed,
    '& svg': {
      color: theme.palette.colors.red,
      fill: theme.palette.colors.red,
    },
  },
  button: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',

      '& svg': {
        fill: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
  menuItem: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',

      '& svg': {
        fill: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
}));
