import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '@root/components/buttons/Button';
import { useCommunityContext } from '@root/views/Community/context/Community.context';
import { communityActions } from '@root/views/Community/context/communityReducer';
import { templateCategories } from '@root/views/Community/Community.constants';
import authContext from '@root/resources/auth/auth.context';

import useStyles from './EmptyView.styles';

const EmptyView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser: user } = authContext.useAuth();

  const {
    dispatch,
    state: { templatesCategory },
  } = useCommunityContext();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;
  const isCommunityCategory = templatesCategory === templateCategories.COMMUNITY;

  const handleCreateCustomTemplateClick = () => {
    history.push('/template-builder');
  };

  const handleSeeCommunityClick = () => {
    dispatch({ type: communityActions.CHANGE_TEMPLATES_CATEGORY, payload: templateCategories.COMMUNITY });
  };

  return (
    <div className={classes.emptyViewContainer}>
      <p className={classes.title}>
        {isCommunityCategory
          ? 'Oops! You’ve angered the content gods.'
          : 'You haven’t made any templates yet!'}
      </p>

      {!isCommunityCategory && (
        <p className={classes.subtitle}>
          Start creating templates or browse our community.
        </p>
      )}

      {!isCommunityCategory && (
        <div className={classes.buttonsContainer}>
          {isAdminOrOwner && (
            <Button onClick={handleCreateCustomTemplateClick}>
              Create Custom Template
            </Button>
          )}

          <Button
            onClick={handleSeeCommunityClick}
            variant={!isAdminOrOwner ? 'contained' : 'link'}
          >
            See Community Templates
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyView;
