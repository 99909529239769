import { useQuery } from 'react-query';

import api from '@root/api';

const getIntegration = async (type) => {
  const { results: integrations } = await api.integration.list({ type });
  if (integrations.length > 0) {
    const { 0: integration } = integrations;
    if (integration.status === 'active') {
      return integration;
    }
  }
  return null;
};

const useIntegration = (type) => useQuery(['integration', type], () => getIntegration(type));

export default useIntegration;
