import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dashboard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
});

export default useStyles;
