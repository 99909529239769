import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  core: {
    position: 'relative',
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      textDecoration: 'underline',
    },
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: theme.spacing(3),
    top: theme.spacing(1),
    color: (
      { type }: { type: 'error' | 'warning' },
    ) => (type === 'error' ? theme.palette.colors.white : theme.palette.colors.pink600),
  },
  error: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.pink600,
    '& a, svg': {
      color: theme.palette.colors.white,
    },
  },
  warning: {
    color: theme.palette.colors.pink600,
    backgroundColor: theme.palette.colors.pink200,
    '& a, svg': {
      color: theme.palette.colors.pink600,
    },
  },
}));

export default useStyles;
