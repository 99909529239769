import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: 24,
    right: 24,
  },
  icon: {
    color: theme.palette.colors.neutrals300,
  },
}));

const CloseButton = ({ onClick, className }) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={onClick}
      color="default"
      className={clsx(classes.button, className)}
      size="small"
      disableRipple
    >
      <IconX className={classes.icon} />
    </IconButton>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CloseButton;
