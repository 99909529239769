import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import config from '@config';

const enabled = config.env === 'production' && window.analytics;

const baseService = {
  identify: (name, companyName, email, id) => {
    if (!enabled) {
      return;
    }

    window.analytics.identify(id, {
      username: name,
      companyName,
      email,
    });
  },
  track: (name, data) => {
    if (!enabled) {
      return;
    }

    window.analytics.track(name, data);
  },
};

const identify = (name, companyName, email, id) => {
  baseService.identify(name, companyName, email, id);
};

const track = (name, data) => {
  baseService.track(name, data);
};

const init = (user, company) => {
  baseService.identify(user.displayName, company.name, user.email, user._id);
};

// Event Aggregator Subscriptions
eventsAggregator.subscribe(EVENT_NAMES.SUGGESTED_TEMPLATES_VIEW_ALL_TEMPLATES_SELECTED, () => {
  track('Recommended Template Modal Action', {
    action: 'View All Templates Selected',
    templateType: 'N/A',
  });
});

eventsAggregator.subscribe(EVENT_NAMES.SUGGESTED_TEMPLATES_RECOMMENDED_TEMPLATE_SELECTED,
  (data) => {
    const { templateType } = data;
    track('Recommended Template Modal Action', {
      action: 'Recommended Template Selected',
      templateType,
    });
  });

eventsAggregator.subscribe(EVENT_NAMES.SUGGESTED_TEMPLATES_MODAL_CLOSED, () => {
  track('Recommended Template Modal Action', {
    action: 'Modal Closed',
    templateType: 'N/A',
  });
});

eventsAggregator.subscribe(EVENT_NAMES.SIGNUP_FORM_COMPLETED, (_, object) => {
  track('Signup Form Completed', object);
});

eventsAggregator.subscribe(EVENT_NAMES.COMPANY_INFO_FORM_COMPLETED, (_, data) => {
  const { data: companyInfo } = data;
  track('Company Info Form Completed', companyInfo);
});

eventsAggregator.subscribe(EVENT_NAMES.SUBSCRIBE_BUTTON_CLICKED, (_, data) => {
  const { currentPlan, newPlan, timeSinceTrialStart } = data;
  track('Subscribe Button Clicked', {
    currentPlan,
    newPlan,
    timeSinceTrialStart,
  });
});

export default {
  init,
  identify,
};
