import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";

export interface CreateOptions extends RequestOptions {
  refereeEmail: string;
}

export class Referral {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async create(data: CreateOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/referrals", data);

    return result;
  }
}