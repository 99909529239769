import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { CompareArrows } from '@material-ui/icons';

import AuthContext from '@root/resources/auth/auth.context';
import { getFineTunesOptions } from '@root/views/FineTunesEngines/helpers';

import AliField from './components/AliField';
import LanguageField from './components/DEPRECATEDLanguageField';
import FileField from './components/FileField';
import AdditionalSettingsButton from './components/AdditionalSettingsButton';

const BLOCKED_TEMPLATE_TRANSLATION_TYPES = [
  'articleSummary',
  'contentRewriter',
  'contentOptimizer',
  'contentImprover',
  'blogPost',
  'articles',
];

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(5),
  },
  buttonWrapper: {
    marginTop: 16,
  },
  newLanguageSelectSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '10px',
    justifyContent: 'center',
  },
  compareLangArrowsWrapper: {
    marginTop: '35px',
  },
  newLanguageSelectWrapper: {
    width: '100%',
  },
}));

const FileInputFields = ({
  fields,
  data,
  onDataChange,
  templateType,
  showAli = true,
  showLanguage = true,
  isFFSE,
  ffseTemplateType,
  disabled = false,
  short = false,
  fieldClassName = '',
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const formattedEngines = getFineTunesOptions(currentUser);

  const isBlogPostOrArticleWritingAssistant = ['articles', 'blogPost'].includes(templateType);

  const showCustomPromptSettings = currentUser.featureToggles.customPromptSettings
    && !isBlogPostOrArticleWritingAssistant;
  const showAdditionalSettingButton = showCustomPromptSettings && !isFFSE;

  const includeAli = showAli && formattedEngines.length > 0
    && !showCustomPromptSettings;
  const aliValue = includeAli && formattedEngines.find((o) => o.value === data.engine);
  const includeLanguage = showLanguage && !['articles', 'blogPost'].includes(templateType);

  const newTranslationLogicFeatureEnabled = currentUser.featureToggles.newTranslationLogic;
  const newTranslationLogic = newTranslationLogicFeatureEnabled
    && !BLOCKED_TEMPLATE_TRANSLATION_TYPES.includes(templateType);
  const isFfseBlockedType = isFFSE && ffseTemplateType
    && BLOCKED_TEMPLATE_TRANSLATION_TYPES.includes(ffseTemplateType);

  const isCustomTemplate = templateType.includes('custom_');

  const onChange = (e) => {
    onDataChange({
      name: 'engine',
      value: e.value,
    });
  };

  const onLanguageChange = (e) => {
    onDataChange({
      name: 'language',
      value: e.value,
    });
  };

  const onOutputLanguageChange = (e) => {
    onDataChange({
      name: 'outputLanguage',
      value: e.value,
    });
  };

  const filedClasses = React.useMemo(() => clsx(classes.field, fieldClassName), [fieldClassName]);

  const AliInput = () => (
    <AliField
      formattedEngines={formattedEngines}
      onChange={onChange}
      fieldValue={aliValue || 'default'}
      templateType={templateType}
      className={filedClasses}
    />
  );

  return (
    <>
      {includeAli && !includeLanguage && <AliInput />}
      {includeLanguage && (
        <div className={filedClasses}>
          {(!newTranslationLogic || isFfseBlockedType) && (
            <LanguageField
              fieldValue={data.language || 'en'}
              onChange={onLanguageChange}
              disabled={disabled}
            />
          )}
          {newTranslationLogic && !isFfseBlockedType && !isCustomTemplate && (
            <div className={classes.newLanguageSelectSection}>
              <div className={classes.newLanguageSelectWrapper}>
                <LanguageField
                  fieldValue={data.language || 'en'}
                  onChange={onLanguageChange}
                  customLabel="Input Language"
                  disabled={disabled}
                />
              </div>
              <div className={classes.compareLangArrowsWrapper}>
                <CompareArrows />
              </div>
              <div className={classes.newLanguageSelectWrapper}>
                <LanguageField
                  fieldValue={data.outputLanguage || data.language || 'en'}
                  onChange={onOutputLanguageChange}
                  customLabel="Output Language"
                  disabled={disabled}
                />
              </div>
            </div>
          )}
          {includeAli && !isCustomTemplate && (
            <AliInput />
          )}
        </div>
      )}

      {showAdditionalSettingButton && !isCustomTemplate && (
        <div className={classes.buttonWrapper}>
          <AdditionalSettingsButton
            templateType={templateType}
            onChange={onDataChange}
            initialValues={data.aiOptions}
          />
        </div>
      )}

      {fields.map((field) => {
        const fieldValue = data[field.name];
        if (field.name === 'language') {
          return null;
        }

        return (
          <FileField
            key={field.name}
            field={field}
            fieldValue={fieldValue}
            templateType={templateType}
            onDataChange={onDataChange}
            className={filedClasses}
            disabled={disabled}
            short={short}
          />
        );
      })}
    </>
  );
};

export default FileInputFields;
