import React from 'react';
import clsx from 'clsx';

import useStyles from './GuidingTooltip.styles';

const GuidingTooltip = ({
  left, top, visible, direction, fontSize, backgroundColor, width, children, right,
}) => {
  const styles = useStyles();
  return (
    <div
      className={clsx({ [styles.invisible]: !visible }, { [styles.right]: direction === 'right' }, styles.root)}
      style={{
        left, top, width, fontSize, backgroundColor, right,
      }}
    >
      {children}
    </div>
  );
};

export default GuidingTooltip;
