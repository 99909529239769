import React from 'react';
import { Tooltip } from '@material-ui/core';

import useStyles from './SimpleTooltip.styles';

const SimpleTooltip = ({
  content,
  placement,
  open,
  interactive,
  leaveDelay,
  onClose,
  children,
  wrapped,
  dark = true,
  disableHoverListener,
  classes: customClasses,
  id,
}) => {
  const defaultClasses = useStyles();
  const classes = customClasses || defaultClasses;
  const component = wrapped ? <span className={classes.wrapper}>{children}</span> : children;

  if (!content) {
    return component;
  }

  // prevents accidental clicking on an item under the tooltip
  const handleTooltipContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Tooltip
      classes={(dark || customClasses) && {
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={(
        <div
          onClick={handleTooltipContentClick}
          className={classes.tooltipContentWrapper}
        >
          {content}
        </div>
      )}
      placement={placement}
      leaveDelay={leaveDelay}
      onClose={onClose}
      interactive={interactive}
      arrow
      open={open}
      disableHoverListener={disableHoverListener}
      id={id}
    >
      {component}
    </Tooltip>
  );
};

export default SimpleTooltip;
