export const templateCategories = Object.freeze({
  COMMUNITY: 'community',
  MY_TEMPLATES: 'myTemplates',
});

export const templatesCategoriesOptions = [
  {
    value: templateCategories.MY_TEMPLATES,
    label: 'My Templates',
  },
  {
    value: templateCategories.COMMUNITY,
    label: 'Community',
  },
];

export const viewTypes = Object.freeze({
  LIST: 'LIST',
  CARD: 'CARD',
});
