import { makeStyles } from '@material-ui/core';

import backgroundPink from './backgroundPink.svg';
import backgroundBlue from './backgroundBlue.svg';
import backgroundPurple from './backgroundPurple.svg';

const useStyles = makeStyles(() => ({
  statsRoot: {
    display: 'flex',
    gap: 16,
    height: 315,
  },
  pink: {
    backgroundImage: `url(${backgroundPink})`,
  },
  blue: {
    backgroundImage: `url(${backgroundBlue})`,
  },
  purple: {
    backgroundImage: `url(${backgroundPurple})`,
  },
  cardContentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    gap: 8,
  },
  useCaseWrapper: {
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
  },
  totalWordsWrapper: {
    justifyContent: 'flex-end',
  },
  daysWrapper: {
  },
  value: {
    display: 'block',
    fontSize: 36,
    lineHeight: '40px',
    fontWeight: 700,
  },
}));

export default useStyles;
