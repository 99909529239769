import React from 'react';

const Steps = () => {
  return (
    <svg
      width="32"
      height="181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="14.5"
        fill="#fff"
        stroke="#000"
        strokeWidth="3"
      />
      <path d="M17.923 10.364h-2.005l-2.892 1.858v1.931l2.721-1.738h.068V22h2.108V10.364Z" fill="#000" />
      <circle
        cx="16"
        cy="165"
        r="14.5"
        fill="#fff"
        stroke="#000"
        strokeWidth="3"
      />
      <path d="M12.065 171h7.978v-1.761h-5.069v-.08l2.006-2.04c2.261-2.17 2.886-3.227 2.886-4.539 0-1.949-1.585-3.375-3.926-3.375-2.306 0-3.943 1.431-3.943 3.642h2.006c0-1.188.75-1.932 1.909-1.932 1.108 0 1.932.676 1.932 1.773 0 .971-.591 1.664-1.739 2.829l-4.04 3.96V171Z" fill="#000" />
      <path
        stroke="#000"
        strokeWidth="2"
        d="M17 30v120"
      />
    </svg>
  );
};

export default Steps;
