import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey.main}`,

    padding: '20px 22px',

    display: 'flex',
    flexDirection: 'column',

  },
  withHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.colors.lightGrey,
    },
  },

  counters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    marginBottom: '20px',
  },

  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',

    padding: '4px 8px',
    borderRadius: '4px',

    lineHeight: '18px',

    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.faded,

    '&.secondary': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.error.faded,
    },

    '& span': {
      fontSize: '12px',
    },
  },

  counterIcon: {
    fontSize: '16px',
  },

  templateInfo: {
    marginBottom: '24px',
    flex: '1',
  },

  templateName: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '700',
    marginBottom: '8px',
    flexGrow: '0',

  },

  templateDescription: {
    fontSize: '12px',
    lineHeight: '18px',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
