const filterTemplatesByBucket = (templates, bucket) => {
  return templates.filter((item) => {
    // Removes items w/out buckets and then items with our bucket.
    return item.buckets && item.buckets.find((b) => b.bucket === bucket);
  }).map((item) => {
    // Ads the bucket info to a item.
    const bucketInfo = item.buckets.find((b) => b.bucket === bucket);

    return {
      ...item,
      order: bucketInfo.order,
      bucket: bucketInfo.bucket,
    };
  }).sort((item1, item2) => {
    return item1.order - item2.order;
  });
};

export default filterTemplatesByBucket;
