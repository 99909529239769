import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import config from '@root/config';
import { colors } from '@root/material.theme';

import ErrorLogo from './ErrorLogo';
import GoBackButton from './GoBackButton';

const isNotProd = config.env !== 'production';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  textContent: {
    margin: '16px 0',
    textAlign: 'center',
  },
  details: {
    whiteSpace: 'pre-line',
    textAlign: 'left',

    '& summary': {
      textAlign: 'center',
      marginBottom: 16,
      fontWeight: 500,
    },
  },
  error: {
    color: colors.red,
    fontWeight: 600,
    textDecoration: 'underline',
  },
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    // todo: log error
    console.error(error);
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      return (
        <div className={classes.root}>
          <ErrorLogo />
          <div className={classes.textContent}>
            <h2>Oops! You’ve angered the content gods.</h2>
            <p>Go back and try again.</p>
            {isNotProd && (
              <details open className={classes.details}>
                <summary>Error stack trace (staging only)</summary>
                <span className={classes.error}>{error.toString()}</span>
                {errorInfo.componentStack}
              </details>
            )}
          </div>
          <GoBackButton />
        </div>
      );
    }

    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
