import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { formatCurrency } from '@root/resources/billing/billingInfo.helpers';
import uiService from '@root/services/uiNotification.service';
import api from '@root/api';
import { InputField } from '@root/components/form';

import useStyles from './PromotionCode.styles';

const PromotionCode = ({
  promotion, onClear, onApply, discount, planId,
}) => {
  const classes = useStyles();

  const [promo, setPromo] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setPromo(e.target.value);
  };

  const validatePromo = async () => {
    setError('');

    if (!promo) {
      return;
    }

    try {
      const promoData = await api.billing.validatePromo({ promo, planId });
      if (promoData.isValid) {
        onApply(promoData);
      } else {
        setError(promoData.error);
      }
    } catch (e) {
      uiService.showErrorMessage('Something went wrong. Please try again');
    }
  };

  if (promotion) {
    return (
      <>
        <div className={classes.promoRow}>
          <div className={classes.promoCode}>
            {promotion.code}
            <IconButton
              onClick={onClear}
              aria-label="delete"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <span className={classes.discount}>{`-${formatCurrency(discount)}`}</span>
        </div>
        <div className={classes.promoDescription}>{promotion.name}</div>
      </>
    );
  }

  return (
    <div className={classes.promoBlock}>
      <InputField
        type="text"
        placeholder="Promotion code"
        onChange={handleChange}
        error={error}
      />
      <Button
        onClick={validatePromo}
        variant="outlined"
        color="default"
        className={classes.button}
      >
        Apply
      </Button>
    </div>
  );
};

export default PromotionCode;
