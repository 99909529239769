import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  keywordRoot: {
    padding: '4px 8px',
    background: ({ color, selected }) => (selected ? color.fill : theme.palette.colors.white),
    fontWeight: 500,
    fontSize: 12,
    border: ({ color, selected }) => `1px solid ${selected ? color.fill : color.text}`,
    borderRadius: 6,
    color: ({ color, selected }) => (selected ? theme.palette.colors.white : color.text),
    cursor: ({ disabled }) => !disabled && 'pointer',
  },
}));

export default useStyles;
