import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DateTime } from 'luxon';

import { SelectField } from '@root/components/form';
import useWordsUsage from '@root/resources/usage/useWordsUsage';

import useSectionStyles from '../Sections.styles';

import useStyles from './WordsUsageChart.styles';
import { chartOptions, dateRangeOptionToSettings, dateRangeOptions } from './WordsUsageChart.constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

interface GenerationsUsage extends ChartData<'line'> {
  creditsUsed: string;
}

type Props = {
  isOverageSectionVisible: boolean;
};

const TemplatesUsageSection:FC<Props> = ({ isOverageSectionVisible }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const [startDate, setStartDate] = useState(dateRangeOptionToSettings.thisWeek.startDate);
  const [endDate, setEndDate] = useState(dateRangeOptionToSettings.thisWeek.endDate);

  const {
    data: generationsUsage,
    isFetching: isGenerationsUsageFetching,
  } = useWordsUsage<GenerationsUsage>({
    startDate,
    endDate,
    timezone: DateTime.now().zoneName,
  });

  const getDatasets = () => {
    if (!generationsUsage) {
      return [];
    }

    const dataSets = generationsUsage.datasets.map((item) => {
      return {
        ...item,
        borderColor: '#9F9EAB',
        borderWidth: 2,
        fill: true,
        backgroundColor: 'rgba(0,0,0,0.1)',
        pointRadius: 0,
        pointHitRadius: 10,
        pointBackgroundColor: '#9F9EAB',
      };
    });

    // sum datasets with same label
    const dataSetsSum = dataSets.reduce((acc: any, curr: any) => {
      const found = acc.find((item: any) => item.label === curr.label);

      if (found) {
        found.data = found.data.map((item: any, index: number) => item + curr.data[index]);
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    return dataSetsSum;
  };

  const handleDateRangeChange = (e: any) => {
    setStartDate(dateRangeOptionToSettings[e.target.value].startDate);
    setEndDate(dateRangeOptionToSettings[e.target.value].endDate);
  };

  return (
    <Grid
      item
      md={12}
      sm={isOverageSectionVisible ? 8 : 12}
      xs={12}
    >
      <div className={clsx(sectionClasses.sectionContainer)}>
        {/* @ts-expect-error SelectField is not .ts yes */}
        <SelectField
          onChange={handleDateRangeChange}
          options={dateRangeOptions}
          defaultValue={dateRangeOptions[0].value}
          style={{ maxWidth: 150 }}
        />

        <div style={{ height: 200 }}>
          {isGenerationsUsageFetching && (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}

          {!isGenerationsUsageFetching && (
            <Line
              options={chartOptions}
              data={{
                labels: generationsUsage?.labels,
                datasets: getDatasets(),
              }}
            />
          )}
        </div>
      </div>
    </Grid>
  );
};

export default TemplatesUsageSection;
