const escapeText = (text) => text
  .replace('\r\n', '\n')
  .replace(/<p>/gm, '')
  .replace(/<\/p>/gm, '\n')
  .replace(/<\/?[a-z]+>/gm, '')
  .replace(/ {2,}/gm, ' ')
  .replace(/\n |\n{2,}/gm, '\n')
  .trim();

const findSelectedDomNode = (selection, nodes) => {
  if (!selection) {
    return null;
  }

  const { anchorNode, focusNode } = selection;

  const startNode = nodes.find((n) => n.contains(anchorNode));
  if (startNode) {
    return startNode;
  }

  const endNode = nodes.find((n) => n.contains(focusNode));
  if (endNode) {
    return endNode;
  }

  return null;
};

const TEMPLATE_SEARCH_REPLACEMENT_RULES = [
  [/blog(\spo?s?t?s?)?/gm, 'blog'],
];

const handleTemplateSearch = (search) => TEMPLATE_SEARCH_REPLACEMENT_RULES
  .reduce((s, [from, to]) => s.replace(from, to), search.toLowerCase());

export {
  escapeText,
  findSelectedDomNode,
  handleTemplateSearch,
};
