import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.colors.white,
    backgroundPosition: '95% 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    padding: '36px',
  },
  title: {
    textAlign: 'start',
  },
}));

export default useStyles;
