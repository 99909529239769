import React from 'react';

import MainLayout from '@root/views/Layout';
import TeamManagementLayout, { ACTIVE_TAB } from '@root/views/Team/TeamManagementLayout';
import EditTeamDetails from '@root/views/Team/components/EditTeamDetails';

const TeamDetails = () => {
  return (
    <MainLayout>
      <TeamManagementLayout activeTab={ACTIVE_TAB.DETAILS}>
        <EditTeamDetails />
      </TeamManagementLayout>
    </MainLayout>
  );
};

export default TeamDetails;
