import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

type SearchCatalogItemType = { 
  searchQuery?: string,
  catalogId: string,
} & RequestOptions;
type GetCatalogItemType = { id: string } & RequestOptions;
type UpdateCatalogItemType = { id: string, title: string } & RequestOptions;
type CreateCatalogItemType = { url?: string, catalogId: string } & RequestOptions;
type ImportCatalogItemsType = { catalogId: string, fileId: string, fileMapping: Record<string, string>} & RequestOptions;
type DeleteCatalogItemsType = { catalogId: string, catalogItemIds: string[]} & RequestOptions;

export class CatalogItem {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async search(data: SearchCatalogItemType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/catalog-item/", data, options);

    return result;
  }

  public async get(data: GetCatalogItemType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/catalog-item/${data.id}`, data, options);

    return result;
  }

  public async create(data: CreateCatalogItemType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/catalog-item", data, options);

    return result;
  }
  
  public async update(data: UpdateCatalogItemType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/catalog-item/${data.id}`, data, options);

    return result;
  }

  public async import(data: ImportCatalogItemsType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/catalog-item/import", data, options);

    return result;
  }

  public async delete(data: DeleteCatalogItemsType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del("/catalog-item", data, options);

    return result;
  }
}