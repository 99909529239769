import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ProgressButton from '@root/components/ProgressButton';
import aiResource from '@root/resources/ai';

import useStyles from './PromptUserFieldsForm.styles';
import PromptField from './PromptField';

const getValidationSchema = (fields) => {
  const obj = {};
  fields.forEach((f) => {
    if (f.required) {
      obj[f.name] = yup
        .string(`Type ${f.name}`)
        .required(`${f.name} is required`);
    }
  });

  return yup.object(obj);
};

const PromptUserFieldsForm = ({
  template,
  onGenerated,
  promptsCount,
}) => {
  const classes = useStyles();

  const fields = React.useMemo(() => {
    return template.fields.filter((f) => f.showOnUi);
  }, [template]);

  const isTemplateLive = template.status === 'live';
  const isRunExperimentsDisabled = template.instructions
    ? !template.instructions.default
    : promptsCount < 2;

  const { mutateAsync: generateNow, isLoading } = aiResource.useGenerateContentNow();

  const onSubmit = async (values) => {
    const keywordFields = template.fields
      .filter((f) => f?.uiOptions?.type === 'keywords')
      .map((f) => f.name);
    const requestValues = {
      ...values,
    };
    Object.keys(values).forEach((key) => {
      if (keywordFields.includes(key)) {
        requestValues[key] = (requestValues[key] || '').split(',');
      }
    });

    const { results, isBadRequest } = await generateNow({
      templateType: template.templateType,
      negativeKeywordArray: requestValues.negativeKeywordArray || [],
      data: requestValues,
      isSuperAdminPanel: true,
    });
    if (isBadRequest) {
      return;
    }
    onGenerated(results, requestValues);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: getValidationSchema(fields),
    onSubmit,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          {fields.map((field) => {
            return (
              <Grid
                key={field.name}
                item
                className={classes.cell}
                sm={12}
                xs={12}
              >
                <PromptField
                  formik={formik}
                  field={field}
                  className={classes.input}
                />
              </Grid>
            );
          })}
        </Grid>
      </form>
      <div>
        {!isTemplateLive && (
          <div>
            {`Template must be Live to run experiments
            (don't worry in only shown to customers when it live AND public).`}
          </div>
        )}
        <ProgressButton
          size="small"
          color="primary"
          disabled={isRunExperimentsDisabled}
          onClick={formik.handleSubmit}
          isLoading={isLoading}
          variant="outlined"
        >
          Run experiment
        </ProgressButton>
      </div>
    </div>
  );
};

export default PromptUserFieldsForm;
