import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M17.333 14c.369 0 .667.299.667.667v2.666a.667.667 0 0 1-.667.667h-2.666a.667.667 0 0 1 0-1.333h2v-2c0-.368.298-.667.666-.667Z" />
      <path d="M12.862 12.862c.26-.26.682-.26.943 0l4 4a.667.667 0 1 1-.943.943l-4-4a.667.667 0 0 1 0-.943ZM6 6.667C6 6.298 6.298 6 6.667 6h2.666a.667.667 0 0 1 0 1.333h-2v2a.667.667 0 1 1-1.333 0V6.667Z" />
      <path d="M6.195 6.195c.26-.26.683-.26.943 0l4 4a.667.667 0 0 1-.943.943l-4-4a.667.667 0 0 1 0-.943Z" />
    </svg>
  );
};
