import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useDeleteDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id }) => api.documents.delete(_id),
    {
      onMutate: async ({ _id, fileId, batch }) => {
        const queryKey = ['documents.list', { fileId }];

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const previousDocuments = queryClient.getQueryData(queryKey);

        if (!previousDocuments) {
          return null;
        }

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, () => previousDocuments.filter((d) => d._id !== _id));

        // Optimistically update single deletion, invalidate for batch deletion
        if (batch) {
          queryClient.invalidateQueries('files.list');
        } else {
          queryClient.getQueryCache().findAll('files.list').forEach(({ queryKey: key, state }) => {
            queryClient.setQueryData(key,
              () => ({
                ...state.data,
                files: state.data.files.map((file) => (
                  file._id === fileId
                    ? { ...file, generationSize: file.generationSize - 1 } : file)),
              }));
          });
        }

        return { previousDocuments, queryKey };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(context.queryKey, context.previousDocuments);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('documents.getSharedDocuments');
      },
    },
  );
}
