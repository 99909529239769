import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    maxWidth: 816,
  },
  section: {
    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,
    paddingBottom: 24,
    marginBottom: 8,
    '& button': {
      minWidth: 130,
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '40px',
    marginBottom: 4,
  },
  text: {
    marginBottom: 24,
  },
  greyText: {
    color: theme.palette.colors.neutrals400,
  },
  buttonsBlock: {
    display: 'flex',
    gap: 16,
    justifyContent: 'flex-end',
    margin: '24px 0 8px',
  },
  getStartedButton: {
    height: '40px',
    width: '450px',
  },
  productRow: {
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    justifyContent: 'space-between',
  },
  productRowText: {
    marginBottom: 0,
    width: '1200px',
  },
  brandLogo: {
    height: '50px',
    width: '50px',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

export default useStyles;
