import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  status: {
    borderRadius: '4px',
    display: 'inline',
    padding: '0.25rem 0.4rem',
    backgroundColor: '#CEFDE1',
    fontSize: '0.8rem',
  },
}));

export default useStyles;
