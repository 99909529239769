import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 7),
    backgroundColor: theme.palette.colors.neutrals50,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...theme.typography.h2,
    textAlign: 'left !important',
    marginBottom: theme.spacing(2),

    '&:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
}));

export default useStyles;
