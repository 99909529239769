import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  plansRoot: {
    maxWidth: 1350,
    alignSelf: 'center',
    fontSize: 14,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
  },
  pageInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    marginBottom: 32,
    textAlign: 'center',
  },
  pageTitle: {
    fontFamily: 'Manrope',
    color: theme.palette.colors.primary,
    fontSize: 48,
    fontWeight: 800,
    lineHeight: '72px',
    letterSpacing: -2,
    margin: 0,
  },
  annualDiscount: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.black,
    borderRadius: 50,
    padding: '1px 6px',
    margin: '0 6px',
  },
  scrollDownButton: {
    textDecoration: 'underline',
    margin: '24px 0',
    alignSelf: 'center',
  },
  disclaimer: {
    paddingTop: '10px',
  },
  upgradeCTA: {
    backgroundColor: theme.palette.colors.green200,
    border: `1px solid ${theme.palette.colors.green400}`,
    borderRadius: '6px',
    color: theme.palette.green600,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    gap: '12px',
    padding: '20px',
  },
}));

export default useStyles;
