import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1.5rem',
  },
  submit: {
    marginLeft: '0.5rem',
  },
}));

export default useStyles;
