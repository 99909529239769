import React from 'react';

import DEPRECATEDTranslateSelect from '@root/components/DEPRECATEDTranslateSelect';
import languages from '@root/utils/allLanguages';

const DEPRECATEDLanguageField = ({
  fieldValue,
  onChange,
  customLabel,
}) => {
  const lang = languages.find((l) => l.code === fieldValue);
  return (
    <DEPRECATEDTranslateSelect
      value={{ value: lang.code, label: lang.name }}
      onChange={onChange}
      label={customLabel || 'Language'}
    />
  );
};

export default DEPRECATEDLanguageField;
