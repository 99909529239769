import React, { useEffect, useMemo, useState } from 'react';
import {
  CompareArrows,
} from '@material-ui/icons';
import {
  MenuItem, TextField,
} from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import AuthContext from '@root/resources/auth/auth.context';
import languages from '@root/utils/allLanguages';

import PromptSettings from './PromptSettings';
import useStyles from './PromptSettingsForm.styles';

const LanguageField = ({
  classes,
  value,
  onChange,
  label,
}) => (
  <TextField
    className={classes.select}
    variant="outlined"
    select
    value={value}
    onChange={onChange}
    size="small"
    margin="dense"
    SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, getContentAnchorEl: null } }}
    label={label}
  >
    {languages.map((l) => (
      <MenuItem
        className={classes.menuItem}
        key={l.code}
        value={l.code}
      >
        {l.name}
      </MenuItem>
    ))}
  </TextField>
);

const PromptSettingsForm = ({ onSubmit, templateConfig, aiOptions, lang, outputLang }) => {
  const classes = useStyles();

  const isOutOfCredits = false;
  const [aiValues, setAiValues] = useState(aiOptions);
  const [selectedLanguage, setSelectedLanguage] = useState(lang);
  const [selectedOutputLanguage, setSelectedOutputLanguage] = useState(outputLang);

  useEffect(() => {
    setAiValues(aiOptions);
    setSelectedLanguage(lang);
    setSelectedOutputLanguage(outputLang);
  }, [aiOptions, lang, outputLang]);

  const { maxAiOptions, minAiOptions } = useMemo(() => ({
    minAiOptions: templateConfig.bulkCustomOptions?.minN || 1,
    maxAiOptions: templateConfig.bulkCustomOptions?.maxN || 3,
  }), [templateConfig]);

  const { currentUser } = AuthContext.useAuth();

  const newTranslationEnabled = currentUser.featureToggles.newTranslationLogic;

  const handleAiChange = (changes) => {
    setAiValues({ ...aiValues, [changes.name]: changes.value });
  };

  const onLanguageSelect = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const onOutputLanguageSelect = (e) => {
    setSelectedOutputLanguage(e.target.value);
  };

  return (
    <div className={classes.root}>

      <div className={classes.wrapper}>
        <div className={classes.title}>
          Settings
        </div>
        <div className={classes.description}>
          Please provide general information about your template.
        </div>

        <div className={classes.form}>
          {/* Languages select */}
          <div className={classes.params}>
            {newTranslationEnabled ? (
              <>
                <LanguageField
                  classes={classes}
                  onChange={onLanguageSelect}
                  value={selectedLanguage}
                  label="Input Language"
                />
                <CompareArrows />
                <LanguageField
                  classes={classes}
                  onChange={onOutputLanguageSelect}
                  value={selectedOutputLanguage}
                  label="Output Language"
                />
              </>
            ) : (
              <LanguageField
                classes={classes}
                onChange={onLanguageSelect}
                value={selectedLanguage}
              />
            )}
          </div>
          <PromptSettings
            templateType={templateConfig.templateType}
            values={aiValues}
            onChange={handleAiChange}
            showAli={!templateConfig.hasInstruction}
            config={{
              nProps: {
                min: minAiOptions,
                max: maxAiOptions,
                step: 1,
              },
              nMarks: new Array(maxAiOptions)
                .fill(1)
                .map((v, index) => {
                  return { value: index + 1, label: index + 1 };
                }),
            }}
          />
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={() => {
              onSubmit({
                aiOptions: aiValues,
                lang: selectedLanguage,
                ouputLang: selectedOutputLanguage,
              });
            }}
            disabled={isOutOfCredits}
          >
            Next Step
          </Button>
        </div>
      </div>
    </div>

  );
};

export default PromptSettingsForm;
