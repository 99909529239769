import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
}));
