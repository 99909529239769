import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  inputsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    marginTop: 8,
  },
  addButton: {
    height: 26,
  },
}));

export default useStyles;
