import React from 'react';
import { useQueryClient } from 'react-query';

export default function useInvalidateCatalogItems() {
  const queryClient = useQueryClient();

  const invalidateCatalogItems = React.useCallback(() => {
    queryClient.invalidateQueries('catalog.items');
  }, []);

  return { invalidateCatalogItems };
}
