import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import fileContext from '@root/resources/file/file.context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    padding: '30px',
    backgroundColor: '#ffffff70',
    borderRadius: theme.border.radius.default,
    border: `1px solid ${theme.palette.colors.grey}`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    color: '#444',
  },
  text: {
    marginTop: 16,
  },
  button: {
    marginTop: 24,
  },
}));

const EmptyState = () => {
  const classes = useStyles();
  const {
    template,
    isNoDocs,
    filteredDocuments,
    openExample,
    statusFilter,
  } = fileContext.useFile();
  const isCustomTemplate = template.templateType.includes('custom_');

  const getContent = () => {
    if (isNoDocs) {
      return {
        title: 'You haven\'t made any generations yet.',
        text: isCustomTemplate
          ? 'This is a custom content generator and an example cannot be provided.'
          : 'Take a look at our examples to help you get started with this template.',
      };
    }

    if (!isNoDocs && filteredDocuments.length === 0) {
      if (statusFilter === 'notAssigned') {
        return {
          title: 'This file has no unassigned copy.',
          text: 'All copy has been assigned for team review.',
        };
      }
      if (statusFilter === 'assigned') {
        return {
          title: 'This file has no assigned copy to review.',
        };
      }
    }

    return null;
  };
  const content = getContent();

  if (!content) {
    return null;
  }

  const { title, text } = content;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <span className={classes.title}>
          {title}
        </span>

        {text && (
          <span className={classes.text}>
            {text}
          </span>
        )}

        {(isNoDocs && !isCustomTemplate) && (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={openExample}
          >
            See examples
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
