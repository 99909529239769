import { FC } from 'react';

import { newColors } from '@root/newMaterial.theme';

import blogIdea from './blogIdea.icon';
import blogIntro from './blogIntro.icon';
import blogOutline from './blogOutline.icon';
import freeFormEditorInstruct from './commandCopy.icon';
import custom from './custom.icon';
import contentImprover from './enhance.icon';
import contentExpander from './expand.icon';
import freeFormEditor from './extend.icon';
import instagramAd from './instagramAd.icon';
import productDescription from './productDescription.icon';
import contentRewriter from './rewrite.icon';
import socialMediaCaptions from './socialMediaCaptions.icon';
import flipkartProductDescription from './flipkartProductDescription.icon';
import instagramProductDescription from './instagramProductDescription.icon';
import amazonProductDescription from './amazonProductDescription.icon';
import productTitle from './productTitle.icon';
import productFeatureBullets from './productFeatureBullets.icon';

interface Icons {
  [key: string] : {
    icon: FC,
    bgColor: string,
    color: string;
  }
}

const icons: Icons = {
  blogIdea: {
    icon: blogIdea,
    bgColor: newColors.green200,
    color: newColors.green600,
  },
  blogIntro: {
    icon: blogIntro,
    bgColor: newColors.yellow200,
    color: newColors.yellow600,
  },
  blogOutline: {
    icon: blogOutline,
    bgColor: newColors.pink200,
    color: newColors.pink600,
  },
  freeFormEditorInstruct: {
    icon: freeFormEditorInstruct,
    bgColor: newColors.pink200,
    color: newColors.pink600,
  },
  custom: {
    icon: custom,
    bgColor: newColors.neutrals100,
    color: newColors.neutrals400,
  },
  contentImprover: {
    icon: contentImprover,
    bgColor: newColors.green200,
    color: newColors.green600,
  },
  contentExpander: {
    icon: contentExpander,
    bgColor: newColors.neutrals100,
    color: newColors.neutrals400,
  },
  freeFormEditor: {
    icon: freeFormEditor,
    bgColor: newColors.neutrals100,
    color: newColors.neutrals400,
  },
  instagramAd: {
    icon: instagramAd,
    bgColor: newColors.purple200,
    color: newColors.purple600,
  },
  productDescription: {
    icon: productDescription,
    bgColor: newColors.blue200,
    color: newColors.blue600,
  },
  contentRewriter: {
    icon: contentRewriter,
    bgColor: newColors.orange200,
    color: newColors.orange600,
  },
  socialMediaCaptions: {
    icon: socialMediaCaptions,
    bgColor: newColors.yellow200,
    color: newColors.yellow600,
  },
  flipkartProductDescription: {
    icon: flipkartProductDescription,
    bgColor: newColors.blue200,
    color: newColors.blue600,
  },
  instagramProductDescription: {
    icon: instagramProductDescription,
    bgColor: newColors.yellow200,
    color: newColors.yellow600,
  },
  amazonProductDescription: {
    icon: amazonProductDescription,
    bgColor: newColors.green200,
    color: newColors.green600,
  },
  productTitle: {
    icon: productTitle,
    bgColor: newColors.green200,
    color: newColors.green600,
  },
  productFeatureBullets: {
    icon: productFeatureBullets,
    bgColor: newColors.orange200,
    color: newColors.orange600,
  },
};

export default icons;
