import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import { IconCloudOff, IconCloudUpload, IconDotsVertical, IconEdit, IconTrash, IconUpload } from '@tabler/icons-react';

import AuthContext from '@root/resources/auth/auth.context';
import getTemplateActionStatuses from '@root/views/CreateAndExplore/CreateAndExplore.helpers';
import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';
import useModalState from '@root/utils/hooks/useModalState';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import SubmitTemplateModal from '@root/components/modals/SubmitTemplateModal';
import aiTemplateResource from '@root/resources/aiTemplate';
import uiNotificationService from '@root/services/uiNotification.service';
import TemplatesContext from '@root/resources/templates/templates.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './CustomTemplateCard.styles';

const CustomTemplateKebabMenu = ({ template }) => {
  const classes = useStyles();
  const history = useHistory();

  const [menuAnchor, setMenuAnchor] = useState();
  const [isDeleteOpen, openDelete, closeDelete] = useModalState();
  const [isSubmitOpen, openSubmit, closeSubmit] = useModalState();

  const { mutateAsync: createOrUpdateCustom } = aiTemplateResource.useCreateOrUpdateCustomAiTemplate();
  const { mutateAsync: deleteAiTemplate } = aiTemplateResource.useDeleteAiTemplate();
  const { loadTemplates } = TemplatesContext.useTemplates();
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const {
    templates,
    handleUploadToCommunityClick,
    handleRemoveFromCommunityClick,
  } = useCreateAndExploreContext();

  const { hasFiles, _id, status, fromCommunity } = template;
  const isDownloadedByMe = template?.downloadedBy === currentUser?._id;
  const canManageTemplateBuilder = currentUser.permissions.manageTemplateBuilder;
  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;
  const isLive = status === 'live';

  const {
    isRemoveFromCommunityVisible,
    isUploadToCommunityVisible,
  } = getTemplateActionStatuses({ templates, template, company: currentCompany });

  const deleteTemplate = async () => {
    const { isBadRequest } = await deleteAiTemplate(_id);
    if (!isBadRequest) {
      closeDelete();
      loadTemplates();
      uiNotificationService.showSuccessMessage('Generator was successfully deleted');
    }
  };

  const submitTemplate = async ({ submitNotes }) => {
    const { isBadRequest } = await createOrUpdateCustom({
      ...template,
      _id,
      status: 'submitted',
      submitNotes,
    });
    if (!isBadRequest) {
      closeSubmit();
      loadTemplates();
      uiNotificationService.showSuccessMessage('Generator was successfully submitted');
    }
  };

  const handleDotsClick = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleEditClick = () => {
    history.push(`/template-builder/${_id}`);
  };

  return (
    <>
      {isDeleteOpen && (
        <ConfirmationModal
          title="Delete generator"
          confirmButtonText="Delete"
          negative
          onConfirm={deleteTemplate}
          onClose={closeDelete}
        >
          {hasFiles ? (
            <>
              If you delete the&nbsp;
              <b>generator</b>
              , members on your team won&apos;t be able to use this&nbsp;
              <b>generator</b>
              &nbsp;for new content creation.
              Files that have already been created will still be available.
            </>
          ) : 'You cannot restore the generator.'}
        </ConfirmationModal>
      )}

      {isSubmitOpen && (
        <SubmitTemplateModal
          onClose={closeSubmit}
          onSubmit={submitTemplate}
        />
      )}

      <IconDotsVertical
        className={classes.kebabIcon}
        onClick={handleDotsClick}
      />

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          list: classes.menuList,
          paper: classes.menuPaper,
        }}
      >
        {status === 'draft' && (
          <MenuItem
            onClick={openSubmit}
            classes={{ root: classes.menuItem }}
          >
            <IconUpload className={classes.menuItemIcon} />
            <span>Submit</span>
          </MenuItem>
        )}
        {isUploadToCommunityVisible && isLive && !fromCommunity && (
          <SimpleTooltip
            wrapped
            content={
            !isAdminOrOwner
              ? 'This action can only be performed by Administrators.'
              : ''
          }
          >
            <MenuItem
              disabled={!isAdminOrOwner}
              onClick={(event) => handleUploadToCommunityClick(event, template)}
              classes={{ root: classes.menuItem }}
            >
              <IconCloudUpload className={classes.menuItemIcon} />
              <span>Upload</span>
            </MenuItem>
          </SimpleTooltip>
        )}

        {isRemoveFromCommunityVisible && (
          <SimpleTooltip
            wrapped
            content={
              !isAdminOrOwner
                ? 'This action can only be performed by Administrators.'
                : ''
            }
          >
            <MenuItem
              disabled={!isAdminOrOwner}
              onClick={(event) => handleRemoveFromCommunityClick(event, template)}
              classes={{ root: classes.menuItem }}
            >
              <IconCloudOff className={classes.menuItemIcon} />
              <span>Remove</span>
            </MenuItem>
          </SimpleTooltip>
        )}
        {status !== 'submitted' && status !== 'draft' && canManageTemplateBuilder && (
          <MenuItem
            onClick={handleEditClick}
            classes={{ root: classes.menuItem }}
          >
            <IconEdit className={classes.menuItemIcon} />
            <span>Edit</span>
          </MenuItem>
        )}

        <SimpleTooltip
          wrapped
          content={
            !isAdminOrOwner && !isDownloadedByMe
              ? 'This action can only be performed by Administrators.'
              : ''
          }
        >
          <MenuItem
            disabled={!canManageTemplateBuilder && !isDownloadedByMe}
            onClick={openDelete}
            classes={{ root: classes.menuItem }}
          >
            <IconTrash className={classes.menuItemIcon} />
            <span>Delete</span>
          </MenuItem>
        </SimpleTooltip>
      </Menu>
    </>
  );
};

export default CustomTemplateKebabMenu;
