import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { SearchField } from '@root/components/form';
import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import billingResource from '@root/resources/billing';

import { tabs } from '../../../CreateAndExplore.constants';
import { useCreateAndExploreContext } from '../../../context/CreateAndExplore.context';
import { communityActions } from '../../../context/createAndExploreReducer';

import useStyles from './FilterBar.styles';

const FilterBar = () => {
  const history = useHistory();
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { templatesToShow } = useCreateAndExploreContext();
  const { data: { maxCustomTemplates } = {} } = billingResource.useLimits();

  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;
  const isLimitReached = templatesToShow.length >= maxCustomTemplates;

  const {
    state: { searchQuery, activeTab },
    dispatch,
  } = useCreateAndExploreContext();

  const isCreateTab = activeTab === tabs.CREATE;

  const handleSearchChange = (value) => {
    dispatch({ type: communityActions.SET_SEARCH_QUERY, payload: value });
  };

  const handleCreateCustomTemplateClick = () => {
    history.push('/template-builder');
  };

  const createButtonTooltipContent = useMemo(() => {
    if (!isAdminOrOwner) {
      return 'This action can only be performed by Administrators.';
    }

    if (isLimitReached) {
      return 'Limit reached.';
    }

    return '';
  }, [isAdminOrOwner, isLimitReached]);

  return (
    <div className={classes.filterBarContainer}>
      {isCreateTab && (
        <SimpleTooltip
          content={createButtonTooltipContent}
          wrapped
        >
          <Button
            startIcon={<IconPlus className={classes.startIcon} />}
            className={classes.createButton}
            onClick={handleCreateCustomTemplateClick}
            disabled={!isAdminOrOwner || isLimitReached}
          >
            Create custom generator
          </Button>
        </SimpleTooltip>
      )}

      <SearchField
        debounce
        placeholder="Search for a template"
        value={searchQuery}
        onChange={handleSearchChange}
        classNames={{
          container: clsx(
            classes.searchField,
            { [classes.searchFieldSmall]: isCreateTab },
          ),
        }}
      />
    </div>
  );
};

export default FilterBar;
