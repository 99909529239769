import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, InputBase, Paper, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SearchIcon from '@material-ui/icons/Search';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import AddSpacing from '@root/components/AddSpacing';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import LoadingGen from '@root/components/Loading/LoadingGen';
import UpdatePlanTooltip from '@root/components/UpdatePlanTooltip';
import { colors } from '@root/material.theme';

import useFraseKeywords from '../resources/useFraseKeywords';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    border: `1px solid ${theme.palette.colors.grey}`,
    borderRadius: '5px',
    padding: '0.125rem 0.5rem',
    fontWeight: 400,
    outline: 0,
    height: '2.5rem',
    width: '100%',
    background: '#fff',
    fontSize: 12,
  },
  searchButton: {
    padding: 4,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  pillAtom: {
    fontSize: '14px',
    padding: '2px 5px 2px 12px',
    marginRight: '10px',
    borderRadius: theme.border.radius.default,
    border: `1px solid ${theme.palette.colors.grey}`,
    backgroundColor: 'white',
    cursor: 'default',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#523ae710',
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const KeywordPill = ({
  entity, selected, className, onClick,
}) => {
  const classes = useStyles();
  const animationProps = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: 24 },
  });
  return (
    <animated.div style={animationProps}>
      <div
        style={{ display: 'inline-block' }}
        className={className}
        onClick={onClick}
      >
        <div className={classes.pillAtom} style={{ background: selected && '#EEEBFE', border: selected && 'none', cursor: 'pointer' }}>
          <span style={{ margin: 'auto' }}>{entity.entity}</span>
          <div style={{
            marginLeft: '8px', padding: '2px 4px', background: colors.mediumLightGrey, borderRadius: '4px',
          }}
          >
            <span style={{ color: '#828282', fontWeight: 500 }}>{`${entity.frequency}`}</span>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

const FraseKeywords = ({
  showFrase,
  setShowFrase,
  setArray,
  array = [],
  templateType,
  disabled,
}) => {
  const classes = useStyles();
  const [queryText, setQueryText] = useState([]);
  const [fraseOptions, setFraseOptions] = useState([]);

  const { isLoading, mutateAsync } = useFraseKeywords();

  const addElement = (inputString) => {
    if (inputString !== '' && array) {
      setArray((adjs = []) => [...new Set([...adjs, inputString])]);
    } else if (inputString !== '' && !array) {
      setArray([inputString]);
    }
  };

  const remove = (inputString) => setArray((s) => s.filter((e) => e !== inputString));

  const generateKeywords = async () => {
    const trimmedQueryText = queryText.trim();
    if (trimmedQueryText.length === 0) {
      // do not allow user to submit if no input
      return;
    }
    const queryObj = {
      content: trimmedQueryText,
      lang: 'en',
      country: 'us',
    };

    eventsAggregator.publish(EVENT_NAMES.FRASE_KEYWORDS_PROCESSED, {
      template: templateType,
      query: trimmedQueryText,
    });

    const optionsAndResult = await mutateAsync(queryObj);
    const { isBadRequest } = optionsAndResult;
    if (!isBadRequest) {
      setFraseOptions(optionsAndResult);
    } else {
      setFraseOptions([]);
    }
  };

  return showFrase
    && (
      <Grid container>
        <Paper style={{ width: '100%', padding: '20px', marginTop: '16px' }}>
          <AddSpacing spacing={12}>
            <Grid item container>
              <Grid item xs={10}>
                <span style={{ fontSize: '16px' }}>
                  <b>Find SEO-Optimized Keywords with Frase</b>
                </span>
                <SimpleTooltip
                  interactive
                  content={(
                    <span>
                      Numbers indicate a keyword&apos;s frequency score when searched on Google
                    </span>
                  )}
                  placement="right"
                >
                  <HelpOutlineIcon style={{ margin: '0 0 0.25rem 0.75rem', fontSize: '1rem' }} />
                </SimpleTooltip>
              </Grid>
              <Grid item xs={2}>
                <FontAwesomeIcon
                  icon="chevron-up"
                  style={{
                    fontSize: '1.2em',
                    float: 'right',
                    marginRight: '12px',
                    color: colors.grey,
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowFrase(false)}
                />
              </Grid>

            </Grid>
            <Grid item className={classes.inputWrapper}>
              <InputBase
                disabled={disabled}
                placeholder="What is your targeted search query"
                type="text"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    generateKeywords();
                  }
                }}
                onChange={(event) => setQueryText(event.target.value)}
                value={queryText}
                className={classes.searchInput}
                endAdornment={(
                  <IconButton
                    disabled={disabled}
                    onClick={generateKeywords}
                    className={classes.searchButton}
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              />
              {disabled && (
                <UpdatePlanTooltip feature="Frase SEO Enhancer">
                  <ErrorOutlineIcon className="ml-1" />
                </UpdatePlanTooltip>
              )}
            </Grid>
            <Grid item>
              {isLoading ? <LoadingGen />
                : (
                  <div className="d-flex flex-wrap">
                    {fraseOptions
                  && fraseOptions.map((entity) => {
                    const exists = array.includes(entity.entity);
                    return (
                      <KeywordPill
                        selected={exists}
                        entity={entity}
                        key={entity.entity}
                        className="mt-1 mb-1"
                        onClick={() => {
                          if (exists) remove(entity.entity);
                          else addElement(entity.entity);
                        }}
                      />
                    );
                  })}
                  </div>

                )}

            </Grid>
          </AddSpacing>
        </Paper>
      </Grid>
    );
};

export default FraseKeywords;
