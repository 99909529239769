import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateDocumentExample() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.documentExample.create(data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        const queryKey = ['documentExample.list'];
        const prevData = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, () => [response, ...prevData]);

        return response;
      },
    },
  );
}
