import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    minWidth: '816px',
  },
  modalPaperLong: {
    minWidth: '1072px',
  },
  optionsContainer: {
    display: 'flex',
    gap: '16px',
  },
  createOptionContainer: {
    flex: 1,
    padding: '16px 16px 64px',

    borderRadius: '6px',
    border: `1px solid ${theme.palette.colors.neutrals100}`,

    '&.disabled': {
      pointerEvents: 'none',

      '& $title, $description': {
        color: `${theme.palette.colors.neutrals200}`,
      },
    },

    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.colors.neutrals400,
    },
  },

  illustrationContainer: {
    height: '128px',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.colors.neutrals50,
    marginBottom: '24px',
  },

  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '40px',

  },
  description: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals400,
  },

  hootsuiteText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '40px',
    color: theme.palette.colors.neutrals200,
  },
}));

export default useStyles;
