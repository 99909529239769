import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  notificationContainer: {
    position: 'relative',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.colors.greyHalfOpacity,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  notificationIcon: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  notificationCounter: {
    position: 'absolute',
    height: '16px',
    width: '16px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: theme.palette.colors.error,
    borderRadius: '8px',
    textAlign: 'center',
    top: '-2px',
    right: '-3px',
  },
}));
