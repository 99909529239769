import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import useStyles from './FilterItem.styles';

const IconsComponent = ({ title, id, icon, isActive }) => {
  const classes = useStyles();
  const Component = icon;

  return (
    <div
      id={id}
      className={clsx({
        [classes.item]: true,
      })}
    >
      <Link
        className={clsx({
          [classes.icon]: true,
          [classes.itemActive]: isActive,
        })}
        to={`/create/${id}`}
      >
        <Component
          size={32}
          htmlColor={isActive ? '#523AE7' : 'white'}
        />
      </Link>

      <h2 className={classes.title}>
        <Link
          className={clsx({
            [classes.title]: true,
            [classes.titleActive]: isActive,
          })}
          to={`/create/${id}`}
        >
          {title}
        </Link>
      </h2>
      {isActive && (
        <div className={classes.arrow} />
      )}
    </div>
  );
};

const InlineComponent = ({ title, id, isActive, onClick }) => {
  const classes = useStyles();
  return (
    <div
      id={id}
      onClick={onClick}
      className={classes.inlineItem}
    >
      <h2 className={clsx({
        [classes.inlineTitle]: true,
        [classes.inlineTitleActive]: isActive,
      })}
      >
        <div>
          {title}
        </div>
      </h2>
    </div>
  );
};

const filterItemByStyle = {
  icons: IconsComponent,
  inline: InlineComponent,
};

const FilterItem = ({ isActive, title, id, icon, styling = 'icons', onClick }) => {
  const Item = filterItemByStyle[styling];

  return (
    <Item
      title={title}
      id={id}
      icon={icon}
      isActive={isActive}
      onClick={onClick}
    />
  );
};

export default FilterItem;
