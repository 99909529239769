import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useStyles from './EditFieldModal.styles';
import Fields from './Fields';

const validationSchema = yup.object({
  name: yup
    .string('Type name')
    .required('name is required'),
  label: yup
    .string('Type label')
    .required('label is required'),
});

const EditFieldModal = ({
  onClose,
  updateField,
  field,
}) => {
  const classes = useStyles();
  const close = (_e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const defaultValues = React.useMemo(() => {
    const d = field.uiOptions?.values || [];

    return d;
  }, [field.name]);
  const [selectValues, setSelectValues] = React.useState(defaultValues);

  const onSubmit = (newField) => {
    if (newField.uiOptions && newField.uiOptions.type === 'singleSelect') {
      newField.uiOptions.values = selectValues.filter((f) => !!f.label && !!f.value);
    }
    updateField(newField);
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: field,
    validationSchema,
    onSubmit,
  });

  const addItem = () => {
    setSelectValues([
      ...selectValues,
      { value: '', label: '' },
    ]);
  };

  const onValuesKeyChange = (item) => (e) => {
    const newItems = selectValues.map((i) => {
      if (i === item) {
        return {
          label: e.target.value,
          value: i.value,
        };
      }
      return i;
    });
    setSelectValues(newItems);
  };

  const onValuesItemRemove = (index) => () => {
    const newItems = selectValues.filter((i, itemIndex) => index !== itemIndex);
    setSelectValues(newItems);
  };

  const onValuesValueChange = (index) => (e) => {
    const newItems = selectValues.map((i, itemIndex) => {
      if (index === itemIndex) {
        return {
          label: i.label,
          value: e.target.value,
        };
      }
      return i;
    });
    setSelectValues(newItems);
  };

  const { showOnUi } = formik.values;

  return (
    <Dialog
      open
      onClose={close}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitle}>
          {field._id ? 'Edit field' : 'Create field'}
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <p className={classes.modalBodyText}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="name"
                    className={classes.input}
                    helperText="Field name. Alphanumeric, no spaces. e.x.: companyName"
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="label"
                    className={classes.input}
                    helperText="This is what user sees on UI and what sent to Open AI"
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  className={classes.cell}
                  sm={12}
                >
                  <Fields.StringField
                    formik={formik}
                    name="default"
                    className={classes.input}
                    helperText="Default value"
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes.cell}
                sm={12}
                xs={12}
                item
              >
                <Fields.StringField
                  formik={formik}
                  name="example"
                  className={classes.input}
                  helperText="Example value. This is what user sees when opens template example"
                  multiline
                  minRows={2}
                  maxRows={4}
                />
              </Grid>
              <Grid item container>
                <Grid
                  item
                  className={classes.cell}
                  sm={12}
                >
                  <Fields.StringField
                    formik={formik}
                    name="description"
                    className={classes.input}
                    helperText="Field description. Used as field placeholder on UI"
                    multiline
                    minRows={2}
                    maxRows={4}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="min"
                    className={classes.input}
                    helperText="Minimum value length"
                    type="number"
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.StringField
                    formik={formik}
                    name="max"
                    className={classes.input}
                    helperText="Maximum value length"
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.CheckboxField
                    label="Is required?"
                    formik={formik}
                    name="required"
                    className={classes.checkbox}
                  />
                </Grid>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.CheckboxField
                    label="Show on UI?"
                    formik={formik}
                    name="showOnUi"
                    className={classes.checkbox}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  className={classes.cell}
                  sm={6}
                  xs={12}
                  item
                >
                  <Fields.CheckboxField
                    label="Prompt only?"
                    formik={formik}
                    name="promptOnly"
                    className={classes.checkbox}
                    helperText="Used only for chained prompts"
                  />
                </Grid>
              </Grid>
              {showOnUi && (
                <>
                  <Grid item container>
                    <Grid
                      className={classes.cell}
                      sm={6}
                      xs={12}
                      item
                    >
                      <Fields.StringSelect
                        formik={formik}
                        name="uiOptions.type"
                        label="Field type"
                        className=""
                        options={['string', 'singleSelect', 'multilineString', 'keywords']}
                      />
                    </Grid>
                    <Grid
                      className={classes.cell}
                      sm={6}
                      xs={12}
                      item
                    >
                      <Fields.StringField
                        formik={formik}
                        name="uiOptions.helper"
                        className={classes.input}
                        helperText="Helper text visible on UI"
                      />
                    </Grid>
                  </Grid>
                  {formik.values.uiOptions?.type === 'singleSelect' && (
                    <>
                      {selectValues.map((item, index) => {
                        return (
                          <Grid item container>
                            <Grid
                              className={classes.cell}
                              sm={6}
                              xs={12}
                              item
                            >
                              <TextField
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                size="small"
                                label="Label"
                                value={item.label}
                                onChange={onValuesKeyChange(item)}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              container
                              className={classes.cell}
                              sm={6}
                              xs={12}
                              item
                            >
                              <TextField
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                size="small"
                                label="Value"
                                value={item.value}
                                onChange={onValuesValueChange(index)}
                                variant="outlined"
                              />
                              <IconButton
                                size="small"
                                variant="outlined"
                                onClick={onValuesItemRemove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Grid item container>
                        <Button
                          variant="text"
                          className={classes.cancelButton}
                          onClick={addItem}
                        >
                          Add select item
                        </Button>
                      </Grid>
                    </>
                  )}
                  {['multilineString', 'string'].includes(formik.values.uiOptions?.type) && (
                    <Grid item container>
                      <Grid
                        className={classes.cell}
                        sm={4}
                        xs={12}
                        item
                      >
                        <Fields.StringField
                          formik={formik}
                          name="uiOptions.quality.fairThreshold"
                          className={classes.input}
                          helperText="fair text threshold"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        className={classes.cell}
                        sm={4}
                        xs={12}
                        item
                      >
                        <Fields.StringField
                          formik={formik}
                          name="uiOptions.quality.greatThreshold"
                          className={classes.input}
                          helperText="great text threshold"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        className={classes.cell}
                        sm={4}
                        xs={12}
                        item
                      >
                        <Fields.StringField
                          formik={formik}
                          name="uiOptions.quality.excessThreshold"
                          className={classes.input}
                          helperText="excess text threshold"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </form>
        </p>
      </DialogContent>
      <DialogActions className={classes.cancelButtonWrap}>
        <Button
          variant="text"
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFieldModal;
