import {
  Button, DialogActions, DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Modal from '@root/components/modals/Modal';

import useStyles from './FreeTrialEnd.styles';

/**
 * Modal which will appear to Free Trial users when they are on Templates and use up their
 * free trial.
 * @param isOpen {boolean}
 * @param setIsOpen
 * @param didHitLimit {boolean} if true then user hit 100 gens per day
 * @return {JSX.Element}
 * @constructor
 */
const FreeTrialEndModal = ({
  modalTitle,
  modalText,
  keepOpen,
  isOpen,
  setIsOpen,
  didHitLimit,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const title = modalTitle
    || (didHitLimit
      ? "You've reached the free trial limit of 100 generations per day"
      : 'Looks like your free trial has expired!');

  const defaultText = `We hope you were able to generate some amazing content! We'd love
  for you to stick around. Head on over to our plans page and pick
  out the right one for you.`;

  const text = modalText || defaultText;

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      maxWidth="xs"
      open={isOpen}
      title={title}
      onClose={handleModalClose}
    >
      <DialogContentText>
        {text}
      </DialogContentText>
      {!keepOpen ? (
        <DialogActions className={classes.actions}>
          <div>
            <Button
              onClick={handleModalClose}
              variant="text"
            >
              Maybe Later
            </Button>
          </div>
          <Button
            onClick={() => (window.location.href.includes('profile/plans')
              ? handleModalClose() : history.push('/profile/plans'))}
            text=""
          >
            Upgrade
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Link
            to={{ pathname: '/profile/plans' }}
            target="_blank"
          >
            <Button>
              Upgrade
            </Button>
          </Link>
        </DialogActions>
      )}
    </Modal>
  );
};

export default FreeTrialEndModal;
