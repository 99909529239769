import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import { SHARE_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals';
import useFile from '@root/resources/file/useFile';
import AuthContext from '@root/resources/auth/auth.context';

const ShareFileButton = ({ fileId, button }) => {
  const { data: file } = useFile(fileId);
  const { dispatch } = useGlobalModals();

  const { currentUser } = AuthContext.useAuth();

  const onShare = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: SHARE_FOLDER_MODAL_ID,
        modalContent: {
          file,
        },
      },
    });
  };

  if (!file || !currentUser.featureToggles.newFileSharing) {
    return null;
  }

  if (button) {
    return React.cloneElement(button, { onClick: onShare });
  }

  return (
    <Button
      onClick={onShare}
    >
      Share
    </Button>
  );
};

ShareFileButton.propTypes = {
  fileId: PropTypes.string.isRequired,
};

export default ShareFileButton;
