import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  titleAtom: {
    fontFamily: '"Inter", sans-serif',
    color: theme.palette.colors.neutrals400,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    marginBottom: '6px',
  },
}));

const AddTitleAtom = ({
  title, children, style, required,
}) => {
  const classes = useStyles();

  if (!title) {
    return children;
  }

  return (
    <div style={style}>
      <div className={classes.titleAtom}>
        {title}
        {required && (
          <span
            style={{
              color: 'red',
            }}
          >
            {' '}
            *
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

export default AddTitleAtom;
