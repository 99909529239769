import { useHistory } from 'react-router-dom';

import authContext from '@root/resources/auth/auth.context';
import TemplatesDashboard from '@root/views/TemplatesDashboard/TemplatesDashboard';
import TemplatesDashboardNew from '@root/views/TemplatesDashboardNew/TemplatesDashboardNew';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

const HomePageRoute = () => {
  const {
    currentUser,
  } = authContext.useAuth();

  const {
    currentExternalSource: {
      homePage: externalSourceHomePage,
    },
  } = externalSourcesContext.useExternalSource();

  const history = useHistory();

  if (externalSourceHomePage) {
    history.replace(externalSourceHomePage);
    return null;
  }

  if (currentUser?.featureToggles?.homePage) {
    history.replace('/home');
    return null;
  }

  return currentUser?.featureToggles?.createPageRedesign
    ? TemplatesDashboardNew
    : TemplatesDashboard;
};

export default HomePageRoute;
