import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  userEmail: {
    fontWeight: '500',
    lineHeight: '22px',
  },
  trialBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  trialText: {
    lineHeight: '22px',
  },
  red: {
    color: theme.palette.colors.pink600,
  },
  buttonsContainer: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  trialButton: {
    minWidth: 'unset',
    color: theme.palette.colors.neutrals600,
    fontWeight: 400,
    lineHeight: '22px',
    textDecoration: 'underline',

    '&:hover, &:active, &:focus': {
      color: theme.palette.colors.neutrals400,
      textDecoration: 'underline',
    },
  },
  totalCredits: {
    lineHeight: '22px',
  },
  lowCredits: {
    color: theme.palette.colors.pink600,
  },
  creditsNumber: {
    fontWeight: 700,
  },
}));
