import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateDocumentExample() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.documentExample.submitToReview(data),
    {
      onSuccess: (response, vars) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        if (!vars.documentId) {
          return response;
        }

        queryClient.getQueryCache().findAll('documents.list').forEach(({ queryKey: key, state }) => {
          queryClient.setQueryData(key, state.data.map((doc) => {
            if (doc._id !== vars.documentId) {
              return doc;
            }

            return {
              ...doc,
              submittedExamples: {
                ...doc.submittedExamples,
                [response._id]: response.submissionData.type,
              },
            };
          }));
        });

        return response;
      },
    },
  );
}
