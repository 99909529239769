import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
  },
  leftBar: {
    height: '8px',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
    backgroundColor: (quality) => quality.leftColor,
    width: '33%',
  },
  midBar: {
    height: '8px',
    width: '33%',
    backgroundColor: (quality) => quality.middleColor,
  },
  rightBar: {
    height: '8px',
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: (quality) => quality.rightColor,
    width: '33%',
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.5rem',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  qualityBadge: {
    padding: '4px 8px',
    backgroundColor: (quality) => quality.qualityBgColor,
    borderRadius: 6,
    fontWeight: 500,
    fontSize: 12,
    color: (quality) => quality.qualityColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qualityDescription: {
    marginLeft: '0.5rem',
    color: theme.palette.colors.neutrals300,
    fontSize: 12,
    textAlign: 'right',
  },
  infoSection: {
    position: 'absolute',
    top: 122,
    fontSize: 15,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    right: 0,
    padding: '0 8px',
  },
  creditsBadge: {
    color: theme.palette.colors.white,
    padding: 4,
    borderRadius: 4,
    fontSize: 14,
  },
  contentLength: {
    color: theme.palette.colors.pureGray,
    background: theme.palette.colors.mediumLightGrey,
    padding: 4,
    borderRadius: 4,
    fontSize: 14,
  },
  emptyContainer: {
    height: '25px',
    width: '100%',
  },
}));
