import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './ProductFileUploader.styles';

const ProductFileUploader = ({
  onFileSelect,
  file,
  dropSelectedFile,
  uploadDisabled,
}) => {
  const [isDragover, setIsDragover] = React.useState(false);

  const onDndStart = (e) => {
    e.preventDefault();
    if (uploadDisabled) return;
    const [item] = e.dataTransfer.items;
    setIsDragover(item.kind === 'file');
  };

  const onDndStop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragover(false);
  };

  const onDrop = async (e) => {
    e.preventDefault();
    if (uploadDisabled) return;
    setIsDragover(false);
    const [item] = e.dataTransfer.items;
    onFileSelect(item.getAsFile());
  };

  const exploreFiles = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.onchange = () => {
      const [f] = fileInput.files;
      onFileSelect(f);
    };
    fileInput.click();
    fileInput.remove();
  };

  const classes = useStyles();

  return (
    <>
      <input
        id="file-input"
        type="file"
        accept=".csv"
        hidden
        onChange={(e) => {
          const [f] = e.target.files;
          onFileSelect(f);
        }}
      />
      <div
        className={clsx(classes.root, { [classes.onDragover]: isDragover })}
        onDragOver={onDndStart}
        onDragLeave={onDndStop}
        onDrop={onDrop}
      >
        <div className={classes.content}>
          <div className={classes.fileInfo}>
            <div>
              {file && `${file.fileName} (${file.rowsCount} rows${file.duplicatesCount ? `, ${file.duplicatesCount} duplicates will be skipped` : ''})`}
              {!file && (
                <div>
                  <strong>
                    Drag file here to upload
                  </strong>
                  <div>or</div>
                </div>
              )}
            </div>
            <Button
              className={classes.button}
              variant="outlined"
              size="small"
              onClick={file ? dropSelectedFile : exploreFiles}
              disabled={uploadDisabled}
            >
              Browse files
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFileUploader;
