/* eslint-disable object-property-newline */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  TableContainer, TableBody, Table, TableRow, TableCell,
  CircularProgress,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import AuthContext from '@root/resources/auth/auth.context';
import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import templatesContext from '@root/resources/templates/templates.context';
import folderIcon from '@root/assets/subfolder.svg';
import dateUtil from '@root/utils/date.util';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import Tooltip from '@root/components/tooltips/SimpleTooltip';
import BoosteAltTooltipContent from '@root/components/tooltips/BoosteAltTooltipContent';

import WorkflowStatus from '../FolderContentNew/DashListViewNew/components/WorkflowStatus';

import CustomTableHead from './components/CustomTableHead';
import FileTableRow from './components/FileTableRow';
import TextWithOverflow from './components/TextWithOverflow';
import CreateFileRow from './components/CreateFileRow';
import SubfolderTableRow from './components/SubfolderTableRow';
import useStyles from './DashListView.styles';

const BOOSTE_LEGACY_TEMPLATE_TYPES = [
  'articles',
  'blogPost',
];

const cellsSelectors = (fixedColumn) => ({
  workflowQueue: (cells, options) => {
    const notWorkflowSettings = {
      title: { width: fixedColumn ? '15%' : '25%' },
      templateType: { width: '18%' },
      generationSize: { width: '10%' },
      assignedTo: { width: '18%' },
      workflowStatus: { width: '15%' },
      updatedOn: { width: '10%' },
    };

    const newNotWorkflowSettings = {
      title: { width: fixedColumn ? '15%' : '25%' },
      templateType: { width: '20%' },
      updatedOn: { width: '12%' },
      generationSize: { width: '10%' },
      assignedTo: { width: '15%' },
      workflowStatus: { width: '15%' },
    };

    const workflowSettings = {
      title: { width: fixedColumn ? '15%' : '25%' },
      templateType: { width: '25%' },
      workflowStatus: { width: '25%' },
      updatedOn: { width: '25%' },
    };

    const oldSelectedSettings = options.isWorkflow
      ? workflowSettings
      : notWorkflowSettings;

    const newSelectedSettings = options.isWorkflow
      ? workflowSettings
      : newNotWorkflowSettings;

    const selectedSettings = options.isNewDesign ? newSelectedSettings : oldSelectedSettings;

    const keys = Object.keys(selectedSettings);

    return cells
      .filter(({ id }) => keys.includes(id))
      .map((cell) => {
        return {
          ...cell,
          width: selectedSettings[cell.id].width,
        };
      });
  },
  default: (cells) => {
    const settings = {
      title: { width: '25%' },
      templateType: { width: '25%' },
      generationSize: { width: '25%' },
      updatedOn: { width: '25%' },
    };

    const keys = Object.keys(settings);

    return cells
      .filter(({ id }) => keys.includes(id))
      .map((cell) => {
        return {
          ...cell,
          width: settings[cell.id].width,
        };
      });
  },
});

const getCells = ({ getTemplate, user, classes, isNewDesignCells }) => {
  if (isNewDesignCells) {
    return [
      {
        id: 'title', label: 'Name',
      },
      {
        id: 'templateType', label: 'Type',
        render: ({ templateType }) => {
          const { Icon, title } = getTemplate(templateType);
          const isBoosteLegacyTemplateType = BOOSTE_LEGACY_TEMPLATE_TYPES.includes(templateType);

          return (
            <>
              <Tooltip
                wrapped
                content={isBoosteLegacyTemplateType
                  && <BoosteAltTooltipContent templateName={title} />}
                placement="bottom"
                interactive
              >
                <span className="mr-4">
                  {isBoosteLegacyTemplateType ? <ErrorIcon style={{ color: 'orangered' }} /> : <Icon color="#000" size={24} />}
                </span>
              </Tooltip>
              {title}
            </>
          );
        },
      },
      {
        id: 'updatedOn',
        label: 'Modified',
        render: (item) => dateUtil.getRelative(item.updatedOn),
      },
      {
        id: 'assignedTo',
        label: 'Assigned To',
        render: ({ assignedTo }, innerRef, isDisabled) => {
          if (!assignedTo) {
            return '—';
          }

          const { userId, email, displayName } = assignedTo;
          const text = userId === user._id ? (
            <span className={clsx(classes.youHighlight, {
              [classes.disabledText]: isDisabled,
            })}
            >
              You
            </span>
          ) : (displayName || email);

          return (
            <TextWithOverflow text={text} isDisabled={isDisabled} />
          );
        },
      },
      {
        id: 'workflowStatus',
        label: 'Status',
        render: ({ workflowStatus }, innerRef, isDisabled) => (
          <WorkflowStatus status={workflowStatus} isDisabled={isDisabled} />
        ),
      },
      {
        id: 'generationSize',
        label: 'Generations',
      },
    ];
  }

  return [
    {
      id: 'title', label: 'Name',
    },
    {
      id: 'templateType', label: 'Type',
      render: ({ templateType }) => {
        const { Icon, title } = getTemplate(templateType);

        return (
          <>
            <span className="mr-4"><Icon color="#000" size={24} /></span>
            {title}
          </>
        );
      },
    },
    {
      id: 'generationSize',
      label: 'Generations',
    },
    {
      id: 'assignedTo',
      label: 'Assigned To',
      render: ({ assignedTo }, innerRef, isDisabled) => {
        if (!assignedTo) {
          return '—';
        }

        const { userId, email, displayName } = assignedTo;
        const text = userId === user._id ? (
          <span className={clsx(classes.youHighlight, {
            [classes.disabledText]: isDisabled,
          })}
          >
            You
          </span>
        ) : (displayName || email);

        return (
          <TextWithOverflow text={text} isDisabled={isDisabled} />
        );
      },
    },
    {
      id: 'workflowStatus',
      label: 'Workflow status',
      render: ({ workflowStatus }, innerRef, isDisabled) => (
        <WorkflowStatus status={workflowStatus} isDisabled={isDisabled} />
      ),
    },
    {
      id: 'updatedOn',
      label: 'Modified',
      render: (item) => dateUtil.getRelative(item.updatedOn),
    },
  ];
};

const getSubfolderCells = () => {
  return [
    {
      id: 'title', label: 'Name',
    },
    {
      id: 'templateType',
      label: 'Type',
      render: () => {
        return (
          <>
            <img
              src={folderIcon}
              alt="Subfolder Icon"
              className="mr-3"
            />
            Folder
          </>
        );
      },
    },
    {
      id: 'generationSize',
      label: 'Generations',
      render: () => {},
    },
    {
      id: 'assignedTo',
      label: 'Assigned To',
      render: () => '—',
    },
    {
      id: 'workflowStatus',
      label: 'Workflow status',
      render: () => '—',
    },
    {
      id: 'updatedOn',
      label: 'Modified',
      render: () => {},
    },
  ];
};

const DashListView = ({
  folder,
  files = [],
  subfolders,
  isLoading,
  isDraggable,
}) => {
  const { pathname } = useLocation();
  const { locationId } = useParams();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const {
    fixedColumn,
  } = currentExternalSource.toggles;

  const uncategorizedFolder = pathname.includes('Home_') && 'Uncategorized';
  const bookmarkFolder = pathname.includes('bookmarks') && 'Bookmarks';
  const isWorkflow = pathname.includes('workflow') && 'Workflow';
  const selectedFolder = locationId || uncategorizedFolder || bookmarkFolder || isWorkflow || 'allFiles';

  const tableContainerRef = React.useRef();
  const [fileRefs, setFileRefs] = React.useState({ folder: selectedFolder, refs: [] });

  const { currentUser: user } = AuthContext.useAuth();
  const hasTeamPermissions = user.permissions.teams;
  const newDesign = user.featureToggles.dashboardAIRedesign2;

  const classes = useStyles();
  const {
    state: {
      filters: { sort, sortOrder },
      selectedFileIds,
    },
    dispatch,
  } = useDashboard();

  const { getTemplate } = templatesContext.useTemplates();

  const selectorKey = user.permissions.workflowQueue && hasTeamPermissions
    ? 'workflowQueue'
    : 'default';

  const selector = cellsSelectors(fixedColumn)[selectorKey];

  const selectedCells = React.useMemo(() => {
    const cells = getCells({ getTemplate, user, classes, isNewDesignCells: newDesign });
    return selector(cells, { isWorkflow, isNewDesign: newDesign });
  }, [getTemplate, selector, user]);

  const selectedSubfolderCells = React.useMemo(() => {
    const subfolderCells = getSubfolderCells();
    return selector(subfolderCells, { isWorkflow });
  }, [selector]);

  const setSelectedFileIds = (newSelectedFileIds) => dispatch({
    type: actions.SET_SELECTED_FILES,
    payload: { selectedFileIds: newSelectedFileIds },
  });

  const handleRequestSort = (property) => {
    let newSortOrder;
    if (sort !== property) {
      newSortOrder = 'asc';
    } else {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    dispatch({
      type: actions.SET_SORT,
      payload: {
        sort: property,
        sortOrder: newSortOrder,
      },
    });
  };

  const toggleSelectedMeta = (fileId) => (event) => {
    event.stopPropagation();
    if (selectedFileIds.includes(fileId)) {
      setSelectedFileIds(selectedFileIds.filter((id) => id !== fileId));
    } else {
      setSelectedFileIds([...selectedFileIds, fileId]);
    }
  };

  const availableRowsFilter = ({ templateType }) => {
    return !currentExternalSource.isTemplateDisabled(templateType);
  };

  const availableRowsNum = files.filter(availableRowsFilter).length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedFiles = files
        .filter(availableRowsFilter)
        .map((n) => n._id);

      setSelectedFileIds(selectedFiles);
      return;
    }
    setSelectedFileIds([]);
  };

  const allowedToCreateFile = React
    .useMemo(() => {
      const userFolderRoles = user.roles.getFolderRoles(folder);

      return !folder || userFolderRoles.editor || userFolderRoles.owner;
    }, [user, folder]);

  const setFileRef = (arr) => (index) => (refData) => {
    if (!document.body.contains(refData.element)) {
      return;
    }

    arr[index] = refData;

    if (arr.length === files.length) {
      setFileRefs(() => ({ folder: selectedFolder, refs: arr }));
    }
  };

  React.useEffect(() => {
    if (files.length === 0) {
      setFileRefs({ folder: selectedFolder, refs: [] });
    }
  }, [files.length]);

  React.useEffect(() => {
    if (files.length < 20) {
      return () => {};
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        const ref = fileRefs.refs.find((r) => r && r.element === e.target);
        if (!ref) {
          return;
        }
        ref.setVisible(e.isIntersecting);
      });
    }, { root: tableContainerRef.current, threshold: 0, rootMargin: '500px 0px 500px 0px' });
    fileRefs.refs.forEach((r) => observer.observe(r.element));

    return () => {
      fileRefs.refs.forEach((r) => { observer.unobserve(r.element); });
    };
  }, [
    fileRefs.refs,
    fileRefs.folder,
  ]);

  const refsArr = [];
  const fileRows = React.useMemo(() => (!isLoading && files.map((file, i) => (
    <FileTableRow
      id={file._id}
      key={`file-${file._id}`}
      file={file}
      selected={selectedFileIds.includes(file._id)}
      toggleSelected={toggleSelectedMeta(file._id)}
      isDraggable={isDraggable}
      cells={selectedCells}
      setRef={setFileRef(refsArr)(i)}
      files={files}
    />
  ))), [isLoading, files, selectedFileIds]);

  return (
    <div className={classes.root}>
      <TableContainer
        innerRef={tableContainerRef}
        classes={{
          root: clsx(classes.tableContainer, {
            [classes.newTableContainer]: newDesign,
          }),
        }}
      >
        <Table stickyHeader classes={{ root: classes.table }}>
          <CustomTableHead
            onRequestSort={handleRequestSort}
            orderBy={sort}
            order={sortOrder}
            onSelectAllClick={handleSelectAllClick}
            availableRowsNum={availableRowsNum}
            selectedFileIds={selectedFileIds}
            folder={folder}
            files={files}
            cells={selectedCells}
          />
          <TableBody>
            {isLoading
              ? (
                <TableRow className={classes.loaderWrap}>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )
              : (
                <>
                  {subfolders.map((subfolder) => (
                    <SubfolderTableRow
                      key={`subfolder-${subfolder._id}`}
                      folder={subfolder}
                      isDraggable={isDraggable}
                      cells={selectedSubfolderCells}
                    />
                  ))}
                  {fileRows}
                  {allowedToCreateFile && (
                    <CreateFileRow isSharableFolder={folder?.unifiedAcl.length > 1} />
                  )}
                </>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashListView;
