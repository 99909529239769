import React, { useMemo, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';
import useUpdateFolder from '@root/resources/folder/useUpdateFolder';
import useFolders from '@root/resources/folder/useFolders';
import LocationSelectMenu from '@root/components/Interactive/LocationSelectMenu';
import { getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';
import Button from '@root/components/buttons/Button';

import useStyles from './MoveFolderModal.styles';

const MoveFolderModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { state: { modalContent } } = useGlobalModals();

  const folderTitle = modalContent?.folder?.title;
  const folderId = modalContent?.folder?._id;
  const initialParentFolderId = modalContent?.folder?.parentFolderId;

  const [isLocationSelectMenuOpen, setIsLocationSelectMenuOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(initialParentFolderId);

  const { currentUser } = AuthContext.useAuth();

  const { mutateAsync: updateFolder } = useUpdateFolder();
  const { data: folders } = useFolders();

  const foldersList = useMemo(() => {
    if (!folders?.length) {
      return [];
    }

    const rootFolders = getRootFolders(folders, currentUser);
    const rootSharedFolders = getSharedRootFolders(folders, currentUser);

    const parentFolders = [...rootFolders, ...rootSharedFolders].filter((f) => !f.home);

    const homeRootFolder = rootFolders.find((f) => f.home);
    const projectFolders = parentFolders.filter((f) => !f.home);
    const sortedFolders = [homeRootFolder, ...projectFolders];

    const withoutHomeFolders = sortedFolders.filter((f) => !f.isSharedUncategorized && !f.home);

    return withoutHomeFolders.map(({
      _id,
      title,
      parentFolderId,
      home,
      hasChildFolders,
    }) => ({
      home,
      parentFolderId,
      hasChildFolders,
      label: title,
      value: _id,
    }));
  }, [folders]);

  const submit = async () => {
    const selectedParentFolderId = selectedLocation;
    await updateFolder({
      folderId,
      update: {
        parentFolderId: selectedParentFolderId,
      },
    });
    closeModal();
  };

  const handleLocationSelect = (event, value) => {
    if (event.target.id === 'arrowIndicator') {
      return;
    }
    setIsLocationSelectMenuOpen(!isLocationSelectMenuOpen);

    if (value) {
      setSelectedLocation(value);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      title={`Move folder '${folderTitle}'`}
    >
      <p className={classes.selectLabel}>Select a new project</p>
      <div onClick={(event, value) => handleLocationSelect(event, value)}>
        <ClickAwayListener
          onClickAway={() => setIsLocationSelectMenuOpen(false)}
        >
          <span>
            <LocationSelectMenu
              folders={foldersList}
              location={selectedLocation || folderId}
              onLocationSelect={(event, value) => handleLocationSelect(event, value)}
              isOpen={isLocationSelectMenuOpen}
            />
          </span>
        </ClickAwayListener>
      </div>

      <div className={classes.buttonsContainer}>
        <Button variant="text" onClick={closeModal}>Discard</Button>
        <Button type="button" onClick={submit}>Confirm</Button>
      </div>
    </Modal>
  );
};

export default MoveFolderModal;
