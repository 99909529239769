import React, { FC } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';

import TruncatedText from '@root/components/TruncatedText';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import AuthContext from '@root/resources/auth/auth.context';

import useSectionStyles from '../Sections.styles';

import useStyles from './PlanSection.styles';

type Props = {
  wordsLeft?: number;
  isLatestRecordSelected?: boolean;
  recordIsUnlimited?: boolean
};

const TotalWords: FC<Props> = ({ wordsLeft, isLatestRecordSelected, recordIsUnlimited }) => {
  const sectionClasses = useSectionStyles();
  const classes = useStyles();

  const history = useHistory();

  const { currentCompany, currentUser } : {
    currentCompany: any,
    currentUser: any
  } = AuthContext.useAuth();

  const isOnTrial = currentUser?.onFreePlan;
  const isSelfServeEnterprise = currentUser.onSelfServeEnterprisePlan;
  const isUnlimited = currentUser?.isUnlimitedWordsPlan
  || currentCompany?.billing?.addons?.unlimitedCredits?.quantity
  || recordIsUnlimited;
  const isBuyMoreButtonAvailable = !isUnlimited && !isSelfServeEnterprise && isLatestRecordSelected;

  const handleBuyMoreClick = () => {
    history.push('/addons');
  };

  return (
    <article className={sectionClasses.articleContainer}>
      <div style={{ width: '100%' }}>
        <Typography classes={{ root: sectionClasses.articleTitle }}>
          Words Remaining

          {/* @ts-expect-error SimpleTooltip is not .ts yet */}
          <SimpleTooltip
            content="The number of words remaining in your current billing cycle."
            wrapped
          >
            <IconInfoCircle size={16} />
          </SimpleTooltip>
        </Typography>

        <div className={classes.valueWithButton}>
          {/* @ts-expect-error TruncatedText is not .ts yet */}
          <TruncatedText classes={{ root: sectionClasses.articleValue }}>
            {isUnlimited ? 'Unlimited' : wordsLeft?.toLocaleString()}
          </TruncatedText>

          {isBuyMoreButtonAvailable && (
            <Button
              onClick={handleBuyMoreClick}
              className={classes.buyMoreButton}
              disabled={isOnTrial}
            >
              <IconPlus />
            </Button>
          )}
        </div>
      </div>
    </article>
  );
};

export default TotalWords;
