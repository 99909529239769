import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import useFolder from '@root/resources/folder/useFolder';
import { getParamByName } from '@root/utils/uri';
import AuthContext from '@root/resources/auth/auth.context';
import CreateButtonNew from '@root/views/Dashboard/components/CreateButtonNew/CreateButtonNew';

import { selectionTypes, useDashboard } from '../dashboardContext';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontFamily: 'Inter,sans-serif',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '39px',

    textAlign: 'center',

    marginBottom: 8,
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',

    fontFamily: 'Inter,sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',

    maxWidth: '430px',

    marginBottom: '24px',
  },

  startIcon: {
    width: 16,
    height: 16,
    strokeWidth: 3,
  },

  createNewButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.neutrals600,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 24px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
}));

const CATEGORY_TEXT = {
  ALL: 'Create a new file or project to get started!',
  UNCATEGORIZED: 'All of your uncategorized files will appear here.',
  BOOKMARKS: 'All of your bookmarked files will appear here.',
  WORKFLOW: 'There are no assigned items in your workflow queue.',
};

const transformCategory = (data) => {
  if (!data) return 'ALL';
  if (data.includes('uncategorized')) return 'UNCATEGORIZED';
  if (data === 'bookmarks') return 'BOOKMARKS';
  if (data === 'workflow') return 'WORKFLOW';
  return null;
};

const FilesEmpty = () => {
  const { state: { selected: { type }, filters: { folderId, workflowStatuses } } } = useDashboard();
  const { data: folder } = useFolder(folderId);
  const { currentUser } = AuthContext.useAuth();
  const location = useLocation();
  const classes = useStyles();

  const { newFilesPage } = currentUser.featureToggles;
  const category = transformCategory(getParamByName('selected', location));
  const isSubFolder = (type === selectionTypes.FOLDER && folder?.parentFolderId !== null);

  const text = React.useMemo(() => {
    if (workflowStatuses.length !== 0) {
      return {
        header: '',
        content: 'There are no matching files with the selected workflow queue status.',
      };
    }

    if (category) {
      return {
        header: 'This section is empty',
        content: CATEGORY_TEXT[category],
      };
    }

    const projectText = isSubFolder ? 'folder' : 'project';
    const subunitText = isSubFolder ? 'file' : 'file / folder';
    const subunitTextPlural = isSubFolder ? 'files' : 'files / folders';

    return {
      header: `This ${projectText} is empty.`,
      content: `Create a ${subunitText} or drag and drop existing ${subunitTextPlural} into this ${projectText}.`,
    };
  }, [workflowStatuses.length, category, isSubFolder]);

  const headerText = `Hi ${currentUser.firstName}, getting started is easy`;

  const contentText = (
    <>
      <span>It&apos;s time to write something amazing! Let your creativity flow and</span>
      <span> create something that you&apos;re proud of.</span>
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.headerText}>
          {newFilesPage ? headerText : text.header}
        </div>
        <div className={classes.contentText}>
          {newFilesPage ? contentText : text.content}
        </div>

        {newFilesPage && <CreateButtonNew />}
      </div>
    </div>
  );
};

export default FilesEmpty;
