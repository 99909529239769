import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useDeletePromptItem() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }) => api.promptItem.remove({ id }),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries('promptItems.list');

        return response;
      },
    },
  );
}
