import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  addonsCard: {
    minHeight: '180px',
    maxHeight: '300px',
    overflow: 'auto',
  },

  addonsList: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 1fr',
    gap: '12px',
  },

  addonsHeader: {
    display: 'contents',
  },

  headerText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '30px',
    color: theme.palette.colors.neutrals300,
  },

  addonItem: {
    display: 'contents',
    paddingBottom: '12px',
    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,

    '&:not(:last-child)': {
      marginBottom: '12px',
    },
  },

  addonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '30px',
    color: theme.palette.colors.neutrals600,
  },

  billed: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '30px',
    color: theme.palette.colors.neutrals600,
  },

  divider: {
    gridColumn: '1/-1',
    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,
  },

  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 11,
  },

  cancelButton: {
    textDecoration: 'underline',
  },

  emptyStateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    gap: 4,
  },
  emptyStateTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals600,
  },
  emptyStateLink: {
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.blue400,
    textDecoration: 'underline',
  },
}));
