/* eslint-disable no-unused-vars */

import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import WizardLayout from '@root/views/Layout/WizardLayout';

// import StartForm from './components/StartForm';
// import GenerationCard from './components/GenerationCard';
// import HintBlock from './components/HintBlock';
// import ExpandedOutlines from './components/ExpandedOutlines';

import useStyles from './TemplateBuilder.styles';
import TemplateForm from './components/TemplateForm';

const steps = ['General info', 'Prompt & inputs', 'Experiments'];

const TemplateBuilder = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { templateId } = useParams();

  const params = new URLSearchParams(history.location.search);
  const initialStep = Number(params.get('step'));
  const [step, setStep] = useState(initialStep || 0);
  // const [settings, setSettings] = useState({
  //   temperature: 0.7,
  //   tokens: 50,
  // });
  const classes = useStyles();
  // const {
  //   mutateAsync: generate, isLoading: isGenerating,
  // } = aiResource.useGenerateBlogKickstarter();
  // const {
  //   mutateAsync: createFile, isLoading: isFileCreating,
  // } = fileResource.useCreateFile();
  // const {
  //   mutateAsync: createDocument, isLoading: isDocCreating,
  // } = documentResource.useCreateDocument();

  return (
    <WizardLayout
      steps={steps}
      step={step}
      onStepChange={setStep}
      nextAvailable={false}
      prevAvailable={step > 0}
    >
      <TemplateForm
        step={step}
        onStepChange={setStep}
        // formRef={formRef}
      />
    </WizardLayout>
  );
};

export default TemplateBuilder;
