import * as React from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import { IconPlus } from '@tabler/icons-react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './SimpleAvatarGroup.styles';

const SimpleAvatarGroup = React.forwardRef(({
  children: childrenProp,
  classes: externalClasses,
  className,
  max = 4,
  spacing = 'medium',
  extraAvatarTooltipContent = '',
  ...props
}, ref) => {
  const classes = useStyles();
  const spacings = {
    small: -16,
    medium: -8,
  };

  const children = React.Children.toArray(childrenProp).filter((child) => {
    return React.isValidElement(child);
  });

  const extraAvatars = children.length > max ? children.length - max : 0;

  const marginLeft = spacing && spacings[spacing] !== undefined ? spacings[spacing] : -spacing;

  return (
    // root of avatar group
    <div className={clsx(classes.root, className, externalClasses?.root)} ref={ref} {...props}>
      {children.slice(0, children.length - extraAvatars).map((child, index) => {
        // avatars inside group
        return React.cloneElement(child, {
          className: clsx(classes?.avatar, externalClasses?.avatar, child.props.className),
          style: {
            zIndex: children.length - index,
            marginLeft: index === 0 ? undefined : marginLeft,
            ...child.props.style,
          },
        });
      })}

      {extraAvatars ? (
        <SimpleTooltip
          content={extraAvatarTooltipContent}
          interactive
        >
          <Avatar
            className={clsx(classes.avatar, classes.lastAvatar, externalClasses?.avatar)}
            style={{
              zIndex: 0,
              marginLeft,
            }}
          >
            <IconPlus width={12} height={12} />
          </Avatar>
        </SimpleTooltip>
      ) : null}
    </div>
  );
});

export default SimpleAvatarGroup;
