import { makeStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  tablesWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableWrap: {
    width: 'calc(50% - 8px)',
  },
  exampleTextWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  createButton: {
    marginBottom: theme.spacing(2),
  },
  exampleType: {
    fontWeight: 700,
    fontSize: 16,
  },
  exampleTypeGood: {
    color: green[500],
  },
  exampleTypeBad: {
    color: red[500],
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
