import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
  },
  gapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  fieldsWrapper: {
    padding: '20px 32px',
  },
  button: {
    alignSelf: 'center',
    minWidth: 112,
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'sticky',
    bottom: 0,
    margin: '16px 0',
  },
  exampleImage: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  exampleImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingLeft: '32px',
    paddingRight: '18px',
    position: 'relative',
  },
  exploreButton: {
    cursor: 'pointer',
    color: theme.palette.colors.neutrals600,
    paddingLeft: '8px',
    textDecoration: 'underline',
  },
  exploreText: {
    padding: '0px 32px 20px 32px',
  },
  scrollable: {
    overflowY: 'auto',
  },
  addOnCTA: {
    backgroundColor: theme.palette.colors.green200,
    border: `1px solid ${theme.palette.colors.green400}`,
    borderRadius: '6px',
    color: theme.palette.green600,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '12px',
  },
  addOnCTAClose: {
    cursor: 'pointer',
  },
  addOnCTAHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500,
    justifyContent: 'space-between',
  },
  purchaseAddOn: {
    color: `${theme.palette.colors.green600} !important`,
  },
}));
export default useStyles;
