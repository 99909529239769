import React from 'react';
import { useHistory } from 'react-router-dom';

import config from '@config';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

const ExternalSourceText = ({ link, getText }) => {
  const { currentExternalSource: { name } } = externalSourcesContext.useExternalSource();
  const history = useHistory();
  const hootSuiteLink = `${config.appUrl}${history.location.pathname}`;
  const text = getText ? getText({ link: link || hootSuiteLink, externalSourceName: name }) : (
    <>
      <a
        href={link || hootSuiteLink}
        target="_blank"
        rel="noreferrer"
      >
        Open your Copysmith web app
      </a>
      &nbsp;to use the full suite of integration features.
    </>
  );

  return text;
};

export default ExternalSourceText;
