import { HttpClient } from "../../HttpClient";
import { RequestOptions } from "../../RequestOptions";

export interface GenerateSerpOptions extends RequestOptions {
  content: string;
  lang: string;
  country: string;
  documentId: string;
}

export class Frase {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async generateSerp(data: GenerateSerpOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/generateSerp", data);

    return result;
  }

  public async generateKeywords(data: GenerateSerpOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/generateKeywords", data);

    return result;
  }

  public async getFile(fileId: string, data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/documents/${fileId}`, data);

    return result;
  } 

  public async removeFile(fileId: string, data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del(`/documents/${fileId}`, data);

    return result;
  }
}
