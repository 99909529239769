import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreatePromptItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (variables) => api.promptItem.create(variables),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries('promptItems.list');

        return response;
      },
    },
  );
}
