import React from 'react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';

import useStyles from './ImproveWriting.styles';

const ImproveWritingButton = ({ state }) => {
  const classes = useStyles();

  const ChevronIcon = state ? IconChevronUp : IconChevronDown;

  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant="text"
        className={classes.button}
        id="button-improveWriting"
      >
        Improve Writing
        <ChevronIcon className={classes.chevron} />
      </Button>
    </div>
  );
};

export default ImproveWritingButton;
