import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { InputField, FormField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import useCreateCatalogItem from '@root/resources/catalogItem/useCreateCatalogItem';

import useStyles from './ProductUrlForm.styles';

const ProductUrlForm = () => {
  const { id: catalogId } = useParams();
  const { history } = useHistory();
  const classes = useStyles();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { isLoading, mutateAsync } = useCreateCatalogItem();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const submitCatalog = async ({ url }) => {
    const response = await mutateAsync({
      url,
      catalogId,
    });

    if (response.isBadRequest) {
      return;
    }

    closeModal();
  };

  const addProductFromUrlSchema = Yup.object({
    url: Yup.string().required('URL is required').max(200, 'URL must be 200 characters or less.'),
  });

  return (
    <Formik
      initialValues={{
        url: '',
      }}
      onSubmit={submitCatalog}
      validationSchema={addProductFromUrlSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormField
              name="url"
              label="Add Product using a URL"
              placeholder="https://www.amazon.com/Savage-Sheer-Sleep-Forest-Green/dp/B0BHC1G53C/"
              component={InputField}
              required
            />
            <div className={classes.actions}>
              <Button variant="outlined" type="button" onClick={closeModal}>Cancel</Button>
              <Button
                type="submit"
                className={classes.submit}
                disabled={isLoading || isSubmitting}
              >
                Add Product
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductUrlForm;
