import React from 'react';

import useStyles from './PlanCardView.styles';
import PlanCard from './components/PlanCard';

const PlanCardView = ({ period, pricingData }) => {
  const classes = useStyles();

  return (
    <div className={classes.planSelectionRoot}>
      <div className={classes.plansWrapper}>
        {pricingData.map((plan) => {
          return (
            <PlanCard
              key={plan.rank}
              plan={plan}
              period={period}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PlanCardView;
