import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
    '& tr:last-child td': {
      border: 'none',
    },
    borderCollapse: 'separate',
    [theme.breakpoints.down('md')]: {
      minWidth: 1200,
    },
    borderSpacing: '0px 8px',
  },
  stickyHeader: {
    top: '0',
    zIndex: '2',
    position: 'sticky',
    backgroundColor: theme.palette.colors.white,
  },
  controls: {
    width: 48,
  },
  tableRow: {
    borderRadius: '6px',
    height: 53,
    borderSpacing: '0px 8px',
    '&&:hover': {
      border: `1px solid ${theme.palette.colors.grey}`,

      '& td': {
        borderTop: `1px solid ${theme.palette.colors.grey}`,
        borderBottom: `1px solid ${theme.palette.colors.grey}`,
      },
      '& td:first-child': {
        borderLeft: `1px solid ${theme.palette.colors.grey}`,
      },
      '& td:last-child': {
        borderRight: `1px solid ${theme.palette.colors.grey}`,
      },
    },
  },
  editTableRow: {
    border: 'none',
  },
  tableRowOpen: {
    borderSpacing: '0',
    marginBottom: 0,
    border: `1px solid ${theme.palette.colors.grey}`,

    '& td': {
      borderTop: `1px solid ${theme.palette.colors.grey}`,
      borderBottom: `1px solid ${theme.palette.colors.grey}`,
    },
    '& td:first-child': {
      borderLeft: `1px solid ${theme.palette.colors.grey}`,
    },
    '& td:last-child': {
      borderRight: `1px solid ${theme.palette.colors.grey}`,
    },
  },
  newCell: {
    border: 'none',
  },
  alignRight: {
    textAlign: 'right',
  },
  rowCellOpen: {
    '&&:first-child': {
      borderBottomLeftRadius: '0px !important',
    },
    '&&:last-child': {
      borderBottomRightRadius: '0px !important',
    },
  },
  rowCell: {
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    border: 'none',
    cursor: 'pointer',
    '&&:first-child': {
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
    },
    '&&:last-child': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
  },
  tableBody: {
    borderSpacing: '0px 8px',
  },
  tableAction: {
    backgroundColor: '#F7F7F8',
    padding: '0.5rem',
    display: 'inline',
    borderRadius: '8px',
  },
}));

export default useStyles;
