import { useQuery } from 'react-query';

import api from '@root/api';

export default function useGetCatalog(data) {
  return useQuery(
    ['activity'],
    () => api.activity.getActivity(data), {
      refetchOnMount: 'always',
    },
  );
}
