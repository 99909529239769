import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,
  },
}));

export default useStyles;
