import React from 'react';
import { IconPalette } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';
import { GradientSvg3 } from '@root/assets/cardGradients';

import useStyles from './TemplateCard.styles';

const TEMPLATE_TYPE = 'freeFormEditor';

const EditorCard = () => {
  const classes = useStyles();

  const { handleCreate } = useTemplatesDashboard();

  const handleCreateClick = () => {
    handleCreate(TEMPLATE_TYPE);
  };

  return (
    <div
      className={classes.card}
      style={{ backgroundImage: `url(${GradientSvg3})`, cursor: 'pointer' }}
      onClick={handleCreateClick}
    >
      <div>
        <div className={classes.topActions}>
          <div className={classes.icon}>
            <IconPalette />
          </div>
        </div>

        <div style={{ flexGrow: 0 }}>
          <h2 className={classes.title}>
            Editor
          </h2>
          <TruncatedText
            multiline
            maxLines={2}
            className={classes.description}
            style={{ cursor: 'unset' }}
            withoutTooltip
          >
            Our new editor allows you to create content for multiple uses cases in one document. You can
            also rewrite, extend, or enhance any existing copy you already have.
          </TruncatedText>
        </div>
      </div>

      <div className={classes.bottomActions}>
        <Button
          className={classes.createButton}
          id="create-button"
          size="small"
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default EditorCard;
