export default {
  title: 'SEO Metatag',
  companyName: 'Candlesmith',
  companyUrl: 'www.url.com',
  description: 'SEO-optimize your site in an instant.',
  input: [
    {
      title: 'Company Name',
      value: 'Promix Nutrition',
    },
    {
      title: 'Page URL',
      value: 'promixnutrition.com',
    },
    {
      title: 'Company Description',
      value:
        'Promix Nutrition sells whey protein supplements made with all-natural ingredients.',
    },
    {
      title: 'Target Keywords',
      value: 'Natural protein supplements',
    },
  ],
  output: {
    _id: '1',
    templateType: 'seoMetatag',
    liked: false,
    content: [
      'Natural Protein & Supplements Store | Promix Nutrition®',
      'Promix Nutrition natural supplements contain only the purest ingredients. Buy high quality, high performance, natural protein supplements & more at our online supplements store!',
    ],
  },
  fileData: { url: 'promixnutrition.com' },
};
