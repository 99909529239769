import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  thead: {
    backgroundColor: theme.palette.colors.neutrals50,
    border: 'none',
  },
  theadRoot: {
    '&$active': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
  id: {
    width: '10%',
  },
  title: {
    width: '55%',
  },
  lastUpdated: {
    width: '12.5%',
  },
  status: {
    width: '12.5%',
  },
}));

export default useStyles;
