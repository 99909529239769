import React from 'react';

import { useTemplatesDashboard, actions } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import SearchField from '@root/components/form/SearchField';

import useStyles from './TemplatesList.styles';

const TemplateSearch = () => {
  const classes = useStyles();
  const { dispatch, state: { query, isModal } } = useTemplatesDashboard();

  return (
    <SearchField
      placeholder="Search for a template or topic"
      value={query || ''}
      debounce
      onChange={(q) => {
        dispatch({
          type: isModal ? actions.MODAL_SEARCH : actions.SEARCH,
          payload: {
            query: q,
          },
        });
      }}
      classNames={{ container: classes.searchContainer }}
      style={{ maxWidth: 500 }}
    />
  );
};

export default TemplateSearch;
