const modalsActions = Object.freeze({
  TOGGLE_SHARE_TO_COMMUNITY_MODAL: 'TOGGLE_SHARE_TO_COMMUNITY_MODAL',
  TOGGLE_REMOVE_FROM_COMMUNITY_MODAL: 'TOGGLE_REMOVE_FROM_COMMUNITY_MODAL',
  TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL: 'TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL',
  TOGGLE_PREVIEW_TEMPLATE_MODAL: 'TOGGLE_PREVIEW_TEMPLATE_MODAL',
  TOGGLE_REMOVE_FROM_LIBRARY_MODAL: 'TOGGLE_REMOVE_FROM_LIBRARY_MODAL',
});

const initialModalsState = {
  shareToCommunity: {
    open: false,
    template: null,
  },
  removeFromCommunity: {
    open: false,
    templateId: null,
  },
  saveToMyAccount: {
    open: false,
    templateId: null,
  },
  removeFromLibrary: {
    open: false,
    templateId: null,
  },
  previewTemplate: {
    open: false,
    template: null,
  },
};

const confirmationModalsReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case modalsActions.TOGGLE_SHARE_TO_COMMUNITY_MODAL:
        return {
          ...state,
          shareToCommunity: {
            open: !state.shareToCommunity.open,
            template: action.payload,
          },
        };

      case modalsActions.TOGGLE_REMOVE_FROM_COMMUNITY_MODAL:
        return {
          ...state,
          removeFromCommunity: {
            open: !state.removeFromCommunity.open,
            template: action.payload,
          },
        };

      case modalsActions.TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL:
        return {
          ...state,
          saveToMyAccount: {
            open: !state.saveToMyAccount.open,
            templateId: action.payload,
          },
        };

      case modalsActions.TOGGLE_REMOVE_FROM_LIBRARY_MODAL:
        return {
          ...state,
          removeFromLibrary: {
            open: !state.removeFromLibrary.open,
            templateId: action.payload,
          },
        };

      case modalsActions.TOGGLE_PREVIEW_TEMPLATE_MODAL:
        return {
          ...state,
          previewTemplate: {
            open: !state.previewTemplate.open,
            template: action?.payload || state?.previewTemplate?.template,
          },
        };
      default: {
        throw new Error(`Community Modals Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

export { confirmationModalsReducer, modalsActions, initialModalsState };
