import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    width: '1000px',
    maxWidth: '95%',
    margin: '2rem auto -1rem',
  },
});

export default useStyles;
