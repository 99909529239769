import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bookmarkIcon: {
    color: theme.palette.colors.neutrals300,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },

  bookmarkedIcon: {
    fill: theme.palette.colors.neutrals600,
    color: theme.palette.colors.neutrals600,

    '&:hover': {
      fill: theme.palette.colors.neutrals400,
      color: theme.palette.colors.neutrals400,
    },
  },

  disabled: {
    pointerEvents: 'none',
    color: theme.palette.colors.neutrals200,
  },

}));

export default useStyles;
