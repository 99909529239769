import React from 'react';
import { useSpring, animated } from 'react-spring';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { IconX } from '@tabler/icons-react';

import XIcon from '@root/assets/times-icon.svg';
import TooltipIcon from '@root/components/tooltips/TooltipIcon';
import { isEditor } from '@root/views/Editor/Editor.helpers';

const useStyles = makeStyles((theme) => ({
  pillAtom: {
    fontSize: 12,
    fontWeight: 500,
    padding: '4px 4px 4px 8px',
    marginRight: '10px',
    borderRadius: 6,
    backgroundColor: ({ warning }) => (warning ? theme.palette.colors.pink600 : theme.palette.colors.neutrals100),
    color: ({ warning }) => warning && theme.palette.colors.white,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pillIcon: {
    marginLeft: 6,
    cursor: ({ disabled }) => !disabled && 'pointer',
  },
  pillInfo: {
    marginLeft: 5,
    color: theme.palette.colors.white,
  },
}));

const useLegacyStyles = makeStyles((theme) => ({
  pillAtom: {
    fontSize: '14px',
    padding: '2px 5px 2px 12px',
    marginRight: '10px',
    borderRadius: theme.border.radius.default,
    border: `1px solid ${theme.palette.colors.grey}`,
    backgroundColor: 'white',
    cursor: 'default',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: ({ disabled }) => !disabled && '#523ae710',
    },
  },
  pillIcon: {
    color: theme.palette.primary.main,
    width: '15px',
    marginLeft: '10px',
    cursor: ({ disabled }) => !disabled && 'pointer',
  },
  pillInfo: {
    color: theme.palette.colors.yellow400,
  },
}));

/**
 *
 * @param {obj} props
 * @param {function} props.handleRemove optional function replacing removeState if specified which
 *    allows a higher component to handle removal. Passes pill "name" as sole argument into the
 *    handleRemove function.
 * @returns
 */
const Pill = ({
  name, removeState, className, handleRemove = null, disabled, warning,
}) => {
  const classes = isEditor
    ? useStyles({ disabled, warning }) // eslint-disable-line react-hooks/rules-of-hooks
    : useLegacyStyles({ disabled, warning }); // eslint-disable-line react-hooks/rules-of-hooks

  const remove = () => {
    if (handleRemove) {
      handleRemove(name);
    } else {
      removeState((s) => s.filter((e) => e !== name));
    }
  };

  const animationProps = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: 24 },
  });

  return (
    <animated.div style={animationProps}>
      <div className={clsx(classes.pillAtom, className)}>
        <span>{name}</span>
        <TooltipIcon
          className={classes.pillInfo}
          content={warning}
        />
        {isEditor ? (
          <IconX
            size={14}
            className={classes.pillIcon}
            onClick={disabled ? undefined : remove}
          />
        ) : (
          <img
            alt="remove"
            className={classes.pillIcon}
            src={XIcon}
            onClick={disabled ? undefined : remove}
          />
        )}

      </div>
    </animated.div>
  );
};

export default Pill;
