import React from 'react';
import clsx from 'clsx';

import useStats from '@root/resources/usage/useStats';
import templatesContext from '@root/resources/templates/templates.context';

import useStyles from './Stats.styles';
import StatsCard from './StatsCard';

const Stats = () => {
  const classes = useStyles();

  const { data: { daysSinceSignup, mostUsedTemplate, totalWords } = {}, isFetching } = useStats();
  const { templates } = templatesContext.useTemplates();
  const template = templates[mostUsedTemplate?.templateType];

  return (
    <div className={classes.statsRoot}>
      <StatsCard
        className={!isFetching && classes.pink}
        isLoading={isFetching}
      >
        <div className={clsx(classes.cardContentWrapper, classes.useCaseWrapper)}>
          <div>
            You’re top
            <br />
            use case was:
            <span className={classes.value}>{template?.title}</span>
          </div>
          <div>
            <span style={{ alignSelf: 'center' }}>with</span>
            <span className={classes.value}>
              {mostUsedTemplate?.count}
              &nbsp;number of times
            </span>
          </div>
        </div>
      </StatsCard>

      <StatsCard
        className={!isFetching && classes.blue}
        isLoading={isFetching}
      >
        <div className={clsx(classes.cardContentWrapper, classes.totalWordsWrapper)}>
          You&apos;ve generated:
          <span className={classes.value}>
            {totalWords?.toLocaleString()}
            <br />
            number of words
          </span>
        </div>
      </StatsCard>

      <StatsCard
        className={!isFetching && classes.purple}
        isLoading={isFetching}
      >
        <div className={clsx(classes.cardContentWrapper, classes.daysWrapper)}>
          We’ve been
          <br />
          through a lot:
          <span className={classes.value}>
            these past
            <br />
            {daysSinceSignup}
            &nbsp;days
          </span>
        </div>
      </StatsCard>
    </div>
  );
};

export default Stats;
