import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: grey[200],
    border: `1px dashed ${grey[600]}`,
    height: 200,
    flexGrow: '1',
    margin: `${theme.spacing(2)}px 0 0`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: grey[600],
    transition: 'box-shadow 0.3s',

    '& svg': {
      pointerEvents: 'none',
    },
  },
  onDragover: {
    boxShadow: `inset 0 0 8px 0px ${grey[600]}`,
  },
  content: {
    display: 'flex',
  },
  fileInfo: {
    textAlign: 'center',
  },
  button: {
    background: theme.palette.colors.white,
    marginTop: '0.825rem',
  },
}));

export default useStyles;
