import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    ...theme.typography.h6,
  },
  description: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
  },
  lightgrey: {
    color: theme.palette.grey.pure,
  },
  darkgrey: {
    color: theme.palette.colors.darkGrey,
  },
  buttonContainer: {
    display: 'flex',
    paddingTop: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1.5),
  },
  submitButtonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  wide: {
    width: '100%',
  },
  rightPadding: {
    paddingRight: '20px',
  },
  cell: {
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },

  fieldSectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldIndex: {
    fontWeight: 600,
  },
  fieldViewWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    fontSize: theme.font.size.sm,
    color: theme.palette.colors.red,
  },
}));
