import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import FormGroup from '@root/components/form/FormGroup';
import Button from '@root/components/buttons/Button';
import useCreateFolder from '@root/resources/folder/useCreateFolder';
import useFolders from '@root/resources/folder/useFolders';
import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { InputField, CheckboxField, FormField, SelectField } from '@root/components/form';

import PageEnum from './PageEnumFolder';

const validationSchema = yup.object({
  title: yup
    .string()
    .min(1, 'Folder name is required')
    .max(100, 'Folder name cannot exceed 100 characters')
    .required('Folder name is required'),
  addPresets: yup
    .bool(),
});

const NewFolderForm = ({
  closeModal, classes, setFolderName, setParentFolderId, initialParentFolderId, setPage,
}) => {
  const { mutateAsync: createFolder, isLoading: isCreatingFolder } = useCreateFolder();
  const { data: folders } = useFolders();
  const { dispatch } = useDashboard();

  const rootFolders = folders.filter((f) => {
    if (f.parentFolderId) {
      return false;
    }
    if (f.home) {
      return false;
    }
    return true;
  });

  const parentFolderOptions = [
    ...rootFolders.map((f) => ({
      value: f.id,
      label: f.folderName,
    })),
  ];

  const submit = async (values, formActions) => {
    const { title, addPresets, parentFolderId } = values;

    if (addPresets) {
      setFolderName(title);
      setParentFolderId(parentFolderId);
      setPage(PageEnum.PRESETS);
    } else {
      formActions.setSubmitting(isCreatingFolder);
      const newFolder = await createFolder({ title, parentFolderId });
      dispatch({
        type: actions.SELECT_FOLDER,
        payload: {
          folderId: newFolder._id,
        },
      });
      closeModal();
    }
  };

  return (
    <div className={classes.newFolderFormRoot}>
      <Formik
        initialValues={{
          title: '',
          addPresets: true,
          parentFolderId: initialParentFolderId,
        }}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({
          values, handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormField
                name="title"
                component={InputField}
                label="Folder name"
                placeholder="e.g. Holiday Sale Campaign"
                required
              />
              <FormField
                component={SelectField}
                options={parentFolderOptions}
                name="parentFolderId"
                label="Parent project"
                required
              />
              <FormField
                component={CheckboxField}
                name="addPresets"
                label="Add folder settings"
                labelTooltip="Apply settings to files inside the folder."
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
            </FormGroup>

            <div className={classes.buttonsContainer}>
              <Button
                type="submit"
                disabled={!values.title}
              >
                {values.addPresets ? 'Next' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewFolderForm;
