import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
  },
  page: {
    backgroundColor: '#fff',
    minWidth: '600px',
    width: '660px',
    // marginTop: theme.spacing(4),
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    padding: theme.spacing(3.5, 0),
    margin: theme.spacing(0, 0, 0, 3),
  },
  pageWrap: {
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
    padding: theme.spacing(4, 0, 0, 0),
    transform: `translate(${-theme.spacing(22.75)}px, 0px)`,
    justifyContent: 'center',
    '@media (max-width:1439px)': {
      transform: 'none',
      justifyContent: 'flex-start',
    },
  },
  inFileSidebarWrap: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: theme.spacing(6),
    zIndex: 10,
  },
  leftToolbarComponent: {
    width: `calc((100vw - 660px)/2 + ${theme.spacing(10.25)}px)`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '@media (max-width:1439px)': {
      width: theme.spacing(13),
    },

    '& button': {
      width: 28,
      height: 24,
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',

      '&:hover': {
        color: '#06c',
        backgroundColor: `${theme.palette.colors.primaryFaded} !important`,
      },

      '& svg': {
        width: 18,
        height: 18,
      },
    },
  },
  workflowQueueWrapper: {
    display: 'flex',
    '& > :not(:last-child)': {
      marginRight: 12,
    },
  },
  modalActions: {
    marginTop: theme.spacing(1),
  },
  expandedContentPreview: {
    fontWeight: 'bold',
    color: 'coral',
  },
  contentPreviewContent: {
    width: '60%',
  },
  contentPreviewRow: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '220px',
  },
  menuItemIcon: {
    marginRight: theme.spacing(2),
  },
  menu: {
    marginLeft: theme.spacing(1.5),
  },
  collapsedSidebar: {
    width: theme.spacing(6),
  },
}));
