import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { FolderOptionsKebab, BookmarkFolder } from '@root/views/Dashboard/components/IconButtons';
import uiNotificationService from '@root/services/uiNotification.service';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import ItemTypes from '@root/utils/dndItemTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    cursor: 'pointer',
    overflow: 'hidden',
    marginBottom: '0.25rem',
    alignItems: 'center',
    padding: '3px',
    height: '2rem',
    marginLeft: '2.125rem',
  },
  active: {
    '&&': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  hover: {
    '&&': {
      border: '1px solid black',
    },
  },
  folderTitle: {
    fontSize: '0.875rem',
    color: '#000',
    flexGrow: '1',
    '&:hover': {
      color: '#5b5b5b',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  icon: {
    marginRight: '0.8rem',
    fontSize: '1.3rem',
    color: theme.palette.colors.darkGrey,
  },
  iconContainer: {
    display: 'flex',
  },
  rotateRight: {
    transform: 'rotate(-90deg)',
  },
  transition: {
    transition: 'transform 0.4s',
  },
  hidden: {
    display: 'none',
  },
  bookmarkContainer: {
    fontSize: '1.3rem',
  },
  listIcon: {
    marginRight: '1rem',
    fontSize: '0.8rem',
    color: theme.palette.colors.darkGrey,
  },
  useDrop: {
    boxSizing: 'border-box',
    border: '1px solid #666',
    borderRadius: '3px',
    padding: '3px',
  },
  isDraggingOver: {
    background: '#ddd',
    borderColor: 'transparent',
    color: '#000',
  },
}));

const ChildFolderListItem = ({
  folder, isDraggable = false,
}) => {
  const [hover, setHover] = useState(false);
  const {
    state: {
      filters: { folderId: currentFolderId },
    }, dispatch,
  } = useDashboard();
  const classes = useStyles();
  const ref = useRef(null);

  const isActive = folder.id === currentFolderId;

  const { mutateAsync: updateFile } = useUpdateFile();

  const moveFileHere = async (fileId) => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move file to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`File has been moved to ${folder.title}`);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.FILE_CARD,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
    drop: async (item) => {
      moveFileHere(item.fileId);
    },
  });

  const drag = useDrag({
    type: ItemTypes.FOLDER_CARD,
    item: { folderId: folder._id },
  })[1];

  const handleSelectFolder = () => dispatch({
    type: actions.SELECT_FOLDER,
    payload:
      {
        title: folder.title,
        folderId: folder._id,
      },
  });

  if (isDraggable) {
    drag(drop(ref));
  }

  return (
    <div
      className={classes.root}
      onClick={handleSelectFolder}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
    >
      <div className={classes.listIcon}>•</div>
      <div className={clsx(classes.folderTitle, {
        [classes.active]: isActive && !isOver,
        [classes.useDrop]: canDrop && isDraggable,
        [classes.isDraggingOver]: isOver && isDraggable,
      })}
      >
        { folder.title }
      </div>
      <div className={clsx({ [classes.hidden]: !hover })}>
        <FolderOptionsKebab folder={folder} variant="sidebar" />
      </div>
      <div className={clsx(classes.bookmarkContainer,
        { [classes.hidden]: !hover && !folder.liked })}
      >
        <BookmarkFolder folderId={folder._id} liked={folder.liked} />
      </div>
    </div>
  );
};

export default ChildFolderListItem;
