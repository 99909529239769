import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import useStyles from './ProductUploadPreviewModal.styles';

const ProductUploadPreviewModal = ({ onClose, rows = [], title }) => {
  const classes = useStyles();

  const [headers, ...files] = rows;
  const handleClose = () => {
    onClose();
  };

  return (
    <ConfirmationModal
      title={title}
      onConfirm={handleClose}
      onClose={handleClose}
      noCancel
      maxWidth="lg"
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                return (
                  <TableCell classNames={classes.cell}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((cells) => {
              return (
                <TableRow>
                  {cells.map((cell) => {
                    return (
                      <TableCell classNames={classes.cell}>
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfirmationModal>
  );
};

export default ProductUploadPreviewModal;
