import React from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import pluralize from 'pluralize';

import useGetCurrentBulkJob from '@root/resources/akeneo/useGetCurrentBulkJob';
import { InputField, SelectField } from '@root/components/form';
import useUpdateBulkProductDescription from '@root/resources/akeneo/useUpdateBulkProductDescription ';
import useGetChannels from '@root/resources/akeneo/useGetChannels';
import AkeneoDescriptionFieldSelect from '@root/components/AkeneoDescriptionFieldSelect/AkeneoDescriptionFieldSelect';

import useStyles from './Result.styles';
import BottomToolbar from './BottomToolbar';

const findSameItems = (arrays) => {
  if (arrays.length <= 1) {
    return arrays[0] || [];
  }

  const firstArray = arrays[0];
  const remainingArrays = arrays.slice(1);

  return firstArray.filter((string) => {
    return remainingArrays.every((array) => array.includes(string));
  });
};

const BodyCell = ({ children }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.bodyCell}>
      <div className={classes.bodyCellContent}>
        {children}
      </div>
    </TableCell>
  );
};

const Result = ({
  onBack,
  onPublish,
}) => {
  const classes = useStyles();

  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [productCodeToEdit, setProductCodeToEdit] = React.useState(null);
  const [descriptionToEdit, setDescriptionToEdit] = React.useState(null);
  const [selectedChannels, setSelectedChannels] = React.useState([]);
  const [selectedLocale, setSelectedLocale] = React.useState(null);
  const [selectedField, setSelectedField] = React.useState(null);

  const { data: { data: channels = [] } = {} } = useGetChannels();

  const onChannelChange = (arg) => setSelectedChannels(Array.isArray(arg) ? arg : arg.target.value);

  const onLocaleChange = (arg) => setSelectedLocale(arg.target.value);

  const onFieldChange = (arg) => setSelectedField(arg.target.value);

  const locales = findSameItems(
    channels.filter(
      (channel) => selectedChannels.includes(channel.code),
    ).map((channel) => channel.locales),
  );

  const {
    mutateAsync: updateDescription,
  } = useUpdateBulkProductDescription();

  const publish = async () => {
    try {
      setIsLoading(true);
      await onPublish({
        productCodes: selectedProducts,
        channels: selectedChannels,
        locale: selectedLocale,
        field: selectedField,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onEdit = (product) => (e) => {
    e.stopPropagation();

    setProductCodeToEdit(product.code);
    setDescriptionToEdit(product.row.result.documents[0].content.blocks[0].data.text);
  };
  const onEditCancel = () => {
    setProductCodeToEdit(null);
    setDescriptionToEdit(null);
  };
  const onEditSave = async () => {
    try {
      setIsLoading(true);
      await updateDescription({
        productCode: productCodeToEdit,
        description: descriptionToEdit,
      });
    } finally {
      setIsLoading(false);
      onEditCancel();
    }
  };

  const onDescriptionChange = (event) => {
    setDescriptionToEdit(event.target.value);
  };

  const {
    data: currentBulkJob = {},
  } = useGetCurrentBulkJob();

  const onProductSelect = (id) => () => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const successProducts = React.useMemo(() => (currentBulkJob.products || [])
    .filter((product) => product.row?.state === 'done'), [currentBulkJob]);

  const onAllSelect = () => {
    if (selectedProducts.length === successProducts.length) {
      setSelectedProducts([]);
      return;
    }

    setSelectedProducts(successProducts.map((product) => product.code));
  };

  const rows = React.useMemo(() => successProducts
    .map((product) => {
      return (
        <TableRow onClick={onProductSelect(product.code)} className={classes.tableRow} key={product.code}>
          <BodyCell>
            <Checkbox
              checked={selectedProducts.includes(product.code)}
            />
          </BodyCell>
          <BodyCell>
            <div className={classes.productCell}>
              <div>
                <div className={classes.productName}>
                  {product.code}
                </div>
                <div>
                  {product.categories || ''}
                </div>
              </div>
            </div>
          </BodyCell>
          <BodyCell>{product.row.result.documents[0].content.blocks[0].data.text}</BodyCell>
          <BodyCell>
            <IconButton onClick={onEdit(product)}>
              <EditOutlined />
            </IconButton>
          </BodyCell>
        </TableRow>
      );
    }), [selectedProducts, currentBulkJob]);

  const tooltipText = React.useMemo(() => {
    if (selectedProducts.length === 0) {
      return `${successProducts.length} ${pluralize('product', successProducts.length)} ${pluralize('was', successProducts.length)}  processed with Bulk`;
    }

    if (selectedProducts.length === successProducts.length) {
      return 'All products selected';
    }

    return `${selectedProducts.length} ${pluralize('product', selectedProducts.length)} selected`;
  }, [selectedProducts, successProducts]);

  return (
    <div className={classes.root}>
      <Dialog
        onClose={onEditCancel}
        fullWidth
        maxWidth="sm"
        open={!!productCodeToEdit}
      >
        <DialogTitle>
          Edit product description
        </DialogTitle>
        <DialogContent>
          <InputField
            multiline
            rows={5}
            fullWidth
            value={descriptionToEdit}
            onChange={onDescriptionChange}
            autoFocus
          />
          <DialogActions>
            <Button
              color="primary"
              variant="text"
              onClick={onEditCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onEditSave}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <div className={classes.toolbar}>
        <div className={classes.toolbarLeftSide}>
          <SelectField
            classNames={{ root: classes.channelSelect }}
            multiple
            onChange={onChannelChange}
            options={channels.map((channel) => ({
              value: channel.code,
              label: channel.code,
            }))}
            value={selectedChannels}
            placeholder="Select channels"
          />
          <SelectField
            classNames={{ root: classes.localeSelect }}
            onChange={onLocaleChange}
            options={locales.map((locale) => ({
              value: locale,
              label: locale,
            }))}
            value={selectedLocale}
            placeholder="Select locale"
            disabled={selectedChannels.length === 0}
          />
        </div>
        <AkeneoDescriptionFieldSelect
          onChange={onFieldChange}
          value={selectedField}
          className={classes.fieldSelect}
        />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell>
                <Checkbox
                  checked={selectedProducts.length === successProducts.length}
                  onClick={onAllSelect}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
            <TableRow className={classes.stub}>
              <TableCell>
                <div className={classes.stub} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <BottomToolbar
        text={tooltipText}
        next={{
          label: 'Publish',
          disabled: selectedProducts.length === 0
            || isLoading
            || !selectedLocale
            || !selectedField
            || selectedChannels.length === 0,
          action: publish,
        }}
        cancel={{
          label: 'Back',
          action: onBack,
        }}
      />
    </div>
  );
};

export default Result;
