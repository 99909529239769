import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { FormField, InputField, SelectField, PhoneNumberField } from '@root/components/form';
import industrySegmentOptions from '@root/views/Authentication/industrySegmentOptions';
import companyResource from '@root/resources/company';
import authContext from '@root/resources/auth/auth.context';

import AuthLayout from '../Layout/AuthLayout';

import useStyles from './AddCompanyInfo.styles';
import { initialValues, getCompanySchema, getPhoneOption } from './AddCompanyInfo.helpers';

const AddCompanyInfo = () => {
  const classes = useStyles();
  const { mutateAsync: updateCompany } = companyResource.useUpdateCompany();
  const { fetchAuthData, currentCompany } = authContext.useAuth();
  const history = useHistory();

  const phoneOption = getPhoneOption(currentCompany);
  const isPhoneNumberRequired = phoneOption === 'required';
  const companySchema = getCompanySchema(isPhoneNumberRequired);

  const submit = async (values, { setSubmitting }) => {
    const industrySegment = values.industrySegment === 'other'
      ? values.otherIndustrySegment
      : values.industrySegment;
    const phoneNumber = (values.phoneNumber === '+' || !values.phoneNumber) ? null : values.phoneNumber;
    const valuesToSubmit = {
      ...values,
      industrySegment,
      phoneNumber,
    };

    const { isBadRequest } = await updateCompany(valuesToSubmit);
    await fetchAuthData();
    setSubmitting(false);

    if (!isBadRequest) {
      history.replace('/');
    }
  };

  return (
    <AuthLayout>
      <h1 className={classes.title}>Welcome to Copysmith</h1>
      <p className={classes.description}>Please provide some information about your company</p>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={companySchema}
        enableReinitialize
      >
        {({ values, isSubmitting }) => {
          const { industrySegment } = values;
          const isOtherSegment = industrySegment === 'other';
          return (
            <Form className={classes.form}>
              <FormField
                component={SelectField}
                label="Which option best describes your business?"
                name="industrySegment"
                options={industrySegmentOptions}
                placeholder="The kind of work you do"
                disabled={isSubmitting}
                required
              />
              {isOtherSegment && (
                <FormField
                  name="otherIndustrySegment"
                  label="Other"
                  component={InputField}
                  disabled={isSubmitting}
                  required
                />
              )}
              {phoneOption !== 'none' && (
                <FormField
                  name="phoneNumber"
                  label="Phone Number"
                  component={PhoneNumberField}
                  disabled={isSubmitting}
                  required={isPhoneNumberRequired}
                />
              )}

              <Button
                size="large"
                fullWidth
                type="submit"
                className={classes.button}
              >
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default AddCompanyInfo;
