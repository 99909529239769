import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Copy = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.25 6.2002C8.55964 6.2002 8 6.75984 8 7.4502V19.7502C8 20.4405 8.55964 21.0002 9.25 21.0002H18.75C19.4404 21.0002 20 20.4406 20 19.7502V7.4502C20 6.75984 19.4404 6.2002 18.75 6.2002H9.25Z" />
      <path d="M16 5V4C16 3.44772 15.5523 3 15 3H6C5.44772 3 5 3.44771 5 4V16.5C5 17.0523 5.44772 17.5 6 17.5H7V5H16Z" />
    </SvgIcon>
  );
};

export default Copy;
