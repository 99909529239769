import React from 'react';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';

import useStyles from './StepNumber.styles';

const StepNumber = ({
  number,
  disabled,
  checked,
}: $TSFixMe) => {
  const classes = useStyles();

  return (
    <div className={clsx(
      classes.stepCircle,
      disabled && classes.disabled,
      checked && classes.checked,
    )}
    >
      {checked ? <Check fontSize="small" /> : number}
    </div>
  );
};

export default StepNumber;
