import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface ListOptions extends RequestOptions {
  fileId?: string | null;
}

export interface UpdateOptions extends RequestOptions {
  data: Record<string, unknown>;
}

export interface CreateOptions extends RequestOptions {
  content: Record<string, unknown> | string;
  fileId: string;
}

export interface VoteOptions extends RequestOptions {
  vote: number;
}

export interface PublishRequest extends RequestOptions {
  integrationId: string;
  externalId?: string;
}

export interface UploadImageRequest extends RequestOptions {
  documentId: string;
  /**
   * multipart/form-data
   * requires key 'file' with file object
   */
  formData: any;
}

export class Document {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public list(data?: ListOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    if (!data) {
      data = {};
    }

    return this.client.get("/documents", data, options);
  } 

  public listFreeFormLatest(data?: ListOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    if (!data) {
      data = {};
    }

    return this.client.get("/documents/freeFormLatest", data, options);
  }

  public getSharedList(
    data?: ListOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    if (!data) {
      data = {};
    }

    return this.client.get("/documents/shared", data, options);
  }

  public getById(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get(`/documents/${id}`, { token }, options);
  }

  public create(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/documents", data, options);
  }

  public update(
    id: string,
    data: UpdateOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}`, data, options);
  }

  public like(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}/like`, { token }, options);
  }

  public flag(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}/flag`, { token }, options);
  }

  public vote(id: string, data: VoteOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}/vote`, data, options);
  }

  public delete(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.del(`/documents/${id}`, { token }, options);
  }

  public restore(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}/restore`, { token }, options);
  }

  public publish(
    id: string,
    data: PublishRequest,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/documents/${id}/publish`, data, options);
  }

  public uploadImage(data: UploadImageRequest, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/documents/${data.documentId}/image`, data.formData, options);
  }
}
