import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    fontSize: '22px',
    lineHeight: '39px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '600',
    color: theme.palette.colors.neutrals600,
  },
  subtitle: {
    color: theme.palette.colors.neutrals600,
    fontSize: 14,
    lineHeight: '22px',
  },

  divider: {
    width: '100%',
    height: '1px',
    minHeight: '1px',
    margin: '16px 0px 24px',
    backgroundColor: theme.palette.colors.neutrals100,
  },

  sectionDivider: {
    gridColumn: '1/-1',

    margin: '8px 0px 16px',
    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,
  },

  billingContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 463px 463px',
    gap: 16,
  },

  planContainer: {
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  expandRight: {
    marginRight: 'auto',
  },
  billingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addonsPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.colors.mediumLightGrey,
    border: `1px dashed ${theme.palette.colors.grey}`,
    borderRadius: 4,
    padding: '32px 0',
    '& > :first-child': {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 16,
    },
  },
  billingIssueAlert: {
    color: theme.palette.colors.red,
    fontSize: 14,
    marginTop: theme.spacing(-1),
  },
}));
