import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    maxHeight: 'calc(100vh - 72px)',
    margin: '2rem 0.5rem 2rem 1.5rem',
    paddingBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '14rem',
  },
  list: {
    padding: 0,
  },
  listTitle: {
    padding: '32px 32px',
    fontSize: '22px',
    fontWeight: '700',
  },
  listItemText: {
    '& span': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  listIcon: {
    minWidth: '40px',
  },
  listItemWrap: {
    padding: '12px',
    height: '40px',
    borderRadius: '4px',
    marginBottom: 10,
    '&.Mui-selected svg': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      background: 'transparent',
      color: '#5b5b5b',
    },
  },
}));

export default useStyles;
