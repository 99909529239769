import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface DeleteRequest extends RequestOptions {
  id: string;
  soft?: boolean;
  token?: string;
}

export interface GetByIdRequest extends RequestOptions {
  id: string;
}

export interface CreateRequest extends RequestOptions {
  integrationId: string;
  integrationType: string;
}

export default class ApiKey {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async list(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/", data, options);

    return result;
  }

  public async createOrUpdate(data: CreateRequest, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/", data, options);

    return result;
  }

  public async getApiKeyInfo(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/apiKeyInfo", data, options);

    return result;
  }

  public async delete(data: DeleteRequest, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del(`/${data.id}`, data, options);

    return result;
  }

  public async getById(data: GetByIdRequest, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/${data.id}`, data, options);

    return result;
  }
}