import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    margin: `${theme.spacing(4)}px 0 !important`,
  },
  stepperWrap: {
    width: '100%',
    padding: '0 48px',

    backgroundColor: theme.palette.colors.white,
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
  },
}));

export default useStyles;
