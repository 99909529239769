import React from 'react';

import menFashion from '@root/assets/engineImages/men-fashion.png';
import menFashionFull from '@root/assets/engineImages/men-fashion-big.jpg';

export default {
  _id: 'FT-MEN',
  title: "Men's Apparel",
  description: 'Ali knows what modern men look for in apparel.  Reach customers by speaking their language and elevate your products in a crowded space.',
  longerDescription: 'Ali can make your copy more effective at targeting customers by speaking their language.  Whether buyers are looking for hats, shirts, pants, shoes, or anything in between, Ali supports and elevates your products in a crowded space.',
  imageSrc: menFashion,
  bigImg: menFashionFull,
  sampleGenerations: [
    <div>
      <h2 style={{ fontWeight: 500, fontSize: '24px' }}>
        Men&apos;s Custom-Fit Ivy Polo from Copysmith Boutique
      </h2>
      The Ivy by Copysmith is a modern tailored polo made from 100% organic cotton.
      Wear it with any pant or shorts and casual kicks. Pair it with your favorite chinos
      or denim and some leather loafers for a smart-casual look. Or, go all out and wear it with
      your favourite suit and a classic pair of Derby shoes!
    </div>,
    <div>
      <ul>
        <li>
          • Made with a soft cotton fabric and finished with ribbed collar and cuffs,
          this classic short sleeve polo shirt is perfect for wearing to the
          office or for an easy dinner date.
        </li>
        <li>
          • Classic Fit: Slim fit through the body
          and slightly shorter length through the body.
        </li>
        <li>
          • Durable, Wrinkle-Free Fabric: Strongly constructed from a durable wrinkle-free
          fabric, this polo shirt can be easily worn for daily activities
          such as work, travel or a weekend getaway.
        </li>
        <li>
          • Classic Style: Pair with any pants and kicks for a complete casual office look.
        </li>
      </ul>
    </div>,
    <div>
      <h2 style={{ fontWeight: 500, fontSize: '24px' }}>
        Men&apos;s Original Boat Shoe from Copysmith Boutique
      </h2>
      Stylish, flexible, and comfortable, the Copysmith Original Boat Shoe lets
      you move with ease. Crafted from leather and featuring an iconic design that’s
      been a men’s style mainstay for decades, these timeless shoes will be a durable and
      dependable companion every step of the way. The Rubber Outsole ensures traction on a
      variety of surfaces while the lace-up design allows for easy fit adjustment. With eco-friendly
      construction and sustainable materials, these shoes are as stylish as they are eco-friendly.
    </div>,
  ],
};
