import React from 'react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './IconNavbar.styles';

const IconNavbar = ({ onClick, tabs, selectedTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.navbarRoot}>
      {tabs.map(({ value, icon: Icon, tooltip, disabled, disabledTooltip }) => {
        const isSelected = value === selectedTab;
        return (
          <SimpleTooltip
            content={disabled ? disabledTooltip : tooltip}
            key={value}
            wrapped
          >
            <IconButton
              className={clsx(classes.icon, isSelected && classes.selected)}
              onClick={() => onClick(value)}
              color={isSelected ? 'primary' : 'default'}
              disabled={disabled}
              id={`button-${value}`}
            >
              <Icon />
            </IconButton>
          </SimpleTooltip>
        );
      })}
    </div>
  );
};

export default IconNavbar;
