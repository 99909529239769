import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLink,
  faPowerOff,
  faUser,
  faDollarSign,
  faHeart,
  faStream,
  faTag,
  faNewspaper,
  faCode,
  faPaperPlane,
  faTrash,
  faPlaneArrival,
  faPencilAlt,
  faInfoCircle,
  faArrowUp,
  faArrowDown,
  faEnvelopeSquare,
  faLightbulb,
  faThLarge,
  faExpandAlt,
  faBolt,
  faHeading,
  faCloudSun,
  faFilter,
  faFolderPlus,
  faFolder,
  faFileMedical,
  faHome,
  faSortDown,
  faSortUp,
  faChevronDown,
  faNetworkWired,
  faList,
  faArchive,
  faPencilRuler,
  faPollH,
  faClipboard,
  faFastForward,
  faFillDrip,
  faFingerprint,
  faSortNumericDownAlt,
  faCommentDots,
  faCircle,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faFacebook,
  faGoogle,
  faAmazon,
  faMicrosoft,
  faInstagramSquare,
  faReddit,
  faTelegram,
  faWhatsapp,
  faTwitter,
  faLinkedin,
  faChrome,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faStar,
  faMap,
  faCreditCard,
} from '@fortawesome/free-regular-svg-icons';

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faDollarSign);
  library.add(faStream);
  library.add(faTag);
  library.add(faAmazon);
  library.add(faFacebookSquare);
  library.add(faGoogle);
  library.add(faNewspaper);
  library.add(faCode);
  library.add(faPaperPlane);
  library.add(faTrash);
  library.add(faHeart);
  library.add(faInstagramSquare);
  library.add(faPlaneArrival);
  library.add(faPencilAlt);
  library.add(faInfoCircle);
  library.add(faArrowUp);
  library.add(faArrowDown);
  library.add(faMicrosoft);
  library.add(faEnvelopeSquare);
  library.add(faLightbulb);
  library.add(faStar);
  library.add(faCreditCard);
  library.add(faMap);
  library.add(faThLarge);
  library.add(faExpandAlt);
  library.add(faBolt);
  library.add(faHeading);
  library.add(faFilter);
  library.add(faCloudSun);
  library.add(faFolderPlus);
  library.add(faFolder);
  library.add(faFileMedical);
  library.add(faHome);
  library.add(faSortDown);
  library.add(faSortUp);
  library.add(faChevronDown);
  library.add(faNetworkWired);
  library.add(faList);
  library.add(faArchive);
  library.add(faPencilRuler);
  library.add(faPollH);
  library.add(faClipboard);
  library.add(faLinkedin);
  library.add(faFastForward);
  library.add(faFillDrip);
  library.add(faFacebook);
  library.add(faReddit);
  library.add(faTelegram);
  library.add(faWhatsapp);
  library.add(faTwitter);
  library.add(faFingerprint);
  library.add(faSortNumericDownAlt);
  library.add(faChrome);
  library.add(faYoutube);
  library.add(faCommentDots);
  library.add(faCircle);
  library.add(faSignature);
}

export default initFontAwesome;
