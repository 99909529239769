import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api/newApi';

const removeFraseGeneration = (fileId) => api.frase.removeFile(fileId);

const useFraseFileRemove = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (fileId) => removeFraseGeneration(fileId),
    {
      onSuccess: (data, fileId) => {
        queryClient.setQueryData(['fraseFile', fileId], null);
      },
    },
  );
};

export default useFraseFileRemove;
