import React, { useState } from 'react';
import { IconButton, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';

import authContext from '@root/resources/auth/auth.context';
import MenuButton from '@root/components/MenuButton';
import { getOnboardingMinimizedState, setOnboardingMinimizedState } from '@root/services/localStorage.service';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import config from '@root/config';

import useStyles from './ChecklistModal.styles';
import ChecklistItem from './components/ChecklistItem';

const ChecklistModal = () => {
  const { currentUser: { onboarding, roles } } = authContext.useAuth();
  const wasMinimized = getOnboardingMinimizedState() === 'true';
  const [minimized, setMinimized] = useState(wasMinimized);
  const classes = useStyles({ minimized });
  const { startFeatureTour, closeChecklist, dropState } = onboardingContext.useOnboarding();
  const history = useHistory();
  const isOwnerOrAdmin = roles.owner || roles.admin;

  const items = [
    {
      label: 'Sign up for an account',
      number: 1,
      completed: true,
    },
    {
      label: 'Create a piece of content',
      number: 2,
      onClick: () => {
        history.push(config.homeRoute);
        startFeatureTour();
      },
      completed: onboarding.items?.generateContent?.completed,
    },
    ...(isOwnerOrAdmin ? [{
      label: 'Invite a team member',
      number: 3,
      onClick: () => {
        dropState();
        history.push('/profile/team-members');
      },
      completed: onboarding.items?.inviteTeamMember?.completed,
    }] : []),
    {
      label: 'Create a project',
      number: isOwnerOrAdmin ? 4 : 3,
      onClick: () => {
        dropState();
        history.push('/templates');
      },
      completed: onboarding.items?.createProject?.completed,
    },
  ];

  const totalSteps = items.length;
  const stepsCompleted = items.reduce((acc, curr) => (curr.completed ? acc + 1 : acc), 0);
  const completePercentage = Math.floor((stepsCompleted / totalSteps) * 100);

  const handleMinimize = () => {
    setMinimized(true);
    setOnboardingMinimizedState(true);
  };

  const handleExpand = () => {
    setMinimized(false);
    setOnboardingMinimizedState(false);
  };

  const handleClose = () => {
    closeChecklist();
  };

  return (
    <div className={classes.checklistRoot}>
      <div className={classes.header}>
        <span className={classes.title}>Get started</span>
        <span className={classes.progressSteps}>{`${stepsCompleted}/${totalSteps} steps complete!`}</span>
        <MenuButton
          className={classes.menuButton}
          menuItems={[
            {
              text: minimized ? 'Expand' : 'Minimize',
              onClick: minimized ? handleExpand : handleMinimize,
            },
            {
              text: 'Close',
              onClick: handleClose,
            },
          ]}
          button={(
            <IconButton
              color="default"
              size="small"
            >
              <ChevronLeft
                className={classes.menuIcon}
                color="action"
              />
            </IconButton>
          )}
        />

      </div>
      <div className={classes.progressBarBlock}>
        <LinearProgress
          variant="determinate"
          value={completePercentage}
          className={classes.progressBar}
        />
        <span>{`${completePercentage}%`}</span>
      </div>
      <div className={classes.list}>
        {items.map((item) => (
          <ChecklistItem
            key={item.number}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default ChecklistModal;
