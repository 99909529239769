import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import dateUtils from '@root/utils/date.util';
import Button from '@root/components/buttons/Button';
import { lowCreditsLimit } from '@root/resources/billing/billingInfo.helpers';
import NotificationBar from '@root/views/Layout/components/NotificationBar';

import ShadowLoginInfo from '../ShadowLoginInfo';

import ProfileDropdown from './components/ProfileDropdown';
import DaysLeft from './components/DaysLeft';
import useCardAlert from './hooks/useCardAlert';
import useStyles from './Navbar.styles';

const Navbar = () => {
  const classes = useStyles({ });
  const { data: { limit: creditsLeft } = {} } = billingResource.useLimits();

  const {
    data: {
      renewOn,
      priceId,
      trialEndOn,
      cardStatus,
      rank,
    } = {},
  } = billingResource.useBillingInfo();
  const { currentUser } = AuthContext.useAuth();
  const history = useHistory();

  const showLowCreditsBar = !currentUser.onFreePlan
    && rank < 300
    && creditsLeft <= lowCreditsLimit
    && creditsLeft !== null;
  const showDaysLeft = priceId === 'free' && trialEndOn;
  const showCredits = currentUser?.showCredits && Number.isInteger(creditsLeft);

  const handleSubscribeClick = () => {
    history.push('/profile/plans');
  };

  const cardAlert = useCardAlert({ cardStatus, currentUser });

  return (
    <div className={classes.navbarContainer}>
      <nav className={classes.navbar}>
        {currentUser?.isShadowLogin && <ShadowLoginInfo user={currentUser} />}

        {cardAlert || (
          <div className={classes.leftBlock}>
            {showDaysLeft && (
              <>
                <Button
                  variant="outlined"
                  className={classes.leftSubscribeButton}
                  onClick={handleSubscribeClick}
                >
                  Subscribe
                </Button>
                <div className={classes.trialBlock}>
                  <DaysLeft trialEndOn={trialEndOn} />
                </div>
              </>
            )}

            {showCredits && (
              <span>
                {creditsLeft > 9
                  ? `${creditsLeft} words available`
                  : renewOn && `Next words renewal: ${dateUtils.longFormat(renewOn + 60 * 60)}.`}
              </span>
            )}
          </div>
        )}

        <ProfileDropdown />
      </nav>

      {showLowCreditsBar && <NotificationBar />}
    </div>
  );
};

export default Navbar;
