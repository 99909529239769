import * as Yup from 'yup';

const namePattern = /^[^#:"<>[\]\\;~`!$%^*+={}|?/_&@0-9]{1,255}$/;
const onlyOneSpacePattern = /^(?!.*\s{2,}).*$/;

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'First name is too short')
    .max(255, 'First name is too long')
    .matches(namePattern, 'Are you sure you entered your first name correctly?')
    .matches(onlyOneSpacePattern, 'Are you sure you entered your first name correctly?')
    .required('First name is required'),
  surname: Yup.string()
    .min(2, 'Last name is too short')
    .max(255, 'Last name is too long')
    .matches(namePattern, 'Are you sure you entered your last name correctly?')
    .matches(onlyOneSpacePattern, 'Are you sure you entered your last name correctly?')
    .required('Last name is required'),
  contractDuration: Yup.number().required('Contract duration is required'),
});

export default schema;
