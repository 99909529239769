import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    tooltipRoot: {
      minWidth: 300,
      maxWidth: 350,
      backgroundColor: theme.palette.colors.primaryLight,
      color: theme.palette.colors.white,
      borderRadius: 8,
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',
      marginBottom: 16,
    },
    content: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      whiteSpace: 'pre-line',
    },
    footer: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 400,
      '& button': {
        marginRight: 16,
        color: theme.palette.colors.white,
        minWidth: 0,
        '&:hover': {
          color: theme.palette.colors.white,
          opacity: 1,
        },
      },
    },
    fadedText: {
      color: theme.palette.colors.white,
      opacity: 0.6,
    },
  });
});

export default useStyles;
