import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: '"Inter", sans-serif',
    width: '100%',
    overflowY: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    padding: '20px',
    background: theme.palette.colors.lightGrey,
  },
}));

export default useStyles;
