import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

const processBulk = async ({
  productCodes,
}) => {
  const job = await api.akeneo.processBulk({
    productCodes,
  });

  return job;
};

export default function useProcessBulk() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ productCodes }) => processBulk({ productCodes }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['akeneo.getCurrentBulkJob'], data);
      },
    },
  );
}
