import React from 'react';
import { Typography, makeStyles, CircularProgress } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
}));

const DocusignCallbackPage = () => {
  const classes = useStyle();

  const currentUrl = window.location.href;

  const params = new URL(currentUrl).searchParams;
  const docusignContractState = params.get('event');
  window.parent.postMessage({
    docusignContractState,
  }, '*');

  return (
    <div className={classes.root}>
      <CircularProgress />
      <Typography variant="body2">
        Validating your signature...
      </Typography>
    </div>
  );
};

export default DocusignCallbackPage;
