/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { FieldLabel, SelectField } from '@root/components/form';
import TemplatesContext from '@root/resources/templates/templates.context';
import { getFineTunesOptions } from '@root/views/FineTunesEngines/helpers';

const useStyles = makeStyles((theme) => ({
  promptSettingsRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: 20,
  },
  hiddenThumb: {
    visibility: 'hidden',
  },
  languageWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > svg': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const getNProps = ({
  config,
  defaultValues,
}) => {
  if (config.nProps) {
    return config.nProps;
  }

  if (defaultValues.n >= 3) {
    return {
      min: defaultValues.n - 2,
      max: defaultValues.n + 2,
      step: 1,
    };
  }

  return {
    min: 1,
    max: 5,
    step: 1,
  };
};

const PromptSettings = ({
  templateType,
  onChange,
  values,
  config = {},
  showAli = true,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const engines = getFineTunesOptions(currentUser);

  const {
    engine = 'default',
    temperature,
    max_tokens,
    n,
  } = values;

  const { getTemplate } = TemplatesContext.useTemplates();
  const template = getTemplate(templateType);
  const { aiOptions: defaultValues, hasInstruction } = template;

  const hasAli = !engine || engine !== 'default';

  const maxTokensProps = {
    min: defaultValues.max_tokens * 0.5,
    max: defaultValues.max_tokens * 1.25,
    step: defaultValues.max_tokens * 0.25,
  };

  const nProps = useMemo(() => getNProps({
    config,
    defaultValues,
  }));

  const isMaxContentLengthDisabled = currentUser.onFreePlan
  && ['contentRewriter', 'contentImprover'].includes(templateType);

  const numberOfVersionsOptions = Array
    .from({ length: nProps.max - nProps.min + 1 }, (_, i) => i + nProps.min)
    .map((opt) => ({ value: opt, label: opt }));
  const creativityOptions = [
    { value: '', label: 'Ideal' },
    { value: 0.5, label: 'Simple' },
    { value: 0.75, label: 'Chatty' },
    { value: 0.85, label: 'Verbose' },
  ];

  const tooltipContent = isMaxContentLengthDisabled
  && (
    <>
      Optimize up to 1,200 characters as a time with any plan type.&nbsp;
      <Link to={{
        pathname: '/profile/plans',
      }}
      >
        Click here to subscribe now
      </Link>
      &nbsp;
    </>
  );

  return (
    <>
      <div className={classes.promptSettingsRoot}>
        <SelectField
          label="Creativity Level"
          options={creativityOptions}
          value={temperature}
          onChange={(e) => onChange({ name: 'temperature', value: e.target.value })}
        />

        {hasInstruction && !hasAli && (
          <SelectField
            label="Number of Variants"
            options={numberOfVersionsOptions}
            value={n}
            onChange={(e) => onChange({ name: 'n', value: e.target.value })}
          />
        )}

        {showAli && (
          <SelectField
            label="Ali’s Expert Voice"
            options={[{ value: 'default', label: 'None' }, ...engines]}
            value={engine}
            onChange={(e) => onChange({ name: 'engine', value: e.target.value })}
          />
        )}
      </div>

      {hasInstruction && !hasAli && (
        <SimpleTooltip
          content={tooltipContent}
          wrapped
          interactive
        >
          <div>
            <FieldLabel
              label="Max Content Length"
            />
            <Slider
              valueLabelDisplay="auto"
              value={max_tokens}
              onChange={(e, maxTokensValue) => onChange(
                { name: 'max_tokens', value: maxTokensValue },
              )}
              min={maxTokensProps.min}
              max={maxTokensProps.max}
              step={maxTokensProps.step}
              classes={{
                thumb: clsx({ [classes.hiddenThumb]: isMaxContentLengthDisabled }),
              }}
              valueLabelFormat={(value) => `${Math.ceil(value * 5)}`}
              marks={[
                { value: maxTokensProps.min, label: 'Very short' },
                { value: maxTokensProps.max, label: 'Long' },
              ]}
              disabled={isMaxContentLengthDisabled}
            />
          </div>
        </SimpleTooltip>
      )}
    </>
  );
};

export default PromptSettings;
