import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gradientCanvas: {
    position: 'absolute',
    zIndex: '-1',
  },

  bannerContainer: {
    gridColumn: '1/-1',

    position: 'relative',
    overflow: 'hidden',

    width: '100%',
    height: '240px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,

    borderRadius: '8px',
  },

  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '700',
    color: theme.palette.colors.white,
    marginBottom: '8px',
  },

  subtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.colors.white,
    maxWidth: '568px',
    marginBottom: '24px',
  },

  actionButton: {
    backgroundColor: theme.palette.colors.white,
    color: 'black',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    mixBlendMode: 'lighten',

    '&:hover': {
      backgroundColor: '#ffffff4D',
      color: theme.palette.colors.white,
    },
  },
}));

export default useStyles;
