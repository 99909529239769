"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertInObjectIf = exports.insertInArrayIf = exports.listify = exports.isObject = void 0;
const isObject = (value) => !!value && value.constructor === Object;
exports.isObject = isObject;
/**
 * Convert an object to a list, mapping each entry
 * into a list item
 */
const listify = (obj, toItem) => {
    if (!obj)
        return [];
    const entries = Object.entries(obj);
    if (entries.length === 0)
        return [];
    return entries.reduce((acc, entry) => [
        ...acc,
        toItem(entry[0], entry[1]),
    ], []);
};
exports.listify = listify;
const insertInArrayIf = (condition, ...elements) => (condition ? elements : []);
exports.insertInArrayIf = insertInArrayIf;
const insertInObjectIf = (condition, elements) => (condition ? elements : {});
exports.insertInObjectIf = insertInObjectIf;
