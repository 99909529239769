import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1.5rem',
  },
  formGroup: {
    marginBottom: '1rem',
  },
  info: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    marginLeft: '0.5rem',
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    marginBottom: 12,
    fontFamily: 'Manrope',
  },
  wrapper: {
    alignSelf: 'center',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 4,
    padding: 40,
    minWidth: 664,
  },
}));

export default useStyles;
