import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Link as UiLink } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import hubspotService from '@root/services/hubspot.service';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

const getLink = ({
  to,
  content,
  inNewTab,
}) => (
  <Link
    to={inNewTab ? { pathname: to } : to}
    {...(inNewTab ? {
      target: '_blank',
    } : {
      replace: true,
    })}
  >
    {content}
  </Link>
);

const DisabledGenerateTooltip = ({
  enabled = true,
  children,
  notEnoughPermissions,
  notEnoughCredits,
  customDisableTooltipContent,
  noActiveSubscription,
  readOnly,
  interactive,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const isTrial = currentUser.roles.owner && currentUser.onFreePlan;
  const isSelfServeEnterprise = currentUser.onSelfServeEnterprisePlan;
  const { currentExternalSource } = externalSourcesContext.useExternalSource();

  const { toggles: { openBillingLinksInNewTab } } = currentExternalSource;

  const getContent = () => {
    if (customDisableTooltipContent) {
      return customDisableTooltipContent;
    }

    if (readOnly) {
      return 'Not available in viewer mode';
    }

    if (notEnoughCredits) {
      if (isTrial) {
        return getLink({
          to: '/profile/plans',
          content: (
            <>
              You&apos;ve used all your daily and/or total free words.
              {' '}
              <b>Click here</b>
              {' '}
              to subscribe today and get back to work!
            </>
          ),
          inNewTab: openBillingLinksInNewTab,
        });
      }
      if (isSelfServeEnterprise) {
        return (
          <div className={classes.root}>
            <span>
              You&apos;ve reached your word limit.
              {' '}
              <UiLink onClick={hubspotService.show}>Contact our sales team</UiLink>
              {' '}
              to upgrade.
            </span>
          </div>
        );
      }
      return (
        <div className={classes.root}>
          <span>
            You&apos;ve reached your word limit.
            {' '}
            Please
            {' '}
            {getLink({
              to: '/profile/plans',
              content: 'upgrade',
              inNewTab: openBillingLinksInNewTab,
            })}
            {' '}
            or
            {' '}
            {getLink({
              to: '/addons',
              content: 'get more words',
              inNewTab: openBillingLinksInNewTab,
            })}
            .
          </span>
        </div>
      );
    }
    if (notEnoughPermissions) {
      return (
        <div className={classes.root}>
          Not enough permissions to generate in this file
        </div>
      );
    }
    if (noActiveSubscription) {
      return (
        <div className={classes.root}>
          Please verify your&nbsp;
          {getLink({
            to: '/profile/billing',
            content: 'billing information',
            inNewTab: openBillingLinksInNewTab,
          })}
          .
        </div>
      );
    }
    return null;
  };

  const content = getContent();

  if (!enabled || !content) {
    return children;
  }

  return (
    <SimpleTooltip
      content={content}
      placement="bottom"
      wrapped
      interactive={interactive || true}
    >
      {children}
    </SimpleTooltip>
  );
};

export default DisabledGenerateTooltip;
