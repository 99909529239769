/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Backdrop, Fade, Modal, Button,
} from '@material-ui/core';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import api from '@root/api';
import config from '@root/config';
import Loading from '@root/components/Loading/Loading';

import useStyles from './RenewSuspendedAccountModal.styles';

const RenewError = ({ error }) => {
  const classes = useStyles();

  // should always be overwritten
  let message = 'There was a problem with renewing your account';
  if (typeof error === 'string') {
    message = error;
  } else if (error.message) {
    message = error.message;
  }
  return (
    <div className={classes.error}>
      <h3>An Error has Occurred:</h3>
      <p>{message}</p>
    </div>
  );
};

const RenewResult = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.resultContent}
    >
      <p>
        Your subscription has been renewed! If you have any questions or
        concerns please reach out to&nbsp;
        <a href={`mailto:${config.contactSupportEmail}`}>
          {config.contactSupportEmail}
        </a>
      </p>

      <h3 className={classes.welcomeMessage}>
        <b>Welcome Back!</b>
      </h3>
    </div>
  );
};

const Conditional = ({
  loading, error, didComplete, handleClose, renew,
}) => {
  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <RenewError error={error} />;
  }
  if (didComplete) {
    return <RenewResult />;
  }
  return (
    <div className={classes.renewContainer}>
      <p>
        <b>You are about to renew your suspended subscription</b>
      </p>
      <p>
        <div className={classes.b8}>
          If your suspension period has not yet started, you will not be charged for
          {' '}
          renewal and billing for next month will resume as
          if you had never cancelled.
        </div>
        <div>
          If your suspension has already begun, you will immediately resubscribe
          {' '}
          to your previous plan.
        </div>
      </p>
      <div className={classes.renewButtonContainer}>
        <Button
          variant="outlined"
          className={classes.laterButton}
          onClick={handleClose}
        >
          Maybe Later
        </Button>
        <Button onClick={renew}>
          Renew Subscription
        </Button>
      </div>
    </div>
  );
};

const RenewSuspendedAccountModal = ({ modalOpen, setModalOpen }) => {
  const { currentCompany } = AuthContext.useAuth();
  const [loading, setLoading] = useState(false);
  // Note: didComplete is currently never changed;
  const didComplete = false;
  // const [didComplete, setDidComplete] = useState();
  const [error, setError] = useState();

  const handleClose = () => { setModalOpen(false); };

  const subscriptionPlan = currentCompany.suspendPlan;

  const classes = useStyles();

  const renew = async () => {
    setLoading(true);
    try {
      await api.billing.renewSuspendedSubscription({
        plan: subscriptionPlan,
      });
      window.location.reload();
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={clsx(classes.small, classes.prelative)}>
          <div className={classes.header}>
            <span
              className={classes.renewTitle}
            >
              Renew Suspended Subscription
            </span>
          </div>
          <div
            className={classes.closeIcon}
            onClick={handleClose}
          >
            ×
          </div>
          <Conditional
            loading={loading}
            error={error}
            didComplete={didComplete}
            handleClose={handleClose}
            renew={renew}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default RenewSuspendedAccountModal;
