import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputButtonRoot: {
    padding: '0 4px',
    minWidth: 0,
    minHeight: 0,
    backgroundColor: theme.palette.colors.white,
    height: 26,
    '& .MuiButton-label > svg': {
      marginRight: 4,
    },
    '&:hover $subButtonsBlock': {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
    },
  },
  subButtonsBlock: {
    display: 'none',
    gap: 4,
    paddingLeft: 4,
    marginLeft: 4,
    borderLeft: `1px solid ${theme.palette.colors.primary}`,
  },
  buttonText: {
    marginRight: 4,
  },
}));

export default useStyles;
