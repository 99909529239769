import { tabs, viewTypes } from '@root/views/CreateAndExplore/CreateAndExplore.constants';

const communityActions = Object.freeze({
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
});

const initialState = {
  viewType: viewTypes.LIST,
  activeTab: tabs.COMMUNITY,
  searchQuery: '',
};

const communityReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case communityActions.SET_SEARCH_QUERY:
        return {
          ...state,
          searchQuery: action.payload,
        };
      case communityActions.SET_ACTIVE_TAB:
        return {
          ...state,
          searchQuery: initialState.searchQuery,
          activeTab: action.payload,
        };
      default: {
        throw new Error(`Community Context Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

export { communityReducer, communityActions, initialState };
