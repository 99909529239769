import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Cancel } from '@material-ui/icons';

import useStyles from './KeywordPill.styles';

const KeywordPill = ({ text, onRemove }) => {
  const classes = useStyles();

  const animationProps = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: 24 },
  });

  const handleRemove = () => {
    onRemove(text);
  };

  return (
    <animated.div style={animationProps}>
      <div className={classes.root}>
        <span>{text}</span>
        <Cancel
          fontSize="small"
          onClick={handleRemove}
        />
      </div>
    </animated.div>
  );
};

export default KeywordPill;
