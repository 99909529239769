import { HttpClient } from "../../HttpClient";
import { RequestOptions } from "../../RequestOptions";

export interface GetProductsOptions extends RequestOptions {
  search: string;
}

export interface UpdateProductDescriptionOptions extends RequestOptions {
  productCode: string;
  description: string;
  locale: string;
  channels: string[];
}

export interface ProcessBulkOptions extends RequestOptions {
  productCodes: string[];
}

export interface UpdateBulkDescriptionOptions extends RequestOptions {
  productCode: string;
  description: string;
}

export interface PublishBulkOptions extends RequestOptions {
  productCodes: string[];
  locale: string;
  channels: string[];
  field: string;
}

export default class Akeneo {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async getProducts(data: GetProductsOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/products", data);

    return result;
  }

  public async getChannels(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/channels", data);

    return result;
  }

  public async getCategories(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/categories", data);

    return result;
  }

  public async updateProductDescription(data: UpdateProductDescriptionOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/products/${data.productCode}`, data);

    return result;
  }

  public async processBulk(data: ProcessBulkOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/bulk", data);

    return result;
  }
  
  public async getCurrentBulkJob(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/bulk/current", data);

    return result;
  }

  public async updateBulkDescription(data: UpdateBulkDescriptionOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/bulk/current/description", data);

    return result;
  }

  public async publishBulkJob(data: PublishBulkOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/bulk/current/publish", data);

    return result;
  }
}
