import React from 'react';

import useFiles from '@root/resources/file/useFiles';

const useTitle = ({
  setLocalFile, localFile, updateFile, saveLocalFile, fileId,
}) => {
  const { refetch: refetchFiles } = useFiles({ folderIds: [localFile?.folderId] });

  const onTitleChange = React.useCallback((e) => {
    setLocalFile({
      ...localFile,
      fileTitle: e.target.value,
    });
  }, [localFile, updateFile, setLocalFile, fileId]);

  const updateTitle = React.useCallback(async () => {
    const newFile = await updateFile({ fileId, data: { title: localFile.fileTitle } });
    refetchFiles();
    saveLocalFile({
      ...newFile,
      workflowStatus: localFile.workflowStatus,
      assignedToId: localFile.assignedToId,
    });
  }, [
    localFile, updateFile, saveLocalFile, fileId,
  ]);

  return {
    onTitleChange,
    updateTitle,
  };
};

export default useTitle;
