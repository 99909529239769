import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  generatorInput: {
    height: '160px',
    borderRadius: theme.border.radius.default,
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    resize: 'none',
    padding: '10px 20px 0 20px',
    fontSize: '14px',
    width: '100%',
    border: '0.5px solid #aaaaaa',
    backgroundColor: 'white',
    background: 'white',
    '&:focus': {
      outline: 0,
    },
    '&:active': {
      outline: 0,
    },
  },
  generatorInputSmall: {
    height: '40px',
    '-ms-overflow-style': 'none', /* for Internet Explorer, Edge */
    'scrollbar-width': 'none', /* for Firefox */
    'overflow-y': 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  rootWithQualityTracker: {
    position: 'relative',
  },
  inputWithQualityTracker: {
    paddingBottom: 56,
  },
}));

export default useStyles;
