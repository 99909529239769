import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100%',
    borderRight: `1px solid ${theme.palette.colors.grey}`,
    background: 'white',
    position: 'relative',
  },
  newRoot: {
    background: theme.palette.background.primary,
    borderRight: 'none',
  },
  rootCollapsed: {
    width: '4rem',
    minWidth: '4rem',
    overflow: 'hidden',
  },
  container: {
    maxHeight: 'calc(100vh - 72px)',
    marginRight: 32,
    paddingBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '14rem',
  },
  overflow: {
    overflowY: 'auto',
  },
  newProjectsContainer: {
    paddingLeft: '12px',
  },
  customGroup: {
    '& > div:not(:last-child)': {
      marginBottom: '1rem',
    },
    marginBottom: '2rem',
  },
  newCustomGroup: {
    '& > div:not(:last-child)': {
      marginBottom: '10px',
    },
    marginBottom: '0',
  },
  foldersGroup: {
    display: 'inline-flex',
    flexDirection: 'column',
    '& > div:not(:last-child)': {
      marginBottom: '0.5rem',
    },
    paddingTop: '7px',
    flexGrow: '1',
    width: '100%',
  },
  newFoldersGroup: {
    '& > div:not(:last-child)': {
      marginBottom: '3px',
    },
  },
  icon: {
    fontSize: '1.3rem',
    color: theme.palette.colors.darkGrey,
  },
  newIcon: {
    fontSize: '1.5rem',
    color: theme.palette.colors.black,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.8rem',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '1rem',
    color: '#000',
    fontWeight: '600',
  },
  newHeaderText: {
    fontWeight: 500,
    fontSize: '14px',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(),
    marginTop: '1rem',
  },
  newHeaderRow: {
    marginTop: '10px',
  },
  emptyFoldersText: {
    cursor: 'pointer',
    color: '#aaa',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    display: 'inline-block',
    marginLeft: '0.5rem',
    padding: '0.5rem',
  },
  collapseButton: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
}));

export default useStyles;
