import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  topMargin: {
    marginTop: '2rem',
  },
  contentWrapper: {
    marginTop: 20,
  },
  contentText: {
    marginTop: '2rem',
    fontSize: '0.925rem',
    color: 'darkgray',
  },
  buttonWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
}));
