import React from 'react';
import {
  FormControl,
  Select, InputLabel,
  MenuItem,
} from '@material-ui/core';

const StringSelect = ({
  formik,
  name,
  label,
  options,
}) => {
  const onChange = (e) => {
    formik.handleChange(e);
  };

  const selectOptions = React.useMemo(() => {
    if (options.length === 0) {
      return [];
    }
    const { 0: firstOption } = options;
    if (typeof firstOption === 'string') {
      return options.map((o) => {
        return {
          value: o,
          label: o,
        };
      });
    }

    return options;
  });

  // Get deep value, e.x. uiOptions.type
  const parts = name.split('.');
  let value = formik.values;
  parts.forEach((p) => {
    value = value[p] ? value[p] : '';
  });

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {selectOptions.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default StringSelect;
