import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';

import ProductSVG from '@root/assets/product-pages/product.svg';
import LinkSVG from '@root/assets/product-pages/link.svg';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';

import { PRODUCT_FROM_URL_MODAL } from '../AddProductFromURLModal';
import { ADD_SINGLE_PRODUCT_MODAL } from '../AddSingleProductModal';

import useStyles from './SingleProductModalOptions.styles';

const SingleProductModalOptions = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { dispatch } = useGlobalModals();

  const productOptions = [
    {
      image: LinkSVG,
      bgColor: '#E8E7FF',
      title: 'Import from URL',
      description: 'Provide us a link. We will go gather your product information for you.',
      buttonText: 'Import from URL',
      action: () => {
        dispatch({
          type: modalsActions.OPEN_MODAL,
          payload: {
            modalId: PRODUCT_FROM_URL_MODAL,
            modalContent: '',
          },
        });
      },
    },
    {
      image: ProductSVG,
      bgColor: '#FFE5EC',
      title: 'Manual Entry',
      description: 'Use the manual entry form to add a product with a title, description, and bullet points.',
      buttonText: 'Add Product Manually',
      action: () => {
        dispatch({
          type: modalsActions.OPEN_MODAL,
          payload: {
            modalId: ADD_SINGLE_PRODUCT_MODAL,
            modalContent: '',
          },
        });
      },
    },
  ];

  return (
    <div className={classes.wrapper}>
      {productOptions.map((product) => (
        <div key={product.title} className={classes.product}>
          <div className={classes.imageWrapper}>
            <div className={classes.image} style={{ backgroundColor: product.bgColor }}>
              <img src={product.image} alt={product.description} />
            </div>
          </div>
          <h2 className={classes.title}>{ product.title }</h2>
          <p className={classes.description}>{product.description}</p>
          <Button
            startIcon={<IconPlus className={classes.startIcon} />}
            onClick={product.action}
          >
            { product.buttonText }
          </Button>
        </div>
      ))}
    </div>
  );
};

export default SingleProductModalOptions;
