import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';

import aiTemplateResource from '@root/resources/aiTemplate';
import { FormField, InputField, SelectField } from '@root/components/form';
import uiNotificationService from '@root/services/uiNotification.service';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useModalState from '@root/utils/hooks/useModalState';
import CustomBadge from '@root/components/Badges/CustomBadge';

import useStyles from './CustomTemplateView.styles';

const validationSchema = Yup.object({
  title: Yup
    .string()
    .required('title is required'),
});

const CustomTemplateView = () => {
  const classes = useStyles();

  const params = useParams();
  const [approveModalOpened, openApproveModal, closeApproveModal] = useModalState(false);
  const { data } = aiTemplateResource.useAiTemplate(params.id);
  const { mutateAsync: adminUpdateTemplate } = aiTemplateResource.useAdminUpdateTemplate();
  const { mutateAsync: approveTemplate } = aiTemplateResource.useApproveTemplate();

  const isDeleted = !!data.deletedOn;

  const handleSubmit = async (values) => {
    const { isBadRequest } = await adminUpdateTemplate(values);
    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Updated');
    }
  };

  const handleApprove = async () => {
    const response = await approveTemplate(data._id);
    if (!response.isBadRequest) {
      uiNotificationService.showSuccessMessage(`"${data.title}" was successfully approved!`);
    }
    closeApproveModal();
  };

  // just overwrite view status in case of deleted
  data.status = isDeleted ? 'deleted' : data.status;

  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid }) => {
        return (
          <Form
            className={classes.form}
          >
            <div className={classes.indicator}>
              <CustomBadge />
            </div>
            <div className={classes.row}>
              <FormField
                name="title"
                label="title"
                component={InputField}
                required
                readOnly={isDeleted}
              />
              <InputField
                label="email"
                value={data.user?.email}
                disabled
              />
            </div>
            <div className={classes.row}>
              <FormField
                name="description"
                label="description"
                multiline
                component={InputField}
                readOnly={isDeleted}
              />
              <FormField
                name="submitNotes"
                label="submitNotes"
                multiline
                component={InputField}
                readOnly
              />
            </div>
            <div className={classes.row}>
              <FormField
                name="status"
                label="status"
                readOnly
                component={SelectField}
                options={[
                  { value: 'draft', label: 'draft' },
                  { value: 'submitted', label: 'submitted' },
                  { value: 'live', label: 'live' },
                  { value: 'deleted', label: 'deleted' },
                ]}
              />
              <FormField
                name="creditCost"
                label="creditCost"
                component={InputField}
                required
                readOnly={isDeleted}
              />
            </div>
            <div className={classes.row}>
              <FormField
                name="aiOptions.temperature"
                label="Creativity"
                component={InputField}
                required
                type="number"
                readOnly={isDeleted}
              />
              <FormField
                name="aiOptions.max_tokens"
                label="max_tokens"
                component={InputField}
                required
                type="number"
                readOnly={isDeleted}
              />
            </div>
            <div className={classes.row}>
              <FormField
                name="aiOptions.n"
                label="n"
                component={InputField}
                required
                type="number"
                readOnly={isDeleted}
              />
              <FormField
                name="promptEngine"
                label="Prompt engine"
                component={SelectField}
                options={[
                  { value: 'text-davinci-001', label: 'text-davinci-001' },
                  { value: 'text-davinci-002', label: 'text-davinci-002' },
                  { value: 'text-davinci-003', label: 'text-davinci-003' },
                ]}
                readOnly={isDeleted}
              />
            </div>

            <div className={classes.buttons}>
              <Button
                fullWidth
                disabled={!isValid || isDeleted}
                type="submit"
              >
                Update
              </Button>
            </div>
            <div className={classes.buttons}>
              <Button
                fullWidth
                disabled={data?.status !== 'submitted' || isDeleted}
                onClick={openApproveModal}
              >
                Approve
              </Button>
            </div>
            {approveModalOpened && (
              <ConfirmationModal
                title="Approve generator and set Live"
                text={(
                  <>
                    If you approve this
                    <b> generator</b>
                    , the
                    <b> generator </b>
                    will be set Live. All members of customer`s team will be able to use it.
                    You cannot undo this.
                  </>
                )}
                confirmButtonText={(
                  <b>Approve</b>
                )}
                onConfirm={handleApprove}
                onClose={closeApproveModal}
              />
            )}
          </Form>
        );
      }}

    </Formik>
  );
};

export default CustomTemplateView;
