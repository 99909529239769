import React from 'react';

import GuidingTooltip from '@root/components/Interactive/GuidingTooltip';

const Guide = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const newUser = searchParams.get('newuser');

  return (
    <>
      <GuidingTooltip
        top={50}
        left={45}
        visible={newUser}
        fontSize="0.825rem"
        backgroundColor="#4f37e3"
        width="12rem"
      >
        <span>
          Tell our AI how to write your content — it’s
          here to listen!
          {' '}
          <i>
            Tip: Use the right-hand sample as a guide!
          </i>
        </span>
      </GuidingTooltip>
      <GuidingTooltip
        top={20}
        left={35}
        direction="right"
        visible={newUser}
        fontSize="0.825rem"
        backgroundColor="#4f37e3"
        width="12rem"
      >
        <div
          role="presentation"
          style={{
            position: 'absolute',
            top: '0.25rem',
            right: '0.5rem',
            fontSize: '0.9rem',
            cursor: 'pointer',
          }}
          onClick={() => window.history.push(
            window.location.pathname
            + window.location.search.replace(
              'newuser=true',
              '',
            ),
          )}
        >
          ✕
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          Voila! Fresh, original content. We’ll let you
          read through your generations now. Cheers to a
          new world of writing!
        </div>
      </GuidingTooltip>
    </>
  );
};

export default Guide;
