import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 464,
    padding: '24px 32px 32px',
  },
  content: {
    fontFamily: 'Inter, sans-serif',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '30px',
  },
  sectionDescription: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.darkGrey,
    marginBottom: '16px',
  },
  fields: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  fieldName: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    marginBottom: 0,

    textTransform: 'capitalize',
  },
  divider: {
    margin: '16px 0',
  },
  generatedContentLabel: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    color: theme.palette.colors.black,

    marginBottom: '8px',
  },
  outputExampleContent: {
    borderRadius: '4px',
    padding: '8px 12px',
    backgroundColor: theme.palette.colors.lightGrey,
  },
  stepCircle: {
    width: 24,
    height: 24,
    borderRadius: 24,
    fontWeight: '500',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.colors.black,
    border: `1px solid ${theme.palette.colors.black}`,
  },

  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '40px',
  },
}));

export default useStyles;
