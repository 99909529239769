import ReactDOM from 'react-dom';
import React from 'react';
import PubSub from 'pubsub-js';

import ContextWrapper from '@root/views/Editor/EditorToolsContextWrapper';

import './ImproveWriting.css';
import ImproveWritingButton from './ImproveWriting.button';
import ImproveWritingToolbox from './ImproveWriting.toolbox';

const getRandomString = () => Math.random().toString(16).slice(2);

export default class ImproveWriting {
  static get isInline() {
    return true;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
  }

  // this is custom destroy lifecycle function
  // for child components like toolbox tooltips
  // if they have side effects outside of element render
  // onDestroy(key, func) {
  //   this.destroyFuncs[key] = func;
  // }

  constructor({ api, config }) {
    this.api = api;
    this.config = config;
    this.button = null;
    this.toolbox = null;
    this._state = false;
    this.events = {
      openActionEvent: 'cs-improve-writing-open-actions',
      destroy: 'cs-improve-writing-destroy',
    };

    this.editorJSChangeEventID = getRandomString();

    // this.onDestroy = this.onDestroy.bind(this);
    // this.destroyFuncs = {};
  }

  surround(range) {
    this.state = !this.state;

    if (this.state) {
      PubSub.publish(this.events.openActionEvent, { range });
    }
  }

  clear() {
    PubSub.publish(this.events.destroy);
    this.button = null;
    this.toolbox = null;
  }

  checkState() {
    if (this.state) {
      this.showActions();
    } else {
      this.hideActions();
    }

    // Dispatch Editor.js change event even without DOM changes.
    this.editorJSChangeEventID = getRandomString();

    this.renderContainer();
  }

  render() {
    this.button = document.createElement('div');

    this.renderContainer();

    return this.button;
  }

  renderContainer() {
    ReactDOM.render((
      <ContextWrapper>
        <ImproveWritingButton
          styles={this.api.styles}
          state={this.state}
        />
      </ContextWrapper>
    ),
    this.button);
  }

  renderActions() {
    this.toolbox = document.createElement('div');
    ReactDOM.render((
      <ContextWrapper>
        <ImproveWritingToolbox
          styles={this.api.styles}
          selectionApi={this.api.selection}
          templates={this.config.templatesList}
          formRef={this.config.formRef}
          // onDestroy={this.onDestroy}
          events={this.events}
        />
      </ContextWrapper>
    ),
    this.toolbox);
    this.toolbox.hidden = true;

    return this.toolbox;
  }

  showActions() {
    this.toolbox.hidden = false;
  }

  hideActions() {
    this.toolbox.hidden = true;
  }
}
