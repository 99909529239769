import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'react-quill/dist/quill.snow.css';

import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';
import Onboarding from '@root/components/Onboarding';

import config from './config';
import history from './utils/history';
import Routes from './routes';
import useScripts from './useScripts';
import theme from './material.theme';
import GlobalLoading from './components/Loading/GlobalLoading';
import './App.css';
import useStyles from './App.styles';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const stripePromise = loadStripe(config.stripeKey);

const App = () => {
  const classes = useStyles();
  useScripts();

  return (
    <QueryClientProvider client={queryClient}>
      <BulkGenerationsContext.BulkGenerationsProvider>
        <Elements stripe={stripePromise}>
          <ReactQueryDevtools initialIsOpen={false} />
          <DndProvider backend={HTML5Backend}>
            <Router history={history}>
              <MuiThemeProvider theme={theme}>
                <div className={classes.content}>
                  <Routes />
                  <GlobalLoading />
                  <Onboarding />
                </div>
              </MuiThemeProvider>
            </Router>
          </DndProvider>
        </Elements>
      </BulkGenerationsContext.BulkGenerationsProvider>
    </QueryClientProvider>
  );
};

export default React.memo(App);
