import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  templatesListContainer: {
    flex: 1,
    padding: '24px 32px 30px',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.neutrals50,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    overflow: 'auto',
    width: '100%',
  },
  listContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: ({ columns = 3 }) => `repeat(${columns}, minmax(249px, 1fr))`,
    gridAutoRows: '180px',
    gridGap: 16,
  },
  searchContainer: {
    textAlign: 'center',
  },
  noResults: {
    marginTop: 32,
    textAlign: 'center',
  },
}));

export default useStyles;
