import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api/newApi';

const getFraseGeneration = (queryObj) => api.frase.generateSerp(queryObj);

const useFraseGeneration = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (queryObj) => getFraseGeneration(queryObj),
    {
      onSuccess: (data, variables) => { queryClient.setQueryData(['fraseFile', variables.documentId], { content: { query: variables.content, clusters: data } }); },
    },
  );
};

export default useFraseGeneration;
