import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M14 7.333c0-1.115.884-2 2-2s2 .885 2 2v8c0 .177-.07.347-.195.472l-1.334 1.333a.667.667 0 0 1-.942 0l-1.334-1.333a.666.666 0 0 1-.195-.472v-8Zm2-.666a.653.653 0 0 0-.667.666v7.724l.667.667.667-.667V7.333A.653.653 0 0 0 16 6.667Z" />
      <path d="M14 8.667c0-.369.299-.667.667-.667h2.666a.667.667 0 1 1 0 1.333h-2.666A.667.667 0 0 1 14 8.667ZM7.333 11.333c0-.368.299-.666.667-.666h2a2 2 0 1 1 0 4H7.333a.667.667 0 1 0 0 1.333H16a.667.667 0 0 1 0 1.333H7.333a2 2 0 1 1 0-4H10A.667.667 0 1 0 10 12H8a.667.667 0 0 1-.667-.667Z" />
    </svg>
  );
};
