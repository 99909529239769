import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usePlagiarismCheck from '@root/utils/hooks/usePlagiarismCheck';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import Modal from '@root/components/modals/Modal';

import useStyles from './CheckCopyUniqueness.styles';

const CheckCopyUniqueness = ({
  lastHighlightedText,
}) => {
  const [modalData, setModalData] = React.useState(null);
  const [tooltipData, setTooltipData] = React.useState(null);
  const classes = useStyles();

  const {
    plagiarismCheck,
    checkPlagiarism,
  } = usePlagiarismCheck(lastHighlightedText, setModalData, setTooltipData);
  const closeModal = () => {
    setModalData(null);
  };

  return (
    <>
      <Modal
        open={!!modalData}
        onClose={closeModal}
        maxWidth="md"
        title={modalData?.title}
      >
        {modalData?.body}
      </Modal>
      <SimpleTooltip
        content={tooltipData}
        placement="bottom"
      >
        <span>
          <FontAwesomeIcon
            icon="fingerprint"
            onClick={checkPlagiarism}
            className={clsx(classes.documentControlButton, {
              [classes.redDocumentControlButton]: plagiarismCheck.error,
            })}
          />
        </span>
      </SimpleTooltip>
    </>
  );
};

export default CheckCopyUniqueness;
