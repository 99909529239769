import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    marginTop: '32px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
  },
  movePageButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  movePageButton: {
    color: theme.palette.colors.neutrals600,

    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },
  disabledMovePageButton: {
    color: `${theme.palette.colors.neutrals200} !important`,
    fill: theme.palette.colors.neutrals200,
  },
  perPageContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  perPageSelect: {
    width: '60px',
    padding: '5px 5px',
    borderColor: theme.palette.primary.main,
  },
}));

export default useStyles;
