import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';
import aiTemplateResource from '@root/resources/aiTemplate';
import useModalState from '@root/utils/hooks/useModalState';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import TemplatesContext from '@root/resources/templates/templates.context';
import uiNotificationService from '@root/services/uiNotification.service';
import SubmitTemplateModal from '@root/components/modals/SubmitTemplateModal';
import Badge from '@root/components/Badges/NewBadge';
import AuthContext from '@root/resources/auth/auth.context';
import { Gradient1, Gradient2, Gradient3, Gradient4, Gradient5, Gradient6 } from '@root/assets/cardGradients';

import { TEMPLATE_CREATE_MODAL_ID } from '../modals';
import useCommonStyles from '../TemplateCard.styles';

import useStyles from './CustomTemplateCard.styles';

const CustomTemplateCard = ({ template }) => {
  const history = useHistory();
  const { currentUser } = AuthContext.useAuth();
  const {
    mutateAsync: createOrUpdateCustom,
  } = aiTemplateResource.useCreateOrUpdateCustomAiTemplate();
  const { mutateAsync: deleteAiTemplate } = aiTemplateResource.useDeleteAiTemplate();
  const { loadTemplates } = TemplatesContext.useTemplates();
  const canManageTemplateBuilder = currentUser.permissions.manageTemplateBuilder;
  const isTemplateDownloadedByMe = template?.downloadedBy === currentUser?._id;

  const [isDeleteOpen, openDelete, closeDelete] = useModalState();
  const [isSubmitOpen, openSubmit, closeSubmit] = useModalState();

  const { hasFiles, _id, status, submittedOn, fromCommunity } = template;

  const classes = useStyles({ status });
  const commonClasses = useCommonStyles();

  const { dispatch } = useGlobalModals();

  const isLive = status === 'live';
  const isSubmitted = status === 'submitted';

  const handleCreateModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: template,
      },
    });
  };

  const handleEditClick = () => {
    history.push(`/template-builder/${_id}`);
  };

  const deleteTemplate = async () => {
    const { isBadRequest } = await deleteAiTemplate(_id);
    if (!isBadRequest) {
      closeDelete();
      loadTemplates();
      uiNotificationService.showSuccessMessage('Generator was successfully deleted');
    }
  };

  const submitTemplate = async ({ submitNotes }) => {
    const { isBadRequest } = await createOrUpdateCustom({
      ...template,
      _id,
      status: 'submitted',
      submitNotes,
    });
    if (!isBadRequest) {
      closeSubmit();
      loadTemplates();
      uiNotificationService.showSuccessMessage('Generator was successfully submitted');
    }
  };

  const badgeColor = useMemo(() => {
    if (fromCommunity) {
      return 'white';
    }

    if (isLive) {
      return 'success';
    }

    return 'primary';
  }, [fromCommunity, isLive]);

  const badgeContent = useMemo(() => {
    if (fromCommunity) {
      return 'Community';
    }
    if (isSubmitted) {
      return `${capitalize(status)} on ${DateTime.fromISO(submittedOn).toFormat('MMMM dd')}`;
    }

    return capitalize(status);
  }, [fromCommunity, isSubmitted, status, submittedOn]);

  const backgrounds = useMemo(
    () => [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5, Gradient6],
  );

  const background = useMemo(
    () => backgrounds[Math.floor(Math.random() * backgrounds.length)], [],
  );

  return (
    <div
      className={clsx(
        commonClasses.card, classes[status],
        { [classes.communityCard]: fromCommunity },
      )}
      style={{ backgroundImage: `${fromCommunity ? `url(${background})` : 'unset'}` }}
    >

      <div className={commonClasses.topActions}>
        <Badge
          color={badgeColor}
          content={badgeContent}
        />
        <div className={commonClasses.iconButtons}>
          {(canManageTemplateBuilder || isTemplateDownloadedByMe) && (
            <IconButton
              onClick={openDelete}
              className={clsx({ [classes.communityIcon]: fromCommunity })}
            >
              <Delete />
            </IconButton>
          )}
        </div>
      </div>

      <h2
        className={commonClasses.title}
      >
        {template.title}
      </h2>
      <p
        className={commonClasses.description}
      >
        {template.description}
      </p>
      <div className={classes.actionButtons}>
        {status === 'live' && (
          <Button
            size="small"
            onClick={handleCreateModal}
            className={clsx({ [classes.communityButton]: fromCommunity })}
          >
            Create
          </Button>
        )}

        {status === 'draft' && (
          <Button
            size="small"
            onClick={openSubmit}
          >
            Submit
          </Button>
        )}

        {status === 'submitted' && (
          <Button
            size="small"
            variant="outlined"
            color="default"
            onClick={handleEditClick}
          >
            Overview
          </Button>
        )}
        {status !== 'submitted' && canManageTemplateBuilder && (
          <Button
            size="small"
            variant="text"
            onClick={handleEditClick}
            className={clsx({ [classes.communityEditButton]: fromCommunity })}
          >
            Edit
          </Button>
        )}
        {isDeleteOpen && (
          <ConfirmationModal
            title="Delete generator"
            confirmButtonText="Delete"
            negative
            onConfirm={deleteTemplate}
            onClose={closeDelete}
          >
            {hasFiles ? (
              <>
                If you delete the&nbsp;
                <b>generator</b>
                , members on your team won&apos;t be able to use this&nbsp;
                <b>generator</b>
                &nbsp;for new content creation.
                Files that have already been created will still be available.
              </>
            ) : 'You cannot restore the generator.'}
          </ConfirmationModal>
        )}

        {isSubmitOpen && (
          <SubmitTemplateModal
            onClose={closeSubmit}
            onSubmit={submitTemplate}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTemplateCard;
