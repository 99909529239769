import React from 'react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { getReadabilityData } from '@root/utils/readability.util';

import BadgeIcon from './BadgeIcon';

const ReadabilityBadge = ({ readabilityScore, readingTime }) => {
  const badgeData = getReadabilityData(readabilityScore);

  const tooltipContent = (
    <div>
      <div>
        {badgeData.text}
        {' '}
        to read by grade level 9
      </div>
      <div>
        Reading time:&nbsp;
        {readingTime}
      </div>
    </div>
  );

  return (
    <SimpleTooltip
      content={tooltipContent}
      placement="top"
      wrapped
    >
      <BadgeIcon
        highlight
        color={badgeData.color}
        text={`Readability: ${badgeData.text}`}
      />
    </SimpleTooltip>
  );
};

export default ReadabilityBadge;
