import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  delete: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: '10px',
  },
  startIcon: {
    color: theme.palette.colors.black,
  },

}));

export default useStyles;
