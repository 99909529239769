import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const getProductByDocumentId = async (documentId) => {
  const res = await api.shopify.getProduct({ documentId });
  return res;
};

export default function useProduct(documentId) {
  return useQuery(['shopify-product', documentId], () => getProductByDocumentId(documentId));
}
