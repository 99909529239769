/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import SingleSelectField from './Fields/StringSelect';
import StringField from './Fields/StringField';

const PromptField = ({
  field,
  formik,
  className,
}) => {
  let FieldComponent = null;
  let helperText = field.helper;
  let extraProps = {};
  switch (field?.uiOptions?.type) {
    case 'singleSelect':
      FieldComponent = SingleSelectField;
      extraProps = {
        options: field?.uiOptions?.values || [],
      };
      break;
    case 'string':
      FieldComponent = StringField;
      break;
    case 'keywords':
      helperText = 'Separate multiple values by comma';
      FieldComponent = StringField;
      break;
    case 'multilineString':
      FieldComponent = StringField;
      extraProps = {
        minRows: 2,
        maxRows: 4,
        multiline: true,
      };
      break;
    default:
      FieldComponent = StringField;
      break;
  }

  return (
    <FieldComponent
      formik={formik}
      name={field.name}
      helperText={helperText}
      className={className}
      {...extraProps}
    />
  );
};

export default PromptField;
