import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import Button from '@root/components/buttons/Button';
import OverageModal from '@root/components/modals/OverageModal';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useModalState from '@root/utils/hooks/useModalState';
import useCreateOveragesSettings from '@root/resources/billing/useCreateOveragesSettings';
import useUpdateOveragesSettings from '@root/resources/billing/useUpdateOveragesSettings';
import useDeleteOveragesSettings from '@root/resources/billing/useDeleteOveragesSettings';
import usePayOverageInvoice from '@root/resources/billing/usePayOverageInvoice';

import useSectionsStyles from '../Sections.styles';

import EmptyState from './EmptyState';
import useStyles from './Overage.styles';

const Overage = ({ billingData, isOwner }) => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();
  const [isOverageSettingsOpen, openOverageSettings, closeOverageSettings] = useModalState(false);
  const [isCancelOpen, openCancel, closeCancel] = useModalState(false);
  const [isPayOpen, openPay, closePay] = useModalState(false);
  const { mutateAsync: createOveragesSettings, isLoading: isCreateOveragesLoading } = useCreateOveragesSettings();
  const { mutateAsync: updateOveragesSettings, isLoading: isUpdateOveragesLoading } = useUpdateOveragesSettings();
  const { mutateAsync: deleteOveragesSettings, isLoading: isDeleteOveragesLoading } = useDeleteOveragesSettings();
  const { mutateAsync: payOverageInvoice, isLoading: isPayOverageInvoiceLoading } = usePayOverageInvoice();

  const unitsUsed = billingData.maxOverageUnits - billingData.overageUnitsRemaining;
  const overagesEnabled = billingData.maxOverageUnits > 0;

  return (
    <>
      <section className={sectionClasses.sectionContainer}>
        <div className={sectionClasses.sectionTitleContainer}>
          <Typography variant="h4" className={sectionClasses.sectionTitle}>
            Overage
          </Typography>
          <Typography variant="body1" className={sectionClasses.sectionSubtitle}>
            Here is where your overage will show.
          </Typography>
        </div>
        <div className={clsx(
          sectionClasses.cardContainer,
          sectionClasses.wideCard,
          classes.overageCard,
        )}
        >
          {overagesEnabled
            ? (
              <div className={classes.overageList}>
                <header className={classes.overageHeader}>
                  <Typography className={classes.headerText}>Add-on</Typography>
                  <Typography className={classes.headerText}>Unit</Typography>
                  <Typography className={classes.headerText}>Limit</Typography>
                  <Typography className={classes.headerText}>Units used</Typography>
                  <div />
                </header>
                <div className={classes.divider} />
                <div className={classes.overageInfo}>Overage</div>
                <div className={classes.overageInfo}>
                  {`$${billingData.overageTierPrice}.00 / ${billingData.overageTierWordCount} Words`}
                </div>
                <div className={classes.overageInfo}>
                  {`${billingData.overageTierWordCount * billingData.maxOverageUnits} Words`}
                </div>
                <div className={classes.overageInfo}>
                  {`${unitsUsed
                  } Units = $${billingData.overageTierPrice * unitsUsed}`}

                </div>
                {isOwner && (
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={billingData.overageInvoiceStatus === 'failed' ? openPay : openOverageSettings}
                    >
                      {billingData.overageInvoiceStatus === 'failed' ? 'Pay' : 'Modify'}
                    </Button>
                    {billingData.maxOverageUnits === billingData.overageUnitsRemaining && (
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={openCancel}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                ) }
                <div className={classes.divider} />
              </div>
            )
            : <EmptyState onActivate={openOverageSettings} isOwner={isOwner} />}
        </div>
      </section>
      {isOverageSettingsOpen && (
        <OverageModal
          currentQuantity={billingData.maxOverageUnits}
          availableUnitsRemaining={billingData.overageUnitsRemaining}
          isLoading={isCreateOveragesLoading || isUpdateOveragesLoading}
          onClose={closeOverageSettings}
          onSubmit={overagesEnabled ? updateOveragesSettings : createOveragesSettings}
          tier={billingData.overageTier > 0 ? billingData.overageTier.toString() : undefined}
        />
      )}
      {isCancelOpen && (
        <ConfirmationModal
          onClose={closeCancel}
          onConfirm={async () => {
            await deleteOveragesSettings();
            closeCancel();
          }}
          title="Cancel Overage Settings"
          confirmButtonText="Cancel Overage Settings"
          cancelButtonText="Back"
          negative
          isLoading={isDeleteOveragesLoading}
        >
          Are you sure you want to cancel your overage settings?
        </ConfirmationModal>
      )}
      {isPayOpen && (
        <ConfirmationModal
          onClose={closePay}
          onConfirm={async () => {
            await payOverageInvoice();
            closePay();
          }}
          title="Pay Overage Invoice"
          confirmButtonText="Pay"
          cancelButtonText="Back"
          negative
          isLoading={isPayOverageInvoiceLoading}
        >
          {`You will be charged $${billingData.overageTierPrice}.00 for ${billingData.overageTierWordCount} words.`}
        </ConfirmationModal>
      )}
    </>
  );
};

export default Overage;
