import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { IconAdjustmentsOff, IconBookmark, IconAnalyze, IconChevronRight, IconClipboardCheck } from '@tabler/icons-react';
import { Menu } from '@material-ui/core';
import clsx from 'clsx';

import { colors } from '@root/material.theme';
import { workflowStatusOptions } from '@root/resources/file/file.constants';
import AuthContext from '@root/resources/auth/auth.context';
import { actions, useDashboard } from '@root/views/Dashboard/dashboardContext';

import useStyles from './FiltersSelect.styles';

const customStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    minHeight: '36px',
    height: '36px',
    borderRadius: '6px',
    cursor: 'pointer',

    borderColor: isFocused ? colors.neutrals400 : colors.neutrals200,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
      borderColor: isFocused ? colors.neutrals400 : colors.neutrals300,
    },
  }),

  input: (provided) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '2px 6px',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    alignItems: 'center',
    justifyContent: 'center',
    height: '34px',
    padding: '2px 6px',
  }),

  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  }),

  menu: (provided) => ({
    ...provided,
    width: 'max-content',
    borderRadius: '6px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '4px',
    borderRadius: '6px',

    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  }),

  group: () => ({
    padding: 0,
  }),
  groupHeading: () => ({
    display: 'none',
  }),

  option: (styles, state) => ({
    ...styles,
    padding: '4px 8px 4px 4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: colors.neutrals400,
    cursor: 'pointer',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: colors.neutrals50,
    },

    backgroundColor: state.isSelected ? colors.neutrals50 : colors.white,
  }),

};

// custom option component for 'react-select'
const Option = (props) => {
  const classes = useStyles();

  const { data } = props;

  return (
    <components.Option {...props}>
      {data.Icon && (
        <data.Icon />
      )}

      <span className={classes.optionLabel}>{data.label}</span>
    </components.Option>
  );
};

// custom group component for 'react-select'
const Group = (props) => {
  const classes = useStyles();
  const [anchorRef, setAnchorRef] = useState();

  const { children, headingProps } = props;

  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };
  const transformOrigin = {
    horizontal: 'right',
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (anchorRef !== e.currentTarget) {
      setAnchorRef(e.currentTarget);
    }
    if (!anchorRef) {
      setAnchorRef(e.currentTarget);
    } else {
      setAnchorRef(null);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorRef(null);
  };

  const modifyChildren = (child) => {
    const className = clsx(
      child.props.className,
      classes.nestedOption,
    );

    const childProps = {
      className,
    };

    return React.cloneElement(child, childProps);
  };

  return (
    <components.Group {...props}>
      <div
        ref={anchorRef}
        onClick={handleOpen}
        className={classes.groupHeading}
      >
        {headingProps?.data?.Icon && <headingProps.data.Icon />}

        <span className={classes.optionLabel}>{headingProps?.data?.label}</span>

        <IconChevronRight
          className={clsx(
            classes.chevronIcon,
            { [classes.openedChevron]: Boolean(anchorRef) },
          )}
        />
      </div>

      <Menu
        anchorEl={anchorRef}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={Boolean(anchorRef)}
        onClose={handleClose}
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        style={{ transform: 'translateX(-12px)' }}
        disableEnforceFocus
        disableAutoFocus
        disableAutoFocusItem
        autoFocus={false}
      >
        {children.map((child) => modifyChildren(child))}
      </Menu>
    </components.Group>
  );
};

const FiltersSelect = () => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { state: { filters }, dispatch } = useDashboard();

  const { teams, workflowQueue } = currentUser.permissions;
  const isFilterByStatusEnabled = teams && workflowQueue;

  const options = useMemo(() => ([
    {
      value: 'none',
      label: 'None',
      Icon: IconAdjustmentsOff,
    },
    {
      value: 'bookmarked',
      label: 'Bookmarked',
      Icon: IconBookmark,
    },
    ...(isFilterByStatusEnabled ? [{
      value: 'byStatus',
      label: 'By Status',
      Icon: IconAnalyze,
      options: workflowStatusOptions,
    }] : []),
    {
      value: 'assignedToMe',
      label: 'Assigned to me',
      Icon: IconClipboardCheck,
    },
  ]), [isFilterByStatusEnabled, workflowStatusOptions]);

  const handleChange = (data) => {
    const workflowStatusValues = workflowStatusOptions.map((statusOption) => statusOption.value);

    const isWorkflowStatusSelected = workflowStatusValues.includes(data.value);

    if (isWorkflowStatusSelected) {
      dispatch({
        type: actions.SET_FILTERS,
        payload: {
          filters: {
            folderId: filters?.folderId,
            folderIds: filters?.folderIds,
            workflowStatuses: [data.value],
          },
        },
      });
    }

    if (data?.value === 'bookmarked') {
      dispatch({
        type: actions.SET_FILTERS,
        payload: {
          filters: {
            folderId: filters?.folderId,
            folderIds: filters?.folderIds,
            liked: true,
          },
        },
      });
    }

    if (data?.value === 'none') {
      dispatch({
        type: actions.SET_FILTERS,
        payload: {
          filters: {
            folderId: filters?.folderId,
            folderIds: filters?.folderIds,
          },
        },
      });
    }

    if (data?.value === 'assignedToMe') {
      dispatch({
        type: actions.SET_FILTERS,
        payload: {
          filters: {
            folderId: filters?.folderId,
            folderIds: filters?.folderIds,
            isAssignedToMeOnly: true,
          },
        },
      });
    }
  };

  // renew select value on project/folders change
  const selectValue = useMemo(() => {
    if (filters?.isAssignedToMeOnly) {
      return options.find((option) => option.value === 'assignedToMe');
    }

    if (filters?.liked) {
      return options.find((option) => option.value === 'bookmarked');
    }

    const isWorkflowStatusSelected = (
      filters?.workflowStatuses?.some(
        (filterStatus) => workflowStatusOptions.map(
          (option) => option.value,
        ).indexOf(filterStatus) >= 0,
      )
    );

    if (isWorkflowStatusSelected) {
      return workflowStatusOptions.find((option) => option.value === filters?.workflowStatuses[0]);
    }

    return options.find((option) => option.value === 'none');
  }, [filters, options]);

  return (
    <Select
      options={options}
      className={classes.select}
      components={{ Option, Group, IndicatorSeparator: null }}
      styles={customStyles}
      defaultValue={options[0]}
      value={selectValue}
      onChange={handleChange}
    />
  );
};

export default FiltersSelect;
