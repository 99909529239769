import React from 'react';

import RemoveFromLibraryConfirmationModal from '../RemoveFromLibraryConfirmationModal';
import TemplatePreviewModal from '../TemplatePreviewModal';
import SaveConfirmationModal from '../SaveConfirmationModal';
import RemoveConfirmationModal from '../RemoveConfirmationModal';
import UploadConfirmationModal from '../UploadConfirmationModal';
import { viewTypes } from '../../Community.constants';
import { useCommunityContext } from '../../context/Community.context';

import EmptyView from './components/EmptyView';
import EmptySearchView from './components/EmptySearchView';
import CardContentView from './components/CardContentView';
import ListContentView from './components/ListContentView';

const Content = () => {
  const {
    state: { viewType, searchQuery },
    templatesToShow,
  } = useCommunityContext();

  const isEmptyView = !templatesToShow?.length;
  const isEmptySearchView = !!searchQuery && !templatesToShow?.length;

  const isListView = !isEmptyView && viewType === viewTypes.LIST;
  const isCardView = !isEmptyView && viewType === viewTypes.CARD;

  return (
    <>
      <TemplatePreviewModal />
      <UploadConfirmationModal />
      <RemoveConfirmationModal />
      <SaveConfirmationModal />
      <RemoveFromLibraryConfirmationModal />

      {isEmptyView && !isEmptySearchView && <EmptyView />}
      {isEmptySearchView && <EmptySearchView />}

      {isListView && <ListContentView />}

      {isCardView && <CardContentView />}
    </>
  );
};

export default Content;
