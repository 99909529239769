import React from 'react';

import AuthContext from '@root/resources/auth/auth.context';
import useFolders from '@root/resources/folder/useFolders';
import { getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';
import MyWorkspaceSection from '@root/views/Dashboard/components/FolderSidebarNew/MyWorkspaceSection';
import SharedSection from '@root/views/Dashboard/components/FolderSidebarNew/SharedSection';

import useStyles from './FolderSidebarNew.styles';

const FolderSidebar = () => {
  const { currentUser } = AuthContext.useAuth();

  const {
    data: folders = [], isLoading: isLoadingFolders,
  } = useFolders();

  const rootFolders = !isLoadingFolders ? getRootFolders(folders, currentUser) : [];
  const sharedRootFolders = !isLoadingFolders ? getSharedRootFolders(folders, currentUser) : [];

  const classes = useStyles();

  return (
    <div className={classes.folderTreeContainer}>
      <MyWorkspaceSection rootFolders={rootFolders} />

      {!!sharedRootFolders?.length && <SharedSection rootFolders={sharedRootFolders} />}
    </div>
  );
};

export default FolderSidebar;
