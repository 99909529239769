import { StringUtil } from '@copysmith/utils';
import { unified } from 'unified';
import retextEnglish from 'retext-english';
import retextStringify from 'retext-stringify';
import retextReadability from 'retext-readability';

export const getReadabilityScore = async (text) => {
  const { messages } = await unified()
    .use(retextEnglish)
    .use(retextReadability)
    .use(retextStringify)
    .process(text);
  return messages[0]?.confidence || 1;
};

export const getReadabilityData = (readabilityScore) => {
  if (readabilityScore <= 0.25) {
    return {
      text: 'Hard',
      color: '#F1436D',
    };
  }
  if (readabilityScore <= 0.5) {
    return {
      text: 'Fairly Hard',
      color: '#FFD965',
    };
  }

  if (readabilityScore <= 0.75) {
    return {
      text: 'Easy',
      color: '#46DC00',
    };
  }

  return {
    text: 'Very Easy',
    color: '#523AE7',
  };
};

export const getReadingTime = (text) => {
  const wordsPerMinute = 225;
  const wordsPerSecond = wordsPerMinute / 60;
  const words = StringUtil.countWordsString(text);
  const time = Math.ceil(words / wordsPerSecond);
  if (time < 60) {
    return `${time} seconds`;
  }
  return `${Math.ceil(time / 60)} minutes`;
};
