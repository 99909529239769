import React, { useState, useMemo } from 'react';
import { ClickAwayListener, makeStyles } from '@material-ui/core';

import LocationSelectMenu from '@root/components/Interactive/LocationSelectMenu';
import Button from '@root/components/buttons/Button';
import CloseButton from '@root/components/buttons/CloseButton';
import { useDashboard } from '@root/views/Dashboard/dashboardContext';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import useFolders from '@root/resources/folder/useFolders';
import { getChildFolders, getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    padding: '1.5rem',
    position: 'relative',
    minWidth: '20rem',
  },
  header: {
    fontSize: '0.95rem',
    marginBottom: '1rem',
  },
  button: {
    textAlign: 'right',
    marginTop: '1rem',
  },
  label: {
    marginBottom: '0.5rem',
  },
});

const BulkMoveFileForm = ({ closeModal }) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const { mutateAsync: updateFile } = useUpdateFile();
  const { data: folders } = useFolders();
  const {
    state: { filters: { folderId: currentFolderId } },
  } = useDashboard();
  const { state: { modalContent } } = useGlobalModals();

  const foldersList = useMemo(() => {
    if (!folders?.length) {
      return [];
    }

    const rootFolders = getRootFolders(folders, currentUser);
    const rootSharedFolders = getSharedRootFolders(folders, currentUser);

    const parentFolders = [...rootFolders, ...rootSharedFolders];

    const homeFolder = parentFolders.find((f) => f.home);
    const projectFolders = parentFolders.filter((f) => !f.home);

    const sortedFolders = [homeFolder, ...projectFolders]
      .flatMap((parentFolder) => {
        const childFolders = getChildFolders(parentFolder._id, folders);

        return [
          {
            ...parentFolder,
            hasChildFolders: !!childFolders?.length,
          },
          ...childFolders,
        ];
      });

    const withoutSharedUncategorized = sortedFolders.filter((f) => !f.isSharedUncategorized);

    return withoutSharedUncategorized.map(({
      _id,
      title,
      parentFolderId,
      home,
      hasChildFolders,
      isOptionDisabled,
    }) => ({
      home,
      parentFolderId,
      hasChildFolders,
      label: home ? 'Other' : title,
      value: _id,
      isOptionDisabled,
    }));
  }, [folders]);

  const [isLocationSelectMenuOpen, setIsLocationSelectMenuOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(foldersList.find(
    (x) => x.value === currentFolderId,
  ).value);

  if (!modalContent) {
    return null;
  }

  const { selectedFileIds } = modalContent;

  const submit = async () => {
    await Promise.all(
      selectedFileIds.map(
        (fileId) => updateFile({
          fileId,
          data: {
            folderId: selectedLocation,
          },
        }),
      ),
    );
    closeModal();
  };

  const handleLocationSelect = (event, value) => {
    if (event.target.id === 'arrowIndicator') {
      return;
    }
    setIsLocationSelectMenuOpen(!isLocationSelectMenuOpen);

    if (value) {
      setSelectedLocation(value);
    }
  };

  return (
    <div className={classes.root}>
      <h1 className={classes.header}>
        Move
        {' '}
        {selectedFileIds.length}
        {' '}
        file(s)?
      </h1>
      <div className={classes.label}>
        Select a new folder:
      </div>
      <div onClick={(event, value) => handleLocationSelect(event, value)}>
        <ClickAwayListener
          onClickAway={() => setIsLocationSelectMenuOpen(false)}
        >
          <span>
            <LocationSelectMenu
              folders={foldersList}
              location={selectedLocation}
              onLocationSelect={(event, value) => handleLocationSelect(event, value)}
              isOpen={isLocationSelectMenuOpen}
            />
          </span>
        </ClickAwayListener>
      </div>
      <div className={classes.button}>
        <Button type="button" onClick={submit}>Confirm</Button>
      </div>
      <CloseButton onClick={closeModal} />
    </div>
  );
};

export default BulkMoveFileForm;
