import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  modal: {
  },
  paper: {
  },
  modalTitle: {
    ...theme.typography.h6,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  modalSubtext: {
    ...theme.typography.body2,
  },
  modalBodyText: {
    ...theme.typography.body1,
    whiteSpace: 'pre-line',
    marginTop: '0.5rem',
    height: '76%',
    overflowY: 'auto',
  },
  cancelButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '1rem',
    cursor: 'pointer',
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  cell: {
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },
}));
