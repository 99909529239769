import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    zIndex: '1',
    width: '150px',

    '&:active, &:focus': {
      outline: 'none',
    },
  },

  groupHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.colors.neutrals400,
    padding: '4px',
    cursor: 'pointer',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },
  chevronIcon: {
    marginLeft: 'auto',
    color: theme.palette.colors.neutrals200,
  },
  openedChevron: {
    color: theme.palette.colors.neutrals400,
  },

  menuList: {
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },

  optionLabel: {
    color: `${theme.palette.colors.neutrals600} !important`,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  },

  nestedOption: {
    '&&': {
      padding: '4px 8px',
    },
  },
}));

export default useStyles;
