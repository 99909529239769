import React from 'react';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import useStyles from './GenerateSingleProductModal.styles';

export const GENERATE_PRODUCT_MODAL = 'GENERATE_PRODUCT_MODAL';

const GenerateSingleProductModal = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch, state: { modalId } } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  return (
    <Modal
      title="Add a product from a URL"
      aria-labelledby="transition-modal-product-url"
      aria-describedby="transition-modal-product-description"
      open={modalId === GENERATE_PRODUCT_MODAL}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modalPaper }}
    >
      {/* <ProductUrlForm /> */}
    </Modal>
  );
};

export default GenerateSingleProductModal;
