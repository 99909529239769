import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1.5rem',
  },
  formGroup: {
    marginBottom: '1rem',
  },
  info: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  radioField: {
    color: theme.palette.colors.neutrals600,
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    marginLeft: '0.5rem',
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.neutrals600,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 12px',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
}));

export default useStyles;
