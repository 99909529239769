import React from 'react';
import { useIsFetching } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

import templatesContext from '@root/resources/templates/templates.context';

import useStyles from './GlobalLoading.styles';

const GlobalLoading = () => {
  const queriesCount = useIsFetching();
  const bulkJobQueriesCount = useIsFetching({
    queryKey: ['currentBulkGenerationJob', {}],
  });

  // since we use templates fetching across react-query
  const { isFetching: isTemplatesFetching } = templatesContext.useTemplates();

  // prevent spinner flickering
  const queriesToShowCount = queriesCount - bulkJobQueriesCount;

  const styles = useStyles();

  const isLoading = (queriesToShowCount !== 0) || isTemplatesFetching;

  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.loader}>
      <CircularProgress />
    </div>
  );
};

export default GlobalLoading;
