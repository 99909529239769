export default {
  title: 'Google Ad',
  description: 'Headline, description, result!',
  input: [
    {
      title: 'Company Name',
      value: 'JBL',
    },
    {
      title: 'Audience',
      value: 'Young professionals',
    },
    {
      title: 'Description',
      value: 'JBL makes consumer audio for the everyday listener.',
    },
    {
      title: 'Tone or Product Keywords',
      value: 'Earphones, Headphones, Immerse, Wireless, Superior',
    },
  ],
  output: {
    _id: '1',
    templateType: 'googleAd',
    content: {
      headline1: 'JBL Earphones',
      headline2: 'Take Your Music with You',
      headline3: 'Get Yours Today',
      description1: 'Immerse yourself in music no matter where you are with JBL headphones. Get yours today.',
      description2: 'Whether you prefer in-, on-, or over-ear headphones, we have got you covered.',
    },
    liked: false,
    editing: false,
    votes: null,
  },
  fileData: {
    companyName: 'JBL',
  },
};
