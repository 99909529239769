/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { DeleteOutline as RemoveIcon } from '@material-ui/icons';
import { Form, Formik, FieldArray } from 'formik';

import api from '@root/api';
import useInvites from '@root/resources/invite/useInvites';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import AuthContext from '@root/resources/auth/auth.context';
import useModalState from '@root/utils/hooks/useModalState';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import Modal from '@root/components/modals/Modal';
import { FormField, InputField } from '@root/components/form';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import uiNotificationService from '@root/services/uiNotification.service';

import RoleSelect from './RoleSelect';
import useStyles from './InviteModal.styles';

const validateEmail = (email, emails) => {
  let error;
  if (emails.includes(email)) {
    error = `[${email}] is already a member or has a pending invite.`;
  }
  return error;
};

const InviteModal = ({ onClose }) => {
  const classes = useStyles();

  const { currentCompany, currentUser } = AuthContext.useAuth();
  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const { refetch, data: invitesData = { results: [] } } = useInvites();
  const { data: membersDataAll = { results: [] } } = useTeamMembers({ allMembers: true });

  const membersData = membersDataAll.results.filter((m) => !m.deactivated);
  const currentMembersCount = membersData.length;
  const {
    limits: { members: baseMembersLimit }, memberPrice, currentLimitId,
  } = currentCompany.billing;
  const additionalTeamSeatsCount = currentCompany.billing?.addons?.additionalMembers?.quantity || 0;
  const currentEmails = [
    ...membersData.map((x) => x.email),
    ...invitesData.results.map((x) => x.inviteeEmail),
  ];

  const membersRemaining = baseMembersLimit - currentMembersCount;
  const emails = currentEmails;

  const [isConfirmationOpen, openConfirmation, closeConfirmation] = useModalState();

  const isFree = PLANS_IDS.free.includes(currentLimitId);
  const isTeams = PLANS_IDS.legacyTeams.includes(currentLimitId);
  const isSelfServeEnterprise = currentUser.onSelfServeEnterprisePlan;

  // we won't display modal confirmation for these plans
  // if we reached the maximum amount during the free trial period, an error popup appears
  const isPlanWithoutChargingModal = isTeams || isSelfServeEnterprise || isFree;
  const isMembersLimitReached = currentMembersCount - baseMembersLimit >= additionalTeamSeatsCount;

  const shouldConfirmInvite = !isPlanWithoutChargingModal && isMembersLimitReached;

  const inviteMembers = (members) => {
    const invitees = members.filter((x) => x.email.length !== 0);

    if (invitees.length === 0) {
      return false;
    }

    return Promise.all(invitees.map((invitee) => api.invite.create({
      inviteeEmail: invitee.email,
      inviteeRole: invitee.role,
    })));
  };

  const handleSubmit = async ({ members }) => {
    if (isSelfServeEnterprise && (members.length + emails.length) > baseMembersLimit) {
      uiNotificationService.showErrorMessage('Please contact sales team to invite more members.');
      return;
    }

    await inviteMembers(members);
    refetchOnboarding('inviteTeamMember');
    refetch();
  };

  return (
    <Modal
      onClose={onClose}
      maxWidth="md"
      title="Invite Team Members"
    >
      <div className={classes.content}>
        <p>Your teammates will receive an invite through email.</p>
        <Formik
          initialValues={{
            members: [
              {
                email: '',
                role: 'member',
              },
            ],
          }}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, isSubmitting, handleSubmit: submitForm }) => {
            return (
              <Form>
                <>
                  <FieldArray name="members">
                    {({ remove, replace, push }) => {
                      const handleRemove = (idx) => () => {
                        if (values.members.length > 1) {
                          remove(idx);
                        } else {
                          replace(idx, { email: '', role: 'member' });
                        }
                      };
                      return (
                        <>
                          <div className={classes.memberRowContainer}>
                            {values.members.map((member, index) => (
                              <div className={classes.memberRow} key={index}>
                                <FormField
                                  component={InputField}
                                  name={`members[${index}].email`}
                                  placeholder="Enter email"
                                  classNames={{
                                    root: classes.emailInput,
                                  }}
                                  validate={(value) => validateEmail(value, emails)}
                                />
                                <FormField
                                  component={RoleSelect}
                                  name={`members[${index}].role`}
                                />
                                <IconButton
                                  onClick={handleRemove(index)}
                                  className={classes.selectItem}
                                  color="default"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            ))}
                          </div>
                          {(values.members.length < membersRemaining) || memberPrice ? (
                            <Button
                              variant="text"
                              disabled={values.members.slice(-1).email === ''}
                              type="button"
                              onClick={() => push({
                                email: '',
                                role: 'member',
                              })}
                            >
                              + Add another team member
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              disabled
                              type="button"
                            >
                              + Cannot exceed maximum team size
                            </Button>
                          )}
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={onClose}
                      type="button"
                      variant="text"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting || !isValid}
                      onClick={shouldConfirmInvite ? openConfirmation : submitForm}
                    >
                      Send Invites
                    </Button>
                  </div>

                  {isConfirmationOpen && (
                    <ConfirmationModal
                      title="Add team member"
                      maxWidth="xs"
                      text="You're adding team members in the middle of a billing cycle. You will be pro-rated for this additional and charged $9 for each new seat next month. Confirm this charge."
                      onClose={closeConfirmation}
                      onConfirm={submitForm}
                      isConfirmDisabled={isSubmitting}
                    />
                  )}
                </>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default InviteModal;
