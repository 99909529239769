import React, { createContext, useState } from 'react';

import api from '@root/api/newApi';
import userService from '@root/resources/user/user.service';

import companyService from '../company/company.service';

export const UserContext = createContext({
  currentUser: null,
  currentCompany: null,
});

const AuthProvider = ({
  children, user, company,
}) => {
  const [currentUser, setCurrentUser] = useState(user);
  const [currentCompany, setCurrentCompany] = useState(company);

  const fetchAuthData = async () => {
    let u = await api.user.getCurrentUser();
    if (u.status > 300) {
      return null;
    }

    let c = await api.company.get();
    const billingData = await api.billing.getInfo();
    u = userService.addBackwardCompUserFields(u, c, billingData);
    c = companyService.expandCurrentCompany(c);

    setCurrentUser(u);
    setCurrentCompany(c);

    return u;
  };

  const setShowCredits = (showCredits) => {
    setCurrentUser({ ...currentUser, showCredits });
  };

  return (
    <UserContext.Provider
      value={{
        currentUser, currentCompany, fetchAuthData, setShowCredits,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export default { AuthProvider, useAuth };
