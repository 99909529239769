export default {
  title: 'LinkedIn Messages',
  description: 'Impactful messages to help generate new leads.',
  input: [
    {
      title: 'Message Intent',
      value: 'Pitch an online e-commerce course',
    },
    {
      title: 'Lead Position & Company',
      value: 'Marketing Lead at Candlesmith',
    },
    {
      title: 'Your Position & Company',
      value: 'Sales Associate at Shopify',
    },
    {
      title: 'Common Interests & Background',
      value: 'Met at the Shopify community event',
    },
  ],
  output: {
    _id: '1',
    templateType: 'linkedinMessages',
    liked: false,
    content:
        "Hey! I met you at the Shopify community event earlier this year. You had mentioned your interest in e-commerce - we are offering an online course on how to build a Shopify store from the ground up that is pretty interesting! The course is completely free and we'd love for you to check it out.",
  },
  fileData: {
    displayName: 'John Smith',
    yourCompany: 'Sales Associate at Shopify',
  },
};
