import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  documentControls: {
    height: 67,
    minHeight: 67,
    padding: '0 3rem',
    paddingRight: '3.95rem',
    borderBottom: '1px solid #dbdbdb',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.colors.darkGrey,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  hasErrors: {
    height: 70,
    paddingBottom: 12,
  },
  documentControlsLeft: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: 16,
    },
  },
  documentControlButton: {
    fontSize: '1.2em',
    color: '#000429',
    cursor: 'pointer',
    margin: theme.spacing(1.75),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  redDocumentControlButton: {
    '&:hover': {
      color: 'red',
    },
  },
  greenDocumentControlButton: {
    '&:hover': {
      color: 'green',
    },
  },
  documentControlDivider: {
    marginRight: '1.625rem',
    fontSize: '1.625rem',
    fontWeight: 100,
    color: theme.palette.colors.grey,
    marginBottom: '0.25rem',
    '&::after': {
      content: '"|"',
    },
  },
  selectAll: {
    cursor: 'pointer',
    fontSize: '0.95rem',
    color: '#000',
  },
  frase: {
    height: 32,
    width: 32,
    '&:hover': {
      color: 0.5,
    },
  },
  checkUniquenessWrap: {
    margin: theme.spacing(1.75),
  },
  redText: {
    color: 'red',
  },
  contentChangeInfo: {
    marginTop: '1rem',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '280px',
  },
  menuItemIcon: {
    fontSize: '1.2em',
    color: '#000429',
    marginRight: 14,
  },
  menuItemIconBulb: {
    marginRight: 17,
    marginLeft: 3,
  },
  menu: {
    color: '#000429',
    fontSize: 28,
    fontWeight: 'bold',
    marginLeft: 12,
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinAnimation: {
    animation: '$spin 2s linear infinite;',
  },
}));
