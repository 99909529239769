import React from 'react';

import { SelectField } from '../form';

const fields = [{
  label: 'Description',
  value: 'description',
}, {
  label: 'Short Description',
  value: 'short_description',
}];

const AkeneoDescriptionFieldSelect = ({
  onChange,
  value,
  className,
}) => {
  return (
    <SelectField
      classNames={{ root: className }}
      onChange={onChange}
      options={fields}
      value={value}
      placeholder="Select field to update"
    />
  );
};

export default AkeneoDescriptionFieldSelect;
