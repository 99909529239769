import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const REDIRECT_LINK = '/create?type=blogKickstarter';

const BoosteAltTooltipContent = ({ templateName }) => (
  <p style={{ margin: 'auto' }}>
    We&#x2019;ve replaced the
    {' '}
    {templateName}
    {' '}
    with our new and improved Blog Kickstarter.
    Create high-quality blog posts through a step-by-step wizard or with a single click.
    {' '}
    <Link
      to={REDIRECT_LINK}
      onClick={(e) => e.stopPropagation()}
    >
      Click here to try it out today!
    </Link>
  </p>
);

BoosteAltTooltipContent.propTypes = {
  templateName: PropTypes.string.isRequired,
};

export default BoosteAltTooltipContent;
