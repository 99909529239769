import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import loading from '@root/assets/loading.svg';

import useStyles from './LoadingGen.styles';

const LoadingGen = ({ text, embedded = false }) => {
  const classes = useStyles();
  const [dotNum, setDotNum] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setDotNum((s) => (s + 1) % 4);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={clsx(classes.root, { [classes.embedded]: embedded })}>
      <img src={loading} alt="Loading" />
      {!text ? (
        <div className={classes.text}>
          The magic
          {' '}
          <span
            alt="emoji"
            role="img"
            aria-label="star emoji"
          >
            ✨
          </span>
          {' '}
          can take up to 15 seconds
          {' '}
          {Array(dotNum).fill('.').join('')}
        </div>
      ) : (
        <div className={classes.text}>
          {text}
          {' '}
          {Array(dotNum).fill('.').join('')}
        </div>
      )}
    </div>
  );
};

export default LoadingGen;
