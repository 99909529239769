import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import companyResource from '@root/resources/company';
import aiTemplateResource from '@root/resources/aiTemplate';
import uiService from '@root/services/uiNotification.service';

import useStyles from './CompaniesAccessListTab.styles';

const TemplateView = () => {
  const classes = useStyles();
  const params = useParams();
  const { data: template } = aiTemplateResource.useAiTemplate(params.id);

  const companyFilters = {
    templateType: template ? template.templateType : '',
  };
  const {
    data: companies,
    refetch: reloadCompanies,
  } = companyResource.useCompanies(companyFilters);
  const { mutateAsync: assign } = companyResource.assignTemplate();
  const { mutateAsync: unassign } = companyResource.unassignTemplate();
  const [userIdOrEmail, setUserIdOrEmail] = React.useState('');

  const onUserIdOrEmailChange = (e) => {
    setUserIdOrEmail(e.target.value);
  };

  const assignTemplate = async () => {
    const { isBadRequest } = await assign({
      templateType: template.templateType,
      userIdOrEmail,
    });
    if (isBadRequest) {
      return;
    }
    setUserIdOrEmail('');

    uiService.showSuccessMessage('Template assigned');
  };

  const unassignTemplate = (companyId) => async () => {
    const { isBadRequest } = await unassign({
      templateType: template.templateType,
      companyId,
    });
    if (isBadRequest) {
      return;
    }

    uiService.showSuccessMessage('Template unassigned');
  };

  React.useEffect(() => {
    if (!template) {
      return;
    }
    reloadCompanies();
  }, [template]);

  return (
    <div>
      <Grid container>
        <Grid
          sm={6}
          xs={12}
          item
        >
          <div className={classes.topActions}>
            <TextField
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              name="userIdOrEmail"
              label="User id or email"
              value={userIdOrEmail}
              onChange={onUserIdOrEmailChange}
              helperText="Type userId or email here and click assign to company"
              variant="outlined"
            />
            <div>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={assignTemplate}
              >
                Assign to company
              </Button>
            </div>
          </div>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {`Companies with access to ${template && template.templateType} template`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(companies || []).map((item) => {
                  return (
                    <TableRow key={item._id}>
                      <TableCell className={classes.item}>
                        <div>
                          {item.name}
                        </div>
                        <div>
                          <IconButton
                            size="small"
                            variant="contained"
                            onClick={unassignTemplate(item._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {companies && companies.length === 0 && (
                  <TableRow>
                    <TableCell>
                      No companies assigned
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default TemplateView;
