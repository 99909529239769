import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const UpdatePlanTooltip = ({ feature, children, disable = false }) => (disable ? children : (
  <Tooltip
    interactive
    PopperProps={{
      style: {
        zIndex: 9999,
      },
    }}
    title={(
      <p style={{
        height: '100%',
        fontSize: '14px',
        fontWeight: 400,
        margin: '8px',
        lineHeight: '16px',
      }}
      >
        {`${feature || 'This feature'}`}
        {' '}
        is not supported on your current subscription.
        {' '}
        <Link to="/profile/plans" style={{ textDecoration: 'underline', color: '#fff' }}>Upgrade your plan</Link>
        {' '}
        to use this feature.
      </p>
        )}
  >
    {children}
  </Tooltip>
));

export default UpdatePlanTooltip;
