import React from 'react';
import { Switch, Route, useHistory, Link } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import newMaterialTheme from '@root/newMaterial.theme';
import { featureEnabled, featureToggles } from '@root/utils/features';
import AuthContext from '@root/resources/auth/auth.context';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import MainLayout from '../Layout/MainLayout';

import {
  Catalog as SingleCatalog,
  CatalogList,
  UploadProductWizard,
  CatalogItem,
} from './Pages';

const Catalog = () => {
  const history = useHistory();
  const { currentUser } = AuthContext.useAuth();
  const hasAccess = currentUser.onFreePlan
  || currentUser.onFeatureGatedProPlan
  || currentUser.onSelfServeEnterprisePlan
  || currentUser.onEnterprisePlan;

  if (!featureEnabled(featureToggles.catalogPCM)) {
    history.push('/');
    return null;
  }

  const handleConfirm = () => {
    history.push('/templates');
  };

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <Switch>
        <Route path="/catalog/:id/upload" component={UploadProductWizard} />
        <MainLayout>
          <Route path="/catalog/:id/product/:productId" component={CatalogItem} />
          <Route path="/catalog/:id" component={SingleCatalog} />
          <Route path="/catalog" component={CatalogList} exact />
        </MainLayout>
      </Switch>
      {!hasAccess && (
        <ConfirmationModal
          title="Product Catalog"
          text={(
            <div>
              The Product Catalog is a powerful feature that allows you to manage products and create content at scale.
              {' '}
              <br />
              <br />
              {' '}
              With the Product Catalog you can save time and streamline your workflow.
              <br />
              <br />
              <Link to="/profile/plans">Click here</Link>
              {' '}
              to upgrade and access the Product Catalog today!
            </div>
          )}
          onConfirm={handleConfirm}
          noCancel
          noClose
          maxWidth="lg"
          confirmButtonText="Go back to your files page"
        />
      )}
    </ThemeProvider>
  );
};

export default Catalog;
