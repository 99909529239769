import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useHistory } from 'react-router-dom';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';

import useSectionStyles from '../Sections.styles';

import useStyles from './PlanSection.styles';

type Props = {
  isRolloverEnabled?: boolean;
  rollover?: number;
  isLatestRecordSelected?: boolean;
};

const Rollover: FC<Props> = ({ isRolloverEnabled, rollover, isLatestRecordSelected }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const history = useHistory();

  const handleSubscribeClick = () => {
    history.push('/profile/plans');
  };

  return (
    <article className={sectionClasses.articleContainer}>
      {!isRolloverEnabled && isLatestRecordSelected && (
        <span className={sectionClasses.emptyText}>
          Want unused words to rollover to the next month?&nbsp;
          <span className={sectionClasses.linkText} onClick={handleSubscribeClick}>Subscribe</span>
          &nbsp;to our annual plan today.
        </span>
      )}

      {!isRolloverEnabled && !isLatestRecordSelected && (
        <span className={sectionClasses.emptyText}>
          Rollover data is not applicable for this date range.
        </span>
      )}

      {isRolloverEnabled && (
        <div style={{ width: '100%' }}>
          <Typography classes={{ root: sectionClasses.articleTitle }}>
            Rollover

            {/* @ts-expect-error SimpleTooltip is not .ts yet */}
            <SimpleTooltip
              content="Annual subscribers can rollover their unused words to the next month for up to 3 months. Make the most of your plan!"
              wrapped
            >
              <IconInfoCircle size={16} />
            </SimpleTooltip>
          </Typography>

          <div className={classes.valueWithButton}>
            {/* @ts-expect-error TruncatedText is not .ts yet */}
            <TruncatedText classes={{ root: sectionClasses.articleValue }}>
              {rollover?.toLocaleString()}
            </TruncatedText>
          </div>
        </div>
      )}
    </article>
  );
};

export default Rollover;
