import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';

import TemplatesContext from '@root/resources/templates/templates.context';

const useStyles = makeStyles(() => ({
  templateSelectMenu: {
    height: 250,
  },
}));

const NewRuleDialog = ({ open, onNewRule, closeDialog }) => {
  const classes = useStyles();

  const { templates } = TemplatesContext.useTemplates();
  const personalizedTemplates = React.useMemo(
    () => Object.values(templates).filter((t) => t.personalization),
    [templates],
  );

  const [selectedTemplate, setSelectedTemplate] = React
    .useState(personalizedTemplates[0].templateType);
  const onSelectedTemplateChange = (e) => setSelectedTemplate(e.target.value);
  const selectedTemplateData = React.useMemo(
    () => personalizedTemplates.find((t) => t.templateType === selectedTemplate), [
      selectedTemplate,
    ],
  );

  const [personalizationData, setPersonalizationData] = React.useState({});
  const onPersonalizationDataChange = (key) => (e) => setPersonalizationData({
    ...personalizationData,
    [key]: e.target.value,
  });

  const dropDialogState = () => {
    setPersonalizationData({});
    setSelectedTemplate(personalizedTemplates[0].templateType);
  };
  React.useEffect(() => {
    if (!open) {
      dropDialogState();
    }
  }, [open]);

  const onSubmit = () => {
    if (selectedTemplateData.personalization.fields.every((f) => f.target)) {
      const requestData = {};
      selectedTemplateData.personalization.fields.forEach((f) => {
        f.target.forEach((ft) => {
          requestData[ft] = requestData[ft] || {};
          requestData[ft][f.name] = personalizationData[f.name];
        });
      });

      return onNewRule({
        templateType: selectedTemplate,
        data: requestData,
      });
    }

    return onNewRule({
      templateType: selectedTemplate,
      data: personalizationData,
    });
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        New personalization rule
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Template"
          fullWidth
          select
          variant="outlined"
          size="small"
          margin="normal"
          value={selectedTemplate}
          onChange={onSelectedTemplateChange}
          SelectProps={{
            MenuProps: {
              className: classes.templateSelectMenu,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
        >
          {personalizedTemplates.map((t) => (
            <MenuItem key={t.templateType} value={t.templateType}>{t.title}</MenuItem>
          ))}
        </TextField>
        {selectedTemplateData.personalization.fields.map((f) => (
          <div>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              fullWidth
              label={f.label}
              key={f.name}
              // required
              value={personalizationData[f.name] || ''}
              onChange={onPersonalizationDataChange(f.name)}
              select={!!f.values}
              // eslint-disable-next-line
              children={
                f.values
                  ? f.values.map((v) => (
                    <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                  ))
                  : null
}
            />
          </div>
        ))}
        <DialogActions>
          <Button onClick={closeDialog} variant="text">Cancel</Button>
          <Button onClick={onSubmit}>Create</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default NewRuleDialog;
