import React from 'react';

import Loading from '@root/components/Loading/Loading';

export default () => {
  const currentUrl = window.location.href;

  const params = new URL(currentUrl).searchParams;
  const code = params.get('code');
  window.opener.window.postMessage({
    copysmithOauthCode: code,
  }, '*');

  setTimeout(() => window.close(), 5000);

  return <Loading />;
};
