import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  startIcon: {
    width: 16,
    height: 16,
    strokeWidth: 3,
  },

  createNewButton: {
    display: 'flex',
    marginLeft: '1rem',
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.neutrals600,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 12px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },

}));

export default useStyles;
