import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, Fade, Modal, Button } from '@material-ui/core';
import React, { useState, createRef } from 'react';
import { Input, Row, Col, Table } from 'reactstrap';
import Dropzone from 'react-dropzone';
import csv from 'csv';
import clsx from 'clsx';

import folderResource from '@root/resources/folder';
import fileResource from '@root/resources/file';
import migrationUtil from '@root/api/migrationUtil';
import AddSpacing from '@root/components/AddSpacing';
import LoadingGen from '@root/components/Loading/LoadingGen';
import { downloadAsFile, makeFileName } from '@root/utils/Export';
import TemplatesContext from '@root/resources/templates/templates.context';
import { CheckboxField } from '@root/components/form';

import useStyles from './BulkGenerateModal.styles';

const BulkGenerateModal = ({
  modalOpen,
  closeModal,
}) => {
  const classes = useStyles();
  const { templates, getTemplate } = TemplatesContext.useTemplates();
  const [selectedTemplateType, setSelectedTemplateType] = useState(null);
  const [slideOrder, setSlideOrder] = useState(1);
  const [fileName, setFileName] = useState('');
  const [fileData, setFileData] = useState([]);
  const slideDescription = ['Select file type', 'Upload CSV'];
  const [folderName, setFolderName] = useState('');
  const [csvError, setCsvError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dropZoneRef = createRef();

  const { mutateAsync: createFolder } = folderResource.useCreateFolder();
  const { mutateAsync: createFile } = fileResource.useCreateFile();

  const clearFile = () => {
    setFileName('');
    setFileData([]);
  };

  const createSampleCSV = () => {
    const { fields } = templates[selectedTemplateType];
    const fieldMap = [{
      name: 'fileName',
      label: 'File Name',
      placeholder: `e.g. Draft_${selectedTemplateType}`,
      required: true,
    }].concat(fields);
    const row1 = fieldMap.map((field) => `"${field.name}"`).join(',');
    const row2 = fieldMap.map((field) => `"${field.placeholder}"`).join(',');
    const csvContent = `${row1}\r\n${row2}`;
    downloadAsFile(csvContent, makeFileName(selectedTemplateType, 'Sample'));
  };

  const parseData = (data) => {
    const { fields } = templates[selectedTemplateType];
    const fieldMap = [{
      name: 'fileName',
      label: 'File Name',
      placeholder: `e.g. Draft_${selectedTemplateType}`,
      required: true,
    }].concat(fields);
    let isSuccess = true;
    const generations = [];

    for (let rowNum = 0; rowNum < data.length; rowNum += 1) {
      if (!isSuccess) break;
      const genObj = {};
      const row = data[rowNum];
      for (let valNum = 0; valNum < row.length; valNum += 1) {
        const value = row[valNum].trim();
        if (!value.length) {
          // for empty fields

          if (fieldMap[valNum].required) {
            // if it's required and not there, display error
            isSuccess = false;
            break;
          } else {
            // if not required just use placeholder
            genObj[fieldMap[valNum].name] = fieldMap[valNum].name
              .toLowerCase()
              .includes('keyword')
              ? []
              : '';
          }
        } else {
          genObj[fieldMap[valNum].name] = fieldMap[valNum].name
            .toLowerCase()
            .includes('keyword')
            ? value
              .split(',')
              .map((item) => item.trim())
              .splice(0, 10)
            : value;
        }
      }
      genObj.MoreLikeThisDict = null;
      generations.push(genObj);
    }
    setFileData(generations);
    return generations.length && isSuccess;
  };

  const onDrop = (acceptedFile) => {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, { relax_column_count: true }, (err, data) => {
        let isValid = true;
        if (err) {
          setCsvError(true);
          return;
        }
        let fieldNum;

        // validate fields
        for (fieldNum = 0; fieldNum < data[0].length; fieldNum += 1) {
          if ((fieldNum === 0 && data[0][fieldNum] !== 'fileName') && data[0][fieldNum] !== templates[selectedTemplateType].fields[fieldNum].name) {
            isValid = false;
            break;
          }
        }

        if (isValid && parseData(data)) {
          setFileName(acceptedFile[0].name);
          setCsvError(false);
        } else {
          setCsvError(true);
          clearFile();
        }
      });
    };
    if (acceptedFile.length) reader.readAsBinaryString(acceptedFile[0]);
  };

  const onCreateFile = async (file, folderId, templateType) => {
    const title = file.fileName ? file.fileName : 'Untitled File';
    delete file.fileName;
    const { slug } = getTemplate(templateType);
    const data = migrationUtil.bodyToNewApiFormat(file);

    await createFile({
      title,
      folderId,
      slug,
      templateType,
      data,
      negativeKeywordArray: file.negativeKeywordList || [],
    });
  };

  const clearModalData = () => {
    setSelectedTemplateType(null);
    setFolderName('');
    setCsvError(false);
    clearFile();
  };

  const handleClose = () => {
    closeModal();
    clearModalData();
    setSlideOrder(1);
  };

  const createFiles = async () => {
    setLoading(true);
    const { _id: folderId } = await createFolder({ title: folderName });

    await Promise.all(fileData.map((f) => onCreateFile(f, folderId, selectedTemplateType)));
    handleClose();
    setLoading(false);
  };

  const quickAccessTemplates = templates ? Object.values(templates).filter(
    (template) => template.group === 'Quick Access' || template.starred,
  ) : [];
  const adTemplates = templates ? Object.values(templates).filter(
    (template) => template.group === 'Ad' && !template.starred,
  ) : [];
  const brainstormingTemplates = templates ? Object.values(templates).filter(
    (template) => template.group === 'Brainstorming' && !template.starred,
  ) : [];
  const brandingTemplates = templates ? Object.values(templates).filter(
    (template) => template.group === 'Branding' && !template.starred,
  ) : [];
  const otherTemplates = templates ? Object.values(templates).filter(
    (template) => template.group === 'Other'
      && !template.starred
      && template.templateType !== 'blogPost',
  ) : [];

  const displayGenerators = ({ _templates, title, subtitle }) => (
    <div className={classes.generatorsWrapper}>
      <div className={classes.generatorsTitleBox}>
        <span className={classes.fileModalTitle}>{title}</span>
        <div className={classes.fileModalSubtitle}>{subtitle}</div>
      </div>
      <div className={classes.generatorsContentBox}>
        {_templates.map((template) => (
          <div
            key={template.templateType}
            className={selectedTemplateType === template.templateType
              ? clsx(classes.generatorsItem, classes.generatorsItemSelected)
              : classes.generatorsItem}
            onClick={() => setSelectedTemplateType(template.templateType)}
          >
            <CheckboxField
              label={template.title}
              onChange={() => setSelectedTemplateType(template.templateType)}
              value={selectedTemplateType === template.templateType}
            />
            <div className={classes.templatesDescription}>
              {template.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSampleTable = (style) => {
    const { fields } = templates[selectedTemplateType];
    if (!fields) return null;
    const fieldMap = [{
      name: 'fileName',
      label: 'File Name',
      placeholder: `e.g. Draft_${selectedTemplateType}`,
      required: true,
    }].concat(fields);

    return (
      <div className={classes.tableWrap}>
        <Table className={style}>
          <thead>
            <tr>
              {fieldMap.map((field) => (
                <td key={field.name}>
                  <div className={classes.tableFieldWrap}>
                    {field.name.toLowerCase().includes('eyword') && (
                      <span className={classes.tableKeywordMark}>
                        {' '}
                        *
                      </span>
                    )}
                    <span>{field.name}</span>
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={classes.halfOpacity}>
              {fieldMap.map((field) => {
                const content = field.placeholder || field.helper;
                return (
                  <td key={content}>
                    <div className={classes.tablePlaceholder}>
                      {content}
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div className={clsx(classes.fileModalHeader, classes.topModalWrap)}>
              Import CSV to File
              <span className={classes.modalText}>
                Step
                {' '}
                {slideOrder}
                {' '}
                of 2 (
                {slideDescription[slideOrder - 1]}
                )
              </span>
            </div>
          </div>
          <div className={classes.modalCloseButton} onClick={handleClose}>
            ×
          </div>
          <div className={classes.offersWrap}>
            {loading && (
              <LoadingGen text="Just a sec, we're generating your files!" />
            )}
            {slideOrder === 1 && !loading && (
              <>
                <div className={clsx(classes.fileModalHeader, classes.colorBlack)}>
                  What can we write for you today?
                  {' '}
                </div>
                {displayGenerators({
                  _templates: quickAccessTemplates,
                  title: 'Popular',
                  subtitle: 'Your favorites, at your fingertips.',
                })}
                {displayGenerators({
                  _templates: adTemplates,
                  title: 'Ads',
                  subtitle: 'Get your brand the attention it deserves.',
                })}
                {displayGenerators({
                  _templates: brainstormingTemplates,
                  title: 'Brainstorming',
                  subtitle: "Have writer's block? Not anymore.",
                })}
                {displayGenerators({
                  _templates: brandingTemplates,
                  title: 'Branding',
                  subtitle: 'Show off the best version of your business.',
                })}
                {displayGenerators({
                  _templates: otherTemplates,
                  title: 'Other',
                  subtitle: 'Assorted content types.',
                })}
              </>
            )}
            {slideOrder === 2 && !loading && (
              <Row className={classes.rowWrap}>
                <Col xs="5">
                  <Dropzone
                    accept={['.csv', 'text/csv']}
                    onDragEnter={() => {
                      dropZoneRef.current.style.opacity = 0.6;
                    }}
                    onDragLeave={() => {
                      dropZoneRef.current.style.opacity = 1;
                    }}
                    onDropRejected={() => setCsvError(true)}
                    onDrop={(acceptedFiles) => {
                      dropZoneRef.current.style.opacity = 1;
                      onDrop(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => {
                      const {
                        onBlur,
                        onClick,
                        onDragEnter,
                        onDragLeave,
                        onDragOver,
                        onDrop: _onDrop,
                        onFocus,
                        onKeyDown,
                        ref,
                        tabIndex,
                      } = getRootProps();
                      const {
                        accept,
                        autoComplete,
                        multiple,
                        onChange,
                        onClick: _onClick,
                        ref: _ref,
                        style,
                        tabIndex: _tabIndex,
                        type,
                      } = getInputProps();

                      return (
                        <section
                          ref={dropZoneRef}
                          className={`${classes.dropContainer} ${classes.centered}`}
                        >
                          <div
                            onBlur={onBlur}
                            onClick={onClick}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDragOver={onDragOver}
                            onDrop={_onDrop}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            ref={ref}
                            tabIndex={tabIndex}
                          >
                            <input
                              accept={accept}
                              autoComplete={autoComplete}
                              multiple={multiple}
                              onChange={onChange}
                              onClick={_onClick}
                              ref={_ref}
                              style={style}
                              tabIndex={_tabIndex}
                              type={type}
                            />
                            <Row className={clsx(classes.centered, classes.aligned)}>
                              <FontAwesomeIcon
                                icon="upload"
                                size="5x"
                                style={{
                                  color: 'lightgrey',
                                  marginBottom: '2rem',
                                }}
                              />

                              <p className={clsx(classes.rowText, classes.bold)}>
                                {fileName.length
                                  ? `${
                                    fileName.length > 25
                                      ? `${fileName.substring(0, 25)}...`
                                      : fileName
                                  } successfully uploaded!`
                                  : 'Drag file here to upload'}
                              </p>

                              <br />
                              <p className={classes.rowText}>
                                or
                              </p>
                              <Button size="large">
                                {fileName.length ? 'Reupload' : 'Browse'}
                              </Button>
                            </Row>
                          </div>
                        </section>
                      );
                    }}
                  </Dropzone>
                </Col>

                <Col xs="7" className={classes.columnSpacing}>
                  <AddSpacing spacing={10}>
                    <div>
                      <span className={classes.columnTitle}>
                        <b>Upload File</b>
                      </span>
                    </div>
                    <div>
                      <span className={classes.fileFormatText}>
                        Refer to the table below or
                        <span
                          onClick={createSampleCSV}
                          className={classes.downloadButton}
                        >
                          {' '}
                          download CSV
                          {' '}
                        </span>
                        for expected file format.
                        <br />
                        An asterisk (
                        <span className={classes.csvErrorColor}>*</span>
                        ) indicates
                        fields where multiple keywords should be separated by
                        commas.
                      </span>

                      {csvError && (
                        <>
                          <br />
                          <span className={clsx(classes.genericTableText, classes.csvErrorColor)}>
                            Could not process CSV file, please refer to sample
                          </span>
                        </>
                      )}
                    </div>
                    <div>{renderSampleTable(classes.tableStyle)}</div>
                    <div>
                      <span className={classes.columnTitle}>
                        <b>Project Name</b>
                      </span>
                      <br />
                      <span className={clsx(classes.genericTableText, classes.fileFormatText)}>
                        File(s) created will automatically be grouped into a
                        project
                      </span>

                      <Input
                        className={classes.generatorInput}
                        onChange={(e) => setFolderName(e.target.value)}
                        placeholder="e.g. My Blog Ideas"
                      />
                    </div>
                  </AddSpacing>
                </Col>
              </Row>
            )}
            <div className={classes.fileModalFooter}>
              <Row>
                <Col>
                  <b>Template Selected:</b>
                  {' '}
                  {selectedTemplateType ? templates[selectedTemplateType].title : 'None'}
                </Col>
                <Col className={classes.colWrap}>
                  {slideOrder === 1 && (
                    <Button
                      className={classes.button}
                      disabled={!selectedTemplateType}
                      onClick={() => {
                        setSlideOrder(slideOrder + 1);
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {slideOrder === 2 && (
                    <>
                      <Button
                        disabled={!selectedTemplateType}
                        onClick={() => {
                          setSlideOrder(slideOrder - 1);
                          clearModalData();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        className={classes.button}
                        disabled={!folderName.length || !fileName.length}
                        onClick={createFiles}
                      >
                        Create
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BulkGenerateModal;
