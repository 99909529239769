import React, { createContext, useState, useEffect, useMemo } from 'react';

import billingResource from '@root/resources/billing';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

const SubscriptionsContext = createContext();

const SubscriptionsProvider = ({
  children,
}) => {
  const { data: userBillingInfo = {} } = billingResource.useBillingInfo();
  const { data: pricingData } = billingResource.usePricing();

  const [renewModalOpen, setRenewModalOpen] = useState(false);
  const [renewSuspendedAccountModalOpen, setRenewSuspendedAccountModalOpen] = useState(false);

  const isMonthly = userBillingInfo.billing === 'monthly';
  const [period, setPeriod] = useState(isMonthly ? 'monthly' : 'yearly');
  const [tiers, setTiers] = useState({});

  const march2023StarterOrPro = [
    ...PLANS_IDS.march2023StarterMonthly,
    ...PLANS_IDS.march2023StarterAnnual,
    ...PLANS_IDS.march2023Pros,
  ];

  const setTier = React.useCallback(({
    rank,
    tier,
  }) => {
    setTiers({
      ...tiers,
      [rank]: tier,
    });
  }, []);

  const pricing = useMemo(() => {
    if (!pricingData) {
      return null;
    }

    const { plans } = pricingData;
    const yearlyPlans = plans
      .filter((p) => p.period === 'yearly')
      .sort((a, b) => a.rank - b.rank)
      .map((p) => {
        const monthlyPlan = plans.find((plan) => plan.rank === p.rank && plan.period === 'monthly');
        return {
          ...p,
          oldPrice: monthlyPlan ? monthlyPlan?.amount / 100 : null,
        };
      });
    const monthlyPlans = plans
      .filter((p) => p.period === 'monthly')
      .sort((a, b) => a.rank - b.rank);

    const data = {
      yearly: yearlyPlans,
      monthly: monthlyPlans,
    };

    if (!yearlyPlans.some((p) => p.rank === 500)) {
      yearlyPlans.push({
        _id: 'enterprise',
        internalName: 'enterprise',
        name: 'Custom Enterprise',
        description: 'For businesses that need unlimited content creation at scale.',
        rank: 500,
        data: { limits: {} },
      });
    }
    if (!monthlyPlans.some((p) => p.rank === 500)) {
      monthlyPlans.push({
        _id: 'enterprise',
        internalName: 'enterprise',
        name: 'Custom Enterprise',
        description: 'For businesses that need unlimited content creation at scale.',
        rank: 500,
        data: { limits: {} },
      });
    }

    const monthlyPlansWithTier = monthlyPlans.filter((p) => p.tier > 0);
    if (monthlyPlansWithTier.length > 0) {
      const ranks = [...new Set(monthlyPlansWithTier.map((p) => p.rank))];
      data.monthly = data.monthly
        .filter((p) => !(ranks.includes(p.rank) && p.tier !== (tiers[p.rank] || 0)))
        .map((p) => (ranks.includes(p.rank) ? {
          ...p,
          tiersSupported: true,
          maxTier: Math.max(...monthlyPlansWithTier.filter((plan) => plan.rank === p.rank).map((plan) => plan.tier)),
        } : p));
    }
    const yearlyPlansWithTier = yearlyPlans.filter((p) => p.tier > 0);
    if (yearlyPlansWithTier.length > 0) {
      const ranks = [...new Set(yearlyPlansWithTier.map((p) => p.rank))];
      data.yearly = data.yearly
        .filter((p) => !(ranks.includes(p.rank) && p.tier !== (tiers[p.rank] || 0)))
        .map((p) => (ranks.includes(p.rank) ? {
          ...p,
          tiersSupported: true,
          maxTier: Math.max(...yearlyPlansWithTier.filter((plan) => plan.rank === p.rank).map((plan) => plan.tier)),
        } : p));
    }

    return data;
  }, [pricingData, tiers]);

  useEffect(() => {
    const plansWithoutEnterprise = (pricingData?.plans || []).filter((p) => p.rank < 500);

    if (plansWithoutEnterprise.every((p) => p.period === 'monthly')) {
      setPeriod('monthly');
      return;
    }

    if (plansWithoutEnterprise.every((p) => p.period === 'yearly')) {
      setPeriod('yearly');
      return;
    }

    if (userBillingInfo.billing) {
      setPeriod(isMonthly && march2023StarterOrPro.includes(userBillingInfo?.priceId) ? 'monthly' : 'yearly');
    }
  }, [userBillingInfo, pricingData]);

  return (
    <SubscriptionsContext.Provider
      value={{
        isLoading: !pricing,
        period,
        setPeriod,
        allPricing: pricingData?.plans,
        pricingData: pricing?.[period],
        currentPlan: pricingData?.currentPlan,
        renewModalOpen,
        setRenewModalOpen,
        renewSuspendedAccountModalOpen,
        setRenewSuspendedAccountModalOpen,
        setTier,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

function useSubscriptions() {
  const context = React.useContext(SubscriptionsContext);
  if (context === undefined) {
    throw new Error('useExternalSource must be used within a ExternalSourcesProvider');
  }
  return context;
}

export default { SubscriptionsProvider, useSubscriptions };
