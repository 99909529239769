import React from 'react';

const UncheckedIcon = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x=".5"
      y=".5"
      width="15"
      height="15"
      rx="3.5"
      fill="#fff"
      stroke="currentColor"
      opacity=".5"
    />
  </svg>
);

export default UncheckedIcon;
