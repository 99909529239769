import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  modal: {
    position: 'relative',
    fontFamily: '"Inter", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  header: {
    backgroundColor: theme.palette.colors.lightGrey,
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    padding: '1rem 2.5rem',
    width: '100%',
    height: '88px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '95%',
    minWidth: '44rem',
    height: '90%',
    minHeight: '30rem',
    alignContent: 'center',
    outline: 'none',
  },
  small: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '30rem',
    alignContent: 'center',
    outline: 'none',
    position: 'relative',
  },
  smallContainer: {
    padding: '2rem 3rem 0 3rem',
    height: 'calc(100% - 88px)',
    overflow: 'scroll',
  },
  title: {
    marginBottom: '0.825rem',
  },
  renewalDate: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  buttons: {
    margin: '2rem 0',
  },
}));
