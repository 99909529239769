import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@root/components/buttons/Button';
import TemplatesContext from '@root/resources/templates/templates.context';
import useCreateFile from '@root/resources/file/useCreateFile';

import useStyles from './Actions.styles';

const Actions = ({ templateType, title, folderId, onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const { getTemplate } = TemplatesContext.useTemplates();
  const { mutateAsync: createFile } = useCreateFile();

  const handleCreate = async () => {
    const generator = getTemplate(templateType);
    const { slug } = generator;

    const newFile = await createFile({ title, folderId, templateType, slug });

    if (newFile.isBadRequest) {
      return;
    }

    const { _id, title: newFileTitle } = newFile;
    const url = `/${slug}/${_id}?title=${newFileTitle}`;
    history.push(url);
  };

  return (
    <div className={classes.actions}>
      <Button
        size="small"
        variant="text"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        size="small"
        onClick={handleCreate}
        disabled={!title}
      >
        Create
      </Button>
    </div>
  );
};

export default Actions;
