import React, { useState } from 'react';
import { OutlinedInput } from '@material-ui/core';

import useStyles from './TextInput.styles';

const shortInputError = 'Your input seems to short. It should be no less than 3 characters.';
const duplicateInputError = 'Input already exists.';
const noSpecialCharsRegex = /^[a-zA-Z0-9-_ ]*$/;

const TextInput = ({ inputs, onSave, onClose, onError, initialValue = '' }) => {
  const classes = useStyles();
  const [input, setInput] = useState(initialValue);

  const handleNewInputChange = (e) => {
    const isValid = noSpecialCharsRegex.test(e.target.value);
    if (isValid) {
      onError(null);
      setInput(e.target.value);
    }
  };

  const validateAndSave = () => {
    if (input.length < 3) {
      onError(shortInputError);
    } else if (inputs.includes(input) && input !== initialValue) {
      onError(duplicateInputError);
    } else {
      onSave(input);
      onClose();
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      validateAndSave();
    }
  };

  const handleNewInputBlur = () => {
    if (input) {
      validateAndSave();
    } else {
      onClose();
    }
  };

  return (
    <OutlinedInput
      value={input}
      onChange={handleNewInputChange}
      onBlur={handleNewInputBlur}
      onKeyDown={handleEnterPress}
      autoFocus
      style={{ width: `${1 + (input.length * 0.85)}ch` }}
      className={classes.input}
      inputProps={{ maxLength: 20 }}
    />
  );
};

export default TextInput;
