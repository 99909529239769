import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  community: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

export default useStyles;
