export const ADDON_TYPES = {
  // integrations & apps
  CHROME_EXTENSION: 'chromeExtension',
  GOOGLE_ADS: 'googleAds',
  GOOGLE_DOCS_ADDON: 'googleDocsAddOn',
  GOOGLE_DOCS: 'googleDocs',
  MS_WORD: 'MSWordAddIn',
  SHOPIFY: 'shopify',
  FRASE: 'frase',
  WORDPRESS: 'wordpressEcommercePlugin',
  ZAPIER: 'zapier',
  HOOTSUITE: 'hootsuite',
  REST_CLIENT: 'restClient',
  BIG_COMMERCE: 'bigCommerce',
  AKENEO: 'akeneo',
};

export const CATEGORIES = {
  feature: { label: 'Features', value: 'features' },
  integration: { label: 'Integrations', value: 'integrations' },
  app: { label: 'Apps', value: 'apps' },
  zapier: { label: 'Zapier', value: 'zapier' },
  api: { label: 'Copysmith API', value: 'api' },
};

export const ADDON_TABS = [
  CATEGORIES.feature.value, CATEGORIES.integration.value, CATEGORIES.app.value,
];

export const FEATURES = {
  additionalMembers: { label: 'Additional Team Seats', type: 'additionalMembers', cost: 9, addonName: 'additionalMembers', unit: 'seat' },
  additionalCredits: { label: 'Additional Words', type: 'additionalCredits', cost: 10, addonName: 'additionalCredits', unit: 'unit' },
  unlimitedCredits: { label: 'Unlimited Words', type: 'unlimitedCredits', cost: 149, addonName: 'unlimitedCredits', unit: null },
  aiImages: { label: 'Art Studio', type: 'aiImages', cost: 15, addonName: 'aiImages', unit: 'unit' },
};

export const ERROR_CODE_MESSAGES = {
  notAcceptedScopes: 'In order to connect Google Docs, please grant access to Copysmith in your Google account.',
};
