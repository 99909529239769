import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  sidebarRoot: {
    background: theme.palette.colors.neutrals600,
    zIndex: 99,
    padding: '16px 8px',
    width: 56,
    height: '100%',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&.disabled': {
      pointerEvents: 'none',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  sidebarLogo: {
    cursor: 'pointer',
    height: 24,
    marginBottom: 16,
  },
  menuItemContainer: ({ newLeftSidebar }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,

    height: newLeftSidebar ? 'unset' : 40,
    width: 40,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 250ms',
    cursor: 'pointer',

    color: theme.palette.colors.neutrals400,

    '& svg': {
      flexShrink: 0,
      height: 32,
      width: 32,
      strokeWidth: '1.5px',
    },

    '&:hover': {
      color: theme.palette.colors.white,
      textDecoration: 'none',
    },
    '&.active': {
      backgroundColor: newLeftSidebar ? 'unset' : theme.palette.colors.neutrals400,
      color: theme.palette.colors.white,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'auto',
      pointerEvents: 'none',
    },
  }),
  itemLabel: {
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '14px',
  },
}));
