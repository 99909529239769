import { useMutation } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const publishBulk = async ({
  productIds,
}) => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.publishBulkJob({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
    productIds,
  });

  return products;
};

export default function usePublishBulk() {
  return useMutation(
    ({ productIds }) => publishBulk({ productIds }),
  );
}
