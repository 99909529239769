import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  list: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridAutoRows: '240px',
    width: '1000px',
    maxWidth: '95%',
    margin: '0 auto 48px',
    rowGap: '1.5rem',
    columnGap: '2rem',
    ['@media (max-width: 1000px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    ['@media (max-width: 630px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      maxWidth: '90%',
    },
  },
  searchContainer: {
    display: 'flex',
    margin: '2rem auto',
    width: '1000px',
  },
}));

export default useStyles;
