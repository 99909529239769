import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 24px',
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: theme.palette.colors.mediumDarkGrey,
  },
  dividerText: {
    fontSize: 14,
    color: theme.palette.colors.mediumDarkGrey,
    margin: '0 16px',
  },

  linkBlock: {
    color: theme.palette.colors.darkGrey,
    fontSize: 14,
    marginTop: 8,
    alignSelf: 'center',
  },
  link: {
    fontWeight: 500,
  },
}));
