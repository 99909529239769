import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import clsx from 'clsx';
import copy from 'clipboard-copy';
import { DialogContentText, Popover } from '@material-ui/core';
import { ThumbDownOutlined, ThumbUpAltOutlined } from '@material-ui/icons';

import useTextSelect from '@root/utils/hooks/useTextSelect';
import uiNotificationService from '@root/services/uiNotification.service';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import NewFileModal from '@root/components/DocumentView/components/PreviewFooter/components/CreateMenuButton/NewFileModal';
import Modal from '@root/components/modals/Modal';
import { getReadabilityData, getReadingTime } from '@root/utils/readability.util';
import useModalState from '@root/utils/hooks/useModalState';
import usePlagiarismCheck from '@root/utils/hooks/usePlagiarismCheck';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import quillConfig from '../quill.config';
import useContentImprovement from '../hooks/useContentImprovement';
import useContentLength from '../hooks/useContentLength';

import PublishDialog from './PublishDialog';
import useStyles from './Document.styles';
import DocumentActions from './DocumentActions';

const Delta = Quill.import('delta');

const Document = ({
  document: {
    _id: id,
    content: initialContent,
    isNew,
    fileId,
    submittedExamples = {},
    readabilityScore,
  },
  document,
  toolbarRef,
  mergeDocuments,
  editable,
  setEditableDocumentId,
  handleDocumentContentChange,
  forceCaretPosition,
  onTextSelect,
  readOnly,
  selectedText,
  isActionInProgress,
  personalizationRules,
  personalize,
  removePersonalization,
  submitToReview,
  removeDocumentExample,
  onMoreLikeThis,
  deleteDocument,
  file,
  zapierIntegration,
  googleDocsIntegration,
  allowedActions,
  onBlockActionStart,
  onBlockActionEnd,
  dropSelectedText,
  showChar,
  htmlId,
  limits,
}) => {
  const classes = useStyles();

  const { featureTourActive } = onboardingContext.useOnboarding();

  const rootRef = React.useRef();

  const [ref, setRef] = React.useState(null);

  const [isBackspacePressed, setIsBackspacePressed] = React.useState(false);
  const [content, setContent] = React.useState(initialContent);
  const [plainText, setPlainText] = React.useState('');
  const [skipChange, setSkipChange] = React.useState(false);
  const [forceSaveChange, serForceSaveChange] = React.useState(false);
  const [badExampleId, setBadExampleId] = React
    .useState(
      (Object.entries(submittedExamples)
        .find(([, value]) => value === 'bad') || [])[0],
    );

  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useModalState(false);
  const [readabilityModalOpen, openReadabilityModal, closeReadabilityModal] = useModalState(false);
  const [publishDialogOpen, openPublishDialogDialog, closePublishDialog] = useModalState(false);
  const [
    newCampaignDialogOpen, openNewCampaignDialog, closeNewCampaignDialog,
  ] = useModalState(false);

  const [plagiarismCheckTooltipData, setPlagiarismCheckTooltipData] = React.useState(null);
  const [plagiarismCheckModalData, setPlagiarismCheckModalData] = React.useState(null);
  const dropPlagiarismCheckModalData = () => {
    setPlagiarismCheckModalData(null);
  };

  const onElementFocus = () => {
    setEditableDocumentId(id);
  };

  const onChange = async (c, delta, source, editor) => {
    if (!ref) {
      return;
    }

    if (skipChange) {
      setSkipChange(false);
      return;
    }

    setContent(c);

    handleDocumentContentChange(id, {
      content: c,
      delta,
      editor,
      forceSaveChange,
    });

    if (forceSaveChange) {
      serForceSaveChange(false);
    }
  };

  const onKeyDown = async (e) => {
    if (e.keyCode !== 8) { // backspace
      setIsBackspacePressed(false);
      return;
    }
    const promise = new Promise((res) => {
      const { target } = e;
      let timeoutId;

      // changes
      const listener = target.addEventListener('input', () => {
        target.removeEventListener('input', listener);
        clearTimeout(timeoutId);
        res(false);
      });

      // no changes
      timeoutId = setTimeout(() => {
        target.removeEventListener('input', listener);
        res(true);
      }, 100);
    });

    const noChanges = await promise;

    if (!noChanges) {
      setIsBackspacePressed(false);
      return;
    }
    if (!isBackspacePressed) {
      setIsBackspacePressed(true);
      return;
    }

    mergeDocuments(id);
  };

  React.useEffect(() => {
    if (content !== initialContent) {
      setSkipChange(true); // skip quill change event when update content (for undo/redo/merge)
      setTimeout(() => {
        setContent(initialContent);
      });
    }
  }, [initialContent]);

  React.useEffect(() => {
    if (editable && forceCaretPosition !== null) {
      setTimeout(() => {
        if (!ref) {
          return;
        }

        const editor = ref.getEditor();
        if (!editor) {
          return;
        }

        ref.getEditor()
          .setSelection(forceCaretPosition);
      });
    }
  }, [forceCaretPosition]);

  useTextSelect(() => {
    const editor = ref.getEditor();

    if (!editor) {
      return;
    }

    const selection = editor.getSelection();

    if (!selection || selection.length === 0) {
      return;
    }

    const selectedTextObject = {
      documentId: id,
      value: plainText.substr(selection.index, selection.length),
      start: selection.index,
      length: selection.length,
    };

    onTextSelect(selectedTextObject);
  }, rootRef, [content, ref, plainText], 'mouseup');

  React.useEffect(() => {
    if (editable && ref) {
      const editor = ref.getEditor();
      if (!editor) {
        return;
      }
      editor
        .setSelection(forceCaretPosition || 0);
    }
  }, [ref]);

  React.useEffect(() => {
    if (!ref) {
      return;
    }

    const editor = ref.getEditor();
    if (!editor) {
      return;
    }

    setPlainText(editor.getText());
  }, [content, ref]);

  const personalizationRule = React
    .useMemo(() => personalizationRules.find((pr) => pr.documentId === id), [
      personalizationRules,
    ]);

  const { checkPlagiarism } = usePlagiarismCheck(
    (selectedText?.documentId === id && selectedText.value) || plainText,
    setPlagiarismCheckModalData,
    setPlagiarismCheckTooltipData,
  );

  const {
    content: popoverContent,
    improveContent,
    drop: dropContentImprovement,
  } = useContentImprovement({
    fileId,
  });

  const { contentLength } = useContentLength({
    plainText,
    showChar,
  });

  const onAction = React.useCallback(async (action) => {
    switch (action) {
      case 'copy': {
        await copy(plainText);
        uiNotificationService.showSuccessMessage('Copied to Clipboard!');
        break;
      }
      case 'expand':
      case 'rewrite': {
        const useSelectedText = selectedText?.documentId === id;

        const result = await improveContent(
          action === 'rewrite' ? 'contentRewriter' : 'contentExpanderNew',
          useSelectedText ? selectedText.value : plainText,
        );
        if (!result) {
          break;
        }

        serForceSaveChange(true);

        const editor = ref.getEditor();

        if (useSelectedText) {
          editor.updateContents(
            new Delta()
              .retain(selectedText.start)
              .delete(selectedText.length)
              .insert(result),
          );
          dropSelectedText();
        } else {
          editor.setText(result);
          editor.updateContents(
            new Delta()
              .insert(''),
          );
        }
        break;
      }
      case 'like': {
        onBlockActionStart();
        const promises = [];
        if (badExampleId) {
          promises.push(async () => {
            await removeDocumentExample({ id: badExampleId });
            setBadExampleId(null);
          });
        }
        if (personalizationRule) {
          promises.push(async () => {
            await removePersonalization({ id: personalizationRule._id });
          });
        } else {
          promises.push(async () => {
            await personalize({ documentId: id });
          });
        }
        await Promise.all(promises.map((f) => f()));
        onBlockActionEnd();
        break;
      }
      case 'dislike': {
        onBlockActionStart();
        const promises = [];
        if (personalizationRule) {
          promises.push(async () => {
            await removePersonalization({ id: personalizationRule._id });
          });
        }
        if (!badExampleId) {
          promises.push(async () => {
            const badExample = await submitToReview({
              documentId: id,
              fileId,
              type: 'bad',
            });
            setBadExampleId(badExample._id);
          });
        } else {
          promises.push(async () => {
            await removeDocumentExample({ id: badExampleId });
            setBadExampleId(null);
          });
        }
        await Promise.all(promises.map((f) => f()));
        onBlockActionEnd();
        break;
      }
      case 'moreLikeThis': {
        await onMoreLikeThis();
        break;
      }
      case 'delete': {
        openDeleteDialog();
        break;
      }
      case 'readability': {
        openReadabilityModal();
        break;
      }
      case 'campaign': {
        openNewCampaignDialog();
        break;
      }
      case 'publish': {
        openPublishDialogDialog();
        break;
      }
      case 'plagiarismCheck':
        onBlockActionStart();
        await checkPlagiarism();
        onBlockActionEnd();
        break;
      default:
        break;
    }
  }, [
    selectedText,
    document,
    personalizationRule,
    content,
    ref,
    badExampleId,
    skipChange,
    plainText,
    checkPlagiarism,
    improveContent,
    dropContentImprovement,
    popoverContent,
  ]);

  const deleteDoc = React.useCallback(() => deleteDocument(id), []);

  const readability = React.useMemo(() => {
    const readabilityTime = getReadingTime(content);
    const readabilityData = getReadabilityData(readabilityScore);

    return {
      readabilityTime,
      readabilityData,
    };
  }, [content]);

  if (!toolbarRef) {
    return null;
  }

  return (
    <>
      {deleteDialogOpen && (
        <ConfirmationModal
          confirmButtonText="Delete"
          onCancel={closeDeleteDialog}
          onConfirm={deleteDoc}
          onClose={closeDeleteDialog}
          open
          title="Are you sure you want to delete this description?"
        >
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: content.replace(/<p><br><\/p>/gm, '') }}
            className={classes.readabilityContent}
          />
        </ConfirmationModal>
      )}
      {readabilityModalOpen && (
        <Modal
          onClose={closeReadabilityModal}
          open
          title="Readability"
        >
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: content.replace(/<p><br><\/p>/gm, '') }}
            className={classes.readabilityContent}
          />
          <DialogContentText>
            <div>
              Overall Score:
              <span
                className={classes.readabilityPoint}
                style={{ color: readability.readabilityData.color }}
              >
                {readability.readabilityData.text}
              </span>
            </div>
            <div>
              Reading Time:
              <span
                className={classes.readabilityPoint}
              >
                {readability.readabilityTime}
              </span>
            </div>
          </DialogContentText>
        </Modal>
      )}
      {publishDialogOpen && (
        <PublishDialog
          document={document}
          onClose={closePublishDialog}
          zapierIntegration={zapierIntegration}
          googleDocsIntegration={googleDocsIntegration}
        />
      )}
      {newCampaignDialogOpen && (
        <NewFileModal
          type="campaign"
          document={document}
          fileData={{
            ...file,
            ...file.data,
          }}
          onClose={closeNewCampaignDialog}
        />
      )}
      {plagiarismCheckModalData && (
        <Modal
          open={!!plagiarismCheckModalData}
          onClose={dropPlagiarismCheckModalData}
          maxWidth="md"
          title={plagiarismCheckModalData?.title}
        >
          {plagiarismCheckModalData?.body}
        </Modal>
      )}
      <DocumentActions
        isActionInProgress={isActionInProgress}
        onAction={onAction}
        liked={!!personalizationRule}
        disliked={!!badExampleId}
        selectedText={selectedText?.documentId === id && selectedText}
        plainText={plainText}
        quillRef={ref}
        allowedActions={allowedActions}
        readOnly={readOnly}
        plagiarismCheckTooltipData={plagiarismCheckTooltipData}
        hidden={!!popoverContent}
        open={(featureTourActive && htmlId === 'feature-tour-step-4') || undefined}
        limits={limits}
      >
        <div className={clsx(classes.root)}>
          <Popover
            open={!!popoverContent}
            anchorEl={rootRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              className: classes.popover,
            }}
            onClose={dropContentImprovement}
          >
            {popoverContent}
          </Popover>
          <div
            ref={rootRef}
            onClick={getSelection}
          >
            {isNew && (
              <div className={classes.newBadgeWrap}>
                <span className={classes.newBadge}>
                  New!
                </span>
              </div>
            )}
            <ReactQuill
              ref={setRef}
              onFocus={onElementFocus}
              modules={{
                ...quillConfig.modules,
                toolbar: {
                  container: `#toolbar-${id}`,
                },
              }}
              formats={quillConfig.formats}
              value={content}
              onChange={onChange}
              className={classes.editor}
              onKeyDown={onKeyDown}
              readOnly={readOnly}
              defaultValue={content}
            />
          </div>
          <div className={classes.footer}>
            {personalizationRule && (
              <div className={clsx(classes.footerBadge, classes.footerBadgeBlue)}>
                <ThumbUpAltOutlined />
                Liked
              </div>
            )}
            {badExampleId && (
              <div className={clsx(classes.footerBadge, classes.footerBadgeBlue)}>
                <ThumbDownOutlined />
                Disliked
              </div>
            )}
            <div
              className={clsx(classes.footerBadge, classes.wordCount, {
                [classes.wordCountVisible]: editable,
              })}
            >
              {contentLength}
            </div>
          </div>
          {featureTourActive && (
            <div
              className={classes.onboardingMask}
              id={htmlId}
            />
          )}
        </div>
      </DocumentActions>
    </>
  );
};

export default React.memo(
  Document,
  ({
    document: { content: pContent },
    toolbarRef: pToolbarRef,
    editable: pEditable,
    forceCaretPosition: pForceCaretPosition,
    selectedText: pSelectedText,
    isActionInProgress: pIsActionInProgress,
    personalizationRules: pPersonalizationRules = [],
    file: pFile,
    allowedActions: pAllowedActions,
    showChar: pShowChar,
    limits: pLimits,
  }, {
    document: { content: nContent },
    toolbarRef: nToolbarRef,
    editable: nEditable,
    forceCaretPosition: nForceCaretPosition,
    selectedText: nSelectedText,
    isActionInProgress: nIsActionInProgress,
    personalizationRules: nPersonalizationRules = [],
    file: nFile,
    allowedActions: nAllowedActions,
    showChar: nShowChar,
    limits: nLimits,
  }) => pContent === nContent
    && pToolbarRef === nToolbarRef
    && pEditable === nEditable
    && pForceCaretPosition === nForceCaretPosition
    && pSelectedText === nSelectedText
    && pIsActionInProgress === nIsActionInProgress
    && pPersonalizationRules.length === nPersonalizationRules.length
    && pFile?.title === nFile?.title
    && pAllowedActions === nAllowedActions
    && pShowChar === nShowChar
    && pLimits === nLimits,
);
