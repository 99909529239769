import { featureEnabled, featureToggles } from '@root/utils/features';
import permissionsService from '@root/services/permissions.service';
import { WORDS_MAX_CAP } from '@root/resources/billing/billingInfo.helpers';

const expandLoggedInUser = (user, company, billingData) => {
  const isLifetimeDeal = billingData?.priceId === 'lifetime_deal';

  const newUser = {
    ...user,
    onFreePlan: billingData.rank >= 0 && billingData.rank < 100,
    onStarterPlan: billingData.rank >= 100 && billingData.rank < 150,
    onFeatureGatedStarterPlan: billingData.rank >= 150 && billingData.rank < 200,
    onProPlan: billingData.rank >= 200 && billingData.rank < 250,
    onFeatureGatedProPlan: billingData.rank >= 250 && billingData.rank < 300,
    onTeamPlan: billingData.rank >= 300 && billingData.rank < 400,
    onSelfServeEnterprisePlan: billingData.rank >= 400 && billingData.rank < 500,
    onEnterprisePlan: billingData.rank >= 500 && billingData.rank < 600,
    // ↓ used to determinate where to redirect user after login (onAfterLogin) method
    needToSubscribe: !isLifetimeDeal && (billingData?.status === 'cancelled' || billingData?.status === 'trialExpired' || billingData?.status === 'billingIssue'),
    showCredits: billingData.showCredits,
    latestCreditIsMigration: billingData.latestCreditIsMigration,
    isUnlimitedWordsPlan: company.billing.limits.mainCredits === WORDS_MAX_CAP,
  };

  const userRoles = permissionsService.getUserRoles(user);
  newUser.roles = {
    ...userRoles,
    getFolderRoles: permissionsService.getFolderRoles(user),
  };

  newUser.featureToggles = {
    zapier: featureEnabled(featureToggles.zapier, user),
    hootsuite: featureEnabled(featureToggles.hootsuite, user),
    restClient: featureEnabled(featureToggles.restClient, user),
    workflowQueue: featureEnabled(featureToggles.workflowQueue, user),
    googleDocs: featureEnabled(featureToggles.googleDocs, user),
    googleDocsPublishing: featureEnabled(featureToggles.googleDocsPublishing, user),
    newPlans: featureEnabled(featureToggles.newPlans, user),
    newCheckout: featureEnabled(featureToggles.newCheckout, user),
    newShopifyIntegration: featureEnabled(featureToggles.newShopifyIntegration, user),
    wordpressEcommercePlugin: featureEnabled(featureToggles.wordpressEcommercePlugin, user),
    personalizedGenerations: featureEnabled(featureToggles.personalizedGenerations, user),
    personalizedGenerationsList: featureEnabled(featureToggles.personalizedGenerationsList, user),
    freeFormV2: featureEnabled(featureToggles.freeFormV2, user),
    travelFineTune: featureEnabled(featureToggles.travelFineTune, user),
    davinciPlusEngine: featureEnabled(featureToggles.davinciPlusEngine, user),
    MSWordAddIn: featureEnabled(featureToggles.MSWordAddIn, user),
    googleDocsButton: featureEnabled(featureToggles.googleDocsButton, user),
    ffseFraseIntegration: featureEnabled(featureToggles.ffseFraseIntegration, user),
    newFileSharing: featureEnabled(featureToggles.newFileSharing, user),
    profileSidebarRework: featureEnabled(featureToggles.profileSidebarRework, user),
    createFromAli: featureEnabled(featureToggles.createFromAli, user),
    iaDashboard: featureEnabled(featureToggles.iaDashboard, user),
    IANewWorkflowBadge: featureEnabled(featureToggles.IANewWorkflowBadge, user),
    dashboardPagination: featureEnabled(featureToggles.dashboardPagination, user),
    landingPageInstruct: featureEnabled(featureToggles.landingPageInstruct, user),
    dashboardAIRedesign2: featureEnabled(featureToggles.dashboardAIRedesign2, user),
    workflowToTeamMembers: featureEnabled(featureToggles.workflowToTeamMembers, user),
    newProfileTeamPage: featureEnabled(featureToggles.newProfileTeamPage, user),
    customPromptSettings: featureEnabled(featureToggles.customPromptSettings, user),
    newTranslationLogic: featureEnabled(featureToggles.newTranslationLogic, user),
    templateBuilder: featureEnabled(featureToggles.templateBuilder, user),
    fileRefactoring: featureEnabled(featureToggles.fileRefactoring, user),
    zapierEmbeded: featureEnabled(featureToggles.zapierEmbeded, user),
    rewardsPage: featureEnabled(featureToggles.rewardsPage, user),
    templatesLibrary: featureEnabled(featureToggles.templatesLibrary, user),
    apiDocs: featureEnabled(featureToggles.apiDocs, user),
    oct2022AnnualPriceUpdates: featureEnabled(featureToggles.oct2022AnnualPriceUpdates, user),
    newEditor: featureEnabled(featureToggles.newEditor, user),
    editorLoginRedirect: featureEnabled(featureToggles.editorLoginRedirect, user),
    usageDashboard: featureEnabled(featureToggles.usageDashboard, user),
    newCreateButton: featureEnabled(featureToggles.newCreateButton, user),
    selfServeEnterprise: featureEnabled(featureToggles.selfServeEnterprise, user),
    newFilesPage: featureEnabled(featureToggles.newFilesPage, user),
    newNavBar: featureEnabled(featureToggles.newNavBar, user),
    createAndExplore: featureEnabled(featureToggles.createAndExplore, user),
    editorImageTool: featureEnabled(featureToggles.editorImageTool, user),
    aiImages: featureEnabled(featureToggles.aiImages, user),
    cancellationBonus: featureEnabled(featureToggles.cancellationBonus, user),
    dashboardV2: featureEnabled(featureToggles.dashboardV2, user),
    rewardWords: featureEnabled(featureToggles.rewardWords, user),
    nonEnterpriseOverage: featureEnabled(featureToggles.nonEnterpriseOverage, user),
    march2023Pricing: featureEnabled(featureToggles.march2023Pricing, user),
    newLeftSidebar: featureEnabled(featureToggles.newLeftSidebar, user),
    createPageRedesign: featureEnabled(featureToggles.createPageRedesign, user),
    bigCommerceCard: featureEnabled(featureToggles.bigCommerceCard, user),
    akeneoCard: featureEnabled(featureToggles.akeneoCard, user),
    homePage: featureEnabled(featureToggles.homePage, user),
    new2023Navigation: featureEnabled(featureToggles.new2023Navigation, user),
    useCaseModalInstantly: featureEnabled(featureToggles.useCaseModalInstantly, user),
  };

  newUser.permissions = {
    ...permissionsService.getUserPermissions(
      userRoles,
      billingData,
      company.billing,
      newUser.featureToggles,
      company.previousPlanSettings?.integrationsEnabled,
    ),
    getFolderPermissions: permissionsService.getFolderPermissions(user),
    getFilePermissions: permissionsService.getFilePermissions(user),
  };

  // eslint-disable-next-line no-console
  console.log('Loaded currentUser. Use it to check roles, permissions and featureToggles.');

  const isAdminOrOwner = (newUser.roles.owner || newUser.roles.admin);
  const isDashboardV2Enabled = isAdminOrOwner && newUser.featureToggles.dashboardV2;
  const isDashboardV1Enabled = isAdminOrOwner && (newUser.showCredits || newUser.onFreePlan);

  newUser.isUsageDashboardEnabled = newUser.featureToggles.usageDashboard && (
    isDashboardV2Enabled || isDashboardV1Enabled
  );

  newUser.noActiveSubscription = !['trial', 'customer', 'ltd'].includes(company.billing.status);

  return newUser;
};

/** Extract the user's roles from the user.workspace object */
const getRoles = (user) => {
  if (!user) {
    return null;
  }
  return user.workspaces.find((ws) => ws.workspaceId === user.activeWorkspaceId).roles;
};

/** Get the highest role the user currently holds for visual display */
const getHighestRole = (rolesArray) => {
  if (!rolesArray) {
    return '';
  }
  if (rolesArray.includes('owner')) {
    return 'owner';
  }
  if (rolesArray.includes('admin')) {
    return 'admin';
  }
  if (rolesArray.includes('member')) {
    return 'member';
  }
  return 'viewer';
};

export default {
  addBackwardCompUserFields: expandLoggedInUser,
  getRoles,
  getHighestRole,
};
