import React from 'react';

import QualityTrack from '../QualityTrack';
import TextAreaInput from '../TextAreaInput';
import checkQuality from '../../utils/quality.util';

const GENERATOR_LIMITS = {
  FAIR: 50,
  GREAT: 100,
  EXCESS: 1200,
};

const newLineRegex = /(\r\n|\n|\r)/gm;

const ReadonlyTemplateDetails = ({
  text,
  selectedTemplateType,
  templateTypes,
  maxLength,
  creditCostsThresholds = [],
}) => {
  const textLength = text.replace(newLineRegex, '').length;
  const selectedTemplate = templateTypes.find(({ template }) => template === selectedTemplateType);

  const creditCostThreshold = creditCostsThresholds.find(({ threshold }) => {
    return textLength < threshold;
  }) || creditCostsThresholds.slice(-1)[0];
  const creditCost = creditCostThreshold?.value;

  const config = {
    fairThreshold: GENERATOR_LIMITS.FAIR,
    greatThreshold: GENERATOR_LIMITS.GREAT,
    excessThreshold: maxLength || GENERATOR_LIMITS.EXCESS,
    label: selectedTemplate.qualityTrackLabel,
  };

  const quality = checkQuality(textLength, config);

  return (
    <>
      <TextAreaInput
        label="Input Text"
        name="inputText"
        placeholder=""
        value={text}
        length={textLength}
        creditCost={creditCost}
        quality={quality}
        maxLength={maxLength || GENERATOR_LIMITS.EXCESS}
      />

      <QualityTrack
        quality={quality}
      />
    </>
  );
};

export default ReadonlyTemplateDetails;
