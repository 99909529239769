import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return ({
    qualityTrack: {
      marginTop: 16,
    },
  });
});

export default useStyles;
