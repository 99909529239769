import React from 'react';
import { makeStyles } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltip: {
    width: '100%',
  },
});

const TextWithOverflow = ({ text }) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseOver = (e) => {
    setShowTooltip(e.target.scrollWidth > e.target.offsetWidth);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  return (
    <SimpleTooltip
      content={text}
      open={showTooltip}
    >
      <div
        className={classes.root}
        onFocus={handleMouseOver}
        onMouseOver={handleMouseOver}
        onBlur={handleMouseOut}
        onMouseOut={handleMouseOut}
      >
        {text}
      </div>
    </SimpleTooltip>
  );
};

export default TextWithOverflow;
