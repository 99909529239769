import useCreateFolder from './useCreateFolder';
import useDeleteFolder from './useDeleteFolder';
import useUpdateFolder from './useUpdateFolder';
import useFolders from './useFolders';
import useLikeFolder from './useLikeFolder';
import useShareFolder from './useShareFolder';
import useFolder from './useFolder';

export default {
  useCreateFolder,
  useDeleteFolder,
  useUpdateFolder,
  useFolders,
  useFolder,
  useLikeFolder,
  useShareFolder,
};
