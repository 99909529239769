import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  image: {
    maxHeight: 20,
    maxWidth: 20,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 10,
  },
}));

export default useStyles;
