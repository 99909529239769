import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';

import logo from '@root/assets/logo/black.svg';

import useStyles from './NavHeader.styles';

const NavHeader = () => {
  const classes = useStyles();
  const history = useHistory();

  const goHome = () => {
    history.replace('/');
  };

  const goToPlans = () => {
    history.replace('profile/plans');
  };

  return (
    <div className={classes.navHeader}>
      <div onClick={goHome} className={classes.link}>
        <img
          className={classes.logo}
          src={logo}
          alt="Logo"
        />
      </div>
      <div onClick={goToPlans} className={classes.link}>
        Plans
      </div>
      <ChevronRightIcon color="inherit" />
      Checkout
    </div>
  );
};

export default NavHeader;
