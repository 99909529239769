import React from 'react';
import { StringUtil } from '@copysmith/utils';
import ReactHtmlParser from 'react-html-parser';

import uiNotificationService from '@root/services/uiNotification.service';

export const enhanceAction = async (text, templates, generateNow) => {
  const templateType = 'contentImprover';
  const template = templates?.find((t) => t.templateType === templateType);

  if (!template) {
    return null;
  }

  const { results, errors } = await generateNow({
    templateType,
    negativeKeywordArray: [],
    data: {
      draft: text,
    },
    aiOptions: {
      ...template.aiOptions,
      n: 1,
      best_of: 2,
    },
  });

  if (errors?.length) {
    return null;
  }

  if (!results.length) {
    uiNotificationService.showErrorMessage('Something went wrong. Please try again.');
    return null;
  }

  return results[0];
};

export const rewriteAction = async (text, templates, generateNow) => {
  const templateType = 'contentRewriter';
  const template = templates?.find((t) => t.templateType === templateType);

  if (!template) {
    return null;
  }

  const { results, errors } = await generateNow({
    templateType,
    negativeKeywordArray: [],
    data: {
      inputParagraph: text,
    },
    aiOptions: {
      ...template.aiOptions,
      n: 1,
      best_of: 2,
    },
  });

  if (errors?.length) {
    return null;
  }

  if (!results?.length) {
    uiNotificationService.showErrorMessage('Something went wrong. Please try again.');
    return null;
  }

  return results[0];
};

export const extendAction = async (text, templates, generateNow) => {
  const templateType = 'freeFormEditor';
  const template = templates?.find((t) => t.templateType === templateType);

  if (!template) {
    return null;
  }

  const { results, errors } = await generateNow({
    templateType,
    negativeKeywordArray: [],
    data: {
      exampleParagraph: text,
    },
    aiOptions: {
      ...template.aiOptions,
      n: 1,
      best_of: 2,
    },
  });

  if (errors?.length) {
    return null;
  }

  if (!results.length) {
    uiNotificationService.showErrorMessage('Something went wrong. Please try again..');
    return null;
  }

  return results[0];
};

export const plagiarismAction = async ({ limits, text, setModalData, checkPlagiarism }) => {
  const { plagiarismCheckLimit: numberOfPlagiarismChecks } = limits;

  const wordsCount = text ? StringUtil.countWordsString(text) : 0;
  const wordsCountInvalid = wordsCount < 15 || wordsCount > 200;
  const insufficientlyChecks = numberOfPlagiarismChecks === 0;
  const plagiarismCheck = {
    wordsCount,
    wordsCountInvalid,
    checksCount: numberOfPlagiarismChecks,
    insufficientlyChecks,
    error: insufficientlyChecks || wordsCountInvalid,
  };

  if (plagiarismCheck.error) {
    return;
  }

  const [checkTitle, checkContent] = await checkPlagiarism({ text });
  setModalData(
    {
      title: checkTitle.replace('0 results found', 'No matches found'),
      body: checkContent.includes('<p>') ? (
        <div>{ReactHtmlParser(checkContent)}</div>
      ) : (
        <div>Plagiarism check passed!</div>
      ),
    },
  );
};
