import React from 'react';

import api from '@root/api/newApi';
import Button from '@root/components/buttons/Button';
import usePlanInfo from '@root/resources/billing/usePricing';
import useBillingInfo from '@root/resources/billing/useBillingInfo';

import useStyles from './Discount.styles';

const Discount = ({ onSuccess }) => {
  const classes = useStyles();
  const { refetch } = useBillingInfo();
  const { data: { currentPlan } = {} } = usePlanInfo();
  const amount = currentPlan?.data?.limits?.mainCredits;

  const applyDiscount = async () => {
    const { isBadRequest } = await api.billing.applyCancelationDiscount();
    if (!isBadRequest) {
      refetch();
      onSuccess();
    }
  };

  return (
    <div className={classes.discountRoot}>
      <div className={classes.wrapper}>
        Grab&nbsp;
        {amount}
        <br />
        words FREE
        <Button onClick={applyDiscount}>
          Sign me in
        </Button>
      </div>
    </div>
  );
};

export default Discount;
