import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateOptions extends RequestOptions {
  userId: string;
  displayName: string;
  email: string;
  affiliateId?: string;
  referralCode?: string;
  referralType?: string;
}

export interface FailOptions extends RequestOptions {
  email: string;
}

export interface GenerateJwtOptions extends RequestOptions {
  magicToken: string;
}

export interface ValidateSignupOptions extends RequestOptions {
  email: string;
}

export interface OauthDataOptions extends RequestOptions {
  code: string;
  redirectUri: string;
  provider: string;
  thirdPartyPayload?: Record<string, unknown>,
}

export interface LoginOptions extends RequestOptions {
  email: string;
  password: string;
  thirdPartyPayload?: Record<string, unknown>; 
}

export interface SSOConfigOptions extends RequestOptions {
  email: string;
}

export interface RegisterExternalSourceOptions extends RequestOptions {
  type: string;
  payload: Record<string, unknown>
}

export interface RefreshExternalSourceOptions extends RequestOptions {
  type: string;
  payload: Record<string, unknown>
}

export interface RemoveExternalSourceOptions extends RequestOptions {
  type: string;
  payload: Record<string, unknown>
}

export interface OauthSignupOptions extends RequestOptions {
  code: string;
  provider: string;
  redirectUrl: string;
  affiliateId?: string;
  thirdPartyPayload?: Record<string, unknown>;
  referralCode?: string;
  referralType?: string;
}

export interface OauthLoginOptions extends RequestOptions {
  code: string;
  provider: string;
  redirectUrl: string;
  thirdPartyPayload?: Record<string, unknown>;
}
export interface SSOLoginOptions extends RequestOptions {
  code: string;
  thirdPartyPayload?: Record<string, unknown>;
}

export interface RestorePasswordOptions extends RequestOptions {
  email: string;
}

export interface ResetPasswordOptions extends RequestOptions {
  resetPasswordToken: string;
}

export interface VerifyEmailOptions extends RequestOptions {
  verifyEmailToken: string;
}

export interface SetNewEmailOptions extends RequestOptions {
  email: string;
}

export interface ConfirmNewEmailOptions extends RequestOptions {
  confirmNewEmailToken: string;
  thirdPartyPayload?: Record<string, unknown>,
}

export interface ResendTrialVerificationOptions extends RequestOptions {
  email: string;
}

export class Account {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async signup(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/signup", data, options);

    return result;
  }

  public async signupWithOauth(data: OauthSignupOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/signup/oauth", data, options);

    return result;
  }

  public async login(data: LoginOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/login", data, options);

    return result;
  }

  public async registerExternalSource(data: RegisterExternalSourceOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/external-source", data, options);

    return result;
  }
  
  public async refreshExternalSource(data: RefreshExternalSourceOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/refresh-external-source", data, options);

    return result;
  }

  public async removeExternalSource(data: RefreshExternalSourceOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del("/account/external-source", data, options);

    return result;
  }

  public async loginWithOauth(data: LoginOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/login/oauth", data, options);

    return result;
  }

  public async loginWithSSO(data: SSOLoginOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/login/sso", data, options);

    return result;
  }

  public async logout(): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/logout");

    return result;
  }

  public async logFailedLoginAttempt(data: FailOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/login/fail", data, options);

    return result;
  }

  public async generateJwt(data: GenerateJwtOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/jwt", data, options);

    return result;
  }

  public async restorePassword(data: RestorePasswordOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/restore-password", data, options);

    return result;
  }

  public async resetPassword(data: ResetPasswordOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/reset-password", data, options);

    return result;
  }

  public async verifyEmail(data: VerifyEmailOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/verify-email", data, options);

    return result;
  }

  public async setNewEmail(data: SetNewEmailOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/account/email", data, options);

    return result;
  }

  public async getSSOConfig(data: SSOConfigOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/account/sso-config", data, options);

    return result;
  }

  public async confirmNewEmail(data: ConfirmNewEmailOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/confirm-new-email", data, options);

    return result;
  }

  public async generateVisitorToken(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/visitor-token", data, options);

    return result;
  }

  public async resendTrialVerification(data: ResendTrialVerificationOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/account/resend-trial-verification", data, options);

    return result;
  }
}
