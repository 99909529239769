export default {
  title: 'FAQ Ideas',
  description: 'Find out what questions your customers may be asking.',
  input: [
    {
      title: 'Company Name',
      value: 'BikeShare',
    },
    {
      title: 'Description',
      value:
        'BikeShare offers 24/7 convenient access to 6,850 bikes and 625 stations across 200 km of the city. BikeShare is a fun, flexible and cost-effective way to navigate Toronto',
    },
  ],
  companyName: 'BikeShare',
  output: {
    _id: '1',
    templateType: 'faqIdeas',
    liked: false,
    content:
      '• How does BikeShare work?\n• How do I take out a bike?\n• How long can I keep a bike out?\n• How do I return a bike?\n• Are there any associated fees if I keep a bike longer than booked?',
  },
  fileData: { companyName: 'BikeShare' },
};
