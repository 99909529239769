import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { InputField, FormField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import useCreateCatalogItem from '@root/resources/catalogItem/useCreateCatalogItem';

import useStyles from './AddSingleProductForm.styles';

const AddSingleProductForm = () => {
  const classes = useStyles();
  const { id: catalogId } = useParams();
  const { history } = useHistory();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { isLoading, mutateAsync } = useCreateCatalogItem();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const submitProduct = async (values) => {
    const response = await mutateAsync({
      ...values,
      catalogId,
    });

    if (response.isBadRequest) {
      return;
    }

    closeModal();
  };

  const createProductSchema = Yup.object({
    title: Yup.string().required('Title is required').max(2000, 'Title must be 2000 characters or less.'),
    externalId: Yup.string().required('Product ID is required').max(200, 'ID must be 200 characters or less.'),
    bulletPoints: Yup.string().optional().max(2000, 'Bullet points must be 2000 characters or less.'),
    description: Yup.string().optional().max(4000, 'Description must be 4000 characters or less.'),
  });

  return (
    <Formik
      initialValues={{
        title: '',
        externalId: '',
        description: '',
        bulletPoints: '',
      }}
      onSubmit={submitProduct}
      validationSchema={createProductSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className={classes.formGroup}>
              <FormField
                name="title"
                label="Title"
                placeholdres=""
                component={InputField}
                required
              />
            </div>

            <div className={classes.formGroup}>
              <FormField
                name="externalId"
                label="Product Id"
                placeholdres=""
                component={InputField}
                required
              />
            </div>

            <div className={classes.formGroup}>
              <FormField
                name="description"
                label="Description"
                placeholdres=""
                component={InputField}
                multiline
              />
            </div>

            <div className={classes.formGroup}>
              <FormField
                name="bulletPoints"
                label="Bullet Points"
                placeholdres=""
                component={InputField}
                multiline
              />
            </div>

            <div className={classes.actions}>
              <Button variant="outlined" type="button" onClick={closeModal}>Cancel</Button>
              <Button
                type="submit"
                className={classes.submit}
                disabled={isLoading || isSubmitting}
              >
                Add Product
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddSingleProductForm;
