import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';
import { IconFolder, IconShare } from '@tabler/icons-react';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { FolderOptionsKebab } from '@root/views/Dashboard/components/IconButtons';
import uiNotificationService from '@root/services/uiNotification.service';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import ItemTypes from '@root/utils/dndItemTypes';
import TruncatedText from '@root/components/TruncatedText';
import { isFolderShared } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './ChildFolderListItemNew.styles';

const ChildFolderListItemNew = ({
  folder, isDraggable = false,
}) => {
  const [hover, setHover] = useState(false);
  const {
    state: {
      filters: { folderId: currentFolderId },
    }, dispatch,
  } = useDashboard();
  const { currentUser } = AuthContext.useAuth();
  const classes = useStyles();
  const ref = useRef(null);

  const isActive = folder.id === currentFolderId;
  const isShared = isFolderShared(folder, currentUser);

  const { mutateAsync: updateFile } = useUpdateFile();

  const moveFileHere = async (fileId) => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move file to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`File has been moved to ${folder.title}`);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.FILE_CARD,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
    drop: async (item) => {
      moveFileHere(item.fileId);
    },
  });

  const drag = useDrag({
    type: ItemTypes.FOLDER_CARD,
    item: { folderId: folder._id },
  })[1];

  const handleSelectFolder = () => dispatch({
    type: actions.SELECT_FOLDER,
    payload:
      {
        title: folder.title,
        folderId: folder._id,
      },
  });

  if (isDraggable) {
    drag(drop(ref));
  }

  return (
    <div
      className={clsx(
        classes.childFolderListItemContainer,
        {
          [classes.childFolderListItemActive]: isActive,
          [classes.useDrop]: canDrop && isDraggable,
          [classes.isDraggingOver]: isOver && isDraggable,
        },
      )}
      onClick={handleSelectFolder}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
    >
      <div className={classes.folderItemWrapper}>
        <IconFolder className={classes.folderIcon} />

        <TruncatedText className={classes.title}>{folder.title}</TruncatedText>

        {isShared && <IconShare className={classes.sharedIcon} />}

        <div className={clsx(classes.actionsContainer, { [classes.hidden]: !hover })}>
          <FolderOptionsKebab
            variant="sidebar"
            folder={folder}
          />
        </div>
      </div>
    </div>
  );
};

export default ChildFolderListItemNew;
