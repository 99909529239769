import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormHelperText } from '@material-ui/core';

import Checkbox from '@root/components/Checkbox';
import FieldLabel from '@root/components/form/FieldLabel';

const CheckboxField = ({
  name,
  label,
  labelTooltip,
  error,
  value,
  ...props
}) => {
  const checkboxComponent = (
    <Checkbox
      id={name}
      name={name}
      checked={value}
      {...props}
    />
  );

  const labelComponent = (
    <FieldLabel
      name={name}
      label={label}
      labelTooltip={labelTooltip}
      isControl
    />
  );

  return (
    <div>
      <FormControlLabel
        control={checkboxComponent}
        label={labelComponent}
      />
      <FormHelperText error={!!error}>
        {error}
      </FormHelperText>
    </div>
  );
};

CheckboxField.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  error: PropTypes.string,
  labelTooltip: PropTypes.string,
};

CheckboxField.defaultProps = {
  name: undefined,
  error: undefined,
  labelTooltip: undefined,
};

export default CheckboxField;
