import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import Button from '@root/components/buttons/Button';

import useStyles from '../../Plans.styles';

const AddonsFeatures = () => {
  const classes = useStyles();
  const history = useHistory();
  const addonsRoute = '/addons';

  const handleButtonClick = () => {
    history.push(addonsRoute);
  };

  return (
    <div className={classes.subBlock}>
      <h3 className={classes.subTitle}>
        Add-on Features
      </h3>
      <div>
        We&apos;re always adding more features to Copysmith.&nbsp;
        <Link to={addonsRoute}>Visit the Add-ons page to explore</Link>
        &nbsp;additional features, integrations, and apps.
        Whether you want to integrate with your other tools, add more monthly words,
        or invite just 1 more team member, we&apos;ve got a solution for you!
      </div>

      <Button
        className={classes.button}
        onClick={handleButtonClick}
      >
        Explore Add-ons
      </Button>
    </div>
  );
};

export default AddonsFeatures;
