import React from 'react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import Radio from '@root/components/Radio';

import ApplePay from './icons/ApplePay.icon';
import GooglePay from './icons/GooglePay.icon';
import useStyles from './MethodSelector.styles';

const additionalMethodsLabels = {
  applePay: <ApplePay />,
  googlePay: <GooglePay />,
};

const MethodSelector = ({ additionalMethods, value, onChange }) => {
  const classes = useStyles();

  return (
    <RadioGroup
      className={classes.root}
      value={value}
      onChange={onChange}
    >
      <FormControlLabel
        className={classes.item}
        value="card"
        control={<Radio />}
        labelPlacement="start"
        label={(
          <span className={classes.creditCardLabel}>
            Card
            <CreditCardIcon />
          </span>
        )}
      />
      {additionalMethods.map((method) => (
        <FormControlLabel
          key={method}
          value={method}
          className={classes.item}
          control={<Radio />}
          labelPlacement="start"
          label={additionalMethodsLabels[method]}
        />
      ))}
    </RadioGroup>
  );
};

export default MethodSelector;
