import React, { useState, useRef, useEffect } from 'react';
import {
  KeyboardArrowDown as DownIcon,
  KeyboardArrowRight as RightIcon,
} from '@material-ui/icons';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

import api from '@root/api';
import Button from '@root/components/buttons/Button';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useModalState from '@root/utils/hooks/useModalState';
import SavedCard from '@root/components/SavedCard';
import CardForm from '@root/components/CardForm';
import uiNotificationService from '@root/services/uiNotification.service';
import { FEATURES } from '@root/views/Addons/addonsConstants';
import billingResource from '@root/resources/billing';
import userResource from '@root/resources/user';
import AuthContext from '@root/resources/auth/auth.context';
import TooltipIcon from '@root/components/tooltips/TooltipIcon';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import Counter from './components/Counter';
import useStyles from './FeatureModal.styles';

const FeatureModal = ({ type, currentQuantity, onClose, title, additionalDetails }) => {
  const { currentCompany } = AuthContext.useAuth();
  const { refetch: refetchBilling, data: billingInfoData } = billingResource.useBillingInfo();
  const { invalidateLimits } = billingResource.useInvalidateLimits();
  const { data: memberData, isFetching } = userResource.useTeamMembers();
  const { count: currentMembersCount } = isFetching ? { count: 0 } : memberData;

  const savedCard = billingInfoData?.paymentMethod?.card;
  const userHasCard = !!savedCard;

  const [isDetailsOpen, openDetails, closeDetails, toggleDetails] = useModalState(!userHasCard);
  const [useSavedCard, setUseSavedCard] = useState(userHasCard);
  const [paymentError, setPaymentError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const formRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();

  const featureName = FEATURES[type].label;
  const { cost } = FEATURES[type];
  const defaultTitle = `${currentQuantity ? 'Modify' : 'Activate'} ${featureName}`;

  const isUnlimitedCredits = type === FEATURES.unlimitedCredits.type;
  const isTeamSeats = type === FEATURES.additionalMembers.type;
  const isCredits = type === FEATURES.additionalCredits.type;
  const isAiImages = type === FEATURES.aiImages.type;

  const isPrePro = [
    ...PLANS_IDS.ltds,
    ...PLANS_IDS.starters,
    ...PLANS_IDS.legacyStarters,
    ...PLANS_IDS.legacyBasics,
    ...PLANS_IDS.legacyGrowths,
  ].includes(currentCompany.billing.currentLimitId);
  const isPro = [
    ...PLANS_IDS.pros,
    ...PLANS_IDS.legacyPros,
    ...PLANS_IDS.legacyEarlyAdopters,
  ].includes(currentCompany.billing.currentLimitId);

  let maxQuantity;
  let minQuantity = 1;
  if (isTeamSeats) {
    maxQuantity = currentCompany.billing.limits.maxMembers - currentCompany.billing.limits.members;
    minQuantity = Math.max(currentMembersCount - currentCompany.billing.limits.members, 1);
  } else if (isCredits) {
    if (currentQuantity) {
      maxQuantity = currentQuantity + 10;
    } else {
      maxQuantity = 10;
    }
  } else if (isAiImages) {
    maxQuantity = 10;
  }

  const [quantity, setQuantity] = useState(currentQuantity || minQuantity);
  useEffect(() => {
    setQuantity(currentQuantity || minQuantity);
  }, [minQuantity]);

  const handleClose = () => {
    onClose();
    closeDetails();
    setUseSavedCard(true);
  };

  const handleChangeCard = () => {
    setUseSavedCard(false);
    setPaymentError(null);
  };

  const applyAddon = async () => {
    const response = await api.billing.applyAddon({
      addon: type,
      quantity,
    });

    const { isBadRequest, errors } = response;
    const isBillingError = errors && errors.some((error) => Object.keys(error).includes('billing'));

    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Addon applied successfully');
      handleClose();
      refetchBilling();
      invalidateLimits();
    } else if (isBillingError) {
      setPaymentError('We failed to process payment with this method.');
      openDetails();
    }
    setIsLoading(false);
  };

  const handleSubmit = async (values = {}) => {
    const card = elements.getElement(CardNumberElement);
    setIsLoading(true);
    if (!useSavedCard) {
      if (!stripe || !elements) {
        setIsLoading(false);
        return;
      }
      if (!card) {
        openDetails();
        setIsLoading(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
      });

      if (error) {
        if (formRef.current) {
          formRef.current.setFieldError('cardNumber', error.message);
        }
        setIsLoading(false);
        return;
      }

      const { isBadRequest } = await api.billing.updateCard({
        paymentMethod: paymentMethod.id,
      });
      if (isBadRequest) {
        setPaymentError('We failed to process payment with this method.');
        openDetails();
        setIsLoading(false);
        return;
      }
    }

    applyAddon(values.quantity);
  };

  const modalTitle = (
    <>
      {title || defaultTitle}
      {isUnlimitedCredits && (
        <TooltipIcon
          margin={6}
          content="Unlimited Words will automatically replace any Additional Words add-on units."
        />
      )}
    </>
  );

  const renderTeamSeatsInfo = () => {
    return (
      <>
        {(currentQuantity && minQuantity > 1) && (
          <div>
            {`You have ${currentMembersCount} active team members (${minQuantity} ${pluralize('seat', currentQuantity)} in use). If you want to downgrade, `}
            <Link to={{
              pathname: '/profile/team',
              state: { tab: 'members' },
            }}
            >
              manage your team
            </Link>
            &nbsp;first.
          </div>
        )}
        {(isPrePro && currentQuantity === maxQuantity) && (
          <div>
            You&apos;ve already added the maximum team seats allowed by your plan type.&nbsp;
            <Link to="/profile/plans">
              Upgrade to the Professional plan
            </Link>
            &nbsp;to add up to 15 team seats.
          </div>
        )}
        {(isPro && currentQuantity === maxQuantity) && (
          <div>
            You&apos;ve already added the maximum team seats allowed by your plan type.&nbsp;
            <a
              href="https://form.typeform.com/to/gvJBqbTK"
              target="_blank"
              rel="noreferrer"
            >
              Contact our team
            </a>
            &nbsp;to discuss custom plan options.
          </div>
        )}
      </>
    );
  };

  const renderFeature = () => {
    if (isUnlimitedCredits) {
      return (
        <div className={classes.feature}>
          You are about to activate Unlimited Words add-on. This will add&nbsp;
          <b>{`$${cost} / month`}</b>
          &nbsp;to your subscription.
        </div>
      );
    }
    return (
      <div className={classes.feature}>
        {isTeamSeats && renderTeamSeatsInfo()}
        <Counter
          quantity={quantity}
          onQuantityChange={setQuantity}
          type={type}
          min={minQuantity}
          max={maxQuantity}
        />
      </div>
    );
  };

  return (
    <ConfirmationModal
      title={modalTitle}
      onConfirm={handleSubmit}
      onClose={handleClose}
      isConfirmDisabled={quantity === currentQuantity || isLoading}
      confirmButtonText={isLoading ? 'Processing' : undefined}
    >
      {renderFeature()}

      {additionalDetails}

      <Button
        onClick={toggleDetails}
        variant="link"
      >
        {isDetailsOpen ? <DownIcon /> : <RightIcon />}
        Payment Details
      </Button>

      {isDetailsOpen && (
        <div className={classes.details}>
          {useSavedCard ? (
            <SavedCard
              lastNumbers={savedCard?.last4}
              error={paymentError}
              onChangeClick={handleChangeCard}
            />
          ) : (
            <CardForm error={paymentError} />
          )}
        </div>
      )}
    </ConfirmationModal>
  );
};

export default FeatureModal;
