import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { IconCreditCard } from '@tabler/icons-react';

import TruncatedText from '@root/components/TruncatedText';
import dateUtils from '@root/utils/date.util';
import EditPaymentMethodButton from '@root/views/Profile/Billing/components/EditPaymentMethodButton';
import useModalState from '@root/utils/hooks/useModalState';
import AddCardDialog from '@root/views/Profile/Billing/components/AddCardDialog';
import Visa from '@root/views/Profile/Billing/components/Sections/AccountSection/icons/Visa.icon';
import MasterCardIcon from '@root/views/Profile/Billing/components/Sections/AccountSection/icons/MasterCard.icon';

import BillingDates from '../../BillingDates';
import useSectionsStyles from '../Sections.styles';

import BillingEmail from './BillingEmail';
import useStyles from './AccountSection.styles';

const getIconByBrand = (brand) => {
  switch (brand) {
    case 'visa':
      return <Visa style={{ width: '100%', height: '100%' }} />;
    case 'mastercard':
      return <MasterCardIcon style={{ width: '100%' }} />;
    default:
      return <IconCreditCard strokeWidth={1} />;
  }
};

const PaymentWidget = ({ billingData }) => {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const [isAddCardOpen, openAddCard, closeAddCard] = useModalState(false);

  const hasPaymentMethod = !!billingData?.paymentMethod;
  const isCreditRenewalShown = hasPaymentMethod && !billingData?.cancelAtPeriodEnd && billingData?.status !== 'cancelled';

  const paymentMethod = useMemo(() => {
    if (billingData?.paymentMethod) {
      return billingData.paymentMethod;
    }

    return { card: {} };
  }, [billingData]);

  const { card: { brand, last4 }, expirationMonth, expirationYear, email } = paymentMethod;

  useEffect(() => {
    if (location.state?.addCardOpen) {
      openAddCard();
      history.replace({}); // drop state
    }
  }, [location.state]);

  return (
    <div className={clsx(sectionClasses.cardContainer, classes.accountCard)}>
      <AddCardDialog open={isAddCardOpen} onClose={closeAddCard} />

      <header className={classes.header}>
        <div className={classes.titleContainer}>
          <TruncatedText className={classes.title}>Payment method</TruncatedText>

          {isCreditRenewalShown && (
            <div className={classes.renewalBadge}>
              Words renewal:
              {' '}
              {dateUtils.formatDate(billingData?.renewOn + 60 * 60, 'LLL d, h a')}
            </div>
          )}
        </div>
      </header>

      {!hasPaymentMethod && (
        <div className={classes.emptyPaymentContainer}>
          <span>Currently there is no need for your payment method</span>
        </div>
      )}

      {hasPaymentMethod && (
        <BillingDates />
      )}

      {hasPaymentMethod && (
        <div className={classes.paymentMethodContainer}>
          <div className={classes.brandIconContainer}>{getIconByBrand(brand)}</div>

          <div className={classes.paymentInfoContainer}>
            <p className={clsx(classes.cardInfoText, classes.bold)}>
              {brand}
              {' '}
              ending in
              {' '}
              {last4}
            </p>
            <p className={classes.cardInfoText}>
              expiry
              {' '}
              {expirationMonth}
              /
              {expirationYear}
            </p>

            <BillingEmail email={email} />
          </div>

          <EditPaymentMethodButton onClick={openAddCard} />
        </div>
      )}
    </div>
  );
};

export default PaymentWidget;
