import React from 'react';
import { MenuItem } from '@material-ui/core';
import { IconChevronRight, IconDownload } from '@tabler/icons-react';
import { DocumentUtil } from '@copysmith/utils';

import MenuButton from '@root/components/MenuButton';
import { downloadAsDocX, downloadAsFile, downloadHtmlAsPDF, getDateString } from '@root/utils/Export/functions';

import useMenuStyles from '../AdditionalMenuButton.styles';

const ExportSubmenu = ({ editorData, editorDataHtml = '', fileTitle }) => {
  const classes = useMenuStyles();
  const exportFileName = `${fileTitle}_${getDateString()}`;

  const processedText = DocumentUtil.transformEditorDataToText(editorData);
  const csvText = DocumentUtil.transformEditorDataToCSVText(editorData);

  const exportPDF = () => {
    downloadHtmlAsPDF(editorDataHtml, exportFileName);
  };

  const exportTXT = () => {
    downloadAsFile(processedText, `${exportFileName}.txt`);
  };

  const exportCSV = () => {
    downloadAsFile(csvText, `${exportFileName}.csv`);
  };

  const exportDOC = () => {
    downloadAsDocX(processedText, `${exportFileName}.docx`);
  };

  return (
    <MenuButton
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        horizontal: 238,
      }}
      button={(
        <MenuItem
          className={classes.button}
        >
          <div className={classes.buttonContent}>
            <IconDownload className={classes.icon} />
            Export
          </div>
          <IconChevronRight
            className={classes.chevron}
            size={16}
            strokeWidth={3}
          />
        </MenuItem>
      )}
    >
      <MenuItem
        onClick={exportTXT}
        className={classes.button}
      >
        Plain text (.txt)
      </MenuItem>
      <MenuItem
        onClick={exportPDF}
        className={classes.button}
      >
        PDF Document (.pdf)
      </MenuItem>
      <MenuItem
        onClick={exportCSV}
        className={classes.button}
      >
        Comma-separated values (.csv)
      </MenuItem>
      <MenuItem
        onClick={exportDOC}
        className={classes.button}
      >
        Microsoft Word (.docx)
      </MenuItem>
    </MenuButton>
  );
};

export default ExportSubmenu;
