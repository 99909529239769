import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  planTableRoot: {
    padding: '32px 48px',
    marginBottom: 45,
    backgroundColor: theme.palette.colors.white,
    borderRadius: 8,
  },
  currentPlanInfo: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  plansWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 48,
  },
  planInfoWrapper: {
    padding: '0 12px',
    width: window.hsp?.Initialized ? 'max(25%, 220px)' : '25%',
  },
  resubscribeButton: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bigRowWrapper: {
    height: 64,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: 20,
    marginTop: 48,
  },
  buttonWrapper: {
    padding: '0 48px',
  },
  space: {
    flexGrow: 1,
  },
}));
