import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rootIcon: {
    marginRight: theme.spacing(1.5),
  },
  rootAvatar: {
    marginRight: theme.spacing(0.5),
  },
  teamMemberSelect: {
    flexGrow: 1,
  },
  roleSelect: {
    marginLeft: theme.spacing(1.5),
    width: 140,
  },
  teamMemberRow: {
    display: 'flex',
    marginTop: theme.spacing(1.5),
  },
  deleteTeamMemberRow: {
    marginLeft: theme.spacing(1.5),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  divider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  rule: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    alignItems: 'center',
  },
  ruleTeamMember: {
    display: 'flex',
  },
  ruleAvatar: {
    marginRight: theme.spacing(2),
  },
  selectedRole: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: theme.spacing(0.5),

    '& svg': {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
  remainingAvatars: {
    borderRadius: '50%',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: `${theme.spacing(2)}px`,
    fontWeight: 500,
    marginRight: theme.spacing(2),
    backgroundColor: grey[400],
    textAlign: 'center',
  },
  inviteBlock: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    '& > :last-child': {
      marginLeft: 8,
    },
    '& a': {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
