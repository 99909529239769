import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    navHeader: {
      display: 'flex',
      alignItems: 'center',
      margin: '34px 0 46px 64px',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '22px',
    },
    logo: {
      width: 40,
      height: 40,
      marginRight: 32,
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  });
});

export default useStyles;
