import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  select: {
    position: 'relative',
  },
  icon: {
    fontSize: '1em',
    color: '#AAAAAA',
    marginRight: '10px',
  },
}));
