import { makeStyles } from '@material-ui/core';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { colors } from '@root/material.theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    borderRadius: '6px',
    backgroundColor: ({ backgroundColor }) => backgroundColor,

  },
  content: {
    color: ({ color }) => color,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
  },
}));

const TemplateStatusBadge = ({ template }) => {
  const { status, submittedOn, fromCommunity } = template;

  const isLive = status === 'live';
  const isSubmitted = status === 'submitted';

  const badgeContent = useMemo(() => {
    if (fromCommunity) {
      return 'Community';
    }
    if (isSubmitted) {
      return `${capitalize(status)} on ${DateTime.fromISO(submittedOn).toFormat('MMMM dd')}`;
    }

    return capitalize(status);
  }, [fromCommunity, isSubmitted, status, submittedOn]);

  const { backgroundColor, color } = useMemo(() => {
    if (isLive && !fromCommunity) {
      return {
        backgroundColor: colors.green200,
        color: colors.green600,
      };
    }

    if (isSubmitted) {
      return {
        backgroundColor: colors.yellow200,
        color: colors.yellow600,
      };
    }

    return {
      backgroundColor: colors.neutrals50,
      color: colors.neutrals600,
    };
  }, [fromCommunity, isLive]);

  const classes = useStyles({ backgroundColor, color });

  return (
    <div className={classes.container}>
      <span className={classes.content}>{badgeContent}</span>
    </div>
  );
};

export default TemplateStatusBadge;
