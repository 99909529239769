import React from 'react';

import billingService from '@root/resources/billing/billing.service';

import useStyles from './PlanDetails.styles';

const PlanDetails = ({
  pipelineData,
  plan,
  proration,
  proratedWords,
}) => {
  const classes = useStyles();

  const numberFormatter = React.useMemo(() => new Intl.NumberFormat('en-US'));

  return (
    <div className={classes.paymentInfo}>
      <div className={classes.paymentInfoTitle}>
        <span>
          {plan.nickname}
        </span>
        <span>
          {`$${numberFormatter.format(plan.amount / 100)}`}
        </span>
      </div>
      <div className={classes.paymentInfoRows}>
        <div className={classes.paymentInfoRow}>
          <span>Words</span>
          <span>{numberFormatter.format(plan.data.limits.mainCredits)}</span>
        </div>
        <div className={classes.paymentInfoRow}>
          <span>Overage Fee</span>
          <span>{`$${(plan.data.limits.mainCreditsOveragePrice || 0.01) / 100} / word`}</span>
        </div>
        <div className={classes.paymentInfoRow}>
          <span>Contract Duration</span>
          <span>{`${pipelineData.contractDuration} months`}</span>
        </div>
        <div className={classes.paymentInfoRow}>
          <span>Billing Frequency</span>
          <span>{pipelineData.billingCycle === 'monthly' ? 'Monthly' : 'Yearly'}</span>
        </div>
        <div className={classes.paymentInfoRow}>
          <span>First Invoice Amount</span>
          <span>
            {
              !proration
                ? '...'
                : `$${numberFormatter.format(billingService.getInvoiceAmount({
                  planAmount: plan.amount,
                  prorationTotal: proration.total,
                }) / 100)}`
            }
          </span>
        </div>
        {proratedWords && (
          <div className={classes.paymentInfoRow}>
            <span>Words available in the first month</span>
            <span>
              {numberFormatter.format(proratedWords)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanDetails;
