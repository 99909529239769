import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Loading from '@root/components/Loading/Loading';

import FileListItem from './FileListItem';

const useStyles = makeStyles(() => ({
  inFolderFiles: {
    padding: '10px 0',
  },
  inFolderFilesText: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '40px',
    margin: '0 21px 5px 31px',
  },
  kebabWrapper: {
    display: 'block',
    position: 'absolute',
    right: '5px',
    bottom: '8px',
    transform: 'rotate(90deg)',
  },
}));

const FilesList = ({
  selectedFileId,
  selectedFolderId,
  folderName = 'this Folder',
  isHomeFolder,
  autoSave,
  filesData,
  sidebarRef,
}) => {
  const classes = useStyles();

  const onFileClick = (navFunction) => async () => {
    await autoSave();
    navFunction();
  };

  const filesAmount = filesData && filesData.length;
  const listTitle = isHomeFolder
    ? `${filesAmount} Files`
    : `${filesAmount} Files in ${folderName}`;

  const [fileRefs, setFileRefs] = React.useState([]);
  const setFileRef = (index) => (refData) => setFileRefs((refs) => {
    if (refs[index]) {
      return refs;
    }

    refs[index] = refData;
    return [...refs];
  });
  React.useEffect(() => {
    setFileRefs([]);
  }, [selectedFolderId]);
  React.useEffect(() => {
    if (fileRefs.length === 0) {
      return () => {};
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        const ref = fileRefs.find((r) => r.element === e.target);
        if (!ref) {
          return;
        }
        ref.setVisible(e.isIntersecting);
      });
    }, { root: sidebarRef, threshold: 0, rootMargin: '500px 0px 500px 0px' });
    fileRefs.forEach((r) => observer.observe(r.element));

    return () => {
      fileRefs.forEach((r) => observer.unobserve(r.element));
    };
  }, [fileRefs, fileRefs.length]);

  if (!filesData) {
    return (
      <Loading />
    );
  }

  return (
    <div className={classes.inFolderFiles}>
      <div className={classes.inFolderFilesText}>
        {listTitle}
      </div>
      <div className={classes.filesList}>
        {filesData.map((file, i) => {
          return (
            <FileListItem
              key={file._id}
              file={file}
              selectedFileId={selectedFileId}
              onFileClick={onFileClick}
              setRef={setFileRef(i)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilesList;
