import DocumentEditor from '@root/components/DocumentView/components/CommonEditor';
import DocumentPreview from '@root/components/DocumentView/components/CommonPreview';
import {
  escapeCSV,
} from '@root/utils/Export';

import Icon from './Default.icon';

const htmlTagsRegex = /(<([^>]+)>)/gi;

const getWordCounts = (document) => {
  if (!document) {
    return null;
  }
  return [{
    title: 'Content',
    value: document.content,
  }];
};

const docsToString = (docs) => {
  return docs.reduce((txt, doc) => {
    const ad = doc.content.replace(htmlTagsRegex, '').trim();
    return `${txt}"${ad}"\r\n\n`;
  }, '');
};

const docsToCsv = (docs) => {
  return docs.reduce((csv, doc) => {
    const ad = doc.content.replace(htmlTagsRegex, '').trim();
    return `${csv}"${escapeCSV(ad)}"\r\n`;
  }, '');
};

export default {
  Icon,
  getWordCounts,
  getWordCountSecondRow: null,
  DocumentEditor,
  DocumentPreview,
  getPdfContent: docsToString,
  getTxtContent: docsToString,
  getCsvContent: docsToCsv,
  getClipboardItem: null,
  stringifyContent: (content) => content.toString().replace(htmlTagsRegex, ''),
};
