import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    maxWidth: 300,
    minWidth: 250,
    height: 200,
    border: `1px solid ${theme.palette.colors.mediumDarkGrey}`,
    borderRadius: '10px',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.colors.lightGrey,
    },
    margin: '0 0.5rem 1rem',
  },
  iconBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inconWrapper: {
    display: 'inline',
    borderRadius: '8px',
    padding: '0.5rem',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '1rem',
    lineJeight: '18px',
  },
  openButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.neutrals600,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 12px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
  rightIcons: {
    display: 'flex',
  },
}));

export default useStyles;
