import React from 'react';
import clsx from 'clsx';

import useStyles from './TemplatePreviewModal.styles';

const FieldNumber = ({ number }) => {
  const classes = useStyles();

  return <div className={clsx(classes.stepCircle)}>{number}</div>;
};

export default FieldNumber;
