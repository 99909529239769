import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { Folder, Person } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import pluralize from 'pluralize';
import clsx from 'clsx';

import useFolders from '@root/resources/folder/useFolders';
import useFiles from '@root/resources/file/useFiles';
import { homeFolder } from '@root/views/Dashboard/helpers';
import ShareGroup from '@root/views/Dashboard/components/ShareFolderButton';
import AuthContext from '@root/resources/auth/auth.context';
import CollapseButton from '@root/components/CollapseButton';
import { getInFileNavCollapseState, setInFileNavCollapseState } from '@root/services/localStorage.service';

import FilesList from './components/FilesList';
import FoldersDropdown from './components/FoldersDropdown';
import useStyles from './InFileSidebar.styles';

const formatNumber = (number) => (number < 100 ? number : '99+');

const InFileSidebar = ({
  file,
  folder: currentFileFolder,
  autoSave = () => {},
  showCollapsedContent = true,
  collapsedClassName = '',
}) => {
  const sidebarRef = useRef();

  const { isLoading: isLoadingFolders, data: foldersData } = useFolders();

  const [
    selectedFolderId,
    setSelectedFolderId,
  ] = useState(currentFileFolder && currentFileFolder._id);
  const [collapsed, setCollapsed] = useState(getInFileNavCollapseState() === 'true');
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  useEffect(() => {
    if (currentFileFolder && currentFileFolder._id) {
      setSelectedFolderId(currentFileFolder._id);
    }
  }, [currentFileFolder]);

  const {
    data: filesData,
  } = useFiles({ folderIds: [selectedFolderId] });

  const toggleCollapsed = () => {
    setInFileNavCollapseState(!collapsed);
    setCollapsed(!collapsed);
  };

  const selectedFolder = useMemo(
    () => (foldersData && foldersData.find((f) => f._id === selectedFolderId)) || {},
    [selectedFolderId, isLoadingFolders],
  );

  const projectTitle = useMemo(() => (selectedFolder.parentFolderId && !isLoadingFolders
    ? foldersData.find((folder) => folder._id === selectedFolder.parentFolderId).title
    : selectedFolder.title), [selectedFolderId, foldersData]);

  const isHomeFolder = useMemo(
    () => foldersData
      && homeFolder(foldersData)._id === selectedFolderId, [selectedFolderId, isLoadingFolders],
  );

  const showShareButton = !selectedFolder?.home;
  const isNoAccess = currentFileFolder
    && !currentFileFolder.unifiedAcl.some((user) => user.userId === currentUser._id);

  if (isNoAccess) {
    return null;
  }

  if ((!currentFileFolder || !selectedFolder._id || isLoadingFolders || !filesData)) {
    if (collapsed) {
      return <div className={classes.collapsedSidebar} />;
    }
    return <div className={classes.sidebarWrap} />;
  }

  if (collapsed) {
    const filesLength = filesData.files.length;
    const pluralizedItemName = pluralize('File', filesLength);
    const currentLocationTooltipCopy = isHomeFolder ? '' : `in ${currentFileFolder.title}`;

    return (
      <div className={clsx(classes.collapsedSidebar, collapsedClassName)}>
        <CollapseButton onClick={toggleCollapsed} />
        {showCollapsedContent && (
          <div className={classes.collapsedSidebarInner}>
            <div className={classes.collapsedMemberCount}>
              <Person />
              <span>{formatNumber(currentFileFolder.unifiedAcl.length)}</span>
            </div>
            <Tooltip title={`${filesLength} ${pluralizedItemName} ${currentLocationTooltipCopy}`}>
              <div className={classes.collapsedFileCount}>
                <Folder />
                <div className={classes.collapsedFileCountWrap}>
                  <span>{formatNumber(filesLength)}</span>
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classes.sidebarWrap}
      ref={sidebarRef}
    >
      <div className={classes.sidebarHeader}>
        <h3 className={classes.projectName}>
          {isHomeFolder ? 'Other' : projectTitle}
          <CollapseButton onClick={toggleCollapsed} rotated />
        </h3>
        <div className={classes.projectTeam}>
          { showShareButton && (
            <ShareGroup
              itemId={selectedFolderId}
              visibleAvatarsNumber={3}
              showFeaturePreview
            />
          )}
        </div>
      </div>
      <div className={classes.sidebarContentsWrap}>
        {!isHomeFolder && (
          <FoldersDropdown
            selectedFolderId={selectedFolderId}
            setSelectedFolderId={setSelectedFolderId}
            foldersList={foldersData}
            autoSave={autoSave}
          />
        )}
        <FilesList
          selectedFolderId={selectedFolderId}
          selectedFileId={file.id || file._id}
          folderName={selectedFolder.title}
          isHomeFolder={isHomeFolder}
          autoSave={autoSave}
          filesData={filesData.files}
          sidebarRef={sidebarRef.current}
        />
      </div>
    </div>

  );
};

export default InFileSidebar;
