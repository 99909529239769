import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Button, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import uiNotificationService from '@root/services/uiNotification.service';
import PublishAdDialog from '@root/components/integrations/GoogleAds/components/PublishAdDialog';
import PublishDocsDialog from '@root/components/integrations/GoogleDocs/components/PublishDocsDialog';
import NewGoogleAdGroupDialog from '@root/components/integrations/GoogleAds/components/NewGoogleAdGroupDialog';
import AuthContext from '@root/resources/auth/auth.context';
import integrationResource from '@root/resources/integrations';
import documentResource from '@root/resources/document';
import useModalState from '@root/utils/hooks/useModalState';
import MenuButton from '@root/components/MenuButton';
import PublishToAkeneoModal from '@root/views/Editor/tools/PublishToAkeneo/PublishToAkeneoModal';
import { escapeText } from '@root/utils/text.utils';

import zapierIcon from './zapier-icon.svg';
import googleAdIcon from './googleAd-icon.svg';
import hootsuiteIcon from './hootsuite-icon.svg';
import googleDocsIcon from './googleDocs-icon.svg';
import akeneoIcon from './akeneo-icon.svg';
import CheckIcon from './CheckIcon';

const HOOTSUITE_INTEGRATION_TYPE = 'hootsuite';

const useStyles = makeStyles(() => ({
  avatarImg: {
    width: 'auto',
    objectFit: 'fill',
  },
  avatar: {
    borderRadius: 0,
  },
  menuItem: {
    overflow: 'visible',
  },
  menuItemDisabled: {
    cursor: 'default',
    pointerEvents: 'all',
    opacity: 0.5,

    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const PublishButton = ({
  document = {},
  publishLog,
  fileId,
  templateType,
  className = '',
  placementVertical = 'bottom',
  placementHorizontal = 'right',
  button,
  openOnHover,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const { data: integrations } = integrationResource.useIntegrations();
  const { mutateAsync: publishDocument } = documentResource.usePublishDocument();
  const { mutateAsync: createIntegration } = integrationResource.useCreateIntegration();
  const [publishGoogleAdsOpen, setPublishGoogleAdsOpen] = React.useState(false);
  const [publishGoogleDocsOpen, setPublishGoogleDocsOpen] = React.useState(false);
  const [createAdGroupOpen, setCreateAdGroupOpen] = React.useState(false);
  const [adGroupCampaignId, setAdGroupCampaignId] = React.useState(null);
  const [adGroupCustomerId, setAdGroupCustomerId] = React.useState(null);
  const [adGroupCustomerManagerId, setAdGroupCustomerManagerId] = React.useState(null);
  const [publishToAkeneoOpen, setPublishToAkeneoOpen] = React.useState(false);

  const anchorOrigin = {
    vertical: placementVertical,
    horizontal: placementHorizontal,
  };
  const transformOrigin = {
    horizontal: 'right',
  };

  const onAddNewAdGroup = ({ customerId, managerCustomerId, campaignId }) => {
    setAdGroupCampaignId(campaignId);
    setAdGroupCustomerId(customerId);
    setAdGroupCustomerManagerId(managerCustomerId);
    setCreateAdGroupOpen(true);
  };

  const onCloseAddGroupAd = () => {
    setCreateAdGroupOpen(false);
    setPublishGoogleAdsOpen(true);
  };

  const {
    publishedToZapier,
    publishedToGoogleAds,
    publishedToGoogleDocs,
    zapierIntegration,
    googleAdsIntegration,
    googleDocsIntegration,
    akeneoIntegration,
  } = React.useMemo(() => {
    let googleDocs;
    let googleAds;
    let zapier;
    let akeneo;
    if (integrations) {
      integrations.forEach((integration) => {
        if (integration.status === 'active') {
          switch (integration.type) {
            case 'zapier':
              zapier = integration;
              break;
            case 'googleAds':
              googleAds = integration;
              break;
            case 'googleDocs':
              googleDocs = integration;
              break;
            case 'akeneo':
              akeneo = integration;
              break;
            default:
              break;
          }
        }
      });
    }

    return {
      publishedToZapier: publishLog && publishLog.find((l) => l.integrationType === 'zapier'),
      publishedToGoogleAds: publishLog && publishLog.find((l) => l.integrationType === 'googleAds'),
      publishedToGoogleDocs: publishLog && publishLog.find((l) => l.integrationType === 'googleDocs'),
      publishedToAkeneo: publishLog && publishLog.find((l) => l.integrationType === 'akeneo'),
      googleAdsIntegration: googleAds,
      zapierIntegration: zapier,
      googleDocsIntegration: googleDocs,
      akeneoIntegration: akeneo,
    };
  }, [publishLog, document, integrations]);

  const [isOpen, /* open */, close] = useModalState();

  const {
    toggles: {
      showHootsuitePublishButton,
      showExternalSourceTooltip,
      disableZapierPublish,
      disableGoogleAdPublish,
      disableGoogleDocsPublish,
      disableAkeneoPublish,
    },
    getPublishMessage,
  } = currentExternalSource;

  const zapierEnabled = !!zapierIntegration;
  const googleAdsEnabled = !!googleAdsIntegration;
  const googleDocsEnabled = !!googleDocsIntegration;
  const akeneoEnabled = !!akeneoIntegration;
  const showGoogleAds = templateType === 'googleAd' && currentUser.permissions.googleAds;
  const showAkeneo = templateType === 'productDescription' && currentUser.permissions.akeneo && currentUser.featureToggles.akeneoCard;

  const showGoogleDocs = currentUser.permissions.googleDocsPublishing;
  const showZapier = currentUser.permissions.zapier;
  const showHootsuite = showHootsuitePublishButton;

  if (!showZapier && !showGoogleAds && !showHootsuite) {
    return null;
  }

  const onZapierPublish = async () => {
    const { isBadRequest } = await publishDocument({
      _id: document._id,
      fileId,
      data: {
        integrationId: zapierIntegration._id,
      },
    });

    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Document has been published to Zapier.');
      close();
    }
  };

  const onHootsuitePublish = async () => {
    const text = getPublishMessage(document);
    const htmlRegExp = /<\/?[^>]+(>|$)/g;

    hsp.composeMessageV2(
      text.replace(htmlRegExp, ''),
    );

    const integration = await createIntegration({
      type: HOOTSUITE_INTEGRATION_TYPE,
      status: 'active',
    });

    await publishDocument({
      _id: document._id,
      fileId,
      data: {
        integrationId: integration._id,
      },
    });
  };

  const onGoogleAdsPublish = async () => {
    setPublishGoogleAdsOpen(true);
    close();
  };

  const onGoogleDocsPublish = async () => {
    setPublishGoogleDocsOpen(true);
    close();
  };

  const onGoogleAdsPublishClose = async () => {
    setPublishGoogleAdsOpen(false);
  };

  const onGoogleDocsPublishClose = async () => {
    setPublishGoogleDocsOpen(false);
  };

  const onAkeneoPublish = async () => {
    setPublishToAkeneoOpen(true);
    close();
  };

  const onAkeneoPublishClose = async () => {
    setPublishToAkeneoOpen(false);
  };

  const isGoogleAdsDisabled = disableGoogleAdPublish || !googleAdsEnabled;
  const isGoogleDocsDisabled = disableGoogleDocsPublish || !googleDocsEnabled;
  const isZapierDisabled = !zapierEnabled || disableZapierPublish;
  const isAkeneoDisabled = !akeneoEnabled || disableAkeneoPublish;

  const actionButton = button || (
    <Button
      size="small"
      color="primary"
      variant="outlined"
      endIcon={isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    >
      Publish
    </Button>
  );

  return (
    <div className={className}>
      <MenuButton
        button={actionButton}
        openOnHover={openOnHover}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {showHootsuite && (
          <MenuItem
            onClick={onHootsuitePublish}
            className={classes.menuItem}
          >
            <ListItemAvatar>
              <>
                <Avatar
                  classes={{ root: classes.avatar, img: classes.avatarImg }}
                  src={hootsuiteIcon}
                />
                {publishedToZapier && <CheckIcon />}
              </>
            </ListItemAvatar>
            <ListItemText
              className={classes.username}
              primary="Publish to Hootsuite"
            />
          </MenuItem>
        )}
        {showZapier && (
          <SimpleTooltip
            content={showExternalSourceTooltip && <ExternalSourceText />}
            interactive
            placement="left"
          >
            <MenuItem
              onClick={!isZapierDisabled && onZapierPublish}
              className={clsx(
                classes.menuItem,
                { [classes.menuItemDisabled]: isZapierDisabled },
              )}
            >
              <ListItemAvatar>
                <>
                  <Avatar
                    className={classes.avatar}
                    src={zapierIcon}
                  />
                  {publishedToZapier && <CheckIcon />}
                </>
              </ListItemAvatar>
              <ListItemText
                className={classes.username}
                primary="Publish to Zapier"
              />
            </MenuItem>
          </SimpleTooltip>
        )}
        {showGoogleAds && (
          <SimpleTooltip
            content={showExternalSourceTooltip && <ExternalSourceText />}
            interactive
            placement="left"
          >
            <MenuItem
              onClick={!isGoogleAdsDisabled && onGoogleAdsPublish}
              className={clsx(
                classes.menuItem,
                { [classes.menuItemDisabled]: isGoogleAdsDisabled },
              )}
            >

              <ListItemAvatar>
                <>
                  <Avatar
                    className={classes.avatar}
                    src={googleAdIcon}
                  />
                  {publishedToGoogleAds && <CheckIcon />}
                </>
              </ListItemAvatar>
              <ListItemText
                className={classes.username}
                primary="Publish to Google Ads"
              />
            </MenuItem>
          </SimpleTooltip>
        )}
        {showAkeneo && (
          <MenuItem
            onClick={!isAkeneoDisabled && onAkeneoPublish}
            className={clsx(
              classes.menuItem,
              { [classes.menuItemDisabled]: isAkeneoDisabled },
            )}
          >
            <ListItemAvatar>
              <>
                <Avatar
                  classes={{ root: classes.avatar, img: classes.avatarImg }}
                  src={akeneoIcon}
                />
                {publishedToGoogleDocs && <CheckIcon />}
              </>
            </ListItemAvatar>
            <ListItemText
              className={classes.username}
              primary="Publish to Akeneo"
            />
          </MenuItem>
        )}
        {showGoogleDocs && (
          <SimpleTooltip
            content={showExternalSourceTooltip && <ExternalSourceText />}
            interactive
            placement="left"
          >
            <MenuItem
              onClick={!isGoogleDocsDisabled && onGoogleDocsPublish}
              className={clsx(
                classes.menuItem,
                { [classes.menuItemDisabled]: isGoogleDocsDisabled },
              )}
            >
              <ListItemAvatar>
                <>
                  <Avatar
                    classes={{ root: classes.avatar, img: classes.avatarImg }}
                    src={googleDocsIcon}
                  />
                  {publishedToGoogleDocs && <CheckIcon />}
                </>
              </ListItemAvatar>
              <ListItemText
                className={classes.username}
                primary="Publish to Google Docs"
              />
            </MenuItem>
          </SimpleTooltip>
        )}
      </MenuButton>
      {publishGoogleAdsOpen && (
        <PublishAdDialog
          documentId={document._id}
          fileId={fileId}
          onClose={onGoogleAdsPublishClose}
          integrationId={googleAdsIntegration?._id}
          onAddNewAdGroup={onAddNewAdGroup}
        />
      )}
      {createAdGroupOpen && (
        <NewGoogleAdGroupDialog
          fileId={fileId}
          campaignId={adGroupCampaignId}
          customerId={adGroupCustomerId}
          managerCustomerId={adGroupCustomerManagerId}
          onClose={onCloseAddGroupAd}
        />
      )}
      {publishGoogleDocsOpen && (
        <PublishDocsDialog
          document={document}
          onClose={onGoogleDocsPublishClose}
          integrationId={googleDocsIntegration?._id}
        />
      )}
      {publishToAkeneoOpen && (
        <PublishToAkeneoModal
          text={escapeText(document.content)}
          unmount={onAkeneoPublishClose}
        />
      )}
    </div>
  );
};

export default PublishButton;
