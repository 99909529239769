import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  successRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.colors.darkGrey,
    maxWidth: 400,
  },
  icon: {
    color: theme.palette.colors.primary,
    height: 100,
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    margin: '24px 0 16px',
  },
  button: {
    marginTop: 16,
  },

}));

export default useStyles;
