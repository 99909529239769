/* eslint-disable import/prefer-default-export */
import EnginesTemplates from '@root/views/FineTunesEngines/components/EnginesTemplates';

function filterFineTunes(currentUser) {
  const enginesTemplatesEntries = Object.entries(EnginesTemplates);

  const featureToggles = {
    Covid: true,
    MenFashion: true,
    WomenFashion: true,
    Technology: true,
    Travel: currentUser.featureToggles.travelFineTune,
    DavinciPlusEngineTemplate: currentUser.featureToggles.davinciPlusEngine,
  };

  return enginesTemplatesEntries
    .filter(([key]) => {
      return featureToggles[key];
    })
    .map(([, value]) => {
      return value;
    });
}

function getFineTunesOptions(currentUser) {
  const filteredEngines = filterFineTunes(currentUser);
  const { permissions } = currentUser;

  return filteredEngines.map((e) => ({
    label: e.title,
    value: e._id,
    isDisabled: !permissions.fineTunes,
  }));
}

export {
  filterFineTunes,
  getFineTunesOptions,
};
