import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  content: {
    minWidth: 650,
  },
  memberRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  memberRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  emailInput: {
    flex: 1,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
}));
