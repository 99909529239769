import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateIntegration() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ type, status, data }) => api.integration.create({ type, status, data }),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries(['integration', response.type]);
        queryClient.invalidateQueries(['get.integrations']);
      },
    },
  );
}
