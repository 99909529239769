export default {
  title: 'Sales Email',
  description: 'Convert customers in one click.',
  leadName: 'Monica',
  yourName: 'Dan',
  companyName: 'Scentive',
  input: [
    {
      title: 'Email Purpose',
      value: 'Selling a newly released perfume',
    },
    {
      title: 'Your Industry',
      value: 'Makeup',
    },
    {
      title: 'Lead Industry',
      value: 'Fashion',
    },
    {
      title: 'Lead Goals',
      value: 'Sell perfume to a bigger audience at a 30% discount',
    },
  ],
  output: {
    templateType: 'salesEmail',
    content: {
      subject: 'Checking in - Scentive Marketing',
      emailBody:
        "Hi Monica,\n\n Wanted to quickly check in and see how your perfume distribution work is coming along. I think Calerbanc's tactics quite unique and you have a lot of potential as an individual. If we have a chance to chat in person we can talk about a 30% affiliate deal! I may be available between 1pm-3pm on Friday the 29th. Let me know if that's possible? Thanks!\n\nBest,\nDan",
    },
  },
  fileData: { leadName: 'Monica', yourName: 'Dan' },
};
