import React from 'react';
import clsx from 'clsx';

import Navbar from '@root/views/Layout/components/Navbar/Navbar';
import NavbarNew from '@root/views/Layout/components/NavbarNew';
import AuthContext from '@root/resources/auth/auth.context';
import NotificationBar from '@root/views/Layout/components/NotificationBar';

import RootLayout from './RootLayout';
import LeftSidebar from './components/LeftSidebar';
import useStyles from './MainLayout.styles';

const MainLayout = ({ children }) => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { newNavBar } = currentUser.featureToggles;

  return (
    <RootLayout>
      <div className={classes.root}>
        <LeftSidebar />
        <div className={classes.main}>
          <NotificationBar />

          {!newNavBar && <Navbar /> }

          {newNavBar && <NavbarNew />}

          <div className={clsx(classes.content)}>
            {children}
          </div>
        </div>

        <div className={classes.mobileError}>
          <div className={classes.mobileErrorText}>
            Please access Copysmith using desktop — mobile
            access is currently unavailable.
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default MainLayout;
