import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import DoubleChevronRight from '@root/assets/double-chevron-right.svg';

const useStyles = makeStyles((theme) => ({
  chevronIcon: {
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    width: theme.spacing(5),
    height: theme.spacing(5),
    lineHeight: '40px',
    display: 'flex',
    userSelect: 'none',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
}));

const CollapseButton = ({ onClick, rotated, className }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.chevronIcon, className, {
        [classes.rotated]: rotated,
      })}
      onClick={onClick}
    >
      <img src={DoubleChevronRight} alt="collapse" />
    </div>
  );
};

export default CollapseButton;
