import React from 'react';
import { Button as MuiButton, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  link: {
    padding: 0,
    margin: 0,
    lineHeight: 'unset',
  },
}));

const Button = ({ variant, disableRipple, onClick, children, className, isLoading, ...props }) => {
  const classes = useStyles();
  const isLink = variant === 'link';
  const isText = variant === 'text';

  return (
    <MuiButton
      className={clsx(classes[variant], className)}
      onClick={onClick}
      variant={isLink ? 'text' : variant}
      disableRipple={(isLink || isText) ? true : disableRipple}
      {...props}
    >
      {isLoading ? <CircularProgress color="inherit" size={24} /> : children}
    </MuiButton>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'link']),
  disableRipple: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  variant: 'contained',
  disableRipple: false,
  isLoading: false,
  onClick: () => {},
};

export default Button;
