import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import {
  signInWithGoogle,
  signInWithMicrosoft,
} from '@root/services/auth.service';
import {
  GoogleSignInButton,
  MicrosoftSignInButton,
  error,
} from '@root/views/Authentication/components/SharedComponents/SharedComponents';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
    minWidth: '30rem',
    height: '18rem',
    borderRadius: theme.border.radius.default,
    alignContent: 'center',
  },
}));

const UpVoteSignupModal = ({ modalOpen, setModalOpen }) => {
  const classes = useStyles();
  const GoogleLogIn = async () => {
    try {
      await signInWithGoogle();
    } catch (e) {
      error(e.message);
    }

    setModalOpen(false);
  };

  const MicrosoftLogin = async () => {
    try {
      await signInWithMicrosoft();
    } catch (e) {
      error(e.message);
    }

    setModalOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={classes.paper}>
          <div
            style={{
              fontSize: '1.25rem',
              margin: '0.625rem 0 -1.675rem 0',
              cursor: 'pointer',
              color: '#535ca5',
            }}
            onClick={() => setModalOpen(false)}
          >
            ×
          </div>
          <div
            style={{
              fontSize: '1rem',
              fontFamily: 'Inter, sans-serif"',
              color: '#535ca5',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '1.5rem',
                fontWeight: 600,
                margin: '1rem 0',
              }}
            >
              Sign up to upvote
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              Join your team to collaborate on Copysmith!
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1.5rem',
              }}
            >
              <GoogleSignInButton
                onClick={GoogleLogIn}
                style={{ margin: '0.5rem 0.675rem' }}
              />
              <MicrosoftSignInButton
                onClick={MicrosoftLogin}
                style={{ margin: '0.5rem 0.675rem' }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default UpVoteSignupModal;
