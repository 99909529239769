import React from 'react';
import { Button, CircularProgress, DialogActions, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';

import useGenerateContentNow from '@root/resources/ai/useGenerateContentNow';

const useStyles = makeStyles((theme) => ({
  preview: {
    border: `1px solid ${theme.palette.colors.grey}`,
    padding: theme.spacing(1, 1.5),
    height: theme.spacing(14),
    overflow: 'auto',
    boxSizing: 'content-box',
    borderRadius: theme.border.radius.default,
    margin: theme.spacing(0, 0, 2, 0),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > button': {
      fontSize: '14px',
      padding: '1px 4px',
    },
  },
  button: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  loadingWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const useContentImprovement = ({
  fileId,
}) => {
  const classes = useStyles();

  const { mutateAsync: generate } = useGenerateContentNow();

  const [isLoading, setIsLoading] = React.useState(false);
  const [content, setContent] = React.useState(null);
  const [generations, setGenerations] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const [templateType, setTemplateType] = React.useState(null);
  const [textToImprove, setTextToImprove] = React.useState(null);

  const promiseResoleRef = React.useRef(null);

  const improveContent = React.useCallback(async (type, text) => {
    setIsLoading(true);

    setTemplateType(type);
    setTextToImprove(text);

    const promise = new Promise((res) => {
      promiseResoleRef.current = res;
    });

    const { results } = await generate({
      data: {
        inputParagraph: text,
      },
      templateType: type,
      fileId,
    });

    if (!results || results.length === 0) {
      promiseResoleRef.current(null);
      setIsLoading(false);
      return null;
    }

    setPage(1);
    setGenerations(results.map((r) => r.content.trim()));

    setIsLoading(false);

    return promise;
  }, []);

  const generateMore = React.useCallback(async () => {
    setIsLoading(true);

    const { results } = await generate({
      data: {
        inputParagraph: textToImprove,
      },
      templateType,
      fileId,
    });

    if (results?.length > 0) {
      setGenerations([...generations, ...results.map((r) => r.content.trim())]);
    }

    setIsLoading(false);
  }, [templateType, generations, textToImprove]);

  const drop = React.useCallback(async () => {
    setGenerations([]);
    promiseResoleRef.current(null);
  }, []);

  const useGeneration = React.useCallback(async () => {
    promiseResoleRef.current(generations[page - 1]);
    setGenerations([]);
  }, [generations, page]);

  const onPageChange = (e, value) => {
    setPage(value);
  };
  const onNextPage = () => {
    setPage(page + 1);
  };
  const onPrevPage = () => {
    setPage(page - 1);
  };

  React.useEffect(() => {
    if (isLoading) {
      setContent((
        <div className={classes.loadingWrap}>
          <CircularProgress />
        </div>
      ));
      return;
    }
    if (generations.length === 0) {
      setContent(null);
      return;
    }

    setContent(
      <>
        <IconButton
          onClick={drop}
          color="default"
          className={classes.closeButton}
          size="small"
        >
          <Close />
        </IconButton>
        <DialogTitle>Generations</DialogTitle>
        <Typography variant="body2" className={classes.preview}>
          {generations[page - 1]}
        </Typography>
        <div className={classes.pagination}>
          <Button
            size="small"
            color="primary"
            onClick={onPrevPage}
            variant="text"
            disabled={page === 1}
            startIcon={<ArrowBack />}
          >
            Previous
          </Button>
          <Pagination
            onChange={onPageChange}
            page={page}
            count={generations.length}
            shape="rounded"
            color="primary"
            size="small"
            hideNextButton
            hidePrevButton
          />
          <Button
            size="small"
            color="primary"
            onClick={onNextPage}
            variant="text"
            disabled={page === generations.length}
            endIcon={<ArrowForward />}
          >
            Next
          </Button>
        </div>
        <DialogActions>
          <Button onClick={useGeneration} className={classes.button}>
            Insert in block
          </Button>
          <Button
            onClick={generateMore}
            className={classes.button}
            variant="outlined"
          >
            Generate more
          </Button>
        </DialogActions>
      </>,
    );
  }, [generations, page, useGeneration, generateMore, isLoading]);

  return {
    content,
    improveContent,
    drop,
  };
};

export default useContentImprovement;
