import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const actions = Object.freeze({
  SELECT_FOLDER: 'SELECT',
  SELECT_PROJECT: 'SELECT_PROJECT',
  SELECT_BOOKMARKS: 'SELECT_BOOKMARKS',
  SELECT_MY_FILES: 'SELECT_MY_FILES',
  SELECT_SHARED_FILES: 'SELECT_SHARED_FILES',
  SELECT_ALL_FILES: 'SELECT_ALL_FILES',
  SELECT_UNCATEGORIZED: 'SELECT_UNCATEGORIZED',
  SELECT_SHARED_UNCATEGORIZED: 'SELECT_SHARED_UNCATEGORIZED',
  SELECT_WORKFLOW: 'SELECT_WORKFLOW',
  SET_VIEW: 'SET_VIEW',
  SET_FILTERS: 'SET_FILTERS',
  SET_SORT: 'SET_SORT',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SELECTED_FILES: 'SET_SELECTED_FILES',
  RENAME_FOLDER: 'RENAME_FOLDER',
});

const selectionTypes = Object.freeze({
  FOLDER: 'FOLDER',
  PROJECT: 'PROJECT',
  MY_FILES: 'MY_FILES',
  ALL_FILES: 'ALL_FILES',
  SHARED_FILES: 'SHARED_FILES',
  UNCATEGORIZED: 'UNCATEGORIZED',
  SHARED_UNCATEGORIZED: 'SHARED_UNCATEGORIZED',
  BOOKMARKS: 'BOOKMARKS',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  WORKFLOW: 'WORKFLOW',
});

const viewTypes = Object.freeze({
  LIST: 'LIST',
  CARD: 'CARD',
});

const initialState = {
  selected: {
    type: selectionTypes.MY_FILES,
    title: 'My Workspace',
  },
  view: viewTypes.LIST,
  filters: {
    folderId: null,
    templateTypes: [],
    workflowStatuses: [],
    liked: null,
    sort: 'updatedOn',
    sortOrder: 'desc',
    searchQuery: '',
  },
  selectedFileIds: [],
};

const DashboardContext = React.createContext();

const dashboardReducer = ({ history }) => {
  const currentUrl = history.location.pathname;
  const navigate = (segment1, segment2) => {
    const url = `/templates/${segment1}${segment2 ? `/${segment2}` : ''}`;
    if (url !== currentUrl) {
      history.push(url, { from: 'dashboard-context' });
    }
  };

  return (state, action) => {
    switch (action.type) {
      case actions.SELECT_FOLDER:
        navigate('folder', action.payload.folderId);
        return {
          ...state,
          selected: {
            type: selectionTypes.FOLDER,
            title: action.payload.title,
          },
          filters: {
            ...initialState.filters,
            folderId: action.payload.folderId,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_PROJECT:
        navigate('project', action.payload.folderId);
        return {
          ...state,
          selected: {
            type: selectionTypes.PROJECT,
            title: action.payload?.title || 'Project',
          },
          filters: {
            ...initialState.filters,
            folderId: action.payload.folderId,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_BOOKMARKS:
        navigate('bookmarks');
        return {
          ...state,
          selected: {
            type: selectionTypes.BOOKMARKS,
            title: 'Bookmarks',
          },
          filters: {
            ...initialState.filters,
            liked: true,
            folderId: null,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_UNCATEGORIZED:
        navigate(`uncategorized_Home_${action.payload.homeFolderId}`);
        return {
          ...state,
          selected: {
            type: selectionTypes.UNCATEGORIZED,
            title: 'Uncategorized',
          },
          filters: {
            ...initialState.filters,
            folderId: action.payload.homeFolderId,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_SHARED_UNCATEGORIZED:
        navigate(`shared_uncategorized_${action.payload.folderIds.join(',')}`);
        return {
          ...state,
          selected: {
            type: selectionTypes.SHARED_UNCATEGORIZED,
            title: 'Uncategorized',
          },
          filters: {
            ...initialState.filters,
            folderIds: action.payload.folderIds,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_WORKFLOW:
        if (!action?.payload?.skipNavigation) {
          navigate('workflow');
        }
        return {
          ...state,
          selected: {
            type: selectionTypes.WORKFLOW,
            title: 'Assigned To Me',
          },
          filters: {
            ...initialState.filters,
            workflowStatuses: action.payload?.shouldApplyFilters ? ['returned', 'needsReview'] : [],
            isAssignedToMeOnly: true,
            folderId: null,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_MY_FILES:
        navigate('my-files');

        return {
          ...state,
          selected: {
            type: selectionTypes.MY_FILES,
            title: 'My Workspace',
          },
          filters: {
            ...initialState.filters,
            folderIds: action?.payload?.folderIds,
          },
          selectedFileIds: [],
        };
      case actions.SELECT_SHARED_FILES:
        navigate('shared');

        return {
          ...state,
          selected: {
            type: selectionTypes.SHARED_FILES,
            title: 'Shared',
          },
          filters: {
            ...initialState.filters,
            folderIds: action.payload.folderIds,
          },
          selectedFileIds: [],
        };

      case actions.SELECT_ALL_FILES:
        navigate('');

        return {
          ...state,
          selected: {
            type: selectionTypes.ALL_FILES,
            title: 'Files',
          },
          filters: {
            ...initialState.filters,
            folderIds: action?.payload?.folderIds || null,
          },
          selectedFileIds: [],
        };

      case actions.SET_VIEW:
        return {
          ...state,
          view: action.payload.view,
          selectedFileIds: [],
        };
      case actions.SET_FILTERS: {
        const isOnWorkflow = state.selected.type === selectionTypes.WORKFLOW;

        if (isOnWorkflow) {
          navigate('my-files');
        }

        return {
          ...state,
          ...(isOnWorkflow && { selected: initialState.selected }),
          filters: {
            ...initialState.filters,
            ...action.payload.filters,
          },
        };
      }
      case actions.SET_SORT:
        return {
          ...state,
          filters: {
            ...state.filters,
            sort: action.payload.sort,
            sortOrder: action.payload.sortOrder,
          },
        };
      case actions.SET_SEARCH_QUERY:
        return {
          ...state,
          selected: {
            type: selectionTypes.SEARCH_RESULTS,
            title: `Results for "${action.payload.searchQuery}"`,
          },
          filters: {
            ...initialState.filters,
            searchQuery: action.payload.searchQuery,
          },
          selectedFileIds: [],
        };
      case actions.SET_SELECTED_FILES:
        return {
          ...state,
          selectedFileIds: action.payload.selectedFileIds,
        };
      default: {
        throw new Error(`Dashboard Context Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

const DashboardProvider = ({ children }) => {
  const history = useHistory();

  const [state, dispatch] = React.useReducer(dashboardReducer({ history }), initialState);

  const value = { state, dispatch };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

const useDashboard = () => {
  const context = React.useContext(DashboardContext);

  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  return context;
};

export {
  DashboardProvider, useDashboard, actions, selectionTypes, viewTypes,
};
