import React from 'react';

import Step from '@root/components/Stepper/Step';

import useStyles from './ChecklistItem.styles';

const ChecklistItem = ({ item }) => {
  const { label, number, completed, onClick } = item;
  const classes = useStyles({ interactive: !completed && onClick });

  return (
    <div
      className={classes.checklistItemRoot}
      onClick={completed ? undefined : onClick}
    >
      <Step
        label={label}
        number={number}
        checked={completed}
      />
    </div>
  );
};

export default ChecklistItem;
