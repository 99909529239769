import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.colors.neutrals600,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContent: {
    display: 'flex',
    gap: 8,
  },
  icon: {
    color: theme.palette.colors.neutrals400,
  },
  chevron: {
    color: theme.palette.colors.neutrals200,
    marginLeft: 8,
  },
}));

export default useStyles;
