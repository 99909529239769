import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  // Remove transform, -webkit-font-smoothing and -moz-osx-font-smoothing for
  // performance improvements.
  content: {
    height: '100vh',
    width: '100%',
    margin: '0px auto 0 auto',
    display: 'flex',
    flexDirection: 'column',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontFamily: "'Inter', sans-serif",
  },
}));
