import { colors } from '@root/material.theme';

const defaultObj = {
  id: 'default',
  leftColor: colors.white,
  middleColor: colors.white,
  rightColor: colors.white,
};
const redColors = {
  leftColor: colors.pink600,
  middleColor: colors.white,
  rightColor: colors.white,
  qualityBgColor: colors.pink200,
  qualityColor: colors.pink600,
};
const yellowColors = {
  leftColor: colors.yellow400,
  middleColor: colors.yellow400,
  rightColor: colors.white,
  qualityBgColor: colors.yellow200,
  qualityColor: colors.yellow600,
};
const greenColors = {
  leftColor: colors.green400,
  middleColor: colors.green400,
  rightColor: colors.green400,
  qualityBgColor: colors.green200,
  qualityColor: colors.green600,
};

const getQualityMap = (config) => ({
  Weak: {
    id: 'Weak',
    description: `For best-quality generations, enter a description of at least ${config.fairThreshold} characters.`,
    ...redColors,
  },
  Fair: {
    id: 'Fair',
    description: 'Extend your description for better quality generations.',
    ...yellowColors,
  },
  Great: {
    id: 'Great',
    description: 'Great description!',
    ...greenColors,
  },
  Long: {
    id: 'Long',
    description: 'Your description is a bit long and could make deterministic generations.',
    ...yellowColors,
    leftColor: colors.yellow400,
    middleColor: colors.yellow400,
    rightColor: colors.yellow400,
  },
  Limit: {
    id: 'Limit',
    description: `Limit of ${config.limit} characters reached.`,
    ...redColors,
    leftColor: colors.pink600,
    middleColor: colors.pink600,
    rightColor: colors.pink600,
  },
  default: defaultObj,
});

export { defaultObj, getQualityMap };
