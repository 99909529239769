import React from 'react';

import { DEPRECATEDInputAtom } from '@root/components/Fields';

// TODO: refactor all superadmin
const StringField = ({
  field: {
    name,
    placeholder,
    required,
    minimum,
    maximum,
  },
  onChange,
  fieldValue,
  disabled,
}) => {
  return (
    <DEPRECATEDInputAtom
      required={required}
      propObject={{
        name,
        type: 'text',
        onChange,
        onBlur: () => null,
        placeholder,
        value: fieldValue,
      }}
      minimum={minimum}
      maximum={maximum}
      disabled={disabled}
    />
  );
};

export default StringField;
