import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import jwtService from '@root/services/jwt.service';
import AuthContext from '@root/resources/auth/auth.context';
import api from '@root/api';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import EditorRow from './EditorRow';

const MembersList = () => {
  const history = useHistory();
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const defaultConfirmModalState = {
    isOpen: false,
    title: '',
    body: '',
    removeUserId: null,
  };
  const [confirmModalState, setConfirmModalState] = useState(defaultConfirmModalState);

  const {
    refetch: reloadTeamMembers,
    data: membersDataAll,
  } = useTeamMembers({ allMembers: true });
  const members = membersDataAll.results.filter((m) => !m.deactivated);

  const isFreePlan = PLANS_IDS.free.includes(currentCompany.billing.currentLimitId);
  const isDeactivateDisabled = isFreePlan
    && members.length >= currentCompany.billing.limits.members;

  const handleRemoveUser = async (removeUserId) => {
    const resp = await api.user.deactivateCompanyMember(removeUserId);

    if (!resp.isBadRequest && removeUserId === currentUser._id) {
      await jwtService.dropJwt();
      history.push('/login');
    }

    await reloadTeamMembers();
  };

  return (
    <>
      <ConfirmationModal
        open={confirmModalState.isOpen}
        onClose={() => { setConfirmModalState(defaultConfirmModalState); }}
        onConfirm={() => handleRemoveUser(confirmModalState.removeUserId)}
        text={confirmModalState.body}
        title={confirmModalState.title}
        confirmButtonText="Confirm"
      />
      {members.map((member) => {
        return (
          <EditorRow
            key={member._id}
            member={member}
            setConfirmModalState={setConfirmModalState}
            reloadTeamMembers={reloadTeamMembers}
            isDeactivateDisabled={isDeactivateDisabled}
          />
        );
      })}
    </>
  );
};

export default MembersList;
