import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    height: 276,
  },
  templateItem: {
    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0px 8px 11px',
    paddingBottom: '11px',
    marginBottom: '11px',

    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,

    '&:first-child': {
      paddingTop: '11px',
      borderTop: `1px solid ${theme.palette.colors.neutrals100}`,
    },
  },
  templateWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  templateText: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    zIndex: 1,
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.colors.neutrals600,
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    height: '32px',
    zIndex: 0,

    marginLeft: '-8px',

    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: '6px',
  },
  emptyViewContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '39px',
    marginBottom: 0,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '24px',
    maxWidth: '607px',
    textAlign: 'center',
  },
  loadingContainer: {
    flex: 1,
    display: 'grid',
    placeItems: 'center',
  },
  iconContainer: {
    height: 24,
    width: 24,
    borderRadius: 12,
    display: 'grid',
    placeItems: 'center',
  },
}));

export default useStyles;
