import React, { useCallback } from 'react';

import Modal from '@root/components/modals/Modal';
import Button from '@root/components/buttons/Button';
import ProgressButton from '@root/components/ProgressButton';

import { useCreateAndExploreContext } from '../../context/CreateAndExplore.context';
import { modalsActions } from '../../context/confirmationModalsReducer';

import useStyles from './RemoveFromLibraryConfirmationModal.styles';

const RemoveFromLibraryConfirmationModal = () => {
  const classes = useStyles();

  const {
    modalsState,
    modalsDispatch,
    removeFromAccount,
    isTemplateProcessing,
  } = useCreateAndExploreContext();

  const { open, templateId } = modalsState.removeFromLibrary;

  const handleModalClose = () => {
    modalsDispatch({
      type: modalsActions.TOGGLE_REMOVE_FROM_LIBRARY_MODAL,
      payload: null,
    });
  };

  const handleRemoveClick = useCallback(async () => {
    await removeFromAccount(templateId);
    handleModalClose();
  }, [templateId, handleModalClose]);

  return (
    <Modal
      title="Remove from My Account"
      open={open}
      onClose={handleModalClose}
      classNames={{ paper: classes.modal }}
    >
      <div className={classes.content}>
        <p className={classes.contentText}>
          By removing this template you’ll be unable to generate content going forward.
          You can always resave this template to your account later.
        </p>
      </div>

      <div className={classes.buttonsContainer}>
        <Button
          variant="text"
          onClick={handleModalClose}
        >
          Cancel
        </Button>

        <ProgressButton
          variant="contained"
          onClick={handleRemoveClick}
          isLoading={isTemplateProcessing}
          size="small"
        >
          {!isTemplateProcessing && 'Remove'}
          {isTemplateProcessing && 'Loading...'}
        </ProgressButton>
      </div>
    </Modal>
  );
};

export default RemoveFromLibraryConfirmationModal;
