import React from 'react';
import CreateIcon from '@material-ui/icons/Create';

const CustomIcon = ({
  color = '#48B0FF',
  size = 12,
}) => (
  <CreateIcon size={size} style={{ color }} />
);

export default CustomIcon;
