import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      width: '100%',
      marginBottom: 24,
    },
    item: {
      border: `1px solid ${theme.palette.colors.grey}`,
      borderRadius: 4,
      '& .MuiFormControlLabel-label': {
        width: '100%',
        paddingLeft: 8,
      },
    },
    creditCardLabel: {
      color: theme.palette.colors.black,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: 8,
      },
    },
  });
});

export default useStyles;
