import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.colors.lightGrey,
    flexGrow: 1,
    overflowY: 'auto',
    padding: '32px 48px',
  },
  title: {
    fontWeight: 600,
    textAlign: 'start',
  },
}));

export default useStyles;
