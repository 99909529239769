import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&&&': {
      height: 24,
      paddingRight: 2,
      fontWeight: 500,
      lineHeight: 0,
      '&:hover': {
        backgroundColor: theme.palette.colors.neutrals50,
        color: theme.palette.colors.black,
      },
      '& path': {
        stroke: 'none',
      },
    },
  },
  buttonWrapper: {
    borderLeft: `1px solid ${theme.palette.colors.neutrals200}`,
    borderRight: `1px solid ${theme.palette.colors.neutrals200}`,
    marginRight: 4,
    padding: '0 4px',
    height: '100%',
  },
  chevron: {
    '&&&': {
      color: theme.palette.colors.neutrals200,
      width: 14,
      height: 14,
      marginLeft: 4,
    },
  },
  icon: {
    color: theme.palette.colors.neutrals400,
    marginRight: 8,
    minWidth: 24,
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: theme.palette.colors.white,
    borderRadius: '6px',
    border: `1px solid ${theme.palette.colors.neutrals200}`,
    marginTop: 10,
    left: 76,
    padding: 4,
    '& button': {
      justifyContent: 'flex-start',
      maxHeight: 32,
      fontWeight: 500,
    },
  },
  plagiarismChecks: {
    marginTop: '1.5rem',
    fontWeight: 'bolder',
    color: theme.palette.colors.pink,
  },
  plagiarismChecksError: {
    fontWeight: 200,
    color: 'red',
  },
  redText: {
    color: 'red',
  },
  modalContent: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '1rem',
  },
  tooltipWrapperPlagiarism: {
    lineHeight: '20px',
  },
}));

export default useStyles;
