import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: ({ color }) => (theme.palette.colors[`${color}Faded`]),
    display: ({ display }) => display,
  },
  content: {
    color: ({ color }) => (theme.palette[color].main),
  },
}));

const Badge = ({ content, color = 'primary', display = 'block' }) => {
  const classes = useStyles({ color, display });
  return (
    <div className={classes.container}>
      <span className={classes.content}>{content}</span>
    </div>
  );
};

export default Badge;
