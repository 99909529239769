import React from 'react';
import { capitalize } from 'lodash';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import authContext from '@root/resources/auth/auth.context';

import useStyles from './OverageInformation.styles';

const OverageInformation = ({
  creditRecord,
  creditLabel,
}) => {
  const { currentCompany } = authContext.useAuth();
  const classes = useStyles();

  const overage = React.useMemo(() => {
    return creditRecord?.overage?.mainCredits || 0;
  }, [creditRecord]);
  const overageFee = React.useMemo(() => {
    const numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    return numberFormatter.format((overage * currentCompany.billing.limits.mainCreditsOveragePrice) / 100);
  }, [overage]);

  if (!currentCompany?.billing.limits.mainCreditsOverage) {
    return null;
  }

  return (
    <Grid
      item
      sm={12}
      md={6}
      lg={overage < 100000 ? 4 : 5}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.title} variant="h6">Overage Information</Typography>
        </Grid>
        {creditRecord?.isLatest && (
          <Grid className={classes.card} container>
            {!creditRecord
              ? (
                <div className={classes.loading}>
                  <CircularProgress size={30} />
                </div>
              )
              : (
                <>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography className={classes.title} variant="body2">
                      {`${capitalize(creditLabel)}s over base plan`}
                    </Typography>
                    <div className={classes.infoWrap}>
                      <Typography className={classes.value} variant="h1">
                        {overage}
                      </Typography>
                      <Typography variant="body2">this month</Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography className={classes.title} variant="body2">Overage fee</Typography>
                    <div className={classes.infoWrap}>
                      <Typography className={classes.value} variant="h1">
                        {`$${overageFee}`}
                      </Typography>
                      <Typography variant="body2">this month</Typography>
                    </div>
                  </Grid>
                </>
              ) }
          </Grid>
        ) }
      </Grid>
    </Grid>
  );
};

export default OverageInformation;
