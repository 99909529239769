import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, CircularProgress, Popover, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { FileCopy, Cached, ThumbDown, ThumbDownOutlined, ThumbUpOutlined, ThumbUp, MoreHoriz, Delete, MenuBook, Fingerprint } from '@material-ui/icons';

import { CampaignIcon, ExpanderIcon, MoreLikeThisIcon, PublishIcon } from './DocumentActions.icons';
import useStyles from './DocumentActions.styles';

const actions = [{
  type: 'copy',
  readOnly: true,
  icon: () => FileCopy,
  title: 'Copy',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'rewrite',
  readOnly: false,
  icon: () => Cached,
  title: 'Rewrite Content',
  helper: ({
    selectedText,
    plainText,
    limits,
  }) => {
    if (limits?.limit === 0) {
      return 'Insufficient words.';
    }
    if (!((selectedText && selectedText.value.length <= 200) || plainText.length <= 200)) {
      return 'Text is too long. Select up to 200 characters to continue.';
    }
    return '';
  },
  disabled: ({
    selectedText,
    plainText,
    limits,
  }) => {
    return !((selectedText && selectedText.value.length <= 200) || plainText.length <= 200)
      || limits?.limit === 0;
  },
  visibleStateTooltip: () => 'Rewrite your copy',
}, {
  type: 'expand',
  readOnly: false,
  icon: () => ExpanderIcon,
  title: 'Expand Content',
  helper: ({
    selectedText,
    plainText,
    limits,
  }) => {
    if (limits?.limit === 0) {
      return 'Insufficient words.';
    }
    if (!((selectedText && selectedText.value.length <= 150) || plainText.length <= 150)) {
      return 'Text is too long. Select up to 150 characters to continue.';
    }
    return '';
  },
  disabled: ({
    selectedText,
    plainText,
    limits,
  }) => {
    return !((selectedText && selectedText.value.length <= 150) || plainText.length <= 150)
      || limits?.limit === 0;
  },
  visibleStateTooltip: () => 'Enhance your copy',
}, {
  type: 'like',
  readOnly: false,
  icon: ({ liked }) => (liked ? ThumbUp : ThumbUpOutlined),
  title: 'Use for future generations',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'dislike',
  readOnly: false,
  icon: ({ disliked }) => (disliked ? ThumbDown : ThumbDownOutlined),
  title: 'Rate the quality of generation',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => 'Rate the quality of generation.',
}, {
  type: 'readability',
  readOnly: true,
  icon: () => MenuBook,
  title: 'Readability score',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'plagiarismCheck',
  readOnly: false,
  icon: () => Fingerprint,
  title: 'Plagiarism Check',
  helper: ({ plagiarismCheckTooltipData }) => plagiarismCheckTooltipData,
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'moreLikeThis',
  readOnly: false,
  icon: () => MoreLikeThisIcon,
  title: 'More Like This',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'campaign',
  readOnly: false,
  icon: () => CampaignIcon,
  title: 'Create a Campaign',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'publish',
  readOnly: false,
  icon: () => PublishIcon,
  title: 'Publish to',
  helper: () => '',
  disabled: () => false,
  visibleStateTooltip: () => '',
}, {
  type: 'delete',
  readOnly: false,
  icon: () => Delete,
  title: 'Delete generation',
  helper: () => '',
  disabled: () => false,
  className: 'redColor',
  visibleStateTooltip: () => '',
}];

const DocumentActions = ({
  children,
  onAction,
  isActionInProgress,
  liked,
  disliked,
  allowedActions = ['copy', 'rewrite', 'expand', 'like', 'dislike', 'readability', 'moreLikeThis', 'campaign', 'delete', 'publish'],
  maxActions = 5,
  plainText,
  selectedText,
  quillRef,
  readOnly,
  plagiarismCheckTooltipData,
  hidden,
  open,
  limits,
}) => {
  const classes = useStyles();

  const [hiddenActionsOpen, setHiddenActionOpen] = React.useState(false);
  const [tooltipHidden, setTooltipHidden] = React.useState(false);
  const hiddenActionsButtonRef = React.useRef();
  const openHiddenActions = () => setHiddenActionOpen(true);
  const closeHiddenActions = () => setHiddenActionOpen(false);

  const onActionClick = (action) => () => {
    onAction(action);
  };
  const onHiddenActionClick = (action) => () => {
    setHiddenActionOpen(false);
    onAction(action);
  };

  const onClickAway = () => {
    setTooltipHidden(true);
    setHiddenActionOpen(false);
  };
  const onMouseEnter = () => {
    setTooltipHidden(false);
  };

  const {
    visibleActions,
    hiddenActions,
  } = React.useMemo(() => {
    const allowedActionsList = actions
      .filter((a) => (allowedActions.includes(a.type) && (readOnly ? a.readOnly : true)));

    const v = allowedActionsList.slice(0, maxActions);
    const h = allowedActionsList.slice(maxActions);

    return {
      visibleActions: v,
      hiddenActions: h,
    };
  }, [readOnly]);

  const renderedActions = React.useMemo(() => {
    const elements = (
      <div>
        {visibleActions.map((a) => {
          const Icon = a.icon({
            liked,
            disliked,
          });
          const helper = a.helper({
            selectedText,
            plainText,
            plagiarismCheckTooltipData,
            limits,
          });
          const tooltip = a.visibleStateTooltip();

          const element = (
            <span>
              <IconButton
                disabled={a.disabled({
                  selectedText,
                  plainText,
                  limits,
                })}
                key={a.type}
                disableRipple
                onClick={onActionClick(a.type)}
                className={clsx(
                  a.className ? classes[a.className] : classes.action,
                  classes.button,
                )}
              >
                <Icon />
              </IconButton>
            </span>
          );

          if (helper || tooltip) {
            return (
              <Tooltip
                interactive
                title={helper || tooltip}
                key={a.type}
              >
                {element}
              </Tooltip>
            );
          }

          return (
            <React.Fragment key={a.type}>
              {element}
            </React.Fragment>
          );
        })}
        {hiddenActions.length > 0 && (
          <>
            <IconButton
              innerRef={hiddenActionsButtonRef}
              onClick={hiddenActionsOpen
                ? closeHiddenActions
                : openHiddenActions}
            >
              <MoreHoriz />
            </IconButton>
            <Popover
              anchorEl={hiddenActionsButtonRef.current}
              open={hiddenActionsOpen}
              onClose={onClickAway}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {hiddenActions.map((a) => {
                const Icon = a.icon({
                  liked,
                  disliked,
                });
                const helper = a.helper({
                  selectedText,
                  plainText,
                  plagiarismCheckTooltipData,
                  limits,
                });

                const element = (
                  <MenuItem
                    className={clsx(
                      a.className ? classes[a.className] : classes.hiddenAction,
                      classes.menuItem,
                    )}
                    onClick={onHiddenActionClick(a.type)}
                    disabled={a.disabled({
                      selectedText,
                      plainText,
                      limits,
                    })}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText>
                      {a.title}
                    </ListItemText>
                  </MenuItem>
                );

                if (helper) {
                  return (
                    <Tooltip title={helper} key={a.type}>
                      {element}
                    </Tooltip>
                  );
                }

                return (
                  <React.Fragment key={a.type}>
                    {element}
                  </React.Fragment>
                );
              })}
            </Popover>
          </>
        )}
      </div>
    );

    return (
      <div>
        {elements}
      </div>
    );
  }, [
    liked,
    disliked,
    hiddenActionsOpen,
    selectedText,
    plainText,
    quillRef,
    allowedActions,
    plagiarismCheckTooltipData,
    limits,
  ]);

  // if (tooltipHidden) {
  //   return children;
  // }

  const content = isActionInProgress
    ? <CircularProgress />
    : renderedActions;

  return (
    <Tooltip
      title={!(tooltipHidden || hidden) && content}
      open={open}
      interactive
      placement="right"
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
      disableFocusListener
      onClose={closeHiddenActions}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </Tooltip>
  );
};

export default DocumentActions;
