import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 8,
  },
  copyText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.primary.main,
    borderRadius: 4,
    color: 'white',
    padding: '8px 12px',
    fontSize: 14,
    lineHeight: '16px',
    display: 'none',
    alignItems: 'center',
    gap: 4,
  },
  copyIcon: {
    cursor: 'pointer',
    color: 'white',
  },
  buttonContainer: {
    marginTop: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  navButton: {
    minWidth: '39%',
  },
  addButton: {
    marginTop: 70,
  },
  title: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.grey.main,
    margin: 0,
  },
  text: {
    padding: 12,
    border: '1px solid #C4C4C4',
    resize: 'none',
    whiteSpace: 'break-spaces',
    backgroundColor: '#fff',
    width: '100%',
    height: 300,
    maxHeight: 300,
    overflow: 'auto',
    borderRadius: 4,
    cursor: 'pointer',
    position: 'relative',
    fontSize: 14,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.faded,
      color: 'rgba(82, 58, 231, 0.5)',
      border: `1px solid ${theme.palette.primary.main}`,

      '& > div': {
        display: 'flex',
      },
    },
  },
  dotIndicatorContainer: {
    marginTop: 19,
    minWidth: '22%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
