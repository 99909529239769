import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api/newApi';

const createAdGroup = async ({
  customerId,
  managerCustomerId,
  campaignId,
  name,
  keywords,
}) => {
  const res = await api.googleAd.createAdGroup({
    name,
    campaignId,
    managerCustomerId,
    customerId,
    keywords,
  });

  return res;
};

export default function useCreateAdGroup() {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => createAdGroup(data),
    {
      onSuccess: (data, variables) => {
        const { customerId, campaignId } = variables;

        queryClient.invalidateQueries(['googleAds.getAdGroups', customerId, campaignId]);
      },
    },
  );
}
