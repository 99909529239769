import React from 'react';

import RemoveFromLibraryConfirmationModal from './RemoveFromLibraryConfirmationModal';
import SaveConfirmationModal from './SaveConfirmationModal';
import TemplatePreviewModal from './TemplatePreviewModal';
import UploadConfirmationModal from './UploadConfirmationModal';
import RemoveConfirmationModal from './RemoveConfirmationModal';
import CreateModal from './CreateModal';

const CreateAndExploreModals = () => {
  return (
    <>
      <RemoveConfirmationModal />
      <RemoveFromLibraryConfirmationModal />
      <SaveConfirmationModal />
      <TemplatePreviewModal />
      <UploadConfirmationModal />
      <CreateModal />
    </>
  );
};

export default CreateAndExploreModals;
