import React from 'react';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useDeleteCatalogItems from '@root/resources/catalogItem/useDeleteCatalogItems';
import uiNotificationService from '@root/services/uiNotification.service';

const DeleteProductModal = ({ catalogId, onClose, resetSelectedItems, selectedItemIds }) => {
  const { mutateAsync: deleteCatalogItems } = useDeleteCatalogItems();

  const handleDeleteProductClick = async () => {
    const response = await deleteCatalogItems({ catalogId, catalogItemIds: selectedItemIds });

    if (response.isBadRequest) {
      return;
    }

    uiNotificationService.showSuccessMessage('Products deleted.');
    resetSelectedItems();
    onClose();
  };

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={async () => {
        await handleDeleteProductClick();
      }}
      title="Delete Products"
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      negative
    >
      {`Are you sure you want to delete ${selectedItemIds.length} products?`}
    </ConfirmationModal>
  );
};

export default DeleteProductModal;
