import { useMutation } from 'react-query';

import api from '@root/api';

const publishBulk = async ({
  productCodes,
  locale,
  channels,
  field,
}) => {
  const products = await api.akeneo.publishBulkJob({
    productCodes,
    locale,
    channels,
    field,
  });

  return products;
};

export default function usePublishBulk() {
  return useMutation(
    ({
      productCodes,
      locale,
      channels,
      field,
    }) => publishBulk({
      productCodes,
      locale,
      channels,
      field,
    }),
  );
}
