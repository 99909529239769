import { merge } from "lodash";

export type ConfigType = {
  apiUrl: string;
  authUrl: string;
  integrationUrls: {
    fraseUrl: string;
    googleAdUrl: string;
    googleDocsUrl: string;
    shopifyUrl: string;
    bigCommerceUrl: string;
    akeneoUrl: string;
  };
};

const selectConfig = (env: string, external: boolean): ConfigType => {
  if (env === "production") {
    return {
      apiUrl: "https://new-api.copysmith.ai",
      authUrl: "https://new-api.copysmith.ai/auth",
      integrationUrls: {
        fraseUrl: "https://new-api.copysmith.ai/apps/frase",
        googleAdUrl: "https://new-api.copysmith.ai/apps/googleAds",
        googleDocsUrl: "https://new-api.copysmith.ai/apps/googleDocs",
        shopifyUrl: "https://new-api.copysmith.ai/apps/shopify",
        bigCommerceUrl: "https://new-api.copysmith.ai/apps/bigCommerce",
        akeneoUrl: "https://new-api.copysmith.ai/apps/akeneo",
      },
    };
  }

  if (env === "staging") {
    return {
      apiUrl: "https://staging-new-api.copysmith.ai",
      authUrl: "https://staging-new-api.copysmith.ai/auth",
      integrationUrls: {
        fraseUrl: "https://staging-new-api.copysmith.ai/apps/frase",
        googleAdUrl: "https://staging-new-api.copysmith.ai/apps/googleAds",
        googleDocsUrl: "https://staging-new-api.copysmith.ai/apps/googleDocs",
        shopifyUrl: "https://staging-new-api.copysmith.ai/apps/shopify",
        bigCommerceUrl: "https://staging-new-api.copysmith.ai/apps/bigCommerce",
        akeneoUrl: "https://staging-new-api.copysmith.ai/apps/akeneo",
      },
    };
  }

  if (external) {
    return {
      apiUrl: "http://localhost:4000/",
      authUrl: "http://localhost:4005/auth",
      integrationUrls: {
        fraseUrl: "http://localhost:4002/apps/frase",
        googleAdUrl: "http://localhost:4003/apps/googleAds",
        googleDocsUrl: "http://localhost:4007/apps/googleDocs",
        shopifyUrl: "http://localhost:4004/apps/shopify",
        bigCommerceUrl: "http://localhost:4008/apps/bigCommerce",
        akeneoUrl: "http://localhost:4009/apps/akeneo",
      },
    };
  }

  return {
    apiUrl: "http://localhost:4000/",
    authUrl: "http://localhost:4005/auth",
    integrationUrls: {
      fraseUrl: "http://localhost:4002/",
      googleAdUrl: "http://localhost:4003/",
      googleDocsUrl: "http://localhost:4007/",
      shopifyUrl: "http://localhost:4004/",
      bigCommerceUrl: "http://localhost:4008/apps/bigCommerce",
      akeneoUrl: "http://localhost:4009/apps/akeneo",
    },
  };
};

const getConfig = (env: string, external: boolean, configOverride: ConfigType): ConfigType => {
  const config = selectConfig(env, external);

  if (configOverride) {
    return merge(config, configOverride);
  }

  return config;
};

export default {
  getConfig,
};
