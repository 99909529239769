import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#a)"
      >
        <path d="M8 18a1.333 1.333 0 1 0 0-2.667A1.333 1.333 0 0 0 8 18ZM15.333 18a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM15.333 15.333H8V6H6.667" />
        <path d="M8 7.333 17.333 8l-.666 4.667H8" />
      </g>
    </svg>
  );
};
