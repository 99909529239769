import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined';
import clsx from 'clsx';
import { pickBy } from 'lodash';

import api from '@root/api';
import AuthContext from '@root/resources/auth/auth.context';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import hubspotService from '@root/services/hubspot.service';
import papService from '@root/services/pap.service';
import config from '@root/config';
import billingResource from '@root/resources/billing';
import { RadioButtonField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import Loading from '@root/components/Loading/Loading';
import uiNotificationService from '@root/services/uiNotification.service';
import { filterActivePlans, getMonoPeriod, PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import apiKeyResource from '@root/resources/apiKey';
import useIntegrations from '@root/resources/integrations/useIntegrations';

import PaymentDetails from './components/PaymentDetails';
import NavHeader from './components/NavHeader';
import Success from './components/Success';
import PlanDetails from './components/PlanDetails';
import constants from './constants';
import useStyles from './Checkout.styles';

const Checkout = ({ location }) => {
  const {
    state: {
      plan: initialPlan,
      selfServePipelineData,
      proratedWords,
    },
  } = location;
  const [period, setPeriod] = useState(initialPlan.period);

  const isSelfServeEnterprise = initialPlan.rank === 400;

  const classes = useStyles();
  const formRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const { currentUser, fetchAuthData, setShowCredits } = AuthContext.useAuth();
  const { invalidateLimits } = billingResource.useInvalidateLimits();
  const { data: billingData, refetch: refetchBillingInfo } = billingResource.useBillingInfo();
  const { refereeCredit = 0 } = billingData || {};
  const { refetch: refetchApiKeys } = apiKeyResource.useApiKeys();
  const { refetch: refetchIntegrations } = useIntegrations();

  const {
    data: pricingData = { plans: [] },
    isLoading, refetch: refetchPricingData,
  } = billingResource.usePricing();
  const activePlans = filterActivePlans(pricingData.plans, currentUser);
  const plan = activePlans
    .find((p) => (
      p.name === initialPlan.name
        && p.period === period
    ));

  const monoPeriod = React.useMemo(() => getMonoPeriod(pricingData.plans), [pricingData]);

  const hasSavedCard = !!billingData.paymentMethod;

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [useSavedCard, setUseSavedCard] = useState(hasSavedCard);

  const [method, setMethod] = useState('card');
  const [additionalMethods, setAdditionalMethods] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSuccess = paymentIntent && paymentIntent.status === 'succeeded';

  const march2023Unlimited = [
    ...PLANS_IDS.march2023StarterAnnual,
    ...PLANS_IDS.march2023Pros,
  ];

  const handleMethodChange = (e) => {
    setMethod(e.target.value);
  };

  const createSubscription = async () => {
    if (subscription) {
      return subscription;
    }

    const papCookie = papService.retrieveCookie();

    let subscriptionResponse;
    try {
      subscriptionResponse = await api.billing.createSubscription({
        price: plan._id,
        papCookie,
        promoId: promotion?.id,
      });
      setSubscription(subscriptionResponse);
    } catch (error) {
      uiNotificationService.showErrorMessage('Something went wrong. Please try again');
    }

    return subscriptionResponse;
  };

  const handleSelfServeEnterprise = async (values, cardElement) => {
    if (!useSavedCard) {
      const { name, email, country, address } = values;
      const paymentMethodData = {
        card: cardElement,
        billing_details: {
          name,
          email,
          address: { ...pickBy(address, Boolean), country },
        },
        type: 'card',
      };

      const { error, paymentMethod } = await stripe.createPaymentMethod(paymentMethodData);
      if (error) {
        if (formRef.current) {
          formRef.current.setFieldError('cardNumber', error.message);
        }
        setIsSubmitting(false);
        return { success: false };
      }

      const { isBadRequest } = await api.billing.updateCard({
        paymentMethod: paymentMethod.id,
      });
      if (isBadRequest) {
        if (formRef.current) {
          formRef.current.setFieldError('cardNumber', 'We failed to process payment with this method.');
        }
        setIsSubmitting(false);
        return { success: false };
      }
    }

    if (billingData.planName === 'free') {
      const { isBadRequest, paid, clientSecret } = await createSubscription();

      if (isBadRequest) {
        return { success: !isBadRequest };
      }

      const { name, email, country, address } = values || {};
      const paymentMethod = useSavedCard
        ? undefined
        : {
          card: cardElement,
          billing_details: {
            name,
            email,
            address: { ...pickBy(address, Boolean), country },
          },
          type: 'card',
        };

      const cardData = paid ? await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethod,
      }) : await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      });

      const { error } = cardData;
      if (error) {
        if (formRef.current) {
          formRef.current.setFieldError('cardNumber', error.message);
        }
        return { success: false };
      }

      return { success: true };
    }

    const { isBadRequest } = await api.billing.updateSubscription({
      price: plan._id,
      promoId: promotion?.id,
    });

    return { success: !isBadRequest };
  };

  const handleSelfServeEnterpriseByWalletService = async () => {
    const { isBadRequest, paid, clientSecret } = billingData.planName === 'free'
      ? await createSubscription()
      : await api.billing.updateSubscription({
        price: plan._id,
        promoId: promotion?.id,
      });

    if (isBadRequest) {
      return { success: !isBadRequest };
    }

    return { clientSecret, paid };
  };

  const handleSubmit = async (values, cardElement) => {
    setIsSubmitting(true);

    if (isSelfServeEnterprise) {
      const { success } = await handleSelfServeEnterprise(values, cardElement);
      if (!success) {
        setIsSubmitting(false);
        return;
      }
      setPaymentIntent({ status: 'succeeded' });
      return;
    }

    const subscriptionData = await createSubscription();

    if (!subscriptionData) {
      setIsSubmitting(false);
      return;
    }

    const { clientSecret, paid } = subscriptionData;

    // case when 100% promo is applied and total = 0
    if (paid) {
      if (useSavedCard) {
        setPaymentIntent({ status: 'succeeded' });
        setIsSubmitting(false);
        return;
      }

      const { name, email, country, address } = values;
      const paymentMethod = {
        card: cardElement,
        billing_details: {
          name,
          email,
          address: { ...pickBy(address, Boolean), country },
        },
      };
      const setupData = await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethod,
      });

      const { error, setupIntent } = setupData;
      if (error) {
        if (formRef.current) {
          formRef.current.setFieldError('cardNumber', error.message);
        }
        setIsSubmitting(false);
        return;
      }

      setPaymentIntent(setupIntent);
      setIsSubmitting(false);
      return;
    }

    const { name, email, country, address } = values || {};
    const paymentMethod = useSavedCard
      ? undefined
      : {
        card: cardElement,
        billing_details: {
          name,
          email,
          address: { ...pickBy(address, Boolean), country },
        },
      };

    const paymentData = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod,
    });

    const { error, paymentIntent: intent } = paymentData;
    if (error) {
      if (formRef.current) {
        formRef.current.setFieldError('cardNumber', error.message);
      }
      setIsSubmitting(false);
      return;
    }

    setPaymentIntent(intent);
    setIsSubmitting(false);
  };

  const handleSubscribe = async () => {
    if (useSavedCard) {
      handleSubmit();
      return;
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const payWithWalletService = async (e) => {
    const subscriptionData = !isSelfServeEnterprise
      ? await createSubscription()
      : await handleSelfServeEnterpriseByWalletService();

    if (!subscriptionData) {
      return;
    }

    const { clientSecret, paid } = subscriptionData;
    let intent;

    if (paid) {
      const setupData = await stripe.confirmCardSetup(
        clientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false },
      );

      const { error, setupIntent } = setupData;
      if (error) {
        e.complete('fail');
        uiNotificationService.showErrorMessage(error.message);
        return;
      }

      intent = setupIntent;
    } else {
      const paymentData = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false },
      );
      const { error, paymentIntent: newPaymentIntent } = paymentData;

      if (error) {
        e.complete('fail');
        uiNotificationService.showErrorMessage(error.message);
        return;
      }

      intent = newPaymentIntent;
    }

    e.complete('success');
    setPaymentIntent(intent);

    if (intent.status === 'requires_action') {
      stripe.confirmCardPayment(clientSecret);
    }
  };

  const clearPromo = () => {
    setPromotion(null);
  };

  const contactEmail = () => {
    window.location = `mailto:${config.contactSupportEmail}`;
  };

  const handleSavedCardChange = () => {
    setUseSavedCard(false);
  };

  const trackSuccess = async () => {
    const info = await api.billing.getInfo();
    const { priceId, plan: productId, pastInvoices } = info;
    const total = pastInvoices[0].amount_due;

    eventsAggregator.publish(
      EVENT_NAMES.PURCHASE_COMPLETED,
      {
        priceId,
        productId,
        total,
        email: currentUser.email,
      },
    );
  };

  const refetchData = () => {
    setTimeout(() => {
      if (march2023Unlimited.includes(plan._id)) {
        setShowCredits(false);
      }
      refetchApiKeys();
      refetchIntegrations();
      invalidateLimits();
      refetchBillingInfo();
      refetchPricingData();
      fetchAuthData();
    }, constants.RESUBSCRIBE_TIMEOUT);
  };

  useEffect(() => {
    if (isSuccess) {
      window.history.replaceState({}, document.title);
      refetchData();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (stripe && plan) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: plan.name,
          amount: plan.amount + refereeCredit,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['link'],
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          const methods = Object.keys(result).filter((m) => result[m]);
          setAdditionalMethods(methods);
        }
      });

      pr.on('paymentmethod', payWithWalletService);
    }
  }, [stripe, plan, refereeCredit]);

  useEffect(() => {
    if (promotion && paymentRequest) {
      const discount = (plan.amount * promotion.percentOff) / 100 + promotion.amountOff;
      paymentRequest.update({
        total: {
          label: plan.name,
          amount: plan.amount - discount + refereeCredit,
        },
      });

      if (paymentRequest.hasRegisteredListener('paymentmethod')) {
        paymentRequest.off('paymentmethod');
      }
      paymentRequest.on('paymentmethod', payWithWalletService);
    }
  }, [promotion]);

  useEffect(() => {
    setPromotion(null);
  }, [plan]);

  if (isLoading) {
    return <Loading />;
  }

  if (!plan) {
    return <Redirect to="/" />;
  }

  if (!stripe || !elements) return null;

  if (isSuccess) {
    trackSuccess();

    return (
      <div className={classes.root}>
        <NavHeader />
        <div className={classes.successWrapper}>
          <Success />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <NavHeader />

      <div className={classes.main}>
        <div className={classes.side}>
          <div className={classes.contentWrapper}>
            <div className={classes.title}>
              {useSavedCard ? 'Payment details' : 'Add your payment information'}
            </div>
            <PaymentDetails
              formRef={formRef}
              useSavedCard={useSavedCard}
              onSavedCardChange={handleSavedCardChange}
              onSubmit={handleSubmit}
              method={method}
              onMethodChange={handleMethodChange}
              additionalMethods={additionalMethods}
              billingData={billingData}
            />
          </div>
          <div className={classes.needHelp}>
            Need help?
            <ContactSupportIcon />
            <span onClick={contactEmail}>
              Email
            </span>
            or
            <span onClick={hubspotService.show}>
              Contact support
            </span>
          </div>
        </div>

        <div className={clsx(classes.side, classes.rightSide)}>
          <div className={classes.contentWrapper}>
            <div className={classes.title}>
              Review your plan
            </div>
            {!monoPeriod && !isSelfServeEnterprise && (
              <RadioButtonField
                row
                className={classes.radioGroup}
                options={[
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Yearly', value: 'yearly' },
                ]}
                value={period}
                onChange={(e) => { setPeriod(e.target.value); }}
              />
            )}
            <PlanDetails
              plan={plan}
              promotion={promotion}
              onPromoClear={clearPromo}
              onPromoApply={setPromotion}
              refereeCredit={refereeCredit}
              isSelfServeEnterprise={isSelfServeEnterprise}
              selfServePipelineData={selfServePipelineData}
              proratedWords={proratedWords}
            />
            {method === 'card' ? (
              <Button
                disabled={isSubmitting}
                fullWidth
                className={classes.button}
                onClick={handleSubscribe}
              >
                {isSubmitting ? 'Processing...' : 'Subscribe'}
              </Button>
            ) : paymentRequest && (
              <PaymentRequestButtonElement
                className={classes.button}
                options={{ paymentRequest }}
              />
            )}

            <div className={classes.disclaimer}>
              By confirming your subscription, you allow Copysmith AI to charge your card
              for this payment and future payments in accordance with their terms.
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Checkout;
