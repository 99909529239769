import React from 'react';

const Unchecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
    >
      <rect
        width="15"
        height="15"
        x=".5"
        y=".5"
        stroke="#C4C4C4"
        rx="7.5"
      />
    </svg>
  );
};

export default Unchecked;
