import { HttpClient } from "../../HttpClient";
import { RequestOptions } from "../../RequestOptions";

export default class GoogleDocs {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async getDocuments(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/documents", data);

    return result;
  } 
  
  public async insertToDocument(documentId: string, data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/documents/${documentId}`, data);

    return result;
  } 
  
  public async createDocument(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/documents", data);

    return result;
  }
}