import { templateCategories, viewTypes } from '@root/views/Community/Community.constants';

const communityActions = Object.freeze({
  CHANGE_TEMPLATES_CATEGORY: 'CHANGE_TEMPLATES_CATEGORY',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  CHANGE_VIEW_TYPE: 'CHANGE_VIEW_TYPE',
});

const initialState = {
  templatesCategory: templateCategories.COMMUNITY,
  viewType: viewTypes.LIST,
  searchQuery: '',
};

const communityReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case communityActions.CHANGE_TEMPLATES_CATEGORY:
        return {
          ...state,
          templatesCategory: action.payload,
        };
      case communityActions.SET_SEARCH_QUERY:
        return {
          ...state,
          searchQuery: action.payload,
        };
      case communityActions.CHANGE_VIEW_TYPE:
        return {
          ...state,
          viewType: action.payload,
        };
      default: {
        throw new Error(`Community Context Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

export { communityReducer, communityActions, initialState };
