import React from 'react';

import MainLayout from '@root/views/Layout';
import authContext from '@root/resources/auth/auth.context';

import Header from './components/Header';
import TemplateList from './components/TemplateList';
import Modals from './components/modals/Modals';
import { TemplatesDashboardProvider } from './TemplatesDashboard.context';
import useStyles from './TemplatesDashboard.styles';
import InitialModal from './components/InitialModal';

const TemplatesDashboard = () => {
  const classes = useStyles();
  const { currentUser } = authContext.useAuth();

  const showInitialModal = currentUser.onboarding?.items?.walkthrough
    && !currentUser.onboarding.items.walkthrough.completed
    && !currentUser.featureToggles.newEditor;

  return (
    <MainLayout>
      <TemplatesDashboardProvider>
        <div className={classes.dashboard}>
          <Header />
          <TemplateList />
        </div>
        <Modals />
        {showInitialModal && <InitialModal />}
      </TemplatesDashboardProvider>
    </MainLayout>
  );
};

export default TemplatesDashboard;
