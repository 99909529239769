import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rightPanelWrapper: {
    width: 326,
    marginTop: 32,
    overflow: 'visible',
  },
  buttonGroup: {
    width: '100%',
  },
  tab: {
    textTransform: 'none',
    flexBasis: 'auto',
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 72,
    borderRadius: 6,
    minHeight: 'auto',
    color: 'black',
  },
  tabs: {
    justifyContent: 'space-between',
    minHeight: 'auto',
    marginBottom: 24,
  },
  tabIndicator: {
    display: 'none',
  },
  tabActive: {
    color: 'rgba(82, 58, 231, 0.05)',
    backgroundColor: 'rgba(82, 58, 231, 0.05)',
  },
  button: {
    display: 'flex',
    width: '100%',
    marginBottom: 8,
  },
  upgrade: {
    paddingTop: 16,
    margin: -8,
    color: theme.palette.warning.main,
    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: '.8',
      zIndex: -1,
      color: '#000',
    },
  },
  upgradeLink: {
    textDecoration: 'underline',
  },
  cta: {
    margin: '8px 0',
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  settingsButton: {
    marginLeft: 8,
  },
  buttonsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    width: '10%',
    textAlign: 'center',
    alignSelf: 'center',
    color: '#523AE7',
    fontSize: 14,
    fontWeight: 500,
  },
  closeButton2: {
    color: theme.palette.colors.darkGrey,
  },
  bottomButtonsWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% + 16px)',
    marginTop: 16,
    '& > :not(:last-child)': {
      marginRight: 16,
    },
  },
}));

export default useStyles;
