import * as Yup from 'yup';

import config from '@root/config';

const isDev = config.env === 'development';

export const getValidationSchema = (inputs) => [
  Yup.object().shape({
    title: Yup
      .string()
      .required('Title is required'),
    description: Yup
      .string(),
  }),
  Yup.object().shape({
    prompt: Yup
      .string()
      .required('Prompt example is required')
      .test(
        'prompt',
        'Prompt must contain at least one occurrence of each input',
        (value) => inputs.every((input) => value.includes(`{${input}}`)),
      ),
  }),
];

export const initialValues = isDev ? {
  title: 'Custom Template',
  description: 'Template description',
  prompt: 'Write a {something} about {topic}',
} : {
  title: '',
  description: '',
  prompt: '',
};

export const pageText = [{
  title: 'Create new content generator',
  description: 'Give your generator a title and description. The title and description will help the Copysmith team in the approval process.',
}, {
  title: 'Add prompt & inputs',
  description: 'Now we build the guts of your content generator. Below you\'ll see an area to add inputs and an area to add your prompt.',
}, {
  title: 'Experiments',
  description: 'Experiments are the results of your content in action. Make adjustments to your settings or fields to get the result you want.',
}];

export const PROMPT_MAX_LENGTH = 300;
