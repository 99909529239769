import React from 'react';
import { InputLabel, InputLabelProps, TooltipProps } from '@material-ui/core';

import TooltipIcon from '@root/components/tooltips/TooltipIcon';

import useStyles from './FieldLabel.styles';

interface FieldLabelProps extends InputLabelProps {
  label?: string;
  name?: string;
  labelTooltip?: string | React.ReactNode;
  isControl?: boolean;
  className?: string;
  required?: boolean;
  optional?: boolean;
  tooltipProps?: TooltipProps;
}

const FieldLabel = ({
  name, label, labelTooltip, tooltipProps, required, optional, isControl, className,
}: FieldLabelProps) => {
  const classes = useStyles();

  if (!label) {
    return null;
  }

  const labelContent = (
    <>
      <span className={className}>{label}</span>
      {required && <span className={classes.required}> *</span>}
      {optional && <span className={classes.optional}> (optional)</span>}
      {labelTooltip && (
        <TooltipIcon
          className=""
          content={labelTooltip}
          tooltipProps={tooltipProps}
          margin={4}
        />
      )}
    </>
  );

  if (isControl) {
    return labelContent;
  }

  return (
    <InputLabel htmlFor={name}>
      {labelContent}
    </InputLabel>
  );
};

FieldLabel.defaultProps = {
  label: null,
  name: undefined,
  labelTooltip: undefined,
  required: false,
  optional: false,
  isControl: false,
  className: undefined,
};

export default FieldLabel;
