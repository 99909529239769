import React from 'react';
import clsx from 'clsx';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './WorkflowStatus.styles';

const getWorkflowStatusProps = (status) => {
  switch (status) {
    case 'needsReview':
      return {
        label: 'Needs review',
        tooltipText: 'Assigned to team for review',
      };
    case 'returned':
      return {
        label: 'Returned',
        tooltipText: 'Returned by assigned reviewer',
      };
    case 'approved':
      return {
        label: 'Approved',
        tooltipText: 'Approved by reviewer',
      };
    default:
      return {
        label: '',
        tooltipText: '',
      };
  }
};

const WorkflowStatus = ({ status, small = false, isDisabled }) => {
  const { currentUser } = AuthContext.useAuth();

  const { newFilesPage } = currentUser.featureToggles;

  const classes = useStyles({ newFilesPage });
  const statusProps = getWorkflowStatusProps(status);

  if (!statusProps.tooltipText) {
    return statusProps?.label || <span className={classes.dash}>—</span>;
  }

  return (
    <SimpleTooltip
      content={statusProps.tooltipText}
    >
      <div className={clsx(
        classes.root,
        classes[status],
        {
          [classes.small]: small,
          [classes.disabled]: isDisabled,
        },
      )}
      >
        {statusProps.label}
      </div>
    </SimpleTooltip>
  );
};

export default WorkflowStatus;
