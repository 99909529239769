import React, { useState } from 'react';

import Modal from '@root/components/modals/Modal';

import PageEnumProject from './PageEnumProject';
import useStyles from './NewProjectModal.styles';
import NewProjectForm from './NewProjectForm';
import NewProjectPresetForm from './NewProjectPresetForm';

const NewProjectModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const [page, setPage] = useState(PageEnumProject.INITIAL);
  const [folderName, setFolderName] = useState('');

  const isInitialPage = page === PageEnumProject.INITIAL;
  const isPresetsPage = page === PageEnumProject.PRESETS;

  const handleModalClose = () => {
    setPage(PageEnumProject.INITIAL);
    closeModal?.();
  };

  return (
    <Modal
      open={modalOpen}
      title={isInitialPage ? 'New project' : 'Project settings'}
      subtitle={
        isInitialPage
          ? ''
          : (
            <span>
              When creating new files within this project,
              the input fields will be auto-filled according
              to the settings you have chosen for the project.
              <span className={classes.redText}> If left blank, Copysmith will use your Profile defaults.</span>
            </span>
          )
      }
      onClose={handleModalClose}
      classNames={{ paper: classes.paper }}
    >
      {isInitialPage && (
        <NewProjectForm
          setPage={setPage}
          setFolderName={setFolderName}
          classes={classes}
          closeModal={closeModal}
        />
      )}

      {isPresetsPage && (
        <NewProjectPresetForm
          classes={classes}
          closeModal={handleModalClose}
          title={folderName}
        />
      )}
    </Modal>
  );
};

export default NewProjectModal;
