import { useMutation } from 'react-query';

import api from '@root/api';

export default function useUploadFile() {
  return useMutation(async ({ file, templateType }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templateType', templateType);
    const shouldDisableNotification = (errors) => {
      const fileErrors = errors?.filter((e) => e.file || e.cta) || [];
      return fileErrors.length > 0;
    };

    const response = await api.bulkGeneration.uploadFile(formData, {
      disableFirstErrorNotification: shouldDisableNotification,
    });

    return response;
  });
}
