import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: theme.palette.colors.error,
  },

  mappingContainer: {
    flex: 1,
    height: '100%',
    maxHeight: '100%',
    maxWidth: 587,
    padding: '24px 32px',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  titleContainer: {
    marginBottom: 16,
  },

  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals400,
  },

  validationLoaderContainer: {
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    display: 'grid',
    position: 'absolute',
    placeItems: 'center',
    left: '-1px',
    top: '-1px',
    backgroundColor: 'rgba(255,255,255,0.7)',
    backdropFilter: 'blur(1.5px)',
    borderRadius: 6,
    border: `1px solid ${theme.palette.colors.purple400}`,

    '& $selected': {
      border: '1px solid transparent',
    },
  },
  loader: {
    color: '#4D41F1',
  },
  errorMessage: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.pink600,
    marginTop: 16,
  },
  columnsContainer: {
    overflow: 'auto',
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(166px, 1fr))',
    gridAutoRows: 'max-content',
    gap: 12,

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  column: {
    position: 'relative',
    cursor: 'pointer',
    padding: '8px 16px 16px',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.neutrals50,
    border: '1px solid transparent',

    '&:hover': {
      backgroundColor: '#D3D0FB40',
      border: `1px solid ${theme.palette.colors.purple400}`,
    },
  },
  selected: {
    border: `1px solid ${theme.palette.colors.purple400}`,
    color: theme.palette.colors.purple400,

    '& $columnValue': {
      color: theme.palette.colors.purple400,
    },
  },
  columnHeader: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '39px',
    marginBottom: 2,
  },
  valuesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  columnValue: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals400,
  },
  buttonsContainer: {
    marginTop: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 16,
  },
}));

export default useStyles;
