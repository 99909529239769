import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';
import { InputField, FormField, CheckboxField, SelectField } from '@root/components/form';
import useCreateCatalog from '@root/resources/catalog/useCreateCatalog';

import useStyles from './CreateCatalogForm.styles';

const CreateCatalogForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { mutateAsync, isLoading } = useCreateCatalog();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const submitCatalog = async (values) => {
    const response = await mutateAsync(values);

    if (response.isBadRequest) {
      return;
    }

    history.push(`/catalog/${response.catalog._id}`);
    closeModal();
  };

  const createCatalogSchema = Yup.object({
    title: Yup.string().required('Catalog name is required').max(100, 'Catalog name must be 100 characters or less.'),
  });

  return (
    <Formik
      initialValues={{
        title: '',
      }}
      onSubmit={submitCatalog}
      validationSchema={createCatalogSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormField
              name="title"
              label="Catalog name"
              placeholder="Amazon Product List"
              component={InputField}
              required
            />
            <div className={classes.actions}>
              <Button variant="outlined" type="button" onClick={closeModal}>Cancel</Button>
              <Button
                type="submit"
                className={classes.submit}
                disabled={isLoading || isSubmitting}
              >
                Create
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateCatalogForm;
