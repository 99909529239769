import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return ({
    form: {
      width: '100%',
      marginBottom: 32,
      '& > :not(:last-child)': {
        marginBottom: 16,
      },
    },
    row: {
      display: 'flex',
      '& > *': {
        flex: 1,
      },
      '& > :not(:last-child)': {
        marginRight: 16,
      },
    },
  });
});

export default useStyles;
