import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc(100vh - 72px)',
    margin: '2rem 0.5rem 2rem 1.5rem',
    paddingBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 160,
  },
}));

export default useStyles;
