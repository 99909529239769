import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

type SearchCatalogType = { searchQuery?: string } & RequestOptions;
type GetCatalogType = { id: string } & RequestOptions;
type UpdateCatalogType = { id: string, title: string } & RequestOptions;
type CreateCatalogType = { title: string } & RequestOptions;
type UploadFileType = { id: string, data: unknown } & RequestOptions;
type GetCatalogCSVType = { id: string } & RequestOptions;
type DeleteCatalogType = { id: string } & RequestOptions;
export class Catalog {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async search(data: SearchCatalogType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/catalog", data, options);

    return result;
  }

  public async get(data: GetCatalogType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/catalog/${data.id}`, data, options);

    return result;
  }

  public async create(data: CreateCatalogType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/catalog", data, options);

    return result;
  }
  
  public async update(data: UpdateCatalogType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/catalog/${data.id}`, data, options);

    return result;
  }

  public async upload({id, data}: UploadFileType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const results = await this.client.post(`catalog/${id}/upload`, data, options);
    return results;
  }

  public async getCSV(data: GetCatalogCSVType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const results = await this.client.get(`catalog/${data.id}/csv`, {}, options);
    return results;
  }

  public async delete(data: DeleteCatalogType, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del(`/catalog/${data.id}`, {}, options);

    return result;
  }
}