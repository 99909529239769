import { useQuery } from 'react-query';

import api from '@root/api';

export default function useSearchCatalogItem(data) {
  return useQuery(
    ['catalog.items', data.catalogId, data.searchQuery],
    () => api.catalogItem.search(data), {
      staleTime: 30 * 1000,
    },
  );
}
