import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalTitleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  titleProgress: {
    marginLeft: theme.spacing(1),
  },
  details: {
    ...theme.typography.body2,
    marginBottom: theme.spacing(2),
  },
  disabled: {
    color: theme.palette.colors.grey,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 0,

    '&:hover': {
      color: 'rgb(116, 97, 235)',
      textDecoration: 'none',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputHeader: {
    fontSize: 14,
    color: theme.palette.colors.darkGrey,
  },
  newAdGroup: {
    margin: 0,
    padding: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    alignItems: 'center',
  },
}));

export default useStyles;
