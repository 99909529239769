import PublishToPim from '../PublishToPim';

import Modal from './PublishToAkeneoModal';

export default class PublishToAkeneo extends PublishToPim {
  constructor({ api, block }) {
    super({ api, block });
  }

  // eslint-disable-next-line class-methods-use-this
  getPimInfo() {
    return {
      modal: Modal,
      pimName: 'Akeneo',
      menuKey: 'publishToBigAkeneo',
      menuLabel: 'Send to Akeneo',
      menuIcon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 156.5 162.8" style="enable-background:new 0 0 156.5 162.8;" xml:space="preserve">
      <style type="text/css">
       .st0{fill:url(#SVGID_1_);}
       .st1{opacity:0.8;}
       .st2{opacity:0.51;fill:url(#SVGID_2_);enable-background:new    ;}
       .st3{opacity:0.51;fill:url(#SVGID_3_);enable-background:new    ;}
       .st4{opacity:0.7;}
       .st5{opacity:0.7;fill:url(#SVGID_4_);enable-background:new    ;}
       .st6{opacity:0.7;fill:url(#SVGID_5_);enable-background:new    ;}
      </style>
      <g>
       <g>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.514" y1="32.366" x2="156.3037" y2="104.6682" gradientTransform="matrix(1 0 0 -1 0 163.9514)">
         <stop offset="4.294480e-02" style="stop-color:#5C257E">
         </stop>
         <stop offset="0.9693" style="stop-color:#8C509A">
         </stop>
        </linearGradient>
        <path class="st0" d="M123.7,159c0.6,2,1.1,3.3,1.3,3.8c0.1,0-0.1,0,0,0c-3-42.5,21.7-59.3,30.4-87.2c0.1-0.4,0.2-0.9,0.3-1.4
           c0.2-0.6,0.3-1.3,0.4-1.9c1.4-7.8-1.5-16.9-7.2-26.5c0-0.1-0.1-0.1-0.1-0.2c-0.5-0.8-1-1.6-1.5-2.4c-0.9-1.5-2-3-3-4.5
           c-0.1-0.1-0.2-0.3-0.3-0.5c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.6-0.8-0.9-1.3c-0.3-0.4-0.6-0.8-1-1.2c-0.3-0.4-0.7-0.8-1-1.3
           c-0.2-0.3-0.5-0.6-0.7-0.9C130.3,21.1,118.3,9.9,105.7,0c0,0-0.1-0.1,0,0c1.2,15.9,0.9,32-1.1,44.7c-2.3,15.3-8.5,33-16.7,48.9
           c-17.9-2.1-36-6.8-50.1-13.4C26.1,74.8,12.7,66.3,0,56.4c0.1,1.3,0.2,2.7,0.3,4c0,0.4,0.1,0.8,0.1,1.2c0.1,1,0.2,1.9,0.3,2.8
           c0,0.4,0.1,0.9,0.1,1.3c0.1,1,0.2,1.9,0.3,2.8c0,0.4,0.1,0.8,0.1,1.2c0.2,1.2,0.3,2.4,0.5,3.6c0,0.1,0,0.2,0,0.4
           c0.2,1.4,0.4,2.8,0.6,4.1c0,0.1,0,0.2,0.1,0.3c0.2,1.2,0.4,2.4,0.6,3.7c0,0.3,0.1,0.5,0.1,0.7c0.2,1.1,0.4,2.2,0.6,3.3
           c0.1,0.2,0.1,0.4,0.1,0.6c0.3,1.3,0.5,2.5,0.8,3.8c0.9,4,2,7.9,3.1,11.6c0,0.1,0,0.1,0,0.1c1.9,6.2,4.2,12,6.7,17
           c0.4,0.7,0.8,1.5,1.1,2.2v0.1c0.3,0.6,0.7,1.3,1.1,1.9c0,0,0,0,0,0.1c0.4,0.7,0.8,1.3,1.2,1.9c0.1,0.1,0.1,0.2,0.2,0.2
           c0.5,0.8,1.1,1.6,1.6,2.3c0.1,0.1,0.2,0.2,0.3,0.4l0.1,0.1c0.6,0.8,1.3,1.6,1.9,2.4c0.5,0.5,0.9,1,1.4,1.5
           c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.6,0.6,0.9,0.9c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.3,0.6,0.5,0.9,0.7c0.2,0.2,0.5,0.4,0.7,0.5
           c0.3,0.2,0.6,0.5,0.9,0.7c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.6,0.4,1,0.6c0.2,0.1,0.5,0.3,0.7,0.4c0.4,0.2,0.7,0.4,1.1,0.5
           c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.2,0.5,1.7,0.7c0.4,0.1,0.7,0.3,1,0.4c28.1,8.4,56.1-2.5,89.9,23.3">
        </path>
        <g class="st1">
         <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="155.6833" y1="24.6265" x2="86.7916" y2="153.7688" gradientTransform="matrix(1 0 0 -1 0 163.9514)">
          <stop offset="4.294480e-02" style="stop-color:#5C257E">
          </stop>
          <stop offset="1" style="stop-color:#FFFFFF">
          </stop>
         </linearGradient>
         <path class="st2" d="M104.6,44.7c-2.3,15.3-8.5,33-16.7,48.9c31.7,3.7,62.5-1.1,67.8-19.5c5.8-20.1-20.4-51-50-74.1
             C106.8,16.2,106.5,31.9,104.6,44.7z">
         </path>
         <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="88.1763" y1="-11.3788" x2="19.2846" y2="117.7639" gradientTransform="matrix(1 0 0 -1 0 163.9514)">
          <stop offset="4.294480e-02" style="stop-color:#5C257E">
          </stop>
          <stop offset="1" style="stop-color:#FFFFFF">
          </stop>
         </linearGradient>
         <path class="st3" d="M37.9,80.3C26.1,74.8,12.7,66.3,0,56.4c2.8,37.4,14.1,76.2,34.1,82.6c18.3,5.8,39.4-17,53.9-45.4
             C70.1,91.6,52,86.9,37.9,80.3z">
         </path>
        </g>
        <g class="st4">
         <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="31.756" y1="94.5678" x2="85.3905" y2="-5.9742" gradientTransform="matrix(1 0 0 -1 0 163.9514)">
          <stop offset="0.2331" style="stop-color:#4F256F">
          </stop>
          <stop offset="0.8405" style="stop-color:#5C257E">
          </stop>
         </linearGradient>
         <path class="st5" d="M63.1,115.7C16.4,98.4,0,56.4,0,56.4c1.7,22.9,6.6,46.3,14.8,62.6c0.4,0.7,0.7,1.4,1.1,2.1
             c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,0.7,1.3,1.1,1.9c0,0.1,0.1,0.2,0.2,0.2c0.4,0.6,0.7,1.2,1.1,1.8c0.1,0.1,0.1,0.2,0.2,0.2
             c0.4,0.6,0.7,1.1,1.1,1.7c0.3,0.4,0.5,0.7,0.7,1c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.5,0.5,0.7c0.4,0.6,0.9,1.1,1.3,1.6
             c3.5,4.1,7.5,7.1,11.8,8.5c0.4,0.1,0.7,0.3,1,0.4c28.1,8.4,56.1-2.5,89.9,23.3C122.4,159.3,98.4,128.8,63.1,115.7z">
         </path>
         <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="99.2464" y1="130.5639" x2="152.8799" y2="30.0235" gradientTransform="matrix(1 0 0 -1 0 163.9514)">
          <stop offset="0.2331" style="stop-color:#4F256F">
          </stop>
          <stop offset="0.8405" style="stop-color:#5C257E">
          </stop>
         </linearGradient>
         <path class="st6" d="M155.7,74.1c5.8-20.1-20.4-51-50-74.1c0,0,25.8,37,14.3,85.3c-8.9,37.3,4,74.6,5,77.4c0.1,0-0.1,0,0,0
             c-3-42.5,21.7-59.3,30.4-87.2C155.4,75.1,155.5,74.6,155.7,74.1z">
         </path>
        </g>
       </g>
      </g>
     </svg>`,
    };
  }
}
