import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import api from '@root/api';

const createFile = (fileData) => api.files.create({
  title: fileData.title,
  templateType: fileData.templateType,
  folderId: fileData.folderId,
  data: fileData.data || {},
  negativeKeywordArray: fileData.negativeKeywordArray,
  ...(fileData.engine && { engine: fileData.engine }),
  ...(fileData.campaignDocumentId && { campaignDocumentId: fileData.campaignDocumentId }),
});

export default function useCreateFile() {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    (variables) => createFile(variables),
    {
      onSuccess: (response, variables) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.refetchQueries('files.list');

        const { slug } = variables;

        if (variables.redirect) {
          history.push(`${slug}/${response._id}`);
        }

        return response;
      },
    },
  );
}
