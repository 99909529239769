import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    modalRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
      textAlign: 'center',
    },
    title: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '39px',
    },
    promoCode: {
      color: theme.palette.colors.warning,
      fontWeight: 600,
    },
    promoCodeInfo: {
      color: theme.palette.colors.mediumDarkGrey,
      marginBottom: 0,
    },
    buttons: {
      display: 'flex',
      gap: 16,
    },
  });
});

export default useStyles;
