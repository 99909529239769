/* eslint-disable react/react-in-jsx-scope */
import { IconForms, IconZoomPan, IconPalette } from '@tabler/icons-react';

import TemplateLabel from './components/TemplateLabel';

export const templates = {
  freeFormEditorInstruct: {
    templateType: 'freeFormEditorInstruct',
    label: 'Command copy',
    description: (
      <>
        Tell us what to create.
        {' '}
        <a target="_blank" rel="noreferrer" href="https://describely.notion.site/What-is-Command-Copy-e10e03f553784de381802de3fe3b8e5e?pvs=25">
          Learn more
        </a>
      </>
    ),
    aliAvailable: false,
    seoAvailable: false,
    maxLengthSettingAvailable: false,
  },
  contentRewriter: {
    templateType: 'contentRewriter',
    label: 'Rewrite',
    description: 'Rewrite existing copy.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
    qualityTrackFields: ['inputParagraph'],
  },
  contentImprover: {
    templateType: 'contentImprover',
    label: 'Enhance',
    description: 'Tweak and refine existing copy.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: false,
    qualityTrackFields: ['draft'],
  },
  freeFormEditor: {
    templateType: 'freeFormEditor',
    label: 'Extend',
    description: 'Auto-write the next sentence.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: false,
  },
  productDescription: {
    templateType: 'productDescriptionV2',
    label: 'Product Description',
    description: 'Generate a brief description for your product.',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
  },
  homeDepotProductDescription: {
    templateType: 'homeDepotProductDescription',
    label: 'Home Depot Product Description',
    description: 'Generate a product description for your Home Depot product.',
    aliAvailable: false,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  lowesProductDescription: {
    templateType: 'lowesProductDescription',
    label: 'Lowes Product Description',
    description: 'Generate a product description for your Lowes product.',
    aliAvailable: false,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  overstockProductDescription: {
    templateType: 'overstockProductDescription',
    label: 'Overstock Product Description',
    description: 'Generate a product description for your Overstock product.',
    aliAvailable: false,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  wayfairProductDescription: {
    templateType: 'wayfairProductDescription',
    label: 'Wayfair Product Description',
    description: 'Generate a product description for your Wayfair product.',
    aliAvailable: false,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  blogIdea: {
    templateType: 'blogIdea',
    label: 'Blog Ideas',
    description: 'Generate blog and article ideas. Pair with Blog Intro.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  blogIntro: {
    templateType: 'blogIntro',
    label: 'Blog Intro',
    description: 'Get unstuck with a blog intro. Pair with Blog ideas.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: false,
  },
  blogOutline: {
    templateType: 'blogOutline',
    label: 'Blog Outline',
    description: 'Generate the structure of a blog or article.',
    aliAvailable: true,
    seoAvailable: false,
    qualityTrackFields: ['description'],
    maxLengthSettingAvailable: true,
  },
  contentExpander: {
    templateType: 'contentExpander',
    label: 'Expand',
    description: 'Get a paragraph from one bullet point.',
    aliAvailable: true,
    seoAvailable: false,
    maxLengthSettingAvailable: true,
  },
  socialMediaCaptions: {
    templateType: 'socialMediaCaptions',
    label: 'Social Media Captions',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
    qualityTrackFields: ['topic'],
  },
  instagramAd: {
    templateType: 'instagramAd',
    label: 'Instagram Ad',
    description: 'Ad Copy specifically for Instagram',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
    qualityTrackFields: ['companyDescription'],
  },
  instagramProductDescription: {
    templateType: 'instagramProductDescription',
    label: 'Instagram Product Description',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: false,
    qualityTrackFields: ['companyDescription'],
  },
  amazonProductDescription: {
    templateType: 'amazonProductDescription',
    label: 'Amazon Product Description',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
    qualityTrackFields: ['companyDescription'],
  },
  flipkartProductDescription: {
    templateType: 'flipkartProductDescription',
    label: 'Flipkart Product Description',
    aliAvailable: true,
    seoAvailable: true,
    maxLengthSettingAvailable: false,
  },
  productTitle: {
    templateType: 'productTitle',
    label: 'Product Title',
    description: 'Generate compelling product titles instantly',
    aliAvailable: false,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
  },
  productFeatureBullets: {
    templateType: 'productFeatureBullets',
    label: 'Product Feature Bullets',
    description: 'Boost ecommerce sales with feature bullet points',
    aliAvailable: false,
    seoAvailable: true,
    maxLengthSettingAvailable: true,
  },
};

export const getTabs = ({ readOnly, aiImagesEnabled }) => {
  const tabs = {
    create: {
      value: 'create',
      icon: IconForms,
    },
    seo: {
      value: 'seo',
      icon: IconZoomPan,
      tooltip: 'SEO optimize your content',
      disabled: readOnly,
      disabledTooltip: 'SEO is not available in viewer mode',
    },
  };

  if (aiImagesEnabled) {
    return { ...tabs,
      aiImages: {
        value: 'aiImages',
        icon: IconPalette,
        tooltip: 'Art Studio',
        disabled: readOnly,
        disabledTooltip: 'Art Studio is not available in viewer mode',
      } };
  }

  return tabs;
};

export const templatesOptions = Object.values(templates)
  .map(({ templateType, label }) => ({
    value: templateType,
    label: <TemplateLabel text={label} templateType={templateType} />,
  }));
