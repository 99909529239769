import React from 'react';
import { useHistory } from 'react-router-dom';

import useSelfServeEnterpriseEnvelope from '@root/resources/billing/useSelfServeEnterpriseEnvelope';
import useProration from '@root/utils/hooks/useProration';

import PlanDetails from '../PlanDetails';

import useStyles from './DocumentSign.styles';

const DocumentSign = ({
  documentUrl,
  pipelineData,
  proratedWords,
  plan,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { refetch: refetchEnvelope } = useSelfServeEnterpriseEnvelope();

  const [isPollingStarted, setIsPollingStarted] = React.useState(false);

  const proration = useProration(plan._id);

  React.useEffect(() => {
    const messageListener = (event) => {
      if (event.data.docusignContractState) {
        if (event.data.docusignContractState === 'signing_complete') {
          setIsPollingStarted(true);
        } else {
          history.replace('/profile/plans');
        }
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  React.useEffect(() => {
    if (isPollingStarted) {
      const interval = setInterval(async () => {
        const resp = await refetchEnvelope();
        if (resp.data.status === 'completed') {
          history.replace('/checkout', {
            plan,
            selfServePipelineData: pipelineData,
            proratedWords,
          });
        }
      }, 5000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [isPollingStarted]);

  return (
    <div className={classes.root}>
      <iframe className={classes.iframe} title="Document" src={documentUrl} />
      <div>
        <div className={classes.planDetailsWrap}>
          <PlanDetails
            pipelineData={pipelineData}
            plan={plan}
            proration={proration}
            proratedWords={proratedWords}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentSign;
