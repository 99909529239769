import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ObjectUtil } from '@copysmith/utils';

import Avatar from '@root/components/Avatar';
import AuthContext from '@root/resources/auth/auth.context';
import TruncatedText from '@root/components/TruncatedText';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import { getLeftNavigationMenuHint, setLeftNavigationMenuHint } from '@root/services/localStorage.service';
import { getUserInfoToDisplay } from '@root/utils/formatString.util';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

import PlanBadge from './PlanBadge';
import MembersList from './MembersList';
import useStyles from './OrganizationDropdown.styles';

const { insertInArrayIf: insertIf } = ObjectUtil;

const OrganizationDropdown = () => {
  const { currentExternalSource } = externalSourcesContext.useExternalSource();
  const { toggles: { redirectToAppTopBar } } = currentExternalSource;

  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const { currentUser } = AuthContext.useAuth();

  const { data: membersDataAll } = useTeamMembers({ allMembers: true });
  const membersCount = membersDataAll?.results?.filter((m) => !m.deactivated)?.length;

  const isMember = currentUser.roles.member;

  const userFullName = getUserInfoToDisplay({
    firstName: currentUser?.firstName,
    surname: currentUser?.surname,
    email: currentUser?.email,
  });

  const isHintVisible = getLeftNavigationMenuHint();

  const handleAvatarClick = (e) => {
    setMenuAnchor(e.currentTarget);
    setLeftNavigationMenuHint(false);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    // check if hint wasn't shown before
    if (isHintVisible === null) {
      setLeftNavigationMenuHint(true);
    }
  }, [setLeftNavigationMenuHint, isHintVisible]);

  const MENU_ITEMS = [
    ...insertIf(!isMember, {
      title: 'Organization',
      items: [
        {
          key: 'teams',
          content: (
            <p className={classes.teamsTitle}>
              <span>Teams</span>
              {!isMember && (
                <span className={classes.membersCounter}>
                  {membersCount > 1 ? membersCount : 'Just you' }
                </span>
              )}
            </p>
          ),
          to: '/profile/team-members',
        },
        {
          key: 'plan',
          content: 'Plan',
          to: '/profile/plans',
        },
        {
          key: 'billing',
          content: 'Billing',
          to: '/profile/billing',
        },
        ...insertIf(currentUser?.isUsageDashboardEnabled, {
          key: 'usage-dashboard',
          content: 'Usage Dashboard',
          to: '/usage',
        }),
      ],
    }),
  ];

  return (
    <div>
      <IconButton
        classes={{ root: classes.iconButton }}
        onClick={handleAvatarClick}
      >
        <Avatar
          name={userFullName}
          userId={currentUser?._id}
          size="medium"
          className={classes.avatar}
          popperEnabled={false}
          variant="square"
          oneChar={false}
        />

        {isHintVisible && <div className={classes.hintPuls} />}
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -20,
          horizontal: 'left',
        }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
        keepMounted
      >
        <div className={classes.menuSectionsContainer}>
          {/* section with useful links */}
          {MENU_ITEMS.map((section) => (
            <div
              key={section.title}
              className={classes.menuSectionContainer}
            >
              {section?.title && (
                <TruncatedText className={classes.menuSectionTitle}>
                  {section.title}
                </TruncatedText>
              )}

              <div className={classes.itemsContainer}>
                {section?.items?.map((item) => (
                  <MenuItem
                    key={item.key}
                    classes={{ root: classes.menuItem }}
                    component={Link}
                    to={item.to}
                    target={redirectToAppTopBar && '_blank'}
                    disableRipple
                  >
                    <TruncatedText className={classes.menuItemText}>
                      {item.content}
                    </TruncatedText>
                  </MenuItem>
                ))}
              </div>
            </div>
          ))}

          {!isMember && <div className={classes.divider} />}

          {/* section with organization info */}
          <div className={classes.menuSectionContainer}>
            <TruncatedText className={clsx(classes.menuSectionTitle)}>
              Your Team
            </TruncatedText>

            <PlanBadge />

            <MembersList />
          </div>
        </div>
      </Menu>

    </div>
  );
};

export default OrganizationDropdown;
