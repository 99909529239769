import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '464px',
  },
  presetFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  redText: {
    color: theme.palette.colors.pink600,
  },
  actionButton: {
    textAlign: 'right',
  },
  checkbox: {
    color: theme.palette.colors.neutrals600,
    '& svg rect': {
      opacity: 1,
    },
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
    '&$checked': {
      color: theme.palette.colors.neutrals600,
    },
    '&$indeterminate': {
      color: theme.palette.colors.neutrals600,
    },
  },
  checked: {
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },
  scrapeButtonContainer: {
    marginTop: '0.85rem',
    display: 'flex',
  },
  scrapeFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '8px',
  },
  scrapeField: {
    flex: 1,
  },
  scrapeButton: {
    height: 36,
    padding: '8px 24px',
  },
  presetInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '24px',
  },
  errorMessage: {
    color: 'red',
    marginTop: '0.25rem',
  },
  input: {
    marginBottom: '8px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '8px',
  },
}));

export default useStyles;
