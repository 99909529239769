import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: '6px',
    cursor: 'default',
  },
  span: {
    fontSize: '0.9rem',
    color: theme.palette.grey.pure,
    padding: theme.spacing(1),
    borderRadius: theme.border.radius.default,
    backgroundColor: theme.palette.colors.mediumLightGrey,
  },
  spanHighlight: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
  },
  spanLong: {
    color: theme.palette.secondary.main,
  },
}));

const BadgeIcon = ({ text, highlight, isTooLong, color }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.badge)}>
      <span
        style={{ backgroundColor: color }}
        className={clsx(classes.span, {
          [classes.spanLong]: isTooLong,
          [classes.spanHighlight]: highlight,
        })}
      >
        {text}
      </span>
    </div>
  );
};

export default BadgeIcon;
