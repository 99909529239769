import { darken, lighten, makeStyles } from '@material-ui/core';

import { colors } from '@root/material.theme';

import background from './background.svg';

export default makeStyles((theme) => ({
  authRoot: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flex: 1,
    fontSize: 14,
    padding: '24px 0',
    '& a': {
      color: colors.primary,
      '&:hover': {
        color: lighten(colors.primary, 0.2),
        backgroundColor: 'transparent',
      },
      '&:active': {
        color: darken(colors.primary, 0.2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  main: {
    minHeight: 0,
    overflowY: 'auto',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 8,
    padding: '48px 114px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: '10px 25px',
      height: '100%',
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
}));
