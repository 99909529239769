import React from 'react';
import clsx from 'clsx';

import TemplatesContext from '@root/resources/templates/templates.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import { searchGenerators } from '@root/views/TemplatesDashboardNew/helpers';
import useIntegrations from '@root/resources/integrations/useIntegrations';

import addFavoriteData from '../helpers/addFavoriteData';
import filterTemplatesByBucket from '../helpers/filterTemplatesByBucket';
import madeForYou from '../helpers/madeForYou';

import TemplateCard from './TemplateCard/TemplateCard';
import TemplatesSearch from './TemplatesSearch';
import useStyles from './TemplatesList.styles';
import EditorCard from './TemplateCard/EditorCard';
import AkeneoCard from './TemplateCard/AkeneoCard';
import BigCommerceCard from './TemplateCard/BigCommerceCard';

const TemplatesList = () => {
  const { templatesList } = TemplatesContext.useTemplates();

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { filterGenerators, availableCreateModalOptions } = currentExternalSource;

  const { data: integrations = [] } = useIntegrations();

  const akeneoIntegration = integrations.find((integration) => integration.type === 'akeneo' && integration.status === 'active');

  const { state: {
    query: templatesQuery,
    bucket,
    favorites,
    customTemplatesFilter,
    isModal,
  } } = useTemplatesDashboard();
  const classes = useStyles({ columns: isModal ? 3 : 4 });

  let list = filterGenerators(templatesList)
    .map((item) => addFavoriteData(item, favorites));

  if (bucket !== 'made-for-you' && bucket !== 'all') {
    list = filterTemplatesByBucket(list, bucket);
  } else if (bucket === 'made-for-you') {
    list = madeForYou(list);
  }

  if (templatesQuery) {
    list = searchGenerators(templatesQuery, list)
      .filter((item) => !!item.buckets);
  }

  list = list.filter(customTemplatesFilter);

  return (
    <div className={clsx(classes.templatesListContainer)}>
      <TemplatesSearch />

      <div className={classes.listContainer}>
        <EditorCard />
        {availableCreateModalOptions.includes('bulkCopyAkeneo') && akeneoIntegration && <AkeneoCard />}
        {availableCreateModalOptions.includes('bulkCopyBigCommerce') && <BigCommerceCard />}
        {list.map((template) => (
          <TemplateCard
            template={template}
            disabled={availableCreateModalOptions.includes('bulkCopyBigCommerce')}
            disabledTooltipText={availableCreateModalOptions.includes('bulkCopyBigCommerce') && 'This template is not available for BigCommerce'}
            key={template?._id}
          />
        ))}
      </div>

    </div>
  );
};

export default TemplatesList;
