import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useLikeFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    (folderId) => api.folders.like(folderId),
    {

      onMutate: async (folderId) => {
        const queryKey = ['folders.list'];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const prevFolders = queryClient.getQueryData(queryKey);
        if (!prevFolders) {
          return null;
        }

        const doc = prevFolders.find((f) => f._id === folderId);

        doc.liked = !doc.liked;
        const updatedDocs = prevFolders.map((folder) => ({
          ...folder,
        }));

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, () => updatedDocs);

        return { prevFolders, queryKey };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(context.queryKey, context.prevFolders);
      },
      onSuccess: (response, variables, context) => {
        if (!response || response.isBadRequest) {
          queryClient.setQueryData(context.queryKey, context.prevFolders);
          return;
        }
        const folderId = variables;
        queryClient.getQueryCache().findAll('folders.list')
          .forEach(({ queryKey: key, state }) => {
            if (!state.data) return;
            queryClient.setQueryData(
              key,
              () => state.data.map((f) => (
                f._id === folderId ? { ...f, liked: response.liked } : f)),
            );
          });
      },
    },
  );
}
