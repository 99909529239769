import React from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

import useGetCurrentBulkJob from '@root/resources/bigCommerce/useGetCurrentBulkJob';
import { InputField } from '@root/components/form';
import useUpdateBulkProductDescription from '@root/resources/bigCommerce/useUpdateBulkProductDescription ';

import useStyles from './Result.styles';
import BottomToolbar from './BottomToolbar';

const BodyCell = ({ children }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.bodyCell}>
      <div className={classes.bodyCellContent}>
        {children}
      </div>
    </TableCell>
  );
};

const Result = ({
  onBack,
  onPublish,
}) => {
  const classes = useStyles();

  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [productIdToEdit, setProductIdToEdit] = React.useState(null);
  const [descriptionToEdit, setDescriptionToEdit] = React.useState(null);

  const {
    mutateAsync: updateDescription,
  } = useUpdateBulkProductDescription();

  const publish = async () => {
    try {
      setIsLoading(true);
      await onPublish(selectedProducts);
    } finally {
      setIsLoading(false);
    }
  };

  const onEdit = (product) => (e) => {
    e.stopPropagation();

    setProductIdToEdit(product.id);
    setDescriptionToEdit(product.row.result.documents[0].content.blocks[0].data.text);
  };
  const onEditCancel = () => {
    setProductIdToEdit(null);
    setDescriptionToEdit(null);
  };
  const onEditSave = async () => {
    try {
      setIsLoading(true);
      await updateDescription({
        productId: productIdToEdit,
        description: descriptionToEdit,
      });
    } finally {
      setIsLoading(false);
      onEditCancel();
    }
  };

  const onDescriptionChange = (event) => {
    setDescriptionToEdit(event.target.value);
  };

  const {
    data: currentBulkJob = {},
  } = useGetCurrentBulkJob();

  const onProductSelect = (id) => () => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const successProducts = React.useMemo(() => (currentBulkJob.products || [])
    .filter((product) => product.row?.state === 'done'), [currentBulkJob]);

  const rows = React.useMemo(() => successProducts
    .map((product) => {
      const image = (product.images || [])[0]?.url_standard;

      return (
        <TableRow onClick={onProductSelect(product.id)} className={classes.tableRow} key={product.id}>
          <BodyCell>
            <Checkbox
              checked={selectedProducts.includes(product.id)}
            />
          </BodyCell>
          <BodyCell>
            <div className={classes.productCell}>
              <div className={classes.imageWrap}>
                {image && (<img src={image} alt="Product" />)}
              </div>
              <div>
                <div className={classes.productName}>
                  {product.name}
                </div>
                <div className={classes.productSku}>
                  {product.sku}
                </div>
                <div>
                  {product.categories || ''}
                </div>
              </div>
            </div>
          </BodyCell>
          <BodyCell>{product.brand || '-'}</BodyCell>
          <BodyCell>{product.row.result.documents[0].content.blocks[0].data.text}</BodyCell>
          <BodyCell>
            <IconButton onClick={onEdit(product)}>
              <EditOutlined />
            </IconButton>
          </BodyCell>
        </TableRow>
      );
    }), [selectedProducts, currentBulkJob]);

  return (
    <div className={classes.root}>
      <Dialog
        onClose={onEditCancel}
        fullWidth
        maxWidth="sm"
        open={!!productIdToEdit}
      >
        <DialogTitle>
          Edit product description
        </DialogTitle>
        <DialogContent>
          <InputField
            multiline
            rows={5}
            fullWidth
            value={descriptionToEdit}
            onChange={onDescriptionChange}
            autoFocus
          />
          <DialogActions>
            <Button
              color="primary"
              variant="text"
              onClick={onEditCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onEditSave}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableBody>
            {rows}
            <TableRow className={classes.stub}>
              <TableCell>
                <div className={classes.stub} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <BottomToolbar
        text={`${successProducts.length} product was processed with Bulk`}
        next={{
          label: 'Publish',
          disabled: selectedProducts.length === 0 || isLoading,
          action: publish,
        }}
        cancel={{
          label: 'Back',
          action: onBack,
        }}
      />
    </div>
  );
};

export default Result;
