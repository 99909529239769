import React from 'react';

const Create = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="86"
      fill="none"
    >
      <path fill="currentColor" d="M50.167 75.25c-.717 0-1.344-.269-1.882-.806-.537-.538-.806-1.165-.806-1.882 0-.776.269-1.418.806-1.925.538-.508 1.165-.762 1.882-.762 3.523 0 6.494-.717 8.913-2.15 2.419-1.433 3.628-3.106 3.628-5.017 0-1.373-.88-2.717-2.642-4.03-1.762-1.315-4.136-2.33-7.122-3.047l4.21-4.21c3.763 1.135 6.525 2.702 8.287 4.703 1.761 2 2.642 4.195 2.642 6.584 0 4.002-1.821 7.092-5.464 9.272-3.643 2.18-7.794 3.27-12.452 3.27ZM21.5 48.913c-3.822-.837-6.57-2.15-8.242-3.942-1.672-1.792-2.508-3.643-2.508-5.554 0-2.09.776-3.972 2.33-5.644 1.552-1.672 5.135-3.524 10.75-5.554 3.94-1.434 6.479-2.598 7.614-3.494 1.134-.896 1.702-1.941 1.702-3.135 0-1.494-.657-2.777-1.971-3.852-1.314-1.075-3.344-1.613-6.092-1.613-1.612 0-2.986.209-4.12.627a8.361 8.361 0 0 0-3.046 1.97c-.478.479-1.09.762-1.837.852-.746.09-1.418-.105-2.015-.582-.657-.478-1-1.075-1.03-1.792a2.571 2.571 0 0 1 .671-1.881c1.016-1.314 2.538-2.404 4.569-3.27 2.03-.866 4.3-1.299 6.808-1.299 4.061 0 7.316.97 9.765 2.912 2.449 1.94 3.673 4.583 3.673 7.928 0 2.448-.851 4.523-2.553 6.226-1.702 1.702-5.032 3.478-9.989 5.33-4.001 1.493-6.644 2.672-7.928 3.538-1.284.866-1.926 1.777-1.926 2.733 0 .955.806 1.866 2.419 2.732 1.612.866 4.031 1.687 7.256 2.463l-4.3 4.3Zm44.433-13.796L54.467 23.65l4.03-4.031c1.076-1.075 2.27-1.613 3.584-1.613s2.509.538 3.584 1.613l4.3 4.3c1.075 1.075 1.612 2.27 1.612 3.583 0 1.314-.537 2.508-1.612 3.583l-4.032 4.032ZM19.708 69.875h3.763l30.906-30.906-3.762-3.763-30.907 30.906v3.763Zm-5.375 5.375V63.783l36.282-36.28L62.08 38.968 25.8 75.25H14.333Zm36.282-40.044 3.762 3.763-3.762-3.763Z" />
    </svg>
  );
};

export default Create;
