import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import promptItemResource from '@root/resources/promptItem';

import useStyles from './EditFieldModal.styles';
import PromptField from './PromptField';

const getValidationSchema = (fields) => {
  const obj = {};
  fields.forEach((f) => {
    if (f.required) {
      obj[f.name] = yup
        .string(`Type ${f.name}`)
        .required(`${f.name} is required`);
    }
  });

  return yup.object(obj);
};

const EditPromptItemModal = ({
  onClose,
  template,
  promptItem,
}) => {
  const classes = useStyles();
  const close = (_e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };
  const initialData = promptItem.data || {};
  const isNew = !promptItem._id;

  const { mutateAsync: createPrompt } = promptItemResource.useCreatePromptItem();
  const { mutateAsync: updatePrompt } = promptItemResource.useUpdatePromptItem();

  const onSubmit = async (values) => {
    let result = null;
    if (isNew) {
      result = await createPrompt({
        templateType: template.templateType,
        data: values,
        default: true,
      });
    } else {
      result = await updatePrompt({
        data: values,
      });
    }
    if (result.isBadRequest) {
      return;
    }
    onClose();
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: getValidationSchema(template.fields),
    onSubmit,
  });

  return (
    <Dialog
      open
      onClose={close}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitle}>
          {isNew ? 'Create prompt' : 'Edit prompt'}
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <p className={classes.modalBodyText}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              {template.fields.map((field) => {
                return (
                  <Grid
                    key={field.name}
                    item
                    className={classes.cell}
                    sm={12}
                    xs={12}
                  >
                    <PromptField
                      formik={formik}
                      field={field}
                      className={classes.input}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </p>
      </DialogContent>
      <DialogActions className={classes.cancelButtonWrap}>
        <Button
          variant="text"
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPromptItemModal;
