import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const getCampaigns = async (data) => {
  if (!data.customerId) {
    return null;
  }
  const campaigns = await api.googleAd.getCampaigns(data);
  if (campaigns.isBadRequest) {
    return null;
  }

  return campaigns.map((campaign) => ({ label: campaign.name, value: campaign.id }));
};

export default function useCampaigns({
  customerId,
  managerCustomerId,
}, { enabled } = { enabled: true }) {
  return useQuery(['googleAds.getCampaigns', customerId],
    () => getCampaigns({ customerId, managerCustomerId }), {
      enabled,
    });
}
