/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CheckCopyUniqueness from '@root/views/File/components/CheckCopyUniqueness';

import EditorToolbar from './components/EditorToolbar';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 56,
    minHeight: 56,
    display: 'flex',
    zIndex: 2,
    background: '#fff',
  },
  toolbarLeft: {
    width: '25%',
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightPanel: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: theme.spacing(3),
  },
  checkUniquenessWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.25, 2, 0, 2),
  },
}));

const FileToolbar = ({
  filePermissions,
  lastHighlightedText,
  children,
  toolbarComponent,
  leftComponent,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      {leftComponent || <div className={classes.toolbarLeft} /> }
      <div className={classes.toolbarRight}>
        {toolbarComponent || <EditorToolbar />}
        {filePermissions.canEditFile && (
          <span className={classes.checkUniquenessWrap}>
            <CheckCopyUniqueness lastHighlightedText={lastHighlightedText} />
          </span>
        )}
        <div className={classes.toolbarRightPanel}>

          {children}

        </div>
      </div>
    </div>
  );
};

export default FileToolbar;
