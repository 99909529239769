import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import useQuery from '@root/common/useQuery';
import useFolders from '@root/resources/folder/useFolders';
import AuthContext from '@root/resources/auth/auth.context';
import { getChildFolders, getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';

const getFolderIdsWithNested = (folders, user, isShared) => {
  const rootFolders = isShared ? getSharedRootFolders(folders, user) : getRootFolders(folders, user);

  return rootFolders.flatMap((sharedRootFolder) => {
    const childFolders = getChildFolders(sharedRootFolder._id, folders);

    return [...childFolders.map((childFolder) => childFolder._id), sharedRootFolder._id];
  });
};

/** Effects run once on dashboard page load.
 *
 * Use one effect per function.
 *
 * This is a react component so it has full access to all dashboard/react hooks.
 *
 * E.g. usage: read url params or asynchronously set dashboard state
 */
const PageLoadListener = () => {
  const { currentUser } = AuthContext.useAuth();
  const { dispatch } = useDashboard();
  const { locationType, locationId } = useParams();

  const { data: folders } = useFolders();

  const query = useQuery();
  // capture folder state from url parameter on page load / refresh
  useEffect(() => {
    const url = window.location.pathname;
    switch (locationType) {
      case 'bookmarks':
        dispatch({ type: actions.SELECT_BOOKMARKS });
        break;
      case 'my-files':
        dispatch({
          type: actions.SELECT_MY_FILES,
          payload: {
            ...(folders && {
              folderIds: getFolderIdsWithNested(folders, currentUser, false),
            }),
          },
        });
        break;
      case 'shared':
        dispatch({
          type: actions.SELECT_SHARED_FILES,
          payload: {
            ...(folders && {
              folderIds: getFolderIdsWithNested(folders, currentUser, true),
            }),
          },
        });
        break;
      case 'workflow':
        if (query.get('filters')) {
          dispatch({
            type: actions.SELECT_WORKFLOW,
            payload: { shouldApplyFilters: true },
          });
        } else {
          dispatch({ type: actions.SELECT_WORKFLOW });
        }
        break;
      case 'project':
        if (locationId) {
          dispatch({ type: actions.SELECT_PROJECT, payload: { folderId: locationId } });
        } else {
          dispatch({ type: actions.SELECT_ALL_FILES });
        }
        break;
      case 'folder':
        if (locationId) {
          dispatch({ type: actions.SELECT_FOLDER, payload: { folderId: locationId } });
        } else {
          dispatch({ type: actions.SELECT_ALL_FILES });
        }
        break;
      default:
        if (url.includes('Home')) {
          const homeFolderId = url.match(/Home_(.+)/)[1];
          dispatch({ type: actions.SELECT_UNCATEGORIZED, payload: { homeFolderId } });
          break;
        }
        if (url.includes('shared_uncategorized')) {
          dispatch({
            type: actions.SELECT_SHARED_UNCATEGORIZED,
            payload: {
              ...(folders && {
                folderIds: folders
                  .filter((folder) => folder.isSharedUncategorized)
                  .map(({ _id: folderId }) => folderId),
              }),
            },
          });
          break;
        }
        dispatch({
          type: actions.SELECT_MY_FILES,
          payload: {
            ...(folders && {
              folderIds: getFolderIdsWithNested(folders, currentUser, false),
            }),
          },
        });
        break;
    }
  }, []);

  return null;
};

export default PageLoadListener;
