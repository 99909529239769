import React from 'react';
import isEqual from 'lodash.isequal';

const useFlaggedDocuments = (documents) => {
  const initialFlaggedDocumentsIds = React.useMemo(() => documents
    .filter(({
      flagged,
    }) => flagged)
    .map(({
      _id,
    }) => _id), [documents]);

  const [flaggedDocumentsIds, setFlaggedDocumentsIds] = React.useState(initialFlaggedDocumentsIds);

  React.useEffect(() => {
    const newFlaggedDocumentsIds = flaggedDocumentsIds
      .filter((flaggedId) => documents.find(({
        _id,
      }) => _id === flaggedId));

    if (!isEqual(flaggedDocumentsIds, newFlaggedDocumentsIds)) {
      setFlaggedDocumentsIds(newFlaggedDocumentsIds);
    }
  }, [documents, flaggedDocumentsIds]);

  React.useEffect(() => {
    setFlaggedDocumentsIds(initialFlaggedDocumentsIds);
  }, [initialFlaggedDocumentsIds.length]);

  const toggleFlagState = (docId) => () => {
    if (flaggedDocumentsIds.includes(docId)) {
      setFlaggedDocumentsIds(flaggedDocumentsIds.filter((c) => c !== docId));
    } else {
      setFlaggedDocumentsIds([...flaggedDocumentsIds, docId]);
    }
  };

  return {
    flaggedDocumentsIds,
    setFlaggedDocumentsIds,
    toggleFlagState,
    initialFlaggedDocumentsIds,
  };
};

export default useFlaggedDocuments;
