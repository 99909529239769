import React from 'react';
import { IconStack2 } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import { BULK_COPY_MODAL } from '../BulkCopyModal';

import useStyles from './BulkCopyButton.styles';

const BulkCopyButton = ({ bulkGenerationRows, limit, selectedItemIds, catalogId }) => {
  const classes = useStyles();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const disabled = !selectedItemIds
  || selectedItemIds.length <= 0
  || limit <= 0
  || (bulkGenerationRows !== null && bulkGenerationRows <= 0)
  || (bulkGenerationRows > 0 && selectedItemIds.length > bulkGenerationRows);

  const handleBulkClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL,
      payload: { modalId: BULK_COPY_MODAL,
        modalContent: { selectedItemIds, catalogId } },
    });
  };

  const getTooltipContent = () => {
    if (bulkGenerationRows !== null && bulkGenerationRows <= 0) {
      return 'You do not have any bulk generations remaining.';
    }

    if (limit <= 0) {
      return 'You do not have any words remaining.';
    }

    if (bulkGenerationRows > 0 && selectedItemIds.length > bulkGenerationRows) {
      return `You only have ${bulkGenerationRows} bulk generations remaining. Unselect some products in order to run bulk copy.`;
    }

    if (!selectedItemIds || selectedItemIds.length <= 0) {
      return 'Select at least one product to start a bulk copy.';
    }

    return '';
  };

  return (
    <SimpleTooltip
      content={getTooltipContent()}
      wrapped
      placement="bottom"
    >
      <Button
        className={classes.bulkCopyButton}
        disabled={disabled}
        onClick={handleBulkClick}
        startIcon={<IconStack2 className={classes.startIcon} />}
      >
        Bulk Copy
      </Button>
    </SimpleTooltip>
  );
};

export default BulkCopyButton;
