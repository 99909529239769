import { Form, Formik } from 'formik';
import React from 'react';
import { omit, shuffle } from 'lodash';
import { IconInfoCircle } from '@tabler/icons-react';
import * as Yup from 'yup';

import api from '@root/api/newApi';
import { FormField, InputField, RadioButtonField } from '@root/components/form';

import useStyles from './CancelationForm.styles';

const schema = Yup.lazy(({ reason }) => {
  const isOther = reason === 'other';
  return (
    Yup.object().shape({
      reason: Yup.string().required('Reason is required'),
      otherReasonDescription: isOther && Yup.string().required('Reason is required'),
      comments: Yup.string().required('Field is required'),
    })
  );
});

const initialValues = {
  reason: '',
  otherReasonDescription: '',
  comments: '',
};

const reasonOptions = [
  { value: 'not needed', label: 'Don\'t need it any longer' },
  { value: 'expensive', label: 'Pricing is too expensive' },
  { value: 'confusing', label: 'Product is confusing or difficult to use' },
  { value: 'using alternative product', label: 'Using alternative product' },
  { value: 'missing features', label: 'Missing features I need' },
  { value: 'customer service', label: 'Customer service' },
];

const shuffledReasonOptions = [
  ...shuffle(reasonOptions),
  { value: 'other', label: 'Other' },
];

const CancelationForm = ({ formRef, onSuccess }) => {
  const classes = useStyles();

  const handleSubmit = async (values) => {
    const reasonIndex = shuffledReasonOptions.findIndex((option) => option.value === values.reason) + 1;
    const submitValues = {
      ...omit(values, values.reason !== 'other' && 'otherReasonDescription'),
      reasonIndex,
    };
    const { isBadRequest } = await api.billing.cancelSubscription(submitValues);

    if (!isBadRequest) {
      onSuccess();
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ values }) => {
          return (
            <Form className={classes.form}>
              <div>
                <h6>Why are you cancelling?</h6>
                <FormField
                  name="reason"
                  component={RadioButtonField}
                  label="(Select best option)"
                  options={shuffledReasonOptions}
                />
                {values.reason === 'other' && (
                  <FormField
                    name="otherReasonDescription"
                    component={InputField}
                    placeholder="Please provide some additional details."
                  />
                )}
              </div>
              <FormField
                name="comments"
                component={InputField}
                label="Anything else you&apos;d like to share?"
                multiline
                placeholder="We'd love to hear any details on the above or other suggestions!"
              />
            </Form>
          );
        }}
      </Formik>
      <div className={classes.warning}>
        <IconInfoCircle />
        Please note: Once you cancel your subscription,
        you will no longer be able to access your generations after this billing cycle ends.
      </div>
    </>
  );
};

export default CancelationForm;
