import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Add } from '@material-ui/icons';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useCommonStyles from '../TemplateCard.styles';

import useStyles from './NewCustomTemplateButton.styles';

const DESCRIPTION = 'Use this tool to create your own content generator within Copysmith. Once approved by Copysmith your entire team will have access to the generator to output content.';

const NewCustomTemplateButton = ({ disabled, openLimitsModal }) => {
  const history = useHistory();

  const classes = useStyles();
  const commonStyles = useCommonStyles();

  const goToTemplateBuilder = () => {
    history.push('/template-builder');
  };

  return (
    <div
      className={clsx(commonStyles.card, classes.buttonRoot, disabled && classes.disabled)}
      onClick={(disabled && openLimitsModal) || (!disabled && goToTemplateBuilder)}
    >
      <div className={classes.iconBox}>
        <Add fontSize="large" color={disabled ? 'disabled' : 'primary'} />
      </div>
      <h2 className={commonStyles.title}>
        New custom content generator
      </h2>
      <SimpleTooltip
        content={DESCRIPTION}
      >
        <div className={commonStyles.description}>
          {DESCRIPTION}
        </div>
      </SimpleTooltip>

    </div>
  );
};

export default NewCustomTemplateButton;
