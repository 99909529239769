import React from 'react';

import useStyles from './QualityTrack.styles';

const QualityTracking = ({ quality }) => {
  const classes = useStyles(quality);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.badgeWrap}>
          <div className={classes.qualityBadge}>
            {quality.id}
          </div>
        </div>
        <div className={classes.barContainer}>
          <div className={classes.leftBar} />
          <div className={classes.midBar} />
          <div className={classes.rightBar} />
        </div>
        <div className={classes.descriptionContainer}>
          <div className={classes.qualityDescription}>
            {quality.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityTracking;
