import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  blogKickstarterRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    maxWidth: 1032,
    flex: 1,
    marginBottom: ({ step }) => (step === 3 ? 0 : 48),
    height: ({ step }) => (step === 3 ? 0 : 'unset'),
  },
  generationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    backgroundColor: theme.palette.colors.white,
    width: 760,
    padding: '24px 32px',
    flex: 1,
    alignSelf: 'center',
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.mediumLightGrey}`,
  },
  main: {
    margin: 0,
    padding: '32px max(calc(100% - 587px) / 2, 72px)',
    alignSelf: 'unset',
    display: 'block',
  },
}));

export default useStyles;
