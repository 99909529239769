import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '39px',
    textAlign: 'center',
    marginBottom: 24,
  },
});

export default useStyles;
