import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bulkCopyButton: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
    width: '128px',
    padding: '6px',

    '&:disabled': {
      color: theme.palette.colors.black,
    },

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals100,
    },
  },
  startIcon: {
    color: theme.palette.colors.black,
  },
}));

export default useStyles;
