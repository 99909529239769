import { useQuery } from 'react-query';

import api from '@root/api';

const getFile = async (fileId) => {
  const file = await api.googleAd.getFile(fileId);
  return file;
};

const useFile = (fileId) => useQuery(['googleAds.getFile', fileId], () => getFile(fileId));

export default useFile;
