import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  emptyViewContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '39px',
    marginBottom: 0,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '24px',
    maxWidth: '607px',
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

export default useStyles;
