import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: theme.palette.colors.neutrals50,
    flexGrow: 1,
    padding: theme.spacing(2, 0, 0, 0),
  },
  table: {
    overflow: 'auto',
    backgroundColor: theme.palette.colors.neutrals50,
  },
  tableRow: {
    height: 1,
    cursor: 'pointer',

    '& td, th': {
      height: 'inherit',
      border: 'none',
      padding: theme.spacing(0, 0, 1, 0),
    },
    '& td:first-child': {
      paddingLeft: theme.spacing(2),
      width: theme.spacing(4.5),

      '& $bodyCellContent': {
        borderTopLeftRadius: theme.border.radius.md,
        borderBottomLeftRadius: theme.border.radius.md,
        paddingLeft: 5,
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1.25),
      },
    },
    '& th:first-child': {
      paddingLeft: 21,
      width: theme.spacing(4.5),
    },
    '& td:last-child': {
      paddingRight: theme.spacing(2),

      '& $bodyCellContent': {
        borderTopRightRadius: theme.border.radius.md,
        borderBottomRightRadius: theme.border.radius.md,
      },
    },
  },
  bodyCellContent: {
    backgroundColor: theme.palette.colors.white,
    minHeight: 56,
    height: '100%',
    padding: theme.spacing(2.25, 2),
    display: 'flex',
  },
  imageWrap: {
    height: theme.spacing(5),
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.colors.neutrals100,
    borderRadius: theme.border.radius.default,
    marginTop: -theme.spacing(1.25),

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  stub: {
    height: theme.spacing(4.5),
    '& td': {
      border: 'none',
    },
  },
  productCell: {
    display: 'flex',
    marginLeft: -11,
  },
  productName: {
    fontWeight: 500,
    color: theme.palette.colors.black,
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  channelSelect: {
    width: 400,
    marginRight: theme.spacing(2),
  },
  localeSelect: {
    width: 128,
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarLeftSide: {
    display: 'flex',
  },
  fieldSelect: {
    width: 200,
  },
}));

export default useStyles;
