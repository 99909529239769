import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

type ListNotificationsQuery = { startDate?: number, endDate?: number } & RequestOptions;
type ReadNotificationPayload = { id: string } & RequestOptions;

export class Notification {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async listNotifications(data: ListNotificationsQuery, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/notifications", data, options);

    return result;
  }

  public async readNotification(data: ReadNotificationPayload, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/notifications/${data.id}/read`, data, options);

    return result;
  }
}