import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  documentControlButton: {
    fontSize: '1.2em',
    color: '#000429',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  redDocumentControlButton: {
    '&:hover': {
      color: 'red',
    },
  },
}));
