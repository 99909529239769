import React from 'react';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import useEngines from '@root/resources/engine/useEngines';
import uiNotificationService from '@root/services/uiNotification.service';
import useInvalidateLimits from '@root/resources/billing/useInvalidateLimits';

import { compareFileData } from '../blockEditor.helper';

const useGenerate = ({
  template,
  file,
  fileData,
  fileSettings,
  setIsMagicLoading,
  updateFile,
  generateContent,
  pageRef,
  isFileSettingsChanged,
}) => {
  const { data: engineData } = useEngines();
  const { invalidateLimits } = useInvalidateLimits();

  const generate = React.useCallback(async ({ likeId } = {}) => {
    const {
      language,
      outputLanguage,
      aiOptions: {
        engine,
        ...aiOptions
      },
    } = fileSettings;

    const validEngine = !engineData || engineData.length === 0 ? 'default' : fileSettings.aiOptions.engine;

    eventsAggregator.publish(
      EVENT_NAMES.GENERATE_BUTTON_CLICKED,
      { template: template.templateType, engine: validEngine },
    );

    if (!likeId) {
      const requiredFields = template.fields.filter((f) => f.required);
      const reqFieldsEmpty = requiredFields.some((f) => (f.type !== 'keywords' ? !fileData[f.name] : fileData[f.name].length === 0));
      if (reqFieldsEmpty) {
        uiNotificationService.showErrorMessage('Please fill in all required elements and try again. Tip: You need to press \'enter\' after entering keywords!');
        return;
      }

      const minFields = template.fields.filter((f) => f.minimum);
      const shortMinField = minFields
        .find((f) => (f.wordCount ? fileData[f.name].split(' ') : fileData[f.name]).length < f.minimum);
      if (shortMinField) {
        uiNotificationService.showErrorMessage(
          shortMinField.minimumLabel
          || `Minimum length of "${shortMinField.label}" is ${shortMinField.minimum} characters`,
        );
        return;
      }

      setIsMagicLoading(true);
      const isDataEqual = compareFileData(file, fileData);

      if (!isDataEqual || validEngine !== engine) {
        await updateFile({
          fileId: file._id,
          data: {
            data: fileData,
            negativeKeywordArray: file.negativeKeywordArray,
            language,
            ...(outputLanguage && { outputLanguage }),
            engine: validEngine,
          },
        });
      }
    } else {
      setIsMagicLoading(true);
    }

    const requestData = {
      fileId: file._id,
      language,
      ...(outputLanguage && { outputLanguage }),
      type: template.templateType,
      ...(isFileSettingsChanged && { aiOptions }),
    };
    if (likeId) {
      requestData.likeId = likeId;
    }
    if (fileData.tone) {
      requestData.tone = fileData.tone;
    }
    if (validEngine) {
      requestData.engine = validEngine;
    }
    await generateContent(requestData);
    setIsMagicLoading(false);
    pageRef.current.scrollTop = 0;
    invalidateLimits();
  }, [
    fileData,
    updateFile,
    fileSettings,
    file,
    template,
    setIsMagicLoading,
    isFileSettingsChanged,
    engineData,
  ]);

  return generate;
};

export default useGenerate;
