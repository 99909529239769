import { UseQueryOptions, UseQueryResult, useQuery, useQueryClient } from 'react-query';

import api from '@root/api';

const getLimits = async () => {
  const data = await api.billing.getLimits({});

  return data;
};

const pollForOverageInvoiceStatus = (data: any, query: any): boolean => {
  if (data?.overageInvoiceStatus === 'processing' && query.state.dataUpdateCount < 5) {
    return true;
  }

  return false;
};

const useLimits = <T = any>(
  queryOptions?: UseQueryOptions<any, Error, T, [string, any]>,
): UseQueryResult<T, Error> => {
  const queryClient = useQueryClient();

  return useQuery(['billing.getLimits', { }], () => getLimits(), {
    onSuccess: (data: any) => {
      queryClient.setQueryData(['billing.getInfo', {}], (billingInfo: any) => ({
        addons: {},
        ...billingInfo,
        overageInvoiceStatus: data?.overageInvoiceStatus,
        overageUnitsRemaining: data?.overageUnitsRemaining,
      }));

      if (data?.overageInvoiceStatus === 'failed') {
        queryClient.invalidateQueries(['notification.listNotifications']);
      }
    },
    refetchInterval: (data, query) => (pollForOverageInvoiceStatus(data, query) ? 3000 : false),
    ...queryOptions,
  });
};

export default useLimits;
