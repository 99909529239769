import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  IconPuzzle,
  IconUsers,
  IconSettings,
  IconDiamond,
  IconCell,
  IconStack2,
  IconFeather,
  IconArticle,
  IconBook2,
  IconHome2,
  IconPencil,
} from '@tabler/icons-react';
import { ObjectUtil } from '@copysmith/utils';
import { Typography } from '@material-ui/core';

import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import config from '@config';
import Logo from '@root/assets/logo/white.svg';
import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { featureEnabled, featureToggles } from '@root/utils/features';
import useBillingInfo from '@root/resources/billing/useBillingInfo';

import useStyles from './LeftSidebar.styles';

const { insertInArrayIf: insertIf } = ObjectUtil;

const LeftSidebar = () => {
  const { currentUser } = AuthContext.useAuth();

  const {
    data: billingInfo,
  } = useBillingInfo();

  const isCancelled = billingInfo?.status === 'cancelled';

  const isBulkCopySelected = window.location.href.includes('/bulk-copy');
  const isTeamSelected = window.location.href.includes('/profile/team-members');
  const isAddonsSelected = window.location.href.includes('/addons');
  const isRewardsSelected = window.location.href.includes('/rewards');
  const superAdminSelected = window.location.href.includes('/super-admin');
  const isTemplateSelected = window.location.href.includes('/templates');
  const isCommunitySelected = window.location.href.includes('/community');
  const isProfileSelected = window.location.href.includes('/profile');
  const isAliSelected = window.location.href.includes('/engines');
  const isUsageSelected = window.location.href.includes('/usage');
  const isCatalogSelected = window.location.href.includes('/catalog');
  const isHomeSelected = !isProfileSelected
    && !isBulkCopySelected
    && !isAliSelected
    && !isAddonsSelected
    && !superAdminSelected
    && !isTemplateSelected
    && !isRewardsSelected
    && !isCommunitySelected
    && !isUsageSelected
    && !isCatalogSelected;

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { toggles: { redirectToApp, hideCreateAndExplore, disableSidebar, disableNavHome } } = currentExternalSource;

  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;
  const isTemplatesLibraryAvailable = currentUser.featureToggles.templatesLibrary;
  const isNewLeftSidebar = currentUser.featureToggles.newLeftSidebar;
  const isHomePageAllowed = currentUser.featureToggles.homePage;
  const isNew2023Navigation = currentUser.featureToggles.new2023Navigation;
  const catalogPCM = currentUser.featureToggles.catalogPCM || featureEnabled(featureToggles.catalogPCM);
  const classes = useStyles({ newLeftSidebar: isNewLeftSidebar });

  if (disableSidebar) {
    return null;
  }

  const navigation = [
    ...insertIf(!disableNavHome, !isHomePageAllowed ? {
      id: 'link_Home',
      to: '/create',
      Icon: IconFeather,
      active: isHomeSelected,
      label: 'Create',
    } : {
      id: 'link_Home',
      to: '/home',
      Icon: IconHome2,
      active: isHomeSelected,
      label: 'Home',
    }),
    ...(!isNew2023Navigation ? [
      ...insertIf(catalogPCM, {
        id: 'link_Catalog',
        to: '/catalog',
        Icon: IconBook2,
        active: isCatalogSelected,
        label: 'Catalog',
      }),
      {
        id: 'link_Files',
        to: '/templates',
        Icon: IconArticle,
        active: isTemplateSelected,
        label: 'Files',
      },
      {
        id: 'link_BulkCopy',
        to: redirectToApp ? { pathname: `${config.appUrl}/bulk-copy/new` } : '/bulk-copy',
        target: redirectToApp && '_blank',
        Icon: IconStack2,
        active: isBulkCopySelected,
        label: isNewLeftSidebar ? 'Bulk' : 'Bulk Copy',
      },
      ...insertIf(!isNewLeftSidebar && isAdminOrOwner, {
        id: 'link_Teams',
        to: '/profile/team-members',
        Icon: IconUsers,
        active: isTeamSelected,
        label: 'Team',
      }),
      ...insertIf(!isNewLeftSidebar, {
        id: 'link_Addons',
        to: '/addons',
        Icon: IconPuzzle,
        active: isAddonsSelected,
        label: 'Add-ons',
      }),
      ...insertIf(isTemplatesLibraryAvailable && !hideCreateAndExplore, {
        id: 'community',
        to: '/community',
        Icon: IconCell,
        active: isCommunitySelected,
        label: 'Create Explore',
      }),
      // ...insertIf(
      //   !isNewLeftSidebar && currentUser && !currentUser.onFreePlan && currentUser.featureToggles.rewardsPage,
      //   {
      //     id: 'link_Rewards',
      //     to: '/rewards',
      //     Icon: IconDiamond,
      //     active: isRewardsSelected,
      //     label: 'Rewards',
      //   },
      // ),
      ...insertIf(currentUser.roles.superAdmin, {
        id: 'link_SuperAdmin',
        to: '/super-admin/templates',
        Icon: IconSettings,
        active: superAdminSelected,
        label: 'Super Admin',
      }),
    ] : [
      ...insertIf(catalogPCM, {
        id: 'link_Catalog',
        to: '/catalog',
        Icon: IconBook2,
        active: isCatalogSelected,
        label: 'Products',
      }),
      {
        id: 'link_Editor',
        to: '/editor/new',
        Icon: IconPencil,
        active: false,
        label: 'Editor',
      },
      {
        id: 'link_Files',
        to: '/templates',
        Icon: IconArticle,
        active: isTemplateSelected,
        label: 'Files',
      },
      {
        id: 'link_BulkCopy',
        to: redirectToApp ? { pathname: `${config.appUrl}/bulk-copy/new` } : '/bulk-copy',
        target: redirectToApp && '_blank',
        Icon: IconStack2,
        active: isBulkCopySelected,
        label: isNewLeftSidebar ? 'Bulk' : 'Bulk Copy',
      },
    ]),
  ];

  return (
    <div className={clsx(classes.sidebarRoot, { disabled: isCancelled })}>
      <Link to={config.homeRoute}>
        <img
          src={Logo}
          alt="Logo"
          className={clsx(classes.sidebarLogo, 'logo')}
        />
      </Link>

      <div className={classes.links}>
        {navigation.map(({ id, Icon, to, active, target, label, disabled }) => {
          return (
            <>
              {isNewLeftSidebar && (
                <Link
                  key={id}
                  className={clsx(classes.menuItemContainer, { active, disabled })}
                  to={to}
                  target={target}
                  id={id}
                >
                  <Icon />

                  <Typography className={classes.itemLabel}>{label}</Typography>
                </Link>
              )}

              {!isNewLeftSidebar && (
                <SimpleTooltip
                  content={label}
                  placement="right"
                  key={id}
                >
                  <Link
                    className={clsx(classes.menuItemContainer, { active })}
                    to={to}
                    target={target}
                    id={id}
                  >
                    <Icon />
                  </Link>
                </SimpleTooltip>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default LeftSidebar;
