import React from 'react';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';

import permissionsService from '@root/services/permissions.service';
import AuthContext from '@root/resources/auth/auth.context';
import TooltipIcon from '@root/components/tooltips/TooltipIcon';
import { WORDS_MAX_CAP } from '@root/resources/billing/billingInfo.helpers';

import PlanButton from '../PlanButton/PlanButton';
import PlanInfo from '../PlanInfo';

import FeatureRow from './components/FeatureRow';
import CollapsibleSubtable from './components/CollapsibleSubtable';
import MonthlyCredit from './components/MonthlyCredit';
import useRowStyles from './components/FeatureRow.styles';
import useStyles from './PlanTable.styles';

const plansRank = {
  starter: 150,
  pro: 250,
  enterprise: 500,
};

const isFeatureAllowed = (feature, options) => (rank) => {
  const { allowedComponent, disallowedComponent, featureToggles } = options;

  return permissionsService.featureAllowed(feature, { rank }, featureToggles)
    ? allowedComponent
    : disallowedComponent;
};

const getAllowedFeatureArray = (options) => (feature) => {
  const isAllowed = isFeatureAllowed(feature, options);
  return [
    isAllowed(plansRank.starter),
    isAllowed(plansRank.pro),
    isAllowed(plansRank.enterprise),
  ];
};

const features = [
  { name: 'teams', title: 'Teams & Collaboration' },
  { name: 'bulk-product-descriptions', title: 'Bulk product descriptions' },
  { name: 'bulk-content-generation', title: 'Bulk content generation' },
  { name: 'bulk-import-export', title: 'Bulk import & export' },
  { name: 'custom-templates', title: 'Custom templates' },
  { name: 'project-sharing', title: 'Project & file sharing' },
  { name: 'workflow-queue', title: 'Workflow Queue' },

  { name: 'campaign-builder', title: 'Campaign Builder' },

  { name: 'integrations', title: 'Integrations' },
  { name: 'google-ads', title: 'Google Ads' },
  { name: 'shopify', title: 'Shopify' },
  { name: 'hootsuite', title: 'Hootsuite' },
  { name: 'frase', title: 'Frase SEO Enhancer' },
  { name: 'google-docs', title: 'Google Docs' },
  { name: 'chrome-extensions', title: 'Chrome Extension' },
  { name: 'wordpressEcommercePlugin', title: 'WooCommerce' },
  { name: 'ms-word-add-in', title: 'Microsoft Word' },
  { name: 'zapier', title: 'Zapier' },
  { name: 'rest', title: 'API' },

  { name: 'support-service', title: 'Support Services' },
  { name: 'account-manager', title: 'Account manager' },
  { name: 'custom-onboarding', title: 'Custom Onboarding' },
  { name: 'inapp-chat', title: 'In-app chat' },
  { name: 'email', title: 'Email' },
];

const featureSections = [
  ['teams', 'bulk-product-descriptions', 'bulk-content-generation', 'bulk-import-export', 'custom-templates', 'project-sharing', 'workflow-queue'],
  ['campaign-builder'],
  ['integrations', 'google-ads', 'shopify', 'hootsuite', 'frase', 'zapier', 'google-docs', 'chrome-extensions', 'wordpressEcommercePlugin', 'ms-word-add-in', 'rest'],
  ['support-service', 'account-manager', 'custom-onboarding', 'inapp-chat', 'email'],
];

const PlanTable = ({ pricingData, tableRef, period }) => {
  const { currentUser } = AuthContext.useAuth();
  const classes = useStyles();
  const rowClasses = useRowStyles();

  const check = <Check style={{ fontSize: '20px' }} />;

  const additionalFeatureProps = {
    teams: {
      itemClassname: classes.firstInColumn,
      isBold: true,
    },
    'campaign-builder': { noPadding: true },
    integrations: {
      isBold: true,
    },
    'support-service': {
      isBold: true,
    },
  };

  const needFeatureToggles = {
    hootsuite: currentUser.featureToggles.hootsuite,
    zapier: currentUser.featureToggles.zapier,
    'google-docs': currentUser.featureToggles.googleDocs,
    woocommerce: currentUser.featureToggles.wordpressEcommercePlugin,
    'microsoft-word': currentUser.featureToggles.MSWordAddIn,
  };

  const getAllowedArray = getAllowedFeatureArray({
    allowedComponent: check,
    disallowedComponent: '',
    featureToggles: currentUser.featureToggles,
  });

  const renderFeatureRow = (feature, props = {}) => {
    return (
      <FeatureRow
        title={feature.title}
        columns={getAllowedArray(feature.name)}
        key={feature.name}
        {...props}
      />
    );
  };

  return (
    <div className={classes.planTableRoot} ref={tableRef}>
      <div className={clsx(classes.desktop, 'desktop')}>
        <div className={classes.plansWrapper}>
          <div className={classes.space} />
          {pricingData.map((p) => {
            return (
              <div
                className={classes.planInfoWrapper}
                key={p._id}
              >
                <PlanInfo
                  plan={p}
                  period={period}
                />
              </div>
            );
          })}
        </div>

        {featureSections.map((featureSection) => {
          const needFeaturesKeys = Object.keys(needFeatureToggles);

          const withFeatureCheck = featureSection.filter((feature) => {
            return !needFeaturesKeys.includes(feature) || needFeatureToggles[feature];
          });

          const featuresInSection = withFeatureCheck
            .map((f) => features.find((feat) => feat.name === f));

          if (!featuresInSection.length) {
            return null;
          }

          if (featuresInSection.length === 1) {
            const feature = featuresInSection[0];
            return renderFeatureRow(feature, additionalFeatureProps[feature.name]);
          }

          return (
            <CollapsibleSubtable key={featureSection[0]}>
              {featuresInSection.map((feature) => {
                return renderFeatureRow(
                  feature,
                  additionalFeatureProps[feature.name],
                );
              })}
            </CollapsibleSubtable>
          );
        })}

        <CollapsibleSubtable>
          <FeatureRow
            isBold
            title="Words"
            columns={[]}
          />
          <FeatureRow
            title="Monthly words"
            columns={[
              <MonthlyCredit key="1" title={pricingData[0]?.data.limits.mainCredits === WORDS_MAX_CAP ? 'Unlimited' : `${(new Intl.NumberFormat('en-Us').format(pricingData[0]?.data.limits.mainCredits))} words / month`} />,
              <MonthlyCredit key="2" title={pricingData[1]?.data.limits.mainCredits === WORDS_MAX_CAP ? 'Unlimited' : `${(new Intl.NumberFormat('en-Us').format(pricingData[1]?.data.limits.mainCredits))} words / month`} />,
              <MonthlyCredit
                key="4"
                title={(
                  <>
                    Unlimited&nbsp;
                    <TooltipIcon content="Enterprise users can purchase as many generation words as needed, subject to terms and conditions." />
                  </>
                )}
              />,
            ]}
          />
          <FeatureRow title="Additional words" columns={[period === 'monthly' ? check : '', check, '']} />
          <FeatureRow title="Plagiarism checks" columns={['20 / month', '100 / month', 'Unlimited']} />
        </CollapsibleSubtable>
        <FeatureRow
          noPadding
          title="Free team members"
          columns={['1', '5', '10']}
        />
        <FeatureRow
          noPadding
          title="Additional users"
          columns={['$9 / month', '$9 / month', '$9 / month']}
        />
        <FeatureRow
          noPadding
          title="Ali's expertise"
          columns={[check, check, check]}
        />
        <FeatureRow
          noPadding
          title="Personalized copy"
          columns={[check, check, check]}
        />
        <CollapsibleSubtable>
          <FeatureRow
            isBold
            title="Custom Content Builder"
            columns={[check, check, check]}
          />
          <FeatureRow title="Custom generators" columns={['3', '10', '50']} />
          <FeatureRow title="Test credits" columns={['25 / month', '50 / month', 'Unlimited']} />
        </CollapsibleSubtable>
      </div>

      <div className={classes.buttonsWrapper}>
        {pricingData.map((p) => {
          return (
            <div
              className={clsx(rowClasses.rowItem, classes.buttonWrapper)}
              key={p._id}
            >
              <PlanButton
                plan={p}
                period={period}
                fullWidth
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlanTable;
