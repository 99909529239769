import { useQuery } from 'react-query';

import api from '@root/api';

const list = async (filters) => {
  if (!filters.templateType) {
    return null;
  }
  const { results: items } = await api.company.list(filters);

  return items;
};

export default function useCompanies(filters, { enabled } = { enabled: true }) {
  return useQuery(
    ['companies.list'],
    () => list(filters),
    { enabled },
  );
}
