import React from 'react';

const Success = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 110 100"
      className={className}
    >
      <path fill="currentColor" d="M101.408 1.339a4.452 4.452 0 0 1 6.365 0 4.624 4.624 0 0 1 0 6.465L54.131 62.297a4.45 4.45 0 0 1-6.365 0l-14.63-14.862a4.623 4.623 0 0 1 0-6.465 4.45 4.45 0 0 1 6.365 0L50.948 52.6l25.23-25.631 25.23-25.63Z" />
      <path fill="currentColor" d="M91.551 45.769a4.224 4.224 0 1 1 8.449 0v4.257c-.008 13.358-5.216 25.911-14.665 35.35C75.893 94.805 63.345 100 50.001 100h-.03c-13.355-.008-25.908-5.216-35.347-14.665C5.186 75.885-.008 63.327 0 49.972c.008-13.356 5.216-25.91 14.665-35.348C24.107 5.194 36.655 0 49.999 0h.03A49.683 49.683 0 0 1 70.35 4.329a4.224 4.224 0 0 1-3.438 7.717 41.293 41.293 0 0 0-16.888-3.597h-.025c-11.089 0-21.516 4.315-29.363 12.153-7.853 7.843-12.18 18.275-12.187 29.374-.013 22.912 18.616 41.562 41.527 41.575h.025c11.09 0 21.517-4.316 29.363-12.153 7.853-7.843 12.181-18.275 12.187-29.374v-4.255Z" />
    </svg>
  );
};

export default Success;
