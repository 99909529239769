import React, { useState } from 'react';
import classNames from 'clsx';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';

import Modal from '@root/components/modals/Modal';
import templatesContext from '@root/resources/templates/templates.context';
import { InputField, SelectField } from '@root/components/form';
import uiNotificationService from '@root/services/uiNotification.service';
import ProgressButton from '@root/components/ProgressButton';
import documentResource from '@root/resources/document';

import googleDocsFile from '../resources';

import useStyles from './PublishDocsDialog.styles';

const documentLinkPrefix = 'https://docs.google.com/document/d/';

const PublishDocsDialog = ({
  document,
  fileId,
  onClose,
  integrationId,
}) => {
  const documentId = document?._id;
  const { getTemplate } = templatesContext.useTemplates();

  const { stringifyContent } = getTemplate(document.templateType);
  const { mutateAsync: publishDocument } = documentResource.usePublishDocument();
  const {
    data: documents,
    isLoading: isLoadingDocuments,
    refetch: refetchDocuments,
  } = googleDocsFile.useDocuments();
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [isNewDocumentSelected, setIsNewDocumentSelected] = useState(false);
  const [newDocumentTitle, setNewDocumentTitle] = useState('');
  const [documentLink, setDocumentLink] = useState('');
  const { mutateAsync: insertToDocument, isLoading: isInserting } = googleDocsFile
    .useInsertToDocument();
  const { mutateAsync: createDocument, isLoading: isCreating } = googleDocsFile
    .useCreateDocument();

  const canPublish = selectedDocumentId || isNewDocumentSelected;
  const isPublishing = isInserting || isCreating;
  const isLoading = isLoadingDocuments;

  const classes = useStyles();

  const toggleNewDocument = () => {
    const newValue = !isNewDocumentSelected;
    setIsNewDocumentSelected(newValue);
    if (!newValue) {
      setNewDocumentTitle('');
    }
  };

  const onChangeDocumentTitle = (event) => {
    setNewDocumentTitle(event.target.value);
  };

  const publish = async () => {
    let response = null;
    const text = stringifyContent(document.content);
    if (isNewDocumentSelected) {
      response = await createDocument({
        title: newDocumentTitle || 'Untitled',
        text,
      });
      refetchDocuments();
    } else {
      response = await insertToDocument({
        documentId: selectedDocumentId,
        data: { text },
      });
    }

    if (!response || response.isBadRequest) {
      return;
    }

    setDocumentLink(`${documentLinkPrefix}${response.documentId}`);

    const { isBadRequest } = await publishDocument({
      _id: documentId,
      fileId,
      data: {
        integrationId,
        externalId: response.documentId,
      },
    });
    if (isBadRequest) {
      return;
    }

    uiNotificationService.showSuccessMessage('Content published.');
  };

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    onSubmit: publish,
  });

  const onDocumentChange = async (e) => {
    setSelectedDocumentId(e.target.value);
  };

  const submitForm = async () => {
    await formik.submitForm();
  };

  const title = (
    <div className={classes.modalTitleWrap}>
      <div>Publish to Google Docs</div>
      {isLoading && <CircularProgress size={20} className={classes.titleProgress} />}
    </div>
  );

  return (
    <Modal
      open
      onClose={onClose}
      className={classes.modal}
      title={title}
    >
      <div className={classes.details}>
        Select document to publish

      </div>
      <div className={classes.input}>
        {isNewDocumentSelected
          ? (
            <InputField
              value={newDocumentTitle}
              onChange={onChangeDocumentTitle}
              label="Document Title"
              placeholder="Untitled"
              name="newDocumentTitle"
              fullWidth
            />
          )
          : (
            <SelectField
              label="Select Document"
              placeholder="None selected"
              isDisabled={isNewDocumentSelected}
              value={selectedDocumentId}
              onChange={onDocumentChange}
              options={documents}
            />
          )}

        <div>
          <Button
            variant="text"
            onClick={toggleNewDocument}
            className={classNames(
              classes.button,
              classes.newAdGroup,
            )}
          >
            { isNewDocumentSelected ? 'Use existing document' : '+ Create new document' }
          </Button>
        </div>

      </div>

      <div className={classes.footer}>
        {documentLink
          ? (
            <a
              className={classNames(
                classes.button,
                classes.newAdGroup,
              )}
              href={documentLink}
              target="blank"
            >
              View Google Document
            </a>
          )
          : <div />}
        <div>
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={onClose}
            size="small"
          >
            Cancel
          </Button>
          <ProgressButton
            onClick={submitForm}
            disabled={!canPublish || isPublishing}
            size="small"
            isLoading={isPublishing}
          >
            Publish
          </ProgressButton>
        </div>

      </div>
    </Modal>
  );
};

export default PublishDocsDialog;
