import React from 'react';

import Button from '@root/components/buttons/Button';
import authContext from '@root/resources/auth/auth.context';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import { ADD_PRODUCT_MODAL } from '../AddProductModal';

import useStyles from './NoCatalogItems.styles';

const NoCatalogItems = ({ productsRemaining }) => {
  const classes = useStyles();
  const { currentUser } = authContext.useAuth();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleCreateClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: ADD_PRODUCT_MODAL },
    });
  };

  const getTooltipContent = () => {
    if (productsRemaining <= 0) {
      return 'You do not have any products remaining.';
    }

    return '';
  };

  return (
    <div className={classes.page}>
      <p className={classes.header}>
        Hi&nbsp;
        {currentUser.displayName || currentUser.firstName || currentUser.email}
        , getting started is easy
      </p>
      <p variant="body2" className={classes.subHeader}>
        Looks like your catalog is feeling a little lonely!
        <br />
        Simply click the &lsquo;Add Product&lsquo; button to start uploading your items.
        Once a product is added you&lsquo;ll be able to generate content for all your products.
      </p>
      <div>
        <SimpleTooltip
          content={getTooltipContent()}
          wrapped
          placement="bottom"
        >
          <Button
            onClick={handleCreateClick}
            className={classes.createNewButton}
            disabled={productsRemaining <= 0}
          >
            Add Product
          </Button>
        </SimpleTooltip>
      </div>
    </div>
  );
};

export default NoCatalogItems;
