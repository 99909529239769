import React from 'react';
import { components } from 'react-select';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import UpdatePlanTooltip from '@root/components/UpdatePlanTooltip';
import DEPRECATEDSingleSelect from '@root/components/Interactive/DEPRECATEDSingleSelect';

const CustomSelectOption = (props) => {
  const { isDisabled } = props;

  if (isDisabled) {
    return (
      <UpdatePlanTooltip>
        <div style={{ height: '100%' }}>
          {/*  eslint-disable-next-line react/jsx-props-no-spreading */}
          <components.Option {...props} />
        </div>
      </UpdatePlanTooltip>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      {/*  eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props} />
    </div>
  );
};

const AliField = ({
  className,
  formattedEngines,
  onChange,
  fieldValue,
}) => {
  const options = [
    { value: 'default', label: 'None' },
    ...formattedEngines,
  ];

  const onAliOptionChange = (e) => {
    eventsAggregator.publish(EVENT_NAMES.FINE_TUNE_SELECTED, { fineTuneId: e.value });
    onChange(e);
  };

  return (
    <div className={className} key="engine">
      <DEPRECATEDSingleSelect
        label="Ali's Expert Voice"
        placeholder="None"
        options={options}
        handleChange={onAliOptionChange}
        required={false}
        value={fieldValue}
        components={{ Option: CustomSelectOption }}
      />
    </div>
  );
};

export default AliField;
