import safeLocalStorage from './safeLocalStorage.service';

export const getInFileNavCollapseState = () => {
  return safeLocalStorage.getItem('inFileNavCollapseState') || 'false';
};
export const setInFileNavCollapseState = (state) => {
  return safeLocalStorage.setItem('inFileNavCollapseState', state);
};

export const getBlockEditorSettingsOpenState = () => {
  return safeLocalStorage.getItem('blockEditorSettingsOpenState') || 'false';
};
export const setBlockEditorSettingsOpenState = (state) => {
  return safeLocalStorage.setItem('blockEditorSettingsOpenState', state);
};

export const getBlockEditorInputsOpenState = () => {
  return safeLocalStorage.getItem('blockEditorInputsOpenState') || 'false';
};
export const setBlockEditorInputsOpenState = (state) => {
  return safeLocalStorage.setItem('blockEditorInputsOpenState', state);
};

export const getOnboardingMinimizedState = () => {
  return safeLocalStorage.getItem('onboardingMinimizedState') || 'false';
};
export const setOnboardingMinimizedState = (state) => {
  return safeLocalStorage.setItem('onboardingMinimizedState', state);
};

export const getOnboardingRewardModalState = () => {
  return safeLocalStorage.getItem('onboardingRewardModalState') || 'false';
};
export const setOnboardingRewardModalState = (state) => {
  return safeLocalStorage.setItem('onboardingRewardModalState', state);
};

export const getAlmostExpiredCardAlertHiddenState = () => {
  return safeLocalStorage.getItem('almostExpiredCardAlertHiddenState') || 'false';
};
export const setAlmostExpiredCardAlertHiddenState = (state) => {
  return safeLocalStorage.setItem('almostExpiredCardAlertHiddenState', state);
};

export const getOverageAlertHiddenState = (key) => {
  return safeLocalStorage.getItem(`overageAlertHiddenState-${key}`) || 'false';
};
export const setOverageAlertHiddenState = (state, key) => {
  return safeLocalStorage.setItem(`overageAlertHiddenState-${key}`, state);
};

export const getLeftNavigationMenuHint = () => {
  return JSON.parse(safeLocalStorage.getItem('leftMenuHint'));
};
export const setLeftNavigationMenuHint = (state) => {
  return safeLocalStorage.setItem('leftMenuHint', state);
};

export const getHideAIImageHints = () => {
  return safeLocalStorage.getItem('hideAiImageHints') || 'false';
};
export const setHideAIImageHints = (state) => {
  return safeLocalStorage.setItem('hideAiImageHints', state);
};

export const getHideArtStudioCTA = () => {
  return safeLocalStorage.getItem('hideArtStudioCTA') || 'false';
};
export const setHideArtStudioCTA = (state) => {
  return safeLocalStorage.setItem('hideArtStudioCTA', state);
};

const BIG_COMMERCE_STUB = {
  isBigCommerce: false,
  payload: {},
};
export const getBigCommerceState = () => {
  try {
    const value = safeLocalStorage.getItem('bigCommerceState');
    return value ? JSON.parse(value) : { isBigCommerce: false, payload: {} };
  } catch (error) {
    return BIG_COMMERCE_STUB;
  }
};
export const setBigCommerceState = (state) => {
  return safeLocalStorage.setItem('bigCommerceState', JSON.stringify(state));
};
