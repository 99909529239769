import React from 'react';

const actions = Object.freeze({
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
});

const initialState = {
  modalOpen: false,
  modalId: null,
  modalContent: null,
  filters: {},
};

const ModalsContext = React.createContext({});

const modalsReducer = (state, action) => {
  switch (action.type) {
    case actions.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
        modalId: null,
        modalContent: null,
      };
    case actions.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
        modalId: action.payload.modalId,
        modalContent: action.payload.modalContent,
      };
    default: {
      throw new Error(`Modals Context Reducer: Unhandled action type: ${action.type}`);
    }
  }
};

const ModalsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalsReducer, initialState);

  const handleOpenModal = (id) => {
    dispatch({
      type: actions.OPEN_MODAL, payload: { modalId: id },
    });
  };

  const value = { state, dispatch, onOpenModal: handleOpenModal };
  return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
};

const useGlobalModals = () => {
  const context = React.useContext(ModalsContext);

  if (context === undefined) {
    throw new Error('useModals must be used within a ModalsProvider');
  }

  return context;
};

export {
  ModalsProvider, useGlobalModals, actions,
};
