import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { TabContext } from '@material-ui/lab';
import { MuiThemeProvider } from '@material-ui/core';

import MainLayout from '@root/views/Layout/MainLayout';
import authContext from '@root/resources/auth/auth.context';
import newMaterialTheme from '@root/newMaterial.theme';
import config from '@root/config';

import { CreateAndExploreContextProvider } from './context/CreateAndExplore.context';
import CreateAndExploreModals from './Modals';
import Header from './Header/Header';
import Content from './Content';
import useStyles from './CreateAndExplore.styles';

const CreateAndExplore = () => {
  const classes = useStyles();

  const { currentUser } = authContext.useAuth();

  if (!currentUser.featureToggles.templatesLibrary) {
    return <Redirect to={config.homeRoute} />;
  }

  return (
    <MuiThemeProvider theme={newMaterialTheme}>
      <MainLayout>
        <CreateAndExploreContextProvider>
          {({ state: { activeTab } }) => (
            <TabContext value={activeTab}>
              <div className={classes.community}>
                <Header />

                <Content />

                <CreateAndExploreModals />
              </div>
            </TabContext>
          )}
        </CreateAndExploreContextProvider>
      </MainLayout>
    </MuiThemeProvider>
  );
};

export default CreateAndExplore;
