import React from 'react';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import {
  GetApp,
  TrendingUpOutlined,
  CloudUploadOutlined,
  ClearOutlined,
  NoteAddOutlined,
} from '@material-ui/icons';

import TruncatedText from '@root/components/TruncatedText';
import authContext from '@root/resources/auth/auth.context';
import getTemplateActionStatuses from '@root/views/Community/Community.helpers';
import uiNotificationService from '@root/services/uiNotification.service';
import { templateCategories } from '@root/views/Community/Community.constants';

import { useCommunityContext } from '../../../../context/Community.context';

import useStyles from './ListContentView.styles';

const memberTooltipContent = 'This action can only be performed by Administrators.';

const ListContentView = () => {
  const classes = useStyles();

  const { currentUser: user, currentCompany: company } = authContext.useAuth();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;

  const {
    templates,
    templatesToShow,
    handlePreviewTemplateClick,
    handleSaveToMyAccountClick,
    handleUploadTemplateToCommunityClick,
    handleRemoveFromCommunityClick,
    handleRemoveFromLibraryClick,
    state: { templatesCategory },
  } = useCommunityContext();

  return (
    <div className={classes.listContentContainer}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell>Template Name</TableCell>
            <TableCell>Template Description</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell align="center">Total Generations</TableCell>
            <TableCell align="center">Total Downloads</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {templatesToShow.map((template) => {
            const createdBy = `${template?.user?.firstName || ''} ${template?.user?.surname || ''}`;
            const isMe = template?.user?._id === user?._id;

            const isCommunityCategory = templatesCategory === templateCategories.COMMUNITY;

            const isMeVisible = isMe;

            const {
              isDownloadedByMe,
              isRemoveFromCommunityVisible,
              isRemoveFromLibraryVisible,
              isSaveToAccountVisible,
              isUploadToCommunityVisible,
              correspondingTemplate,
            } = getTemplateActionStatuses({ templates, template, user, templatesCategory, company });

            return (
              <TableRow key={template._id}>
                <TableCell>
                  <TruncatedText
                    variant="body2"
                    onClick={() => (isCommunityCategory ? handlePreviewTemplateClick(template) : null)}
                    className={clsx(
                      classes.templateTitle,
                      { [classes.link]: isCommunityCategory },
                    )}
                  >
                    {template?.title || '–'}
                  </TruncatedText>
                </TableCell>

                <TableCell>
                  <TruncatedText variant="body2">{template?.description || '–'}</TruncatedText>
                </TableCell>

                <TableCell>
                  <TruncatedText variant="body2">{`${isMeVisible ? 'You' : createdBy}` || '-'}</TruncatedText>
                </TableCell>

                <TableCell align="center">
                  <div className={classes.counterContainer}>
                    {!!template?.childDocumentsCount && (
                      <>
                        <div className={clsx(classes.counterIconContainer, 'secondary')}>
                          <TrendingUpOutlined className={classes.counterIcon} />
                        </div>
                        {template?.childDocumentsCount}
                      </>
                    )}

                    {!template?.childDocumentsCount && '-'}
                  </div>
                </TableCell>

                <TableCell align="center">
                  <div className={classes.counterContainer}>
                    {!!template?.downloadsCount && (
                      <>
                        <div className={classes.counterIconContainer}>
                          <GetApp className={classes.counterIcon} />
                        </div>
                        {template?.downloadsCount}
                      </>
                    )}

                    {!template?.downloadsCount && '-'}
                  </div>
                </TableCell>

                <TableCell align="center">
                  {isRemoveFromCommunityVisible && (
                    <Tooltip
                      arrow
                      title={isAdminOrOwner ? 'Remove from Community' : memberTooltipContent}
                      classes={{
                        arrow: classes.actionIconTooltipArrow,
                        tooltip: classes.actionIconTooltip,
                      }}
                    >
                      <ClearOutlined
                        className={clsx(
                          classes.actionIcon,
                          { [classes.disabled]: !isAdminOrOwner },
                        )}
                        onClick={(event) => handleRemoveFromCommunityClick(event, template)}
                      />
                    </Tooltip>
                  )}

                  {isRemoveFromLibraryVisible && (
                    <Tooltip
                      arrow
                      title={(isAdminOrOwner || isDownloadedByMe) ? 'Remove from Account' : memberTooltipContent}
                      classes={{
                        arrow: classes.actionIconTooltipArrow,
                        tooltip: classes.actionIconTooltip,
                      }}
                    >
                      <ClearOutlined
                        className={clsx(
                          classes.actionIcon,
                          { [classes.disabled]: !isAdminOrOwner && !isDownloadedByMe },
                        )}
                        onClick={(event) => {
                          if (isAdminOrOwner || isDownloadedByMe) {
                            handleRemoveFromLibraryClick(event, correspondingTemplate?._id);
                            return;
                          }
                          uiNotificationService.showWarnMessage('This action can only be performed by Administrators.');
                        }}
                      />
                    </Tooltip>
                  )}

                  {isUploadToCommunityVisible && (
                    <Tooltip
                      arrow
                      title={isAdminOrOwner ? 'Upload to Community' : memberTooltipContent}
                      classes={{
                        arrow: classes.actionIconTooltipArrow,
                        tooltip: classes.actionIconTooltip,
                      }}
                    >
                      <CloudUploadOutlined
                        className={clsx(
                          classes.actionIcon,
                          { [classes.disabled]: !isAdminOrOwner },
                        )}
                        onClick={(event) => handleUploadTemplateToCommunityClick(event, template)}
                      />
                    </Tooltip>
                  )}

                  {isSaveToAccountVisible && (
                    <Tooltip
                      arrow
                      title="Save to My Account"
                      classes={{
                        arrow: classes.actionIconTooltipArrow,
                        tooltip: classes.actionIconTooltip,
                      }}
                    >
                      <NoteAddOutlined
                        className={clsx(
                          classes.actionIcon,
                        )}
                        onClick={(event) => handleSaveToMyAccountClick(event, template?._id)}
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ListContentView;
