import { HttpClient } from "../HttpClient";
import { HandlingOptions } from "../HandlingOptions";

export default class PromptItem {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public list(
    data: unknown,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/promptItems", data, options);
  }

  public create(
    data: unknown,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.post("/promptItems", data, options);
  }

  public update(
    data: unknown,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put("/promptItems", data, options);
  }

  public remove(
    data: any,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.del(`/promptItems/${data.id}`, data, options);
  }
}
