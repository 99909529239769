import React from 'react';
import { truncate } from 'lodash';
import { IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

import AuthContext from '@root/resources/auth/auth.context';
import NavbarLayout from '@root/views/Layout/NavbarLayout';
import ShadowLoginInfo from '@root/views/Layout/components/ShadowLoginInfo';

import useStyles from './EditorNav.styles';

const EditorNav = ({
  folder,
  fileTitle,
  onFileTitleChange,
  onClose,
  readOnly,
  isPendingChanges,
}) => {
  const { currentUser } = AuthContext.useAuth();
  const classes = useStyles({ isNewEditor: currentUser.featureToggles.newEditor });

  const isHomeFolder = folder && folder.home;

  const folderTitle = isHomeFolder ? 'Other' : folder && folder.title;

  const handleTitleChange = (e) => {
    onFileTitleChange(e.target.value);
  };

  return (
    <NavbarLayout
      newEditor
      onClose={onClose}
    >
      {currentUser.isShadowLogin && <ShadowLoginInfo user={currentUser} />}
      <div />
      <div className={classes.title}>
        <span>
          {`${truncate(folderTitle, { length: 15 })} / `}
        </span>
        <input
          disabled={readOnly}
          className={classes.fileTitle}
          value={fileTitle}
          onChange={handleTitleChange}
          style={{
            width: `${fileTitle.length / 1.3 + 1}ch`,
          }}
          id="input-fileTitle"
        />
        {readOnly && (
          <span className={classes.viewOnly}>
            View only
          </span>
        )}
      </div>

      <div className={classes.rightSide}>
        <IconButton
          id="button-close"
          className={classes.closeButton}
          classes={{
            root: classes.closeButton,
            disabled: classes.disabledButton,
          }}
          disabled={isPendingChanges}
          onClick={onClose}
        >
          <CloseRounded />
        </IconButton>
      </div>
    </NavbarLayout>
  );
};

export default EditorNav;
