import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3.5rem 2rem',
  },
  product: {
    flexGrow: 1,
    cursor: 'pointer',
    maxWidth: '49%',
    '&&:first-child': {
      marginRight: '0.5rem',
    },
    textAlign: 'center',
  },
  startIcon: {
    width: 16,
    height: 16,
    strokeWidth: 3,
  },
  image: {
    padding: '1rem 1rem',
    marginBottom: '0.5rem',
    maxWidth: '100%',
    borderRadius: '8px',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.65rem',
  },
  title: {
    fontSize: '1.35rem',
    textAlign: 'center',
    marginBottom: '0.65rem',
  },
  description: {
    fontSize: '0.85rem',
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
}));

export default useStyles;
