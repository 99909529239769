import CSapi from '@root/api/newApi';

import './CustomImage.css';

export default class SimpleImage {
  constructor({ data, config, api, readOnly, block }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;
    this.block = block;
    this.CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      wrapper: 'cdx-custom-image',
      imageHolder: 'cdx-custom-image__picture',
    };

    this.nodes = {
      wrapper: null,
      imageHolder: null,
      image: null,
    };

    this.data = {
      url: data.url || '',
    };
  }

  render() {
    const wrapper = this._make('div', [this.CSS.baseClass, this.CSS.wrapper]);
    const loader = this._make('div', this.CSS.loading);
    const imageHolder = this._make('div', this.CSS.imageHolder);
    const image = this._make('img');
    wrapper.appendChild(loader);

    if (this.data.url) {
      image.src = this.data.url;
    }

    image.onload = () => {
      wrapper.classList.remove(this.CSS.loading);
      imageHolder.appendChild(image);
      wrapper.appendChild(imageHolder);
      loader.remove();
    };

    image.onerror = (event) => {
      // eslint-disable-next-line no-console
      console.error('Failed to load an image', event);
      loader.remove();
      const index = this.api.blocks.getCurrentBlockIndex();
      this.api.blocks.delete(index);
    };

    this.nodes.imageHolder = imageHolder;
    this.nodes.wrapper = wrapper;
    this.nodes.image = image;
    return wrapper;
  }

  save(blockContent) {
    const image = blockContent.querySelector('img');

    if (!image) {
      return this.data;
    }

    return Object.assign(this.data, {
      url: image.src,
    });
  }

  static get sanitize() {
    return {
      url: {},
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  async onDropHandler(file) {
    const formData = new FormData();
    formData.append('files', file);
    const { url } = await CSapi.documents.uploadImage({ documentId: this.config.documentId, formData });
    return url;
  }

  onPaste(event) {
    switch (event.type) {
      case 'pattern': {
        const { data: text } = event.detail;
        this.data = {
          url: text,
        };
        break;
      }

      case 'file': {
        const { file } = event.detail;
        this.onDropHandler(file)
          .then((url) => {
            this.data.url = url;
            this.nodes.image.src = url;
          });
        break;
      }

      default: {
        break;
      }
    }
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = { ...this.data, ...data };

    if (this.nodes.image) {
      this.nodes.image.src = this.data.url;
    }
  }

  static get pasteConfig() {
    return {
      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|png)$/i,
      },

      files: {
        extensions: ['gif', 'jpg', 'jpeg', 'png'],
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
