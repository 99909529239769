export default {
  title: 'Facebook Ad',
  description: 'Craft the perfect headline and primary text.',
  input: [
    {
      title: 'Company Name',
      value: 'Dollar Shave Club',
    },
    {
      title: 'Description',
      value:
        'We sell everything you need in the bathroom – from razor blades to grooming products for a small fee. ',
    },
    {
      title: 'Ad keywords',
      value: 'Quality, Cheap, Subscription, Gender neutral ',
    },
  ],
  output: {
    content: [
      'A top-shelf grooming routine',
      'Shop Now',
      "It's 2016. Who says a lady's razor has to be pink? Dollar Shave Club delivers amazing razors (to both genders) for just a few bucks a month. Try the Club today.",
    ],
    templateType: 'facebookAd',
    liked: false,
    _id: '1',
    editing: false,
    votes: null,
  },
  fileData: {
    companyName: 'Dollar Shave Club',
  },
};
