import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: '"Inter", sans-serif',
    width: '100%',
    overflowY: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    padding: '24px max(calc(100% - 1440px) / 2, 72px)',
    background: theme.palette.colors.neutrals50,
    '& a': {
      color: theme.palette.colors.primary,
    },
  },
}));

export default useStyles;
