import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="currentColor"
    >
      <path d="M2 6c0-.368.298-.667.667-.667h6.666a.667.667 0 1 1 0 1.334H2.667A.667.667 0 0 1 2 6Z" />
      <path d="M2.667.667c.368 0 .666.298.666.666v9.334a.667.667 0 1 1-1.333 0V1.332c0-.368.298-.666.667-.666ZM9.333.667c.368 0 .667.298.667.666v9.334a.667.667 0 1 1-1.334 0V1.332c0-.368.299-.666.667-.666Z" />
      <path d="M7.333 10.667c0-.368.299-.667.667-.667h2.667a.667.667 0 0 1 0 1.333H8a.667.667 0 0 1-.667-.666ZM7.333 1.333c0-.368.299-.666.667-.666h2.667a.667.667 0 1 1 0 1.333H8a.667.667 0 0 1-.667-.667ZM.667 10.667c0-.368.298-.667.666-.667H4a.667.667 0 0 1 0 1.333H1.333a.667.667 0 0 1-.666-.666ZM.667 1.333c0-.368.298-.666.666-.666H4A.667.667 0 0 1 4 2H1.333a.667.667 0 0 1-.666-.667Z" />
    </svg>
  );
};
