// Disabled eslint to enable dynamic config
/* eslint-disable global-require */
const env = process.env.REACT_APP_ENV || 'development';

let config = require(`./${env}.json`); // eslint-disable-line import/no-dynamic-require, import/no-mutable-exports, @typescript-eslint/no-var-requires

if (env === 'development') {
  try {
    // eslint-disable-next-line import/no-unresolved, @typescript-eslint/no-var-requires
    const localConfig = require('./local.json');
    config = {
      ...config,
      ...localConfig,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.info('Add local.json file to the ./config folder to create your personal config.');
  }
}

export default config;
