import StackdriverErrorReporter from 'stackdriver-errors-js';

import config from '@root/config';

const errorHandler = new StackdriverErrorReporter();

const start = () => {
  errorHandler.start({
    key: config.stackdriver.publicKey,
    projectId: config.stackdriver.projectId,

    // The following optional arguments can also be provided:

    // service: myServiceName,
    // Name of the service reporting the error, defaults to 'web'.

    // version: myServiceVersion,
    // Version identifier of the service reporting the error.

    // reportUncaughtExceptions: false
    // Set to false to prevent reporting unhandled exceptions, default: `true`.

    // reportUnhandledPromiseRejections: false
    // Set to false to prevent reporting unhandled promise rejections, default: `true`.

    disabled: !config.clientLoggingEnabled,
    // Set to true to not send error reports,
    // this can be used when developing locally, default: `false`.

    // context: {user: 'user1'}
    // You can set the user later using setUser()
  });
};

const service = {
  start,
  setUser: (userId) => {
    errorHandler.setUser(userId);
  },
  reportError: (err) => {
    errorHandler.report(err);
  },
};

export default service;
