import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { ThumbUpAlt, ThumbUpAltOutlined, ThumbDownAlt, ThumbDownAltOutlined } from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DocumentUtil } from '@copysmith/utils';

import api from '@root/api';
import { InputField } from '@root/components/form';
import useCreateFile from '@root/resources/file/useCreateFile';
import useCreateDocument from '@root/resources/document/useCreateDocument';
import useSubmitDocumentExampleToReview from '@root/resources/documentExample/useSubmitDocumentExampleToReview';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';
import Modal from '@root/components/modals/Modal';
import { homeFolder as getHomeFolder } from '@root/views/Dashboard/helpers';
import useFolders from '@root/resources/folder/useFolders';
import billingResource from '@root/resources/billing';
import aiResource from '@root/resources/ai';
import { useTemplatesDashboard, actions, SEARCH_FIELD_TYPES } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import uiService from '@root/services/uiNotification.service';
import authContext from '@root/resources/auth/auth.context';

import useStyles from './Instruct.styles';

const SPECIAL_TEMPLATE_TYPE = 'special.instruct';
const FFSE_TEMPLATE_TYPE = 'freeFormEditor';
const DEFAULT_FILE_NAME = 'Untitled File';

const defaultOptions = [
  { useCase: 'product description', topic: 'ukraine 3x5 flag' },
  { useCase: 'google ad', topic: 'merino wool socks' },
  { useCase: 'amazon product description', topic: '7th generation laptop' },
  { useCase: 'blog idea', topic: 'starting a new eCommerce company' },
  { useCase: 'short email subject line', topic: 'a holiday promo to increase sales' },
  { useCase: 'instagram ad', topic: 'cushioned canoe seat' },
  { useCase: 'facebook ad', topic: 'hiking boots' },
  { useCase: 'ad headline', topic: 'women’s silk blouse' },
  { useCase: 'article title', topic: 'top consumer tech trends' },
];

const REACTIONS = {
  LIKE: 'good',
  DISLIKE: 'bad',
  NONE: 'none',
};

const MAX_USE_CASE_LENGTH = 50;
const MAX_TOPIC_LENGTH = 150;
const FORBIDDEN_CHARS = ['/', ':', '[', ']', '{', '}', '$', '%', '*', '(', ')', '=', '|', '\\'];

const SHORT_INPUTS_TOOLTIP_TEXT = 'Try longer inputs to create new content.';

const DISLIKE_TOOLTIP_TEXT = 'Click to flag this content for poor quality and generate a different version.';
const LIKE_TOOLTIP_TEXT = 'Click to mark this content as good quality and use it to create a new file.';

const fetchInstructGeneration = async ({ fileId, useCase, topic }) => {
  try {
    const result = await api.ai.generateByInstruction({
      useCase,
      topic,
      ...(fileId && { fileId }),
    });

    const isError = result.errors && result.errors.length;
    return { ...result, isError };
  } catch (error) {
    const RATE_LIMIT_STATUS = 429;
    if (error?.response.status === RATE_LIMIT_STATUS && error.response.data) {
      uiService.showErrorMessage(error.response.data);
    }
    return { isError: true };
  }
};

const checkForForbiddenCharacters = ({ text, chars }) => chars.some((char) => text.includes(char));

const Instruct = () => {
  const { data: folders } = useFolders();
  const history = useHistory();

  const { currentUser } = authContext.useAuth();

  const { data: limitsData } = billingResource.useLimits();
  const { invalidateLimits } = billingResource.useInvalidateLimits();
  const creditsLeft = limitsData && limitsData.limit;

  const homeFolderId = getHomeFolder(folders)._id;

  const classes = useStyles();
  const { mutateAsync: createFile } = useCreateFile();
  const { mutateAsync: createDocument } = useCreateDocument();

  const { mutateAsync: submitDocumentExample } = useSubmitDocumentExampleToReview();
  const { mutateAsync: submitPersonalize } = aiResource.usePersonalize();

  const { dispatch, state: { useCaseValue: useCase } } = useTemplatesDashboard();

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isFFSECreationLoading, setIsFFSECreationLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [useCasePlaceholder, setUseCasePlaceholder] = useState('');
  const [topicPlaceholder, setTopicPlaceholder] = useState('');

  const [topic, setTopic] = useState('');

  const [fileId, setFileId] = useState(null);
  const [generationId, setGenerationId] = useState(null);
  const [logRecordId, setLogRecordId] = useState(null);
  const [generation, setGeneration] = useState(null);
  const [reaction, setReaction] = useState(REACTIONS.NONE);

  const { refetchOnboarding } = onboardingContext.useOnboarding();

  useEffect(() => {
    const getOptionIndex = (length) => Math.floor(Math.random() * length);

    const optionIndex = getOptionIndex(defaultOptions.length);

    setUseCasePlaceholder(defaultOptions[optionIndex].useCase);
    setTopicPlaceholder(defaultOptions[optionIndex].topic);
  }, []);

  const createNewGeneration = async () => {
    setIsGenerationLoading(true);
    const {
      result,
      fileId: newFileId,
      documentId: newDocumentId,
      isError,
      logRecordId: newLogRecordId,
    } = await fetchInstructGeneration({
      useCase,
      topic,
      ...(fileId && { fileId }),
    });

    if (isError) {
      setIsGenerationLoading(false);
      setReaction(REACTIONS.NONE);
      return;
    }

    setFileId(newFileId);
    setGenerationId(newDocumentId);
    setLogRecordId(newLogRecordId);
    setGeneration(result.text);
    setReaction(REACTIONS.NONE);
    setIsGenerationLoading(false);
    setIsModalOpen(true);
  };

  const createNewFFSEFile = async () => {
    setIsFFSECreationLoading(true);
    const ffseFile = await createFile({
      title: DEFAULT_FILE_NAME,
      templateType: FFSE_TEMPLATE_TYPE,
      folderId: homeFolderId,
    });

    const content = currentUser.featureToggles.newEditor
      ? { blocks: [DocumentUtil.createEditorParagraph(generation)] }
      : generation;

    const ffseDoc = await createDocument({
      fileId: ffseFile._id,
      content,
      isFromInstruct: true,
      logRecordId,
    });

    await invalidateLimits();

    setIsFFSECreationLoading(false);
    history.push(`/documents/${ffseFile._id}/${ffseDoc._id}`);
  };

  const submitReaction = async (reactionType) => {
    if (reactionType === REACTIONS.DISLIKE) {
      await submitDocumentExample({
        fileId,
        documentId: generationId,
        content: generation,
        type: reactionType,
      });
    }

    if (reactionType === REACTIONS.LIKE) {
      await submitPersonalize({ documentId: generationId, templateType: SPECIAL_TEMPLATE_TYPE });
    }
  };

  const onUseCaseChange = (e) => {
    const content = e.target.value;
    const { length } = content;

    if (
      length > MAX_USE_CASE_LENGTH
      || checkForForbiddenCharacters({ text: content, chars: FORBIDDEN_CHARS })
    ) {
      return;
    }

    dispatch({
      type: actions.SEARCH,
      payload: {
        type: SEARCH_FIELD_TYPES.USE_CASE,
        query: e.target.value,
      },
    });
  };

  const onTopicChange = (e) => {
    const content = e.target.value;
    const { length } = content;

    if (
      length > MAX_TOPIC_LENGTH
      || checkForForbiddenCharacters({ text: content, chars: FORBIDDEN_CHARS })
    ) {
      return;
    }

    setTopic(e.target.value);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setFileId(null);
    setGenerationId(null);
    setGeneration(null);
  };

  const onCreate = async () => {
    await createNewGeneration();
    refetchOnboarding('generateContent');
  };

  const onLike = async () => {
    setReaction(REACTIONS.LIKE);
    await submitReaction(REACTIONS.LIKE);
    await createNewFFSEFile();
  };

  const onDislike = async () => {
    setReaction(REACTIONS.DISLIKE);
    await submitReaction(REACTIONS.DISLIKE);
    await createNewGeneration();
  };

  const insufficientCredits = creditsLeft < 1 && creditsLeft !== null;
  const isTooShortInputs = useCase.length < 2 || topic.length < 15;

  return (
    <div className={classes.instructLanding}>
      <p className={classes.paragraph}>Write a(n)</p>
      <InputField
        className={classes.useCaseInput}
        placeholder={useCasePlaceholder}
        value={useCase}
        onChange={onUseCaseChange}
      />
      <p className={classes.paragraph}>about</p>
      <InputField
        className={classes.topicInput}
        value={topic}
        placeholder={topicPlaceholder}
        onChange={onTopicChange}
      />
      <SimpleTooltip
        content={SHORT_INPUTS_TOOLTIP_TEXT}
        wrapped
        disableHoverListener={!isTooShortInputs}
      >
        <Button
          disabled={isGenerationLoading || isTooShortInputs}
          className={classes.instructButton}
          onClick={onCreate}
        >
          {isGenerationLoading
            ? <CircularProgress size={24} color="inherit" />
            : 'Get started' }
        </Button>
      </SimpleTooltip>
      <Modal
        open={isModalOpen}
        onClose={onModalClose}
        classNames={{ paper: classes.instructModal }}
        maxWidth="lg"
        fullWidth
      >
        <div className={classes.modalTitle}>
          <p className={classes.paragraph}>{`Write a(n) ${useCase} about ${topic}`}</p>
          <DisabledGenerateTooltip
            enabled={insufficientCredits}
            notEnoughCredits
          >
            <Button
              disabled={isFFSECreationLoading || insufficientCredits}
              className={classes.createContentButton}
              variant="outlined"
              onClick={createNewFFSEFile}
            >
              {isFFSECreationLoading
                ? <CircularProgress size={24} color="inherit" />
                : 'Create content' }
            </Button>
          </DisabledGenerateTooltip>
        </div>
        <div className={classes.modalContainer}>
          {isGenerationLoading ? <CircularProgress size={24} color="inherit" /> : <p className={classes.modalParagraph}>{generation}</p>}
        </div>
        <div className={classes.reactions}>
          <SimpleTooltip
            content={LIKE_TOOLTIP_TEXT}
            wrapper
            placement="top"
          >
            {reaction === REACTIONS.LIKE
              ? (
                <ThumbUpAlt
                  fontSize="large"
                  className={clsx(classes.reactionButton, classes.likeButton)}
                />
              )
              : (
                <ThumbUpAltOutlined
                  onClick={insufficientCredits ? () => {} : onLike}
                  fontSize="large"
                  className={clsx(
                    classes.reactionButton,
                    classes.likeButton,
                    { [classes.likeDisabled]: insufficientCredits },
                  )}
                />
              )}
          </SimpleTooltip>
          <SimpleTooltip
            content={DISLIKE_TOOLTIP_TEXT}
            wrapper
            placement="top"
          >
            {reaction === REACTIONS.DISLIKE
              ? (
                <ThumbDownAlt
                  fontSize="large"
                  className={clsx(classes.reactionButton, classes.dislikeButton)}
                />
              )
              : (
                <ThumbDownAltOutlined
                  onClick={onDislike}
                  fontSize="large"
                  className={clsx(classes.reactionButton, classes.dislikeButton)}
                />
              )}
          </SimpleTooltip>
        </div>
      </Modal>
    </div>
  );
};

export default Instruct;
