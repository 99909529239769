import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';
import AuthContext from '@root/resources/auth/auth.context';

export default function useVoteDocument() {
  const queryClient = useQueryClient();
  const { currentUser } = AuthContext.useAuth();

  return useMutation(({ _id, vote }) => api.documents.vote(
    _id, { vote },
  ), {
    onMutate: async ({ _id, vote, fileId }) => {
      const queryKey = ['documents.getSharedDocuments', { fileId }];
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      const previousDocuments = queryClient.getQueryData(queryKey);
      const doc = previousDocuments.find((d) => d._id === _id);

      const updateDoc = {
        ...doc,
      };

      if (!updateDoc.votes[currentUser._id]) {
        updateDoc.votes[currentUser._id] = 0;
      }
      updateDoc.votes[currentUser._id] = vote;
      const updatedDocs = previousDocuments.map((d) => (d._id === _id ? updateDoc : d));

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, () => updatedDocs);

      return { previousDocuments, queryKey };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(context.queryKey, context.previousDocuments);
    },
  });
}
