import React from 'react';

import { actions, useGlobalModals } from '@root/views/Layout/modalsContext';
import CloneFileModal, { CLONE_FILE_MODAL_ID } from '@root/views/Dashboard/components/Modals/CloneFileModal';
import DeleteFileModal, { DELETE_FILE_MODAL_ID } from '@root/views/Dashboard/components/Modals/DeleteFileModal';
import EditPresetModal, { EDIT_PRESET_MODAL_ID } from '@root/views/Dashboard/components/Modals/EditPresetModal';
import MoveFileModal, { MOVE_FILE_MODAL_ID } from '@root/views/Dashboard/components/Modals/MoveFileModal';
import RenameFileModal, { RENAME_FILE_MODAL_ID } from '@root/views/Dashboard/components/Modals/RenameFileModal';
import RenameFolderModal, { RENAME_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals/RenameFolderModal';
import ShareFolderModal, { SHARE_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals/ShareFolderModal';
import MoveFolderModal, { MOVE_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals/MoveFolderModal';
import DeleteFolderModal, { DELETE_FOLDER_MODAL_ID } from '@root/views/Dashboard/components/Modals/DeleteFolderModal';
import ExportToCSVModal, { EXPORT_TO_CSV_MODAL_ID } from '@root/views/Dashboard/components/Modals/ExportToCSVModal';
import CancelSubscriptionModal, { CANCEL_SUBSCRIPTION_MODAL_ID } from '@root/views/Dashboard/components/Modals/CancelSubscriptionModal';

const DashboardModals = () => {
  const { state: { modalOpen, modalId }, dispatch } = useGlobalModals();

  const handleCloseModal = () => {
    dispatch({
      type: actions.CLOSE_MODAL,
    });
  };

  return (
    <>
      <CloneFileModal
        modalOpen={modalOpen && modalId === CLONE_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <DeleteFileModal
        modalOpen={modalOpen && modalId === DELETE_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <EditPresetModal
        modalOpen={modalOpen && modalId === EDIT_PRESET_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <MoveFileModal
        modalOpen={modalOpen && modalId === MOVE_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <RenameFileModal
        modalOpen={modalOpen && modalId === RENAME_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <RenameFolderModal
        modalOpen={modalOpen && modalId === RENAME_FOLDER_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <ShareFolderModal
        modalOpen={modalOpen && modalId === SHARE_FOLDER_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <MoveFolderModal
        modalOpen={modalOpen && modalId === MOVE_FOLDER_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <DeleteFolderModal
        modalOpen={modalOpen && modalId === DELETE_FOLDER_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <ExportToCSVModal
        modalOpen={modalOpen && modalId === EXPORT_TO_CSV_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <CancelSubscriptionModal
        open={modalOpen && modalId === CANCEL_SUBSCRIPTION_MODAL_ID}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default DashboardModals;
