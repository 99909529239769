import api from '@root/api';
import config from '@config';
import { downloadAsFile, makeFileName } from '@root/utils/Export';

/** replace escaped quotes in mongo (\") with escaped quote for csv ("") */
const escapeQuoteCSV = (str) => str.replaceAll('"', '""');

const joinObjectKeys = (obj) => (
  Object
    .entries(obj)
    .map((key, value) => {
      let result = '';
      if (typeof value === 'string') {
        result = value.trim();
      } else if (Array.isArray(value)) {
        result = value.join(',');
      } else {
        result = (value || '').toString();
      }

      return `${key}: ${result}`;
    })
    .join(' '));

const formatFileData = ({
  fileTitle, templateTitle, link, documents,
}) => {
  const trimmedTitle = fileTitle.trim();
  const formattedDocuments = documents
    .map((doc) => doc.content || '')
    .map(
      (content) => ((typeof content === 'object')
        ? joinObjectKeys(content)
        : content.trim()),
    )
    .map(escapeQuoteCSV);

  let str = `"${trimmedTitle}","${templateTitle}","${link}",`;
  str += formattedDocuments.map((doc) => `"${doc}"`).join(',');
  return str;
};

const processFile = async (file, templates) => {
  const { _id: fileId, templateType, title: fileTitle } = file;
  const { results: documents } = await api.documents.list({
    fileId,
  });

  const fileConfig = templates[templateType];
  const { slug: templateSlug, title: templateTitle } = fileConfig;

  const fileLink = encodeURI(`${config.appUrl}/${templateSlug}/${fileId}?title=${fileTitle}`);

  return {
    fileTitle,
    templateTitle,
    link: fileLink,
    documents,
  };
};

const exportCSV = async (allFiles, folderId, folderTitle, templates) => {
  const folderFiles = allFiles.filter((f) => f.folder === folderId);

  const filesAndDocuments = await Promise.all(
    folderFiles.map((file) => processFile(file, templates)),
  );

  const formattedData = filesAndDocuments.map(formatFileData);

  const dataRows = ['File Name,Type,Link,Generations', ...formattedData];

  let csvContent = '';
  dataRows.forEach((row) => {
    csvContent += `${row}\r\n`;
  });

  downloadAsFile(csvContent, makeFileName(folderTitle, 'export'));
};

export default {
  exportCSV,
};
