import React from 'react';
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Checkbox, IconButton, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import useGetProducts from '@root/resources/akeneo/useGetProducts';
import { InputField, SearchField } from '@root/components/form';
import useGetCategories from '@root/resources/akeneo/useGetCategories';
import useLimits from '@root/resources/billing/useLimits';
import useGetChannels from '@root/resources/akeneo/useGetChannels';
import useModalState from '@root/utils/hooks/useModalState';

import useStyles from './SelectProducts.styles';
import BottomToolbar from './BottomToolbar';
import CategoryModal from './CategoryModal';

const buildCategoriesTree = (array) => {
  const map = {};
  const result = [];

  array.forEach((item) => {
    map[item.code] = item;
    item.children = [];
  });

  array.forEach((item) => {
    if (item.parent) {
      const parent = map[item.parent];
      if (parent) {
        parent.children.push(item);
      }
    } else {
      result.push(item);
    }
  });

  return result;
};

const shortString = (str, length) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }

  return str;
};

const htmlToText = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html.replace(/\\n/gm, '<br />');
  const text = tmp.textContent || tmp.innerText || '';
  return shortString(text, 150);
};

const BodyCell = ({ children }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.bodyCell}>
      <div className={classes.bodyCellContent}>
        {children}
      </div>
    </TableCell>
  );
};

const getAllowedRowsCount = ({
  maxBulkGenerationRows,
  bulkGenerationRows,
}) => {
  if (bulkGenerationRows === null) {
    return maxBulkGenerationRows;
  }

  if (maxBulkGenerationRows > bulkGenerationRows) {
    return bulkGenerationRows;
  }

  return maxBulkGenerationRows;
};

const SelectProducts = ({
  onProductsSelect,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);
  const [savedNextLink, setSavedNextLink] = React.useState(null);
  const [isCategoryModalOpen, openCategoryOpenModal, closeCategoryModal] = useModalState(false);

  const { data: {
    maxBulkGenerationRows = 0,
    bulkGenerationRows = null,
  } = {} } = useLimits();

  const allowedRowsCount = React.useMemo(() => getAllowedRowsCount({
    maxBulkGenerationRows,
    bulkGenerationRows,
  }), [maxBulkGenerationRows, bulkGenerationRows]);

  console.log(selectedCategories);

  const {
    data: { data: rawProducts = [], next: nextLink } = {},
    refetch: reloadProducts,
    isFetching: isProductsLoading,
  } = useGetProducts({
    page,
    keyword,
    category: selectedCategories,
    include: ['categories'],
    next: page > 1 ? savedNextLink : null,
  });

  const products = React.useMemo(() => rawProducts.filter((product) => !!product.values.description), [rawProducts]);

  const {
    data: { data: categories = [] } = {},
  } = useGetCategories({});

  const categoriesTree = React.useMemo(() => buildCategoriesTree(categories), [categories]);
  const categoryObjects = React
    .useMemo(() => selectedCategories.map((c) => categories.find((cc) => cc.code === c), [
      categories,
      selectedCategories,
    ]));

  const {
    data: { data: channels = [] } = {},
  } = useGetChannels({});

  const onKeywordChange = (value) => {
    setKeyword(value);
    setPage(1);
  };
  const onCategoryChange = (c) => {
    setSelectedCategories(c);
    setSelectedProducts([]);
    setIsAllSelected(false);
    setPage(1);
    closeCategoryModal();
  };

  const dropCategory = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedCategories([]);
    setSelectedProducts([]);
    setIsAllSelected(false);
    setPage(1);
  };

  const onProductSelect = (id) => () => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.filter((p) => p !== id));
      setIsAllSelected(false);
    } else {
      setSelectedProducts([...selectedProducts, id]);
      setIsAllSelected(selectedProducts.length + 1 === products.length);
    }
  };

  const onAllSelect = () => {
    setIsAllSelected(!isAllSelected);

    if (!isAllSelected) {
      setSelectedProducts(products.map((p) => p.code));
    } else {
      setSelectedProducts([]);
    }
  };

  const next = async () => {
    try {
      setIsNextDisabled(true);
      await onProductsSelect(selectedProducts);
    } finally {
      setIsNextDisabled(false);
    }
  };

  React.useEffect(() => {
    const reload = async () => {
      setIsLoading(true);
      await reloadProducts();
      setIsAllSelected(false);
      setIsLoading(false);
    };
    reload();
  }, [keyword, selectedCategories, page]);

  React.useEffect(() => {
    setSavedNextLink(nextLink);
  }, [nextLink]);

  const onCancel = () => history.push('/');

  const onTableScroll = (e) => {
    if (isProductsLoading) {
      return;
    }

    const isScrolledToBottom = e.target.scrollHeight - 66 < e.target.scrollTop + e.target.clientHeight;

    if (!isScrolledToBottom) {
      return;
    }

    if (!savedNextLink) {
      return;
    }

    setPage(page + 1);
  };

  const rows = React.useMemo(() => products.map((product) => {
    const descriptions = channels.map((channel) => {
      const description = product.values.description?.find((d) => d.scope === channel.code && d.locale.startsWith('en'))?.data || '-';
      return { raw: description, text: htmlToText(description), channel: channel.code };
    });

    return (
      <TableRow onClick={onProductSelect(product.code)} className={classes.tableRow} key={product.id}>
        <BodyCell>
          <Checkbox
            checked={selectedProducts.includes(product.code)}
          />
        </BodyCell>
        <BodyCell>
          {/* <div className={classes.imageWrap}>
            {image && (<img src={image} alt="Product" />)}
          </div> */}
          {product.code}
        </BodyCell>
        <BodyCell>{product.categories || '-'}</BodyCell>
        {descriptions.map((description) => (
          <BodyCell key={description.channel}>
            <div>
              {description.text}
            </div>
          </BodyCell>
        ))}
      </TableRow>
    );
  }), [products, selectedProducts]);

  const toolbarText = React.useMemo(() => {
    if (selectedProducts.length === 0) {
      return 'No products selected';
    }

    return `${isAllSelected ? 'All ' : ''}${selectedProducts.length} ${pluralize('product', selectedProducts.length)} selected`;
  }, [selectedProducts, isAllSelected]);

  const isLimitOver = React.useMemo(() => {
    return selectedProducts.length > allowedRowsCount;
  }, [selectedProducts]);
  const tooltipText = React.useMemo(() => {
    if (bulkGenerationRows === 0) {
      return 'Monthly bulk generation limit exceeded. Contact sales to upgrade';
    }
    if (isLimitOver) {
      return `It seems like you're almost at your limit! Right now, you can only select up to ${allowedRowsCount} products.`;
    }

    return null;
  }, [isLimitOver]);

  return (
    <div className={classes.root}>
      <CategoryModal
        open={isCategoryModalOpen}
        categories={selectedCategories}
        categoriesTree={categoriesTree}
        onSelect={onCategoryChange}
        onClose={closeCategoryModal}
      />
      <div className={classes.searchWrap}>
        <SearchField
          onChange={onKeywordChange}
          classNames={{ container: classes.keywordSearch }}
          debounce
          placeholder="Search Products"
        />
        <InputField
          className={classes.categorySelect}
          placeholder="Select Category"
          value={categoryObjects.map((c) => c?.label || '').join(', ')}
          onClick={openCategoryOpenModal}
          startAdornment={selectedCategories.length > 0 && (
            <IconButton
              className={classes.dropCategory}
              disableRipple
              onClick={dropCategory}
            >
              <Close />
            </IconButton>
          )}
          onFocus={(e) => e.target.blur()}
          classes={{
            root: classes.selectRoot,
          }}
          inputProps={{
            className: classes.selectInput,
          }}
        />
      </div>
      <TableContainer className={classes.tableContainer} onScroll={onTableScroll}>
        {isLoading ? (
          <div className={classes.loadingWrap}>
            <CircularProgress />
          </div>
        ) : (
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableCell>
                <Checkbox
                  checked={isAllSelected}
                  onChange={onAllSelect}
                />
              </TableCell>
              <TableCell>
                Product Name
              </TableCell>
              <TableCell>Category</TableCell>
              {channels.map((channel) => {
                const { code } = channel;
                return (
                  <TableCell key={code}>
                    Description [
                    {code}
                    ]
                  </TableCell>
                );
              })}
            </TableHead>
            <TableBody>
              {rows}
              <TableRow className={classes.stub}>
                <TableCell>
                  <div className={classes.stub} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!isLoading && (
        <BottomToolbar
          text={toolbarText}
          next={{
            label: 'Process Bulk',
            disabled: selectedProducts.length === 0 || isNextDisabled || isLimitOver,
            tooltip: tooltipText,
            action: next,
          }}
          cancel={{
            label: 'Cancel',
            action: onCancel,
          }}
        />
      )}
    </div>
  );
};

export default SelectProducts;
