import React from 'react';

const useAtomRefs = ({ documents, highlightedDocumentIndex, contentRef }) => {
  const [atomRefs, setAtomRefs] = React.useState([]);
  React.useEffect(() => {
    if (documents.length > 0) {
      setAtomRefs((ref) => Array(documents.length)
        .fill()
        .map((r, i) => ref[i] || React.createRef()));
    }
  }, [documents]);

  React.useEffect(() => {
    if (
      atomRefs.length > 1
      && atomRefs[highlightedDocumentIndex]?.current
    ) {
      contentRef.current.scrollTop = atomRefs[highlightedDocumentIndex].current.offsetTop - 159;
    }
  }, [highlightedDocumentIndex]);

  return atomRefs;
};

export default useAtomRefs;
