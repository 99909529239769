import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateCatalogItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.catalogItem.create(data),
    {
      onSuccess: (data) => {
        if (data.isBadRequest) {
          return;
        }
        queryClient.invalidateQueries(['catalog.items', data.catalogItem.catalogId, '']);
        queryClient.invalidateQueries('billing.getLimits');
      },
    },
  );
}
