import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft, faGoogle } from '@fortawesome/free-brands-svg-icons';

import useStyles from './SharedComponents.styles';

export const GoogleSignInButton = ({ onClick, style }) => (
  <div onClick={onClick} style={style}>
    <Button size="large">
      <FontAwesomeIcon icon={faGoogle} />
      <span className="ml-3">Sign in with Google</span>
    </Button>
  </div>
);

export const MicrosoftSignInButton = ({ onClick, style }) => (
  <div onClick={onClick} style={style}>
    <Button size="large">
      <FontAwesomeIcon icon={faMicrosoft} />
      <span className="ml-3">Sign in with Outlook</span>
    </Button>
  </div>
);

export const GoogleSignUpButton = ({ onClick, style }) => (
  <div onClick={onClick} style={style}>
    <Button size="large">
      <FontAwesomeIcon icon={faGoogle} />
      <span className="ml-3">Sign up with Google</span>
    </Button>
  </div>
);

export const MicrosoftSignUpButton = ({ onClick, style }) => (
  <div onClick={onClick} style={style}>
    <Button size="large">
      <FontAwesomeIcon icon={faMicrosoft} />
      <span className="ml-3">Sign up with Outlook</span>
    </Button>
  </div>
);

export const DividerLine = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.divider}>
      <span className={classes.dividerLine} />
      <span className={classes.dividerText}>{text}</span>
      <span className={classes.dividerLine} />
    </div>
  );
};

export const RedirectLink = ({ to, text, linkText }) => {
  const classes = useStyles();

  return (
    <div className={classes.linkBlock}>
      {text}
      &nbsp;
      <Link className={classes.link} to={to}>
        {linkText}
      </Link>
    </div>
  );
};

export const { error } = cogoToast;
export const successToast = cogoToast.success;
