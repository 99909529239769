import React from 'react';
import clsx from 'clsx';
import { useHistory, Link } from 'react-router-dom';

import MainLayout from '@root/views/Layout/MainLayout';
import AuthContext from '@root/resources/auth/auth.context';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import Sidebar from './components/Sidebar';
import useStyles from './BulkCopyLayout.styles';

const BulkCopyLayout = ({
  children,
  classNames = {},
  sidebar = { type: 'default', page: 'newBulkCopy' },
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const handleConfirm = () => {
    history.push('/templates');
  };

  return (
    <>
      <MainLayout>
        <div className={clsx(classes.root, classNames.root)}>
          <Sidebar {...sidebar} />
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </MainLayout>
      {!currentUser.permissions.bulkCopy && (
        <ConfirmationModal
          title="Bulk Copy"
          text={(
            <div>
              Bulk Copy is a powerful feature that allows you to create content at scale.
              {' '}
              <br />
              {' '}
              With Bulk Copy you can save time and streamline your workflow.
              <br />
              <br />
              <Link to="/profile/plans">Click here</Link>
              {' '}
              to upgrade and access Bulk Copy today!
            </div>
          )}
          onConfirm={handleConfirm}
          noCancel
          noClose
          maxWidth="lg"
          confirmButtonText="Go back to your files page"
        />
      )}
    </>
  );
};

export default BulkCopyLayout;
