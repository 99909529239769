import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: theme.palette.colors.neutrals50,
    flexGrow: 1,
  },
  table: {
    overflow: 'auto',
    backgroundColor: theme.palette.colors.neutrals50,
  },
  tableHead: {
    zIndex: 1,
    '& th': {
      whiteSpace: 'nowrap',
      border: 'none',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '&  th:first-child': {
      paddingLeft: 21,
    },
    '&  th:last-child': {
    },
  },
  tableRow: {
    height: 1,
    cursor: 'pointer',

    '& td': {
      height: 'inherit',
      border: 'none',
      padding: theme.spacing(0, 0, 1, 0),
    },
    '& td:first-child': {
      paddingLeft: theme.spacing(2),

      '& $bodyCellContent': {
        borderTopLeftRadius: theme.border.radius.md,
        borderBottomLeftRadius: theme.border.radius.md,
        paddingLeft: 5,
      },
    },
    '& td:last-child': {
      paddingRight: theme.spacing(2),

      '& $bodyCellContent': {
        borderTopRightRadius: theme.border.radius.md,
        borderBottomRightRadius: theme.border.radius.md,
      },
    },
  },
  bodyCellContent: {
    backgroundColor: theme.palette.colors.white,
    minHeight: 56,
    height: '100%',
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  keywordSearch: {
    width: 456,
  },
  categorySelect: {
    width: 200,
  },
  searchWrap: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropCategory: {
    marginLeft: theme.spacing(-1.625),
  },
  imageWrap: {
    height: theme.spacing(5),
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.colors.neutrals100,
    borderRadius: theme.border.radius.default,

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  loadingWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  stub: {
    height: theme.spacing(4.5),
    '& td': {
      border: 'none',
    },
  },
}));

export default useStyles;
