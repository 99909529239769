/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Delete, Add, ArrowDropDown as Arrow, Check,
} from '@material-ui/icons';
import {
  IconButton, MenuItem, TextField,
  Divider, Typography, Popover, Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from '@root/components/Avatar';
import Button from '@root/components/buttons/Button';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import folderResource from '@root/resources/folder';
import fileResource from '@root/resources/file';
import AuthContext from '@root/resources/auth/auth.context';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Switch from '@root/components/Switch';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import useStyles from './ShareFolderModal.styles';

export const SHARE_FOLDER_MODAL_ID = 'SHARE_FOLDER_MODAL_ID';

const ROLES = [{
  value: 'owner',
  label: 'Owner',
}, {
  value: 'viewer',
  label: 'Viewer',
}, {
  value: 'editor',
  label: 'Editor',
}];

const ROLES_LABEL_MAP = {
  owner: 'Owner',
  viewer: 'Viewer',
  editor: 'Editor',
};

const MODAL_TYPES = {
  folder: 'folder',
  file: 'file',
};
/* Hacky guard for modal errors */
const ShareFolderModalInner = ({ closeModal, folder, file, type = MODAL_TYPES.folder }) => {
  let item = folder;
  let parentFolder;
  let singleFile;

  if (type === MODAL_TYPES.file) {
    singleFile = fileResource.useFile(file._id);
    item = singleFile.data;
  }

  if (type === MODAL_TYPES.file && item?.folderId) {
    const singleParentFolder = folderResource.useFolder(item.folderId);
    parentFolder = singleParentFolder?.data;
  }

  const classes = useStyles();

  const [isForParent, setIsForParent] = useState(false);

  const { currentUser } = AuthContext.useAuth();
  const { mutateAsync: shareFolder } = folderResource.useShareFolder();
  const { mutateAsync: shareFile } = fileResource.useShareFile();
  const { isFetching: isFetchingMembers, data: membersData } = useTeamMembers();

  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;

  const teamMembers = membersData ? membersData.results : [];

  const [teamMemberRows, setTeamMemberRows] = React.useState([{
    userId: '',
    role: 'viewer',
  }]);
  const [rules, setRules] = React.useState(item?.unifiedAcl || []);
  const [rulesChanged, setRulesChanged] = React.useState(false);
  const [popoverAnchorElem, setPopoverAnchorElem] = React.useState(null);
  const [selectedRule, setSelectedRule] = React.useState(null);
  const [removedItems, setRemovedItems] = React.useState([]);

  let itemPermissions;

  if (type === MODAL_TYPES.folder) {
    itemPermissions = currentUser.permissions.getFolderPermissions(item);
  }
  if (type === MODAL_TYPES.file) {
    itemPermissions = currentUser.permissions.getFilePermissions(item);
  }

  const {
    canAddMembers,
    canUpdateMemberPermissions,
    canRemoveMembers,
  } = itemPermissions;

  React.useEffect(() => {
    singleFile?.refetch();
  }, []);

  React.useEffect(() => {
    setRules(item?.unifiedAcl || []);
  }, [item?.unifiedAcl]);

  const closeDialog = () => {
    setTeamMemberRows([{
      userId: '',
      role: 'viewer',
    }]);
    setRemovedItems([]);
    closeModal();
  };

  const share = () => {
    const shareItem = type === MODAL_TYPES.folder ? shareFolder : shareFile;
    shareItem({
      _id: item._id,
      data: {
        acl: ([...rules, ...teamMemberRows]).filter((i) => !!i.userId),
        removedItems,
      },
    });

    if (isForParent && parentFolder) {
      shareFolder({
        _id: parentFolder._id,
        data: {
          acl: ([...rules, ...teamMemberRows]).filter((i) => !!i.userId),
          removedItems,
        },
      });
    }
    closeDialog();
  };

  const onTeamMemberChange = (index) => (e) => {
    setTeamMemberRows(teamMemberRows.map((tr, i) => {
      if (i === index) {
        return {
          ...tr,
          userId: e.target.value,
        };
      }

      return tr;
    }));

    setRemovedItems(removedItems.filter((i) => i !== e.target.value));
  };

  const onRoleChange = (index) => (e) => {
    setTeamMemberRows(teamMemberRows.map((tr, i) => {
      if (i === index) {
        return {
          ...tr,
          role: e.target.value,
        };
      }

      return tr;
    }));
  };

  const onRowAdd = () => {
    setTeamMemberRows([
      ...teamMemberRows,
      {
        userId: '',
        role: 'viewer',
      },
    ]);
  };

  const onRowDelete = (index) => () => {
    const row = teamMemberRows[index];
    setTeamMemberRows(teamMemberRows.filter((tr, i) => i !== index));

    if (item.unifiedAcl.some((i) => i.userId === row.userId)
      && rules.every((i) => i.userId !== row.userId)) {
      setRemovedItems([...removedItems, row.userId]);
    }
  };

  const { availableTeamMembersIds, isEmptyRowsExits } = React.useMemo(() => {
    const usedTeamMembersIds = [
      ...teamMemberRows.map((tr) => tr.userId),
      ...rules.map((r) => r.userId),
    ];

    return {
      availableTeamMembersIds: teamMembers
        .filter((t) => !usedTeamMembersIds.includes(t._id)).map((t) => t._id),
      isEmptyRowsExits: teamMemberRows.some((tr) => !tr.userId),
    };
  }, [teamMemberRows, teamMembers, rules]);

  const enrichedRules = React.useMemo(() => rules.map((r) => ({
    ...r,
    teamMember: teamMembers.find((t) => t._id === r.userId),
  })).filter((r) => !!r.teamMember), [rules, teamMembers]);

  const openPopover = (index) => (e) => {
    setPopoverAnchorElem(e.currentTarget);
    setSelectedRule(rules[index]);
  };

  const dropPopover = () => {
    setPopoverAnchorElem(null);
  };

  const onRuleChange = (userId, role) => () => {
    setRules(rules.map((r) => {
      if (r.userId === userId) {
        return {
          ...r,
          role,
        };
      }
      return r;
    }));

    setRulesChanged(true);

    dropPopover();
  };

  const onRuleRemove = (userId) => () => {
    dropPopover();
    setRules(rules.filter((r) => r.userId !== userId));
    setRulesChanged(true);
    setRemovedItems([...removedItems, userId]);
  };

  if (!item || isFetchingMembers) {
    return null;
  }
  const rolesWithoutOwner = ROLES.filter((r) => r.value !== 'owner');

  const folderCopy = type === MODAL_TYPES.folder && (item.parentFolderId ? 'folder' : 'project');

  return (
    <>
      <Popover
        open={!!popoverAnchorElem}
        anchorEl={popoverAnchorElem}
        onClose={dropPopover}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {selectedRule && rolesWithoutOwner.map((t) => (
          <MenuItem onClick={onRuleChange(selectedRule.userId, t.value)} key={t.value}>
            {t.label}
            {selectedRule.role === t.value && <Check className={classes.selectedRole} />}
          </MenuItem>
        ))}
        {canRemoveMembers && (
          <>
            <Divider />
            <MenuItem onClick={onRuleRemove(selectedRule && selectedRule.userId)}>Remove</MenuItem>
          </>
        )}
      </Popover>

      <ConfirmationModal
        onConfirm={share}
        isConfirmDisabled={isEmptyRowsExits && !rulesChanged}
        confirmButtonText="Update rules"
        onCancel={closeDialog}
        onClose={closeModal}
        title={`Share ${item.title} with team members`}
      >
        {canAddMembers && (
          <>
            {`Select which team member to share this ${folderCopy || 'file'} with. Selected team members can access this ${folderCopy || 'file'} ${folderCopy ? 'and all its contents.' : 'from within the folder it belongs in.'}`}
            {type === MODAL_TYPES.file && parentFolder && !parentFolder.home && (
              <div>
                <Switch checked={isForParent} onChange={() => setIsForParent((prev) => !prev)} />
                Share entire
                {' '}
                {parentFolder.title}
                {' '}
                {parentFolder.parentFolderId ? 'folder' : 'project'}
              </div>
            )}
            {teamMemberRows.map((tr, index) => (
              <div key={tr.userId} className={classes.teamMemberRow}>
                <TextField
                  disabled={!canAddMembers}
                  className={classes.teamMemberSelect}
                  value={tr.userId}
                  onChange={onTeamMemberChange(index)}
                  variant="outlined"
                  size="small"
                  select
                >
                  {teamMembers.map((t) => (
                    <MenuItem
                      disabled={!availableTeamMembersIds.includes(t._id)}
                      value={t._id}
                      key={t._id}
                    >
                      {t.displayName || t.email}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className={classes.roleSelect}
                  value={tr.role}
                  onChange={onRoleChange(index)}
                  variant="outlined"
                  size="small"
                  select
                >
                  {rolesWithoutOwner.map((t) => (
                    <MenuItem key={t.value} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </TextField>
                <IconButton
                  onClick={onRowDelete(index)}
                  className={classes.deleteTeamMemberRow}
                  size="small"
                  disabled={teamMemberRows.length === 1}
                >
                  <Delete />
                </IconButton>
              </div>
            ))}
            <Link
              component="button"
              onClick={onRowAdd}
              color="primary"
              className={classes.link}
            >
              <Add />
              Add another team member
            </Link>
            {isAdminOrOwner && (
              <div className={classes.inviteBlock}>
                Don&apos;t see your team member listed?
                <RouterLink
                  to={{
                    pathname: '/profile/team-members',
                    state: { tab: 'members' },
                  }}
                >
                  Invite them here
                </RouterLink>
              </div>
            )}
            <Divider className={classes.divider} />
          </>
        )}
        {enrichedRules.map((r, index) => (
          <div key={r.userId} className={classes.rule}>
            <div className={classes.ruleTeamMember}>
              <Avatar
                className={classes.ruleAvatar}
                name={r.teamMember.displayName || r.teamMember.email}
                userId={r.teamMember._id}
              />
              <Typography>
                {r.teamMember.displayName || r.teamMember.email}
                {currentUser._id === r.userId && <>&nbsp;(you)</>}
              </Typography>
            </div>
            <Button
              disabled={currentUser._id === r.userId || !canUpdateMemberPermissions}
              endIcon={<Arrow />}
              onClick={openPopover(index)}
              variant="text"
            >
              {ROLES_LABEL_MAP[r.role]}
            </Button>
          </div>
        ))}
      </ConfirmationModal>
    </>
  );
};

const ShareFolderModal = ({ modalOpen, closeModal }) => {
  const { state: { modalId, modalContent } } = useGlobalModals();
  if (
    !modalOpen
      || modalId !== SHARE_FOLDER_MODAL_ID
      || (!modalContent?.folder && !modalContent?.file)) {
    return null;
  }

  return (
    <ShareFolderModalInner
      folder={modalContent.folder}
      file={modalContent.file}
      modalOpen={modalOpen}
      closeModal={closeModal}
      type={modalContent.folder ? MODAL_TYPES.folder : MODAL_TYPES.file}
    />
  );
};

export default ShareFolderModal;
