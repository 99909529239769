import React from 'react';
import clsx from 'clsx';
import { IconBriefcase, IconChevronDown, IconFolder } from '@tabler/icons-react';

import TruncatedText from '@root/components/TruncatedText';

import useStyles from './LocationSelectMenuItem.styles';

const LocationSelectMenuItem = ({
  folder,
  projectIdsOpen,
  setProjectIdsOpen,
  onLocationSelect,
  selected,
}) => {
  const classes = useStyles();

  const toggleVisibility = () => {
    if (projectIdsOpen.includes(folder.value)) {
      setProjectIdsOpen(projectIdsOpen.filter((id) => id !== folder.value));
    } else {
      setProjectIdsOpen([...projectIdsOpen, folder.value]);
    }
  };

  return (
    <div
      className={classes.root}
      onClick={!folder.isOptionDisabled ? (e) => onLocationSelect(e, folder.value) : () => {}}
    >
      {folder.parentFolderId && (
        <div className={clsx(
          classes.rootFolderContainer,
          classes.childFolderContainer,
          { [classes.activeRootFolderRow]: selected },
        )}
        >
          <IconFolder className={classes.folderIcon} />

          <TruncatedText>{folder.label}</TruncatedText>
        </div>
      )}

      {!folder?.parentFolderId && (
        <div className={clsx(
          classes.rootFolderContainer,
          { [classes.activeRootFolderRow]: selected },
        )}
        >
          <IconBriefcase className={classes.folderIcon} />

          <TruncatedText className={classes.projectTitle}>
            {folder.label}
          </TruncatedText>

          {!folder.home && folder?.hasChildFolders && (
            <IconChevronDown
              onClick={(event) => { event.stopPropagation(); toggleVisibility(); }}
              className={clsx(
                classes.chevronIcon,
                { [classes.expandedChevronDownIcon]: projectIdsOpen.includes(folder.value) },
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LocationSelectMenuItem;
