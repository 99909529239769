import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  documents: {
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.colors.mediumLightGrey,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  documentsOverlapped: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '4rem',
    },
  },
  form: {
    width: '35%',
    padding: theme.spacing(2, 6),
    borderRight: '1px solid #dbdbdb',
    position: 'relative',
    overflowY: 'auto',
  },
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',

    '& p, h6': {
      fontWeight: 400,
      fontFamily: 'Inter, sans-serif',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    minHeight: 0,
    flex: 1,
  },
  selectAll: {
    cursor: 'pointer',
    fontSize: '0.95rem',
    color: '#000',
    flexGrow: 1,
  },
  formHidden: {
    '&': {
      width: '4rem',
      minWidth: '4rem',
      padding: '0',
    },
    '& $innerForm': {
      display: 'none',
    },
    '& $image': {
      display: 'none',
    },
  },
  formOverlapped: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
      position: 'absolute',
      zIndex: 2,
      background: 'white',
      width: 360,
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  chevronIcon: {
    top: '27px',
    right: '1rem',
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  flex: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  innerForm: {
    flex: 1,
  },
  documentsList: {
    flexGrow: 1,
    whiteSpace: 'break-spaces',
  },
  documentsListContainer: {
    display: 'flex',
  },
  documentsFilters: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  documentView: {
    width: '100%',
  },
  noDocs: {
    flexGrow: 1,
    display: 'flex',
  },
  documentsBody: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2.5, 6),
  },
  expandedContentPreview: {
    fontWeight: 'bold',
    color: 'coral',
  },
  generate: {
    marginTop: theme.spacing(2.5),
  },
  image: {
    marginTop: '3rem',
    width: '35%',
    marginRight: '40px',
    '& > *': {
      width: '100%',
    },
  },
  modalActions: {
    marginTop: theme.spacing(1),
  },
  contentPreviewRow: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentPreviewContent: {
    width: '60%',
  },
}));

export default useStyles;
