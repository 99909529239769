import React from 'react';

import icons from '../../icons';

import useStyles from './TemplateLabel.styles';

const TemplateLabel = ({ templateType, text }) => {
  const classes = useStyles();

  const { icon: Icon, bgColor, color } = icons?.[templateType] || icons.custom;

  return (
    <span className={classes.labelRoot}>
      <div
        className={classes.iconContainer}
        style={{
          backgroundColor: bgColor,
          color,
        }}
      >
        <Icon />
      </div>
      {text}
    </span>
  );
};

export default TemplateLabel;
