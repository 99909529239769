import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  filterBySection: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  selectField: {
    minWidth: '150px',
  },

  viewBySection: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  iconButton: {
    width: 36,
    height: 36,
    minWidth: 'unset',
    borderRadius: 4,

    padding: 0,

    display: 'grid',
    placeItems: 'center',

    color: theme.palette.colors.grey,

    '&:hover': {
      backgroundColor: theme.palette.primary.faded,
    },
  },

  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.faded,
  },
}));

export default useStyles;
