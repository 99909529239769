import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tabs, Tab, Button, IconButton, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import fileResource from '@root/resources/file';
import aiResource from '@root/resources/ai';
import documentResource from '@root/resources/document';
import AuthContext from '@root/resources/auth/auth.context';
import AddCreditsButton from '@root/components/buttons/AddCreditsButton';
import billingResource from '@root/resources/billing';
import TemplatesContext from '@root/resources/templates/templates.context';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';

import templatesConfig from '../../constants/freeFormEditor.templatesConfig';
import { readOnlyTemplates } from '../../constants/readOnlyTemplates';
import SEOTab from '../SEOTab';
import SelectTemplateButton from '../SelectTemplateButton';
import TabPanel from '../TabPanel';
import CarouselSidebar from '../CarouselSidebar';
import ReadonlyTemplate from '../ReadonlyTemplateDetails';
import FieldTemplate from '../FieldTemplateDetails/FieldTemplateDetails';
import FileSettingsDialog from '../FileSettingsDialog';

import useStyles from './RightPanel.styles';

function a11yProps(index) {
  return {
    id: `right-panel-tab-${index}`,
    'aria-controls': `right-panel-tabpanel-${index}`,
  };
}

const tabs = {
  generate: 'generate',
  seo: 'seo',
  output: 'output',
};

const RightPanel = ({
  onInsert,
  onClose,
  highlightedText,
  disabled,
  fileId,
  limitedAccess,
  onTemplateSelected,
  selectedTemplateType,
  document,
}) => {
  const classes = useStyles();
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const { mutateAsync: generateNow } = aiResource.useGenerateContentNow();

  const [activeTab, setActiveTab] = useState(tabs.generate);

  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const { getTemplate } = TemplatesContext.useTemplates();
  const selectedTemplate = getTemplate(selectedTemplateType);
  const { aiOptionsByPlan,
    creditCostByContentLength,
    aiOptions: aiOpt,
    fieldsByByPlan,
    fields } = selectedTemplate;

  const templateAiOptions = aiOptionsByPlan
    ? aiOptionsByPlan[currentCompany.billing.currentLimitId] || aiOpt
    : aiOpt;

  let templateFields = fieldsByByPlan
    ? fieldsByByPlan[currentCompany.billing.currentLimitId] || fields
    : fields;

  templateFields = templateFields.map((field) => {
    if (!creditCostByContentLength) {
      return field;
    }
    if (creditCostByContentLength.fieldKey !== field.name) {
      return field;
    }

    return {
      ...field,
      creditCosts: creditCostByContentLength.thresholds,
    };
  });

  const multilineStringField = templateFields.find((field) => field.type === 'multilineString');

  const [isLoading, setLoading] = useState(false);
  const [isEverGenerated, setIsEverGenerated] = useState(false);
  const [currentAiResults, setCurrentAiResults] = useState([]);
  const [fileFieldsData, setFileFieldsData] = useState({ aiOptions: templateAiOptions });

  const { data: limitsData } = billingResource.useLimits();
  const creditsLeft = limitsData?.limit;
  const hasCredits = !!creditsLeft || creditsLeft === null;
  const showAddCreditsButton = !hasCredits && !currentUser.onFreePlan;
  const onFileFieldsDataChange = ({ name, value }) => {
    setFileFieldsData({
      ...fileFieldsData,
      [name]: value,
    });
  };

  const { data: filePresets } = fileResource.usePresets(fileId);
  const { data: file } = fileResource.useFile(fileId);
  const { mutateAsync: updateFile } = fileResource.useUpdateFile();

  const [fileSettingsOpen, setFileSettingsOpen] = useState(false);

  const toggleFileSettingsDialog = () => setFileSettingsOpen(!fileSettingsOpen);

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const isReadonlySelected = readOnlyTemplates
    .map(({ template }) => template).includes(selectedTemplateType);
  const {
    data: freeFormLatestDocuments,
    isLoading: isFreeFormLatestLoading,
  } = documentResource.useFreeFormLatestDocuments({ fileId });
  useEffect(() => {
    if (!isFreeFormLatestLoading && freeFormLatestDocuments.length) {
      setIsEverGenerated(true);
      onTemplateSelected(freeFormLatestDocuments[0].templateType);
      setCurrentAiResults(freeFormLatestDocuments.map((d) => d.content));
      setActiveTab(tabs.output);
      refetchOnboarding('generateContent');
    }
  }, [isFreeFormLatestLoading]);

  const generate = async (type) => {
    onTemplateSelected(type);
    setLoading(true);

    const { aiOptions, ...fileData } = fileFieldsData;
    const textData = highlightedText || templatesConfig[type].getText(fileFieldsData);
    const data = isReadonlySelected
      ? templatesConfig[type].getData(textData)
      : fileData;

    const negativeKeywordArray = data.negativeKeywordArray || [];

    const { results, isBadRequest } = await generateNow({
      templateType: type,
      negativeKeywordArray,
      data,
      fileId,
      aiOptions,
      outputLanguage: 'be',
    });
    await updateFile({
      fileId,
      data: {
        data: {
          ...data,
        },
        negativeKeywordArray,
      },
    });
    setLoading(false);
    if (!isBadRequest) {
      setIsEverGenerated(true);
      setCurrentAiResults(results.map((d) => d.content));
      setActiveTab(tabs.output);
      refetchOnboarding('generateContent');
    }
  };

  const text = disabled ? '' : highlightedText;
  const freeFormTemplateConfig = templatesConfig[selectedTemplateType];

  const isGenerateButtonDisabled = () => {
    if (isLoading || disabled || limitedAccess || !hasCredits) {
      return true;
    }

    if (isReadonlySelected) {
      const selectedText = text || templatesConfig[selectedTemplateType].getText(fileFieldsData);
      return selectedText.length > freeFormTemplateConfig.limit
        || !selectedText
        || selectedText.length > multilineStringField.maximum;
    }

    return false;
  };

  const generateButtonDisabled = isGenerateButtonDisabled();

  useEffect(() => {
    if (filePresets) {
      setFileFieldsData((data) => ({
        ...data,
        ...filePresets,
      }));
    }
  }, [filePresets]);

  useEffect(() => {
    if (file) {
      setFileFieldsData((data) => ({
        ...data,
        ...file.data,
        negativeKeywordArray: file.negativeKeywordArray,
        aiOptions: {
          ...data.aiOptions,
          engine: file.engine || 'default',
        },
        language: file.language,
        outputLanguage: file.outputLanguage || file.language,
        engine: file.engine || 'default',
        tone: file.tone || 'Neutral',
      }));
    }
  }, [file]);

  useEffect(() => {
    setFileFieldsData((data) => ({
      ...data,
      aiOptions: {
        ...data.aiOptions,
        ...templateAiOptions,
      },
    }));
  }, [templateAiOptions]);

  return (
    <div className={classes.rightPanelWrapper}>
      <Tabs
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
        value={activeTab}
        onChange={handleTabChange}
        aria-label="right panel tabs"
        classes={{
          flexContainer: classes.tabs,
          indicator: classes.tabIndicator,
        }}
      >
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabActive,
          }}
          value={tabs.generate}
          label="Generate More"
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabActive,
          }}
          value={tabs.seo}
          label="SEO"
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabActive,

          }}
          value={tabs.output}
          label="Last Generation"
          {...a11yProps(1)}
          disabled={!isEverGenerated}
        />
      </Tabs>
      <TabPanel value={activeTab} index={tabs.generate}>
        <div>
          {disabled ? (
            <div>
              <div className={classes.upgrade}>
                {'Free form editor is not available on your current plan. Please, '}
                <RouterLink
                  className={classes.upgradeLink}
                  to="/profile/plans"
                  color="primary"
                >
                  upgrade your plan
                </RouterLink>
                {' to continue use this feature.'}
              </div>
            </div>
          ) : (
            <>
              <div className={classes.buttonsWrap}>
                <SelectTemplateButton
                  onGenerate={generate}
                  onTemplateSelected={onTemplateSelected}
                  selectedTemplateType={selectedTemplateType}
                  isLoading={isLoading}
                  disabled={generateButtonDisabled}
                />
                <Tooltip title="Use the additional options below to change the style, contents, and number of content variations in this file.">
                  <IconButton
                    className={classes.settingsButton}
                    size="small"
                    variant="text"
                    onClick={toggleFileSettingsDialog}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {isReadonlySelected ? (
                <ReadonlyTemplate
                  text={text || templatesConfig[selectedTemplateType].getText(fileFieldsData)}
                  selectedTemplateType={selectedTemplateType}
                  templateTypes={readOnlyTemplates}
                  creditCostsThresholds={multilineStringField?.creditCosts}
                  maxLength={multilineStringField?.maximum}
                />
              ) : (
                <FieldTemplate
                  text={text}
                  templateType={selectedTemplateType}
                  fileFieldsData={fileFieldsData}
                  onFileFieldsDataChange={onFileFieldsDataChange}
                />
              )}
            </>
          )}
        </div>
        <div className={classes.bottomButtonsWrap}>
          <DisabledGenerateTooltip
            notEnoughCredits
            enabled={!hasCredits}
          >
            <Button
              className={classes.generateButton}
              disabled={generateButtonDisabled}
              size="small"
              onClick={() => generate(selectedTemplateType)}
            >
              Generate
            </Button>
          </DisabledGenerateTooltip>
          {showAddCreditsButton && <AddCreditsButton />}
        </div>
        <div className={classes.bottomButtonsWrap}>
          <Button
            size="small"
            variant="text"
            className={classes.closeButton}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </TabPanel>

      <TabPanel value={activeTab} index={tabs.seo}>
        <SEOTab
          documentId={document?._id}
          content={document?.content}
          onInsert={(textToInsert) => onInsert(
            textToInsert,
            selectedTemplateType !== templatesConfig.freeFormEditor.templateType,
          )}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={tabs.output}>
        <CarouselSidebar
          title={templatesConfig[selectedTemplateType].sidebarTitle}
          options={currentAiResults}
          onButtonClick={(textToInsert) => onInsert(
            textToInsert,
            selectedTemplateType !== templatesConfig.freeFormEditor.templateType,
          )}
          limitedAccess={limitedAccess}
        />
        <div className={classes.bottomButtonsWrap}>
          <Button
            size="small"
            variant="text"
            className={classes.closeButton}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </TabPanel>

      {fileSettingsOpen && (
        <FileSettingsDialog
          fileId={fileId}
          onClose={toggleFileSettingsDialog}
          onChange={setFileFieldsData}
          values={fileFieldsData}
          templateType={selectedTemplateType}
        />
      )}
    </div>
  );
};

export default RightPanel;
