import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import Button from '@root/components/buttons/Button';
import { InputField, FormField } from '@root/components/form';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import useFiles from '@root/resources/file/useFiles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
}));

const validationSchema = Yup.object().shape({
  newTitle: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, { message: 'The filename cannot contain only spaces' })
    .required('File name is required'),
});

const RenameFileForm = ({ closeModal }) => {
  const { mutateAsync: updateFile } = useUpdateFile();
  const { state: { modalContent } } = useGlobalModals();
  const { refetch: refetchFiles } = useFiles({ folderIds: [modalContent?.file?.folderId] });

  const classes = useStyles();

  if (!modalContent) {
    return null;
  }

  const { file: { title: originalTitle, _id: fileId } } = modalContent;

  const submit = async (values, actions) => {
    const { newTitle } = values;
    actions.setSubmitting(true);
    await updateFile({
      fileId,
      data: {
        title: newTitle,
      },
    });
    refetchFiles();
    closeModal();
  };

  return (
    <div>
      <Formik
        initialValues={{ newTitle: originalTitle }}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({ isValid }) => (
          <Form>
            <FormField
              name="newTitle"
              component={InputField}
              label="New file name"
              required
              autoFocus
            />
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={closeModal}>Discard</Button>
              <Button type="submit" disabled={!isValid}>Rename</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RenameFileForm;
