import React from 'react';

import DocumentView from '@root/components/DocumentView';

const DocumentExample = ({ template }) => {
  const sampleDoc = {
    _id: template.templateType,
    templateType: template.templateType,
    content: template.newExample?.content,
  };
  const { fileFields } = template.newExample || {};
  const sampleFileData = fileFields || {
    companyName: '',
  };

  return (
    <DocumentView
      document={sampleDoc}
      fileData={sampleFileData}
      options={{
        canEdit: false,
        canDelete: false,
        showVote: false,
        canLike: false,
        canCopy: false,
        canFlag: false,
      }}
    />
  );
};

export default DocumentExample;
