import { useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';

import api from '@root/api';

export default function useCurrentPCMJob(queryOptions = {}) {
  const queryClient = useQueryClient();
  const [interval, setInterval] = useState(false);

  return useQuery(['currentPCMBulkGenerationJob', {}], () => api.bulkGeneration.getCurrentPCMJob(),
    { onSuccess: (data) => {
      if (data?.bulkGeneration) {
        setInterval(3000);
      }

      if (interval && data?.bulkGeneration) {
        queryClient.invalidateQueries('catalog.items');
      }

      if (interval && !data?.bulkGeneration) {
        setInterval(false);
        queryClient.invalidateQueries('catalog.items');
        queryClient.invalidateQueries(['billing.getLimits', { }]);
      }
    },
    refetchInterval: interval,
    refetchOnMount: 'always' },
    queryOptions);
}
