import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M9.333 12.167a.5.5 0 0 1 .5.5v3.024L12 16.774l2.167-1.083v-3.024a.5.5 0 1 1 1 0V16a.5.5 0 0 1-.277.447l-2.667 1.334a.5.5 0 0 1-.447 0L9.11 16.447A.5.5 0 0 1 8.833 16v-3.333a.5.5 0 0 1 .5-.5Z" />
      <path d="M14.443 6.22a.5.5 0 0 1 .447 0l2.667 1.333a.5.5 0 0 1 .276.447v3.333a.5.5 0 0 1-.276.448l-2.667 1.333a.5.5 0 0 1-.447 0l-2.666-1.333a.5.5 0 0 1 .447-.895l2.443 1.222 2.166-1.084V8.31l-2.166-1.083-2.443 1.221a.5.5 0 1 1-.447-.894l2.666-1.334Z" />
      <path d="M9.11 6.22a.5.5 0 0 1 .447 0l2.667 1.333A.5.5 0 0 1 12.5 8v3.333a.5.5 0 0 1-.276.448l-2.667 1.333a.5.5 0 0 1-.447 0L6.443 11.78a.5.5 0 0 1-.276-.448V8a.5.5 0 0 1 .276-.447L9.11 6.219ZM7.167 8.308v2.715l2.166 1.084 2.167-1.084V8.31L9.333 7.226 7.167 8.309Z" />
    </svg>
  );
};
