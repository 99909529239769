import React from 'react';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import UseCaseModal, { USE_CASE_MODAL_ID } from '@root/views/TemplatesDashboardNew/UseCaseModal';

import {
  BulkDeleteFileModal, BULK_DELETE_FILE_MODAL_ID,
  BulkGenerateModal, BULK_GENERATE_MODAL_ID,
  BulkMoveFileModal, BULK_MOVE_FILE_MODAL_ID,
  NewFolderModal, NEW_FOLDER_MODAL_ID,
  NewProjectModal, NEW_PROJECT_MODAL_ID,
  NewUserTour, NEW_USER_TOUR_ID,
  TourModal, TOUR_MODAL_ID,
  NotificationModal, NOTIFICATION_MODAL_ID,
  CreateModal, CREATE_MODAL_ID,
} from './index';

const DashboardModals = () => {
  const { state: { modalOpen, modalId }, dispatch } = useGlobalModals();

  const handleCloseModal = () => {
    dispatch({
      type: actions.CLOSE_MODAL,
    });
  };

  return (
    <>
      <NotificationModal
        modalOpen={modalOpen && modalId === NOTIFICATION_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <BulkDeleteFileModal
        modalOpen={modalOpen && modalId === BULK_DELETE_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <BulkGenerateModal
        modalOpen={modalOpen && modalId === BULK_GENERATE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <BulkMoveFileModal
        modalOpen={modalOpen && modalId === BULK_MOVE_FILE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <CreateModal
        modalOpen={modalOpen && modalId === CREATE_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <UseCaseModal
        open={modalOpen && modalId === USE_CASE_MODAL_ID}
        onClose={handleCloseModal}
      />
      <NewFolderModal
        modalOpen={modalOpen && modalId === NEW_FOLDER_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <NewProjectModal
        modalOpen={modalOpen && modalId === NEW_PROJECT_MODAL_ID}
        closeModal={handleCloseModal}
      />
      <NewUserTour
        modalOpen={modalOpen && modalId === NEW_USER_TOUR_ID}
        closeModal={handleCloseModal}
      />
      <TourModal
        modalOpen={modalOpen && modalId === TOUR_MODAL_ID}
        closeModal={handleCloseModal}
      />
    </>
  );
};

export default DashboardModals;
