import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useDeleteFile() {
  const queryClient = useQueryClient();

  return useMutation(
    (fileId) => api.files.delete(fileId),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries('files.list');
        queryClient.invalidateQueries('files.workflowStats');
      },
    },
  );
}
