import CopysmithApi from '@copysmith/api';

import config from '@root/config';
import errorInterceptor from '@root/api/errorInterceptor';
import afterRequestHandler from '@root/api/afterRequestHandler';
import jwtService from '@root/services/jwt.service';

const customClients = {
  'https://hootsuite.com': 'hootsuite',
};

const getCustomHeaders = () => {
  const customHeaders = {};

  if (window.location.ancestorOrigins?.length) {
    const [ancestorOrigin] = window.location.ancestorOrigins;
    customHeaders['client-source'] = customClients[ancestorOrigin] || ancestorOrigin;
  } else {
    customHeaders['client-source'] = 'webApp';
  }

  return customHeaders;
};

const client = new CopysmithApi(jwtService.getJwtValue, {
  env: config.env,
  external: true,
  errorInterceptor,
  onAfterRequest: afterRequestHandler,
  customHeaders: getCustomHeaders(),
});

export default client;
