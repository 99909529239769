import React from 'react';

const ImagesIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2621_3985)">
        <rect width="32" height="32" fill="white" />
        <rect width="8" height="8" fill="#FFC199" />
        <rect
          y="8"
          width="8"
          height="8"
          fill="#FF7096"
        />
        <rect
          x="8"
          y="16"
          width="8"
          height="8"
          fill="#FF8C42"
        />
        <rect
          y="24"
          width="8"
          height="8"
          fill="#262445"
        />
        <rect
          x="8"
          width="8"
          height="8"
          fill="#262445"
        />
        <rect
          x="8"
          y="8"
          width="8"
          height="8"
          fill="#CFE8FC"
        />
        <rect
          y="16"
          width="8"
          height="8"
          fill="#FFCCDA"
        />
        <rect
          x="8"
          y="24"
          width="8"
          height="8"
          fill="#D3D0FB"
        />
        <rect
          x="16"
          y="8"
          width="8"
          height="8"
          fill="#262445"
        />
        <rect
          x="16"
          width="8"
          height="8"
          fill="#D3D0FB"
        />
        <rect
          x="24"
          y="16"
          width="8"
          height="8"
          fill="#05612A"
        />
        <rect
          x="16"
          y="24"
          width="8"
          height="8"
          fill="#140B8E"
        />
        <rect
          x="24"
          width="8"
          height="8"
          fill="#4D41F1"
        />
        <rect
          x="24"
          y="8"
          width="8"
          height="8"
          fill="#CEFDE1"
        />
        <rect
          x="16"
          y="16"
          width="8"
          height="8"
          fill="#FFE599"
        />
        <rect
          x="24"
          y="24"
          width="8"
          height="8"
          fill="#CFE8FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2621_3985">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default ImagesIcon;
