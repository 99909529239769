import React from 'react';
import { Select } from '@material-ui/core';

import ScrollFade from '@root/components/ScrollFade';

import LocationSelectMenuItem from './LocationSelectMenuItem';
import useStyles from './LocationSelectMenuItem.styles';

const LocationSelectMenu = ({
  folders,
  location,
  onLocationSelect,
  isOpen,
  disabled,
}) => {
  const classes = useStyles();

  const [projectIdsOpen, setProjectIdsOpen] = React.useState([]);

  return (
    <Select
      value={location}
      renderValue={(selected) => {
        const selectedFolder = folders.find(({ value }) => value === selected);

        if (selectedFolder?.label) {
          return selectedFolder.label;
        }

        return 'Select project';
      }}
      MenuProps={{
        disablePortal: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: -6 },
        getContentAnchorEl: null,
        classes: {
          paper: classes.selectRoot,
          list: classes.list,
        },
      }}
      variant="outlined"
      fullWidth
      open={isOpen}
      disabled={disabled}
    >
      <>
        <ScrollFade />

        {folders.map(
          (folder) => (
            !folder.parentFolderId || projectIdsOpen.includes(folder.parentFolderId)
          ) && (
            <span key={folder.value} value={folder.value}>
              <LocationSelectMenuItem
                folder={folder}
                projectIdsOpen={projectIdsOpen}
                setProjectIdsOpen={setProjectIdsOpen}
                selected={location === folder.value}
                onLocationSelect={onLocationSelect}
              />
            </span>
          ),
        )}
      </>
    </Select>
  );
};

export default LocationSelectMenu;
