import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: 16,
  },
  leftBar: {
    height: 8,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: (quality) => quality.leftColor,
    width: '33%',
  },
  midBar: {
    height: 8,
    width: '33%',
    backgroundColor: (quality) => quality.middleColor,
  },
  rightBar: {
    height: 8,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: (quality) => quality.rightColor,
    width: '33%',
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 16,
    height: 42, // two lines
  },
  badgeWrap: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
  },
  qualityBadge: {
    height: 25,
    backgroundColor: (quality) => quality.qualityBgColor,
    borderRadius: theme.border.radius.default,
    fontWeight: 600,
    fontSize: 16,
    color: (quality) => quality.qualityColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
  },
  qualityDescription: {
    color: theme.palette.colors.darkGrey,
    fontSize: 14,
  },
  textCountBadge: {
    position: 'absolute',
    right: 10,
    bottom: 50,
    color: '#AAAAAA',
    fontSize: 15,
    userSelect: 'none',
  },
  emptyContainer: {
    height: 25,
    width: '100%',
  },
}));
