import React from 'react';

import DEPRECATEDSingleSelect from '@root/components/Interactive/DEPRECATEDSingleSelect';

const SingleSelectField = ({
  field: {
    options,
    placeholder,
    required,
  },
  onChange,
  fieldValue,
  disabled,
}) => {
  const selectedOption = options.find((o) => o.value === fieldValue);
  return (
    <DEPRECATEDSingleSelect
      placeholder={placeholder}
      options={options}
      handleChange={onChange}
      required={required}
      value={selectedOption}
      disabled={disabled}
    />
  );
};

export default SingleSelectField;
