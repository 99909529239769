import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as LinkIcon } from '@material-ui/icons';

const useMenuItems = ({
  handlers: {
    toggleExampleOpen,
    navigateToShare,
    downloadSelectedAsTxt,
    downloadSelectedAsPdf,
    downloadSelectedAsCsv,
    downloadSelectedAsDocx,
    setShowChar,
  },
  params: {
    exportWord,
  },
  classes,
}) => {
  const menuItems = React.useMemo(() => {
    return {
      example: {
        key: 'example',
        title: (
          <span className={classes.menuItem}>
            <FontAwesomeIcon
              icon="lightbulb"
              className={clsx(classes.menuItemIcon, classes.menuItemIconBulb)}
            />
            See examples
          </span>
        ),
        onClick: ({ handleClose }) => {
          handleClose();
          toggleExampleOpen();
        },
      },
      share: {
        key: 'share',
        title: (
          <span className={classes.menuItem}>
            <LinkIcon className={classes.menuItemIcon} />
            Share externally
          </span>
        ),
        onClick: ({ handleClose }) => {
          handleClose();
          navigateToShare();
        },
      },
      export: {
        key: 'export',
        title: (
          <span className={classes.menuItem}>
            <FontAwesomeIcon
              icon="download"
              className={classes.menuItemIcon}
            />
            Download copy
          </span>
        ),
        items: [
          {
            title: `Export ${exportWord} as txt`,
            onClick: ({ handleClose }) => {
              handleClose();
              downloadSelectedAsTxt();
            },
          },
          {
            title: `Export ${exportWord} as pdf`,
            onClick: ({ handleClose }) => {
              handleClose();
              downloadSelectedAsPdf();
            },
          },
          {
            title: `Export ${exportWord} as csv`,
            onClick: ({ handleClose }) => {
              handleClose();
              downloadSelectedAsCsv();
            },
          },
          {
            title: `Export ${exportWord} as docx`,
            onClick: ({ handleClose }) => {
              handleClose();
              downloadSelectedAsDocx();
            },
          },
        ],
      },
      settings: {
        key: 'settings',
        title: (
          <span className={classes.menuItem}>
            <FontAwesomeIcon
              icon="sliders-h"
              className={classes.menuItemIcon}
            />
            Settings
          </span>
        ),
        items: [
          {
            title: 'Character Count',
            onClick: ({ handleClose }) => {
              handleClose();
              setShowChar(true);
            },
          },
          {
            title: 'Word Count',
            onClick: ({ handleClose }) => {
              handleClose();
              setShowChar(false);
            },
          },
        ],
      },
    };
  }, [
    toggleExampleOpen,
    navigateToShare,
    downloadSelectedAsTxt,
    downloadSelectedAsPdf,
    downloadSelectedAsCsv,
    downloadSelectedAsDocx,
    setShowChar,
    classes,
    exportWord,
  ]);

  return menuItems;
};

export default useMenuItems;
