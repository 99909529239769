import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ObjectUtil } from '@copysmith/utils';

import Avatar from '@root/components/Avatar';
import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import newsResource from '@root/resources/news';
import userResource from '@root/resources/user';
import DateUtil from '@root/utils/date.util';
import NewsDrawer from '@root/views/Layout/components/NewsDrawer';
import { logOutUser } from '@root/services/auth.service';
import TruncatedText from '@root/components/TruncatedText';
import { getUserInfoToDisplay } from '@root/utils/formatString.util';
import useTeamMembers from '@root/resources/user/useTeamMembers';

import useStyles from './ProfileDropdown.styles';

const { insertInArrayIf: insertIf } = ObjectUtil;

const ProfileDropdown = () => {
  const classes = useStyles();
  const [newsDrawerOpen, setNewsDrawerOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const { currentUser } = AuthContext.useAuth();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { data: news } = newsResource.useNews();
  const { mutateAsync: updateCurrentUser } = userResource.useUpdateUser(currentUser._id);
  const logoutWithRedirect = () => logOutUser({ redirect: '/' });

  const {
    profileDropdownDisabledItems,
    profileDropdownDisabledResources,
    toggles: { redirectToAppTopBar },
  } = currentExternalSource;
  const lastSeenNews = currentUser.lastCheckedNews || 0;
  const mostRecentPost = news?.[0]?.date;
  const diff = mostRecentPost - lastSeenNews;
  const newsNotification = diff > 0;

  const userFullName = getUserInfoToDisplay({
    firstName: currentUser?.firstName,
    surname: currentUser?.surname,
    email: currentUser?.email,
  });

  const { data: membersDataAll } = useTeamMembers({ allMembers: true });
  const membersCount = membersDataAll?.results?.filter((m) => !m.deactivated)?.length;

  const isMember = currentUser.roles.member;
  const isRewardsPageAvailable = currentUser && !currentUser.onFreePlan && currentUser.featureToggles.rewardsPage;
  const isNewLeftSidebar = currentUser.featureToggles.newLeftSidebar;

  const onNewsClick = async () => {
    await updateCurrentUser({
      lastCheckedNews: DateUtil.getNowSeconds(),
    });
    setNewsDrawerOpen(true);
  };

  const handleAvatarClick = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const MENU_ITEMS = [
    {
      title: 'Profile',
      items: [
        {
          key: 'settings',
          content: 'Settings',
          to: '/profile/edit',
        },
      ],
    },
    {
      title: 'resources',
      items: [
        {
          key: 'api-docs',
          content: 'API',
          to: '/addons/api',
        },
        {
          key: 'video-tutorials',
          content: 'Video Tutorials',
          to: 'https://youtube.com/@copysmithai9334',
          target: '_blank',
        },
        {
          key: 'knowledge-base',
          content: 'Knowledge Base',
          to: 'https://describely.notion.site/Copysmith-Help-Center-b0c792cf63a64cc7bc5d1fb07648ff1c',
          target: '_blank',
        },
        {
          key: 'updates',
          content: (
            <>
              {newsNotification && <div className={classes.redCircle} />}
              Blog
            </>
          ),
          toggle: false,
          onClick: () => onNewsClick(),
        },
      ],
    },
    ...insertIf(isNewLeftSidebar, {
      title: 'Organization',
      items: [
        {
          key: 'addons',
          content: 'Add-ons',
          to: '/addons',
        },
        ...insertIf(!isMember,
          {
            key: 'teams',
            content: (
              <p className={classes.teamsTitle}>
                <span>Teams</span>
                {!isMember && (
                  <span className={classes.membersCounter}>
                    {membersCount > 1 ? membersCount : 'Just you' }
                  </span>
                )}
              </p>
            ),
            to: '/profile/team-members',
          },
          {
            key: 'plan',
            content: 'Plan',
            to: '/profile/plans',
          },
          {
            key: 'billing',
            content: 'Billing',
            to: '/profile/billing',
          }),
        ...insertIf(currentUser?.isUsageDashboardEnabled && !isMember, {
          key: 'usage-dashboard',
          content: 'Usage Dashboard',
          to: '/usage',
        }),
      ],
    }),
    {
      title: '',
      items: [
        {
          key: 'log-out',
          content: (
            <span
              className={`${classes.menuItemText} ${classes.logoutButton}`}
            >
              Log out
            </span>
          ),
          onClick: () => logoutWithRedirect(),
        },
      ],
    },
  ];

  return (
    <>
      <NewsDrawer news={news} isOpen={newsDrawerOpen} setIsOpen={setNewsDrawerOpen} />

      <IconButton classes={{ root: classes.iconButton }} onClick={handleAvatarClick}>
        <Avatar
          name={userFullName || currentUser.email}
          userId={currentUser?._id}
          size="medium"
          className={classes.avatar}
          popperEnabled={false}
          oneChar={false}
        />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -20,
          horizontal: 'right',
        }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        <div className={classes.menuSectionsContainer}>
          <div className={classes.profileInfo}>
            <Avatar
              name={userFullName || currentUser.email}
              userId={currentUser?._id}
              className={classes.profileInfoAvatar}
              popperEnabled={false}
              oneChar={false}
            />
            <section className={classes.usernameContainer}>
              <TruncatedText className={classes.userFullName}>{userFullName}</TruncatedText>
              <TruncatedText className={classes.userEmail}>{currentUser?.email}</TruncatedText>
            </section>
          </div>

          {MENU_ITEMS.map((section, index, sections) => {
            const filteredItems = section.items.filter(
              (item) => ![...profileDropdownDisabledItems, ...profileDropdownDisabledResources].includes(item.key),
            );

            return (
              <div key={section.title}>
                <div
                  className={classes.menuSectionContainer}
                >
                  {section?.title && (
                    <TruncatedText className={classes.menuSectionTitle}>
                      {section.title}
                    </TruncatedText>
                  )}

                  <div className={classes.itemsContainer}>
                    {filteredItems?.map((item) => (
                      <MenuItem
                        key={item.key}
                        classes={{ root: classes.menuItem }}
                        onClick={item.onClick}
                        disableRipple
                        {...(item.to ? {
                          component: Link,
                          to: item.target === '_blank' ? { pathname: item.to } : item.to,
                          target: redirectToAppTopBar ? '_blank' : item.target,
                        } : {
                          onClick: item.onClick,
                        })}
                      >
                        <TruncatedText className={classes.menuItemText}>
                          {item.content}
                        </TruncatedText>
                      </MenuItem>
                    ))}
                  </div>
                </div>

                {index !== sections?.length - 1 && <div className={classes.divider} />}
              </div>
            );
          })}
        </div>
      </Menu>
    </>
  );
};

export default ProfileDropdown;
