import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  planInfoRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '39px',
    marginBottom: 8,
    whiteSpace: 'nowrap',
    minWidth: 216,
    textAlign: 'center',
  },
  price: {
    fontFamily: 'Manrope',
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '39px',
    marginBottom: 4,
    whiteSpace: 'nowrap',
    minWidth: 260,
    textAlign: 'center',
  },
  oldPrice: {
    color: theme.palette.colors.grey,
    textDecoration: 'line-through',
    marginRight: 4,
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  secondaryText: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 8,
  },
}));
