import React from 'react';
import { makeStyles } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import TemplatesContext from '@root/resources/templates/templates.context';
import Select from '@root/components/Interactive/FilterSelect';
import { workflowStatusOptions } from '@root/resources/file/file.constants';
import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import useFileCounter from './useFileCounter';

const EXCLUDE_TEMPLATE_TYPES = {
  blogPost: 'blogPost',
  articles: 'articles',
  ffseInstruct: 'freeFormEditorInstruct',
  blogKickstarter: 'blogKickstarter',
};

const useStyles = makeStyles({
  selectContainer: {
    minWidth: '12rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 25,
    width: 'calc(100% - 120px)',
  },
  typeSelect: {
    marginTop: 10,
    minWidth: 'max(50% - 6px, 230px)',
    flexGrow: 2,
    marginRight: '12px',
  },
  statusSelect: {
    marginTop: 10,
    minWidth: 'max(50% - 6px, 200px)',
    flexGrow: 1,
  },
});

const getSelectedValue = (options, filterValues) => {
  return options.filter(({ value }) => filterValues.includes(value));
};

const Filters = () => {
  const { state: { filters }, dispatch } = useDashboard();
  const classes = useStyles();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const statusOptions = [{ label: 'All', value: 'all' }].concat(workflowStatusOptions);

  const { currentUser, currentCompany } = AuthContext.useAuth();
  const hasTeamPermissions = currentUser.permissions.teams;

  const currentPlanId = currentCompany.billing.currentLimitId;
  const isNewPlan = [...PLANS_IDS.pros, ...PLANS_IDS.starters].includes(currentPlanId);

  const { data: blogPostCountData } = useFileCounter(EXCLUDE_TEMPLATE_TYPES.blogPost);
  const { data: articlesCountData } = useFileCounter(EXCLUDE_TEMPLATE_TYPES.articles);

  const { templates } = TemplatesContext.useTemplates();

  let generators = templates ? Object.values(templates) : [];

  const isLegacyTemplatesHidden = isNewPlan || currentUser.onFreePlan;
  const excludedTemplateTypes = [
    EXCLUDE_TEMPLATE_TYPES.ffseInstruct,
    EXCLUDE_TEMPLATE_TYPES.blogKickstarter,
  ];

  if (blogPostCountData?.count === 0 && isLegacyTemplatesHidden) {
    excludedTemplateTypes.push(EXCLUDE_TEMPLATE_TYPES.blogPost);
  }
  if (articlesCountData?.count === 0 && isLegacyTemplatesHidden) {
    excludedTemplateTypes.push(EXCLUDE_TEMPLATE_TYPES.articles);
  }

  generators = generators.filter(
    (generator) => {
      const notLiveCustom = generator.templateType.startsWith('custom_') && generator.status !== 'live';
      return !excludedTemplateTypes.includes(generator.templateType) && !notLiveCustom;
    },
  );

  const { filterGenerators } = currentExternalSource;

  const typeFilterOptions = filterGenerators(generators).map((x) => ({
    value: x.templateType,
    label: x.title,
    Icon: x.Icon,
  }));

  const handleChange = React.useCallback((key) => (value) => {
    dispatch({
      type: actions.SET_FILTERS,
      payload: {
        filters: {
          ...filters,
          [key]: value,
        },
      },
    });
  }, [dispatch, filters]);

  return (
    <div className={classes.selectContainer}>
      <Select
        className={classes.typeSelect}
        handleChange={handleChange('templateTypes')}
        options={typeFilterOptions}
        value={getSelectedValue(typeFilterOptions, filters.templateTypes)}
        placeholder="Filter by template type"
      />
      {currentUser.permissions.workflowQueue
        && hasTeamPermissions && (
          <Select
            className={classes.statusSelect}
            handleChange={handleChange('workflowStatuses')}
            options={statusOptions}
            value={getSelectedValue(statusOptions, filters.workflowStatuses)}
            placeholder="Workflow status"
          />
      )}
    </div>
  );
};

export default Filters;
