import React from 'react';

import graphic1 from '@root/assets/copysmith-workflow-medium.png';

export default [
  {
    name: 'Workflow Illustration',
    component: (
      <div style={{ textAlign: 'center', margin: '-2rem 0 -8rem 0' }}>
        <img
          src={graphic1}
          alt="logo"
          width="50%"
        />
      </div>
    ),
  },

];
