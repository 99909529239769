import React from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';

import WordCountRow from './components/WordCountRow';
import BadgeIcon from './components/BadgeIcon';
import ReadabilityBadge from './components/ReadabilityBadge';

const useStyles = makeStyles(() => ({
  secondWordsRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0.75rem 0 0 0 ',
  },
  line: {
    marginBottom: '0.75rem',
  },
}));

const BadgeRows = ({
  wordCount: workCountArray, // [title, value, limit (optional)]
  wordCountSecondRow,
  readabilityScore,
  readingTime,
  isNew,
  showChar,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container>
        {isNew && <BadgeIcon highlight text="New" />}
        {readabilityScore && (
          <ReadabilityBadge readabilityScore={readabilityScore} readingTime={readingTime} />
        )}
        {workCountArray && workCountArray.map((badge) => (
          <WordCountRow
            key={badge.title}
            title={badge.title}
            value={badge.value}
            limit={badge.limit || 0}
            showChar={showChar}
          />
        ))}
      </Grid>
      {wordCountSecondRow && (
        <Grid item className={classes.secondWordsRowContainer}>
          {wordCountSecondRow.map((badge) => (
            <WordCountRow
              key={badge.title}
              title={badge.title}
              value={badge.value}
              limit={badge.limit || 0}
              showChar={showChar}
            />
          ))}
        </Grid>
      )}
      <Grid item>
        <hr className={classes.line} />
      </Grid>
    </Grid>
  );
};

export default BadgeRows;
