import { IconStar, IconShoppingCart, IconAd, IconArticle, IconAppWindow, IconBrain, IconComet } from '@tabler/icons-react';

import { colors } from '@root/material.theme';

const buckets = [
  {
    id: 'made-for-you',
    title: 'Made for you',
    icon: IconStar,
    primaryColor: colors.yellow600,
    secondaryColor: colors.yellow200,
  },
  {
    id: 'ecommerce',
    title: 'eCommerce',
    icon: IconShoppingCart,
    primaryColor: colors.purple600,
    secondaryColor: colors.purple200,
  },
  {
    id: 'ads',
    title: 'Ads',
    icon: IconAd,
    primaryColor: colors.blue600,
    secondaryColor: colors.blue200,
  },
  {
    id: 'articles-and-blogs',
    title: 'Articles & Blogs',
    icon: IconArticle,
    primaryColor: colors.green600,
    secondaryColor: colors.green200,
  },
  {
    id: 'branding-and-websites',
    title: 'Branding & Web',
    icon: IconAppWindow,
    primaryColor: colors.pink600,
    secondaryColor: colors.pink200,
  },
  {
    id: 'brainstorming',
    title: 'Brainstorming',
    icon: IconBrain,
    primaryColor: colors.orange600,
    secondaryColor: colors.orange200,
  },
  {
    id: 'enhancement',
    title: 'Enhancement',
    icon: IconComet,
    primaryColor: colors.neutrals400,
    secondaryColor: colors.neutrals50,
  },
];

export default buckets;
