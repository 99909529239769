import { useQuery } from 'react-query';

import api from '@root/api';

export default function useSearchCatalog(data) {
  return useQuery(
    ['catalogs.search', data.searchQuery],
    () => api.catalog.search(data), {
      staleTime: 30 * 1000,
    },
  );
}
