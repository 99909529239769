import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  progressWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: 560,
    padding: '12px 32px 12px 32px',
    borderRadius: '10px',
    height: '72px',
    background: theme.palette.colors.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  },
  progressText: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  percentBar: {
    width: '100%',
    borderRadius: '4px',
    background: '#c1c1c1',
    height: '1rem',
    overflow: 'hidden',
    marginRight: '8px',
  },
  percentBarInner: {
    height: '100%',
    borderRadius: '4px',
    background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, #FFD965 52.08%, #46DC00 100%)`,
  },
  percentBarInnerSuccess: {
    height: '100%',
    borderRadius: '4px',
    background: '#46DC00',
  },
  percentBarInnerFailed: {
    height: '100%',
    borderRadius: '4px',
    background: theme.palette.secondary.main,
  },
  percentBarInnerCanceled: {
    height: '100%',
    borderRadius: '4px',
    background: theme.palette.secondary.main,
  },
}));

export default useStyles;
