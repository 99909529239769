import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ArrowDropDown } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import api from '@root/api/newApi';
import templatesContext from '@root/resources/templates/templates.context';
import { FolderOptionsKebab } from '@root/views/Dashboard/components/IconButtons';

import FolderIconWithNumber from './FolderIconWithNumber';
import InfileSidebarFolderRow from './InfileSidebarFolderRow';

const useStyles = makeStyles((theme) => ({
  inFolderNavigation: {
    padding: '24px 16px',
  },
  folderDropdown: {
    position: 'relative',
    backgroundColor: '#EEEBFD',
    height: '56px',
    width: '100%',
    padding: '10px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    overflow: 'hidden',
  },
  greyFolderDropdown: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    color: '#000',
  },
  folderDropdownIcon: {
    fontSize: '32px',
    marginTop: '-6px',
  },
  folderDropDownText: {
    lineHeight: '40px',
    marginLeft: '10px',
    fontSize: '16px',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  foldersListWrap: {
    border: `2px solid ${theme.palette.primary.main}`,
    maxHeight: '450px',
    overflow: 'auto',
    transition: 'max-height 0.6s ease-in-out',
    '&::-webkit-scrollbar': { width: '9px', height: '8px' },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      background: 'rgba(0,0,0,0.1)',
      border: '1px solid #ccc',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: '#a8a8a8',
      border: '1px solid #aaa',
    },
    '&::-webkit-scrollbar-thumb:hover': { background: '#fff' },
  },
  foldersListHidden: {
    maxHeight: '0px',
    border: 0,
    overflow: 'hidden',
    transition: 'max-height 0s',
  },
  folderTitle: {
    fontSize: '16px',
    display: 'inline-block',
    paddingTop: '3px',
    marginLeft: '10px',
  },
  titleSelected: {
    color: theme.palette.primary.main,
  },
  foldersKebabWrap: {
    position: 'absolute',
    right: '5px',
    top: '12px',
  },
  halfOpacity: {
    opacity: '0.5',
  },
  minusMargin: {
    marginLeft: '-15px',
  },
  dropdownTextRow: {
    display: 'inline-block',
  },
}));

const EmptyFolderRow = ({ classes, onClick, projectTitle }) => (
  <div className={clsx(classes.halfOpacity, classes.minusMargin)}>
    <InfileSidebarFolderRow onClick={onClick}>
      <>
        <FolderIconWithNumber isGrey />
        <span className={classes.folderTitle}>
          {projectTitle}
        </span>
      </>
    </InfileSidebarFolderRow>
  </div>
);

const FoldersDropdown = ({
  selectedFolderId, foldersList, setSelectedFolderId, autoSave,
}) => {
  const classes = useStyles();
  const [isFoldersDropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const { getTemplate } = templatesContext.useTemplates();
  const selectedFolder = foldersList.find((f) => f._id === selectedFolderId) || {};

  const toggleDropdownOpen = () => {
    setDropdownOpen(!isFoldersDropdownOpen);
  };

  const moveToFirstFileInFolder = async (newFolderId) => {
    const files = await api.files.list({ folderId: newFolderId });
    if (files && files.results && files.results.length) {
      const firstFileInFolder = files.results[0];
      const { slug } = getTemplate(firstFileInFolder.templateType);
      history.push(`/${slug}/${firstFileInFolder._id}?title=${firstFileInFolder.title}`);
    }
  };

  const onFolderClick = (folder) => async () => {
    await autoSave();
    setSelectedFolderId(folder._id);
    setDropdownOpen(false);
    moveToFirstFileInFolder(folder._id);
  };

  const parentProject = useMemo(() => (
    selectedFolder.parentFolderId ? foldersList.find((f) => selectedFolder.parentFolderId === f._id)
      : foldersList.find((f) => selectedFolderId === f._id)
  ), [selectedFolder]);

  const availableFolders = parentProject
    && foldersList.filter((folder) => folder.parentFolderId === parentProject._id);

  if (availableFolders.length === 0) return null;

  return (
    <div className={classes.inFolderNavigation}>
      <div
        onClick={toggleDropdownOpen}
        className={clsx(classes.folderDropdown, {
          [classes.greyFolderDropdown]: !selectedFolder.parentFolderId,
        })}
      >
        <ArrowDropDown className={clsx(classes.folderDropdownIcon, {
          [classes.rotated]: isFoldersDropdownOpen,
        })}
        />
        <div className={clsx(classes.dropdownTextRow, {
          [classes.halfOpacity]: !selectedFolder.parentFolderId,
        })}
        >
          <FolderIconWithNumber
            isGrey={!selectedFolder.parentFolderId}
            numberAsString={selectedFolder.parentFolderId ? selectedFolder.filesCount : ''}
          />
          <span className={classes.folderDropDownText}>
            {selectedFolder.parentFolderId ? selectedFolder.title
              : <span className={classes.halfOpacity}>{parentProject && parentProject.title}</span>}
          </span>
        </div>
        <div className={classes.foldersKebabWrap}>
          {selectedFolder.parentFolderId && (
            <FolderOptionsKebab
              shortened
              variant="list"
              folder={selectedFolder}
            />
          )}
        </div>
      </div>
      <div className={clsx(classes.foldersListWrap, {
        [classes.foldersListHidden]: !isFoldersDropdownOpen,
      })}
      >
        {(selectedFolder.parentFolderId && parentProject) && (
          <EmptyFolderRow
            onClick={onFolderClick(parentProject)}
            classes={classes}
            projectTitle={parentProject && parentProject.title}
          />
        )}
        {availableFolders.map((f) => {
          const isSelected = selectedFolder._id === f._id;
          return (
            <InfileSidebarFolderRow
              selected={isSelected}
              key={f._id}
              onClick={onFolderClick(f)}
            >
              <FolderIconWithNumber
                isGrey={!isSelected}
                numberAsString={f.filesCount}
              />
              <span className={clsx(classes.folderTitle, {
                [classes.titleSelected]: isSelected,
              })}
              >
                {f.title}
              </span>
              <div className={classes.foldersKebabWrap}>
                {!isSelected && (
                  <FolderOptionsKebab
                    shortened
                    variant="list"
                    folder={f}
                  />
                )}
              </div>
            </InfileSidebarFolderRow>
          );
        })}
      </div>
    </div>
  );
};

export default FoldersDropdown;
