import React from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { getFavorites, saveFavoritesInLocalStorage } from '@root/resources/aiTemplate/favorites';
import { handleTemplateSearch } from '@root/utils/text.utils';
import templatesContext from '@root/resources/templates/templates.context';
import useCreateFile from '@root/resources/file/useCreateFile';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

const actions = Object.freeze({
  UPDATE_TITLE: 'UPDATE_TITLE',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  SEARCH: 'SEARCH',
  ADD_FAVORITE: 'ADD_FAVORITE',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
});

const initialState = {
  title: 'Untitled File',
  folder: null,
  query: null,
  useCaseValue: '',
  searchFieldValue: '',
  favorites: [],
  hideFavorite: false,
  onCreateClick: null,
  isBulk: false,
  customTemplatesFilter: () => true,
};

const SEARCH_FIELD_TYPES = {
  USE_CASE: 'useCase',
  SEARCH_FIELD: 'searchField',
};

const TemplatesDashboardContext = React.createContext();

const templateDashboardReducer = ({ history, location }) => {
  return (state, action) => {
    switch (action.type) {
      case actions.UPDATE_TITLE:
        return { ...state, title: action.payload };
      case actions.UPDATE_FOLDER:
        return { ...state, folder: action.payload };
      case actions.SEARCH: {
        if (!action?.payload?.query) {
          history.goBack();
        } else if (!state.query) {
          history.push('/create/search', { from: location.pathname });
        }

        if (action.payload.type === SEARCH_FIELD_TYPES.SEARCH_FIELD) {
          const q = handleTemplateSearch(action.payload.query);
          return { ...state, query: q, searchFieldValue: q };
        }

        if (action.payload.type === SEARCH_FIELD_TYPES.USE_CASE) {
          return {
            ...state,
            useCaseValue: action.payload.query,
            ...(!state.searchFieldValue && { query: handleTemplateSearch(action.payload.query) }),
          };
        }

        return { ...state, query: action.payload.query };
      }
      case actions.ADD_FAVORITE: {
        const { favorites } = state;
        favorites.push(action.payload);

        saveFavoritesInLocalStorage(favorites);

        return { ...state, favorites };
      }
      case actions.REMOVE_FAVORITE: {
        const favorites = state.favorites.filter((v) => v !== action.payload);

        saveFavoritesInLocalStorage(favorites);

        return { ...state, favorites };
      }
      default: {
        throw new Error(`Dashboard Context Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

const TemplatesDashboardProvider = ({ children, defaultState = {} }) => {
  const history = useHistory();
  const location = useLocation;
  const favorites = getFavorites();
  const { mutateAsync: createFile } = useCreateFile();

  const { nextStep } = onboardingContext.useOnboarding();
  const { getTemplate } = templatesContext.useTemplates();

  const [state, dispatch] = React.useReducer(
    templateDashboardReducer({ history, location }),
    { ...initialState, ...defaultState, favorites },
  );

  const handleCreate = async (templateType, editorTemplateType) => {
    const generator = getTemplate(templateType);
    const { slug } = generator;
    const { title, folder: folderId } = state;

    const newFile = await createFile({ title, folderId, templateType, slug });

    if (newFile.isBadRequest) {
      return;
    }

    const { _id, title: newFileTitle } = newFile;
    const url = `/${slug}/${_id}?title=${newFileTitle}`;
    history.push(url, { initialTemplate: editorTemplateType });
    nextStep();
  };

  const value = { state, dispatch, handleCreate };

  return (
    <TemplatesDashboardContext.Provider value={value}>
      {children}
    </TemplatesDashboardContext.Provider>
  );
};

const useTemplatesDashboard = () => {
  const context = React.useContext(TemplatesDashboardContext);

  if (context === undefined) {
    throw new Error('useTemplatesDashboard must be used within a DashboardProvider');
  }

  return context;
};

export {
  TemplatesDashboardProvider, useTemplatesDashboard, actions, SEARCH_FIELD_TYPES,
};
