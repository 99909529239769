import React from 'react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  ListItemText, ListItemIcon, Grow,
  Popper, ClickAwayListener, MenuList, MenuItem,
} from '@material-ui/core';

import ProgressButton from '@root/components/ProgressButton';
import templatesContext from '@root/resources/templates/templates.context';

import templatesConfig, { selectTitleMap } from '../../constants/freeFormEditor.templatesConfig';

import useStyles from './SelectTemplateButton.styles';

const SelectTemplateButton = ({
  className = '',
  selectedTemplateType,
  onTemplateSelected,
  isLoading,
}) => {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const { templates = [] } = templatesContext.useTemplates();

  const allTemplates = React.useMemo(() => {
    const res = [];
    if (templates.length === 0) {
      return res;
    }
    const supportedTemplates = Object.keys(templatesConfig);
    supportedTemplates.forEach((type) => {
      res.push(templates[type]);
    });

    return res;
  }, [templates]);

  const selectTemplate = (templateType) => () => {
    onTemplateSelected(templateType);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const freeFormTemplateConfig = templatesConfig[selectedTemplateType];
  const templateConfig = templates[selectedTemplateType];
  const Icon = templateConfig?.Icon ? templateConfig.Icon : freeFormTemplateConfig.Icon;

  return (
    <div className={clsx(classes.templateButtonWrap, className)}>
      <ProgressButton
        size="small"
        onClick={handleToggle}
        btnRef={anchorRef}
        className={classes.templateGenerateButton}
        variant="outlined"
        isLoading={isLoading}
      >
        <div className={classes.templateTitle}>
          {Icon && <Icon size={16} color="rgba(82, 58, 231, 1)" />}
          {freeFormTemplateConfig.actionTitle}
        </div>
        {open ? (
          <ArrowDropUpIcon
            classes={{ root: classes.arrowIcon }}
          />
        ) : (
          <ArrowDropDownIcon
            classes={{ root: classes.arrowIcon }}
          />
        )}
      </ProgressButton>
      <Popper
        className={classes.popover}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <ClickAwayListener
              onClickAway={handleClose}
            >
              <MenuList
                className={classes.menu}
                autoFocusItem={open}
                id="menu-list-grow"
              >
                {allTemplates.map((t) => {
                  const template = templates[t.templateType];
                  const title = selectTitleMap[t.templateType] || template.title;
                  return (
                    <MenuItem
                      key={t.templateType}
                      className={classes.menuItem}
                      onClick={selectTemplate(t.templateType)}
                      dense
                    >
                      <ListItemIcon dense className={classes.iconWrap}>
                        {t.Icon ? <t.Icon size={20} color="black" /> : null}
                      </ListItemIcon>
                      <ListItemText
                        dense
                        className={classes.menuItemText}
                        primary={title}
                        secondary={t.description}
                      />
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SelectTemplateButton;
