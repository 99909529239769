import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  details: {
    margin: '14px 0 32px',
  },
  feature: {
    marginBottom: 16,

    '& a': {
      color: theme.palette.primary.main,
    },

    '& > :not(:last-child)': {
      marginBottom: 16,
    },
  },
  overageText: {
    color: theme.palette.colors.neutrals400,
  },
}));

export default useStyles;
