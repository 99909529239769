import React from 'react';

const TemplateListWrapper = ({ children }) => {
  if (!(children instanceof Function)) {
    throw new Error('TemplateListWrapper children should be a function');
  }

  return (
    <>
      {children()}
    </>
  );
};

export default TemplateListWrapper;
