import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import SocialIcon from './SocialIcon';
import useStyles from './SpecialSignInButton.styles';

const SocialSignInButton = ({
  icon, title, className, onClick, disabled,
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, className)}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
    >
      {icon && <SocialIcon icon={icon} />}
      {title}
    </Button>
  );
};

SocialSignInButton.propTypes = {
  icon: PropTypes.oneOf(['google', 'outlook']),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

SocialSignInButton.defaultProps = {
  icon: undefined,
  className: '',
  disabled: false,
};

export default SocialSignInButton;
