import React from 'react';

import { buckets } from '@root/views/TemplatesDashboardNew/filters.buckets.constant';

import FilterItem from './FilterItem';
import useStyles from './Filters.styles';

const Filters = () => {
  const classes = useStyles();

  return (
    <div className={classes.filtersContainer}>
      {Object.values(buckets).map((bucket) => (
        <FilterItem
          bucket={bucket}
          key={`filter-item-${bucket.id}`}
        />
      ))}
    </div>
  );
};

export default Filters;
