import React, { CSSProperties } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import { CloseRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import useStyles from './NotificationBarWide.styles';

type ComponentTypes = {
  title: string | null | undefined,
  text: string,
  variant: 'standard' | 'filled' | 'outlined',
  type: 'error' | 'warning',
  icon: React.ReactNode
  open: boolean,
  styles: CSSProperties,
  onClose: (event: React.MouseEvent) => void;
};

const NotificationBarWide = ({
  title, text, type, open, icon, styles, variant, onClose,
}: ComponentTypes) => {
  const classes = useStyles({ type });

  return (
    <Alert
      className={clsx(classes.core, classes[type])}
      severity={type}
      icon={icon}
      variant={variant}
      style={styles}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {text}
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseRounded />
      </IconButton>
    </Alert>
  );
};

export default NotificationBarWide;
