import React from 'react';

import Button from '@root/components/buttons/Button';
import useDeleteFolder from '@root/resources/folder/useDeleteFolder';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import useStyles from './DeleteFolderModal.styles';

const DeleteFolderModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { mutateAsync: deleteFolder } = useDeleteFolder();
  const { state: { modalContent } } = useGlobalModals();

  const folderId = modalContent?.folder?._id;
  const parentFolderId = modalContent?.folder?.parentFolderId;
  const title = modalContent?.folder?.title;

  const handleDelete = async () => {
    await deleteFolder(folderId);
    closeModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      title={`Delete ${parentFolderId ? 'folder' : 'project'} '${title}'?`}
      subtitle="This action cannot be undone."
      classNames={{ paper: classes.paper }}
    >
      <div className={classes.buttonsContainer}>
        <Button variant="text" onClick={closeModal}>Discard</Button>
        <Button
          type="button"
          onClick={handleDelete}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteFolderModal;
