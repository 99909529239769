import React from 'react';
import { Form, Formik } from 'formik';

import { RadioButtonField, FormField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import useGeneratePCMBulk from '@root/resources/bulkGenerate/useGeneratePCMBulk';
import useCurrentPCMJob from '@root/resources/bulkGenerate/useCurrentPCMJob';
import useInvalidateCatalogItems from '@root/resources/catalogItem/useInvalidateCatalogItems';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import useStyles from './BulkCopyForm.styles';

const BulkCopyForm = ({ catalogId, selectedItemIds }) => {
  const classes = useStyles();

  const { refetch: reloadPCMJob } = useCurrentPCMJob();
  const { mutateAsync: generatePCMBulk } = useGeneratePCMBulk();
  const { invalidateCatalogItems } = useInvalidateCatalogItems();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const submitFieldSelection = async (values) => {
    await generatePCMBulk({ catalogItemIds: selectedItemIds, catalogId, field: values.fieldSelection });
    await invalidateCatalogItems();
    await reloadPCMJob();
    closeModal();
  };

  const options = [
    { value: 'description', label: 'Product Description' },
    { value: 'bulletPoints', label: 'Product Bullet Points' },
    { value: 'title', label: 'Product Title' },
  ];

  return (
    <Formik
      initialValues={{ fieldSelection: '' }}
      onSubmit={submitFieldSelection}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className={classes.info}>
              What would you like to generate?
            </div>
            <div className={classes.formGroup}>
              <FormField
                name="fieldSelection"
                component={RadioButtonField}
                options={options}
                required
              />
            </div>
            <div className={classes.actions}>
              <Button
                type="submit"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Generate
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BulkCopyForm;
