import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import Button from '@root/components/buttons/Button';

import useStyles from './Actions.styles';

const Actions = ({ templateType, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { state, handleCreate } = useTemplatesDashboard();

  const { title, folder: folderId } = state;
  const isBlogKickstarter = templateType === 'blogKickstarter';

  const goToBlogKickstarter = () => {
    history.push('/blog-kickstarter', { title, folderId });
  };

  return (
    <div className={classes.actions}>
      <Button
        size="small"
        variant="text"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        size="small"
        onClick={() => (
          isBlogKickstarter ? goToBlogKickstarter() : handleCreate(templateType)
        )}
        disabled={!state.title}
      >
        {isBlogKickstarter ? 'Continue' : 'Create'}
      </Button>
    </div>
  );
};

export default Actions;
