import React from 'react';

import Button from '@root/components/buttons/Button';
import authContext from '@root/resources/auth/auth.context';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import { CREATE_CATALOG_MODAL } from '../CreateModal';

import useStyles from './EmptyCatalog.styles';

const EmptyCatalog = () => {
  const classes = useStyles();
  const { currentUser } = authContext.useAuth();
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleCreateClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: CREATE_CATALOG_MODAL },
    });
  };

  return (
    <div className={classes.page}>
      <p className={classes.header}>
        Hi&nbsp;
        {currentUser.displayName || currentUser.firstName || currentUser.email}
        , getting started is easy
      </p>
      <p className={classes.subHeader}>
        It looks a little empty in here, don&lsquo;t you think?
        Let&lsquo;s fix that by creating your very first catalog!
        A catalog is where your amazing products will live ready for you to make them shine.
        Don&lsquo;t be shy, give it a try!
      </p>
      <div>
        <Button
          onClick={handleCreateClick}
          className={classes.createNewButton}
        >
          Create catalog
        </Button>
      </div>
    </div>
  );
};

export default EmptyCatalog;
