import {
  Document, Packer, Paragraph, TextRun,
} from 'docx';
import jsPDF from 'jspdf';

/** Escape double quotes in a CSV */
export const escapeCSV = (str) => str.replace('"', '""');

export const convertHTMLToText = (html) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(
    `<!doctype html><body>${html}`,
    'text/html',
  );
  const decodedString = dom.body.textContent;
  return decodedString;
};

/**
 * Copy text directly to clipboard
 *
 * @param {string} textToCopy
 */
export const copyToClipboard = (textToCopy) => {
  const textAreaPlaceholder = document.createElement('textarea');
  document.body.appendChild(textAreaPlaceholder);
  textAreaPlaceholder.value = textToCopy;
  textAreaPlaceholder.select();
  document.execCommand('Copy');
  textAreaPlaceholder.remove();
};

/**
   * Download text as the file specified.
   *
   * @param {string} textToDownload
   * @param {string} filename
   *
   */
export const downloadAsFile = (textToDownload, filename, type = 'text/plain') => {
  textToDownload = textToDownload
    .replaceAll('“', '"')
    .replaceAll('”', '"')
    .replaceAll('’', "'")
    .replaceAll('‘', "'");

  const blob = new Blob([textToDownload], { type });
  const link = window.document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadAsDocX = (textToDownload, filename) => {
  const paragraphs = [];

  textToDownload.split('\n').forEach((line) => {
    const p = new Paragraph({
      children: [
        new TextRun({
          text: line,
          color: '000000',
        }),
      ],
    });
    paragraphs.push(p);
  });

  const doc = new Document({
    creator: 'Copysmith.ai',
    sections: [{
      properties: {},
      children: paragraphs,
    }],
  });
  Packer.toBlob(doc).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.click();
  });
};

export const getDateString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const hh = `${date.getHours()}`.padStart(2, '0');
  const mm = `${date.getMinutes()}`.padStart(2, '0');
  const ss = `${date.getSeconds()}`.padStart(2, '0');

  return `${year}${month}${day}-${hh}${mm}${ss}`;
};

export const downloadHtmlAsPDF = (html, name) => {
  const fileName = `${name || getDateString()}.pdf`;
  const fixedHtml = html.replaceAll('<li>', '<li style="padding-left: 20px;">'); // fix list formatting bug
  const htmlData = `<div style='font-size:12px; width:500px; letter-spacing: 0.3px'>${fixedHtml}</div>`;
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'pt', 'a4');
  doc.html(
    htmlData,
    {
      callback(d) {
        d.save(fileName);
      },
      autoPaging: 'text',
      margin: [20, 0, 20, 40],
    },
  );
};

export const downloadAsPNG = async (fileName, url) => {
  const a = document.createElement('a');
  const blob = await fetch(url).then((res) => res.blob());
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
   * Returns a nicely-formatted filename including date, companyname, and card title
   * @param {string} companyName
   * @param {string} cardTitle
   */
export const makeFileName = (companyName, cardTitle = '') => `Copysmith_${companyName}_${cardTitle}_${getDateString()}.csv`;
