import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateOveragesSettings() {
  const queryClient = useQueryClient();

  return useMutation(
    () => api.billing.payOverageInvoice(),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.setQueryData(['billing.getInfo', {}], (billingInfo) => ({
          ...billingInfo,
          overageInvoiceStatus: 'paid',
        }));

        return response;
      },
    },
  );
}
