import React, { useState } from 'react';

import { getPlanItems, getPlanItemContent, formatCurrency, formatAmount } from '@root/resources/billing/billingInfo.helpers';
import Button from '@root/components/buttons/Button';
import SelfServeEnterprisePlanDetails from '@root/views/Profile/SelfServeEnterprise/components/PlanDetails';
import useProration from '@root/utils/hooks/useProration';

import PromotionCode from './PromotionCode/PromotionCode';
import useStyles from './PlanDetails.styles';

const planItems = getPlanItems({ isFullList: false });

const PlanDetails = ({
  plan,
  promotion,
  onPromoClear,
  onPromoApply,
  refereeCredit,
  isSelfServeEnterprise,
  selfServePipelineData,
  proratedWords,
}) => {
  const classes = useStyles();
  const [showPromo, setShowPromo] = useState(false);

  const proration = useProration(plan._id);

  const price = Math.ceil(plan.amount / 100);
  const proratedPrice = (proration?.total / 100) || price;

  const { percentOff = 0 } = promotion || {};
  const { amountOff = 0 } = promotion || {};
  const discount = (price * percentOff) / 100 + amountOff / 100;
  const referralAward = refereeCredit ? Math.abs(refereeCredit / 100) : 0;
  let total = proratedPrice - discount - referralAward;
  if (total < 0) {
    total = 0;
  }

  const isEnterprise = plan.rank === 500;

  const showPromoField = () => {
    setShowPromo(true);
  };

  const renderPromo = () => {
    if (isEnterprise) {
      return null;
    }
    if (showPromo) {
      return (
        <PromotionCode
          promotion={promotion}
          onClear={onPromoClear}
          onApply={onPromoApply}
          discount={discount}
          planId={plan._id}
        />
      );
    }
    return (
      <Button
        variant="link"
        onClick={showPromoField}
      >
        Add promotion code
      </Button>
    );
  };

  const renderReferralCredit = () => {
    if (!refereeCredit) {
      return null;
    }

    return (
      <div className={classes.referralAward}>
        <span className={classes.referralAwardDescription}>Referral Award</span>
        <span className={classes.referralAwardDiscount}>{`-${formatAmount(refereeCredit)}`}</span>
      </div>
    );
  };

  return (
    <div className={classes.planDetailsRoot}>
      {!isSelfServeEnterprise ? (
        <div className={classes.planInfo}>
          <div className={classes.planName}>
            <span>
              {plan.nickname || `${plan.name} Plan`}
            </span>
            <span>
              {`${formatCurrency(price)} ${plan.period === 'monthly' ? '/month' : '/year'}`}
            </span>
          </div>
          <div className={classes.planItemList}>
            {Object.keys(planItems).map((key) => {
              const planItemContent = getPlanItemContent(plan.rank, key);
              if (!planItemContent) {
                return null;
              }
              return (
                <div className={classes.planItem} key={key}>
                  <span>{planItems[key]}</span>
                  <span>{planItemContent}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <SelfServeEnterprisePlanDetails
          plan={plan}
          pipelineData={selfServePipelineData}
          proration={proration}
          proratedWords={proratedWords}
        />
      )}
      <div className={classes.subtotal}>
        <span>Subtotal</span>
        <span>{formatCurrency(price)}</span>
      </div>
      {renderPromo()}
      {renderReferralCredit()}
      <div className={classes.total}>
        <span>Total</span>
        <span>{formatCurrency(total)}</span>
      </div>
    </div>
  );
};

export default PlanDetails;
