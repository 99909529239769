import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  folderRowWrap: {
    fontSize: '14px',
    height: '56px',
    padding: '0 16px 0 10px',
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
    cursor: 'pointer',
    '&:last-child > div': {
      borderBottom: '0',
    },
    position: 'relative',
  },
  selected: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
  },
  folderRow: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
    padding: '0 8px 0 45px',
  },
}));

const InfileSidebarFileRow = ({ children, onClick, selected = false }) => {
  const classes = useStyles();
  return (
    <div
      onClick={onClick}
      className={clsx(classes.folderRowWrap, {
        [classes.selected]: selected,
      })}
    >
      <div className={classes.folderRow}>
        {children}
      </div>
    </div>
  );
};

export default InfileSidebarFileRow;
