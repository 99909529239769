import Covid from './CovidEngineTemplate';
import MenFashion from './MenFashionEngineTemplate';
import WomenFashion from './WomenFashionEngineTemplate';
import Technology from './TechnologyEngineTemplate';
import Travel from './TravelEngineTemplate';
import DavinciPlusEngineTemplate from './DavinciPlusEngineTemplate';

export default {
  Covid,
  MenFashion,
  WomenFashion,
  Technology,
  Travel,
  DavinciPlusEngineTemplate,
};
