import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    textAlign: 'center',
  },
  card: {
    backgroundColor: theme.palette.colors.black,
    borderRadius: '8px',
    color: theme.palette.colors.white,
    padding: '30px 36px 30px 36px',
    marginBottom: '20px',
  },
  creditsRemainingTitle: {
    paddingBottom: '15px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
  },
  tooltip: {
    color: theme.palette.colors.white,
    marginBottom: '2px',
  },
}));

export default useStyles;
