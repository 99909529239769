import React, { useState } from 'react';
import { CircularProgress, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

import MainLayout from '@root/views/Layout/MainLayout';
import Divider from '@root/components/Divider/Divider';
import newMaterialTheme from '@root/newMaterial.theme';
import AuthContext from '@root/resources/auth/auth.context';
import { SelectField } from '@root/components/form';
import useListCreditRecords from '@root/resources/usage/useListCreditRecords';

import {
  OverageSection,
  PlanSection,
  SavingsSection,
  TemplatesUsageSection,
  WordsUsageChart,
  WordsUsageSection,
} from './Sections';
import useStyles from './UsageV2.styles';
import useSectionStyles from './Sections/Sections.styles';
import CreditRecordType from './UsageV2.types';

const UsageV2 = () => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const [selectedCreditRecord, setSelectedCreditRecord] = useState();

  const {
    data: creditRecords,
    isFetching: isCreditRecordsFetching,
  } = useListCreditRecords<CreditRecordType[]>();
  const { currentCompany }: { currentCompany: any } = AuthContext.useAuth();

  const isOverageSectionVisible = currentCompany?.billing.limits.mainCreditsOverage;

  const creditRecord = creditRecords && creditRecords.length > 0 ? creditRecords.find(
    (record) => {
      if (selectedCreditRecord) {
        return selectedCreditRecord === record._id;
      }
      if (creditRecords && creditRecords.length > 0) {
        return creditRecords[0]._id === record._id;
      }
      return undefined;
    },
  ) : undefined;

  const creditRecordOptions = isCreditRecordsFetching ? []
    : creditRecords?.filter((record) => record.type !== 'migration')
      .map((record) => ({
        label: `${DateTime.fromSeconds(record.createdOn).toFormat('LLL dd yyyy')} to ${DateTime.fromSeconds(record.endDate).toFormat('LLL dd yyyy')}`,
        value: record._id,
      }));

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <MainLayout>
        <div className={classes.usageContainer}>
          <header className={classes.header}>
            <div>
              <Typography
                classes={{ root: classes.title }}
              >
                Usage
              </Typography>
              <Typography
                classes={{ root: classes.subtitle }}
              >
                Manage your plan and billing details.
              </Typography>
            </div>

            {isCreditRecordsFetching
              ? <CircularProgress size={12} />
              : (
                // @ts-expect-error SelectField is not .ts yet
                <SelectField
                  onChange={(e: any) => setSelectedCreditRecord(e?.target?.value)}
                  options={creditRecordOptions}
                  value={selectedCreditRecord || creditRecords?.[0]._id}
                />
              )}
          </header>

          <Divider className={classes.divider} />

          <Grid
            container
            spacing={2}
            classes={{ item: sectionClasses.sectionContainer }}
          >
            <PlanSection
              creditRecord={creditRecord}
              isLoading={isCreditRecordsFetching}
            />

            <WordsUsageSection
              creditRecord={creditRecord}
              isLoading={isCreditRecordsFetching}
            />

            <SavingsSection
              isOverageSectionVisible={isOverageSectionVisible}
              creditRecord={creditRecord}
              isLoading={isCreditRecordsFetching}
            />

            <TemplatesUsageSection
              isOverageSectionVisible={isOverageSectionVisible}
              creditRecord={creditRecord}
              isLoading={isCreditRecordsFetching}
            />

            {!!isOverageSectionVisible && (
              <OverageSection
                creditRecord={creditRecord}
                isLoading={isCreditRecordsFetching}
              />
            )}

            <WordsUsageChart
              isOverageSectionVisible={isOverageSectionVisible}
            />
          </Grid>
        </div>
      </MainLayout>
    </ThemeProvider>
  );
};

export default UsageV2;
