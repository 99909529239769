import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '@root/components/buttons/Button';
import { InputField, FormField } from '@root/components/form';
import useUpdateCatalogItem from '@root/resources/catalogItem/useUpdateCatalogItem';
import uiNotificationService from '@root/services/uiNotification.service';

import useStyles from './EditProductRow.styles';

const EditProductRow = ({ product }) => {
  const classes = useStyles();
  const { mutateAsync, isLoading } = useUpdateCatalogItem();
  const initialValues = {
    title: product?.data?.title || '',
    description: product?.data?.description || '',
    externalId: product?.externalId || '',
    bulletPoints: product?.data?.bulletPoints || '',
    titleKeywords: product?.data?.titleKeywords || '',
    descriptionKeywords: product?.data?.descriptionKeywords || '',
    bulletPointsKeywords: product?.data?.bulletPointsKeywords || '',
  };

  const handleSubmit = async (values) => {
    await mutateAsync({
      ...values,
      id: product._id,
    });

    uiNotificationService.showSuccessMessage('Product updated successfully.');
  };

  const editProductSchema = Yup.object({
    externalId: Yup.string().required('ID is required').max(200, 'ID must be 200 characters or less.'),
    title: Yup.string().required('Title is required').max(2000, 'Title must be 2000 characters or less.'),
    titleKeywords: Yup.string().optional().max(500, 'Keywords must be 500 characters or less.'),
    bulletPoints: Yup.string().optional().max(2000, 'Bullet points must be 2000 characters or less.'),
    bulletPointsKeywords: Yup.string().optional().max(500, 'Keywords must be 500 characters or less.'),
    description: Yup.string().optional().max(4000, 'Description must be 4000 characters or less.'),
    descriptionKeywords: Yup.string().optional().max(500, 'Keywords must be 500 characters or less.'),
  });

  return (
    <div className={classes.editRow}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editProductSchema}
      >
        {() => (
          <Form>
            <div className={classes.wrapper}>
              <div className={classes.aSide}>
                <div className={classes.formGroup}>
                  <FormField
                    name="externalId"
                    label="Id"
                    placeholder=""
                    component={InputField}
                    required
                    defaultValue={initialValues.externalId}
                  />
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="title"
                    label="Title"
                    placeholder=""
                    component={InputField}
                    required
                    defaultValue={initialValues.title}
                    rows={3}
                    maxRows={25}
                  />
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="titleKeywords"
                    label="Title Keywords"
                    placeholder=""
                    component={InputField}
                    defaultValue={initialValues.titleKeywords}
                    rows={1}
                    maxRows={5}
                  />
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="bulletPoints"
                    label="Feature Bullet Points"
                    placeholder=""
                    multiline
                    component={InputField}
                    defaultValue={initialValues.bulletPoints}
                    rows={3}
                    maxRows={25}
                  />
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="bulletPointsKeywords"
                    label="Feature Bullet Keywords"
                    placeholder=""
                    component={InputField}
                    multiline
                    defaultValue={initialValues.bulletPointsKeywords}
                    rows={1}
                    maxRows={5}
                  />
                </div>
              </div>
              <div className={classes.aSide}>
                <div className={classes.formGroup}>
                  <FormField
                    name="description"
                    label="Description"
                    placeholder=""
                    multiline
                    component={InputField}
                    defaultValue={initialValues.description}
                    rows={3}
                    maxRows={25}
                  />
                </div>
                <div className={classes.formGroup}>
                  <FormField
                    name="descriptionKeywords"
                    label="Description Keywords"
                    placeholder=""
                    component={InputField}
                    multiline
                    defaultValue={initialValues.descriptionKeywords}
                    rows={1}
                    maxRows={5}
                  />
                </div>
              </div>
            </div>
            <div className={classes.actionBar}>
              <div>
                <Button type="submit">Save Changes</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductRow;
