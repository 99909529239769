import React from 'react';

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5287 5.52858C11.789 5.26823 12.2111 5.26823 12.4715 5.52858L15.1382 8.19524C15.3985 8.45559 15.3985 8.8777 15.1382 9.13805C14.8778 9.3984 14.4557 9.3984 14.1953 9.13805L12.0001 6.94279L9.80482 9.13805C9.54447 9.3984 9.12236 9.3984 8.86201 9.13805C8.60166 8.8777 8.60166 8.45559 8.86201 8.19524L11.5287 5.52858Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86201 14.8619C9.12236 14.6016 9.54447 14.6016 9.80482 14.8619L12.0001 17.0572L14.1953 14.8619C14.4557 14.6016 14.8778 14.6016 15.1382 14.8619C15.3985 15.1223 15.3985 15.5444 15.1382 15.8048L12.4715 18.4714C12.2111 18.7318 11.789 18.7318 11.5287 18.4714L8.86201 15.8048C8.60166 15.5444 8.60166 15.1223 8.86201 14.8619Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 5.33331C12.3681 5.33331 12.6666 5.63179 12.6666 5.99998V18C12.6666 18.3682 12.3681 18.6666 11.9999 18.6666C11.6317 18.6666 11.3333 18.3682 11.3333 18V5.99998C11.3333 5.63179 11.6317 5.33331 11.9999 5.33331Z"
      />
    </svg>

  );
};
