import React from 'react';
import {
  EmojiObjects as ExampleIcon,
  GetApp as ExportIcon,
  Tune as SettingsIcon,
} from '@material-ui/icons';

import useExport from './useExport';

const useMenuItems = ({
  fileTitle,
  documents,
  template,
  toggleExampleOpen,
  isCustomTemplate,
  setShowChar,
  classes,
}) => {
  const {
    downloadAsCsv,
    downloadAsDocx,
    downloadAsPdf,
    downloadAsTxt,
  } = useExport({
    documents,
    fileTitle,
    template,
  });

  const menuItems = React.useMemo(() => {
    return [
      !isCustomTemplate && template.example && {
        key: 'example',
        title: (
          <span className={classes.menuItem}>
            <ExampleIcon className={classes.menuItemIcon} />
            See examples
          </span>
        ),
        onClick: ({ handleClose }) => {
          handleClose();
          toggleExampleOpen();
        },
      },
      {
        key: 'export',
        title: (
          <span className={classes.menuItem}>
            <ExportIcon className={classes.menuItemIcon} />
            Download copy
          </span>
        ),
        items: [
          {
            title: 'Export as txt',
            onClick: ({ handleClose }) => {
              handleClose();
              downloadAsTxt();
            },
          },
          {
            title: 'Export as pdf',
            onClick: ({ handleClose }) => {
              handleClose();
              downloadAsPdf();
            },
          },
          {
            title: 'Export as csv',
            onClick: ({ handleClose }) => {
              handleClose();
              downloadAsCsv();
            },
          },
          {
            title: 'Export as docx',
            onClick: ({ handleClose }) => {
              handleClose();
              downloadAsDocx();
            },
          },
        ],
      },
      {
        key: 'settings',
        title: (
          <span className={classes.menuItem}>
            <SettingsIcon className={classes.menuItemIcon} />
            Settings
          </span>
        ),
        items: [
          {
            title: 'Character Count',
            onClick: ({ handleClose }) => {
              handleClose();
              setShowChar(true);
            },
          },
          {
            title: 'Word Count',
            onClick: ({ handleClose }) => {
              handleClose();
              setShowChar(false);
            },
          },
        ],
      },
    ].filter((i) => !!i);
  }, [
    toggleExampleOpen,
    downloadAsTxt,
    downloadAsPdf,
    downloadAsCsv,
    downloadAsDocx,
    classes,
  ]);

  return menuItems;
};

export default useMenuItems;
