import React from 'react';

import graphic1 from '@root/assets/copysmith-workflow-medium.png';

export default [
  {
    name: '',
    component: (
      <div style={{ textAlign: 'center', margin: '-6rem 0 -9rem 0' }}>
        <img
          src={graphic1}
          alt="logo"
          width="35%"
        />
      </div>
    ),
  },

];
