import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

import useInvalidateLimits from '../billing/useInvalidateLimits';

export default function useGenerateImage() {
  const queryClient = useQueryClient();
  const { invalidateLimits } = useInvalidateLimits();

  return useMutation(
    (data) => api.ai.generateImage(data),
    {
      onSuccess: () => {
        invalidateLimits();
        queryClient.invalidateQueries('documentImage.getLatestAiImages');
      },
    },
  );
}
