import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateOptions extends RequestOptions {
  inviteeEmail: string;
  inviteeRole: "admin" | "member" | "viewer";
}

export interface CancelOptions extends RequestOptions {
  inviteId: string
}

export interface UpdateOptions extends RequestOptions {
  inviteeRole: "admin" | "member" | "viewer";
}

export interface GetWithCodeOptions extends RequestOptions {
  acceptCode: string; 
}

export interface ValidateBySSOOptions extends RequestOptions {
  acceptCode: string; 
  code: string;
  redirectUri: string;
}

export interface AcceptInviteOptions extends RequestOptions {
  userId: string;
  displayName: string;
  withSSO: boolean;
}

export class Invite {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }
  
  // authenticated routes
  public list(options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("invites/list", {}, options);
  }

  public create(data: CreateOptions, options?: HandlingOptions):Promise<Record<string, unknown>>{
    return this.client.post("invites/", data, options);
  }

  public accept(acceptCode: string, data: AcceptInviteOptions, options?: HandlingOptions):Promise<Record<string, unknown>>{
    return this.client.post(`invites/accept/${acceptCode}`, data, options);
  }

  public cancel(inviteId:string, options?: HandlingOptions):Promise<Record<string, unknown>>{
    return this.client.del(`invites/${inviteId}`, {}, options);
  }
  
  public decline(acceptCode: string, options?: HandlingOptions):Promise<Record<string, unknown>>{
    return this.client.del(`invites/decline/${acceptCode}`, {}, options);
  }

  public update(inviteId: string, data: UpdateOptions, options?: HandlingOptions):Promise<Record<string, unknown>>{
    return this.client.put(`invites/${inviteId}`, data, options);
  }

  // public routes
  public get(data: GetWithCodeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("invites/getWithCode", data, options);
  } 
  
  public validateBySSO(data: ValidateBySSOOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("invites/validateBySSO", data, options);
  }
}