import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dot: {
    color: theme.palette.colors.grey,
    fontSize: 6,
    margin: '0 4px',
  },
  selected: {
    color: theme.palette.colors.darkGrey,
  },
  small: {
    fontSize: 4,
    margin: '1px 4px',
  },
}));

export default useStyles;
