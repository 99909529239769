import React from 'react';

import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import RenameFolderForm from './RenameFolderForm';
import useStyles from './RenameFolderModal.styles';

const RenameFolderModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { state: { modalContent } } = useGlobalModals();

  const folderType = modalContent?.folder?.parentFolderId ? 'folder' : 'project';
  const originalTitle = modalContent?.folder?.title;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      title={`Rename ${folderType} "${originalTitle}"`}
      subtitle={`Do you want to save changes in ${folderType} before exit?`}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      classNames={{ paper: classes.modal }}
    >
      <RenameFolderForm closeModal={closeModal} />
    </Modal>
  );
};

export default RenameFolderModal;
