import useCreateFile from './useCreateFile';
import useDeleteFile from './useDeleteFile';
import useUpdateFile from './useUpdateFile';
import useFiles from './useFiles';
import useFile from './useFile';
import useLikeFile from './useLikeFile';
import useShareFile from './useShareFile';
import useSharedFile from './useSharedFile';
import useCloneFile from './useCloneFile';
import usePresets from './usePresets';

export default {
  useCreateFile,
  useDeleteFile,
  useUpdateFile,
  useLikeFile,
  useFiles,
  useFile,
  useShareFile,
  useSharedFile,
  useCloneFile,
  usePresets,
};
