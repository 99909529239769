import { useQuery } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const getCurrentBulkJob = async () => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.getCurrentBulkJob({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
  });

  return products;
};

export default function useGetCurrentBulkJob() {
  return useQuery(['bigCommerce.getCurrentBulkJob'], () => getCurrentBulkJob(), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
