import React from 'react';
import { Tooltip } from '@material-ui/core';

import useStyles from './TextAreaInput.styles';

const TextAreaInput = ({
  name, label, value, maxLength, length, creditCost, quality,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={name}>{label}</label>
      <textarea
        name={name}
        className={classes.input}
        placeholder={'Write an example intro paragraph - this will help guide the style and topic.\n\nThen highlight the section you’d like to rewrite, enhance, or use to create additional content.'}
        readOnly
        disabled
        value={value}
      />
      <div className={classes.footer}>
        {creditCost && !!length && (
          <Tooltip
            title="The number of words used for each generation varies by original text length. Try shorter text to use fewer words."
          >
            <div
              className={classes.creditsBadge}
              style={{ background: quality?.qualityColor }}
            >
              Credit cost:
              {' '}
              {creditCost}
            </div>
          </Tooltip>
        )}
        {maxLength && !!length && (
          <div className={classes.counter}>
            {`${length}/${maxLength}`}
          </div>
        )}
      </div>

    </div>
  );
};

export default TextAreaInput;
