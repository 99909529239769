import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { BookmarkBorderSharp, BookmarkSharp } from '@material-ui/icons';
import clsx from 'clsx';

import useLikeFolder from '@root/resources/folder/useLikeFolder';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.colors.grey,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'relative',
  },
  liked: {
    color: theme.palette.primary.main,
  },
  bookmarkWrap: {
    position: 'relative',
    marginLeft: '5px',
  },
  loadingWrap: {
    position: 'absolute',
    right: '-7px',
    bottom: '-7px',
  },
  buttonWrap: {
    width: '28px',
    height: '28px',
  },
}));

const BookmarkFolder = ({ isDisabled, folderId, liked }) => {
  const classes = useStyles();
  const { isLoading, mutateAsync: toggleLikeFolder } = useLikeFolder();

  const handleLikeFile = (event) => {
    toggleLikeFolder(folderId);
    event.stopPropagation();
  };

  const BookmarkIcon = liked ? BookmarkSharp : BookmarkBorderSharp;
  return (
    <div className={classes.bookmarkWrap}>
      <BookmarkIcon
        className={clsx(classes.icon, {
          [classes.liked]: liked,
        })}
        onClick={!isDisabled && handleLikeFile}
      />
      <div className={classes.loadingWrap}>
        {isLoading && <CircularProgress size={10} />}
      </div>
    </div>
  );
};

export default BookmarkFolder;
