import { useQuery } from 'react-query';

import api from '@root/api';

const getFolder = async (id) => {
  if (!id) {
    return null;
  }
  const folder = await api.folders.getById(id);

  return folder;
};

export default function useFolder(id) {
  return useQuery(['folders.getById', id], () => getFolder(id));
}
