import React, { FC } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons-react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import useRoiStats from '@root/resources/usage/useRoiStats';

import useSectionStyles from '../Sections.styles';
import CreditRecordType from '../../UsageV2.types';

import useStyles from './SavingsSections.styles';

type Props = {
  isOverageSectionVisible: boolean;
  creditRecord?: CreditRecordType;
  isLoading: boolean;
};

type RoiStatsType = {
  timeSaved: number,
  moneySaved: number,
};

const SavingsSection:FC<Props> = ({ isOverageSectionVisible, creditRecord, isLoading }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const { data: roiStats, isFetching: isStatsFetching } = useRoiStats<RoiStatsType>(
    {
      startDate: creditRecord?.createdOn,
      endDate: creditRecord?.endDate,
    },
    {
      enabled: !!creditRecord,
      refetchOnMount: 'always',
    },
  );

  return (
    <Grid
      item
      md={isOverageSectionVisible ? 3 : 4}
      sm={4}
      xs={12}
    >
      <div className={clsx(sectionClasses.sectionContainer)}>
        <Typography classes={{ root: sectionClasses.sectionTitle }}>
          ROI Breakdown
        </Typography>

        {(isLoading || isStatsFetching) && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!(isLoading || isStatsFetching) && (
          <div className={sectionClasses.statsContainer}>
            <article
              className={clsx(sectionClasses.articleContainer, sectionClasses.fullWidthArticle)}
            >
              <div>
                <Typography classes={{ root: sectionClasses.articleTitle }}>
                  Saved by using Copysmith

                  {/* @ts-expect-error SimpleTooltip is not .ts yet */}
                  <SimpleTooltip
                    content={`You have saved $${roiStats?.moneySaved} while using Copysmith this past billing cycle`}
                    wrapped
                  >
                    <IconInfoCircle size={16} />
                  </SimpleTooltip>
                </Typography>

                <Typography classes={{ root: sectionClasses.articleValue }}>
                  {`$${roiStats?.moneySaved}`}
                </Typography>
              </div>
            </article>

            <article
              className={clsx(sectionClasses.articleContainer, sectionClasses.fullWidthArticle)}
            >
              <div>
                <Typography classes={{ root: sectionClasses.articleTitle }}>
                  Hours would have taken

                  {/* @ts-expect-error SimpleTooltip is not .ts yet */}
                  <SimpleTooltip
                    content={`You have saved ${roiStats?.timeSaved} hours while using Copysmith this past billing cycle`}
                    wrapped
                  >
                    <IconInfoCircle size={16} />
                  </SimpleTooltip>
                </Typography>

                <Typography classes={{ root: sectionClasses.articleValue }}>
                  {roiStats?.timeSaved}
                </Typography>
              </div>
            </article>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default SavingsSection;
