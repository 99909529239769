import cogoToast from 'cogo-toast';

const { error, success, warn } = cogoToast;

const showSuccessMessage = (message) => {
  success(message);
};

const showErrorMessage = (message) => {
  error(message);
};

const showWarnMessage = (message) => {
  warn(message, { hideAfter: 6 });
};

export default {
  showSuccessMessage,
  showErrorMessage,
  showWarnMessage,
};
