import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      fontSize: 14,
    },
    main: {
      display: 'flex',
      flex: 1,
      width: '100%',
      maxWidth: 1000,
      alignSelf: 'center',
    },
    side: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 500,
    },
    rightSide: {
      position: 'fixed',
      top: 120,
      left: '50%',
      overflowX: 'hidden',
      maxHeight: 'calc(100vh - 120px)',
      paddingBottom: 32,
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: 394,
      width: '100%',
    },
    needHelp: {
      margin: '32px 0',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      '& svg': {
        marginLeft: 8,
        color: theme.palette.primary.main,
      },
      '& > span': {
        cursor: 'pointer',
        margin: '0 4px',
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    successWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '34px 178px',
      alignItems: 'center',
    },
    radioGroup: {
      gap: 16,
      alignSelf: 'left',
    },
    title: {
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: '24px',
      marginBottom: 32,
    },
    button: {
      margin: '64px 0 32px',
      height: 36,
    },
  });
});

export default useStyles;
