"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWordsString = exports.countWordsHtml = exports.countWordsString = exports.removeHtmlTags = exports.replaceHtmlSpecialCharacters = void 0;
const lodash_1 = require("lodash");
const htmlTagRegexp = /(<([^>]+)>)/ig;
/**
 * @desc Returns decoded string without special html characters
 *
 * @param input {string} - any sentence
 * @return {number} - sentence decoded without special characters
 */
const replaceHtmlSpecialCharacters = (input) => {
    const specialCharactersRegexp = /&(nbsp|amp|quot|lt|gt);/g;
    const specialCharactersMap = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
    };
    return (input === null || input === void 0 ? void 0 : input.replace(specialCharactersRegexp, (match, entity) => specialCharactersMap[entity] || "")) || "";
};
exports.replaceHtmlSpecialCharacters = replaceHtmlSpecialCharacters;
/**
 * @desc Returns text without html tags
 *
 * @param input {string} - any sentence
 * @return {string} - sentence without tags
 */
const removeHtmlTags = (input) => {
    var _a, _b;
    const multiSpacesRegexp = /\s{2,}/;
    return ((_b = (_a = input === null || input === void 0 ? void 0 : input.replace(htmlTagRegexp, " ")) === null || _a === void 0 ? void 0 : _a.replace(multiSpacesRegexp, " ")) === null || _b === void 0 ? void 0 : _b.trim()) || "";
};
exports.removeHtmlTags = removeHtmlTags;
/**
 * @desc Returns number of words in string
 *
 * @param input {string} - any sentence
 * @return {number} - number of words
 */
const countWordsString = (input) => {
    var _a, _b;
    return (_b = (_a = exports.getWordsString(input)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
};
exports.countWordsString = countWordsString;
/**
 * @desc Returns number of words in html text with tags
 *
 * @param input {string} - any sentence
 * @return {number} - number of words
 */
const countWordsHtml = (input) => {
    var _a, _b;
    const inputWithoutTags = exports.removeHtmlTags(input);
    return (_b = (_a = exports.getWordsString(inputWithoutTags)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
};
exports.countWordsHtml = countWordsHtml;
/**
 * @desc Returns words in string
 *
 * @param input {string} - any sentence
 * @return {number} - number of words
 */
const getWordsString = (input) => {
    var _a, _b;
    const pattern = /[\p{Alphabetic}\p{Hex_Digit}]+/gu;
    return (_b = (_a = lodash_1.unescape(exports.replaceHtmlSpecialCharacters(input === null || input === void 0 ? void 0 : input.trim()))) === null || _a === void 0 ? void 0 : _a.match(pattern)) === null || _b === void 0 ? void 0 : _b.filter((s) => s);
};
exports.getWordsString = getWordsString;
