import React from 'react';

import covidImg from '@root/assets/engineImages/covid-img.png';
import covidFull from '@root/assets/engineImages/covid-img-big.png';

export default {
  _id: 'FT-COVID',
  title: 'COVID-19',
  description: 'The pandemic has affected every aspect of our lives.  Let Ali help you speak with confidence in the era of COVID.',
  longerDescription: 'COVID-19 has significantly changed the way individuals and consumers live. Ali empowers you to speak directly to consumers on a range of topics about the ways that the pandemic has impacted our society, economy, and daily life.',
  imageSrc: covidImg,
  bigImg: covidFull,
  sampleGenerations: [
    <div>
      <b>FAQ: Matt&apos;s Best Travel Agency</b>
      <ul>
        <li>• What are your safety tips?</li>
        <li>• Are there any restrictions on where we can travel or for how long?</li>
        <li>• Is there anything I should not bring with me?</li>
        <li>• How do you find the latest COVID-related news and information?</li>
      </ul>
    </div>,
    'Our masks are designed to protect you from the virus, not just from the common cold. They\'re made with a super-absorbent material that traps and locks away 99.9% of viruses on contact. That means they\'re not just protecting your health, they\'re also protecting everyone around you.',
    <div>
      <div>
        <b>Section 1</b>
        : No one is immune to the pandemic.
      </div>
      <div>
        <b>Section 2</b>
        : COVID has forced businesses to go back to the drawing board and pitch in.
      </div>
      <div>
        <b>Section 3</b>
        : Social media marketing has evolved into an essential part of any business’ plan.
      </div>
      <div>
        <b>Takeaway</b>
        : Social media is a powerful way to connect with your customers
        and build relationships.
        Leverage these platforms for your business&apos;s advantage,
        but remember that social media is not the only way you can connect with people.
      </div>
    </div>,
  ],
};
