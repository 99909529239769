import React from 'react';

import Button from '@root/components/buttons/Button';

import useStyles from './GoBackSidebar.styles';

const GoBackSidebar = ({ onGoBack, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="link" onClick={onGoBack}>
        {text}
      </Button>

    </div>
  );
};

export default GoBackSidebar;
