import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  iconButton: {
    width: '40px',
    height: '40px',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  avatar: {
    fontSize: '14px',
    fontWeight: '500',
  },
  profileInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    width: 'calc(100% - 16px)',

    margin: '0px 8px',
    paddingBottom: '8px',
    marginBottom: '12px',
    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,
  },
  usernameContainer: {
    overflow: 'hidden',
  },
  profileInfoAvatar: {
    width: 40,
    height: 40,
  },
  userFullName: {
    fontWeight: 500,
    lineHeight: '22px',
  },
  userEmail: {
    color: theme.palette.colors.neutrals400,
    lineHeight: '22px',
  },
  menuList: {
    padding: '16px 16px 12px',
    maxWidth: '264px',
    width: '264px',
  },
  menuPaper: {
    borderRadius: '6px',
  },
  menuSectionTitle: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '22px',
    textTransform: 'uppercase',
    marginBottom: '4px',
    padding: '0px 8px',
    letterSpacing: '1px',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuItem: {
    display: 'block',
    width: '100%',
    color: theme.palette.colors.neutrals400,
    padding: '4px 8px',
    borderRadius: '6px',

    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.colors.neutrals50,
      color: theme.palette.colors.neutrals600,
    },
  },
  menuItemText: {
    lineHeight: '22px',
  },
  divider: {
    height: '1px',
    width: 'calc(100% - 16px)',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '12px 0',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  logoutButton: {
    color: theme.palette.colors.pink600,
  },
  teamsTitle: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  membersCounter: {
    minWidth: '22px',
    height: '22px',
    borderRadius: '12px',
    padding: '0px 4px',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.colors.neutrals50,
    color: theme.palette.colors.neutrals400,
  },
}));
