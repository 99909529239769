import React from 'react';
import { FormHelperText } from '@material-ui/core';

import { SelectField } from '@root/components/form';
import templatesContext from '@root/resources/templates/templates.context';

import { templates, templatesOptions } from '../../Editor.constants';
import TemplateLabel from '../TemplateLabel';

import useStyles from './TemplateSelect.styles';

const TemplateSelect = ({ onChange, selectedTemplateType }) => {
  const classes = useStyles();

  const { getTemplate, templatesList } = templatesContext.useTemplates();
  const selectedTemplate = getTemplate(selectedTemplateType);
  const customTemplatesOptions = templatesList
    .filter(({ templateType, status, deletedOn }) => templateType.startsWith('custom_') && status === 'live' && !deletedOn)
    .map((template) => ({
      value: template.templateType,
      label: <TemplateLabel text={template.title} templateType={template.templateType} />,
    }));

  const allTemplatesOptions = [
    ...templatesOptions,
    ...customTemplatesOptions,
  ];

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={classes.templateSelect}>
      <SelectField
        label="Choose use case"
        value={selectedTemplateType}
        onChange={handleChange}
        options={allTemplatesOptions}
        id="select-template"
      />
      <FormHelperText>
        {templates[selectedTemplateType]?.description || selectedTemplate.description}
      </FormHelperText>
    </div>
  );
};

export default TemplateSelect;
