import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useLikeFile() {
  const queryClient = useQueryClient();

  return useMutation(
    // eslint-disable-next-line no-unused-vars
    ({ fileId, filters }) => api.files.like(fileId),
    {
      onMutate: async ({ fileId, filters }) => {
        const queryKey = ['files.list', filters];

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const prevFiles = queryClient.getQueryData(queryKey);
        if (!prevFiles) {
          return null;
        }

        const doc = prevFiles.find((f) => f._id === fileId);

        doc.liked = !doc.liked;
        const updatedDocs = prevFiles.map((file) => ({
          ...file,
        }));

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, () => updatedDocs);

        return { prevFiles, queryKey };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(context.queryKey, context.prevFiles);
      },
      onSuccess: (response, variables, context) => {
        if (!response || response.isBadRequest) {
          queryClient.setQueryData(context.queryKey, context.prevFiles);
          return;
        }
        const { fileId } = variables;
        queryClient.getQueryCache().findAll('files.list')
          .forEach(({ queryKey: key, state }) => {
            if (!state.data) return;

            queryClient.setQueryData(
              key,
              () => ({
                ...state.data,
                files: state.data.files.map((f) => (
                  f._id === fileId ? { ...f, liked: response.liked } : f)),
              }),
            );
          });
      },
    },
  );
}
