import { HttpClient } from "./HttpClient";
import { Folder } from "./resources/Folder";
import { File } from "./resources/File";
import { Template } from "./resources/Template";
import { Document } from "./resources/Document";
import { AI } from "./resources/AI";
import { AiTemplate } from "./resources/AiTemplate";
import { Admin } from "./resources/Admin";
import { Account } from "./resources/Account";
import { Billing } from "./resources/Billing";
import { Catalog } from "./resources/Catalog";
import { CatalogItem } from "./resources/CatalogItem";
import { Company } from "./resources/Company";
import { User } from "./resources/User";
import { Invite } from "./resources/Invite";
import { News } from "./resources/News";
import { Frase } from "./resources/integrations/Frase";
import { Engine } from "./resources/Engine";
import { BulkGeneration } from "./resources/BulkGeneration";
import { DocumentExample } from "./resources/DocumentExample";
import { Referral } from "./resources/Referral";
import { Usage } from "./resources/Usage";
import { Notification } from "./resources/Notification";
import { DocumentImage } from "./resources/DocumentImage";
import GoogleAd from "./resources/integrations/GoogleAd";
import GoogleDocs from "./resources/integrations/GoogleDocs";
import Shopify from "./resources/integrations/Shopify";
import BigCommerce from "./resources/integrations/BigCommerce";
import Akeneo from "./resources/integrations/Akeneo";
import Integration from "./resources/Integration";
import PromptItem from "./resources/PromptItem";
import ApiKey from "./resources/ApiKey";
import MailLookup from "./resources/MailLookup";

import configLoader, { ConfigType } from "./config";

import * as packageJson from "../package.json";
import ClientConfig from "./resources/ClientConfig";
import Activity from "./resources/Activity";

const PACKAGE_VERSION = packageJson.version;

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type OptionsType = {
  env: string;
  // Only used on the dev environment
  // If false it connects user host names defined in docker-compose
  // for service to service communication
  // if set to true, it will connect using external ports, e.x. localhost:4000
  // used for frontend
  external?: boolean; 
  errorInterceptor: ((err: Error) => Promise<void>) | null;
  onAfterRequest: (() => void) | null;
  customHeaders: Record<string, string>;
  configOverride?: RecursivePartial<ConfigType> | null;
}

export class Copysmith {
  public folders: Folder;
  public files: File;
  public templates: Template;
  public documents: Document;
  public ai: AI;
  public account: Account;
  public billing: Billing;
  public catalog: Catalog;
  public catalogItem: CatalogItem;
  public company: Company;
  public user: User;
  public frase: Frase;
  public googleAd: GoogleAd;
  public googleDocs: GoogleDocs;
  public shopify: Shopify;
  public bigCommerce: BigCommerce;
  public akeneo: Akeneo;
  public invite: Invite;
  public integration: Integration;
  public news: News;
  public engine: Engine;
  public apiKeys: ApiKey;
  public clientConfig: ClientConfig;
  public mailLookup: MailLookup;
  public bulkGeneration: BulkGeneration;
  public promptItem: PromptItem;
  public aiTemplate: AiTemplate;
  public admin: Admin;
  public documentExample: DocumentExample;
  public referrals: Referral;
  public usage: Usage;
  public notification: Notification;
  public documentImages: DocumentImage;
  public activity: Activity;

  private client: HttpClient;
  private url: string;
  
  constructor(
    getToken: (() => Promise<string>) | null,
    options: OptionsType = {
      env: "production",
      external: false,
      errorInterceptor: null,
      onAfterRequest: null,
      customHeaders: {},
      configOverride: null,
    }
  ) {
    const config = configLoader.getConfig(options.env, options.external || false, options.configOverride as ConfigType);
    this.url = config.apiUrl;

    const clientOptions = {
      getToken,
      errorInterceptor: options.errorInterceptor,
      onAfterRequest: options.onAfterRequest,
      packageVersion: PACKAGE_VERSION,
      customHeaders: options.customHeaders,
    };
    this.client = new HttpClient(this.url, clientOptions);

    this.folders = new Folder(this.client);
    this.files = new File(this.client);
    this.templates = new Template(this.client);
    this.documents = new Document(this.client);
    this.ai = new AI(this.client);
    this.account = new Account(this.client);
    this.billing = new Billing(this.client);
    this.catalog = new Catalog(this.client);
    this.catalogItem = new CatalogItem(this.client);
    this.company = new Company(this.client);
    this.user = new User(this.client);
    this.invite = new Invite(this.client);
    this.integration = new Integration(this.client);
    this.news = new News(this.client);
    this.engine = new Engine(this.client);
    this.clientConfig = new ClientConfig(this.client);
    this.mailLookup = new MailLookup(this.client);
    this.bulkGeneration = new BulkGeneration(this.client);
    this.promptItem = new PromptItem(this.client);
    this.aiTemplate = new AiTemplate(this.client);
    this.admin = new Admin(this.client);
    this.documentExample = new DocumentExample(this.client);
    this.referrals = new Referral(this.client);
    this.usage = new Usage(this.client);
    this.notification = new Notification(this.client);
    this.documentImages = new DocumentImage(this.client);
    this.activity = new Activity(this.client);

    const fraseClient = new HttpClient(config.integrationUrls.fraseUrl, clientOptions);
    this.frase = new Frase(fraseClient);

    const googleAdClient = new HttpClient(config.integrationUrls.googleAdUrl, clientOptions);
    this.googleAd = new GoogleAd(googleAdClient);   
    
    const googleDocsClient = new HttpClient(config.integrationUrls.googleDocsUrl, clientOptions);
    this.googleDocs = new GoogleDocs(googleDocsClient);

    const shopifyClient = new HttpClient(config.integrationUrls.shopifyUrl, clientOptions);
    this.shopify = new Shopify(shopifyClient);

    const bigCommerceClient = new HttpClient(config.integrationUrls.bigCommerceUrl, clientOptions);
    this.bigCommerce = new BigCommerce(bigCommerceClient);

    const akeneoClient = new HttpClient(config.integrationUrls.akeneoUrl, clientOptions);
    this.akeneo = new Akeneo(akeneoClient);

    const apiKeyClient = new HttpClient(config.authUrl, clientOptions);
    this.apiKeys = new ApiKey(apiKeyClient);
  }

  public getUrl = () => {
    return this.url;
  }
}
