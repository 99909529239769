import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useGenerateBlogKickstarter() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.ai.checkPlagiarism(data),
    {
      onSuccess: () => queryClient.invalidateQueries(['billing.getLimits', { }]),
    },
  );
}
