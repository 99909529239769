import React from 'react';
import { IconButton } from '@material-ui/core';
import {
  AddCircle as PlusIcon,
  RemoveCircle as MinusIcon,
} from '@material-ui/icons';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { FEATURES } from '@root/views/Addons/addonsConstants';

import useStyles from './Counter.styles';

const Counter = ({ quantity, onQuantityChange, type, min, max }) => {
  const classes = useStyles();

  const { cost, unit } = FEATURES[type];
  const total = (cost * quantity).toFixed(2);
  const unitPriceLabel = unit && capitalize(unit);

  const getUnitLabel = () => {
    switch (type) {
      case FEATURES.additionalMembers.type:
        return pluralize('Team Seat', quantity);
      case FEATURES.aiImages.type:
        return '30 generations per Unit';
      default:
        return '8000 per Unit';
    }
  };

  const isMax = quantity === max;
  const isMin = quantity === min;

  const increaseQuantity = () => {
    onQuantityChange((q) => q + 1);
  };

  const decreaseQuantity = () => {
    onQuantityChange((q) => q - 1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.counterBlock}>
        <div className={classes.counter}>
          <IconButton
            color="primary"
            disabled={isMin}
            onClick={decreaseQuantity}
            className={classes.button}
          >
            <MinusIcon />
          </IconButton>
          <span className={classes.value}>{quantity}</span>
          <IconButton
            color="primary"
            disabled={isMax}
            onClick={increaseQuantity}
            className={classes.button}
          >
            <PlusIcon />
          </IconButton>
        </div>
        <div className={classes.label}>{getUnitLabel()}</div>
      </div>
      <div className={classes.totalBlock}>
        <div>
          <div className={classes.value}>
            {`$${cost.toFixed(2)}`}
          </div>
          <div className={classes.label}>{`${unitPriceLabel} Price`}</div>
        </div>
        <div>
          <div className={classes.value}>
            {`$${total}`}
          </div>
          <div className={classes.label}>Monthly</div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
