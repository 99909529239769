import React from 'react';
import { jsPDF } from 'jspdf';

import {
  downloadAsFile,
  makeFileName,
  getDateString,
  escapeCSV,
} from '@root/utils/Export';
import { downloadAsDocX } from '@root/utils/Export/functions';

const useExport = ({
  documents,
  template,
  fileTitle,
}) => {
  const downloadAsCsv = React.useCallback(() => {
    const csvContent = template.getCsvContent
      ? template.getCsvContent(documents)
      : documents.reduce((csv, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${csv}"${escapeCSV(ad)}"\r\n`;
      }, '');

    downloadAsFile(csvContent, makeFileName(fileTitle, template.templateType), 'text/csv');
  }, [template, documents, fileTitle]);

  const downloadAsDocx = React.useCallback(() => {
    const txtContent = template.getPdfContent
      ? template.getPdfContent(documents)
      : documents.reduce((txt, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${txt}"${ad.trim()}"\r\n\n`;
      }, '');
    downloadAsDocX(txtContent, `Copysmith_${template.templateType}_${fileTitle}_${getDateString()}.docx`);
  }, [template, documents, fileTitle]);

  const downloadAsPdf = React.useCallback(() => {
    const pdfContent = template.getPdfContent
      ? template.getPdfContent(documents)
      : documents.reduce((pdf, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${pdf}"${ad}"\r\n\n`;
      }, '');

    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(`${fileTitle}\n\n`, 10, 18);

    doc.setFontSize(12);
    const splitText = doc.splitTextToSize(
      pdfContent.replace(/(<([^>]+)>)/gi, ''),
      180,
    );
    let line = 34;

    for (
      let counter = 0, { length } = splitText;
      counter < length;
      counter += 1
    ) {
      if (counter % 50 === 0 && counter >= 50) {
        doc.addPage();
        line = 15;
      }

      doc.text(splitText[counter], 10, line);
      line += 5;
    }

    doc.save(`Copysmith_${template.templateType}_${fileTitle}_${getDateString()}.pdf`);
  }, [template, documents, fileTitle]);

  const downloadAsTxt = React.useCallback(() => {
    const txtContent = template.getTxtContent
      ? template.getTxtContent(documents)
      : documents.reduce((txt, doc) => {
        const ad = doc.content.replace(/(<([^>]+)>)/gi, '').trim();
        return `${txt}"${ad.trim()}"\r\n\n`;
      }, '');

    const dateString = getDateString();
    const fileName = `Copysmith_${template.templateType}_${fileTitle}_${dateString}.txt`;

    downloadAsFile(txtContent, fileName);
  }, [template, documents, fileTitle]);

  return {
    downloadAsCsv,
    downloadAsDocx,
    downloadAsPdf,
    downloadAsTxt,
  };
};

export default useExport;
