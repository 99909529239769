import React from 'react';

import Modal from '@root/components/modals/Modal';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import RenameCatalogForm from './RenameCatalogForm';
import useStyles from './RenameCatalogModal.styles';

export const RENAME_CATALOG_MODAL = 'RENAME_CATALOG_MODAL';

const RenameCatalogModal = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch, state: { modalId } } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title="Rename Catalog"
      open={modalId === RENAME_CATALOG_MODAL}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modal }}
    >
      <RenameCatalogForm />
    </Modal>
  );
};

export default RenameCatalogModal;
