import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
  cell: {
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },
}));
