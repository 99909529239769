import { useQuery } from 'react-query';

import api from '@root/api';

const getSharedDocuments = async ({ fileId }) => {
  const { results: sharedDocuments } = await api.documents.getSharedList({ fileId });

  const docs = sharedDocuments;

  return docs;
};

export default function useSharedDocuments({ fileId }) {
  return useQuery(['documents.getSharedDocuments', { fileId }], () => getSharedDocuments({ fileId }));
}
