import React from 'react';
import { Link } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import Logo from '@root/assets/logo/white.svg';
import { lowCreditsLimit } from '@root/resources/billing/billingInfo.helpers';
import NotificationBar from '@root/views/Layout/components/NotificationBar';

import useStyles from './NavbarLayout.styles';

const NavbarLayout = ({ children, withLowCreditsBar = true, newEditor, onClose }) => {
  const { data: limits } = withLowCreditsBar
    ? billingResource.useLimits()
    : {};
  const { data: billingInfo } = withLowCreditsBar
    ? billingResource.useBillingInfo()
    : {};
  const creditsLeft = limits?.limit;
  const { currentUser } = AuthContext.useAuth();
  const classes = useStyles({ newEditor });

  const showLowCreditsBar = withLowCreditsBar
    && !currentUser.onFreePlan
    && billingInfo?.rank < 300
    && creditsLeft <= lowCreditsLimit
    && creditsLeft !== null;

  const handleLogoClick = (e) => {
    if (e.metaKey) {
      window.open('/templates/my-files', '_blank');
    } else {
      onClose();
    }
  };

  const logo = (
    <img
      src={Logo}
      alt="Logo"
      className={classes.logo}
    />
  );

  const renderLogo = () => {
    if (onClose) {
      return (
        <div
          id="button-logo"
          onClick={handleLogoClick}
        >
          {logo}
        </div>
      );
    }
    return (
      <Link
        id="button-logo"
        to="/templates/my-files"
      >
        {logo}
      </Link>
    );
  };

  return (
    <div className={classes.navbarContainer}>
      <nav className={classes.navbar}>
        {renderLogo()}
        <div className={classes.content}>
          {children}
        </div>
      </nav>

      {showLowCreditsBar && <NotificationBar />}
    </div>
  );
};

export default NavbarLayout;
