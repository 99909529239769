import React from 'react';
import clsx from 'clsx';

import Step from './Step';
import useStyles from './Stepper.styles';

const Stepper = ({
  steps,
  stepIndex,
  forceCheck = false,
}: $TSFixMe) => {
  const classes = useStyles();

  return (
    <div className={classes.stepperRoot}>
      {steps.map((step: $TSFixMe, index: $TSFixMe) => {
        const isCurrent = stepIndex === index;
        const isPrev = index < stepIndex;
        const isNext = index > stepIndex;
        const isLast = index === steps.length - 1;
        return (
          <React.Fragment key={step}>
            <Step
              number={index + 1}
              disabled={isNext}
              checked={isPrev || forceCheck}
              label={step}
            />
            {!isLast && (
              <div className={clsx(
                classes.transition,
                (isNext || isCurrent) && classes.nextTransition,
              )}
              />
            )}
          </React.Fragment>
        );
      })}

    </div>
  );
};

export default Stepper;
