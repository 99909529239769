import axios from 'axios';

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
export const isWindows = windowsPlatforms.includes(navigator.platform);
export const isMacOS = macosPlatforms.includes(navigator.platform);
export const isIOS = iosPlatforms.includes(navigator.platform);

export const checkImageByUrl = (url: string) => new Promise((resolve) => {
  const img = new Image();
  img.src = url;
  img.onload = () => resolve(true);
  img.onerror = () => resolve(false);
});

export const copyImgToClipboard = async (url: string) => {
  let { data: blob } = await axios.get(url, { responseType: 'blob' });

  // use canvas to convert jpeg to png
  if (blob.type.includes('jpeg')) {
    const newBlob = await new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      img.decode().then(() => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(resolve, 'image/png');
      });
    });
    blob = newBlob;
  }

  await navigator.clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ]);
};
