import React from 'react';
import { Radio as MuiRadio } from '@material-ui/core';

import CheckedIcon from './icons/Checked.icon';
import UncheckedIcon from './icons/Unchecked.icon';

const Radio = (props) => {
  return (
    <MuiRadio
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      color="primary"
      {...props}
    />
  );
};

export default Radio;
