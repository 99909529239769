import React from 'react';

import DEPRECATEDInputAtom from '@root/components/Fields/DEPRECATEDInputAtom';
import KeywordInputAtom from '@root/components/Fields/KeywordInputAtom';
import DEPRECATEDTextAreaInputAtom from '@root/components/Fields/DEPRECATEDTextAreaInputAtom';

export default [
  {
    name: 'Text Field',
    component: <DEPRECATEDInputAtom
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: '',
      }}
      title="Field Name"
    />,
  },
  {
    name: 'Text Field (required)',
    component: <DEPRECATEDInputAtom
      disabled
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: '',
      }}
      required
      title="Field Name"
    />,
  },
  {
    name: 'Keyword Input',
    component: <KeywordInputAtom
      title="Keyword Input"
      array={['Keyword 1', 'Keyword 2', 'Keyword 3']}
      setArray={() => {}}
      required
    />,
  },
  {
    name: 'Keyword Input - Frase',
    component: <KeywordInputAtom
      title="Keyword Input"
      array={['Keyword 1', 'Keyword 2', 'Keyword 3']}
      setArray={() => {}}
      required
      frase
    />,
  },
  {
    name: 'Quality Tracking - Poor',
    component: <DEPRECATEDTextAreaInputAtom
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: 'Lorem Ipsum',
      }}
      title="Field Name"
      maxLength={100}
      minLength={10}
      qualityTrackingObj={{
        limit: 100,
        fairThreshold: 20,
        greatThreshold: 40,
        excessThreshold: 80,
      }}
    />,
  },
  {
    name: 'Text Area - Great',
    component: <DEPRECATEDTextAreaInputAtom
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      }}
      title="Field Name"
      maxLength={100}
      minLength={10}
      qualityTrackingObj={{
        limit: 100,
        fairThreshold: 20,
        greatThreshold: 40,
        excessThreshold: 80,
      }}
    />,
  },
  {
    name: 'Text Area - Long',
    component: <DEPRECATEDTextAreaInputAtom
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus sem at.',
      }}
      title="Field Name"
      maxLength={100}
      minLength={10}
      qualityTrackingObj={{
        limit: 100,
        fairThreshold: 20,
        greatThreshold: 40,
        excessThreshold: 80,
      }}
    />,
  },
  {
    name: 'Text Area - Excess',
    component: <DEPRECATEDTextAreaInputAtom
      propObject={{
        name: 'name',
        type: 'text',
        placeholder: 'Type here...',
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus sem at dolor sit amet Lore.',
      }}
      title="Field Name"
      maxLength={100}
      minLength={10}
      qualityTrackingObj={{
        limit: 100,
        fairThreshold: 20,
        greatThreshold: 40,
        excessThreshold: 80,
      }}
    />,
  },
];
