import React, { useEffect, useMemo, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import folderResource from '@root/resources/folder';
import { FieldLabel } from '@root/components/form';
import LocationSelectMenu from '@root/components/Interactive/LocationSelectMenu';
import { getChildFolders, getRootFolders, getSharedRootFolders } from '@root/views/Dashboard/helpers';

const ProjectSelect = ({ folderId, onChange }) => {
  const { currentUser } = AuthContext.useAuth();
  const { data = [], isFetching } = folderResource.useFolders();
  const [isLocationSelectMenuOpen, setIsLocationSelectMenuOpen] = useState(false);

  const folders = useMemo(() => data.map((f) => {
    const { canCreateFolderFiles } = currentUser.permissions.getFolderPermissions(f);
    return { ...f,
      isOptionDisabled: !canCreateFolderFiles };
  }), [data]);

  const onLocationSelect = (location) => {
    onChange?.(location);
  };

  const foldersList = useMemo(() => {
    if (!folders?.length) {
      return [];
    }

    const rootFolders = getRootFolders(folders, currentUser);
    const rootSharedFolders = getSharedRootFolders(folders, currentUser);

    const parentFolders = [...rootFolders, ...rootSharedFolders];

    const homeFolder = parentFolders.find((f) => f.home);
    const projectFolders = parentFolders.filter((f) => !f.home);

    const sortedFolders = [homeFolder, ...projectFolders]
      .flatMap((parentFolder) => {
        const childFolders = getChildFolders(parentFolder._id, folders);

        return [
          {
            ...parentFolder,
            hasChildFolders: !!childFolders?.length,
          },
          ...childFolders,
        ];
      });

    const withoutSharedUncategorized = sortedFolders.filter((f) => !f.isSharedUncategorized);

    return withoutSharedUncategorized.map(({
      _id,
      title,
      parentFolderId,
      home,
      hasChildFolders,
    }) => ({
      home,
      parentFolderId,
      hasChildFolders,
      label: home ? 'Other' : title,
      value: _id,
    }));
  }, [folders]);

  useEffect(() => {
    if (folderId || folders.length > 0) {
      const validatedFolderId = foldersList.find((folder) => folder.value === folderId) && folderId;
      const homeFolder = foldersList.find((folder) => folder.home).value;

      const fileLocation = validatedFolderId || homeFolder;
      onLocationSelect(fileLocation);
    }
  }, [folderId, folders]);

  const handleLocationSelect = (event, value) => {
    if (event.target.id !== 'arrowIndicator') {
      setIsLocationSelectMenuOpen(!isLocationSelectMenuOpen);
      if (value) {
        onLocationSelect(value);
      }
    }
  };

  return (
    <>
      <FieldLabel label="Choose a Location" required />
      {isFetching ? <option>Loading...</option> : null}
      {!isFetching && (
        <div
          onClick={(event, value) => handleLocationSelect(event, value)}
        >
          <ClickAwayListener
            onClickAway={() => setIsLocationSelectMenuOpen(false)}
          >
            <span>
              <LocationSelectMenu
                folders={foldersList}
                location={folderId}
                onLocationSelect={(event, value) => onLocationSelect(value)}
                isOpen={isLocationSelectMenuOpen}
              />
            </span>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default ProjectSelect;
