import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@material-ui/core';

import { CheckedIcon, UncheckedIcon, IndeterminateIcon } from './icons';

const Checkbox = ({
  checked,
  onChange,
  onClick,
  ...props
}) => {
  return (
    <MuiCheckbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      color="primary"
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      {...props}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: () => {},
  onClick: () => {},
};

export default Checkbox;
