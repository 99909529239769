import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import useStyles from './CreateModal.styles';
import CreateCatalogForm from './CreateCatalogForm';

export const CREATE_CATALOG_MODAL = 'CREATE_CATALOG_MODAL';

const CreateModal = () => {
  const classes = useStyles();
  const { dispatch: modalsDispatch, state: { modalId } } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  return (
    <Modal
      title="Create a new product catalog"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalId === CREATE_CATALOG_MODAL}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modalPaper }}
    >
      <CreateCatalogForm />
    </Modal>
  );
};

export default CreateModal;
