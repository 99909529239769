import React from 'react';
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Checkbox, IconButton, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import useGetProducts from '@root/resources/bigCommerce/useGetProducts';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { SearchField, SelectField } from '@root/components/form';
import useGetCategories from '@root/resources/bigCommerce/useGetCategories';
import useLimits from '@root/resources/billing/useLimits';

import useStyles from './SelectProducts.styles';
import BottomToolbar from './BottomToolbar';

const shortString = (str, length) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }

  return str;
};

const htmlToText = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html.replace(/\\n/gm, '<br />');
  const text = tmp.textContent || tmp.innerText || '';
  return shortString(text, 150);
};

const BodyCell = ({ children }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.bodyCell}>
      <div className={classes.bodyCellContent}>
        {children}
      </div>
    </TableCell>
  );
};

const getAllowedRowsCount = ({
  maxBulkGenerationRows,
  bulkGenerationRows,
}) => {
  if (bulkGenerationRows === null) {
    return maxBulkGenerationRows;
  }

  if (maxBulkGenerationRows > bulkGenerationRows) {
    return bulkGenerationRows;
  }

  return maxBulkGenerationRows;
};

const SelectProducts = ({
  onProductsSelect,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);

  const { data: {
    maxBulkGenerationRows = 0,
    bulkGenerationRows = null,
  } = {} } = useLimits();

  const allowedRowsCount = React.useMemo(() => getAllowedRowsCount({
    maxBulkGenerationRows,
    bulkGenerationRows,
  }), [maxBulkGenerationRows, bulkGenerationRows]);

  const {
    data: {
      data: products,
      meta: {
        pagination: {
          // total: totalProducts,
          total_pages: totalPages,
          current_page: currentPage,
        },
      },
    } = {
      data: [],
      meta: {
        pagination: {},
      },
    },
    refetch: reloadProducts,
    isFetching: isProductsLoading,
  } = useGetProducts({
    page,
    keyword,
    category,
    include: ['images', 'categories', 'brands'],
  });

  const {
    data: categories = [],
  } = useGetCategories({});

  const onKeywordChange = (value) => {
    setKeyword(value);
    setPage(1);
  };
  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    setSelectedProducts([]);
    setIsAllSelected(false);
    setPage(1);
  };

  const dropCategory = () => {
    setCategory('');
    setSelectedProducts([]);
    setIsAllSelected(false);
    setPage(1);
  };

  const onProductSelect = (id) => () => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.filter((p) => p !== id));
      setIsAllSelected(false);
    } else {
      setSelectedProducts([...selectedProducts, id]);
      setIsAllSelected(selectedProducts.length + 1 === products.length);
    }
  };

  const onAllSelect = () => {
    setIsAllSelected(!isAllSelected);

    if (!isAllSelected) {
      setSelectedProducts(products.map((p) => p.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const next = async () => {
    try {
      setIsNextDisabled(true);
      await onProductsSelect(selectedProducts);
    } finally {
      setIsNextDisabled(false);
    }
  };

  React.useEffect(() => {
    const reload = async () => {
      setIsLoading(true);
      await reloadProducts();
      setIsAllSelected(false);
      setIsLoading(false);
    };
    reload();
  }, [keyword, category, page]);

  const onCancel = () => history.push('/');

  const onTableScroll = (e) => {
    if (isProductsLoading) {
      return;
    }

    const isScrolledToBottom = e.target.scrollHeight - 66 < e.target.scrollTop + e.target.clientHeight;

    if (!isScrolledToBottom) {
      return;
    }

    if (page + 1 > totalPages) {
      return;
    }

    setPage(page + 1);
  };

  const rows = React.useMemo(() => products.map((product) => {
    const html = htmlToText(product.description);
    const image = (product.images || [])[0]?.url_standard;

    return (
      <TableRow onClick={onProductSelect(product.id)} className={classes.tableRow} key={product.id}>
        <BodyCell>
          <Checkbox
            checked={selectedProducts.includes(product.id)}
          />
        </BodyCell>
        <BodyCell>
          <div className={classes.imageWrap}>
            {image && (<img src={image} alt="Product" />)}
          </div>
          {product.name}
        </BodyCell>
        <BodyCell>{product.sku}</BodyCell>
        <BodyCell>{product.categories || '-'}</BodyCell>
        <BodyCell>{product.brand || '-'}</BodyCell>
        <BodyCell>
          <SimpleTooltip
            content={(
              <div
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
          )}
          >
            <div>
              {html}
            </div>
          </SimpleTooltip>
        </BodyCell>
      </TableRow>
    );
  }), [products, selectedProducts]);

  const toolbarText = React.useMemo(() => {
    if (selectedProducts.length === 0) {
      return 'No products selected';
    }

    return `${isAllSelected ? 'All ' : ''}${selectedProducts.length} ${pluralize('product', selectedProducts.length)} selected`;
  }, [selectedProducts, isAllSelected]);

  const isLimitOver = React.useMemo(() => {
    return selectedProducts.length > allowedRowsCount;
  }, [selectedProducts]);
  const tooltipText = React.useMemo(() => {
    if (bulkGenerationRows === 0) {
      return 'Monthly bulk generation limit exceeded. Contact sales to upgrade';
    }
    if (isLimitOver) {
      return `It seems like you're almost at your limit! Right now, you can only select up to ${allowedRowsCount} products.`;
    }

    return null;
  }, [isLimitOver]);

  return (
    <div className={classes.root}>
      <div className={classes.searchWrap}>
        <SearchField
          onChange={onKeywordChange}
          classNames={{ container: classes.keywordSearch }}
          debounce
          placeholder="Search Products"
        />
        <SelectField
          className={classes.categorySelect}
          options={categories.map((c) => ({
            value: c.id,
            label: c.name,
          }))}
          placeholder="Select Category"
          onChange={onCategoryChange}
          value={category}
          startAdornment={category && (
            <IconButton
              className={classes.dropCategory}
              disableRipple
              onClick={dropCategory}
            >
              <Close />
            </IconButton>
          )}
        />
      </div>
      <TableContainer className={classes.tableContainer} onScroll={onTableScroll}>
        {isLoading ? (
          <div className={classes.loadingWrap}>
            <CircularProgress />
          </div>
        ) : (
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableCell>
                <Checkbox
                  checked={isAllSelected}
                  onChange={onAllSelect}
                />
              </TableCell>
              <TableCell>
                Product Name
              </TableCell>
              <TableCell>Product SKU</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Description</TableCell>
            </TableHead>
            <TableBody>
              {rows}
              <TableRow className={classes.stub}>
                <TableCell>
                  <div className={classes.stub} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!isLoading && (
        <BottomToolbar
          text={toolbarText}
          next={{
            label: 'Process Bulk',
            disabled: selectedProducts.length === 0 || isNextDisabled || isLimitOver,
            tooltip: tooltipText,
            action: next,
          }}
          cancel={{
            label: 'Cancel',
            action: onCancel,
          }}
        />
      )}
    </div>
  );
};

export default SelectProducts;
