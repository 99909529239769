import React, { useState } from 'react';
import {
  CardNumberElement, CardExpiryElement, CardCvcElement, useElements,
} from '@stripe/react-stripe-js';
import { Formik, Form } from 'formik';

import { FormField, CheckboxField, InputField, SelectField } from '@root/components/form';
import AuthContext from '@root/resources/auth/auth.context';
import CardField from '@root/components/form/CardField';

import { countryOptions, getInitialValues, schema } from './cardFormHelpers';
import BillingAddressForm from './components/BillingAddressForm';
import useStyles from './FullCardForm.styles';

const FullCardForm = ({ onSubmit, formRef, isCheckout }) => {
  const classes = useStyles();
  const elements = useElements();
  const { currentUser } = AuthContext.useAuth();

  const [cardFieldsComplete, setCardFieldsComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const allCardFieldComplete = Object.values(cardFieldsComplete).every(Boolean);
  const initialValues = getInitialValues(currentUser);

  const handleSubmit = async (values, { setFieldError }) => {
    if (!allCardFieldComplete) {
      Object.keys(cardFieldsComplete).forEach((field) => {
        if (!cardFieldsComplete[field]) {
          setFieldError(field, 'Required.');
        }
      });
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    await onSubmit(values, cardElement);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      innerRef={formRef}
      isInitialValid={false}
    >
      {() => {
        return (
          <Form className={classes.form}>
            <div className={classes.sectionName}>
              Payment information
              <div className={classes.line} />
            </div>
            <div className={classes.fieldsWrapper}>
              {isCheckout && (
                <FormField
                  name="email"
                  label="Email"
                  component={InputField}
                  required
                  fullWidth
                />
              )}
              <FormField
                name="country"
                label="Country or region"
                component={SelectField}
                options={countryOptions}
                fullWidth
                required
              />
              <BillingAddressForm />
            </div>

            <div className={classes.sectionName}>
              Card details
              <div className={classes.line} />
            </div>
            <div className={classes.fieldsWrapper}>
              <FormField
                name="name"
                label="Name on card"
                component={InputField}
                required
                fullWidth
              />
              <div className={classes.cardInfo}>
                <CardField
                  label="Card information"
                  required
                  name="cardNumber"
                  component={CardNumberElement}
                  onFieldComplete={setCardFieldsComplete}
                />
                <div className={classes.row}>
                  <CardField
                    name="cardExpiry"
                    component={CardExpiryElement}
                    onFieldComplete={setCardFieldsComplete}
                  />
                  <CardField
                    name="cardCvc"
                    component={CardCvcElement}
                    onFieldComplete={setCardFieldsComplete}
                  />
                </div>
              </div>
            </div>
            {isCheckout && (
              <FormField
                name="save"
                component={CheckboxField}
                label="Save information to pay faster next time"
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FullCardForm;
