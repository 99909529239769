import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modal: {
    position: 'relative',
    fontFamily: '"Inter", sans-serif',

    width: '100%',
    minWidth: 'unset',
    maxWidth: '460px',
  },
}));

export default useStyles;
