import React from 'react';

import MainLayout from '../Layout/MainLayout';

import useStyles from './home.styles';
import QuickStart from './Components/QuickStart';
import RecentActivity from './Components/RecentActivity';

const HomePage = () => {
  const classes = useStyles();

  return (
    <MainLayout>
      <div className={classes.root}>
        <div className={classes.title}>Quick Start</div>
        <QuickStart />
        <div className={classes.title}>Recent Activity</div>
        <RecentActivity />
      </div>
    </MainLayout>
  );
};

export default HomePage;
