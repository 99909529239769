import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import * as Yup from 'yup';

import { FormField, InputField } from '@root/components/form';
import example1 from '@root/assets/aiImageExamples/example1.png';
import example2 from '@root/assets/aiImageExamples/example2.png';
import example3 from '@root/assets/aiImageExamples/example3.png';
import example4 from '@root/assets/aiImageExamples/example4.png';
import example5 from '@root/assets/aiImageExamples/example5.png';
import example6 from '@root/assets/aiImageExamples/example6.png';
import example7 from '@root/assets/aiImageExamples/example7.png';
import example8 from '@root/assets/aiImageExamples/example8.png';
import example9 from '@root/assets/aiImageExamples/example9.png';
import example10 from '@root/assets/aiImageExamples/example10.png';
import ScrollFade from '@root/components/ScrollFade';
import AIImage from '@root/views/Editor/components/AIImage';
import { getHideAIImageHints, setHideAIImageHints, getHideArtStudioCTA, setHideArtStudioCTA } from '@root/services/localStorage.service';
import config from '@root/config';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import dateUtils from '@root/utils/date.util';
import kbArticles from '@root/utils/kbArticles';

import useStyles from './AIImageInputForm.styles';

const promptNoSpecialCharacters = /^[^#:<>[\]\\;~`!$%^*+={}|?/_&@()|"]+$/;

const generateImageSchema = Yup.object().shape({
  prompt: Yup.string()
    .matches(promptNoSpecialCharacters, 'Special characters are not allowed in prompt.')
    .required('Prompt is required to generate images.').max(1000, 'Prompt must be 1000 characters or less.'),
});

const AIImageInputForm = ({ aiImageGenerations, billingInfo, isAdminOrOwner, onGenerate, readOnly }) => {
  const classes = useStyles();
  const [hideHints, setHideHints] = useState(getHideAIImageHints() === 'true');
  const [isCTAClosed, setCTAClosed] = useState(getHideArtStudioCTA() === 'true');

  const isFreeTrial = PLANS_IDS.free.includes(billingInfo?.priceId);

  const examples = [{
    file: example1,
    prompt: 'Bauhaus style interior picture, 4k',
  },
  {
    file: example2,
    prompt: 'A tropical island in the shape of a love heart, drone photo 8k zoomed out',
  },
  {
    file: example3,
    prompt: 'A synthwave style sunset with a racing car, digital art',
  },
  {
    file: example4,
    prompt: 'An oil painting of a steaming cup of coffee next to a French press',
  },
  {
    file: example5,
    prompt: 'A picture of a German Sheperd with its tongue out hugging a cat',
  },
  {
    file: example6,
    prompt: 'A photo of a snow capped mountain range behind a reflective lake',
  },
  {
    file: example7,
    prompt: 'A 3D render of a parrot sitting on a branch with a dark green background',
  },
  {
    file: example8,
    prompt: 'An abstract oil painting of a person fly fishing during sunrise',
  },
  {
    file: example9,
    prompt: 'A hand-drawn sailboat in rough water during a storm',
  },
  {
    file: example10,
    prompt: 'An explosion of a nebula on a black background',
  },
  ];

  return (
    <Formik
      initialValues={{ prompt: '' }}
      onSubmit={onGenerate}
      validationSchema={generateImageSchema}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form className={classes.form}>
            <div className={clsx(classes.fieldsWrapper, classes.gapWrapper)}>
              {aiImageGenerations <= 1 && !isCTAClosed && isAdminOrOwner && (
                <div className={classes.addOnCTA}>
                  <div className={classes.addOnCTAHeader}>
                    <div>Art Studio Add-on</div>
                    <IconX
                      className={classes.addOnCTAClose}
                      onClick={() => {
                        setHideArtStudioCTA(!isCTAClosed);
                        setCTAClosed(!isCTAClosed);
                      }}
                      size={20}
                    />
                  </div>
                  <div>
                    {billingInfo?.addons?.aiImages
                      ? (
                        <div>
                          You are running low on credits.
                          <br />
                          <br />
                          { billingInfo.renewOn && `Your next credit renewal: ${dateUtils.formatDate(billingInfo.renewOn + 60 * 60, 'LLL d, h a')}.` }
                          <br />
                          <br />
                          Purchase additional Art Studio add-on units to
                          continue harnessing the power of AI image generation.
                        </div>
                      )
                      : (
                        <div>
                          {isFreeTrial
                            ? 'Subscribe to a plan to get 5 AI image generations and the ability to purchase the Art Studio add-on.'
                            : 'Purchase the Art Studio add-on to continue harnessing the power of AI image generation.'}
                        </div>
                      )}
                  </div>
                  <a
                    className={classes.purchaseAddOn}
                    href={isFreeTrial ? `${config.appUrl}/profile/plans` : `${config.appUrl}/addons/features`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    { isFreeTrial ? <u>Subscribe</u> : <u>Purchase Add-on</u> }
                  </a>
                </div>
              )}
              <FormField
                component={InputField}
                disabled={readOnly || isSubmitting || aiImageGenerations === 0}
                label={(
                  <div>
                    Start with a detailed description. Tips
                    {' '}
                    <a
                      target="__blank"
                      href={kbArticles.aiImages}
                    >
                      here
                    </a>
                  </div>
                )}
                multiline
                name="prompt"
                placeholder="e.g. the solar system depicted as simplified shapes, minimalism abstract art"
              />
            </div>
            <div className={classes.exploreText}>
              Explore some ideas from Copysmith below.
              {' '}
              <b>Hover</b>
              {' '}
              to see the prompt example and
              {' '}
              <b>Click</b>
              {' '}
              to Try!
              <span
                className={classes.exploreButton}
                onClick={() => {
                  setHideAIImageHints(!hideHints);
                  setHideHints(!hideHints);
                }}
              >
                {hideHints ? 'Show hints' : 'Hide hints'}

              </span>
            </div>
            { hideHints || (
              <div className={classes.scrollable}>
                <div className={classes.exampleImageContainer}>
                  {examples.map((example) => (
                    <AIImage
                      image={example}
                      isExample
                      key={example.prompt}
                      onImageClick={(prompt) => (aiImageGenerations >= 1 ? setFieldValue('prompt', prompt) : null)}
                      visible
                    />
                  ))}
                </div>
                <ScrollFade topFadePercent={5} bottomFadePercent={95} />
              </div>
            )}
            <div className={classes.buttonBlock}>
              <Button
                type="submit"
                className={classes.button}
                disabled={readOnly || isSubmitting || aiImageGenerations === 0}
                id="button-generateImage"
              >
                {isSubmitting ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : (
                  'Generate'
                )}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AIImageInputForm;
