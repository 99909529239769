let isHootsuiteIframe;

// FIXME: Add timeout for now. Because don't know how to handle postMessage errors.
// The right solution is to deploy to separate domain

const TIMEOUT = 600;

const init = async () => {
  if (isHootsuiteIframe !== undefined) {
    return isHootsuiteIframe;
  }

  const initPromise = new Promise((resolve) => {
    if (!window.hsp) {
      return resolve({ success: false });
    }

    return hsp.init({
      callback: (data) => {
        if (data === 'No Error') {
          isHootsuiteIframe = true;
          window.disableLogs();
          hsp.getAuth((authData) => { hsp.authData = authData; });
          return resolve({ success: true });
        }

        isHootsuiteIframe = false;
        return resolve({ success: true });
      },
    });
  });

  const errPromise = new Promise((resolve) => {
    return setTimeout(() => resolve({ success: false }), TIMEOUT);
  });

  const result = await Promise.race([
    initPromise,
    errPromise,
  ]);

  if (!result.success) {
    isHootsuiteIframe = false;
  }

  return isHootsuiteIframe;
};

const isHootsuite = async () => {
  if (isHootsuiteIframe === undefined) {
    return init();
  }

  return isHootsuiteIframe;
};

const getData = () => {
  if (!isHootsuiteIframe) {
    return null;
  }

  return new Promise((resolve) => {
    hsp.getData((data) => {
      resolve(data);
    });
  });
};

const saveData = (data) => {
  if (!isHootsuiteIframe) {
    return null;
  }

  return new Promise((resolve) => {
    hsp.saveData(data, (cbData) => {
      resolve(cbData);
    });
  });
};

const getAuth = () => {
  if (!isHootsuiteIframe) {
    return null;
  }

  return new Promise((resolve) => {
    hsp.getAuth((data) => {
      resolve(data);
    });
  });
};

export default {
  getData,
  getAuth,
  saveData,
  isHootsuite,
  init,
};
