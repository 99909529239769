import config from '@config';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';

const service = window.PostAffTracker;
const enabled = config.env === 'production' && service;

const retrieveCookie = () => {
  if (!enabled || !service) {
    return null;
  }
  const papCookie = window.PostAffTracker._getAccountId()
  + window.PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue();

  return papCookie;
};

// Event Aggregator Subscriptions
eventsAggregator.subscribe(EVENT_NAMES.SIGNUP_FORM_COMPLETED, (_, data) => {
  if (!enabled) {
    return;
  }
  const { email } = data;

  service.setAccountId('default1');
  const sale = service.createSale();
  sale.setData1(email);
  sale.setTotalCost(0);
  sale.setProductID('free');
  service.register();
});

eventsAggregator.subscribe(EVENT_NAMES.PURCHASE_COMPLETED, (_, data) => {
  if (!enabled) {
    return;
  }
  const {
    priceId,
    total,
    email,
  } = data;

  service.setAccountId('default1');

  const sale = service.createSale();

  sale.setData1(email);
  sale.setTotalCost(total);
  sale.setProductID(priceId);
  if (sale.setOrderId) {
    sale.setOrderId(email);
  }

  service.register();
});

export default {
  retrieveCookie,
};
