import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  generationAtom: {
    padding: theme.spacing(4, 4, 3, 4),
    backgroundColor: 'white',
    width: '100%',
    borderRadius: theme.border.radius.default,
    marginTop: '20px',
    marginBottom: '20px',
    flexGrow: 2,
    border: `1px solid ${theme.palette.colors.grey}`,
  },
  generationAtomChecked: {
    outlineColor: theme.palette.primary.main,
    outlineStyle: 'auto',
    outlineWidth: '2px',
  },
  noMargin: {
    margin: 0,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  documentPreview: {
    marginBottom: '16px',
  },
  editContainer: {
    position: 'relative',
    margin: '0.25rem 0 0 0',
  },
  content: {
    whiteSpace: 'pre-line',
  },
}));
