import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface GetSniperLink extends RequestOptions {
  email: string;
}

export default class MailLookup {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async getSniperLink(data: GetSniperLink, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/mail/sniper-link", data, options);
  }
}
