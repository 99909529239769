import { DateTime } from 'luxon';

const toCommonDateString = (timestamp) => {
  if (!timestamp) {
    return '';
  }

  return DateTime.fromSeconds(timestamp).toFormat('MM/dd/yyyy');
};

const getNowSeconds = () => DateTime.now().toSeconds();

// TODO: Fix me
const getDayFromNumber = (number) => {
  if (!number) return 'N/A';
  if (number > 1577883600) return new Date(number * 1000).toLocaleDateString();
  // for last modified
  return new Date(number * 8.64e7).toLocaleDateString(); // for date created
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatDate = (unixSeconds, format) => {
  if (!unixSeconds) return '';

  const then = DateTime.fromSeconds(unixSeconds);

  return then.toFormat(format);
};

/** Returns a relative time from now e.g. 5 hours ago, or 2 days ago */
const getRelative = (
  unixSeconds,
  {
    withAbsolute = false,
    absoluteFormat = 'dd LLL yyyy',
    absoluteFormatAfter = 'weeks',
    absoluteFormatAfterAmount = '1',
  } = {},
) => {
  const then = DateTime.fromSeconds(unixSeconds);

  if (withAbsolute && (Math.abs(then.diffNow(absoluteFormatAfter)[absoluteFormatAfter]) > absoluteFormatAfterAmount)) {
    return formatDate(unixSeconds, absoluteFormat);
  }

  if (Math.abs(then.diffNow('seconds').seconds) < 5) {
    return 'Just now';
  }
  if (Math.abs(then.diffNow('minutes').minutes) < 1) {
    return then.toRelativeCalendar({ unit: 'seconds' });
  }

  if (Math.abs(then.diffNow('hours').hours) < 1) {
    return then.toRelativeCalendar({ unit: 'minutes' });
  }

  if (Math.abs(then.diffNow('days').days) < 1) {
    return then.toRelativeCalendar({ unit: 'hours' });
  }

  if (Math.abs(then.diffNow('weeks').weeks) < 1) {
    return then.toRelativeCalendar({ unit: 'days' });
  }

  return then.toRelativeCalendar();
};

/** Returns a relative date from now (e.g. today, yesterday, 2 days ago), and includes a timestamp
 *    if the relative date was yesterday or today.
 *  Capitalizes first letter.
  */
const getRelativeWithTime = (unixSeconds) => {
  const then = DateTime.fromSeconds(unixSeconds);
  if (then.endOf('day').diffNow('days').days < -1) {
    return capitalizeFirstLetter(then.toRelativeCalendar());
  }
  return capitalizeFirstLetter(`${
    then.toRelativeCalendar()
  } at ${then.toLocaleString(DateTime.TIME_SIMPLE)}`);
};

const shortFormat = (unixSeconds) => {
  if (!unixSeconds) return '';

  const then = DateTime.fromSeconds(unixSeconds);

  return then.toFormat('yyyy LLL dd');
};

const longFormat = (unixSeconds) => {
  const then = DateTime.fromSeconds(unixSeconds);
  return then.toFormat('yyyy LLL dd, hh:mm a');
};

const time = (unixSeconds) => {
  const then = DateTime.fromSeconds(unixSeconds);

  return then.toFormat('hh:mm');
};

export default {
  toCommonDateString,
  getNowSeconds,
  getDayFromNumber,
  getRelative,
  getRelativeWithTime,
  shortFormat,
  longFormat,
  time,
  formatDate,
};
