import React, { createContext, useEffect, useState } from 'react';
import { keyBy, orderBy } from 'lodash';

import api from '@root/api/newApi';
import customizeTemplateService from '@root/resources/templates/customizeTemplate.service';
import BlogKickstarterIcon from '@root/views/BlogKickstarter/components/BlogKickstarter.icon';
import Loading from '@root/components/Loading/Loading';

const blogKickstarterTemplate = {
  templateType: 'blogKickstarter',
  title: 'Blog Kickstarter',
  description: 'The Blog Kickstarter takes your topic from idea to outline and then to blog. Generate your long form blogs and articles in rapid time. ',
  group: 'Quick Access',
  Icon: BlogKickstarterIcon,
  buckets: [
    {
      bucket: 'made-for-you',
      order: 0,
    },
    {
      bucket: 'articles-and-blogs',
      order: 0,
    },
  ],
  newExample: {
    content: 'As the technology developed within the emerging energy storage sector, we have begun to explore a range capabilities of energy storage from the battery as a stationary energy resource to as a mobile energy resource on the power grid. This emerging technology is referred to as battery technology and consists of several key components: a battery, a power management system, a high-voltage power source, and an energy density.\n\n Battery technology has the potential to change the shape of global energy production, storage, and consumption. In this blog post, we provide a definition and overview of the key components of battery technology, as well as a brief history of battery technology and its applications.\n\n Then, we review the potential of the emerging battery technology to change the shape of global energy production, storage, and consumption.\n\n Today, grid storage is largely composed of pumped hydro and thermal energy storage, both of which offer different advantages. Pumped hydro is an excellent storage option for renewables with high-water availability, as it can store energy for use at a later date. On the other hand, thermal energy storage is a more effective option for renewables with low-water availability, as it can store energy for use at a later date.\n\n Both types of storage have the potential to impact the grid in drastically different ways. In particular, pumped hydro storage can help improve grid stability and enhance energy security, as it can be easily turned on or off.\n\n ',
  },
};

export const TemplateContext = createContext({
  templates: {},
  templatesList: [],
  templatesListWithHidden: [],
});

const getTemplates = async (useCache) => {
  const templates = await api.templates.getTemplates(useCache);

  const enrichedTemplates = await Promise.all(templates.map(async (item) => {
    return customizeTemplateService.applyCustomization(item);
  }));

  return enrichedTemplates;
};

const getMissingTemplate = (templateType) => {
  const missingTemplate = {
    title: 'Missing template',
    templateType,
    slug: 'missing-template',
    group: 'Other',
    description: 'This template was removed or access disabled.',
    fields: [],
    isBeta: false,
    creditCost: 0,
    bulkAllowed: false,
    isLegacy: false,
    newExample: {
      content: 'Sample content',
      fields: {},
      fileFields: {},
    },
  };

  return customizeTemplateService.applyDefaultCustomization(missingTemplate);
};

const TemplatesProvider = ({ children }) => {
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesListWithHidden, setTemplatesListWithHidden] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const templates = keyBy(templatesListWithHidden, 'templateType');

  const getTemplate = (templateType) => {
    if (!templateType) {
      throw new Error('templateType is required to fetch template');
    }
    let template = templates[templateType];
    if (!template && templatesList.length !== 0) {
      template = getMissingTemplate(templateType);
      console.log(`File template [${templateType}] is missing.`); // eslint-disable-line no-console
    }

    return template;
  };

  const loadTemplates = async (useCache) => {
    setIsFetching(true);
    const fetchedTemplates = await getTemplates(useCache);
    const list = orderBy([...fetchedTemplates, blogKickstarterTemplate], 'title');
    setTemplatesListWithHidden(list);
    setTemplatesList(list.filter((template) => !template.hidden));
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await loadTemplates();
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <TemplateContext.Provider
      value={{
        templates,
        templatesList,
        templatesListWithHidden,
        getTemplate,
        loadTemplates,
        isFetching,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

function useTemplates({ withHidden } = { withHidden: false }) {
  const context = React.useContext(TemplateContext);
  if (context === undefined) {
    throw new Error('useTemplates must be used within a TemplateProvider');
  }

  if (withHidden) {
    return {
      ...context,
      templatesList: context.templatesListWithHidden,
    };
  }

  return context;
}

export default { TemplatesProvider, useTemplates };
