import { useEffect } from 'react';

export default function useGlobalKeyDownListener(
  callback,
  { meta = false, shift = false, ctrl = false, keys = [] },
) {
  return useEffect(() => {
    const globalKeyDownListener = (e) => {
      if ((meta && !e.metaKey) || (!meta && e.metaKey)) {
        return;
      }
      if ((shift && !e.shiftKey) || (!shift && e.shiftKey)) {
        return;
      }
      if ((ctrl && !e.ctrlKey) || (!ctrl && e.ctrlKey)) {
        return;
      }
      if (keys.every((k) => k.toLowerCase() !== e.key.toLowerCase())) {
        return;
      }
      callback(e);
    };

    document.addEventListener('keydown', globalKeyDownListener);

    return () => document.removeEventListener('keydown', globalKeyDownListener);
  });
}
