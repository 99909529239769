import { IconStar, IconArticle, IconAppWindow, IconListNumbers, IconBrain, IconComet, IconAd } from '@tabler/icons-react';

import { colors } from '@root/material.theme';
import { newColors } from '@root/newMaterial.theme';

const generalBuckets = {
  madeForYou: {
    id: 'made-for-you',
    title: 'Made for you',
    icon: IconStar,
    primaryColor: colors.yellow600,
    secondaryColor: colors.yellow200,
  },
  ecommerce: {
    id: 'ecommerce',
    title: 'eCommerce',
    icon: IconAppWindow,
    primaryColor: colors.green600,
    secondaryColor: colors.green200,
  },
  articlesAndBlogs: {
    id: 'articles-and-blogs',
    title: 'Articles & Blogs',
    icon: IconArticle,
    primaryColor: colors.blue600,
    secondaryColor: colors.blue200,
  },
};

const buckets = {
  all: {
    id: 'all',
    title: 'All',
    icon: IconListNumbers,
    primaryColor: newColors.purple600,
    secondaryColor: newColors.purple200,
  },
  ...generalBuckets,
};

const modalBuckets = {
  ...generalBuckets,
  ads: {
    id: 'ads',
    title: 'Ads',
    icon: IconAd,
    primaryColor: colors.blue600,
    secondaryColor: colors.blue200,
  },
  brandingAndWebsites: {
    id: 'branding-and-websites',
    title: 'Branding & Web',
    icon: IconAppWindow,
    primaryColor: colors.pink600,
    secondaryColor: colors.pink200,
  },
  brainstorming: {
    id: 'brainstorming',
    title: 'Brainstorming',
    icon: IconBrain,
    primaryColor: colors.orange600,
    secondaryColor: colors.orange200,
  },
  enhancement: {
    id: 'enhancement',
    title: 'Enhancement',
    icon: IconComet,
    primaryColor: colors.neutrals400,
    secondaryColor: colors.neutrals50,
  },
};

export {
  buckets,
  modalBuckets,
};
