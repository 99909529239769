import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { VisibilityOffOutlined, Visibility } from '@material-ui/icons';

import InputField from '@root/components/form/InputField';
import useModalState from '@root/utils/hooks/useModalState';
import { colors } from '@root/material.theme';

const PasswordField = (props) => {
  const [showPassword, , , toggleShowPassword] = useModalState(false);

  const passwordToggler = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={toggleShowPassword}
        color="default"
      >
        {showPassword ? (
          <Visibility htmlColor={colors.mediumDarkGrey} />
        ) : (
          <VisibilityOffOutlined htmlColor={colors.grey} />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <InputField
      type={showPassword ? undefined : 'password'}
      endAdornment={passwordToggler}
      {...props}
    />
  );
};

export default PasswordField;
