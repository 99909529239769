import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    flex: 1,
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: theme.palette.colors.neutrals50,
    padding: '16px 20px',
  },
  loaderWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
