import React from 'react';

const CongratulationsLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164"
      height="147"
      fill="none"
    >
      <path
        fill="#523AE7"
        fillOpacity=".1"
        d="M136.975 112.006c5.222-2.143 10.464-4.436 14.495-7.74 4.032-3.305 6.742-7.812 5.91-12.268-.765-4.097-4.354-7.63-5.307-11.704-1.635-6.992 4.683-13.557 6.158-20.569 1.108-5.273-.683-10.862-4.86-15.163-3.808-3.92-9.413-6.733-12.85-10.846-4.632-5.542-4.872-12.902-10.528-17.868-4.097-3.597-10.32-5.287-16.266-6.795-10.54-2.672-21.296-5.249-32.402-5.554-11.106-.306-22.775 1.925-30.794 7.755-2.493 1.812-4.617 3.98-5.692 6.467-2.542 5.881 1.097 12.122 3.378 18.064.446 1.163.844 2.39.507 3.573-.332 1.166-1.34 2.15-2.401 3.03-4.947 4.103-11.53 6.811-17.883 9.587s-12.772 5.812-17.054 10.323c-4.282 4.511-5.962 10.872-2.24 15.66 1.443 1.857 3.646 3.457 4.23 5.566 1.035 3.73-3.255 6.918-5.778 10.214-4.19 5.474-3.416 12.884 1.856 17.791 5.271 4.907 14.692 6.984 22.616 4.987l104.905-4.51Z"
      />
      <path fill="#DF9481" d="m67.713 42.525 20.105-13.049 2.144-6.192-.133 6.907-17.01 14.803-5.106-2.47Z" />
      <path fill="#FFBE55" d="m60.062 47.115 12.729-7.888 2.575 9.442-14.377 9.441-.927-10.995Z" />
      <path
        fill="#FFBE55"
        d="m60.062 47.115 12.729-7.888 2.575 9.442-14.377 9.441-.927-10.995Z"
        opacity=".2"
      />
      <path fill="#FFBE55" d="m34.14 105.995-5.345 10.673-.756-10.509 2.522-1.173-2.764-.417.01-1.499 6.308-.14.026 3.065Z" />
      <path fill="#360959" d="M54.5 72.726s6.115 23.17 4.345 26.226c-1.77 3.058-25.042 8.09-25.042 8.09l-.059-4.55s16.265-7.536 18.196-7.053c-3.861-3.54-7.683-21.587-7.683-21.587L54.5 72.726Z" />
      <path fill="#FFBE55" d="m30.355 124.878 4.58 11.023-8.445-6.299.761-2.676-2.123 1.819-1.128-.987 4.02-4.865 2.335 1.985Z" />
      <path fill="#440B70" d="M53.347 72.933s-13.575 32.205-21.699 53.993c-4.988 0-4.373-4.849-4.373-4.849s9.847-45.683 15.455-52.419c8.625.587 10.617 3.275 10.617 3.275Z" />
      <path fill="#DF9481" d="M44.257 58.11 27.845 73.286l-8.859 1.596 8.79.58L45.24 63.697l-.983-5.586Z" />
      <path fill="#440B70" d="M59.036 43.328c3.74 0 6.772-3.201 6.772-7.15 0-3.95-3.032-7.151-6.772-7.151s-6.771 3.201-6.771 7.15c0 3.95 3.032 7.151 6.771 7.151Z" />
      <path fill="#DF9481" d="M65.433 31.396s1.816 11.33-.29 13.598c-6.39-.436-7.988-2.851-9.586-6.191-1.597-3.34 2.033-10.167 9.876-7.407Z" />
      <path fill="#440B70" d="M67.032 31.386c-.325 2.483-9.952 8.818-12.063 7.712-.302-4.523 2.222-12.75 12.063-7.712Z" />
      <path fill="#DF9481" d="M56.532 39.343c-.804-.82-1.58-.916-1.709.136-.116.952 1.131 2.35 2.036 2.13.904-.221-.327-2.266-.327-2.266Z" />
      <path fill="#440B70" d="M56.865 30.172c-2.186-3.01-6.02-4.736-9.722-4.38-1.254.12-2.523.482-3.499 1.279-.976.797-1.608 2.086-1.386 3.327.364 2.04 2.818 3.46 2.587 5.52-.102.903-.74 1.675-1.508 2.162-.767.487-1.658.734-2.534.974-1.969.54-4 1.11-5.589 2.39-1.59 1.28-2.632 3.474-1.953 5.4.442 1.25 1.562 2.384 1.291 3.683-.278 1.338-1.867 1.953-2.553 3.136-.672 1.159-.321 2.716.57 3.716.892 1 2.213 1.518 3.53 1.762 2.123.394 4.69-.055 5.741-1.94.732-1.315.515-2.963-.016-4.37s-1.347-2.7-1.805-4.132c-.458-1.433-.5-3.128.425-4.314.97-1.245 2.675-1.579 4.216-1.921 1.541-.343 3.248-.934 3.89-2.376.579-1.304.116-2.996 1.015-4.104.808-.996 2.288-1.007 3.506-1.409 1.678-.553 3.017-2.032 3.4-3.756l.394-.647Z" />
      <path fill="#DF9481" d="m60.989 43.026-.316 3.812 2.585 6.224-6.067-6.054-.326-5.84 4.123 1.858Z" />
      <path fill="#FFBE55" d="m64.423 60.246-4.363 4.446-4.058 10.77-11.679-4.14-3.951-1.403 3.884-3.397s-4.016-4.185-4.8-7.25C46.777 53.07 52.398 48.09 57.19 47.01h1.278l4.79 6.052 1.165 7.185Z" />
      <path
        fill="#FFBE55"
        d="m51.94 59.746-7.617 11.575-3.951-1.402 3.884-3.397 7.684-6.776Z"
        opacity=".5"
      />
      <path fill="#FFBE55" d="m136.445 106.004 8.528 11.264-3.167-17.63-6.574 1.225 1.213 5.141Z" />
      <path fill="#F2B1B1" d="M115.84 77.138c-2.516 7.487-5.534 18.105-1.671 24.139 3.863 6.033 22.993 5.086 22.993 5.086l-.755-5.567s-13.254-2.704-12.678-4.428c.576-1.724 3.899-14.161 3.396-15.835-.503-1.673-1.19-6.712-1.19-6.712l-10.095 3.317Z" />
      <path fill="#FFBE55" d="m139.34 127.952-9.922 10.058 16.992-5.667-2.156-6.329-4.914 1.938Z" />
      <path fill="#FCC" d="M116.79 75.724c.134 2.667 21.618 52.608 21.618 52.608l6.301-1.51s-11.408-45.863-12.577-51.87c-1.169-6.007-15.342.772-15.342.772Z" />
      <path fill="#FFE3CA" d="m140.42 60.117 1.789 2.235 4.312 15.034 1.166 6.337-3.091-.896-.627-4.08-.803 1.61-.882.163 1.093-3.46-4.957-12.317-3.131-2.097 5.131-2.53ZM113.255 41.479 92.52 29.457l-2.558-6.173-.132 6.907 18.45 14.01 4.975-2.722Z" />
      <path fill="#440B70" d="m120.503 45.432-9.83-7.125-5.191 5.601 11.784 10.207 3.237-8.683Z" />
      <path fill="#440B70" d="M120.503 45.433c-.394.35-4.185 3.237-5.93 6.53-1.746 3.294.123 26.54.123 26.54l20.303-1.021-4.052-17.174 4.046 4.37 7.036-5.912s-7.486-10.216-9.074-11.278c-2.977-1.993-12.452-2.055-12.452-2.055Zm.514-16.174c1.623.12 9.608 1.397 3.001 11.06-1.388 2.546-3.001-11.06-3.001-11.06Z" />
      <path fill="#FFE3CA" d="M114.076 31.527s-1.012 10.163 1.206 12.323c1.59-.19 4.054-.24 5.109-.634 3.165-1.18 2.689-3.377 3.923-4.875 2.354-2.858-2.544-9.964-10.238-6.814Z" />
      <path fill="#FFE3CA" d="m119.997 41.642.331 3.936-.682 2.25c-.307.86.792 1.519 1.406.842l3.351-3.06-.254-7.261-4.152 3.293Z" />
      <path fill="#440B70" d="M117.658 34.34c-.769-.042-1.524.136-2.279.283-.755.147-1.595.19-2.235-.236-.878-.585-1.045-1.802-1.696-2.632-.464-.591-1.149-.957-1.724-1.44a.69.69 0 0 1-.212-.25c-.097-.23.054-.486.213-.678.564-.686 1.335-1.212 2.197-1.425.862-.214 1.812-.101 2.57.362.691.422 1.232 1.125 2.017 1.323 1.185.299 2.298-.664 3.499-.885a2.954 2.954 0 0 1 1.264.043c.262.068.517.173.727.343.577.468.707 1.324 1.261 1.818.577.514 1.482.52 2.055 1.04.399.362.564.91.706 1.43.178.65.335 1.393-.029 1.96-.159.248-.566 2.447-.817 2.6-1.444.882-2.948-.868-4.325-1.549-.498-.246-.897-.646-1.208-1.107-.258-.382-.829-.938-1.984-1Z" />
      <path fill="#440B70" d="m122.574 35.3.038 3.678.733-.036 1.192-3.742-1.963.1Z" />
      <path fill="#FFE3CA" d="M123.581 35.76c1.44-1.095 1.9-.706 1.778.934-.122 1.641-2.014 2.247-2.014 2.247l.236-3.181Z" />
      <path fill="#FFBE55" d="M117.233 63.133c1.155-.058 1.989-2.12 1.864-4.604s-1.162-4.452-2.317-4.393c-1.155.058-1.989 2.12-1.864 4.604s1.163 4.451 2.317 4.393Z" />
      <path
        stroke="#FFBE55"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M87.443 24.73 84.1 21.227M91.993 24.73l3.344-3.503M93.446 19.368l.22-.23M91.993 20.89l1.069-1.12M88.613 20.89l-1.07-1.12"
      />
      <path fill="#FFBE55" d="M84.715 16.17a5.877 5.877 0 0 0-5.877 5.878 5.878 5.878 0 0 0-5.878-5.878 5.878 5.878 0 0 0 5.879-5.879 5.877 5.877 0 0 0 5.876 5.879Zm13.047 24a2.943 2.943 0 0 0-2.944 2.944 2.944 2.944 0 0 0-2.944-2.944 2.944 2.944 0 0 0 2.944-2.944 2.944 2.944 0 0 0 2.944 2.944Z" />
    </svg>
  );
};

export default CongratulationsLogo;
