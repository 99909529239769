import PubSub from 'pubsub-js';

// TODO: Remove dependency cycle after magic.link integration
const errorInterceptor = async (error) => new Promise((resolve, reject) => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 401) {
    // TODO rethink the logic. error interceptor shouldn't know about app implementation
    const urlsToIgnore = ['/ai/personalized-copy'];

    if (urlsToIgnore.includes(error.config.url)) {
      resolve(error.response);
      return;
    }

    if (error.config.url === '/account/logout' || error.config.url === '/users/currentUser') {
      // PubSub.publish('logout', { redirect: '/', log: false });
      resolve(error.response);
      return;
    }

    const { errors = [{}] } = error.response.data;
    const [{ authorization: authorizationError }] = errors;
    PubSub.publish('logout', { redirect: '/', log: !authorizationError });
    resolve(error.response);
    return;
  }
  if (statusCode === 403) {
    const { errors = [{}] } = error.response.data;
    const [{ authorization: authorizationError }] = errors;
    PubSub.publish('logout', { redirect: `/login?error=${authorizationError}`, log: false });
    resolve(error.response);
    return;
  }
  if (statusCode === 404) {
    resolve(null);
    return;
  }

  if (statusCode === 400) {
    const { errors } = error.response.data;

    resolve({
      isBadRequest: true,
      errors,
    });
    return;
  }

  if (statusCode === 429) {
    resolve({
      isBadRequest: true,
      errors: [{ message: error.response.data }],
    });
    return;
  }

  reject(error);
});

export default errorInterceptor;
