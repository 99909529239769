import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
  },
  fileTitle: {
    all: 'unset',
    marginLeft: 4,
    padding: '6px 8px',
    backgroundColor: theme.palette.colors.neutrals500,
    borderRadius: 6,
    lineHeight: '18px',
    fontWeight: 600,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 32,
  },
  closeButton: {
    color: theme.palette.colors.white,
  },
  disabledButton: {
    color: `${theme.palette.colors.white} !important`,
  },
  viewOnly: {
    fontSize: 12,
    lineHeight: '18px',
    borderRadius: 6,
    border: `1px solid ${theme.palette.colors.neutrals400}`,
    marginLeft: 8,
    padding: '6px 8px',
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    align: 'end',
  },
  templateType: {
    fontWeight: 500,
  },
  credits: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
}));

export default useStyles;
