import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    details: {
      margin: '14px 0 32px',
    },
    feature: {
      marginBottom: 16,

      '& a': {
        color: theme.palette.primary.main,
      },

      '& > :not(:last-child)': {
        marginBottom: 16,
      },
    },
  });
});

export default useStyles;
