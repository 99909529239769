import React, { useState } from 'react';
import { Create as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';

import Button from '@root/components/buttons/Button';

import TextInput from '../TextInput';

import useStyles from './InputButton.styles';

const InputButton = ({
  text, onClick, onChange, onDelete, onError, inputs, disabled,
}) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(null);

  const handleClick = () => {
    onClick(text);
  };

  const handleChangeClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(text);
  };

  const handleSave = (newInput) => {
    onChange(text, newInput);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <TextInput
        inputs={inputs}
        initialValue={text}
        onSave={handleSave}
        onClose={handleClose}
        onError={onError}
      />
    );
  }
  return (
    <Button
      variant="outlined"
      className={classes.inputButtonRoot}
      onClick={handleClick}
      disabled={disabled}
    >
      <AddIcon
        color={disabled ? 'disabled' : 'primary'}
        fontSize="small"
      />
      <span className={classes.buttonText}>
        {text}
      </span>
      <div className={classes.subButtonsBlock}>
        <EditIcon
          color="primary"
          fontSize="small"
          onClick={handleChangeClick}
        />
        <DeleteIcon
          color="primary"
          fontSize="small"
          onClick={handleDeleteClick}
        />
      </div>
    </Button>

  );
};

export default InputButton;
