import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  warningRoot: {
    backgroundColor: theme.palette.colors.white,
    fontWeight: 500,
    fontSize: 12,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    marginBottom: 16,
    '& svg': {
      marginRight: 6,
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  suspended: {
    color: theme.palette.colors.primary,
    border: `1px solid ${theme.palette.colors.primary}`,
  },
  legacy: {
    color: theme.palette.colors.warning,
    border: `1px solid ${theme.palette.colors.warning}`,
    '& a': {
      color: theme.palette.colors.warning,
    },
  },
  linkButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  jan2022StarterOrProWarning: {
    backgroundColor: theme.palette.colors.white,
    fontWeight: 400,
    fontSize: '14px',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    marginBottom: 16,
    '& svg': {
      marginRight: 6,
    },
  },
}));
