import { useMutation } from 'react-query';

import api from '@root/api';

const updateProductDescription = async ({
  productCode,
  description,
  channels,
  locale,
  field,
}) => {
  const products = await api.akeneo.updateProductDescription({
    productCode,
    description,
    channels,
    locale,
    field,
  });

  return products;
};

export default function useUpdateProductDescription() {
  return useMutation(
    (data) => updateProductDescription(data),
  );
}
