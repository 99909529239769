import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { FormField, InputField } from '@root/components/form';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import useStyles from './SubmitTemplateModal.styles';

export const validationSchema = Yup.object().shape({
  submitNotes: Yup
    .string()
    .required('Explanation is required'),
});

const initialValues = {
  submitNotes: '',
};

const SubmitTemplateModal = ({
  onClose,
  onSubmit,
}) => {
  const formRef = useRef(null);

  const classes = useStyles();

  const handleSubmit = ({ submitNotes }) => {
    onSubmit({ submitNotes });
  };

  const handleConfirm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <ConfirmationModal
      title="Submit generator"
      onConfirm={handleConfirm}
      onClose={onClose}
      confirmButtonText="Submit for review"
    >
      When the&nbsp;
      <b>generator</b>
      &nbsp;gets approved, it will automatically be available to
      all members of your team. You won&apos;t be able to undo this.
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
      >
        {() => {
          return (
            <Form className={classes.form}>
              <FormField
                name="submitNotes"
                label="Add explanation"
                component={InputField}
                required
                multiline
                inputProps={{ maxLength: 150 }}
              />
            </Form>
          );
        }}

      </Formik>
      <Formik />
    </ConfirmationModal>
  );
};

export default SubmitTemplateModal;
