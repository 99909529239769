import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import useFiles from '@root/resources/file/useFiles';
import useFolders from '@root/resources/folder/useFolders';
import {
  useDashboard, viewTypes,
} from '@root/views/Dashboard/dashboardContext';
import { getChildFolders } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';

import DashNav from './DashNav';
import DashListView from './DashListView';
import DashCardView from './DashCardView/DashCardView';
import FilesEmpty from './FilesEmpty';
import Pagination from './Pagination';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE_ITEMS_NUMBER = 25;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: '1',
    backgroundColor: theme.palette.colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

// TODO: should be replaced with FolderContentNew after all QA/user testing
const FolderContent = () => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_ITEMS_NUMBER);

  const {
    state: {
      view, filters,
    },
  } = useDashboard();

  const { currentUser: user } = AuthContext.useAuth();

  const paginationEnabled = user.featureToggles.dashboardPagination;

  const fileFilters = {
    ...filters,
    page,
    ...(paginationEnabled && { perPage }),
    ...(filters.folderId && !filters.folderIds && { folderIds: [filters.folderId] }),
  };

  const { isLoading: isLoadingFiles, data: filesData, isFetching } = useFiles(fileFilters);
  const {
    isLoading: isLoadingFolders,
    data: foldersData,
    isFetching: isFetchingFolders,
  } = useFolders();

  const files = filesData?.files || [];
  const folders = foldersData || [];

  const folder = filters.folderId && folders.find((f) => f._id === filters.folderId);
  const isOwner = user.roles.getFolderRoles(folder).owner;
  const subfolders = getChildFolders(filters.folderId, folders);

  useEffect(() => {
    if (page !== DEFAULT_PAGE) {
      setPage(DEFAULT_PAGE);
    }
  }, [folder?._id, filters]);

  const classes = useStyles();
  const showFilesEmpty = (!isLoadingFiles && files.length === 0)
  && (subfolders.length === 0);

  const isLoading = isLoadingFiles || isLoadingFolders || isFetching || isFetchingFolders;

  return (
    <div className={classes.root}>
      <DashNav />

      {view === viewTypes.LIST
        ? (
          <DashListView
            folder={folder}
            files={files}
            subfolders={subfolders}
            isLoading={isLoading}
            isDraggable={isOwner}
          />
        )
        : (
          <DashCardView
            files={files}
            subfolders={subfolders}
            isLoading={isLoading}
            isDraggable={isOwner}
          />
        )}
      {paginationEnabled && filesData && (files.length >= 25 || page !== 1) && (
        <Pagination
          currentPage={page}
          pageCount={filesData.pageCount}
          onPageChange={(newPage) => setPage(newPage)}
          perPage={perPage}
          onPerPageChange={(newPerPage) => setPerPage(newPerPage)}
        />
      )}
      {showFilesEmpty && <FilesEmpty />}
    </div>
  );
};

export default FolderContent;
