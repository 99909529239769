import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useShareFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id, data }) => api.folders.share(_id, data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const folder = response;

        queryClient.invalidateQueries(['folders.list']);
        queryClient.invalidateQueries(['folders.getById', folder._id]);

        const folders = queryClient.getQueryData(['folders.list']);

        if (!folders) {
          return;
        }

        queryClient.setQueryData(['folders.list'], () => folders.map((f) => (f._id === folder._id ? { ...f, unifiedAcl: folder.unifiedAcl } : f)));
        queryClient.setQueryData(['folders.getById', folder._id], folder);
      },
    },
  );
}
