import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
  },
  popperStyle: (props) => ({
    display: props.open ? 'block' : 'none',
  }),
  nested: {
    paddingLeft: theme.spacing(4),
    fontFamily: "'Inter', sans-serif",
  },
  growStyle: {
    transformOrigin: 'bottom right',
  },
  paperStyle: (props) => ({
    maxHeight: props.maxHeight || '10rem',
    overflowY: 'auto',
    width: props.width,
    marginRight: props.center ? 0 : props.marginRight || props.width,
    marginBottom: props.marginBottom || 0,
  }),
  kebabContainer: {
    width: '100%',
  },
  kebabStyle: {
    color: '#535ca5',
    fontSize: '1.1em',
    pointerEvents: 'none',
    marginLeft: '1rem',
    marginRight: '1.5rem',
  },
  titleStyle: {
    fontFamily: "'Inter', sans-serif",
    color: theme.palette.colors.darkGrey,
    margin: '0 0.625rem',
  },
  subTitleStyle: {
    fontFamily: "'Inter', sans-serif",
    color: theme.palette.colors.darkGrey,
  },
  subMenuOpen: {
    margin: '0.625rem 0 0 1rem',
    pointerEvents: 'none',
  },
  subMenuClose: {
    margin: '0 0 0.125rem 1rem',
    pointerEvents: 'none',
  },

}));
