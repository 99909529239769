import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js/min';

export const initialValues = {
  industrySegment: '',
  otherIndustrySegment: '',
  name: '',
  phoneNumber: '',
};

export const getCompanySchema = (isPhoneNumberRequired) => Yup.lazy(({ industrySegment, countryCode = 'US' }) => {
  const isOtherSegment = industrySegment === 'other';
  return (
    Yup.object().shape({
      industrySegment: Yup.string()
        .required('Company Type is required'),
      otherIndustrySegment: isOtherSegment && Yup.string().required('Company Type is required'),
      phoneNumber: isPhoneNumberRequired
        ? Yup.string().required('Phone Number is required')
          .test(
            'phone',
            'Please enter a valid phone number',
            (value) => value && isValidPhoneNumber(value, countryCode),
          )
        : Yup.string().optional()
          .test(
            'phone',
            'Please enter a valid phone number or leave it blank',
            (value) => !value || value === '+' || isValidPhoneNumber(value, countryCode),
          ),
    })
  );
});

export const getPhoneOption = (currentCompany) => {
  const testValue = currentCompany.createdOn % 100;
  switch (true) {
    case testValue > 0 && testValue <= 33:
      return 'required';
    case testValue >= 34 && testValue <= 66:
      return 'optional';
    default:
      return 'none';
  }
};
