import { escapeText, findSelectedDomNode } from './text.utils';

const getFileData = (localFile, config) => {
  const keys = config.fields
    .filter((f) => f.name !== 'language' && f.name !== 'outputLanguage' && f.name !== 'negativeKeywordArray')
    .map((f) => f.name);

  const data = {};
  keys.forEach((k) => { data[k] = localFile[k]; });

  return data;
};

const buildLocalFile = (file, { initialAIOptions } = {}) => {
  const f = {
    ...file,
    language: file.language || 'en',
    outputLanguage: file.outputLanguage || file.language || 'en',
    engine: file.engine || 'default',
    fileTitle: file.title,
    assignedToId: file.assignedTo?.userId || file.assignedToId,
    aiOptions: file.aiOptions || initialAIOptions,
    ...file.data,
  };

  delete f.data;

  return f;
};

const getPrevDataObject = (localFile) => {
  const pd = { ...localFile };

  delete pd.updatedOn;
  delete pd.version;

  return {
    obj: pd,
    raw: JSON.stringify(pd),
  };
};

const isLocalFileEqualsPrevData = (localFile, prevData) => {
  const lf = { ...localFile };
  delete lf.updatedOn;
  delete lf.version;

  return JSON.stringify(lf) === prevData.raw;
};

const handleDocumentEditorTextSelect = ({
  selection,
  onTextSelect,
  fieldRefList,
  content,
  setFieldValue,
}) => {
  if (fieldRefList) {
    const refs = fieldRefList.map((fr) => fr.ref);
    const selectedRef = findSelectedDomNode(selection, refs);
    const field = fieldRefList.find((fr) => fr.ref === selectedRef)?.field;

    if (!field) {
      return;
    }

    const text = escapeText(selection.toString());

    if (!text || !escapeText(content[field]).includes(text)) {
      onTextSelect(null);
      return;
    }

    onTextSelect({
      text,
      field,
      onTextUpdate: (newText) => setFieldValue(field, newText),
      content,
    });

    return;
  }

  const text = escapeText(selection.toString());

  if (!text || !escapeText(content).includes(text)) {
    onTextSelect(null);
    return;
  }

  onTextSelect({
    text,
    field: null,
    onTextUpdate: (newText) => setFieldValue('content', newText),
    content,
  });
};

export {
  getFileData,
  buildLocalFile,
  getPrevDataObject,
  isLocalFileEqualsPrevData,
  handleDocumentEditorTextSelect,
};
