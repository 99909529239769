import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isEqual, isString, omit } from 'lodash';
import { DocumentUtil, StringUtil } from '@copysmith/utils';
import { MuiThemeProvider } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';

import fileResource from '@root/resources/file';
import folderResource from '@root/resources/folder';
import EditorNav from '@root/components/EditorNav';
import WorkflowQueueContext from '@root/resources/file/workflowQueue.context';
import AssignToSelect from '@root/views/File/components/SelectComponents/AssignToSelect';
import StatusSelect from '@root/views/File/components/SelectComponents/StatusSelect';
import authContext from '@root/resources/auth/auth.context';
import templatesContext from '@root/resources/templates/templates.context';
import SEOTab from '@root/views/DocumentEditor/components/SEOTab';
import documentResource from '@root/resources/document';
import useModalState from '@root/utils/hooks/useModalState';
import RootLayout from '@root/views/Layout/RootLayout';
import billingResource from '@root/resources/billing';
import useDeleteFile from '@root/resources/file/useDeleteFile';
import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import aiResource from '@root/resources/ai';
import newTheme from '@root/newMaterial.theme';
import documentImageResource from '@root/resources/documentImage';
import uiNotificationService from '@root/services/uiNotification.service';

import { templates, getTabs } from './Editor.constants';
import { processGenerationResult } from './Editor.helpers';
import InputForm from './components/InputForm';
import AIImageInputForm from './components/AIImageInputForm';
import EditorJS from './components/EditorJS';
import IconNavbar from './components/IconNavbar';
import useStyles from './EditorPage.styles';
import SaveModal from './components/SaveModal';
import AdditionalMenuButton from './components/AdditionalMenuButton';
import ImageModal from './components/ImageModal';
import AIImageView from './components/AIImageView';

export const TEMPLATE_TYPE = 'freeFormEditor';
const _document = document;

const EditorPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { isTemplateDisabled, toggles: { artStudioDisabled } } = currentExternalSource;

  const { currentUser } = authContext.useAuth();
  const editorRef = useRef(null);
  const [isConfirmationOpen, openConfirmation, closeConfirmation] = useModalState();

  const formRef = useRef();
  const { fileId, id } = useParams();
  const { current: { initialTitle, initialTemplate } = {} } = useRef(history.location.state);

  const { mutateAsync: generateNow } = aiResource.useGenerateContentNow();
  const { isLoading: isGeneratedImagesLoading, mutateAsync: generateImage } = aiResource.useGenerateImage();

  const { data: file, isLoading: isFileLoading } = fileResource.useFile(fileId);
  const [fileTitle, setFileTitle] = useState(initialTitle || file?.title || '');
  const { refetch: refetchFiles } = fileResource.useFiles({ });
  const { mutateAsync: updateFile } = fileResource.useUpdateFile();
  const { mutateAsync: deleteFile } = useDeleteFile();

  const { data: folder } = folderResource.useFolder(file && file.folderId);

  const { mutateAsync: updateDocument } = documentResource.useUpdateDocument();
  const { mutateAsync: createDocument } = documentResource.useCreateDocument();
  const { data: documents, refetch: refetchDocuments } = documentResource.useDocuments({ fileId });
  const document = (documents || [])[0];

  const { canEditFile } = currentUser.permissions.getFilePermissions(file, folder);
  const readOnly = !canEditFile;
  const tabs = getTabs({ readOnly, aiImagesEnabled: currentUser.featureToggles.aiImages && !artStudioDisabled });
  const tabsList = Object.values(tabs);

  if (document?.content) {
    document.content = isString(document?.content)
      ? DocumentUtil.transformHtmlToEditorFormat(document.content)
      : document?.content;
  }

  const [tab, setTab] = useState(tabs.create.value);
  const [editorData, setEditorData] = useState(document?.content);
  const [selectedTemplateType, setSelectedTemplateType] = useState(
    initialTemplate || templates.freeFormEditorInstruct.templateType,
  );

  const { data: historicAiImages } = documentImageResource.useLatestAIImages(
    { documentId: document?._id, enabled: currentUser.featureToggles.aiImages ? tab === tabs.aiImages?.value : false },
  );
  const [generatedAiImages, setGeneratedAiImages] = useState([]);
  const [isGeneratedImagesError, setGeneratedImagesError] = useState(false);
  const [isImagePromptSensitive, setImagePromptSensitive] = useState(false);

  // used to define and disable close button if editor pending changes
  // prevents accidental closing without confirmation window
  const [isEditorChanging, setIsEditorChanging] = useState();
  const [isCloseButtonPressed, setCloseButtonPressed] = useState(false);

  const editorDataHtml = editorData
    ? DocumentUtil.transformEditorDataToHTML(editorData)
    : '';

  const { data: billingInfo = {} } = billingResource.useBillingInfo();
  const { data: { aiImageGenerations, limit: credits, enterpriseDisplayLimit } = {} } = billingResource.useLimits();
  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;

  const { getTemplate } = templatesContext.useTemplates();
  const selectedTemplate = getTemplate(selectedTemplateType);

  const {
    assignedToId, setAssignedToId,
    workflowStatus, setWorkflowStatus,
    setWorkflowStatusError,
  } = WorkflowQueueContext.useWorkflowQueue();

  const isNewFile = !document?.content;
  const hasEditorChanges = document
    && ((!document.content?.time && !!editorData?.time)
      || document.content?.time < editorData?.time);
  const hasChanges = hasEditorChanges
    || workflowStatus !== file?.workflowStatus
    || assignedToId !== file?.assignedTo?.userId
    || fileTitle !== file?.title;

  useBeforeUnload({
    when: hasChanges,
    message: 'Are you sure you want to leave? All progress will be lost',
  });

  const debouncedSave = useDebouncedCallback(
    async () => {
      const data = await editorRef.current.save();
      setEditorData(data);
      updateDocument({
        _id: id,
        data: {
          content: data,
        },
      });
      setIsEditorChanging(false);
    },
    1000,
  );
  const handleEditorChange = () => {
    setIsEditorChanging(true);
    debouncedSave();
  };

  const handleTemplateChange = (value) => {
    setSelectedTemplateType(value);
  };

  const handleNavIconClick = (selectedTab) => {
    setTab(selectedTab);
  };

  const handleInsertBlock = (text) => {
    const textToInsert = text.replace(/\n/g, ' <br>');
    editorRef.current.caret.setToLastBlock();
    editorRef.current.blocks.insert(
      'paragraph',
      { text: textToInsert },
    );
    editorRef.current.caret.setToLastBlock('end');
  };

  const handleInsertImageBlock = (url) => {
    editorRef.current.caret.setToLastBlock();
    editorRef.current.blocks.insert(
      'image',
      { url },
    );
    editorRef.current.caret.setToLastBlock('end');
  };

  const handleInsertToSelection = (text) => {
    const selection = _document.getSelection();
    const isSelectionInEditor = editorRef.current.selection.findParentTag('DIV', 'codex-editor');

    if (!isSelectionInEditor) {
      handleInsertBlock(text);
      return;
    }

    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(_document.createTextNode(text));
    selection.collapseToEnd();
  };

  const handleGenerate = async (values) => {
    const { data, aiOptions, outputLanguage, engine, seoKeywords } = values;
    const seoKeywordsList = seoKeywords.map((keyword) => keyword.text);
    const { results } = await generateNow({
      templateType: selectedTemplateType,
      negativeKeywordArray: data?.negativeKeywordArray || [],
      data,
      aiOptions,
      outputLanguage,
      engine,
      seoKeywords: seoKeywordsList, // name TBD
    });

    if (results) {
      results.forEach((r) => {
        handleInsertBlock(processGenerationResult(r.content));
      });
    }
  };

  const handleGenerateImage = async (values) => {
    const { prompt } = values;
    const { results, isError, isSensitive } = await generateImage({
      documentId: document._id,
      prompt,
      n: 4,
      size: '1024x1024',
    });

    if (results && results.length > 0) {
      setGeneratedAiImages(results.map((result) => { return { ...result, selected: false, visible: true }; }));
      setGeneratedImagesError(false);
      setImagePromptSensitive(false);
    } else if (isError) {
      setGeneratedImagesError(true);
      setImagePromptSensitive(false);
    } else if (isSensitive) {
      setImagePromptSensitive(true);
      setGeneratedImagesError(false);
    }
  };

  const handleSave = async (values) => {
    const internalEditorData = await editorRef.current.save();
    const content = editorData || internalEditorData;
    await updateDocument({
      _id: id,
      data: {
        content,
      },
    });

    const fileName = values?.fileName || fileTitle;
    await updateFile({
      fileId,
      data: {
        title: fileName,
        workflowStatus,
        assignedToId,
        ...(values?.folderId && { folderId: values?.folderId }),
      },
    });

    refetchFiles();
    refetchDocuments();
    history.push('/templates');
  };

  const handleDiscard = async () => {
    if (isNewFile) {
      await deleteFile(fileId);
    } else {
      await updateDocument({
        _id: id,
        data: {
          content: document.content, // restore initial content
        },
      });
    }
    history.push('/templates');
  };

  const checkForChanges = async () => {
    const data = await editorRef.current.save();
    const innerData = data.blocks.map((b) => omit(b, 'id'));
    const stateData = editorData?.blocks && editorData.blocks.map((b) => omit(b, 'id'));

    const hasChangesAfterAutosave = !isEqual(innerData, stateData);
    return hasChanges || hasChangesAfterAutosave;
  };

  const handleClose = async () => {
    if (!editorRef.current) {
      setCloseButtonPressed(true);
      return;
    }

    if (readOnly) {
      history.push('/templates/my-files');
      return;
    }

    const hasChangesUponClose = await checkForChanges();

    if ((isNewFile && (hasEditorChanges || hasChanges)) || (!isNewFile && hasChangesUponClose)) {
      openConfirmation();
      return;
    }

    history.push('/templates/my-files');
  };

  const createDoc = async () => {
    return createDocument({
      fileId,
    });
  };

  const [imageBlockId, setImageBlockId] = useState(null);

  const handleAddImageClick = async (blockId) => {
    setImageBlockId(blockId);
  };

  const closeImageModal = () => {
    const blockIndexToRemove = editorData.blocks.findIndex((b) => b.id === imageBlockId);
    if (blockIndexToRemove !== -1) {
      editorRef.current.blocks.delete(blockIndexToRemove);
    }
    setImageBlockId(null);
  };

  const insertImageBlock = async (url) => {
    const blockIndex = editorData.blocks.findIndex((b) => b.id === imageBlockId);

    if (blockIndex !== -1) {
      closeImageModal();
      editorRef.current.blocks.insert(
        'image',
        { url },
        null,
        blockIndex,
      );
    }
  };

  useEffect(() => {
    if (document?.content) {
      setEditorData(document.content);
    }
  }, [document?._id]);

  useEffect(() => {
    if (file?.title) {
      setFileTitle(file.title);
    }
  }, [file?.title]);

  // TODO: create file & doc during signup flow
  useEffect(async () => {
    if (!documents) {
      return;
    }
    if (!document) {
      const newDoc = await createDoc();
      history.replace({
        pathname: `/documents/${fileId}/${newDoc._id}`,
      });
    } else {
      history.replace({
        pathname: `/documents/${fileId}/${document._id}`,
      });
    }
  }, [documents]);

  useEffect(async () => {
    const isDisabled = isTemplateDisabled(TEMPLATE_TYPE);
    if (isDisabled) {
      history.push('/templates');
    }
  }, [isTemplateDisabled]);

  useEffect(async () => {
    if (isCloseButtonPressed) {
      handleClose();
    }
  }, [editorRef.current]);

  useEffect(() => {
    if (!isFileLoading && !file) {
      history.push('/create');
      uiNotificationService.showErrorMessage('File not found');
    }
  }, [file, isFileLoading]);

  const renderSidebar = () => {
    if (tab === tabs.create.value) {
      return (
        <InputForm
          template={selectedTemplate}
          onTemplateChange={handleTemplateChange}
          formRef={formRef}
          readOnly={readOnly}
          onGenerate={handleGenerate}
        />
      );
    }
    if (tab === tabs.seo.value) {
      return (
        <div className={classes.tabWrapper}>
          <SEOTab
            documentId={document?._id}
            content={editorDataHtml}
            onInsert={handleInsertToSelection}
          />
        </div>
      );
    }
    if (currentUser.featureToggles.aiImages && tab === tabs.aiImages.value) {
      return (
        <AIImageInputForm
          aiImageGenerations={aiImageGenerations}
          billingInfo={billingInfo}
          isAdminOrOwner={isAdminOrOwner}
          onGenerate={handleGenerateImage}
        />
      );
    }
    return null;
  };

  return (
    <RootLayout>
      <EditorNav
        fileTitle={fileTitle}
        onFileTitleChange={setFileTitle}
        folder={folder}
        readOnly={readOnly}
        onClose={handleClose}
        isPendingChanges={isEditorChanging}
      />
      <div className={classes.editorMain}>
        <div className={classes.sidebar}>
          <IconNavbar
            onClick={handleNavIconClick}
            tabs={tabsList}
            selectedTab={tab}
          />
          <div className={classes.tab}>
            {selectedTemplate && renderSidebar()}
          </div>

        </div>
        <div className={clsx(classes.editorWrapper,
          currentUser.featureToggles.aiImages && tab === tabs?.aiImages?.value && classes.hidden)}
        >
          <div className={classes.editorTopBar}>
            <div className={classes.counter}>
              <span>
                Words
                {' '}
                <strong>{StringUtil.countWordsHtml(editorDataHtml) || 0}</strong>
              </span>
              <span>
                Characters
                {' '}
                <strong>{StringUtil.removeHtmlTags(editorDataHtml)?.length || 0}</strong>
              </span>
            </div>

            <div className={classes.rightBlock}>
              {currentUser.showCredits && (
                <div className={classes.credits}>
                  Total words:&nbsp;
                  <b className={classes.creditsAmount}>
                    {enterpriseDisplayLimit !== null
                      ? enterpriseDisplayLimit : credits}

                  </b>
                </div>
              )}
              {currentUser.permissions.workflowQueue && currentUser.permissions.teams && (
                <>
                  <AssignToSelect
                    onChange={setAssignedToId}
                  />
                  <StatusSelect
                    onChange={(status) => {
                      setWorkflowStatusError(null);
                      setWorkflowStatus(status);
                    }}
                  />
                </>
              )}
              <AdditionalMenuButton
                file={file}
                document={document}
                editorDataHtml={editorDataHtml}
                editorData={editorData}
              />
            </div>
          </div>
          {file && document && (
            <EditorJS
              formRef={formRef}
              editorRef={editorRef}
              onChange={handleEditorChange}
              initialData={document.content}
              readOnly={readOnly}
              showShortcuts={hasEditorChanges}
              onAddImageClick={handleAddImageClick}
              documentId={document?._id}
            />
          )}
        </div>
        {document && currentUser.featureToggles.aiImages && tab === tabs?.aiImages?.value && (
          <AIImageView
            aiImageGenerations={aiImageGenerations}
            documentId={document._id}
            generatedImages={generatedAiImages}
            handleInsertImageBlock={handleInsertImageBlock}
            historicImages={historicAiImages}
            isError={isGeneratedImagesError}
            isGeneratedImagesLoading={isGeneratedImagesLoading}
            isSensitive={isImagePromptSensitive}
            selectedImagesCount={generatedAiImages.filter((image) => image.selected).length}
          />
        )}
      </div>
      {isConfirmationOpen && (
        <SaveModal
          onClose={closeConfirmation}
          onDiscard={handleDiscard}
          onSave={handleSave}
        />
      )}
      {imageBlockId && (
        <ImageModal
          onClose={closeImageModal}
          onAddImage={insertImageBlock}
          documentId={document?._id}
        />
      )}
    </RootLayout>
  );
};

const WrappedEditor = (props) => {
  const { fileId } = useParams();
  const { data: file } = fileResource.useFile(fileId);

  return (
    <MuiThemeProvider theme={newTheme}>
      <WorkflowQueueContext.WorkflowQueueProvider file={file}>
        <EditorPage {...props} />
      </WorkflowQueueContext.WorkflowQueueProvider>
    </MuiThemeProvider>
  );
};

export default WrappedEditor;
