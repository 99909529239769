import React from 'react';
import type { ReactNode } from 'react';
import { ThemeProvider, Dialog, DialogContent, DialogActions, DialogTitle, Button, DialogProps } from '@material-ui/core';

import CloseButton from '@root/components/buttons/CloseButton';
import newMaterialTheme from '@root/newMaterial.theme';

interface ConfirmationModalProps extends DialogProps {
  open: boolean;
  onClose?: () => unknown;
  onCancel?: () => void;
  onConfirm?: () => void;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  negative?: boolean;
  noCancel?: boolean;
  noClose?: boolean;
  children?: ReactNode;
}

const ConfirmationModal = ({
  open = true,
  onClose,
  onCancel,
  onConfirm,
  isConfirmDisabled,
  isCancelDisabled,
  title,
  text,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  negative,
  maxWidth,
  noCancel,
  noClose,
  children,
  ...props
}: ConfirmationModalProps) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    if (onClose) {
      onClose();
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ThemeProvider theme={newMaterialTheme}>

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        {...props}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {text}
          {children}
        </DialogContent>

        <DialogActions>
          {!noCancel && (
            <Button
              variant="text"
              onClick={handleCancel}
              disabled={isCancelDisabled}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            onClick={handleConfirm}
            color={negative ? 'secondary' : undefined}
            disabled={isConfirmDisabled}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>

        {!noClose && (
          <CloseButton
            onClick={handleClose}
          />
        )}
      </Dialog>
    </ThemeProvider>

  );
};

export default ConfirmationModal;
