import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useApproveSubmittedDocumentExample() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.documentExample.approveReview(data),
    {
      onSuccess: (response, vars) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const queryKey = ['documentExample.list'];
        const prevData = queryClient.getQueryData(queryKey) || [];

        queryClient
          .setQueryData(queryKey, () => prevData.map((e) => (e._id !== vars.id ? e : response)));
      },
    },
  );
}
