import React from 'react';

import AuthContext from '@root/resources/auth/auth.context';

import Filters from './Filters';
import TemplateSearch from './TemplateSearch';
import Instruct from './Instruct';
import useStyles from './Header.styles';

const Header = () => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const instructLandingEnabled = currentUser.featureToggles.landingPageInstruct;

  return (
    <div className={classes.header}>
      <h1 className={classes.title}>What would you like to create?</h1>
      {!instructLandingEnabled && (
        <div>
          <TemplateSearch />
        </div>
      )}
      {instructLandingEnabled && <Instruct />}
      <Filters />
    </div>
  );
};

export default Header;
