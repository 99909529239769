import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  deleteProductButton: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
    marginLeft: '10px',

    '&:disabled': {
      color: theme.palette.colors.black,
      backgroundColor: theme.palette.colors.neutrals200,
    },

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals100,
    },
  },
  startIcon: {
    color: theme.palette.colors.black,
  },

  '&:disabled': {
    color: theme.palette.colors.black,
  },

  '&:hover': {
    backgroundColor: theme.palette.colors.neutrals200,
  },

}));

export default useStyles;
