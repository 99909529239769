import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    item: {
      color: theme.palette.grey.pure,
      borderRadius: '4px',
      textAlign: 'center',
      height: '22px',
      width: '22px',
      fontSize: '14px',
      cursor: 'pointer',
    },
    activeItem: {
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.primary.main,
    },
  };
});

export default useStyles;
