import { useQuery } from 'react-query';

import api from '@root/api';

export default function useAiTemplate() {
  const queryKey = ['templates'];

  return useQuery(
    queryKey,
    () => api.templates.getTemplates(),
    { keepPreviousData: true },
  );
}
