import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  select: {
    minWidth: 195,
    minHeight: 28,
    height: 28,
    fontSize: 12,
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  avatarSelected: {
    width: 18,
    height: 18,
    fontSize: 11,
  },
  user: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  placeholder: {
    color: theme.palette.colors.neutrals200,
  },
}));
