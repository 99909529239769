import axios, { AxiosInstance, AxiosResponse, Method } from "axios";

interface IHeaders {
  Accept: string,
  Authorization: string,
  "Content-Type"?: string,
  "User-Agent"?: string,
}

interface IClientParams {
  requestToken?: string,
  contentType?: string,
}

export class HttpClient {
  private getToken: () => Promise<string> = () => Promise.resolve("");
  private baseUrl: string;
  private packageVersion: string;
  private customHeaders: Record<string, string>;
  private errorInterceptor: ((err: Error) => Promise<void>) | null;
  private onAfterRequest?: (({ response, options }: { response: AxiosResponse, options: any }) => void) | null;

  constructor(baseUrl: string, options: {
    getToken: (() => Promise<string>) | null,
    errorInterceptor: ((err: Error) => Promise<void>) | null,
    onAfterRequest: (({ response, options }: { response: AxiosResponse, options: any }) => void) | null,
    packageVersion: string,
    customHeaders: Record<string, string>,
  }) {
    const {
      getToken,
      errorInterceptor,
      packageVersion,
      onAfterRequest,
    } = options;
    if (getToken) {
      this.getToken = getToken;
    }

    this.baseUrl = baseUrl;
    this.packageVersion = packageVersion;
    this.errorInterceptor = errorInterceptor;
    this.onAfterRequest = onAfterRequest;
    this.customHeaders = options.customHeaders;
  }

  public get(url: string, queryParams?: any, options?: any): Promise<Record<string, unknown>> {
    return this.makeRequest("get", url, queryParams, options);
  }

  public post(url: string, body?: any, options?: any): Promise<Record<string, unknown>> {
    return this.makeRequest("post", url, body, options);
  }

  public put(url: string, body?: any, options?: any): Promise<Record<string, unknown>> {
    return this.makeRequest("put", url, body, options);
  }

  public del(url: string, body?: any, options?: any): Promise<Record<string, unknown>> {
    return this.makeRequest("delete", url, body, options);
  }

  private async getClient({
    requestToken,
    contentType,
  }: IClientParams): Promise<AxiosInstance> {
    const token = await this.getToken();

    const headers: IHeaders = {
      Accept: "application/json",
      Authorization: `Bearer ${requestToken || token}`,
    };

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    if ((typeof process !== "undefined") && (process.release && process.release.name === "node")) {
      headers["User-Agent"] = `node-client/${this.packageVersion}`;
    }

    const client = axios.create({
      headers: {
        ...headers,
        ...this.customHeaders,
      },
      baseURL: this.baseUrl,
      validateStatus: (status): boolean => status >= 200 && status < 300,
    });

    if (this.errorInterceptor) {
      axios.interceptors.response.use((res) => res, this.errorInterceptor);
      client.interceptors.response.use((res) => res, this.errorInterceptor);
    }

    return client;
  }

  private async makeRequest(method: Method, url: string, data?: Record<string, unknown>, options: any = {}): Promise<Record<string, unknown>> {
    const isGet = method === "get";
    const clientParams: IClientParams = {};
    if (data && data.token) {
      clientParams.requestToken = data.token as string;
      delete data.token;
    }

    let returnAxiosResponse = false;
    if (data && data.returnAxiosResponse) {
      returnAxiosResponse = !!data.returnAxiosResponse;
      delete data.returnAxiosResponse;
    }

    const requestOptions: Record<string, unknown> = {
      method,
      params: isGet ? data : null,
      data: !isGet ? data : null,
    };

    const isForm = data && data.append instanceof Function;
    if (!isForm) {
      clientParams.contentType = "application/json";
    }

    const client = await this.getClient(clientParams);

    return client.request({ ...requestOptions, url })
      .then((response: AxiosResponse) => {
        if (this.onAfterRequest instanceof Function) {
          this.onAfterRequest({ response, options });
        }
        if (response) {
          if (response.status >= 200 && response.status < 300) {
            return returnAxiosResponse ? response : (response.data || {});
          }
        }

        return response;
      });
  }
}