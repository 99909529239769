import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useStyles from '@root/views/Team/TeamManagement.styles';

import ActionCardsRow from './ActionCardsRow';

const TeamPlanUnavailable = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <span className={classes.titleText}>Team</span>
        <Button
          onClick={() => history.push('/profile/plans')}
          size="medium"
          className={classes.addMemberButton}
        >
          Upgrade To Team Plan
        </Button>
      </div>
      <div className={classes.membersLimitLabel}>
        <p>
          Team plans are a way for users to collaboratively use
          Copysmith within their organization.
        </p>
        <p>
          If you are expecting an invite from an another user,
          have them invite you in the Team tab. You should be on the free
          trial to accept team invites.
        </p>
      </div>
      <ActionCardsRow classes={classes} isHorizontal />
    </div>
  );
};

export default TeamPlanUnavailable;
