import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useShareFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id, data }) => api.files.share(_id, data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const file = response;

        queryClient.invalidateQueries(['files.getById', file._id]);

        queryClient.setQueryData(['files.getById', file._id], file);
      },
    },
  );
}
