import React from 'react';

import { DEPRECATEDTextAreaInputAtom } from '@root/components/Fields';

const MultilineStringField = ({
  field: {
    name,
    placeholder,
    required,
    wordCount,
    minimum,
    quality,
    creditCosts,
    limit,
  },
  onChange,
  fieldValue,
  isSmall,
  disabled,
  short,
}) => {
  let maxLength = limit;
  if (quality) {
    maxLength = quality.limit;
  }

  return (
    <DEPRECATEDTextAreaInputAtom
      propObject={{
        name,
        type: 'text',
        onChange,
        onBlur: () => null,
        placeholder,
        value: fieldValue,
      }}
      maxLength={maxLength}
      minLength={minimum}
      isWordCount={wordCount}
      required={required}
      qualitySettings={quality}
      creditCostsThresholds={creditCosts}
      isSmall={isSmall}
      disabled={disabled}
      short={short}
    />
  );
};

export default MultilineStringField;
