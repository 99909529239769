import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useRemoveDocumentExample() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.documentExample.delete(data),
    {
      onSuccess: (response, vars) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const queryKey = ['documentExample.list'];
        const prevData = queryClient.getQueryData(queryKey) || [];

        queryClient.setQueryData(queryKey, () => prevData.filter((r) => r._id !== vars.id));

        queryClient.getQueryCache().findAll('documents.list').forEach(({ queryKey: key, state }) => {
          queryClient.setQueryData(key, state.data.map((doc) => {
            if (!doc.submittedExamples || !doc.submittedExamples[vars.id]) {
              return doc;
            }

            const newExamples = doc.submittedExamples;

            delete newExamples[vars.id];

            return {
              ...doc,
              submittedExamples: newExamples,
            };
          }));
        });
      },
    },
  );
}
