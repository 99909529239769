import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3, 3, 5),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  templateList: {
    margin: '56px 0 0 0',
    width: 'auto',
    maxWidth: 1000,
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
    fontSize: 32,
  },
}));

export default useStyles;
