export default class ModalImage {
  constructor({ data, config, block }) {
    this.data = data;
    this.config = config;
    this.block = block;
  }

  static get toolbox() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-photo"> <path d="M15 8h.01"/> <rect width="16" height="16" x="4" y="4" rx="3"/> <path d="m4 15 4-4a3 5 0 0 1 3 0l5 5"/> <path d="m14 14 1-1a3 5 0 0 1 3 0l2 2"/> </svg>',
      title: 'Image',
    };
  }

  render() {
    this.config.onClick(this.block.id);
    return document.createDocumentFragment(); // return empty node
  }

  save() {
    return this.data;
  }

  static get isReadOnlySupported() {
    return true;
  }
}
