import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './PageItem.styles';

const PageItem = ({ pageNum, isActive, onPageSelect }) => {
  const classes = useStyles();

  return (
    <div onClick={onPageSelect} className={clsx(classes.item, { [classes.activeItem]: isActive })}>
      <p>{pageNum}</p>
    </div>
  );
};

PageItem.propTypes = {
  pageNum: PropTypes.number.isRequired,
  onPageSelect: PropTypes.func,
  isActive: PropTypes.bool,
};

PageItem.defaultProps = {
  onPageSelect: () => {},
  isActive: false,
};

export default PageItem;
