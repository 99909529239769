import React from 'react';

export default () => (
  <svg
    height="12"
    width="12"
    version="1.1"
    viewBox="0 0 232.305 232.305"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M209.869,11.48c1.742,2.108,1.513,4.354,0.367,5.959c0,2.796-1.1,5.775-1.879,8.296
        c-0.962,2.979-2.063,6.508-4.308,8.8c0,1.971-0.963,3.896-3.254,4.354c-16.18,3.208-24.751,15.767-29.655,31.213
        c6.234,0.413,12.467,0.871,18.517,1.467c2.567,0.229,3.942,1.925,4.263,3.758c0.779,1.054,1.192,2.292,0.917,3.713
        c-1.925,8.709-5.317,16.409-9.213,24.384c-0.779,1.604-2.842,2.704-4.583,2.613c-5.317-0.229-10.725-0.321-16.225-0.458
        c-0.871,8.984-1.513,17.509-2.521,24.476c-3.254,22.917-3.896,50.143-16.867,70.264c-11.046,17.096-29.288,23.055-48.493,26.034
        c-20.03,3.071-53.168,10.359-72.373,2.246c-1.879-0.779-3.438-2.2-3.392-4.446c0.183-10.359,5.684-21.496,9.625-30.938
        c0.825-1.971,2.704-2.521,4.171-2.017c0.367-0.138,0.733-0.183,1.192-0.183c21.176,0.825,46.797,4.125,63.069-12.834
        c11.963-12.467,14.53-32.818,16.913-49.135c1.237-8.296,1.879-16.775,2.521-25.301c-7.196-0.688-14.346-1.696-21.267-3.163
        c-1.192,0.229-2.383-0.183-2.979-1.558c-3.346-7.883-5.592-15.584-6.921-24.109c-0.046-0.413,0-0.825,0.046-1.238
        c-0.55-1.65,0.596-3.942,2.704-4.079c0.367,0,0.733-0.046,1.054-0.046c0.458-0.092,0.871-0.092,1.329-0.046
        c9.763-0.55,19.755-0.825,29.838-0.871c1.283-6.967,3.071-13.842,5.683-20.534C138.642,21.243,183.193-20.329,209.869,11.48z"
      />
    </g>
  </svg>
);
