import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '240px',
    height: '230px',
    border: `1px solid ${theme.palette.colors.grey}`,
    borderRadius: '6px',
    backgroundColor: '#fff',
    padding: '1rem',
    cursor: 'pointer',
    fontFamily: '"Inter", sans-serif',
    color: '#000',
    '&:hover': {
      backgroundColor: '#eeebfd',
      border: '1px solid #693ce6',
    },
    transition: 'all 250ms',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  topRight: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  genType: {
    display: 'block',
    fontWeight: '400',
    color: theme.palette.colors.darkGrey,
    fontSize: '0.75rem',
  },
  title: {
    display: 'block',
    fontSize: '1rem',
    width: '90%',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '0.9rem',
  },
  genSize: {
    display: 'block',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    color: theme.palette.colors.darkGrey,
  },
  lastModifiedText: {
    display: 'block',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    color: '#aaa',
    marginBottom: '0.25rem',
  },
  lastModifiedDate: {
    display: 'block',
    fontSize: '0.875rem',
    verticalAlign: 'bottom',
  },
  moreOptions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontWeight: 700,
    fontSize: '1.375rem',
    lineHeight: '1.375rem',
    color: theme.palette.colors.darkGrey,
  },
  footerContainer: {
    flex: 1,
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  lightPurple: {
    color: '#693CE6',
  },
  purple: {
    color: theme.palette.primary.main,
  },
  bookmarkIcon: {
    fontSize: '1.5rem',
  },
  isDraggingOver: {
    background: '#eee',
  },
}));
