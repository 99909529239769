import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  filterBarContainer: {
    gridColumn: '1/-1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  searchField: {
    width: '100%',
    maxWidth: '656px',
  },

  searchFieldSmall: {
    width: 'unset',
    marginLeft: 'auto',
  },

  createButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    textTransform: 'capitalize',
  },

  startIcon: {
    width: 16,
    height: 16,
    strokeWidth: 3,
  },
}));

export default useStyles;
