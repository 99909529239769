import React from 'react';
import { Link as UiLink } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

import {
  getAlmostExpiredCardAlertHiddenState,
  setAlmostExpiredCardAlertHiddenState,
} from '@root/services/localStorage.service';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

const linkContent = 'Update your billing information';
const AlertContent = ({ title, onClick }) => (
  <>
    {title}
    {' '}
    {onClick ? (
      <UiLink onClick={onClick}>
        {linkContent}
      </UiLink>
    ) : (linkContent)}
    {' '}
    now to avoid any disruptions to your team.
  </>
);

const useCardAlert = ({
  cardStatus,
  currentUser,
}) => {
  const history = useHistory();

  const { currentExternalSource } = externalSourcesContext.useExternalSource();
  const billingDisabled = currentExternalSource.profileDropdownDisabledItems?.includes('billing');

  const [alertProps, setAlertProps] = React.useState({});
  const [alertContent, setAlertContent] = React.useState(null);
  const [almostExpiredHidden, setAlmostExpiredHidden] = React.useState(getAlmostExpiredCardAlertHiddenState() === 'true');

  const hideAlmostExpired = () => {
    setAlmostExpiredHidden(true);
    setAlmostExpiredCardAlertHiddenState(true);
  };

  const isAdminOrOwner = currentUser.roles.admin || currentUser.roles.owner;

  const openBilling = () => history.replace('/profile/billing', {
    addCardOpen: true,
  });

  React.useEffect(() => {
    if (!cardStatus) {
      return;
    }

    if (cardStatus === 'valid') {
      setAlertContent(null);
      setAlertProps({});
      return;
    }

    if (cardStatus === 'almostExpired') {
      if (almostExpiredHidden || !isAdminOrOwner) {
        setAlertContent(null);
        setAlertProps({});
        return;
      }
      setAlertProps({
        severity: 'warning',
        icon: false,
        onClose: hideAlmostExpired,
      });
      setAlertContent(
        <AlertContent
          title="Warning! Your credit card is about to expire."
          onClick={!billingDisabled && openBilling}
        />,
      );
    }

    if (cardStatus === 'expired') {
      setAlertProps({
        severity: 'error',
        icon: false,
      });
      setAlertContent(
        <AlertContent
          title="Warning! Your credit card has expired."
          onClick={!billingDisabled && isAdminOrOwner && openBilling}
        />,
      );
    }
  }, [cardStatus, almostExpiredHidden, billingDisabled]);

  if (!alertContent) {
    return null;
  }

  return (
    <Alert {...alertProps}>
      {alertContent}
    </Alert>
  );
};

export default useCardAlert;
