import React from 'react';
import {
  makeStyles,
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import { IconChevronDown } from '@tabler/icons-react';

import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import Checkbox from '@root/components/Checkbox';

import BulkFileOptionsKebab from '../../../IconButtons/BulkFileOptionsKebab';

import useCommonStyles from './tableComponents.styles';

const useStyles = makeStyles((theme) => ({
  thead: {
    border: 'none',

    '& th': {
      zIndex: 5,
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  theadCheckbox: {
    height: 'max-content',
    padding: 0,
  },
  theadRoot: {
    '&$active': {
      fontWeight: '500',
    },
  },
  active: {
    '&$icon': {
      color: theme.palette.colors.neutrals400,
    },
  },
  icon: {
    opacity: 1,
    color: theme.palette.colors.neutrals200,
    height: '16px',
    width: '16px',
    strokeWidth: '3px',
  },
}));

const CustomTableHead = ({
  selectedFileIds,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  availableRowsNum,
  folder,
  files,
  cells,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const { fixedColumn } = currentExternalSource.toggles;
  const numSelected = selectedFileIds.length;

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead classes={{ root: classes.thead }}>
      <TableRow>
        <TableCell
          padding="none"
          classes={{
            root: clsx(commonClasses.controls, commonClasses.cell),
            stickyHeader: clsx({
              [commonClasses.fixedCell]: fixedColumn,
              [commonClasses.fixedCellHeader]: fixedColumn,
              [commonClasses.stickyHeader]: !fixedColumn,
            }),
          }}
        >
          <Checkbox
            checked={availableRowsNum > 0 && numSelected === availableRowsNum}
            indeterminate={numSelected > 0 && numSelected < availableRowsNum}
            onClick={onSelectAllClick}
            disableRipple
            classes={{
              root: clsx(commonClasses.checkbox, classes.theadCheckbox),
              input: classes.theadCheckbox,
              checked: commonClasses.checked,
              indeterminate: commonClasses.indeterminate,
            }}
          />

        </TableCell>

        {cells.map((
          { id, label, width, align, sortEnabled },
          index,
        ) => (
          <TableCell
            key={id}
            align={align}
            padding="none"
            sortDirection={sortEnabled && (orderBy === id) ? order : false}
            classes={{
              root: clsx(commonClasses.controls, commonClasses.cell),
              stickyHeader: clsx({
                [commonClasses.stickyHeader]: !!index || !fixedColumn,
                [commonClasses.fixedCellHeader]: fixedColumn,
                [commonClasses.fixedCell]: fixedColumn && !index,
                [commonClasses.thirdCell]: fixedColumn && !index,
              }),
            }}
            style={{ width }}
          >
            {!sortEnabled && (
              <div classes={{
                active: classes.active,
                icon: classes.icon,
                root: classes.theadRoot,
              }}
              >
                {label}
              </div>
            )}

            {sortEnabled && (
              <TableSortLabel
                IconComponent={IconChevronDown}
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={createSortHandler(id)}
                classes={{
                  active: classes.active,
                  icon: classes.icon,
                  root: classes.theadRoot,
                }}
              >
                {label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}

        {/* Bookmark cell */}
        <TableCell
          padding="none"
          align="center"
          className={commonClasses.cell}
          classes={{
            root: clsx(commonClasses.controls),
            stickyHeader: clsx({
              [commonClasses.stickyHeader]: !fixedColumn,
              [commonClasses.fixedCellHeader]: fixedColumn,
              [commonClasses.fixedCell]: fixedColumn,
              [commonClasses.secondCell]: fixedColumn,
            }),
          }}
        />

        {/* Bulk actions cell */}
        <TableCell
          padding="none"
          align="center"
          className={commonClasses.cell}
          classes={{
            root: clsx(commonClasses.controls),
            stickyHeader: commonClasses.stickyHeader,
          }}
        >
          <BulkFileOptionsKebab
            folder={folder}
            files={files}
            selectedFileIds={selectedFileIds}
            variant="list"
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
