import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import icon from '@root/assets/Frase_icon.png';

const useStyles = makeStyles(() => ({
  icon: {
    height: 30,
    width: 30,
  },

}));

const FraseIcon = ({ setShowFrase, showFrase }) => {
  const classes = useStyles();

  return (
    <div
      type="button"
      onClick={() => {
        setShowFrase(!showFrase);
      }}
    >
      <img
        alt="FraseDrawer"
        className={classes.icon}
        src={icon}
      />
    </div>

  );
};

export default FraseIcon;
