// Temporarily disable camelcase due to Landing Page fields
/* eslint-disable camelcase */
import { escapeCSV } from './functions';

const GOOGLE_HEADLINE_CHARS = 30;
const GOOGLE_DESCRIPTION_CHARS = 90;
const GOOGLE_AD_LABEL = 'copysmith_generated';

export default class GoogleCSVExporter {
  constructor() {
    this.csv_string = '"Ad type","Label","Headline 1","Headline 2","Headline 3","Description 1","Description 2","Final URL"\r\n';
  }

  addAdvertSplit(headlines, descriptions, final_url) {
    let headline_1; let headline_2; let headline_3; let description_1; let
      description_2;
      // try to split headlines at periods or vertical lines
    const test_headlines = headlines.split(/[|.\n]/);
    if (
      test_headlines.length <= 3
        && test_headlines.length > 0
        && test_headlines.every(
          (headline) => headline.length <= GOOGLE_HEADLINE_CHARS,
        )
    ) {
      headline_1 = test_headlines[0].trim();
      headline_2 = test_headlines[1]?.trim() || '';
      headline_3 = test_headlines[2]?.trim() || '';
    } else {
      // otherwise just slice array
      headline_1 = headlines.slice(0, GOOGLE_HEADLINE_CHARS);
      headline_2 = headlines.slice(
        GOOGLE_HEADLINE_CHARS,
        2 * GOOGLE_HEADLINE_CHARS,
      );
      headline_3 = headlines.slice(2 * GOOGLE_HEADLINE_CHARS);
    }
    const test_descriptions = descriptions.split(/[|.\n]/);
    if (
      test_descriptions.length <= 2
        && test_descriptions.length > 0
        && test_descriptions.every((desc) => desc.length <= GOOGLE_DESCRIPTION_CHARS)
    ) {
      description_1 = test_descriptions[0]?.trim();
      description_2 = test_descriptions[1]?.trim() || '';
    } else {
      description_1 = descriptions.slice(0, GOOGLE_DESCRIPTION_CHARS);
      description_2 = descriptions.slice(GOOGLE_DESCRIPTION_CHARS);
    }
    this.addAdvert(
      headline_1,
      headline_2,
      headline_3,
      description_1,
      description_2,
      final_url,
    );
  }

  addAdvert(
    headline_1,
    headline_2,
    headline_3,
    description_1,
    description_2,
    final_url,
  ) {
    this.csv_string
        += `"Expanded text ad","${GOOGLE_AD_LABEL}","${escapeCSV(
        headline_1,
      )}","${escapeCSV(headline_2)}`
        + `","${escapeCSV(headline_3)}","${escapeCSV(description_1)}","${escapeCSV(
          description_2,
        )}","`
        + `${escapeCSV(final_url)}"\r\n`;
  }

  getString() {
    return this.csv_string;
  }
}
