import React, { useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorIcon from '@material-ui/icons/Error';

import useFileMenuItems from '@root/views/Dashboard/hooks/useFileMenuItems';
import { BookmarkFile } from '@root/views/Dashboard/components/IconButtons';
import Tooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import { navToFileMeta } from '@root/views/Dashboard/helpers';
import Avatar from '@root/components/Avatar';
import ItemTypes from '@root/utils/dndItemTypes';
import DateUtil from '@root/utils/date.util';
import { getParamByName } from '@root/utils/uri';
import TemplatesContext from '@root/resources/templates/templates.context';
import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import BoosteAltTooltipContent from '@root/components/tooltips/BoosteAltTooltipContent';
import WorkflowStatus from '@root/views/Dashboard/components/FolderContentNew/DashListViewNew/components/WorkflowStatus';

import useStyles from './FileCard.styles';

const BOOSTE_LEGACY_TEMPLATE_TYPES = [
  'articles',
  'blogPost',
];

const FileCard = ({
  file, isDraggable,
}) => {
  const {
    title,
    generationSize,
    _id,
    liked,
    updatedOn,
    templateType,
    workflowStatus,
    assignedTo,
  } = file;

  const [hover, setHover] = useState(false);
  const fileMenu = useFileMenuItems(file);
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const {
    isTemplateDisabled,
    toggles: {
      showExternalSourceTooltip,
    },
  } = currentExternalSource;

  const isDisabled = isTemplateDisabled(file.templateType);

  const drag = useDrag({
    type: ItemTypes.FILE_CARD,
    item: { fileId: _id },
  })[1];
  const ref = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const { locationId } = useParams();

  const location = useLocation();
  const selected = getParamByName('selected', location);
  const isWorkflow = selected === 'workflow';
  const { currentUser: user } = AuthContext.useAuth();

  const { pathname = '' } = history.location;
  const uncategorizedFolder = (pathname.includes('Home_') || pathname.includes('shared_uncategorized_')) && 'Uncategorized';
  const bookmarkFolder = pathname.includes('bookmarks') && 'Bookmarks';
  const workflowFolder = pathname.includes('workflow') && 'Workflow';
  const prevLinkSelected = locationId || uncategorizedFolder || bookmarkFolder || workflowFolder || 'allFiles';

  const isBoosteLegacyTemplateType = BOOSTE_LEGACY_TEMPLATE_TYPES.includes(templateType);

  const { getTemplate } = TemplatesContext.useTemplates();
  const { title: generationTypeTitle, slug } = getTemplate(templateType);

  if (isDraggable) {
    drag(ref);
  }

  const renderAssignedTo = () => {
    if (!assignedTo) {
      return null;
    }
    const { userId, displayName, email } = assignedTo;
    if (userId === user._id) {
      return <span className={classes.youHighlight}>You</span>;
    }
    if (displayName) {
      return (
        <>
          <Avatar
            popperEnabled={false}
            name={displayName}
            userId={userId}
          />
          <span>{displayName}</span>
        </>
      );
    }
    return email;
  };

  const isTooltipShowed = isDisabled && showExternalSourceTooltip;

  return (
    <Tooltip
      wrapped
      content={isTooltipShowed && <ExternalSourceText />}
      placement="top"
      interactive
    >
      <div
        className={clsx(classes.root, {
          [classes.disabled]: isDisabled,
        })}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={ref}
        onClick={navToFileMeta(file, history, slug, {
          prevLinkSelected,
          prevPathName: pathname,
        })}
      >
        <div className={classes.container}>

          <Tooltip
            wrapped
            content={isBoosteLegacyTemplateType
                  && <BoosteAltTooltipContent templateName={title} />}
            placement="bottom"
            interactive
          >
            <div className={classes.genType}>
              {isBoosteLegacyTemplateType && <ErrorIcon style={{ color: 'orangered' }} />}
              {generationTypeTitle}
            </div>
          </Tooltip>
          <div
            className={clsx(classes.title, { [classes.lightPurple]: hover })}
          >
            {title}
          </div>

          <div className={classes.main}>
            {!isWorkflow && (
              <div className={classes.genSize}>
                {generationSize}
                {' '}
                generations
              </div>
            )}
            <div className={classes.assignedTo}>
              {renderAssignedTo()}
            </div>
            <WorkflowStatus status={workflowStatus} small />
          </div>

          <div className={classes.footerContainer}>
            <div className={classes.footer}>
              <div className={classes.lastModifiedText}>
                Last Modified
              </div>
              <div className={classes.lastModifiedDate}>
                {DateUtil.getRelativeWithTime(updatedOn)}
              </div>
            </div>
          </div>
          <div className={classes.moreOptions}>
            <KebabMenu isDisabled={isDisabled} menuItems={fileMenu} />
          </div>
          <div className={clsx(classes.topRight, classes.bookmarkIcon)}>
            <BookmarkFile
              isDisabled={isDisabled}
              fileId={_id}
              liked={liked}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default FileCard;
