import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3, 0, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 22,
    color: theme.palette.colors.neutrals600,
  },
  content: {
    marginTop: theme.spacing(3),
    overflow: 'hidden',
    flexGrow: 1,
  },
  description: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.colors.neutrals400,
    margin: theme.spacing(1, 0, 6, 0),
  },
}));

export default useStyles;
