import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    color: theme.palette.colors.darkGrey,
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    marginTop: 20,
  },
  embedded: {
    position: 'relative',
  },
}));
