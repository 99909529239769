import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pillContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 12,
    marginTop: 12,
  },
}));

export default useStyles;
