import React, { useEffect, useMemo } from 'react';

import SearchFilesInput from '@root/views/Dashboard/components/FolderContentNew/SearchFilesInput';
import { selectionTypes, useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import useFolders from '@root/resources/folder/useFolders';
import AuthContext from '@root/resources/auth/auth.context';
import CreateButtonNew from '@root/views/Dashboard/components/CreateButtonNew/CreateButtonNew';

import { FilterSelect, ViewSelect, Filters } from './IconButtons';
import CreateButton from './CreateButton';
import ShareGroup from './ShareFolderButton';
import useStyles from './DashNav.styles';

// TODO: should be replaced with DashNavNew after all QA/user testing
const DashNav = () => {
  const { state: { filters, selected: { type, title } }, dispatch } = useDashboard();

  const { data: folders } = useFolders();
  const { currentUser } = AuthContext.useAuth();
  const [showFilters, setShowFilters] = React.useState(false);

  const { folderId } = filters;
  const classes = useStyles();

  useEffect(() => {
    const newShowFilters = filters.templateTypes.length
    || filters.workflowStatuses.length;

    if (newShowFilters && newShowFilters !== showFilters) {
      setShowFilters(newShowFilters);
    }
  }, [filters]);

  const {
    parentFolder, canShareFolder, selectedTitle, isSharableFolder,
  } = useMemo(() => {
    const selectedFolder = folderId && folders?.find((f) => f.id === folderId);
    const selectedParentFolder = folders?.find((p) => p._id === selectedFolder?.parentFolderId);

    let selectedFolderTitle;

    if (type === selectionTypes.FOLDER && selectedFolder) {
      selectedFolderTitle = selectedFolder.title;
    }

    return {
      parentFolder: selectedParentFolder,
      canShareFolder: !selectedFolder?.home,
      selectedTitle: selectedFolderTitle,
      isSharableFolder: selectedFolder?.unifiedAcl?.length > 1,
    };
  }, [folderId, folders, type]);

  const showShareButton = (type === selectionTypes.PROJECT || type === selectionTypes.FOLDER)
    && canShareFolder;

  const handleSelectFolder = () => dispatch({
    type: actions.SELECT_FOLDER,
    payload:
      {
        title: parentFolder?.title,
        folderId: parentFolder?._id,
      },
  });

  const parentFolderTitle = parentFolder?.home ? 'Home' : parentFolder?.title;
  const folderTitle = selectedTitle || title;
  const newCreateButton = currentUser.featureToggles.newEditor && currentUser.featureToggles.newCreateButton;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.selectedTitle}>
          {parentFolderTitle
            ? (
              <div>
                <span onClick={handleSelectFolder} className={classes.parentFolderLink}>
                  {parentFolderTitle}
                </span>
                {' '}
                /
                {' '}
                {folderTitle}
              </div>
            ) : folderTitle}
        </div>
        <div className={classes.buttonContainer}>
          <SearchFilesInput />
          <FilterSelect onClick={() => setShowFilters(!showFilters)} opened={showFilters} />

          {showShareButton && <ShareGroup showFeaturePreview itemId={folderId} />}

          <ViewSelect />

          {newCreateButton && (
            <CreateButtonNew />
          )}

          {!newCreateButton && (
            <CreateButton isSharableFolder={isSharableFolder} />
          )}
        </div>
      </div>

      {showFilters && <Filters />}
    </div>
  );
};

export default DashNav;
