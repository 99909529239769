import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import copy from 'clipboard-copy';
import { Button, DialogActions } from '@material-ui/core';

import MagicLoading from '@root/components/Loading/MagicLoading';
import Loading from '@root/components/Loading/Loading';
import AuthContext from '@root/resources/auth/auth.context';
import EmptyState from '@root/views/File/components/EmptyState';
import Notification from '@root/components/shared/alerts/Notification';
import documentResource from '@root/resources/document';
import fileResource from '@root/resources/file';
import aiResource from '@root/resources/ai';
import folderResource from '@root/resources/folder';
import Modal from '@root/components/modals/Modal';
import RootLayout from '@root/views/Layout/RootLayout';
import DoubleChevronRight from '@root/assets/double-chevron-right.svg';
import uiNotificationService from '@root/services/uiNotification.service';
import EditorNav from '@root/components/EditorNav/LegacyEditorNav';
import Checkbox from '@root/components/Checkbox';
import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import useDocumentContent from '@root/views/File/hooks/useDocumentContent';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import BoosteAltTooltipContent from '@root/components/tooltips/BoosteAltTooltipContent';
import TemplateDeletedTooltipContent from '@root/components/tooltips/TemplateDeletedTooltipContent';
import fileContext from '@root/resources/file/file.context';
import SourceGeneration from '@root/views/File/components/SourceGeneration';
import InFileSidebar from '@root/views/File/components/InFileSidebar/InFileSidebar';
import GenerateButtonWithCap from '@root/views/File/components/GenerateButtonWithCap';
import FileInputFields from '@root/views/File/components/FileInputFields';
import FileGuide from '@root/views/File/components/FileGuide';
import useStyles from '@root/views/File/File.styles';
import DocumentView from '@root/components/DocumentView';

import FilterSelect from './components/SelectComponents/FilterSelect/FilterSelect';
import Example from './components/Example';
import DocumentControls from './components/DocumentControls';

const BOOSTE_LEGACY_TEMPLATE_TYPES = ['blogPost'];

const File = ({
  integration,
  documentControls = ['selectAll', 'example', 'plagiarismCheck', 'share', 'export', 'settings'],
  disableSelect = false,
  includeBlogCredits = false,
  includeCreditsRight = true,
  mapGenerateButtonProps,
  loadingTime,
  showCustomLayoutExample = false,
  maxRewriteLength = 200,
}) => {
  const { currentUser } = AuthContext.useAuth();
  const {
    fileId,
    template,
    fields,
    errorMessage,
    filePermissions: { canManageDocuments },
    contentRef,

    assignTeamMember,
    assignedToError,
    statusFilter,
    workflowStatusError,

    prevData,
    localFile,
    updateLocalFile,
    handleDataChange,
    saveAndExit,
    autoSave,
    generate,

    isNoDocs,
    filteredDocuments,

    toggleCheckState,
    checkedDocumentsIds,
    isExampleOpen,
    openExample,
    closeExample,

    isMagicLoading,
    atomRefs,

    onTitleChange,
    updateTitle,

    formHidden,
    setFormHidden,

    flaggedDocumentsIds,
    toggleFlagState,
  } = fileContext.useFile();

  const classes = useStyles();
  const history = useHistory();

  const { data: file, refetch: reloadData } = fileResource.useFile(fileId);
  const { data: folder } = folderResource.useFolder(file && file.folderId);
  const { data: parentFolder } = folderResource.useFolder(folder && folder.parentFolderId);

  const { deletedOn: templateDeleted, getWordCounts, getWordCountSecondRow } = template;

  const [lastHighlightedText, setLastHighlightedText] = useState(null);
  const [lastHighlightedTextId, setLastHighlightedTextId] = useState(null);
  const [isRewriterLoading, setIsRewriterLoading] = useState(false);

  const [showChar, setShowChar] = useState(true);

  const [highlightedDocumentIndex, setHighlightedDocumentIndex] = useState(0);
  const [integrationOpen, setIntegrationOpen] = useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

  const { mutateAsync: updateDocument } = documentResource.useUpdateDocument();
  const { mutateAsync: deleteDocument } = documentResource.useDeleteDocument();
  const { mutateAsync: likeDocument } = documentResource.useLikeDocument();
  const { mutateAsync: generateRewriterContent } = aiResource.useGenerateContentNow();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const hasTeamPermissions = currentUser.permissions.teams;

  const { templateType } = template;
  const isBoosteLegacyTemplateType = BOOSTE_LEGACY_TEMPLATE_TYPES.includes(templateType);

  const { hideInfileSidebar, formOverlapped } = currentExternalSource.toggles;

  const isCustomTemplate = templateType.includes('custom_');

  const {
    data: docsData,
  } = documentResource.useDocuments({ fileId });

  const documents = docsData || [];

  useBeforeUnload({
    when: (flaggedDocumentsIds.length && !localFile?.assignedToId)
      || (localFile?.assignedToId && !localFile?.workflowStatus),
    message: 'Are you sure you want to leave?',
  });

  useBeforeUnload({
    when: flaggedDocumentsIds.length && !localFile?.assignedToId,
    message: 'Are you sure you want to leave?',
  });

  useEffect(() => {
    if (integration?.formHidden) {
      setFormHidden(false);
    }
  }, [integration?.formHidden]);

  const [modalData, setModalData] = useState(null);
  const dropModalData = async () => {
    setModalData(null);
  };

  const {
    editingDocumentId,
    editDocument,
    expandContent,
    rewriteContent,
    copyDocumentContent,
  } = useDocumentContent({
    generateRewriterContent,
    updateDocument,
    documents,
    setModalData,
    config: template,
    lastHighlightedText,
    lastHighlightedTextId,
    fileId,
    maxRewriteLength,
    isRewriterLoading,
    setIsRewriterLoading,
    setLastHighlightedText,
    classes,
  });

  const onDocumentLiked = (docId) => async () => {
    await likeDocument({ _id: docId, fileId });
  };

  const onDocumentDeleted = (docId) => async () => {
    await deleteDocument({ _id: docId, fileId, batch: false });
  };

  const navigateToShare = () => {
    const shareUrl = `/share/${template.templateType}/${fileId}`;
    const fullUrl = window.location.origin + shareUrl;
    copy(fullUrl).then(() => {
      uiNotificationService.showSuccessMessage('URL copied to clipboard.');
    });
    history.push(shareUrl);
  };

  const backUrl = '/templates';

  const toggleIntegration = () => {
    setIntegrationOpen(!integrationOpen);

    if (!integrationOpen) {
      setFormHidden(true);
    }
  };

  const toggleIntegrationModal = () => {
    setIntegrationModalOpen(!integrationModalOpen);
  };

  const toggleFormVisibility = () => {
    setFormHidden(!formHidden);

    if (formHidden) {
      setIntegrationOpen(false);
    }
  };

  const setLastHighlightedTextData = (docId) => (data) => {
    setLastHighlightedText(data);
    setLastHighlightedTextId(docId);
  };

  // const isAllSelectedFavorited = checkedDocumentsIds
  //   .map((docId) => documents.find((d) => d.id === docId))
  //   .filter((d) => d && !d.liked)
  //   .length === 0;

  let fullDocumentControls = documentControls;
  // TODO add actions to aiTemplate
  if (templateType === 'reviewReplies') {
    fullDocumentControls = fullDocumentControls.concat(['contentExpander', 'contentRewriter']);
  }
  if (template.canDocumentRewrite && canManageDocuments) {
    fullDocumentControls = fullDocumentControls.concat(['contentRewriter']);
  }
  if (template.canDocumentExpand && canManageDocuments) {
    fullDocumentControls = fullDocumentControls.concat(['contentExpander']);
  }
  if (isCustomTemplate) {
    fullDocumentControls = fullDocumentControls.filter((item) => item !== 'example');
  }

  const docs = useMemo(() => {
    return filteredDocuments.map((doc) => (
      <div key={doc._id} className={classes.documentsListContainer}>
        {!disableSelect && (
          <div style={{ marginTop: '40px' }}>
            <Checkbox
              checked={checkedDocumentsIds.includes(doc._id)}
              onChange={toggleCheckState(doc._id)}
            />
          </div>
        )}
        <div className={classes.documentView}>
          <DocumentView
            key={doc.id}
            document={doc}
            fileData={{
              ...prevData.obj,
              ...(localFile && { negativeKeywordArray: localFile.negativeKeywordArray }),
            }}
            selected={checkedDocumentsIds.includes(doc._id)}
            flagged={flaggedDocumentsIds.includes(doc._id)}
            onCopy={copyDocumentContent(doc)}
            onFlag={toggleFlagState(doc._id)}
            onTextSelect={setLastHighlightedTextData(doc._id)}
            editCancellationDisabled={!!modalData}
            wordCount={getWordCounts(doc)}
            wordCountSecondRow={getWordCountSecondRow ? getWordCountSecondRow(doc) : null}
            onMoreLikeThis={template.onMoreLikeThis
              ? () => template.onMoreLikeThis({ generate, canManageDocuments }, doc)
              : null}
            options={{
              showChar,
              canDelete: canManageDocuments,
              canEdit: canManageDocuments,
              canFlag: hasTeamPermissions,
              canLike: true,
              canCopy: true,
              canPersonalized: template.canPersonalize,
              canSubmitExample: template.exampleSubmittingAllowed,
            }}
          />
        </div>
      </div>
    ));
  }, [
    atomRefs,
    documents,
    prevData,
    canManageDocuments,
    checkedDocumentsIds,
    flaggedDocumentsIds,
    editingDocumentId,
    showChar,
    statusFilter,
    filteredDocuments,
    localFile,
    modalData,
  ]);

  const getDynamicCreditsRange = () => {
    if (!template.creditCostByContentLength) {
      return null;
    }
    return [
      template.creditCostByContentLength.thresholds[0].value,
      template.creditCostByContentLength.thresholds.slice(-1)[0].value,
    ];
  };

  const integrationsCustomForm = React
    .useMemo(() => integration && integration?.renderCustomForm
      && integration.renderCustomForm({
        highlightedDocumentIndex,
        setHighlightedDocumentIndex,
        documents,
        integrationOpen,
        toggleIntegration,
        reloadData,
      }), [integration]);

  if (!localFile || !localFile) {
    return <Loading />;
  }

  if (isMagicLoading) {
    return <MagicLoading loadingTime={loadingTime} />;
  }

  const head = (
    <EditorNav
      includeBlogCredits={includeBlogCredits}
      includeCreditsRight={includeCreditsRight}
      isBeta={template.isBeta}
      file={localFile}
      fileTitle={localFile.fileTitle}
      folder={folder}
      parentFolder={parentFolder}
      saveAndExit={saveAndExit}
      backUrl={backUrl}
      onTitleChange={onTitleChange}
      updateTitle={updateTitle}
      fileTemplateType={localFile.templateType}
    />
  );

  if (integration?.renderCustomLayoutOpen) {
    return (
      <div className={classes.root}>
        {head}
        <div className={clsx(classes.content, classes.contentWithLowCredits)}>
          {integration.renderCustomLayout({ fileTitle: localFile.title })}
        </div>
      </div>
    );
  }

  const canShowFilterSelect = currentUser.permissions.workflowQueue
  && hasTeamPermissions
  && !isNoDocs;

  const filterSelect = canShowFilterSelect && (
    <div className={classes.documentsFilters}>
      <FilterSelect />
    </div>
  );

  if (template.CustomLayout) {
    const props = {
      fileData: localFile,
      fieldsComponent: (
        <FileInputFields
          fields={fields}
          data={localFile}
          onDataChange={handleDataChange}
          templateType={template.templateType}
        />
      ),
      generate,
      config: template,
      copyDocumentContent,
      documents: filteredDocuments,
      canManageDocuments,
      hasTeamPermissions,
      toggleFlagState,
      toggleExampleOpen: openExample,
      flaggedDocumentsIds,
      likeDocument: onDocumentLiked,
      deleteDocument: onDocumentDeleted,
      copyDocument: copyDocumentContent,
      editDocument,
      checkedDocumentsIds,
      editingDocumentId,
      canShowFilterSelect,
      filterSelectComponent: filterSelect,
      emptyComponent: <EmptyState />,
      errorMessage,
      documentControlsOptions: {
        onAssign: assignTeamMember,
        canManageDocuments,
        assignedToId: localFile?.assignedToId,
        assignedToError,
        workflowStatusError,
        workflowStatus: localFile.workflowStatus,
        onFileChange: updateLocalFile,
        isDocumentsFlagged: !!flaggedDocumentsIds.length,
      },
    };
    return (
      <RootLayout>
        <div className={classes.root}>
          {head}
          <div className={clsx(classes.content, classes.contentWithLowCredits)}>
            {!hideInfileSidebar && (
              <InFileSidebar
                autoSave={autoSave}
                file={file}
                folder={folder}
              />
            )}
            <template.CustomLayout {...props} />
          </div>
        </div>
        {(showCustomLayoutExample && isExampleOpen) && (
          <Example onClose={closeExample} />
        )}
      </RootLayout>
    );
  }

  return (
    <RootLayout>
      <div className={classes.root}>
        {head}
        <div className={clsx(classes.content, classes.contentWithLowCredits)}>
          {integration?.renderCustomModal && integration.renderCustomModal({
            integrationModalOpen,
            toggleIntegrationModal,
          })}
          {!hideInfileSidebar && (
            <InFileSidebar
              autoSave={autoSave}
              file={file}
              folder={folder}
            />
          )}
          {/* Form content */}
          <div
            className={clsx({
              [classes.flex]: integration?.renderCustomModal,
              [classes.formHidden]: (formHidden || integrationOpen),
              [classes.formOverlapped]: !(formHidden || integrationOpen) && formOverlapped,
            },
            classes.form, integration?.formClass)}
          >
            {!formHidden && integrationsCustomForm}
            {!integration?.formHidden && (
              <div className={classes.innerForm}>
                <FileGuide />
                {localFile.campaignDocumentId && (
                  <div className="mt-2">
                    <SourceGeneration documentId={localFile.campaignDocumentId} />
                  </div>
                )}
                <FileInputFields
                  fields={fields}
                  data={localFile}
                  onDataChange={handleDataChange}
                  templateType={template.templateType}
                />
                <Notification
                  open={!!errorMessage}
                  type="error"
                  text={errorMessage || 'Error'}
                />
                <div className={classes.generate}>
                  <GenerateButtonWithCap
                    canManageDocuments={canManageDocuments}
                    generateHandler={generate}
                    dynamicCreditsRange={getDynamicCreditsRange()}
                    documentsExist={documents.length > 0}
                    {...(mapGenerateButtonProps && mapGenerateButtonProps(localFile))}
                    numOriginalContent={template.maxGenerations}
                    disabled={isBoosteLegacyTemplateType || templateDeleted}
                    customDisableTooltipContent={(isBoosteLegacyTemplateType
                        && <BoosteAltTooltipContent templateName={template.title} />)
                      || (templateDeleted
                        && <TemplateDeletedTooltipContent templateName={template.title} />)}
                  />
                </div>
              </div>
            )}
            {!(integrationsCustomForm && integration?.formHidden && !formHidden) && (
              <div
                className={clsx(classes.chevronIcon, {
                  [classes.rotated]: !formHidden && !integrationOpen,
                })}
                onClick={toggleFormVisibility}
              >
                <img src={DoubleChevronRight} alt="collapse" />
              </div>
            )}
          </div>

          {/* Left side documents */}
          <div
            className={clsx(classes.documents, {
              [classes.documentsOverlapped]: !(formHidden || integrationOpen) && formOverlapped,
            })}
            ref={contentRef}
          >
            <DocumentControls
              actions={!isNoDocs ? fullDocumentControls : undefined}
              setShowChar={!isNoDocs && setShowChar}
              navigateToShare={!isNoDocs && navigateToShare}
              toggleIntegration={!isNoDocs && toggleIntegration}
              expandContent={!isNoDocs && expandContent}
              rewriteContent={!isNoDocs && rewriteContent}
              maxRewriteLength={!isNoDocs && maxRewriteLength}
              isRewriterLoading={!isNoDocs && isRewriterLoading}
              disableSelect={isNoDocs || disableSelect}
              lastHighlightedText={lastHighlightedText?.text}
              integration={integration}
              integrationProps={{
                toggleIntegration,
                toggleIntegrationModal,
                checkedDocumentsIds,
                fileTitle: localFile.title,
              }}
            />
            <div className={classes.documentsBody}>
              <div className={clsx(classes.documentsList, { [classes.noDocs]: isNoDocs })}>
                {filterSelect}
                {docs}
                <EmptyState />
              </div>
              {integration?.renderSideComponent && integration.renderSideComponent({
                highlightedDocumentIndex,
                setHighlightedDocumentIndex,
                documents,
                integrationOpen,
                toggleIntegration,
              })}
            </div>
          </div>
        </div>
      </div>
      {modalData && (
        <Modal onClose={dropModalData}>
          {modalData.content}
          <DialogActions className={classes.modalActions}>
            {modalData.action && (
              <Button onClick={modalData.action.exec}>
                {modalData.action.label}
              </Button>
            )}
            <Button onClick={dropModalData}>
              Cancel
            </Button>
          </DialogActions>
        </Modal>
      )}
      {isExampleOpen && (
        <Example onClose={closeExample} />
      )}
    </RootLayout>
  );
};

export default File;
