import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paymentInfo: {
    color: theme.palette.colors.primary,
    backgroundColor: lighten(theme.palette.colors.primary, 0.95),
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.primary}`,
    padding: '8px 16px',
  },
  paymentInfoTitle: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '39px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 4,
    marginBottom: 8,
    borderBottom: `1px solid ${theme.palette.colors.primaryFaded}`,
  },
  paymentInfoRows: {
  },
  paymentInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 12,
    lineHeight: '200%',
  },
}));

export default useStyles;
