import React, { useState } from 'react';
import { TableContainer, TableBody, Table } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useGetCatalog from '@root/resources/catalog/useGetCatalog';
import useSearchCatalogItem from '@root/resources/catalogItem/useSearchCatalogItem';
import useLimits from '@root/resources/billing/useLimits';
import useCurrentPCMJob from '@root/resources/bulkGenerate/useCurrentPCMJob';
import useModalState from '@root/utils/hooks/useModalState';

import AddProductButton from './Components/AddProductButton';
import ProductRow from './Components/Table/ProductRow';
import ProductTableHeader from './Components/Table/ProductTableHeader';
import tableStyles from './Components/Table/table.styles';
import useStyles from './Catalog.style';
import ProductSearch from './Components/ProductSearch';
import { AddProductModal } from './Components/AddProductModal';
import { AddProductFromURLModal } from './Components/AddProductFromURLModal';
import { AddSingleProductModal } from './Components/AddSingleProductModal';
import { GenerateSingleProductModal } from './Components/GenerateSingleProductModal';
import { SingleProductModal } from './Components/SingleProductModal';
import NoCatalogItems from './Components/NoCatalogItems';
import BulkCopyButton from './Components/BulkCopyButton';
import { BulkCopyModal } from './Components/BulkCopyModal';
import DownloadCSVButton from './Components/DownloadCSVButton';
import DeleteProductButton from './Components/DeleteProductButton';
import DeleteProductModal from './Components/DeleteProductModal';
import { RenameCatalogModal } from './Components/RenameCatalogModal';
import RenameCatalogButton from './Components/RenameCatalogButton';

const Catalog = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const tableClasses = tableStyles();
  const { isLoading, data } = useGetCatalog({ id });
  const { isLoading: isItemsLoading, data: itemData } = useSearchCatalogItem({ catalogId: id, searchQuery });
  const { isLoading: isLimitsLoading, data: { bulkGenerationRows, limit, productsRemaining } = {} } = useLimits();
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  useCurrentPCMJob();
  const [isDeleteOpen, openDelete, closeDelete] = useModalState(false);

  const toggleSelectedItem = (itemId) => (event) => {
    event.stopPropagation();
    if (selectedItemIds.includes(itemId)) {
      setSelectedItemIds(selectedItemIds.filter((selectedId) => selectedId !== itemId));
      setSelectedAll(selectedItemIds.length - 1 === itemData?.catalogItems.results.length);
    } else {
      setSelectedItemIds([...selectedItemIds, itemId]);
      setSelectedAll(selectedItemIds.length + 1 === itemData?.catalogItems.results.length);
    }
  };

  const selectAll = (event) => {
    event.stopPropagation();
    if (selectedAll) {
      setSelectedItemIds([]);
      setSelectedAll(false);
    } else {
      const ids = itemData?.catalogItems.results.map((item) => {
        return item._id;
      });
      setSelectedItemIds(ids);
      setSelectedAll(true);
    }
  };

  const resetSelectedItems = () => {
    setSelectedItemIds([]);
  };

  return (
    <div className={classes.page}>

      <div className={classes.filterBar}>
        <h1 className={classes.title}>
          {isLoading ? 'Loading' : data.catalog.title}
          {!isLoading && <RenameCatalogButton />}
        </h1>
        <div className={classes.actionSide}>
          <ProductSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          {isLimitsLoading || <AddProductButton productsRemaining={productsRemaining} />}
        </div>
      </div>
      <div className={classes.filterBar}>
        <div className={classes.actionSide}>
          <div className={classes.selected}>
            {selectedItemIds ? selectedItemIds.length : 0}
            &nbsp;selected
          </div>
          <div className={classes.totalProducts}>
            {!isLoading && itemData?.catalogItems ? itemData.catalogItems.results.length : 0}
            &nbsp;products
          </div>
          {!isLimitsLoading && (
            <div className={classes.productsRemaining}>
              {productsRemaining}
            &nbsp;products left
            </div>
          )}
        </div>
        <div className={classes.actionSide}>
          {!isLimitsLoading && bulkGenerationRows !== null && bulkGenerationRows >= 0 && (
            <div className={classes.bulkGenerationRowsRemaining}>
              {bulkGenerationRows}
            &nbsp;bulk generations left
            </div>
          )}
          <div>
            <BulkCopyButton
              bulkGenerationRows={bulkGenerationRows}
              catalogId={id}
              limit={limit}
              selectedItemIds={selectedItemIds}
            />
            {!isLoading && (
              <DeleteProductButton
                onClick={openDelete}
                selectedItemIds={selectedItemIds}
              />
            )}
            {!isLoading && <DownloadCSVButton catalogId={id} title={data.catalog.title} />}
          </div>
        </div>
      </div>
      <div className={classes.divider} />
      {!isItemsLoading && itemData?.catalogItems.results.length > 0 && (
        <TableContainer>
          <Table className={tableClasses.table}>
            <ProductTableHeader allSelected={selectedAll} selectAll={selectAll} />
            <TableBody className={tableClasses.tableBody}>
              {!isItemsLoading && itemData?.catalogItems.results.map((item) => (
                <ProductRow
                  key={`product-${item._id}`}
                  product={item}
                  selected={selectedItemIds.includes(item._id)}
                  toggleSelected={toggleSelectedItem(item._id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isLimitsLoading && !isItemsLoading && itemData?.catalogItems.results.length === 0 && !searchQuery && (
        <NoCatalogItems productsRemaining={productsRemaining} />
      )}

      <AddProductModal />
      <AddProductFromURLModal />
      <AddSingleProductModal />
      <GenerateSingleProductModal />
      <SingleProductModal />
      <BulkCopyModal />
      <RenameCatalogModal />
      {isDeleteOpen && (
        <DeleteProductModal
          catalogId={id}
          onClose={closeDelete}
          resetSelectedItems={resetSelectedItems}
          selectedItemIds={selectedItemIds}
        />
      )}
    </div>
  );
};

export default Catalog;
