import React from 'react';
import {
  Menu, MenuItem, Fade, makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { IconDots } from '@tabler/icons-react';

import AuthContext from '@root/resources/auth/auth.context';
import {
  RENAME_FOLDER_MODAL_ID,
  DELETE_FOLDER_MODAL_ID,
  EDIT_PRESET_MODAL_ID,
  EXPORT_TO_CSV_MODAL_ID,
  MOVE_FOLDER_MODAL_ID,
  SHARE_FOLDER_MODAL_ID,
  NEW_FOLDER_MODAL_ID,
} from '@root/views/Dashboard/components/Modals';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';

const useStyles = makeStyles((theme) => ({
  kebab: {
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.colors.darkGrey,
  },
  sidebarVariant: {
    textAlign: 'center',
    color: theme.palette.colors.neutrals300,
    lineHeight: 'normal',
    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },
  listVariant: {
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    height: '2rem',
    lineHeight: '2rem',
    fontSize: '1.5rem',
  },
  cardVariant: {
    margin: '0',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
  },
  menuIcon: {
    fontSize: '1.2rem',
  },
  paper: {
    maxHeight: '30rem',
  },
}));

const FolderOptionsKebab = ({
  folder,
  variant = '',
  shortened = false,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const folderRoles = currentUser.roles.getFolderRoles(folder);
  const isFolderOwner = folder && folderRoles.owner;
  const isFolderEditor = folder && folderRoles.editor;
  const isSubFolder = folder?.parentFolderId !== null;
  const { newCreateButton } = currentUser.featureToggles;

  const { dispatch } = useGlobalModals();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = !!anchorEl;

  const moveFolderDisabled = folder.home || folder.parentFolderId === null;

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  let folderMenu = [];

  if (isFolderOwner) {
    folderMenu = [
      {
        title: 'Rename',
        disabled: false,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: RENAME_FOLDER_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
      {
        title: 'Edit Presets',
        disabled: false,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: EDIT_PRESET_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
      {
        title: 'Move To',
        disabled: moveFolderDisabled,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: MOVE_FOLDER_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
      {
        title: 'Delete',
        disabled: false,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: DELETE_FOLDER_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
    ];
  }

  if (isFolderEditor || isFolderOwner) {
    if (newCreateButton) {
      folderMenu.unshift({
        title: 'Add folder',
        disabled: isSubFolder,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: NEW_FOLDER_MODAL_ID,
              modalContent: { parentFolderId: folder ? folder._id : null },
            },
          });
        },
      });
    }
    folderMenu.push({
      title: 'Export to CSV',
      disabled: false,
      onClick: () => {
        handleClose();
        dispatch({
          type: actions.OPEN_MODAL,
          payload: {
            modalId: EXPORT_TO_CSV_MODAL_ID,
            modalContent: { folder },
          },
        });
      },
    });
  }

  if (
    (isFolderEditor || isFolderOwner)
    && currentUser.permissions.teams
    && (!folder.parentFolderId || currentUser.featureToggles.newFileSharing)
  ) {
    folderMenu.push({
      title: 'Share with Team',
      disabled: false,
      onClick: () => {
        handleClose();
        dispatch({
          type: actions.OPEN_MODAL,
          payload: {
            modalId: SHARE_FOLDER_MODAL_ID,
            modalContent: { folder },
          },
        });
      },
    });
  }

  if (shortened && isFolderOwner) {
    folderMenu = [
      {
        title: 'Rename',
        disabled: false,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: RENAME_FOLDER_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
      {
        title: 'Edit Presets',
        disabled: false,
        onClick: () => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: EDIT_PRESET_MODAL_ID,
              modalContent: { folder },
            },
          });
        },
      },
    ];
  }

  const showKebabMenu = folderMenu.length > 0;
  if (!showKebabMenu) {
    return null;
  }

  return (
    <div
      className={classes.root}
      onClick={stopEventPropagation}
    >
      <div
        className={clsx(classes.kebab, {
          [classes.cardVariant]: variant === 'card',
          [classes.sidebarVariant]: variant === 'sidebar',
          [classes.listVariant]: variant === 'list',
        })}
        onClick={handleClickOpen}
      >
        <IconDots />
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        {folderMenu.map((item) => (
          <MenuItem
            disabled={item.disabled}
            onClick={item.onClick}
            key={item.title}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>

  );
};

export default FolderOptionsKebab;
