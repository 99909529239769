/* eslint-disable camelcase */
import React from 'react';
import { MenuItem, Slider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CompareArrows from '@material-ui/icons/CompareArrows';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import languages from '@root/utils/allLanguages';
import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { FieldLabel } from '@root/components/form';
import TemplatesContext from '@root/resources/templates/templates.context';

import AliButtons from './components/AliButtons';

const useStyles = makeStyles((theme) => ({
  promptSettingsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  hiddenThumb: {
    visibility: 'hidden',
  },
  languageWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > svg': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const LanguageField = ({
  classes,
  value,
  onChange,
  label,
}) => (
  <TextField
    // className={classes.select}
    variant="outlined"
    select
    value={value}
    onChange={onChange}
    size="small"
    margin="dense"
    SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, getContentAnchorEl: null } }}
    label={label}
    fullWidth
  >
    {languages.map((l) => (
      <MenuItem
        className={classes.menuItem}
        key={l.code}
        value={l.code}
      >
        {l.name}
      </MenuItem>
    ))}
  </TextField>
);

const getNMarks = ({
  config,
  defaultValues,
}) => {
  if (config.nMarks) {
    return config.nMarks;
  }

  if (defaultValues.n >= 3) {
    return [
      { value: defaultValues.n - 2, label: defaultValues.n - 2 },
      { value: defaultValues.n - 1, label: defaultValues.n - 1 },
      { value: defaultValues.n, label: defaultValues.n },
      { value: defaultValues.n + 1, label: defaultValues.n + 1 },
      { value: defaultValues.n + 2, label: defaultValues.n + 2 },
    ];
  }

  return [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
};

const getNProps = ({
  config,
  defaultValues,
}) => {
  if (config.nProps) {
    return config.nProps;
  }

  if (defaultValues.n >= 3) {
    return {
      min: defaultValues.n - 2,
      max: defaultValues.n + 2,
      step: 1,
    };
  }

  return {
    min: 1,
    max: 5,
    step: 1,
  };
};

const PromptSettings = ({
  templateType,
  onChange,
  values,
  config = {},
  showAli = true,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const {
    engine = 'default',
    temperature,
    max_tokens,
    n,
    language,
    outputLanguage,
  } = values;

  const { getTemplate } = TemplatesContext.useTemplates();
  const template = getTemplate(templateType);
  const { aiOptions: defaultValues, hasInstruction } = template;

  const hasAli = !engine || engine !== 'default';

  const maxTokensProps = {
    min: defaultValues.max_tokens * 0.5,
    max: defaultValues.max_tokens * 1.25,
    step: defaultValues.max_tokens * 0.25,
  };

  const nProps = React.useMemo(() => getNProps({
    config,
    defaultValues,
  }));

  const nMarks = React.useMemo(() => getNMarks({
    config,
    defaultValues,
  }));

  const isMaxContentLengthDisabled = currentUser.onFreePlan
  && ['contentRewriter', 'contentImprover'].includes(templateType);

  const tooltipContent = isMaxContentLengthDisabled
  && (
    <>
      Optimize up to 1,200 characters as a time with any plan type.&nbsp;
      <Link to={{
        pathname: '/profile/plans',
      }}
      >
        Click here to subscribe now
      </Link>
      &nbsp;
    </>
  );

  return (
    <div className={classes.promptSettingsRoot}>
      {showAli && (
        <AliButtons
          templateType={template.templateType}
          value={engine}
          onChange={(engineValue) => onChange(
            { name: 'engine', value: engineValue },
          )}
        />
      )}
      {language && outputLanguage && (
        <div className={classes.languageWrap}>
          <LanguageField
            classes={classes}
            onChange={(e) => onChange(
              { name: 'language', value: e.target.value },
            )}
            value={language}
            label="Input Language"
          />
          <CompareArrows />
          <LanguageField
            classes={classes}
            onChange={(e) => onChange(
              { name: 'outputLanguage', value: e.target.value },
            )}
            value={outputLanguage}
            label="Output Language"
          />
        </div>
      )}
      <div>
        <FieldLabel
          label="Creativity (variation)"
          labelTooltip="Lower values will give simpler word choices that are closer to your original inputs and more similar to the other copy versions. Higher values will give more complex language that differs more from your inputs and the other copy variations."
        />
        <Slider
          valueLabelDisplay="auto"
          value={temperature}
          onChange={(e, temperatureValue) => onChange(
            { name: 'temperature', value: temperatureValue },
          )}
          min={0}
          max={1}
          step={0.05}
          marks={[
            { value: 0, label: 'Simple' },
            { value: 1, label: 'Creative' },
          ]}
        />
      </div>

      {hasInstruction && !hasAli && (
        <>
          <SimpleTooltip
            content={tooltipContent}
            wrapped
            interactive
          >
            <div>
              <FieldLabel
                label="Max Content Length"
              />
              <Slider
                valueLabelDisplay="auto"
                value={max_tokens}
                onChange={(e, maxTokensValue) => onChange(
                  { name: 'max_tokens', value: maxTokensValue },
                )}
                min={maxTokensProps.min}
                max={maxTokensProps.max}
                step={maxTokensProps.step}
                classes={{
                  thumb: clsx({ [classes.hiddenThumb]: isMaxContentLengthDisabled }),
                }}
                valueLabelFormat={(value) => `${Math.ceil(value * 5)}`}
                marks={[
                  { value: maxTokensProps.min, label: 'Very short' },
                  { value: maxTokensProps.max, label: 'Long' },
                ]}
                disabled={isMaxContentLengthDisabled}
              />
            </div>
          </SimpleTooltip>
          <div>
            <FieldLabel
              label="Number of Versions"
            />
            <Slider
              valueLabelDisplay="auto"
              value={n}
              onChange={(e, nValue) => onChange(
                { name: 'n', value: nValue },
              )}
              min={nProps.min}
              max={nProps.max}
              step={nProps.step}
              marks={nMarks}
            />
          </div>
        </>
      )}

    </div>
  );
};

export default PromptSettings;
