import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gradientCanvas: {
    position: 'absolute',
    top: '-50%',
    transform: 'translateY: 50%',

    width: '100%',
    height: '200%',

    zIndex: '-1',
    '--gradient-color-1': '#97d6e9',
    '--gradient-color-2': '#523ae7',
    '--gradient-color-3': '#5829e1',
    '--gradient-color-4': '#d3e7b4',
  },

  headerContainer: {
    position: 'relative',
    overflow: 'hidden',

    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '24px',
    flexShrink: 0,

    padding: '32px 0px 48px',
    borderRadius: '8px',
    height: '240px',
  },

  title: {
    fontFamily: 'Monrope, sans-serif',
    fontSize: '64px',
    lineHeight: '72px',
    fontWeight: '800',
    color: theme.palette.colors.white,
  },

  subtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.colors.white,
    maxWidth: '500px',
  },
}));

export default useStyles;
