import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import ApproveIcon from '@material-ui/icons/Check';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';

import aiTemplateResource from '@root/resources/aiTemplate';
import {
  useApproveSubmittedDocumentExample,
  useDocumentExamples,
  useRemoveDocumentExample,
} from '@root/resources/documentExample';

import useStyles from './SubmittedExamplesTab.styles';

const SubmittedExamplesTab = () => {
  const classes = useStyles();
  const params = useParams();

  const { data: { templateType } = {} } = aiTemplateResource.useAiTemplate(params.id);
  const { data: examples = [] } = useDocumentExamples();
  const { mutateAsync: removeDocumentExample } = useRemoveDocumentExample();
  const { mutateAsync: approveDocumentExample } = useApproveSubmittedDocumentExample();

  const submittedExamples = React.useMemo(
    () => examples.filter((e) => e.templateType === templateType && e.type === 'submitted'),
    [examples],
  );

  const removeExample = (id) => () => {
    removeDocumentExample({ id });
  };

  const approveExample = (id) => () => {
    approveDocumentExample({ id });
  };

  const exampleContentMap = React.useMemo(() => {
    const map = {};

    examples.forEach((example) => {
      const content = Object.entries(example.content)
        .reduce((c, [key, value]) => `${c}\n${key}: ${value}`, '').replace(new RegExp('\\r?\\n', 'g'), '<br />');
      map[example._id] = content;
    });

    return map;
  }, [examples]);

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                {`Submitted Examples (${submittedExamples.length} total)`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submittedExamples.map((example) => {
              const remove = removeExample(example._id);
              const approve = approveExample(example._id);
              return (
                <TableRow key={example._id}>
                  <TableCell>
                    <div>
                      <span
                        className={clsx(classes.exampleType, {
                          [classes.exampleTypeGood]: example.submissionData.type === 'good',
                          [classes.exampleTypeBad]: example.submissionData.type === 'bad',
                        })}
                      >
                        {example.submissionData.type.toUpperCase()}
                      </span>
                      &nbsp;
                      Workspace ID:
                      &nbsp;
                      {example.submissionData.workspaceId}
                      User ID:
                      &nbsp;
                      {example.submissionData.userId}
                    </div>
                    <div className={classes.exampleTextWrap}>
                      {/* eslint-disable-next-line react/no-danger */}
                      <div dangerouslySetInnerHTML={{ __html: exampleContentMap[example._id] }} />
                      <div className={classes.actions}>
                        <IconButton
                          size="small"
                          variant="contained"
                          onClick={remove}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          variant="contained"
                          onClick={approve}
                        >
                          <ApproveIcon />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubmittedExamplesTab;
