import { useMutation } from 'react-query';

import api from '@root/api';

import useInvalidateLimits from '../billing/useInvalidateLimits';

export default function useGenerateContentNow() {
  const { invalidateLimits } = useInvalidateLimits();

  return useMutation(
    (data) => api.ai.generateContentNow(data),
    {
      onSuccess: () => invalidateLimits(),
    },
  );
}
