import React, { useState } from 'react';
import { useField } from 'formik';

import { InputField } from '@root/components/form';

import KeywordPill from './KeywordPill';
import useStyles from './KeywordInput.styles';

const KeywordInput = ({ name, label, disabled }) => {
  const [currentInputValue, setCurrentInputValue] = useState('');

  const classes = useStyles();

  const [field, , helpers] = useField(name);
  const { value: arrayValue } = field;
  const { setValue: setArrayValue } = helpers;

  const handleChange = (event) => {
    const targetValue = event.target.value;
    setCurrentInputValue(targetValue);
    event.stopPropagation();
    event.preventDefault();
  };

  const pushCurrentValue = () => {
    if (currentInputValue.length > 0) {
      setArrayValue([...new Set([...arrayValue, currentInputValue])]);
    }
    setCurrentInputValue('');
  };

  const handleBlur = (event) => {
    handleChange(event);
    pushCurrentValue();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      pushCurrentValue();
    }
  };

  const handleRemove = (keyword) => {
    const newArrayValue = arrayValue.filter((item) => item !== keyword);
    setArrayValue(newArrayValue);
  };

  return (
    <div>
      <InputField
        label={label}
        placeholder="Enter ↵"
        onBlur={handleBlur}
        onSubmit={pushCurrentValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={currentInputValue}
        disabled={disabled}
      />
      <div className={classes.pillContainer}>
        {arrayValue.map((keyword) => (
          <KeywordPill
            text={keyword}
            key={keyword}
            onRemove={handleRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default KeywordInput;
