import { useHistory } from 'react-router-dom';
import React from 'react';

import useCreateFile from '@root/resources/file/useCreateFile';
import { redirectToNewEditor } from '@root/resources/file/file.helpers';
import Loading from '@root/components/Loading/Loading';
import RootLayout from '@root/views/Layout/RootLayout';

const withRootLayout = (Component) => (props) => (
  <RootLayout>
    <Component {...props} />
  </RootLayout>
);

const NewEditorRoute = () => {
  const {
    mutateAsync: createFile,
  } = useCreateFile();

  const history = useHistory();

  React.useEffect(async () => {
    await redirectToNewEditor(createFile, {}, history);
  }, []);

  return <Loading />;
};

export default withRootLayout(NewEditorRoute);
