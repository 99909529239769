import React from 'react';
import clsx from 'clsx';
import Select, { components } from 'react-select';

import { colors } from '@root/material.theme';

import useStyles from './FilterSelect.styles';

const { Option } = components;

const customStyles = {
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? 'rgba(82, 58, 231, 0.1)' : null,
    color: 'black',
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'rgba(82, 58, 231, 0.1)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: colors.primary,
    fontWeight: '600',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    // Needs to come from theme.
    borderRadius: '5px',
    height: '18px',
    width: '18px',
    backgroundColor: colors.primary,
    margin: 'auto 5px auto 5px',
    fontSize: '20px',
    ':hover': {
      backgroundColor: '#656565',
    },
  }),
  control: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided, { selectProps }) => ({
    ...provided,
    maxHeight: selectProps.maxHeight ? selectProps.maxHeight : null,
    overflowY: 'auto',
    // paddingRight: "17px",
    // boxSizing: "content-box",
    // width: "100%",
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent' /* make scrollbar transparent */,
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
};

const IconOption = ({ data, ...props }) => {
  const {
    clearValue,
    cx,
    getStyles,
    getValue,
    hasValue,
    innerProps,
    innerRef,
    isDisabled,
    isFocused,
    isMulti,
    isRtl,
    isSelected,
    label,
    options,
    selectOption,
    selectProps,
    setValue,
    theme,
    type,
    value,
  } = props;

  const classes = useStyles();

  return (
    <Option
      clearValue={clearValue}
      cx={cx}
      getStyles={getStyles}
      getValue={getValue}
      hasValue={hasValue}
      innerProps={innerProps}
      innerRef={innerRef}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isMulti={isMulti}
      isRtl={isRtl}
      isSelected={isSelected}
      label={label}
      options={options}
      selectOption={selectOption}
      selectProps={selectProps}
      setValue={setValue}
      theme={theme}
      type={type}
      value={value}
    >
      {data.Icon && (
        <span className={classes.icon}>
          <data.Icon color="#333" size={24} />
        </span>
      )}

      {' '}
      {data.label}
    </Option>
  );
};

/**
 * handleChange - returns a list of filter names (e.g. googleAd)
 * @param {} param0
 */
const FilterSelect = ({
  options,
  maxHeight,
  handleChange,
  placeholder,
  className,
  value,
  isMulti = true,
}) => {
  const classes = useStyles();

  const handleChangeIntercept = (selectedOptions) => {
    if (isMulti) {
      handleChange(selectedOptions ? selectedOptions.map((x) => x.value) : []);
      return;
    }
    handleChange(selectedOptions.value);
  };

  return (
    <Select
      isMulti={isMulti}
      options={options}
      className={clsx(classes.select, className)}
      styles={customStyles}
      components={{ Option: IconOption }}
      placeholder={placeholder}
      onChange={handleChangeIntercept}
      maxHeight={maxHeight}
      value={value}
      isSearchable
    />
  );
};

export default FilterSelect;
