import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import AuthContext from '@root/resources/auth/auth.context';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import useInvites from '@root/resources/invite/useInvites';

import TeamPlanUnavailable from './components/TeamPlanUnavailable';
import TeamPlanLoading from './components/TeamPlanLoading';
import useStyles from './TeamManagement.styles';

const ACTIVE_TAB = {
  DETAILS: 'details',
  MEMBERS: 'members',
};

const TeamManagementLayout = ({ children, activeTab }) => {
  const classes = useStyles();
  const {
    currentUser: user,
  } = AuthContext.useAuth();
  const {
    isFetching: isFetchingMembers,
  } = useTeamMembers({ allMembers: true });
  const { isFetching: isFetchingInvites } = useInvites();

  if (isFetchingInvites || isFetchingMembers) {
    return <TeamPlanLoading />;
  }

  const hasTeamPermissions = user.permissions.teams;

  if (!hasTeamPermissions) {
    return <TeamPlanUnavailable />;
  }

  return (
    <div className={classes.rootRedesigned}>
      <div className={classes.teamsLinksSidebar}>
        <Link to={{
          pathname: '/profile/team-members',
        }}
        >
          <div
            className={clsx(classes.teamsLink, classes.newTeamsLink, {
              [classes.newActiveLink]: activeTab === ACTIVE_TAB.MEMBERS,
            })}
          >
            Team members
          </div>
        </Link>

        {hasTeamPermissions && (user.roles.owner || user.roles.admin) && (
          <Link to={{
            pathname: '/profile/team',
          }}
          >
            <div
              className={clsx(classes.teamsLink, classes.newTeamsLink, {
                [classes.newActiveLink]: activeTab === ACTIVE_TAB.DETAILS,
              })}
            >
              Team details
            </div>
          </Link>
        )}
      </div>
      <div className={classes.contentWrapper}>
        {children}
      </div>
    </div>
  );
};

export default TeamManagementLayout;
export { ACTIVE_TAB };
