import React from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default [
  {
    name: 'Default',
    component: <Button> Base Button</Button>,
  },
  {
    name: 'Secondary Colour',
    component: <Button color="secondary"> Base Button</Button>,
  },
  {
    name: 'Disabled',
    component: <Button disabled> Base Button</Button>,
  },
  {
    name: 'Outlined Variant',
    component: <Button variant="outlined">Outlined Button</Button>,
  },
  {
    name: 'Text Variant',
    component: <Button variant="text" style={{ margin: 0 }}>Text Button</Button>,
  },
  {
    name: 'Icon Variant',
    component: (
      <Button>
        <FontAwesomeIcon
          fixedWidth
          icon="plus"
          className="mr-2"
        />
        <span>With Icon</span>
      </Button>),
  },
  {
    name: 'Large Button',
    component: <Button size="large"> Base Button</Button>,
  },
  {
    name: 'Small Button',
    component: <Button size="small"> Base Button</Button>,
  },
];
