import React from 'react';

import api from '@root/api';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import { escapeText } from '@root/utils/text.utils';

const BlockEditorDocumentsContext = React.createContext({
  documents: null,
  loadDocuments: null,
  isDocumentsLoading: false,

  editableDocumentId: null,
  setEditableDocumentId: null,
  dropEditableDocumentId: null,

  isMagicLoading: false,
  setIsMagicLoading: null,
  generateContent: null,

  handleDocumentContentChange: null,
  deleteDocument: null,
  mergeDocuments: null,

  forceCaretPosition: 0,
  undo: null,
  redo: null,
});

const initialState = {
  documents: [],
  initialDocuments: [],
  history: {
    snapshots: [],
    index: -1,
  },
  editableDocumentId: null,
  isDocumentsLoading: false,
  isMagicLoading: false,
  documentsChangedCharsCounters: {},
  forceCaretPosition: 0,
  deletedDocuments: {},
  restoreDocumentId: null,
  deleteDocumentId: null,
  updateDocumentId: null,
};

const actions = Object.freeze({
  LOAD_DOCUMENTS: 'LOAD_DOCUMENTS',
  GENERATE_DOCUMENTS: 'GENERATE_DOCUMENTS',
  SET_DOCUMENTS_LOADING: 'SET_DOCUMENTS_LOADING',
  SET_MAGIC_LOADING: 'SET_MAGIC_LOADING',
  SET_EDITABLE_DOCUMENT_ID: 'SET_EDITABLE_DOCUMENT_ID',
  DROP_EDITABLE_DOCUMENT_ID: 'DROP_EDITABLE_DOCUMENT_ID',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  MERGE_DOCUMENTS: 'MERGE_DOCUMENTS',
  UNDO: 'UNDO',
  REDO: 'REDO',
  DROP_FIELD: 'DROP_FIELD',
});

const isStringsEqual = (s1, s2) => s1.replace(/\s+</gm, '<') === s2.replace(/\s+</gm, '<');

const pushSnapshot = (history, snapshot) => {
  const newSnapshots = history.snapshots.slice(0, history.index + 1);
  const prevSnapshot = newSnapshots[history.index];

  if (snapshot.type === 'change' && prevSnapshot
    && prevSnapshot.documentId === snapshot.documentId
    && isStringsEqual(prevSnapshot.value, snapshot.value)
  ) {
    return snapshot;
  }

  newSnapshots.push(snapshot);
  history.snapshots = newSnapshots;
  history.index += 1;

  return snapshot;
};

const convertTextToHtml = (text) => {
  return text.split('\n')
    .map((line) => {
      if (!line) {
        return '<p><br></p>';
      }
      if (line.startsWith('<p>')) {
        return line
          .replace(/\s{2,}|^ /gm, ' ')
          .replace(/> /gm, '>');
      }
      return `<p>${line
        .replace(/\s{2,}|^ /gm, ' ')
        .replace(/^ /gm, '')
        .replace(/> /gm, '>')
      }</p>`;
    })
    .join('').replace(/(<p><br><\/p>){0,}$/, '');
};

const findPrevSnapshot = ({
  documents,
  history,
}) => {
  const currentSnapshot = history.snapshots[history.index];
  if (currentSnapshot && currentSnapshot.type === 'merge') {
    return {
      unmergeDocumentsRequired: true,
      dataToUnmerge: currentSnapshot.value,
      index: history.index - 1,
    };
  }

  let snapshot = null;
  let index = history.index - 1;
  let restoreDocumentRequired = false;
  do {
    const tempSnapshot = history.snapshots[index];
    if (!tempSnapshot) {
      break;
    }
    const doc = documents.find((d) => d._id === tempSnapshot.documentId);
    if (!doc) {
      snapshot = tempSnapshot;
      restoreDocumentRequired = true;
      break;
    }
    if (!isStringsEqual(tempSnapshot.value, doc.content)) {
      snapshot = tempSnapshot;
      break;
    }
    index -= 1;
  } while (!snapshot && index >= 0);

  if (snapshot && snapshot.type === 'change' && history.snapshots[index - 1]?.type === 'merge') {
    index -= 1;
  }

  return {
    snapshot,
    restoreDocumentRequired,
    index,
  };
};

const findNextSnapshot = ({
  history,
  documents,
}) => {
  let snapshot = null;
  let index = history.index + 1;
  let deleteDocumentRequired = false;
  let mergeDocumentsRequired = false;
  let dataToMerge = null;

  do {
    const tempSnapshot = history.snapshots[index];
    if (!tempSnapshot) {
      break;
    }

    if (tempSnapshot.type === 'delete') {
      deleteDocumentRequired = true;
      snapshot = tempSnapshot;
      break;
    }

    if (tempSnapshot.type === 'merge') {
      mergeDocumentsRequired = true;
      dataToMerge = tempSnapshot.value;
      snapshot = tempSnapshot;
      break;
    }

    const doc = documents.find((d) => d._id === tempSnapshot.documentId);
    if (!isStringsEqual(tempSnapshot.value, doc.content)) {
      snapshot = tempSnapshot;
      break;
    }
    index += 1;
  } while (!snapshot && index < history.snapshots.length);

  return {
    index,
    snapshot: history.snapshots[index],
    deleteDocumentRequired,
    mergeDocumentsRequired,
    dataToMerge,
  };
};

const restoreDocumentToState = (state, documentId) => {
  const doc = state.deletedDocuments[documentId];

  doc.isNew = false;

  state.documents = [...state.documents, doc]
    .sort(
      (a, b) => state.initialDocuments.findIndex((d) => d._id === a._id)
        - state.initialDocuments.findIndex((d) => d._id === b._id),
    );
};

const deleteDocumentFromState = (state, documentId) => {
  state.documents = state.documents.filter((d) => d._id !== documentId);
};

const actionMap = {
  [actions.LOAD_DOCUMENTS]: (state, action) => {
    const convertedDocs = action.payload.map((d) => ({
      ...d,
      content: convertTextToHtml(d.content),
    }));
    return {
      ...state,
      documents: convertedDocs,
      initialDocuments: convertedDocs,
      history: {
        snapshots: [],
        index: -1,
      },
      editableDocumentId: null,
      isDocumentsLoading: false,
      isMagicLoading: false,
      documentsChangedCharsCounters: {},
      forceCaretPosition: 0,
      deletedDocuments: {},
      restoreDocumentId: null,
      deleteDocumentId: null,
      updateDocumentId: null,
    };
  },
  [actions.GENERATE_DOCUMENTS]: (state, action) => {
    const convertedDocs = action.payload.map((d) => ({
      ...d,
      content: convertTextToHtml(d.content),
    }));
    return {
      ...state,
      documents: [
        ...convertedDocs.map((d) => ({ ...d, isNew: true })),
        ...state.documents,
      ],
      initialDocuments: [
        ...convertedDocs,
        ...state.initialDocuments,
      ],
    };
  },
  [actions.SET_DOCUMENTS_LOADING]: (state, action) => {
    return {
      ...state,
      isDocumentsLoading: action.payload,
    };
  },
  [actions.SET_MAGIC_LOADING]: (state, action) => {
    return {
      ...state,
      isMagicLoading: action.payload,
    };
  },
  [actions.DROP_EDITABLE_DOCUMENT_ID]: (state, action) => {
    if (state.editableDocumentId !== action.payload.editableDocumentId) {
      return state;
    }
    return {
      ...state,
      editableDocumentId: null,
    };
  },
  [actions.SET_EDITABLE_DOCUMENT_ID]: (state, action) => {
    const { editableDocumentId, documents, initialDocuments, history } = state;

    if (action.payload.editableDocumentId === editableDocumentId) {
      return state;
    }

    if (!action.payload.editableDocumentId) {
      return {
        ...state,
        editableDocumentId: null,
      };
    }

    const isLastHistoryIndex = history.snapshots.length === history.index + 1;

    if (isLastHistoryIndex) {
      const doc = documents.find((d) => d._id === editableDocumentId);
      const initDoc = doc && initialDocuments.find((d) => d._id === doc._id);
      if (doc) {
        let lastDocSnapshot = [...history.snapshots].reverse()
          .find((s) => s.documentId === doc._id);
        if (!lastDocSnapshot && doc.content !== initDoc.content) {
          lastDocSnapshot = pushSnapshot(history, {
            documentId: doc._id,
            value: initDoc.content,
            caretPosition: initDoc.content.length,
            type: 'change',
          });
        }
        if (lastDocSnapshot && !isStringsEqual(lastDocSnapshot.value, doc.content)) {
          pushSnapshot(history, {
            documentId: doc._id,
            value: doc.content,
            caretPosition: action.payload.caretPosition,
            type: 'change',
          });
        }
      }

      const nextDoc = documents.find((d) => d._id === action.payload.editableDocumentId);
      if (nextDoc) {
        pushSnapshot(history, {
          documentId: nextDoc._id,
          value: nextDoc.content,
          caretPosition: nextDoc.content.length,
          type: 'change',
        });
      }
    }

    return {
      ...state,
      editableDocumentId: action.payload.editableDocumentId,
      history: { ...history },
    };
  },
  [actions.UPDATE_DOCUMENT]: (state, action) => {
    const { documents, initialDocuments, history, documentsChangedCharsCounters } = state;

    const doc = documents.find((d) => d._id === action.payload.id);

    const contentChange = !!action.payload.data.content;

    if (contentChange && doc.content === action.payload.data.content) {
      return state;
    }

    const changedCharsCount = contentChange
      ? Math
        .abs(doc.content.length - action.payload.data.content.length)
      : 0;
    const counterValue = state.documentsChangedCharsCounters[doc._id] || 0;
    const totalCount = counterValue + changedCharsCount;

    if (totalCount !== counterValue) { // content changed
      const currentSnapshot = history.snapshots[history.index];
      if (currentSnapshot && currentSnapshot.type === 'merge' && currentSnapshot.value.toDoc._id === doc._id) {
        pushSnapshot(history, {
          documentId: doc._id,
          value: doc.content,
          caretPosition: currentSnapshot.caretPosition,
          type: 'change',
        });
      }

      if (totalCount > 5) {
        const initDoc = initialDocuments.find((d) => d._id === doc._id);
        if (!isStringsEqual(initDoc.content, action.payload.data.content)) {
          const documentSnapshotExists = history.snapshots.some((s) => s.documentId === doc._id);
          if (!documentSnapshotExists) {
            pushSnapshot(history, {
              documentId: doc._id,
              value: initDoc.content,
              caretPosition: initDoc.content.length,
              type: 'change',
            });
          }

          pushSnapshot(history, {
            documentId: doc._id,
            value: action.payload.data.content,
            caretPosition: action.payload.caretPosition,
            type: 'change',
          });
        }
      } else {
        history.snapshots = history.snapshots.slice(0, history.index + 1);
      }
    }
    return {
      ...state,
      documents: documents
        .map((d) => (d._id === doc._id ? ({ ...d, ...action.payload.data }) : d)),
      history: { ...history },
      documentsChangedCharsCounters: {
        ...documentsChangedCharsCounters,
        [doc._id]: totalCount > 5 ? 0 : totalCount,
      },
      updateDocumentId: action.payload.forceSaveChange && doc._id,
    };
  },
  [actions.DELETE_DOCUMENT]: (state, action) => {
    const { history, documents, initialDocuments } = state;

    const doc = documents.find((d) => d._id === action.payload.id);
    if (doc) {
      const initDoc = initialDocuments.find((d) => d._id === doc._id);
      let lastDocSnapshot = [...history.snapshots].reverse()
        .find((s) => s.documentId === doc._id);
      if (!lastDocSnapshot) {
        lastDocSnapshot = pushSnapshot(history, {
          documentId: doc._id,
          value: initDoc.content,
          caretPosition: initDoc.content.length,
          type: 'change',
        });
      }
      if (lastDocSnapshot && !isStringsEqual(lastDocSnapshot.value, doc.content)) {
        pushSnapshot(history, {
          documentId: doc._id,
          value: doc.content,
          caretPosition: action.payload.caretPosition,
          type: 'change',
        });
      }
    }

    pushSnapshot(history, {
      documentId: doc._id,
      value: doc.content,
      caretPosition: action.payload.caretPosition,
      type: 'delete',
    });

    return {
      ...state,
      documents: documents
        .filter((d) => d._id !== doc._id),
      history: { ...history },
      deletedDocuments: {
        ...state.deletedDocuments,
        [doc._id]: doc,
      },
      deleteDocumentId: doc._id,
    };
  },
  [actions.MERGE_DOCUMENTS]: (state, action) => {
    const { history } = state;

    const fromDocIndex = state.documents.findIndex((d) => d._id === action.payload.id);
    if (fromDocIndex === 0) {
      return state;
    }

    const fromDoc = state.documents[fromDocIndex];
    const toDoc = state.documents[fromDocIndex - 1];

    pushSnapshot(history, {
      documentId: fromDoc._id,
      value: fromDoc.content,
      caretPosition: 0,
      type: 'change',
    });

    const caretPosition = escapeText(toDoc.content).length;
    const newContent = `${toDoc.content}${fromDoc.content}`;

    pushSnapshot(history, {
      documentId: `${toDoc._id}+${fromDoc._id}`,
      type: 'merge',
      value: {
        fromDoc,
        toDoc,
        newContent,
      },
      caretPosition,
    });

    return {
      ...state,
      history,
      documents: state.documents
        .filter((d) => d._id !== fromDoc._id)
        .map((d) => (d._id === toDoc._id ? { ...d, content: newContent } : d)),
      forceCaretPosition: caretPosition,
      editableDocumentId: toDoc._id,
      deleteDocumentId: fromDoc._id,
      deletedDocuments: {
        ...state.deletedDocuments,
        [fromDoc._id]: fromDoc,
      },
      updateDocumentId: toDoc._id,
    };
  },
  [actions.UNDO]: (state, action) => {
    const { history, initialDocuments, editableDocumentId } = state;

    if (editableDocumentId) {
      const doc = state.documents.find((d) => d._id === editableDocumentId);

      if (doc) {
        const lastSnapshot = history.snapshots[history.index];
        const isLastSnapshotMerge = lastSnapshot
          && lastSnapshot.type === 'merge' && lastSnapshot.value.toDoc._id === doc._id;

        if (!isLastSnapshotMerge) {
          const initDoc = initialDocuments.find((d) => d._id === doc._id);
          const reversedSnapshots = [...history.snapshots].reverse();
          let lastDocSnapshot = reversedSnapshots
            .find((s) => s.documentId === doc._id);

          if (!lastDocSnapshot) {
            lastDocSnapshot = pushSnapshot(history, {
              documentId: doc._id,
              value: initDoc.content,
              caretPosition: initDoc.content.length,
              type: 'change',
            });
          }
          if (lastDocSnapshot && !isStringsEqual(lastDocSnapshot.value, doc.content)) {
            pushSnapshot(history, {
              documentId: doc._id,
              value: doc.content,
              caretPosition: action.payload.caretPosition,
              type: 'change',
            });
          }
        }
      }
    }

    const {
      snapshot,
      index,
      restoreDocumentRequired,
      unmergeDocumentsRequired,
      dataToUnmerge,
    } = findPrevSnapshot({
      history,
      documents: state.documents,
    });

    if (unmergeDocumentsRequired) {
      restoreDocumentToState(state, dataToUnmerge.fromDoc._id);
      return {
        ...state,
        documents: state.documents.map((d) => (d._id === dataToUnmerge.toDoc._id ? {
          ...d,
          content: dataToUnmerge.toDoc.content,
        } : d)),
        history: {
          ...state.history,
          index,
        },
        editableDocumentId: dataToUnmerge.fromDoc._id,
        forceCaretPosition: 0,
        restoreDocumentId: dataToUnmerge.fromDoc._id,
        updateDocumentId: dataToUnmerge.toDoc._id,
      };
    }

    if (!snapshot) {
      return state;
    }

    if (restoreDocumentRequired) {
      restoreDocumentToState(state, snapshot.documentId);
    }

    return {
      ...state,
      documents: state.documents.map((d) => (d._id === snapshot.documentId ? {
        ...d,
        content: snapshot.value,
      } : d)),
      history: {
        ...state.history,
        index,
      },
      editableDocumentId: snapshot.documentId,
      forceCaretPosition: snapshot.caretPosition || snapshot.value.length,
      restoreDocumentId: restoreDocumentRequired && snapshot.documentId,
      updateDocumentId: !restoreDocumentRequired && snapshot.documentId,
    };
  },
  [actions.REDO]: (state) => {
    const {
      snapshot,
      index,
      deleteDocumentRequired,
      mergeDocumentsRequired,
      dataToMerge,
    } = findNextSnapshot(state);

    if (!snapshot) {
      return state;
    }

    if (deleteDocumentRequired) {
      deleteDocumentFromState(state, snapshot.documentId);
      return {
        ...state,
        editableDocumentId: state.editableDocumentId === snapshot.documentId
          ? null
          : state.editableDocumentId,
        history: {
          ...state.history,
          index,
        },
        deleteDocumentId: snapshot.documentId,
      };
    }

    if (mergeDocumentsRequired) {
      deleteDocumentFromState(state, dataToMerge.fromDoc._id);
      return {
        ...state,
        editableDocumentId: dataToMerge.toDoc._id,
        forceCaretPosition: snapshot.caretPosition,
        documents: state.documents.map((d) => (d._id === dataToMerge.toDoc._id ? {
          ...d,
          content: dataToMerge.newContent,
        } : d)),
        history: {
          ...state.history,
          index,
        },
        deleteDocumentId: dataToMerge.fromDoc._id,
      };
    }

    return {
      ...state,
      documents: state.documents.map((d) => (d._id === snapshot.documentId ? {
        ...d,
        content: snapshot.value,
      } : d)),
      history: {
        ...state.history,
        index,
      },
      editableDocumentId: snapshot.documentId,
      forceCaretPosition: snapshot.caretPosition || snapshot.value.length,
      updateDocumentId: snapshot.documentId,
    };
  },
  [actions.DROP_FIELD]: (state, action) => {
    return {
      ...state,
      [action.payload.field]: null,
    };
  },
};

// const reducerWithLogs = (state, action) => {
//   const newState = actionMap[action.type](state, action);
//   console.log(
//     action.type,
//     // action,
//     // JSON.stringify(newState, null, 2),
//     newState.history.snapshots,
//     newState.history.index,
//   );
//   return newState;
// };

const documentsReducer = (state, action) => actionMap[action.type](state, action);

const BlockEditorDocumentsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(documentsReducer, initialState);

  const lastSavedContentRef = React.useRef(null);
  const editableDocumentIdRef = React.useRef(null);
  const realtimeContentRef = React.useRef(null);
  const caretPositionRef = React.useRef(0);

  const { featureTourActive, nextStep } = onboardingContext.useOnboarding();

  React.useEffect(() => {
    const doc = state.documents.find((d) => d._id === state.editableDocumentId);
    if (!doc) {
      return () => {};
    }

    lastSavedContentRef.current = doc.content;
    realtimeContentRef.current = doc.content;

    const intervalId = setInterval(async () => {
      if (lastSavedContentRef.current !== realtimeContentRef.current) {
        await api.documents.update(doc._id, { content: realtimeContentRef.current });
        lastSavedContentRef.current = realtimeContentRef.current;
      }
    }, 5000);

    return async () => {
      clearInterval(intervalId);
      if (lastSavedContentRef.current !== realtimeContentRef.current) {
        await api.documents.update(doc._id, { content: realtimeContentRef.current });
      }
    };
  }, [state.editableDocumentId]);

  const loadDocuments = React.useCallback(async ({ fileId, showLoad = true }) => {
    if (showLoad) {
      dispatch({
        type: actions.SET_DOCUMENTS_LOADING,
        payload: true,
      });
    }
    const { results: docs } = await api.documents.list({ fileId });
    dispatch({
      type: actions.LOAD_DOCUMENTS,
      payload: docs,
    });
    if (showLoad) {
      dispatch({
        type: actions.SET_DOCUMENTS_LOADING,
        payload: false,
      });
    }
  }, []);

  const generateContent = React.useCallback(async (requestData) => {
    const { results: docs, isBadRequest } = await api.ai.generateContent(requestData);
    if (isBadRequest) {
      return;
    }

    if (featureTourActive && docs.length > 0) {
      setTimeout(() => {
        nextStep();
      }, 500);
    }
    dispatch({
      type: actions.GENERATE_DOCUMENTS,
      payload: docs,
    });
  }, []);

  const setIsMagicLoading = React.useCallback((value) => {
    dispatch({
      type: actions.SET_MAGIC_LOADING,
      payload: value,
    });
  }, []);

  const setEditableDocumentId = React.useCallback((editableDocumentId) => {
    dispatch({
      type: actions.SET_EDITABLE_DOCUMENT_ID,
      payload: {
        editableDocumentId,
        caretPosition: caretPositionRef.current,
      },
    });
    editableDocumentIdRef.current = editableDocumentId;
  }, [state.editableDocumentId]);

  const dropEditableDocumentId = React.useCallback((editableDocumentId) => {
    dispatch({
      type: actions.DROP_EDITABLE_DOCUMENT_ID,
      payload: {
        editableDocumentId,
      },
    });
    editableDocumentIdRef.current = editableDocumentId;
  }, [state.editableDocumentId]);

  const handleDocumentContentChange = React.useCallback((id, {
    content, editor, forceSaveChange,
  }) => {
    if (editableDocumentIdRef.current === id) {
      realtimeContentRef.current = content;
    }

    const caretPosition = editor.getSelection()?.index || 0;
    caretPositionRef.current = caretPosition;

    const plainText = editor.getText();

    if (plainText.trim().length === 0) {
      dispatch({
        type: actions.DELETE_DOCUMENT,
        payload: {
          id,
          caretPosition,
        },
      });
      return;
    }

    dispatch({
      type: actions.UPDATE_DOCUMENT,
      payload: {
        id,
        data: { content },
        caretPosition,
        forceSaveChange,
      },
    });
  }, []);

  const mergeDocuments = React.useCallback((id) => {
    dispatch({
      type: actions.MERGE_DOCUMENTS,
      payload: {
        id,
      },
    });
  }, [state]);

  const undo = React.useCallback(() => {
    dispatch({
      type: actions.DROP_FIELD,
      payload: {
        field: 'forceCaretPosition',
      },
    });
    setTimeout(() => dispatch({
      type: actions.UNDO,
      payload: {
        caretPosition: caretPositionRef.current,
      },
    }));
  }, []);

  const redo = React.useCallback(() => {
    dispatch({
      type: actions.DROP_FIELD,
      payload: {
        field: 'forceCaretPosition',
      },
    });
    setTimeout(() => dispatch({
      type: actions.REDO,
    }));
  }, []);

  const deleteDocument = (id) => {
    dispatch({
      type: actions.DELETE_DOCUMENT,
      payload: {
        id,
      },
    });
  };

  React.useEffect(() => {
    if (state.deleteDocumentId) {
      api.documents.delete(state.deleteDocumentId).then(() => {
        dispatch({
          type: actions.DROP_FIELD,
          payload: {
            field: 'deleteDocumentId',
          },
        });
      });
    }
  }, [state.deleteDocumentId]);

  React.useEffect(() => {
    if (state.restoreDocumentId) {
      api.documents.restore(state.restoreDocumentId).then(() => {
        dispatch({
          type: actions.DROP_FIELD,
          payload: {
            field: 'restoreDocumentId',
          },
        });
      });
    }
  }, [state.restoreDocumentId]);

  React.useEffect(() => {
    if (state.updateDocumentId) {
      const doc = state.documents.find((d) => d._id === state.updateDocumentId);
      api.documents.update(state.updateDocumentId, {
        content: doc.content,
      }).then(() => {
        if (state.editableDocumentId === doc._id) {
          realtimeContentRef.current = doc.content;
          lastSavedContentRef.current = doc.content;
        }
        dispatch({
          type: actions.DROP_FIELD,
          payload: {
            field: 'updateDocumentId',
          },
        });
      });
    }
  }, [state.updateDocumentId]);

  return (
    <BlockEditorDocumentsContext.Provider
      value={{
        documents: state.documents,
        loadDocuments,
        isDocumentsLoading: state.isDocumentsLoading,

        isMagicLoading: state.isMagicLoading,
        setIsMagicLoading,
        generateContent,

        editableDocumentId: state.editableDocumentId,
        setEditableDocumentId,
        dropEditableDocumentId,

        handleDocumentContentChange,
        deleteDocument,
        mergeDocuments,

        forceCaretPosition: state.forceCaretPosition,
        undo,
        redo,
      }}
    >
      {children}
    </BlockEditorDocumentsContext.Provider>
  );
};

const useBlockEditorDocuments = () => {
  const context = React.useContext(BlockEditorDocumentsContext);

  return context;
};

const withDocuments = (Component) => (props) => (
  <BlockEditorDocumentsProvider>
    <Component {...props} />
  </BlockEditorDocumentsProvider>
);

export { useBlockEditorDocuments, BlockEditorDocumentsProvider, withDocuments };
