import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import Button from '@root/components/buttons/Button';
import { InputField, FormField } from '@root/components/form';
import useUpdateFolder from '@root/resources/folder/useUpdateFolder';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
}));

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, { message: 'The filename cannot contain only spaces' })
    .required('File name is required'),
});

const RenameFolderForm = ({ closeModal }) => {
  const classes = useStyles();
  const { state: { modalContent } } = useGlobalModals();
  const { mutateAsync: updateFolder } = useUpdateFolder();

  if (!modalContent) {
    return null;
  }
  const { folder: { title: originalTitle, _id: folderId, parentFolderId } } = modalContent;

  const submit = async (values, formActions) => {
    const { title } = values;
    formActions.setSubmitting(true);
    await updateFolder({
      folderId,
      update: {
        title,
      },
    });
    closeModal();
  };

  const folderType = parentFolderId ? 'folder' : 'project';

  return (
    <div>
      <Formik
        initialValues={{ title: originalTitle }}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <Form>
            <FormField
              name="title"
              component={InputField}
              label={`New ${folderType} name`}
              required
              autoFocus
            />
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={closeModal}>Discard</Button>
              <Button disabled={!isValid} type="submit">Rename</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RenameFolderForm;
