import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '464px',
  },
  presetFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  scrapeFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '8px',
    width: '100%',
  },
  redText: {
    color: theme.palette.colors.pink600,
  },
  scrapeField: {
    flex: 1,
  },
  scrapeButton: {
    height: 36,
    padding: '8px 24px',
  },
  presetInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '24px',
  },
  errorMessage: {
    color: 'red',
    marginTop: '0.25rem',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
  },
}));

export default useStyles;
