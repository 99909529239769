import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    maxWidth: '98%',
    maxHeight: '98%',
    width: '600px',
    height: 'auto',
    overflowY: 'auto',
    padding: '1.5rem 0',
  },
  innerContent: {
    padding: '0 1.5rem',
  },
  exampleOutput: {
    maxHeight: '50vh',
    padding: '0 1.5rem',
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  description: {
    marginBottom: '2rem',
  },
  close: {
    cursor: 'pointer',
  },
  title: {
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  subTitle: {
    fontSize: '0.9rem',
    fontWeight: 'lighter',
    marginBottom: '-0.4rem',
  },
  sampleOutput: {
    overflowY: 'scroll',
  },
  actions: {
    paddingTop: '1.5rem',
  },
});

export default useStyles;
