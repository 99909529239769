import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  shortcutsRoot: {
    position: 'fixed',
    bottom: 100,
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.neutrals400,
    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: 6,
    padding: '12px 16px',
    fontSize: 12,
    fontWeight: 600,
  },
  command: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    color: theme.palette.colors.neutrals300,
    marginLeft: 16,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.neutrals100,
    color: theme.palette.colors.neutrals400,
    padding: '0 4px',
    minWidth: 24,
    minHeight: 24,
    borderRadius: 6,
    fontWeight: 700,
  },
}));

export default useStyles;
