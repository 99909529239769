import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink as RouterNavLink, useHistory } from 'react-router-dom';
import { Notifications, ChevronRight, ChevronLeft, InsertChart } from '@material-ui/icons';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { logOutUser } from '@root/services/auth.service';
import NewsDrawer from '@root/views/Layout/components/NewsDrawer';
import newsResource from '@root/resources/news';
import DateUtil from '@root/utils/date.util';
import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import billingResource from '@root/resources/billing';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import hubspotService from '@root/services/hubspot.service';
import userResource from '@root/resources/user';
import useWorkflowStats from '@root/resources/file/useWorkflowStats';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import UserIcon from './UserIcon';
import useStyles from './ProfileDropdown.styles';

const COUNTER_BADGE_LIMIT = 9;

const ProfileDropdown = () => {
  const classes = useStyles();
  const logoutWithRedirect = () => logOutUser({ redirect: '/' });
  const history = useHistory();
  const [resFocused, focusResources] = useState(false);
  const [newsDrawerOpen, setNewsDrawerOpen] = useState(false);
  const { data: news } = newsResource.useNews();
  const { currentUser } = AuthContext.useAuth();
  const { mutateAsync: updateCurrentUser } = userResource.useUpdateUser(currentUser._id);
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { data: pricingData = {} } = billingResource.usePricing();

  const {
    steps, stepIndex, setOnboardingState, reminderActive,
  } = onboardingContext.useOnboarding();
  const isDropdownHighlighted = steps[stepIndex]?.highlightDropdown && reminderActive;

  const { profileDropdownDisabledItems, profileDropdownDisabledResources } = currentExternalSource;
  const lastSeenNews = currentUser.lastCheckedNews || 0;

  const {
    data: stats, isLoading: isLoadingWorkflowStats,
  } = useWorkflowStats();

  const sidebarBadgeValue = React.useMemo(() => {
    if (stats?.workflowStats > COUNTER_BADGE_LIMIT) {
      return `${COUNTER_BADGE_LIMIT}+`;
    }

    return stats?.workflowStats;
  }, [stats?.workflowStats]);

  const showNotificationCounter = !isLoadingWorkflowStats && sidebarBadgeValue !== 0;

  useEffect(() => {
    if (reminderActive) {
      setOnboardingState({ run: true });
    }
    return () => {
      if (reminderActive) {
        setOnboardingState({ run: false, reminderActive: false });
      }
    };
  }, []);

  if (!news) {
    return null;
  }

  const mostRecentPost = news[0]?.date;
  const diff = mostRecentPost - lastSeenNews;
  const newsNotification = diff > 0;

  const onNewsClick = async () => {
    await updateCurrentUser({
      lastCheckedNews: DateUtil.getNowSeconds(),
    });
    setNewsDrawerOpen(true);
  };

  const openUsageDashboard = () => {
    history.push('/usage');
  };

  const openWorkflowQueue = () => {
    history.push('/templates/workflow/?filters=true');
  };

  const resetFocused = () => {
    focusResources(false);
  };

  const resources = [
    {
      key: 'resources',
      content: (
        <>
          Resources
          <ChevronLeft className={classes.chevronLeft} />
        </>
      ),
      onClick: () => focusResources(false),
      toggle: false,
      withSeparator: true,
    },
    {
      key: 'video-tutorials',
      content: 'Video Tutorials',
      onClick: () => window.open(
        'https://fast.wistia.com/embed/channel/yvyvu7wjbg',
        '_blank',
      ),
    },
    {
      key: 'knowledge-base',
      content: 'Knowledge Base',
      onClick: () => window.open(
        'https://describely.notion.site/Copysmith-Help-Center-b0c792cf63a64cc7bc5d1fb07648ff1c',
        '_blank',
      ),
    },
    {
      key: 'updates',
      content: (
        <>
          {newsNotification && <div className={classes.redCircle} />}
          Updates
        </>
      ),
      toggle: false,
      onClick: () => onNewsClick(),
    },
  ];

  const filteredResources = resources.filter((resource) => {
    return !profileDropdownDisabledResources.includes(resource.key);
  });

  const isOnTrial = currentUser.onFreePlan;
  const currentPlan = pricingData.currentPlan || {};
  const isLTD = PLANS_IDS.ltds.includes(currentPlan._id) && !currentPlan.productId;
  const LinkToPlansText = () => {
    if (isOnTrial) {
      return 'Subscribe';
    }
    if (isLTD) {
      return 'Upgrade';
    }
    return 'Plans';
  };

  const items = [
    {
      key: 'profile',
      tag: RouterNavLink,
      to: '/profile/edit',
      activeClassName: 'router-link-exact-active',
      content: 'Profile',
    },
    {
      key: 'teams',
      tag: RouterNavLink,
      to: '/profile/team',
      activeClassName: 'router-link-exact-active',
      content: 'Teams',
    },
    {
      key: 'plans',
      tag: RouterNavLink,
      to: '/profile/plans',
      activeClassName: 'router-link-exact-active',
      content: (
        <LinkToPlansText />
      ),
    },
    {
      key: 'billing',
      tag: RouterNavLink,
      to: '/profile/billing',
      activeClassName: 'router-link-exact-active',
      content: 'Billing',
      withSeparator: true,
    },
    {
      key: 'resources',
      content: (
        <>
          {newsNotification && <div className={classes.redCircle} />}
          Resources
          <ChevronRight className={classes.chevronRight} />
        </>
      ),
      toggle: false,
      onClick: () => focusResources(true),
    },
    {
      key: 'api-docs',
      tag: RouterNavLink,
      to: '/addons/api',
      activeClassName: 'router-link-exact-active',
      content: 'API',
      withSeparator: false,
    },
  ];

  // if (currentUser && !currentUser.onFreePlan && currentUser.featureToggles.rewardsPage) {
  //   items.push({ key: 'rewards',
  //     tag: RouterNavLink,
  //     to: '/rewards',
  //     activeClassName: 'router-link-exact-active',
  //     content: 'Rewards' });
  // }

  items.push({
    key: 'help',
    content: 'Help',
    onClick: hubspotService.show,
  },
  {
    key: 'log-out',
    content: 'Log out',
    onClick: () => logoutWithRedirect(),
  });

  const filteredItems = items.filter((item) => {
    return !profileDropdownDisabledItems.includes(item.key);
  });

  const itemsToShow = resFocused ? filteredResources : filteredItems;

  return (
    <div className={classes.dropdownWrap}>
      <NewsDrawer
        news={news}
        isOpen={newsDrawerOpen}
        setIsOpen={setNewsDrawerOpen}
      />
      {currentUser && (
        <div>
          <div className={classes.dropdownPanel}>
            {currentUser?.isUsageDashboardEnabled && (
              <div onClick={openUsageDashboard} className={classes.usageIconWrap}>
                <InsertChart className={classes.usageIcon} />
              </div>
            )}
            <div onClick={openWorkflowQueue} className={classes.notificationIconWrap}>
              {showNotificationCounter && (
                <div className={classes.notificationCounter}>
                  {sidebarBadgeValue}
                </div>
              )}
              <Notifications className={classes.notificationIcon} />
            </div>
            <div
              className={classes.dropdownPanel}
              id="walkthrough-profile"
            >
              <div
                className={classes.userInfoWrap}
                onClick={() => history.push('/profile')}
              >
                <UserIcon
                  name={currentUser.firstName || currentUser.email}
                />
                <span>
                  {currentUser.firstName || 'User'}
                </span>
              </div>
              <UncontrolledDropdown inNavbar className={classes.dropdown}>
                <DropdownToggle
                  tag="span"
                  nav
                  className={clsx(
                    classes.dropdownToggle,
                    isDropdownHighlighted && classes.dropdownToggleHighlighted,
                  )}
                  caret
                  onClick={resetFocused}
                  id={reminderActive ? 'onb-reminder-step' : undefined}
                />
                <DropdownMenu
                  className={clsx('dropdown-menu-right', classes.dropdownMenuRight)}
                >
                  {itemsToShow.map((item) => {
                    const activeClassNameProps = item.activeClassName
                      ? { activeClassName: item.activeClassName }
                      : {};
                    return (
                      <React.Fragment key={item.key}>
                        <DropdownItem
                          tag={item.tag}
                          to={item.to}
                          className={classes.dropdownItem}
                          onClick={item.onClick}
                          toggle={item.toggle}
                          {...activeClassNameProps}
                        >
                          {item.content}
                        </DropdownItem>
                        { item.withSeparator && <hr />}
                      </React.Fragment>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
