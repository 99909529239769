import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useImportCatalogItems() {
  const queryClient = useQueryClient();

  return useMutation(async (data) => {
    const response = await api.catalogItem.import(data);

    queryClient.invalidateQueries(['catalog.items', {}]);

    return response;
  });
}
