import api from '@root/api';
import {
  downloadAsFile,
} from '@root/utils/Export';

const downloadSampleFile = async ({
  templateType,
  templateName,
}) => {
  const response = await api.bulkGeneration.getSampleFile({ templateType });

  if (response.isBadRequest) {
    return;
  }

  downloadAsFile(`${response}`, `${templateName}.csv`);
};

export default {
  downloadSampleFile,
};
