import React, { useState } from 'react';
import clsx from 'clsx';
import { IconEdit, IconMail } from '@tabler/icons-react';

import { InputField } from '@root/components/form';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useUpdateBillingEmail from '@root/resources/billing/useUpdateBillingEmail';
import TruncatedText from '@root/components/TruncatedText';

import useStyles from './AccountSection.styles';

const BillingEmail = ({ email }) => {
  const classes = useStyles();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [changeModeEnabled, setChangeModeEnabled] = useState(false);
  const [newEmailValue, setNewEmailValue] = useState(email);
  const [isOverEmail, setIsOverEmail] = useState(false);

  const { mutateAsync: updateBillingEmail, isLoading: isEmailUpdating } = useUpdateBillingEmail();

  const toggleChangeMode = () => {
    setChangeModeEnabled(!changeModeEnabled);
    setNewEmailValue(email);
  };

  const updateEmail = async () => {
    await updateBillingEmail(newEmailValue);
    setChangeModeEnabled(false);
    setIsConfirmationModalOpen(false);
  };

  const handleEmailChange = (e) => {
    setNewEmailValue(e.target.value);
  };

  const handleEmailBlur = () => {
    if (email !== newEmailValue) {
      setIsConfirmationModalOpen(true);
    } else {
      toggleChangeMode();
      setIsOverEmail(false);
    }
    setIsOverEmail(false);
  };

  const handleChangeEmailCancel = () => {
    setChangeModeEnabled(false);
  };

  return (
    <>
      <ConfirmationModal
        title="Changing billing email address"
        open={isConfirmationModalOpen}
        onConfirm={updateEmail}
        onCancel={handleChangeEmailCancel}
        onClose={() => setIsConfirmationModalOpen(false)}
        cancelButtonText="Discard"
        confirmButtonText="Confirm"
        classes={{ paper: classes.confirmationModal }}
      >
        You about to change your billing email address to
        {' '}
        {newEmailValue}
        , please confirm the changes.
      </ConfirmationModal>

      <div className={classes.emailContainer}>
        {!changeModeEnabled && (
          <p
            className={clsx(classes.cardInfoText, classes.emailTextContainer)}
            onMouseEnter={() => setIsOverEmail(true)}
            onMouseLeave={() => setIsOverEmail(false)}
            onClick={toggleChangeMode}
          >
            {isOverEmail ? <IconEdit /> : <IconMail />}
            <TruncatedText className={classes.emailText}>{email}</TruncatedText>
          </p>
        )}

        {changeModeEnabled && (
          <InputField
            size="small"
            margin="dense"
            variant="outlined"
            placeholder="New billing email"
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            value={newEmailValue}
            autoFocus
            disabled={isEmailUpdating}
            classes={{ root: classes.emailInputRoot, input: classes.emailInput }}
          />
        )}
      </div>
    </>
  );
};

export default BillingEmail;
