import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.6rem',
  },
});

export default useStyles;
