import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  newRuleWrap: {
    display: 'flex',
  },
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    // flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  typeFilterSelect: {
    minWidth: 200,
    marginRight: theme.spacing(2),
  },
  groupFilterSelect: {
    minWidth: 200,
  },
  tabs: {
    marginBottom: 16,
  },
}));

export default useStyles;
