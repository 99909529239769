import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 22,
    textAlign: 'center',
    marginBottom: 24,
  },
  socialButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16,
  },
  form: {
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  forgotPassword: {
    alignSelf: 'flex-start',
    marginTop: 8,
  },
  buttonsContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  confirmEmailText: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: theme.spacing(-1),
  },
  confirmEmailTimeout: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: 66,
  },
  buttonsContainerTimeout: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  emailToConfirm: {
    fontWeight: 600,
  },
  loading: {
    color: theme.palette.colors.white,
  },
}));

export default useStyles;
