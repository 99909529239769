import config from '@root/config';

const openOauthPopup = ({
  url,
  title,
  lazy = false,
}) => {
  const left = window.screenX + (window.outerWidth - 600) / 2;
  const top = window.screenY + (window.outerHeight - 700) / 2.5;
  const windowParams = `toolbar=no, menubar=no, width=600, height=700, top=${top}, left=${left}`;

  let codePromiseResolve = null;
  const codePromise = new Promise((res) => {
    codePromiseResolve = res;
  });

  const initHandler = (w) => {
    const messageHandler = ({ data }) => {
      if (data.copysmithOauthCode) {
        codePromiseResolve(data.copysmithOauthCode);
        w.close();
        window.removeEventListener('message', messageHandler);
      }
    };

    window.addEventListener('message', messageHandler);
  };

  // used for safari when open popup in async function
  if (lazy) {
    const oauthWindow = window.open('', title, windowParams);

    const passUrl = (windowUrl) => {
      oauthWindow.location = windowUrl;
      initHandler(oauthWindow);

      return codePromise;
    };
    const close = oauthWindow?.close;

    return {
      passUrl,
      close,
    };
  }

  const oauthWindow = window.open(url, title, windowParams);
  initHandler(oauthWindow);

  return codePromise;
};

const getGoogleOauthCode = async ({
  scope = ['email', 'profile'],
  redirectUri = `${config.appUrl}/oauth-redirect`,
  hint = null,
} = {}) => {
  const code = await openOauthPopup({
    url: `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(scope.join(' '))}&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${config.googleOauthClientId}${hint ? `&login_hint=${hint}` : ''}`,
  });

  if (!code) {
    throw new Error('Unable to get profile information from Google.');
  }

  return {
    code,
    scope,
    redirectUri,
    provider: 'google',
  };
};

const getMicrosoftOauthCode = async ({
  scope = ['user.read', 'offline_access', 'openid', 'email', 'profile'],
  redirectUri = `${config.appUrl}/oauth-redirect`,
//  hint = null,
} = {}) => {
  const code = await openOauthPopup({
    url: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=0564aedc-3669-4fbd-96cd-1835b4dc3719&scope=user.read%20openid%20profile%20offline_access&redirect_uri=${redirectUri}&client-request-id=155e5d55-d7a8-43bc-b4b4-314dc16bf258&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=1.0.0&x-client-OS=darwin&x-client-CPU=x64&client_info=1`,
  });

  if (!code) {
    throw new Error('Unable to get profile information from Microsoft.');
  }

  return {
    code,
    scope,
    redirectUri,
    provider: 'microsoft',
  };
};

const getSSOOauthCode = () => {
  const { passUrl, close } = openOauthPopup({ lazy: true });

  const passSsoConfig = async (data = {}) => {
    const {
      authUrl = 'https://accounts.google.com/o/oauth2/v2/auth',
      scope = ['email'],
      redirectUri = `${config.appUrl}/oauth-redirect`,
    } = data;
    const isUrlHasQuerySymbol = authUrl.includes('?');

    const urlParams = `scope=${encodeURIComponent(scope.join(' '))}&redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${config.googleOauthClientId}`;
    const urlWithParams = `${authUrl}${isUrlHasQuerySymbol ? '&' : ''}${urlParams}`;

    const code = await passUrl(urlWithParams);

    if (!code) {
      throw new Error('Unable to get profile information from SSO Indentity server.');
    }

    return {
      code,
      redirectUri,
    };
  };

  return { passSsoConfig, close };
};

const oauthMap = {
  google: getGoogleOauthCode,
  microsoft: getMicrosoftOauthCode,
  sso: getSSOOauthCode,
};

export default oauthMap;
