import { useQuery } from 'react-query';

import api from '@root/api';

const getWorkflowStats = async () => {
  const stats = await api.files.getWorkflowStats();

  return stats;
};

export default function useWorkflowStats() {
  return useQuery(
    'files.workflowStats',
    getWorkflowStats,
  );
}
