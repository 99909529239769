import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import * as yup from 'yup';

import ContentEditor from '@root/components/ContentEditor';
import { REWRITE_BUTTON_ID } from '@root/resources/file/file.constants';
import useTextSelect from '@root/utils/hooks/useTextSelect';
import { handleDocumentEditorTextSelect } from '@root/utils/file.utils';

import EditFooter from './EditFooter';

const validationSchema = yup.object({
  content: yup
    .string()
    .required('Content is required'),
});

const useStyles = makeStyles(() => ({
  root: {
  },
}));

export const checkAwayClick = (target) => !(
  target.id === REWRITE_BUTTON_ID
    || target.parentElement.id === REWRITE_BUTTON_ID
);

const CommonEditor = ({
  // content is a content object of the Document, see document.schema for more details
  content: initialContent,
  onSave,
  onCancelEdit,
  children,
  onTextSelect,
}) => {
  const classes = useStyles();
  const ref = React.useRef();

  const formik = useFormik({
    initialValues: {
      content: initialContent,
    },
    validationSchema,
  });

  const save = async () => {
    await onSave({ content: formik.values.content });
  };

  const onUpdateContent = (newContent) => {
    formik.setFieldValue('content', newContent);
  };

  const onClickAway = async (e) => {
    e.preventDefault();

    if (!checkAwayClick(e.target)) {
      return;
    }

    const noSelection = window.getSelection().toString().length <= 0;
    if (noSelection) {
      await onSave({ content: formik.values.content });
      onCancelEdit();
    }
  };

  useTextSelect((selection) => {
    handleDocumentEditorTextSelect({
      selection,
      setFieldValue: formik.setFieldValue,
      content: formik.values.content,
      onTextSelect,
    });
  }, ref);

  return (
    <form className={classes.root} ref={ref}>
      <ClickAwayListener
        onClickAway={onClickAway}
      >
        <div>
          <ContentEditor
            initialTextValue={formik.values.content}
            changeContent={(data) => onUpdateContent(data.value)}
          />
        </div>
      </ClickAwayListener>
      {children}
      <EditFooter
        onCancelEdit={onCancelEdit}
        onSave={save}
      />
    </form>
  );
};

export default CommonEditor;
