import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export class Usage {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async listCredits(data?: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/credits", data, options);

    return result;
  }

  public async getStatsForTemplates(data?: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/templateStats", data, options);

    return result;
  }

  public async getGenerationsUsage(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/generations", data, options);

    return result;
  }

  public async getWordsUsage(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/words", data, options);

    return result;
  }

  public async getStats(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/stats", data, options);

    return result;
  }

  public async getRoiStats(data?: RequestOptions, options?: HandlingOptions) : Promise<Record<string, unknown>> {
    const result = await this.client.get("/usage/roiStats", data, options);

    return result;
  }
}