import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api/newApi';

const createOrUpdateAd = async (data) => {
  const res = await api.googleAd.createOrUpdateAd({
    documentId: data.documentId,
    finalUrl: data.finalUrl,
    integrationId: data.integrationId,
  });
  return res;
};

export default function useCreateOrUpdateAd() {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => createOrUpdateAd(data),
    {
      onSuccess: (data, variables) => {
        const { fileId } = variables;
        queryClient.invalidateQueries(['googleAds.getFile', fileId]);
        queryClient.invalidateQueries(['documents.list', { fileId }]);
      },
    },
  );
}
