import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  multiline: (props) => ({
    display: '-webkit-box',
    maxHeight: 'unset',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-line-clamp': props.maxLines,
    '-webkit-box-orient': 'vertical',
  }),
}));

export default useStyles;
