import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 462,
  },
  content: {
    color: theme.palette.text.secondary,
    marginBottom: 40,
  },
  contentText: {
    marginBottom: 20,
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 24,
  },
}));

export default useStyles;
