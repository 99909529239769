/**
 * Returns the home folder from a group of folders.
 *
 * @param {Array<Record>} folders
 * @returns {Record} home folder object
 */
export const homeFolder = (folders) => {
  if (!folders) {
    return {};
  }

  return folders.find((f) => f.home);
};

/**
 * Stable sort an array returning a copy of the array
 *
 * @param {Array<any>} array
 * @param {function} comparator
 * @returns {Array<any>} sorted array copy
 */
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

/**
 * Meta / suspended function navigate to file page
 *
 * @param {Record} file
 * @param {any} history
 */
export const navToFileMeta = (file, history, slug, state) => () => {
  history.push(`/${slug}/${file._id}?title=${file.title}`, state);
};

/**
 * returns folders that are
 * 1) not sub folders 2) not the Uncategorized/home folder 3) created by user
 * @param {Array.<Record>} folders
 * @param {any} user
 * @returns {Array.<Record>} array of valid folders
 */
export const getRootFolders = (folders, user) => folders.filter(
  (folder) => !folder.parentFolderId
    && folder.unifiedAcl.some((i) => i.role === 'owner' && i.userId === user._id),
);

/**
 * returns folders that are
 * 1) not sub folders 2) not the Uncategorized/home folder 3) not created by user
 * @param {Array.<Record>} folders
 * @param {any} user
 * @returns {Array.<Record>} array of valid folders
 */
export const getSharedRootFolders = (folders, user) => folders.filter(
  (folder) => !folder.parentFolderId
    && folder.unifiedAcl.some((i) => i.role === 'owner' && i.userId !== user._id),
);

/**
 *
 * @param {string} rootFolderId
 * @param {Array.<Record>} folders
 * @returns {Array.<Record>} child folder array
 */
export const getChildFolders = (rootFolderId, folders) => (rootFolderId ? folders.filter(
  (folder) => folder.parentFolderId === rootFolderId,
) : []);

/**
 *
 * @param {string} rootFolderId _id of the root folder
 * @param {string} activeFolderId _id of the currently active folder
 * @param {Array.<Record>} folders
 * @returns {boolean} true if a child folder's _id match activeFolderId
 */
export const isChildActive = (rootFolderId, activeFolderId, folders) => getChildFolders(
  rootFolderId, folders,
).map((folder) => folder._id).includes(activeFolderId);

export const isFolderShared = (folder, user) => folder.unifiedAcl.some((i) => i.role === 'owner' && i.userId === user._id) && folder.unifiedAcl.length > 1;
