import { HttpClient } from "../../HttpClient";

export interface GetProductsRequest {
  collectionId?: number;
  productStatuses: string[];
}
export interface GetProductRequest {
  documentId: string;
}
export interface LinkProductRequest {
    documentId: string;
    productId: string;
}
export interface UpdateProductRequest {
    documentId: string;
    description: string;
}

export default class Shopify {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }
    
  public async getProducts(data: GetProductsRequest): Promise<Record<string, unknown>> {
      const result = await this.client.get("/getProducts", data);

      return result;
  }

  public async getProduct(data: GetProductRequest): Promise<Record<string, unknown>> {
    const result = await this.client.get("/getProduct", data);

    return result;
  }

  public async getCollections(): Promise<Record<string,unknown>> {
    const result = await this.client.get("/getCollections");

    return result;
  }

  public async checkPermissions(): Promise<Record<string, unknown>> {
    const result = await this.client.get("checkPermissions");

    return result;
  }

  public async linkProduct(data: LinkProductRequest): Promise<Record<string, unknown>> {
    const result = await this.client.post("/linkProduct", data);

    return result;
  }
    
  public async updateProduct(data: UpdateProductRequest): Promise<Record<string, unknown>> {
      const result = await this.client.post("/updateProduct", data);

      return result;
  }

  public async getFile(fileId: string): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/documents/${fileId}`);

    return result;
  }

  public async unlinkProducts(): Promise<Record<string, unknown>> {
    const result = await this.client.post("/unlinkProducts");

    return result;
  }
}