import React from 'react';
import { Button } from '@material-ui/core';

import { useTemplatesDashboard } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';

import BannerGradient from './bannerGradient.svg';
import useStyles from './EditorBanner.styles';

const TEMPLATE_TYPE = 'freeFormEditor';

const EditorBanner = () => {
  const classes = useStyles();

  const { handleCreate } = useTemplatesDashboard();

  const handleCreateClick = () => {
    handleCreate(TEMPLATE_TYPE);
  };

  return (
    <div className={classes.bannerContainer}>
      <img
        src={BannerGradient}
        alt="gradient"
        className={classes.gradientCanvas}
      />

      <h3 className={classes.title}>
        New Editor Experience
      </h3>

      <p className={classes.subtitle}>
        Our new editor allows you to create content for multiple uses cases in one document.
        You can also rewrite, extend, or enhance any existing copy you already have.
      </p>

      <Button
        className={classes.actionButton}
        onClick={handleCreateClick}
      >
        Create Editor File
      </Button>
    </div>
  );
};

export default EditorBanner;
