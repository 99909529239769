import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@material-ui/core';

import hubspotService from '@root/services/hubspot.service';
import authContext from '@root/resources/auth/auth.context';
import subscriptionsContext from '@root/resources/billing/subscriptions.context';
import { FormField, InputField, SelectField } from '@root/components/form';

import useStyles from './TermsOfPlan.styles';
import schema from './schema';

const TermsOfPlan = ({ onSubmit, plan: initialPlan }) => {
  const formikRef = React.useRef();
  const classes = useStyles();
  const { currentUser: user } = authContext.useAuth();

  const [plan, setPlan] = React.useState(initialPlan);

  const {
    allPricing,
  } = subscriptionsContext.useSubscriptions();

  const validateInput = (values) => {
    if (values.contractDuration === 6 && values.billingCycle === 'yearly') {
      formikRef.current.setFieldValue('contractDuration', 12);
    }
    if (values.contractDuration === 'discount') {
      formikRef.current.setFieldValue('contractDuration', formikRef.current.values.contractDuration);
      hubspotService.show();
    }

    setPlan(allPricing.find((p) => (
      p.rank === initialPlan.rank
        && p.period === values.billingCycle
        && p.tier === values.wordsPerMonthTier
    )));
  };

  const wordsCountByTier = React.useMemo(() => {
    if (!allPricing) {
      return [];
    }

    const plansByTier = allPricing
      .filter((p) => p.period === 'monthly' && p.rank === initialPlan.rank)
      .sort((a, b) => a.tier - b.tier);

    const numberFormatter = new Intl.NumberFormat('en-US');

    return plansByTier.map((p) => numberFormatter.format(p.data.limits.mainCredits));
  }, [allPricing]);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          firstName: user.firstName,
          surname: user.surname,
          contractDuration: 12,
          billingCycle: initialPlan.period,
          wordsPerMonthTier: initialPlan.tier,
        }}
        validateOnChange
        validate={validateInput}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form className={classes.form}>
            <div className={classes.title}>
              {`${plan.nickname} Plan`}
            </div>

            <FormField
              component={InputField}
              label="First Name"
              name="firstName"
              required
            />

            <FormField
              component={InputField}
              label="Last Name"
              name="surname"
              required
            />

            <FormField
              component={SelectField}
              label="Duration"
              name="contractDuration"
              required
              options={[
                { value: 6, label: '6 months', disabled: formik.values.billingCycle === 'yearly' },
                { value: 12, label: '12 months' },
                { value: 'discount', label: 'Request discount for a longer contract duration' },
              ]}
            />

            <FormField
              component={SelectField}
              label="Billing Cycle"
              name="billingCycle"
              required
              options={[
                { value: 'monthly', label: 'Monthly' },
                { value: 'yearly', label: 'Yearly' },
              ]}
            />

            <FormField
              component={SelectField}
              label="Words Per Month "
              name="wordsPerMonthTier"
              required
              options={wordsCountByTier.map((wordsCount, index) => ({
                value: index,
                label: wordsCount,
              }))}
            />

            <Button
              type="submit"
              className={classes.submit}
            >
              Sign and subscribe
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TermsOfPlan;
