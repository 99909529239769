import React, { createContext } from 'react';

import { downloadAsFile } from '@root/utils/Export';
import AuthContext from '@root/resources/auth/auth.context';
import api from '@root/api';
import billingResource from '@root/resources/billing';

import useCurrentJob from './useCurrentJob';

const BulkGenerationsContext = createContext();

const actions = Object.freeze({
  SET_CURRENT_JOB: 'SET_CURRENT_JOB',
  TOGGLE_REPORT_MODAL: 'TOGGLE_REPORT_MODAL',
  TOGGLE_AUTODOWNLOAD: 'TOGGLE_AUTODOWNLOAD',
  FINISH_JOB: 'FINISH_JOB',
});

const initialState = {
  currentJob: null,
  showReportModal: false,
  isAutodownloadEnabled: false,
  currentJobProcessing: false,
};

const downloadCsv = async ({
  job,
  isAutodownloadEnabled,
}) => {
  if (isAutodownloadEnabled) {
    const response = await api.bulkGeneration.getBulkCSV({ id: job.bulkGeneration._id });

    if (response.isBadRequest) {
      return;
    }

    downloadAsFile(`${response}`, `${job.bulkGeneration.title}.csv`);
  }
};

const bulkGenerationsReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case actions.SET_CURRENT_JOB:
        return {
          ...state,
          currentJob: action.payload.job,
          showReportModal: false,
          currentJobProcessing: action.payload.job.bulkGeneration.state === 'pending',
        };
      case actions.FINISH_JOB: {
        return {
          ...state,
          showReportModal: true,
          isAutodownloadEnabled: false,
          currentJobProcessing: false,
        };
      }
      case actions.TOGGLE_REPORT_MODAL: {
        return {
          ...state,
          showReportModal: action.payload.isOpened,
        };
      }
      case actions.TOGGLE_AUTODOWNLOAD: {
        return {
          ...state,
          isAutodownloadEnabled: action.payload.isAutodownloadEnabled,
        };
      }
      default: {
        throw new Error(`Dashboard Context Reducer: Unhandled action type: ${action.type}`);
      }
    }
  };
};

function useBulkGenerations() {
  const context = React.useContext(BulkGenerationsContext);
  if (context === undefined) {
    throw new Error('useBulkGenerations must be used within a BulkGenerationsProvider');
  }
  return context;
}

const isJobDone = (job, { withCancel = false } = {}) => (
  job?.bulkGeneration
    ? ['done', 'failed', withCancel && 'canceled'].includes(job.bulkGeneration?.state)
    : true
);

const ProgressChecker = ({ children }) => {
  const {
    state: {
      currentJob,
      isAutodownloadEnabled,
    },
    dispatch,
  } = useBulkGenerations();
  const { currentUser } = AuthContext.useAuth();
  const { data: job, refetch: reloadJob } = useCurrentJob({ enabled: !!currentUser });
  const {
    data: { limit: bulkGenerationRows } = {},
    refetch: refetchLimits,
  } = billingResource.useLimits({ enabled: !!currentUser });

  const checkBulkGenerationStatus = async () => {
    if (!currentUser) {
      return;
    }

    const { data: realtimeJob = {} } = await reloadJob();

    if (!realtimeJob?.bulkGeneration) {
      return;
    }

    const isDone = isJobDone(realtimeJob);
    if (isDone && !currentJob) {
      return;
    }

    dispatch({
      type: actions.SET_CURRENT_JOB,
      payload: {
        job: realtimeJob,
      },
    });

    if (isJobDone(realtimeJob) && !isJobDone(currentJob)) {
      await downloadCsv({
        job: realtimeJob,
        isAutodownloadEnabled,
      });

      if (bulkGenerationRows) {
        await refetchLimits();
      }

      dispatch({
        type: actions.FINISH_JOB,
      });
    }
  };

  React.useEffect(() => {
    if (!isJobDone(job, { withCancel: true })) {
      const interval = setInterval(() => {
        checkBulkGenerationStatus();
      }, 3000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [currentJob, job, isAutodownloadEnabled]);

  return children;
};

const BulkGenerationsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    bulkGenerationsReducer(),
    { ...initialState },
  );

  const value = { state, dispatch };

  return (
    <BulkGenerationsContext.Provider value={value}>
      <ProgressChecker>
        {children}
      </ProgressChecker>

    </BulkGenerationsContext.Provider>
  );
};

export default { BulkGenerationsProvider, useBulkGenerations, actions };
