import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
