import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import companyService from '@root/resources/company/company.service';
import DateUtil from '@root/utils/date.util';
import billingResource from '@root/resources/billing';
import { ModalsProvider } from '@root/views/Layout/modalsContext';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import newMaterialTheme from '@root/newMaterial.theme';
import bigCommerceService from '@root/services/bigCommerce.service';

import GlobalModals from './components/GlobalModals/GlobalModals';
import AccountSuspendedModal from './components/AccountSuspendedModal';

/**
 * Root layout is a base layout for all Copysmith pages
 * Use it to implement common logic for all pages
 */
const RootLayout = ({ children }) => {
  const [accountSuspendedModalOpen, setAccountSuspendedModalOpen] = useState(false);
  const { data: billingInfo } = billingResource.useBillingInfo();
  const { currentCompany, currentUser } = AuthContext.useAuth();
  const { suspensionEnd } = currentCompany.billing;
  const location = useLocation();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  useEffect(() => {
    const isCompanySuspended = companyService.isCompanySuspended(currentCompany);
    if (isCompanySuspended && !window.location.pathname.includes('/profile')) {
      setAccountSuspendedModalOpen(true);
    }
  }, []);

  if (!billingInfo) {
    return null;
  }

  const isLifetimeDeal = billingInfo?.priceId === 'lifetime_deal';
  const { isNeedToSubscribe, isTrialEndShowed } = currentExternalSource;

  const needToSubscribeExternal = isNeedToSubscribe({ billingInfo, isLifetimeDeal })
    || isTrialEndShowed({ currentUser, billingInfo });
  const needToSubscribe = !isLifetimeDeal && ((billingInfo?.status === 'cancelled' && !billingInfo.suspensionStart) || billingInfo?.status === 'trialExpired' || (billingInfo?.status === 'billingIssue' && billingInfo?.priceId === 'free'));

  const isOwner = currentUser.roles.owner; // eslint-disable-line @typescript-eslint/no-unused-vars

  if (!location.pathname.includes('/profile/plans') && (needToSubscribe || needToSubscribeExternal)) {
    return <Redirect to="/profile/plans" />;
  }

  if (bigCommerceService.isInvalidUrl()) {
    return <Redirect to="/bigcommerce-incognito-warn" />;
  }

  const shouldRedirectToAddCompany = !location.pathname.includes('/add-company-info')
    && !currentCompany.onboarding.industrySegment
    && isOwner
    && !currentUser.featureToggles.newEditor; // temporary disabled for new editor
  if (shouldRedirectToAddCompany) {
    return <Redirect to="/add-company-info" />;
  }

  return (
    <ModalsProvider>
      {children}
      <AccountSuspendedModal
        modalOpen={accountSuspendedModalOpen}
        setModalOpen={setAccountSuspendedModalOpen}
        renewalDate={DateUtil.toCommonDateString(suspensionEnd)}
      />
      <ThemeProvider theme={newMaterialTheme}>
        <GlobalModals />
      </ThemeProvider>
    </ModalsProvider>
  );
};

export default RootLayout;
