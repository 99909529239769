import React from 'react';
import clsx from 'clsx';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import MainLayout from '@root/views/Layout';
import Stepper from '@root/components/Stepper';
import Button from '@root/components/buttons/Button';

import useStyles from './WizardLayout.styles';

const WizardLayout = ({
  children,
  nextAvailable,
  prevAvailable,
  steps,
  step,
  previousText = 'Previous step',
  onStepChange,
  classes: customClasses = { main: '' },
}) => {
  const classes = useStyles({ step });

  const handlePrevStepClick = () => {
    onStepChange((currentStep) => currentStep - 1);
  };

  const handleNextStepClick = () => {
    onStepChange((currentStep) => currentStep + 1);
  };

  return (
    <MainLayout>
      <div className={classes.wizardRoot}>
        <div className={classes.stepperBlock}>
          {prevAvailable ? (
            <Button
              variant="link"
              className={classes.prevStepButton}
              onClick={handlePrevStepClick}
            >
              <ChevronLeft />
              { previousText }
            </Button>
          ) : <div className={classes.prevStepButton} />}

          <Stepper
            steps={steps}
            stepIndex={step}
          />

          {(nextAvailable && step < steps.length - 1) ? (
            <Button
              variant="link"
              className={classes.nextStepButton}
              onClick={handleNextStepClick}
            >
              Next step
              <ChevronRight />
            </Button>
          ) : <div className={classes.nextStepButton} />}
        </div>

        <div className={clsx(classes.main, customClasses.main)}>
          {children}
        </div>

      </div>
    </MainLayout>
  );
};

export default WizardLayout;
