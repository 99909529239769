import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import File from '@root/views/File/File';
import TemplatesContext from '@root/resources/templates/templates.context';
import FileContext from '@root/resources/file/file.context';

const GenericFilePage = () => {
  const params = useParams();
  const history = useHistory();
  const { templatesList } = TemplatesContext.useTemplates();

  const template = React.useMemo(() => {
    return templatesList.find((t) => t.slug === params.slug);
  });
  if (!params.slug) {
    return null;
  }

  if (!template && templatesList.length > 0) {
    history.push('/not-found');
  }
  if (!template) {
    return null;
  }
  if (template && template.CustomPage) {
    return (
      <FileContext.FileProvider
        template={template}
        fileId={params.id}
      >
        <template.CustomPage />
      </FileContext.FileProvider>
    );
  }

  const {
    templateType,
    creditCost,
  } = template;

  return (
    <FileContext.FileProvider
      template={template}
      fileId={params.id}
    >
      <File
        id={params.id}
        templateType={templateType}
        mapGenerateButtonProps={() => ({
          creditDecrementAmount: creditCost,
        })}
      />
    </FileContext.FileProvider>
  );
};

export default GenericFilePage;
