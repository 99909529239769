import React from 'react';

import useStyles from './ShadowLoginInfo.styles';

const ShadowLoginInfo = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.shadowLogin}>
      {`SHADOW LOGGED IN TO: ${user.displayName} (${user.email})`}
    </div>
  );
};

export default ShadowLoginInfo;
