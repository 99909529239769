import React, { FC } from 'react';

import authContext from '@root/resources/auth/auth.context';

import useStyles from './TemplatesUsageSection.styles';

type Props = {
  isLatestRecordSelected?: boolean;
};

const EmptyView: FC<Props> = ({ isLatestRecordSelected }) => {
  const classes = useStyles();
  const { currentUser: user }: { currentUser: any } = authContext.useAuth();

  return (
    <div className={classes.emptyViewContainer}>
      {isLatestRecordSelected && (
        <p className={classes.title}>
          {`Hi ${user?.firstName}, getting started is easy`}
        </p>
      )}

      {isLatestRecordSelected && (
        <p className={classes.subtitle}>
          It&apos;s time to write something amazing! Let your creativity flow and
          create something that you&apos;re proud of.
        </p>
      )}

      {!isLatestRecordSelected && (
        <p className={classes.subtitle}>
          No words were generated for this date range.
        </p>
      )}
    </div>
  );
};

export default EmptyView;
