import React, { useCallback, useEffect, useState } from 'react';

import Modal from '@root/components/modals/Modal';
import ProgressButton from '@root/components/ProgressButton';
import { InputField } from '@root/components/form';
import Button from '@root/components/buttons/Button';

import { modalsActions } from '../../context/confirmationModalsReducer';
import { useCommunityContext } from '../../context/Community.context';

import useStyles from './UploadConfirmationModal.styles';

const UploadConfirmationModal = () => {
  const classes = useStyles();

  const {
    modalsState,
    modalsDispatch,
    uploadToCommunity,
    isTemplateProcessing,
  } = useCommunityContext();

  const { open, template } = modalsState.shareToCommunity;

  const [isSummary, setIsSummary] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [outputExample, setOutputExample] = useState('');

  const isSubmitEnabled = !!templateName && !!outputExample;

  const handleModalClose = () => {
    modalsDispatch({
      type: modalsActions.TOGGLE_SHARE_TO_COMMUNITY_MODAL,
      payload: null,
    });

    // preventing state change flicking
    setTimeout(() => {
      setIsSummary(false);
      setTemplateName('');
      setOutputExample('');
    }, 500);
  };

  const handleCancelClick = () => {
    if (isTemplateProcessing) {
      return;
    }

    if (isSummary) {
      setIsSummary(false);
    } else {
      handleModalClose();
    }
  };

  const handleNextClick = useCallback(async () => {
    if (isTemplateProcessing) {
      return;
    }

    if (!isSummary) {
      setIsSummary(true);
    } else {
      await uploadToCommunity({
        templateId: template?._id,
        templateName,
        outputExample,
      });
      handleModalClose();
    }
  }, [isSummary, setIsSummary, template, templateName, outputExample, isTemplateProcessing]);

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleOutputExampleChange = (e) => {
    setOutputExample(e.target.value);
  };

  // initial data load
  useEffect(() => {
    if (template) {
      setTemplateName(template?.title || '');
      setOutputExample(template?.outputExample || '');
    }
  }, [template, setTemplateName]);

  return (
    <Modal
      title="Add template to community"
      open={open}
      onClose={handleModalClose}
      classNames={{ paper: classes.modal }}
    >
      <div className={classes.content}>
        {!isSummary ? (
          <p className={classes.contentText}>
            Give your template a helpful name for community members to find and use.
            The same template name will also be reflected in your personal copysmith account.
          </p>
        ) : (
          <p className={classes.contentText}>
            Your template will be published for all Copysmith users to use.
            Once your template is saved to a user’s account it cannot
            be removed but you can remove it from being saved by future users.
            Are you sure you want to publish your template?
          </p>
        )}

        {!isSummary && (
          <div className={classes.inputsContainer}>
            <InputField
              label="Name"
              required
              value={templateName}
              onChange={handleTemplateNameChange}
            />

            <InputField
              label="Sample output"
              required
              multiline
              value={outputExample}
              onChange={handleOutputExampleChange}
            />
          </div>
        )}

      </div>

      <div className={classes.buttonsContainer}>
        <Button
          variant="text"
          onClick={handleCancelClick}
          loading={isTemplateProcessing}
        >
          Cancel
        </Button>

        <ProgressButton
          variant="contained"
          onClick={handleNextClick}
          disabled={!isSubmitEnabled}
          isLoading={isTemplateProcessing}
          size="small"
        >
          {!isTemplateProcessing && (!isSummary ? 'Next' : 'Add to community')}
          {isTemplateProcessing && 'Loading...'}
        </ProgressButton>
      </div>
    </Modal>
  );
};

export default UploadConfirmationModal;
