import React from 'react';
import { useParams } from 'react-router-dom';

import useStyles from './EmptySearch.styles';

const EmptySearch = ({ templates }) => {
  const { bucket } = useParams();
  const classes = useStyles();

  if (!bucket || bucket !== 'search') {
    return null;
  }

  if (templates && templates.length > 0) {
    return null;
  }

  return (
    <p className={classes.copy}>
      No matches found.  Try another search term or click a tab to explore!
    </p>
  );
};

export default EmptySearch;
