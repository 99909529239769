import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
  },
  content: {
    flexGrow: 1,
    minHeight: '100%',
    overflow: 'scroll',
  },
}));

export default useStyles;
