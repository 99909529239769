import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.colors.neutrals400,
  },
  rowsAvailable: {
    fontWeight: 600,
  },
}));

export default useStyles;
