import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import MainLayout from '@root/views/Layout/MainLayout';
import authContext from '@root/resources/auth/auth.context';
import { CommunityContextProvider } from '@root/views/Community/context/Community.context';
import config from '@root/config';

import Header from './components/Header/Header';
import FilterBar from './components/FilterBar';
import Content from './components/Content';
import useStyles from './Community.styles';

const Community = () => {
  const classes = useStyles();

  const { currentUser } = authContext.useAuth();

  if (!currentUser.featureToggles.templatesLibrary) {
    return <Redirect to={config.homeRoute} />;
  }

  return (
    <CommunityContextProvider>
      <MainLayout>
        <div className={classes.community}>
          <Header />

          <div className={classes.communityContent}>
            <FilterBar />
            <Content />
          </div>
        </div>
      </MainLayout>
    </CommunityContextProvider>
  );
};

export default Community;
