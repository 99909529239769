import React from 'react';
import { IconTrash } from '@tabler/icons-react';
import { IconButton } from '@material-ui/core';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './DeleteProductButton.styles';

const DeleteProductButton = ({ onClick, selectedItemIds }) => {
  const classes = useStyles();
  const disabled = !selectedItemIds
  || selectedItemIds.length <= 0;

  const getTooltipContent = () => {
    if (!selectedItemIds || selectedItemIds.length <= 0) {
      return 'Select at least one product to delete.';
    }

    return 'Delete';
  };

  return (
    <SimpleTooltip
      content={getTooltipContent()}
      wrapped
      placement="bottom"
    >
      <IconButton
        className={classes.deleteProductButton}
        disabled={disabled}
        onClick={onClick}
      >
        <IconTrash className={classes.startIcon} />
      </IconButton>
    </SimpleTooltip>
  );
};

export default DeleteProductButton;
