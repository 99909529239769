/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select, MenuItem } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import useStyles from './Pagination.styles';
import PageItem from './PageItem';

const ALL_FILES = 2000;
const ALL_FILES_LABEL = 'All';
const PER_PAGE_OPTIONS = [25, 50, 100];

// TODO: should be replaced with PaginationNew after all QA/user testing
const Pagination = ({
  pageCount,
  currentPage,
  onPageChange,
  perPage,
  onPerPageChange,
}) => {
  const classes = useStyles();
  const afterPageNum = pageCount - currentPage;
  const prevPageNum = currentPage - 1;

  const prevPages = Array.from({ length: prevPageNum }, (_, i) => currentPage - (i + 1))
    .reverse()
    .slice(0, afterPageNum < 4 ? 8 - afterPageNum : 4);
  const prevItems = prevPages.map(
    (pageNum, index) => (
      <PageItem
        key={pageNum + index}
        pageNum={pageNum}
        onPageSelect={() => onPageChange(pageNum)}
      />
    ),
  );

  const afterPages = Array.from({ length: afterPageNum }, (_, i) => i + 1 + currentPage)
    .slice(0, 8 - prevPages.length);
  const afterItems = afterPages.map(
    (pageNum, index) => (
      <PageItem
        key={pageNum + index}
        pageNum={pageNum}
        onPageSelect={() => onPageChange(pageNum)}
      />
    ),
  );

  const perPageOptions = PER_PAGE_OPTIONS.map((option) => (
    <MenuItem key={option} value={option}>{option}</MenuItem>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.paginationContainer}>
        <Button
          disabled={currentPage === 1}
          variant="text"
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ArrowBack />
          {' '}
          Previous
        </Button>

        <div className={classes.itemContainer}>
          {prevItems}
          <PageItem pageNum={currentPage} isActive />
          {afterItems}
        </div>
        <Button
          disabled={currentPage === pageCount}
          variant="text"
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
          {' '}
          <ArrowForward />
        </Button>
      </div>

      {perPageOptions && (
        <div className={classes.perPageContainer}>
          <Select
            variant="outlined"
            value={perPage}
            onChange={(e) => onPerPageChange(e.target.value)}
            classes={{
              select: classes.perPageSelect,
              // TODO: Adjust root component border & icon according the Figma design
              // icon: classes.perPageSelectIcon,
            }}
          >
            {perPageOptions}
            <MenuItem value={ALL_FILES}>{ALL_FILES_LABEL}</MenuItem>
          </Select>
          files per page
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  perPage: PropTypes.number.isRequired,
  onPerPageChange: PropTypes.func,
};

Pagination.defaultProps = {
  onPageChange: () => {},
  onPerPageChange: () => {},
};

export default Pagination;
