import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '2rem',
  },
  notificationContainer: {
    width: '95%',
    display: 'flex',
  },
}));

export default useStyles;
