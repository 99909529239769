import { DateTime } from 'luxon';

export const compareFileData = (file, newData) => {
  if (!file || !newData) {
    return false;
  }

  const keys = Object.keys(newData);

  const oldData = {
    ...file.data,
  };

  const oldSerialization = keys.reduce((str, key) => `${str}${oldData[key]}`, '');
  const newSerialization = keys.reduce((str, key) => `${str}${newData[key]}`, '');

  const isEqual = oldSerialization === newSerialization;

  return isEqual;
};

export const getInitialFileSettings = ({
  template,
  company,
  file,
}) => {
  if (file?.aiOptions) {
    return {
      aiOptions: file.aiOptions,
      language: file.language || 'en',
      outputLanguage: file.outputLanguage || 'en',
    };
  }
  if (!template) {
    return null;
  }

  const templateAiOptions = template.aiOptionsByPlan
    ? template.aiOptionsByPlan[company.billing.currentLimitId] || template.aiOptions
    : template.aiOptions;

  const { hasInstruction, templateType } = template;

  return {
    aiOptions: {
      engine: file?.engine || 'default',
      temperature: templateAiOptions.temperature,
      ...(
        // Hack for trial users
        ['contentImprover'].includes(templateType) && {
          max_tokens: templateAiOptions.max_tokens,
        }
      ),
      ...(hasInstruction && {
        max_tokens: templateAiOptions.max_tokens,
        n: templateAiOptions.n,
      }),
    },
    language: file?.language || 'en',
    outputLanguage: file?.outputLanguage || 'en',
  };
};

export const isNewFile = (file) => {
  const newThreshold = DateTime.now().minus({ seconds: 10 }).toSeconds();

  return file.createdOn >= newThreshold;
};
