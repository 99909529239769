import React from 'react';
import { IconChalkboard } from '@tabler/icons-react';
import clsx from 'clsx';

import { useDashboard, actions, selectionTypes } from '@root/views/Dashboard/dashboardContext';
import RootFolderListItemNew from '@root/views/Dashboard/components/FolderSidebarNew/RootFolderListItemNew';
import useFolders from '@root/resources/folder/useFolders';
import { getChildFolders } from '@root/views/Dashboard/helpers';
import ScrollFade from '@root/components/ScrollFade';

import useRootStyles from '../FolderSidebarNew.styles';

import useStyles from './SharedSection.styles';

const SharedSection = ({ rootFolders }) => {
  const classes = useStyles();
  const rootClasses = useRootStyles();

  const {
    state: {
      selected: { type: selectedType },
    }, dispatch,
  } = useDashboard();

  const { data: folders } = useFolders();

  const handleSharedClick = () => {
    dispatch({
      type: actions.SELECT_SHARED_FILES,
      payload: { folderIds: rootFolders.flatMap((f) => {
        const childFolders = getChildFolders(f._id, folders);

        return [...childFolders.map((cf) => cf._id), f._id];
      }) },
    });
  };

  const isActive = selectedType === selectionTypes.SHARED_FILES;

  // rename home folder and put in in front
  const foldersToDisplay = rootFolders
    .map((f) => (f?.isSharedUncategorized ? { ...f, title: 'Other' } : f))
    .sort((a, b) => (a.isSharedUncategorized && -1) || (b.isSharedUncategorized && 1) || 0);

  return (
    <div
      className={rootClasses.sectionContainer}
    >
      <div className={classes.headerWrapper}>
        <div
          className={clsx(classes.header, { [classes.activeHeader]: isActive })}
          onClick={handleSharedClick}
        >
          <div className={classes.titleContainer}>
            <IconChalkboard className={classes.titleIcon} />
            <span>Shared</span>
          </div>
        </div>
      </div>

      <div className={classes.divider} />

      <div className={classes.foldersContainer}>
        <ScrollFade />

        {foldersToDisplay.map((f) => (
          <RootFolderListItemNew
            folder={f}
            key={`folder-sub-list-${f._id}`}
            isDraggable={false}
          />
        ))}
      </div>
    </div>
  );
};

export default SharedSection;
