import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormHelperText, makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import { IconCheck } from '@tabler/icons-react';

import FieldLabel from '@root/components/form/FieldLabel';

import SelectedChip from './components/SelectedChip';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    justifyContent: 'space-between',
  },
  placeholder: {
    color: theme.palette.colors.grey,
  },
}));

const SelectField = ({
  name, required, label, placeholder, labelTooltip, tooltipProps,
  options, error, fullWidth, value, multiple,
  onChange, onBlur, renderOption, helperText, classNames,
  ...props
}) => {
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, , helpers = {}] = name ? useField(name) : [null, null, { setValue: onChange }];
  const { setValue } = helpers;

  const handleOptionDelete = (option) => {
    setValue(value.filter((v) => v !== option.value));
  };

  const renderSelected = (selectedValue) => {
    if (multiple) {
      const selectedOptions = options.filter((option) => selectedValue.includes(option.value));
      if (selectedOptions.length === 0) {
        return <span className={classes.placeholder}>{placeholder}</span>;
      }
      return (
        selectedOptions.map((option) => (
          <SelectedChip
            key={option.value}
            option={option}
            onDelete={handleOptionDelete}
          />
        ))
      );
    }

    const selectedOption = options.find((option) => option.value === selectedValue);
    if (!selectedOption) {
      return <span className={classes.placeholder}>{placeholder}</span>;
    }
    return selectedOption?.label;
  };

  const renderMenuItem = (option) => {
    if (renderOption) {
      return renderOption(option);
    }

    const selected = multiple
      ? value.includes(option.value)
      : option.value === value;

    return (
      <MenuItem
        value={option.value}
        key={option.value}
        className={classes.menuItem}
        disabled={option.disabled}
      >
        {option.label}
        {selected && <IconCheck size={20} />}
      </MenuItem>
    );
  };

  return (
    <div className={classNames.root}>
      <FieldLabel
        label={label}
        name={name}
        labelTooltip={labelTooltip}
        tooltipProps={tooltipProps}
        required={required}
      />
      <Select
        id={name}
        fullWidth={fullWidth}
        variant="outlined"
        value={value}
        onChange={name ? onChange(name) : onChange}
        onBlur={onBlur && onBlur(name)}
        error={!!error}
        multiple={multiple}
        renderValue={renderSelected}
        displayEmpty
        {...props}
      >
        {options.map(renderMenuItem)}
      </Select>

      {(error || helperText) && (
        <FormHelperText error={!!error}>
          {error || helperText}
        </FormHelperText>
      )}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  labelTooltip: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  classNames: PropTypes.objectOf(PropTypes.string),
};

SelectField.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
  error: undefined,
  fullWidth: true,
  labelTooltip: undefined,
  options: [],
  multiple: false,
  classNames: {},
};

export default SelectField;
