import React from 'react';

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33333 7.33333C8.22876 7.33333 7.33333 8.22876 7.33333 9.33333V14.6667C7.33333 15.7712 8.22876 16.6667 9.33333 16.6667H14.6667C15.7712 16.6667 16.6667 15.7712 16.6667 14.6667V9.33333C16.6667 8.22876 15.7712 7.33333 14.6667 7.33333H9.33333ZM6 9.33333C6 7.49238 7.49238 6 9.33333 6H14.6667C16.5076 6 18 7.49238 18 9.33333V14.6667C18 16.5076 16.5076 18 14.6667 18H9.33333C7.49238 18 6 16.5076 6 14.6667V9.33333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 10.6666C11.2635 10.6666 10.6666 11.2636 10.6666 12C10.6666 12.7364 11.2635 13.3333 11.9999 13.3333C12.7363 13.3333 13.3333 12.7364 13.3333 12C13.3333 11.2636 12.7363 10.6666 11.9999 10.6666ZM9.33325 12C9.33325 10.5272 10.5272 9.33331 11.9999 9.33331C13.4727 9.33331 14.6666 10.5272 14.6666 12C14.6666 13.4727 13.4727 14.6666 11.9999 14.6666C10.5272 14.6666 9.33325 13.4727 9.33325 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 8.33331C15.3681 8.33331 15.6666 8.63179 15.6666 8.99998V9.00065C15.6666 9.36884 15.3681 9.66731 14.9999 9.66731C14.6317 9.66731 14.3333 9.36884 14.3333 9.00065V8.99998C14.3333 8.63179 14.6317 8.33331 14.9999 8.33331Z"
    />
  </svg>
);
