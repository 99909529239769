import useFile from './useFile';
import useCreateOrUpdateFile from './useCreateOrUpdateFile';
import useCampaigns from './useCampaigns';
import useCustomers from './useCustomers';
import useAdGroups from './useAdGroups';
import useCreateAdGroup from './useCreateAdGroup';
import useCreateOrUpdateAd from './useCreateOrUpdateAd';
import useGetKeywords from './useGetKeywords';

export default {
  useFile,
  useCreateOrUpdateFile,
  useCampaigns,
  useCustomers,
  useAdGroups,
  useCreateAdGroup,
  useCreateOrUpdateAd,
  useGetKeywords,
};
