import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    padding: '2px 8px',
    borderRadius: 6,

    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
  },
  small: {
    padding: '4.5px 8px',
  },
  needsReview: {
    backgroundColor: '#FFCCDA80',
    color: theme.palette.colors.pink600,
  },
  returned: {
    backgroundColor: '#FFE59980',
    color: theme.palette.colors.yellow600,
  },
  approved: {
    backgroundColor: '#CEFDE180',
    color: theme.palette.colors.green600,
  },
  disabled: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    color: theme.palette.colors.grey,
  },
  dash: ({ newFilesPage }) => ({
    color: newFilesPage ? theme.palette.colors.neutrals200 : theme.palette.colors.neutrals600,
  }),
}));

export default useStyles;
