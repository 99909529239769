import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { IconAlertCircle } from '@tabler/icons-react';
import { LinearProgress } from '@material-ui/core';

import AIImage from '@root/views/Editor/components/AIImage';
import kbArticles from '@root/utils/kbArticles';

import useStyles from './AIImageView.styles';

const AIImageView = (
  { aiImageGenerations,
    documentId,
    generatedImages,
    handleInsertImageBlock,
    historicImages,
    isError,
    isSensitive,
    isGeneratedImagesLoading },
) => {
  const [aiImages, setAiImages] = useState([]);
  const classes = useStyles({ selectedImagesCount: aiImages.filter((image) => image.selected).length });

  useEffect(() => {
    // For now, generated images will replace view of historic images
    // Future iteration will append generated images to historic images in view
    if (generatedImages && generatedImages.length > 0) {
      setAiImages(generatedImages);
    } else if (historicImages && historicImages.length > 0) {
      setAiImages(historicImages.map((image) => { return { ...image, selected: false, visible: true }; }));
    }
  }, [historicImages, generatedImages]);

  const onExpandOrMinimizeImage = async (image) => {
    let images;

    if (!image && aiImages.length > 0) {
      images = aiImages.map((item) => { return { ...item, selected: false, visible: true }; });
    } else {
      images = aiImages.map(
        (item) => { return { ...item, selected: image._id === item._id, visible: image._id === item._id }; },
      );
    }
    setAiImages(images);
  };

  const onActionClick = async (image) => {
    const updatedAiImages = aiImages.map(
      (aiImage) => { return aiImage._id === image._id ? { ...image } : { ...aiImage }; },
    );
    setAiImages(updatedAiImages);
  };

  return (
    <div className={classes.aiImageWrapper}>
      <div className={classes.aiImageTopBar}>
        <div className={clsx(classes.aiImageCredits, aiImageGenerations === 0 && classes.noCredits)}>
          Total credits:&nbsp;
          <b>{aiImageGenerations}</b>
        </div>
      </div>
      <div className={classes.aiImageGridWrapper}>
        {isGeneratedImagesLoading || isError || isSensitive
          ? (
            <div className={classes.loadingOrError}>
              { isGeneratedImagesLoading
                ? (
                  <div className={classes.loading}>
                    <LinearProgress className={classes.loadingBar} />
                    <div>Hold on a moment. A work of art is being crafted!</div>
                  </div>
                )
                : (isError || isSensitive) && (
                  <div className={classes.errorContainer}>
                    <div className={classes.error}>
                      <IconAlertCircle className={classes.errorIcon} />
                      <div className={classes.errorText}>
                        {isSensitive ? (
                          <>
                            Please revisit your prompt. Learn more in our knowledge base.
                            {' '}
                            <a
                              target="__blank"
                              href={kbArticles.aiImages}
                            >
                              Knowledge Base
                            </a>
                          </>
                        ) : (
                          <>
                            Oops! Something went wrong.
                            <br />
                            Please, try again.
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )
          : (
            <div className={classes.aiImageGrid}>
              { aiImages && aiImages.map((image) => (
                <AIImage
                  key={image._id}
                  documentId={documentId}
                  image={image}
                  onActionClick={onActionClick}
                  onImageClick={handleInsertImageBlock}
                  onExpandOrMinimizeImage={onExpandOrMinimizeImage}
                  selected={image.selected}
                  visible={image.visible}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default AIImageView;
