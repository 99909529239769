import React, { useState } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import userService from '@root/resources/user/user.service';
import AuthContext from '@root/resources/auth/auth.context';
import api from '@root/api';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import ProfileIcon from '@root/components/ProfileIcon';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import { FEATURES } from '@root/views/Addons/addonsConstants';
import FeatureModal from '@root/components/modals/FeatureModal';
import useBillingInfo from '@root/resources/billing/useBillingInfo';

import useStyles from '../TeamManagement.styles';

const DeactivatedList = ({
  freeMembersExceeded,
}) => {
  const classes = useStyles();
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const {
    refetch: reloadTeamMembers,
    data: membersDataAll,
  } = useTeamMembers({ allMembers: true });
  const { data: billingInfoData = { addons: {} }, refetch: reloadBillingInfo } = useBillingInfo();

  const deactivatedMembers = membersDataAll.results.filter((m) => m.deactivated);
  const defaultConfirmModalState = { isOpen: false, userId: null, userDisplayName: '', isLoading: false };
  const defaultMaxMembersModalState = { isOpen: false };
  const [confirmModalState, setConfirmModalState] = useState(defaultConfirmModalState);
  const [maxMemberModalState, setMembersModalState] = useState(defaultMaxMembersModalState);
  const [featureModalOpened, setFeatureModalOpened] = useState(false);
  const { memberPrice } = currentCompany.billing;

  const handleReactivateUser = async (userId) => {
    setConfirmModalState({ ...confirmModalState, isLoading: true });
    await api.user.reactivateCompanyMember(userId);
    await reloadTeamMembers();
    setConfirmModalState({ ...confirmModalState, isLoading: false });
  };
  const roles = userService.getRoles(currentUser);
  const isAdminOrOwner = roles.includes('admin') || roles.includes('owner');

  const handleReactivateClick = (member) => () => {
    if (freeMembersExceeded && !memberPrice) {
      setMembersModalState({ isOpen: true });
    } else {
      setConfirmModalState({
        isOpen: true, userId: member._id, userDisplayName: member.displayName, isLoading: false,
      });
    }
  };
  const currentAdditionalMemberQuantity = billingInfoData.addons[FEATURES.additionalMembers.type];

  return (
    <>
      <ConfirmationModal
        open={confirmModalState.isOpen}
        onClose={() => { setConfirmModalState(defaultConfirmModalState); }}
        onConfirm={() => handleReactivateUser(confirmModalState.userId)}
        title="Confirm reactivation"
        confirmButtonText={confirmModalState.isLoading ? 'Processing' : 'Reactivate'}
        isConfirmDisabled={confirmModalState.isLoading}
      >
        You are about to reactivate
        <b>{` ${confirmModalState.userDisplayName} `}</b>
        as a team member. Do you wish to proceed?
      </ConfirmationModal>

      <ConfirmationModal
        open={maxMemberModalState.isOpen}
        onClose={() => { setMembersModalState(defaultMaxMembersModalState); }}
        onConfirm={() => {
          setMembersModalState({ isOpen: false });
          if (billingInfoData?.rank !== 400) {
            setFeatureModalOpened(true);
          }
        }}
        noCancel
        text={`You have filled all available team seats on your team. ${billingInfoData?.rank === 400 ? 'Contact our sales team to add more seats.' : 'Add seats to your plan to add more team members.'}`}
        title="Members exceeded"
        confirmButtonText={billingInfoData?.rank === 400 ? 'Confirm' : 'Add more seats'}
      />

      {featureModalOpened && (
        <FeatureModal
          type={FEATURES.additionalMembers.type}
          currentQuantity={currentAdditionalMemberQuantity}
          onClose={() => {
            setFeatureModalOpened(false);
            reloadBillingInfo();
          }}
        />
      )}

      {deactivatedMembers.map((member) => {
        const memberRoles = userService.getRoles(member);
        const highestRole = userService.getHighestRole(memberRoles);
        return (
          <tr className={classes.tableTr} key={member._id}>
            <td className={clsx(classes.cell, classes.memberName)}>
              <div className={classes.nameContainer}>
                <div className={classes.deactivated}>
                  <ProfileIcon
                    className={classes.teamsProfileIcon}
                    name={member.displayName || member.email}
                  />
                </div>
                <div className={clsx(classes.memberInfo, classes.greyLable)}>
                  <div>
                    {member.displayName}
                  </div>
                  <div className={clsx(classes.memberEmail, classes.greyLable)}>
                    {member.email}
                  </div>
                </div>
              </div>
            </td>
            <td className={clsx(
              classes.cell,
              classes.memberAccessLevel,
              classes.greyLable,
            )}
            >
              {highestRole}
            </td>
            <td className={clsx(classes.cell, classes.memberActions)}>
              {isAdminOrOwner ? (
                <Button
                  variant="text"
                  onClick={handleReactivateClick(member)}
                  className={classes.reactivateButton}
                >
                  Reactivate member
                </Button>
              ) : (
                <div>-</div>
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default DeactivatedList;
