const lowerCaseFirstLetter = (str) => {
  if (!str) {
    return str;
  }
  return `${str[0].toLowerCase()}${str.slice(1)}`;
};

const upperCaseFirstLetter = (str) => {
  if (!str) {
    return str;
  }
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

const lowerCaseObject = (data) => {
  const fileFields = {};
  Object.keys(data).forEach((k) => {
    fileFields[lowerCaseFirstLetter(k)] = data[k];
  });

  return fileFields;
};

const upperCaseObject = (data) => {
  const fileFields = {};
  Object.keys(data).forEach((k) => {
    fileFields[upperCaseFirstLetter(k)] = data[k];
  });

  return fileFields;
};

const bodyToNewApiFormat = (data) => {
  const fileFields = {};
  Object.keys(data).forEach((k) => {
    let newKey = lowerCaseFirstLetter(k);
    if (newKey === 'moreLikeThisDict') {
      // deprecated
      return;
    }
    if (newKey === 'language') {
      // not stored in file fields
      return;
    }
    if (newKey === 'negativeKeywordList') {
      // not stored in fileFields
      return;
    }
    if (newKey === 'company') {
      // rename company -> companyName
      newKey = 'companyName';
    }
    if (newKey === 'about') {
      // pressRelease generation
      newKey = 'companyDescription';
    }
    fileFields[newKey] = data[k];
  });

  return fileFields;
};

const documentsToOldApiFormat = (documents) => documents.map((doc) => ({
  content: doc.content,
  liked: doc.liked,
  date: doc.createdOn,
  id: doc._id,
  votes: doc.votes,
}));

const fileToOldApiFormat = (file) => {
  const fileData = {
    ...(file.data || {}),
  };
  Object.keys(file.data || {}).forEach((property) => {
    let newKey = upperCaseFirstLetter(property);
    if (property === 'companyName') {
      if (file.templateType === 'landingPage') {
        newKey = 'companyName';
      } else {
        newKey = 'Company';
      }
    }
    if (file.templateType === 'landingPage' && property === 'companyDescription') {
      newKey = 'companyDescription';
    }
    if (file.templateType === 'pressRelease' && property === 'companyDescription') {
      newKey = 'about';
    }
    if (property === 'createdOn') {
      fileData.dateCreated = file.data[property];
    }
    if (property === 'updatedOn') {
      fileData.lastModified = file.data[property];
    }
    fileData[newKey] = file.data[property];
  });

  return {
    id: file._id,
    generation: file.templateType,
    ...file,
    data: fileData,
    ...fileData,
  };
};

const attributesToOldApiFormat = (attributes, templateType) => attributes.map((a) => {
  if (a === 'companyName') {
    return 'Company';
  }
  if (templateType === 'pressRelease' && a === 'companyDescription') {
    return 'about';
  }
  return upperCaseFirstLetter(a);
});

const newsToOldApiFormat = (news) => ({
  id: news._id,
  date: news.createdOn,
  content: news.content,
  link: news.link,
});

export default {
  lowerCaseObject,
  upperCaseObject,
  bodyToNewApiFormat,
  fileToOldApiFormat,
  documentsToOldApiFormat,
  attributesToOldApiFormat,
  newsToOldApiFormat,
};
