import React from 'react';

import CreateModal from './CreateModal/CreateModal';
import ExampleModal from './ExampleModal/ExampleModal';

const Modals = () => {
  return (
    <>
      <CreateModal />
      <ExampleModal />
    </>
  );
};

export default Modals;
