/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Backdrop, Fade, Modal,
  Button,
} from '@material-ui/core';

import Loading from '@root/components/Loading/Loading';
import api from '@root/api';
import config from '@root/config';

import useStyles from './RenewModal.styles';

const RenewError = ({ error }) => {
  const classes = useStyles();

  // should always be overwritten
  let message = 'There was a problem with renewing your account';
  if (typeof error === 'string') {
    message = error;
  } else if (error.message) {
    message = error.message;
  }
  return (
    <div
      className={classes.renewError}
    >
      <h3>An Error has Occurred:</h3>
      <p>{message}</p>
    </div>
  );
};

const RenewResult = () => {
  const classes = useStyles();

  return (
    <div
      className={classes.renewMargins}
    >
      <p>
        Your subscription has been renewed! If you have any questions or
        concerns please reach out to&nbsp;
        <a href={`mailto:${config.contactSupportEmail}`}>
          {config.contactSupportEmail}
        </a>
      </p>

      <h3 className={classes.b05rem}>
        <b>Welcome Back!</b>
      </h3>
    </div>
  );
};

const Conditional = ({
  loading, error, renew, handleClose, didComplete,
}) => {
  const classes = useStyles();

  if (loading) {
    return (<Loading />);
  }
  if (error) {
    return (<RenewError error={error} />);
  }
  if (didComplete) {
    return <RenewResult />;
  }
  return (

    <div
      className={classes.renewContainer}
    >
      <p>
        <b>You are about to renew your cancelled subscription</b>
      </p>
      <p>
        You will not be charged for renewal and billing will resume as
        if you had never cancelled.
      </p>
      <div
        className={classes.buttonContainer}
      >
        <Button
          variant="outlined"
          className={classes.upgradeButton}
          onClick={handleClose}
        >
          Maybe Later
        </Button>
        <Button onClick={renew}>
          Renew Subscription
        </Button>
      </div>
    </div>
  );
};

const RenewModal = ({ modalOpen, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [didComplete, setDidComplete] = useState();
  const [error, setError] = useState();

  const classes = useStyles();

  const handleClose = () => {
    if (didComplete) {
      window.location.reload();
    } else {
      setModalOpen(false);
    }
  };

  const renew = async () => {
    setLoading(true);
    try {
      const response = await api.billing.renewSubscription({});
      if (response.subscription) {
        setDidComplete(true);
      } else if (response.error) {
        setError(response.error);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={clsx(classes.small, classes.prelative)}>
          <div className={classes.header}>
            <span className={classes.renewSubscriptionText}>
              Renew Subscription For Next Month
            </span>
          </div>
          <div
            className={classes.closeIcon}
            onClick={handleClose}
          >
            ×
          </div>
          <Conditional
            error={error}
            loading={loading}
            renew={renew}
            handleClose={handleClose}
            didComplete={didComplete}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default RenewModal;
