import { useQuery } from 'react-query';

import api from '@root/api';

const getFile = async (id) => {
  const file = await api.files.getById(id);

  return file;
};

export default function useFile(id) {
  return useQuery(
    ['files.getById', id],
    () => getFile(id),
    { refetchOnMount: 'always' },
  );
}
