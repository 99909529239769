import React from 'react';
import { useHistory } from 'react-router-dom';
import { truncate } from 'lodash';
import { IconCircleLetterW } from '@tabler/icons-react';

import AuthContext from '@root/resources/auth/auth.context';
import BetaBadge from '@root/components/Badges/BetaBadge';
import billingResource from '@root/resources/billing';
import ShareFileButton from '@root/components/ShareFileButton';
import TemplatesContext from '@root/resources/templates/templates.context';
import NavbarLayout from '@root/views/Layout/NavbarLayout';
import ShadowLoginInfo from '@root/views/Layout/components/ShadowLoginInfo';
import BackButton from '@root/views/Layout/components/BackButton/BackButton';

import useStyles from './EditorNav.styles';

const EditorNav = ({
  saveAndExit,
  backUrl,
  isBeta,
  folder,
  parentFolder,
  onTitleChange,
  updateTitle,
  file,
  fileTitle,
  fileTemplateType,
  lastModified: externalLastModified,
}) => {
  const { currentUser } = AuthContext.useAuth();
  const history = useHistory();

  const classes = useStyles();

  const { _id: folderId } = folder || {};
  const { _id: fileId, updatedOn } = file || {};

  const lastModified = externalLastModified || updatedOn;

  const { data: { blogPostLimit: blogCreditsLeft,
    limit: creditsLeft,
    enterpriseDisplayLimit } = {} } = billingResource.useLimits();

  const { templates } = TemplatesContext.useTemplates();

  const isBlogPost = fileTemplateType === 'blogPost';

  const folderPermissions = currentUser.permissions.getFolderPermissions(folder);
  const {
    canRenameFolderFiles,
  } = folderPermissions;
  const folderRoles = currentUser.roles.getFolderRoles(folder);

  const isRenameDisabled = React.useMemo(() => {
    if (!file) {
      return false;
    }

    if (folderRoles.viewer) {
      return true;
    }

    if (canRenameFolderFiles) {
      return false;
    }

    return file.userId !== currentUser._id;
  }, [currentUser, file, folder]);

  const customBackUrl = history.location?.state?.prevPathName;
  const prevLinkSelected = history.location?.state?.prevLinkSelected;
  const isWorkflowSelected = prevLinkSelected && prevLinkSelected.toLowerCase() === 'workflow';
  const isHomeFolder = folder && folder.home;
  const isHomeOwnedFolder = folder && folder.unifiedAcl.find((acl) => acl.userId === currentUser._id && acl.role === 'owner');

  const folderTitle = isHomeFolder ? 'Other' : folder && folder.title;

  const folderLink = `/templates/${parentFolder ? 'folder' : 'project'}/${folderId}`;
  const homeFolderLink = isHomeFolder
    && isHomeOwnedFolder ? `/templates/project/${folderId}` : '/templates/shared_uncategorized';

  const lastModifiedString = lastModified && new Date(lastModified * 1000).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  const showCredits = () => {
    if (!currentUser.showCredits) {
      return null;
    }
    if (isBlogPost) {
      if (Number.isInteger(blogCreditsLeft)) {
        return `${blogCreditsLeft} Blog Credits`;
      }
    }
    if (Number.isInteger(creditsLeft)) {
      return (
        <span className={classes.credits}>
          {enterpriseDisplayLimit !== null ? enterpriseDisplayLimit : creditsLeft}
          <IconCircleLetterW size={18} />
        </span>
      );
    }
    return null;
  };

  return (
    <NavbarLayout>

      <BackButton
        backButtonCallBack={saveAndExit({ backUrl: customBackUrl || backUrl })}
        backUrl={customBackUrl || backUrl}
        backButtonText="Save and Exit"
      />

      {currentUser.isShadowLogin && <ShadowLoginInfo user={currentUser} />}

      <div className={classes.title}>
        {isBeta && <BetaBadge isNavbar />}
        {isWorkflowSelected ? (
          <div
            className={classes.navbarCrumb}
            onClick={() => {
              saveAndExit({ backUrl: '/profile/team-workflow' })();
            }}
          >
            My workflow items /
            &nbsp;
          </div>
        ) : (
          <>
            <div
              className={classes.navbarCrumb}
              onClick={() => {
                saveAndExit({ backUrl: `/templates/project/${parentFolder._id}` })();
              }}
            >
              {parentFolder ? `${truncate(parentFolder.title, { length: 15 })} /` : ''}
                &nbsp;
            </div>
            <div
              className={classes.navbarCrumb}
              onClick={() => {
                saveAndExit({ backUrl: isHomeFolder ? homeFolderLink : folderLink })();
              }}
            >
              {`${truncate(folderTitle, { length: 15 })} / `}
              &nbsp;
            </div>
          </>
        )}
        <input
          disabled={isRenameDisabled}
          className={classes.titleInput}
          onChange={onTitleChange}
          onBlur={updateTitle}
          value={fileTitle}
        />
      </div>

      <div className={classes.rightSide}>
        {lastModified && (
          <div className={classes.fileInfo}>
            <span className={classes.templateType}>{templates[fileTemplateType].title}</span>
            <i>{`Autosaved ${lastModifiedString}`}</i>
          </div>
        )}

        {showCredits()}

        {fileId && <ShareFileButton fileId={fileId} />}
      </div>
    </NavbarLayout>

  );
};

export default EditorNav;
