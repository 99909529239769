import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { StringUtil } from '@copysmith/utils';

import useLimits from '@root/resources/billing/useLimits';
import aiResource from '@root/resources/ai';

const trimNChars = (str, n) => {
  const trimmed = str.trim();
  if (trimmed.length <= n) {
    return str;
  }
  return `${trimmed.slice(0, n)}...`;
};

const useStyles = makeStyles((theme) => ({
  plagiarismChecks: {
    marginTop: '1.5rem',
    fontWeight: 'bolder',
  },
  plagiarismChecksError: {
    fontWeight: 200,
    color: 'red',
  },
  redText: {
    color: 'red',
  },
  modalContent: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '1rem',
  },
}));

const usePlagiarismCheck = (lastHighlightedText, setModalData, setTooltipData) => {
  const [text, setText] = React.useState(lastHighlightedText);
  const classes = useStyles();

  const { data: { plagiarismCheckLimit: numberOfPlagiarismChecks } = {} } = useLimits();
  const { mutateAsync: checkPlagiarismRequest } = aiResource.usePlagiarismCheck();

  React.useEffect(() => {
    if (lastHighlightedText) {
      setText(lastHighlightedText);
    }
  }, [lastHighlightedText]);

  const plagiarismCheck = React.useMemo(() => {
    const wordsCount = text ? StringUtil.countWordsString(text) : 0;
    const wordsCountInvalid = wordsCount < 15 || wordsCount > 200;
    const insufficientlyChecks = numberOfPlagiarismChecks === 0;

    return {
      wordsCount,
      wordsCountInvalid,
      checksCount: numberOfPlagiarismChecks,
      insufficientlyChecks,
      error: insufficientlyChecks || wordsCountInvalid,
    };
  }, [text, numberOfPlagiarismChecks]);

  const checkPlagiarism = React.useCallback(async () => {
    if (plagiarismCheck.error) {
      return;
    }

    const [checkTitle, checkContent] = await checkPlagiarismRequest({ text });
    setModalData(
      {
        title: checkTitle.replace('0 results found', 'No matches found'),
        body: checkContent.includes('<p>') ? (
          <div>{ReactHtmlParser(checkContent)}</div>
        ) : (
          <div>Plagiarism check passed!</div>
        ),
      },
    );
    setText('');
  }, [plagiarismCheck, text]);

  React.useEffect(() => {
    setTooltipData(
      <span>
        Check copy uniqueness:
        {' '}
        <br />
        {text && text.length > 0 ? (
          `"${trimNChars(text, 800)}"`
        ) : (
          <i>Highlight text to check</i>
        )}
        {!!plagiarismCheck.wordsCount && (
          <div
            className={clsx({
              [classes.redText]: plagiarismCheck.wordsCountInvalid,
            })}
          >
            {`${plagiarismCheck.wordsCount} / 200 words (Minimum 15)`}
          </div>
        )}
        {Number.isInteger(numberOfPlagiarismChecks) && (
          <div
            className={clsx(classes.plagiarismChecks, {
              [classes.plagiarismChecksError]: plagiarismCheck.insufficientlyChecks,
            })}
          >
            {plagiarismCheck.checksCount}
            {' '}
            checks available
            {' '}
          </div>
        )}
      </span>,
    );
  }, [plagiarismCheck]);

  return {
    numberOfPlagiarismChecks,
    plagiarismCheck,
    checkPlagiarism,
    text,
  };
};

export default usePlagiarismCheck;
