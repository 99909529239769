import filterTemplatesByBucket from './filterTemplatesByBucket';

const madeForYou = (list) => {
  const filterList = filterTemplatesByBucket(list, 'made-for-you')
    .filter((item) => !item.isFavorite);
  const favoritesList = list.filter((item) => item.isFavorite);

  return favoritesList.concat(filterList);
};

export default madeForYou;
