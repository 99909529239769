import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const updateBulkDescription = async ({
  productId,
  description,
}) => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.updateBulkDescription({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
    productId,
    description,
  });

  return products;
};

export default function useUpdateBulkProductDescription() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ productId, description }) => updateBulkDescription({ productId, description }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigCommerce.getCurrentBulkJob']);
      },
    },
  );
}
