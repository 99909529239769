import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formGroup: {
    '& > :not(:last-child)': {
      marginBottom: 16,
    },
  },
}));

const FormGroup = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {children}
    </div>
  );
};

export default FormGroup;
