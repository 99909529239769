import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

import useInvalidateLimits from './useInvalidateLimits';

export default function useCreateOveragesSettings() {
  const queryClient = useQueryClient();
  const { invalidateLimits } = useInvalidateLimits();

  return useMutation(
    (data) => api.billing.createOveragesSettings(data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.setQueryData(['billing.getInfo', {}], (billingInfo) => ({
          ...billingInfo,
          maxOverageUnits: response.maxOverageUnits,
          overageTierWordCount: response.overageTierWordCount,
          overageTierPrice: response.overageTierPrice,
          overageTier: response.overageTier,
          overageUnitsRemaining: response.overageUnitsRemaining,
          overageInvoiceStatus: response.overageInvoiceStatus,
        }));

        invalidateLimits();
        return response;
      },
    },
  );
}
