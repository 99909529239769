import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  planCardRoot: {
    backgroundColor: theme.palette.colors.white,
    flex: 1,
    padding: '36px 48px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  planInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  featureRow: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 8,
    textAlign: 'center',
  },
  separator: {
    margin: '16px 0',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
  },
  banner: {
    position: 'absolute',
    top: 0,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: ({ isSuspended }) => (
      isSuspended
        ? theme.palette.colors.mediumLightGrey
        : theme.palette.colors.yellow
    ),
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
  },
  buttonWrapper: {
    marginTop: 24,
  },
  semibold: {
    fontWeight: 500,
  },
  suspended: {
    color: `${theme.palette.colors.grey}`,
    '& div': {
      color: `${theme.palette.colors.grey}`,
    },
  },
  tierSlider: {
    marginBottom: 7,
  },
}));
