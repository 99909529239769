const example = {
  title: 'Ad Headline',
  description: 'For banner ads or billboards or anything in-between.',
  input: [
    {
      title: 'Company Name',
      value: 'Candlesmith',
    },
    {
      title: 'Audience',
      value: 'Broad',
    },
    {
      title: 'Company Description',
      value:
        'Our candles bring the feeling of the outdoors to your home',
    },
    {
      title: 'Keywords',
      value: 'Organic, Home',
    },
  ],
  output: {
    _id: '1',
    templateType: 'adHeadline',
    liked: false,
    content: 'Candlesmith candles will make your house a home.',
  },
  fileData: {
    companyName: 'Candlesmith',
  },
};

export default example;
