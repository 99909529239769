import React from 'react';
import clsx from 'clsx';

import useStyles from './FeatureRow.styles';

const FeatureRow = ({
  title, columns = [], isBold, noPadding, classNames = {},
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.rowWrapper, classNames.rowWrapper)}>
      <div className={clsx(classes.rowTitle, {
        [classes.bold]: isBold,
        [classes.noPadding]: noPadding,
      })}
      >
        {title}
      </div>
      {columns.map((c, i) => (!c ? null : (
        <div
          key={i} // eslint-disable-line react/no-array-index-key
          className={clsx(classes.rowItem)}
        >
          {c}
        </div>
      )))}
    </div>
  );
};

export default FeatureRow;
