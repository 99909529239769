import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function usePublishDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id, data }) => api.documents.publish(_id, data),
    {
      onSuccess: ({ fileId }) => {
        const queryKey = ['documents.list', { fileId }];
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
}
