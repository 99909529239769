import React from 'react';

import DateUtil from '@root/utils/date.util';
import PreviewButton from '@root/views/File/templateCustomizations/landingPage/components/PreviewButton';
import DocumentView from '@root/components/DocumentView';

import googleAdExample from './atomExamples/googleAd.example';
import productDescriptionExample from './atomExamples/productDescription.example';
import facebookAdExample from './atomExamples/facebookAd.example';
import seoMetatagExample from './atomExamples/seoMetatag.example';
import landingPageExample from './atomExamples/landingPage.example';
import salesEmailExample from './atomExamples/salesEmail.example';
import linkedinMessagesExample from './atomExamples/linkedinMessages.example';
import faqIdeasExample from './atomExamples/faqIdeas.example';
import baseExample from './atomExamples/base.example';

// google ads constants
const MAX_GOOGLEAD_HEADLINE_CHARS = 30;
const MAX_GOOGLEAD_DESCRIPTION_CHARS = 90;

// maximum primary text characters for facebook image ads
const MAX_FACEBOOK_PRIMARYTEXT_CHARS = 125;
const MAX_FACEBOOK_HEADLINES_CHARS = 40;
const MAX_FACEBOOK_DESCRIPTION_CHARS = 30;

// search results page max numbers
const MAX_SERP_DESCRIPTION_CHARS = 160;
const MAX_SERP_TITLE_CHARS = 65;

const DEFAULT_OPT = {
  showChar: false,
  canDelete: true,
  canEdit: true,
  canLike: true,
  canCopy: true,
  canFlag: true,
};

export default [
  {
    name: 'Common Atom',
    component: (
      <DocumentView
        key={baseExample.output.id}
        document={baseExample.output}
        selected={false}
        saveEditor={false}
        wordCount={[
          {
            title: 'Content',
            value: baseExample.output.content,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Common Atom (new)',
    component: (
      <DocumentView
        key={baseExample.output.id}
        document={{ ...baseExample.output, createdOn: DateUtil.getNowSeconds() }}
        saveEditor={false}
        wordCount={[
          {
            title: 'Content',
            value: baseExample.output.content,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Linkedin Messages',
    component: (
      <DocumentView
        key={linkedinMessagesExample.output.id}
        fileData={linkedinMessagesExample.fileData}
        document={linkedinMessagesExample.output}
        saveEditor={false}
        wordCount={[
          {
            title: 'Message',
            value: linkedinMessagesExample.output.content,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'FAQ Ideas',
    component: (
      <DocumentView
        key={faqIdeasExample.output.id}
        fileData={faqIdeasExample.fileData}
        document={faqIdeasExample.output}
        saveEditor={false}
        wordCount={[
          {
            title: 'Content',
            value: faqIdeasExample.output.content,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Facebook Ad Atom',
    component: (
      <DocumentView
        key={facebookAdExample.output.id}
        document={facebookAdExample.output}
        fileData={facebookAdExample.fileData}
        saveEditor={false}
        wordCount={[{
          title: 'Primary',
          value: facebookAdExample.output.content[2],
          limit: MAX_FACEBOOK_PRIMARYTEXT_CHARS,
        }, {
          title: 'Headline',
          value: `${facebookAdExample.output.content[1]} ${facebookAdExample.output.content[0]}`,
          limit: MAX_FACEBOOK_HEADLINES_CHARS + MAX_FACEBOOK_DESCRIPTION_CHARS,
        },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Product Description Atom',
    component: (
      <DocumentView
        key={productDescriptionExample.output.id}
        document={productDescriptionExample.output}
        fileData={productDescriptionExample.fileData}
        saveEditor={false}
        wordCount={[
          {
            title: 'Content',
            value: productDescriptionExample.output.content,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Google Ad Atom',
    component: (
      <DocumentView
        key={googleAdExample.output.id}
        fileData={googleAdExample.fileData}
        document={googleAdExample.output}
        saveEditor={false}
        wordCount={[
          {
            title: 'Headline 1',
            value: googleAdExample.output.content.headline1 || '',
            limit: MAX_GOOGLEAD_HEADLINE_CHARS,
          },
          {
            title: 'Headline 2',
            value: googleAdExample.output.content.headline2 || '',
            limit: MAX_GOOGLEAD_HEADLINE_CHARS,
          },
          {
            title: 'Headline 3',
            value: googleAdExample.output.content.headline3 || '',
            limit: MAX_GOOGLEAD_HEADLINE_CHARS,
          },

        ]}
        wordCountSecondRow={[
          {
            title: 'Description 1',
            value: googleAdExample.output.content.description1 || '',
            limit: MAX_GOOGLEAD_DESCRIPTION_CHARS,
          },
          {
            title: 'Description 2',
            value: googleAdExample.output.content.description2 || '',
            limit: MAX_GOOGLEAD_DESCRIPTION_CHARS,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'SEO Metatag Atom',
    component: (
      <DocumentView
        key={seoMetatagExample.output.id}
        fileData={seoMetatagExample.fileData}
        document={seoMetatagExample.output}
        saveEditor={false}
        wordCount={[
          {
            title: 'Title',
            value: seoMetatagExample.output.content[0],
            limit: MAX_SERP_TITLE_CHARS,
          },
          {
            title: 'Content',
            value: seoMetatagExample.output.content[1],
            limit: MAX_SERP_DESCRIPTION_CHARS,
          },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  {
    name: 'Landing Page Atom',
    component: (
      <DocumentView
        key={landingPageExample.output.id}
        fileData={landingPageExample.fileData}
        document={landingPageExample.output}
        saveEditor={false}
        wordCount={[]}
        options={DEFAULT_OPT}
        customFooterAction={<PreviewButton documentId={document._id} />}
      />
    ),
  },
  {
    name: 'Sales Email Atom',
    component: (
      <DocumentView
        key={salesEmailExample.output.id}
        fileData={salesEmailExample.fileData}
        document={salesEmailExample.output}
        saveEditor={false}
        wordCount={[{
          title: 'Subject',
          value: salesEmailExample.output.content ? salesEmailExample.output.content.subject : '',
        }, {
          title: 'Body',
          value: salesEmailExample.output.content ? salesEmailExample.output.content.emailBody : '',
        },
        ]}
        options={DEFAULT_OPT}
        onCopy={() => {}}
      />
    ),
  },
  // {
  //   name: 'FAQ Ideas Atom',
  //   component: (
  //     <DocumentView
  //       key={faqIdeasExample.output.id}
  //       fileData={faqIdeasExample.fileData}
  //       document={faqIdeasExample.output}
  //       saveEditor={false}
  //       wordCount={[{
  //         title: 'Content',
  //         value: faqIdeasExample.output.content,
  //       }]}
  //       options={DEFAULT_OPT}
  //     />
  //   ),
  // },
];
