import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  aiImage: {
    borderRadius: '6px',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  aiImageContainer: {
    display: 'flex',
    position: 'relative',
    gap: ({ selectedImages }) => (selectedImages === 1 ? '0px' : '20px'),
    height: ({ visible }) => (!visible && '0px'),
    visibility: ({ visible }) => visible,
    gridColumn: ({ selected }) => (selected ? 'span 2' : 'span 1'),
    gridRow: ({ selected }) => (selected ? 'span 2' : 'span 1'),
  },
  aiImageOverlay: {
    borderRadius: '6px',
    height: '100%',
    background: theme.palette.colors.neutrals600,
    opacity: ({ addedToEditor, hover }) => (addedToEditor || hover ? 0.8 : 0),
    padding: '16px',
    position: 'absolute',
    transition: 'opacity 0.2s ease-in-out',
    width: '100%',
  },
  clickToAddEditor: {
    alignItems: 'center',
    color: theme.palette.colors.white,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  clickToAddEditorPadding: {
    paddingBottom: '48px',
  },
  clickToAddPrompt: {
    color: theme.palette.colors.white,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  exampleImagePrompt: {
    fontSize: '14px',
    fontWeight: 500,
  },
  exampleImageClickToTryText: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.colors.neutrals200,
  },
  goBackText: {
    fontSize: '12px',
    fontWeight: 400,
    paddingBottom: '32px',
  },
  hidden: {
    display: 'none',
  },
  imageText: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: '16px',
    fontWeight: 600,
  },
  notVisible: {
    visibility: 'hidden',
  },
  rightToolbarIcon: {
    marginLeft: '8px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarIcon: {
    color: theme.palette.colors.neutrals200,
    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
  toolbarIconSelected: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.neutrals500,
  },
}));

export default useStyles;
