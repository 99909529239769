import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './Header.styles';

const Header = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.title}>Create eCommerce content like a Pro</Typography>
  );
};

export default Header;
