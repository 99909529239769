import config from '@config';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';

const service = window.dataLayer;
const enabled = config.env === 'production' && service;

// Event Aggregator Subscriptions
eventsAggregator.subscribe(EVENT_NAMES.PURCHASE_COMPLETED, (_, data) => {
  if (!enabled) {
    return;
  }
  const {
    priceId,
    product,
    total,
    email,
  } = data;

  service.push({
    event: 'purchase',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: email,
          affiliation: 'Copysmith',
          revenue: total,
          tax: 0,
          shipping: 0,
          coupon: '',
        },
        products: [
          {
            name: product,
            id: priceId,
            price: total,
            brand: 'Copysmith',
            category: 'Copysmith Paid Membership',
            variant: '',
            quantity: 1,
            coupon: '',
          },
        ],
      },
    },
  });
});

eventsAggregator.subscribe(EVENT_NAMES.SIGNUP_FORM_COMPLETED, (_, data) => {
  if (!enabled) {
    return;
  }
  const { method, email } = data;
  service.push({
    event: 'signup',
    authenticationMethod: method,
    email,
  });
});
