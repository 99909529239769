import React, { FC } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';

import authContext from '@root/resources/auth/auth.context';

import useSectionStyles from '../Sections.styles';
import CreditRecordType from '../../UsageV2.types';

import TotalWords from './TotalWords';
import RewardWords from './RewardWords';
import Rollover from './Rollover';
import Images from './Images';
import useStyles from './PlanSection.styles';

type Props = {
  creditRecord?: CreditRecordType;
  isLoading: boolean;
};

const PlanSection:FC<Props> = ({ creditRecord, isLoading }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const { currentUser } : { currentUser: any } = authContext.useAuth();

  return (
    <Grid
      item
      md={8}
      sm={8}
      xs={12}
    >
      <div className={clsx(sectionClasses.sectionContainer)} style={{ minHeight: '192px' }}>
        <div>
          <Typography classes={{ root: sectionClasses.sectionTitle }}>Plan</Typography>

          {!isLoading && (
            <Typography classes={{ root: classes.planText }}>{capitalize(creditRecord?.plan)}</Typography>
          )}
        </div>

        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!isLoading && (
          <div className={sectionClasses.statsContainer}>
            <TotalWords
              wordsLeft={creditRecord?.mainCredits}
              isLatestRecordSelected={creditRecord?.isLatest}
              recordIsUnlimited={creditRecord?.isUnlimited}
            />

            {!currentUser.featureToggles.march2023Pricing && (
              <Rollover
                isRolloverEnabled={creditRecord?.rolloverEligible}
                rollover={creditRecord?.rolloverCredits}
                isLatestRecordSelected={creditRecord?.isLatest}
              />
            )}

            {currentUser.featureToggles.rewardWords && !currentUser.featureToggles.march2023Pricing && <RewardWords />}

            <Images
              imagesLeft={creditRecord?.aiImageGenerations}
              isLatestRecordSelected={creditRecord?.isLatest}
            />
          </div>
        )}
      </div>
    </Grid>
  );
};

export default PlanSection;
