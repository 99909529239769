import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3, 3, 5),
    height: 'auto',
    maxHeight: '95%',
    display: 'flex',
    flexDirection: 'column',
  },
  searchField: {
    width: 464,
  },
  progressWrap: {
    width: 'auto',
    marginBottom: 40,
  },
  tableWrapper: {
    background: theme.palette.colors.white,
    borderRadius: 8,
    padding: '56px 40px',
  },
  layoutRoot: {
    background: theme.palette.colors.lightGrey,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    backgroundColor: theme.palette.colors.white,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  title: {
    marginBottom: 32,
    fontWeight: 700,
    fontSize: 32,
  },
}));

export default useStyles;
