import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    maxWidth: '464px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '24px',
  },
  confirmButton: {
    width: 'max-content',
  },
});

export default useStyles;
