import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',

    height: '100%',

    backgroundColor: theme.palette.colors.white,
    borderRadius: '6px',
    border: `1px solid ${theme.palette.colors.neutrals100}`,
    padding: '16px 24px',
  },

  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',

    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: theme.palette.colors.neutrals300,
  },

  statsContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    width: '100%',
  },

  articleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',

    flex: 1,

    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: '6px',
    padding: '12px 16px',
  },

  fullWidthArticle: {
    flex: 'unset',
    width: '100%',
  },

  articleTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,

    marginBottom: '6px',

    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.palette.colors.neutrals300,
  },

  articleButton: {
    flexShrink: 0,

    display: 'grid',
    placeItems: 'center',
    borderRadius: '6px',
    height: 70,
    width: 70,

    color: theme.palette.colors.neutrals300,
    backgroundColor: theme.palette.colors.neutrals100,

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals200,
    },
  },

  articleValue: {
    maxWidth: '200px',
    flex: 1,

    fontSize: '36px',
    lineHeight: '42px',
    fontWeight: 700,
    color: theme.palette.colors.neutrals400,
  },

  emptyText: {
    flex: 1,
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.colors.neutrals400,
  },

  linkText: {
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
    color: theme.palette.colors.neutrals400,
    '&:hover': {
      color: theme.palette.colors.neutrals500,
    },
  },
}));

export default useStyles;
