import React, { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select } from '@material-ui/core';
import { IconCheck } from '@tabler/icons-react';
import clsx from 'clsx';

import { workflowStatusOptions } from '@root/resources/file/file.constants';
import fileContext from '@root/resources/file/file.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import workflowQueueContext from '@root/resources/file/workflowQueue.context';

import useStyles from './StatusSelect.styles';

const initialStatusFilterOptions = [
  { label: 'No status', value: null },
  ...workflowStatusOptions,
];

const StatusSelect = ({ onChange }) => {
  const classes = useStyles();

  const { isDocumentsFlagged } = fileContext.useFile() || {};
  const {
    assignedToId,
    workflowStatus,
    workflowStatusError,
    canManageDocuments,
  } = workflowQueueContext.useWorkflowQueue();

  const isStatusNotSelected = canManageDocuments && !workflowStatus;

  const [isStatusTooltipOpened, toggleStatusTooltip] = useState(
    !!(isDocumentsFlagged && assignedToId),
  );
  const statusTooltipContent = canManageDocuments
    ? 'Select a workflow status for this assigned file.'
    : 'You do not have editing permissions for this shared file.';

  const options = workflowStatus ? workflowStatusOptions : initialStatusFilterOptions;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleFocus = () => {
    toggleStatusTooltip(false);
  };

  useEffect((() => {
    toggleStatusTooltip(!!assignedToId && isStatusNotSelected);
  }), [assignedToId, isStatusNotSelected]);

  const renderMenuItem = (option) => {
    const selected = option.value === workflowStatus;
    return (
      <MenuItem
        value={option.value}
        key={option.value}
        className={classes.menuItem}
        disabled={option.disabled}
      >
        {option.label}
        {selected && <IconCheck size={20} />}
      </MenuItem>
    );
  };

  const renderSelected = (selectedValue) => {
    const selectedOption = options.find((option) => option.value === selectedValue);
    return selectedOption?.label;
  };

  return (
    <SimpleTooltip
      open={isStatusTooltipOpened}
      wrapped
      content={statusTooltipContent}
    >
      <Select
        variant="outlined"
        value={workflowStatus}
        onChange={handleChange}
        onFocus={handleFocus}
        error={workflowStatusError}
        renderValue={renderSelected}
        displayEmpty
        className={clsx(classes.select, classes[workflowStatus])}
        disabled={!canManageDocuments || !assignedToId}
        id="select-status"
      >
        {options.map(renderMenuItem)}
      </Select>

      {workflowStatusError && (
        <FormHelperText error={!!workflowStatusError}>
          {workflowStatusError}
        </FormHelperText>
      )}
    </SimpleTooltip>
  );
};

export default StatusSelect;
