import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  periodToggleRoot: {
    display: 'flex',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 8,
    padding: 8,
  },
  periodButton: {
    color: theme.palette.colors.primary,
    fontWeight: 500,
    padding: '9px 12px',
    borderRadius: 6,
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.colors.primaryFaded,
  },
}));
