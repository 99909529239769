import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function usePersonalize() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.ai.personalize(data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const queryKey = ['personalizationRules.list'];
        const prevRules = queryClient.getQueryData(queryKey) || [];

        queryClient.setQueryData(queryKey, () => [response, ...prevRules]);
      },
    },
  );
}
