import React from 'react';

import Button from '@root/components/buttons/Button';

const EditPaymentMethodButton = ({ onClick }) => {
  return (
    <Button
      size="small"
      onClick={onClick}
      style={{ marginLeft: 'auto' }}
    >
      Edit
    </Button>
  );
};

export default EditPaymentMethodButton;
