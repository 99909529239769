import React from 'react';
import { Button } from '@material-ui/core';

import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';

const ButtonWithTooltip = ({ notEnoughCredits, children, ...props }) => {
  return (
    <DisabledGenerateTooltip
      enabled={notEnoughCredits}
      notEnoughCredits
    >
      <div>
        <Button
          fullWidth
          {...props}
        >
          {children}
        </Button>
      </div>
    </DisabledGenerateTooltip>
  );
};

export default ButtonWithTooltip;
