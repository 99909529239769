import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    kebab: {
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.palette.text.secondary,
    },
    listVariant: {
      display: 'grid',
      placeItems: 'center',

      textAlign: 'center',
      height: '2rem',
      lineHeight: '2rem',
      fontSize: '1.5rem',
    },
    cardVariant: {
      margin: '0',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      fontSize: '1.5rem',
    },
    menuIcon: {
      fontSize: '1.2rem',
    },
    paper: {
      maxHeight: '30rem',
    },
  };
});

export default useStyles;
