import { useQuery } from 'react-query';

import api from '@root/api';

export default function useTeamMembers({ allMembers } = { allMembers: false }) {
  const queryKey = allMembers ? 'user.allCompanyMembers' : 'user.companyMembers';
  return useQuery(queryKey, () => {
    return allMembers ? api.user.getAllCompanyMembers() : api.user.getCompanyMembers();
  });
}
