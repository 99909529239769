import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateOptions extends RequestOptions {
  templateType: string;
  content: string;
  type: "bad" | "good";
}

export interface SubmitToReviewOption extends RequestOptions {
  fileId: string;
  documentId?: string;
  content?: string;
  type: "bad" | "good";
}

export interface ApproveReviewOptions extends RequestOptions {
  id: string;
}

export interface DeleteOptions extends RequestOptions {
  id: string;
}

export class DocumentExample {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async list(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/documentExamples", data, options);

    return result;
  }

  public async create(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/documentExamples", data, options);

    return result;
  }

  public async submitToReview(data: SubmitToReviewOption, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/documentExamples/review", data, options);

    return result;
  }

  public async approveReview({ id, ...data }: ApproveReviewOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put(`/documentExamples/${id}/approve`, data, options);

    return result;
  }

  public async delete({ id, ...data }: DeleteOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del(`/documentExamples/${id}`, data, options);

    return result;
  }
}