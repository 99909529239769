import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    checklistRoot: {
      position: 'fixed',
      bottom: 16,
      right: 100,
      backgroundColor: theme.palette.colors.white,
      boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: 4,
      padding: '16px 32px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
    },
    list: {
      marginTop: 32,
      flexDirection: 'column',
      gap: 8,
      display: ({ minimized }) => (minimized ? 'none' : 'flex'),
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
    },
    progressSteps: {
      marginLeft: 16,
      fontSize: 10,
      color: theme.palette.colors.mediumDarkGrey,
    },
    progressBarBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      fontSize: 12,
    },
    progressBar: {
      flex: 1,
    },
    menuButton: {
      marginLeft: 16,
    },
    menuIcon: {
      transform: ({ minimized }) => (minimized ? 'rotate(90deg)' : 'rotate(-90deg)'),
    },
  });
});

export default useStyles;
