import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  sectionContainer: {
    display: 'contents',
  },
  sectionTitleContainer: {
    paddingRight: '40px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '40px',
    color: theme.palette.colors.neutrals600,
  },
  sectionSubtitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: theme.palette.colors.neutrals600,
  },
  cardContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 6,
    border: `1px solid ${theme.palette.colors.neutrals100}`,
    padding: '16px 24px',
  },
  wideCard: {
    gridColumn: '2/-1',
  },
}));
