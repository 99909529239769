import { useMutation } from 'react-query';

import api from '@root/api';

export default function useUpdateRowResults() {
  return useMutation(async (data) => {
    const response = await api.bulkGeneration.updateRowResults(data);

    return response;
  });
}
