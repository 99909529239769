import React from 'react';

import SavedCard from '@root/components/SavedCard';

import FullCardForm from '../FullCardForm';
import MethodSelector from '../MethodSelector';

const PaymentDetails = ({
  onSubmit, formRef, useSavedCard, onSavedCardChange,
  method, onMethodChange, additionalMethods, billingData,
}) => {
  if (useSavedCard) {
    return (
      <SavedCard
        email={billingData?.paymentMethod.email}
        lastNumbers={billingData?.paymentMethod.card.last4}
        brand={billingData?.paymentMethod.card.brand}
        onChangeClick={onSavedCardChange}
      />
    );
  }

  return (
    <>
      {additionalMethods && (
        <MethodSelector
          additionalMethods={additionalMethods}
          value={method}
          onChange={onMethodChange}
        />
      )}
      {method === 'card' ? (
        <FullCardForm
          formRef={formRef}
          onSubmit={onSubmit}
          isCheckout
        />
      ) : (
        <div>
          Another step will appear after submitting
          your order to complete your purchase details.
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
