import React from 'react';
import clsx from 'clsx';
import { reduce } from 'lodash';

import DateUtil from '@root/utils/date.util';
import AuthContext from '@root/resources/auth/auth.context';
import aiResource from '@root/resources/ai';
import documentResource from '@root/resources/document';
import { useSubmitDocumentExampleToReview, useRemoveDocumentExample } from '@root/resources/documentExample';
import { getReadingTime } from '@root/utils/readability.util';
import templatesContext from '@root/resources/templates/templates.context';

import PreviewFooter from './components/PreviewFooter';
import BadgeRows from './components/BadgeRows';
import UpVotes from './components/UpVotes';
import CommonEditor from './components/CommonEditor';
import CommonPreview from './components/CommonPreview';
import useStyles from './DocumentView.styles';

const optionDefaults = {
  showChar: true,
  showVote: false,
  canLike: true,
  canEdit: true,
  canDelete: true,
  canCopy: true,
  canFlag: true,
  showFooter: true,
  hideWordsBadges: false,
};

const getPersonalizationResources = (publicView) => {
  if (publicView) {
    const personalize = () => {};
    const removePersonalization = () => {};
    const personalizationRules = [];

    return { personalize, removePersonalization, personalizationRules };
  }

  const { mutateAsync: personalize } = aiResource.usePersonalize();
  const { mutateAsync: removePersonalization } = aiResource.useRemovePersonalization();
  const { data: personalizationRules = [] } = aiResource.usePersonalizationRules();

  return { personalize, removePersonalization, personalizationRules };
};

const convertContentToString = (content) => {
  if (typeof content === 'string') {
    return content;
  }

  return reduce(content, (acc, val) => {
    return acc.concat(val);
  }, '');
};

const DocumentView = ({
  document,
  fileData, // the file.data
  selected,
  flagged,

  wordCount,
  wordCountSecondRow,
  onMoreLikeThis,
  onCopy,
  onFlag,
  onDelete: handleCustomDelete,

  options: opt,
  customFooterAction,
  saveEditor = true,
  noMargin = false,
  transparentBackground = false,
  publicView = false,
  onTextSelect,
  editCancellationDisabled,
}) => {
  const options = { ...optionDefaults, ...opt };
  const {
    createdOn,
  } = document;
  const createdInLastFiveMinutes = DateUtil.getNowSeconds() - createdOn < 5 * 60;
  const classes = useStyles();

  const { getTemplate } = templatesContext.useTemplates();
  const template = getTemplate(document.templateType);

  // TODO: After refactoring is done throw and error if editor is not specified
  const DocumentEditor = template?.DocumentEditor || CommonEditor;
  const DocumentPreview = template?.DocumentPreview || CommonPreview;

  const [isEdit, setIsEdit] = React.useState(false);
  const [content, setContent] = React.useState(document.content);
  const { mutateAsync: updateDocument } = documentResource.useUpdateDocument();
  const { mutateAsync: likeDocument } = documentResource.useLikeDocument();
  const { mutateAsync: deleteDocument } = documentResource.useDeleteDocument();
  const {
    personalize,
    removePersonalization,
    personalizationRules,
  } = getPersonalizationResources(publicView);
  const { mutateAsync: submitToReview } = useSubmitDocumentExampleToReview();
  const { mutateAsync: removeDocumentExample } = useRemoveDocumentExample();

  const { currentUser } = AuthContext.useAuth();

  const save = async (data) => {
    if (editCancellationDisabled) {
      return;
    }

    setContent(data.content);

    if (saveEditor) {
      await updateDocument({
        _id: document._id,
        fileId: document.fileId,
        data,
      });
    }
  };
  // ensure that content is updated when query is updated
  React.useEffect(() => {
    setContent(document.content);
  }, [document.content]);

  const onAtomClick = (e) => {
    if (!options.canEdit || isEdit) {
      return;
    }
    e.preventDefault();
    const noSelection = window.getSelection().toString().length === 0;
    if (noSelection) {
      setIsEdit(!isEdit);
    }
  };

  const onEdit = () => {
    if (!options.canEdit) {
      return;
    }
    setIsEdit(true);
  };

  const onCancelEdit = () => {
    if (editCancellationDisabled) {
      return;
    }
    setIsEdit(false);
  };

  const deleteCurrentDocument = () => deleteDocument(document);

  const onDelete = async () => {
    if (handleCustomDelete) {
      handleCustomDelete(deleteCurrentDocument);
      return;
    }
    await deleteCurrentDocument();
  };

  const onLike = async () => {
    await likeDocument(document);
  };

  const personalizationRule = React.useMemo(
    () => personalizationRules.find((pr) => pr.documentId === document._id), [
      personalizationRules,
      document._id,
    ],
  );

  const onPersonalize = async () => {
    if (personalizationRule) {
      await removePersonalization({ id: personalizationRule._id });
      return;
    }
    await personalize({ documentId: document._id });
  };

  const onSubmitToReview = async () => {
    const example = Object.entries(document.submittedExamples || {})
      .find(([, value]) => value === 'bad');

    if (!example) {
      await submitToReview({
        fileId: document.fileId,
        documentId: document._id,
        type: 'bad',
      });
    } else {
      const [exampleId] = example;
      await removeDocumentExample({ id: exampleId });
    }
  };

  if (isEdit) {
    return (
      <div className={classes.root}>
        <div
          className={clsx(classes.generationAtom, { [classes.generationAtomChecked]: selected })}
          onClick={onAtomClick}
        >
          <DocumentEditor
            content={content}
            onSave={save}
            fileData={fileData}
            onCancelEdit={onCancelEdit}
            onTextSelect={onTextSelect}
          >
            <BadgeRows
              wordCount={wordCount}
              readabilityScore={document.readabilityScore}
              readingTime={getReadingTime(convertContentToString(document.content))}
              wordCountSecondRow={wordCountSecondRow}
              isNew={createdInLastFiveMinutes}
              showChar={options.showChar}
            />
          </DocumentEditor>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.generationAtom, {
          [classes.generationAtomChecked]: selected,
          [classes.noMargin]: noMargin,
          [classes.transparentBackground]: transparentBackground,
        })}
        onClick={onAtomClick}
      >
        <div className={classes.documentPreview}>
          <DocumentPreview
            documentId={document._id}
            content={content}
            fileData={fileData}
            onTextSelect={onTextSelect}
          />
        </div>
        {!options.hideWordsBadges && (
          <BadgeRows
            wordCount={wordCount}
            readabilityScore={document.readabilityScore}
            readingTime={getReadingTime(convertContentToString(document.content))}
            wordCountSecondRow={wordCountSecondRow}
            isNew={createdInLastFiveMinutes}
            showChar={options.showChar}
          />
        )}
        {options.showVote && (
          <div style={{ width: '100px' }}>
            <UpVotes
              document={{
                _id: document._id,
                votes: document.votes,
                fileId: document.fileId,
              }}
              userId={currentUser ? currentUser._id : null}
            />
          </div>
        )}
        {!options.showVote && options.showFooter && (
          <PreviewFooter
            document={document}
            fileData={fileData}
            content={content}
            isEdit={isEdit}
            onEdit={onEdit}
            onDelete={onDelete}
            onCopy={onCopy}
            onFlag={onFlag}
            onLike={onLike}
            onMoreLikeThis={onMoreLikeThis}
            onPersonalize={onPersonalize}
            onSubmitToReview={onSubmitToReview}
            options={options}
            flagged={flagged}
            customFooterAction={customFooterAction}
            usedForPersonalization={!!personalizationRule}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentView;
