import PubSub from 'pubsub-js';

import api from '@root/api';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import config from '@root/config';

import getCurrentExternalSource from '../resources/externalSources';

import mixpanelService from './mixpanel.service';
import oauthMap from './oauth.service';
import jwtService from './jwt.service';
import papService from './pap.service';
import safeLocalStorage from './safeLocalStorage.service';
import { setAlmostExpiredCardAlertHiddenState } from './localStorage.service';

const dropAlmostExpiredCardAlertHiddenState = () => setAlmostExpiredCardAlertHiddenState(false);

const processSignup = (response) => {
  if (response.forbidden) {
    switch (response.reason) {
      case 'byEmail':
      case 'byIp':
      case 'byRecaptcha':
        throw new Error(`Signup unsuccessful because this machine and/or account has been flagged as suspicious. If you believe this is in error, contact our customer success team at ${config.contactSupportEmail}.`);
      case 'emailUnverified':
        throw new Error('You must use a valid email account to create a trial. Please check for typos or use a different email address.');
      case 'emailUsed':
        throw new Error('An account already exists for this email. Please log in to your existing account.');
      case 'trialUnverified': {
        const error = new Error('Please confirm your email.');
        error.redirectToCheckEmail = true;
        error.email = response.email;
        throw error;
      }
      default:
        break;
    }
  }
};

const processLogin = (response) => {
  if (response.forbidden) {
    switch (response.reason) {
      case 'byEmail':
      case 'byIp':
        throw new Error(`Login unsuccessful because this machine and/or account has been flagged as suspicious. If you believe this is in error, contact our customer success team at ${config.contactSupportEmail}.`);
      case 'emailUnused':
        throw new Error('Account associated with this email not found.');
      case 'inactiveTeamPlan':
        throw new Error('This email is no longer associated with an active team account. Please contact the team admin');
      case 'wrongPassword':
        throw new Error('Username and/or password are incorrect or not found. Please try again.');
      case 'numLogins':
        throw new Error(`You have exceeded the max allowed logins per hour. Please try again in 1 hour. If you received this message in error please contact our team at ${config.contactSupportEmail}.`);
      case 'userDeactivated':
        throw new Error('This user has been deactivated or no longer associated with an active team account. Please contact the team admin.');
      case 'trialUnverified': {
        const error = new Error('Please confirm your email.');
        error.redirectToCheckEmail = true;
        error.email = response.email;
        throw error;
      }
      default:
        break;
    }
  }
};

const getUtmParams = () => {
  const UTMParams = safeLocalStorage.getItem('UTM Parameters')?.replace('?', '') || '';

  return UTMParams;
};

const signup = async ({
  email,
  firstName,
  surname,
  companyUrl,
  industrySegment,
  password,
  companyName,
  recaptchaToken,
  referralCode,
  referralType,
}, parseCompany = true) => {
  const request = {
    firstName,
    surname,
    industrySegment,
    email,
    password,
    companyName,
    recaptchaToken,
    referralCode,
    referralType,
  };

  const papCookie = papService.retrieveCookie();
  request.affiliateId = papCookie;
  request.UTMParameters = getUtmParams();

  const url = companyUrl || email.split('@')[1];
  const isBusinessEmail = url !== 'gmail.com';
  if (parseCompany && isBusinessEmail) {
    request.companyUrl = url;
  }

  const resp = await api.account.signup(request);

  dropAlmostExpiredCardAlertHiddenState();

  processSignup(resp);

  return resp;
};
const signupWithOauth = async ({
  code,
  redirectUri,
  provider,
  referralCode,
  referralType,
}) => {
  const request = {
    code,
    redirectUri,
    provider,
    referralCode,
    referralType,
  };

  const papCookie = papService.retrieveCookie();
  request.affiliateId = papCookie;
  request.UTMParameters = getUtmParams();

  const resp = await api.account.signupWithOauth(request);

  dropAlmostExpiredCardAlertHiddenState();

  processSignup(resp);

  return resp;
};

const signInWithOauthProvider = (provider) => async (setLoading, hint) => {
  try {
    const { code, redirectUri } = await oauthMap[provider]({ hint });
    if (setLoading) {
      setLoading(true);
    }

    const resp = await api.account.loginWithOauth({
      code,
      redirectUri,
      provider,
    });
    processLogin(resp);

    const { token, isBadRequest } = resp;

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(token);
    const currentExternalSource = await getCurrentExternalSource();
    await currentExternalSource.register(api);

    dropAlmostExpiredCardAlertHiddenState();

    return { success: true };
  } catch (error) {
    await jwtService.dropJwt();
    throw error;
  }
};

export const signInWithSSO = async ({ code, userEmail, redirectUri }) => {
  try {
    const resp = await api.account.loginWithSSO({
      code,
      userEmail,
      redirectUri,
    });

    processLogin(resp);

    const { token, isBadRequest } = resp;

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(token);
    const currentExternalSource = await getCurrentExternalSource();
    await currentExternalSource.register(api);

    dropAlmostExpiredCardAlertHiddenState();

    return { success: true };
  } catch (error) {
    await jwtService.dropJwt();
    throw error;
  }
};

export const signInWithMicrosoft = signInWithOauthProvider('microsoft');
export const signInWithGoogle = signInWithOauthProvider('google');

export const signUpWithOauthProvider = async (provider, referralCode, referralType) => {
  try {
    const { code, redirectUri } = await oauthMap[provider]();
    const resp = await signupWithOauth({
      code,
      redirectUri,
      provider,
      referralCode,
      referralType,
    });
    processSignup(resp);
    const { user } = resp;

    eventsAggregator.publish(EVENT_NAMES.SIGNUP_FORM_COMPLETED, {
      method: provider, email: user.email,
    });
    mixpanelService.identify(user._id);

    dropAlmostExpiredCardAlertHiddenState();

    return { success: true, user };
  } catch (error) {
    await jwtService.dropJwt();
    throw error;
  }
};
export const signUpWithMicrosoft = (referralCode, referralType) => signUpWithOauthProvider('microsoft', referralCode, referralType);
export const signUpWithGoogle = (referralCode, referralType) => signUpWithOauthProvider('google', referralCode, referralType);

export const signupFromInvite = async ({
  firstName,
  surname,
  password,
  acceptCode,
  withSSO,
}) => {
  const { token } = await api.invite.accept(acceptCode, {
    displayName: `${firstName} ${surname}`,
    password,
    withSSO,
  });

  await jwtService.saveJwt(token);

  dropAlmostExpiredCardAlertHiddenState();

  return { success: true };
};

export const signupWithEmail = async ({
  email,
  firstName,
  surname,
  industrySegment,
  companyUrl,
  password,
  companyName,
  recaptchaToken,
  referralCode,
  referralType,
}) => {
  const { user } = await signup({
    firstName,
    surname,
    industrySegment,
    email,
    companyUrl,
    password,
    companyName,
    recaptchaToken,
    referralCode,
    referralType,
  });

  eventsAggregator.publish(EVENT_NAMES.SIGNUP_FORM_COMPLETED, { method: 'email', email });

  dropAlmostExpiredCardAlertHiddenState();

  return { success: true, user };
};

export const logInUser = async ({ email, password }) => {
  try {
    const resp = await api.account.login({
      email,
      password,
    });

    processLogin(resp);
    const { token, isBadRequest } = resp;

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(token);
    const currentExternalSource = await getCurrentExternalSource();
    await currentExternalSource.register(api);

    dropAlmostExpiredCardAlertHiddenState();

    return { success: true };
  } catch (error) {
    await jwtService.dropJwt();
    throw error;
  }
};

export const logOutUser = async ({ redirect = null, log = true } = {}) => {
  if (log) {
    await api.account.logout();
  }

  const currentExternalSource = await getCurrentExternalSource();
  await currentExternalSource.unregister(api);

  await jwtService.dropJwt();
  if (redirect) {
    window.location.assign(redirect);
  }
};

export const restorePassword = async (email) => {
  await api.account.restorePassword({
    email,
  });
};

export const resetPassword = async (resetPasswordToken, password) => {
  await api.account.resetPassword({
    resetPasswordToken,
    password,
  });
};

export const verifyEmail = async (verifyEmailToken) => {
  try {
    const { token, isBadRequest } = await api.account.verifyEmail({
      verifyEmailToken,
    });

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(token);

    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const changeEmail = async (email) => {
  try {
    const { isBadRequest } = await api.account.setNewEmail({
      email,
    });

    if (isBadRequest) {
      return { success: false };
    }

    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const confirmNewEmail = async (confirmNewEmailToken) => {
  try {
    const { token: jwt, isBadRequest } = await api.account.confirmNewEmail({
      confirmNewEmailToken,
    });

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(jwt);

    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const refreshExternalSource = async (authData) => {
  try {
    const { token: jwt, isBadRequest } = await api.account.refreshExternalSource(authData);

    if (isBadRequest) {
      return { success: false };
    }

    await jwtService.saveJwt(jwt);

    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const resendTrialVerification = (email) => {
  return api.account.resendTrialVerification({
    email,
  });
};

PubSub.subscribe('logout', (data) => {
  logOutUser(data);
});

PubSub.subscribe('registerExternalSource', (data) => {
  api.account.registerExternalSource(data);
});

PubSub.subscribe('removeExternalSource', (data) => {
  api.account.removeExternalSource(data);
});
