import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
    padding: '36px',
    backgroundColor: theme.palette.colors.white,
  },
  copy: {
    color: theme.palette.colors.primary,
    cursor: 'pointer',
    marginLeft: '15px',
  },
  loading: {
    color: theme.palette.colors.primary,
    marginLeft: '15px',
  },
  refereeEmailFields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  submitRefereeEmail: {
    marginLeft: '12px',
    marginTop: '20px',
    height: '36px',
  },
  title: {
    fontWeight: 600,
  },
  disclaimer: {
    color: theme.palette.colors.mediumDarkGrey,
  },
}));

export default useStyles;
