import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import useStyles from './Drawer.styles';

const Drawer = (props) => {
  const {
    open,
    anchor,
    children,
  } = props;
  const [display, setDisplay] = useState('none');
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setDisplay(open ? 'inherit' : 'none');
    }, 500);
  }, [open]);

  return (
    <div
      className={classes.drawerContainer}
      open={open}
      id="drawer-container"
      style={{ display }}
    >
      <div className="w-100 h-100 position-relative">
        <div
          className={clsx(classes.overlay, {
            [classes.overlayOpen]: open,
          })}
          open={open}
        />
        <div
          className={classes.drawerTransition}
          open={open}
          anchor={anchor}
        >
          <div
            className={clsx(classes.drawerContent, {
              [classes.drawerContentLeft]: anchor === 'left',
              [classes.drawerContentRight]: anchor === 'right',
            })}
            anchor={anchor}
          >
            {children}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Drawer;
