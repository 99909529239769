import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette: { colors } }) => ({
  root: {
    margin: '25px 20px 0 20px',
  },
  contentWrapper: {
    minWidth: '85%',
    marginLeft: '28px',
  },
  teamsLinksSidebar: {
    minWidth: '170px',
    display: 'flex',
    flexDirection: 'column',
  },
  teamsLink: {
    fontWeight: '600',
    fontSize: '14px',
    marginBottom: '10px',
    padding: '5px',
    cursor: 'pointer',
    userSelect: 'none',
    color: colors.black,
    '&:active': {
      opacity: '60%',
    },
  },
  activeLink: {
    color: colors.primary,
  },
  newTeamsLink: {
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '12px',
  },
  newActiveLink: {
    backgroundColor: colors.primaryFaded,
    color: colors.primary,
  },
  rootRedesigned: {
    padding: '40px 70px 40px 50px',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    background: colors.lightGrey,
  },
  titleText: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  addMemberButton: {
    padding: '5px 22px',
  },
  tableContainer: {
    marginTop: '10px',
    display: 'block',
  },
  tableRoot: {
    tableLayout: 'fixed',
    width: 'max(100%, 520px)',
    border: 0,
    marginTop: '30px',
  },
  cell: {
    borderBottom: '1px solid #EFEFEF',
    height: '64px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  teamsProfileIcon: {
    width: 40,
    minWidth: 40,
    height: 40,
    lineHeight: '40px',
    fontSize: '20px',
  },
  membersText: {
    fontWeight: 600,
    fontAize: 16,
    lineHeight: '40px',
  },
  deactivated: {
    opacity: '50%',
  },
  infoRow: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: 22,
    marginBottom: 14,
  },
  tableTr: {
    '&:nth-child(odd)': {
      background: colors.white,
    },
  },
  memberName: {
    width: '20%',
    textAlign: 'left',
    padding: '5px 0 5px 2.5rem',
  },
  headname: {
    paddingLeft: '3.1rem',
  },
  memberAccessLevel: {
    width: '25%',
    textAlign: 'left',
  },
  memberEditedRow: {
    width: '45%',
    padding: '5px 0 5px 2.5rem',
    '& label': {
      display: 'none',
    },
    '& input': {
      height: '0.5em',
      backgroundColor: colors.white,
      padding: '12px 14px',
    },
  },
  smallLabel: {
    fontSize: '10px',
    color: '#8D9091',
    lineHeight: '10px',
    marginLeft: '5px',
  },
  inputWrap: {
    paddingTop: '4px',
    marginRight: '25px',
  },
  editRowWrap: {
    display: 'flex',
  },
  editSaveButton: {
    fontFamily: 'Inter, sans-serif',
    backgroundColor: colors.primary,
    padding: '7px 20px',
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
    color: colors.white,
    fontSize: '16px',
    transition: 'all 250ms',
    '&:hover,focus': {
      opacity: '0.8',
    },
  },
  memberActions: {
    width: '15%',
    textAlign: 'center',
    paddingRight: '30px',
  },
  memberEmail: {
    fontSize: '10pt',
    color: colors.greyOffcolor,
  },
  redColor: {
    color: 'red',
  },
  darkGreyColor: {
    color: '#656565',
  },
  greyLable: {
    color: colors.grey,
  },
  buttonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  membersLimitLabel: {
    marginTop: '45px',
    textAlign: 'right',
    paddingBottom: 20,
  },
  actionCardWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '60px',
  },
  actionCardWrapHorizontal: {
    flexWrap: 'wrap',
  },
  actionCard: {
    position: 'relative',
    width: '200px',
    height: '200px',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    marginRight: '22px',
    marginBottom: '15px',
    padding: '22px 14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.6)',
    },
  },
  actionCardHorizontal: {
    width: '47%',
    height: '100px',
    flexDirection: 'row',
    marginRight: 15,
    '& img, svg': {
      width: '40px',
      height: '40px',
      margin: '16px 40px',
    },
  },
  actionCardImg: {
    margin: '16px',
    width: '35px',
    height: '35px',
    color: '#4D33EE',
    fontSize: '35px',
    stroke: '#4D33EE',
  },
  actionCardText: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
  },
  actionCardSoon: {
    position: 'absolute',
    top: '-32px',
    fontSize: '12px',
    background: 'rgba(68,61,246,0.1)',
    borderRadius: '10px',
    color: '#443DF6',
    padding: '2px 12px',
    border: '1px solid #443DF6',
    fontWeight: 500,
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  switchWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));
