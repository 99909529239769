import React from 'react';
import { useHistory } from 'react-router-dom';

import GuidingTooltip from '@root/components/Interactive/GuidingTooltip';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';

import useStyles from './NewUserTour.styles';

const NewUserTour = ({ modalOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useGlobalModals();

  if (!modalOpen) {
    return null;
  }

  const handleClose = () => {
    dispatch({ type: actions.CLOSE_MODAL });
    history.push(
      window.location.pathname
        + window.location.search.replace('newuser=true', ''),
    );
  };

  return (
    <span className={classes.newUserContainer}>
      <GuidingTooltip
        left={0}
        visible={modalOpen}
        fontSize="0.825rem"
        backgroundColor="#4f37e3"
        direction="right"
        width="12rem"
      >
        <div
          aria-hidden="true"
          className={classes.newUserTooltip}
          onClick={handleClose}
        >
          ✕
        </div>
        <div className={classes.newUserText}>
          Let’s start writing! Click here to begin generating your first piece
          of content.
        </div>
      </GuidingTooltip>
    </span>
  );
};

export default NewUserTour;
