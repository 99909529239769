import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const get = async (data) => {
  if (!data.pageUrl || !data.keywords) {
    return null;
  }
  return api.googleAd.getKeywords(data);
};

export default function useGetKeywords(data) {
  return useQuery(['googleAds.getKeywords'],
    (reqData) => get({ ...data, ...reqData }));
}
