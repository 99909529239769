import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fileRow: {
    height: 53,
    cursor: 'pointer',
    '&&:hover': {
      '& td': {
        backgroundColor: theme.palette.colors.mediumLightGrey,
      },
    },
    border: 'none',
  },
  newAlternatingRow: {
    '&&:nth-child(odd)': {
      backgroundColor: theme.palette.colors.mediumLightGrey,
    },
  },
  textOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rowDisabled: {
    cursor: 'default',
    '& *': {
      color: theme.palette.colors.grey,
      cursor: 'default',
      fill: theme.palette.colors.grey,
    },
    '&&:hover': {
      '& td': {
        backgroundColor: 'white',
      },
    },
  },
  draggingPlaceholder: {
    background: '#EBF1FD',
    border: '2px dashed darkblue',
    boxSizing: 'border-box',
    '& td': {
      fontSize: 0,
      height: '53px',
    },
  },
  cell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  newCell: {
    border: 'none',
  },

  fixedCell: {
    [theme.breakpoints.down('md')]: {
      position: 'sticky',
      backgroundColor: 'white',
      left: 0,
      zIndex: 5,
    },
  },
  fixedCellHeader: {
    [theme.breakpoints.down('md')]: {
      zIndex: 6,
    },
  },
  secondCell: {
    [theme.breakpoints.down('md')]: {
      left: 48,
    },
  },
  thirdCell: {
    [theme.breakpoints.down('md')]: {
      left: 48 * 2,
      borderRight: `1px solid ${theme.palette.colors.grey}`,
      boxShadow: '4px 0px 2px rgb(0 0 0 / 4%)',
    },
  },
  fixedWithoutBorder: {
    borderRight: 'none',
    boxShadow: 'none',
  },
  checkbox: {
    color: theme.palette.colors.grey,
    '&&:hover': {
      backgroundColor: '#523AE710',
    },
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$indeterminate': {
      color: theme.palette.colors.darkGrey,
    },
  },
  checked: {},
  indeterminate: {},
  stickyHeader: {
    top: '0',
    zIndex: '2',
    position: 'sticky',
    backgroundColor: theme.palette.colors.white,
  },
  controls: {
    width: 48,
  },
  isDraggingOver: {
    border: '3px solid #88A9FF',
    borderLeftWidth: '4px',
  },
}));

export default useStyles;
