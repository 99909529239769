import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';

import Drawer from '@root/components/shared/drawer/Drawer';
import AuthContext from '@root/resources/auth/auth.context';
import fileContext from '@root/resources/file/file.context';

import DocumentExample from '../DocumentExample';

import useStyles from './Example.styles';

const Example = ({ onClose }) => {
  const { currentCompany } = AuthContext.useAuth();
  const { template } = fileContext.useFile();
  const { title, description } = template;
  const fields = template.fieldsByByPlan
    ? template.fieldsByByPlan[currentCompany.billing.currentLimitId] || template.fields
    : template.fields;

  const sampleFields = React.useMemo(() => {
    const res = [];
    fields.forEach((f) => {
      if (f.example) {
        res.push({ title: f.label, value: f.example });
      }
    });
    return res;
  }, [fields]);

  const classes = useStyles();
  return (
    <Drawer anchor="right" open>
      <div className={classes.drawerCloseContainer}>
        <div
          className={classes.examplesWrap}
          onClick={onClose}
        >
          <FontAwesomeIcon icon="arrow-right" className="mr-2" />
          <span>Close</span>
        </div>
        <a href="https://copysmith.ai/blog">
          <span className="mr-2">More examples</span>
          <FontAwesomeIcon icon="external-link-alt" />
        </a>
      </div>
      <div className={classes.container}>
        <div className={classes.sectionContainer}>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="font-weight-bold mb-4 text-left p-0">
              {title}
              {' '}
              Example
            </h4>
          </div>
          <p className="font-weight-bold mb-2">Input</p>
          <table>
            <tbody>
              {sampleFields
                  && sampleFields.map((input, i) => {
                    const paddingBottom = sampleFields.length === i + 1
                      ? '0'
                      : '10px';
                    return (
                      <tr key={input.title}>
                        <th
                          className={classes.tableHeader}
                          style={{ padding: `0 30px ${paddingBottom} 0` }}
                        >
                          {input.title}
                        </th>
                        <td
                          style={{
                            verticalAlign: 'top',
                            padding: `0 0 ${paddingBottom} 0`,
                          }}
                        >
                          {input.value}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <div className={classes.sectionContainer}>
          <p className="font-weight-bold mb-2">Output</p>
          <div className={classes.outputContainer}>
            <DocumentExample template={template} />
          </div>
        </div>
        {description && (
          <div className={classes.sectionContainer}>
            <p className="font-weight-bold mb-2">About this template</p>
            <p>{description}</p>
          </div>
        )}
        <Divider />
        <div className={clsx(classes.sectionContainer, classes.extraMarginBottom)}>
          <p>
            Have a good example you think could help others?
            {' '}
            <br />
            {' '}
            <a
              href="https://form.typeform.com/to/omJE2dAV"
              target="blank"
            >
              Share it with us here!
            </a>
          </p>
        </div>
      </div>
    </Drawer>
  );
};

export default Example;
