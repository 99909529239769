import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  title: {
    lineHeight: 1.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  description: {
    fontSize: 13,
    fontStyle: 'italic',
    lineHeight: 1.8,
  },
}));
