import { useQuery } from 'react-query';

import api from '@root/api';

export default function usePresets(fileId) {
  return useQuery(
    ['files.getPresets', fileId],
    () => api.files.getPresets({ fileId }),
  );
}
