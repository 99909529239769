import React, { useState } from 'react';
import {
  CardNumberElement, CardExpiryElement, CardCvcElement, useElements,
} from '@stripe/react-stripe-js';
import { Formik, Form } from 'formik';

import CardField from '../form/CardField';

import useStyles from './CardForm.styles';

const CardForm = ({
  onSubmit, formRef, error,
}) => {
  const classes = useStyles();
  const elements = useElements();
  const [cardFieldsComplete, setCardFieldsComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  // TODO: handle empty fields
  // eslint-disable-next-line no-unused-vars
  const allCardFieldComplete = Object.values(cardFieldsComplete).every(Boolean);

  const handleSubmit = async (values, { setSubmitting }) => {
    const cardElement = elements.getElement(CardNumberElement);
    await onSubmit(values, cardElement);
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      innerRef={formRef}
      isInitialValid={false}
      initialValues={{}}
    >
      {({ errors }) => {
        return (
          <Form className={classes.form}>
            <CardField
              label="Card number"
              name="cardNumber"
              component={CardNumberElement}
              onFieldComplete={setCardFieldsComplete}
              error={errors.cardNumber || error}
            />
            <div className={classes.row}>
              <CardField
                label="Expiration date"
                name="cardExpiry"
                component={CardExpiryElement}
                onFieldComplete={setCardFieldsComplete}
              />
              <CardField
                label="CVC"
                name="cardCvc"
                component={CardCvcElement}
                onFieldComplete={setCardFieldsComplete}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CardForm;
