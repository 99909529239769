import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sidebarWrap: {
    padding: '20px 0',
    width: '20%',
    minWidth: '340px',
    boxShadow: '-1px 18px 20px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': { width: '9px', height: '8px' },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      background: 'rgba(0,0,0,0.1)',
      border: '1px solid #ccc',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: '#a8a8a8',
      border: '1px solid #aaa',
    },
    '&::-webkit-scrollbar-thumb:hover': { background: '#fff' },
    '&::-webkit-scrollbar-thumb:active': {
      background: theme.palette.primary.main,
    },
    zIndex: 1300,
  },
  fixed: {
    position: 'fixed',
    top: '72px',
    height: 'calc(100% - 72px)',
    bottom: 0,
    left: 0,
  },
  sidebarHeader: {
    padding: '2px 20px',
  },
  projectName: {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '40px',
    wordBreak: 'break-word',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  projectDescription: {
    fontSize: '16px',
    lineHeight: '26px',
    wordBreak: 'break-word',
    marginBottom: '5px',
  },
  projectTeam: {
    display: 'flex',
    padding: '5px 0',
  },
  teamSampleMember: {
    width: '38px',
    height: '38px',
    borderRadius: '19px',
    backgroundColor: theme.palette.grey.main,
    marginRight: '5px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  sidebarContentsWrap: {},
  chevronIcon: {
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    width: theme.spacing(5),
    height: theme.spacing(5),
    lineHeight: '40px',
    display: 'flex',
    userSelect: 'none',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  collapsedSidebar: {
    width: theme.spacing(10.5),
    padding: theme.spacing(2.75, 0),
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'default',
    boxShadow: '-1px 18px 20px 1px rgba(0, 0, 0, 0.15)',
  },
  sidebarPlaceholder: {
    height: '10px',
  },
  collapsedSidebarInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexGrow: 1,
  },
  collapsedMemberCount: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%',
    background: theme.palette.colors.grey,
    marginTop: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    '& svg': {
      height: theme.spacing(3.75),
      width: theme.spacing(3.75),
      pointerEvents: 'none',
    },

    '& span': {
      marginTop: theme.spacing(-1.25),
      fontWeight: 500,
      pointerEvents: 'none',
    },
  },
  collapsedFileCountWrap: {
    height: 0,
  },
  collapsedFileCount: {
    width: theme.spacing(10.5),
    height: theme.spacing(10.5),
    marginTop: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    '& svg': {
      height: theme.spacing(3.75),
      width: theme.spacing(3.75),
      color: theme.palette.primary.main,
    },

    '& span': {
      fontWeight: 500,
      color: theme.palette.common.white,
      fontSize: '10px',
      position: 'relative',
      bottom: 29,
    },
  },
  aclTooltip: {
    pointerEvents: 'all',

    '& button': {
      color: theme.palette.colors.white,
    },
  },
}));

export default useStyles;
