import React from 'react';
import {
  AllInclusive, CallMerge, CloudUpload, GroupWork, Receipt,
} from '@material-ui/icons';
import clsx from 'clsx';

import PublishedWithChanges from '@root/assets/published-with-changes.svg';

const ActionCard = ({
  classes, img, text, isHorizontal, comingSoon,
}) => (
  <div className={clsx(classes.actionCard, {
    [classes.actionCardHorizontal]: isHorizontal,
  })}
  >
    {img}
    <div className={classes.actionCardText}>
      {text}
    </div>
    {comingSoon && !isHorizontal && (
      <div className={classes.actionCardSoon}>Coming Soon!</div>
    )}
  </div>
);

const ActionCardsRow = ({ classes, isHorizontal }) => {
  return (
    <div className={clsx(classes.actionCardWrap, {
      [classes.actionCardWrapHorizontal]: isHorizontal,
    })}
    >
      <ActionCard
        classes={classes}
        img={<CloudUpload className={classes.actionCardImg} />}
        text="Share projects, folders, and files among team members"
        isHorizontal={isHorizontal}
      />
      <ActionCard
        classes={classes}
        img={<Receipt className={classes.actionCardImg} />}
        text="Easily consolidate billing for your whole team"
        isHorizontal={isHorizontal}
      />
      <ActionCard
        classes={classes}
        img={<AllInclusive className={classes.actionCardImg} />}
        text="Enjoy unlimited generation words"
        isHorizontal={isHorizontal}
      />
      <ActionCard
        classes={classes}
        img={<CallMerge className={classes.actionCardImg} />}
        text="Access Google Ads, Shopify, and Frase integrations"
        isHorizontal={isHorizontal}
      />
      <ActionCard
        classes={classes}
        img={<GroupWork className={classes.actionCardImg} />}
        text="Collaborate on features to streamline your team’s work"
        isHorizontal={isHorizontal}
      />
      <ActionCard
        classes={classes}
        img={(
          <img
            alt="Empower"
            src={PublishedWithChanges}
            className={classes.actionCardImg}
          />
)}
        isHorizontal={isHorizontal}
        text="Empower members to review, edit, and approve copy"
      />
    </div>
  );
};

export default ActionCardsRow;
