import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  usageContainer: {
    width: '100%',
    padding: '24px max(calc(100% - 1240px) / 2, 72px)',
    backgroundColor: theme.palette.colors.neutrals50,
    overflow: 'auto',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  title: {
    fontSize: '22px',
    lineHeight: '39px',
    fontWeight: 600,
    marginBottom: 8,
  },

  subtitle: {
    lineHeight: '22px',
  },

  divider: {
    margin: '16px 0px 24px',
  },
}));

export default useStyles;
