import React from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import Loading from '@root/components/Loading/Loading';
import useSelfServeEnterprisePipeline from '@root/resources/billing/useSelfServeEnterprisePipeline';
import subscriptionsContext from '@root/resources/billing/subscriptions.context';
import uiNotificationService from '@root/services/uiNotification.service';

const withProvider = (Component) => (props) => (
  <subscriptionsContext.SubscriptionsProvider>
    <Component {...props} />
  </subscriptionsContext.SubscriptionsProvider>
);

const SelfServeEnterpriseCheckoutRedirect = withProvider(() => {
  const history = useHistory();

  const {
    allPricing,
  } = subscriptionsContext.useSubscriptions();

  const { data: pipeline, isLoading } = useSelfServeEnterprisePipeline();

  React.useEffect(() => {
    if (!allPricing) {
      return;
    }
    if (isLoading) {
      return;
    }
    if (
      !pipeline
      || pipeline.state !== 'contractSigned'
      || pipeline.quoteExpiresOn < DateTime.now().toSeconds()
    ) {
      uiNotificationService.showErrorMessage('Please contact support support@copysmith.ai or subscribe again.');
      history.replace('/');
      return;
    }

    const plan = allPricing.find((p) => p._id === pipeline.planId);

    if (!plan) {
      history.replace('/');
    }

    history.replace('/checkout', { plan, selfServePipelineData: pipeline.data, proratedWords: pipeline.proratedWords });
  }, [isLoading, pipeline, allPricing]);

  return <Loading />;
});

export default SelfServeEnterpriseCheckoutRedirect;
