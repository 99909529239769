import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  instructLanding: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 44px 0',
    color: theme.palette.colors.white,
    fontSize: '1.45rem',
    fontWeight: '600',
    fontFamily: '"Inter", sans-serif',
    gap: '15px',
  },
  paragraph: {
    textAlign: 'center',
    margin: 0,
  },
  useCaseInput: {
    width: '256px',
  },
  topicInput: {
    width: '425px',
  },
  instructButton: {
    border: `1px solid ${theme.palette.colors.white}`,
    color: theme.palette.colors.white,
    background: `${theme.palette.colors.white}25`,
    width: '120px',
    fontWeight: '600',
  },
  instructModal: {
    padding: '46px 96px 64px 96px',
    marginLeft: '110px',
  },
  modalTitle: {
    fontFamily: '"Inter", sans-serif',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '700',
    fontSize: '18px',
    marginBottom: '22px',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalParagraph: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '29px',
    width: '100%',
    userSelect: 'none',
  },
  createContentButton: {
    width: '150px',
  },
  reactions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px',
    marginTop: '33px',
  },
  reactionButton: {
    cursor: 'pointer',
  },
  likeButton: {
    color: theme.palette.colors.darkGreen,
  },
  likeDisabled: {
    color: theme.palette.colors.grey,
  },
  dislikeButton: {
    color: theme.palette.colors.secondary,
  },
}));

export default useStyles;
