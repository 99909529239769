import React from 'react';

import { useTemplatesDashboard, actions } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import { InputField } from '@root/components/form';

import useStyles from './FileName.styles';

const FileName = () => {
  const classes = useStyles();
  const { state, dispatch } = useTemplatesDashboard();

  return (
    <div className={classes.filename}>
      <InputField
        label="File Name"
        name="name"
        required
        margin="dense"
        onChange={(event) => dispatch({
          type: actions.UPDATE_TITLE,
          payload: event.target.value,
        })}
        value={state.title}
      />
    </div>
  );
};

export default FileName;
