import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: '4px 8px',
    borderRadius: 4,
    cursor: 'default',
  },
  red: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.colors.white,
  },
  green: {
    color: theme.palette.colors.success,
    backgroundColor: theme.palette.colors.successFaded,
  },
  yellow: {
    backgroundColor: 'rgba(147, 106, 0, 0.05)',
    color: theme.palette.colors.warning,
  },
  blue: {
    backgroundColor: theme.palette.colors.lightBlue,
    color: theme.palette.colors.white,
  },
}));

export default useStyles;
