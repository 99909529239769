import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import CloseButton from '@root/components/buttons/CloseButton';
import TruncatedText from '@root/components/TruncatedText';

import useStyles from './Modal.styles';

const Modal = ({
  open = true,
  onClose,
  title,
  subtitle,
  maxWidth,
  fullWidth,
  children,
  classNames = {},
  noPadding,
  noContentMargin,
  id,
  ...props
}) => {
  const classes = useStyles({
    withSubtitle: !!subtitle,
    noPadding,
    noContentMargin: noContentMargin || !title,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        className: clsx(classes.root, classNames.paper),
        id,
      }}
      {...props}
    >
      {title && (
        <DialogTitle disableTypography className={classes.titleContainer}>
          {title && <TruncatedText className={classes.title}>{title}</TruncatedText>}

          {onClose && (
            <CloseButton
              onClick={onClose}
              className={classes.closeButtonInTitle}
            />
          )}
        </DialogTitle>
      )}

      {subtitle && (
        <DialogContentText
          variant="body2"
          className={classes.subtitle}
        >
          {subtitle}
        </DialogContentText>
      )}

      <DialogContent className={classes.content}>
        {children}
      </DialogContent>

      {!title && onClose && (
        <CloseButton
          onClick={onClose}
        />
      )}
    </Dialog>
  );
};

export default Modal;
