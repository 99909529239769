import React from 'react';

import useStyles from './Overage.styles';

const EmptyState = ({ onActivate, isOwner }) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyStateContainer}>
      {isOwner
        ? (
          <>
            <div className={classes.emptyStateTitle}>
              Activate overages
            </div>
            <div className={classes.emptyStateLink} onClick={onActivate}>
              Activate
            </div>
          </>
        ) : (
          <div className={classes.emptyStateTitle}>
            Only account owners can activate overage.
          </div>
        )}
    </div>
  );
};

export default EmptyState;
