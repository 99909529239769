const navDisabled = [
];

const profileSidebarDisabledItems = [
];

const profileDropdownDisabledItems = [
];

const profileDropdownDisabledResources = [
];

const trialEndModal = {
  modalTitle: 'Copysmith for BigCommerce not supported',
  modalText: 'The Copysmith for BigCommerce app is not supported on your current plan type. Upgrade your subscription to enable access',
  keepOpen: true,
};

const config = {
  name: 'BigCommerce',

  jwtToken: {
    value: 'bigCommerce-client-token-value',
    expiresOn: 'bigCommerce-client-token-expiresOn',
  },

  toggles: {
    openBillingLinksInNewTab: true,
    showPublishToBigCommerce: true,
    showHootsuitePublishButton: false,
    showExternalSourceTooltip: true,
    disableZapierPublish: true,
    disableGoogleAdPublish: true,
    disableGoogleDocsPublish: true,
    disableAkeneoPublish: true,
    disableShopify: true,
    disableIntegrations: true,
    disableCancelSubscription: true,
    disableEditor: false,
    fixedColumn: true,
    collapsibleSidebar: true,
    formOverlapped: true,
    hideInfileSidebar: true,
    redirectToApp: true,
    redirectToAppTopBar: true,
    hideProfileSidebar: true,
    hideModifyAddonButton: true,
    hideCustomBucket: true,
    hideCreateAndExplore: true,
    instructLandingEnabled: false,
    disableSidebar: true,
    artStudioDisabled: true,
    createEditorInstantly: true,
    logoutFromSuspendModal: true,
  },

  availableCreateModalOptions: ['editor', 'bulkCopyBigCommerce'],

  register: async () => {
  },
  unregister: async () => {
  },
  refresh: async () => {
  },

  isTemplateDisabled: (templateType) => {
    return templateType !== 'freeFormEditor';
  },

  navDisabled,
  profileDropdownDisabledItems,
  profileSidebarDisabledItems,
  profileDropdownDisabledResources,
  trialEndModal,
  isTrialEndShowed: ({
    currentUser, billingInfo,
  }) => {
    const isCancelled = billingInfo?.status === 'cancelled' && !billingInfo?.suspensionStart;
    const isTrialExpired = billingInfo?.status === 'trialExpired';

    return isCancelled || isTrialExpired || !currentUser.permissions.bigCommerce;
  },

  isNeedToSubscribe: () => {
    return false;
  },

  filterGenerators: (generators) => {
    return generators;
  },

  getPublishMessage: (document) => {
  },

  successLoginRedirectPath: '/templates/my-files',
  homePage: '/templates/my-files',
};

export default config;
