import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconBook2 } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import useModalState from '@root/utils/hooks/useModalState';

import BookmarkCatalog from '../BookmarkCatalog';
import DeleteCatalog from '../DeleteCatalog';
import DeleteCatalogModal from '../DeleteCatalogModal';

import useStyles from './CatalogCard.styles';

const getIconColor = (index) => {
  if (index === 0) {
    return '#D3D0FB';
  }

  if (index === 1) {
    return '#CFE8FC';
  }

  if (index === 2) {
    return '#CEFDE1';
  }

  return '#D3D0FB';
};

const CatalogCard = ({ catalog, index }) => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToCatalog = () => {
    history.push(`/catalog/${catalog._id}`);
  };

  const [isDeleteOpen, openDelete, closeDelete] = useModalState(false);

  return (
    <>
      <div className={classes.card}>
        <div className={classes.iconBar}>
          <div className={classes.inconWrapper} style={{ backgroundColor: getIconColor(index) }}>
            <IconBook2 />
          </div>
          <div className={classes.rightIcons}>
            <div>
              <DeleteCatalog catalogId={catalog._id} onClick={openDelete} />
            </div>
            <div>
              <BookmarkCatalog catalogId={catalog._id} isFavorite={catalog.isFavorite} />
            </div>
          </div>
        </div>
        <div>
          <h2 className={classes.title}>{catalog.title}</h2>
        </div>
        <div>
          <Button className={classes.openButton} fullWidth onClick={navigateToCatalog}>View Catalog</Button>
        </div>
      </div>
      {isDeleteOpen && <DeleteCatalogModal catalog={catalog} onClose={closeDelete} />}
    </>
  );
};

export default CatalogCard;
