import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { pick } from 'lodash';

import { FormField, InputField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import aiTemplateResource from '@root/resources/aiTemplate';
import TemplatesContext from '@root/resources/templates/templates.context';
import Loading from '@root/components/Loading/Loading';
import useModalState from '@root/utils/hooks/useModalState';
import SubmitTemplateModal from '@root/components/modals/SubmitTemplateModal';
import uiNotificationService from '@root/services/uiNotification.service';
import AuthContext from '@root/resources/auth/auth.context';

import Experiments from '../Experiments';

import PromptField from './PromptField';
import { initialValues, pageText, getValidationSchema, PROMPT_MAX_LENGTH } from './constants';
import useStyles from './TemplateForm.styles';

const TemplateForm = ({ step, onStepChange }) => {
  const classes = useStyles();
  const history = useHistory();
  const { templateId } = useParams();

  const { currentUser } = AuthContext.useAuth();

  const [isSubmitOpen, openSubmit, closeSubmit] = useModalState();

  const { data, isLoading } = templateId
    ? aiTemplateResource.useAiTemplate(templateId)
    : {};
  const [template, setTemplate] = useState(data);

  useEffect(() => {
    setTemplate(data);
  }, [data]);

  const isEdit = !!templateId;
  const isSubmitted = template?.status === 'submitted';
  const isDraft = template?.status === 'draft';
  const isLive = template?.status === 'live';
  const isCreateAndExploreAvailable = currentUser?.featureToggles?.createAndExplore;

  const initialData = (isEdit && template) ? {
    inputs: template.fields.map((field) => field.name),
    settings: template.aiOptions,
    values: {
      ...pick(template, Object.keys(initialValues)),
      prompt: template.instructions?.default ?? '',
    },
  } : {
    inputs: [],
    settings: {
      temperature: 0.7,
      max_tokens: 50,
    },
    values: initialValues,
  };

  const [inputs, setInputs] = useState(initialData.inputs);
  const [settings, setSettings] = useState(initialData.settings);

  const {
    mutateAsync: createOrUpdateCustom,
  } = aiTemplateResource.useCreateOrUpdateCustomAiTemplate();
  const { loadTemplates } = TemplatesContext.useTemplates();

  const formRef = useRef();

  const handleExit = () => {
    if (isCreateAndExploreAvailable) {
      history.push('/community', { afterCreation: true });
    } else {
      history.push('/create/custom');
    }
    loadTemplates();
  };

  const handleSubmitClick = () => {
    openSubmit();
  };

  const getTemplateValues = () => {
    if (!formRef.current) {
      return null;
    }

    const { values } = formRef.current;
    return {
      ...values,
      _id: templateId,
      fields: inputs.map((input) => ({ name: input })),
      aiOptions: settings,
    };
  };

  const submitTemplate = async ({ submitNotes, saveAsDraft, saveAsLive } = {}) => {
    if (!formRef.current) {
      return;
    }
    // TODO: rework submit logic?

    const { values, setErrors } = formRef.current;
    const templateValues = {
      ...template,
      ...values,
      _id: templateId || template?._id,
      fields: inputs.map((input) => ({ name: input })),
      aiOptions: settings,
      ...(!saveAsDraft && !saveAsLive && {
        submitNotes,
        status: 'submitted',
      }),
    };

    const { errors } = await createOrUpdateCustom(templateValues);
    if (errors) {
      setErrors(errors[0]);
      if (errors[0].title || errors[0].description) {
        onStepChange(0);
        closeSubmit();
      }
      if (errors[0].prompt || errors[0].fields) {
        onStepChange(1);
        closeSubmit();
      }
    } else {
      handleExit();
      closeSubmit();
      uiNotificationService.showSuccessMessage(`Generator was successfully ${(saveAsDraft || saveAsLive) ? 'saved' : 'submitted'} `);
      loadTemplates();
    }
  };

  const saveDraft = () => {
    submitTemplate({ saveAsDraft: true });
  };

  const saveLive = () => {
    submitTemplate({ saveAsLive: true });
  };

  const preventFormSubmit = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const handleNext = () => {
    onStepChange((s) => s + 1);
  };

  useEffect(() => {
    if (template) {
      setInputs(template.fields.map((field) => field.name));
      setSettings(template.aiOptions);
    }
  }, [template]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <div className={classes.templateFormRoot}>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.title}>
            {pageText[step].title}
          </div>
          <div className={classes.description}>
            {pageText[step].description}
            {step === 1 && (
              <div>
                We highly recommend you read this&nbsp;
                <a
                  href="https://describely.notion.site/Copysmith-Help-Center-b0c792cf63a64cc7bc5d1fb07648ff1c"
                  target="_blank"
                  rel="noreferrer"
                >
                  article
                </a>
                ,&nbsp;
                <a
                  href="https://fast.wistia.net/embed/channel/yvyvu7wjbg?wchannelid=yvyvu7wjbg&wvideoid=1hweyv01o2"
                  rel="noreferrer"
                  target="_blank"
                >
                  watch video
                </a>
                .
              </div>
            )}
          </div>
        </div>
        <Formik
          initialValues={initialData.values}
          onSubmit={handleSubmitClick}
          validationSchema={getValidationSchema(inputs)[step]}
          enableReinitialize
          isInitialValid={isEdit}
          innerRef={formRef}
        >
          {({ isValid, handleSubmit }) => {
            const isNextDisabled = !isValid || (step === 1 && inputs.length === 0);
            return (
              <Form
                onKeyDown={preventFormSubmit}
                className={classes.form}
              >
                {step === 0 && (
                  <>
                    <FormField
                      name="title"
                      label="Title"
                      component={InputField}
                      required
                      disabled={isSubmitted}
                      inputProps={{ maxLength: 30 }}
                    />
                    <FormField
                      name="description"
                      label="Description (optional)"
                      component={InputField}
                      disabled={isSubmitted}
                      inputProps={{ maxLength: 150 }}
                    />
                  </>
                )}

                {step === 1 && (
                  <FormField
                    name="prompt"
                    label="Prompt example"
                    component={PromptField}
                    required
                    multiline
                    disabled={isSubmitted || isLive}
                    placeholder="Create an {ad headline} based off keywords {keywords} for {product name} created by {company name} using {tone} tone"
                    inputs={inputs}
                    onInputsChange={setInputs}
                    inputProps={{ maxLength: PROMPT_MAX_LENGTH }}
                  />
                )}

                {step === 2 && (
                  <Experiments
                    settings={settings}
                    template={template}
                    onSettingsChange={setSettings}
                    getTemplateValues={getTemplateValues}
                    setTemplate={setTemplate}
                    inputs={inputs}
                    disabled={isSubmitted}
                  />
                )}

                <div className={classes.buttons}>
                  {step === 2 ? (!isSubmitted && !isLive && (
                    <Button
                      fullWidth
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Submit content generator for review
                    </Button>
                  )) : (
                    <Button
                      fullWidth
                      disabled={isNextDisabled}
                      onClick={handleNext}
                    >
                      Next step
                    </Button>
                  )}

                  {(!isEdit || isDraft) && (
                    <Button
                      onClick={saveDraft}
                      variant="text"
                      fullWidth
                    >
                      Save as draft and finish later
                    </Button>
                  )}

                  {isLive && (
                    <Button
                      onClick={saveLive}
                      fullWidth
                      variant="outlined"
                    >
                      Save and exit
                    </Button>
                  )}

                  {(isSubmitted || isLive) && (
                    <Button
                      onClick={handleExit}
                      variant="text"
                      fullWidth
                    >
                      Exit
                    </Button>
                  )}

                </div>
              </Form>
            );
          }}

        </Formik>
      </div>

      {isSubmitOpen && (
        <SubmitTemplateModal
          onClose={closeSubmit}
          onSubmit={submitTemplate}
        />
      )}
    </div>

  );
};

export default TemplateForm;
