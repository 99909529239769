import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import MainLayout from '@root/views/Layout/MainLayout';
import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import FreeTrialEndModal from '@root/components/FreeTrialEndModal/FreeTrialEndModal';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';
import useModalState from '@root/utils/hooks/useModalState';

import RenewModal from '../Billing/components/RenewModal';
import ResubscribeModal from '../Billing/components/ResubscribeModal';
import RenewSuspendedAccountModal from '../Billing/components/RenewSuspendedAccountModal';

import useStyles from './ProfileLayout.styles';

const memberPrivatePages = [
  '/profile/team',
  '/profile/plans',
  '/profile/billing',
];

const ProfileLayout = ({ children, isPlansPage, ...props }) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const { data: billingInfo } = billingResource.useBillingInfo();
  const [resubscribeModalOpen, openResubscribeModal, closeResubscribeModal] = useModalState(false);
  const [freeTrialEndModalOpen, setFreeTrialEndModalOpen] = useState(null);
  const [freeTrialEndModalData, setFreeTrialEndModalData] = useState({});
  const location = useLocation();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { isTrialEndShowed, trialEndModal } = currentExternalSource;
  const {
    renewModalOpen,
    renewSuspendedAccountModalOpen,
    setRenewModalOpen,
    setRenewSuspendedAccountModalOpen,
  } = isPlansPage ? SubscriptionsContext.useSubscriptions() : props;

  useEffect(() => {
    const isLifetimeDeal = billingInfo?.priceId === 'lifetime_deal';
    const isTrialExpired = billingInfo?.status === 'trialExpired';
    const isCancelled = billingInfo?.status === 'cancelled' && !billingInfo?.suspensionStart;
    const isBillingIssue = billingInfo?.status === 'billingIssue';
    const isStarter = billingInfo?.plan === 'Starter';
    const showExternalTrialEndModal = isTrialEndShowed({
      isStarter, isCancelled, isTrialExpired, isLifetimeDeal, currentUser, billingInfo,
    });

    if (showExternalTrialEndModal) {
      setFreeTrialEndModalOpen(true);
      setFreeTrialEndModalData(trialEndModal);
      return;
    }

    if (isLifetimeDeal) {
      // skip resubscribe / expired trial modals for LTD users
    } else if (isCancelled || (isBillingIssue && billingInfo?.priceId === 'free')) {
      openResubscribeModal();
    } else if (isTrialExpired && freeTrialEndModalOpen === null) {
      setFreeTrialEndModalOpen(true);
      setFreeTrialEndModalData({});
    } else {
      closeResubscribeModal();
    }
  }, [billingInfo]);

  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path="*"
          render={() => {
            if (currentUser.roles.owner || currentUser.roles.admin) {
              return null;
            }

            if (!memberPrivatePages.some((p) => location.pathname.includes(p))) {
              return null;
            }

            return <Redirect to="/profile/edit" />;
          }}
        />
      </Switch>
      {renewModalOpen && (
        <RenewModal
          setModalOpen={setRenewModalOpen}
          modalOpen={renewModalOpen}
        />
      )}
      {resubscribeModalOpen && (
        <ResubscribeModal onClose={closeResubscribeModal} />
      )}
      {renewSuspendedAccountModalOpen && (
        <RenewSuspendedAccountModal
          setModalOpen={setRenewSuspendedAccountModalOpen}
          modalOpen={renewSuspendedAccountModalOpen}
        />
      )}
      {freeTrialEndModalOpen && (
        <FreeTrialEndModal
          isOpen={freeTrialEndModalOpen}
          setIsOpen={setFreeTrialEndModalOpen}
          modalText={freeTrialEndModalData.modalText}
          modalTitle={freeTrialEndModalData.modalTitle}
          keepOpen={freeTrialEndModalData.keepOpen}
        />
      )}
      <div className={classes.root}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </MainLayout>
  );
};

export default ProfileLayout;
