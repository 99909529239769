import React from 'react';
import clsx from 'clsx';
import { GetApp, TrendingUpOutlined } from '@material-ui/icons';

import TruncatedText from '@root/components/TruncatedText';
import { useCommunityContext } from '@root/views/Community/context/Community.context';
import authContext from '@root/resources/auth/auth.context';
import Button from '@root/components/buttons/Button';
import getTemplateActionStatuses from '@root/views/Community/Community.helpers';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { templateCategories } from '@root/views/Community/Community.constants';

import useStyles from './CommunityTemplateCard.styles';

const CommunityTemplateCard = ({ template }) => {
  const classes = useStyles();

  const {
    currentUser: user,
    currentCompany: company,
  } = authContext.useAuth();

  const {
    state: { templatesCategory },
    templates,
    handleSaveToMyAccountClick,
    handleRemoveFromLibraryClick,
    handleUploadTemplateToCommunityClick,
    handleRemoveFromCommunityClick,
    handlePreviewTemplateClick,
  } = useCommunityContext();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;
  const isCommunityCategory = templatesCategory === templateCategories.COMMUNITY;

  const getActionButton = () => {
    const {
      isDownloadedByMe,
      isRemoveFromCommunityVisible,
      isRemoveFromLibraryVisible,
      isSaveToAccountVisible,
      isUploadToCommunityVisible,
      correspondingTemplate,
    } = getTemplateActionStatuses({ templates, template, user, templatesCategory, company });

    if (isSaveToAccountVisible) {
      return (
        <Button
          size="small"
          onClick={(event) => {
            handleSaveToMyAccountClick(event, template._id);
          }}
        >
          Save to My Account
        </Button>
      );
    }

    if (isRemoveFromLibraryVisible) {
      const isButtonDisabled = !isAdminOrOwner && !isDownloadedByMe;

      return (
        <SimpleTooltip
          arrow
          interactive
          wrapped
          content="This action can only be performed by Administrators."
          disableHoverListener={!isButtonDisabled}
        >
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(event) => {
              handleRemoveFromLibraryClick(event, correspondingTemplate?._id);
            }}
            disabled={isButtonDisabled}
          >
            Remove from Account
          </Button>
        </SimpleTooltip>
      );
    }

    if (isUploadToCommunityVisible) {
      return (
        <SimpleTooltip
          arrow
          interactive
          wrapped
          content="This action can only be performed by Administrators."
          disableHoverListener={isAdminOrOwner}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => handleUploadTemplateToCommunityClick(event, template)}
            disabled={!isAdminOrOwner}
          >
            Upload to Community
          </Button>
        </SimpleTooltip>
      );
    }

    if (isRemoveFromCommunityVisible) {
      return (
        <SimpleTooltip
          arrow
          interactive
          wrapped
          content="This action can only be performed by Administrators."
          disableHoverListener={isAdminOrOwner}
        >
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(event) => {
              handleRemoveFromCommunityClick(event, template);
            }}
            disabled={!isAdminOrOwner}
          >
            Remove from Community
          </Button>
        </SimpleTooltip>
      );
    }

    return null;
  };

  return (
    <div
      className={clsx(
        classes.cardContainer,
        { [classes.withHover]: isCommunityCategory },
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (isCommunityCategory) {
          handlePreviewTemplateClick(template);
        }
      }}
    >
      <section className={classes.counters}>
        <div className={clsx(classes.counterContainer, 'secondary')}>
          <TrendingUpOutlined className={classes.counterIcon} />
          <span>{template?.childDocumentsCount || '-'}</span>
        </div>

        <div className={classes.counterContainer}>
          <GetApp className={classes.counterIcon} />
          <span>{template?.downloadsCount || '-'}</span>
        </div>
      </section>

      <section className={classes.templateInfo}>
        <TruncatedText
          className={classes.templateName}
        >
          {template?.title}
        </TruncatedText>

        <TruncatedText
          multiline
          maxLines={2}
          className={classes.templateDescription}
        >
          {template?.description}
        </TruncatedText>
      </section>

      <section className={classes.actionButtons}>
        {getActionButton()}
      </section>
    </div>
  );
};

export default CommunityTemplateCard;
