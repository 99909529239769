import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: `${theme.palette.colors.pink600}`,
  },
  textContent: {
    lineHeight: '22px',
  },
  cancelButton: {
    marginTop: 32,
  },
}));

export default useStyles;
