import React, { useEffect, useState } from 'react';

import loading from '@root/assets/loading.svg';

import useStyles from './MagicLoading.styles';

/**
 * Loading screen used for generators only
 * @param {string} loadingTime? ex: "30 seconds"
 * @returns {JSX.Element}
 * @constructor
 */
const MagicLoading = ({ loadingTime }) => {
  const classes = useStyles();
  const [dotNum, setDotNum] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotNum((s) => (s + 1) % 4);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.spinner}>
        <div>
          <img src={loading} alt="Loading" />
        </div>
        <div className={classes.text}>
          Take a deep breath, the magic
          {' '}
          <span
            alt="emoji"
            role="img"
            aria-label="star emoji"
          >
            ✨
          </span>
          {' '}
          can take up to
          {' '}
          {loadingTime || '15 seconds'}
          {' '}
          {Array(dotNum).fill('.').join('')}
        </div>
      </div>
    </div>
  );
};

export default MagicLoading;
