/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  useHistory, useParams, Link as RouterLink,
} from 'react-router-dom';

import aiTemplateResource from '@root/resources/aiTemplate';

import TemplateViewTab from './components/TemplateViewTab';
import ExperimentTab from './components/ExperimentTab';
import CompaniesAccessListTab from './components/CompaniesAccessListTab';
import QualityDiscriminatorTab from './components/QualityDiscriminatorTab';
import SubmittedExamplesTab from './components/SubmittedExamplesTab';
import TabPanel from './components/TabPanel';
import SuperAdminLayout from './components/SuperAdminLayout';

function a11yProps(index) {
  return {
    id: `right-panel-tab-${index}`,
    'aria-controls': `right-panel-tabpanel-${index}`,
  };
}

const TABS = {
  manage: { label: 'Manage templates', value: '', index: 0 },
  experiment: { label: 'Run experiments', value: 'experiment', index: 1 },
  qualityDiscriminator: { label: 'Quality Discriminator', value: 'quality-discriminator', index: 2 },
  submittedExamples: { label: 'Submitted Examples', value: 'submitted-examples', index: 3 },
  companies: { label: 'Private Access List', value: 'companies', index: 4 },
};

// const TABS_LIST = Object.values(TABS)

// const TABS = {
//   MANAGE: 0,
//   EXPERIMENT: 1,
//   QUALITY_DISCRIMINATOR: 2,
//   SUBMITTED_EXAMPLES: 3,
//   COMPANIES: 4,
// };

// const getTabIdByName = (name) => {
//   switch (name) {
//     case 'experiment':
//       return 1;
//     case 'quality-discriminator':
//       return 2;
//     case 'submitted-examples':
//       return 3;
//     case 'companies':
//       return 4;
//     default:
//       return 0;
//   }
// };

const TemplatePage = () => {
  const history = useHistory();
  const params = useParams();
  const { data: template } = aiTemplateResource.useAiTemplate(params.id);
  const activeTab = TABS[params.tab] || TABS.manage;
  // const [activeTab, setActiveTab] = React.useState(initialTab);

  // React.useEffect(() => {
  //   const newTab = getTabIdByName(params.tab);
  //   setActiveTab(newTab);
  // }, [params.tab]);

  const handleTabChange = (e, newValue) => {
    history.push(`/super-admin/templates/${params.id}/${newValue}`);
    // switch (newValue) {
    //   case TABS.EXPERIMENT:
    //     history.push(`/super-admin/templates/${params.id}/experiment`);
    //     break;
    //   case TABS.QUALITY_DISCRIMINATOR:
    //     history.push(`/super-admin/templates/${params.id}/quality-discriminator`);
    //     break;
    //   case TABS.SUBMITTED_EXAMPLES:
    //     history.push(`/super-admin/templates/${params.id}/submitted-examples`);
    //     break;
    //   case TABS.COMPANIES:
    //     history.push(`/super-admin/templates/${params.id}/companies`);
    //     break;
    //   case TABS.MANAGE:
    //   default:
    //     history.push(`/super-admin/templates/${params.id}`);
    // }
  };

  const isCustomTemplate = template && template.buckets.some((b) => b.bucket === 'custom'); // TEMP
  const showPrivateCompanies = template && (isCustomTemplate || !template.public);
  const isNewTemplate = params.id === 'new';

  return (
    <SuperAdminLayout>
      <div>
        <div>
          <RouterLink
            to={`/super-admin/templates${isCustomTemplate ? '/list/custom' : ''}`}
          >
            Back to templates
          </RouterLink>
        </div>
      </div>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={activeTab.value}
        onChange={handleTabChange}
        aria-label="right panel tabs"
      >
        <Tab
          label={TABS.manage.label}
          value={TABS.manage.value}
          {...a11yProps(0)}
        />
        {!isNewTemplate && (
          <Tab
            label={TABS.experiment.label}
            value={TABS.experiment.value}
            {...a11yProps(1)}
          />
        )}
        {!isCustomTemplate && (
          <>
            <Tab
              label={TABS.qualityDiscriminator.label}
              value={TABS.qualityDiscriminator.value}
              {...a11yProps(2)}
            />
            <Tab
              label={TABS.submittedExamples.label}
              value={TABS.submittedExamples.value}
              {...a11yProps(3)}
            />
          </>
        )}
        {showPrivateCompanies && (
          <Tab
            label={TABS.companies.label}
            value={TABS.companies.value}
            {...a11yProps(4)}
          />
        )}
      </Tabs>
      <TabPanel value={activeTab.index} index={0}>
        <TemplateViewTab />
      </TabPanel>
      <TabPanel value={activeTab.index} index={1}>
        <ExperimentTab />
      </TabPanel>
      <TabPanel value={activeTab.index} index={2}>
        <QualityDiscriminatorTab />
      </TabPanel>
      <TabPanel value={activeTab.index} index={3}>
        <SubmittedExamplesTab />
      </TabPanel>
      <TabPanel value={activeTab.index} index={4}>
        <CompaniesAccessListTab />
      </TabPanel>
    </SuperAdminLayout>
  );
};

export default TemplatePage;
