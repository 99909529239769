import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey.main}`,

    padding: '24px',

    display: 'flex',
    flexDirection: 'column',

    overflow: 'auto',

    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.colors.neutrals400,
    },
  },
  cardHeader: {
    marginBottom: '16px',
  },
  previewButton: {
    fontFamily: 'Inter, sans-sefif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    padding: '4px 8px 4px 4px',
    maxHeight: '32px',

    color: theme.palette.colors.neutrals400,

    '&:hover, &:active': {
      color: theme.palette.colors.neutrals400,
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },
  previewButtonLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createdBySection: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    maxWidth: '50%',
  },
  creatorNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',

    overflow: 'hidden',
  },
  createdByText: {
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.colors.neutrals300,
  },
  creatorName: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  creatorAvatar: {
    height: 32,
    width: 32,
    fontSize: '14px',
  },
  counters: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
  },
  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',

    fontSize: '12px',
    lineHeight: '18px',

    color: theme.palette.colors.neutrals400,
  },
  counterIcon: {
    width: 16,
    height: 16,
    color: theme.palette.colors.neutrals400,
  },
  templateInfo: {
    marginBottom: 'auto',
  },
  templateName: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '700',
    marginBottom: '8px',
    flexGrow: '0',
  },
  templateDescription: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

export default useStyles;
