export const getDefaultClipboardItem = (doc) => doc.content.replace(/<\/p>|<\/h[1-6]>/, '\r\n').replace(/(<([^>]+)>)/ig, '');

export const filterByWorkflowStatus = ({ documents, statusFilter, flaggedDocumentsIds }) => {
  return documents.filter(
    (doc) => {
      switch (statusFilter) {
        case 'all':
          return true;
        case 'notAssigned':
          return !flaggedDocumentsIds.includes(doc._id);
        case 'assigned':
          return flaggedDocumentsIds.includes(doc._id);
        default: return true;
      }
    },
  );
};

const freeFormTemplate = {
  templateType: 'freeFormEditor',
  slug: 'free-form-smart-editor',
};

export const redirectToNewEditor = async (createFile, additionalData, history) => {
  const folderId = additionalData?.folderId;
  const { templateType, slug } = freeFormTemplate;
  const initialFile = await createFile({
    title: 'Untitled Document',
    templateType,
    ...(folderId ? { folderId } : {}), // fixme
  });
  if (initialFile.isBadRequest) {
    return;
  }

  const { _id, title: newFileTitle } = initialFile;
  const url = `/${slug}/${_id}?title=${newFileTitle}`;

  history.push(url);
};
