import { escapeRegExp } from 'lodash';

/**
 * Removes favorites from an array of generators
 *
 * @param {array} favorites
 * @param {array} generators
 * @returns {array}
 */
const filterOutFavorites = (favorites, generators) => {
  if (!favorites || !generators) {
    return [];
  }

  if (!favorites) {
    return generators;
  }

  return generators.filter((g) => !favorites.includes(g.templateType));
};

/**
 * Filters a list of generators by a keyword. It checks the
 * title, type, and description for the keyword.
 *
 * @param {string} keyword
 * @param {array|null} generators
 * @returns {array}
 */
// eslint-disable-next-line import/prefer-default-export
export const searchGenerators = (keyword, generators, favorites) => {
  if (!keyword) {
    return filterOutFavorites(favorites, generators);
  }

  const formattedKeyword = escapeRegExp(keyword).toUpperCase();

  const searchedGenerators = generators.filter(
    (generator) => generator.title?.toUpperCase().search(formattedKeyword) > -1
      || generator.group?.toUpperCase().search(formattedKeyword) > -1
      || generator.description?.toUpperCase().search(formattedKeyword) > -1
      || generator.searchKeywords?.some(
        (str) => str.toUpperCase().search(formattedKeyword) > -1,
      ),
  );

  if (!favorites || favorites.length === 0) {
    return searchedGenerators;
  }

  return filterOutFavorites(favorites, searchedGenerators);
};
