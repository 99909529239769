import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 'calc(100vh - 56px)',
    minHeight: '500px',
    padding: '40px 24px',
  },
}));

export default useStyles;
