import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  page: {
    padding: '2rem 3rem',
    backgroundColor: theme.palette.colors.neutrals50,
    width: '100%',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '500',
    fontFamily: 'Inter, sans-serif',
    marginBottom: '1.5rem',
  },
  subTitle: {
    color: theme.palette.grey.pure,
  },
  sectionTitle: {
    fontSize: '1.1rem',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '24px 0px 16px',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listings: {
    display: 'flex',
  },
}));

export default useStyles;
