import React from 'react';
import isEqual from 'lodash.isequal';

import uiNotificationService from '@root/services/uiNotification.service';

const useSaveAnExit = ({
  isLocalFileEqualsPrevData,
  localFile,
  prevData,
  updateFile,
  canManageDocuments,
  flaggedDocumentsIds,
  history,
  initialFlaggedDocumentsIds,
  setAssignedToError,
  setWorkflowStatusError,
  fileId,
  getFileData,
  config,
  flagDocument,
}) => {
  const validateSaveAndExit = (data) => {
    const isAssignmentRequired = flaggedDocumentsIds.length || data.workflowStatus;
    const errors = [];
    if (isAssignmentRequired && !data.assignedToId) {
      errors.push({
        assignedToId: 'Select a team member to assign this copy.',
      });
    }

    if (data.assignedToId && !data.workflowStatus) {
      errors.push({
        workflowStatus: 'Select a workflow status for this assigned file.',
      });
    }

    return {
      isValid: !errors.length,
      errors,
    };
  };

  const saveAndExit = React.useCallback(({ backUrl }) => async () => {
    if (!canManageDocuments) {
      history.push(backUrl);
      return;
    }

    const isFlaggedDocsEqual = isEqual(
      initialFlaggedDocumentsIds.slice().sort(),
      flaggedDocumentsIds.slice().sort(),
    );

    if (isFlaggedDocsEqual && isLocalFileEqualsPrevData(localFile, prevData)) {
      history.push(backUrl);
      return;
    }

    const {
      negativeKeywordArray,
      language,
      outputLanguage,
      engine,
      workflowStatus,
      assignedToId,
      ...d
    } = localFile;

    const validationResult = validateSaveAndExit({
      assignedToId,
      workflowStatus,
    });

    if (!validationResult.isValid) {
      const assignedError = validationResult.errors.find((error) => error.assignedToId);
      const statusError = validationResult.errors.find((error) => error.workflowStatus);

      if (assignedError) {
        setAssignedToError(assignedError.assignedToId);
      }
      if (statusError) {
        setWorkflowStatusError(statusError.workflowStatus);
      }

      return;
    }
    history.push(backUrl);

    await updateFile({
      fileId,
      data: {
        data: getFileData(d, config),
        negativeKeywordArray,
        language,
        ...(outputLanguage && { outputLanguage }),
        engine,
        assignedToId,
        workflowStatus,
      },
    });

    if (initialFlaggedDocumentsIds.length || flaggedDocumentsIds) {
      const difference = initialFlaggedDocumentsIds
        .filter((x) => !flaggedDocumentsIds.includes(x))
        .concat(flaggedDocumentsIds.filter((x) => !initialFlaggedDocumentsIds.includes(x)));

      difference.map((docId) => flagDocument({ _id: docId, fileId }));
    }

    if (prevData.obj.assignedTo?.userId !== localFile.assignedToId) {
      uiNotificationService.showSuccessMessage('File has been assigned and added to team workflow queue.');
    }
  }, [
    isLocalFileEqualsPrevData,
    localFile,
    prevData,
    updateFile,
    canManageDocuments,
    validateSaveAndExit,
  ]);

  return saveAndExit;
};

export default useSaveAnExit;
