/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';

import useStyles from './PaginationNew.styles';
import PageItem from './PageItem';

const Pagination = ({
  pageCount,
  currentPage,
  onPageChange,
}) => {
  const classes = useStyles();
  const afterPageNum = pageCount - currentPage;
  const prevPageNum = currentPage - 1;

  const prevPages = Array.from({ length: prevPageNum }, (_, i) => currentPage - (i + 1))
    .reverse()
    .slice(0, afterPageNum < 4 ? 8 - afterPageNum : 4);
  const prevItems = prevPages.map(
    (pageNum, index) => (
      <PageItem
        key={pageNum + index}
        pageNum={pageNum}
        onPageSelect={() => onPageChange(pageNum)}
      />
    ),
  );

  const afterPages = Array.from({ length: afterPageNum }, (_, i) => i + 1 + currentPage)
    .slice(0, 8 - prevPages.length);
  const afterItems = afterPages.map(
    (pageNum, index) => (
      <PageItem
        key={pageNum + index}
        pageNum={pageNum}
        onPageSelect={() => onPageChange(pageNum)}
      />
    ),
  );

  return (
    <div className={classes.root}>
      <div className={classes.paginationContainer}>
        <Button
          disabled={currentPage === 1}
          variant="text"
          onClick={() => onPageChange(currentPage - 1)}
          className={classes.movePageButton}
          classes={{ disabled: classes.disabledMovePageButton, label: classes.movePageButtonLabel }}
        >
          <IconArrowLeft />

          <span>Previous</span>
        </Button>

        <div className={classes.itemContainer}>
          {prevItems}
          <PageItem pageNum={currentPage} isActive />
          {afterItems}
        </div>

        <Button
          disabled={currentPage === pageCount}
          variant="text"
          onClick={() => onPageChange(currentPage + 1)}
          className={classes.movePageButton}
          classes={{ disabled: classes.disabledMovePageButton, label: classes.movePageButtonLabel }}

        >
          <span>Next</span>

          <IconArrowRight />
        </Button>
      </div>

      <div>
        <span style={{
          fontFamily: 'Inter,sans-serif',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '24px',
        }}
        >
          25
        </span>
        {' '}
        <span style={{
          fontFamily: 'Inter,sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '17px',
        }}
        >
          files per page
        </span>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
  onPageChange: () => {},
};

export default Pagination;
