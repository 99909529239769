import React from 'react';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import ContentArea from './components/ContentArea';

export const TEMPLATE_EXAMPLE_MODAL_ID = 'TEMPLATE_EXAMPLE_MODAL_ID';

const ExampleModal = () => {
  const { state: { modalContent, modalId }, dispatch } = useGlobalModals();
  const modalOpen = modalId === TEMPLATE_EXAMPLE_MODAL_ID;

  const handleModalClose = () => {
    dispatch({
      type: actions.CLOSE_MODAL,
    });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeAfterTransition
    >
      <ContentArea content={modalContent} />
    </Modal>
  );
};

export default ExampleModal;
