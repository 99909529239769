import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const getCustomers = async () => {
  const response = await api.googleAd.getCustomers();
  if (response.isBadRequest) {
    return null;
  }

  return response;
};

export default function useCustomers() {
  return useQuery(['googleAds.getCustomers'], getCustomers);
}
