import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {

  },
  prelative: {
    position: 'relative',
  },
  renewSubscriptionText: {
    position: 'absolute',
    top: '2rem',
    left: '3rem',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  closeIcon: {
    position: 'absolute',
    fontSize: '2.325rem',
    top: '0.825rem',
    right: '2rem',
    cursor: 'pointer',
    color: theme.palette.colors.grey,
  },
  upgradeButton: {
    color: theme.palette.colors.darkGrey, borderColor: theme.palette.colors.darkGrey,
  },
  buttonContainer: {
    width: '20rem',
    marginBottom: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  renewContainer: {
    padding: '1rem 3rem 0 3rem',
    height: 'calc(100% - 88px)',
    overflow: 'scroll',
  },
  renewError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  b05rem: {
    marginBottom: '0.5rem',
  },
  renewMargins: {
    margin: '2rem 3rem 3rem 3rem',
  },
  modal: {
    position: 'relative',
    fontFamily: '"Inter", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  header: {
    backgroundColor: theme.palette.colors.lightGrey,
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    padding: '1rem 2.5rem',
    width: '100%',
    height: '88px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '95%',
    minWidth: '44rem',
    height: '90%',
    minHeight: '30rem',
    alignContent: 'center',
    outline: 'none',
  },
  small: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '30rem',
    // minHeight: "30rem",
    alignContent: 'center',
    outline: 'none',
  },
}));

export default useStyles;
