import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface ListOptions extends RequestOptions {
  type?: string;
}

export interface CreateOptions extends RequestOptions {
  type: string;
  status: "active" | "inactive";
  data?: Record<string, unknown>;
}

export interface UpdateOptions extends RequestOptions {
  data?: Record<string, unknown>;
  status: "active" | "inactive";
}

export interface ValidateAkeneoIntegrationOptions extends RequestOptions {
  pimUrl: string;
}

export default class Integration {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public list(data?: ListOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    if (!data) {
      data = {};
    }

    return this.client.get("/integrations", data, options);
  }

  public getById(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get(`/integrations/${id}`, { token }, options);
  }

  public async create(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/integrations", data, options);

    return result;
  }

  public update(
    id: string,
    data: UpdateOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/integrations/${id}`, data, options);
  }

  public validateAkeneoIntegration(data: ValidateAkeneoIntegrationOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/integrations/akeneo/validate", data, options);
  }
}
