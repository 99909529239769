import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useCreateFile from '@root/resources/file/useCreateFile';
import TemplatesContext from '@root/resources/templates/templates.context';
import config from '@root/config';

const NewFileRoute = () => {
  const { mutateAsync: createFile } = useCreateFile();
  const { templatesList } = TemplatesContext.useTemplates();
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);
  const templateSlug = search.get('template');

  const template = templatesList.find(({ slug }) => slug === templateSlug);

  React.useEffect(() => {
    if (!template) {
      return;
    }

    const createFileHandler = async () => {
      const file = await createFile({
        templateType: template.templateType,
        title: 'Untitled File',
      });

      if (!file) {
        history.push(config.homeRoute);
        return;
      }

      history.push(`/${templateSlug}/${file._id}`);
    };

    createFileHandler();
  }, [template]);

  if (!template && templatesList.length > 0) {
    history.replace(config.homeRoute);
  }

  return null;
};

export default NewFileRoute;
