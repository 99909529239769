import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    backgroundColor: theme.palette.colors.neutrals50,
    color: theme.palette.colors.neutrals600,

    borderBottom: `1px solid ${theme.palette.colors.neutrals200}`,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    flexShrink: 0,

    padding: '32px 0px 48px',
    height: '165px',
  },

  title: {
    fontFamily: 'Monrope, sans-serif',
    fontSize: '22px',
    lineHeight: '39px',
    fontWeight: '800',
  },

  subtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'center',
    maxWidth: '430px',
  },

  tabsContainer: {
    position: 'absolute',
    minHeight: 'unset',

    marginBottom: '-1px',
    borderBottom: 'none',

    bottom: 0,
    left: 72,
  },

  tabsFlex: {
    gap: '12px',
  },

  tabsIndicator: {
    display: 'none',
  },

  tabButton: {
    minWidth: 'unset',
    minHeight: 'unset',
    width: 'max-content',
    backgroundColor: theme.palette.colors.white,

    border: `1px solid ${theme.palette.colors.neutrals200}`,

    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',

    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals300,
    textTransform: 'none',

    padding: '4px 12px',

    opacity: 1,
  },

  selectedTab: {
    borderBottomColor: theme.palette.colors.white,
    color: theme.palette.colors.neutrals600,
  },
}));

export default useStyles;
