import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import SideBar from './components/Sidebar';
import sections from './constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  content: {
    padding: '5% 8% 8% 8%',
  },
  subtitlePadding: {
    paddingTop: '24px',
  },
  scrollContainer: {
    overflowY: 'scroll',
  },
}));

const flattenedSections = sections.reduce((t, val) => t.concat(val.properties), []);

const BrandStyle = () => {
  const classes = useStyles();
  const [page, setPage] = useState('');
  const [scrollRefs, setScrollRefs] = React.useState([]);

  useEffect(() => {
    const updateRefs = {};
    flattenedSections.forEach((section) => {
      updateRefs[section.id] = scrollRefs[section.id] || React.createRef();
    });
    setScrollRefs(updateRefs);
  }, [sections]);

  useEffect(() => {
    if (scrollRefs[page]) {
      scrollRefs[page].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [page]);

  return (
    <div className={classes.root}>
      <SideBar setSelected={setPage} selected={page} />
      <div className={classes.scrollContainer}>
        <Grid container justifyContent="center" />
        {sections.map((section) => (
          <Grid
            key={section.name}
            container
            item
            className={classes.content}
            xs={12}
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography align="left" variant="h1">
                {section.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">
                {section.description}
              </Typography>
            </Grid>
            {section.properties.map((property) => (
              <>
                <Grid key={property.name} item>
                  <div ref={scrollRefs[property.id]} className={classes.subtitlePadding}>
                    <Typography variant="h2">
                      {property.name}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  item
                  container
                  spacing={6}
                >
                  {property.variants && property.variants.map((variant) => (
                    <Grid item xs={property.grid}>
                      <Typography paragraph color="textSecondary">
                        {variant.name}
                      </Typography>
                      {variant.component}
                    </Grid>
                  ))}
                </Grid>
              </>
            ))}
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default BrandStyle;
