import { useState } from 'react';

export default function useModalState(initialOpen = false) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return [isOpen, handleOpen, handleClose, handleToggle];
}
