import { HttpClient } from "../../HttpClient";
import { RequestOptions } from "../../RequestOptions";

export interface GetAdGroupsOptions extends RequestOptions {
  customerId: string;
  managerCustomerId: string;
  campaignId: string;
}

export interface CreateOrUpdateAdOptions extends RequestOptions {
  documentId: string[];
  finalUrl: string;
  integrationId: string;
}

export interface CreateAdGroupOptions extends RequestOptions {
  name: string;
  customerId: string;
  managerCustomerId: string;
  campaignId: string;
  keywords: string[];
}

export interface GetKeywordsRequest extends RequestOptions {
  keywords?: string[];
  pageUrl?: string;
  customerId: string;
  managerCustomerId: string;
}

export interface CreateOrUpdateFile extends RequestOptions {
  customerId?: string;
  customerName?: string;
  managerCustomerId?: string;
  campaignId?: string;
  campaignName?: string;
  adGroupId?: string;
  adGroupName?: string;
}

export interface GetCampaignsRequest extends RequestOptions {
  customerId: string;
  managerCustomerId: string;
}

export default class GoogleAd {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async getCustomers(data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/getCustomers", data);

    return result;
  }

  public async getCampaigns(data: GetCampaignsRequest): Promise<Record<string, unknown>> {
    const result = await this.client.get("/getCampaigns", data);

    return result;
  }

  public async getAdGroups(data: GetAdGroupsOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/getAdGroups", data);

    return result;
  }

  public async getKeywords(data: GetKeywordsRequest): Promise<Record<string, unknown>> {
    const requestData: Record<string, string> = {
      customerId: data.customerId,
      managerCustomerId: data.managerCustomerId,
    };
    if (data.keywords) {
      requestData.keywords = data.keywords.join(";");
    }
    if (data.pageUrl) {
      requestData.pageUrl = data.pageUrl;
    }

    const result = await this.client.get("/getKeywords", requestData);

    return result;
  }

  public async createOrUpdateAd(data: CreateOrUpdateAdOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/", data);

    return result;
  }

  public async createAdGroup(data: CreateAdGroupOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/adGroup", data);

    return result;
  }

  public async getFile(fileId: string, data: RequestOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/files/${fileId}`, data);

    return result;
  }

  public async createOrUpdateFile(fileId: string, data: CreateOrUpdateFile): Promise<Record<string, unknown>> {
    const result = await this.client.post(`/files/${fileId}`, data);

    return result;
  }
}