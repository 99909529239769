import React, { useState } from 'react';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';
import { InputField } from '@root/components/form';

import ProjectSelect from './ProjectSelect';
import Actions from './Actions';
import useStyles from './CreateModal.styles';

export const TEMPLATE_CREATE_MODAL_ID = 'TEMPLATE_CREATE_MODAL_ID';

const CreateModal = () => {
  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [folderId, setFolderId] = useState('');

  const { state: { modalContent, modalId }, dispatch } = useGlobalModals();
  const modalOpen = modalId === TEMPLATE_CREATE_MODAL_ID;

  const handleModalClose = () => {
    dispatch({
      type: actions.CLOSE_MODAL,
    });
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleLocationChange = (location) => {
    setFolderId(location);
  };

  if (!modalContent) {
    return null;
  }

  return (
    <Modal
      open={modalOpen}
      title={`New ${modalContent.title}`}
      onClose={handleModalClose}
    >
      <div className={classes.filename}>
        <InputField
          label="File Name"
          name="name"
          required
          margin="dense"
          onChange={handleTitleChange}
          value={title}
        />
      </div>

      <ProjectSelect
        folderId={folderId}
        onChange={handleLocationChange}
      />

      <Actions
        title={title}
        folderId={folderId}
        templateType={modalContent.templateType}
        onClose={handleModalClose}
      />
    </Modal>
  );
};

export default CreateModal;
