import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      display: 'flex',
      border: `1px solid ${theme.palette.colors.grey}`,
      borderRadius: 4,
    },
    counterBlock: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 16,
    },
    totalBlock: {
      flex: 3,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 16px 8px',
      borderLeft: `1px solid ${theme.palette.colors.grey}`,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      backgroundColor: theme.palette.colors.lightGrey,
    },
    counter: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '70%',
    },
    value: {
      fontWeight: 600,
      fontSize: 16,
    },
    label: {
      color: theme.palette.colors.darkGrey,
      fontSize: 14,
      lineHeight: '22px',
      marginTop: 6,
    },
    button: {
      width: 24,
      height: 24,
    },
  });
});

export default useStyles;
