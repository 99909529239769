import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  transition: {
    height: 1,
    width: 32,
    margin: '0 8px',
    backgroundColor: theme.palette.colors.primary,
  },
  nextTransition: {
    backgroundColor: theme.palette.colors.grey,
  },
}));

export default useStyles;
