import React from 'react';
import clsx from 'clsx';
import { IconDots } from '@tabler/icons-react';
import {
  Menu, MenuItem, Fade,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import NestedMenuItem from 'material-ui-nested-menu-item';

import useStyles from './KebabMenu.styles';

const KebabMenu = ({
  menuItems,
  classNames = {},
  variant = 'card',
  isDisabled,
  useIcon = false,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = !!anchorEl;

  const stopEventPropagation = ({ event }) => {
    event.stopPropagation();
  };

  const handleClickOpen = ({ event }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const wrapOnClick = (handler) => {
    if (!handler || isDisabled) {
      return null;
    }

    return (event) => {
      handler({ handleClose, event });
    };
  };

  return (
    <div
      className={classes.root}
      onClick={wrapOnClick(stopEventPropagation)}
    >
      <div
        className={clsx(
          classes.kebab,
          classNames.kebab,
          {
            [classes.listVariant]: variant === 'list',
            [classes.cardVariant]: variant === 'card',
          },
        )}
        onClick={wrapOnClick(handleClickOpen)}
      >
        {useIcon ? <MoreHoriz /> : <IconDots />}
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        autoFocus={false}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        {menuItems.map((item) => {
          if (!item.items || !item.items.length) {
            return (
              <MenuItem onClick={wrapOnClick(item.onClick)} key={item.key || item.title}>
                {item.title}
              </MenuItem>
            );
          }

          return (
            <NestedMenuItem
              label={item.title}
              parentMenuOpen={isOpen}
              onClick={wrapOnClick(item.onClick)}
              key={item.key}
            >
              {item.items.map((nestedItem) => {
                return (
                  <MenuItem key={nestedItem.title} onClick={wrapOnClick(nestedItem.onClick)}>
                    {nestedItem.title}
                  </MenuItem>
                );
              })}
            </NestedMenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default KebabMenu;
