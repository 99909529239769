import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
  },
  buttonContainer: {
    margin: '0.825rem 0 0 0',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  loadingIcon: {
    height: '30px',
  },
}));
