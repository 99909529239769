import React, { useRef, useState } from 'react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { pickBy } from 'lodash';

import api from '@root/api';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useBillingInfo from '@root/resources/billing/useBillingInfo';
import FullCardForm from '@root/views/Checkout/components/FullCardForm';

const AddCardDialog = ({
  open,
  onClose,
  noClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refetch: refetchBillingData } = useBillingInfo();

  const formRef = useRef();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async ({ name, address, country }) => {
    setIsSubmitting(true);
    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        address: { ...pickBy(address, Boolean), country },
      },
    });

    if (error) {
      if (formRef.current) {
        formRef.current.setFieldError('cardNumber', error.message);
      }
      setIsSubmitting(false);
      return;
    }

    const { isBadRequest } = await api.billing.updateCard({
      paymentMethod: paymentMethod.id,
    });
    if (isBadRequest) {
      if (formRef.current) {
        formRef.current.setFieldError('cardNumber', 'We failed to process payment with this method.');
      }
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    onClose();
    setTimeout(() => refetchBillingData(), 2000);
  };

  const handleConfirm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={handleConfirm}
      title="Update billing information"
      confirmButtonText={isSubmitting ? 'Processing...' : 'Update'}
      isConfirmDisabled={isSubmitting}
      open={open}
      {...(noClose ? {
        noClose: true,
        noCancel: true,
        onClose: null,
      } : { onClose })}
    >
      <FullCardForm
        formRef={formRef}
        onSubmit={handleSubmit}
      />
    </ConfirmationModal>
  );
};

export default AddCardDialog;
