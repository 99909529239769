import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  search: {
    margin: '0 auto',
    maxWidth: '90%',
  },
  searchBox: {
    backgroundColor: 'white',
    width: '470px',
    maxWidth: '100%',
    margin: '0 auto',
  },
});

export default useStyles;
