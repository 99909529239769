import React, { useRef } from 'react';

import AuthContext from '@root/resources/auth/auth.context';
import Loading from '@root/components/Loading/Loading';
import Button from '@root/components/buttons/Button';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';
import { filterActivePlans, getMonoPeriod, PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import ProfileLayout from '../components/ProfileLayout';

import PlanTable from './components/PlanTable';
import PlanCardView from './components/PlanCardView';
import PeriodToggle from './components/PeriodToggle';
import AddonsFeatures from './components/AddonFeatures/AddonsFeatures';
import useStyles from './Plans.styles';
import PlanWarning from './components/PlanWarning';

const Plans = () => {
  const { currentUser: user } = AuthContext.useAuth();
  const {
    isLoading,
    period,
    setPeriod,
    pricingData,
    currentPlan,
    allPricing,
  } = SubscriptionsContext.useSubscriptions();

  const monoPeriod = React.useMemo(() => getMonoPeriod(allPricing), [allPricing]);

  const classes = useStyles();

  const tableRef = useRef(null);

  const activePlans = filterActivePlans(pricingData, user, currentPlan);

  const scrollDown = () => {
    tableRef.current.scrollIntoView();
  };

  const legacyStarters = [
    ...PLANS_IDS.starters,
    ...PLANS_IDS.legacyStarters,
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ProfileLayout isPlansPage>
      <div className={classes.plansRoot}>
        <div className={classes.pageInfo}>
          {!monoPeriod && legacyStarters.includes(currentPlan?._id) ? (
            <div className={classes.upgradeCTA}>
              Limited Time!
              Save $38 on our Unlimited Plan!
              Only pay $190 for a year of unlimited words instead of $228!
              {' '}
            </div>
          ) : (
            <>
              <h1 className={classes.pageTitle}>
                Pick a plan that&apos;s right for you
              </h1>
              <div>
                All our plans will help you to stand out from day one.
                <br />
                You can change your plan or cancel any time.
              </div>
            </>
          )}
          <PeriodToggle
            period={period}
            onChange={setPeriod}
            pricingData={allPricing}
          />
        </div>

        <PlanWarning />

        <PlanCardView
          period={period}
          pricingData={activePlans}
        />
        <Button
          onClick={scrollDown}
          variant="link"
          color="default"
          className={classes.scrollDownButton}
        >
          View Features Breakdown
        </Button>

        <PlanTable
          tableRef={tableRef}
          period={period}
          pricingData={activePlans}
        />

        {!user.onSelfServeEnterprisePlan && <AddonsFeatures />}
      </div>
    </ProfileLayout>
  );
};

const WrappedPlans = () => {
  return (
    <SubscriptionsContext.SubscriptionsProvider>
      <Plans />
    </SubscriptionsContext.SubscriptionsProvider>
  );
};

export default WrappedPlans;
