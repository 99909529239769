import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    padding: '48px 66px',
    maxWidth: 480,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
  },
  title: {
    marginBottom: 8,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '30px',
  },
  description: {
    marginBottom: 32,
  },
  form: {
    width: '100%',
    marginBottom: 48,
    '& > div': {
      width: '100%',
    },
    '& > div:not(:last-child)': {
      marginBottom: 24,
    },
  },
  rightSide: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 55px',
  },
  rightSideContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
  },
  templates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    marginLeft: 12,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',

    '& > div:first-child': {
      marginTop: -22,
    },
    '& > div:last-child': {
      marginBottom: 4,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    '& button': {
      '&.MuiButton-text': {
        color: theme.palette.colors.darkGrey,
      },
      minWidth: 120,
    },
  },
  input: {
    paddingLeft: 12,
  },
  select: {
    '& .select__single-value': {
      fontSize: 14,
      color: theme.palette.colors.black,
    },
  },
  templateIcon: {
    '& svg': {
      color: theme.palette.colors.black,
    },
  },
  titleHint: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 6,
  },
}));
