import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  row: {
    display: 'flex',
    gap: 16,
    width: '100%',
    '& > *': {
      flex: 1,
    },
  },
  indicator: {
    display: 'flex',
    width: '10%',
    '& > *': {
      flex: 1,
    },
    alignSelf: 'end',
    textAlign: 'center',
  },
}));
