import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Input, OutlinedInput } from '@material-ui/core';

import FieldLabel from '@root/components/form/FieldLabel';
import QualityTrack from '@root/components/Fields/QualityTracking';

import useStyles from './InputField.styles';

const inputVariants = {
  standard: Input,
  outlined: OutlinedInput,
};

const InputField = ({
  label, name, error, labelTooltip, placeholder, variant,
  required, optional, multiline, rows, fullWidth, classNames,
  onBlur, quality, helperText,
  ...props
}) => {
  const InputComponent = inputVariants[variant];

  const classes = useStyles();

  const handleBlur = (e) => { // sometimes e is undefined, need to investigate more
    if (e && onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={classNames.root}>
      <FieldLabel
        label={label}
        name={name}
        labelTooltip={labelTooltip}
        required={required}
        optional={optional}
      />
      <InputComponent
        id={name}
        variant="outlined"
        error={!!error}
        multiline={multiline}
        rows={rows}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onBlur={handleBlur}
        {...props}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>
          {error || helperText}
        </FormHelperText>
      )}
      {quality && (
        <QualityTrack
          className={classes.qualityTrack}
          qualitySettings={quality}
          length={props.value?.length}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  fullWidth: PropTypes.bool,
  labelTooltip: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
};

InputField.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
  error: undefined,
  multiline: false,
  variant: 'outlined',
  rows: 5,
  maxLength: undefined,
  fullWidth: true,
  labelTooltip: undefined,
  classNames: {},
};

export default InputField;
