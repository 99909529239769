import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    height: 180,
    border: `1px solid ${theme.palette.colors.neutrals200}`,
    borderRadius: '10px',
    padding: '18px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.colors.white,
    backgroundSize: 'cover',
    '&:hover': {
      backgroundColor: theme.palette.colors.lightGrey,
    },
  },
  topActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 18,
    fontFamily: '"Inter", sans-serif',
  },
  bottomActions: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
  },
  csvDownload: {
    marginLeft: '0.8rem',
    fontSize: '0.75rem',
    cursor: 'pointer',
    color: `${theme.palette.colors.grey}`,
    '&:hover': {
      color: `${theme.palette.colors.darkGrey}`,
    },
  },
  title: {
    fontSize: 13.5,
    lineHeight: '18px',
    fontWeight: 700,
    marginBottom: 6,
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  description: {
    marginBottom: 0,
    fontSize: 10.5,
    flexGrow: '1',
    cursor: ({ isOnboardingActive }) => !isOnboardingActive && 'pointer',
    whiteSpace: 'normal',
  },
  iconButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  createButton: {
    fontSize: 10.5,
    height: 27,
    minWidth: 70,
  },
}));

export default useStyles;
