import { useQuery } from 'react-query';

import api from '@root/api';

const getProducts = async () => {
  const products = await api.akeneo.getCategories();

  return products;
};

export default function useGetCategories() {
  return useQuery(['akeneo.getCategories'], () => getProducts(), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
