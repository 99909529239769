import { useMutation } from 'react-query';

import api from '@root/api';

export default function useMappingValidate() {
  return useMutation(async (data) => {
    const shouldDisableNotification = (errors) => {
      const fileErrors = errors?.filter((e) => e.file) || [];
      return fileErrors.length > 0;
    };

    const response = await api.bulkGeneration.validateMapping(data, {
      disableFirstErrorNotification: shouldDisableNotification,
    });

    return response;
  });
}
