import React, { useEffect, useState } from 'react';

import Modal from '@root/components/modals/Modal';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

import NewFolderForm from './NewFolderForm';
import NewFolderPresetForm from './NewFolderPresetForm';
import PageEnum from './PageEnumFolder';
import useStyles from './NewFolderModal.styles';

const NewFolderModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { state: { modalContent } } = useGlobalModals();
  const initialParentFolderId = modalContent?.parentFolderId;

  const [page, setPage] = useState(PageEnum.INITIAL);
  const [folderName, setFolderName] = useState('');
  const [parentFolderId, setParentFolderId] = useState(null);

  const isInitialPage = page === PageEnum.INITIAL;
  const isPresetsPage = page === PageEnum.PRESETS;

  const handleModalClose = () => {
    setPage(PageEnum.INITIAL);
    closeModal?.();
  };

  useEffect(() => {
    if (initialParentFolderId) {
      setParentFolderId(initialParentFolderId);
    }
  }, [initialParentFolderId]);

  return (
    <Modal
      open={modalOpen}
      title={isInitialPage ? 'New folder' : 'Folder settings'}
      subtitle={
        isInitialPage
          ? ''
          : (
            <span>
              When creating new files within this folder,
              the input fields will be auto-filled according
              to the settings you have chosen for the folder.
              <span className={classes.redText}> If left blank, Copysmith will use your Profile defaults.</span>
            </span>
          )
      }
      onClose={handleModalClose}
      classNames={{ paper: classes.paper }}
    >
      {isInitialPage && (
        <NewFolderForm
          setPage={setPage}
          setFolderName={setFolderName}
          setParentFolderId={setParentFolderId}
          initialParentFolderId={parentFolderId}
          classes={classes}
          closeModal={handleModalClose}
        />
      )}

      {isPresetsPage && (
        <NewFolderPresetForm
          classes={classes}
          closeModal={handleModalClose}
          title={folderName}
          parentFolderId={parentFolderId}
        />
      )}
    </Modal>
  );
};

export default NewFolderModal;
