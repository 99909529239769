import React from 'react';
import clsx from 'clsx';

import useStyles from './Divider.styles';

type Props = {
  className?: string
};

const Divider = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.divider, className)} />
  );
};

export default Divider;
