import React from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { CheckboxField, FormField, InputField, SelectField } from '@root/components/form';
import KeywordInput from '@root/views/Profile/EditProfile/components/KeywordInput';
import Button from '@root/components/buttons/Button';
import AuthContext from '@root/resources/auth/auth.context';
import companyResource from '@root/resources/company';
import uiNotificationService from '@root/services/uiNotification.service';
import translateLanguages from '@root/components/translateLanguages';

import TeamPlanUnavailable from './TeamPlanUnavailable';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '2rem',
  },
  innerWrapper: {
    width: '100%',
  },
  title: {
    color: '#000',
    textAlign: 'left',
    fontFamily: '"Inter", sans-serif',
  },
  warn: {
    color: theme.palette.colors.lightRed,
  },
  nameContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  bolded: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  topSpacing: {
    '&$title$bolded': {
      marginBottom: '40px',
    },
  },
  resetPasswordButton: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  form: {
    '& > :not(:last-child)': {
      marginBottom: 18,
    },
    '& input': {
      backgroundColor: 'white',
    },
  },
  textareaWrap: {
    backgroundColor: 'white',
  },
}));

const TeamDetails = () => {
  const { currentUser: user, currentCompany, fetchAuthData } = AuthContext.useAuth();
  const { mutateAsync: updateCompany } = companyResource.useUpdateCompany();
  const classes = useStyles();

  const submitCompany = async (values, { setSubmitting }) => {
    const {
      url,
      company,
      defaultDescription,
      defaultKeywords: defaultKeywordsValues,
      prefillInputs,
      language,
    } = values;

    const { isBadRequest } = await updateCompany({
      name: company,
      url,
      description: defaultDescription,
      keywords: defaultKeywordsValues,
      prefillInputs: !!prefillInputs,
      language,
    });
    await fetchAuthData();
    setSubmitting(false);
    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Information has been updated.');
    }
  };

  const company = currentCompany.company || '';
  const defaultDescription = currentCompany.defaultDescription || '';
  const url = currentCompany.companyUrl || '';
  const prefillInputs = currentCompany.prefillInputs || false;
  const defaultKeywords = currentCompany.defaultKeywords || [];
  const language = currentCompany.language || 'en';
  const isDisabled = !user.roles.owner;

  const hasTeamPermissions = user.permissions.teams;
  if (hasTeamPermissions) {
    if (!(user.roles.owner || user.roles.admin)) {
      return <TeamPlanUnavailable />;
    }
  } else {
    return <TeamPlanUnavailable />;
  }

  return (
    <Formik
      initialValues={{
        company,
        url,
        defaultDescription,
        defaultKeywords,
        prefillInputs,
        language,
      }}
      onSubmit={submitCompany}
    >
      <Form className={classes.form}>
        <div className={clsx(
          classes.title,
          classes.topSpacing,
          classes.bolded,
        )}
        >
          Company Information
        </div>
        <>
          <FormField
            label="Company"
            name="company"
            placeholder="Copysmith"
            component={InputField}
            required
            disabled={isDisabled}
          />
          <FormField
            label="Company URL"
            name="url"
            placeholder="www.copysmith.ai"
            component={InputField}
            disabled={isDisabled}
          />
          <FormField
            label="Company Description"
            name="defaultDescription"
            placeholder="Copysmith uses AI to generate copy."
            multiline
            className={classes.textareaWrap}
            component={InputField}
            disabled={isDisabled}
          />
        </>
        <KeywordInput
          disabled={isDisabled}
          name="defaultKeywords"
          label="Product keywords"
        />
        <FormField
          label="Default language"
          name="language"
          component={SelectField}
          disabled={isDisabled}
          options={translateLanguages}
        />
        <FormField
          component={CheckboxField}
          name="prefillInputs"
          label="Prefill inputs by default"
          disabled={isDisabled}
        />
        <div className={classes.smallTopSpacing}>
          <Button
            disabled={isDisabled}
            type="submit"
          >
            Update
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default TeamDetails;
