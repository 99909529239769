import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emptyStateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    gap: 4,
  },
  emptyStateTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.neutrals600,
  },
  overageCard: {
    height: '140px',
    overflow: 'auto',
  },
  emptyStateLink: {
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.colors.blue400,
    textDecoration: 'underline',
  },
  overageList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gap: '12px',
  },
  overageHeader: {
    display: 'contents',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '30px',
    color: theme.palette.colors.neutrals300,
  },
  divider: {
    gridColumn: '1/-1',
    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 11,
  },
  cancelButton: {
    textDecoration: 'underline',
  },
  overageInfo: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '30px',
    color: theme.palette.colors.neutrals600,
  },
}));

export default useStyles;
