import { Avatar, DialogContentText, ListItemAvatar, ListItemText, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import Modal from '@root/components/modals/Modal';
import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import zapierIcon from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton/zapier-icon.svg';
import googleDocsIcon from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton/googleDocs-icon.svg';
import hootsuiteIcon from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton/hootsuite-icon.svg';
import CheckIcon from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton/CheckIcon';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import uiNotificationService from '@root/services/uiNotification.service';
import usePublishDocument from '@root/resources/document/usePublishDocument';
import useCreateIntegration from '@root/resources/integrations/useCreateIntegration';
import PublishDocsDialog from '@root/components/integrations/GoogleDocs/components/PublishDocsDialog';

const HOOTSUITE_INTEGRATION_TYPE = 'hootsuite';

const useStyles = makeStyles(() => ({
  avatarImg: {
    width: 'auto',
    objectFit: 'fill',
  },
  avatar: {
    borderRadius: 0,
    objectFit: 'fill',
  },
  menuItem: {
    overflow: 'visible',
  },
  menuItemDisabled: {
    cursor: 'default',
    pointerEvents: 'all',
    opacity: 0.5,

    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const PublishDialog = ({
  document,
  onClose,
  zapierIntegration,
  googleDocsIntegration,
}) => {
  const classes = useStyles();

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { currentUser } = AuthContext.useAuth();
  const { mutateAsync: publishDocument } = usePublishDocument();
  const { mutateAsync: createIntegration } = useCreateIntegration();

  const {
    publishedToZapier,
    publishedToGoogleDocs,
  } = React.useMemo(() => {
    const { publishLog } = document || {};

    return {
      publishedToZapier: publishLog && publishLog.find((l) => l.integrationType === 'zapier'),
      publishedToGoogleDocs: publishLog && publishLog.find((l) => l.integrationType === 'googleDocs'),
    };
  }, [document]);

  const {
    toggles: {
      showHootsuitePublishButton,
      showExternalSourceTooltip,
      disableZapierPublish,
      disableGoogleDocsPublish,
    },
    getPublishMessage,
  } = currentExternalSource;

  const zapierEnabled = !!zapierIntegration;
  const googleDocsEnabled = !!googleDocsIntegration;
  const showZapier = currentUser.permissions.zapier;
  const showGoogleDocs = currentUser.permissions.googleDocsPublishing;
  const showHootsuite = showHootsuitePublishButton;
  const [publishGoogleDocsOpen, setPublishGoogleDocsOpen] = React.useState(false);

  const onZapierPublish = async () => {
    const { isBadRequest } = await publishDocument({
      _id: document._id,
      fileId: document.fileId,
      data: {
        integrationId: zapierIntegration._id,
      },
    });

    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Document has been published to Zapier.');
      onClose();
    }
  };

  const onHootsuitePublish = async () => {
    const text = getPublishMessage(document);
    const htmlRegExp = /<\/?[^>]+(>|$)/g;

    hsp.composeMessageV2(
      text.replace(htmlRegExp, ''),
    );

    const integration = await createIntegration({
      type: HOOTSUITE_INTEGRATION_TYPE,
      status: 'active',
    });

    await publishDocument({
      _id: document._id,
      fileId: document.fileId,
      data: {
        integrationId: integration._id,
      },
    });
    onClose();
  };

  const isZapierDisabled = !zapierEnabled || disableZapierPublish;
  const isGoogleDocsDisabled = disableGoogleDocsPublish || !googleDocsEnabled;

  const onGoogleDocsPublish = async () => {
    setPublishGoogleDocsOpen(true);
  };

  const onGoogleDocsPublishClose = async () => {
    setPublishGoogleDocsOpen(false);
  };
  return (
    <Modal onClose={onClose} title="Publish to...">
      <DialogContentText>
        Select where to publish your generations. You can select as many as you wish.
      </DialogContentText>
      <div>
        {showHootsuite && (
          <MenuItem
            onClick={onHootsuitePublish}
            className={classes.menuItem}
          >
            <ListItemAvatar>
              <>
                <Avatar
                  classes={{ root: classes.avatar, img: classes.avatarImg }}
                  src={hootsuiteIcon}
                />
                {publishedToZapier && <CheckIcon />}
              </>
            </ListItemAvatar>
            <ListItemText
              className={classes.username}
              primary="Publish to Hootsuite"
            />
          </MenuItem>
        )}
        {showZapier && (
          <SimpleTooltip
            content={showExternalSourceTooltip && <ExternalSourceText />}
            interactive
            placement="left"
          >
            <MenuItem
              onClick={!isZapierDisabled && onZapierPublish}
              className={clsx(
                classes.menuItem,
                { [classes.menuItemDisabled]: isZapierDisabled },
              )}
            >
              <ListItemAvatar>
                <>
                  <Avatar
                    className={classes.avatar}
                    src={zapierIcon}
                  />
                  {publishedToZapier && <CheckIcon />}
                </>
              </ListItemAvatar>
              <ListItemText
                className={classes.username}
                primary="Publish to Zapier"
              />
            </MenuItem>
          </SimpleTooltip>
        )}
        {showGoogleDocs && (
          <SimpleTooltip
            content={showExternalSourceTooltip && <ExternalSourceText />}
            interactive
            placement="left"
          >
            <MenuItem
              onClick={!isGoogleDocsDisabled && onGoogleDocsPublish}
              className={clsx(
                classes.menuItem,
                { [classes.menuItemDisabled]: isGoogleDocsDisabled },
              )}
            >
              <ListItemAvatar>
                <>
                  <Avatar
                    classes={{ root: classes.avatar, img: classes.avatarImg }}
                    src={googleDocsIcon}
                  />
                  {publishedToGoogleDocs && <CheckIcon />}
                </>
              </ListItemAvatar>
              <ListItemText
                className={classes.username}
                primary="Publish to Google Docs"
              />
            </MenuItem>
          </SimpleTooltip>
        )}
        {publishGoogleDocsOpen && (
          <PublishDocsDialog
            document={document}
            onClose={onGoogleDocsPublishClose}
            integrationId={googleDocsIntegration?._id}
          />
        )}
      </div>
    </Modal>
  );
};

export default PublishDialog;
