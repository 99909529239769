import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    height: 160,
    margin: '16px 0',
    borderRadius: 6,
    border: `1px dashed ${theme.palette.colors.neutrals200}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&.error': {
      border: `1px solid ${theme.palette.colors.pink600}`,
      backgroundColor: lighten(theme.palette.colors.pink400, 0.9),
      color: theme.palette.colors.pink600,
    },
  },
  bottomLine: {
    marginTop: 4,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 8,
    color: theme.palette.colors.neutrals200,
    '&.error': {
      color: theme.palette.colors.pink600,
    },
  },
  fileButton: {
    color: theme.palette.colors.blue400,
    '&:hover': {
      color: lighten(theme.palette.colors.blue400, 0.2),
    },
    '&.error': {
      color: theme.palette.colors.pink600,
    },
  },
  input: {
    paddingRight: 4,
  },
  inputButton: {
    minWidth: 132,
    maxHeight: 30,
  },
}));

export default useStyles;
