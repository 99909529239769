import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface ListOptions extends RequestOptions {
  folderIds?: string[] | null;
  sort?: string;
  sortOrder?: string;
  page?: number;
  perPage?: number;
  searchQuery?: string;
  liked?: boolean;
  templateTypes?: string[];
  workflowStatuses?: string[];
  externalIds?: string[];
}

export interface CreateOptions extends RequestOptions {
  title: string;
  folderId?: string | null;
  templateType: string;
  data: Record<string, unknown>;
  negativeKeywordArray?: string[];
  engine?: string;
  externalId?: string;
  campaignDocumentId?: string;
}

export interface ShareOptions extends RequestOptions {
  acl: {
    userId: string;
    role: string;
  }[];
  removedItems: string[],
}

export interface UpdateOptions extends RequestOptions {
  title?: string;
  folderId?: string;
  data?: Record<string, unknown>;
  negativeKeywordArray?: string[];
  engine?: string;
  externalId?: string;
}

export interface CountOptions extends RequestOptions {
  folderId?: string;
  templateType?: string;
}

export interface GetPresetsOptions extends RequestOptions {
  fileId: string;
}

export class File {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public list(data?: ListOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    if (!data) {
      data = {};
    }
    let templateTypes = "";
    if (data.templateTypes) {
      templateTypes = data.templateTypes.join(",");
    }
    let externalIds = "";
    if (data.externalIds) {
      externalIds = data.externalIds.join(",");
    }
    
    let workflowStatuses = "";
    if (data.workflowStatuses) {
      workflowStatuses = data.workflowStatuses.join(",");
    }

    let folderIds = "";
    if (data.folderIds) {
      folderIds = data.folderIds.join(",");
    }
    
    return this.client.get("/files", {
      ...data,
      templateTypes,
      externalIds,
      workflowStatuses,
      folderIds
    }, options);
  }

  public getById(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get(`/files/${id}`, { token }, options);
  }

  public getByIdShared(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get(`/files/${id}/shared`, { token }, options);
  }

  public async create(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/files", data, options);

    return result;
  }

  public update(
    id: string,
    data: UpdateOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/files/${id}`, data, options);
  }

  public delete(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.del(`/files/${id}`, { token }, options);
  }

  public like(
    id: string,
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.put(`/files/${id}/like`, { token }, options);
  }
  
  public getTemplateTypes(
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/files/templateTypes", { token }, options);
  }

  public getTemplates(
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/files/getTemplates", { token }, options);
  }

  public getWorkflowStats(
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/files/workflowStats", { token }, options);
  }

  public getPresets(data: GetPresetsOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/files/getPresets", data, options);
  }

  public share(id: string, data: ShareOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/files/${id}/share`, data, options);
  }

  public count(
    data: CountOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/files/count", data, options);
  }
}
