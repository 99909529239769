import React from 'react';
import { IconTrash } from '@tabler/icons-react';

import useStyles from './DeleteCatalog.styles';

const DeleteCatalogButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.star} onClick={onClick}>
      <IconTrash className={classes.delete} />
    </div>
  );
};

export default DeleteCatalogButton;
