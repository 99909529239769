import mixpanel from 'mixpanel-browser';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import config from '@config';

const { key } = config.mixpanel;
mixpanel.init(key);

const enabled = config.env === 'production';

const baseService = {
  identify: (id) => {
    if (!enabled) {
      return;
    }

    mixpanel.identify(id);
  },
  alias: (id) => {
    if (!enabled) {
      return;
    }

    mixpanel.alias(id);
  },
  track: (name, props) => {
    if (!enabled) {
      return;
    }

    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (!enabled) {
        return;
      }

      mixpanel.people.set(props);
    },
  },
};

const init = (user, company) => {
  baseService.identify(user._id);

  baseService.people.set({
    companyName: company.name,
    currentLimitId: company.currentLimitId,
    $name: user.displayName,
    $email: user.email,
    emailVerified: user.emailVerified,
    // TODO: what is it?
    lastMonth: undefined,

    monthlyLimit: company.monthlyGenerationsLimit === 'monthlyGenerationsLimit' ? company.billing.limit : 0,
    newFreeTrialLimit: company.billing.limitMode === 'newFreeTrialLimit' ? company.billing.limit : 0,
    start_date: company.billing.trialStartOn,
    stripe_id: company.billing.customer_id,
    uid: user._id,
  });
};

const identify = (userId) => {
  baseService.identify(userId);
};

const track = (name, props) => {
  baseService.track(name, props);
};

// Event Aggregator Subscriptions
eventsAggregator.subscribe(EVENT_NAMES.GENERATION_COPIED_TO_CLIPBOARD, (_, data) => {
  const { template } = data;
  track('Copied to Clipboard', { template });
});

eventsAggregator.subscribe(EVENT_NAMES.GENERATE_BUTTON_CLICKED, (_, data) => {
  const { template, engine } = data;
  track('Clicked Generate', { template, engine });
});

eventsAggregator.subscribe(EVENT_NAMES.PAGE_VISITED, (_, data) => {
  const { pageTitle, pageType } = data;
  track('Page Visited', { pageTitle, pageType });
});

eventsAggregator.subscribe(EVENT_NAMES.PLAGIARISM_CHECKED, (_, data) => {
  const { remainingChecks, template } = data;
  track('Checked Plagiarism', { remainingChecks, template });
});

eventsAggregator.subscribe(EVENT_NAMES.CAMPAIGN_CREATED, () => {
  track('New Campaign', { });
});

eventsAggregator.subscribe(EVENT_NAMES.FRASE_KEYWORDS_PROCESSED, (_, data) => {
  const { template, query, number } = data;
  track('Frase Keywords Processed', { template, query, number });
});

eventsAggregator.subscribe(EVENT_NAMES.FRASE_SEARCH_QUERY_PROCESSED, (_, data) => {
  const { query } = data;
  track('Frase Search Query Processed', { query });
});

eventsAggregator.subscribe(EVENT_NAMES.VIEW_ALI_EXPERT_VOICES_CLICKED, (_, data) => {
  const { templateType } = data;
  track('View All Ali Export Voices Clicked', { templateType });
});

eventsAggregator.subscribe(EVENT_NAMES.FINE_TUNE_SELECTED, (_, data) => {
  const { fineTuneId } = data;
  track('Fine Tune Selected', { fineTuneId });
});

export default {
  init,
  identify,
};
