import hspService from '@root/services/hsp.service';

import hootsuiteConfig from './hootsuite';
import defaultConfig from './default';

const getCurrentExternalSource = async () => {
  const isHootsuite = await hspService.isHootsuite();

  if (isHootsuite) {
    return hootsuiteConfig;
  }

  return defaultConfig;
};

export default getCurrentExternalSource;
