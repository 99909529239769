import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import MainLayout from '@root/views/Layout/MainLayout';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './SuperAdminLayout.styles';

const SuperAdminLayout = ({
  children,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path="*"
          render={() => {
            if (!currentUser.permissions.superAdmin) {
              return <Redirect to="/templates" />;
            }

            return null;
          }}
        />
      </Switch>
      <div className={classes.root}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </MainLayout>
  );
};

export default SuperAdminLayout;
