import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  rowWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '36px',
    paddingLeft: '20px',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
  },
  rowTitle: {
    textAlign: 'left',
    flexGrow: 1,
    lineHeight: '36px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    color: theme.palette.colors.darkGrey,
  },
  rowItem: {
    textAlign: 'center',
    lineHeight: '36px',
    color: theme.palette.colors.primary,
    width: window.hsp?.Initialized ? 'max(25%, 220px)' : '25%',
  },
  bold: {
    fontWeight: 500,
  },
  noPadding: {
    marginLeft: '-20px',
  },
}));
