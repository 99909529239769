import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core';

import FraseIcon from '@root/components/integrations/Frase/components/FraseIcon';
import FraseKeywords from '@root/components/integrations/Frase/components/FraseKeywords';

import SimpleTooltip from '../tooltips/SimpleTooltip';

import Pill from './PillAtom';
import AddTitleAtom from './AddTitleAtom';

const combineMonad = (...funcs) => (...args) => funcs.forEach((f) => f(...args));

const useStyles = makeStyles((theme) => ({
  generatorInput: {
    height: '40px',
    borderRadius: theme.border.radius.default,
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    width: '100%',
    border: '0.5px solid #aaaaaa',
    backgroundColor: 'white',

    background: 'white',
    fontStyle: 'normal',
    paddingLeft: '20px',
    '&:focus': {
      outline: 0,
    },
    '&:active': {
      outline: 0,
    },
  },
  tooltipWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 0,
  },
  tooltipPosition: {
    marginTop: -35,
  },
}));

const KeywordInputAtom = ({
  array,
  setArray,
  title,
  formik = false,
  required = true,
  hasFrase = false,
  isFraseOn = false,
  showFraseDefault = false,
  templateType,
  disabled,
}) => {
  const classes = useStyles();
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [showFrase, setShowFrase] = useState(showFraseDefault);

  const handleOutsideClick = () => {
    if (currentInputValue.adj && currentInputValue.adj.trim()) {
      const splitted = currentInputValue.adj.split(',').map((s) => s.trim());
      if (formik) {
        setArray(splitted);
      } else if (array) {
        setArray((adjs) => [...new Set([...adjs, ...splitted])]);
      } else {
        setArray([...splitted]);
      }
    }
  };
  return (
    <>
      <AddTitleAtom
        required={required}
        title={title}
      >
        <div style={{ width: '100%' }}>
          <Formik
            initialValues={{ adj: '' }}
            onSubmit={combineMonad(({ adj }, { resetForm }) => {
              const strToArray = adj.split(',')
                .map((str) => str.trim())
                .filter((str) => !!str && str !== ' ');
              if (formik) {
                setArray(strToArray);
              } else if (array) {
                setArray((adjs) => [...new Set([...adjs, ...strToArray])]);
              } else {
                setArray(strToArray);
              }
              resetForm();
            })}
          >
            {({
              handleChange, handleSubmit, resetForm, values,
            }) => (
              <Form>
                <ClickAwayListener
                  onClickAway={() => {
                    handleOutsideClick();
                    resetForm();
                  }}
                >
                  <input
                    className={classes.generatorInput}
                    name="adj"
                    id="adj"
                    type="text"
                    placeholder="Enter ↵"
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        handleSubmit(e);
                      }
                    }}
                    onSubmit={handleSubmit}
                    value={currentInputValue.adj}
                    onReset={resetForm}
                    disabled={disabled}
                  />
                </ClickAwayListener>
                {setCurrentInputValue(values)}
              </Form>
            )}
          </Formik>
        </div>

        <div className={classes.tooltipWrap}>
          <div className={classes.tooltipPosition}>
            {hasFrase && (
              <SimpleTooltip
                interactive
                placement="right"
                content={(
                  <span>
                    Improve your content with SEO-optimized keywords.
                    {' '}
                    <a
                      href="https://describely.notion.site/Find-SEO-Optimized-Keywords-Powered-By-Frase-3d89c7193952445691bdf6bf5e17d174"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to learn more!
                    </a>
                  </span>
                )}
              >
                <div>
                  <FraseIcon setShowFrase={setShowFrase} showFrase={showFrase} />
                </div>
              </SimpleTooltip>
            )}
          </div>
        </div>

        <div className="d-flex flex-wrap">
          {array
              && array.map((adj) => (
                <Pill
                  name={adj}
                  key={adj}
                  className="mt-1 mb-1"
                  removeState={setArray}
                />
              ))}
        </div>
      </AddTitleAtom>

      {hasFrase && (
        <FraseKeywords
          showFrase={showFrase}
          disabled={!isFraseOn}
          setShowFrase={setShowFrase}
          setArray={setArray}
          array={array}
          templateType={templateType}
        />
      ) }
    </>
  );
};

export default KeywordInputAtom;
