import React from 'react';

import { useCommunityContext } from '@root/views/Community/context/Community.context';

import CommunityTemplateCard from './components/CommunityTemplateCard';
import useStyles from './CardContentView.styles';

const CardContentView = () => {
  const classes = useStyles();

  const { templatesToShow } = useCommunityContext();

  return (
    <div className={classes.cardContentViewContainer}>
      {Object.values(templatesToShow)?.map((template) => (
        <CommunityTemplateCard
          key={template?._id}
          template={template}
        />
      ))}
    </div>
  );
};

export default CardContentView;
