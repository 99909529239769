import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M12 5.333c.368 0 .667.299.667.667v.667a.667.667 0 0 1-1.334 0V6c0-.368.299-.667.667-.667ZM7.262 7.262c.26-.26.682-.26.943 0l.466.467a.667.667 0 1 1-.942.942l-.467-.466a.667.667 0 0 1 0-.943Zm9.476 0c.26.26.26.682 0 .943l-.467.466a.667.667 0 1 1-.943-.942l.467-.467c.26-.26.683-.26.943 0ZM5.333 12c0-.368.299-.667.667-.667h.667a.667.667 0 0 1 0 1.334H6A.667.667 0 0 1 5.333 12Zm11.334 0c0-.368.298-.667.666-.667H18a.667.667 0 0 1 0 1.334h-.667a.667.667 0 0 1-.666-.667ZM12 9.333a2.666 2.666 0 0 0-1.565 4.829c.015.013.03.026.043.04a3 3 0 0 1 .856 2.502.667.667 0 0 0 1.332 0 3 3 0 0 1 .865-2.511.675.675 0 0 1 .069-.06 2.667 2.667 0 0 0-1.6-4.8ZM9.559 15.17a4 4 0 1 1 4.882 0 1.665 1.665 0 0 0-.448 1.4.666.666 0 0 1 .007.098 2 2 0 0 1-4 0c0-.033.002-.066.007-.098a1.666 1.666 0 0 0-.448-1.4Z" />
      <path d="M9.8 15.333c0-.368.298-.666.667-.666h3.066a.667.667 0 1 1 0 1.333h-3.066a.667.667 0 0 1-.667-.667Z" />
    </svg>
  );
};
