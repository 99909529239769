import React from 'react';

import useStyles from './Keyword.styles';

const Keyword = ({ onClick, keyword, selected, disabled }) => {
  const { text, score, color } = keyword;

  const classes = useStyles({ color, selected, disabled });

  const handleClick = () => {
    onClick(keyword);
  };

  return (
    <span
      className={classes.keywordRoot}
      key={text}
      onClick={!disabled ? handleClick : undefined}
    >
      <b>{score}</b>
      {' '}
      {text}
    </span>
  );
};

export default Keyword;
