import React from 'react';
import { Dashboard, List } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import { useDashboard, actions, viewTypes } from '@root/views/Dashboard/dashboardContext';

const useStyles = makeStyles({
  dashViewSelect: {
    cursor: 'pointer',
    padding: '0.25rem',
    borderRadius: '4px',
    '&:hover,&:active': {
      backgroundColor: '#cecece',
    },
  },
});

const ViewSelect = () => {
  const { state: { view }, dispatch } = useDashboard();

  const classes = useStyles();

  const handleClick = () => {
    dispatch({
      type: actions.SET_VIEW,
      payload: {
        view:
          view === viewTypes.LIST ? viewTypes.CARD : viewTypes.LIST,
      },
    });
  };

  return (
    <div
      className={classes.dashViewSelect}
      title="Card/List View Toggle"
      onClick={handleClick}
    >
      { view === viewTypes.LIST
        ? <Dashboard />
        : <List />}
    </div>
  );
};

export default ViewSelect;
