import pluralize from 'pluralize';
import React from 'react';
import { StringUtil } from '@copysmith/utils';

const useContentLength = ({
  plainText,
  showChar,
}) => {
  const contentLength = React.useMemo(() => {
    if (showChar) {
      return `Content: ${plainText.length} ${pluralize('char', plainText.length)}`;
    }
    const wordsCount = StringUtil.countWordsString(plainText);
    return `Content: ${wordsCount} ${pluralize('word', wordsCount)}`;
  }, [plainText, showChar]);

  return {
    contentLength,
  };
};

export default useContentLength;
