import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';
import templatesContext from '@root/resources/templates/templates.context';
import uiNotificationService from '@root/services/uiNotification.service';

export default function useShareTemplateToCommunity() {
  const queryClient = useQueryClient();
  const { loadTemplates } = templatesContext.useTemplates();

  return useMutation(
    ({ id, data }) => {
      return api.aiTemplate.shareTemplateToCommunity(id, data);
    },
    {
      onSuccess: async (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries('aiTemplates.list');
        queryClient.invalidateQueries(['aiTemplates.getById', response._id]);

        await loadTemplates(false);

        uiNotificationService.showSuccessMessage('Template was uploaded to Community');

        return response;
      },
    },
  );
}
