import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import Button from '@root/components/buttons/Button';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import PromptSettings from '@root/components/PromptSettings';
import useModalState from '@root/utils/hooks/useModalState';

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 32,
    color: theme.palette.colors.darkGrey,
  },
  icon: {
    marginRight: 4,
  },
}));

const AdditionalSettingsButton = ({ templateType, onChange, initialValues = {} }) => {
  const [isOpen, openModal, closeModal] = useModalState();
  const classes = useStyles();

  const [values, setValues] = useState(initialValues);

  const handleChange = ({ name, value }) => {
    setValues((state) => ({ ...state, [name]: value }));
  };

  const handleCancel = () => {
    setValues(initialValues);
    closeModal();
  };

  const handleApply = async () => {
    const {
      engine: newEngine,
      language,
      outputLanguage,
      ...aiOptions
    } = values;
    onChange(
      {
        name: 'aiOptions',
        value: aiOptions,
      },
      {
        name: 'engine',
        value: newEngine,
      },
    );
    closeModal();
  };

  useEffect(() => {
    setValues((state) => ({ ...state, ...initialValues }));
  }, [initialValues]);

  return (
    <>
      <Button
        variant="link"
        onClick={openModal}
      >
        <Add className={classes.icon} />
        Additional Settings
      </Button>
      {isOpen && (
        <ConfirmationModal
          onClose={handleCancel}
          onConfirm={handleApply}
          title="Additional Settings"
          confirmButtonText="Apply"
        >
          <div className={classes.content}>
            <div className={classes.description}>
              Adjust the additional options below to change the style, contents,
              and number of content variations.
            </div>
            <PromptSettings
              templateType={templateType}
              values={values}
              onChange={handleChange}
            />
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default AdditionalSettingsButton;
