import React, { useState, useRef, useMemo } from 'react';
import { Button, Divider } from '@material-ui/core';
import clsx from 'clsx';

import config from '@root/config';
import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import uiNotificationService from '@root/services/uiNotification.service';
import useModalState from '@root/utils/hooks/useModalState';
import Modal from '@root/components/modals/Modal';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import rytrLogo from '@root/assets/rytr-logo.svg';
import describelyLogo from '@root/assets/describely-logo.png';

import useStyles from './CancelSubscriptionModal.styles';
import Stats from './components/Stats';
import Discount from './components/Discount';
import CancelationForm from './components/CancelationForm';

const CancelSubscriptionModal = ({ onClose, open }) => {
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const { data: { currentPlan } = { } } = billingResource.usePricing();
  const billsMonthly = currentPlan?.period === 'monthly';

  const [isCancelled, openCancelled, closeCancelled] = useModalState();
  const [isDiscounted, openDiscounted, closeDiscounted] = useModalState();
  const [tabIndex, setTabIndex] = useState(0);

  const cancellationBonusEnabled = currentUser.featureToggles.cancellationBonus
    && currentUser.featureToggles.rewardWords
    && !currentCompany.billing.extendedBeforeCancel;

  const formRef = useRef();
  const classes = useStyles();

  const setNextStep = () => {
    setTabIndex((i) => i + 1);
  };

  const setPrevStep = () => {
    setTabIndex((i) => i - 1);
  };

  const sendAlert = async () => {
    const templateParameters = {
      name: currentUser.displayName,
      email: currentUser.email,
      uid: currentUser._id,
      price: currentCompany.currentLimitId,
      plan_interval: billsMonthly ? 'month' : 'year',
    };

    if (window.emailjs) {
      try {
        await window.emailjs.send('service_misuujj', 'template_gdf49tw', templateParameters);
        uiNotificationService.showSuccessMessage('Support will contact you shortly!');
      } catch (e) {
        uiNotificationService.showErrorMessage('An unexpected error occurred. Please try contacting support via the chat in the lower right corner.');
      }
    } else {
      uiNotificationService.showErrorMessage('Message failed to send - please contact support via the chat button on the lower right.');
    }
  };

  const handleClose = () => {
    setTabIndex(0);
    onClose();
  };

  const closeCancelledModal = () => {
    closeCancelled();
    window.location.reload();
  };

  const handleCancelled = () => {
    handleClose();
    openCancelled();
  };

  const handleDiscounted = () => {
    handleClose();
    openDiscounted();
  };

  const cancelSubscription = async () => {
    formRef.current.handleSubmit();
  };

  const contactEmail = () => {
    window.location = `mailto:yolinda@copysmith.ai?&body=${`Hi Copysmith Team! ${`My account email is ${currentUser.email}. I am currently on the starter plan, and would like to discuss a potential discount.`}`}&subject=${'Discuss Pricing Request'}`;
  };

  const modalTexts = useMemo(() => [
    {
      title: 'Modify subscription',
      text: '',
    },
    ...cancellationBonusEnabled ? [
      {
        title: 'Don\'t go!',
        text: 'Give us another chance to show you what we are capable of! Here are free words on us!',
      },
    ] : [],
    {
      title: 'We\'ve been through so much together',
      text: 'Take a look at what you\'ve accomplished with Copysmith! This could be just the beginning.',
    },
    {
      title: 'Cancel subscription',
      text: 'We’re sorry to see you go, but grateful you gave us a chance! Before you head out, we\'d greatly appreciate your input so we can do better going forward. Thanks for having been a part of the Copysmith family.',
    },
  ], [cancellationBonusEnabled]);

  const tabs = useMemo(() => [
    (
      <div key="tab0">
        <div className={classes.section}>
          <h6 className={classes.subtitle}>Having trouble?</h6>
          <p className={classes.text}>
            Do you just need some help using Copysmith? Our team is ready to
            answer questions and solve problems.
            <br />
            <br />
            Contact support via the chat in the lower right corner of your screen to get help.
          </p>
        </div>
        <div className={classes.section}>
          <h6 className={classes.subtitle}>Try our other solutions</h6>
          <div className={classes.productRow}>
            <img
              alt="Rytr"
              className={classes.brandLogo}
              src={rytrLogo}
            />
            <p className={classes.productRowText}>
              Rytr is great for copywriting and eliminating writer&apos;s block.
              With 40+ use cases and 30+ languages you&apos;ll be able to generate content effortlessly in seconds.
              Use code
              {' '}
              <b>RYTR50OFF</b>
              {' '}
              to get 50% off one month!
            </p>
            <Button className={classes.getStartedButton} onClick={() => window.open('https://rytr.me', '_blank')}>
              Get Started For Free
            </Button>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.productRow}>
            <img
              alt="Describely"
              className={classes.brandLogo}
              src={describelyLogo}
            />
            <p className={classes.productRowText}>
              With Describely, the ability to create high-quality product content is just a few clicks away.
              Write product descriptions, bullet points, titles and tags easily and at scale with SEO tools built in!
              Use code
              {' '}
              <b>DESCRIBELY50</b>
              {' '}
              to get 50% off one month!
            </p>
            <Button className={classes.getStartedButton} onClick={() => window.open('https://describely.ai', '_blank')}>
              Get Started For Free
            </Button>
          </div>
        </div>
      </div>
    ),
    ...cancellationBonusEnabled ? [
      (
        <div key="tab1">
          <p className={classes.text}>
            {modalTexts[tabIndex].text}
          </p>
          <Discount onSuccess={handleDiscounted} />
        </div>
      ),
    ] : [],
    (
      <div key="tab2">
        <p className={classes.text}>
          {modalTexts[tabIndex].text}
        </p>
        <Stats />
      </div>
    ),
    (
      <div key="tab3">
        <p className={clsx(classes.text, classes.greyText)}>
          {modalTexts[tabIndex].text}
        </p>
        <CancelationForm
          formRef={formRef}
          onSuccess={handleCancelled}
        />
      </div>
    ),
  ], [cancellationBonusEnabled, tabIndex]);

  return (
    <>
      <Modal
        open={open}
        title={modalTexts[tabIndex].title}
        classNames={{ paper: classes.modalRoot }}
        onClose={handleClose}
      >
        {tabs[tabIndex]}

        <div className={classes.buttonsBlock}>
          {tabIndex > 0 && (
            <Button
              onClick={setPrevStep}
              variant="text"
            >
              Back
            </Button>
          )}
          {tabIndex === tabs.length - 1 ? (
            <Button
              onClick={cancelSubscription}
              color="secondary"
            >
              Cancel subscription
            </Button>
          ) : (
            <Button
              onClick={setNextStep}
              variant="outlined"
            >
              Continue to cancellation ➝
            </Button>
          )}
        </div>
      </Modal>

      {isCancelled && (
        <ConfirmationModal
          onClose={closeCancelledModal}
          onConfirm={closeCancelledModal}
          confirmButtonText="Close"
          noCancel
          title="Come back soon!"
        >
          Your subscription has been cancelled.
          You can resubscribe at any time through your account or by emailing&nbsp;
          <a href={`mailto:${config.contactSupportEmail}`}>
            {config.contactSupportEmail}
          </a>
        </ConfirmationModal>
      )}

      {isDiscounted && (
        <ConfirmationModal
          title="Hooray!"
          noCancel
          onConfirm={closeDiscounted}
          onClose={closeDiscounted}
        >
          Thanks for giving us another chance!
          We&apos;ve applied your discount and can&apos;t wait to see what you create next.
        </ConfirmationModal>
      )}
    </>
  );
};

export default CancelSubscriptionModal;
