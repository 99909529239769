import React from 'react';

const CheckedIcon = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x=".5"
      y=".5"
      width="15"
      height="15"
      rx="3.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <path d="M4.665 7.587 3.92 6.92l-1.33 1.494.746.665 1.33-1.493Zm1.954 3.08-.665.746a1 1 0 0 0 1.372-.04l-.707-.706Zm5.707-4.293.707-.707-1.414-1.415-.707.708 1.414 1.414ZM3.335 9.08l2.619 2.333 1.33-1.493-2.619-2.333-1.33 1.493Zm3.991 2.294 5-5-1.414-1.414-5 5 1.414 1.414Z" fill="#fff" />
  </svg>
);

export default CheckedIcon;
