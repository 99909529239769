import React from 'react';

import theme from '@root/material.theme';

const ExpanderIcon = () => {
  return (
    <svg
      fill={theme.palette.colors.primary}
      height={20}
      width={20}
      viewBox="4 4 40 40"
    >
      <path d="M8.1 44V41H39.9V44ZM8.1 7V4H39.9V7ZM24.1 39.5 16.5 31.9 18.65 29.75 22.6 33.7V14.3L18.65 18.25L16.5 16.1L24.1 8.5L31.7 16.1L29.55 18.25L25.6 14.3V33.7L29.55 29.75L31.7 31.9Z" />
    </svg>
  );
};
const MoreLikeThisIcon = () => {
  return (
    <svg
      fill={theme.palette.colors.primary}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M14.1 9L15 9.9L5.9 19H5V18.1L14.1 9ZM17.7 3C17.5 3 17.2 3.1 17 3.3L15.2 5.1L18.9 8.9L20.7 7C21.1 6.6 21.1 6 20.7 5.6L18.4 3.3C18.2 3.1 17.9 3 17.7 3ZM14.1 6.2L3 17.2V21H6.8L17.8 9.9L14.1 6.2ZM7 2V5H10V7H7V10H5V7H2V5H5V2H7Z" />
    </svg>
  );
};
const CampaignIcon = () => {
  return (
    <svg
      fill={theme.palette.colors.primary}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 8H4C3.46957 8 2.96086 8.21071 2.58579 8.58579C2.21071 8.96086 2 9.46957 2 10V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H5V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H8C8.26522 21 8.51957 20.8946 8.70711 20.7071C8.89464 20.5196 9 20.2652 9 20V16H12L17 20V4L12 8ZM15 15.6L13 14H4V10H13L15 8.4V15.6ZM21.5 12C21.5 13.71 20.54 15.26 19 16V8C20.53 8.75 21.5 10.3 21.5 12Z" />
    </svg>
  );
};
const PublishIcon = () => {
  return (
    <svg
      fill={theme.palette.colors.primary}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M16 5L14.58 6.42L12.99 4.83V16H11.01V4.83L9.42 6.42L8 5L12 1L16 5ZM20 10V21C20 22.1 19.1 23 18 23H6C4.89 23 4 22.1 4 21V10C4 8.89 4.89 8 6 8H9V10H6V21H18V10H15V8H18C19.1 8 20 8.89 20 10Z" />
    </svg>
  );
};

export {
  ExpanderIcon,
  CampaignIcon,
  MoreLikeThisIcon,
  PublishIcon,
};
