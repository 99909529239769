const isLocalStorageAvailable = () => {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (err) {
    console.warn('Local storage is not supported: ', err); // eslint-disable-line no-console
    return false;
  }
};

const getLocalStorageMock = () => {
  let localStore = {};
  return {
    getItem: (key) => localStore[key],
    removeItem: (key) => {
      delete localStore[key];
    },
    setItem: (key, value) => {
      localStore[key] = `${value}`;
    },
    clear: () => {
      localStore = {};
    },
  };
};

const safeLocalStorage = isLocalStorageAvailable()
  ? window.localStorage
  : getLocalStorageMock();

export default safeLocalStorage;
