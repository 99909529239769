import React from 'react';
import { IconArrowBarToDown, IconExternalLink, IconTrendingUp } from '@tabler/icons-react';

import TruncatedText from '@root/components/TruncatedText';
import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';
import Button from '@root/components/buttons/Button';
import Avatar from '@root/components/Avatar';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { getUserInfoToDisplay } from '@root/utils/formatString.util';

import useStyles from './CommunityTemplateCard.styles';

const CommunityTemplateCard = ({ template }) => {
  const classes = useStyles();

  const { handlePreviewTemplateClick } = useCreateAndExploreContext();

  const wrappedHandlePreviewTemplateClick = (e) => {
    e.stopPropagation();
    handlePreviewTemplateClick(template);
  };

  const displayCreator = getUserInfoToDisplay({
    firstName: template?.user?.firstName,
    surname: template?.user?.surname,
    email: template?.user?.email,
  });

  return (
    <div className={classes.cardContainer} onClick={wrappedHandlePreviewTemplateClick}>
      <header className={classes.cardHeader}>
        <Button
          variant="text"
          className={classes.previewButton}
          classes={{ root: classes.previewButton, label: classes.previewButtonLabel }}
          onClick={wrappedHandlePreviewTemplateClick}
        >
          <IconExternalLink width={16} height={16} />
          Preview
        </Button>
      </header>

      <section className={classes.templateInfo}>
        <TruncatedText
          className={classes.templateName}
        >
          {template?.title}
        </TruncatedText>

        <TruncatedText
          multiline
          maxLines={2}
          className={classes.templateDescription}
        >
          {template?.description}
        </TruncatedText>
      </section>

      <footer className={classes.cardFooter}>
        <section className={classes.createdBySection}>
          <Avatar
            name={displayCreator}
            userId={template?.user?._id}
            className={classes.creatorAvatar}
            oneChar={false}
            popperEnabled={false}
          />

          <div className={classes.creatorNameContainer}>
            <span className={classes.createdByText}>Created by:</span>
            <TruncatedText className={classes.creatorName}>
              {displayCreator}
            </TruncatedText>
          </div>

        </section>

        <section className={classes.counters}>
          <SimpleTooltip content="Total Generations">
            <div className={classes.counterContainer}>
              <IconTrendingUp className={classes.counterIcon} />
              <span>{template?.childDocumentsCount || '-'}</span>
            </div>
          </SimpleTooltip>

          <SimpleTooltip content="Total Downloads">
            <div className={classes.counterContainer}>
              <IconArrowBarToDown className={classes.counterIcon} />
              <span>{template?.downloadsCount || '-'}</span>
            </div>
          </SimpleTooltip>
        </section>
      </footer>
    </div>
  );
};

export default CommunityTemplateCard;
