import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '@root/components/buttons/Button';
import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';
import { communityActions } from '@root/views/CreateAndExplore/context/createAndExploreReducer';
import { tabs } from '@root/views/CreateAndExplore/CreateAndExplore.constants';
import authContext from '@root/resources/auth/auth.context';

import useStyles from './EmptyView.styles';

const EmptyView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser: user } = authContext.useAuth();

  const {
    dispatch,
    state: { activeTab },
  } = useCreateAndExploreContext();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;
  const isCommunityCategory = activeTab === tabs.COMMUNITY;

  const handleCreateCustomTemplateClick = () => {
    history.push('/template-builder');
  };

  const handleSeeCommunityClick = () => {
    dispatch({ type: communityActions.SET_ACTIVE_TAB, payload: tabs.COMMUNITY });
  };

  return (
    <div className={classes.emptyViewContainer}>
      <p className={classes.title}>
        {isCommunityCategory
          ? 'Oops! You’ve angered the content gods.'
          : `Hi ${user?.firstName}, getting started is easy`}
      </p>

      {!isCommunityCategory && (
        <p className={classes.subtitle}>
          Are you ready to write something incredible? With this tool,
          you can create your own content generator within Copysmith.
          Once approved by Copysmith, your entire team will be able
          to use the generator to create amazing content. Let&apos;s get started!
        </p>
      )}

      {!isCommunityCategory && (
        <div className={classes.buttonsContainer}>
          {isAdminOrOwner && (
            <Button onClick={handleCreateCustomTemplateClick}>
              Create custom generator
            </Button>
          )}

          <Button
            onClick={handleSeeCommunityClick}
            variant={!isAdminOrOwner ? 'contained' : 'link'}
          >
            See Community Templates
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyView;
