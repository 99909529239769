import { DateTime } from 'luxon';

import api from '@root/api';
import config from '@root/config';

import safeLocalStorage from './safeLocalStorage.service';

const tokenValue = 'hubspot-client-token-value';
const tokenExpiresOn = 'hubspot-client-token-expiresOn';
const tokenEmail = 'hubspot-client-token-email';

window.hsConversationsSettings = {
  loadImmediately: false,
};

const getToken = async (user) => {
  const tokenData = {
    value: safeLocalStorage.getItem(tokenValue) || '',
    expiresOn: Number.parseInt(safeLocalStorage.getItem(tokenExpiresOn), 10) || 0,
    email: safeLocalStorage.getItem(tokenEmail) || '',
  };

  const tokenExpired = DateTime.now().toSeconds() > tokenData.expiresOn;

  if (tokenExpired && !user) {
    return null;
  }

  if (tokenExpired || (user && tokenData.email !== user.email)) {
    const { token } = await api.account.generateVisitorToken();
    safeLocalStorage.setItem(tokenValue, token.value);
    safeLocalStorage.setItem(tokenExpiresOn, token.expiresOn);
    safeLocalStorage.setItem(tokenEmail, token.email);
    return { value: token.value, email: token.email };
  }

  return { value: tokenData.value, email: tokenData.email };
};

const dropToken = async () => {
  safeLocalStorage.setItem(tokenValue, '');
  safeLocalStorage.setItem(tokenExpiresOn, 0);
  safeLocalStorage.setItem(tokenEmail, '');
};

const loadHubspotScript = () => new Promise((res) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.id = 'hs-script-loader';
  script.src = config.hubspot.widgetSrc;
  document.body.appendChild(script);
  const interval = setInterval(() => {
    if (window.HubSpotConversations?.widget?.load) {
      clearInterval(interval);
      res();
    }
  }, 1000);
});

const DISABLED_URLS = ['/docusign-callback'];

const init = async (user) => {
  if (DISABLED_URLS.includes(window.location.pathname)) {
    return;
  }

  window.hsConversationsSettings = window.hsConversationsSettings || {};

  if (user) {
    window._hsq = window._hsq || [];
    window._hsq.push(['identify', {
      email: user.email,
    }]);
  }

  if (config.hubspot.useTokens) {
    const token = await getToken(user);
    if (token) {
      window.hsConversationsSettings.identificationEmail = token.email;
      window.hsConversationsSettings.identificationToken = token.value;
    }
  }

  await loadHubspotScript();
  window.HubSpotConversations?.widget?.load();
};

const show = () => {
  if (window.HubSpotConversations?.widget?.open) {
    window.HubSpotConversations?.widget?.open();
  }
};

export default {
  init,
  dropToken,
  show,
};
