import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api/newApi';

const createOrUpdateFile = async (data) => {
  const update = {
  };
  if (data.customerId) {
    update.customerId = data.customerId;
    update.customerName = data.customerName;
    update.managerCustomerId = data.managerCustomerId;
  }
  if ('campaignId' in data) {
    update.campaignId = data.campaignId ? data.campaignId.toString() : null;
    update.campaignName = data.campaignName;
  }
  if ('adGroupId' in data) {
    update.adGroupId = data.adGroupId ? data.adGroupId.toString() : null;
    update.adGroupName = data.adGroupName;
  }

  const res = await api.googleAd.createOrUpdateFile(data.fileId, update);
  return res;
};

export default function useCreateOrUpdateFile() {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => createOrUpdateFile(data),
    {
      onSuccess: (data, variables) => {
        const { fileId } = variables;
        queryClient.invalidateQueries(['googleAds.getFile', fileId]);
      },
    },
  );
}
