import * as Yup from 'yup';

import countries from '@root/utils/countries';

export const countryOptions = countries.map(
  (country) => ({ label: country.name, value: country.code }),
);

export const getInitialValues = (user) => ({
  email: user.email || '',
  name: '',
  country: '',
  address: {
    city: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
  },
  save: true,
});

export const optionalAddressCountries = ['US', 'CA', 'GB', 'AU', 'ZA', 'DE', 'IL', 'SE'];

export const schema = Yup.lazy(({ country }) => {
  const isAddressRequired = !optionalAddressCountries.includes(country);
  return Yup.object().shape({
    email: Yup.string()
      .required('Required.')
      .email('Your email is incomplete.'),
    name: Yup.string()
      .required('Required.'),
    country: Yup.string()
      .required('Required.'),
    address: isAddressRequired ? Yup.object().shape({
      postal_code: Yup.string()
        .required('ZIP or Postal Code is required.'),
      line1: Yup.string()
        .required('Address line 1 is required.'),
    }) : undefined,
  });
});
