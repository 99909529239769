import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import AddTitleAtom from './AddTitleAtom';

const useStyles = makeStyles((theme) => ({
  generatorInput: {
    height: '40px',
    borderRadius: theme.border.radius.default,
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    width: '100%',
    border: '0.5px solid #aaaaaa',
    backgroundColor: 'white',

    background: 'white',
    fontStyle: 'normal',
    paddingLeft: '20px',
    '&:focus': {
      outline: 0,
    },
    '&:active': {
      outline: 0,
    },
    '&.touched:invalid': {
      '&:required': {
        border: '1px solid #bc134c',
      },
    },
  },
  helperText: {
    fontSize: '14px',
    color: '#bc134c',
  },
}));

const DEPRECATEDInputAtom = ({
  type,
  minimum,
  maximum,
  propObject,
  title, style,
  enableEnterKey = false,
  required = false,
  className,
  disabled,
}) => {
  const classes = useStyles();
  const {
    name, onChange, onBlur, placeholder, value, readOnly,
  } = propObject;
  const [touched, setTouched] = useState(false);
  const blurHandler = (event) => {
    setTouched(true);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <AddTitleAtom
      required={required}
      title={title}
      style={style}
    >
      <input
        name={name}
        className={clsx(className, classes.generatorInput, {
          touched,
        })}
        type={type || 'text'}
        minLength={minimum}
        maxLength={maximum}
        onChange={onChange}
        onBlur={blurHandler}
        readOnly={readOnly}
        required={required}
        value={value}
        placeholder={placeholder}
        onKeyPress={(event) => {
          const code = event.which || event.charCode || event.keyCode;
          if (!enableEnterKey && code === 13 && type !== 'textarea') {
            event.preventDefault();
          }
          return code !== 13;
        }}
        disabled={disabled}
      />
      {required && touched && (minimum > value?.length) && (
        <span className={classes.helperText}>
          Please enter at least
          {' '}
          {minimum}
          {' '}
          characters.
        </span>
      )}
    </AddTitleAtom>
  );
};

export default DEPRECATEDInputAtom;
