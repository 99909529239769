import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    borderRadius: 4,
    padding: '3px 6px',
    color: '#FFFFFF',
    fontSize: 14,
    lineHeight: '17px',
    minWidth: 24,
    textAlign: 'center',
  },
}));

export default useStyles;
