import React, { useState } from 'react';

import MenuButton from '@root/components/MenuButton';
import useFolder from '@root/resources/folder/useFolder';
import AuthContext from '@root/resources/auth/auth.context';
import fileContext from '@root/resources/file/file.context';

import { campaignAvailableTemplates, freeFormAvailableTemplates } from './createHelpers';
import NewFileModal from './NewFileModal';

const CreateMenuButton = ({ document, onMoreLikeThis }) => {
  const [fileType, setFileType] = useState(null);
  const { currentUser } = AuthContext.useAuth();
  const { localFile: fileData } = fileContext.useFile();

  const isModalOpened = !!fileType;

  const { data: folder } = useFolder(fileData.folderId);
  const folderPermissions = currentUser.permissions.getFolderPermissions(folder);
  const { canCreateFolderFiles } = folderPermissions;

  const templateSupportsMoreLikeThis = !!onMoreLikeThis;
  const templateSupportsFreeForm = freeFormAvailableTemplates.includes(fileData.templateType);
  const userHasFreeFormPermission = currentUser.permissions.freeFormEditor;
  const templateSupportsCampaignBuilder = campaignAvailableTemplates
    .includes(fileData.templateType);

  if (!canCreateFolderFiles || (
    !templateSupportsMoreLikeThis
    && !templateSupportsFreeForm
    && !templateSupportsCampaignBuilder
  )) {
    return null;
  }

  const handleModalClose = () => {
    setFileType(null);
  };

  /**
   * @type {Array<{text: string, tooltip: string?, onClick: function?, disabled: boolean? }>}
   */
  const menuItems = [];

  if (templateSupportsMoreLikeThis) {
    menuItems.push({
      text: 'More like this',
      tooltip: 'Once this output is satisfactory, click this button to generate variants. It\'s free!',
      onClick: onMoreLikeThis,
      key: 'moreLikeThis',
    });
  }
  if (templateSupportsFreeForm) {
    menuItems.push({
      text: 'Long form document',
      onClick: () => {
        setFileType('freeForm');
      },
      disabled: !userHasFreeFormPermission,
      tooltip: !userHasFreeFormPermission && 'This feature is not available on your current subscription.  Upgrade your plan to enable.',
      key: 'freeForm',
    });
  }
  if (templateSupportsCampaignBuilder) {
    menuItems.push({
      text: 'New campaign file',
      onClick: () => {
        setFileType('campaign');
      },
      key: 'campaign',
    });
  }

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <MenuButton
        text="Create"
        menuItems={menuItems}
      />
      {isModalOpened && (
        <NewFileModal
          fileData={fileData}
          document={document}
          onClose={handleModalClose}
          type={fileType}
        />
      )}
    </>
  );
};

export default CreateMenuButton;
