import React from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './StatsCard.styles';

const Stats = ({ children, isLoading, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.cardRoot, className)}>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress size={30} />
        </div>
      ) : children}
    </div>
  );
};

export default Stats;
