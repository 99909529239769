import React from 'react';
import { IconButton } from '@material-ui/core';
import {
  AddCircle as PlusIcon,
  RemoveCircle as MinusIcon,
} from '@material-ui/icons';

import useStyles from './Counter.styles';

const Counter = ({ quantity, onQuantityChange, min, max, tier }) => {
  const classes = useStyles();

  const overageTierPrice = {
    1: {
      wordCount: 3000,
      price: 5,
    },
    2: {
      wordCount: 8000,
      price: 10,
    },
  };

  const total = (overageTierPrice[tier].price * quantity).toFixed(2);

  const isMax = quantity === max;
  const isMin = quantity === min;

  const increaseQuantity = () => {
    onQuantityChange((q) => q + 1);
  };

  const decreaseQuantity = () => {
    onQuantityChange((q) => q - 1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.counterBlock}>
        <div className={classes.counter}>
          <IconButton
            color="primary"
            disabled={isMin}
            onClick={decreaseQuantity}
            className={classes.button}
          >
            <MinusIcon />
          </IconButton>
          <span className={classes.value}>{quantity}</span>
          <IconButton
            color="primary"
            disabled={isMax}
            onClick={increaseQuantity}
            className={classes.button}
          >
            <PlusIcon />
          </IconButton>
        </div>
        <div className={classes.label}>{`${overageTierPrice[tier].wordCount} per Unit`}</div>
      </div>
      <div className={classes.totalBlock}>
        <div>
          <div className={classes.value}>
            {`$${overageTierPrice[tier].price.toFixed(2)}`}
          </div>
          <div className={classes.label}>Unit Price</div>
        </div>
        <div>
          <div className={classes.value}>
            {`$${total}`}
          </div>
          <div className={classes.label}>Set Limit</div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
