import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';

import fileResource from '@root/resources/file';
import Fields from '@root/views/File/components/FileInputFields';
import uiNotificationService from '@root/services/uiNotification.service';
import ProgressButton from '@root/components/ProgressButton';
import PromptSettings from '@root/components/PromptSettings';
import AuthContext from '@root/resources/auth/auth.context';

import templatesConfig from '../../constants/freeFormEditor.templatesConfig';

import useStyles from './FileSettingsDialog.styles';

const FileSettingsDialog = ({
  fileId,
  onClose,
  values,
  onChange,
  templateType,
}) => {
  const { mutateAsync: updateFile, isLoading: isSaving } = fileResource.useUpdateFile();

  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const showCustomPromptSettings = currentUser.featureToggles.customPromptSettings;

  const [settings, setSettings] = useState(values);

  const handleClose = () => {
    setSettings(values);
    onClose();
  };

  const save = async () => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        ...settings,
        engine: settings.aiOptions.engine || settings.engine,
      },
    });
    if (isBadRequest) {
      return;
    }

    onChange((fileFieldsData) => ({
      ...fileFieldsData,
      aiOptions: settings.aiOptions,
    }));

    uiNotificationService.showSuccessMessage('File settings has been updated.', { hideAfter: 10 });
    onClose();
  };

  const fields = [{
    name: 'tone',
    label: 'Tone',
    placeholder: 'Neutral (default)',
    type: 'singleSelect',
    options: [
      { value: 'Neutral', label: 'Neutral' },
      { value: 'Friendly', label: 'Friendly' },
      { value: 'Funny', label: 'Funny' },
      { value: 'Professional', label: 'Professional' },
      { value: 'Adventurous', label: 'Adventurous' },
      { value: 'Luxurious', label: 'Luxurious' },
    ],
    required: false,
  }];

  const onFileFieldsDataChange = ({ name, value }) => {
    setSettings((fileFieldsData) => ({
      ...fileFieldsData,
      [name]: value,
    }));
  };

  const handleAdditionalSettingsChange = ({ name, value }) => {
    setSettings((fileFieldsData) => ({
      ...fileFieldsData,
      aiOptions: {
        ...fileFieldsData.aiOptions,
        [name]: value,
      },
    }));
  };

  const freeFormTemplateConfig = templatesConfig[templateType];

  return (
    <Dialog
      open
      onClose={handleClose}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitleWrap}>
          <div>Edit long form file settings</div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <div className={classes.details}>
          Use the additional options below to change the style, contents,
          and number of content variations in this file.
        </div>
        <div className={classes.input}>
          <Fields
            fields={fields}
            data={settings}
            onDataChange={onFileFieldsDataChange}
            templateType="freeFormEditor"
            showAli={!freeFormTemplateConfig.aliDisabled}
            showLanguage
            isFFSE
            ffseTemplateType={templateType}
          />
        </div>
        {showCustomPromptSettings && (
          <div className={classes.input}>
            <PromptSettings
              templateType={templateType}
              values={settings.aiOptions}
              onChange={handleAdditionalSettingsChange}
              showAli={!freeFormTemplateConfig.aliDisabled}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.cancelButtonWrap}>
        <Button
          variant="text"
          className={classes.cancelButton}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <ProgressButton
          onClick={save}
          disabled={isSaving}
          size="small"
          isLoading={isSaving}
        >
          Save
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default FileSettingsDialog;
