import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
} from '@material-ui/core';

import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';
import useFolders from '@root/resources/folder/useFolders';
import Loading from '@root/components/Loading/Loading';
import { TemplatesDashboardProvider } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import TemplateList from '@root/views/TemplatesDashboard/components/TemplateList';
import Filters from '@root/views/TemplatesDashboard/components/Filters';

import BulkCopyLayout from './components/BulkCopyLayout';
import BulkGenerationReportDialog from './components/BulkGenerationReportDialog';
import ProgressBar from './components/ProgressBar';
import useStyles from './NewBulkGeneration.styles';

const NewBulkGeneration = () => {
  const classes = useStyles();
  const history = useHistory();

  const filterItems = [
    'ecommerce',
    'ads',
    'articles-and-blogs',
    'branding-and-websites',
    'brainstorming',
    'enhancement',
  ];
  const [filterItem, setFilterItem] = React.useState(filterItems[0]);

  const { data: folders = [] } = useFolders({});
  const { state: { jobInProgress } } = BulkGenerationsContext.useBulkGenerations();

  const inProgressGeneration = jobInProgress;

  if (folders.length === 0) {
    return <Loading />;
  }

  return (
    <BulkCopyLayout sidebar={{ type: 'default', page: 'newBulkCopy' }}>
      <TemplatesDashboardProvider
        defaultState={{
          hideFavorite: true,
          isBulk: true,
          customTemplatesFilter: (t) => t.bulkAllowed,
          onCreateClick: (template) => { history.push(`/bulk-copy/create/${template.templateType}`); },
        }}
      >
        <BulkGenerationReportDialog generation={inProgressGeneration} />
        <div className={classes.root}>
          <div>
            <div className={classes.header}>
              <Typography variant="h5" className={classes.title}>
                Bulk Copy
              </Typography>
              {inProgressGeneration && (
                <ProgressBar
                  classes={classes}
                  generation={inProgressGeneration}
                />
              )}
            </div>

            <Typography variant="body2">
              With the click of a button, create thousands of amazing
              product descriptions, online ads, social media content, and more!
            </Typography>
          </div>

          <div className={classes.content}>
            <Filters
              styling="inline"
              items={filterItems}
              getCurrentBucket={() => filterItem}
              setCurrentBucket={(bucket) => setFilterItem(bucket.id)}
            />
            <TemplateList
              hideSearch
              isCardsDisabled={!!inProgressGeneration}
              cardsDisabledTooltipText={inProgressGeneration && 'Cannot create new files until current job is complete.'}
              getCurrentBucket={() => filterItem}
              classNames={{
                list: classes.templateList,
              }}
            />
          </div>
        </div>
      </TemplatesDashboardProvider>
    </BulkCopyLayout>
  );
};

export default NewBulkGeneration;
