import React from 'react';
import {
  Menu, MenuItem, Fade, makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { IconDots } from '@tabler/icons-react';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import {
  BULK_MOVE_FILE_MODAL_ID,
  BULK_DELETE_FILE_MODAL_ID,
} from '@root/views/Dashboard/components/Modals';
import AuthContext from '@root/resources/auth/auth.context';

const useStyles = makeStyles((theme) => ({
  kebab: {
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.colors.darkGrey,
  },
  listVariant: {
    textAlign: 'center',
    height: '2rem',
    lineHeight: '2rem',
    fontSize: '1.5rem',
  },
  isOpen: {
    background: '#B1A7F1',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
  },
  menuIcon: {
    fontSize: '1.2rem',
  },
  paper: {
    maxHeight: '30rem',
  },
  root: {
  },
}));

const BulkFileOptionsKebab = ({
  selectedFileIds,
  variant = 'list',
  folder,
  files,
}) => {
  const { currentUser: user } = AuthContext.useAuth();

  const classes = useStyles();
  const { dispatch } = useGlobalModals();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isOpen = !!anchorEl;

  const bulkActionsDisabled = selectedFileIds.length < 1;

  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: BULK_DELETE_FILE_MODAL_ID,
        modalContent: { selectedFileIds },
      },
    });
  };

  const handleMoveFile = () => {
    handleClose();
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: BULK_MOVE_FILE_MODAL_ID,
        modalContent: { selectedFileIds },
      },
    });
  };

  const allowedActions = React.useMemo(() => {
    const folderRoles = user.roles.getFolderRoles(folder);

    if (folderRoles.viewer) {
      return {
        move: false,
        delete: false,
      };
    }

    const aa = { move: true, delete: true };

    if (folderRoles.owner) {
      return aa;
    }

    aa.move = false;

    const isAllFilesCreatedByUser = selectedFileIds
      .map((sfid) => files.find((f) => f._id === sfid))
      .every((f) => f.userId === user._id);

    if (isAllFilesCreatedByUser) {
      return aa;
    }

    aa.delete = false;

    return aa;
  }, [user, selectedFileIds]);

  return (
    <div
      className={classes.root}
      onClick={stopEventPropagation}
    >
      <div
        className={clsx(
          classes.kebab,
          {
            [classes.listVariant]: variant === 'list',
            [classes.cardVariant]: variant === 'card',
          },
        )}
        onClick={handleClickOpen}
      >
        <IconDots />
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem disabled={bulkActionsDisabled || !allowedActions.move} onClick={handleMoveFile}>
          Move Selected Files
        </MenuItem>
        <MenuItem disabled={bulkActionsDisabled || !allowedActions.delete} onClick={handleDelete}>
          Delete Selected Files
        </MenuItem>
      </Menu>
    </div>

  );
};

export default BulkFileOptionsKebab;
