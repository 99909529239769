import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateOptions extends RequestOptions {
  title: string;
  parentFolderId: string | null;
  externalId?: string;
}

export interface UpdateOptions extends RequestOptions {
  title: string;
  enablePrefill: boolean;
  presetSettings: {
    audience: string;
    company: string;
    companyDescription: string;
    keywords: string[];
    url: string;
  };
  parentFolderId: string | null;
  externalId?: string;
}

export interface ShareOptions extends RequestOptions {
  acl: {
    userId: string;
    role: string;
  }[];
  removedItems: string[],
}

export interface ListOptions extends RequestOptions {
  externalIds?: string[];
}

export class Folder {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async create(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/folders", data, options);

    return result;
  }

  public update(id: string, data: UpdateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/folders/${id}`, data, options);
  }

  public like(id: string, token: string | null = null, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/folders/${id}/like`, { token }, options);
  }

  public share(id: string, data: ShareOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/folders/${id}/share`, data, options);
  }

  public list(data: ListOptions = {}, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const params: Record<string, unknown> = {
      ...data,
    };
    if (data.externalIds) {
      params.externalIds = data.externalIds.join(",");
    }

    return this.client.get("/folders", params, options);
  }

  public getById(id: string, token: string | null = null, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/folders/${id}`, { token }, options);
  }

  public delete(id: string, token: string | null = null, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.del(`/folders/${id}`, { token }, options);
  }
}