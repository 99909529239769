import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    isolation: 'isolate',
  },

  avatar: {
    border: '1px solid #fff',
  },

  lastAvatar: {
    backgroundColor: theme.palette.colors.neutrals50,
    color: theme.palette.colors.neutrals300,
  },
}));

export default useStyles;
