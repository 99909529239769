import { useQuery } from 'react-query';

import api from '@root/api/newApi';

const getAdGroups = async ({ customerId, managerCustomerId, campaignId }) => {
  if (!customerId || !campaignId) {
    return null;
  }
  const adGroups = await api.googleAd.getAdGroups({ customerId, managerCustomerId, campaignId });
  if (adGroups.isBadRequest) {
    return null;
  }
  return adGroups.map((adGroup) => ({ label: adGroup.name, value: adGroup.id }));
};

export default function useAdGroups({
  customerId, managerCustomerId, campaignId,
}, { enabled } = { enabled: true }) {
  return useQuery(['googleAds.getAdGroups', customerId, campaignId],
    () => getAdGroups({ customerId, managerCustomerId, campaignId }), {
      enabled,
    });
}
