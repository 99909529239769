import React from 'react';

import DefaultSidebar from '../DefaultSidebar';
import GoBackSidebar from '../GoBackSidebar';

const Sidebar = ({ type, ...sidebarProps }) => {
  switch (type) {
    case 'default': {
      return <DefaultSidebar {...sidebarProps} />;
    }
    case 'goBack': {
      return <GoBackSidebar {...sidebarProps} />;
    }

    default: throw Error('The sidebar doesn\'t exist');
  }
};

export default Sidebar;
