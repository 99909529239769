import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { maxBy } from 'lodash';

import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';
import useFolders from '@root/resources/folder/useFolders';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import useBulkGeneration from '@root/resources/bulkGenerate/useBulkGeneration';
import Loading from '@root/components/Loading/Loading';
import { SearchField } from '@root/components/form';
import Pagination from '@root/views/Dashboard/components/Pagination';
import api from '@root/api';
import {
  downloadAsFile,
} from '@root/utils/Export';
import Checkbox from '@root/components/Checkbox';

import useStyles from './BulkGenerationFiles.styles';
import BulkGenerationReportDialog from './components/BulkGenerationReportDialog';
import ProgressBar from './components/ProgressBar';
import BulkGenerationRow from './components/BulkGenerationRow';
import BulkCopyLayout from './components/BulkCopyLayout';
import useBulkGenerationRows from './hooks/useBulkGenerationRows';

const BulkGenerationFiles = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: bulkCopyId } = useParams();

  const [page, setCurrentPage] = React.useState(1);
  const [filesPerPage, setFilesPerPage] = React.useState(25);
  const [filesSearchQuery, setFilesSearchQuery] = useState('');

  const { data: { results: teamMembers = [] } = {} } = useTeamMembers();

  const { data: bulkJob = {} } = useBulkGeneration({ id: bulkCopyId });
  const { state } = BulkGenerationsContext.useBulkGenerations();
  const { data: folders = [] } = useFolders();
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [selectedRowIds, setSelectedRowIds] = React.useState([]);

  const { rows, pagesCount, reload: reloadRows } = useBulkGenerationRows({
    bulkGeneration: bulkJob,
    currentJob: state.currentJob,
    filesPerPage,
    filesSearchQuery,
    page,
  });

  const inProgressGeneration = state.currentJob?.bulkGeneration;

  const downloadFilesAsCSV = async (fileIds) => {
    const response = await api.bulkGeneration.getBulkCSV({ id: bulkCopyId, fileIds: fileIds.join(',') });

    if (response.isBadRequest) {
      return;
    }

    downloadAsFile(`${response}`, `${bulkJob?.title}.csv`);
  };

  const toggleRowSelect = (row) => {
    if (isAllSelected) {
      setIsAllSelected(false);
      setSelectedRowIds(rows
        .filter((r) => r.state === 'done')
        .map(({ _id }) => _id)
        .filter((rowId) => rowId !== row._id));
      return;
    }

    if (selectedRowIds.includes(row._id)) {
      setIsAllSelected(false);

      setSelectedRowIds(selectedRowIds.filter((selectedRowId) => selectedRowId !== row._id));
    } else {
      const newSelectedRowIds = selectedRowIds.concat(row._id);
      setSelectedRowIds(newSelectedRowIds);

      if (bulkJob?.result?.totalRows === newSelectedRowIds.length) {
        setIsAllSelected(true);
      }
    }
  };

  const docsNumberToShow = React.useMemo(() => {
    if (!rows.length) {
      return 1;
    }

    const maxDocumentsCountRow = maxBy(rows, ({ result }) => {
      return result?.documents?.length;
    });

    return maxDocumentsCountRow?.result.documents.length || 1;
  }, [rows]);

  const updateSearchQuery = (searchQuery) => {
    setCurrentPage(1);
    setFilesSearchQuery(searchQuery.trim());
  };

  const handleGoBack = () => {
    history.push('/bulk-copy/history');
  };

  const handleSelectAllClick = () => {
    const newIsAllSelected = !isAllSelected;
    setIsAllSelected(newIsAllSelected);
    setSelectedRowIds([]);
  };

  const menu = [{
    title: 'Export to CSV',
    key: 'exportToCSV',
    onClick: async ({ handleClose }) => {
      handleClose();
      downloadFilesAsCSV(
        selectedRowIds.map((id) => rows.find((row) => row._id === id).result.fileId),
      );
    },
  }];

  const onResultUpdated = () => {
    reloadRows();
  };

  if (teamMembers.length === 0 || folders.length === 0) {
    return <Loading />;
  }

  return (
    <BulkCopyLayout
      classNames={{ root: classes.layoutRoot }}
      sidebar={{ type: 'goBack', text: '<- Back to Bulk History', onGoBack: handleGoBack }}
    >
      {inProgressGeneration?._id === bulkJob?._id && (
        <BulkGenerationReportDialog />
      )}
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            {bulkJob?.title}
          </Typography>
          <div className={classes.searchField}>
            <SearchField
              placeholder="Search for a file"
              onChange={updateSearchQuery}
              debounce
            />
          </div>
        </div>
        <div className={classes.tableWrapper}>
          {inProgressGeneration?._id === bulkJob?._id && (
            <ProgressBar
              job={state.currentJob}
              generation={inProgressGeneration}
              classNames={{ progressWrap: classes.progressWrap }}
            />
          )}

          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                  >
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={!isAllSelected && selectedRowIds.length}
                      onClick={handleSelectAllClick}
                      disabled={bulkJob.state === 'failed'}
                    />

                  </TableCell>
                  <TableCell>
                    File Name
                  </TableCell>
                  {new Array(docsNumberToShow)
                    .fill(0)
                    .map((d, index) => {
                      return (
                        <TableCell>
                          Generation
                          {' '}
                          {index + 1}
                        </TableCell>
                      );
                    })}
                  <TableCell padding="none">
                    Status
                  </TableCell>
                  <TableCell>
                    <KebabMenu
                      isDisabled={!isAllSelected && !selectedRowIds.length}
                      menuItems={menu}
                      variant="list"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <BulkGenerationRow
                      key={row._id}
                      row={row}
                      isSelected={isAllSelected || selectedRowIds.includes(row._id)}
                      toggleSelect={toggleRowSelect}
                      handleDownload={downloadFilesAsCSV}
                      docsNumberToShow={docsNumberToShow}
                      templateType={bulkJob.templateType}
                      onResultUpdated={onResultUpdated}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          { rows && (rows.length >= 25 || page !== 1) && (
            <Pagination
              currentPage={page}
              pageCount={pagesCount}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              perPage={filesPerPage}
              onPerPageChange={(newPerPage) => setFilesPerPage(newPerPage)}
            />
          )}
        </div>
      </div>
    </BulkCopyLayout>
  );
};

export default BulkGenerationFiles;
