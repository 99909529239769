import React from 'react';

import travelImg from '@root/assets/engineImages/travel2.png';

export default {
  _id: 'DAVINCI-PLUS',
  title: 'DAVINCI-PLUS',
  description: 'For internal testing only. New OpenAI engine.',
  longerDescription: 'For internal testing only. New OpenAI engine.',
  imageSrc: travelImg,
  bigImg: travelImg,
  sampleGenerations: [
    <div>
      <b>The Most Immersive Experiences in Travel This Year</b>
      <div>
        A new generation of travelers is reconsidering their approach to travel.
        What they want has changed, and they want it differently than their parents did.
        They prize authenticity and access, a sense that they are living life on the edge.
        This means less focus on traditional sites and more exploration of local culture,
        real experiences and something new every day.
      </div>
    </div>,
    'Tastemakers are pushing the envelope, pushing travel boundaries. They seek unique and immersive experiences that provide deeper context for what you see, how you see it, and why you ought to see it. They\'re discerning travelers who are always on the lookout for something new, something different, something that makes a lasting impression. By following the lead of tastemakers and leading tastemakers, tourism can capture the imagination of travelers once more.',
    <div>
      <div>
        <b>Section 1</b>
        : Decide where you want to go
      </div>
      <div>
        <b>Section 2</b>
        : Prepare physically and mentally
      </div>
      <div>
        <b>Section 3</b>
        : Stay safe and don&apos;t get lost
      </div>
      <div>
        <b>Section 4</b>
        : Have an epic experience
      </div>
      <div>
        <b>Takeaway</b>
        : Travel is about the journey, not the destination.
        If you travel with a purpose, you&apos;ll have an amazing time no matter where you go.
        This article details how to prepare your mind for this epic adventure.
      </div>
    </div>,
  ],
};
