import React from 'react';

import Modal from '@root/components/modals/Modal';
import { buckets } from '@root/views/TemplatesDashboardNew/filters.buckets.constant';

import { actions, TemplatesDashboardProviderNew, useTemplatesDashboard } from '../TemplatesDashboard.context';
import TemplateList from '../TemplatesList';
import Modals from '../modals';
import Filters from '../Filters';

import useStyles from './UseCaseModal.styles';

export const USE_CASE_MODAL_ID = 'USE_CASE_MODAL_ID';

const UseCaseModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { dispatch } = useTemplatesDashboard();

  const resetSearch = () => {
    dispatch({
      type: actions.MODAL_SEARCH,
      payload: {
        query: '',
      },
    });
  };

  const handleClose = () => {
    onClose();
    resetSearch();
    dispatch({ type: actions.CHANGE_BUCKET, payload: buckets.all.id });
  };

  return (
    <>
      <Modal
        open={open}
        maxWidth="xl"
        noPadding
        onClose={handleClose}
        classNames={{ paper: classes.modal }}
      >
        <div className={classes.useCaseModalContent}>
          <h3 className={classes.title}>Create eCommerce content like a Pro</h3>
          <Filters />
          <TemplateList />
        </div>
      </Modal>
      <Modals />
    </>
  );
};

const WrappedModal = (props) => (
  <TemplatesDashboardProviderNew
    defaultState={{
      bucket: buckets.all.id,
      isModal: true,
    }}
  >
    <UseCaseModal {...props} />
  </TemplatesDashboardProviderNew>
);

export default WrappedModal;
