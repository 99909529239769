import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import { FormField, RadioButtonField } from '@root/components/form';

import Counter from './components/Counter';
import useStyles from './OverageModal.styles';

const OverageModal = ({ availableUnitsRemaining,
  currentQuantity,
  isApiKeyRequest = false,
  isLoading,
  onClose,
  onSubmit,
  tier }) => {
  const classes = useStyles();
  const unitsUsed = currentQuantity ? currentQuantity - availableUnitsRemaining : 0;
  const minQuantity = unitsUsed > 0 ? unitsUsed : 1;
  const maxQuantity = 10;

  const [quantity, setQuantity] = useState(currentQuantity || minQuantity);

  const options = [
    { value: '1', label: '$5 - 3,000 Words' },
    { value: '2', label: '$10 - 8,000 Words' },
  ];

  if (isApiKeyRequest) {
    options.unshift({ value: '0', label: 'No overage' });
  }

  const handleSubmit = async (values) => {
    await onSubmit({ ...values, maxOverageUnits: quantity });
    onClose();
  };
  return (
    <Formik
      initialValues={{ overageTier: tier || undefined }}
    >
      {({ values }) => {
        return (
          <ConfirmationModal
            title="Overage"
            onConfirm={async () => handleSubmit(values)}
            onClose={onClose}
            isConfirmDisabled={(quantity === currentQuantity && values.overageTier === tier)
              || isLoading
              || !values.overageTier}
          >
            <Form className={classes.form}>
              <div className={classes.overageText}>
                Overage charges provide a suitable solution for users
                experiencing temporary spikes in their volume demands.
                With this option, you will only be billed for the words you actually require,
                and at a rate that is within your control.
                To ensure maximum convenience,
                Copysmith will provide you with a warning once you have reached 80% of your monthly word limit,
                alerting you to the possibility of incurring overage charges.
                <br />
                <br />
                If you have words remaining and opt in to overages you will only be charged
                for the overage units when you run out of your remaining words.
              </div>
              <FormField
                className={classes.radioField}
                name="overageTier"
                component={RadioButtonField}
                options={options}
              />
              {values.overageTier > 0
              && (
                <div className={classes.feature}>
                  <Counter
                    quantity={quantity}
                    onQuantityChange={setQuantity}
                    min={minQuantity}
                    max={maxQuantity}
                    tier={values.overageTier}
                  />
                </div>
              )}
            </Form>
          </ConfirmationModal>
        );
      }}
    </Formik>
  );
};

export default OverageModal;
