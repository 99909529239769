import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  editProfileRoot: {
  },
  title: {
    color: '#000',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: '"Inter", sans-serif',
    marginBottom: '18px',
  },
  warn: {
    color: theme.palette.colors.lightRed,
  },
  nameContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  topSpacing: {
    marginTop: '40px',
  },
  smallTopSpacing: {
    marginTop: '12px',
  },
  resetPasswordButton: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  form: {
    '& > :not(:last-child)': {
      marginBottom: 16,
    },
  },
}));
