import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3, 3, 5),
    height: 'auto',
    maxHeight: '95%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    backgroundColor: theme.palette.colors.white,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  title: {
    marginBottom: 56,
    fontWeight: 700,
    fontSize: 32,
  },
}));

export default useStyles;
