import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  planText: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '26px',
  },

  buyMoreButton: {
    height: 32,
    minWidth: 32,
    padding: 0,
    display: 'grid',
    placeItems: 'center',
  },

  valueWithButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    width: '100%',
  },

  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
  },
}));

export default useStyles;
