import React from 'react';
import clsx from 'clsx';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { MenuOpen } from '@root/components/SVGIcon';
import RootLayout from '@root/views/Layout/RootLayout';
import fileResource from '@root/resources/file';
import documentResource from '@root/resources/document';
import uiNotificationService from '@root/services/uiNotification.service';
import AuthContext from '@root/resources/auth/auth.context';
import InFileSidebar from '@root/views/File/components/InFileSidebar/InFileSidebar';
import folderResource from '@root/resources/folder';
import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import PublishButton from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import safeLocalStorage from '@root/services/safeLocalStorage.service';
import EditorNav from '@root/components/EditorNav/LegacyEditorNav';
import FileToolbar from '@root/components/FileToolbar';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import AssignToSelect from '@root/views/File/components/SelectComponents/AssignToSelect';
import StatusSelect from '@root/views/File/components/SelectComponents/StatusSelect';
import WorkflowQueueContext from '@root/resources/file/workflowQueue.context';

import templatesConfig from './constants/freeFormEditor.templatesConfig';
import useStyles from './DocumentEditor.styles';
import Editor from './components/Editor';
import RightPanel from './components/RightPanel';
import { readOnlyTemplates } from './constants/readOnlyTemplates';

const BACK_URL = '/create';
const TEMPLATE_TYPE = 'freeFormEditor';

// TODO: Rewrite `getInFileNavCollapseState` helper to be able to customize the item of state.
const getInFileRightPanelCollapseState = () => {
  return safeLocalStorage.getItem('inFileRightPanelCollapseState') || 'true';
};

const setInFileRightPanelCollapseState = (state) => {
  return safeLocalStorage.setItem('inFileRightPanelCollapseState', state);
};

const DocumentEditor = () => {
  const location = useLocation();
  const { initialContent, initiallySelectAll } = location.state || {};

  const classes = useStyles();
  const { fileId, id } = useParams();
  const history = useHistory();
  const { currentUser } = AuthContext.useAuth();
  const { data: file } = fileResource.useFile(fileId);
  const { mutateAsync: updateFile } = fileResource.useUpdateFile();
  const valueOnCleanup = React.useRef('');
  const { data: folder } = folderResource.useFolder(file && file.folderId);
  const { mutateAsync: updateDocument } = documentResource.useUpdateDocument();
  const { mutateAsync: createDocument } = documentResource.useCreateDocument();

  const { data: document, refetch: loadDocument } = documentResource
    .useDocument(id, false);

  const {
    data: docsData,
  } = documentResource.useDocuments({ fileId });
  const documents = docsData;

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { hideInfileSidebar = false } = currentExternalSource.toggles;
  const freeFormEditorAvailable = currentUser.permissions.freeFormEditor;
  const filePermissions = currentUser.permissions.getFilePermissions(file, folder);

  const [title, setTitle] = React.useState((file || {}).title);
  const [lastHighlightedText, setLastHighlightedText] = React.useState('');
  const [content, setContent] = React.useState('');

  const [selectedTemplateType, setSelectedTemplateType] = React
    .useState(templatesConfig.freeFormEditor.templateType);

  const [isHighlightTooltipOpen, setHighlightTooltipOpen] = React.useState(false);

  const [newParagraphText, setNewParagraphText] = React.useState('');
  const [replaceSelected, setReplaceSelected] = React.useState(false);

  const {
    assignedToId, setAssignedToId,
    workflowStatus, setWorkflowStatus,
    setWorkflowStatusError,
  } = WorkflowQueueContext.useWorkflowQueue();

  React.useEffect(() => {
    if (!file || file.title === title) {
      return;
    }

    setTitle(file.title);
  }, [file]);

  React.useEffect(() => {
    const isReadonlySelected = readOnlyTemplates.find(({ template }) => {
      return template === selectedTemplateType;
    });
    const tooltipShowTime = 5000;

    if (isReadonlySelected) {
      setHighlightTooltipOpen(true);

      setTimeout(() => {
        setHighlightTooltipOpen(false);
      }, tooltipShowTime);
    } else {
      setHighlightTooltipOpen(false);
    }
  }, [selectedTemplateType]);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const updateFileTitle = async () => {
    if (!title) {
      return;
    }
    await updateFile({ fileId, data: { title } });
  };

  const [RightPanelVisibility, setRightPanelVisibility] = React.useState(
    getInFileRightPanelCollapseState() === 'true',
  );

  const onNewParagraphTextAdded = () => {
    setNewParagraphText('');
  };

  React.useEffect(() => {
    const userId = file?.assignedTo?.userId;

    if (userId) {
      setAssignedToId(userId);
    }
  }, [
    file?.assignedTo?.userId,
  ]);

  React.useEffect(() => {
    if (file?.workflowStatus) {
      setWorkflowStatus(file?.workflowStatus);
    }
  }, [
    file?.workflowStatus,
  ]);

  const create = async () => {
    return createDocument({
      fileId,
      content: initialContent || '',
    });
  };

  // Load first document or create new
  React.useEffect(() => {
    if (!documents) {
      return;
    }
    if (documents.length === 0) {
      create()
        .then((d) => {
          history.replace({
            pathname: `/documents/${fileId}/${d._id}`,
            state: {
              initiallySelectAll: true,
              prevLinkSelected: history.location?.state?.prevLinkSelected,
              prevPathName: history.location?.state?.prevPathName,
            },
          });
        });
    } else {
      const { 0: firstDoc } = documents;
      history.replace({
        pathname: `/documents/${fileId}/${firstDoc._id}`,
        state: {
          prevLinkSelected: history.location?.state?.prevLinkSelected,
          prevPathName: history.location?.state?.prevPathName,
        },
      });
    }
  }, [documents]);

  React.useEffect(() => {
    if (id !== 'first') {
      loadDocument();
    }
  }, [id]);

  const documentId = document ? document._id : null;
  React.useEffect(() => {
    if (documentId) {
      setContent(document.content);
    }
  }, [documentId]);

  useBeforeUnload({
    when: (document && content !== document.content),
    message: 'Are you sure you want to leave?',
  });

  const save = async (data) => {
    const isAssignedToChanged = file?.assignedTo?.userId !== assignedToId;
    const isWorkflowStatusChanged = file?.workflowStatus !== workflowStatus;
    if (!data.isAutoSave && file && (isAssignedToChanged || isWorkflowStatusChanged)) {
      await updateFile({
        fileId,
        data: {
          ...file,
          assignedToId,
          workflowStatus,
        },
      });
    }

    if (document && data.content !== document.content) {
      await updateDocument({
        _id: id,
        data: {
          content: data.content,
        },
      });
    }

    valueOnCleanup.current = '';
    await loadDocument();
  };

  const onSave = (options = {}) => async () => {
    const { isAutoSave, backUrl } = options;

    if (assignedToId && !workflowStatus) {
      setWorkflowStatusError('Select a workflow status for this assigned file.');
      return;
    }

    if (!isAutoSave) {
      history.push(backUrl);
    }

    await save({
      content,
      isAutoSave,
    });

    if (file?.assignedTo?.userId !== assignedToId && !isAutoSave) {
      uiNotificationService.showSuccessMessage('File has been assigned and added to team workflow queue.');
    }
  };

  React.useEffect(() => {
    return function cleanup() {
      const newContent = valueOnCleanup.current;
      if (newContent) {
        save({ content: newContent });
      }
    };
  }, [id]);

  useIdleTimer({
    timeout: 30 * 1000, // set timeout to 30 seconds
    onIdle: onSave({ isAutoSave: true }),
    debounce: 0.5 * 1000, // store content every 0.5 seconds
    onAction: onSave({ isAutoSave: true }),
  });

  const onContentChange = (newContent) => {
    setContent(newContent);
    valueOnCleanup.current = newContent;
  };

  const hasTeamPermissions = currentUser.permissions.teams;

  return (
    <RootLayout>
      <div className={classes.root}>
        <EditorNav
          backUrl={BACK_URL}
          file={file}
          fileTitle={title}
          folder={folder}
          includeBlogCredits={false}
          includeCreditsRight
          isBeta={false}
          onTitleChange={onTitleChange}
          parentFolder={null}
          saveAndExit={onSave}
          updateTitle={updateFileTitle}
          fileTemplateType={TEMPLATE_TYPE}
        />
        <div className={classes.content}>
          <FileToolbar
            file={file}
            filePermissions={filePermissions}
            lastHighlightedText={lastHighlightedText}
          >
            <>
              <div className={classes.workflowQueueWrapper}>
                {currentUser.permissions.workflowQueue && hasTeamPermissions && (
                  <>
                    <AssignToSelect onChange={setAssignedToId} />
                    <StatusSelect onChange={(status) => {
                      setWorkflowStatusError(null);
                      setWorkflowStatus(status);
                    }}
                    />
                  </>

                )}
              </div>
              <PublishButton
                className={classes.publishButton}
                document={document}
                publishLog={document ? document.publishLog : []}
                fileId={fileId}
                templateType={file && file.templateType}
              />
            </>
          </FileToolbar>
          <div className={classes.pageWrap}>
            {!hideInfileSidebar && (
              <InFileSidebar file={file} folder={folder} />
            )}
            <div className={clsx(classes.page, 'copysmith-editor')}>
              <SimpleTooltip
                content="Highlight text and click to generate content."
                placement="top-end"
                open={isHighlightTooltipOpen}
                dark
                wrapped
              >
                <Editor
                  disabled={!freeFormEditorAvailable || !filePermissions.canEditFile}
                  initialContent={content}
                  onTextSelected={(text) => setLastHighlightedText(text)}
                  onCancelSelection={() => setLastHighlightedText('')}
                  onChange={onContentChange}
                  newParagraphText={newParagraphText}
                  onNewParagraphTextAdded={onNewParagraphTextAdded}
                  replaceSelected={replaceSelected}
                  initiallySelectAll={initiallySelectAll}
                />
              </SimpleTooltip>

            </div>
            <div className={classes.pageRight}>
              <div className={classes.rightToolbar}>
                <MenuOpen
                  onClick={() => {
                    setRightPanelVisibility(true);
                    setInFileRightPanelCollapseState('true');
                  }}
                  className={classes.rightPanelToggle}
                />
              </div>
              {RightPanelVisibility && (
                <div className={classes.rightPanel}>
                  <RightPanel
                    fileId={fileId}
                    document={document}
                    onTemplateSelected={(t) => setSelectedTemplateType(t)}
                    selectedTemplateType={selectedTemplateType}
                    disabled={!freeFormEditorAvailable}
                    limitedAccess={!filePermissions.canEditFile}
                    onClose={() => {
                      setRightPanelVisibility(false);
                      setInFileRightPanelCollapseState('false');
                    }}
                    highlightedText={lastHighlightedText}
                    onInsert={(newText, shouldWeReplaceSelected) => {
                      setNewParagraphText(newText);
                      setReplaceSelected(shouldWeReplaceSelected);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

const WrappedDocumentEditor = (props) => {
  const { fileId } = useParams();
  const { data: file } = fileResource.useFile(fileId);

  return (
    <WorkflowQueueContext.WorkflowQueueProvider file={file}>
      <DocumentEditor {...props} />
    </WorkflowQueueContext.WorkflowQueueProvider>
  );
};

export default WrappedDocumentEditor;
