import { makeStyles, lighten } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  planDetailsRoot: {
    width: '100%',
  },
  planInfo: {
    color: theme.palette.colors.primary,
    backgroundColor: lighten(theme.palette.colors.primary, 0.95),
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.primary}`,
    padding: '8px 16px',
  },
  title: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '24px',
    marginBottom: 32,
  },
  planName: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '39px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 4,
    marginBottom: 8,
    borderBottom: `1px solid ${theme.palette.colors.primaryFaded}`,
  },
  planItemList: {
    width: '100%',
  },
  planItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 12,
    lineHeight: '200%',
    '& > :last-child': {
      fontWeight: 500,
    },
  },
  referralAward: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  referralAwardDescription: {
    fontSize: 12,
    color: theme.palette.colors.grey,
  },
  referralAwardDiscount: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.colors.grey,
  },
  subtotal: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0 16px',
    fontSize: 16,
    '& > :last-child': {
      fontWeight: 600,
    },
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 20,
    marginTop: 32,
    paddingTop: 16,
    borderTop: `1px solid ${theme.palette.colors.grey}`,
    '& > :last-child': {
      fontWeight: 600,
    },
  },
}));

export default useStyles;
