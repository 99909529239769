import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';

import config from '@root/config';

import routes from '../utils/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '5rem 10%',
    backgroundColor: 'black',
  },
  columnTitle: {
    color: theme.palette.colors.white,
    fontWeight: 700,
    fontSize: '16px',
    margin: 0,
  },
  copyrightNotice: {
    fontFamily: "'Inter', sans-serif",
    color: theme.palette.colors.white,
    opacity: 0.5,
    marginRight: '24px',
    width: '100%',
    textAlign: 'center',
    marginTop: '75px',
  },
  footerLink: {
    fontFamily: "'Inter', sans-serif",
    opacity: 0.7,
    marginRight: '24px',
    textDecoration: 'none',
    transition: 'all 250ms',
    marginTop: '12px',
    color: theme.palette.colors.white,
    '&:hover': {
      color: theme.palette.colors.white,
      textDecoration: 'none',
      opacity: 1,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <footer>
        <Grid container spacing={4}>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={3}
            sm={6}
          >
            <h3 className={classes.columnTitle}>
              Explore
            </h3>
            {routes.map((route) => {
              if (route.linkObj) {
                return (
                  route.linkObj.map((link) => (
                    <a key={link.name} href={link.url} className={classes.footerLink}>
                      {link.name}
                    </a>
                  ))
                );
              }
              return (
                <a key={route.name} href={route.to} className={classes.footerLink}>
                  {route.name}
                </a>
              );
            })}
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={3}
            sm={6}
          >
            <h3 className={classes.columnTitle}>
              Terms
              {' '}
            </h3>
            <Link className={classes.footerLink} to="/privacy">
              Privacy Policy
            </Link>
            <Link className={classes.footerLink} to="/terms-of-service">
              Terms of Use
            </Link>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={3}
            sm={6}
          >
            <h3 className={classes.columnTitle}>
              Company
            </h3>
            <a
              className={classes.footerLink}
              rel="noreferrer"
              href="https://copysmith.bamboohr.com/jobs/"
              target="_blank"
            >
              Careers
            </a>
            <a
              className={classes.footerLink}
              rel="noreferrer"
              href="https://copysmith.upvoty.com/b/feature-requests/"
              target="_blank"
            >
              Feature Request
            </a>
            <a
              className={classes.footerLink}
              rel="noreferrer"
              href="https://form.typeform.com/to/y9HSF4My"
              target="_blank"
            >
              Join Affiliate Program
            </a>
            <a
              className={classes.footerLink}
              rel="noreferrer"
              href="https://form.typeform.com/to/FR5jtNWI"
              target="_blank"
            >
              Request API Access
            </a>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={3}
            sm={6}
          >
            <h3 className={classes.columnTitle}>
              Get in Touch
            </h3>
            <div className="d-flex mt-3">
              <a
                className={classes.footerLink}
                rel="noreferrer"
                href="https://www.facebook.com/copysmithai"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faFacebook} />
              </a>
              <a
                className={classes.footerLink}
                rel="noreferrer"
                href="https://www.linkedin.com/company/copysmith/"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faLinkedin} />
              </a>
              <a
                className={classes.footerLink}
                rel="noreferrer"
                href="https://twitter.com/copysmith_ai"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faTwitter} />
              </a>
              <a
                className={classes.footerLink}
                rel="noreferrer"
                href="https://www.instagram.com/copysmithai/"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faInstagram} />
              </a>
            </div>
            <a
              className={classes.footerLink}
              rel="noreferrer"
              href={`mailto:${config.contactSupportEmail}`}
            >
              {config.contactSupportEmail}
            </a>
          </Grid>
        </Grid>
        <p className={classes.copyrightNotice}>
          &copy;
          {' '}
          {new Date().getFullYear()}
          , Copysmith
        </p>
      </footer>
    </div>
  );
};
export default Footer;
