import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import TemplatesContext from '@root/resources/templates/templates.context';
import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import WizardLayout from '@root/views/Layout/WizardLayout';
import useGenerateBulk from '@root/resources/bulkGenerate/useGenerateBulk';
import AuthContext from '@root/resources/auth/auth.context';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';
import useStyles from '@root/views/BlogKickstarter/BlogKickstarter.styles';
import newMaterialTheme from '@root/newMaterial.theme';

import useBulkWizardStyles from './BulkGenerationWizard.styles';
import PromptSettingsForm from './components/PromptSettingsForm';
import GeneralInfoForm from './components/GeneralInfoForm';
import UploadForm from './components/UploadForm';
import FieldMappingForm from './components/FieldMappingForm';

const steps = ['General', 'Settings', 'Upload', 'Field mapping'];
const previousTexts = ['Back to templates', 'Back to description', 'Back to settings', 'Back to upload'];
const promptMessage = 'Are you sure you want to leave? All progress will be lost';

const BulkGenerationWizard = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { templateType } = useParams();
  const { getTemplate } = TemplatesContext.useTemplates();
  const templateConfig = getTemplate(templateType) || {};
  const { mutateAsync: generateBulk } = useGenerateBulk();
  const { currentCompany } = AuthContext.useAuth();
  const { dispatch } = BulkGenerationsContext.useBulkGenerations();

  const [step, setStep] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const defaultLanguage = currentCompany.language || 'en';
  const [lang, setLang] = useState(defaultLanguage);
  const [outputLang, setOutputLang] = useState(defaultLanguage);
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayedFileMeta, setDisplayedFileMeta] = useState({});

  const [aiOptions, setAiOptions] = useState({
    ...templateConfig.aiOptions,
    temperature: 0.5,
    n: templateConfig.bulkCustomOptions?.defaultN || 1,
  });

  const [generalInfo, setGeneralInfo] = useState({});

  const classes = useStyles({ step });
  const bulkWizardStyles = useBulkWizardStyles({ step });

  const handleGeneralInfoSubmit = async (values) => {
    setShowPrompt(true);
    setGeneralInfo(values);
    setStep(1);
    dispatch({
      type: BulkGenerationsContext.actions.TOGGLE_AUTODOWNLOAD,
      payload: {
        isAutodownloadEnabled: values.autoDownloadCSV,
      },
    });
  };

  useBeforeUnload({
    when: showPrompt,
    message: promptMessage,
  });

  const previousText = previousTexts[step];
  const handleStepChange = () => {
    if (!step) {
      history.push('/bulk-copy/new');
    }
    setStep((currentStep) => currentStep - 1);
  };

  const handleUploadSubmit = () => {
    setStep(3);
  };

  const handleFieldMappingSubmit = async (fileMapping) => {
    const resp = await generateBulk({
      title: generalInfo.name,
      fileId: selectedFile.fileId,
      folderId: generalInfo.folderId || generalInfo.projectId,
      lang,
      outputLang,
      aiOptions,
      fileMapping,
    });
    setShowPrompt(false);

    if (resp.isBadRequest) {
      return;
    }

    history.push(`/bulk-copy/${resp._id}`);
  };

  const handlePromptSettingsSubmit = ({
    aiOptions: newAiOptions,
    lang: newLang,
    ouputLang: newOutputLang,
  }) => {
    setAiOptions(newAiOptions);
    setLang(newLang);
    setOutputLang(newOutputLang);
    setStep(2);
  };

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <WizardLayout
        steps={steps}
        step={step}
        onStepChange={handleStepChange}
        prevAvailable
        previousText={previousText}
        classes={{ main: classes.main }}
      >
        <div className={bulkWizardStyles.bulkWizardContainer}>
          {step === 0 && (
            <GeneralInfoForm
              onSubmit={handleGeneralInfoSubmit}
              formRef={formRef}
              values={generalInfo}
              title={`Bulk ${templateConfig.title}`}
            />
          )}

          {step === 1 && (
            <PromptSettingsForm
              templateConfig={templateConfig}
              aiOptions={aiOptions}
              lang={lang}
              outputLang={outputLang}
              onSubmit={handlePromptSettingsSubmit}
            />
          )}

          {step === 2 && (
            <UploadForm
              template={templateConfig}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              displayedFileMeta={displayedFileMeta}
              setDisplayedFileMeta={setDisplayedFileMeta}
              onSubmit={handleUploadSubmit}
            />
          )}

          {step === 3 && (
            <FieldMappingForm
              aiOptions={aiOptions}
              template={templateConfig}
              selectedFile={selectedFile}
              onSubmit={handleFieldMappingSubmit}
            />
          )}
        </div>
      </WizardLayout>
    </ThemeProvider>
  );
};

export default BulkGenerationWizard;
