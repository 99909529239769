import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 136,
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
  },
  inputField: {
    width: 300,
    border: `1px solid ${theme.palette.colors.grey}`,
    borderRadius: 4,
    height: 36,
    marginBottom: 16,
  },
  form: {
    '& > :not(:last-child)': {
      marginBottom: 24,
    },
  },
  backToLogin: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
