import React, { useState } from 'react';
import { useField } from 'formik';
import { IconArrowBack, IconZoomPan } from '@tabler/icons-react';
import { CircularProgress, FormHelperText, IconButton } from '@material-ui/core';

import { InputField } from '@root/components/form';
import TooltipIcon from '@root/components/tooltips/TooltipIcon';
import useFraseKeywords from '@root/components/integrations/Frase/resources/useFraseKeywords';
import { colors } from '@root/material.theme';

import CollapsibleSection from '../CollapsibleSection';

import useStyles from './SEOKeywords.styles';
import Keyword from './Keyword';

const colorArray = [
  { fill: colors.purple400, text: colors.purple400 },
  { fill: colors.yellow400, text: colors.yellow600 },
  { fill: colors.blue400, text: colors.blue400 },
  { fill: colors.orange400, text: colors.orange400 },
  { fill: colors.green400, text: colors.green400 },
  { fill: colors.pink400, text: colors.pink400 },
];

const SEOKeywords = ({ disabled }) => {
  const classes = useStyles();
  const { isLoading, mutateAsync } = useFraseKeywords();
  const [{ value: selectedKeywords }, , { setValue }] = useField('seoKeywords');

  const [query, setQuery] = useState('');
  const [keywords, setKeywords] = useState(null);
  const [colorIndex, setColorIndex] = useState(0);

  const handleKeywordClick = (keyword) => {
    const selected = selectedKeywords.some((el) => el.text === keyword.text);

    const newSelectedKeywords = selected
      ? selectedKeywords.filter((el) => el.text !== keyword.text)
      : [...selectedKeywords, keyword];

    setValue(newSelectedKeywords);
  };

  const generateKeywords = async () => {
    const content = query.trim();

    const result = await mutateAsync({ content });
    const { isBadRequest } = result;
    if (!isBadRequest) {
      setKeywords(
        [
          ...selectedKeywords,
          ...result.map(({ entity, frequency }) => (
            { text: entity, score: frequency, color: colorArray[colorIndex] }
          )),
        ],
      );
    }

    setColorIndex((i) => (i < 5 ? i + 1 : 0));
  };

  const endIcon = (
    isLoading
      ? <CircularProgress size={18} color="inherit" />
      : (
        <IconButton
          size="small"
          onClick={generateKeywords}
          color="default"
          disabled={query.length === 0}
          className={classes.inputButton}
        >
          <IconArrowBack />
        </IconButton>
      )
  );

  return (
    <CollapsibleSection
      Icon={IconZoomPan}
      buttonText="SEO"
      id="button-seoWords"
    >
      <div className={classes.content}>
        <InputField
          label="Type your search query"
          placeholder="kids bikes"
          disabled={disabled || isLoading}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              generateKeywords();
            }
          }}
          endAdornment={endIcon}
          id="input-query"
        />
        <FormHelperText>
          Run your query and select SEO optimized words
          <TooltipIcon
            className={classes.tooltipIcon}
            content="Numbers indicate a keyword’s frequency score when searched on Google"
          />
        </FormHelperText>
        {keywords && (
          <div className={classes.wordsWrapper}>
            {keywords.map((keyword) => {
              const selected = selectedKeywords.some((el) => el.text === keyword.text);
              return (
                <Keyword
                  key={keyword.text}
                  onClick={handleKeywordClick}
                  keyword={keyword}
                  disabled={disabled}
                  selected={selected}
                />
              );
            })}
          </div>
        )}
      </div>
    </CollapsibleSection>
  );
};

export default SEOKeywords;
