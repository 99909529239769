import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import loading from '../assets/loading.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    background: 'none',
  },
  spinnerWrapper: {
    color: theme.palette.colors.darkGrey,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
  },
  loadingText: {
    marginLeft: 15,
    marginTop: 10,
    fontFamily: "'Inter', sans-serif",
  },
}));

const TransparentLoading = () => {
  const [dotNum, setDotNum] = useState(0);
  const classes = useStyles();
  useEffect(() => {
    const interval = setInterval(() => {
      setDotNum((s) => (s + 1) % 4);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={classes.root}>
      <div className={clsx('spinner', classes.spinnerWrapper)}>
        <div>
          <img src={loading} width="30px" alt="Loading" />
        </div>
        <div className={classes.loadingText}>
          Loading ...
          {Array(dotNum).fill('.').join('')}
        </div>
      </div>
    </div>
  );
};

export default TransparentLoading;
