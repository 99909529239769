import React, { useState } from 'react';
import clsx from 'clsx';
import { ArrowDropDown } from '@material-ui/icons';

import useStyles from './CollapsibleSubtable.styles';

const CollapsibleSubtable = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = useStyles();
  return (
    <div className={clsx(classes.collapsibleWrapper, {
      [classes.collapsed]: isCollapsed,
    })}
    >
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={classes.collapsibleIcon}
      >
        <ArrowDropDown className={clsx({
          [classes.rotated]: isCollapsed,
        })}
        />
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleSubtable;
