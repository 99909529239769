import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  productsList: {
    height: 352,
    overflowY: 'auto',
    marginTop: 16,
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  product: {
    display: 'flex',
    backgroundColor: theme.palette.colors.neutrals50,
    height: 64,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px 8px 8px',
    borderRadius: theme.shape.borderRadius,

    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  productKey: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    lineHeight: '18px',
  },
  productValue: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    lineHeight: '18px',
  },
  productPrice: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.colors.neutrals400,
    backgroundColor: theme.palette.colors.neutrals100,
  },
  customElementWrap: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
