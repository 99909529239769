import React from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';

import bigCommerceService from '@root/services/bigCommerce.service';

import useStyles from './AuthLayout.styles';

const AuthLayout = ({ children, checkBigCommerceUrl = true }) => {
  const classes = useStyles();

  if (checkBigCommerceUrl && bigCommerceService.isInvalidUrl()) {
    return <Redirect to="/bigcommerce-incognito-warn" />;
  }

  return (
    <div className={clsx(classes.authRoot)}>
      <div className={classes.main}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
