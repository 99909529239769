import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import PaymentWidget from '@root/views/Profile/Billing/components/Sections/AccountSection/PaymentWidget';

import useSectionsStyles from '../Sections.styles';

import PlanWidget from './PlanWidget';
import useStyles from './AccountSection.styles';

const AccountSection = ({
  plan,
  billingData = {},
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const history = useHistory();

  const handleUpgradePlanClick = () => {
    history.push('/profile/plans');
  };

  return (
    <section className={sectionClasses.sectionContainer}>
      <div className={sectionClasses.sectionTitleContainer}>
        <Typography variant="h4" className={sectionClasses.sectionTitle}>
          Account plan & billing
        </Typography>
        <Typography variant="body1" className={sectionClasses.sectionSubtitle}>
          Here you can review your plan and billing details. To find more about different plans go
          to
          {' '}
          <span onClick={handleUpgradePlanClick} className={classes.plansLink}>Plan page</span>
        </Typography>
      </div>

      <PlanWidget
        plan={plan}
        billingData={billingData}
      />

      <PaymentWidget
        billingData={billingData}
      />
    </section>
  );
};

export default AccountSection;
