import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconContainer: {
    height: 24,
    width: 24,
    borderRadius: 12,
    display: 'grid',
    placeItems: 'center',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export default useStyles;
