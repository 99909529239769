import * as Yup from 'yup';

import { namePattern, onlyOneSpacePattern } from './formRegexPatterns';

// eslint-disable-next-line import/prefer-default-export
export const validations = {
  email: Yup.string()
    .required('Email is required')
    .email('Invalid Email'),
  name: Yup.string()
    .min(2, 'First name is too short')
    .max(255, 'First name is too long')
    .matches(namePattern, 'Are you sure you entered your first name correctly?')
    .matches(onlyOneSpacePattern, 'Are you sure you entered your first name correctly?')
    .required('First name is required'),
  surname: Yup.string()
    .min(2, 'Last name is too short')
    .max(255, 'Last name is too long')
    .matches(namePattern, 'Are you sure you entered your last name correctly?')
    .matches(onlyOneSpacePattern, 'Are you sure you entered your last name correctly?')
    .required('Last name is required'),
};
