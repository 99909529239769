import React from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useExportToShopify from '@root/resources/integrations/shopify-integration/useExportToShopify';
import ShopifySuccessModal from '@root/components/integrations/Shopify/ShopifySuccessModal';
import Tooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import useProduct from '@root/resources/integrations/shopify-integration/useProduct';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import useModalState from '@root/utils/hooks/useModalState';

import useStyles from './ShopifyExportButton.styles';

const ShopifyExportButton = ({ document }) => {
  const { mutateAsync: exportToShopify } = useExportToShopify();
  const classes = useStyles();
  const params = useParams();
  const { data } = useProduct(params.id);

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { showExternalSourceTooltip, disableShopify } = currentExternalSource.toggles;

  const [showSuccessModal, openSuccessModal, closeSuccessModal] = useModalState(false);

  if (!data?.product) {
    return null;
  }

  const handleClick = async () => {
    await exportToShopify({
      documentId: params.id,
      description: document.content,
    });
    openSuccessModal();
  };

  return (
    <>
      <Tooltip
        content={showExternalSourceTooltip && <ExternalSourceText />}
        interactive
        wrapped
      >
        <Button
          variant="outlined"
          type="button"
          onClick={handleClick}
          className={classes.root}
          disabled={disableShopify}
        >
          Export to Shopify
        </Button>
      </Tooltip>

      <ShopifySuccessModal
        modalOpen={showSuccessModal}
        image={data?.product?.image}
        productName={data?.product?.title}
        exit={closeSuccessModal}
      />
    </>
  );
};

export default ShopifyExportButton;
