import { DateTime } from 'luxon';

import getCurrentExternalSource from '../resources/externalSources';

import safeLocalStorage from './safeLocalStorage.service';

const defaultTokenValue = 'copysmith-client-token-value';
const defaultTokenExpiresOn = 'copysmith-client-token-expiresOn';

const getTokenKeys = async () => {
  const currentExternalSource = await getCurrentExternalSource();
  const tokenValue = currentExternalSource.jwtToken.value || defaultTokenValue;
  const tokenExpiresOn = currentExternalSource.jwtToken.expiresOn || defaultTokenExpiresOn;

  return {
    tokenValue,
    tokenExpiresOn,
  };
};

const getTokenData = async () => {
  const {
    tokenValue,
    tokenExpiresOn,
  } = await getTokenKeys();

  const localTokenData = {
    value: safeLocalStorage.getItem(tokenValue) || '',
    expiresOn: Number.parseInt(safeLocalStorage.getItem(tokenExpiresOn), 10) || 0,
  };

  return { value: localTokenData.value, expiresOn: localTokenData.expiresOn };
};

const isLoggedIn = async () => {
  const tokenData = await getTokenData();
  return DateTime.now().toSeconds() < tokenData.expiresOn;
};

const saveJwt = async (token) => {
  const {
    tokenValue,
    tokenExpiresOn,
  } = await getTokenKeys();

  safeLocalStorage.setItem(tokenValue, token.value);
  safeLocalStorage.setItem(tokenExpiresOn, token.expiresOn);
};

const dropJwt = async () => {
  const {
    tokenValue,
    tokenExpiresOn,
  } = await getTokenKeys();

  safeLocalStorage.setItem(tokenValue, '');
  safeLocalStorage.setItem(tokenExpiresOn, 0);
};

const getJwtValue = async () => {
  const tokenData = await getTokenData();

  return tokenData.value;
};

const jwtService = {
  isLoggedIn, saveJwt, getJwtValue, dropJwt,
};

export default jwtService;
