import React from 'react';
import { useFormikContext } from 'formik';
import { FormHelperText, OutlinedInput } from '@material-ui/core';
import { pick, keys } from 'lodash';

import { FieldLabel, FormField } from '@root/components/form';

import { optionalAddressCountries } from '../../cardFormHelpers';

import useStyles from './BillingAddressForm.styles';

const BillingAddressForm = () => {
  const classes = useStyles();
  const { values, errors, touched } = useFormikContext();
  const { country } = values;
  const isAddressRequired = !optionalAddressCountries.includes(country);

  const errorFields = pick(errors?.address, keys(touched?.address));
  const errorsList = Object.values(errorFields);

  return (
    <div>
      <FieldLabel
        label={isAddressRequired ? 'Billing address' : 'Billing address (optional)'}
        required={isAddressRequired}
      />
      <div>
        <FormField
          name="address.line1"
          placeholder={isAddressRequired ? 'Address line 1 *' : 'Address line 1'}
          component={OutlinedInput}
          required
          fullWidth
          className={classes.topField}
        />
        <FormField
          name="address.line2"
          placeholder="Address line 2"
          component={OutlinedInput}
          required
          fullWidth
          className={classes.midField}
        />
        <div className={classes.rowWrapper}>
          <FormField
            name="address.city"
            placeholder="City"
            component={OutlinedInput}
            required
            fullWidth
            className={classes.midField}
          />
          <FormField
            name="address.postal_code"
            placeholder={isAddressRequired ? 'ZIP or Postal Code *' : 'ZIP or Postal Code'}
            component={OutlinedInput}
            required
            fullWidth
            className={classes.midField}
          />
        </div>
        <FormField
          name="address.state"
          placeholder="State"
          component={OutlinedInput}
          required
          fullWidth
          className={classes.bottomField}
        />
      </div>
      {errorsList.map((error) => (
        <FormHelperText error>{error}</FormHelperText>
      ))}
    </div>
  );
};

export default BillingAddressForm;
