import config from '@config';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';

const service = window.fbq;
const enabled = config.env === 'production' && service;

// Event Aggregator Subscriptions
eventsAggregator.subscribe(EVENT_NAMES.PURCHASE_COMPLETED, (_, data) => {
  if (!enabled) {
    return;
  }

  const { total } = data;
  service('track', 'Purchase', { currency: 'USD', value: total });
});
