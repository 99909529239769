import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as UiLink } from '@material-ui/core';
import {
  InsertDriveFile,
  Error,
  Warning,
  CheckCircle,
  Visibility,
} from '@material-ui/icons';
import clsx from 'clsx';

import Delete from '@root/components/SVGIcon/Delete';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import FeatureModal from '@root/components/modals/FeatureModal';
import { FEATURES } from '@root/views/Addons/addonsConstants';
import useModalState from '@root/utils/hooks/useModalState';
import bulkCopyUtils from '@root/views/BulkCopy/bulkCopy.utils';
import Button from '@root/components/buttons/Button';
import billingResource from '@root/resources/billing';
import useUploadFile from '@root/resources/bulkGenerate/useUploadFile';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';

import FileUploader from '../FileUploader';
import PreviewModal from '../PreviewModal';

import useStyles from './UploadForm.styles';

const PRO_PLAN_ROWS = 100;

const UploadForm = ({
  onSubmit,
  template,
  selectedFile,
  setSelectedFile,
  displayedFileMeta,
  setDisplayedFileMeta,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const { mutateAsync: uploadFile } = useUploadFile();
  const {
    data: {
      bulkGenerationRows,
      maxBulkGenerationRows,
      limit,
    } = {},
  } = billingResource.useLimits();
  const { data: billingInfo = {} } = billingResource.useBillingInfo();

  const {
    state: { currentJobProcessing },
  } = BulkGenerationsContext.useBulkGenerations();
  const [
    isAddCreditsOpen,
    openAddCredits,
    closeAddCredits,
  ] = useModalState(false);

  const [
    isPreviewOpen,
    openPreviewModal,
    closePreviewModal,
  ] = useModalState(false);

  const dropSelectedFile = () => {
    setErrors([]);
    setDisplayedFileMeta({});
    setSelectedFile(null);
  };

  const onFileSelect = async (file) => {
    setDisplayedFileMeta({ name: file.name, size: file.size });
    setErrors([]);
    const response = await uploadFile({
      file,
      templateType: template.templateType,
    });
    if (response.isBadRequest) {
      const responseErrors = response.errors?.filter((e) => e.file) || [];

      if (responseErrors.length > 0) {
        setErrors(responseErrors);
        return;
      }

      setDisplayedFileMeta({});
      return;
    }
    setSelectedFile(response);
  };

  const downloadSample = async () => {
    await bulkCopyUtils.downloadSampleFile({
      templateName: template.title,
      templateType: template.templateType,
    });
  };

  const cheapPlanIds = [
    ...PLANS_IDS.starters,
    ...PLANS_IDS.legacyStarters,
    ...PLANS_IDS.legacyBasics,
    ...PLANS_IDS.legacyGrowths,
  ];

  const { priceId } = billingInfo;

  const showMaxRowsWarning = selectedFile && selectedFile.overLimitRows > 0;
  const isOnCheapPlan = cheapPlanIds.includes(priceId);

  const showMaxRowsWarningCheap = selectedFile && selectedFile.overLimitRows > 0 && isOnCheapPlan;
  const showMaxRowsWarningNotCheap = selectedFile
    && selectedFile.overLimitRows > 0 && !isOnCheapPlan;

  const maxRowsWarningContentCheap = showMaxRowsWarningCheap && (
    <span className={classes.maxRowsWarningContentCheap}>
      Uploaded file has
      {' '}
      <b>{selectedFile.overLimitRows}</b>
      {' '}
      rows over the maximum allowed,
      which will be skipped.
      Please upload a new file or continue to process the first&nbsp;
      <b>{maxBulkGenerationRows}</b>
      &nbsp;items in the current file.
      You can also upgrade to the Professional plan to process&nbsp;
      <b>{PRO_PLAN_ROWS}</b>
      &nbsp;items in each file -&nbsp;
      <Link
        to="/profile/plans"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.learnMoreLink}
      >
        click here to learn more
      </Link>
      .
    </span>
  );

  const maxRowsWarningContentNotCheap = showMaxRowsWarningNotCheap
    && `File has ${selectedFile.overLimitRows} rows over the maximum amount that will be skipped`;

  const maxRowsWarningContent = maxRowsWarningContentCheap || maxRowsWarningContentNotCheap;

  const starterPlanIds = [
    ...PLANS_IDS.starters,
    ...PLANS_IDS.legacyStarters,
  ];
  const proOrLtdPlanIds = [
    ...PLANS_IDS.pros,
    ...PLANS_IDS.legacyPros,
    ...PLANS_IDS.ltds,
  ];
  const newProOrLtdPlanIds = [
    ...PLANS_IDS.pros,
    ...PLANS_IDS.ltds,
  ];
  const addonQuantity = billingInfo.addons?.[FEATURES.additionalCredits.type];

  const isTrial = PLANS_IDS.free.includes(priceId);
  const isOnStarterPlan = starterPlanIds.includes(priceId);
  const isOnProOrLtdPlan = proOrLtdPlanIds.includes(priceId);
  const isOnNewProOrLtdPlan = newProOrLtdPlanIds.includes(priceId);

  const showNoCreditsTrial = !errors.length && limit === 0 && isTrial;
  const showNoCreditsStarter = !errors.length && limit === 0 && isOnStarterPlan;
  const showNoCreditsPro = !errors.length && limit === 0 && isOnProOrLtdPlan;
  const showNotEnoughCreditsStarter = !errors.length
    && selectedFile
    && limit !== null
    && limit < selectedFile.rowsCount
    && isOnStarterPlan;
  const showNotEnoughCreditsPro = !errors.length
    && selectedFile
    && limit !== null
    && limit < selectedFile.rowsCount
    && isOnNewProOrLtdPlan;

  const renderAddCreditsLink = (text) => {
    return (
      <span
        className={classes.addCreditsLink}
        onClick={openAddCredits}
      >
        {text}
      </span>
    );
  };

  const noCreditsTrialContent = showNoCreditsTrial && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      <div>
        You&apos;ve used all your daily and/or total free words.
        {' '}
        <Link to="/profile/plans">Click here</Link>
        {' '}
        to subscribe today and get back to work!
      </div>
    </div>
  );

  const noCreditsStarterContent = showNoCreditsStarter && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      <div>
        You’re out of words for this month!&nbsp;
        {renderAddCreditsLink('Add additional words')}
        &nbsp;
        now to keep creating great content
      </div>
    </div>
  );

  const noCreditsProContent = showNoCreditsPro && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      You do not have enough words remaining for this generation.&nbsp;
      {renderAddCreditsLink('Add additional words')}
      &nbsp;
      {' '}
      now to keep creating great content!
    </div>
  );

  const notEnoughCreditsStarterContent = showNotEnoughCreditsStarter && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      You do not have enough words remaining to process the selected file.
      Please upload a new file with fewer items or&nbsp;
      {renderAddCreditsLink('add more words')}
      &nbsp;
      to your account to proceed.
    </div>
  );

  const notEnoughCreditsProContent = showNotEnoughCreditsPro && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      You do not have enough words remaining to process the selected file.
      Please upload a new file with fewer items,&nbsp;
      {renderAddCreditsLink('add more words')}
      &nbsp;
      to your account to proceed
    </div>
  );

  const jobInProgress = currentJobProcessing && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      You currently have a job in progress. A new file can be uploaded once it has completed.
    </div>
  );

  const ctaContent = bulkGenerationRows === 0 && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      <div className={classes.cta}>
        Sorry, you have used all of your monthly bulk generation rows.
        <br />
        <br />
        Upgrade to Enterprise for more bulk generations and take advantage of even more advanced features.
        <br />
        <br />
        <a href="mailto:kayla.hesseltine@copysmith.ai">Click here</a>
        {' '}
        to talk to sales!
      </div>
    </div>
  );

  const errorsContent = errors.length !== 0 && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      <div>
        Upload failed. The following errors were found:
        <ul className={classes.errorsList}>
          <li>
            {errors.map((e) => e && e.file).join('.')}
          </li>
        </ul>
      </div>
    </div>
  );

  const successContent = (
    <div className={classes.successWrap}>
      <CheckCircle className={classes.successIcon} />
      File successfully uploaded
    </div>
  );

  const warningContent = [
    errorsContent,
    noCreditsTrialContent,
    noCreditsStarterContent,
    noCreditsProContent,
    notEnoughCreditsStarterContent,
    notEnoughCreditsProContent,
  ].find((content) => !!content);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          Upload
        </div>
        <div className={classes.description}>
          Bulk Copy accepts a CSV file. Please use the provided&nbsp;
          <UiLink onClick={downloadSample}>example file</UiLink>
          &nbsp;or upload your own csv file.
        </div>

        <div className={classes.fileUploadWrapper}>
          <FileUploader
            onFileSelect={onFileSelect}
            dropSelectedFile={dropSelectedFile}
            file={selectedFile}
            uploadDisabled={!!selectedFile
              || (displayedFileMeta.name && errors.length === 0)
              || currentJobProcessing
              || bulkGenerationRows === 0}
            maxBulkGenerationRows={maxBulkGenerationRows}
            bulkGenerationRows={bulkGenerationRows}
          />
          {displayedFileMeta.name && (
            <div className={classes.uploadInfo}>
              <div className={classes.uploadInfoMeta}>
                <div className={classes.uploadInfoText}>
                  <InsertDriveFile className={classes.iconFile} />
                  <div className={classes.progressBarWrap}>
                    {displayedFileMeta.name}
                    &nbsp;&nbsp;
                    {!selectedFile && (
                      <div className={clsx(classes.progressBar, {
                        [classes.progressBarError]: errors.length,
                      })}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.fileActions}>
                  {selectedFile && (
                    <span
                      className={classes.actionIcon}
                      onClick={openPreviewModal}
                    >
                      <Visibility className={classes.visibilityAction} />
                    </span>
                  )}
                  <span
                    className={classes.actionIcon}
                    onClick={dropSelectedFile}
                  >
                    <Delete fill="#523AE7" />
                  </span>
                </div>

              </div>
              {showMaxRowsWarning
                && (
                  <div className={classes.warningBox}>
                    <Warning className={classes.errorIcon} />
                    { maxRowsWarningContent}
                  </div>
                )}
              {warningContent}
              {selectedFile && successContent}
            </div>
          )}
          {jobInProgress}
          {ctaContent}
        </div>

        <div className={classes.buttons}>
          <Button
            onClick={onSubmit}
            disabled={!!warningContent || !selectedFile || bulkGenerationRows === 0}
          >
            Next Step
          </Button>
        </div>
      </div>
      {isAddCreditsOpen && (
        <FeatureModal
          type={FEATURES.additionalCredits.type}
          currentQuantity={addonQuantity}
          onClose={closeAddCredits}
        />
      )}
      {isPreviewOpen && (
        <PreviewModal
          title={selectedFile?.fileName}
          rows={selectedFile?.rows}
          onClose={closePreviewModal}
        />
      )}
    </div>

  );
};

export default UploadForm;
