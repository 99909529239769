import React from 'react';

import useStyles from './MonthlyCredit.styles';

const MonthlyCredit = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {title}
      </div>
      {description && (
        <div className={classes.description}>
          {description}
        </div>
      )}
    </div>
  );
};

export default MonthlyCredit;
