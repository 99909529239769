import { useQuery } from 'react-query';

import api from '@root/api';

const getIntegrations = async () => {
  const { results: integrations } = await api.integration.list();

  return integrations;
};

const useIntegrations = () => useQuery(['get.integrations'], () => getIntegrations());

export default useIntegrations;
