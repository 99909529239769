import copy from 'clipboard-copy';
import { DocumentUtil } from '@copysmith/utils';

import uiNotificationService from '@root/services/uiNotification.service';
import { copyImgToClipboard } from '@root/utils/browser.utils';

export default class CopyBlock {
  constructor({ api, block }) {
    this.api = api;
    this.block = block;
    this.rootNode = null;
  }

  static get isTune() {
    return true;
  }

  handleCopyClick = async () => {
    const { blocks } = await this.api.saver.save();
    const block = blocks.find((b) => b.id === this.block.id);
    try {
      if (block.type === 'image') {
        await copyImgToClipboard(block.data.url);
      } else {
        const text = DocumentUtil.transformBlockToText(block);
        await copy(text);
      }
      uiNotificationService.showSuccessMessage('Copied to clipboard!');
    } catch (error) {
      uiNotificationService.showErrorMessage('Sorry, this content could not be copied to clipboard');
    }
  };

  render() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-copy"> <rect width="12" height="12" x="8" y="8" rx="2"/> <path d="M16 8V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2"/> </svg>',
      label: 'Copy',
      onActivate: (item, e) => this.handleCopyClick(e),
      name: 'copy',
      closeOnActivate: true,
    };
  }
}
