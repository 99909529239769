import React, { useState } from 'react';
import { useField } from 'formik';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core';

import Pill from './PillAtom';
import AddTitleAtom from './AddTitleAtom';

const useStyles = makeStyles((theme) => ({
  generatorInput: {
    height: '36px',
    borderRadius: '6px',
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    width: '100%',
    border: `1px solid ${theme.palette.colors.neutrals200}`,
    backgroundColor: 'white',

    background: 'white',
    fontStyle: 'normal',
    paddingLeft: '20px',

    '&:hover': {
      borderColor: theme.palette.colors.neutrals300,
    },
    '&:focus': {
      outline: 0,
      borderColor: theme.palette.colors.neutrals400,
    },
    '&:active': {
      outline: 0,
    },
  },
  w100: {
    width: '100%',
  },
  pillContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pill: {
    marginTop: '0.5rem', marginBottom: '0.5rem',
  },
}));

const KeywordInputAtomField = ({ title, required, ...props }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const [field, /* meta */, helpers] = useField(props);

  const { value: array } = field;
  const { setValue: setArray } = helpers;

  /** push the temporary input value into the Keyword Input array  */
  const pushInputValue = () => {
    if (inputValue.trim().length > 0) {
      const splitValues = inputValue
        .split(',')
        .map((s) => s.trim());
      const newArray = Array.from(new Set([...array, ...splitValues]));
      setArray(newArray);
      setInputValue('');
    }
  };

  const removeArrayValue = (removeValue) => {
    const filteredArray = array.filter((item) => item !== removeValue);
    setArray(filteredArray);
  };

  return (
    <AddTitleAtom
      required={required}
      title={title}
    >
      <div className={classes.w100}>
        <ClickAwayListener
          onClickAway={pushInputValue}
        >
          <input
            className={classes.generatorInput}
            type="text"
            placeholder="Enter ↵"
            onChange={(event) => setInputValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                pushInputValue();
              }
            }}
            value={inputValue}
          />
        </ClickAwayListener>
      </div>
      <div className={classes.pillContainer}>
        { array.map((adj) => (
          <Pill
            name={adj}
            key={adj}
            className={classes.pill}
            handleRemove={removeArrayValue}
          />
        ))}
      </div>
    </AddTitleAtom>
  );
};

export default KeywordInputAtomField;
