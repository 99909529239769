import React from 'react';
import { useHistory } from 'react-router-dom';

import useStyles from './AddonsSection.styles';

const EmptyState = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={classes.emptyStateContainer}>
      <div className={classes.emptyStateTitle}>
        You&apos;ll be able to modify any add-ons after they are added here.
      </div>
      <div className={classes.emptyStateLink} onClick={() => { history.push('/addons'); }}>
        Click here to learn more
      </div>
    </div>
  );
};

export default EmptyState;
