import React from 'react';
import { TabPanel } from '@material-ui/lab';
import { Fade } from '@material-ui/core';

import { tabs } from '../CreateAndExplore.constants';
import { useCreateAndExploreContext } from '../context/CreateAndExplore.context';

import useStyles from './Content.styles';
import CardContentView from './CardContentView';

const Content = () => {
  const classes = useStyles();
  const { state: { activeTab } } = useCreateAndExploreContext();

  return (
    <>
      <Fade in={activeTab === tabs.COMMUNITY}>
        <TabPanel value={tabs.COMMUNITY} index={0} style={{ padding: 0 }}>
          <div className={classes.content}>
            <CardContentView />
          </div>
        </TabPanel>
      </Fade>

      <Fade in={activeTab === tabs.CREATE}>
        <TabPanel value={tabs.CREATE} index={1} style={{ padding: 0 }}>
          <div className={classes.content}>
            <CardContentView />
          </div>
        </TabPanel>
      </Fade>
    </>
  );
};

export default Content;
