import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  accountCard: {
    minHeight: '230px',

    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,

    paddingBottom: '8px',

    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,

    width: '100%',
    overflow: 'hidden',
  },

  title: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '39px',
    color: theme.palette.colors.neutrals600,
    textTransform: 'capitalize',
  },

  description: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
  },

  plansLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  paymentMethodLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.colors.blue400,
  },

  price: {
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '1',
    color: theme.palette.colors.neutrals600,
  },

  billingPeriod: {
    fontSize: '14px',
    fontWeight: '400',
  },

  renewalBadge: {
    height: '22px',
    padding: '2px 16px',
    borderRadius: '11px',
    backgroundColor: theme.palette.colors.blue200,
    fontSize: '12px',
  },

  paymentMethodContainer: {
    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: '6px',
    padding: '12px',

    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
  },

  cardInfoText: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    marginBottom: '0',

    '&:first-letter': {
      textTransform: 'uppercase',
    },

    '&:last-child': {
    },
  },
  emailContainer: {
    marginTop: 8,
    lineHeight: 'normal',
  },
  emailTextContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '& svg': {
      flexShrink: 0,
    },
  },
  emailText: {
    margin: 0,
  },
  emailInputRoot: {
    maxHeight: 24,
    minHeight: 24,

  },
  emailInput: {
    height: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },

  bold: {
    fontWeight: '500',
  },

  footer: {
    marginTop: 'auto',
  },

  planStatusContainer: {
    borderRadius: '50px',
    color: theme.palette.colors.blue600,
    backgroundColor: theme.palette.colors.blue200,
    padding: '2px 16px',
    maxWidth: '220px',
  },

  badgeText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },

  active: {
    backgroundColor: theme.palette.colors.green200,
    color: theme.palette.colors.green600,
  },
  suspended: {
    backgroundColor: theme.palette.colors.yellow200,
    color: theme.palette.colors.yellow600,
  },
  cancelled: {
    backgroundColor: theme.palette.colors.pink200,
    color: theme.palette.colors.pink600,
  },

  confirmationModal: {
    maxWidth: 464,
  },

  brandIconContainer: {
    flexShrink: 0,

    width: 60,
    height: 36,
    display: 'grid',
    placeItems: 'center',
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 4,
  },

  paymentInfoContainer: {
    overflow: 'hidden',
  },

  emptyPaymentContainer: {
    flex: 1,
    alignSelf: 'center',
    textAlign: 'center',
    display: 'grid',
    placeContent: 'center',
    placeItems: 'center',

    fontSize: '14px',
    lineHeight: '22px',
  },
}));
