import React, { useCallback, useMemo, useReducer } from 'react';
import { escapeRegExp } from 'lodash';

import templatesContext from '@root/resources/templates/templates.context';
import {
  checkIsTemplateCustom,
  checkIsTemplateLive,
} from '@root/resources/aiTemplate/template.helpers';
import aiTemplateResource from '@root/resources/aiTemplate';
import { communityReducer, initialState } from '@root/views/Community/context/communityReducer';
import {
  confirmationModalsReducer,
  initialModalsState,
  modalsActions,
} from '@root/views/Community/context/confirmationModalsReducer';
import uiNotificationService from '@root/services/uiNotification.service';
import authContext from '@root/resources/auth/auth.context';

import { templateCategories } from '../Community.constants';

const searchTemplates = (searchQuery, templatesToSearch) => {
  if (!searchQuery) {
    return templatesToSearch;
  }

  const formattedSearchQuery = escapeRegExp(searchQuery).toUpperCase();

  const searchedTemplates = templatesToSearch.filter(
    (template) => template.title?.toUpperCase().search(formattedSearchQuery) > -1
      || template.user?.firstName?.toUpperCase().search(formattedSearchQuery) > -1
      || template.user?.surname?.toUpperCase().search(formattedSearchQuery) > -1,
  );

  return searchedTemplates;
};

const CommunityContext = React.createContext();

const CommunityContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(communityReducer(), initialState);
  const [modalsState, modalsDispatch] = useReducer(confirmationModalsReducer(), initialModalsState);

  const { templates, loadTemplates } = templatesContext.useTemplates();
  const { currentUser: user } = authContext.useAuth();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;

  const {
    data: communityTemplates,
    refetch: refetchCommunityTemplates,
  } = aiTemplateResource.useCommunityTemplates();

  const {
    mutateAsync: addCommunityTemplateToLibrary,
    isLoading: isTemplateAdding,
  } = aiTemplateResource.useAddCommunityTemplate();

  const {
    mutateAsync: deleteAiTemplate,
    isLoading: isTemplateDeleting,
  } = aiTemplateResource.useDeleteAiTemplate();

  const {
    mutateAsync: shareTemplateToCommunity,
    isLoading: isTemplateSharing,
  } = aiTemplateResource.useShareTemplateToCommunity();

  const {
    mutateAsync: removeTemplateFromCommunity,
    isLoading: isTemplateRemoving,
  } = aiTemplateResource.useRemoveTemplateFromCommunity();

  const isTemplateProcessing = (
    isTemplateSharing || isTemplateRemoving || isTemplateAdding || isTemplateDeleting
  );

  const handlePreviewTemplateClick = (template) => {
    modalsDispatch({
      type: modalsActions.TOGGLE_PREVIEW_TEMPLATE_MODAL,
      payload: template,
    });
  };

  const handleUploadTemplateToCommunityClick = (event, template) => {
    event.stopPropagation();

    if (isAdminOrOwner) {
      modalsDispatch({
        type: modalsActions.TOGGLE_SHARE_TO_COMMUNITY_MODAL,
        payload: template,
      });
      return;
    }
    uiNotificationService.showWarnMessage('This action can only be performed by Administrators.');
  };

  const handleRemoveFromCommunityClick = (event, template) => {
    event.stopPropagation();

    if (isAdminOrOwner) {
      modalsDispatch({
        type: modalsActions.TOGGLE_REMOVE_FROM_COMMUNITY_MODAL,
        payload: template,
      });
      return;
    }
    uiNotificationService.showWarnMessage('This action can only be performed by Administrators.');
  };

  const handleSaveToMyAccountClick = (event, templateId) => {
    event.stopPropagation();

    modalsDispatch({
      type: modalsActions.TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL,
      payload: templateId,
    });
  };

  const handleRemoveFromLibraryClick = (event, templateId) => {
    event.stopPropagation();

    modalsDispatch({
      type: modalsActions.TOGGLE_REMOVE_FROM_LIBRARY_MODAL,
      payload: templateId,
    });
  };

  const saveToMyAccount = useCallback(
    async (templateId) => {
      await addCommunityTemplateToLibrary(templateId);
    },
    [addCommunityTemplateToLibrary, loadTemplates],
  );

  const removeFromAccount = useCallback(
    async (templateId) => {
      await deleteAiTemplate(templateId, {
        onSuccess: () => {
          loadTemplates(false);

          refetchCommunityTemplates();
        },
      });

      uiNotificationService.showSuccessMessage('Success! Template removed from account.');
    },
    [deleteAiTemplate, loadTemplates],
  );

  const uploadToCommunity = useCallback(
    async ({ templateId, templateName, outputExample }) => {
      await shareTemplateToCommunity(
        { id: templateId, data: { templateName, outputExample } },
        { onSuccess: () => refetchCommunityTemplates() },
      );
    },
    [shareTemplateToCommunity],
  );

  const removeFromCommunity = useCallback(
    async (templateId) => {
      await removeTemplateFromCommunity(templateId);
    },
    [removeTemplateFromCommunity],
  );

  const templatesToShow = useMemo(() => {
    let result = [];

    switch (state.templatesCategory) {
      case templateCategories.MY_TEMPLATES:
        result = Object.values(templates)?.filter(
          (template) => checkIsTemplateLive(template)
            && checkIsTemplateCustom(template)
            && !template?.fromCommunity
            && !template?.deletedOn,
        );
        break;
      case templateCategories.COMMUNITY:
        result = communityTemplates;
        break;
      default:
        result = [];
        break;
    }

    if (state?.searchQuery) {
      return searchTemplates(state?.searchQuery, result);
    }

    return result || [];
  }, [state, templates, communityTemplates]);

  const value = {
    state,
    dispatch,
    modalsState,
    modalsDispatch,
    templates,
    templatesToShow,

    isTemplateProcessing,

    uploadToCommunity,
    handleUploadTemplateToCommunityClick,

    removeFromCommunity,
    handleRemoveFromCommunityClick,

    saveToMyAccount,
    handleSaveToMyAccountClick,

    removeFromAccount,
    handleRemoveFromLibraryClick,

    handlePreviewTemplateClick,
  };

  return <CommunityContext.Provider value={value}>{children}</CommunityContext.Provider>;
};

const useCommunityContext = () => {
  const context = React.useContext(CommunityContext);

  if (context === undefined) {
    throw new Error('useCommunityContext must be used within a CommunityContextProvider');
  }

  return context;
};

export { CommunityContextProvider, useCommunityContext };
