import React, { useState } from 'react';
import ReactQuill from 'react-quill';

const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'background',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'width',
  'style',
];

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'super' }], // superscript/subscript
      [{ background: [] }], // dropdown with defaults from theme
      ['clean'], // remove formatting button
    ],
  },
};

const getInitialContent = (content) => {
  const value = content.replace(/\n/g, '<br>');
  return value;
};

const ContentEditor = ({
  initialTextValue,
  changeContent,
}) => {
  const ref = React.useRef();
  const initialContent = getInitialContent(initialTextValue);
  const [textValue, setTextValue] = useState(initialContent);

  const handleChange = (newValue) => {
    const processedNewValue = newValue.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    setTextValue(processedNewValue);
    changeContent({ value: processedNewValue });
  };

  React.useEffect(() => {
    if (initialContent !== textValue) {
      setTextValue(initialContent);
      ref.current.getEditor().root.innerHTML = initialContent;
    }
  }, [initialContent]);

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      format={formats}
      defaultValue={textValue}
      onChange={handleChange}
      ref={ref}
    />
  );
};

export default ContentEditor;
