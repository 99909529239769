import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  drawerContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflowX: 'hidden',
    zIndex: 2000,
  },
  drawerContent: {
    background: 'white',
    position: 'absolute',
    maxWidth: '600px',
    height: '100%',
    display: 'inline-block',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    overflow: 'hidden',
  },
  drawerContentLeft: {
    left: 0,
    margin: '0 40px 0 0',
  },
  drawerContentRight: {
    right: 0,
  },
  overlayOpen: {
    animation: 'fade-in 0.5s forwards',
    '-webkit-animation': 'fade-in 0.5s forwards',
  },
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(0, 0, 0, 50%)',
    position: 'absolute',
    animation: 'fade-out 0.5s forwards',
    '-webkit-animation': 'fade-out 0.5s forwards',
  },
  '@keyframes fade-in': {
    '0%': {
      backgroundColor: 'rgb(0, 0, 0, 0%)',
    },
    '100%': {
      backgroundColor: 'rgb(0, 0, 0, 50%)',
    },
  },
  // '@-webkit-keyframes fade-in': {
  //   '0%': {
  //     backgroundColor: 'rgb(0, 0, 0, 0%)',
  //   },
  //   '100%': {
  //     backgroundColor: 'rgb(0, 0, 0, 50%)',
  //   },
  // },
  '@keyframes fade-out': {
    '0%': {
      backgroundColor: 'rgb(0, 0, 0, 50%)',
    },
    '100%': {
      backgroundColor: 'rgb(0, 0, 0, 0%)',
    },
  },
  // '@-webkit-keyframes fade-out': {
  //   '0%': {
  //     backgroundColor: 'rgb(0, 0, 0, 50%)',
  //   },
  //   '100%': {
  //     backgroundColor: 'rgb(0, 0, 0, 0%)',
  //   },
  // },
  // TODO: fix the transition
  drawerTransition: {
    // visibility: (props) => props.open === 'preload' && 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'all 250ms',
    overflow: 'hidden',
    // animation: (props) => `${(props.open === true ? 'slide-in' : 'slide-out')} 0.5s forwards`,
    // '-webkit-animation': (props) => `${(props.open === true ? 'slide-in' : 'slide-out')}
    // 0.5s forwards`,
    // '@keyframes slide-in': {
    //   '0%': {
    //     transform: `translateX(${(props) => props.anchor === 'left' && '-'}100%)`,
    //   },
    //   '100%': {
    //     transform: 'translateX(0%)',
    //   },
    // },
    // '@-webkit-keyframes slide-in': {
    //   '0%': {
    //     '-webkit-transform': (props) => `translateX(${props.anchor === 'left' && '-'}100%`,
    //   },
    //   '100%': {
    //     '-webkit-transform': 'translateX(0%)',
    //   },
    // },
    // '@keyframes slide-out': {
    //   '0%': {
    //     transform: 'translateX(0%)',
    //   },
    //   '100%': {
    //     transform: (props) => `translateX(${props.anchor === 'left' && '-'}100%)`,
    //   },
    // },

    // '@-webkit-keyframes slide-out': {
    //   '0%': {
    //     '-webkit-transform': 'translateX(0%)',
    //   },
    //   '100%': {
    //     '-webkit-transform': (props) => `translateX(${props.anchor === 'left' && '-'}100%`,
    //   },
    // },
  },
}));
