import { LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

import useGetCurrentBulkJob from '@root/resources/bigCommerce/useGetCurrentBulkJob';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    height: '100%',
    fontSize: 14,
    fontWeight: 400,
  },
  wrap: {
    width: theme.spacing(48.25),

    '& > div > div': {
      backgroundColor: theme.palette.colors.neutrals600,
    },
  },
}));

const Progress = ({
  onBulkDone,
  onBulkFailed,
}) => {
  const classes = useStyles();

  const {
    data: currentBulkJob,
    refetch: refetchCurrentBulkJob,
  } = useGetCurrentBulkJob();

  const { percentage } = React.useMemo(() => {
    const total = currentBulkJob?.rowsCount || 0;
    const completed = currentBulkJob?.result?.successfulRows || 0;

    const p = Math.round((completed / total) * 100);

    return {
      percentage: p,
      state: currentBulkJob?.state,
    };
  }, [currentBulkJob]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      refetchCurrentBulkJob();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    if (currentBulkJob?.state === 'done') {
      onBulkDone();
    }
    if (currentBulkJob?.state === 'failed') {
      onBulkFailed();
    }
  }, [currentBulkJob]);

  return (
    <div className={classes.root}>
      <div className={classes.wrap}>
        <LinearProgress
          value={percentage}
          variant={['inProgress', 'done'].includes(currentBulkJob?.state) ? 'determinate' : 'indeterminate'}
        />
      </div>
      <div>
        Hold on a moment. A work of art is being crafted!
      </div>
    </div>
  );
};

export default Progress;
