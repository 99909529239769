import React from 'react';

const MasterCard = ({ style }) => {
  return (
    <svg
      width="35"
      height="22"
      viewBox="0 0 35 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M12.4341 2.74524H22.5411V19.253H12.4341V2.74524Z" fill="#FF5F00" />
      <path d="M13.4749 11.0011C13.4736 9.41155 13.834 7.84254 14.5286 6.41281C15.2233 4.98308 16.2341 3.73009 17.4845 2.74867C15.9358 1.53158 14.0759 0.774734 12.1175 0.564649C10.159 0.354563 8.18092 0.69971 6.40933 1.56064C4.63774 2.42157 3.14411 3.76356 2.09913 5.43321C1.05416 7.10287 0.5 9.03284 0.5 11.0025C0.5 12.9722 1.05416 14.9022 2.09913 16.5719C3.14411 18.2415 4.63774 19.5835 6.40933 20.4444C8.18092 21.3054 10.159 21.6505 12.1175 21.4404C14.0759 21.2303 15.9358 20.4735 17.4845 19.2564C16.2337 18.2747 15.2227 17.0212 14.528 15.591C13.8333 14.1607 13.4732 12.5911 13.4749 11.0011V11.0011Z" fill="#EB001B" />
      <path d="M34.4716 11.0005C34.4716 12.9704 33.9173 14.9005 32.8721 16.5702C31.827 18.2399 30.3331 19.5819 28.5613 20.4427C26.7895 21.3035 24.8112 21.6484 22.8526 21.438C20.894 21.2276 19.0342 20.4704 17.4856 19.2529C18.7355 18.2706 19.746 17.0173 20.4408 15.5875C21.1357 14.1577 21.4967 12.5887 21.4967 10.999C21.4967 9.40932 21.1357 7.84038 20.4408 6.41058C19.746 4.98077 18.7355 3.72744 17.4856 2.74515C19.0342 1.52766 20.894 0.770446 22.8526 0.560054C24.8112 0.349662 26.7895 0.694587 28.5613 1.5554C30.3331 2.41621 31.827 3.75818 32.8722 5.42791C33.9173 7.09763 34.4716 9.02773 34.4716 10.9976V11.0005Z" fill="#F79E1B" />
    </svg>

  );
};

export default MasterCard;
