import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';

import { KeywordInputAtom } from '@root/components/Fields';
import { InputField, FormField } from '@root/components/form';

import googleAdFileResource from '../resources';

import useStyles from './GoogleAdKeywords.styles';

const URL = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;

const validationSchema = yup.object({
  url: yup
    .string()
    .matches(URL, 'Valid URL is required, including http:// or https://')
    .notRequired(),
  productsArray: yup
    .array()
    .of(yup.string())
    .min(1)
    .notRequired(),
});

const GoogleAdKeywords = ({
  updateKeywords,
  keywords,
  customerId,
  managerCustomerId,
}) => {
  const classes = useStyles();

  const [query, setQuery] = React.useState({
    customerId,
    managerCustomerId,
  });
  const { refetch: getKeywords, isFetching } = googleAdFileResource.useGetKeywords(query);

  const submitHandler = async () => {
    const { data: res } = await getKeywords();
    if (res.isBadRequest) {
      return;
    }
    updateKeywords(res.map((x) => x.text).join('\n'));
  };

  return (
    <Formik
      initialValues={{
        url: '',
      }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, isValid, values, setValues }) => {
        const updateArray = (value) => {
          let newValues = null;
          if (typeof value === 'function') {
            newValues = {
              url: values.url,
              productsArray: value(values.productsArray) || [],
            };
          } else {
            newValues = {
              url: values.url,
              productsArray: values.productsArray
                ? [...values.productsArray, ...value] : [...value],
            };
          }
          setValues(newValues);

          setQuery({
            ...query,
            keywords: newValues.productsArray,
            pageUrl: newValues.url,
          });
        };
        const submitEnabled = isValid && (values.productsArray?.length > 0 && values.url);

        return (
          <div>
            <FormField
              name="url"
              component={InputField}
              label="Get keywords by web page URL:"
              placeholder="Enter related web page URL"
              fullWidth
            />
            <KeywordInputAtom
              array={values.productsArray}
              setArray={updateArray}
              required={false}
              title="Get keywords by entering products or services"
              defaultValue="Hit “Enter” between products and services"
              formik
            />
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!submitEnabled || isFetching}
              >
                {keywords ? 'Update' : 'Get keywords'}
              </Button>
              {isFetching && <CircularProgress size={16} /> }
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default GoogleAdKeywords;
