import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  topActions: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
