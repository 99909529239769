import React from 'react';

const useExampleOpen = () => {
  const [exampleOpen, setExampleOpen] = React.useState(false);

  const toggleExampleOpen = () => {
    setExampleOpen(!exampleOpen);
  };

  const openExample = () => {
    setExampleOpen(true);
  };

  return {
    toggleExampleOpen,
    openExample,
    exampleOpen,
  };
};

export default useExampleOpen;
