export default {
  title: 'Product Description',
  description: 'Craft a caption for your product.',
  input: [
    {
      title: 'Name',
      value: 'Anthropologie',
    },
    {
      title: 'Product Description',
      value: 'Elizabeth Mini Dress',
    },
    {
      title: 'Keywords',
      value: 'Friendly, Organic, Hipster',
    },
    {
      title: 'Product Characteristics',
      value: 'Wrap Dress, Red, Floral Print, V Neckline, Belt, 100% Cotton',
    },
  ],
  output: {
    _id: '1',
    templateType: 'productDescription',
    content:
      'Stay cool when the temperatures rise with the comfortable yet flattering Elisabeth Mini Dress. The floral print gives the smooth cotton fabric a feminine flair, ensuring all eyes will be on you when you wear it out. Belted up, it keeps the fitted waist even and stylist, outlining your figure in romantic floral details.',
  },
  fileData: {
    companyName: 'Anthropologie',
    productName: 'Elizabeth Mini Dress',
  },
};
