import { capitalize } from 'lodash';
import * as Yup from 'yup';

const getSchemaShape = (fields) => {
  const shape = {};
  fields.forEach((field) => {
    const { required, name, label, maximum, type } = field;
    const isKeywords = type === 'keywords';
    const fieldLabel = capitalize(label);

    let fieldSchema = isKeywords
      ? Yup.array().of(Yup.string())
      : Yup.string();
    if (required) {
      fieldSchema = isKeywords
        ? fieldSchema.min(1, `${fieldLabel} are required`)
        : fieldSchema.required(`${fieldLabel} is required`);
    }
    if (maximum) {
      fieldSchema = fieldSchema.max(maximum, `${fieldLabel} cannot exceed ${maximum} characters`);
    }
    shape[name] = fieldSchema;
  });
  return shape;
};

export const getSchema = (fields) => Yup.object().shape({
  data: Yup.object().shape(getSchemaShape(fields)),
});

export const getInitialValues = ({ max_tokens, fields }) => {
  const data = {
    tone: 'Neutral',
  };
  fields.forEach((f) => {
    data[f.name] = f.type === 'keywords' ? [] : '';
  });
  return ({
    outputLanguage: 'en',
    aiOptions: {
      n: 1,
      temperature: '',
      max_tokens,
    },
    engine: 'default',
    seoKeywords: [],
    data,
  });
};

export const variantsOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

export const creativityOptions = [
  { value: '', label: 'Ideal' },
  { value: 0.5, label: 'Simple' },
  { value: 0.75, label: 'Chatty' },
  { value: 0.85, label: 'Verbose' },
];

export const toneOptions = [
  { value: 'Neutral', label: 'Neutral' },
  { value: 'Friendly', label: 'Friendly' },
  { value: 'Funny', label: 'Funny' },
  { value: 'Professional', label: 'Professional' },
  { value: 'Adventurous', label: 'Adventurous' },
  { value: 'Luxurious', label: 'Luxurious' },
];
