import React, { useRef } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';

import FolderOptionsKebab from '@root/views/Dashboard/components/IconButtons/FolderOptionsKebab';
import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import ItemTypes from '@root/utils/dndItemTypes';
import uiNotificationService from '@root/services/uiNotification.service';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import AuthContext from '@root/resources/auth/auth.context';

import useCommonStyles from './tableComponents.styles';

const SubfolderTableRow = ({
  folder,
  isDraggable,
  cells,
}) => {
  const { dispatch } = useDashboard();
  const { mutateAsync: updateFile } = useUpdateFile();
  const ref = useRef(null);
  const commonClasses = useCommonStyles();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const { fixedColumn } = currentExternalSource.toggles;

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  const moveFileHere = async (fileId) => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move file to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`File has been moved to ${folder.title}`);
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.FILE_CARD,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item) => {
      await moveFileHere(item.fileId);
    },
  });

  const drag = useDrag({
    type: ItemTypes.FOLDER_CARD,
    item: { folderId: folder._id },
  })[1];

  const openSubfolder = () => {
    dispatch({
      type: actions.SELECT_FOLDER,
      payload: { folderId: folder._id, title: folder.title },
    });
  };
  if (isDraggable) {
    drag(drop(ref));
  }

  const renderCells = (rowRef) => cells.map(({ render, id }, index) => {
    return (
      <TableCell
        key={id}
        id={id}
        padding="none"
        classes={{
          root: clsx({
            [commonClasses.textOverflow]: id === 'title',
            [commonClasses.fixedCell]: fixedColumn && !index,
          }),
        }}
        className={commonClasses.cell}
      >
        {render ? render(folder, rowRef) : folder[id]}
      </TableCell>
    );
  });

  return (
    <TableRow
      className={clsx(commonClasses.fileRow, {
        [commonClasses.isDraggingOver]: isOver,
        [commonClasses.newAlternatingRow]: newDesign,
      })}
      key={folder._id}
      onClick={openSubfolder}
      ref={ref}
    >
      <TableCell className={commonClasses.cell} padding="checkbox" align="center" />

      {renderCells(ref)}

      <TableCell className={commonClasses.cell} padding="none" />

      <TableCell className={commonClasses.cell} padding="none" align="right">
        <FolderOptionsKebab variant="list" folder={folder} />
      </TableCell>
    </TableRow>
  );
};

export default SubfolderTableRow;
