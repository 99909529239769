import { redirectToNewEditor } from '@root/resources/file/file.helpers';
import api from '@root/api/newApi';

import importProductsIcon from './importProducts.icon.svg';
import connectStoreIcon from './connectStore.icon.svg';
import generateContentIcon from './generateContent.icon.svg';

const quickStartData = [{
  title: 'Import Products',
  description: 'Have all your products tucked away in a file on your computer? Hand them over to us via our import tool to let the product content management happen.',
  icon: importProductsIcon,
  onAction: async ({ history }) => {
    try {
      const { catalogs: { results = [] } } = await api.catalog.search({
        sort: '_id',
      });

      if (results.length === 0) {
        history.push('/catalog?new=true');
        return;
      }

      const lastCatalog = results[results.length - 1];

      history.push(`/catalog/${lastCatalog._id}`);
    } catch (error) {
      history.push('/catalog?new=true');
    }
  },
  disabled: ({ currentUser }) => !currentUser?.permissions?.catalogPCM,
}, {
  title: 'Connect Store',
  description: 'Are your products already selling in a store? You can connect your store to our system and off load all your products for easy product management.',
  icon: connectStoreIcon,
  onAction: ({ history }) => history.push('/addons/apps'),
  disabled: () => false,
}, {
  title: 'Generate product Content',
  description: 'Want to see the power of AI quickly? Click here to start drafting product content using our AI editor tool.',
  icon: generateContentIcon,
  onAction: async ({ history, createFile }) => {
    await redirectToNewEditor(createFile, {}, history);
  },
  disabled: () => false,
}];

export default quickStartData;
