import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '3rem 0',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    minWidth: '300px',
  },
  smallCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '28%',
    minWidth: '300px',
  },
  image: {
    maxWidth: '100%',
    width: '500px',
    height: 'auto',
    paddingTop: '1%',
    paddingLeft: '2%',
    paddingRight: '2%',
    alignSelf: 'center',
    clipPath: 'circle(45% at center)',
  },
  contentBlock: {
    paddingBottom: '10px',
  },
  largeHeaderText: {
    width: '100%',
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    textAlign: 'left',
  },
  largeBodyText: {
    textAlign: 'left',
    marginBottom: 0,
    fontSize: '18px',
    fontStretch: '100%',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
  },
}));

const ErrorDisplay = ({ title, name }) => {
  const classes = useStyles();
  return (
    <div className={classes.contentBlock}>
      <div className={classes.section}>
        <div className={classes.col}>
          <div className={classes.largeHeaderText}>{title}</div>
          <div className="mt-4 text-center text-md-left">
            {name}
          </div>
        </div>
        <div className={classes.smallCol}>
          <img
            className={clsx('w-75', classes.image)}
            src="https://i.imgur.com/docf8Fw.png"
            alt="Not Found"
            title="Workflow"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorDisplay;
