import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dateBlurbRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',

    '& b': {
      fontWeight: 500,
    },
  },
}));

export default useStyles;
