import React from 'react';
import clsx from 'clsx';
import {
  KeyboardArrowDown as DownIcon,
  KeyboardArrowRight as RightIcon,
} from '@material-ui/icons';
import { DialogActions, DialogContent } from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import aiTemplateResource from '@root/resources/aiTemplate';
import useTemplates from '@root/resources/aiTemplate/useTemplates';
import userService from '@root/resources/user/user.service';
import ProfileIcon from '@root/components/ProfileIcon';
import useModalState from '@root/utils/hooks/useModalState';
import authContext from '@root/resources/auth/auth.context';
import CustomTemplateStarsIcon from '@root/assets/customTemplateStars.svg';
import api from '@root/api';

const getActionButtonText = ({ isOwner, isRemoved }) => {
  if (isOwner) {
    return 'Owner';
  }
  return isRemoved ? 'Bring Back' : 'Deactivate';
};

const Step1 = ({
  freeSeats = 0,
  shouldRemoveUnlimited,
  shouldRemoveCustomTemplates,
  maxCustomTemplatesPerPlan = 0,
  customTemplatesCount = 0,
  classes,
  planName = '',
  onContinue,
  onClose,
  doNotPromptForRemoveSeats,
}) => {
  const { currentUser } = authContext.useAuth();

  const { isFetching: isFetchingMembers, data: membersData } = useTeamMembers();
  const { data: templates } = useTemplates();
  const { mutateAsync: deleteAiTemplate } = aiTemplateResource.useDeleteAiTemplate();

  const [userIdsToDeactivate, setUserIdsToDeactivate] = React.useState([]);
  const [templateIdsToRemove, setTemplateIdsToRemove] = React.useState([]);

  const [isDetailsOpen, , closeDetails, toggleDetails] = useModalState(false);
  const [
    isCustomTemplatesDetailsOpen,,
    closeCustomTemplatesDetails,
    toggleCustomTemplatesDetails,
  ] = useModalState(false);

  const notDeletedCustomTemplates = templates
    ?.filter(({ deletedOn, templateType }) => !deletedOn && templateType.includes('custom_')) || [];

  const currentMembersCount = membersData.count;
  const isRemoveRequired = currentMembersCount > freeSeats && !doNotPromptForRemoveSeats;

  const numberOfCustomTemplatesToRemove = customTemplatesCount - maxCustomTemplatesPerPlan;

  const exceededCustomTemplatesCount = customTemplatesCount - templateIdsToRemove.length;
  const exceededMembersCount = currentMembersCount - userIdsToDeactivate.length;

  const isNumberOfCustomTemplatesExceed = exceededCustomTemplatesCount > maxCustomTemplatesPerPlan;
  const isNumberOfMembersExceed = exceededMembersCount > freeSeats;

  const handleClose = () => {
    closeCustomTemplatesDetails();
    closeDetails();
    onClose();
  };

  const toggleUserDeactivate = (user) => {
    if (userIdsToDeactivate.includes(user._id)) {
      const newUserIdsToRemove = userIdsToDeactivate.filter((userId) => {
        return userId !== user._id;
      });
      return setUserIdsToDeactivate(newUserIdsToRemove);
    }

    const newUserIdsToRemove = userIdsToDeactivate.concat(user._id);
    return setUserIdsToDeactivate(newUserIdsToRemove);
  };

  const toggleTemplateRemove = (templateId) => {
    if (templateIdsToRemove.includes(templateId)) {
      setTemplateIdsToRemove((prevTemplateToRemove) => {
        const newtemplateIdsToRemove = prevTemplateToRemove.filter((id) => templateId !== id);

        return newtemplateIdsToRemove;
      });
      return;
    }

    setTemplateIdsToRemove((prevTemplateIds) => [...prevTemplateIds, templateId]);
  };

  const removeCustomTemplates = async () => {
    const removePromises = templateIdsToRemove.map((id) => {
      return deleteAiTemplate(id);
    });

    await Promise.all(removePromises);
  };

  const deactivateUsers = async () => {
    const removePromises = userIdsToDeactivate.map((userId) => {
      return api.user.deactivateCompanyMember(userId);
    });

    return Promise.all(removePromises);
  };

  const handleContinue = async () => {
    await deactivateUsers();
    await removeCustomTemplates();
    onContinue();
  };

  const description = (
    <>
      The plan you selected includes only
      {' '}
      <b>
        { freeSeats }
        {' '}
        team seats
      </b>
      .
      Please deactivate at least
      {' '}
      <b>{ currentMembersCount - freeSeats }</b>
      {' '}
      of team members or subscribe to a higher plan.
    </>
  );

  const removeCustomTemplatesDescription = (
    <>
      It looks like you currently have more custom content generators
      than what is included in
      {planName ? ` ${planName} ` : ' ' }
      Plan.
      In order to downgrade, please delete
      {' '}
      {numberOfCustomTemplatesToRemove}
      {' '}
      extra generator
      {numberOfCustomTemplatesToRemove > 1 ? 's' : ''}
      {' '}
      first.
      Once you&apos;ve done that, you&apos;ll be able to downgrade.
    </>
  );

  const unlimitedDescription = (
    <>
      <b>Unlimited Credits</b>
      {' '}
      are not available on the selected plan type.
      This add-on will be deactivated.
    </>
  );

  return (
    <>
      <DialogContent className={classes.content}>
        {shouldRemoveUnlimited && (
          <div className={clsx(classes.description, {
            [classes.descriptionWithSeparator]: isRemoveRequired,
          })}
          >
            {unlimitedDescription}
          </div>
        )}

        {shouldRemoveCustomTemplates && (
          <div className={clsx(classes.description)}>
            {removeCustomTemplatesDescription}
          </div>
        )}

        {isRemoveRequired && (
          <div className={classes.description}>
            {description}
          </div>
        )}

        <div className={classes.details}>
          {isRemoveRequired && (
            <div className={clsx(classes.detailsToggle, {
              [classes.detailsToggleExceeded]: isNumberOfMembersExceed,
            })}
            >
              <Button
                onClick={toggleDetails}
                variant="link"
                className={classes.collapseButton}
              >
                {isDetailsOpen ? <DownIcon /> : <RightIcon />}
                Manage team
              </Button>
              <p>
                {exceededMembersCount}
                {' '}
                out of
                {' '}
                {freeSeats}
              </p>
            </div>
          )}

          <div className={classes.detailsOpen}>
            {isRemoveRequired && isDetailsOpen && !isFetchingMembers && (
              membersData.results
                .map((member) => {
                  const memberRoles = userService.getRoles(member);
                  const highestRole = userService.getHighestRole(memberRoles);
                  const isOwner = highestRole === 'owner';
                  const isRemoved = userIdsToDeactivate.includes(member._id);

                  return (
                    <div className={classes.memberRow} key={member._id}>
                      <div className={classes.memberInfo}>
                        <ProfileIcon
                          name={member.displayName || member.email}
                          className={clsx(classes.profileIcon, {
                            [classes.profileIconRemoved]: isRemoved,
                          })}
                        />
                        <div className={clsx(classes.memberName, {
                          [classes.memberNameRemoved]: isRemoved,
                        })}
                        >
                          {member.displayName}
                          {currentUser._id === member._id && '(you)'}
                        </div>
                      </div>
                      <Button
                        onClick={() => toggleUserDeactivate(member)}
                        variant="link"
                        disabled={isOwner}
                        disableRipple
                      >
                        <span className={classes.buttonText}>
                          {getActionButtonText({ isOwner, isRemoved, freeSeats })}
                        </span>
                      </Button>
                    </div>
                  );
                })
            )}
          </div>

          {shouldRemoveCustomTemplates && (
            <div className={clsx(classes.detailsToggle, {
              [classes.detailsToggleExceeded]: isNumberOfCustomTemplatesExceed,
            })}
            >
              <Button
                onClick={toggleCustomTemplatesDetails}
                variant="link"
                className={classes.collapseButton}
              >
                {isDetailsOpen ? <DownIcon /> : <RightIcon />}
                Manage custom generators
              </Button>
              <p>
                {exceededCustomTemplatesCount}
                {' '}
                out of
                {' '}
                {maxCustomTemplatesPerPlan}
              </p>
            </div>
          )}

          <div className={classes.detailsOpen}>
            {shouldRemoveCustomTemplates && isCustomTemplatesDetailsOpen && (
              notDeletedCustomTemplates.map(({ title: templateTitle, _id: templateId }) => {
                const isRemoved = false;
                return (
                  <div className={classes.memberRow} key={templateId}>
                    <div className={classes.memberInfo}>
                      <img
                        src={CustomTemplateStarsIcon}
                        alt="Custom Template Icon"
                        className={clsx(classes.profileIcon, {
                          [classes.profileIconRemoved]: isRemoved,
                        })}
                      />
                      <div className={clsx(classes.memberName, {
                        [classes.memberNameRemoved]: isRemoved,
                      })}
                      >
                        {templateTitle}
                      </div>
                    </div>
                    <Button
                      onClick={() => toggleTemplateRemove(templateId)}
                      variant="link"
                      disableRipple
                    >
                      <span className={classes.buttonText}>
                        {templateIdsToRemove.includes(templateId)
                          ? 'Restore'
                          : 'Remove'}
                      </span>
                    </Button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleContinue}
          disabled={isNumberOfMembersExceed || isNumberOfCustomTemplatesExceed}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default Step1;
