import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

interface GenerateShadowLoginTokenOptions extends RequestOptions {
  userId: string;
}

export class Admin {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async generateShadowLoginToken(
    data: GenerateShadowLoginTokenOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/admin/shadow-login", data, options);

    return result;
  }

  public async generateAdminShadowLoginToken(
    data: GenerateShadowLoginTokenOptions,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/admin/super-shadow-login", data, options);

    return result;
  }
}
