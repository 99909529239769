import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {

  },
  closeIcon: {
    position: 'absolute',
    fontSize: '38px',
    top: '14px',
    right: '32px',
    cursor: 'pointer',
    color: theme.palette.colors.grey,
  },
  renewTitle: {
    position: 'absolute',
    top: '32px',
    left: '48px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  renewContainer: {
    padding: '16px 48px 0 48px',
    height: 'calc(100% - 88px)',
    overflow: 'scroll',
  },
  b8: {
    marginBottom: '8px',
  },
  renewButtonContainer: {
    width: '320px',
    marginBottom: '48px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  laterButton: {
    color: theme.palette.colors.darkGrey,
    borderColor: theme.palette.colors.darkGrey,
  },
  prelative: {
    position: 'relative',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  resultContent: {
    margin: '32px 48px 48px 48px',
  },
  welcomeMessage: {

  },
  modal: {
    position: 'relative',
    fontFamily: '"Inter", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  header: {
    backgroundColor: theme.palette.colors.lightGrey,
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    padding: '1rem 2.5rem',
    width: '100%',
    height: '88px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '95%',
    minWidth: '44rem',
    height: '90%',
    minHeight: '30rem',
    alignContent: 'center',
    outline: 'none',
  },
  small: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    width: '30rem',
    // minHeight: "30rem",
    alignContent: 'center',
    outline: 'none',
  },
}));

export default useStyles;
