import React from 'react';

import Button from '@root/components/buttons/Button';

import useStyles from './OnboardingTooltip.styles';

const OnboardingTooltip = (props) => {
  const {
    index,
    continuous,
    step,
    size,
    isLastStep,
    // closeProps,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  } = props;
  const {
    title, content, hideBackButton, showSkipButton, showNextButton, showProgress, locale,
  } = step;

  const classes = useStyles();

  return (
    <div
      {...tooltipProps}
      className={classes.tooltipRoot}
    >
      {title && (
        <h3 className={classes.title}>
          {title}
        </h3>
      )}

      <div className={classes.content}>
        {content}
      </div>

      <div className={classes.footer}>
        <div>
          {(!hideBackButton && index > 0) && (
            <Button
              {...backProps}
              variant="link"
            >
              {locale.back}
            </Button>
          )}
          {showNextButton && (
            <Button
              {...primaryProps}
              variant="link"
            >
              {(continuous && !isLastStep) ? locale.next : locale.last}
            </Button>
          )}
          {(showSkipButton && !isLastStep) && (
            <Button
              {...skipProps}
              variant="link"
              className={classes.fadedText}
            >
              {locale.skip}
            </Button>
          )}
        </div>
        {showProgress && (
          <div className={classes.fadedText}>
            {`${index + 1}/${size}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingTooltip;
