import ReactDOM from 'react-dom';
import React from 'react';
import { DocumentUtil } from '@copysmith/utils';

import ContextWrapper from '@root/views/Editor/EditorToolsContextWrapper';
import uiNotificationService from '@root/services/uiNotification.service';

export default class PublishToPim {
  constructor({ api, block }) {
    this.api = api;
    this.block = block;
    this.node = null;
  }

  static get isTune() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getPimInfo() {
    return {
      modal: '',
      menuLabel: '',
      menuIcon: '',
      menuKey: '',
      pimName: '',
    };
  }

  handlePublish = async () => {
    const {
      modal: Modal,
      pimName,
    } = this.getPimInfo();

    const { blocks } = await this.api.saver.save();
    const block = blocks.find((b) => b.id === this.block.id);

    if (!['paragraph', 'header', 'checklist', 'list'].includes(block.type)) {
      uiNotificationService.showErrorMessage(`Sorry, this content could not be published to ${pimName}`);
      return;
    }

    const text = DocumentUtil.transformBlockToPlainText(block);

    this.node = document.createElement('div');

    const unmount = () => {
      ReactDOM.unmountComponentAtNode(this.node);
      this.node.remove();
    };

    ReactDOM.render((
      <ContextWrapper>
        <Modal
          unmount={unmount}
          text={text}
        />
      </ContextWrapper>
    ), this.node);
  };

  render() {
    const { menuLabel, menuIcon, menuKey } = this.getPimInfo();
    return {
      icon: menuIcon,
      label: menuLabel,
      onActivate: (item, e) => this.handlePublish(e),
      name: menuKey,
      closeOnActivate: true,
    };
  }
}
