import config from '@config';

const { Cohere } = window;
const enabled = config.env === 'production' && Cohere;

const initUser = (userId, email, displayName) => {
  if (!enabled) {
    return;
  }

  Cohere.identify(
    userId,
    {
      displayName,
      email,
    },
  );
};

export default {
  initUser,
};
