import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  avatar: {
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    flexShrink: 0,
  },
  membersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '0px 8px',
  },
  memberDataContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  memberInfo: {
    flex: 1,
    overflow: 'hidden',
  },
  memberNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  memberRole: {
    flexShrink: 0,
    borderRadius: '36px',
    backgroundColor: theme.palette.colors.neutrals50,
    padding: '0px 8px',

    textTransform: 'capitalize',
    color: theme.palette.colors.neutrals300,
    fontSize: '12px',
    lineHeight: '18px',
  },
  memberEmail: {
    lineHeight: '22px',
  },
}));
