import React from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import Projects from '@root/components/SVGIcon/Projects';

import useStyles from './DefaultSidebar.styles';

const Sidebar = ({
  page,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const openPage = (p, params = {}) => () => {
    const { shouldReplace } = params;
    if (shouldReplace) {
      history.replace(`/bulk-copy/${p}`, {
        reset: true,
      });
    } else {
      history.push(`/bulk-copy/${p}`);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <List className={classes.list}>
          <ListItem
            onClick={openPage('new', {
              shouldReplace: true,
            })}
            button
            selected={page === 'newBulkCopy'}
            className={classes.listItemWrap}
          >
            <ListItemIcon className={classes.listIcon}>
              <Add />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              New Bulk Copy
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={openPage('history')}
            button
            selected={page === 'history'}
            className={classes.listItemWrap}
          >
            <ListItemIcon className={classes.listIcon}>
              <Projects />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Bulk history
            </ListItemText>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default Sidebar;
