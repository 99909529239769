import React from 'react';
import clsx from 'clsx';

import useStyles from './PublishToPim.product.styles';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

const PublishToPimProduct = ({
  product,
  onSelect,
  selected,
}) => {
  const classes = useStyles();

  const handleSelect = () => {
    onSelect(product.id);
  };

  return (
    <div
      className={clsx(classes.product, { [classes.selected]: selected })}
      key={product.id}
      onClick={handleSelect}
    >
      {product.image ? (
        <img
          src={product.image}
          alt={product.name}
          className={classes.productImage}
        />
      ) : (
        <div className={classes.productImage} />
      )}
      <div className={classes.productInfo}>
        <div className={classes.productInfoItem}>
          {product.sku && (
            <>
              <span className={classes.productKey}>
                Product SKU:
                &nbsp;
              </span>
              <span className={classes.productValue}>
                {product.sku}
              </span>
            </>
          )}
        </div>
        <div className={clsx(classes.productInfoItem, {
          [classes.productInfoItemNoSku]: !product.sku,
        })}
        >
          <span className={classes.productKey}>
            Product Name:
            &nbsp;
          </span>
          <span className={classes.productValue}>
            {product.name}
          </span>
        </div>
      </div>
      <div className={classes.productPrice}>
        <div className={classes.productKey}>
          Price:
        </div>
        <span className={classes.productPriceValue}>
          {product.price ? currencyFormatter.format(product.price) : 'N/A'}
        </span>
      </div>
    </div>
  );
};

export default PublishToPimProduct;
