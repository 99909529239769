import { makeStyles } from '@material-ui/core';

import background from './background.svg';

const useStyles = makeStyles(() => ({
  discountRoot: {
    display: 'flex',
    alignItems: 'center',
    height: 226,
    backgroundImage: `url(${background})`,
    borderRadius: 6,
    fontWeight: 700,
    fontSize: 36,
  },
  wrapper: {
    maxWidth: 212,
    marginLeft: 120,
  },
}));

export default useStyles;
