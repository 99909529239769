import React from 'react';
import { components } from 'react-select';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionText: {
    fontSize: 14,
    lineHeight: '17px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 100,
  },
  optionCheck: {
    width: 17,
    height: 17,
  },
}));

const Option = (props) => {
  const classes = useStyles();
  const { data, isSelected } = props;
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <components.Option {...props}>
      <div className={classes.optionContainer}>
        <span className={classes.optionText}>{data.label}</span>
        {isSelected && <Check className={classes.optionCheck} />}
      </div>
    </components.Option>
  );
};

export default Option;
