import ReactDOM from 'react-dom';
import React from 'react';

import EditorToolsContextWrapper from '../../EditorToolsContextWrapper';

import WordCountButton from './WordCount.button';

const getRandomString = () => Math.random().toString(16).slice(2);

export default class WordCount {
  static get isInline() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
    this.button = null;
    this.count = {
      chars: 0,
      words: 0,
    };
    this.editorJSChangeEventID = getRandomString();
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  surround(range) {} // do nothing for now

  clear() {
    this.button = null;
    this.count = {
      chars: 0,
      words: 0,
    };
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  checkState(selection) {
    const parentEl = document.querySelector('[data-tool="wordCount"]');
    if (parentEl) {
      parentEl.addEventListener('mouseenter', (event) => { event.stopImmediatePropagation(); }, true);
      parentEl.addEventListener('mouseleave', (event) => { event.stopImmediatePropagation(); }, true);
    }
  } // read-only

  render() {
    this.button = document.createElement('div');
    this.renderContainer();

    return this.button;
  }

  onCountChange = (newCount) => {
    this.count = newCount;

    // Dispatch Editor.js change event even without DOM changes.
    this.editorJSChangeEventID = getRandomString();

    this.renderContainer();
  };

  renderContainer() {
    ReactDOM.render(
      (
        <EditorToolsContextWrapper>
          <div data-editorjs-change-event-id={this.editorJSChangeEventID}>
            <WordCountButton
              onCountChange={this.onCountChange}
              count={this.count}
            />
          </div>
        </EditorToolsContextWrapper>
      ),
      this.button,
    );
  }
}
