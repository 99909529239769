import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 320,
    minHeight: 160,
  },
  modalTitleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    ...theme.typography.body2,
    marginBottom: 16,
  },
  header: {
    fontSize: 18,
    marginBottom: 16,
  },
  input: {
    marginBottom: 16,
  },
}));

export default useStyles;
