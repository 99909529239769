import React from 'react';
import { ErrorOutline as ErrorIcon, CreditCard as CreditCardIcon } from '@material-ui/icons';

import Button from '@root/components/buttons/Button';
import { InputField } from '@root/components/form';

import MastercardIcon from './icons/Mastercard.icon';
import VisaIcon from './icons/Visa.icon';
import useStyles from './SavedCard.styles';

const cardIcons = {
  visa: <VisaIcon />,
  mastercard: <MastercardIcon />,
  default: <CreditCardIcon color="inherit" />,
};

const SavedCard = ({ email, lastNumbers, brand = 'default', onChangeClick, error }) => {
  const classes = useStyles();
  const cardValue = `•••• ${lastNumbers}`;

  return (
    <div className={classes.savedCard}>
      {email && (
        <InputField
          label="Email"
          value={email}
          readOnly
        />
      )}
      <InputField
        label="Card information"
        value={cardValue}
        readOnly
        error={error}
        startAdornment={cardIcons[brand] || cardIcons.default}
        endAdornment={error && <ErrorIcon color="error" />}
      />
      <Button
        className={classes.button}
        onClick={onChangeClick}
        variant="link"
      >
        Change
      </Button>
    </div>
  );
};

export default SavedCard;
