import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

const cloneFile = async ({
  fileId, fileTitle, destFolderId, user,
}) => {
  const originalFile = await api.files.getById(fileId);
  const originalFolder = await api.folders.getById(originalFile.folderId);
  const { canViewFolderFiles } = user.permissions.getFolderPermissions(originalFolder);
  const permittedFolderId = canViewFolderFiles ? originalFolder?._id : null;

  const resp = await api.files.create({
    title: fileTitle,
    data: originalFile.data,
    negativeKeywordArray: originalFile.negativeKeywordArray,
    folderId: destFolderId || permittedFolderId,
    templateType: originalFile.templateType,
    returnAxiosResponse: true,
  });

  return {
    ...resp,
    data: resp.data._id,
  };
};

export default function useCloneFile() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      fileId, cloneTitle, destFolderId, user,
    }) => cloneFile({
      fileId,
      fileTitle: cloneTitle,
      destFolderId,
      user,
    }),
    {
      mutationKey: 'useCloneFile',
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries('files.list');
      },
      onMutate: () => {
        // opertunistic UI update before mutationfn is run
      },
    },
  );
}
