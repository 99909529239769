import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateOptions extends RequestOptions {
  fileId: string;
  likeId?: string;
  language: string;
  outputLanguage?: string;
  tone: string;
  type: string;
  engine?: string;
}

export interface GenerateNowOptions extends RequestOptions {
  negativeKeywordArray?: string[];
  data: Record<string, unknown>;
  templateType: string;
}

export interface GenerateImageOptions extends RequestOptions {
  prompt: string;
  n: number;
  size: string;
}

export interface RewriterText extends RequestOptions {
  textToRewrite: string;
  fileType: string;
}

export interface GetAttributesOptions extends RequestOptions {
  templateType: string;
}

export interface CheckPlagiarismOptions extends RequestOptions {
  text: string;
}

export interface PersonalizeOptions extends RequestOptions {
  data: Record<string, unknown>;
  templateType: string;
}

export interface GetTemplateCostOptions extends RequestOptions {
  templateType: string;
  aiOptions: Record<string, unknown>,
}

export interface RemovePersonalizationOptions extends RequestOptions {
  id: string;
}

export interface GenerateByInstruction extends RequestOptions {
  fileId?: string;
  useCase?: string;
  topic?: string;
}

export interface GenerateBlogPost extends RequestOptions {
  data: Record<string, unknown>;
  step: number;
}

export class AI {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async generateContent(data: CreateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generateContent", data, options);

    return result;
  }

  public async generateByInstruction(data: GenerateByInstruction, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generate-by-instruction", data, options);

    return result;
  }

  public async generateContentNow(data: GenerateNowOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generateContentNow", data, options);

    return result;
  }

  public async generateRewriterContent(data: RewriterText, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generateRewriterContent", data, options);

    return result;
  }

  public async generateImage(data: GenerateImageOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generateImage", data, options);

    return result;
  }

  public async getAttributes(data: GetAttributesOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/ai/attributes", data, options);

    return result;
  }

  public async checkPlagiarism(data: CheckPlagiarismOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/checkPlagiarism", data, options);

    return result;
  }

  public async personalize(data: Record<string, string>, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/personalized-copy", data, options);

    return result;
  }

  public async listPersonalizationRules(data: PersonalizeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/ai/personalized-copy", data, options);

    return result;
  }

  public async removePersonalizationRule({ id, ...data }: RemovePersonalizationOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del(`/ai/personalized-copy/${id}`, data, options);

    return result;
  }

  public async generateBlogKickstarter(data: GenerateBlogPost, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/ai/generate-blog-kickstarter/", data, options);

    return result;
  }

  public async getTemplateCost(data: GetTemplateCostOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post(`/ai/${data.templateType}/cost`, data, options);

    return result;
  }
}
