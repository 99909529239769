import { useQuery } from 'react-query';

import api from '@root/api';

const getProducts = async () => {
  const products = await api.akeneo.getChannels();

  return products;
};

export default function useGetChannels() {
  return useQuery(['akeneo.getChannels'], () => getProducts(), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
