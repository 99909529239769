import React from 'react';

import { useTemplatesDashboard, actions, SEARCH_FIELD_TYPES } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import Search from '@root/components/form/SearchField';

import useStyles from './TemplateSearch.styles';

const TemplateSearch = () => {
  const classes = useStyles();
  const { dispatch, state: { searchFieldValue: query } } = useTemplatesDashboard();

  return (
    <div className={classes.search}>
      <Search
        placeholder="Search for a template"
        value={query || ''}
        onChange={(q) => {
          dispatch({
            type: actions.SEARCH,
            payload: {
              type: SEARCH_FIELD_TYPES.SEARCH_FIELD,
              query: q,
            },
          });
        }}
        className={classes.searchBox}
      />
    </div>
  );
};

export default TemplateSearch;
