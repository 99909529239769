import { QueryClientProvider } from 'react-query';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

import { queryClient } from '@root/App';
import newMaterialTheme from '@root/newMaterial.theme';

const ContextWrapper = ({ children }) => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={newMaterialTheme}>
          {children}
        </MuiThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default React.memo(ContextWrapper);
