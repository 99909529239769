import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  editorMain: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    fontSize: 14,
    '& a': {
      color: theme.palette.colors.primary,
    },
  },
  sidebar: {
    display: 'flex',
  },
  editorWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  editorTopBar: {
    borderBottom: `1px solid ${theme.palette.colors.neutrals200}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    height: 52,
    padding: '0 16px',
    zIndex: 10,
  },
  counter: {
    display: 'flex',
    gap: 16,
  },
  rightBlock: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  credits: {
    height: 28,
    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: 6,
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    width: 360,
    backgroundColor: theme.palette.colors.neutrals50,
    borderLeft: `1px solid ${theme.palette.colors.neutrals200}`,
    borderRight: `1px solid ${theme.palette.colors.neutrals200}`,
    display: 'flex',
    flexDirection: 'column',
  },
  tabWrapper: {
    padding: '20px 32px',
  },
  templateSelect: {
    marginBottom: 20,
    '& .MuiInputBase-root': {
      borderRadius: 6,
      fontWeight: 600,
    },
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
