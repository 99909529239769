import { makeStyles, darken } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navbarRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: 56,
    padding: '16px 0',
  },
  icon: {
    borderRadius: 8,
    '&:hover': {
      backgroundColor: darken(theme.palette.colors.primaryFaded, 0.5),
    },
  },
  selected: {
    backgroundColor: theme.palette.colors.primaryFaded,
  },
}));

export default useStyles;
