import React from 'react';
import { Typography } from '@material-ui/core';

import { actions, useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';

import useStyles from './Filters.styles';

const FilterItem = ({ bucket }) => {
  const classes = useStyles();

  const { dispatch, state } = useTemplatesDashboard();

  const { icon: Icon } = bucket;

  const handleItemClick = () => {
    dispatch({ type: actions.CHANGE_BUCKET, payload: bucket.id });
  };

  const isActive = state.bucket === bucket.id;

  return (
    <div
      className={classes.filterItemContainer}
      style={{
        backgroundColor: isActive ? bucket.primaryColor : bucket.secondaryColor,
        color: isActive ? bucket.secondaryColor : bucket.primaryColor,
      }}
      onClick={handleItemClick}
    >
      <Icon />
      <Typography className={classes.filterItemTitle}>{bucket.title}</Typography>
    </div>
  );
};

export default FilterItem;
