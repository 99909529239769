import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    flex: 1,
    display: 'grid',
    placeItems: 'center',
  },
}));

export default useStyles;
