import { useEffect } from 'react';

import { isFirefox } from '../browser.utils';

const INPUT_TAG_NAMES = ['INPUT', 'TEXTAREA'];

export default function useTextSelect(callback, reactRef = null, deps = null, event = 'click') {
  return useEffect(() => {
    const globalClickListener = (e) => {
      const selection = window.getSelection();

      if (reactRef && !reactRef.current.contains(selection.anchorNode)) {
        return;
      }

      if (isFirefox) {
        const inputNode = (INPUT_TAG_NAMES.includes(e.target.tagName) && e.target) || e.target.querySelector('input, textarea');
        if (inputNode) {
          callback({
            ...selection,
            anchorNode: e.target,
            focusNode: e.target,
            toString: () => inputNode
              .value.substring(inputNode.selectionStart, inputNode.selectionEnd),
          });
        }
      }

      callback(selection);
    };

    document.addEventListener(event, globalClickListener);

    return () => {
      document.removeEventListener(event, globalClickListener);
    };
  }, deps);
}
