import PubSub from 'pubsub-js';

import hspService from '@root/services/hsp.service';

const navDisabled = [
  'link_Updates',
];

const profileSidebarDisabledItems = [
  'Referrals',
  'Billing',
];

const profileDropdownDisabledItems = [
  'billing',
];

const profileDropdownDisabledResources = [
  'updates',
];

const hootsuiteEnabledGenerators = [
  'adHeadline',
  'adIdeas',
  'aidaFramework',
  'amazonProductDescription',
  'beforeAfterBridge',
  'brandEssence',
  'carousel',
  'contentImprover',
  'contentRewriter',
  'facebookAd',
  'googleAd',
  'growthIdeas',
  'instagramAd',
  'instagramProductDescription',
  'linkedinAds',
  'nicheContentExpander',
  'painBenefitSolution',
  'productDescription',
  'socialMediaCaptions',
  'tagline',
  'valueProposition',
  'youtubeVideoDescription',
];

const trialEndModal = {
  modalTitle: 'Copysmith for Hootsuite not supported.',
  modalText: 'The Copysmith for Hootsuite app is not supported on your current plan type.  Upgrade your subscription to enable access',
};

const config = {
  name: 'Hootsuite',

  jwtToken: {
    value: 'hootsuite-client-token-value',
    expiresOn: 'hootsuite-client-token-expiresOn',
  },

  toggles: {
    showHootsuitePublishButton: true,
    showExternalSourceTooltip: true,
    disableZapierPublish: true,
    disableGoogleAdPublish: true,
    disableGoogleDocsPublish: true,
    disableShopify: true,
    disableIntegrations: true,
    disableCancelSubscription: true,
    disableAkeneoPublish: true,
    disableEditor: true,
    fixedColumn: true,
    collapsibleSidebar: true,
    formOverlapped: true,
    hideInfileSidebar: true,
    redirectToApp: true,
    hideProfileSidebar: true,
    hideModifyAddonButton: true,
    hideCustomBucket: true,
    hideCreateAndExplore: true,
    instructLandingEnabled: false,
    disableNavHome: true,
  },

  availableCreateModalOptions: ['editor', 'useCase', 'bulkCopy'],

  register: async () => {
    const hspAuth = hsp.authData;
    PubSub.publish('registerExternalSource',
      {
        type: 'hootsuite',
        payload: hspAuth,
      });

    await hspService.saveData(hspAuth);
  },

  unregister: async () => {
    const hspData = await hspService.getData();

    if (!hspData) {
      return;
    }

    PubSub.publish('removeExternalSource',
      {
        type: 'hootsuite',
        payload: hspData,
      });
    await hspService.saveData(null);
  },

  refresh: async ({ isLoggedIn, authService }) => {
    const hspData = await hspService.getData();

    if (isLoggedIn && !hspData) {
      await authService.logOut();
    }

    if (!isLoggedIn && hspData) {
      const result = await authService.refreshExternalSource({
        type: 'hootsuite',
        payload: hspData,
      });
      if (!result.success) {
        await hspService.saveData(null);
      }
    }

    return true;
  },

  isTemplateDisabled: (templateType) => {
    return !hootsuiteEnabledGenerators.includes(templateType);
  },

  navDisabled,
  profileDropdownDisabledItems,
  profileSidebarDisabledItems,
  profileDropdownDisabledResources,
  trialEndModal,
  isTrialEndShowed: ({
    isCancelled, isTrialExpired,
  }) => {
    return isCancelled || isTrialExpired;
  },

  isNeedToSubscribe: () => {
    return false;
  },

  filterGenerators: (generators) => {
    return generators.filter(({ templateType }) => {
      return hootsuiteEnabledGenerators.includes(templateType);
    });
  },

  getPublishMessage: (document) => {
    if (typeof document.content === 'string') {
      return document.content;
    }

    if (Array.isArray(document.content)) {
      return document.content.join(' ');
    }

    if (document.templateType === 'googleAd') {
      return [
        [document.content?.headline1, document.content?.headline2, document.content?.headline3].join(' | '),
        document.content?.description1,
        document.content?.description2,
      ].join('\n');
    }

    return '';
  },

  successLoginRedirectPath: '/templates/my-files',
  homePage: '/templates/my-files',
};

export default config;
