import { useQuery } from 'react-query';

import api from '@root/api';

const getCommunityTemplates = async () => {
  return api.aiTemplate.getCommunityTemplates();
};

export default function useAiTemplates() {
  const queryKey = 'aiTemplates.communityTemplates';

  return useQuery(
    queryKey,
    () => getCommunityTemplates(),
  );
}
