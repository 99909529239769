import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  iconButton: {
    position: 'relative',

    width: '40px',
    height: '40px',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  hintPuls: {
    border: `1px solid ${theme.palette.colors.neutrals200}`,
    borderRadius: '6px',
    height: '40px',
    width: '40px',
    position: 'absolute',
    animation: '$pulsate 1s ease-out',
    animationIterationCount: 'infinite',
    opacity: 0.0,
  },

  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1.0, 1.0)',
      opacity: '0',
    },
    '50%': {
      opacity: '1',
    },
    '100%': {
      transform: 'scale(1.2, 1.2)',
      opacity: '0',
    },
  },

  avatar: {
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    flexShrink: 0,
  },
  menuList: {
    padding: '16px',
    maxWidth: '264px',
    width: '264px',
  },
  menuPaper: {
    borderRadius: '6px',
  },
  menuSectionTitle: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '22px',
    textTransform: 'uppercase',
    marginBottom: '4px',
    padding: '4px 8px',
    letterSpacing: '1px',
  },
  teamSectionTitle: {
    marginBottom: '18px',
    padding: '4px 8px',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    // gap: '8px',
  },
  menuItem: {
    padding: 0,
    color: theme.palette.colors.neutrals400,
    width: '100%',
    borderRadius: '6px',

    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.colors.neutrals50,
      color: theme.palette.colors.neutrals600,
    },
  },
  menuItemText: {
    lineHeight: '22px',
    width: '100%',
    padding: '4px 8px',
  },
  divider: {
    height: '1px',
    width: 'calc(100% - 16px)',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '12px 0',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  teamsTitle: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  membersCounter: {
    minWidth: '22px',
    height: '22px',
    borderRadius: '12px',
    padding: '0px 4px',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.colors.neutrals50,
    color: theme.palette.colors.neutrals400,
  },
}));
