import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Slider } from '@material-ui/core';
import { capitalize } from 'lodash';
import clsx from 'clsx';

import billingResource from '@root/resources/billing';
import { FieldLabel, FormField, InputField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import aiTemplateResource from '@root/resources/aiTemplate';
import aiResource from '@root/resources/ai';
import LoadingGen from '@root/components/Loading/LoadingGen';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './Experiments.styles';

const validationSchema = Yup.lazy((values) => {
  const dynamicShape = Object.keys(values).reduce((obj, key) => {
    obj[key] = Yup.string().required('Field is required');
    return obj;
  }, {});
  return Yup.object().shape(dynamicShape);
});

const Experiments = ({
  template, settings, onSettingsChange,
  getTemplateValues, setTemplate, inputs, disabled,
}) => {
  const classes = useStyles({ inputsCount: inputs.length });

  const [results, setResults] = useState([]);

  const initialValues = inputs.reduce((obj, key) => {
    obj[key] = '';
    return obj;
  }, {});

  const { data: { testGenerations } = {} } = billingResource.useLimits();
  const { currentUser } = AuthContext.useAuth();
  const {
    mutateAsync: createOrUpdateCustom,
  } = aiTemplateResource.useCreateOrUpdateCustomAiTemplate();
  const { mutateAsync: generateNow, isLoading } = aiResource.useGenerateContentNow();

  const isLowCredits = testGenerations <= 5;

  const handleGenerate = async (values) => {
    const saveTemplateValues = {
      ...template,
      ...getTemplateValues(),
      _id: template?._id,
    };

    const result = await createOrUpdateCustom(saveTemplateValues);

    if (!result.errors) {
      if (!saveTemplateValues._id) {
        setTemplate(result);
      }
      const generatedOutput = await generateNow({
        templateType: result.templateType,
        negativeKeywordArray: [],
        data: values,
        aiOptions: result.aiOptions,
        outputLanguage: 'en',
        isExperiment: true,
      });

      setResults(generatedOutput.results.map((output) => ({ text: output.content })));
    }
  };

  const isCreditsUnlimited = currentUser.onSelfServeEnterprisePlan || currentUser.onEnterprisePlan;

  return (
    <div className={classes.experimentsRoot}>
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <div className={classes.leftSide}>
            <div>
              <h3 className={classes.subtitle}>
                Apply settings
              </h3>
              <div className={classes.description}>
                These settings will impact your results on the right.
                You can make changes to the language and length to get your desired result.
              </div>
              <div className={classes.sliders}>
                <div>
                  <FieldLabel label="Creativity" />
                  <Slider
                    valueLabelDisplay="auto"
                    value={settings.temperature}
                    onChange={(e, temperatureValue) => onSettingsChange(
                      (state) => ({ ...state, temperature: temperatureValue }),
                    )}
                    min={0}
                    max={1}
                    step={0.05}
                    marks={[
                      { value: 0, label: 'Simple' },
                      { value: 1, label: 'Creative' },
                    ]}
                    disabled={disabled}
                  />
                </div>
                <div>
                  <FieldLabel label="Max Content Length" />
                  <Slider
                    valueLabelDisplay="auto"
                    value={settings.max_tokens}
                    onChange={(e, maxTokensValue) => onSettingsChange(
                      (state) => ({ ...state, max_tokens: maxTokensValue }),
                    )}
                    min={10}
                    max={400}
                    step={10}
                    marks={[
                      { value: 10, label: 'Very short' },
                      { value: 400, label: 'Long' },
                    ]}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleGenerate}
              validationSchema={validationSchema}
              enableReinitialize
              isInitialValid={false}
            >
              {({ isValid, handleSubmit }) => {
                const isGenerateDisabled = (testGenerations === 0 && testGenerations !== null)
                  || disabled
                  || !isValid
                  || isLoading;
                return (
                  <Form className={classes.form}>
                    <div className={classes.gapWrapper}>
                      {inputs.map((input) => {
                        return (
                          <FormField
                            key={input}
                            name={input}
                            label={capitalize(input)}
                            component={InputField}
                            disabled={disabled}
                            required
                            inputProps={{ maxLength: 400 }}
                          />
                        );
                      })}
                    </div>
                    <Button
                      onClick={handleSubmit}
                      disabled={isGenerateDisabled}
                      className={classes.generateButton}
                    >
                      Generate
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className={classes.rightSide}>

            {isLoading ? (
              <div className={classes.loadingContainer}>
                <LoadingGen />
              </div>
            ) : (
              <>
                <div className={classes.subtitleBlock}>
                  <h3 className={classes.subtitle}>
                    Sample outputs
                  </h3>
                  <div className={clsx(classes.creditsCount, isLowCredits && classes.lowCredits)}>
                    Test credits:&nbsp;
                    {isCreditsUnlimited ? 'Unlimited' : testGenerations}
                  </div>
                </div>

                <div className={classes.gapWrapper}>
                  {results.map((result) => {
                    return (
                      <div
                        className={classes.result}
                        key={result.text}
                      >
                        {result.text}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default Experiments;
