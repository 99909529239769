import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

const CheckboxField = ({
  formik,
  name,
  className,
  helperText,
  label,
}) => {
  // Get deep value, e.x. uiOptions.type
  const parts = name.split('.');
  let value = formik.values;
  parts.forEach((p) => {
    value = value[p] ? value[p] : '';
  });

  const text = (formik.touched[name] && formik.errors[name]) || helperText;

  return (
    <div>
      <FormControlLabel
        className={className}
        control={(
          <Checkbox
            color="primary"
            checked={value}
            onChange={formik.handleChange}
            id={name}
            name={name}
          />
        )}
        label={label}
      />
      <FormHelperText error={!!text}>{text}</FormHelperText>
    </div>
  );
};

export default CheckboxField;
