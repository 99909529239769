import React from 'react';
import Select from 'react-select';

import fileContext from '@root/resources/file/file.context';
import Option from '@root/views/File/components/SelectComponents/components/Option';
import CustomDropdownIndicator from '@root/views/File/components/SelectComponents/components/CustomDropdownIndicator';
import SingleValue from '@root/views/File/components/SelectComponents/FilterSelect/components/SingleValue';
import customStyles from '@root/views/File/components/SelectComponents/FilterSelect/FilterSelect.styles';

const statusFilterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Not assigned', value: 'notAssigned' },
  { label: 'Assigned', value: 'assigned' },
];

const FilterSelect = () => {
  const {
    statusFilter,
    setStatusFilter,
  } = fileContext.useFile();
  const selectedOption = statusFilterOptions.find(({ value }) => value === statusFilter);

  const handleStatusFilterChange = ({ value }) => {
    setStatusFilter(value);
  };

  return (
    <Select
      isSearchable={false}
      styles={customStyles}
      options={statusFilterOptions}
      onChange={handleStatusFilterChange}
      value={selectedOption}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        Option,
        SingleValue,
      }}
    />
  );
};

export default FilterSelect;
