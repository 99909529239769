import React, { useState } from 'react';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core';

import Pill from '@root/components/Fields/PillAtom';

import InputField from '../InputField';

const useStyles = makeStyles(() => ({
  pillContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    marginTop: 8,
  },
  pill: {
    margin: 0,
    borderRadius: 6,
  },
}));

const KeywordsField = (props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const { disabled, name } = props;
  const [field, /* meta */, helpers] = useField(props);
  const { value: array } = field;
  const { setValue: setArray } = helpers;

  const pushInputValue = () => {
    if (inputValue.trim().length > 0) {
      const splitValues = inputValue
        .split(',')
        .map((s) => s.trim());
      const newArray = Array.from(new Set([...array, ...splitValues]));
      setArray(newArray);
      setInputValue('');
    }
  };

  const removeArrayValue = (removeValue) => {
    const filteredArray = array.filter((item) => item !== removeValue);
    setArray(filteredArray);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      pushInputValue();
    }
  };

  return (
    <div>
      <InputField
        {...props}
        onChange={handleChange}
        onBlur={pushInputValue}
        onKeyDown={handleEnter}
        value={inputValue}
      />

      <div className={classes.pillContainer}>
        {array && array.map((keyword) => {
          const warning = name === 'data.negativeKeywordArray' && keyword.includes(' ')
            ? 'You may notice more consistent and quality generations if you target single keywords instead of using a phrase.'
            : undefined;
          return (
            <Pill
              name={keyword}
              key={keyword}
              className={classes.pill}
              handleRemove={removeArrayValue}
              disabled={disabled}
              warning={warning}
              isInfoSupported={name === 'data.negativeKeywordArray'}
            />
          );
        })}
      </div>

    </div>
  );
};

export default KeywordsField;
