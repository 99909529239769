import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  templateButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 2,
  },
  templateGenerateButton: {
    display: 'flex',
    flexGrow: 2,
    width: 250,
  },
  arrowIcon: {
    fontSize: 18,
  },
  iconWrap: {
    paddingLeft: 8,
  },
  popover: {
  },
  menu: {
    width: 326,
    borderRadius: 8,
    background: 'white',
    border: '1px solid #C4C4C4',
    padding: 0,
  },
  templateTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    flexGrow: 2,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 16,

    '&:hover': {
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemText-secondary': {
        color: theme.palette.primary.main,
        maxHeight: 100,
        transition: 'all 500ms ease 200ms',

      },
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  menuItemText: {
    whiteSpace: 'normal',
    margin: 0,
    lineHeight: 1,

    '& > .MuiListItemText-secondary': {
      fontWeight: 400,
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'all 200ms',
    },

    '& > .MuiListItemText-primary': {
      fontWeight: 500,
    },

    '&:hover': {
      '& > .MuiListItemText-secondary': {
      },
    },
  },
}));

export default useStyles;
