import React from 'react';
import {
  Backdrop, Fade, Modal,
} from '@material-ui/core';

import BulkMoveFileForm from './BulkMoveFileForm';
import useStyles from './BulkMoveFileModal.styles';

const BulkMoveFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen} className={classes.fullHeight}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '450px',
            margin: '0 auto',
            outline: 0,
          }}
          // For some reason I can't refactor the above styles to the MUI format
        >
          <BulkMoveFileForm closeModal={closeModal} />
        </div>
      </Fade>
    </Modal>
  );
};

export default BulkMoveFileModal;
