import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  filtersContainer: {
    marginBottom: 32,

    display: 'flex',
    gap: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterItemContainer: {
    padding: '8px 12px',
    borderRadius: 6,

    display: 'flex',
    alignItems: 'center',
    gap: 8,

    cursor: 'pointer',
  },
  activeFilterItem: {
    outline: '1px solid red',
  },
  filterItemTitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22px',
  },
});

export default useStyles;
