import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
  },
  gapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  topSection: {
    padding: '20px 32px',
    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,
  },
  row: {
    display: 'flex',
    gap: 16,
    '& > *': {
      flex: 1,
    },
  },
  scrollableArea: {
    flex: 1,
    overflowY: 'auto',
  },
  fieldsWrapper: {
    padding: '20px 32px',
    borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,
  },
  button: {
    alignSelf: 'center',
    minWidth: 112,
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'sticky',
    bottom: 0,
    margin: '16px 0',
  },
  additionalSettings: {
    '& > *': {
      padding: '12px 32px',
      borderBottom: `1px solid ${theme.palette.colors.neutrals100}`,

      '&:first-child': {
        borderTop: `1px solid ${theme.palette.colors.neutrals100}`,
      },
    },
  },
  buttonTooltipWrap: {
    textAlign: 'center',
  },
}));
export default useStyles;
