import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem, Fade, makeStyles } from '@material-ui/core';
import { Add, ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';

import { useDashboard, selectionTypes } from '@root/views/Dashboard/dashboardContext';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import authContext from '@root/resources/auth/auth.context';
import useFolder from '@root/resources/folder/useFolder';

import {
  NEW_FOLDER_MODAL_ID,
  USE_CASE_MODAL_ID,
  BULK_GENERATE_MODAL_ID,
  NEW_PROJECT_MODAL_ID,
  NOTIFICATION_MODAL_ID,
} from './Modals';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '0.875rem',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#7561EC',
    },
    padding: '4px 6px',
  },
  isOpen: {
    background: '#B1A7F1',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
  },
  menuIcon: {
    fontSize: '1.2rem',
  },
  mr: {
    marginRight: '0.25rem',
  },
  ml: {
    marginLeft: '0.25rem',
  },
  mr2: {
    marginRight: '0.5rem',
  },
  ml2: {
    marginLeft: '0.5rem',
  },
  paper: {
    boxShadow: '0px 0px 4px 0px #00000040',
  },
}));

const CreateButton = ({ isSharableFolder }) => {
  const history = useHistory();

  const { state: { filters: { folderId }, selected: { type } } } = useDashboard();
  const { dispatch } = useGlobalModals();

  const { data: folder } = useFolder(folderId);
  const [anchorEl, setAnchorEl] = useState(null);

  const { currentUser } = authContext.useAuth();
  const isOpen = !!anchorEl;

  const classes = useStyles();

  const showBulkExport = currentUser.permissions.bulkUploadCsv;
  const isSubFolder = type === selectionTypes.FOLDER && folder?.parentFolderId !== null;
  const isProject = type === selectionTypes.FOLDER && !isSubFolder;

  const createFileAllowed = useMemo(() => {
    const userFolderRoles = currentUser.roles.getFolderRoles(folder);
    return !folder || userFolderRoles.editor || userFolderRoles.owner;
  }, [folder, currentUser]);

  const createFolderDisabled = isSubFolder
    || type === selectionTypes.UNCATEGORIZED
    || type === selectionTypes.ALL_FILES
    || type === selectionTypes.BOOKMARKS
    || type === selectionTypes.WORKFLOW;

  const createProjectDisabled = isSubFolder
    || isProject
    || type === selectionTypes.WORKFLOW;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateFolder = () => {
    handleClose();

    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: NEW_FOLDER_MODAL_ID,
        modalContent: { parentFolderId: folder ? folder._id : null },
      },
    });
  };

  const handleCreateProject = () => {
    handleClose();

    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: NEW_PROJECT_MODAL_ID,
      },
    });
  };

  const handleCreateFile = () => {
    handleClose();
    dispatch({
      type: actions.OPEN_MODAL, payload: { modalId: USE_CASE_MODAL_ID },
    });
  };

  const handleBulkImport = () => {
    handleClose();

    dispatch({
      type: actions.OPEN_MODAL, payload: { modalId: BULK_GENERATE_MODAL_ID },
    });
  };

  const openSharingNotificationModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: NOTIFICATION_MODAL_ID,
        modalContent: {
          title: 'New shared file',
          description: 'You are about to create a new file in a shared folder. This means that it can be accessed by everyone within this folder.',
          onSubmit: handleCreateFile,
        },
      },
    });
  };

  const handleBulkCopy = () => {
    history.push('/bulk-copy/new');
  };

  return (
    <div>
      <Button
        disableElevation
        variant="contained"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClickOpen}
        className={clsx(classes.button, { [classes.isOpen]: isOpen })}
      >
        <Add className={clsx(classes.icon, classes.mr)} />
        Create
        <ArrowDropDown className={clsx(classes.icon, classes.ml)} />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem
          disabled={!createFileAllowed}
          onClick={isSharableFolder ? openSharingNotificationModal : handleCreateFile}
        >
          File
        </MenuItem>
        <MenuItem disabled={createFolderDisabled} onClick={handleCreateFolder}>
          Folder
        </MenuItem>
        <MenuItem disabled={createProjectDisabled} onClick={handleCreateProject}>
          Project
        </MenuItem>
        {showBulkExport && (
          <MenuItem onClick={handleBulkImport}>
            Bulk import CSV
          </MenuItem>
        )}
        <MenuItem onClick={handleBulkCopy}>
          Bulk Copy
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CreateButton;
