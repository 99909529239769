import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Delete, VisibilityOutlined } from '@material-ui/icons';
import { DateTime } from 'luxon';

import aiTemplatesResource from '@root/resources/aiTemplate';
import config from '@root/config';
import Badge from '@root/components/Badges/NewBadge';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import uiNotificationService from '@root/services/uiNotification.service';
import TemplatesContext from '@root/resources/templates/templates.context';

import useStyles from './CustomTemplatesTable.styles';

const getColorByStatus = (status) => {
  switch (status) {
    case 'draft':
      return 'grey';
    case 'submitted':
      return 'primary';
    case 'live':
      return 'success';
    case 'deleted':
      return 'error';
    default:
      return 'primary';
  }
};

const CustomTemplatesTable = ({ filters }) => {
  const classes = useStyles();
  const { data: templatesData } = aiTemplatesResource.useAiTemplates({ custom: true });
  const { mutateAsync: deleteAiTemplate } = aiTemplatesResource.useDeleteAiTemplate();
  const { loadTemplates } = TemplatesContext.useTemplates();

  const [templates, setTemplates] = useState([]);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const openDelete = (template) => {
    setTemplateToDelete(template);
  };
  const closeDelete = () => {
    setTemplateToDelete(null);
  };

  useEffect(() => {
    if (!templatesData) {
      return;
    }

    let filteredTemplates = templatesData;
    switch (filters.status) {
      case 'draft':
        filteredTemplates = templatesData.filter((t) => t.status === 'draft' && !t.deletedOn);
        break;
      case 'submitted':
        filteredTemplates = templatesData.filter((t) => t.status === 'submitted' && !t.deletedOn);
        break;
      case 'live':
        filteredTemplates = templatesData.filter((t) => t.status === 'live' && !t.deletedOn);
        break;
      case 'deleted':
        filteredTemplates = templatesData.filter((t) => t.deletedOn);
        break;
      default:
    }
    setTemplates(filteredTemplates);
  }, [templatesData, filters]);

  const shadowLogin = async (userId) => {
    if (!userId) {
      return;
    }

    const redirectUrl = `${config.retool.userManagementUrl}#userId=${userId}`;

    window.open(redirectUrl, '_blank');
  };

  const deleteTemplate = async () => {
    if (!templateToDelete) {
      return;
    }

    const { isBadRequest } = await deleteAiTemplate(templateToDelete._id);
    if (!isBadRequest) {
      closeDelete();
      loadTemplates();
      uiNotificationService.showSuccessMessage('Generator was successfully deleted');
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>User email</TableCell>
            <TableCell>Template status</TableCell>
            <TableCell>Submission date</TableCell>
            <TableCell>Usage (files/docs)</TableCell>
            <TableCell>Shadow login</TableCell>
            { filters.status !== 'deleted' && (<TableCell>Delete</TableCell>) }
          </TableRow>
        </TableHead>

        <TableBody>
          {templates
            .sort((a, b) => new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime())
            .map((template) => {
              const { _id, title, submittedOn, deletedOn, user } = template;

              const isDeleted = !!deletedOn;
              // not use real status just to allow restore previous status when needed
              const status = isDeleted ? 'deleted' : template.status;

              return (
                <TableRow key={template._id}>
                  <TableCell>
                    <RouterLink
                      to={`/super-admin/templates/${_id}`}
                    >
                      {title}
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    {user?.email ? user.email : (
                      <span>-</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <Badge
                      content={status.toUpperCase()}
                      color={getColorByStatus(status)}
                      display="inline-block"
                    />
                  </TableCell>
                  <TableCell>
                    {submittedOn ? DateTime.fromISO(submittedOn).toFormat('MM/dd/yyyy') : '-'}
                  </TableCell>
                  <TableCell>
                    {template.filesCount > 0 || template.documentsCount > 0 ? (
                      <span>{`${template.filesCount} / ${template.documentsCount}`}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {template.userId && (
                      <IconButton
                        onClick={() => shadowLogin(template.userId)}
                      >
                        <VisibilityOutlined />
                      </IconButton>
                    )}
                  </TableCell>
                  { filters.status !== 'deleted' && !isDeleted && (
                    <TableCell>
                      <IconButton onClick={() => openDelete(template)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}

          {templateToDelete && (
            <ConfirmationModal
              title={`Are you sure you want to delete ${templateToDelete.title}?`}
              confirmButtonText="Delete"
              negative
              onConfirm={deleteTemplate}
              onClose={closeDelete}
            >
              Developers can recover this template if needed
            </ConfirmationModal>
          )}

          {templates.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <span>Nothing here</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTemplatesTable;
