import React from 'react';

import { FieldLabel } from '@root/components/form';

import KeywordsField from './KeywordsField';
import MultilineStringField from './MultilineStringField';
import SingleSelectField from './SingleSelectFIeld';
import StringField from './StringField';

const FileField = ({
  field,
  fieldValue,
  templateType,
  onDataChange,
  className,
  disabled,
  short,
}) => {
  let Field = null;
  const onFieldChange = (arg) => {
    let value = '';

    if (field.name === 'negativeKeywordArray') {
      value = Array.isArray(arg) ? arg : arg(fieldValue);
    } else if (field.name === 'language') {
      value = arg.value;
    } else {
      switch (field.type) {
        case 'string':
        case 'multilineString':
          value = arg.target.value;
          break;
        case 'keywords':
          value = Array.isArray(arg) ? arg : arg(fieldValue);
          break;
        default:
          value = arg.value;
          break;
      }
    }

    onDataChange({
      name: field.name,
      value,
    });
  };

  // TODO: remove this after language removed from all templates
  if (field.name === 'language') {
    return null;
  }

  let FieldComponent = null;

  const isCustom = templateType.includes('custom_');

  const getFieldType = () => {
    if (isCustom) {
      return 'multilineString';
    }

    return field.type;
  };

  const fieldType = getFieldType();

  switch (fieldType) {
    case 'singleSelect':
      FieldComponent = SingleSelectField;
      break;
    case 'string':
      FieldComponent = StringField;
      break;
    case 'keywords':
      FieldComponent = KeywordsField;
      break;
    case 'multilineString':
      FieldComponent = MultilineStringField;
      break;
    default:
      break;
  }

  if (FieldComponent) {
    Field = (
      <FieldComponent
        field={field}
        fieldValue={fieldValue}
        onChange={onFieldChange}
        templateType={templateType}
        isSmall={isCustom && fieldValue?.length < 80}
        disabled={disabled}
        short={short}
      />
    );
  }

  return (
    <div className={className}>
      <FieldLabel
        label={field.label}
        labelTooltip={field.helper}
        name={field.name}
        required={field.required}
      />
      {Field}
    </div>
  );
};

export default FileField;
