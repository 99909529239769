/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect, useMemo, useContext, useRef, useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import useFlaggedDocuments from '@root/views/File/hooks/useFlaggedDocuments';
import useAtomRefs from '@root/views/File/hooks/useAtomRefs';
import useCheckedDocuments from '@root/views/File/hooks/useCheckedDocuments';
import useCheckedExport from '@root/views/File/hooks/useCheckedExport';
import useCheckedMutations from '@root/views/File/hooks/useCheckedMutations';
import useTitle from '@root/views/File/hooks/useTitle';
import useDocumentContent from '@root/views/File/hooks/useDocumentContent';
import useGenerate from '@root/views/File/hooks/useGenerate';
import useSaveAndExit from '@root/views/File/hooks/useSaveAndExit';
import {
  buildLocalFile, getFileData, getPrevDataObject, isLocalFileEqualsPrevData,
} from '@root/utils/file.utils';
import fileResource from '@root/resources/file';
import folderResource from '@root/resources/folder';
import documentResource from '@root/resources/document';
import billingResource from '@root/resources/billing';
import TemplatesContext from '@root/resources/templates/templates.context';
import aiResource from '@root/resources/ai';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';
import authContext from '@root/resources/auth/auth.context';
import engineResource from '@root/resources/engine';
import useModalState from '@root/utils/hooks/useModalState';
import uiNotificationService from '@root/services/uiNotification.service';

import { filterByWorkflowStatus } from './file.helpers';
import workflowQueueContext from './workflowQueue.context';

const BOOSTE_LEGACY_TEMPLATE_TYPES = ['blogPost'];

const FileContext = createContext();

const FileProvider = ({
  children, fileId, template,
}) => {
  const history = useHistory();

  const { data: file, refetch: reloadData, isLoading: isFileLoading } = fileResource.useFile(fileId);
  const { data: folder } = folderResource.useFolder(file && file.folderId);
  const { data: parentFolder } = folderResource.useFolder(folder && folder.parentFolderId);

  const [prevData, setPrevData] = useState({ obj: {}, raw: {} });
  const [highlightedDocumentIndex, setHighlightedDocumentIndex] = useState(0);
  const [integrationOpen, setIntegrationOpen] = useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [lastHighlightedTextId, setLastHighlightedTextId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const contentRef = useRef(null);

  const [isMagicLoading, setIsMagicLoading] = useState(false);
  const [lastHighlightedText, setLastHighlightedText] = useState(null);
  const [showChar, setShowChar] = useState(true);
  const [showAutoSaveMessage, setShowAutoSaveMessage] = useState(false);
  const [formHidden, setFormHidden] = useState(false);

  const {
    assignedToId, setAssignedToId,
    workflowStatus, setWorkflowStatus,
    workflowStatusError, setWorkflowStatusError,
    assignedToError, setAssignedToError,
  } = workflowQueueContext.useWorkflowQueue();

  const [statusFilter, setStatusFilter] = useState('all');

  const [localFile, setLocalFile] = useState(file && buildLocalFile(file));

  const [isRewriterLoading, setIsRewriterLoading] = useState(false);

  const [isExampleOpen, openExample, closeExample] = useModalState();

  const { templates } = TemplatesContext.useTemplates();
  // const template = templates[templateType];

  const { mutateAsync: updateDocument } = documentResource.useUpdateDocument();
  const { mutateAsync: deleteDocument } = documentResource.useDeleteDocument();
  const { mutateAsync: likeDocument } = documentResource.useLikeDocument();
  const { mutateAsync: flagDocument } = documentResource.useFlagDocument();
  const { mutateAsync: updateFile } = fileResource.useUpdateFile();
  const { mutateAsync: generateContent } = aiResource.useGenerateContent();
  const { mutateAsync: generateRewriterContent } = aiResource.useGenerateContentNow();
  const { data: engineData } = engineResource.useEngines();
  const { currentExternalSource } = externalSourcesContext.useExternalSource();
  const { data: documents = [] } = documentResource.useDocuments({ fileId });

  const { currentUser, currentCompany } = authContext.useAuth();
  const filePermissions = currentUser.permissions.getFilePermissions(file, folder);
  const { canManageDocuments, canEditFile } = filePermissions;
  const hasTeamPermissions = currentUser.permissions.teams;

  const isBoosteLegacyTemplateType = BOOSTE_LEGACY_TEMPLATE_TYPES.includes(template.templateType);

  const { hideInfileSidebar, formOverlapped } = currentExternalSource.toggles;

  const templateAiOptions = template.aiOptionsByPlan?.[currentCompany.billing.currentLimitId]
    || template.aiOptions;

  const { hasInstruction, deletedOn: templateDeleted } = template;
  const initialAIOptions = {
    engine: file?.engine || 'default',
    temperature: templateAiOptions.temperature,
    ...(
      // Hack for trial users
      ['contentImprover'].includes(template.templateType) && {
        max_tokens: templateAiOptions.max_tokens,
      }
    ),
    ...(hasInstruction && {
      max_tokens: templateAiOptions.max_tokens,
      n: templateAiOptions.n,
    }),
  };

  const {
    flaggedDocumentsIds,
    initialFlaggedDocumentsIds,
    toggleFlagState,
  } = useFlaggedDocuments(documents);

  const handleStatusFilterChange = ({ value }) => {
    setStatusFilter(value);
  };

  const filteredDocuments = useMemo(() => {
    return filterByWorkflowStatus({ documents, statusFilter, flaggedDocumentsIds });
  }, [statusFilter, flaggedDocumentsIds, documents]);

  useBeforeUnload({
    when: (flaggedDocumentsIds.length && !localFile?.assignedToId)
      || (localFile?.assignedToId && !localFile?.workflowStatus),
    message: 'Are you sure you want to leave?',
  });

  useBeforeUnload({
    when: flaggedDocumentsIds.length && !localFile?.assignedToId,
    message: 'Are you sure you want to leave?',
  });

  const atomRefs = useAtomRefs({ documents, highlightedDocumentIndex, contentRef });

  const updateLocalFile = (data) => {
    if (data.workflowStatus) {
      setWorkflowStatusError(null);
    }
    setLocalFile({
      ...localFile,
      ...data,
    });
  };

  const saveLocalFile = (data) => {
    const f = buildLocalFile(data, { initialAIOptions });
    setLocalFile(f);
    return f;
  };

  const handleDataChange = (...args) => {
    const valuesObject = args.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }), {},
    );
    updateLocalFile(valuesObject);
  };

  const savePrevData = () => {
    setPrevData(getPrevDataObject(localFile));
  };

  const {
    selectAll,
    deselectAll,
    toggleCheckState,
    checkedDocumentsIds,
    isAllSelected,
  } = useCheckedDocuments(filteredDocuments);
  const isAllSelectedFavorited = checkedDocumentsIds
    .map((docId) => documents.find((d) => d.id === docId))
    .filter((d) => d && !d.liked)
    .length === 0;

  const {
    likeCheckedDocuments,
    unlikeCheckedDocuments,
    deleteCheckedDocuments,
  } = useCheckedMutations({
    checkedDocumentsIds,
    documents,
    deselectAll,
    likeDocument,
    fileId,
    deleteDocument,
  });

  const {
    downloadSelectedAsCsv,
    downloadSelectedAsDocx,
    downloadSelectedAsPdf,
    downloadSelectedAsTxt,
  } = useCheckedExport({
    checkedDocumentsIds,
    documents,
    config: template,
    localFile,
  });

  const {
    onTitleChange,
    updateTitle,
  } = useTitle({
    setLocalFile,
    localFile,
    updateFile,
    saveLocalFile,
    fileId,
  });

  // const {
  //   editingDocumentId,
  //   editDocument,
  //   expandContent,
  //   rewriteContent,
  //   copyDocumentContent,
  // } = useDocumentContent({
  //   generateRewriterContent,
  //   updateDocument,
  //   documents,
  //   setModalData,
  //   template,
  //   lastHighlightedText,
  //   lastHighlightedTextId,
  //   fileId,
  //   maxRewriteLength,
  //   isRewriterLoading,
  //   setIsRewriterLoading,
  //   setLastHighlightedText,
  //   classes,
  // });

  const generate = useGenerate({
    config: template,
    localFile,
    setErrorMessage,
    updateFile,
    savePrevData,
    saveLocalFile,
    generateContent,
    engineData,
    setIsMagicLoading,
    isLocalFileEqualsPrevData,
    prevData,
    fileId,
    getFileData,
    templateType: template.templateType,
  });

  const saveAndExit = useSaveAndExit({
    isLocalFileEqualsPrevData,
    localFile,
    prevData,
    updateFile,
    canManageDocuments,
    backUrl: '/templates',
    flaggedDocumentsIds,
    history,
    initialFlaggedDocumentsIds,
    setAssignedToError,
    setWorkflowStatusError,
    fileId,
    getFileData,
    config: template,
    flagDocument,
  });

  let fields = template.fieldsByByPlan
    ? template.fieldsByByPlan[currentCompany.billing.currentLimitId] || template.fields
    : template.fields;

  fields = fields.map((field) => {
    const { creditCostByContentLength } = template;

    if (!creditCostByContentLength) {
      return field;
    }
    if (creditCostByContentLength.fieldKey !== field.name) {
      return field;
    }

    return {
      ...field,
      creditCosts: creditCostByContentLength.thresholds,
    };
  });

  const autoSave = async () => {
    if (!canEditFile) {
      return;
    }

    if (isLocalFileEqualsPrevData(localFile, prevData)) {
      return;
    }

    const {
      negativeKeywordArray,
      language,
      outputLanguage,
      engine,
      aiOptions,
      ...d
    } = localFile;
    const newFile = await updateFile({
      fileId,
      data: {
        data: getFileData(d, template),
        negativeKeywordArray,
        language,
        ...(outputLanguage && { outputLanguage }),
        engine,
      },
    });

    setShowAutoSaveMessage(true);
    savePrevData();
    saveLocalFile({
      ...newFile,
      workflowStatus,
      assignedToId,
      aiOptions: { ...aiOptions, engine },
    });
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileData = {
      ...file,
      aiOptions: file.aiOptions || initialAIOptions,
    };
    saveLocalFile(fileData);
  }, [file]);

  useEffect(() => {
    if (!localFile || prevData.obj._id) {
      return;
    }

    savePrevData();
  }, [localFile]);

  useEffect(() => {
    if (showAutoSaveMessage) {
      setTimeout(() => {
        setShowAutoSaveMessage(false);
      }, 5000);
    } // to hide save notif
  }, [showAutoSaveMessage]);

  const handleOnIdle = async () => {
    if (canEditFile) {
      autoSave();
    }
  };

  useIdleTimer({
    timeout: 30 * 1000, // set timeout to 30 seconds
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // useEffect(() => {
  //   if (integration?.formHidden) {
  //     setFormHidden(false);
  //   }
  // }, [integration?.formHidden]);

  useEffect(() => {
    const userId = localFile?.assignedToId;
    if (userId) {
      setAssignedToId(userId);
    }
  }, [localFile?.assignedToId]);

  useEffect(() => {
    const status = localFile?.workflowStatus;
    if (status) {
      setWorkflowStatus(status);
    }
  }, [localFile?.workflowStatus]);

  React.useEffect(() => {
    if (!isFileLoading && !file) {
      history.push('/create');
      uiNotificationService.showErrorMessage('File not found');
    }
  }, [file, isFileLoading]);

  return (
    <FileContext.Provider
      value={{
        template,
        fileId,
        fields,
        errorMessage,

        localFile,
        prevData,
        updateLocalFile,
        saveLocalFile,
        handleDataChange,
        savePrevData,
        autoSave,
        saveAndExit,

        filePermissions,

        isMagicLoading,

        documents,
        filteredDocuments,
        isNoDocs: documents.length === 0,

        generate,

        downloadSelectedAsCsv,
        downloadSelectedAsDocx,
        downloadSelectedAsPdf,
        downloadSelectedAsTxt,

        flaggedDocumentsIds,
        initialFlaggedDocumentsIds,
        toggleFlagState,
        isDocumentsFlagged: !!flaggedDocumentsIds.length,

        selectAll,
        deselectAll,
        toggleCheckState,
        checkedDocumentsIds,
        isAllSelected,
        likeCheckedDocuments,
        unlikeCheckedDocuments,
        deleteCheckedDocuments,
        isAllSelectedFavorited,

        isExampleOpen,
        openExample,
        closeExample,

        statusFilter,
        setStatusFilter,

        atomRefs,

        onTitleChange,
        updateTitle,

        formHidden,
        setFormHidden,

        contentRef,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

function useFile() {
  const context = useContext(FileContext);
  return context;
}

export default { FileProvider, useFile };
