import { makeStyles, lighten, darken } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  hintBlockRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 25,
    left: '50%',
    transform: 'translate(-50%, 0)',
    color: theme.palette.colors.white,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 4,
    padding: '7px 7px 7px 16px',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  selected: {
    backgroundColor: theme.palette.colors.success,
  },
  content: {
    '& > :not(:last-child)': {
      marginRight: 8,
    },
  },
  buttonsBlock: {
    display: 'flex',
    gap: 8,
    marginLeft: 64,
  },
  generateButtonSelected: {
    backgroundColor: theme.palette.colors.success,
    border: `1.5px solid ${theme.palette.colors.white}`,
    '&:hover': {
      backgroundColor: lighten(theme.palette.colors.success, 0.2),
    },
  },
  continueButtonSelected: {
    color: theme.palette.colors.success,
    backgroundColor: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: darken(theme.palette.colors.white, 0.1),
    },
  },
}));

export default useStyles;
