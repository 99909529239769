import PubSub from 'pubsub-js';

// Event aggregator is used to publish UI events across the app
// Event aggregator help with two things:
// 1. Make sure analytical services (intercom, mixpanel, segment) are
//    using the same events and not spread across app
// 2. Use events aggregator to decouple different UI components

const EVENT_NAMES = {
  PAGE_VISITED: 'Global.PAGE-VISITED',
  PLAGIARISM_CHECKED: 'Global.PLAGIARISM-CHECKED',
  SIGNUP_FORM_COMPLETED: 'Global.SIGNUP-FORM-COMPLETED',
  CAMPAIGN_CREATED: 'Global.CAMPAIGN-CREATED',
  COMPANY_INFO_FORM_COMPLETED: 'Global.COMPANY-INFO-FORM-COMPLETED',
  SUGGESTED_TEMPLATES_VIEW_ALL_TEMPLATES_SELECTED: 'Global.SUGGESTED-TEMPLATES-VIEW-ALL-TEMPLATES-SELECTED',
  SUGGESTED_TEMPLATES_RECOMMENDED_TEMPLATE_SELECTED: 'Global.SUGGESTED-TEMPLATES-RECOMMENDED-TEMPLATE-SELECTED',
  SUGGESTED_TEMPLATES_MODAL_CLOSED: 'Global.SUGGESTED-TEMPLATES-MODAL-CLOSED',
  GENERATION_COPIED_TO_CLIPBOARD: 'Global.GENERATION-COPIED-TO-CLIPBOARD',
  GENERATE_BUTTON_CLICKED: 'Global.GENERATE-BUTTON-CLICKED',
  FRASE_SEARCH_QUERY_PROCESSED: 'Global.FRASE-SEARCH-QUERY-PROCESSED',
  FRASE_KEYWORDS_PROCESSED: 'Global.FRASE-KEYWORDS-PROCESSED',
  SUBSCRIBE_BUTTON_CLICKED: 'Global.SUBSCRIBE-BUTTON-CLICKED',
  PURCHASE_COMPLETED: 'Global.PURCHASE-COMPLETED',
  VIEW_ALI_EXPERT_VOICES_CLICKED: 'Global.VIEW_ALI_EXPERT_VOICES_CLICKED',
  FINE_TUNE_SELECTED: 'Global.FINE_TUNE_SELECTED',
};

const publish = (eventName, data) => {
  PubSub.publish(eventName, data);
};

const subscribe = (eventName, fn) => {
  PubSub.subscribe(eventName, fn);
};

export {
  EVENT_NAMES,
};

export default {
  publish,
  subscribe,
};
