import React from 'react';
import clsx from 'clsx';
import { Slider, Tooltip } from '@material-ui/core';

import PlanInfo from '@root/views/Profile/Plans/components/PlanInfo';
import PlanButton from '@root/views/Profile/Plans/components/PlanButton';
import AuthContext from '@root/resources/auth/auth.context';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';
import { WORDS_MAX_CAP } from '@root/resources/billing/billingInfo.helpers';

import useStyles from './PlanCard.styles';

const ValueLabelComponent = (props) => {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const PlanCard = ({ period, plan }) => {
  const { currentCompany } = AuthContext.useAuth();
  const { allPricing, setTier } = SubscriptionsContext.useSubscriptions();

  const isStarter = plan.internalName === 'starter';
  const isPro = plan.internalName === 'pro';
  const { data: { limits } } = plan;
  const { mainCredits, plagiarismChecks, members } = limits;
  const isSuspended = currentCompany.suspendStart;
  const classes = useStyles({ isSuspended });

  const onTierSelect = (event, tier) => setTier({
    tier: Math.round(tier),
    rank: plan.rank,
  });

  const renderFeatures = () => {
    if (isStarter || isPro) {
      return (
        <>
          <div className={classes.featureRow}>
            <span className={classes.semibold}>{mainCredits === WORDS_MAX_CAP ? 'Unlimited' : mainCredits}</span>
            &nbsp;words
            <br />
          </div>
          <div className={classes.featureRow}>
            <span className={classes.semibold}>{members}</span>
            {` User Seat${isPro ? 's' : ''}`}
            <br />
          </div>
          {
            isPro && (
              <div className={classes.featureRow}>
                <span className={classes.semibold}>10+ Integrations</span>
                <br />
              </div>
            )
          }
          {
            isPro && (
              <div className={classes.featureRow}>
                <span className={classes.semibold}>Unlimited In-app Support</span>
                <br />
              </div>
            )
          }
          <div className={classes.featureRow}>
            <span className={classes.semibold}>{plagiarismChecks}</span>
            &nbsp;Plagiarism Checks / mo
          </div>
        </>
      );
    }
    return (
      <>
        {plan.rank === 500 && <div className={classes.featureRow}>Unlimited Words</div>}
        <div className={classes.featureRow}>Bulk Import & Export</div>
        <div className={classes.featureRow}>Custom Development</div>
        <div className={classes.featureRow}>Account Manager</div>
        <div className={classes.featureRow}>Unlimited Plagiarism Checks</div>
        <div className={classes.featureRow}>Unlimited In-app Support</div>
      </>
    );
  };

  const tiers = React.useMemo(() => {
    if (!plan.tiersSupported) {
      return null;
    }

    const maxTierPlan = allPricing.find(
      (p) => p.period === plan.period && p.rank === plan.rank && p.tier === plan.maxTier,
    );

    const minTierPlan = allPricing.find((p) => p.rank === plan.rank && p.tier === 0);

    const formatter = new Intl.NumberFormat('en-US');

    // if (plan.maxTier === 1) {
    //   return (
    //     <div>
    //       <span>{formatter.format(minTierPlan.data.limits.mainCredits)}</span>
    //       <Switch classes={{ track: classes.tierSwitch }} disableRipple />
    //       <span>{formatter.format(maxTierPlan.data.limits.mainCredits)}</span>
    //     </div>
    //   );
    // }

    const getSliderTooltip = () => {
      let label = `${formatter.format(plan.data.limits.mainCredits)} words / month`;
      if (plan.data.limits.mainCredits === 299000) {
        label += ' (~4,271 Product Descriptions / mo*)';
      }
      if (plan.data.limits.mainCredits === 499000) {
        label += ' (~7,128 Product Descriptions / mo*)';
      }
      return label;
    };

    return (
      <Slider
        className={classes.tierSlider}
        valueLabelDisplay="auto"
        valueLabelFormat={getSliderTooltip()}
        min={0}
        max={plan.maxTier}
        onChange={onTierSelect}
        ValueLabelComponent={ValueLabelComponent}
        marks={[
          { value: 0, label: formatter.format(minTierPlan.data.limits.mainCredits) },
          { value: plan.maxTier, label: formatter.format(maxTierPlan.data.limits.mainCredits) },
        ]}
        step={0.0}
        defaultValue={0}
        value={plan.tier}
      />
    );
  }, [plan]);

  return (
    <div className={clsx(classes.planCardRoot, isSuspended && classes.suspended)}>
      <div className={classes.planInfo}>
        {isPro && (
          <div className={classes.banner}>Most popular</div>
        )}
        <PlanInfo
          plan={plan}
          period={period}
        />
        {tiers}
        <div className={classes.separator} />
        {renderFeatures()}
      </div>

      <div className={classes.buttonWrapper}>
        <PlanButton
          plan={plan}
          period={period}
          fullWidth
        />
      </div>
    </div>
  );
};

export default PlanCard;
