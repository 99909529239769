import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import userService from '@root/resources/user/user.service';
import AuthContext from '@root/resources/auth/auth.context';
import api from '@root/api';
import useInvites from '@root/resources/invite/useInvites';
import ProfileIcon from '@root/components/ProfileIcon';

import useStyles from '../TeamManagement.styles';

const InvitesList = ({
  invites,
}) => {
  const { currentUser } = AuthContext.useAuth();
  const { refetch: reloadInvites } = useInvites();
  const classes = useStyles();

  const handleCancelInvite = async (inviteId) => {
    await api.invite.cancel(inviteId);
    await reloadInvites();
  };

  const roles = userService.getRoles(currentUser);
  const isAdminOrOwner = roles.includes('admin') || roles.includes('owner');

  return (
    <>
      {invites.map((invite) => (
        <tr className={classes.tableTr} key={invite._id}>
          <td className={clsx(classes.cell, classes.memberName)}>
            <div className={classes.nameContainer}>
              <ProfileIcon
                className={classes.teamsProfileIcon}
                name={invite.inviteeEmail}
                disabled
              />
              <div>
                <div className={classes.greyLable}>Invite Pending</div>
                <div className={clsx(classes.memberEmail, classes.greyLable)}>
                  {invite.inviteeEmail}
                </div>
              </div>
            </div>
          </td>
          <td className={clsx(
            classes.cell,
            classes.memberAccessLevel,
            classes.greyLable,
          )}
          >
            {invite.inviteeRole}
          </td>
          <td className={clsx(classes.cell, classes.memberActions)}>
            {isAdminOrOwner ? (
              <Button
                variant="text"
                onClick={() => handleCancelInvite(invite._id)}
              >
                Cancel Invite
              </Button>
            ) : (
              <div>-</div>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default InvitesList;
