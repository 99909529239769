import React from 'react';

import AuthContext from '@root/resources/auth/auth.context';
import useFolders from '@root/resources/folder/useFolders';
import {
  RENAME_FILE_MODAL_ID,
  CLONE_FILE_MODAL_ID,
  DELETE_FILE_MODAL_ID,
  MOVE_FILE_MODAL_ID,
  SHARE_FOLDER_MODAL_ID,
} from '@root/views/Dashboard/components/Modals';
import TemplatesContext from '@root/resources/templates/templates.context';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';

const CLONE_DISABLED_TEMPLATE_TYPES = [
  'articles',
  'blogPost',
];

const useFileMenuItems = (file) => {
  const { dispatch } = useGlobalModals();
  const { getTemplate } = TemplatesContext.useTemplates();

  const { currentUser: user } = AuthContext.useAuth();
  const { data: folders } = useFolders();
  const folder = folders.find((f) => f._id === file.folderId);

  const {
    _id, templateType, title,
  } = file;

  const { slug, deletedOn: templateDeleted } = getTemplate(templateType);

  const fileMenu = React.useMemo(() => {
    const folderRoles = user.roles.getFolderRoles(folder);
    const {
      canDeleteFolderFiles,
      canRenameFolderFiles,
    } = user.permissions.getFolderPermissions(folder);

    const isCloneDisabled = CLONE_DISABLED_TEMPLATE_TYPES.includes(templateType) || templateDeleted;

    const {
      canCloneFile,
      canEditFile,
      canAddMembers,
    } = user.permissions.getFilePermissions(file, folder);

    const fm = [{
      title: 'Open in New Tab',
      key: 'newTab',
      onClick: ({ handleClose }) => {
        handleClose();
        window.open(
          `/${slug}/${_id}?title=${title}`,
          '_blank',
        );
      },
    }];

    if (canRenameFolderFiles || (canEditFile && file.userId === user._id)) {
      fm.push({
        title: 'Rename',
        key: 'rename',
        onClick: ({ handleClose }) => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: RENAME_FILE_MODAL_ID,
              modalContent: { file },
            },
          });
        },
      });
    }

    if (folderRoles.owner) {
      fm.push({
        title: 'Move To',
        key: 'moveTo',
        onClick: ({ handleClose }) => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: MOVE_FILE_MODAL_ID,
              modalContent: { file },
            },
          });
        },
      });
    }

    if (canCloneFile && !isCloneDisabled) {
      fm.push({
        title: 'Clone File',
        key: 'cloneFile',
        onClick: ({ handleClose }) => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: CLONE_FILE_MODAL_ID,
              modalContent: { file },
            },
          });
        },
      });
    }

    if (canDeleteFolderFiles || (canEditFile && file.userId === user._id)) {
      fm.push({
        title: 'Delete',
        key: 'delete',
        onClick: ({ handleClose }) => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: DELETE_FILE_MODAL_ID,
              modalContent: { file },
            },
          });
        },
      });
    }

    if (user.featureToggles.newFileSharing && canAddMembers) {
      fm.push({
        title: 'Share with Team',
        key: 'shareWithTeam',
        onClick: ({ handleClose }) => {
          handleClose();
          dispatch({
            type: actions.OPEN_MODAL,
            payload: {
              modalId: SHARE_FOLDER_MODAL_ID,
              modalContent: { file },
            },
          });
        },
      });
    }

    return fm;
  }, [folder, file, user]);

  return fileMenu;
};

export default useFileMenuItems;
