import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import stringToColor from 'string-to-color';

import useStyles from './UsageChart.styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const UsageChart = ({ generationsUsage, isGenerationsUsageFetching, templatesList }) => {
  const classes = useStyles();

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Usage By Template Type',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const getDatasets = () => {
    if (!generationsUsage) {
      return [];
    }

    const dataSetsWithColor = generationsUsage.datasets.map(
      (item) => { return { ...item, backgroundColor: stringToColor(item.label) }; },
    );

    const dataSets = dataSetsWithColor.map(
      (item) => {
        const matchedTemplate = templatesList.find((template) => template.templateType === item.label);

        if (!matchedTemplate && item.label !== 'special.instruct') {
          return item;
        }

        return { ...item, label: item.label === 'special.instruct' ? 'Instruct' : matchedTemplate.title };
      },
    );

    // sum datasets with same label
    const dataSetsSum = dataSets.reduce((acc, curr) => {
      const found = acc.find((item) => item.label === curr.label);

      if (found) {
        found.data = found.data.map((item, index) => item + curr.data[index]);
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    return dataSetsSum;
  };

  return (
    <Grid
      item
      sm={12}
      md={8}
      lg={9}
    >
      <div className={classes.chart}>
        {isGenerationsUsageFetching || !generationsUsage || generationsUsage.length === 0 || !templatesList
          ? <CircularProgress size={75} /> : (
            <Bar
              options={options}
              data={{
                labels: generationsUsage.labels,
                datasets: getDatasets(),
              }}
            />
          )}
      </div>
    </Grid>
  );
};

export default UsageChart;
