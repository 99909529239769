import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    form: {
    },
    fieldsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      marginBottom: 36,
    },
    cardInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    row: {
      display: 'flex',
      '& > *': {
        flex: 1,
      },
      '& > :not(:last-child)': {
        marginRight: 16,
      },
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      marginBottom: 32,
    },
    line: {
      flex: 1,
      marginLeft: 12,
      borderBottom: `1px solid  ${theme.palette.colors.black}`,
    },
  });
});

export default useStyles;
