import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    input: {
      width: '100%',
      height: 36,
      borderRadius: theme.border.radius.default,
      border: `${theme.form.input.borderWidth} solid ${theme.form.input.borderColor}`,
      fontSize: theme.form.input.fontSize,
      fontFamily: theme.font.family.default,
      padding: theme.form.input.padding,
      backgroundColor: 'white',
      '&::placeholder': {
        opacity: '0.75',
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
    inputError: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    inputFocus: {
      borderColor: theme.palette.primary.main,
    },
  });
});

export default useStyles;
