import React from 'react';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import pluralize from 'pluralize';

import avatarColors from '@root/utils/avatarColors';
import TruncatedText from '@root/components/TruncatedText';
import dateUtil from '@root/utils/date.util';
import BoosteAltTooltipContent from '@root/components/tooltips/BoosteAltTooltipContent';
import Avatar from '@root/components/Avatar';
import WorkflowStatus from '@root/views/Dashboard/components/FolderContentNew/DashListViewNew/components/WorkflowStatus';
import SimpleAvatarGroup from '@root/components/SimpleAvatarGroup';
import { idToDecimalSum } from '@root/utils/formatString.util';

const BOOSTE_LEGACY_TEMPLATE_TYPES = [
  'articles',
  'blogPost',
];

const expandCellsWithSettings = {
  workflowQueue: (cells) => {
    const settings = {
      title: { width: '20%', sortEnabled: true },
      sharedWith: { width: '15%' },
      templateType: { width: '20%' },
      updatedOn: { width: '15%', sortEnabled: true },
      assignedTo: { width: '15%', align: 'center', sortEnabled: true },
      workflowStatus: { width: '15%', align: 'left' },
    };

    const keys = Object.keys(settings);

    return cells
      .filter(({ id }) => keys.includes(id))
      .map((cell) => {
        return {
          ...cell,
          width: settings[cell.id].width,
          align: settings[cell.id].align,
          sortEnabled: settings[cell.id].sortEnabled,
        };
      });
  },
  default: (cells) => {
    const settings = {
      title: { width: '25%' },
      templateType: { width: '25%' },
      generationSize: { width: '25%' },
      updatedOn: { width: '25%' },
    };

    const keys = Object.keys(settings);

    return cells
      .filter(({ id }) => keys.includes(id))
      .map((cell) => {
        return {
          ...cell,
          width: settings[cell.id].width,
        };
      });
  },
  subfolder: (cells) => {
    const settings = {
      title: { width: '20%' },
      sharedWith: { width: '15%' },
      templateType: { width: '20%' },
      updatedOn: { width: '15%' },
      assignedTo: { width: '15%', align: 'center' },
      workflowStatus: { width: '15%' },
    };

    const keys = Object.keys(settings);

    return cells
      .filter(({ id }) => keys.includes(id))
      .map((cell) => {
        return {
          ...cell,
          width: settings[cell.id].width,
        };
      });
  },
};

const getCells = ({ getTemplate, user, classes, teamMembers }) => {
  return [
    {
      id: 'title',
      label: 'Name',
      render: (item) => (
        <div className={classes.titleCellContainer}>
          <div
            className={classes.colorIndicator}
            style={{ backgroundColor: avatarColors[idToDecimalSum(item?._id) % 6] }}
          />

          <div className={classes.title}>
            <TruncatedText className={classes.titleText}>
              {item.title}
            </TruncatedText>
            <TruncatedText className={classes.createdOnText}>
              Created:
              {' '}
              {dateUtil.formatDate(item.createdOn, 'dd LLL yyyy')}
            </TruncatedText>
          </div>
        </div>
      ),
    },
    {
      id: 'sharedWith',
      label: '',
      render: (item) => {
        const maxAvatarCount = 4;
        // extend unifiedAcl with info of founded team members
        const sharedWith = item?.unifiedAcl?.filter((person) => person?.userId !== user?._id)
          .map((sharedPerson) => {
            const foundedMember = teamMembers?.find((teamMember) => teamMember?._id === sharedPerson?.userId);

            return {
              ...sharedPerson,
              firstName: foundedMember?.firstName,
              surname: foundedMember?.surname,
              email: foundedMember?.email,
            };
          });
        const extraSharedWith = sharedWith?.slice(maxAvatarCount);
        const sharedWithCount = sharedWith?.length;

        return (

          <SimpleAvatarGroup
            max={maxAvatarCount}
            classes={{ avatar: classes.sharedWithAvatar }}
            extraAvatarTooltipContent={(
              <div className={classes.extraTooltipContent}>
                <p className={classes.extraTooltipTitle}>
                  {`Shared with ${sharedWithCount - maxAvatarCount} more ${pluralize('person', sharedWithCount - maxAvatarCount)}:`}
                </p>
                <div className={classes.extraTooltipEmailsContainer}>
                  <span>{extraSharedWith.map((extraPerson) => extraPerson?.email).join(', ')}</span>
                </div>
              </div>
            )}
          >
            {sharedWith.map((person) => {
              const { firstName, surname, email } = person;
              const fullName = (firstName && surname) ? `${firstName} ${surname}` : '';

              return (
                <Avatar
                  key={person?._id}
                  oneChar
                  name={fullName}
                  email={email}
                  userId={person?.userId}
                  popperEnabled
                  alt={person.firstName}
                  className={classes.sharedWithAvatar}
                />
              );
            })}
          </SimpleAvatarGroup>
        );
      },
    },
    {
      id: 'templateType',
      label: 'Type',
      render: ({ templateType }) => {
        const { Icon, title } = getTemplate(templateType);
        const isBoosteLegacyTemplateType = BOOSTE_LEGACY_TEMPLATE_TYPES.includes(templateType);

        return (
          <div className={classes.legacyTemplateContainer}>
            {isBoosteLegacyTemplateType && (
              <Tooltip
                wrapped
                content={isBoosteLegacyTemplateType
                  && <BoosteAltTooltipContent templateName={title} />}
                placement="bottom"
                interactive
              >
                <span>
                  {isBoosteLegacyTemplateType ? <ErrorIcon style={{ color: 'orangered' }} /> : <Icon color="#000" size={24} />}
                </span>
              </Tooltip>
            )}

            <TruncatedText className={classes.templateType}>{title}</TruncatedText>
          </div>
        );
      },
    },
    {
      id: 'updatedOn',
      label: 'Modified',
      render: (item) => (
        <TruncatedText>
          {dateUtil.getRelative(
            item.updatedOn,
            {
              withAbsolute: true,
              absoluteFormat: 'dd LLL yyyy',
              absoluteFormatAfter: 'weeks',
              absoluteFormatAfterAmount: '1',
            },
          )}
        </TruncatedText>
      ),
    },
    {
      id: 'assignedTo',
      label: 'Assigned To',
      render: ({ assignedTo }, innerRef, isDisabled) => {
        if (!assignedTo) {
          return <span className={classes.dash}>—</span>;
        }

        const { userId, email, displayName } = assignedTo;

        const isAssignedToMe = userId === user._id;

        if (isAssignedToMe) {
          return (
            <div className={clsx(classes.youHighlight, {
              [classes.disabledText]: isDisabled,
            })}
            >
              You
            </div>
          );
        }

        return (
          <Avatar
            className={classes.assignedToAvatar}
            name={displayName || email || ''}
            popperEnabled
            popperContent={(
              <div>
                <span className={classes.assignedToTooltipText}>Assigned To: </span>
                <span className={classes.assignedToTooltipName}>{displayName}</span>
              </div>
            )}
            oneChar={false}
          />
        );
      },
    },
    {
      id: 'workflowStatus',
      label: 'Status',
      render: ({ workflowStatus }, innerRef, isDisabled) => {
        return (
          <WorkflowStatus status={workflowStatus} isDisabled={isDisabled} />
        );
      },
    },
  ];
};

const getSubfolderCells = ({ classes }) => {
  return [
    {
      id: 'title',
      label: 'Name',
      render: (folder) => (
        <div className={classes.titleCellContainer}>
          <div>
            <TruncatedText className={classes.titleText}>
              {folder?.title}
            </TruncatedText>

            <TruncatedText className={classes.createdOnText}>
              Created:
              {' '}
              {dateUtil.shortFormat(folder?.createdOn)}
            </TruncatedText>
          </div>
        </div>
      ),
    },
    { id: 'sharedWith', label: 'Shared', render: () => {} },
    {
      id: 'templateType',
      label: 'Type',
      render: () => (
        <span>
          Folder
        </span>
      ),

    },
    // dummy cells to make subfolder row fullwidth
    { id: 'updatedOn', label: 'Modified', render: () => {} },
    {
      id: 'assignedTo',
      label: 'Assigned To',
      render: () => {},
    },
    {
      id: 'workflowStatus',
      label: 'Status',
      render: () => {},
    },
  ];
};

export { expandCellsWithSettings, getCells, getSubfolderCells };
