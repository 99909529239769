import React from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import OnboardingContext from '@root/resources/onboarding/onboarding.context';
import AuthContext from '@root/resources/auth/auth.context';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';
import { templates } from '@root/views/Editor/Editor.constants';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';

import { TEMPLATE_CREATE_MODAL_ID, TEMPLATE_EXAMPLE_MODAL_ID } from '../../modals';

import Bookmark from './Bookmark';
import useStyles from './TemplateCard.styles';

const TemplateCard = ({ template, disabled, disabledTooltipText }) => {
  const { dispatch } = useGlobalModals();
  const { currentUser } = AuthContext.useAuth();
  const { state: {
    hideFavorite,
    onCreateClick,
  }, handleCreate } = useTemplatesDashboard();
  const history = useHistory();

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { featureTourActive, nextStep } = OnboardingContext.useOnboarding();
  const { newEditor: isRemoveCreateFileModalEnabled } = currentUser.featureToggles;

  const { Icon } = template;

  const isBlogIntro = template.templateType === 'blogIntro';
  const isOnboardingActive = featureTourActive && isBlogIntro;

  const classes = useStyles({ isOnboardingActive });

  const handleCreateModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: template,
      },
    });
  };

  const handleCreateClick = () => {
    if (disabled) {
      return;
    }

    const isFreeFormEditor = template.templateType === 'freeFormEditor';
    const isEditorTemplate = Object.values(templates).map((t) => t.templateType).includes(template.templateType);
    const isBlogKickstarter = template.templateType === 'blogKickstarter';
    const { disableEditor } = currentExternalSource?.toggles;

    if (isOnboardingActive && !isFreeFormEditor) {
      handleCreateModal();
      nextStep();
      return;
    }

    if (isOnboardingActive && isFreeFormEditor && isRemoveCreateFileModalEnabled) {
      handleCreate(template.templateType);
      nextStep();
      return;
    }

    if (isBlogKickstarter) {
      history.push('/blog-kickstarter');
      return;
    }

    if (onCreateClick) {
      onCreateClick(template);
      return;
    }

    if (!disableEditor && (isFreeFormEditor || isEditorTemplate) && isRemoveCreateFileModalEnabled) {
      handleCreate('freeFormEditor', isEditorTemplate && template.templateType);
      return;
    }

    handleCreate(template.templateType);
  };

  return (
    <div className={classes.card} id={isOnboardingActive ? 'feature-tour-step-1' : undefined}>
      <div>
        <div className={classes.topActions}>
          <div className={classes.icon} onClick={handleCreateClick}>
            {Icon && <Icon color="#000" size={24} />}
          </div>

          {(!hideFavorite && !isOnboardingActive) && (
            <Bookmark isFavorite={template.isFavorite} type={template.templateType} />
          )}
        </div>

        <div style={{ flexGrow: 0 }}>
          <h2 className={classes.title} onClick={handleCreateClick}>
            {template.title}
          </h2>

          <TruncatedText
            multiline
            maxLines={2}
            className={classes.description}
            onClick={handleCreateClick}
            withoutTooltip
          >
            {template.description}
          </TruncatedText>
        </div>
      </div>

      <div className={classes.bottomActions}>
        <SimpleTooltip
          content={disabledTooltipText}
          wrapped
          placement="bottom"
        >
          <Button
            className={classes.createButton}
            disabled={disabled}
            id="create-button"
            size="small"
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </SimpleTooltip>
      </div>
    </div>
  );
};

export default TemplateCard;
