import useAiTemplates from './useAiTemplates';
import useAiTemplate from './useAiTemplate';
import useCreateOrUpdateAiTemplate from './useCreateOrUpdateAiTemplate';
import useCreateOrUpdateCustomAiTemplate from './useCreateOrUpdateCustomAiTemplate';
import useDeleteAiTemplate from './useDeleteAiTemplate';
import useCreateQualityDiscriminator from './useCreateQualityDiscriminator';
import useAdminUpdateTemplate from './useAdminUpdateTemplate';
import useApproveTemplate from './useApproveTemplate';
import useShareTemplateToCommunity from './useShareTemplateToCommunity';
import useRemoveTemplateFromCommunity from './useRemoveTemplateFromCommunity';
import useCommunityTemplates from './useCommunityTemplates';
import useAddCommunityTemplate from './useAddCommunityTemplate';

export default {
  useAiTemplates,
  useAiTemplate,
  useCreateOrUpdateAiTemplate,
  useCreateOrUpdateCustomAiTemplate,
  useAdminUpdateTemplate,
  useApproveTemplate,
  useDeleteAiTemplate,
  useCreateQualityDiscriminator,
  useShareTemplateToCommunity,
  useRemoveTemplateFromCommunity,
  useCommunityTemplates,
  useAddCommunityTemplate,
};
