import React from 'react';

const CheckCircle = () => {
  return (
    <svg
      width="26"
      height="26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.999"
        cy="13"
        r="10.8"
        fill="#fff"
      />
      <path
        d="m18.728 10.655-.003.004-5.775 5.883-.007.005a2.542 2.542 0 0 1-.03.015l.223.447-.223-.447a1.105 1.105 0 0 0-.222.138c-.206.101-.302.136-.373.136-.198 0-.417-.061-.662-.215l-3.342-3.45-.006-.006c-.35-.35-.35-1.016 0-1.366l.016-.016.015-.018c.186-.223.404-.31.597-.31.194 0 .412.087.598.31l.015.018.016.016 2.4 2.4.357.358.354-.362 4.688-4.798c.351-.348 1.015-.348 1.364.002.35.35.35.906 0 1.257ZM13 25.5c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5.5 6.096.5 13 6.096 25.5 13 25.5Z"
        fill="currentColor"
        stroke="#fff"
      />
    </svg>
  );
};

export default CheckCircle;
