import React from 'react';
import copy from 'clipboard-copy';
import { Button } from '@material-ui/core';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import { escapeText } from '@root/utils/text.utils';
import { getDefaultClipboardItem } from '@root/resources/file/file.helpers';
import uiNotificationService from '@root/services/uiNotification.service';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const getNewContent = ({
  lastHighlightedText,
  lastHighlightedDocument,
  newContent,
}) => {
  const contentData = lastHighlightedText.content || lastHighlightedDocument.content;

  if (lastHighlightedText.field === null) {
    return newContent;
  }

  if (Array.isArray(contentData)) {
    const newArr = [...contentData];
    newArr[lastHighlightedText.field] = newContent;
    return newArr;
  }

  return {
    ...contentData,
    [lastHighlightedText.field]: newContent,
  };
};

const useDocumentContent = ({
  generateRewriterContent,
  updateDocument,
  documents,
  setModalData,
  config,
  lastHighlightedText,
  lastHighlightedTextId,
  fileId,
  maxRewriteLength,
  isRewriterLoading,
  setIsRewriterLoading,
  setLastHighlightedText,
  classes,
}) => {
  const [editingDocumentId, setEditingDocumentId] = React.useState(null);

  const lastHighlightedDocument = documents.find((d) => d.id === lastHighlightedTextId);

  const editDocument = (docId) => () => {
    setEditingDocumentId(docId);
  };

  const onDocumentEdited = async (newContent) => {
    const content = getNewContent({
      lastHighlightedText,
      lastHighlightedDocument,
      newContent,
    });

    await updateDocument({
      _id: lastHighlightedTextId,
      fileId,
      data: { content },
    });
  };

  const changeDocumentContent = async (newTexts, action) => {
    if (!newTexts || newTexts.length === 0) {
      return;
    }

    const contentData = lastHighlightedText.content || lastHighlightedDocument.content;

    const content = escapeText(lastHighlightedText.field === null
      ? contentData
      : contentData[lastHighlightedText.field]);
    const [p1, p2] = content.split(lastHighlightedText.text);

    const newContent = (text) => `${p1} ${text} ${p2}`;
    const preview = (text) => (
      <span>
        {p1}
        {' '}
        <span className={classes.expandedContentPreview}>
          {text}
        </span>
        {' '}
        {p2}
      </span>
    );

    setModalData({
      content: (
        <div>
          <div>
            Original:
            {lastHighlightedText.text}
          </div>
          {newTexts.filter((text) => !!text && text.length > 5).map((text) => (
            <div
              className={classes.contentPreviewRow}
              key={text}
            >
              <span className={classes.contentPreviewContent}>
                New:
                {' '}
                {text}
              </span>
              <SimpleTooltip
                content={preview(text)}
                placement="right-start"
              >
                <Button
                  onClick={() => {
                    if (lastHighlightedText.onTextUpdate) {
                      lastHighlightedText.onTextUpdate(newContent(text));
                      setTimeout(() => {
                        setModalData(null);
                      }, 100); // prevent instantly document editor closing
                    } else {
                      onDocumentEdited(newContent(text));
                      setModalData(null);
                    }
                    setLastHighlightedText(null);
                  }}
                >
                  Replace Original
                </Button>
              </SimpleTooltip>
            </div>
          ))}
        </div>
      ),
      action,
    });
  };

  const expandContent = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!lastHighlightedText || lastHighlightedText.text.length > 400) {
      return;
    }

    const doRequest = () => generateRewriterContent({
      data: {
        inputParagraph: lastHighlightedText.text,
      },
      templateType: 'contentExpanderNew',
      fileId,
    });

    let { results } = await doRequest();
    results = results.map((r) => r.content.trim());

    const action = {
      exec: async () => {
        const { results: newResults } = await doRequest();
        results = results.concat(newResults.map((r) => r.content.trim()));
        changeDocumentContent(results, action);
      },
      label: 'Generate more',
    };

    if (results) {
      changeDocumentContent(results, action);
    }
  };

  const rewriteContent = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!lastHighlightedText || lastHighlightedText.text.length > maxRewriteLength) {
      return;
    }

    if (isRewriterLoading) {
      return;
    }

    setIsRewriterLoading(true);

    const doRequest = () => generateRewriterContent({
      data: {
        inputParagraph: lastHighlightedText.text,
      },
      templateType: 'contentRewriter',
      fileId,
    });

    let { results } = await doRequest();
    results = results.map((r) => r.content.trim());

    const action = {
      exec: async () => {
        const { results: newResults } = await doRequest();
        results = results.concat(newResults.map((r) => r.content.trim()));
        changeDocumentContent(results, action);
      },
      label: 'Generate more',
    };

    if (results) {
      changeDocumentContent(results, action);
    }

    setIsRewriterLoading(false);
  };

  const copyDocumentContent = (doc) => async () => {
    await copy(config.getClipboardItem
      ? config.getClipboardItem(doc)
      : getDefaultClipboardItem(doc));
    uiNotificationService.showSuccessMessage('Copied to Clipboard!');

    eventsAggregator.publish(
      EVENT_NAMES.GENERATION_COPIED_TO_CLIPBOARD,
      { template: config.templateType },
    );
  };

  return {
    editingDocumentId,
    editDocument,
    changeDocumentContent,
    expandContent,
    rewriteContent,
    copyDocumentContent,
  };
};

export default useDocumentContent;
