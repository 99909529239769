import { Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import AddonItem from '@root/views/Profile/Billing/components/Sections/AddonsSection/AddonItem';

import useSectionsStyles from '../Sections.styles';

import EmptyState from './EmptyState';
import useStyles from './AddonsSection.styles';

const AccountSection = ({ addons = [] }) => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const hasAddons = addons.length > 0;

  return (
    <section className={sectionClasses.sectionContainer}>
      <div className={sectionClasses.sectionTitleContainer}>
        <Typography variant="h4" className={sectionClasses.sectionTitle}>
          Active Add-ons
        </Typography>
        <Typography variant="body1" className={sectionClasses.sectionSubtitle}>
          Here is where your monthly add-ons will show.
        </Typography>
      </div>

      <div className={clsx(
        sectionClasses.cardContainer,
        sectionClasses.wideCard,
        classes.addonsCard,
      )}
      >
        {!hasAddons && <EmptyState />}

        {hasAddons && (
          <div className={classes.addonsList}>
            <header className={classes.addonsHeader}>
              <Typography className={classes.headerText}>Add-on</Typography>
              <Typography className={classes.headerText}>Billed</Typography>
              <div />
            </header>

            <div className={classes.divider} />

            {addons.map((addon) => (
              <AddonItem
                key={addon.type}
                type={addon.type}
                quantity={addon.quantity}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default AccountSection;
