import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress } from '@material-ui/core';

import config from '@root/config';
import { featureEnabled } from '@root/utils/features';
import { signInWithGoogle, signInWithMicrosoft, logInUser } from '@root/services/auth.service';
import uiNotificationService from '@root/services/uiNotification.service';
import { InputField, FormField, PasswordField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

import AuthLayout from '../Layout/AuthLayout';

import { DividerLine, RedirectLink } from './components/SharedComponents';
import SpecialSignInButton from './components/SpecialSignInButton';
import useStyles from './LogInPage.styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid Email'),
  password: Yup.string()
    .required('Password is required'),
});

const LoginPage = ({
  title = 'Sign In to Copysmith',
  buttonText = 'Sign In',
  withPasswordRestore = true,
  returnUrlOverride = null,
} = {}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const classes = useStyles({ isMobile });
  const history = useHistory();
  const { search, state: locationState = {} } = useLocation();

  const searchParams = new URLSearchParams(search);
  const error = searchParams.get('error');
  // variables passed via query from accept team invite function
  const forcedEmail = searchParams.get('email');

  const {
    currentExternalSource: {
      successLoginRedirectPath: externalSourceSuccessLoginRedirectPath,
    },
  } = externalSourcesContext.useExternalSource();

  const formikRef = useRef();

  // const goToSignUp = () => {
  //   history.push('/signup');
  // };

  const onPasswordRestore = () => {
    history.push('/usermgmt/restore-password', { email: formikRef.current?.values.email });
  };

  const returnUrl = useMemo(() => {
    if (returnUrlOverride) {
      return returnUrlOverride;
    }

    if (externalSourceSuccessLoginRedirectPath) {
      return externalSourceSuccessLoginRedirectPath;
    }

    if (!locationState.from?.pathname || locationState.from.pathname === config.homeRoute) {
      return `${config.successLoginRedirectPath}${window.location.search ? `?${window.location.search}` : ''}`;
    }

    return `${locationState.from?.pathname}${locationState.from?.search || ''}`;
  }, [locationState]);

  const onSingleSignOn = () => {
    history.push('/single-sign-on', { email: formikRef.current?.values.email, returnUrl });
  };

  const onAfterLogin = async () => {
    window.location.href = returnUrl;
  };

  const submit = async (object) => {
    try {
      setIsLoading(true);
      const { success } = await logInUser(object);
      if (success) {
        await onAfterLogin();
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      if (e.redirectToCheckEmail) {
        history.push('/check-email', { email: object.email });
        return;
      }
      uiNotificationService.showErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const GoogleLogIn = async () => {
    try {
      const { success } = await signInWithGoogle(setIsLoading);
      if (success) {
        await onAfterLogin();
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      if (e.redirectToCheckEmail) {
        history.push('/check-email', { email: e.email });
        return;
      }
      uiNotificationService.showErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const MicrosoftLogin = async () => {
    try {
      const { success } = await signInWithMicrosoft(setIsLoading);
      if (success) {
        await onAfterLogin();
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      if (e.redirectToCheckEmail) {
        history.push('/check-email', { email: e.email });
        return;
      }
      uiNotificationService.showErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      uiNotificationService.showErrorMessage(error);
    }
    if (locationState?.successMessage) {
      uiNotificationService.showSuccessMessage(locationState.successMessage);
    }
    if (locationState?.errorMessage) {
      uiNotificationService.showErrorMessage(locationState.errorMessage);
    }
  }, [error, locationState]);

  return (
    <AuthLayout showLogo>
      <h1 className={classes.title}>
        {title}
      </h1>
      <div className={classes.socialButtons}>
        <SpecialSignInButton
          icon="google"
          title="Sign In with Google"
          onClick={GoogleLogIn}
        />
        <SpecialSignInButton
          icon="outlook"
          title="Sign In with Outlook"
          onClick={MicrosoftLogin}
        />
        {featureEnabled('sso') && (
          <SpecialSignInButton
            title="Sign in with Single Sign On"
            onClick={onSingleSignOn}
          />
        )}
      </div>
      <DividerLine text="Or sign in with email" />
      <Formik
        innerRef={formikRef}
        initialValues={{ email: forcedEmail || '', password: '' }}
        validationSchema={schema}
        onSubmit={submit}
        enableReinitialize
      >
        <Form className={classes.form}>
          <div className={classes.inputsContainer}>
            <FormField
              name="email"
              component={InputField}
              label="Email Address"
              required
              fullWidth
              readOnly={!!forcedEmail}
            />
            <FormField
              name="password"
              component={PasswordField}
              label="Password"
              required
              fullWidth
            />

          </div>
          {withPasswordRestore && (
            <Button
              variant="link"
              onClick={onPasswordRestore}
              className={classes.forgotPassword}
            >
              Forgot password
            </Button>
          )}
          <div className={classes.buttonsContainer}>
            <Button
              disabled={isLoading}
              size="large"
              type="submit"
            >
              {isLoading ? <CircularProgress className={classes.loading} size={14} /> : buttonText}
            </Button>
            {/* <RedirectLink
              to="/signup"
              linkText="Sign Up"
              text="Don’t have an account?"
            /> */}
          </div>
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default LoginPage;
