import React from 'react';
import { TuneRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    cursor: 'pointer',
    padding: '0.25rem',
    borderRadius: '4px',
    '&:hover,&:active': {
      backgroundColor: '#cecece',
    },
  },
  opened: {
    color: theme.palette.primary.main,
  },
}));

const FilterSelect = ({ onClick, opened }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.iconContainer, { [classes.opened]: opened })}
        title="File filter and sorting"
        onClick={onClick}
      >
        <TuneRounded />
      </div>
    </div>
  );
};

export default FilterSelect;
