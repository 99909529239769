import React from 'react';

const Rewrite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="86"
      fill="none"
    >
      <path fill="currentColor" d="M27.43 72c-1.558 0-2.922-.59-4.091-1.772-1.17-1.181-1.754-2.56-1.754-4.134V56.25h12.082V43.748c-2.469.197-4.937-.147-7.405-1.033-2.469-.886-4.677-2.28-6.626-4.184v-5.71h-4.872L2 19.928c2.338-2.232 5.132-4.053 8.38-5.464 3.247-1.41 6.56-2.116 9.938-2.116 1.949 0 4.173.312 6.674.935 2.501.623 4.726 1.624 6.675 3.002V9H78v52.664c0 2.888-.99 5.332-2.972 7.334C73.047 70.999 70.628 72 67.77 72H27.431Zm12.083-15.75h23.872v5.414c0 1.313.406 2.379 1.218 3.2.812.82 1.867 1.23 3.166 1.23 1.3 0 2.355-.41 3.167-1.23.812-.821 1.218-1.887 1.218-3.2V14.906H39.513v5.611l23.482 23.724v4.232h-4.19L46.528 36.07l-1.656 1.97c-.845.983-1.69 1.738-2.533 2.263a22.84 22.84 0 0 1-2.826 1.477v14.47ZM17.395 26.916h8.087v8.76c1.104.722 2.192 1.264 3.264 1.625 1.072.36 2.16.541 3.264.541 1.624 0 3.28-.443 4.97-1.329 1.688-.886 2.923-1.788 3.702-2.707l1.657-1.968-6.724-6.793c-2.078-2.1-4.417-3.757-7.015-4.97-2.598-1.215-5.359-1.822-8.282-1.822-1.754 0-3.345.213-4.774.64-1.43.427-2.891 1-4.385 1.723l6.236 6.3Zm40.144 35.24H27.43v3.938h31.472c-.39-.394-.715-.935-.975-1.624-.26-.69-.39-1.46-.39-2.314ZM27.43 66.094v-3.938 3.938Z" />
    </svg>
  );
};

export default Rewrite;
