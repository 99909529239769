import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
    width: '100%',
    overflowY: 'hidden',
  },
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: theme.palette.colors.white,
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    height: '100%',
  },
  newTableContainer: {
    border: `1px solid ${theme.palette.colors.mediumLightGrey}`,
  },
  table: {
    tableLayout: 'fixed',
    '& tr:last-child td': {
      border: 'none',
    },
    borderCollapse: 'separate',
    [theme.breakpoints.down('md')]: {
      minWidth: 1200,
    },
  },
  youHighlight: {
    color: theme.palette.secondary.main,
  },
  disabledText: {
    color: theme.palette.colors.grey,
  },
  loaderWrap: {
    width: '75vw',
    height: '70px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
