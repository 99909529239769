import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';
import uiNotificationService from '@root/services/uiNotification.service';
import useInvalidateLimits from '@root/resources/billing/useInvalidateLimits';

export default function useGenerateContent() {
  const queryClient = useQueryClient();
  const { invalidateLimits } = useInvalidateLimits();

  return useMutation(
    (data) => api.ai.generateContent(data),
    {
      onSuccess: async (response) => {
        if (!response) {
          return null;
        }

        const { results: documents, filterWarnings, isBadRequest } = response;

        if (filterWarnings) {
          if (filterWarnings.unsafeMessage) {
            uiNotificationService.showWarnMessage(filterWarnings.unsafeMessage);
          }

          if (filterWarnings.sensitiveMessage) {
            uiNotificationService.showWarnMessage(filterWarnings.sensitiveMessage);
          }
        }

        if (isBadRequest || documents.length === 0) {
          return null;
        }

        const [{ fileId }] = documents;

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['documents.list', { fileId }]);

        const previousDocuments = queryClient.getQueryData(['documents.list', { fileId }]);

        if (!previousDocuments) {
          return null;
        }

        // Optimistically update to the new value
        queryClient.setQueryData(['documents.list', { fileId }], () => [
          ...documents.map((d) => ({ ...d, id: d._id, isNew: true })),
          ...previousDocuments.map((d) => ({ ...d, isNew: false })),
        ]);
        // Optimistically update file cache to new length
        queryClient.getQueryCache().findAll('files.list').forEach(({ queryKey: key, state }) => {
          queryClient.setQueryData(key,
            () => ({
              ...state.data,
              files: state.data.files.map((file) => (
                file._id === fileId
                  ? { ...file, generationSize: previousDocuments.length + documents.length }
                  : file)),
            }));
        });

        invalidateLimits();

        return { previousDocuments };
      },
    },
  );
}
