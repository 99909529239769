import { templateCategories } from '@root/views/Community/Community.constants';

const getTemplateActionStatuses = ({ templates, template, templatesCategory, user, company }) => {
  const isCommunityCategory = templatesCategory === templateCategories.COMMUNITY;
  const isMyTemplatesCategory = templatesCategory === templateCategories.MY_TEMPLATES;

  const correspondingMyTemplates = Object.values(templates).filter(
    (t) => t.originTemplateId === template?._id,
  );

  const isSomeTemplatesFromCommunity = correspondingMyTemplates.some(
    (t) => !!t?.fromCommunity && !t?.deletedOn,
  );
  const isTemplateInLibrary = isSomeTemplatesFromCommunity;

  const isCompanyTemplate = (
    template?.companyId === company?._id
  );

  const isSaveToAccountVisible = (
    isCommunityCategory && !isTemplateInLibrary && !isCompanyTemplate
  );
  const isUploadToCommunityVisible = (
    isMyTemplatesCategory && !template?.shared
  );
  const isRemoveFromCommunityVisible = (
    (isMyTemplatesCategory && template?.shared) || (isCommunityCategory && isCompanyTemplate)
  );
  const isRemoveFromLibraryVisible = (
    isCommunityCategory && !isCompanyTemplate && isTemplateInLibrary
  );

  const correspondingTemplate = correspondingMyTemplates.find(
    (t) => !t.deletedOn && !!t?.fromCommunity,
  );

  const isDownloadedByMe = (
    correspondingTemplate?.downloadedBy === user?._id
  );

  return {
    isDownloadedByMe,
    isSaveToAccountVisible,
    isUploadToCommunityVisible,
    isRemoveFromCommunityVisible,
    isRemoveFromLibraryVisible,
    correspondingTemplate,
  };
};

export default getTemplateActionStatuses;
