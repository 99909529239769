import React, { useMemo } from 'react';
import clsx from 'clsx';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';

import avatarColors from '@root/utils/avatarColors';
import { colors } from '@root/material.theme';
import { idToDecimalSum } from '@root/utils/formatString.util';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const fontColorByBackground = {
  [colors.blue200]: colors.blue600,
  [colors.purple200]: colors.purple600,
  [colors.green200]: colors.green600,
  [colors.pink200]: colors.pink600,
  [colors.yellow200]: colors.yellow600,
  [colors.orange200]: colors.orange600,
};

const stringAvatar = (name, userId, oneChar) => {
  const initials = name
    .match(/(^\S\S?|\s\S)?/g)
    .map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toLocaleUpperCase();

  const idSum = idToDecimalSum(userId);

  const backgroundColor = typeof idSum === 'number'
    ? avatarColors[idToDecimalSum(userId) % 6]
    : undefined;

  return {
    style: {
      backgroundColor,
      color: fontColorByBackground[backgroundColor],
    },
    children: oneChar ? initials[0] : initials,
  };
};

const useStyles = makeStyles((theme) => ({
  avatar: ({ backgroundColor, color }) => ({
    backgroundColor,
    color,
  }),

  small: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: `${theme.spacing(2)}px`,
    fontWeight: 500,
  },

  popperContent: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  name: {
    color: theme.palette.colors.white,
    fontSize: '14px',
    lineHeight: '16px',
  },
  email: {
    color: theme.palette.colors.neutrals300,
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

const Avatar = ({
  // this prop used only to define random color for user by
  userId,
  // used to display initials instead of picture
  name = '',
  oneChar = true,
  url,
  email = '',
  size = 'small',
  className = '',
  popperEnabled = true,
  popperContent = '',
  ...props
}) => {
  const {
    style: { backgroundColor, color },
  } = stringAvatar(name, userId, oneChar);

  const classes = useStyles({ backgroundColor, color });
  const classNames = clsx(classes.avatar, { [classes.small]: size === 'small' }, className);

  const avatarComponent = useMemo(() => {
    if (url) {
      return <MuiAvatar className={classNames} src={url} {...props} />;
    }

    if (name) {
      return (
        <MuiAvatar className={classNames} {...stringAvatar(name, userId, oneChar)} {...props} />
      );
    }

    return <MuiAvatar className={classNames} {...props} />;
  }, [name, url]);

  return (
    <span>
      {popperEnabled ? (
        <SimpleTooltip
          content={popperContent || (
            <div className={classes.popperContent}>
              <div className={classes.name}>{name}</div>

              {email && <div className={classes.email}>{email}</div>}
            </div>
          )}
          interactive
        >
          {avatarComponent}
        </SimpleTooltip>
      ) : (
        avatarComponent
      )}
    </span>
  );
};

export default Avatar;
