import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

import useInvalidateLimits from '../billing/useInvalidateLimits';

const createDocument = (data) => api.documents.create({
  fileId: data.fileId,
  content: data.content,
  ...(data.isFromInstruct && { isFromInstruct: data.isFromInstruct, logRecordId: data.logRecordId }),
});

export default function useCreateDocument() {
  const queryClient = useQueryClient();
  const { invalidateLimits } = useInvalidateLimits();

  return useMutation(
    (data) => createDocument(data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries(['documents.list', { fileId: response.fileId }]);

        invalidateLimits();

        return response;
      },
    },
  );
}
