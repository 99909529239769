import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    margin: '20px 0 4px',
  },
}));

export default useStyles;
