import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 750,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '39px',
  },
  icon: {
    fontSize: 32,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 4,
    maxWidth: 320,
  },
  buttons: {
    margin: '24px 0 16px',
    display: 'flex',
    gap: 16,
  },
  button: {
    width: 295,
    height: 166,
  },
  buttonLabel: {
    flexDirection: 'column',
  },
}));

export default useStyles;
