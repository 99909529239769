import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  collapsibleRoot: {
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.colors.neutrals600,
  },
  buttonLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  icon: {
    color: theme.palette.colors.neutrals400,
  },
  chevron: {
    color: theme.palette.colors.neutrals200,
  },
}));

export default useStyles;
