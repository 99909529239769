import React from 'react';
import { capitalize } from 'lodash';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import TooltipIcon from '@root/components/tooltips/TooltipIcon';
import kbArticles from '@root/utils/kbArticles';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import useStyles from './CreditDetails.styles';

const CreditDetails = ({
  billingInfo,
  generationsUsage,
  creditRecord,
  isBillingInfoFetching,
  isCreditRecordsFetching,
  isGenerationsUsageFetching,
  creditLabel,
}) => {
  const classes = useStyles();

  const isAnnualWithRolloverDisabled = billingInfo?.period === 'yearly' && creditRecord?.rolloverEligible === false;
  const isFreeTrial = PLANS_IDS.free.includes(billingInfo?.priceId);
  const isEnterpriseNonSelfServe = billingInfo?.rank === 501;

  const overage = React.useMemo(() => {
    return creditRecord?.overage?.mainCredits || 0;
  }, [creditRecord]);

  const RolloverCreditLink = () => (
    <>
      <br />
      <br />
      <a
        target="__blank"
        href={kbArticles.rolloverCredits}
      >
        Click here to learn more about how rollover words work.
      </a>
    </>
  );

  const getRolloverContent = () => {
    if (creditRecord.rolloverEligible) {
      return (
        <>
          You have rollover words enabled!
          <RolloverCreditLink />
        </>
      );
    }

    return (
      <>
        Rollover words are disabled. Subscribe to an annual plan to unlock the ability to accrue rollover words!
        {!creditRecord.rolloverEligible && creditRecord.rolloverCredits > 0 && (
          <>
            <br />
            <br />
            For example, if you had been on an annual plan you would have
            {' '}
            {creditRecord.rolloverCredits}
            {' '}
            rollover words available.
          </>
        )}
        <RolloverCreditLink />
      </>
    );
  };

  return (
    <Grid
      item
      sm={12}
      md={4}
      lg={3}
    >
      <Grid container>
        <Grid item xs={12} className={classes.card}>
          <Typography className={classes.title} variant="h1">
            {isCreditRecordsFetching || !creditRecord
              ? <CircularProgress size={30} /> : `${capitalize(creditRecord.plan)}`}
          </Typography>
          <Typography className={classes.body} variant="body1">Plan</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.card}
        >
          <Typography className={classes.title} variant="h1">
            { isCreditRecordsFetching || isGenerationsUsageFetching
              ? <CircularProgress size={30} /> : generationsUsage.creditsUsed - overage}

          </Typography>
          <Typography className={classes.body} variant="body1">
            {`${capitalize(creditLabel)}s Used`}
          </Typography>
        </Grid>
        {creditRecord?.isLatest && !isFreeTrial && (
          <Grid className={classes.card} container>
            <>
              <Grid
                className={classes.creditsRemainingTitle}
                item
                xs={12}
              >
                <Typography className={classes.body} variant="body1">
                  {`${capitalize(creditLabel)}s Remaining`}
                </Typography>
              </Grid>
              {(
                isBillingInfoFetching
                  || isCreditRecordsFetching
                  || isGenerationsUsageFetching
                  || !creditRecord
              ) ? (
                <div className={classes.loading}>
                  <CircularProgress size={30} />
                </div>
                ) : (
                  <>
                    <Grid
                      item
                      xs={isAnnualWithRolloverDisabled || isEnterpriseNonSelfServe ? 12 : 6}
                    >
                      <Typography className={classes.title} variant="h1">
                        {creditRecord.mainCredits}
                      </Typography>
                      <Typography className={classes.body} variant="body1">{`${capitalize(creditLabel)}s`}</Typography>
                    </Grid>
                    { (isAnnualWithRolloverDisabled || isEnterpriseNonSelfServe) || (
                      <Grid
                        item
                        xs={6}
                      >
                        <Typography className={classes.title} variant="h1">
                          {creditRecord.rolloverEligible ? creditRecord.rolloverCredits : 0}
                        </Typography>
                        <Typography className={classes.body} variant="body1">
                          Rollover
                          <TooltipIcon
                            className={classes.tooltip}
                            color="white"
                            content={getRolloverContent()}
                            margin={2}
                            tooltipProps={{ dark: false, interactive: true }}
                          />
                        </Typography>
                      </Grid>
                    ) }
                  </>
                )}
            </>
          </Grid>
        ) }
      </Grid>
    </Grid>
  );
};

export default CreditDetails;
