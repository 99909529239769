import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  input: {
    minHeight: 26,
    minWidth: 12,
    '& > input': {
      padding: '0 4px',
    },
  },
}));

export default useStyles;
