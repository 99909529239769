import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.colors.lightGrey,
    flexGrow: 1,
    overflowY: 'auto',
    padding: '32px 48px',
  },
  title: {
    textAlign: 'start',
  },
}));

export default useStyles;
