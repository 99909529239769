import 'bootstrap/dist/css/bootstrap.css';
import { DateTime, Duration } from 'luxon';
import { setLogger } from 'react-query';
import getDebug from 'debug';
import pluralize from 'pluralize';
import React from 'react';
import ReactDOM from 'react-dom';

import api from '@root/api/newApi';
import Loading from '@root/components/Loading/Loading';
import hubspotService from '@root/services/hubspot.service';
import cohereService from '@root/services/cohere.service';
import getCurrentExternalSource from '@root/resources/externalSources';
import mixpanelService from '@root/services/mixpanel.service';
import AuthContext from '@root/resources/auth/auth.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import { logOutUser, refreshExternalSource } from '@root/services/auth.service';
import TemplatesContext from '@root/resources/templates/templates.context';
import OnboardingContext from '@root/resources/onboarding/onboarding.context';
import userService from '@root/resources/user/user.service';
import config from '@config';
import ErrorBoundary from '@root/components/ErrorBoundary';

import uiService from './services/uiNotification.service';
import initFontAwesome from './utils/initFontAwesome';
import errorLogger from './services/errorLogger';
import App from './App';
import segmentService from './services/segment.service';
import initPendo from './services/pendo.service';
import jwtService from './services/jwt.service';
import companyService from './resources/company/company.service';
import { setFeatures } from './utils/features';
import './services/ga.service';
import './services/facebook.service';
import './index.css';
import bigCommerceService from './services/bigCommerce.service';

const debug = getDebug('copysmith/index.js');

initFontAwesome();
errorLogger.start();
setLogger({
  error: (error) => {
    const status = error.response ? error.response.status : -1;
    if ([401, 403, 400, 404].includes(status)) { // processed in the interceptor of newApi.js
      return;
    }

    if (config.env === 'production' || config.env === 'staging') {
      if (status >= 500 && status < 600) {
        uiService.showErrorMessage('Internal server error. Please, refresh the page or contact support.');
        return;
      }

      uiService.showErrorMessage('Sorry an unexpected error occurred! Please, refresh the page or contact support.');
      errorLogger.reportError(error);
      return;
    }

    uiService.showErrorMessage(error.toString());
  },
});

const renderLoading = () => {
  const rootEl = document.getElementById('root');
  if (!(rootEl instanceof Element)) {
    throw new Error('invalid type');
  }
  window.react1 = React;
  ReactDOM.render(
    <React.StrictMode>
      <Loading />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const renderApp = ({ user, company }) => {
  const rootEl = document.getElementById('root');

  if (!(rootEl instanceof Element)) {
    throw new Error('invalid type');
  }

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <AuthContext.AuthProvider user={user} company={company}>
          <TemplatesContext.TemplatesProvider>
            <ExternalSourcesContext.ExternalSourcesProvider>
              <OnboardingContext.OnboardingProvider>
                <App />
              </OnboardingContext.OnboardingProvider>
            </ExternalSourcesContext.ExternalSourcesProvider>
          </TemplatesContext.TemplatesProvider>
        </AuthContext.AuthProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const loadData = async () => {
  const params = new URLSearchParams(window.location.search);
  const tokenValue = params.get('token-value');
  const tokenExpiresOn = Number.parseInt(params.get('token-expires-on'), 10);
  if (tokenValue && tokenExpiresOn) { // save token received from url for shadow login
    await jwtService.saveJwt({
      value: tokenValue,
      expiresOn: tokenExpiresOn,
    });
  }

  let isLoggedIn = await jwtService.isLoggedIn();
  const currentExternalSource = await getCurrentExternalSource();
  const isRefreshSucceed = await currentExternalSource.refresh({
    isLoggedIn,
    authService: {
      refreshExternalSource,
      logOut: () => logOutUser({ redirect: '/', log: false }),
    },
  });

  if (isRefreshSucceed) {
    isLoggedIn = await jwtService.isLoggedIn();
  }

  if (isLoggedIn) {
    const userResp = await api.user.getCurrentUser();

    if (userResp.status >= 300) {
      return { user: null, company: null, templates: null };
    }

    const promises = ([
      /* growthflagsService.fetchFeatureFlags({
        id: userResp?._id,
        data: {
          email: userResp?.email,
          companyId: userResp?.companyId,
          activeWorkspaceId: userResp?.activeWorkspaceId,
          createdOn: userResp?.createdOn,
        },
      }), */
      api.clientConfig.getClientConfig(),
      api.company.get(),
      api.billing.getContextInfo(),
    ]);

    const results = await Promise.all(promises.map((p) => p.catch((e) => e)));
    const [clientConfig, companyResp, billingData] = results;

    setFeatures(clientConfig.features);

    const company = companyService.expandCurrentCompany(companyResp);
    const user = userService.addBackwardCompUserFields(userResp, company, billingData);

    errorLogger.setUser(user._id);

    if (user.isShadowLogin) {
      return { user, company };
    }

    hubspotService.init(user);

    if (billingData.cohereEnabled) {
      cohereService.initUser(user._id, user.email, user.displayName);
    }
    if (window.clarity) {
      window.clarity('set', 'uid', user._id);
      window.clarity('set', 'currentLimitId', company.currentLimitId);
      window.clarity('set', 'email', user.email);
    }
    mixpanelService.init(user, company);
    segmentService.init(user, company);
    initPendo(user, company, billingData);
    if (window.profitwell) {
      window.profitwell('start', {
        user_email: user.email,
      });
    }

    // safety check, as we don't know why staging config is used on production sometimes
    if (config.env !== 'production' && window.location.hostname === 'app.copysmith.ai') {
      throw new Error('Invalid config is used for production environment');
    }

    return { user, company };
  }

  hubspotService.init();

  // await growthflagsService.fetchFeatureFlags({});

  return { user: null, company: null };
};

// Load global data, before showing the app.
(async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const signedPayloadParam = urlSearchParams.get('signed_payload_jwt');

  if (signedPayloadParam) {
    bigCommerceService.validateJwtPayload(signedPayloadParam);
  }

  // TODO: move loader to the body of the HTML page.
  renderLoading();
  const loadResult = await loadData();
  if (!loadResult) {
    return;
  }
  const { user, company } = loadResult;

  renderApp({ user, company });
  const appLoadTime = DateTime.now().toMillis();
  const delta = appLoadTime - window.loadingTime;
  const duration = Duration.fromMillis(delta).as('seconds');
  debug(`App loaded in ${duration} ${pluralize('second', duration)}.`);
})();
