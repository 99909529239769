import React from 'react';
import clsx from 'clsx';

import StepNumber from './StepNumber/StepNumber';
import useStyles from './Step.styles';

const Step = ({
  number,
  label,
  checked,
  disabled,
}: $TSFixMe) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.step, disabled && classes.disabled)}>
      <StepNumber
        number={number}
        disabled={disabled}
        checked={checked}
      />
      {label}
    </div>
  );
};

export default Step;
