import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: '0.5rem 1rem',
    background: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 'none',
    borderRadius: theme.border.radius.default,
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
    margin: '0 0.675rem',
    pointer: 'cursor',
    '&:hover': {
      opacity: '0.8',
    },
  },
}));
