import { useQuery } from 'react-query';

import api from '@root/api';

export default function useDocument(id, { enabled } = { enabled: true }) {
  return useQuery(
    ['documents.getById', id],
    () => api.documents.getById(id), {
      enabled,
    },
  );
}
