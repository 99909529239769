import config from '@root/config';

export default [
  {
    name: 'Features',
    linkObj: [
      {
        name: 'Templates',
        url: 'https://copysmith.ai/templates',
      },
      {
        name: 'Chrome Extension',
        url: config.chromeExtensionUrl,
        external: true,
        icon: 'external-link-alt',
      },
    ],
  },
  {
    name: 'Pricing',
    to: 'https://copysmith.ai/pricing',
  },
  {
    name: 'Blog & Tutorials',
    to: 'https://copysmith.ai/pricing',
  },
];
