import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

import api from '@root/api';

const useRoiStats = <T = any>(
  filters: any, queryOptions?: UseQueryOptions<any, Error, T, [string, any]>,
): UseQueryResult<T, Error> => useQuery(
    ['usage.roiStats', filters],
    () => api.usage.getRoiStats(filters),
    queryOptions,
  );

export default useRoiStats;
