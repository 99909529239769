import React from 'react';

import theme from '@root/material.theme';
import logo1 from '@root/assets/copysmith-logo-25.png';
import logo2 from '@root/assets/copysmith-logo-26.png';
import logo3 from '@root/assets/logo/white.png';
import logo4 from '@root/assets/logo/white-text.png';

export default [
  {
    name: 'Icon - light',
    component: (
      <div style={{ width: '70%', padding: '2rem' }}>
        <img
          src={logo1}
          alt="logo"
          width="100%"
        />
      </div>
    ),
  },
  {
    name: 'Icon - dark',
    component: (
      <div style={{
        background: theme.palette.colors.navy, width: '70%', padding: '2rem', borderRadius: theme.border.radius.lg,
      }}
      >
        <img
          src={logo3}
          alt="logo"
          width="100%"
        />
      </div>),
  },
  {
    name: 'Text - light',
    component: (
      <div style={{ width: '100%', padding: '2rem' }}>
        <img
          src={logo2}
          alt="logo"
          width="100%"
        />
      </div>
    ),
  },
  {
    name: 'Text - dark',
    component: (
      <div style={{
        background: theme.palette.colors.navy, width: '100%', padding: '1rem', borderRadius: theme.border.radius.lg,
      }}
      >
        <img
          src={logo4}
          alt="logo"
          width="100%"
        />
      </div>),
  },
];
