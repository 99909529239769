import React, { useMemo } from 'react';
import { Divider } from '@material-ui/core';

import Modal from '@root/components/modals/Modal';
import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';
import { modalsActions } from '@root/views/CreateAndExplore/context/confirmationModalsReducer';
import Button from '@root/components/buttons/Button';
import getTemplateActionStatuses from '@root/views/CreateAndExplore/CreateAndExplore.helpers';
import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import FieldNumber from './FieldNumber';
import useStyles from './TemplatePreviewModal.styles';

const TemplatePreviewModal = () => {
  const classes = useStyles();

  const { currentCompany: company } = AuthContext.useAuth();

  const {
    modalsState,
    modalsDispatch,
    templates,
    handleSaveToMyAccountClick,
  } = useCreateAndExploreContext();

  const { open, template } = modalsState.previewTemplate;

  const { fields = [] } = template || {};

  const handleModalClose = () => {
    modalsDispatch({
      type: modalsActions.TOGGLE_PREVIEW_TEMPLATE_MODAL,
    });
  };

  const handleSaveClick = (e) => {
    handleModalClose();
    handleSaveToMyAccountClick(e, template?._id);
  };

  const {
    isSaveToAccountVisible,
    isTemplateInLibrary,
    isCompanyTemplate,
  } = getTemplateActionStatuses({ templates, template, company });

  const saveTooltipContent = useMemo(() => {
    if (isCompanyTemplate) {
      return 'You are the creator of this template.';
    }

    return '';
  }, [isTemplateInLibrary, isCompanyTemplate]);

  return (
    <Modal
      open={open}
      noContentMargin
      onClose={handleModalClose}
      keepMounted
      classNames={{ paper: classes.modal, root: classes.modalRoot }}
    >
      <div className={classes.content}>
        <h3 className={classes.sectionTitle}>Required Inputs</h3>
        <p className={classes.sectionDescription}>
          These are the fields that will be required for you to input in order to generate content
          using this template.
        </p>

        <div className={classes.fields}>
          {fields.map((field, index) => (
            <div key={field?._id} className={classes.fieldContainer}>
              <FieldNumber number={index + 1} />
              <p className={classes.fieldName}>{field.name}</p>
            </div>
          ))}
        </div>

        <Divider className={classes.divider} />

        <p className={classes.generatedContentLabel}>Generated content</p>

        <div className={classes.outputExampleContent}>
          {template?.outputExample || '-'}
        </div>

        <section className={classes.actionButtons}>
          <Button
            variant="text"
            onClick={handleModalClose}
          >
            Cancel
          </Button>

          <Button
            disabled={!isSaveToAccountVisible}
            onClick={handleSaveClick}
          >
            {isTemplateInLibrary ? 'Added to your account' : 'Save to My Account'}
          </Button>
        </section>
      </div>
    </Modal>
  );
};

export default TemplatePreviewModal;
