import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  newUserContainer: {
    position: 'absolute',
    top: '5rem',
    right: '22rem',
    zIndex: 1001,
  },
  newUserTooltip: {
    position: 'absolute',
    top: '0.25rem',
    right: '0.5rem',
    fontSize: '0.9rem',
    cursor: 'pointer',
  },
  newUserText: {
    marginTop: '0.25rem',
    fontWeight: 400,
  },
}));
