import React from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import authContext from '@root/resources/auth/auth.context';
import subscriptionsContext from '@root/resources/billing/subscriptions.context';
import { PLANS_IDS, PLANS_NAMES_LIST } from '@root/resources/billing/billingInfo.helpers';

const ResubscribeModal = ({ onClose }) => {
  const history = useHistory();
  const {
    currentCompany: { billing: { recentLimitId }, suspendStart: isSuspended },
  } = authContext.useAuth();
  const { pricingData } = subscriptionsContext.useSubscriptions();

  const hadStarter = [...PLANS_IDS.starters,
    ...PLANS_IDS.legacyStarters,
    ...PLANS_IDS.march2023StarterAnnual,
    ...PLANS_IDS.march2023StarterMonthly].includes(recentLimitId);
  const hadPro = [...PLANS_IDS.pros, ...PLANS_IDS.legacyPros, ...PLANS_IDS.march2023Pros].includes(recentLimitId);

  const recentPlanName = ((hadStarter || hadPro) && PLANS_NAMES_LIST.find(({ ids }) => ids.includes(recentLimitId))?.name) || '';

  const handleConfirm = () => {
    if (hadStarter && !isSuspended) {
      const plan = pricingData.find(({ internalName }) => internalName === 'starter');
      history.push('/checkout', { plan });
    } else if (hadPro && !isSuspended) {
      const plan = pricingData.find(({ internalName }) => internalName === 'pro');
      history.push('/checkout', { plan });
    } else {
      onClose();
    }
  };

  return (
    <ConfirmationModal
      title="Renew Subscription"
      text={`To access your files and get back to work, reactivate your ${recentPlanName} subscription below.`}
      confirmButtonText="Reactivate Account"
      onConfirm={handleConfirm}
      onClose={onClose}
      noCancel
      maxWidth="xs"
    />
  );
};

export default ResubscribeModal;
