import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import folderResource from '@root/resources/folder';
import { KeywordInputAtomField } from '@root/components/Fields';
import { InputField, FormField } from '@root/components/form';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';
import Button from '@root/components/buttons/Button';
import api from '@root/api';

import useStyles from './EditPresetModal.styles';

const EditPresetModal = ({
  modalOpen,
  closeModal,
}) => {
  const classes = useStyles();

  const [isScrapping, setIsScrapping] = useState(false);
  const [scrapedData, setScrapedData] = useState({
    companyName: null,
    companyDescription: null,
    url: null,
  });

  const { state: { modalContent } } = useGlobalModals();
  const { mutateAsync: updateFolder } = folderResource.useUpdateFolder();

  const folderId = modalContent?.folder?._id;
  const title = modalContent?.folder?.title;
  const presetSettings = modalContent?.folder?.presetSettings;
  const folderType = modalContent?.folder?.parentFolderId ? 'folder' : 'project';

  const handleModalClose = () => {
    setScrapedData({
      companyName: null,
      companyDescription: null,
      url: null,
    });
    closeModal();
  };

  const submit = async ({ audience, keywords, companyName, companyDescription, url }) => {
    const newPresetSettings = {
      audience,
      keywords,
      company: companyName,
      companyDescription,
      url,
    };

    const presetsNotEmpty = Object.values(newPresetSettings).some(
      (x) => x.length !== 0,
    );

    await updateFolder({
      folderId,
      update: {
        presetSettings: newPresetSettings,
        enablePrefill: presetsNotEmpty,
      },
    });

    handleModalClose();
  };

  const handleScrape = async (url) => {
    setIsScrapping(true);
    const scrapedUrlContents = await api.company.parse({
      url,
    });
    if (scrapedUrlContents) {
      setScrapedData({
        companyName: scrapedUrlContents.name,
        companyDescription: scrapedUrlContents.description,
        url,
      });
    }
    setIsScrapping(false);
  };

  const initialValues = {
    companyName: scrapedData.companyName || presetSettings?.company || '',
    companyDescription: scrapedData.companyDescription || presetSettings?.companyDescription || '',
    audience: presetSettings?.audience || '',
    url: scrapedData.url || presetSettings?.url || '',
    keywords: presetSettings?.keywords || [],
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      title={`${title} Settings`}
      subtitle={(
        <span>
          When creating new files within this
          {' '}
          {folderType}
          ,
          the input fields will be auto-filled according
          to the settings you have chosen for the
          {' '}
          {folderType}
          .
          <span className={classes.redText}> If left blank, Copysmith will use your Profile defaults.</span>
        </span>
)}
      classNames={{ paper: classes.paper }}
    >
      <div className={classes.presetFormRoot}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={submit}
        >
          {({ values }) => (
            <Form>
              <div className={classes.presetInputs}>
                <div className={classes.scrapeFieldContainer}>
                  <div className={classes.scrapeFieldContainer}>
                    <FormField
                      name="url"
                      component={InputField}
                      label="Scrape URL"
                      labelTooltip={`The URL you enter will be scraped for metadata. This data can help you automatically populate the ${folderType} settings below.`}
                      classNames={{ root: classes.scrapeField }}
                    />
                    <div className={classes.scrapeButtonContainer}>
                      <Button
                        className={classes.scrapeButton}
                        disabled={values.url.trim().length === 0 || isScrapping}
                        onClick={() => handleScrape(values.url)}
                      >
                        Get data
                      </Button>
                    </div>
                  </div>
                </div>

                <FormField
                  name="companyName"
                  component={InputField}
                  label="Name"
                  placeholder="Add company or product name"
                />

                <KeywordInputAtomField
                  name="keywords"
                  title="Keyword bank"
                />

                <FormField
                  name="audience"
                  component={InputField}
                  label="Audience bank"
                  placeholder="Add up to 3 audiences, e.g. Millennials, Urbanites"
                />

                <FormField
                  name="companyDescription"
                  component={InputField}
                  multiline
                  label="Description"
                  placeholder="e.g. Copysmith is a AI-powered copywriting tool. We can generate anything from product descriptions to ads to landing pages."
                />
              </div>

              <div className={classes.buttonsContainer}>
                <Button variant="text" onClick={handleModalClose}>Discard</Button>
                <Button type="submit">Update</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditPresetModal;
