import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface CreateCheckoutSessionOptions extends RequestOptions {
  price: string;
  papCookie?: string;
}

export interface CreateStripeCustomerOptions extends RequestOptions {
  papCookie?: string;
}

export interface CreateSubscriptionOptions extends RequestOptions {
  price: string;
  papCookie?: string;
}

export interface UpdateSubscriptionOptions extends RequestOptions {
  price: string;
}

export interface ProrateOptions extends RequestOptions {
  newPrice: string;
}

export interface UpdateCardOptions extends RequestOptions {
  paymentMethod: string;
}

export interface SessionInfoOptions extends RequestOptions {
  id: string;
}

export interface DecrementPlagiarismCheckLimitOptions extends RequestOptions {
  amount: number;
}

export interface PlanInfoOptions extends RequestOptions {
  plan: string;
}

export interface ValidatePromoOptions extends RequestOptions {
  promo: string;
}

export interface ApplyAddonOptions extends RequestOptions {
  addon: string;
  quantity?: string;
}

export interface CancelAddonOptions extends RequestOptions {
  addon: string;
  quantity?: string;
}

export interface UpdateEmailOptions extends RequestOptions {
  email: string;
}

export interface SelfServeOptions extends RequestOptions {
  wordsPerMonthTier: 1 | 2;
  firstName: string;
  surname: string;
  contractDuration: 6 | 12;
  billingCycle: "monthly" | "yearly";
}

export class Billing {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async createCheckoutSession(
      data: CreateCheckoutSessionOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/checkoutSession", data, options);

    return result;
  }

  public async createStripeCustomer(
      data: CreateStripeCustomerOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/createStripeCustomer", data, options);

    return result;
  }

  public async createSubscription(
      data: CreateSubscriptionOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription", data, options);

    return result;
  }

  public async cancelSubscription(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del("/billing/subscription", data, options);

    return result;
  }

  public async suspendSubscription(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/suspend", data, options);

    return result;
  }

  public async renewSuspendedSubscription(
      data: RequestOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/renewSuspendedSubscription", data, options);

    return result;
  }

  public async renewSubscription(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/renew", data, options);

    return result;
  }

  public async updateSubscription(
      data: UpdateSubscriptionOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.put("/billing/subscription", data, options);

    return result;
  }

  public async prorateSubscription(data: ProrateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/prorate", data, options);

    return result;
  }

  public async getLimits(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/limits", data, options);

    return result;
  }

  public async getInfo(data?: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/info", data, options);

    return result;
  }

  public async getContextInfo(data?: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/contextInfo", data, options);

    return result;
  }

  public async updateCard(data: UpdateCardOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/billing/subscription/card", data, options);

    return result;
  }

  public async sessionInfo(data: SessionInfoOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get(`/billing/subscription/session/${data.id}`, { token: data.token }, options);

    return result;
  }

  public async decrementPlagiarismCheckLimit(
      data: DecrementPlagiarismCheckLimitOptions,
      options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/decrementPlagiarismCheckLimit",  data, options);

    return result;
  }

  public async planInfo(data: PlanInfoOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/planInfo", data, options);

    return result;
  }

  public async validatePromo(data: ValidatePromoOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/promoValidation", data, options);

    return result;
  }

  public async getPricing(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/subscription/pricing", data, options);

    return result;
  }

  public async applyAddon(data: ApplyAddonOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/billing/subscription/addon", data, options);

    return result;
  }

  public async cancelAddon(data: CancelAddonOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del("/billing/subscription/addon", data, options);

    return result;
  }

  async updateEmail(data: UpdateEmailOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
      const result = await this.client.put("/billing/subscription/email", data, options);
      return result;
  }

  public async createSelfServeEnterprisePipeline(data: SelfServeOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/self-serve", data, options);

    return result;
  }

  public async getSelfServeEnterprisePipeline(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/self-serve", data, options);

    return result;
  }

  public async getSelfServeEnterpriseEnvelopeStatus(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/billing/self-serve/envelope", data, options);

    return result;
  }

  public async applyCancelationDiscount(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/cancelation-discount", data, options);

    return result;
  }

  public async createOveragesSettings(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/overages/settings", data, options);

    return result;
  }

  public async updateOveragesSettings(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/billing/subscription/overages/settings", data, options);

    return result;
  }

  public async deleteOveragesSettings(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.del("/billing/subscription/overages/settings", data, options);

    return result;
  }

  public async payOverageInvoice(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/billing/subscription/overages/pay", data, options);

    return result;
  }
}
