import React, { useState } from 'react';
import clsx from 'clsx';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Checkbox, TableRow, TableCell, Collapse } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import EditProductRow from './EditProductRow';
import ProductStatus from './ProductStatus';
import useStyles from './table.styles';

const ProductRow = ({ product, selected, toggleSelected }) => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  // const handleRowClick = () => {
  //   history.push(`/catalog/${id}/product/${product._id}`);
  // };

  return (
    <>
      <TableRow className={clsx(
        classes.tableRow,
        isOpen && classes.tableRowOpen,
      )}
      >
        <TableCell
          padding="checkbox"
          className={clsx(
            classes.rowCell,
            isOpen && classes.rowCellOpen,
          )}
        >
          <Checkbox
            checked={selected}
            onClick={toggleSelected}
          />
        </TableCell>

        <TableCell className={clsx(
          classes.id,
          classes.rowCell,
          [classes.rowCellOpen, isOpen],
        )}
        >
          {product.externalId}
        </TableCell>

        <TableCell className={clsx(
          classes.rowCell,
          [classes.rowCellOpen, isOpen],
        )}
        >
          {product?.data?.title || 'No Title'}
        </TableCell>

        <TableCell className={clsx(
          classes.rowCell,
          [classes.rowCellOpen, isOpen],
        )}
        >
          {DateTime.fromSeconds(product.updatedOn).toFormat('MMMM dd yyyy')}
        </TableCell>

        <TableCell className={clsx(
          classes.rowCell,
          [classes.rowCellOpen, isOpen],
        )}
        >
          <ProductStatus status={product.status} />
        </TableCell>
        <TableCell className={clsx(
          classes.rowCell,
          classes.alignRight,
          [classes.rowCellOpen, isOpen],
        )}
        >
          <div onClick={() => setIsOpen(!isOpen)} className={classes.tableAction}>
            {isOpen ? <IconChevronDown /> : <IconChevronUp />}
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={classes.editTableRow}>
        <TableCell colSpan={6} padding="none">
          <Collapse hidden={!isOpen} in={isOpen}>
            <EditProductRow product={product} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductRow;
