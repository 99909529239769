import { loadStripe } from '@stripe/stripe-js';

import api from '@root/api';
import config from '@root/config';

const stripePromise = loadStripe(config.stripeKey);

const getStripeCheckoutAddOns = (
  successURL,
  failureURL,
) => api.billing.createCheckoutSession({
  price: 'blogPost-addons',
  successUrl: successURL,
  failureUrl: failureURL,
});

const getStripeCheckout = (
  price,
  papCookie,
) => api.billing.createCheckoutSession({
  price,
  papCookie,
});

const cancelSubscription = async (data) => {
  const resp = await api.billing.cancelSubscription(data);

  return {
    result: resp,
    didDelete: true,
  };
};

const getPlanInfo = async (plan) => {
  const resp = await api.billing.planInfo({
    plan,
  });

  return resp;
};

const getProration = async (newPrice) => {
  const resp = await api.billing.prorateSubscription({
    newPrice,
  });

  return resp;
};

const updatePlan = async (price) => {
  const resp = await api.billing.updateSubscription({
    price,
  });

  return resp;
};

const handleAddOnButtonClick = async () => {
  const stripe = await stripePromise;
  const currentURL = window.location.href;

  const response = await getStripeCheckoutAddOns(
    `${currentURL}&success=true`,
    currentURL,
  );

  const session = response.id;
  await stripe.redirectToCheckout({
    sessionId: session,
  });
};

const getInvoiceAmount = ({
  planAmount,
  prorationTotal,
}) => {
  if (prorationTotal && prorationTotal < 0) {
    return 0;
  }

  if (prorationTotal) {
    return prorationTotal;
  }

  return planAmount;
};

export default {
  handleAddOnButtonClick,
  getStripeCheckout,
  cancelSubscription,
  getPlanInfo,
  getProration,
  updatePlan,
  getInvoiceAmount,
};
