import React from 'react';
import { makeStyles } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import { escapeText } from '@root/utils/text.utils';
import useTextSelect from '@root/utils/hooks/useTextSelect';

const useStyles = makeStyles(() => ({
  content: {
    whiteSpace: 'break-spaces',
  },
}));

const CommonPreview = ({
  content,
  onTextSelect = () => null,
}) => {
  const classes = useStyles();
  const ref = React.useRef();

  useTextSelect((selection) => {
    const text = escapeText(selection.toString());

    if (!text || !escapeText(content).includes(text)) {
      onTextSelect(null);
      return;
    }

    onTextSelect({
      text,
      field: null,
    });
  }, ref);

  return (
    <div className={classes.content} ref={ref}>
      {ReactHtmlParser(content)}
    </div>
  );
};

export default CommonPreview;
