import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    padding: '0px 16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',

    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '40px',

    padding: '6px 8px',
    borderRadius: '6px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },

  activeHeader: {
    backgroundColor: theme.palette.colors.neutrals50,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px;',
  },

  titleIcon: {
    color: theme.palette.colors.neutrals400,
  },

  divider: {
    width: 'calc(100% + 48px)',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    height: '1px',
    backgroundColor: theme.palette.colors.neutrals100,

    margin: '8px 0px 12px',
  },

  foldersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    padding: '0px 16px',

    overflow: 'auto',

    /* width */
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '10px',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.colors.neutrals300,
      width: 6,
      borderRadius: '10px',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.colors.neutrals400,
    },
  },
}));

export default useStyles;
