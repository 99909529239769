import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
  },
  paper: {
  },
  modalTitle: {
    ...theme.typography.h6,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  modalSubtext: {
    ...theme.typography.body2,
  },
  modalBodyText: {
    ...theme.typography.body1,
    whiteSpace: 'pre-line',
    marginTop: '0.5rem',
    height: '76%',
    overflowY: 'auto',
  },
  cancelButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '1rem',
    cursor: 'pointer',
  },
}));

const DEPRECATEDConfirmationModal = ({
  modalOpen,
  setModalOpen,
  handleClick,
  titleText,
  subText,
  bodyText,
  buttonText,
  disableCancelButton = false,
  disableBackdropClick = true,
}) => {
  const classes = useStyles();
  const submit = () => {
    handleClick();
    if (setModalOpen) {
      setModalOpen(false);
    }
  };

  const close = (_e, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    if (setModalOpen) {
      setModalOpen(false);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={close}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitle}>
          {titleText}
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <div className={classes.modalSubtext}>
          <i>{subText}</i>
        </div>
        <p className={classes.modalBodyText}>
          {bodyText}
        </p>
      </DialogContent>
      <DialogActions className={classes.cancelButtonWrap}>
        {!disableCancelButton && (
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
        )}
        <Button
          color="primary"
          onClick={submit}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DEPRECATEDConfirmationModal;
