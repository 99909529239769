import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useUpdateBillingEmail() {
  const queryClient = useQueryClient();

  return useMutation(
    (email) => api.billing.updateEmail({ email }),
    {
      onSuccess: (response, upcomingInvoiceEmail) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.setQueryData(['billing.getInfo', {}], (billingInfo) => ({
          ...billingInfo,
          upcomingInvoiceEmail,
          paymentMethod: {
            ...billingInfo.paymentMethod,
            email: upcomingInvoiceEmail,
          },
        }));

        return response;
      },
    },
  );
}
