import useDeleteDocument from './useDeleteDocument';
import useDocuments from './useDocuments';
import useVoteDocument from './useVoteDocument';
import useUpdateDocument from './useUpdateDocument';
import useLikeDocument from './useLikeDocument';
import useFlagDocument from './useFlagDocument';
import useSharedDocuments from './useSharedDocuments';
import usePublishDocument from './usePublishDocument';
import useCreateDocument from './useCreateDocument';
import useDocument from './useDocument';
import useFreeFormLatestDocuments from './useFreeFormLatestDocuments';

export default {
  useDeleteDocument,
  useDocuments,
  useVoteDocument,
  useUpdateDocument,
  useLikeDocument,
  useFlagDocument,
  useSharedDocuments,
  usePublishDocument,
  useCreateDocument,
  useDocument,
  useFreeFormLatestDocuments,
};
