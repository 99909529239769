import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderColor: theme.palette.colors.green,
    borderRadius: theme.border.radius.sm,
    border: '0.1rem solid',
    padding: '0.15rem 0.35rem',
    color: (props) => (props.isNavbar ? theme.palette.colors.white : theme.palette.colors.green),
    backgroundColor: (props) => (props.isNavbar ? theme.palette.colors.green : undefined),
    fontSize: '0.7rem',
    marginLeft: (props) => (props.isNavbar ? 0 : '0.8rem'),
    marginRight: (props) => (props.isNavbar ? '0.8rem' : 0),
    maxHeight: '1.5rem',
    fontWeight: 'bold',
  },

}));

/**
 * A small green badge to indicate that a template is in 'Custom'
 * @param isNavbar gives a solid coloring and different margins to fit in the navbar
 */
const CustomBadge = ({ isNavbar = false }) => {
  const classes = useStyles({ isNavbar });
  return (
    <div className={classes.container}>
      Custom
    </div>
  );
};

export default CustomBadge;
