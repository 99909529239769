import React from 'react';
import {
  Bookmark,
  BookmarkBorder,
  Delete,
  Edit,
  FileCopy,
  ThumbDownOutlined,
} from '@material-ui/icons';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import ShopifyExportButton from '@root/components/integrations/Shopify/ShopifyExportButton';

import FooterButton from './components/FooterButton';
import PublishButton from './components/PublishButton';
import FlagButton from './components/FlagButton';
import CreateMenuButton from './components/CreateMenuButton';
import PersonalizeButton from './components/PersonalizeButton';
import useStyles from './PreviewFooter.styles';

const PreviewFooter = ({
  document,
  fileData,
  onEdit,
  onDelete,
  onCopy,
  onLike,
  onFlag,
  flagged,
  onMoreLikeThis,
  onPersonalize,
  onSubmitToReview,
  options,
  addIcon,
  customFooterAction,
  usedForPersonalization,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const hasTeamPermissions = currentUser.permissions.teams;
  const personalizedGenerationsAllowed = currentUser.featureToggles.personalizedGenerations;

  const { liked, templateType } = document;
  const hasFile = !!fileData?._id;

  const showShopifyButton = hasFile
    && options.canEdit
    && templateType === 'productDescription' && currentUser.permissions.shopify;
  const canFlag = currentUser.permissions.workflowQueue
    && hasTeamPermissions
    && options.canFlag;
  const canPersonalize = personalizedGenerationsAllowed && options.canPersonalized;
  const hasAnyButtons = options.canEdit || options.canDelete || options.canCopy
    || canFlag || canPersonalize || customFooterAction || hasFile || options.canEdit
    || showShopifyButton;

  if (!hasAnyButtons) {
    return null;
  }

  const isMarkedAsBad = Object.values(document.submittedExamples || {}).includes('bad');

  return (
    <div
      className={classes.buttonsWrap}
      aria-hidden="true"
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.leftButtonsWrap}>
        {options.canEdit && (
          <FooterButton
            Icon={Edit}
            onClick={onEdit}
            className={classes.buttonFirst}
          />
        )}
        {options.canDelete && (
          <FooterButton
            Icon={Delete}
            onClick={() => onDelete(document._id)}
          />
        )}
        {options.canCopy && (
          <FooterButton Icon={FileCopy} onClick={onCopy} />
        )}
        <div className={classes.separator} />
        {canFlag && (
          <FlagButton
            flagged={flagged}
            isEnabled={options.canEdit}
            onClick={onFlag}
          />
        )}
        {options.canLike && (
          liked ? (
            <FooterButton
              Icon={Bookmark}
              onClick={onLike}
              className={classes.active}
            />
          ) : (
            <FooterButton Icon={BookmarkBorder} onClick={onLike} />
          )
        )}
        {canPersonalize && (
          <PersonalizeButton
            classes={classes}
            onPersonalize={onPersonalize}
            usedForPersonalization={usedForPersonalization}
          />
        )}
        {options.canSubmitExample && (
          <SimpleTooltip
            content="Rate the quality of generation."
            wrapped
          >
            <FooterButton
              Icon={ThumbDownOutlined}
              onClick={onSubmitToReview}
              className={clsx({
                [classes.active]: isMarkedAsBad,
              })}
            />
          </SimpleTooltip>
        )}
        {addIcon && addIcon}
      </div>
      <div className={classes.rightButtonsWrap}>
        {customFooterAction}
        {showShopifyButton && (
          <ShopifyExportButton
            document={document}
          />
        )}
        {options.canEdit && (
          <PublishButton
            document={document}
            publishLog={document.publishLog}
            fileId={document.fileId}
            templateType={document.templateType}
          />
        )}
        {hasFile && (
          <CreateMenuButton
            onMoreLikeThis={onMoreLikeThis}
            document={document}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewFooter;
