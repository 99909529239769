import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useUpdateCatalogItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.catalogItem.update(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['catalog.items', data.catalogItem.catalogId, '']);
      },
    },
  );
}
