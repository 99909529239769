import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import useQuery from '@root/common/useQuery';
import TemplatesContext from '@root/resources/templates/templates.context';
import billingResource from '@root/resources/billing';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import { searchGenerators } from '@root/views/TemplatesDashboardNew/helpers';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useModalState from '@root/utils/hooks/useModalState';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import EditorBanner from './EditorBanner';
import addFavoriteData from './helpers/addFavoriteData';
import filterTemplatesByBucket from './helpers/filterTemplatesByBucket';
import madeForYou from './helpers/madeForYou';
import TemplateListWrapper from './TemplateListWrapper';
import TemplateCard from './TemplateCard';
import MadeForYouCopy from './MadeForYouCopy';
import EmptySearch from './EmptySearch';
import TemplateSearch from './TemplateSearch';
import useStyles from './TemplateList.styles';
import { TEMPLATE_CREATE_MODAL_ID } from './modals';
import NewCustomTemplateButton from './NewCustomTemplateButton';
import CustomTemplateCard from './CustomTemplateCard';

const getCurrentBucketDefault = (urlParamBucket) => {
  return urlParamBucket;
};

const TemplateList = ({
  hideSearch,
  classNames = {},
  getCurrentBucket = getCurrentBucketDefault,
  isCardsDisabled,
  cardsDisabledTooltipText,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const { getTemplate, templatesList } = TemplatesContext.useTemplates();
  const { data: { maxCustomTemplates } = {} } = billingResource.useLimits();
  const { bucket } = useParams();
  const history = useHistory();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { filterGenerators } = currentExternalSource;
  const { state: {
    query: templatesQuery,
    favorites,
    customTemplatesFilter,
    isBulk,
  } } = useTemplatesDashboard();
  const currentBucket = getCurrentBucket(bucket);

  const { featureTourActive } = onboardingContext.useOnboarding();

  useEffect(() => {
    if (currentBucket === 'search' && templatesQuery === null) {
      history.push('/create');
    }
  }, [templatesQuery, currentBucket]);

  const instructLandingEnabled = currentUser.featureToggles.landingPageInstruct;
  const canManageTemplateBuilder = currentUser.permissions.manageTemplateBuilder;

  const { dispatch } = useGlobalModals();

  const [isLimitsModalOpen, openLimitsModal, closeLimitsModal] = useModalState();

  const isCustomTab = currentBucket === 'custom';

  const query = useQuery();
  const queryTemplateType = query.get('type');

  const goToPlansPage = () => {
    history.push('/profile/plans');
  };

  const handleCreateModal = (templateType) => {
    query.delete('type');
    history.replace({ search: query.toString() });
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: getTemplate(templateType),
      },
    });
  };

  useEffect(() => {
    if (queryTemplateType) {
      handleCreateModal(queryTemplateType);
    }
  }, [queryTemplateType]);

  let list = filterGenerators(templatesList)
    .map((item) => addFavoriteData(item, favorites));

  // For the main buckets
  if (currentBucket && currentBucket !== 'search' && currentBucket !== 'made-for-you') {
    list = filterTemplatesByBucket(list, currentBucket);
  }

  // Made for you logic
  if (currentBucket === undefined || currentBucket === 'made-for-you') {
    list = madeForYou(list);
    if (featureTourActive) {
      list = list.filter((t) => t.templateType !== 'blogIntro');
      list.unshift(getTemplate('blogIntro'));
    }
  }

  // Search logic
  if (currentBucket && currentBucket === 'search' && templatesQuery) {
    list = searchGenerators(templatesQuery, list)
      .filter((item) => !!item.buckets);
  }

  list = list.filter(customTemplatesFilter);

  const isSearching = useMemo(() => currentBucket === 'search', [currentBucket]);
  const isBannerVisible = useMemo(() => (
    !isSearching && (currentBucket === undefined || currentBucket === 'made-for-you')
  ), [currentBucket, isSearching]);

  return (
    <TemplateListWrapper>
      {() => {
        const templatesNotDeleted = list.filter((template) => !template.deletedOn);
        const isAddDisabled = templatesNotDeleted.length >= maxCustomTemplates;
        return (
          <>
            {!instructLandingEnabled && <MadeForYouCopy />}
            {instructLandingEnabled && !hideSearch && (
              <div className={classes.searchContainer}>
                <TemplateSearch disableMargins />
              </div>
            )}
            <EmptySearch templates={templatesNotDeleted} />
            <div className={clsx(classes.list, classNames.list)}>
              {isBannerVisible && <EditorBanner />}

              {templatesNotDeleted.map((template) => (template.templateType.startsWith('custom_') && !isBulk ? (
                <CustomTemplateCard
                  template={template}
                  key={template._id}
                />
              ) : (!template.templateType.startsWith('custom_') || isBulk) && (
                <TemplateCard
                  template={template}
                  disabled={isCardsDisabled}
                  disabledTooltipText={cardsDisabledTooltipText}
                  key={template.templateType}
                />
              )))}
              {isCustomTab && canManageTemplateBuilder && (
                <NewCustomTemplateButton
                  disabled={isAddDisabled}
                  openLimitsModal={openLimitsModal}
                />
              )}
            </div>

            {isLimitsModalOpen && (
              <ConfirmationModal
                title="Template limit"
                confirmButtonText="Upgrade"
                onConfirm={goToPlansPage}
                onClose={closeLimitsModal}
              >
                It looks like your generator limit is reached.
                Please upgrade to get more generators.
              </ConfirmationModal>
            )}
          </>
        );
      }}
    </TemplateListWrapper>
  );
};

export default TemplateList;
