const getParamByName = (name, location) => {
  const { search } = location;
  const value = new URLSearchParams(search).get(name);

  return value;
};

const removeParamByName = (name, history, location) => {
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  if (queryParams.has(name)) {
    queryParams.delete(name);
    history.replace({
      search: queryParams.toString(),
    });
  }
};

const setParamByName = (name, value, history, location) => {
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  queryParams.set(name, value);
  history.replace({
    search: queryParams.toString(),
  });
};

export { getParamByName, setParamByName, removeParamByName };
