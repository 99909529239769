import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  largeHeaderText: {
    marginBottom: '0',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '58px',
    textAlign: 'left',

  },
  mediumHeaderText: {
    marginBottom: '0',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 700,
    fontSize: '36px',
    textAlign: 'left',
  },
  smallBodyText: {
    fontFamily: '"Inter", sans-serif',
    textAlign: 'left',
    marginBottom: '0',
    width: '100%',
    fontSize: '14px',
    margin: '0',
  },
  largeBodyText: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '18px',
    color: theme.palette.text.secondary,
    fontStretch: '100%',
    fontWeight: '500',
    lineHeight: '40px',
    textAlign: 'left',
    marginBottom: '0',
    width: '100%',
    margin: '0',
  },
}));

export const LargeHeaderText = ({ children }) => {
  const classes = styles();
  return (
    <div className={classes.largeHeaderText}>
      {children}
    </div>
  );
};

export const MediumHeaderText = ({ children }) => {
  const classes = styles();
  return (
    <div className={classes.mediumHeaderText}>
      {children}
    </div>
  );
};

export const LargeBodyText = ({ children }) => {
  const classes = styles();
  return (
    <div className={classes.largeBodyText}>
      {children}
    </div>
  );
};
export const SmallBodyText = ({ children }) => {
  const classes = styles();
  return (
    <div className={classes.smallBodyText}>
      {children}
    </div>
  );
};
