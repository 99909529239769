import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MenuOpen = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M3 18V16.5H16V18H3ZM19.95 16.7L15.225 11.975L19.925 7.275L21 8.35L17.375 11.975L21.025 15.625L19.95 16.7ZM3 12.7V11.2H13V12.7H3ZM3 7.5V6H16V7.5H3Z" fill="black" />
    </SvgIcon>
  );
};

export default MenuOpen;
