import React from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';

import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';
import { tabs } from '@root/views/CreateAndExplore/CreateAndExplore.constants';
import { communityActions } from '@root/views/CreateAndExplore/context/createAndExploreReducer';
import { colors } from '@root/material.theme';

import useStyles from './Header.styles';

const Header = () => {
  const classes = useStyles();

  const {
    state: { activeTab },
    dispatch,
  } = useCreateAndExploreContext();

  const handleActiveTabChange = (event, newValue) => {
    dispatch({ type: communityActions.SET_ACTIVE_TAB, payload: newValue });
  };

  const isCommunityTab = activeTab === tabs.COMMUNITY;

  return (
    <div className={classes.headerContainer}>
      <Typography variant="h3" className={classes.title}>
        {isCommunityTab
          ? 'For the community from the community'
          : 'Create custom content generator and share it with the world'}
      </Typography>

      <Typography variant="body2" className={classes.subtitle}>
        {isCommunityTab
          ? 'Browse our library of use cases for Copysmith created by users like you.'
          : "It's time to write something amazing! Let your creativity flow and create something that you're proud of."}
      </Typography>

      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        classes={{
          root: classes.tabsContainer,
          flexContainer: classes.tabsFlex,
          indicator: classes.tabsIndicator,
        }}
        indicatorColor={colors.white}
      >
        <Tab
          label="Community"
          value={tabs.COMMUNITY}
          classes={{ root: classes.tabButton, selected: classes.selectedTab }}
          disableRipple
        />
        <Tab
          label="Custom content generator"
          value={tabs.CREATE}
          classes={{ root: classes.tabButton, selected: classes.selectedTab }}
          disableRipple
        />
      </Tabs>
    </div>
  );
};

export default Header;
