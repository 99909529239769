import React, { } from 'react';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { capitalize } from 'lodash';
import clsx from 'clsx';

import { FormField, InputField, SelectField, KeywordsField } from '@root/components/form';
import translateLanguages from '@root/components/translateLanguages';
import ScrollFade from '@root/components/ScrollFade';
import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import billingResource from '@root/resources/billing';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';

import { templates } from '../../Editor.constants';
import TemplateSelect from '../TemplateSelect';

import { getInitialValues, getSchema, variantsOptions, creativityOptions, toneOptions } from './InputForm.helpers';
import useStyles from './InputForm.styles';
import ExtraSettings from './components/ExtraSettings';
import SEOKeywords from './components/SEOKeywords';

const MultilineInput = (props) => <InputField multiline {...props} />;
const fieldsMap = {
  string: InputField,
  keywords: KeywordsField,
  singleSelect: SelectField,
  multilineString: MultilineInput,
};

const InputForm = ({
  template,
  onTemplateChange,
  formRef,
  onGenerate,
  readOnly,
}) => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();

  const { data: { limit: credits } = {} } = billingResource.useLimits();
  const insufficientCredits = credits === 0;

  const getGenerateButton = React.useCallback((isSubmitting) => {
    return (
      <DisabledGenerateTooltip
        notEnoughCredits={insufficientCredits}
        noActiveSubscription={currentUser.noActiveSubscription}
        readOnly={readOnly}
        interactive={currentUser.noActiveSubscription || insufficientCredits}
      >
        <div className={classes.buttonTooltipWrap}>
          <Button
            type="submit"
            className={classes.button}
            disabled={readOnly || isSubmitting || currentUser.noActiveSubscription || insufficientCredits}
            id="button-generate"
          >
            {isSubmitting ? (
              <CircularProgress
                color="inherit"
                size={20}
              />
            ) : (
              'Generate'
            )}
          </Button>
        </div>
      </DisabledGenerateTooltip>
    );
  }, [readOnly, currentUser]);

  const hasTone = React.useMemo(() => (
    template && template.fields.some((f) => f.name === 'tone')
  ), [template]);

  if (!template) {
    return null;
  }

  const { aiOptions: { max_tokens }, fields: templateFields, templateType } = template;
  const templateConfig = templates[templateType] || {};
  const isCustom = templateType.includes('custom_');
  const fields = templateFields.filter((f) => f.showOnUi && (isCustom || f.name !== 'tone'));
  const schema = getSchema(fields);
  const initialValues = getInitialValues({ max_tokens, fields });

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onGenerate}
      validationSchema={schema}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form className={classes.form}>
            <div className={classes.scrollableArea}>
              <div className={clsx(classes.fieldsWrapper, classes.gapWrapper)}>
                <TemplateSelect
                  selectedTemplateType={templateType}
                  onChange={onTemplateChange}
                />

                {fields.map(({ type, name, label, placeholder, required, options, maximum, helper, quality }) => {
                  const showQuality = quality
                    && templateConfig.qualityTrackFields
                    && templateConfig.qualityTrackFields.includes(name);
                  const fieldLabel = capitalize(label);
                  return (
                    <FormField
                      key={name}
                      component={fieldsMap[type]}
                      name={`data.${name}`}
                      label={fieldLabel}
                      placeholder={placeholder}
                      disabled={readOnly || isSubmitting}
                      required={required}
                      options={options}
                      maxLength={maximum}
                      labelTooltip={helper}
                      quality={showQuality && quality}
                    />
                  );
                })}
              </div>

              <div className={clsx(classes.topSection, classes.gapWrapper)}>

                <div className={classes.row}>
                  <FormField
                    component={SelectField}
                    label="Number of variants"
                    name="aiOptions.n"
                    disabled={readOnly || isSubmitting}
                    options={variantsOptions}
                  />
                  <FormField
                    component={SelectField}
                    label="Output language"
                    name="outputLanguage"
                    disabled={readOnly || isSubmitting}
                    options={translateLanguages}
                  />
                </div>

                <div className={classes.row}>
                  {hasTone ? (
                    !isCustom && (
                      <FormField
                        component={SelectField}
                        label="Tone"
                        name="data.tone"
                        disabled={readOnly || isSubmitting}
                        options={toneOptions}
                      />
                    )
                  ) : (
                    <SimpleTooltip content="The selected use case does not support this field.">
                      <div>
                        <FormField
                          component={SelectField}
                          label="Tone"
                          name="data.tone"
                          disabled
                          options={toneOptions}
                        />
                      </div>
                    </SimpleTooltip>
                  )}
                  <FormField
                    component={SelectField}
                    label="Creativity"
                    name="aiOptions.temperature"
                    disabled={readOnly || isSubmitting}
                    options={creativityOptions}
                  />
                </div>
              </div>

              <ScrollFade />
            </div>

            <div className={classes.additionalSettings}>
              <ExtraSettings
                template={template}
                disabled={isSubmitting || readOnly}
              />
              {templateConfig.seoAvailable && (
                <SEOKeywords disabled={isSubmitting || readOnly} />
              )}
            </div>
            <div className={classes.buttonBlock}>
              {getGenerateButton(isSubmitting)}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InputForm;
