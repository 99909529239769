import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  button: {
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonFirst: {
    marginLeft: 0,
  },
  rightButtonsWrap: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: 8,
    },
  },
  leftButtonsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fill: `${theme.palette.primary.main} !important`,
  },
  inactive: {
    color: grey[300],
    fill: `${grey[300]} !important`,
    pointerEvents: 'auto !important',
  },
  separator: {
    width: '1px',
    height: '24px',
    background: theme.palette.colors.grey,
    margin: '0 8px',
  },
  personalizeButton: {
    margin: '0 8px',
    fill: 'rgba(0, 0, 0, 0.54)',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  personalizeRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  popper: {
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    color: theme.palette.grey.pure,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    ...theme.typography.body2,
  },
  popOver: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
  personalizeIconWrap: {
    display: 'flex',
  },
}));
