import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useSearchCatalog from '@root/resources/catalog/useSearchCatalog';
import BetaBadge from '@root/components/Badges/BetaBadge';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';

import CatalogCard from './Components/CatalogCard';
import EmptyCatalog from './Components/EmptyCatalog';
import AddCatalogButton from './Components/AddCatalogButton';
import useStyles from './Catalog.style';
import SearchCatalogs from './Components/CatalogSearch/SearchCalatogs';
import { CREATE_CATALOG_MODAL, CreateModal } from './Components/CreateModal';

const Catalog = () => {
  const classes = useStyles();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState('');
  const { isLoading, data } = useSearchCatalog({ searchQuery });

  const { dispatch: modalsDispatch } = useGlobalModals();

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openModal = urlParams.get('new') === 'true';

    if (!openModal) {
      return;
    }

    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: CREATE_CATALOG_MODAL },
    });

    history.replace(window.location.pathname);
  }, []);

  return (
    <div className={classes.page}>
      <div className={classes.filterBar}>
        <h1 className={classes.title}>
          All Catalogs
          {' '}
          <BetaBadge isNavbar />
        </h1>
        <div className={classes.actionSide}>
          <SearchCatalogs searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <AddCatalogButton />
        </div>
      </div>
      <div className={classes.filterBar}>
        <div className={classes.totalCatalogs}>
          {!isLoading && data?.catalogs ? data?.catalogs.results.length : 0}
          &nbsp;catalogs
        </div>
      </div>
      {data?.catalogs && data.catalogs.results.length > 0 && (
        <div className={classes.cardList}>
          {!isLoading && data?.catalogs.results.map((catalog, index) => (
            <CatalogCard
              key={`catalog-${catalog._id}`}
              catalog={catalog}
              index={index}
            />
          ))}
        </div>
      )}
      {data?.catalogs && data.catalogs.results.length === 0 && !searchQuery && (
        <EmptyCatalog />
      )}
      <CreateModal />
    </div>
  );
};

export default Catalog;
