import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    height: 'calc(100% - 56px)',
    backgroundColor: theme.palette.colors.mediumLightGrey,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    margin: 0,
  },
  page: {
    backgroundColor: '#fff',
    minWidth: '600px',
    width: '50%',
    marginTop: 16,
    height: 'calc(100vh - 120px - 56px)',
    overflowY: 'scroll',
    borderRadius: 2,
  },
  pageLeft: {
    height: '100%',
  },
  toolbar: {
    height: 56,
    display: 'flex',
    position: 'sticky',
    zIndex: 1,
    top: 72,
    left: 0,
    background: '#fff',
  },
  toolbarLeft: {
    width: '25%',
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightPanel: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 48,
  },
  backButton: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.colors.white,
    cursor: 'pointer',
  },
  pageRight: {
    // maxWidth: '30%',
    position: 'sticky',
  },
  rightPanel: {
    width: 411,
    height: '100%',
    marginLeft: 16,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    boxShadow: '-1px 18px 20px 1px rgba(0, 0, 0, 0.15)',
  },
  checkUniquenessWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2px 16px 0 16px',
  },
  publishButton: {
    marginTop: 4,
  },
  rightToolbar: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: '#fff',
    display: 'flex-column',
    width: 84,
    height: '100%',
  },
  rightPanelToggle: {
    cursor: 'pointer',
    width: '100%',
    fontSize: 36,
    marginTop: 12,
  },
  fraseToggleWrapper: {
    marginLeft: 8,
  },
  fraseToggle: {
    height: 32,
    width: 32,
    '&:hover': {
      color: 0.5,
    },
    marginTop: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 2,
    borderRadius: 8,
    cursor: 'pointer',
  },
  fraseGroupContainer: {
    width: '100%',
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fraseContainer: {
    width: '80%',
  },
  fraseContainerPanel: {
    width: '90%',
    textAlign: 'right',
  },
  closeIcon: {
    color: theme.palette.colors.mediumDarkGrey,
    height: 24,
    cursor: 'pointer',
  },
  workflowQueueWrapper: {
    marginTop: 4,
    '& > :not(:last-child)': {
      marginRight: 12,
    },
  },
  content: {
  },
  root: {
    height: '100vh',
  },
}));

export default useStyles;
