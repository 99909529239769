import useGenerateContent from './useGenerateContent';
import useGenerateContentNow from './useGenerateContentNow';
import useGenerateImage from './useGenerateImage';
import usePersonalizationRules from './usePersonalizationRules';
import usePersonalize from './usePersonalize';
import useRemovePersonalization from './useRemovePersonalization';
import useGenerateBlogKickstarter from './useGenerateBlogKickstarter';
import usePlagiarismCheck from './usePlagiarismCheck';
import useTemplateCost from './useTemplateCost';

export default {
  useGenerateContent,
  useGenerateContentNow,
  useGenerateImage,
  usePersonalizationRules,
  usePersonalize,
  useRemovePersonalization,
  useGenerateBlogKickstarter,
  usePlagiarismCheck,
  useTemplateCost,
};
