import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listContentContainer: {
    flex: 1,
    overflowX: 'auto',
  },

  tableRoot: {
    outline: '1px solid #C4C4C4',
    outlineOffset: '-1px',
    borderRadius: '8px',

    '& .MuiTableCell-root': {
      maxWidth: '200px',
      padding: '14px 24px',
      lineHeight: '22px',
    },
  },

  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },

  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  counterIconContainer: {
    display: 'grid',
    placeItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 100,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.faded,

    '&.secondary': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.error.faded,
    },

  },

  counterIcon: {
    fontSize: '16px',
  },

  actionIconTooltipArrow: {
    color: theme.palette.colors.black,

    '&:before': {
      borderColor: theme.palette.colors.black,
    },
  },

  actionIconTooltip: {
    backgroundColor: theme.palette.colors.black,
    color: theme.palette.colors.white,
    borderColor: theme.palette.colors.black,
  },

  actionIcon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  disabled: {
    color: theme.palette.colors.grey,

    '&:hover': {
      color: theme.palette.colors.grey,
    },
  },
}));

export default useStyles;
