import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    description: {
      marginBottom: 16,
    },
    descriptionWithSeparator: {
      paddingBottom: 16,
      borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
    memberRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 12,
    },
    memberInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    collapseButton: {
      marginBottom: 14,
      marginLeft: 0,
    },
    memberNameRemoved: {
      color: theme.palette.grey.dark,
    },
    profileIconRemoved: {
      background: theme.palette.grey.dark,
    },
    profileIcon: {
      width: 24,
      height: 24,
      fontSize: 14,
      minWidth: 24,
      lineHeight: '24px',
      marginRight: 8,
    },
    buttonText: {
      fontSize: 14,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    detailsOpen: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    detailsToggle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.colors.primary,
    },
    detailsToggleExceeded: {
      color: theme.palette.colors.error,
    },
    bold: {
      fontWeight: 700,
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 100,
    },
    content: {
      minHeight: 100,
    },
    checkboxWrap: {
      display: 'flex',
      paddingTop: theme.spacing(1),
      gap: theme.spacing(1),
      cursor: 'pointer',

      '& > div': {
        paddingTop: theme.spacing(0.75),
      },
    },
  });
});

export default useStyles;
