/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  TextField,
} from '@material-ui/core';

const StringField = ({
  formik,
  name,
  className,
  helperText,
  ...otherProps
}) => {
  const arrayValue = formik.values[name] || [];

  const value = React.useMemo(() => {
    return arrayValue.join(',');
  }, [arrayValue]);

  const onChange = (e) => {
    const newValue = e.target.value || '';
    const array = newValue.split(',')
      .map((i) => i.trim())
      .filter((i) => i);

    formik.setFieldValue(name, array);
  };

  return (
    <TextField
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      size="small"
      fullWidth
      id={name}
      name={name}
      label={name}
      value={value}
      onChange={onChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={(formik.touched[name] && formik.errors[name]) || helperText}
      variant="outlined"
      className={className}
      {...otherProps}
    />
  );
};

export default StringField;
