import React, { useRef, useState } from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ArrowDropDown } from '@material-ui/icons';
import { useDrop } from 'react-dnd';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { FolderOptionsKebab, BookmarkFolder } from '@root/views/Dashboard/components/IconButtons';
import useFolders from '@root/resources/folder/useFolders';
import { getChildFolders, isChildActive } from '@root/views/Dashboard/helpers';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import useUpdateFolder from '@root/resources/folder/useUpdateFolder';
import uiNotificationService from '@root/services/uiNotification.service';
import AuthContext from '@root/resources/auth/auth.context';
import ItemTypes from '@root/utils/dndItemTypes';

import ChildFolderListItem from './ChildFolderListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  newRoot: {
    paddingLeft: '5px',
  },
  rootFolder: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    overflow: 'hidden',
    marginBottom: '0.25rem',
    alignItems: 'center',
    minHeight: '2rem',
    maxWidth: '100%',
  },
  newRootFolder: {
    marginBottom: '0',
  },
  active: {
    '&&': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  hover: {
    '&&': {
      border: '1px solid black',
    },
  },
  folderTitle: {
    fontSize: '0.875rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#000',
    flexGrow: '1',
    '&:hover': {
      color: '#5b5b5b',
    },
  },
  newFolderTitle: {
    fontWeight: 500,
  },
  icon: {
    marginRight: '0.8rem',
    fontSize: '1.3rem',
    color: theme.palette.colors.darkGrey,
  },
  newIcon: {
    color: theme.palette.colors.black,
  },
  iconContainer: {
    display: 'flex',
  },
  rotateRight: {
    transform: 'rotate(-90deg)',
  },
  transition: {
    transition: 'transform 0.4s',
  },
  hidden: {
    display: 'none',
  },
  bookmarkContainer: {
    fontSize: '1.3rem',
  },
  useDrop: {
    boxSizing: 'border-box',
    border: '1px solid #666',
    borderRadius: '3px',
    padding: '3px',
  },
  isDraggingOver: {
    background: '#ddd',
    borderColor: 'transparent',
    color: '#000',
  },
}));

const RootFolderListItem = ({
  folder, isDraggable = false,
}) => {
  const [hover, setHover] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { data: folders } = useFolders();
  const {
    state: {
      filters: { folderId: currentFolderId },
    }, dispatch,
  } = useDashboard();
  const classes = useStyles();
  const { mutateAsync: updateFile } = useUpdateFile();
  const { mutateAsync: updateFolder } = useUpdateFolder();
  const ref = useRef(null);

  const active = folder.id === currentFolderId;
  const childActive = isChildActive(folder._id, currentFolderId, folders);

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  const moveFileHere = async (fileId) => {
    const { isBadRequest } = await updateFile({
      fileId,
      data: {
        folderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move file to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`File has been moved to ${folder.title}`);
    }
  };

  const moveFolderHere = async (folderId) => {
    const { isBadRequest } = await updateFolder({
      folderId,
      update: {
        parentFolderId: folder._id,
      },
    });
    if (isBadRequest) {
      uiNotificationService.showErrorMessage(`Unable to move folder to ${folder.title}, check permissions or network`);
    } else {
      uiNotificationService.showSuccessMessage(`Folder has been moved to ${folder.title}`);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.FILE_CARD, ItemTypes.FOLDER_CARD],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item, monitor) => {
      const itemType = monitor.getItemType();
      if (itemType === ItemTypes.FILE_CARD) {
        moveFileHere(item.fileId);
      } else if (itemType === ItemTypes.FOLDER_CARD) {
        moveFolderHere(item.folderId);
      }
    },
  });

  const handleSelectFolder = () => {
    setCollapsed(false);
    dispatch({
      type: actions.SELECT_PROJECT,
      payload:
        {
          title: folder.title,
          folderId: folder._id,
        },
    });
  };

  const handleArrowClick = (e) => {
    if (active || childActive) {
      e.stopPropagation();
      setCollapsed(!collapsed);
    }
  };

  if (isDraggable) {
    drop(ref);
  }

  const childFolders = getChildFolders(folder._id, folders);
  return (
    <div className={clsx(classes.root, { [classes.newRoot]: newDesign })}>
      <div
        className={clsx(classes.rootFolder, { [classes.newRootFolder]: newDesign })}
        onClick={handleSelectFolder}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={ref}
      >
        <div
          onClick={handleArrowClick}
          className={classes.iconContainer}
        >
          <ArrowDropDown classes={{
            root: clsx(
              classes.icon,
              classes.transition,
              {
                [classes.rotateRight]: (!active && !childActive) || collapsed,
                [classes.newIcon]: newDesign,
              },
            ),
          }}
          />
        </div>
        <div className={clsx(classes.folderTitle, {
          [classes.active]: active && !isOver,
          [classes.useDrop]: canDrop && isDraggable,
          [classes.isDraggingOver]: isOver && isDraggable,
          [classes.newFolderTitle]: newDesign,
        })}
        >
          { folder.title }
        </div>
        <div className={clsx({ [classes.hidden]: !hover })}>
          <FolderOptionsKebab variant="sidebar" folder={folder} />
        </div>
        <div className={clsx(classes.bookmarkContainer,
          { [classes.hidden]: !hover && !folder.liked })}
        >
          <BookmarkFolder folderId={folder._id} liked={folder.liked} />
        </div>
      </div>
      <Collapse in={(active || childActive) && !collapsed}>
        <div>
          {childFolders.map((f) => (
            <ChildFolderListItem
              isDraggable={isDraggable}
              key={f._id}
              folder={f}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default RootFolderListItem;
