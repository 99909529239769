import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: '8px',
    color: theme.palette.colors.darkGrey,
    padding: '30px 36px 30px 36px',
    marginBottom: '20px',
    whiteSpace: 'nowrap',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 86,
  },
  value: {
    fontWeight: 800,
    color: theme.palette.colors.purple600,
  },
  infoWrap: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '8px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
