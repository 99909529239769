import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useAdminUpdateTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.aiTemplate.adminUpdateTemplates(data._id, data),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return null;
        }

        queryClient.invalidateQueries('aiTemplates.list');
        queryClient.invalidateQueries(['aiTemplates.getById', response._id]);

        return response;
      },
    },
  );
}
