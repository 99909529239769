import React from 'react';

const Checked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
    >
      <rect
        width="16"
        height="16"
        fill="currentColor"
        rx="8"
      />
      <circle
        cx="8"
        cy="8"
        r="3"
        fill="#fff"
      />
    </svg>
  );
};

export default Checked;
