import React from 'react';
import clsx from 'clsx';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import { FieldLabel } from '@root/components/form';
import { getFineTunesOptions } from '@root/views/FineTunesEngines/helpers';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './AliButtons.styles';

const aliDisabledTemplates = ['articles', 'blogPost', 'blogTitle', 'contentExpanderNew'];

const AliButtons = ({ onChange, value, templateType }) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();

  const engines = getFineTunesOptions(currentUser);

  const includeAli = engines.length > 0 && !aliDisabledTemplates.includes(templateType);

  if (!includeAli) {
    return null;
  }

  const options = [
    { value: 'default', label: 'None' },
    ...engines,
  ];

  const handleOptionClick = (engine) => {
    eventsAggregator.publish(EVENT_NAMES.FINE_TUNE_SELECTED, { fineTuneId: engine });
    onChange(engine);
  };

  return (

    <div>
      <FieldLabel
        label="Ali's Expert Voice"
      />
      <div className={classes.optionsContainer}>
        {options.map((engine) => {
          const isSelected = engine.value === value;
          return (
            <div
              className={clsx(classes.engine, isSelected && classes.selected)}
              onClick={() => handleOptionClick(engine.value)}
              key={engine.value}
            >
              {engine.label}
            </div>
          );
        })}
      </div>

    </div>

  );
};

export default AliButtons;
