import useCreateDocumentExample from './useCreateDocumentExample';
import useDocumentExamples from './useDocumentExamples';
import useRemoveDocumentExample from './useRemoveDocumentExample';
import useSubmitDocumentExampleToReview from './useSubmitDocumentExampleToReview';
import useApproveSubmittedDocumentExample from './useApproveSubmittedDocumentExample';

export {
  useCreateDocumentExample,
  useDocumentExamples,
  useRemoveDocumentExample,
  useSubmitDocumentExampleToReview,
  useApproveSubmittedDocumentExample,
};

export default {
  useCreateDocumentExample,
  useDocumentExamples,
  useRemoveDocumentExample,
  useSubmitDocumentExampleToReview,
  useApproveSubmittedDocumentExample,
};
