import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useGenerateBulk() {
  const queryClient = useQueryClient();

  return useMutation(async (data) => {
    const response = await api.bulkGeneration.generateBulk(data);

    queryClient.invalidateQueries(['currentBulkGenerationJob', {}]);

    return response;
  }, {
    onSuccess: (response) => {
      if (!response || response.isBadRequest) {
        return;
      }

      const queryKey = ['bulkGenerations', {}];

      const prevData = queryClient.getQueryData(queryKey);
      if (!prevData) {
        return;
      }

      queryClient.setQueryData(queryKey, [response, ...prevData]);
    },
  });
}
