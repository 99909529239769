import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import OnboardingContext from '@root/resources/onboarding/onboarding.context';
import AuthContext from '@root/resources/auth/auth.context';
import bulkCopyUtils from '@root/views/BulkCopy/bulkCopy.utils';
import { templates } from '@root/views/Editor/Editor.constants';

import Bookmark from './Bookmark';
import { TEMPLATE_CREATE_MODAL_ID, TEMPLATE_EXAMPLE_MODAL_ID } from './modals';
import useStyles from './TemplateCard.styles';

const TemplateCard = ({ template, disabled, disabledTooltipText }) => {
  const { dispatch } = useGlobalModals();
  const { currentUser } = AuthContext.useAuth();
  const { state: {
    hideFavorite,
    onCreateClick,
    isBulk,
  }, handleCreate } = useTemplatesDashboard();
  const { featureTourActive, nextStep } = OnboardingContext.useOnboarding();
  const { newEditor: isRemoveCreateFileModalEnabled } = currentUser.featureToggles;

  const { Icon } = template;

  const isBlogIntro = template.templateType === 'blogIntro';
  const isOnboardingActive = featureTourActive && isBlogIntro;

  const classes = useStyles({ isOnboardingActive });

  const handleExampleModal = () => {
    if (!isOnboardingActive) {
      dispatch({
        type: actions.OPEN_MODAL,
        payload: {
          modalId: TEMPLATE_EXAMPLE_MODAL_ID,
          modalContent: template,
        },
      });
    }
  };

  const handleCreateModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: template,
      },
    });
  };

  const downloadSample = async () => {
    await bulkCopyUtils.downloadSampleFile({
      templateName: template.title,
      templateType: template.templateType,
    });
  };

  const handleCreateClick = () => {
    const isFreeFormEditor = template.templateType === 'freeFormEditor';
    const isEditorTemplate = Object.values(templates).map((t) => t.templateType).includes(template.templateType);

    if (isOnboardingActive && !isFreeFormEditor) {
      handleCreateModal();
      nextStep();
      return;
    }

    if (isOnboardingActive && isFreeFormEditor && isRemoveCreateFileModalEnabled) {
      handleCreate(template.templateType);
      nextStep();
      return;
    }

    if (onCreateClick) {
      onCreateClick(template);
      return;
    }

    if ((isFreeFormEditor || isEditorTemplate) && isRemoveCreateFileModalEnabled) {
      handleCreate('freeFormEditor', isEditorTemplate && template.templateType);
      return;
    }

    handleCreate(template.templateType);
  };

  return (
    <div className={classes.card} id={isOnboardingActive ? 'feature-tour-step-1' : undefined}>
      <div className={classes.topActions}>
        <div className={classes.icon} onClick={handleExampleModal}>
          {Icon && <Icon color="#000" size={24} />}
        </div>

        {(!hideFavorite && !isOnboardingActive) && (
          <Bookmark isFavorite={template.isFavorite} type={template.templateType} />
        )}
      </div>

      <h2 className={classes.title} onClick={handleExampleModal}>
        {template.title}
      </h2>
      <p className={classes.description} onClick={handleExampleModal}>
        {template.description}
      </p>
      <div className={classes.bottomActions}>
        <SimpleTooltip
          content={disabledTooltipText}
          wrapped
          placement="bottom"
        >
          <Button
            disabled={disabled}
            id="create-button"
            size="small"
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </SimpleTooltip>
        {(isBulk && !template.templateType.startsWith('custom_')) && (
          <div onClick={downloadSample} className={classes.csvDownload}>
            <FontAwesomeIcon
              icon="download"
              style={{
                cursor: 'pointer',
                marginRight: '0.15rem',
              }}
            />
            CSV
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateCard;
