import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  page: {
    padding: '2rem 3rem',
    backgroundColor: theme.palette.colors.neutrals50,
    width: '100%',
    overflowY: 'scroll',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.5rem',
    fontWeight: '500',
    fontFamily: 'Inter, sans-serif',
    gap: '8px',
    marginBottom: '1.5rem',
  },
  subTitle: {
    color: theme.palette.grey.pure,
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '1rem 0px 0px',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px',
  },
  actionSide: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  cardList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selected: {
    fontSize: '0.9rem',
    marginRight: '1rem',
    fontWeight: 500,
  },
  totalProducts: {
    fontSize: '0.9rem',
    marginRight: '1rem',
    color: '#9F9EAB',
  },
  totalCatalogs: {
    fontSize: '0.9rem',
    color: '#9F9EAB',
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  rightSpace: {
    marginRight: '0.5rem',
  },
  bulkCopyButton: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
    width: '128px',

    '&:disabled': {
      color: theme.palette.colors.black,
    },

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals200,
    },
  },
  startIcon: {
    color: theme.palette.colors.black,
  },
  productsRemaining: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '4px 12px',
    background: theme.palette.colors.blue200,
    borderRadius: '4px',
    fontSize: '12px',
    marginRight: '1rem',
  },
  bulkGenerationRowsRemaining: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '4px 12px',
    background: theme.palette.colors.blue200,
    borderRadius: '4px',
    fontSize: '12px',
    marginRight: '1rem',
  },
}));

export default useStyles;
