export default {
  name: '',
  jwtToken: {},
  toggles: {},
  availableCreateModalOptions: ['editor', 'useCase', 'bulkCopy', 'bulkCopyAkeneo'],
  register: () => {},
  unregister: () => {},
  refresh: () => {},
  isTemplateDisabled: () => {},
  navDisabled: [],
  profileSidebarDisabledItems: [],
  profileDropdownDisabledItems: [],
  profileDropdownDisabledResources: [],
  isNeedToSubscribe: () => {},
  trialEndModal: {},
  isTrialEndShowed: () => {},
  filterGenerators: (t) => t,
  getPublishMessage: () => '',
  successLoginRedirectPath: '',
  homePage: '',
};
