import React from 'react';
import { components } from 'react-select';

import useStyles from './SingleValue.styles';

const SingleValue = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <components.SingleValue {...rest}>
      <span className={classes.statusLabel}>Status:</span>
      {children}
    </components.SingleValue>
  );
};

export default SingleValue;
