import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  navbarContainer: {
    zIndex: 99,
  },
  navbar: {
    height: ({ newEditor }) => (newEditor ? 56 : 72),
    color: theme.palette.colors.white,
    backgroundColor: ({ newEditor }) => (
      newEditor
        ? theme.palette.colors.neutrals600
        : theme.palette.colors.darkPurple
    ),
    padding: '0 16px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: ({ newEditor }) => (newEditor ? 24 : 40),
    marginRight: 32,
    cursor: 'pointer',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
