import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import Checkbox from '@root/components/Checkbox';

const useStyles = makeStyles((theme) => {
  return ({
    outlineItemRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: 4,
    },
    selected: {
      backgroundColor: theme.palette.colors.primaryFaded,
    },
    outlineText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginRight: 8,
    },
  });
});

const OutlineItem = ({ onClick, onCheck, text, checked, selected }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.outlineItemRoot, selected && classes.selected)}
      onClick={onClick}
    >
      <Checkbox
        checked={checked}
        onChange={onCheck}
      />
      <span className={classes.outlineText}>
        {text}
      </span>
    </div>
  );
};

export default OutlineItem;
