import React from 'react';

const ApplePay = () => {
  return (
    <svg
      width="39"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.138 2.063c-.456.54-1.187.966-1.918.906-.091-.731.266-1.508.685-1.987C6.362.426 7.161.03 7.808 0c.076.761-.22 1.507-.67 2.063Zm.662 1.05c-1.058-.06-1.963.602-2.466.602-.51 0-1.279-.571-2.116-.556-1.088.015-2.1.632-2.656 1.614-1.142 1.963-.297 4.871.806 6.47.54.791 1.188 1.659 2.04 1.629.807-.03 1.127-.526 2.101-.526.982 0 1.264.526 2.116.51.883-.015 1.439-.791 1.98-1.583.616-.898.867-1.773.882-1.82-.015-.014-1.705-.661-1.72-2.61-.015-1.629 1.332-2.405 1.393-2.451C9.4 3.265 8.212 3.144 7.8 3.113ZM13.913.906v11.867h1.842V8.716h2.55c2.329 0 3.965-1.599 3.965-3.913S20.664.906 18.366.906h-4.453Zm1.842 1.553h2.123c1.599 0 2.512.852 2.512 2.352s-.913 2.36-2.52 2.36h-2.115V2.458Zm9.88 10.405c1.157 0 2.23-.586 2.717-1.515h.038v1.424h1.705V6.866c0-1.713-1.37-2.817-3.478-2.817-1.956 0-3.403 1.12-3.456 2.657h1.66c.136-.73.814-1.21 1.743-1.21 1.126 0 1.758.525 1.758 1.492v.654l-2.299.137c-2.139.13-3.296 1.005-3.296 2.527 0 1.538 1.195 2.558 2.908 2.558Zm.495-1.408c-.982 0-1.606-.472-1.606-1.195 0-.746.6-1.18 1.75-1.249l2.048-.129v.67c0 1.111-.944 1.903-2.192 1.903ZM32.37 16c1.797 0 2.642-.685 3.38-2.763l3.235-9.073h-1.872l-2.17 7.01h-.038l-2.17-7.01h-1.925l3.12 8.639-.167.525c-.281.89-.738 1.233-1.553 1.233-.144 0-.426-.015-.54-.03v1.423c.107.03.563.046.7.046Z" fill="#000" />
    </svg>
  );
};

export default ApplePay;
