const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'background',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'width',
  'style',
  'color',
  'size',
];

const modules = {
  clipboard: {
    matchVisual: false,
  },
  history: {
    maxStack: 0,
    userOnly: true,
  },
};

export default {
  formats,
  modules,
};
