import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    alignSelf: 'center',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 16,
    padding: '24px 32px',
    maxWidth: 587,
    width: '100%',
  },
  title: {
    fontSize: 18,
    lineHeight: '39px',
    fontWeight: 600,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  buttons: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: 8,
    marginTop: 40,
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
