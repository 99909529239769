import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginRight: '24px',
  },
  buttonContainer: {
    display: 'inline-flex',
    flexGrow: 2,
    minHeight: '38px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > div:not(:last-child)': {
      marginRight: '0.8rem',
    },
  },
  parentFolderLink: {
    cursor: 'pointer',
    '&:hover': {
      color: '#5b5b5b',
    },
  },
}));

export default useStyles;
