import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wizardRoot: {
    backgroundColor: theme.palette.colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    transform: 'none',
    fontSize: 14,
    flex: 1,
    overflowY: 'scroll',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    flex: 1,
    margin: '48px 0',
    height: ({ step }) => (step === 3 ? 0 : 'unset'),
  },
  stepperBlock: {
    width: '100%',
    padding: '0 48px',

    backgroundColor: theme.palette.colors.white,
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
  },
  nextStepButton: {
    width: 120,
    marginRight: -8,
  },
  prevStepButton: {
    width: 120,
    marginLeft: -8,
  },
}));

export default useStyles;
