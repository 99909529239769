import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M12 6.667a5.333 5.333 0 1 0 0 10.666 5.333 5.333 0 0 0 0-10.666ZM5.333 12a6.667 6.667 0 1 1 13.334 0 6.667 6.667 0 0 1-13.334 0Z" />
      <path d="M9.333 12c0-.368.299-.667.667-.667h4a.667.667 0 0 1 0 1.334h-4A.667.667 0 0 1 9.333 12Z" />
      <path d="M12 9.333c.368 0 .667.299.667.667v4a.667.667 0 0 1-1.334 0v-4c0-.368.299-.667.667-.667Z" />
    </svg>
  );
};
