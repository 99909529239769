import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export class News {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async list(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/news", data, options);

    return result;
  }
}