import safeLocalStorage from '@root/services/safeLocalStorage.service';

const FAVORITE_KEY = 'favorites-list';

const getFavorites = () => {
  try {
    const items = safeLocalStorage.getItem(FAVORITE_KEY);

    return items ? JSON.parse(items) : [];
  } catch (e) {
    return null;
  }
};

const saveFavoritesInLocalStorage = (favorites) => {
  try {
    safeLocalStorage.setItem(FAVORITE_KEY, JSON.stringify(favorites));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export {
  getFavorites,
  saveFavoritesInLocalStorage,
};
