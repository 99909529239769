import { DateTime } from 'luxon';

const expandCurrentCompany = (company) => {
  const newCompany = {
    ...company,
    currentLimitId: company.billing.currentLimitId,
    stripeId: company.billing.customerId,
    monthlyLimit: company.billing.limitMode === 'monthlyGenerationsLimit' ? company.billing.limit : 0,
    enabledAddOnBilling: company.billing.addOns,
    company: company.name,
    prefillInputs: company.prefillInputs,
    companyUrl: company.url,
    defaultDescription: company.description,
    defaultKeywords: company.keywords,
    growsurf: company.growsurf,
    suspendStart: company.billing.suspensionStart || null,
    suspendEnd: company.billing.suspensionEnd || null,
    suspendPlan: company.billing.suspendedPlan || null,
    onboardingCompleted: company.onboarding.completed,
  };

  return newCompany;
};

const isCompanySuspended = (company) => {
  const { suspensionStart, suspensionEnd } = company.billing;
  if (!suspensionStart) {
    return false;
  }

  const now = DateTime.now().toSeconds();
  if (now < suspensionStart || now > suspensionEnd) {
    return false;
  }

  return true;
};

export default {
  expandCurrentCompany,
  isCompanySuspended,
};
