import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dropdownWrap: {
    minWidth: '100px',
    paddingLeft: '12px',
    marginRight: '-0.35rem',
  },
  dropdownPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
  },
  userDisplayName: {
    fontFamily: 'Inter',
    fontSize: '1.1rem',
    fontWeight: 500,
    cursor: 'pointer',
  },
  userInfoWrap: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    padding: '14px 24px 14px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    '& span': {
      textTransform: 'capitalize',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '30px',
    },
    '&:hover': {
      background: theme.palette.colors.greyHalfOpacity,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  dropdown: {
    position: 'static',
    height: '1rem',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    margin: '0 2px 0.5rem 2px',
    color: '#000000',
    '& hr': {
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
  },
  dropdownItem: {
    padding: '14px 18px',
    minWidth: '220px',
    position: 'relative',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#888',
    },
    '&:active': {
      backgroundColor: '#fff',
      color: '#888',
      opacity: '90%',
    },
    '&:focus': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  dropdownToggle: {
    marginTop: '-17px',
    cursor: 'pointer',
    fontSize: '20px',
    color: theme.palette.colors.darkGrey,
    padding: '22px 12px',
    borderRadius: '4px',

    '&:hover': {
      background: theme.palette.colors.greyHalfOpacity,
    },
    '&::after': {
      marginLeft: 0,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  dropdownToggleHighlighted: {
    border: `1px solid ${theme.palette.colors.primary}`,
    boxShadow: '0px 1px 16px 4px rgba(82, 58, 231, 0.25)',
  },
  dropdownMenuRight: {
    borderTop: 0,
    marginTop: '2px',
    borderRadius: '0',
    padding: '1rem 24px',
    right: '1px',
  },
  qsLogoutBtn: {
    userSelect: 'none',
    margin: 0,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  communityIconWrap: {
    position: 'relative',
    padding: '17px 8px 16px 8px',
    borderRadius: '4px',
    marginRight: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.colors.greyHalfOpacity,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  communityIcon: {
    fontSize: '24px',
    lineHeight: '40px',
  },
  notificationIconWrap: {
    position: 'relative',
    padding: '17px 8px 16px 8px',
    borderRadius: '4px',
    marginRight: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.colors.greyHalfOpacity,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  notificationIcon: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  notificationCounter: {
    position: 'absolute',
    height: '16px',
    width: '16px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: theme.palette.colors.error,
    borderRadius: '8px',
    textAlign: 'center',
    top: '16px',
    right: '4px',
  },
  chevronRight: {
    position: 'absolute',
    right: 0,
    top: '9px',
  },
  chevronLeft: {
    position: 'absolute',
    left: '-14px',
    top: '9px',
  },
  redCircle: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: theme.palette.colors.error,
    position: 'absolute',
    left: '-6px',
    top: '17px',
  },
  usageIconWrap: {
    position: 'relative',
    padding: '17px 8px 16px 8px',
    borderRadius: '4px',
    marginRight: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.colors.greyHalfOpacity,
    },
    '&:active': {
      opacity: '90%',
    },
  },
  usageIcon: {
    fontSize: '28px',
    lineHeight: '40px',
  },
}));
