import React from 'react';
import clsx from 'clsx';
import { Button, CircularProgress } from '@material-ui/core';
import { StringUtil } from '@copysmith/utils';
import PubSub from 'pubsub-js';

import uiNotificationService from '@root/services/uiNotification.service';
import Modal from '@root/components/modals/Modal';
import aiResource from '@root/resources/ai';
import billingResource from '@root/resources/billing';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import useStyles from './ImproveWriting.styles';
import { enhanceAction, extendAction, plagiarismAction, rewriteAction } from './ImproveWriting.actions';
import { EnhanceIcon, ExtendIcon, PlagiarismIcon, RewriteIcon } from './icons';

const ImproveWritingToolbox = ({ templates, formRef, events }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(null);
  const [modalData, setModalData] = React.useState(null);
  const [wordsCount, setWordsCount] = React.useState(0);
  const wordsCountInvalid = wordsCount < 15 || wordsCount > 200;

  const { mutateAsync: generateNow } = aiResource.useGenerateContentNow();
  const { mutateAsync: plagiarismCheck } = aiResource.usePlagiarismCheck();
  const { data: limits } = billingResource.useLimits();

  const getPlagiarismTooltip = () => {
    const { plagiarismCheckLimit: numberOfPlagiarismChecks } = limits;
    const insufficientlyChecks = numberOfPlagiarismChecks === 0;

    return (
      <div className={classes.tooltipWrapperPlagiarism}>
        Check copy uniqueness.
        <br />
        <span
          className={clsx({
            [classes.redText]: wordsCountInvalid,
          })}
        >
          {`${wordsCount} / 200 words (Minimum 15)`}
        </span>
        <br />
        {Number.isInteger(numberOfPlagiarismChecks) && (
          <span
            className={clsx(classes.plagiarismChecks, {
              [classes.plagiarismChecksError]: insufficientlyChecks,
            })}
          >
            {numberOfPlagiarismChecks}
            {' '}
            checks available
          </span>
        )}
      </div>
    );
  };

  const buttons = [
    {
      id: 'cs-quick-enhance',
      action: 'enhance',
      label: 'Enhance',
      icon: EnhanceIcon,
      func: enhanceAction,
      tooltip: 'Tweak and refine this copy',
    },
    {
      id: 'cs-quick-rewrite',
      action: 'rewrite',
      label: 'Rewrite',
      icon: RewriteIcon,
      func: rewriteAction,
      tooltip: 'Rewrite this copy',
    },
    // { action: 'expand', label: 'Expand', icon: 'fingerprint' },
    {
      id: 'cs-quick-extend',
      action: 'extend',
      label: 'Extend',
      icon: ExtendIcon,
      func: extendAction,
      tooltip: 'Auto-write the next sentence',
    },
    {
      id: 'cs-quick-plagiarism',
      action: 'plagiarism',
      label: 'Plagiarism',
      icon: PlagiarismIcon,
      func: plagiarismAction,
      tooltip: getPlagiarismTooltip(),
      disabled: wordsCountInvalid,
    },
  ];

  const onClickBtn = async (e) => {
    e.preventDefault();

    const button = e.target.closest('button[data-action]');
    const { action } = button.dataset;
    const selection = document.getSelection();
    const range = selection.getRangeAt(0);
    const selectedText = selection.toString();
    const buttonConfig = buttons?.find((b) => b.action === action);

    // remove whitespaces at the start/end
    const startOffset = selectedText.length - selectedText.trimStart().length;
    const endOffset = selectedText.length - selectedText.trimEnd().length;

    if (startOffset) {
      range.setStart(range.startContainer, range.startOffset + startOffset);
    }
    if (endOffset) {
      range.setEnd(range.endContainer, range.endOffset - endOffset);
    }

    switch (action) {
      case 'enhance':
      case 'rewrite': {
        setLoading(action);
        const result = await buttonConfig.func(selectedText, templates, generateNow);
        setLoading(null);
        if (result?.content) {
          const textNode = document.createTextNode(result?.content);
          range.deleteContents();
          range.insertNode(textNode);
        }
        break;
      }
      case 'extend': {
        setLoading(action);
        const result = await buttonConfig.func(selectedText, templates, generateNow);
        setLoading(null);
        if (result?.content) {
          const textNode = document.createTextNode(` ${result?.content}`);
          const rangeClone = range.cloneRange();
          rangeClone.collapse(false); // collapse the range to the end of selection
          rangeClone.insertNode(textNode);
        }
        break;
      }
      case 'plagiarism': {
        setLoading(action);
        await buttonConfig.func({
          limits,
          text:
          selectedText,
          setModalData,
          checkPlagiarism: plagiarismCheck,
        });
        setLoading(null);
        break;
      }
      default: {
        uiNotificationService.showWarnMessage('Missing action to execute.');
      }
    }
  };

  React.useEffect(() => {
    PubSub.subscribe(events.openActionEvent, () => {
      const selection = document.getSelection();
      const selectedText = selection.toString();
      setWordsCount(StringUtil.countWordsString(selectedText));
    });

    PubSub.subscribe(events.destroy, () => {
      const selectors = buttons.map((b) => `#${b.id}`).filter((_) => _).join(', ');
      const tooltips = document.querySelectorAll(selectors);
      tooltips.forEach((t) => t.remove());
    });
  });

  return (
    <div className={classes.dropdown}>
      {buttons && buttons.map((button) => (
        <SimpleTooltip
          content={button.tooltip}
          placement="right"
          dark
          wrapped
          key={button.action}
          id={button.id}
        >
          <Button
            fullWidth
            variant="text"
            color="inherit"
            data-action={button.action}
            className="ce-popover__item"
            onClick={onClickBtn}
            disabled={loading === button.action || button.disabled}
            id={button.id}
          >
            <span className={clsx(classes.icon)}>
              {loading === button.action ? (
                <CircularProgress size={16} />
              ) : (
                <button.icon />
              )}
            </span>
            {button.label}
          </Button>
        </SimpleTooltip>
      ))}

      <Modal
        open={!!modalData}
        onClose={() => setModalData(null)}
        maxWidth="md"
        title={modalData?.title}
      >
        {modalData?.body}
      </Modal>
    </div>
  );
};

export default ImproveWritingToolbox;
