import React from 'react';
import { StringUtil } from '@copysmith/utils';

import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';

const useGenerate = ({
  config,
  localFile,
  setErrorMessage,
  updateFile,
  savePrevData,
  saveLocalFile,
  generateContent,
  engineData,
  setIsMagicLoading,
  isLocalFileEqualsPrevData,
  prevData,
  fileId,
  getFileData,
  templateType,
}) => {
  const generate = React.useCallback(async ({ likeId, generateData = {}, newFileData } = {}) => {
    const {
      negativeKeywordArray,
      language,
      outputLanguage,
      engine,
      assignedToId,
      aiOptions,
      ...d
    } = localFile;

    let validEngine = engine;
    if (!engineData || engineData.length === 0) {
      validEngine = 'default';
    }

    eventsAggregator.publish(
      EVENT_NAMES.GENERATE_BUTTON_CLICKED,
      { template: config.templateType, engine: validEngine },
    );

    if (!likeId) {
      const requiredFields = config.fields.filter((f) => f.required);
      const reqFieldsEmpty = requiredFields.some((f) => (f.type !== 'keywords' ? !localFile[f.name] : localFile[f.name].length === 0));
      if (reqFieldsEmpty) {
        setErrorMessage('Please fill in all required elements and try again. Tip: You need to press \'enter\' after entering keywords!');
        setTimeout(() => setErrorMessage(null), 3000);
        return;
      }

      const minFields = config.fields.filter((f) => f.minimum);
      const shortMinField = minFields
        .find((f) => (
          f.wordCount ? StringUtil.getWordsString(localFile[f.name]) : localFile[f.name]
        ).length < f.minimum);
      if (shortMinField) {
        setErrorMessage(shortMinField.minimumLabel);
        setTimeout(() => setErrorMessage(null), 3000);
        return;
      }

      const maxFields = config.fields.filter((f) => f.maximum);
      const longMaxField = maxFields.find((f) => {
        const value = Array.isArray(localFile[f.name]) ? localFile[f.name].join(' ') : localFile[f.name];
        return (f.wordCount ? StringUtil.getWordsString(value) : value).length > f.maximum;
      });

      if (longMaxField) {
        setErrorMessage(`The maximum length (${longMaxField.maximum} ${longMaxField.wordCount ? 'words' : 'characters'}) of the ${longMaxField.label} field has been exceeded.`);
        setTimeout(() => setErrorMessage(null), 3000);
        return;
      }

      setIsMagicLoading(true);
      const dataChanged = !isLocalFileEqualsPrevData(localFile, prevData);
      if (dataChanged || newFileData || validEngine !== engine) {
        const newFile = await updateFile({
          fileId,
          data: {
            data: {
              ...getFileData(d, config),
              ...(newFileData || {}),
            },
            negativeKeywordArray,
            language,
            ...(outputLanguage && { outputLanguage }),
            engine: validEngine,
          },
        });
        savePrevData();

        saveLocalFile({
          ...newFile,
          workflowStatus: localFile.workflowStatus,
          assignedToId: localFile.assignedToId,
          aiOptions: { ...aiOptions, engine },
        });
      }
    } else {
      setIsMagicLoading(true);
    }

    const requestData = {
      fileId,
      language,
      ...(outputLanguage && { outputLanguage }),
      type: templateType,
      aiOptions,
      ...generateData,
    };
    if (likeId) {
      requestData.likeId = likeId;
    }
    if (localFile.tone) {
      requestData.tone = localFile.tone;
    }
    if (validEngine) {
      requestData.engine = validEngine;
    }
    await generateContent(requestData);
    setIsMagicLoading(false);
  }, [
    config,
    localFile,
    setErrorMessage,
    updateFile,
    savePrevData,
    saveLocalFile,
    generateContent,
    engineData,
    setIsMagicLoading,
    isLocalFileEqualsPrevData,
    prevData,
    fileId,
    getFileData,
  ]);

  return generate;
};

export default useGenerate;
