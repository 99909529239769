import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import billingResource from '@root/resources/billing';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import AuthContext from '@root/resources/auth/auth.context';
import { REWRITE_BUTTON_ID } from '@root/resources/file/file.constants';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';
import fileContext from '@root/resources/file/file.context';
import useMenuItems from '@root/views/File/hooks/useMenuItems';
import useStyles from '@root/views/File/components/DocumentControls.styles';
import CheckCopyUniqueness from '@root/views/File/components/CheckCopyUniqueness';

import AssignToSelect from './SelectComponents/AssignToSelect';
import StatusSelect from './SelectComponents/StatusSelect';

const trimNChars = (str, n) => {
  const trimmed = str.trim();
  if (trimmed.length <= n) {
    return str;
  }
  return `${trimmed.slice(0, n)}...`;
};

const DocumentControls = ({
  actions = [],
  integration,
  integrationProps,
  setShowChar,
  navigateToShare,
  expandContent,
  rewriteContent,
  disableSelect,
  lastHighlightedText = '',
  maxRewriteLength,
  isRewriterLoading,
}) => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const {
    assignedToError,
    workflowStatusError,
    downloadSelectedAsCsv,
    downloadSelectedAsDocx,
    downloadSelectedAsPdf,
    downloadSelectedAsTxt,
    isAllSelectedFavorited,
    selectAll,
    deselectAll,
    checkedDocumentsIds,
    isAllSelected,
    likeCheckedDocuments,
    unlikeCheckedDocuments,
    deleteCheckedDocuments,
    openExample,
    filePermissions: { canManageDocuments, canEditFile },
    updateLocalFile,
  } = fileContext.useFile();
  const hasTeamPermissions = currentUser.permissions.teams;

  const {
    data: {
      limit: creditsLeft,
    } = {},
  } = billingResource.useLimits();

  const showDisabledGenerateTooltip = currentUser.onFreePlan && creditsLeft <= 0;
  const showPlagiarismCheck = actions.includes('plagiarismCheck') && canEditFile;
  const showContentRewriter = actions.includes('contentRewriter');

  const hasSelected = checkedDocumentsIds.length > 0;
  const exportWord = hasSelected ? 'selected' : 'all';

  const menuItems = useMenuItems({
    handlers: {
      toggleExampleOpen: openExample,
      navigateToShare,
      downloadSelectedAsTxt,
      downloadSelectedAsPdf,
      downloadSelectedAsCsv,
      downloadSelectedAsDocx,
      setShowChar,
    },
    params: {
      exportWord,
    },
    classes,
  });

  const showedMenuItems = Object
    .entries(menuItems)
    .filter(([key]) => {
      return actions.includes(key);
    })
    .map(([, value]) => {
      return value;
    });

  const assignUser = (id) => {
    updateLocalFile({ assignedToId: id });
  };

  const setWorkflowStatus = (value) => {
    updateLocalFile({ workflowStatus: value });
  };

  return (
    <div className={clsx(
      classes.documentControls,
      { [classes.hasErrors]: assignedToError || workflowStatusError },
    )}
    >
      <div className={classes.documentControlsLeft}>
        {!disableSelect && (
          <span
            className={classes.selectAll}
            onClick={isAllSelected ? deselectAll : selectAll}
            role="presentation"
          >
            {isAllSelected ? 'Deselect all' : 'Select all'}
          </span>
        )}

        {hasTeamPermissions && (
          <>
            <AssignToSelect onChange={assignUser} />
            <StatusSelect onChange={setWorkflowStatus} />
          </>
        )}
      </div>
      {checkedDocumentsIds.length > 0 && (
        <>
          {!isAllSelectedFavorited && (
            <SimpleTooltip
              content="Favorite copy"
              placement="bottom"
            >
              <span>
                <FontAwesomeIcon
                  icon="bookmark"
                  onClick={likeCheckedDocuments}
                  className={classes.documentControlButton}
                />
              </span>
            </SimpleTooltip>
          )}
          {isAllSelectedFavorited && (
            <SimpleTooltip
              content="Unfavorite copy"
              placement="bottom"
            >
              <span>
                <FontAwesomeIcon
                  icon="bookmark"
                  onClick={unlikeCheckedDocuments}
                  className={classes.documentControlButton}
                />
              </span>
            </SimpleTooltip>
          )}
          {canManageDocuments && (
            <>
              <SimpleTooltip
                content="Delete copy"
                placement="bottom"
              >
                <span>
                  <FontAwesomeIcon
                    icon="trash"
                    onClick={deleteCheckedDocuments}
                    className={classes.documentControlButton}
                  />
                </span>
              </SimpleTooltip>
              <span className={classes.documentControlDivider} />
            </>
          )}
        </>
      )}

      {showPlagiarismCheck && (
        ((!showDisabledGenerateTooltip)
          ? (
            <span className={classes.checkUniquenessWrap}>
              <CheckCopyUniqueness lastHighlightedText={lastHighlightedText} />
            </span>
          ) : (
            <DisabledGenerateTooltip enabled>
              <span>
                <FontAwesomeIcon
                  icon="fingerprint"
                  className={classes.documentControlButton}
                />
              </span>
            </DisabledGenerateTooltip>
          )
        ))}

      {actions.includes('contentExpander') && (
        (!showDisabledGenerateTooltip)
          ? (
            <SimpleTooltip
              content={(
                <span>
                  Expand:
                  {' '}
                  <br />
                  {lastHighlightedText.length > 0 ? (
                    <>
                      &quot;
                      {trimNChars(lastHighlightedText, 200)}
                      &quot;
                    </>
                  ) : (
                    <i>Highlight text to check</i>
                  )}
                  <div
                    className={clsx(classes.contentChangeInfo, {
                      [classes.redText]: lastHighlightedText.length > 150,
                    })}
                  >
                    {lastHighlightedText.length}
                    {' '}
                    / 150 characters
                  </div>
                </span>
          )}
              placement="bottom"
            >
              <span>
                <FontAwesomeIcon
                  icon="pencil-ruler"
                  onClick={expandContent}
                  className={clsx(classes.documentControlButton,
                    classes.greenDocumentControlButton)}
                />
              </span>
            </SimpleTooltip>
          ) : (
            <DisabledGenerateTooltip enabled>
              <span>
                <FontAwesomeIcon
                  icon="pencil-ruler"
                  className={classes.documentControlButton}
                />
              </span>
            </DisabledGenerateTooltip>
          )
      )}

      {showContentRewriter && (
        (!showDisabledGenerateTooltip)
          ? (
            <SimpleTooltip
              content={(
                <span>
                  Rewrite:
                  {' '}
                  <br />
                  {lastHighlightedText.length > 0 ? (
                    <>
                      &quot;
                      {trimNChars(lastHighlightedText, 500)}
                      &quot;
                    </>
                  ) : (
                    <i>Highlight text to check</i>
                  )}
                  <div
                    className={clsx(classes.contentChangeInfo, {
                      [classes.redText]: lastHighlightedText.length > maxRewriteLength,
                    })}
                  >
                    {lastHighlightedText.length}
                    {' '}
                    /
                    {' '}
                    {maxRewriteLength}
                    {' '}
                    characters
                  </div>
                </span>
          )}
              placement="bottom"
            >
              <span>
                <FontAwesomeIcon
                  id={REWRITE_BUTTON_ID}
                  icon="sync"
                  onClick={rewriteContent}
                  className={clsx(classes.documentControlButton,
                    classes.greenDocumentControlButton, {
                      [classes.spinAnimation]: isRewriterLoading,
                    })}
                />
              </span>
            </SimpleTooltip>
          ) : (
            <DisabledGenerateTooltip enabled>
              <span>
                <FontAwesomeIcon
                  icon="sync"
                  className={classes.documentControlButton}
                />
              </span>
            </DisabledGenerateTooltip>
          )
      )}

      <div
        className={clsx({
          [classes.documentControlButton]: integration?.renderPanelComponent,
        })}
      >
        {integration?.renderPanelComponent
        && integration?.renderPanelComponent(integrationProps, currentUser)}
      </div>

      {!!showedMenuItems.length && (
        <KebabMenu
          classNames={{
            kebab: classes.menu,
          }}
          menuItems={showedMenuItems}
        />
      )}
    </div>
  );
};

export default DocumentControls;
