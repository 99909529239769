import { Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import dateUtils from '@root/utils/date.util';

import useSectionsStyles from '../Sections.styles';

import EmptyState from './EmptyState';
import useStyles from './InvoicesSection.styles';

const InvoicesSection = ({ pastInvoices }) => {
  const classes = useStyles();
  const sectionClasses = useSectionsStyles();

  const hasInvoices = pastInvoices.length > 0;

  return (
    <section className={sectionClasses.sectionContainer}>
      <div className={sectionClasses.sectionTitleContainer}>
        <Typography variant="h4" className={sectionClasses.sectionTitle}>
          Invoices
        </Typography>
        <Typography variant="body1" className={sectionClasses.sectionSubtitle}>
          Here is where your monthly invoices will show.
        </Typography>
      </div>

      <div className={clsx(
        sectionClasses.cardContainer,
        sectionClasses.wideCard,
        classes.invoicesCard,
      )}
      >
        {!hasInvoices && <EmptyState />}

        {hasInvoices && (
          <div className={classes.invoicesList}>
            <header className={classes.invoicesHeader}>
              <Typography className={classes.headerText}>Billing period</Typography>
              <Typography className={classes.headerText}>Billed</Typography>
              <div />
            </header>

            <div className={classes.divider} />

            {pastInvoices.map((invoice) => (
              <div key={invoice.url} className={classes.invoiceItem}>
                <span className={classes.dateCreated}>
                  {dateUtils.formatDate(invoice.created, 'LLLL dd, yyyy')}
                </span>

                <span className={classes.billed}>
                  $
                  {invoice.amount_due}
                  {Number.isInteger(invoice.amount_due) && '.00'}
                </span>

                <span
                  className={classes.viewInvoice}
                  onClick={() => window.open(invoice.url, '_blank')}
                >
                  View Invoice
                </span>

                <div className={classes.divider} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default InvoicesSection;
