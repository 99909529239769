import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { useTemplatesDashboard, actions } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';

import useStyles from './Bookmark.styles';

const Bookmark = ({ isFavorite, type }) => {
  const classes = useStyles();
  const { dispatch } = useTemplatesDashboard();

  const handelClick = () => {
    dispatch({
      type: isFavorite ? actions.REMOVE_FAVORITE : actions.ADD_FAVORITE,
      payload: type,
    });
  };

  return (
    <div className={classes.star} onClick={handelClick}>
      {isFavorite ? (
        <StarIcon className={classes.gold} />
      ) : (
        <StarBorderIcon />
      )}
    </div>
  );
};

export default Bookmark;
