import React from 'react';

import Modal from '@root/components/modals/Modal';
import Button from '@root/components/buttons/Button';
import { modalsActions } from '@root/views/Community/context/confirmationModalsReducer';
import { useCommunityContext } from '@root/views/Community/context/Community.context';
import ProgressButton from '@root/components/ProgressButton';

import useStyles from './SaveConfirmationModal.styles';

const SaveConfirmationModal = () => {
  const classes = useStyles();

  const {
    modalsState,
    modalsDispatch,
    saveToMyAccount: saveCommunityTemplateToLibrary,
    isTemplateProcessing,
  } = useCommunityContext();

  const { open, templateId } = modalsState.saveToMyAccount;

  const handleModalClose = () => {
    if (isTemplateProcessing) return;

    modalsDispatch({
      type: modalsActions.TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL,
      payload: null,
    });
  };

  const handleSaveClick = async () => {
    if (isTemplateProcessing) return;

    await saveCommunityTemplateToLibrary(templateId);
    handleModalClose();
  };

  return (
    <Modal
      title="Save to my account"
      open={open}
      onClose={handleModalClose}
      classNames={{ paper: classes.modal }}
    >
      <div className={classes.content}>
        <p className={classes.contentText}>
          Saving this template to your account will count toward your custom template limit.
          Once saved you&apos;re allowed to access, run generations, and edit it for custom use.
        </p>
      </div>

      <div className={classes.buttonsContainer}>
        <Button
          variant="text"
          onClick={handleModalClose}
        >
          Cancel
        </Button>

        <ProgressButton
          variant="contained"
          onClick={handleSaveClick}
          isLoading={isTemplateProcessing}
          size="small"
        >
          {!isTemplateProcessing && 'Save'}
          {isTemplateProcessing && 'Loading...'}
        </ProgressButton>
      </div>
    </Modal>
  );
};

export default SaveConfirmationModal;
