import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootFolderItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  rootFolderRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',

    padding: '4px 8px',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },

  activeRootFolderRow: {
    backgroundColor: theme.palette.colors.neutrals50,
  },

  projectIcon: {
    flexShrink: 0,
    color: theme.palette.colors.neutrals400,
  },

  sharedIcon: {
    flexShrink: 0,
    color: theme.palette.colors.neutrals200,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  actionsContainer: {
    marginLeft: 'auto',

    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },

  chevronIcon: {
    color: theme.palette.colors.neutrals300,
    transform: 'rotate(0deg)',
    transition: 'transform .5s',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },

  expandedChevronDownIcon: {
    transform: 'rotate(180deg)',
  },

  childFoldersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    marginTop: '8px',
  },

  newRoot: {
    paddingLeft: '5px',
  },
  rootFolder: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    overflow: 'hidden',
    marginBottom: '0.25rem',
    alignItems: 'center',
    minHeight: '2rem',
    maxWidth: '100%',
  },
  newRootFolder: {
    marginBottom: '0',
  },
  active: {
    '&&': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  hover: {
    '&&': {
      border: '1px solid black',
    },
  },
  folderTitle: {
    fontSize: '0.875rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#000',
    flexGrow: '1',
    '&:hover': {
      color: '#5b5b5b',
    },
  },

  hidden: {
    display: 'none',
  },

  useDrop: {
    boxSizing: 'border-box',
    outline: '1px solid #666',
    // borderRadius: '3px',
    // padding: '3px',
  },
  isDraggingOver: {
    background: '#ddd',
    borderColor: 'transparent',
    color: '#000',
  },
}));

export default useStyles;
