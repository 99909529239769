import React, { createContext, useState, useContext, useEffect } from 'react';

import folderResource from '@root/resources/folder';
import AuthContext from '@root/resources/auth/auth.context';

const WorkflowQueueContext = createContext();

const WorkflowQueueProvider = ({
  children, file,
}) => {
  const { currentUser } = AuthContext.useAuth();

  const fileAssignedToId = file?.assignedTo?.userId;
  const fileWorkflowStatus = file?.workflowStatus;

  const [assignedToId, setAssignedToId] = useState(file?.assignedTo?.userId);
  const [workflowStatus, setWorkflowStatus] = useState(file?.workflowStatus);

  const [assignedToError, setAssignedToError] = useState('');
  const [workflowStatusError, setWorkflowStatusError] = useState('');

  const { data: folder } = folderResource.useFolder(file && file.folderId);
  const filePermissions = currentUser.permissions.getFilePermissions(file, folder);
  const { canManageDocuments } = filePermissions;

  useEffect(() => {
    setAssignedToId(fileAssignedToId);
  }, [fileAssignedToId]);

  useEffect(() => {
    setWorkflowStatus(fileWorkflowStatus);
  }, [fileWorkflowStatus]);

  return (
    <WorkflowQueueContext.Provider
      value={{
        assignedToId,
        setAssignedToId,

        workflowStatus,
        setWorkflowStatus,

        assignedToError,
        setAssignedToError,
        workflowStatusError,
        setWorkflowStatusError,

        canManageDocuments,
      }}
    >
      {children}
    </WorkflowQueueContext.Provider>
  );
};

function useWorkflowQueue() {
  const context = useContext(WorkflowQueueContext);
  if (context === undefined) {
    throw new Error('useWorkflowQueue must be used within a WFQProvider');
  }
  return context;
}

export default { WorkflowQueueProvider, useWorkflowQueue };
