import React from 'react';

import TemplatesContext from '@root/resources/templates/templates.context';
import Fields from '@root/views/File/components/FileInputFields';

import useStyles from './FieldTemplateDetails.styles';

const FieldTemplateDetails = ({
  templateType,
  fileFieldsData,
  onFileFieldsDataChange,
}) => {
  const classes = useStyles();
  const { getTemplate } = TemplatesContext.useTemplates();
  const config = getTemplate(templateType);
  const fields = config.fields.filter((f) => f.name !== 'language' && f.name !== 'tone');
  return (
    <div className={classes.root}>
      <Fields
        fields={fields}
        data={fileFieldsData}
        onDataChange={onFileFieldsDataChange}
        templateType={config.templateType}
        showAli={false}
        showLanguage={false}
        isFFSE
      />
    </div>
  );
};

export default FieldTemplateDetails;
