import React from 'react';
import clsx from 'clsx';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Button from '@root/components/buttons/Button';
import DocumentView from '@root/components/DocumentView';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboard/TemplatesDashboard.context';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './ContentArea.styles';

const TEMPLATE_CREATE_MODAL_ID = 'TEMPLATE_CREATE_MODAL_ID';

const ContentArea = ({ content }) => {
  const { dispatch } = useGlobalModals();
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { newEditor: isRemoveCreateFileModalEnabled } = currentUser.featureToggles;

  const { handleCreate } = useTemplatesDashboard();

  const handleCreateModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: TEMPLATE_CREATE_MODAL_ID,
        modalContent: content,
      },
    });
  };

  const handleCreateClick = () => {
    const isFreeFormEditor = content.templateType === 'freeFormEditor';

    if (isFreeFormEditor && isRemoveCreateFileModalEnabled) {
      handleCreate(content.templateType);
      return;
    }

    handleCreateModal();
  };

  if (!content) {
    return null;
  }

  return (
    <div className={classes.content}>
      <div className={classes.innerContent}>
        <div className={classes.header}>
          <div>
            {content.Icon && <content.Icon color="#000" size={24} />}
          </div>
        </div>
        <h1 className={classes.title}>{content.title}</h1>
        <p className={classes.description}>
          {content.description}
        </p>
        <h2 className={classes.subTitle}>Sample Output:</h2>
      </div>

      <div className={classes.exampleOutput}>
        <DocumentView
          key={content.templateType}
          document={{
            _id: content.templateType,
            templateType: content.templateType,
            content: content.newExample.content,
          }}
          fileData={content.newExample.fileFields || {
            companyName: '',
          }}
          options={{
            canEdit: false,
            canDelete: false,
            showVote: false,
            canLike: false,
            canCopy: false,
            canFlag: false,
          }}
        />
      </div>
      <div className={clsx(classes.innerContent, classes.actions)}>
        <Button onClick={() => handleCreateClick()}>Create</Button>
      </div>
    </div>
  );
};

export default ContentArea;
