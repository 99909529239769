import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return ({
    topField: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    midField: {
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: -1,
        borderRadius: 0,
      },
    },
    bottomField: {
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: -1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    rowWrapper: {
      width: '100%',
      display: 'flex',
      '& > div': {
        flex: 1,
      },
      '& > div:not(:last-child)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRight: 'none',
        },
      },
    },
  });
});

export default useStyles;
