import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.colors.black,
    color: theme.palette.colors.white,
    borderColor: theme.palette.colors.black,
    opacity: 0.5,
    padding: '0',
    '& a': {
      color: theme.palette.colors.blue400,
    },
  },
  arrow: {
    color: theme.palette.colors.black,
    '&:before': {
      borderColor: theme.palette.colors.black,
    },
  },
  wrapper: {
    display: 'inline-block',
    lineHeight: '1',
  },
  tooltipContentWrapper: {
    padding: '8px 12px',
  },
}));
