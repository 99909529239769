import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Button from '@root/components/buttons/Button';
import DocumentView from '@root/components/DocumentView';
import { useTemplatesDashboard } from '@root/views/TemplatesDashboardNew/TemplatesDashboard.context';
import AuthContext from '@root/resources/auth/auth.context';
import { templates } from '@root/views/Editor/Editor.constants';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';

import useStyles from './ContentArea.styles';

const ContentArea = ({ content }) => {
  const classes = useStyles();
  const history = useHistory();

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { currentUser } = AuthContext.useAuth();
  const { newEditor: isRemoveCreateFileModalEnabled } = currentUser.featureToggles;

  const { handleCreate } = useTemplatesDashboard();

  const handleCreateClick = () => {
    const isBlogKickstarter = content.templateType === 'blogKickstarter';
    const isFreeFormEditor = content.templateType === 'freeFormEditor';
    const isEditorTemplate = Object.values(templates)
      .map((t) => t.templateType)
      .includes(content.templateType);
    const { disableEditor } = currentExternalSource?.toggles;

    if (isBlogKickstarter) {
      history.push('/blog-kickstarter');
      return;
    }

    if (isFreeFormEditor && isRemoveCreateFileModalEnabled) {
      handleCreate(content.templateType);
      return;
    }

    if (!disableEditor && (isFreeFormEditor || isEditorTemplate) && isRemoveCreateFileModalEnabled) {
      handleCreate('freeFormEditor', isEditorTemplate && content.templateType);
      return;
    }

    handleCreate(content.templateType);
  };

  if (!content) {
    return null;
  }

  return (
    <div className={classes.content}>
      <div className={classes.innerContent}>
        <div className={classes.header}>
          <div>
            {content.Icon && <content.Icon color="#000" size={24} />}
          </div>
        </div>
        <h1 className={classes.title}>{content.title}</h1>
        <p className={classes.description}>
          {content.description}
        </p>
        <h2 className={classes.subTitle}>Sample Output:</h2>
      </div>

      <div className={classes.exampleOutput}>
        <DocumentView
          key={content.templateType}
          document={{
            _id: content.templateType,
            templateType: content.templateType,
            content: content.newExample.content,
          }}
          fileData={content.newExample.fileFields || {
            companyName: '',
          }}
          options={{
            canEdit: false,
            canDelete: false,
            showVote: false,
            canLike: false,
            canCopy: false,
            canFlag: false,
          }}
        />
      </div>
      <div className={clsx(classes.innerContent, classes.actions)}>
        <Button onClick={handleCreateClick}>Create</Button>
      </div>
    </div>
  );
};

export default ContentArea;
