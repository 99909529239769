import React from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { IconChevronRight, IconDots, IconShare, IconSortAscending } from '@tabler/icons-react';

import MenuButton from '@root/components/MenuButton';
import PublishButton from '@root/components/DocumentView/components/PreviewFooter/components/PublishButton';
import ShareFileButton from '@root/components/ShareFileButton';

import useStyles from './AdditionalMenuButton.styles';
import ExportSubmenu from './ExportSubmenu';

const AdditionalMenuButton = ({ file, document, editorData, editorDataHtml }) => {
  const classes = useStyles();

  if (!file || !document) {
    return null;
  }

  const { _id: fileId, templateType, title } = file;
  const documentCopy = { ...document };
  documentCopy.content = editorDataHtml;

  return (
    <MenuButton
      button={(
        <IconButton className={classes.button}>
          <IconDots />
        </IconButton>
      )}
    >
      <PublishButton
        templateType={templateType}
        fileId={fileId}
        document={documentCopy}
        placementHorizontal="left"
        placementVertical="top"
        button={(
          <MenuItem className={classes.button}>
            <div className={classes.buttonContent}>
              <IconSortAscending className={classes.icon} />
              Publish to
            </div>
            <IconChevronRight
              className={classes.chevron}
              size={16}
              strokeWidth={3}
            />
          </MenuItem>
        )}
      />

      <ExportSubmenu
        fileTitle={title}
        editorDataHtml={editorDataHtml}
        editorData={editorData}
      />

      <ShareFileButton
        fileId={fileId}
        button={(
          <MenuItem className={classes.button}>
            <div className={classes.buttonContent}>
              <IconShare className={classes.icon} />
              Share
            </div>
          </MenuItem>
        )}
      />
    </MenuButton>
  );
};

export default AdditionalMenuButton;
