import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { MeshGradientUtil } from '@copysmith/utils';

import useStyles from './Header.styles';

const Header = () => {
  const classes = useStyles();
  const canvasRef = useRef(null);
  const gradient = new MeshGradientUtil();

  useEffect(() => {
    if (!!gradient && !!canvasRef.current) {
      gradient.initGradient('#gradient-canvas');
    }
    return (() => {
      gradient.disconnect();
    });
  }, [gradient, canvasRef]);

  return (
    <div className={classes.headerContainer}>
      <canvas
        id="gradient-canvas"
        className={classes.gradientCanvas}
        data-transition-in
        ref={canvasRef}
      />

      <Typography variant="h3" className={classes.title}>
        Community Library
      </Typography>

      <Typography variant="body2" className={classes.subtitle}>
        Browse our library of use cases for Copysmith created by users like you
      </Typography>
    </div>
  );
};

export default Header;
