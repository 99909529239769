import React from 'react';

import NotificationBell from './NotificationBell';
import ProfileDropdown from './ProfileDropdown';
import useStyles from './RightPart.styles';

const RightPart = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NotificationBell />

      <ProfileDropdown />
    </div>
  );
};

export default RightPart;
