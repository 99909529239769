import { useQuery } from 'react-query';

import api from '@root/api';

const newsToOldApiFormat = (news) => ({
  id: news._id,
  date: news.createdOn,
  content: news.content,
  link: news.link,
});

const getNews = async () => {
  const resp = await api.news.list();

  return resp.map(newsToOldApiFormat);
};

export default function useNews() {
  return useQuery(['news.list', {}], () => getNews());
}
