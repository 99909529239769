import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface UpdateOptions extends RequestOptions {
  name: string;
  url: string;
  description: string;
  keywords: string[];
  prefillInputs: boolean;
  language: string,
  industrySegment?: string;
  bestHelpYou?: string;
  copyType?: string,
  teamSize?: string,
  completed?: boolean;
}

export interface ParseOptions extends RequestOptions {
  url: string;
}

export class Company {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public async update(data: UpdateOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/company", data, options);

    return result;
  }

  public async get(options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/company", {}, options);

    return result;
  }

  public async parse(data: ParseOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/company/parse", data, options);

    return result;
  }

  public async list(query: Record<string, string>, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.get("/company/list", query, options);

    return result;
  }

  public async assignTemplate(data: Record<string, string>, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/company/assignTemplate", data, options);

    return result;
  }

  public async unassignTemplate(data: Record<string, string>, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.put("/company/unassignTemplate", data, options);

    return result;
  }

  public async requestApiAccess(options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/company/requestApiAccess", {}, options);

    return result;
  }
  
  public async generateReferralCode(data: null, options?: HandlingOptions): Promise<Record<string, unknown>> {
    const result = await this.client.post("/company/generateReferralCode", data, options);

    return result;
  }
}
