import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  folderContentContainer: {
    backgroundColor: theme.palette.colors.neutrals50,

    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  folderContentWrapper: {
    padding: '24px 48px',

    height: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
