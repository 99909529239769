import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons-react';
import isNaN from 'lodash/isNaN';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import AuthContext from '@root/resources/auth/auth.context';

import useSectionStyles from '../Sections.styles';
import CreditRecordType from '../../UsageV2.types';

import useStyles from './OverageSection.styles';

type Props = {
  creditRecord?: CreditRecordType;
  isLoading: boolean;
};

const OverageSection:FC<Props> = ({ creditRecord, isLoading }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const { currentCompany }: { currentCompany: any } = AuthContext.useAuth();

  const overage = useMemo(() => (
    creditRecord?.overage?.mainCredits || 0
  ), [creditRecord]);

  const overageFee = useMemo(() => {
    const numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    return (
      numberFormatter.format((overage * currentCompany?.billing?.limits?.mainCreditsOveragePrice) / 100)
    );
  }, [overage]);

  return (
    <Grid
      item
      md={3}
      sm={4}
      xs={12}
    >
      <div className={sectionClasses.sectionContainer}>
        <Typography classes={{ root: sectionClasses.sectionTitle }}>Overage words</Typography>

        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!isLoading && (
          <div className={sectionClasses.statsContainer}>
            <article className={clsx(sectionClasses.articleContainer, sectionClasses.fullWidthArticle)}>
              <div>
                <Typography
                  classes={{ root: sectionClasses.articleTitle }}
                >
                  Words over base plan
                  {/* @ts-expect-error SimpleTooltip is not .ts yet */}
                  <SimpleTooltip content="The number of words you have exceeded over your plan." wrapped>
                    <IconInfoCircle size={16} />
                  </SimpleTooltip>
                </Typography>

                <Typography classes={{ root: sectionClasses.articleValue }}>{overage}</Typography>
              </div>
            </article>

            <article
              className={clsx(sectionClasses.articleContainer, sectionClasses.fullWidthArticle)}
            >
              <div>
                <Typography classes={{ root: sectionClasses.articleTitle }}>
                  Overage
                  {/* @ts-expect-error SimpleTooltip is not .ts yet */}
                  <SimpleTooltip
                    content="The amount you have spent on overage words."
                    wrapped
                  >
                    <IconInfoCircle size={16} />
                  </SimpleTooltip>
                </Typography>

                <Typography classes={{ root: sectionClasses.articleValue }}>
                  {isNaN(+overageFee) ? '$0' : `$${overageFee}`}
                </Typography>
              </div>
            </article>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default OverageSection;
