import { useQuery } from 'react-query';

import api from '@root/api';

const getPricing = async () => {
  const pricing = await api.billing.getPricing();

  return pricing;
};

export default function usePlanInfo() {
  return useQuery(['billing.getPricing'], () => getPricing(), {
    refetchOnMount: 'always',
  });
}
