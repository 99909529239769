import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link as UiLink, Typography, CircularProgress } from '@material-ui/core';
import { omit } from 'lodash';

import Button from '@root/components/buttons/Button';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';
import ScrollFade from '@root/components/ScrollFade/ScrollFade';
import aiResource from '@root/resources/ai';
import billingResource from '@root/resources/billing';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import useModalState from '@root/utils/hooks/useModalState';
import CostWarningModal from '@root/views/BulkCopy/components/CostWarningModal';
import bulkCopyUtils from '@root/views/BulkCopy/bulkCopy.utils';
import useMappingValidate from '@root/resources/bulkGenerate/useMappingValidate';

import useStyles from './FieldMappingForm.styles';

const march2023Pro = [
  ...PLANS_IDS.march2023Pros,
];

const FieldMappingForm = ({ selectedFile, template, aiOptions, onSubmit }) => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const [fieldMapping, setFieldMapping] = useState({});
  const [expectedGenerations, setExpectedGenerations] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    isCostWarningOpen,
    openCostWarningModal,
    closeCostWarningModal,
  ] = useModalState(false);
  const [isSubmittingGeneration, setSubmittingGeneration] = useState(false);

  const {
    mutateAsync: validateMapping,
    isLoading: isValidating,
  } = useMappingValidate({ templateType: template.templateType, aiOptions });
  const { data: templateCost = 0 } = aiResource.useTemplateCost({ templateType: template.templateType, aiOptions });
  const { data: billingInfo = {} } = billingResource.useBillingInfo();
  const {
    data: {
      maxBulkGenerationRows,
      limit,
    } = {},
  } = billingResource.useLimits();

  // transform data to object like format
  const transformedFileRows = useMemo(() => {
    const result = {};
    const [headers, ...rows] = selectedFile?.rows;

    headers.forEach((header, index) => {
      result[header] = rows.map((row) => row[index]).slice(0, 4);
    });

    return result;
  }, [selectedFile]);

  const stepsCount = selectedFile?.templateFields?.length;
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === stepsCount - 1;

  const currentTemplateField = selectedFile?.templateFields?.[currentStep];
  const isCurrentTemplateFieldRequired = currentTemplateField.required;
  const isNextStepDisabled = (
    isCurrentTemplateFieldRequired && !fieldMapping?.[currentTemplateField.name]
  );

  const downloadSample = async () => {
    await bulkCopyUtils.downloadSampleFile({
      templateName: template.title,
      templateType: template.templateType,
    });
  };

  const handleConfirmCostWarning = () => {
    setSubmittingGeneration(true);
    onSubmit(fieldMapping).then(() => setSubmittingGeneration(false));
    closeCostWarningModal();
  };

  const handleGenerateClick = () => {
    const { priceId } = billingInfo;

    const isEnoughCredits = expectedGenerations === null
    || expectedGenerations >= selectedFile.totalRows
    || march2023Pro.includes(priceId);

    if (isEnoughCredits) {
      setSubmittingGeneration(true);
      onSubmit(fieldMapping).then(() => setSubmittingGeneration(false));
      return;
    }

    openCostWarningModal();
  };

  const handlePreviousStepClick = () => {
    if (currentStep > 0) {
      setErrorMessage(null);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStepClick = () => {
    if (isLastStep) {
      onSubmit(fieldMapping);
      return;
    }

    if (currentStep < stepsCount - 1) {
      setErrorMessage(null);
      setCurrentStep(currentStep + 1);
    }
  };

  const validateMappingFunc = (file, fileMapping) => {
    validateMapping({
      fileId: file.fileId,
      fileMapping,
      singleFieldValidate: selectedFile?.templateFields?.[currentStep].name,
    }).then((response) => {
      const fileErrors = response?.errors?.find((error) => error?.file)?.file;
      if (fileErrors?.length) {
        setErrorMessage(fileErrors);
      } else {
        setErrorMessage(null);
      }
    });
  };

  const handleColumnClick = (columnName) => {
    const isDeselect = fieldMapping?.[selectedFile?.templateFields?.[currentStep].name] === columnName;

    if (isDeselect) {
      setFieldMapping(omit(fieldMapping, selectedFile?.templateFields?.[currentStep].name));
      setErrorMessage(null);
    } else {
      const newFieldMapping = {
        ...fieldMapping,
        [selectedFile?.templateFields?.[currentStep].name]: columnName,
      };
      setFieldMapping(newFieldMapping);
    }
  };

  // ASYNC validation process when column suggested or selected
  useEffect(() => {
    const isColumnSelected = fieldMapping?.[currentTemplateField.name];
    if (isColumnSelected) {
      validateMappingFunc(selectedFile, fieldMapping);
    }
  }, [currentTemplateField, selectedFile, fieldMapping]);

  // set suggestedMapping fields
  useEffect(() => {
    if (!selectedFile?.suggestedMapping) {
      return;
    }
    const transformedObject = Object.values(selectedFile.suggestedMapping)
      .reduce((acc, value) => {
        const { name, field } = value;
        if (field) {
          acc[name] = field;
        }
        return acc;
      }, {});
    setFieldMapping(transformedObject);
  }, [selectedFile]);

  useEffect(() => {
    if (!templateCost) {
      return;
    }
    setExpectedGenerations(Math.min(
      Math.floor(limit / templateCost),
      maxBulkGenerationRows,
    ) || 0);
  }, [templateCost]);

  return (
    <div className={classes.mappingContainer}>
      <div className={classes.titleContainer}>
        <Typography
          className={classes.title}
        >
          {`${currentStep + 1} of ${stepsCount}: Select the column that contains ${currentTemplateField?.label}`}
          {isCurrentTemplateFieldRequired && <span className={classes.asterisk}> *</span>}
        </Typography>

        <Typography
          className={classes.subtitle}
        >
          Bulk Copy accepts a CSV file based on the&nbsp;
          <UiLink onClick={downloadSample}>example file</UiLink>
          &nbsp;found within the application.
        </Typography>

        {errorMessage && (
          <Typography
            className={classes.errorMessage}
          >
            {errorMessage.join('\n')}
          </Typography>
        )}
      </div>

      <div className={classes.columnsContainer}>
        <ScrollFade />
        {Object.entries(transformedFileRows).map(([key, value]) => {
          const isSelected = fieldMapping?.[currentTemplateField?.name] === key;
          return (
            <div
              key={key}
              className={clsx(
                classes.column,
                { [classes.selected]: isSelected },
              )}
              onClick={() => handleColumnClick(key)}
            >
              {isSelected && isValidating && (
                <div className={classes.validationLoaderContainer}>
                  <CircularProgress color="#4D41F1" className={classes.loader} />
                </div>
              )}

              <TruncatedText
                className={classes.columnHeader}
              >
                {key}
              </TruncatedText>

              <div className={classes.valuesContainer}>
                {value.map((cell, index) => (
                  <TruncatedText
                      // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={classes.columnValue}
                    withoutTooltip
                  >
                    {cell || '<<NO DATA>>'}
                  </TruncatedText>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.buttonsContainer}>
        {!isFirstStep && (
          <Button
            variant="text"
            onClick={handlePreviousStepClick}
          >
            Back
          </Button>
        )}

        {/* TODO: process errors after field mapping submit */}
        <Button
          onClick={isLastStep ? handleGenerateClick : handleNextStepClick}
          disabled={isNextStepDisabled || isSubmittingGeneration}
          isLoading={isSubmittingGeneration}
        >
          {isLastStep ? 'Generate' : 'Next Step'}
        </Button>
      </div>

      {isCostWarningOpen && (
        <CostWarningModal
          onConfirm={handleConfirmCostWarning}
          onClose={closeCostWarningModal}
          rowsAvailable={expectedGenerations}
        />
      )}
    </div>
  );
};

export default FieldMappingForm;
