import React, { useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import api from '@root/api';
import useInvalidateLimits from '@root/resources/billing/useInvalidateLimits';
import CloseButton from '@root/components/buttons/CloseButton';
import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import apiKeyResource from '@root/resources/apiKey';
import useIntegrations from '@root/resources/integrations/useIntegrations';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import useStyles from './UpgradeModal.styles';

const UpgradeModal = ({
  onClose,
  freeSeats = 0,
  shouldRemoveUnlimited,
  shouldRemoveCustomTemplates,
  customTemplatesCount = 0,
  maxCustomTemplatesPerPlan = 0,
  showRolloverUnavailableAlert,
  showUnlimitedCreditsUnavailableAlert,
  currentPlan,
  plan,
  showFirstStep,
  doNotPromptForRemoveSeats,
  isUnlimited,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const { invalidateLimits } = useInvalidateLimits();
  const { fetchAuthData, setShowCredits } = AuthContext.useAuth();
  const { refetch: refetchBillingInfo } = billingResource.useBillingInfo();
  const { refetch: refetchApiKeys } = apiKeyResource.useApiKeys();
  const { refetch: refetchIntegrations } = useIntegrations();

  const [step, setStep] = useState(showFirstStep ? 1 : 2);

  const planName = plan.name;

  const onContinue = async () => {
    if (step === 1) {
      setStep(2);
      return;
    }

    const response = await api.billing.updateSubscription({
      price: plan._id,
    });
    if (response.isBadRequest) {
      return;
    }
    history.push({
      pathname: '/templates',
      search: '?refresh=true',
    });
    setTimeout(() => {
      setShowCredits(!isUnlimited);
      refetchApiKeys();
      refetchIntegrations();
      fetchAuthData();
      refetchBillingInfo();
      invalidateLimits();
    }, 5000);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            classes={classes}
            onClose={onClose}
            onContinue={onContinue}
            shouldRemoveCustomTemplates={shouldRemoveCustomTemplates}
            shouldRemoveUnlimited={shouldRemoveUnlimited}
            freeSeats={freeSeats}
            planName={planName}
            customTemplatesCount={customTemplatesCount}
            maxCustomTemplatesPerPlan={maxCustomTemplatesPerPlan}
            doNotPromptForRemoveSeats={doNotPromptForRemoveSeats}
          />
        );
      case 2:
        return (
          <Step2
            classes={classes}
            onClose={onClose}
            onContinue={onContinue}
            newPlan={plan}
            oldPlan={currentPlan}
            showRolloverUnavailableAlert={showRolloverUnavailableAlert}
            showUnlimitedCreditsUnavailableAlert={showUnlimitedCreditsUnavailableAlert}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <CloseButton
        onClick={onClose}
      />
      <DialogTitle>
        {plan.rank <= currentPlan.rank ? 'Downgrade' : 'Upgrade'}
      </DialogTitle>
      {renderStep()}
    </Dialog>
  );
};

export default UpgradeModal;
