import React from 'react';
import { makeStyles } from '@material-ui/core';

import FileCard from './FileCard';
import FolderCard from './FolderCard';

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
  },
  cardContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0.5rem',
    },
    margin: '-0.5rem',
  },
});

// eslint-disable-next-line no-unused-vars
const DashCardView = ({
  files, subfolders, isLoading, isDraggable,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cardContainer}>
        {!isLoading && subfolders.map((subfolder) => (
          <FolderCard
            isDraggable={isDraggable}
            folder={subfolder}
            key={`folder-card-${subfolder._id}`}
          />
        ))}
        {!isLoading && files.map((file) => (
          <FileCard
            isDraggable={isDraggable}
            file={file}
            key={`file-card-${file._id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default DashCardView;
