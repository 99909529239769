import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modal: {
    overflowY: 'hidden',
  },
  useCaseModalContent: {
    padding: '16px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 560,
    width: 936,
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '39px',
    alignSelf: 'flex-start',
    marginBottom: 32,
  },
}));

export default useStyles;
