import React from 'react';

import useStyles from './EmptySearchView.styles';

const EmptySearchView = () => {
  const classes = useStyles();

  return (
    <div className={classes.emptyViewContainer}>
      <p className={classes.title}>
        Oops! Nothing matches that search.
      </p>

      <p className={classes.subtitle}>
        Looks like nothing matches the research, try again!
      </p>

    </div>
  );
};

export default EmptySearchView;
