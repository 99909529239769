import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    checklistItemRoot: {
      padding: '4px 8px',
      border: `1px solid ${theme.palette.colors.mediumLightGrey}`,
      borderRadius: 4,
      cursor: ({ interactive }) => interactive && 'pointer',
    },
  });
});

export default useStyles;
