import React from 'react';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent } from '@material-ui/core';
import { capitalize } from 'lodash';

import billingService from '@root/resources/billing/billing.service';
import { formatAmount, PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

const Step2 = ({
  newPlan,
  oldPlan,
  classes,
  onClose,
  onContinue,
  showRolloverUnavailableAlert,
  showUnlimitedCreditsUnavailableAlert,
}) => {
  const [planProration, setPlanProration] = React.useState();
  const [addonsProration, setAddonsProration] = React.useState();
  const [warningAccepted, setWarningAccepted] = React.useState(!showRolloverUnavailableAlert);
  const [loading, setLoading] = React.useState(false);

  const toggleWarningAccepted = () => {
    setWarningAccepted(!warningAccepted);
  };

  const updateData = newPlan.rank <= oldPlan.rank ? {
    status: 'downgrade',
    action: 'downgrading',
  } : {
    status: 'upgrade',
    action: 'upgrading',
  };

  const getProrationResult = async () => {
    const response = await billingService.getProration(newPlan._id);
    setPlanProration(response.plan);
    setAddonsProration(response.addons);
  };
  React.useEffect(() => {
    if (newPlan) {
      getProrationResult();
    }
  }, []);

  const handleContinue = async () => {
    try {
      setLoading(true);
      onContinue();
    } catch (error) {
      setLoading(false);
    }
  };

  const getMarch2023UpgradeMessage = () => {
    const march2023StarterOrPro = [
      ...PLANS_IDS.march2023StarterMonthly,
      ...PLANS_IDS.march2023StarterAnnual,
      ...PLANS_IDS.march2023Pros,
    ];

    if (march2023StarterOrPro.includes(oldPlan._id)) {
      return '';
    }

    const isDowngrade = newPlan.rank < oldPlan.rank;

    if (isDowngrade && oldPlan.internalName === 'pro' && newPlan.internalName === 'starter') {
      return (
        <div>
          {`By choosing this plan you will${newPlan.rank === 151 ? ' gain unlimited words but will' : ''} lose access to the following:`}
          <ul>
            <li>
              Bulk processing
            </li>
            <li>
              Any and all integrations
            </li>
            <li>
              Ability to request API access
            </li>
          </ul>
        </div>
      );
    }

    if (!isDowngrade && ((oldPlan.internalName === 'starter' && newPlan.internalName === 'pro') || oldPlan.internalName === newPlan.internalName)) {
      return (
        <div>
          {`By upgrading from your current ${oldPlan.name} plan to the new ${newPlan.name} plan you will ${newPlan.rank === 151 || newPlan.rank === 250 ? 'gain access to unlimited words.' : ''}
          ${newPlan.rank === 151 || newPlan.rank === 250 ? 'You will' : ''} be able to keep all existing integrations AND we will honor any team seats.`}

          {newPlan.rank === 250 && (
            <>
              <br />
              <br />
              By upgrading to the new Professional Plan your Bulk usage would be
              limited by 100 product descriptions or rows per month.
            </>
          ) }
          <br />
          <br />
        </div>
      );
    }

    return '';
  };

  return (
    <>
      <DialogContent className={classes.content}>
        {planProration ? (
          <>
            <div>
              {getMarch2023UpgradeMessage()}
              {`You are about to ${updateData.status} `}
              <span className={classes.bold}>
                {`from ${oldPlan.nickname || oldPlan.name}${oldPlan.period === 'yearly' ? ' (Yearly)' : ' (Monthly)'} 
              to ${newPlan.nickname || newPlan.name}${newPlan.period === 'yearly' ? ' (Yearly)' : ' (Monthly)'}`}
              </span>
              .
              <span>
                {planProration.total >= 0 && (
                  <span>
                    {' '}
                    The plan you are
                    {' '}
                    {updateData.action}
                    {' '}
                    to
                    requires a payment and your card on file will be charged
                    {' '}
                    <span>
                      {formatAmount(planProration.total)}
                    </span>
                    {' '}
                    once you
                    {' '}
                    {updateData.status}
                    .
                  </span>
                )}
                {planProration.total < 0 && (
                  <span>
                    {' '}
                    The plan you are switching to is less expensive and you will
                    receive a credit of
                    {' '}
                    <span>
                      {formatAmount(planProration.total)}
                    </span>
                    {' '}
                    once you switch.
                  </span>
                )}
                {oldPlan.period === 'monthly'
              && newPlan.period === 'yearly' && (
                <span>
                  {' '}
                  You are switching from a monthly billing plan to an annual
                  billing plan. Today is the first date of your annual plan. You
                  will be charged again next year on
                  {' '}
                  {new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1),
                  ).toLocaleDateString('en-US')}
                  . See you in a year!
                </span>
                )}
                {oldPlan.period === 'yearly'
              && newPlan.period === 'monthly' && (
                <span>
                  {' '}
                  You are switching from an annual billing plan to a monthly
                  billing plan. Today will become your billing date and your
                  card on file will be charged the monthly fee today.
                </span>
                )}
                {updateData.status === 'downgrade' && oldPlan.data.limits.mainCredits > newPlan.data.limits.mainCredits && (
                  <span>
                    {' '}
                    You are switching to a plan with less monthly words than what
                    you currently receive.
                  </span>
                )}
              </span>
              {showUnlimitedCreditsUnavailableAlert && (
                <span>
                  {' '}
                  Please be aware that unlimited words will no longer be available with your new subscription.
                </span>
              )}
            </div>
            {addonsProration?.total > 0 && (
              <div>
                {' '}
                You will also be charged
                {' '}
                {formatAmount(addonsProration.total)}
                {' '}
                (the amount may be less, as when changing the plan, some of the addons may be canceled)
                {' '}
                for addons to keep your billing dates in sync.
              </div>
            )}
            {showRolloverUnavailableAlert && (
              <>
                <div>
                  After switching to a monthly plan you can no longer roll over any unused words.
                  Please be sure to use up any existing rollover words before making the switch to a monthly plan
                </div>
                <div onClick={toggleWarningAccepted} className={classes.checkboxWrap}>
                  <Checkbox
                    checked={warningAccepted}
                    color="primary"
                  />
                  <div>
                    I understand that roll over words will no longer be available when switching to the monthly plan
                  </div>
                </div>
              </>
            )}
          </>
        ) : <div className={classes.loadingWrapper}><CircularProgress /></div>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleContinue}
          disabled={!warningAccepted || loading}
        >
          {capitalize(updateData.status)}
        </Button>
      </DialogActions>
    </>
  );
};

export default Step2;
