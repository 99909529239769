import React from 'react';

import api from '@root/api';

const getFileState = ({
  file,
  bulkGeneration,
  currentJob,
}) => {
  if (file.isHidden) {
    if (currentJob?.bulkGeneration?._id === bulkGeneration?._id) {
      return 'inProgress';
    }
    if (bulkGeneration?.state === 'canceled') {
      return 'canceled';
    }
    if (bulkGeneration?.state === 'failed') {
      return 'failed';
    }

    return 'inProgress';
  }

  return 'completed';
};

const useBulkGenerationRows = ({
  bulkGeneration,
  filesSearchQuery,
  filesPerPage,
  page,
  currentJob,
}) => {
  const [data, setData] = React.useState({ rows: [], pagesCount: 0 });

  const load = async () => {
    if (!bulkGeneration) {
      return;
    }
    if (currentJob && currentJob?.bulkGeneration?._id === bulkGeneration._id && currentJob?.bulkGeneration?.state !== 'done') {
      const filteredRows = filesSearchQuery
        ? currentJob.rows
          .filter(
            (r) => r.result?.fileName?.toLowerCase().includes(filesSearchQuery.toLowerCase()),
          )
        : currentJob.rows;
      setData({
        rows: filteredRows.slice((page - 1) * filesPerPage, page * filesPerPage),
        pagesCount: Math.ceil(filteredRows.length / filesPerPage),
      });
      return;
    }

    if (bulkGeneration.legacy) {
      const bulkFilesFilters = {
        sort: 'title',
        sortOrder: 'asc',
        searchQuery: filesSearchQuery,
        page,
        perPage: filesPerPage,
        bulkCopyId: bulkGeneration._id,
        showHidden: true,
      };
      const { results: files, pagesCount } = await api.files.list(bulkFilesFilters);

      const newRows = await Promise.all(files.map(async (file) => {
        const { results: documents } = await api.documents.list({ fileId: file._id });
        return {
          _id: file._id,
          state: getFileState({ file, bulkGeneration, currentJob }),
          result: {
            fileId: file._id,
            fileName: file.title,
            documents,
          },
          legacy: true,
        };
      }));

      setData({
        rows: newRows,
        pagesCount,
      });
      return;
    }

    const { results: bulkRows, pagesCount } = await api.bulkGeneration.getRows({
      id: bulkGeneration._id,
      page,
      perPage: filesPerPage,
      searchQuery: filesSearchQuery,
    });

    setData({
      rows: bulkRows,
      pagesCount,
    });
  };

  React.useEffect(() => {
    load();
  }, [
    bulkGeneration,
    filesSearchQuery,
    filesPerPage,
    page,
  ]);

  React.useEffect(() => {
    if (currentJob?.bulkGeneration?._id === bulkGeneration?._id) {
      load();
    }
  }, [currentJob, bulkGeneration]);

  return { rows: data.rows, pagesCount: data.pagesCount, reload: load };
};

export default useBulkGenerationRows;
