import React from 'react';
import {
  makeStyles,
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';

import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import Checkbox from '@root/components/Checkbox';
import AuthContext from '@root/resources/auth/auth.context';

import BulkFileOptionsKebab from '../../IconButtons/BulkFileOptionsKebab';

import useCommonStyles from './tableComponents.styles';

const useStyles = makeStyles((theme) => ({
  thead: {
    backgroundColor: 'white',
  },
  theadRoot: {
    '&$active': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
}));

const CustomTableHead = ({
  selectedFileIds,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  availableRowsNum,
  folder,
  files,
  cells,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const { fixedColumn } = currentExternalSource.toggles;
  const numSelected = selectedFileIds.length;

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  return (
    <TableHead classes={{ root: classes.thead }}>
      <TableRow>
        <TableCell
          padding="checkbox"
          classes={{
            root: clsx(commonClasses.controls, {
              [commonClasses.newCell]: newDesign,
            }),
            stickyHeader: clsx({
              [commonClasses.fixedCell]: fixedColumn,
              [commonClasses.fixedCellHeader]: fixedColumn,
              [commonClasses.stickyHeader]: !fixedColumn,
            }),
          }}
        >
          <Checkbox
            checked={availableRowsNum > 0 && numSelected === availableRowsNum}
            indeterminate={numSelected > 0 && numSelected < availableRowsNum}
            onClick={onSelectAllClick}
          />

        </TableCell>
        <TableCell
          padding="none"
          align="center"
          classes={{
            root: clsx(commonClasses.controls, {
              [commonClasses.newCell]: newDesign,
            }),
            stickyHeader: clsx({
              [commonClasses.stickyHeader]: !fixedColumn,
              [commonClasses.fixedCellHeader]: fixedColumn,
              [commonClasses.fixedCell]: fixedColumn,
              [commonClasses.secondCell]: fixedColumn,
            }),
          }}
        />
        {cells.map(({
          id, numeric, label, width,
        }, index) => {
          return (
            <TableCell
              key={id}
              align={numeric ? 'center' : 'left'}
              padding="none"
              sortDirection={orderBy === id ? order : false}
              classes={{
                root: clsx(commonClasses.controls, {
                  [commonClasses.newCell]: newDesign,
                }),
                stickyHeader: clsx({
                  [commonClasses.stickyHeader]: !!index || !fixedColumn,
                  [commonClasses.fixedCellHeader]: fixedColumn,
                  [commonClasses.fixedCell]: fixedColumn && !index,
                  [commonClasses.thirdCell]: fixedColumn && !index,
                }),
              }}
              style={{ width }}
            >
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={createSortHandler(id)}
                classes={{
                  active: classes.active,
                  icon: classes.icon,
                  root: classes.theadRoot,
                }}
              >
                {label}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell
          padding="none"
          classes={{
            root: clsx(commonClasses.controls, {
              [commonClasses.newCell]: newDesign,
            }),
            stickyHeader: commonClasses.stickyHeader,
          }}
        >
          <BulkFileOptionsKebab
            folder={folder}
            files={files}
            selectedFileIds={selectedFileIds}
            variant="list"
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
