import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SubjectIcon from '@material-ui/icons/Subject';
import clsx from 'clsx';

import { navToFileMeta } from '@root/views/Dashboard/helpers';
import templatesContext from '@root/resources/templates/templates.context';
import useFileMenuItems from '@root/views/Dashboard/hooks/useFileMenuItems';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';

const useStyles = makeStyles((theme) => ({
  kebabWrapper: {
    display: 'block',
    transform: 'rotate(90deg)',
  },
  filesRowWrap: {
    fontSize: '14px',
    height: '56px',
    padding: '0 16px 0 10px',
    '&:hover': {
      backgroundColor: 'rgba(242,242,242,0.3)',
    },
    cursor: 'pointer',
  },
  fileTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexGrow: 1,
  },
  selectedFile: {
    backgroundColor: theme.palette.colors.mediumLightGrey,
    '&:hover': {
      backgroundColor: theme.palette.colors.mediumLightGrey,
    },
  },
  filesRow: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
    padding: '0 8px 0 20px',
  },
  iconWrap: {
    lineHeight: '40px',
    width: '30px',
    paddingBottom: '5px',
    '& svg': {
      fontSize: '20px',
    },
    marginRight: '5px',
  },
  root: {
    height: '56px',
  },
}));

const FilesListItem = ({
  selectedFileId,
  file,
  onFileClick,
  setRef,
}) => {
  const classes = useStyles();
  const { getTemplate } = templatesContext.useTemplates();
  const history = useHistory();

  const { slug, Icon = SubjectIcon } = getTemplate(file.templateType);
  const isSelected = selectedFileId === (file.id || file._id);
  const fileMenu = useFileMenuItems(file);

  const [visible, setVisible] = React.useState(true);
  const innerRef = React.useRef();
  React.useEffect(() => {
    setRef({
      setVisible,
      element: innerRef.current,
      fileId: file._id,
    });
  }, [innerRef.current]);

  return (
    <div className={classes.root} ref={innerRef}>
      {visible && (
        <div
          key={file.id}
          onClick={onFileClick(navToFileMeta(file, history, slug))}
          className={clsx(classes.filesRowWrap, {
            [classes.selectedFile]: isSelected,
          })}
        >
          <div className={classes.filesRow}>
            <div className={classes.iconWrap}>
              <Icon size={24} />
            </div>
            <div className={classes.fileTitle}>
              {file.title}
            </div>
            <div className={classes.kebabWrapper}>
              <KebabMenu menuItems={fileMenu} variant="list" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilesListItem;
