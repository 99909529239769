export type TemplateType = { key: string, type: 'warning' | 'error', priority: 'high' | 'medium' | 'low' };
const templatesMap: TemplateType[] = [
  { key: 'base-limits-almost-reached-alert', type: 'warning', priority: 'medium' },
  { key: 'base-credits-reached-alert', type: 'error', priority: 'high' },
  { key: 'base-credits-reached-alert-without-overages', type: 'error', priority: 'high' },
  { key: 'overage-credits-reached-80p-alert', type: 'warning', priority: 'medium' },
  { key: 'overage-credits-reached-alert', type: 'error', priority: 'medium' },
  { key: 'overage-invoice-payment-failed', type: 'error', priority: 'high' },
];

export const getTemplateByKey = (key: string): TemplateType | undefined => {
  return templatesMap.find(
    (template) => key.startsWith(template.key),
  );
};
