import React from 'react';
import { DateTime } from 'luxon';

const DaysLeft = ({ trialEndOn }) => {
  const trialEndDate = DateTime.fromSeconds(trialEndOn);
  const daysLeft = Math.round(trialEndDate.diffNow().as('days'));

  return (
    <span>
      {daysLeft <= 0
        ? 'Your free trial has ended'
        : `${daysLeft} day${daysLeft > 1 ? 's' : ''} left in your free trial`}
    </span>
  );
};

export default DaysLeft;
