import React from 'react';
import clsx from 'clsx';

import useStyles from './CreateModal.styles';

const CreateOption = ({
  title,
  description,
  onClick,
  illustrationUrl,
  disabledIllustrationUrl,
  isDisabled,
  disabledByHootsuite,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.createOptionContainer, { disabled: isDisabled })}
      onClick={onClick}
    >
      <div className={classes.illustrationContainer}>
        {!disabledByHootsuite && (
          <img
            src={isDisabled ? disabledIllustrationUrl : illustrationUrl}
            alt="create option illustration"
          />
        )}

        {disabledByHootsuite && (
          <span className={classes.hootsuiteText}>
            Currently unavailable
          </span>
        )}
      </div>

      <h3 className={classes.title}>{title}</h3>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default CreateOption;
