import React from 'react';

const useCheckedMutations = ({
  checkedDocumentsIds, documents, deselectAll, likeDocument, fileId, deleteDocument,
}) => {
  const likeCheckedDocuments = React.useCallback(() => {
    const docsToLike = checkedDocumentsIds
      .map((docId) => documents.find((d) => d.id === docId))
      .filter((d) => !d.liked).map((d) => d.id);
    docsToLike.map((docId) => likeDocument({ _id: docId, fileId }));
    deselectAll();
  }, [checkedDocumentsIds, documents, deselectAll, likeDocument, fileId]);

  const unlikeCheckedDocuments = React.useCallback(() => {
    const docsToUnlike = checkedDocumentsIds
      .map((docId) => documents.find((d) => d.id === docId))
      .filter((d) => d.liked).map((d) => d.id);
    docsToUnlike.map((docId) => likeDocument({ _id: docId, fileId }));
    deselectAll();
  }, [checkedDocumentsIds, documents, deselectAll, likeDocument, fileId]);

  const deleteCheckedDocuments = React.useCallback(() => {
    checkedDocumentsIds.map((docId) => deleteDocument({ _id: docId, fileId, batch: true }));
    deselectAll();
  }, [checkedDocumentsIds, documents, deselectAll, deleteDocument, fileId]);

  return {
    likeCheckedDocuments,
    unlikeCheckedDocuments,
    deleteCheckedDocuments,
  };
};

export default useCheckedMutations;
