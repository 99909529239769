import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    maxWidth: '250px',
    maxHeight: '300px',
    height: '100%',
    overflow: 'hidden',
  },

  list: {
    overflow: 'auto',
    height: '100%',
  },

  root: {
    width: '100%',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#000429',
    cursor: 'pointer',
  },

  rootFolderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',

    padding: '4px 8px',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: theme.palette.colors.neutrals50,
    },
  },

  childFolderContainer: {
    marginLeft: '2.125rem',
  },

  projectTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  activeRootFolderRow: {
    backgroundColor: theme.palette.colors.neutrals50,
  },

  folderIcon: {
    color: theme.palette.colors.neutrals400,
    flexShrink: 0,
  },

  chevronIcon: {
    flexShrink: 0,

    color: theme.palette.colors.neutrals300,
    transform: 'rotate(0deg)',
    transition: 'transform .5s',
    cursor: 'pointer',

    marginLeft: 'auto',

    '&:hover': {
      color: theme.palette.colors.neutrals400,
    },
  },

  expandedChevronDownIcon: {
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
