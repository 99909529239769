/* eslint-disable react/button-has-type */
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Undo from '@material-ui/icons/Undo';
import Redo from '@material-ui/icons/Redo';

import uiNotificationService from '@root/services/uiNotification.service';
import useFile from '@root/resources/file/useFile';
import AuthContext from '@root/resources/auth/auth.context';
import TemplatesContext from '@root/resources/templates/templates.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import useFolder from '@root/resources/folder/useFolder';
import useUpdateFile from '@root/resources/file/useUpdateFile';
import usePersonalizationRules from '@root/resources/ai/usePersonalizationRules';
import usePersonalize from '@root/resources/ai/usePersonalize';
import useRemovePersonalization from '@root/resources/ai/useRemovePersonalization';
import useIntegrations from '@root/resources/integrations/useIntegrations';
import useLimits from '@root/resources/billing/useLimits';
import { useRemoveDocumentExample, useSubmitDocumentExampleToReview } from '@root/resources/documentExample';
import useGlobalKeyDownListener from '@root/utils/hooks/useGlobalKeyDownListener';
import InFileSidebar from '@root/views/File/components/InFileSidebar/InFileSidebar';
import ExampleInput from '@root/views/File/components/ExampleInput';
import useExampleOpen from '@root/views/File/hooks/useExampleOpen';
import RootLayout from '@root/views/Layout/RootLayout';
import EditorNav from '@root/components/EditorNav/LegacyEditorNav';
import FileToolbar from '@root/components/FileToolbar';
import MagicLoading from '@root/components/Loading/MagicLoading';
import Loading from '@root/components/Loading/Loading';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import DocumentView from '@root/components/DocumentView';
import TemplateDeletedTooltipContent from '@root/components/tooltips/TemplateDeletedTooltipContent';
import { campaignAvailableTemplates } from '@root/components/DocumentView/components/PreviewFooter/components/CreateMenuButton/createHelpers';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import AssignToSelect from '@root/views/File/components/SelectComponents/AssignToSelect';
import StatusSelect from '@root/views/File/components/SelectComponents/StatusSelect';
import workflowQueueContext from '@root/resources/file/workflowQueue.context';

import useStyles from './BlockEditor.styles';
import Document from './components/Document';
import Toolbars from './components/Toolbars';
import Settings from './components/Settings';
import { getInitialFileSettings } from './blockEditor.helper';
import useGenerate from './hooks/useGenerate';
import { withDocuments, useBlockEditorDocuments } from './documents.context';
import useMenuItems from './hooks/useMenuItems';

const BACK_URL = '/templates/my-files';

const LANG_SETTINGS_FIELDS = ['language', 'outputLanguage'];
const INPUT_TAGS = ['INPUT', 'TEXTAREA'];

const BlockEditor = () => {
  const classes = useStyles();

  const pageRef = React.useRef();

  const {
    documents,

    isDocumentsLoading,
    loadDocuments,

    isMagicLoading,
    setIsMagicLoading,
    generateContent,

    editableDocumentId,
    setEditableDocumentId,
    dropEditableDocumentId,

    handleDocumentContentChange,
    deleteDocument,
    mergeDocuments,

    forceCaretPosition,
    undo,
    redo,
  } = useBlockEditorDocuments();

  const { currentUser, currentCompany } = AuthContext.useAuth();
  const { templatesList } = TemplatesContext.useTemplates({ withHidden: true });
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { featureTourActive } = onboardingContext.useOnboarding();

  const history = useHistory();
  const { id: fileId, slug: templateSlug } = useParams();

  const template = React.useMemo(() => {
    return templatesList.find((t) => t.slug === templateSlug);
  });

  const isCustomTemplate = template.templateType.includes('custom_');
  const { deletedOn: isTemplateDeleted } = template;

  const { data: file, isLoading: isFileLoading } = useFile(fileId);
  const { data: folder, isLoading: isFolderLoading } = useFolder(file?.folderId);
  const {
    data: personalizationRules,
    isLoading: isPersonalizationRulesLoading,
  } = usePersonalizationRules();
  const { data: integrations } = useIntegrations();
  const { data: limits } = useLimits();

  const { mutateAsync: updateFile } = useUpdateFile();
  const { mutateAsync: personalize } = usePersonalize();
  const { mutateAsync: removePersonalization } = useRemovePersonalization();
  const { mutateAsync: submitToReview } = useSubmitDocumentExampleToReview();
  const { mutateAsync: removeDocumentExample } = useRemoveDocumentExample();

  const [toolbarsRef, setToolbarsRef] = React.useState(null);
  const [fileTitle, setFileTitle] = React.useState(file?.title);
  const [fileData, setFileData] = React.useState({
    ...file?.data,
    negativeKeywordArray: file?.negativeKeywordArray,
  });
  const [fileSettings, setFileSettings] = React.useState(getInitialFileSettings({
    template,
    company: currentCompany,
    file,
  }));
  const [isFileSettingsChanged, setIsFileSettingsChanged] = React.useState(false);

  const [selectedText, setSelectedText] = React.useState(null);
  const [isFileIdChanged, setIsFileIdChanged] = React.useState(false);
  const [isBlockActionInProgress, setIsBlockActionInProgress] = React.useState(false);

  const filePermissions = currentUser.permissions.getFilePermissions(file, folder);
  const {
    canManageDocuments,
    canEditFile,
  } = filePermissions;

  const [showChar, setShowChar] = React.useState(true);

  const {
    assignedToId, setAssignedToId,
    workflowStatus, setWorkflowStatus,
  } = workflowQueueContext.useWorkflowQueue();

  const hasTeamPermissions = currentUser.permissions.teams;

  const onAssignUser = (value) => {
    if (value === assignedToId) {
      return;
    }

    setAssignedToId(value);
    setWorkflowStatus(null);
  };

  const onWorkflowStatusChange = async (value) => {
    setWorkflowStatus(value);

    await updateFile({
      fileId,
      data: {
        assignedToId,
        workflowStatus: value,
      },
    });
    uiNotificationService.showSuccessMessage('Status updated!');
  };

  const documentsIds = React
    .useMemo(() => (documents ? documents.map((d) => d._id) : []), [documents]);

  const {
    zapierIntegration,
    googleDocsIntegration,
  } = React.useMemo(() => {
    const zapier = (integrations || []).find((i) => i.status === 'active' && i.type === 'zapier');
    const googleDocs = (integrations || []).find((i) => i.status === 'active' && i.type === 'googleDocs');

    return {
      zapierIntegration: zapier,
      googleDocsIntegration: googleDocs,
    };
  }, [document, integrations]);

  const allowedDocumentActions = React.useMemo(() => {
    const actions = ['copy', 'delete', 'publish', 'plagiarismCheck', 'readability'];
    if (template.canDocumentRewrite) {
      actions.push('rewrite');
    }
    if (template.canDocumentExpand) {
      actions.push('expand');
    }
    if (template.canPersonalize) {
      actions.push('like');
    }
    if (template.exampleSubmittingAllowed) {
      actions.push('dislike');
    }
    if (zapierIntegration) {
      actions.push('publish');
    }
    if (campaignAvailableTemplates.includes(template.templateType)) {
      actions.push('campaign');
    }
    if (template.onMoreLikeThis) {
      actions.push('moreLikeThis');
    }
    return actions;
  }, [template, zapierIntegration]);

  const fileWithUpdatedData = React.useMemo(() => {
    return {
      ...file,
      data: fileData,
    };
  }, [file, fileData]);

  React.useEffect(() => {
    if (!file) {
      return;
    }

    if (!fileTitle) {
      setFileTitle(file.title);
    }
    if (!fileData) {
      setFileData({
        ...file.data,
        negativeKeywordArray: file.negativeKeywordArray,
      });
    }
    if (!fileTitle && !fileData) { // fist load
      setFileSettings(getInitialFileSettings({
        template,
        company: currentCompany,
        file,
      }));
    }
    if (!assignedToId) {
      setAssignedToId(file.assignedTo?.userId);
    }
    if (!workflowStatus) {
      setWorkflowStatus(file.workflowStatus);
    }
  }, [file]);

  React.useEffect(() => {
    if (!file) {
      return;
    }

    if (isFileIdChanged) {
      setFileTitle(file.title);
      setFileData({
        ...file.data,
        negativeKeywordArray: file.negativeKeywordArray,
      });
      setFileSettings(getInitialFileSettings({
        template,
        company: currentCompany,
        file,
      }));
      setAssignedToId(file.assignedTo?.userId);
      setWorkflowStatus(file.workflowStatus);
      loadDocuments({
        fileId: file._id,
        showLoad: false,
      }).then(() => {
        if (!pageRef.current) {
          return;
        }
        pageRef.current.scrollTop = 0;
      });
      setIsFileIdChanged(false);
      return;
    }

    if (!fileTitle) {
      setFileTitle(file.title);
    }
    if (!fileData) {
      setFileData({
        ...file.data,
        negativeKeywordArray: file.negativeKeywordArray,
      });
    }
    if (!fileTitle && !fileData) { // fist load
      setFileSettings(getInitialFileSettings({
        template,
        company: currentCompany,
        file,
      }));
    }
    if (!assignedToId) {
      setAssignedToId(file.assignedTo?.userId);
    }
    if (!workflowStatus) {
      setWorkflowStatus(file.workflowStatus);
    }
  }, [file, isFileIdChanged]);

  React.useEffect(() => {
    if (!file) {
      return;
    }

    setIsFileIdChanged(true);
  }, [fileId]);

  const {
    exampleOpen,
    toggleExampleOpen,
  } = useExampleOpen();

  const menuItems = useMenuItems({
    fileId,
    fileTitle,
    documents,
    template,
    history,
    toggleExampleOpen,
    setShowChar,
    isCustomTemplate,
    classes,
  });
  const { fileFields } = template.newExample || {};
  const sampleDoc = {
    _id: template.templateType,
    templateType: template.templateType,
    content: template.newExample?.content,
  };
  const sampleFileData = fileFields || {
    companyName: '',
  };

  const { hideInfileSidebar = false } = currentExternalSource.toggles;

  const lastModified = React.useMemo(() => {
    const updatedOnValues = [...(documents || []).map((d) => d.updatedOn), file?.updatedOn];
    return Math.max(...updatedOnValues);
  }, [file, documents]);

  const onSave = (options = {}) => () => {
    const { backUrl } = options;

    if (backUrl) {
      history.push(backUrl);
    }
  };

  const isLoading = isFileLoading
    || isFolderLoading
    || !folder
    || isDocumentsLoading
    || !fileData
    || isPersonalizationRulesLoading;

  const generate = useGenerate({
    template,
    file,
    fileData,
    fileSettings,
    isFileSettingsChanged,
    setIsMagicLoading,
    updateFile,
    generateContent,
    pageRef,
  });

  // macOS undo listener
  useGlobalKeyDownListener((e) => {
    if (INPUT_TAGS.includes(document.activeElement.tagName)) {
      return;
    }

    e.preventDefault();
    undo();
  }, { meta: true, keys: ['z'] });

  // macOS redo listener
  useGlobalKeyDownListener((e) => {
    if (INPUT_TAGS.includes(document.activeElement.tagName)) {
      return;
    }

    e.preventDefault();
    redo();
  }, { meta: true, shift: true, keys: ['z'] });

  // windows undo listener
  useGlobalKeyDownListener((e) => {
    if (INPUT_TAGS.includes(document.activeElement.tagName)) {
      return;
    }

    e.preventDefault();
    undo();
  }, { ctrl: true, keys: ['z'] });

  // windows redo listener
  useGlobalKeyDownListener((e) => {
    if (INPUT_TAGS.includes(document.activeElement.tagName)) {
      return;
    }

    e.preventDefault();
    redo();
  }, { ctrl: true, keys: ['y'] });

  // windows optional redo listener
  useGlobalKeyDownListener((e) => {
    if (INPUT_TAGS.includes(document.activeElement.tagName)) {
      return;
    }

    e.preventDefault();
    redo();
  }, { ctrl: true, shift: true, keys: ['z'] });

  const onBlockActionStart = () => {
    setIsBlockActionInProgress(true);
  };
  const onBlockActionEnd = () => {
    setIsBlockActionInProgress(false);
  };

  if (isLoading) {
    return (
      <Loading />
    );
  }

  const onFileTitleChange = (e) => {
    setFileTitle(e.target.value);
  };
  const updateFileTitle = async () => {
    await updateFile({
      fileId,
      data: {
        title: fileTitle,
      },
    });
  };

  const onDataChange = ({ name, value }) => {
    const newFileData = {
      ...fileData,
      [name]: value,
    };
    setFileData(newFileData);
    return newFileData;
  };
  const onSettingsChange = ({ name, value }) => {
    const newFileSettings = LANG_SETTINGS_FIELDS.includes(name) ? {
      ...fileSettings,
      [name]: value,
    } : {
      ...fileSettings,
      aiOptions: {
        ...fileSettings.aiOptions,
        [name]: value,
      },
    };
    setFileSettings(newFileSettings);
    setIsFileSettingsChanged(true);
    return newFileSettings;
  };

  const onDocumentTextSelect = (text) => {
    setSelectedText(text);
  };
  const dropSelectedText = () => {
    setSelectedText(null);
  };

  return (
    <RootLayout>
      {isMagicLoading && (
        <MagicLoading />
      )}
      <ExampleInput
        title={template.title}
        description={template.description}
        allFields={template.fields}
        drawerState={exampleOpen}
        onClose={toggleExampleOpen}
      >
        <DocumentView
          key={template.templateType}
          document={sampleDoc}
          fileData={sampleFileData}
          options={{
            canEdit: false,
            canDelete: false,
            showVote: false,
            canLike: false,
            canCopy: false,
            canFlag: false,
          }}
        />
      </ExampleInput>
      <div className={classes.root}>
        <EditorNav
          backUrl={BACK_URL}
          file={file}
          fileTitle={fileTitle}
          folder={folder}
          includeBlogCredits={false}
          includeCreditsRight
          isBeta={false}
          onTitleChange={onFileTitleChange}
          parentFolder={null}
          saveAndExit={onSave}
          updateTitle={updateFileTitle}
          fileTemplateType={template.templateType}
          lastModified={lastModified}
        />
        <FileToolbar
          file={file}
          filePermissions={filePermissions}
          lastHighlightedText={selectedText?.value}
          leftComponent={(
            <div className={classes.leftToolbarComponent}>
              <button onClick={undo}>
                <Undo />
              </button>
              <button onClick={redo}>
                <Redo />
              </button>
            </div>
          )}
          toolbarComponent={(
            <Toolbars
              toolbarsRef={setToolbarsRef}
              activeDocumentId={editableDocumentId}
              documentsIds={documentsIds}
            />
          )}
        >
          <div className={classes.workflowQueueWrapper}>
            {currentUser.permissions.workflowQueue && hasTeamPermissions && (
              <>
                <AssignToSelect onChange={onAssignUser} />
                <StatusSelect onChange={onWorkflowStatusChange} />
              </>
            )}
          </div>
          <KebabMenu
            classNames={{
              kebab: classes.menu,
            }}
            menuItems={menuItems}
            useIcon
          />
        </FileToolbar>
        <div className={classes.content}>
          {!hideInfileSidebar && !featureTourActive && (
            <div className={classes.inFileSidebarWrap}>
              <InFileSidebar
                showCollapsedContent={false}
                file={file}
                folder={folder}
                collapsedClassName={classes.collapsedSidebar}
              />
            </div>
          )}
          <div className={classes.pageWrap}>
            <Settings
              template={template}
              company={currentCompany}
              file={file}
              fileData={fileData}
              fileSettings={fileSettings}
              canManageDocuments={canManageDocuments}
              canEditFile={canEditFile}
              documentsExist={documents?.length > 0}
              onDataChange={onDataChange}
              onSettingsChange={onSettingsChange}
              updateFile={updateFile}
              generate={generate}
              generateDisabled={isTemplateDeleted}
              customDisableTooltipContent={isTemplateDeleted
                && <TemplateDeletedTooltipContent templateName={template.title} />}
            />
            <div ref={pageRef} className={classes.page}>
              {documents.map((d, index) => {
                return (
                  <Document
                    htmlId={(index === 0 && featureTourActive) ? 'feature-tour-step-4' : undefined}
                    key={d._id}
                    document={d}
                    mergeDocuments={mergeDocuments}
                    toolbarRef={toolbarsRef?.[d._id]}
                    editable={editableDocumentId === d._id}
                    setEditableDocumentId={setEditableDocumentId}
                    dropEditableDocumentId={dropEditableDocumentId}
                    handleDocumentContentChange={handleDocumentContentChange}
                    forceCaretPosition={forceCaretPosition}
                    onTextSelect={onDocumentTextSelect}
                    readOnly={!canManageDocuments}
                    selectedText={selectedText}
                    isActionInProgress={isBlockActionInProgress}
                    personalizationRules={personalizationRules}
                    personalize={personalize}
                    removePersonalization={removePersonalization}
                    submitToReview={submitToReview}
                    removeDocumentExample={removeDocumentExample}
                    onMoreLikeThis={template.onMoreLikeThis && (() => template.onMoreLikeThis({
                      generate,
                      canManageDocuments,
                    }, d))}
                    deleteDocument={deleteDocument}
                    file={fileWithUpdatedData}
                    zapierIntegration={zapierIntegration}
                    googleDocsIntegration={googleDocsIntegration}
                    allowedActions={allowedDocumentActions}
                    onBlockActionStart={onBlockActionStart}
                    onBlockActionEnd={onBlockActionEnd}
                    dropSelectedText={dropSelectedText}
                    showChar={showChar}
                    limits={limits}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default withDocuments(BlockEditor);
