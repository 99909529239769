import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Link as UiLink,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { insertInArrayIf } from '@copysmith/utils/dist/object.util';

import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import { navToFileMeta } from '@root/views/Dashboard/helpers';
import templatesContext from '@root/resources/templates/templates.context';
import Checkbox from '@root/components/Checkbox';
import useModalState from '@root/utils/hooks/useModalState';
import { InputField } from '@root/components/form';
import useUpdateRowResults from '@root/resources/bulkGenerate/useUpdateRowResults';
import { escapeText } from '@root/utils/text.utils';

import StatusBadge from '../StatusBadge';

import useStyles from './BulkGenerationRow.styles';

const shortenString = (string) => {
  if (string.length <= 100) {
    return string;
  }

  const shortenedString = string.substring(0, 100);

  const words = shortenedString.split(' ');
  const lastWord = words[words.length - 1];
  const lastWordLength = lastWord.length;
  if (lastWordLength.length > 20) {
    return `${shortenString}...`;
  }

  return `${shortenedString.substring(0, 100 - lastWordLength - 1)}...`;
};

const BulkGenerationRow = ({
  isSelected: isSelectedProp,
  toggleSelect,
  handleDownload,
  row,
  docsNumberToShow = 1,
  templateType,
  onResultUpdated,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { pathname = '' } = history.location;

  const { getTemplate } = templatesContext.useTemplates();

  const { mutateAsync: updateRowResults } = useUpdateRowResults();

  const { slug, stringifyContent } = getTemplate(templateType);

  const [isEditModalOPen, openEditModal, closeEditModal] = useModalState(false);
  const [editState, setEditState] = React.useState(null);

  const documentsToShow = (row.result?.documents || []).slice(0, docsNumberToShow);

  const getMenu = () => {
    if (row.state !== 'done') {
      return [];
    }

    return [{
      title: 'Export to CSV',
      key: 'exportToCSV',
      onClick: async ({ handleClose }) => {
        handleClose();
        handleDownload([row.result.fileId]);
      },
    },
    ...insertInArrayIf(
      row.result?.documents?.length > 0
      && row.result.documents.every((doc) => doc._id !== row._id)
      && row.result.documents.some((doc) => typeof doc.content === 'string'),
      {
        title: 'Edit',
        key: 'edit',
        onClick: async ({ handleClose }) => {
          handleClose();
          setEditState({
            documents: documentsToShow.filter((doc) => !!doc.content),
            rowId: row._id,
            bulkGenerationId: row.bulkGenerationId,
            handleClose,
          });
          openEditModal();
        },
      },
    ),
    ];
  };

  const menu = React.useMemo(() => getMenu(), [row.state, row.result?.documents?.length, documentsToShow]);

  const onDocumentContentChange = (id) => (event) => {
    const { value } = event.target;
    setEditState({
      ...editState,
      documents: editState.documents.map((d) => {
        if (d._id === id) {
          return { ...d, content: value };
        }
        return d;
      }),
    });
  };

  const onEditSave = async () => {
    await updateRowResults({
      id: editState.bulkGenerationId,
      rowId: editState.rowId,
      contents: editState.documents.map((doc) => ({ documentId: doc._id, content: doc.content })),
    });

    closeEditModal();
    editState.handleClose();
    onResultUpdated();
  };

  const isSelected = isSelectedProp && row.state === 'done';

  return (
    <>
      {isEditModalOPen && (
        <Dialog open onClose={closeEditModal}>
          <DialogTitle>
            Edit content
          </DialogTitle>
          <DialogContent>
            {editState.documents.map((doc, index) => {
              const maxLength = row.result?.documents?.find((d) => d._id === doc._id)?.content?.length * 2;
              return (
                <div key={doc._id}>
                  <div>
                    {`Generation ${index + 1}`}
                    <InputField
                      fullWidth
                      multiline
                      rows={5}
                      value={escapeText(doc.content)}
                      onChange={onDocumentContentChange(doc._id)}
                      inputProps={{
                        maxLength: maxLength < 10000 ? maxLength : 10000,
                      }}
                      autoFocus
                    />
                  </div>
                </div>
              );
            })}
            <DialogActions>
              <Button variant="text" onClick={closeEditModal}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={onEditSave}>
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      <TableRow key={row._id} className={clsx({ [classes.rowSelected]: isSelected })}>
        <TableCell padding="checkbox" className={classes.nowrap}>
          <Checkbox
            checked={isSelected}
            onClick={() => toggleSelect(row)}
            disabled={row.state !== 'done'}
          />
        </TableCell>

        <TableCell className={classes.nowrap}>
          {row.result?.fileName ? (
            <UiLink onClick={navToFileMeta({
              _id: row.result.fileId,
              title: row.result.fileName,
            }, history, slug, {
              prevPathName: pathname,
            })}
            >
              {row.result.fileName}
            </UiLink>
          ) : '-'}
        </TableCell>
        {
          new Array(docsNumberToShow)
            .fill('-')
            .map((placeholder, index) => {
              const document = documentsToShow[index];
              return (
                <TableCell
                  key={index} // eslint-disable-line react/no-array-index-key
                  className={classes.generationCell}
                >
                  {
                    document
                      ? shortenString(stringifyContent(document.content))
                      : placeholder
                  }
                </TableCell>
              );
            })
        }
        <TableCell padding="none">
          <StatusBadge
            state={row.state}
          />
        </TableCell>
        <TableCell>
          <KebabMenu
            isDisabled={!menu.length}
            menuItems={menu}
            variant="list"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default BulkGenerationRow;
