import React, { useState } from 'react';
import pluralize from 'pluralize';
import { IconCirclePlus, IconInfinity, IconUserPlus } from '@tabler/icons-react';

import { FEATURES } from '@root/views/Addons/addonsConstants';
import Button from '@root/components/buttons/Button';
import useModalState from '@root/utils/hooks/useModalState';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import api from '@root/api';
import billingResource from '@root/resources/billing';
import userResource from '@root/resources/user';
import uiNotificationService from '@root/services/uiNotification.service';
import FeatureModal from '@root/components/modals/FeatureModal';

import useStyles from './AddonsSection.styles';

const ICONS = {
  additionalMembers: <IconUserPlus />,
  additionalCredits: <IconCirclePlus />,
  unlimitedCredits: <IconInfinity />,
  aiImages: <IconCirclePlus />,
};

const AddonItem = ({ type, quantity }) => {
  const classes = useStyles();
  const { data: limits } = billingResource.useLimits();
  const { invalidateLimits } = billingResource.useInvalidateLimits();
  const { refetch: refetchBilling } = billingResource.useBillingInfo();
  const { data: { count: currentMembersCount } = {} } = userResource.useTeamMembers();

  const [isLoading, setIsLoading] = useState(false);

  const [isModifyOpen, openModify, closeModify] = useModalState(false);
  const [isCancelOpen, openCancel, closeCancel] = useModalState(false);

  const { label, cost, unit } = FEATURES[type];

  const billed = `$${(cost * quantity).toFixed(2)} / month`;
  const addonLabel = unit ? `${label} (${quantity} ${pluralize(unit, quantity)})` : label;
  const isUnlimitedCredits = type === FEATURES.unlimitedCredits.type;

  const hasPaidMembers = (currentMembersCount - limits?.members) > 0;
  const isCancelDisabled = type === FEATURES.additionalMembers.type && hasPaidMembers;

  const handleCancel = async () => {
    setIsLoading(true);
    const response = await api.billing.cancelAddon({
      addon: type,
    });

    const { isBadRequest } = response;

    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Canceled');
      closeCancel();
      refetchBilling();
      invalidateLimits();
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={classes.addonItem}>
        <span className={classes.addonLabel}>
          {ICONS[type]}
          {addonLabel}
        </span>

        <span className={classes.billed}>
          {billed}
        </span>

        <div className={classes.buttonsContainer}>
          {!isUnlimitedCredits && (
            <Button
              variant="outlined"
              size="small"
              onClick={openModify}
            >
              Modify
            </Button>
          )}

          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={openCancel}
            disabled={isCancelDisabled}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </div>

        <div className={classes.divider} />
      </div>

      {isModifyOpen && (
        <FeatureModal
          type={type}
          currentQuantity={quantity}
          onClose={closeModify}
        />
      )}

      {isCancelOpen && (
        <ConfirmationModal
          onClose={closeCancel}
          onConfirm={handleCancel}
          title="Cancel add-on subscription"
          confirmButtonText="Cancel subscription"
          cancelButtonText="Back"
          negative
          isLoading={isLoading}
        >
          Are you sure you want to cancel
          &nbsp;
          <b>{label}</b>
          &nbsp;
          add-on subscription?
          {!isUnlimitedCredits && ' You still can downgrade to cut the costs.'}
        </ConfirmationModal>
      )}
    </>
  );
};

export default AddonItem;
