import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import billingResource from '@root/resources/billing';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import FreeTrialEndModal from '@root/components/FreeTrialEndModal/FreeTrialEndModal';
import AddCreditsButton from '@root/components/buttons/AddCreditsButton';
import Button from '@root/components/buttons/Button';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';

import useStyles from './GenerateButtonWithCap.styles';

const GenerateButtonWithCap = ({
  resetLimitArray = [],
  documentsExist,
  generateHandler,
  creditDecrementAmount = 1,
  blogCreditDecrementAmount = 1,
  isBlogGenerator = false,
  canManageDocuments = true,
  disabled = false,
  customDisableTooltipContent = null,
  showInfo = true,
  center = true,
}) => {
  const classes = useStyles();
  const { currentUser: user, currentCompany } = AuthContext.useAuth();
  const [blogRunsModalOpen, setBlogRunModalOpen] = useState(false);
  const { data: limitsData } = billingResource.useLimits();
  const { invalidateLimits } = billingResource.useInvalidateLimits();
  const creditsLeft = limitsData && limitsData.limit;

  const nowSeconds = (new Date().getTime()) / 1000;
  const suspended = nowSeconds > currentCompany.suspendStart
    && nowSeconds < currentCompany.suspendEnd;

  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const handleClick = async (...args) => {
    await generateHandler(...args);
    refetchOnboarding('generateContent');
  };

  useEffect(() => {
    invalidateLimits();
  }, [...resetLimitArray]);

  const isReachedCreditsLimit = () => {
    if (user.onEnterprisePlan) {
      return false;
    }
    if (user.onFreePlan && creditsLeft < creditDecrementAmount) {
      return true;
    }
    if (!isBlogGenerator && (creditsLeft >= creditDecrementAmount || creditsLeft === null)) {
      return false;
    }
    return true;
  };

  const notEnoughPermissions = !canManageDocuments || suspended;
  const buttonDisabled = () => {
    if (notEnoughPermissions || disabled || user.noActiveSubscription) {
      return true;
    }
    return isReachedCreditsLimit();
  };

  const showAddCreditsButton = isReachedCreditsLimit()
  && !isBlogGenerator
  && !user.onFreePlan
  && !user.onSelfServeEnterprisePlan;

  return (
    <>
      <div className={clsx(classes.root, { [classes.rootCenter]: center })}>
        <div className={clsx(classes.buttons, { [classes.buttonsCenter]: center })}>
          <DisabledGenerateTooltip
            enabled={buttonDisabled()}
            notEnoughCredits={isReachedCreditsLimit()}
            notEnoughPermissions={notEnoughPermissions}
            customDisableTooltipContent={disabled && customDisableTooltipContent}
            noActiveSubscription={user.noActiveSubscription}
          >
            <Button
              className={classes.generateButton}
              onClick={handleClick}
              disabled={buttonDisabled()}
            >
              {!documentsExist ? 'Generate' : 'Generate More'}
            </Button>
          </DisabledGenerateTooltip>
          { showAddCreditsButton && <AddCreditsButton />}
        </div>

        {showInfo && (
          <>
            {isBlogGenerator && !user.onEnterprisePlan && (
              <div>
                This generation will use
                {' '}
                <b>
                  {blogCreditDecrementAmount}
                  {' '}
                  {pluralize('blog credit', blogCreditDecrementAmount)}
                </b>
                .
              </div>
            )}

            {!isBlogGenerator && (
              <div>
                All content is saved by default, so if the first set isn&apos;t
                perfect, try again! Previous generations can be found above the
                new ones.
              </div>
            )}

            {isBlogGenerator && (
              <div>
                These blog posts are not GPT-3 generated — we are retraining on a
                larger dataset and working on quality improvements! The generator will
                take about 1.5 minutes to return one or two blog posts (1200+ words
                each).
              </div>
            )}
          </>
        )}
      </div>

      <FreeTrialEndModal
        modalTitle="Looks like you've used all your blog runs!"
        isOpen={blogRunsModalOpen}
        setIsOpen={setBlogRunModalOpen}
      />
    </>
  );
};

export default GenerateButtonWithCap;
