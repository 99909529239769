import React, { useState } from 'react';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import AuthContext from '@root/resources/auth/auth.context';
import userService from '@root/resources/user/user.service';
import ProfileIcon from '@root/components/ProfileIcon';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { FormField, InputField, SelectField } from '@root/components/form';
import uiNotificationService from '@root/services/uiNotification.service';
import Button from '@root/components/buttons/Button';
import userResource from '@root/resources/user';
import { validations } from '@root/utils/validation/formValidations';

import useStyles from '../TeamManagement.styles';

const disabledDeactivateTooltip = 'During trials you can add up to 4 members. Once you subscribe you\'ll be able to add more members.';

const editTeamMemberSchema = Yup.object({
  name: validations.name,
  surname: validations.surname,
  email: validations.email,
});

const EditorRow = ({ member, setConfirmModalState, reloadTeamMembers, isDeactivateDisabled }) => {
  const { currentUser, currentCompany } = AuthContext.useAuth();
  const [editing, setIsEditing] = useState(false);
  const classes = useStyles();

  const memberRoles = userService.getRoles(member);
  const highestRole = userService.getHighestRole(memberRoles);
  const { mutateAsync: updateUser } = userResource.useUpdateUser(member._id);

  const openModalRemoveSelf = () => {
    setConfirmModalState({
      isOpen: true,
      title: `Remove yourself from team ${currentCompany?.name}?`,
      body: 'You will not be able to rejoin unless an admin re-invites you and lose access to all shared projects. Do you want to continue?',
      removeUserId: currentUser._id,
    });
  };

  const openModalRemoveMember = (removedMember) => {
    const { _id: memberId, displayName: memberDisplayName } = removedMember;
    setConfirmModalState({
      isOpen: true,
      title: `Deactivate ${memberDisplayName} from team ${currentCompany?.name}?`,
      body: `You are about to deactivate ${memberDisplayName} from your team.`,
      removeUserId: memberId,
    });
  };

  const editUserDetailsHandler = async (data) => {
    const requestData = {
      firstName: data.name,
      surname: data.surname,
    };
    const emailChanged = data.email !== member.email;
    if (emailChanged) {
      requestData.email = data.email;
    }
    if (data.role) {
      requestData.workspaces = [{ workspaceId: currentUser.activeWorkspaceId, roles: [data.role] }];
    }
    try {
      const updateRequest = await updateUser(requestData);
      if (!updateRequest.isBadRequest) {
        uiNotificationService.showSuccessMessage('User has been updated');
      }
    } catch (e) {
      uiNotificationService.showErrorMessage(e.message);
    } finally {
      reloadTeamMembers();
      setIsEditing(false);
    }
  };

  const isMe = currentUser._id === member._id;

  if (editing && member._id) {
    return (
      <Formik
        initialValues={{ name: member.firstName,
          email: member.email,
          surname: member.surname,
          role: highestRole }}
        onSubmit={editUserDetailsHandler}
        validationSchema={editTeamMemberSchema}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <tr className={classes.tableTr} key={member._id}>
              <td colSpan={2} className={clsx(classes.cell, classes.memberEditedRow)}>
                <Form
                  id={`form-${member._id}`}
                  className={classes.editRowWrap}
                >
                  <ProfileIcon
                    className={classes.teamsProfileIcon}
                    name={member.displayName || member.email}
                  />
                  <div className={classes.inputWrap}>
                    <FormField
                      label="First Name"
                      name="name"
                      placeholder="First Name"
                      component={InputField}
                      required
                    />
                    <div className={classes.smallLabel}>First Name</div>
                  </div>
                  <div className={classes.inputWrap}>
                    <FormField
                      label="Last Name"
                      name="surname"
                      placeholder="Last Name"
                      component={InputField}
                      required
                    />
                    <div className={classes.smallLabel}>Last Name</div>
                  </div>
                  <div className={classes.inputWrap}>
                    <FormField
                      label="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      component={InputField}
                      required
                    />
                    <div className={classes.smallLabel}>Email</div>
                  </div>
                  { !memberRoles.includes('superAdmin') && (
                    <div className={classes.inputWrap}>
                      <FormField
                        label="Role"
                        name="role"
                        placeholder="Role"
                        component={SelectField}
                        options={[
                          { label: 'Admin', value: 'admin' },
                          { label: 'Member', value: 'member' },
                        ]}
                        required
                      />
                      <div className={classes.smallLabel}>Role</div>
                    </div>
                  ) }
                </Form>
              </td>
              <td className={clsx(classes.cell, classes.memberActions)}>
                <Button
                  type="submit"
                  form={`form-${member._id}`}
                  disabled={!isValid || isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  onClick={() => setIsEditing(false)}
                >
                  <span>Cancel</span>
                </Button>
              </td>
            </tr>
          );
        }}
      </Formik>
    );
  }

  return (
    <tr className={classes.tableTr} key={member._id}>
      <td className={clsx(classes.cell, classes.memberName)}>
        <div className={classes.nameContainer}>
          <ProfileIcon
            className={classes.teamsProfileIcon}
            name={member.displayName || member.email}
          />
          <div className={classes.memberInfo}>
            <div>
              {member.displayName}
              {isMe ? ' (You)' : ''}
            </div>
            <div className={classes.memberEmail}>{member.email}</div>
          </div>
        </div>
      </td>
      <td className={clsx(classes.cell, classes.memberAccessLevel)}>
        {highestRole}
      </td>
      <td className={clsx(classes.cell, classes.memberActions)}>
        {(() => {
          // only admins can remove themselves
          if (currentUser.roles.admin && isMe) {
            return (
              <Button
                variant="text"
                color="secondary"
                onClick={openModalRemoveSelf}
              >
                Leave
              </Button>
            );
          }

          if (
            !isMe
            && !memberRoles.includes('owner')
            && (currentUser.roles.owner || currentUser.roles.admin)
          ) {
            return (
              <>
                <SimpleTooltip
                  content={isDeactivateDisabled && disabledDeactivateTooltip}
                  wrapped
                >
                  <Button
                    variant="text"
                    disabled={isDeactivateDisabled}
                    onClick={() => openModalRemoveMember(member)}
                  >
                    Deactivate
                  </Button>
                </SimpleTooltip>
                <Button
                  variant="text"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </>
            );
          }
          return <div>-</div>;
        })()}
      </td>
    </tr>
  );
};

export default EditorRow;
