import config from '@root/config';

// Features can be specified in 3 places:
// 1. features in config file on the web
// 2. __featureToggles collection on server
// 3. features array in the user object on server
const configFeatures = config.features || [];
let features = configFeatures;

export const featureToggles = {
  zapier: 'zapier',
  hootsuite: 'hootsuite',
  restClient: 'rest-client',
  workflowQueue: 'workflow-queue',
  googleDocs: 'google-docs',
  newShopifyIntegration: 'new-shopify-integration',
  wordpressEcommercePlugin: 'wordpressEcommercePlugin',
  personalizedGenerations: 'personalized-generations',
  personalizedGenerationsList: 'personalized-generations-list',
  freeFormV2: 'freeFormV2',
  travelFineTune: 'travel-fine-tune',
  davinciPlusEngine: 'davinci-plus-engine',
  MSWordAddIn: 'ms-word-add-in',
  newPlans: 'new-plans',
  ffseFraseIntegration: 'ffse-frase-integration',
  newFileSharing: 'new-file-sharing',
  feRefSearch: 'fe-ref-search',
  profileSidebarRework: 'profile-sidebar-rework',
  createFromAli: 'create-from-ali',
  iaDashboard: 'ia-dashboard',
  IANewWorkflowBadge: 'ia-new-workflow-badge',
  dashboardPagination: 'dashboard-pagination',
  landingPageInstruct: 'landing-page-instruct',
  blogKickstarter: 'blog-kickstarter',
  dashboardAIRedesign2: 'dashboard-ai-redesign-2',
  workflowToTeamMembers: 'workflow-to-team-members',
  newProfileTeamPage: 'new-profile-team-page',
  customPromptSettings: 'custom-prompt-settings',
  newTranslationLogic: 'new-translation-logic',
  templateBuilder: 'template-builder',
  fileRefactoring: 'file-refactoring',
  googleDocsPublishing: 'google-docs-publishing',
  googleDocsButton: 'google-docs-button',
  zapierEmbeded: 'zapier-embeded',
  rewardsPage: 'rewards-page',
  templatesLibrary: 'templates-library',
  apiDocs: 'api-docs',
  oct2022AnnualPriceUpdates: 'oct-2022-annual-price-updates',
  newEditor: 'new-ff-editor',
  editorLoginRedirect: 'editor-login-redirect',
  usageDashboard: 'usage-dashboard',
  newCreateButton: 'new-create-button',
  selfServeEnterprise: 'self-serve-enterprise',
  newFilesPage: 'new-files-page',
  newNavBar: 'new-nav-bar',
  createAndExplore: 'create-and-explore',
  editorImageTool: 'editor-image-tool',
  aiImages: 'ai-images',
  cancellationBonus: 'cancellation-bonus',
  dashboardV2: 'dashboard-v2',
  rewardWords: 'reward-words',
  nonEnterpriseOverage: 'non-enterprise-overage',
  march2023Pricing: 'march-2023-pricing',
  newLeftSidebar: 'new-left-sidebar',
  createPageRedesign: 'create-page-redesign',
  catalogPCM: 'catalog-pcm',
  bigCommerceCard: 'bigCommerce-card',
  akeneoCard: 'akeneo-card',
  homePage: 'home-page',
  new2023Navigation: 'new-2023-navigation',
  useCaseModalInstantly: 'use-case-modal-instantly',
};

export function featureEnabled(name, user) {
  return features.includes(name)
    //|| growthflagsService.isOn(name)
    || (user && user.features.includes(name));
}

export function setFeatures(newFeatures) {
  if (!newFeatures) {
    return;
  }

  features = [
    ...configFeatures,
    ...newFeatures,
  ];

  // eslint-disable-next-line no-console
  console.log(`Loaded feature toggles: ${features.join(', ')}.`);
}
