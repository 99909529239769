export const featureSteps = [
  {
    target: '#feature-tour-step-1',
    title: '1. Select a template',
    content: 'Click the box to learn more about the template or click create to get goin!',
    disableBeacon: true,
    placement: 'right-start',
    styles: {
      spotlight: {
        borderRadius: 10,
        marginTop: 1,
      },
    },
  },
  {
    target: '#feature-tour-step-2',
    title: '2. Fill out basic information',
    content: 'Give your creation a title and choose where it will be stored!',
    disableBeacon: true,
    placement: 'right-start',
    styles: {
      spotlight: {
        borderRadius: 10,
      },
    },
  },
  {
    target: '#feature-tour-step-3',
    title: '3. Help us understand what you are creating',
    content: 'Give some inputs that will help guide the creation of your content! Then click generate!',
    disableBeacon: true,
    placement: 'right-start',
    styles: {
      spotlight: {
        borderRadius: 0,
      },
    },
  },
  {
    target: '#feature-tour-step-4',
    title: '4. Hover to review and edit generated content',
    content: 'Live edit in the text editor, or hover over a block to access additional features like rewriting, enhancing, or saving content for later!',
    disableBeacon: true,
    placement: 'left-start',
    showNextButton: true,
    styles: {
      spotlight: {
        borderRadius: 0,
      },
    },
  },
  {
    target: '#feature-tour-step-5',
    title: '5. Specify Additional Settings',
    content: 'Want your content to be extra creative? How about specified for the tech industry? Check out these additional settings.',
    disableBeacon: true,
    placement: 'right-start',
    spotlightPadding: 2,
    showNextButton: true,
    styles: {
      spotlight: {
        borderRadius: 4,
      },
    },
  },
];

export const reminderStep = {
  target: '#onb-reminder-step',
  content: 'Find our onboarding tour and so much more in our resources section!',
  disableBeacon: true,
  placement: 'bottom-end',
  disableOverlay: true,
  showSkipButton: false,
  showProgress: false,
  showNextButton: true,
  highlightDropdown: true,
  locale: {
    last: 'Got it!',
  },
};

const defaultWalkthroughProps = {
  disableBeacon: true,
  showNextButton: true,
  placement: 'right-start',
};

export const getWalkthroughSteps = (user) => [
  {
    target: '#link_Files',
    title: 'Files',
    content: 'Organize your copy using Project, Folder, and File system. Share it with your team.',
    ...defaultWalkthroughProps,
  },
  {
    target: '#link_BulkCopy',
    title: 'Bulk Copy',
    content: 'Save time with Bulk Copy and create multiple generations at a time.',
    ...defaultWalkthroughProps,
  },
  {
    target: '#link_Teams',
    title: 'Team',
    content: user?.roles?.member
      ? `Use team features to make collaboration simple.
Use the Workflow Queue to assign files to your teammates and apply a project status.`
      : `Use team features to make collaboration simple:
- invite new team members
- assign files to your teammates and keep track of work status with Workflow queue
- set up your team profile`,
    ...defaultWalkthroughProps,
  },
  {
    target: '#link_Addons',
    title: 'Add-ons',
    content: 'Connect more apps by Copysmith, learn about Integrations. If you are Admin user, invite more team members, add more words to enhance your Copysmith experience.',
    ...defaultWalkthroughProps,
  },
  {
    target: '#walkthrough-profile',
    title: 'Account',
    content: 'Change your personal information in your profile. Explore Copysmith resources and manage your subscription. ',
    ...defaultWalkthroughProps,
    placement: 'bottom-end',
  },
];
