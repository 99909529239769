import React from 'react';

import { useCreateAndExploreContext } from '@root/views/CreateAndExplore/context/CreateAndExplore.context';

import EmptySearchView from './EmptySearchView';
import EmptyView from './EmptyView';
import FilterBar from './FilterBar';
import CustomTemplateCard from './CustomTemplateCard';
import CommunityTemplateCard from './CommunityTemplateCard';
import useStyles from './CardContentView.styles';

const CardContentView = () => {
  const classes = useStyles();

  const {
    state: { searchQuery },
    templatesToShow,
    isTemplatesFetching,
  } = useCreateAndExploreContext();

  const isEmptyView = !isTemplatesFetching && !templatesToShow?.length;
  const isEmptySearchView = !isTemplatesFetching && !!searchQuery && !templatesToShow?.length;

  return (
    <>
      {(!isEmptyView || isEmptySearchView) && (
        <div className={classes.navigationContainer}>
          <FilterBar />

          <div className={classes.divider} />
        </div>
      )}

      {!!templatesToShow?.length && (
        <div className={classes.cardContentViewContainer}>

          {templatesToShow?.map((template) => (
            <>
              {template?.community && (
                <CommunityTemplateCard
                  key={template?._id}
                  template={template}
                />
              )}

              {!template?.community && (
                <CustomTemplateCard
                  key={template?._id}
                  template={template}
                />
              )}
            </>
          ))}
        </div>
      )}

      {isEmptyView && !isEmptySearchView && <EmptyView />}
      {isEmptySearchView && <EmptySearchView />}
    </>
  );
};

export default CardContentView;
