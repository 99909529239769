const getEntityRoles = (user) => (entity) => {
  const emptyPermission = {
    owner: false,
    editor: false,
    viewer: false,
  };

  if (!entity || !entity._id) {
    return emptyPermission;
  }

  const entityAcl = (entity.unifiedAcl || []).find((a) => a.userId === user._id);

  if (!entityAcl) {
    return emptyPermission;
  }

  return {
    owner: entityAcl.role === 'owner',
    editor: entityAcl.role === 'editor',
    viewer: entityAcl.role === 'viewer',
  };
};

const getFilePermissions = (user) => (file) => {
  const fileRoles = getEntityRoles(user)(file);

  return {
    canManageDocuments: fileRoles.owner || fileRoles.editor,
    canCloneFile: fileRoles.owner || fileRoles.editor,
    canEditFile: fileRoles.owner || fileRoles.editor,
    canAddMembers: fileRoles.owner || fileRoles.editor,
    canUpdateMemberPermissions: fileRoles.owner,
    canRemoveMembers: fileRoles.owner,
  };
};

const getFolderPermissions = (user) => (folder) => {
  const folderRoles = getEntityRoles(user)(folder);

  return {
    canEditFolder: folderRoles.owner || folderRoles.editor,
    canCreateFolderFiles: folderRoles.owner || folderRoles.editor,
    canEditFolderFiles: folderRoles.owner || folderRoles.editor,
    canRenameFolderFiles: folderRoles.owner,
    canDeleteFolderFiles: folderRoles.owner,
    canManageDocuments: folderRoles.owner || folderRoles.editor,
    canViewFolderMembers: folderRoles.owner || folderRoles.editor,
    canAddMembers: folderRoles.owner || folderRoles.editor,
    canUpdateMemberPermissions: folderRoles.owner,
    canRemoveMembers: folderRoles.owner,
    canViewFolderFiles: folderRoles.owner || folderRoles.editor || folderRoles.viewer,
  };
};

const getUserRoles = (user) => {
  const activeWorkspace = user.workspaces.find((w) => w.workspaceId === user.activeWorkspaceId);
  const { roles } = activeWorkspace;

  return {
    owner: roles.includes('owner'),
    admin: roles.includes('admin'),
    member: roles.includes('member'),
    superAdmin: roles.includes('superAdmin'),
  };
};

const featureGatedStarterDisallowedFeatures = [
  'bulk-copy',
  'bulk-product-descriptions',
  'bulk-content-generation',
  'bulk-import-export',
  'google-ads',
  'google-docs-publishing',
  'hootsuite',
  'rest',
  'shopify',
  'wordpressEcommercePlugin',
  'zapier',
  'bigCommerce',
  'akeneo',
  'catalog-pcm',
];

const featureGatedProDisallowedFeatures = [
  'rest',
];

const newPricingStarterDisallowedFeatures = [
  'bulk-import-export',
  'custom-templates',
  'support-service',
  'account-manager',
  'custom-onboarding',
  'api',
  'bigCommerce',
  'akeneo',
  'catalog-pcm',
];
const newPricingProDisallowedFeatures = [
  'bulk-import-export',
  'custom-templates',
  'support-service',
  'account-manager',
  'custom-onboarding',
  'api',
  'akeneo',
  'catalog-pcm',
];
const freeTrialDisallowedFeatures = ['api'];
const teamsDisallowedFeature = ['api', 'akeneo', 'catalog-pcm'];
const enterpriseDisallowedFeatures = [];

const getDisallowedFeatures = (billingData) => {
  const featuresMap = {
    0: freeTrialDisallowedFeatures,
    100: newPricingStarterDisallowedFeatures,
    101: featureGatedStarterDisallowedFeatures,
    103: newPricingStarterDisallowedFeatures,
    150: featureGatedStarterDisallowedFeatures,
    151: featureGatedStarterDisallowedFeatures,
    200: newPricingProDisallowedFeatures,
    201: newPricingProDisallowedFeatures,
    202: newPricingProDisallowedFeatures,
    203: newPricingProDisallowedFeatures,
    204: newPricingProDisallowedFeatures,
    250: featureGatedProDisallowedFeatures,
    300: teamsDisallowedFeature,
    400: enterpriseDisallowedFeatures,
    500: enterpriseDisallowedFeatures,
    501: enterpriseDisallowedFeatures,
  };

  const mostRestrictivePermissions = newPricingStarterDisallowedFeatures;

  return featuresMap[billingData?.rank] || mostRestrictivePermissions;
};

const featureAllowed = (featureName, billingData, featureToggles, enabledFromPreviousPlan) => {
  if (enabledFromPreviousPlan) {
    return true;
  }
  const disallowedFeatures = getDisallowedFeatures(billingData, featureToggles);
  return !disallowedFeatures.includes(featureName);
};

const getUserPermissions = (userRoles, billingData, companyBilling, featureToggles, previousPlanIntegrations) => {
  const integrationsEnabledFromPreviousPlan = previousPlanIntegrations ? [...previousPlanIntegrations] : [];
  const isOwnerOrAdmin = (userRoles.owner || userRoles.admin);
  return {
    bulkUploadCsv: billingData.enterprise && isOwnerOrAdmin,
    bulkCopy: featureAllowed('bulk-copy', billingData, featureToggles),
    // always allow to use teams feature
    teams: true,
    frase: featureAllowed('frase', billingData, featureToggles),
    workflowQueue: featureToggles.workflowQueue && featureAllowed('workflow-queue', billingData, featureToggles),
    zapier: featureToggles.zapier && featureAllowed('zapier', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('zapier')),
    googleAds: featureAllowed('google-ads', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('googleAds')),
    googleDocsPublishing: featureAllowed('google-docs-publishing', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('googleDocs')) && featureToggles.googleDocsPublishing,
    shopify: featureAllowed('shopify', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('shopify')),
    installZapier: isOwnerOrAdmin && featureToggles.zapier && featureAllowed('zapier', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('zapier')),
    installGoogleAds: isOwnerOrAdmin && featureAllowed('google-ads', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('googleAds')),
    installGoogleDocs: isOwnerOrAdmin && featureAllowed('google-docs-publishing', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('googleDocsAddOn')),
    installShopify: isOwnerOrAdmin && featureAllowed('shopify', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('shopify')),
    hootsuite: featureToggles.hootsuite && featureAllowed('hootsuite', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('hootsuite')),
    restClient: featureToggles.restClient && featureAllowed('rest', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('rest')),
    googleDocsAddOn: featureAllowed('google-docs', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('googleDocsAddOn')),
    fineTunes: featureAllowed('fine-tunes', billingData, featureToggles),
    chromeExtension: featureAllowed('chromeExtension', billingData, featureToggles),
    wordpressEcommercePlugin: featureToggles.wordpressEcommercePlugin && featureAllowed('wordpressEcommercePlugin', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('wordpressEcommercePlugin')),
    // freeFormEditor: featureAllowed('freeFormEditor', billingData, featureToggles),
    superAdmin: userRoles.superAdmin,
    MSWordAddIn: featureAllowed('ms-word-add-in', billingData, featureToggles, integrationsEnabledFromPreviousPlan.includes('MSWordAddIn')),
    manageTemplateBuilder: isOwnerOrAdmin || userRoles.superAdmin,
    viewTemplateBuilder: isOwnerOrAdmin || userRoles.superAdmin || userRoles.member,
    bigCommerce: featureAllowed('bigCommerce', billingData, featureToggles),
    akeneo: featureAllowed('akeneo', billingData, featureToggles),
    catalogPCM: featureAllowed('catalog-pcm', billingData, featureToggles),
  };
};

export default {
  getFolderPermissions,
  getFilePermissions,
  getFolderRoles: getEntityRoles,
  getUserPermissions,
  getUserRoles,
  featureAllowed,
};
