import React, { useEffect, useMemo, useState } from 'react';
import { IconCheck } from '@tabler/icons-react';
import { FormHelperText, MenuItem, Select } from '@material-ui/core';

import fileContext from '@root/resources/file/file.context';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import workflowQueueContext from '@root/resources/file/workflowQueue.context';
import Avatar from '@root/components/Avatar';

import useStyles from './AssignToSelect.styles';

const AssignToSelect = ({ onChange }) => {
  const classes = useStyles();
  const {
    assignedToId, assignedToError, canManageDocuments,
  } = workflowQueueContext.useWorkflowQueue();
  const { isDocumentsFlagged } = fileContext.useFile() || {};

  const isTooltipInitiallyOpened = !!(isDocumentsFlagged && !assignedToId);
  const [isTooltipOpened, toggleTooltip] = useState(isTooltipInitiallyOpened);

  const { data: membersData } = useTeamMembers();

  const options = useMemo(() => {
    if (!membersData || !membersData.results) {
      return [];
    }

    return membersData.results.map((member) => ({
      value: member._id,
      email: member.email,
      name: member.displayName,
    }));
  }, [membersData]);

  const handleFocus = () => {
    toggleTooltip(false);
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const renderUser = (user, selected) => {
    const profileInfo = user.name || user.email;
    return (
      <span className={classes.user}>
        <Avatar
          className={selected && classes.avatarSelected}
          popperEnabled={false}
          name={profileInfo}
          userId={user.value}
        />
        {profileInfo}
      </span>
    );
  };

  const renderMenuItem = (option) => {
    const selected = option.value === assignedToId;
    return (
      <MenuItem
        value={option.value}
        key={option.value}
        className={classes.menuItem}
        disabled={option.disabled}
      >
        {renderUser(option)}
        {selected && <IconCheck size={20} />}
      </MenuItem>
    );
  };

  const renderSelected = (selectedValue) => {
    const selectedOption = options.find((option) => option.value === selectedValue);
    if (!selectedOption) {
      return <span className={classes.placeholder}>Assign to:</span>;
    }
    return renderUser(selectedOption, true);
  };

  useEffect((() => {
    toggleTooltip(isTooltipInitiallyOpened);
  }), [toggleTooltip, isTooltipInitiallyOpened]);

  return (
    <SimpleTooltip
      content="Select a team member to assign this copy."
      open={isTooltipOpened}
      wrapped
    >
      <Select
        variant="outlined"
        value={assignedToId}
        onChange={handleChange}
        onFocus={handleFocus}
        error={assignedToError}
        renderValue={renderSelected}
        displayEmpty
        className={classes.select}
        disabled={!canManageDocuments}
        id="select-assignee"
      >
        {options.map(renderMenuItem)}
      </Select>

      {assignedToError && (
        <FormHelperText error={!!assignedToError}>
          {assignedToError}
        </FormHelperText>
      )}
    </SimpleTooltip>
  );
};

export default AssignToSelect;
