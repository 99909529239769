import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    border: `${theme.form.input.borderWidth} solid ${theme.palette.colors.mediumDarkGrey}`,
    color: theme.palette.colors.darkGrey,
    fontWeight: 600,
    padding: theme.form.input.padding,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.colors.lightGrey,
      border: `${theme.form.input.borderWidth} solid ${theme.palette.colors.mediumDarkGrey}`,
    },
  },
}));

export default useStyles;
