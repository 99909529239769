import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  navbarContainer: {
    zIndex: 99,
  },
  navbar: {
    color: theme.palette.colors.primary,
    backgroundColor: theme.palette.colors.white,
    height: 72,
    padding: '0 48px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
  },
  trialBlock: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.black,
    gap: 8,
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
  },
}));
