import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    item: {
      color: theme.palette.colors.neutrals300,
      borderRadius: '4px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: 'normal',

      padding: '0px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      minWidth: '20px',
      height: '20px',
    },
    activeItem: {
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.colors.neutrals600,
    },
  };
});

export default useStyles;
