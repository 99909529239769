import useIntegration from './useIntegration';
import useCreateIntegration from './useCreateIntegration';
import useUpdateIntegration from './useUpdateIntegration';
import useIntegrations from './useIntegrations';

export default {
  useIntegration,
  useCreateIntegration,
  useUpdateIntegration,
  useIntegrations,
};
