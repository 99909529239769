import React from 'react';
import ReactQuill from 'react-quill';

import { EDITOR_TOOLBAR_NAME } from '@root/components/FileToolbar';

import './Editor.css';

const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'background',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'width',
  'style',
];

const icons = ReactQuill.Quill.import('ui/icons');
icons.undo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons.redo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;

const modules = {
  toolbar: {
    container: `#${EDITOR_TOOLBAR_NAME}`,
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: false,
  },
};

const markupRegex = /<\/?[^>]+(>|$)/g;

const getHtmlTextLength = (content) => {
  return content.replace(markupRegex, '').replace(/&nbsp;/g, ' ').length;
};

const Editor = ({
  initialContent,
  initiallySelectAll,
  onTextSelected,
  onCancelSelection,
  onChange,
  newParagraphText,
  onNewParagraphTextAdded,
  replaceSelected,
  disabled = false,
}) => {
  const [content, setContent] = React.useState(initialContent);
  const [loaded, setLoaded] = React.useState(false);
  const [contentLength, setContentLength] = React.useState(getHtmlTextLength(initialContent));
  const [selectedText, setSelectedText] = React.useState('');
  const reactQuillRef = React.useRef(null);
  const [quillRef, setQuillRef] = React.useState(null);
  const [lastCursorPosition, setLastCursorPosition] = React.useState(0);
  const [selectionLength, setSelectionLength] = React.useState(0);

  React.useEffect(() => {
    if (loaded) {
      return;
    }
    if (reactQuillRef.current && (initialContent || initialContent === '')) {
      setContent(initialContent);
      const editor = reactQuillRef.current.getEditor();
      setQuillRef(editor);
      editor.focus();
      if (initialContent) {
        setLoaded(true);
      }
    }
  }, [initialContent]);

  React.useEffect(() => {
    if (!quillRef) {
      return;
    }

    const toolbar = quillRef.getModule('toolbar');
    toolbar.addHandler('undo', () => {
      quillRef.history.undo();
    });
    toolbar.addHandler('redo', () => {
      quillRef.history.redo();
    });
  }, [quillRef]);

  React.useEffect(() => {
    if (!quillRef) {
      return;
    }
    if (initiallySelectAll) {
      setTimeout(() => quillRef.setSelection(0, quillRef.getLength(), 'user'), 500);
    }
  }, [quillRef, initiallySelectAll]);

  React.useEffect(() => {
    if (!newParagraphText) {
      return;
    }

    /* want to just leave it here for possible future changes
    getting Delta from copied text
    this method translate bold text into attributes
    const newParagraphTextDelta = quillRef.clipboard.convert(newParagraphText);

    building delta for contents update
    const delta = new Delta()
      .retain(lastCursorPosition)
      .delete(selectionLength)
      .insert(newParagraphText);
      .concat(newParagraphTextDelta);

    update contents with prepared above delta
    quillRef.updateContents(delta);
    */

    quillRef.insertText(lastCursorPosition + selectionLength, newParagraphText);

    if (replaceSelected) {
      quillRef.deleteText(lastCursorPosition, selectionLength);
    }

    onNewParagraphTextAdded();
  }, [newParagraphText]);

  const storeCursorPosition = () => {
    if (!quillRef) {
      return;
    }
    const range = quillRef.getSelection();

    const position = range ? range.index : 0;
    const length = range ? range.length : 0;

    setLastCursorPosition(position);
    setSelectionLength(length);
  };

  const handleSelectionChange = (range) => {
    storeCursorPosition();
    if (!range || range.length === 0) {
      if (selectedText) {
        onCancelSelection();
        setSelectedText('');
      }
      return;
    }

    let text = quillRef.getText(range.index, range.length);
    text = (text || '').trim();
    onTextSelected(text);
    setSelectedText(text);
  };

  const handleChange = (newValue) => {
    const processedNewValue = newValue;
    setContent(processedNewValue);
    setContentLength(getHtmlTextLength(processedNewValue));
    onChange(processedNewValue);
  };

  return (
    <div className="copysmith-editor-wrapper">
      <div className="copysmith-editor-char-counter">
        <p>
          {contentLength}
          {' '}
          char
        </p>
      </div>
      <ReactQuill
        readOnly={disabled}
        ref={reactQuillRef}
        theme="snow"
        modules={modules}
        format={formats}
        value={content}
        onChangeSelection={handleSelectionChange}
        onChange={handleChange}
      />
    </div>
  );
};

export default Editor;
