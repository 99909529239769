import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardContentViewContainer: {
    flex: 1,
    overflowX: 'auto',

    display: 'grid',
    gap: '24px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(298px, 1fr))',
    gridAutoRows: '204px',
  },
}));

export default useStyles;
