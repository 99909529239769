import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    width: '22rem',
    height: '22rem',
    borderRadius: '4px', // set via theme
    alignContent: 'center',
    outline: 'none',
  },
  skipTourButton: {
    marginLeft: 0,
  },
  paperWrapper: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: theme.palette.colors.darkGrey,
    marginTop: '1rem',
  },
  onBoardingText: {
    color: theme.palette.colors.darkGrey,
    margin: '1.25rem 0',
  },
  skipTourWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
