import React, { useEffect, useMemo, useRef } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import Tooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import templatesContext from '@root/resources/templates/templates.context';
import ItemTypes from '@root/utils/dndItemTypes';
import useFileMenuItems from '@root/views/Dashboard/hooks/useFileMenuItems';
import { BookmarkFile } from '@root/views/Dashboard/components/IconButtons';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import { navToFileMeta } from '@root/views/Dashboard/helpers';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import Checkbox from '@root/components/Checkbox';
import AuthContext from '@root/resources/auth/auth.context';

import useCommonStyles from './tableComponents.styles';

const FileTableRow = ({
  file,
  selected,
  toggleSelected,
  isDraggable,
  cells,
  setRef,
  files,
}) => {
  const [visible, setVisible] = React.useState(!window.IntersectionObserver);
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const {
    isTemplateDisabled,
    toggles: {
      fixedColumn,
      showExternalSourceTooltip,
    },
  } = currentExternalSource;
  const isDisabled = isTemplateDisabled(file.templateType);
  const commonClasses = useCommonStyles();
  const [isRowHovered, setRowHovered] = React.useState(false);

  const history = useHistory();
  const { locationId } = useParams();
  const innerRef = useRef();

  const { getTemplate } = templatesContext.useTemplates();

  const { slug } = getTemplate(file.templateType);

  const fileMenu = useFileMenuItems(file);

  const { pathname = '' } = history.location;
  const uncategorizedFolder = (pathname.includes('Home_') || pathname.includes('shared_uncategorized_')) && 'Uncategorized';
  const bookmarkFolder = pathname.includes('bookmarks') && 'Bookmarks';
  const workflowFolder = pathname.includes('workflow') && 'Workflow';
  const prevLinkSelected = locationId || uncategorizedFolder || bookmarkFolder || workflowFolder || 'allFiles';
  const forceVisible = files.length < 20;

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  useEffect(() => {
    setRef({
      setVisible,
      element: innerRef.current,
      fileId: file._id,
    });
  }, [innerRef.current, prevLinkSelected, files]);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.FILE_CARD,
    item: { fileId: file._id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDraggable) {
    drag(innerRef);
  }

  const getHootsuiteText = ({ link, externalSourceName }) => (
    <>
      This template is not supported in
      {' '}
      {externalSourceName}
      .
      To create, edit, or manage this type of file,&nbsp;
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        please open your Copysmith web app
      </a>
      .
    </>
  );

  const renderedCells = useMemo(() => (
    cells.map(({ render, id }, index) => (
      <TableCell
        key={`${file._id}-${id}`}
        padding="none"
        classes={{
          root: clsx({
            [commonClasses.fixedCell]: fixedColumn && !index,
            [commonClasses.thirdCell]: fixedColumn && !index,
            [commonClasses.newCell]: newDesign,
          }),
        }}
        className={commonClasses.cell}
      >
        {render ? render(file, innerRef, isDisabled) : file[id]}
      </TableCell>
    ))
  ), [file, cells, isDisabled]);

  const isTooltipShowed = isRowHovered && isDisabled && showExternalSourceTooltip;
  return (
    <TableRow
      className={clsx({
        [commonClasses.draggingPlaceholder]: isDragging,
        [commonClasses.fileRow]: !isDragging,
        [commonClasses.rowDisabled]: isDisabled,
        [commonClasses.newAlternatingRow]: newDesign,
      })}
      onClick={!isDisabled && navToFileMeta(file, history, slug, {
        prevLinkSelected,
        prevPathName: pathname,
      })}
      onMouseEnter={() => { setRowHovered(true); }}
      onMouseLeave={() => { setRowHovered(false); }}
      key={file._id}
      ref={innerRef}
    >
      {(visible || forceVisible) && (
        <>
          <TableCell
            onMouseEnter={() => { setRowHovered(true); }}
            classes={{
              root: clsx({
                [commonClasses.fixedCell]: fixedColumn,
                [commonClasses.newCell]: newDesign,
              }),
            }}
            padding="checkbox"
          >
            {!isDragging && (
              <Checkbox
                checked={selected}
                onClick={toggleSelected}
                disabled={isDisabled}
              />
            )}
          </TableCell>

          <TableCell
            classes={{
              root: clsx({
                [commonClasses.fixedCell]: fixedColumn,
                [commonClasses.secondCell]: fixedColumn,
                [commonClasses.newCell]: newDesign,
              }),
            }}
            padding="none"
          >
            <Tooltip
              content={<ExternalSourceText getText={getHootsuiteText} />}
              wrapped
              interactive
              open={!!isTooltipShowed}
              placement="top"
            >
              <BookmarkFile
                isDisabled={isDisabled}
                fileId={file._id}
                liked={file.liked}
              />
            </Tooltip>

          </TableCell>
          {renderedCells}
          <TableCell
            classes={{
              root: clsx({
                [commonClasses.newCell]: newDesign,
              }),
            }}
            padding="none"
          >
            {!isDragging && (
              <KebabMenu
                isDisabled={isDisabled}
                menuItems={fileMenu}
                variant="list"
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default FileTableRow;
