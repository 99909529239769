import React from 'react';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import SingleProductModalOptions from './SingleProductModalOptions';
import useStyles from './SingleProductModal.styles';

export const SINGLE_PRODUCT_MODAL = 'SINGLE_PRODUCT_MODAL';

const SingleProductModal = () => {
  const classes = useStyles();

  const { dispatch: modalsDispatch, state: { modalId } } = useGlobalModals();

  const closeModal = () => {
    modalsDispatch({ type: modalsActions.CLOSE_MODAL });
  };

  return (
    <Modal
      title="How would you like to add you product?"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalId === SINGLE_PRODUCT_MODAL}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modalPaper }}
    >
      <SingleProductModalOptions />
    </Modal>
  );
};

export default SingleProductModal;
