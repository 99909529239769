import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    maxWidth: '40%',
  },
  newIconContainer: {
    backgroundColor: 'coral',
    color: 'white',
    borderRadius: theme.border.radius.default,
    padding: '1px 6px 2px 6px',
    display: 'inline-block',
    fontSize: '9pt',
    fontWeight: 600,
  },
  newsTile: {
    borderRadius: theme.border.radius.default,
    margin: '10px',
    padding: '16px 16px 6px 16px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  newTileItem: {
    marginBottom: '8px',
  },
  newTileItemDate: {
    color: 'grey',
  },
  newsTileTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& *': {
      margin: '15px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '12px',
    width: '40px',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  panelHeader: {
    height: '60px',
    lineHeight: '60px',
    paddingLeft: '20px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: '15pt',
    fontWeight: 'bold',
    position: 'relative',
  },
  panelTitle: {
    float: 'left',
  },
}));
