import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cell: {
    padding: 8,
    borderWidth: '0.5px',
  },
}));

export default useStyles;
