const getTemplateActionStatuses = ({ templates, template, company }) => {
  const correspondingMyTemplates = Object.values(templates).filter(
    (t) => t.originTemplateId === template?._id,
  );

  const isSomeTemplatesFromCommunity = correspondingMyTemplates.some(
    (t) => !!t?.fromCommunity && !t?.deletedOn,
  );
  const isTemplateInLibrary = isSomeTemplatesFromCommunity;

  const isCompanyTemplate = (
    template?.companyId === company?._id
  );

  const isSaveToAccountVisible = !isTemplateInLibrary && !isCompanyTemplate;

  const isUploadToCommunityVisible = !template?.shared;
  const isRemoveFromCommunityVisible = template?.shared;

  const correspondingTemplate = correspondingMyTemplates.find(
    (t) => !t.deletedOn && !!t?.fromCommunity,
  );

  return {
    isSaveToAccountVisible,
    isUploadToCommunityVisible,
    isRemoveFromCommunityVisible,
    correspondingTemplate,
    isTemplateInLibrary,
    isCompanyTemplate,
  };
};

export default getTemplateActionStatuses;
