import React, { FC } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { startCase } from 'lodash';

import useGetUsageByTemplateType from '@root/resources/usage/useUsageByTemplateType';
import icons from '@root/views/Editor/icons';
import TemplatesContext from '@root/resources/templates/templates.context';

import useSectionStyles from '../Sections.styles';
import CreditRecordType from '../../UsageV2.types';

import useStyles from './TemplatesUsageSection.styles';
import EmptyView from './EmptyView';

interface TemplatesWithTimesUsed {
  _id: string;
  count: number;
  template: any[];
}

type Props = {
  isOverageSectionVisible: boolean;
  creditRecord?: CreditRecordType;
  isLoading: boolean;
};

const TemplatesUsageSection: FC<Props> = ({
  isOverageSectionVisible,
  creditRecord,
  isLoading: isCreditRecordsFetching,
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const { templatesList } = TemplatesContext.useTemplates({ withHidden: true });

  const {
    data: templatesWithTimesUsed = [],
    isFetching: isTemplateStatsFetching,
  } = useGetUsageByTemplateType<TemplatesWithTimesUsed[]>(
    {
      startDate: creditRecord?.createdOn,
      endDate: creditRecord?.endDate,
    },
    {
      enabled: !!creditRecord,
      refetchOnMount: 'always',
    },
  );

  const templatesToDisplay = templatesWithTimesUsed
    ?.map((template: any) => {
      let title = '';

      const templateType = template?._id;

      switch (templateType) {
        case 'special.instruct':
          title = 'Instruct';
          break;
        default:
          title = template?.template?.[0]?.title;
          break;
      }

      if (!title) {
        // try to find template from templateList in case if no template found in db
        const matchedTemplate: any = templatesList.find(
          (t: any) => t.templateType === template?._id,
        );

        // in case if title was not found just use template type with startCase formatting
        title = matchedTemplate?.title || startCase(templateType);
      }

      const { icon, bgColor, color } = icons?.[template?._id] || icons.custom;

      return {
        _id: template._id,
        title,
        count: template.count,
        icon,
        bgColor,
        color,
      };
    })
    .sort((a, b) => b.count - a.count);

  const isLoading = isCreditRecordsFetching || isTemplateStatsFetching;

  return (
    <Grid
      item
      md={isOverageSectionVisible ? 6 : 8}
      sm={8}
      xs={12}
    >
      <div className={clsx(sectionClasses.sectionContainer, classes.sectionContainer)}>
        <Typography classes={{ root: sectionClasses.sectionTitle }}>
          <span>Case</span>
          <span>Times used</span>
        </Typography>

        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!isLoading && !templatesWithTimesUsed?.length && (
          <EmptyView isLatestRecordSelected={creditRecord?.isLatest} />
        )}

        {!isLoading && !!templatesWithTimesUsed?.length && (
          <div style={{ overflow: 'auto', height: '100%' }}>
            {templatesToDisplay?.map(
              ({ _id, title, count, icon: Icon, bgColor, color }, index, templates) => {
                const templateWithMaxTimesUsed = templates[0];
                const templateProgress = Math.floor(
                  (+count * 100) / +templateWithMaxTimesUsed.count,
                );

                return (
                  <div key={_id} className={classes.templateItem}>
                    <div
                      className={classes.progressBar}
                      style={{ width: `${templateProgress}%` }}
                    />

                    <div className={classes.templateText}>
                      <div className={classes.templateWithIcon}>
                        <div
                          className={classes.iconContainer}
                          style={{
                            backgroundColor: bgColor,
                            color,
                          }}
                        >
                          <Icon />
                        </div>
                        <span>{title}</span>
                      </div>
                      <span>{count}</span>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        )}
      </div>
    </Grid>
  );
};

export default TemplatesUsageSection;
