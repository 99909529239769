import React from 'react';
import ReactQuill from 'react-quill';

import EditorToolbar from '@root/components/FileToolbar/components/EditorToolbar';

import quillConfig from '../quill.config';

/**
 * every quill instance requires unique toolbar
 */
const Toolbars = ({
  documentsIds,
  activeDocumentId,
  toolbarsRef,
}) => {
  const toolbarsMap = React.useMemo(() => documentsIds.reduce((map, id) => {
    const toolbar = (
      <div key={id} style={id !== activeDocumentId ? { display: 'none' } : null}>
        <EditorToolbar
          id={`toolbar-${id}`}
        />
      </div>
    );
    map[id] = toolbar;
    return map;
  }, {}), [
    documentsIds,
    activeDocumentId,
  ]);

  toolbarsRef(toolbarsMap);

  const stub = (
    <React.Fragment key="stub">
      <div style={documentsIds.includes(activeDocumentId) ? { display: 'none' } : null}>
        <EditorToolbar
          id="toolbar-stub"
        />
      </div>
      <div style={{ display: 'none' }}>
        <ReactQuill
          modules={{
            ...quillConfig.modules,
            toolbar: {
              container: '#toolbar-stub',
            },
          }}
          formats={quillConfig.formats}
        />
      </div>
    </React.Fragment>
  );

  return [stub, ...Object.values(toolbarsMap)];
};

export default Toolbars;
