import React from 'react';

import Loading from '@root/components/Loading/Loading';
import api from '@root/api';
import jwtService from '@root/services/jwt.service';
import config from '@root/config';
import authContext from '@root/resources/auth/auth.context';

const getToken = () => jwtService.getJwtValue();

const createIntegration = async () => {
  // user has not connected to google Ads yet, need to create local integration id for OAuth
  const newIntegration = await api.integration.create({ type: 'akeneo' });

  return newIntegration;
};

const findIntegration = async () => {
  const { results: integrations } = await api.integration.list({ type: 'akeneo' });
  if (integrations.length > 0) {
    const { 0: integration } = integrations;

    return integration;
  }

  return null;
};

const getOauthUrl = ({
  pimUrl,
  token,
  clientId,
  scopes,
}) => {
  return `${pimUrl}/connect/apps/v1/authorize?response_type=code&client_id=${clientId}&scope=${scopes}&state=${btoa(JSON.stringify({ token, pimUrl }))}`;
};

const AkeneoConnectCallback = () => {
  const [integrationId, setIntegrationId] = React.useState(null);
  const query = new URLSearchParams(window.location.search);

  const { currentUser } = authContext.useAuth();

  const pimUrl = query.get('pimUrl');

  React.useEffect(() => {
    async function checkIfConnected() {
      const integration = await findIntegration();
      if (integration) {
        setIntegrationId(integration._id);
      }
    }
    checkIfConnected();
  }, []);

  const connect = async () => {
    let id = integrationId;
    if (!id) {
      const integration = await createIntegration();
      setIntegrationId(integration._id);
      id = integration._id;
    }

    const token = await getToken();
    const url = getOauthUrl({
      pimUrl,
      token,
      clientId: config.integrations.akeneo.clientId || currentUser.akeneo.clientId,
      scopes: config.integrations.akeneo.scopes.join(' '),
    });

    window.location.assign(url);
  };

  React.useEffect(() => connect(), []);

  return <Loading />;
};

export default AkeneoConnectCallback;
