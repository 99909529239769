import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Fire = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path xmlns="http://www.w3.org/2000/svg" d="M19.0134 12.5296L19.0159 12.5362L19.0187 12.5428C20.0536 15.0193 19.3543 17.8777 17.4212 19.6723C15.6745 21.2702 13.2114 21.7123 10.8733 21.414C10.1024 21.3141 8.58459 20.8602 7.22292 19.7675C5.87984 18.6898 4.68345 16.9887 4.50001 14.353C4.50308 11.937 5.15923 10.3596 5.78261 9.40263C5.78283 9.40272 5.78305 9.40281 5.78327 9.4029L5.78319 9.40363C5.6139 10.8955 5.80587 13.0844 7.81669 14.6075C8.22237 14.9184 8.70891 14.8373 9.01887 14.5922C9.32645 14.349 9.51708 13.9024 9.33588 13.4385C8.28388 10.7388 8.3948 8.39417 9.17552 6.47872C9.95992 4.55423 11.4365 3.02499 13.1739 2.00073L13.1739 2.00075L13.1789 1.99775C13.1792 1.99758 13.1795 1.99742 13.1797 1.99727C13.1799 1.9974 13.1801 1.99754 13.1803 1.99769C13.1821 1.99896 13.1831 2.00011 13.1836 2.0008L13.1836 2.00209C13.1835 2.00341 13.1832 2.00569 13.1825 2.00901C12.8273 3.56895 12.9197 4.79923 13.3218 5.82835C13.7209 6.84985 14.4058 7.62239 15.1421 8.29733C15.4779 8.6051 15.8326 8.89979 16.179 9.18754C16.2115 9.2145 16.2438 9.24139 16.2761 9.26824C16.6569 9.58484 17.0272 9.89595 17.3758 10.2269C18.0703 10.8862 18.6575 11.6049 19.0134 12.5296ZM10.0819 16.8941L9.10821 17.1308L9.8828 17.7665C11.4631 19.0635 13.0907 19.1744 14.3354 18.501C15.5462 17.846 16.3027 16.4928 16.26 15.052C16.2561 13.7985 15.3516 12.8568 14.6034 12.1038C14.5469 12.047 14.491 11.991 14.4358 11.9356C14.0998 11.5992 13.7891 11.2882 13.5313 10.9644C13.2342 10.5911 13.0444 10.2444 12.9824 9.89311L12.6697 8.12135L12.0234 9.80037C11.5391 11.0583 11.6612 12.0833 11.8434 12.9402C11.8801 13.1127 11.9163 13.2673 11.9503 13.4126C12.0045 13.6439 12.0532 13.8517 12.0895 14.071C12.145 14.4056 12.1548 14.6888 12.0928 14.9576L12.0928 14.9576L12.0914 14.9638C11.9281 15.715 11.3184 16.5936 10.0819 16.8941Z" />
    </SvgIcon>
  );
};

export default Fire;
