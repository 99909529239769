import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { useGlobalModals } from '@root/views/Layout/modalsContext';
import CloseButton from '@root/components/buttons/CloseButton';

import useStyles from './NotificationModal.styles';

const MoveFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();
  const {
    state: { modalContent },
  } = useGlobalModals();

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
    >
      <DialogTitle>{modalContent?.title || ''}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {modalContent?.description || ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={closeModal}>Cancel</Button>
          <Button onClick={modalContent?.onSubmit || (() => {})}>Create</Button>
        </div>
      </DialogActions>
      <CloseButton onClick={closeModal} />
    </Dialog>
  );
};

export default MoveFileModal;
