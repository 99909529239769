import React, { createContext, useState, useEffect } from 'react';

import hspService from '@root/services/hsp.service';
import bigCommerceService from '@root/services/bigCommerce.service';

import hootsuiteConfig from './hootsuite';
import bigCommerceConfig from './bigCommerce';
import defaultConfig from './default';

const ExternalSourcesContext = createContext({
  currentExternalSource: defaultConfig,
});

const ExternalSourcesProvider = ({
  children,
}) => {
  const [currentExternalSource, setExternalSource] = useState(defaultConfig);

  useEffect(() => {
    hspService.isHootsuite().then((isHootsuite) => {
      if (isHootsuite) {
        setExternalSource(hootsuiteConfig);
      }
    });

    if (bigCommerceService.isBigCommerce()) {
      setExternalSource(bigCommerceConfig);
    }
  }, []);

  return (
    <ExternalSourcesContext.Provider
      value={{
        currentExternalSource,
      }}
    >
      {children}
    </ExternalSourcesContext.Provider>
  );
};

function useExternalSource() {
  const context = React.useContext(ExternalSourcesContext);
  if (context === undefined) {
    throw new Error('useExternalSource must be used within a ExternalSourcesProvider');
  }
  return context;
}

export default { ExternalSourcesProvider, useExternalSource };
