import React from 'react';
import { useField } from 'formik';
import { FormHelperText, Slider } from '@material-ui/core';

import FieldLabel from '@root/components/form/FieldLabel';

const SliderField = ({
  label, valueLabelDisplay,
  name, error, labelTooltip, placeholder,
  required, classNames = {},
  value, marks, min, max, step,
  onChange,
  ...props
}) => {
  const [, , { setValue }] = useField(name || '');

  const handleChange = (e, v) => {
    if (name) {
      setValue(v);
    } else {
      onChange(v);
    }
  };

  return (
    <div className={classNames.root}>
      <FieldLabel
        label={label}
        name={name}
        labelTooltip={labelTooltip}
        required={required}
      />
      <Slider
        valueLabelDisplay={valueLabelDisplay}
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        marks={marks}
        {...props}
      />
      {error && (
        <FormHelperText
          error={!!error}
        >
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default SliderField;
