import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

import api from '@root/api';

const useBillingInfo = <T = any>(
  queryOptions?: UseQueryOptions<any, Error, T, [string, any]>,
): UseQueryResult<T, Error> => {
  return useQuery(
    ['billing.getInfo', { }],
    () => api.billing.getInfo(),
    {
      staleTime: 60 * 1000,
      ...queryOptions,
    },
  );
};

export default useBillingInfo;
