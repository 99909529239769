import React, { useEffect, useState } from 'react';
import { IconCommand } from '@tabler/icons-react';
import { Fade } from 'reactstrap';

import { isMacOS } from '@root/utils/browser.utils';

import useStyles from './Shortcuts.styles';

const Shortcuts = ({ triggered }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (triggered) {
      setShow(true);
      const timeId = setTimeout(() => {
        setShow(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    return () => {};
  }, [triggered]);

  return (
    <Fade in={show}>
      <div className={classes.shortcutsRoot}>
        To Undo
        <span className={classes.command}>
          <span className={classes.icon}>
            {isMacOS ? <IconCommand size={16} /> : 'Ctrl'}
          </span>
          +
          <span className={classes.icon}>
            Z
          </span>
        </span>
      </div>
    </Fade>
  );
};

export default Shortcuts;
