import React from 'react';

import Modal from '@root/components/modals/Modal';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

import RenameFileForm from './RenameFileForm';
import useStyles from './RenameFileModal.styles';

const RenameFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();
  const { state: { modalContent } } = useGlobalModals();

  const originalTitle = modalContent?.file?.title;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={`Rename File "${originalTitle}"`}
      subtitle="Do you want to save changes in file before exit?"
      open={modalOpen}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: classes.modal }}
    >
      <RenameFileForm closeModal={closeModal} />
    </Modal>
  );
};

export default RenameFileModal;
