import React from 'react';
import { Button, CircularProgress, Link as UiLink, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import useGetActivity from '@root/resources/activity/useGetActivity';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import templatesContext from '@root/resources/templates/templates.context';
import api from '@root/api/newApi';
import uiNotificationService from '@root/services/uiNotification.service';
import authContext from '@root/resources/auth/auth.context';

import stubIcon from './stubIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    borderRadius: theme.border.radius.lg,
    backgroundColor: theme.palette.colors.white,
    flexGrow: 1,
    minHeight: 500,
    overflow: 'auto',
  },
  stub: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  stubTitle: {
    ...theme.typography.h2,
    textAlign: 'left !important',
    margin: theme.spacing(4, 0, 1, 0),
  },
  stubDescription: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.colors.neutrals400,
    width: 490,
    marginBottom: theme.spacing(4),
  },
  shortCell: {
    width: '1px',
    whiteSpace: 'nowrap',
  },
  table: {
    height: 1,
  },
}));

const FileLink = ({ event }) => {
  const history = useHistory();

  const { getTemplate } = templatesContext.useTemplates();

  const file = event.data.object;

  const { slug } = getTemplate(file.templateType);

  const link = `/${slug}/${file._id}?title=${file.title}`;

  const onClick = async () => {
    const fileResp = await api.files.getById(file._id);
    if (!fileResp) {
      uiNotificationService.showErrorMessage('File not found');
      return;
    }
    history.push(link);
  };

  return (
    <UiLink
      onClick={onClick}
    >
      {file.title}
    </UiLink>
  );
};

const CatalogLink = ({ event }) => {
  const history = useHistory();

  const catalog = event.data.object;

  const link = `/catalog/${catalog._id}`;

  const onClick = async () => {
    const catalogResp = await api.catalog.get({
      id: catalog._id,
    });
    if (!catalogResp || !catalogResp.catalog) {
      uiNotificationService.showErrorMessage('Catalog not found');
      return;
    }
    history.push(link);
  };

  return (
    <UiLink
      onClick={onClick}
    >
      {catalog.title}
    </UiLink>
  );
};

const eventTypeContentMap = {
  'file.generated': ({ event }) => {
    return (
      <div>
        Generated content in
        {' '}
        <FileLink event={event} />
      </div>
    );
  },
  'file.created': ({ event }) => {
    return (
      <div>
        Created
        {' '}
        <FileLink event={event} />
        {' '}
        file
      </div>
    );
  },
  'file.removed': ({ event }) => {
    return (
      <div>
        Deleted
        {' '}
        <FileLink event={event} />
        {' '}
        file
      </div>
    );
  },
  'file.workflowUpdated': ({ event }) => {
    if (event.data.change.workflowStatus === 'approved') {
      return (
        <div>
          Approved
          {' '}
          <FileLink event={event} />
          {' '}
          file
        </div>
      );
    }
    if (event.data.change.workflowStatus === 'needsReview') {
      return (
        <div>
          Marked file
          {' '}
          <FileLink event={event} />
          {' '}
          as review needed
        </div>
      );
    }

    return null;
  },
  'catalog.itemsAdded': ({ event }) => {
    return (
      <div>
        Added
        {' '}
        {event.data.change.addedItemsCount}
        {' '}
        products to
        {' '}
        <CatalogLink event={event} />
        {' '}
        catalog
      </div>
    );
  },
  'catalog.created': ({ event }) => {
    return (
      <div>
        Created
        {' '}
        <CatalogLink event={event} />
        {' '}
        catalog
      </div>
    );
  },
};

const RecentActivity = () => {
  const classes = useStyles();

  const {
    currentUser,
  } = authContext.useAuth();

  const {
    data: activity = [],
    isLoading: isActivityLoading,
  } = useGetActivity();

  const {
    data: { results: teamMembers = [] } = {},
    isLoading: isTeamMembersLoading,
  } = useTeamMembers();

  const isLoading = isActivityLoading || isTeamMembersLoading;

  const teamMembersMap = React.useMemo(() => {
    const map = {};
    teamMembers.forEach((member) => {
      map[member._id] = member;
    });
    return map;
  }, [teamMembers]);

  const history = useHistory();

  const importProducts = async () => {
    try {
      const { catalogs: { results = [] } } = await api.catalog.search({
        sort: '_id',
      });

      if (results.length === 0) {
        history.push('/catalog?new=true');
        return;
      }

      const lastCatalog = results[results.length - 1];

      history.push(`/catalog/${lastCatalog._id}`);
    } catch (error) {
      history.push('/catalog?new=true');
    }
  };

  const importProductsButton = React.useMemo(() => {
    const disabled = !currentUser?.permissions?.catalogPCM;
    const button = (
      <Button
        onClick={importProducts}
        startIcon={<Add />}
        disabled={disabled}
      >
        Import Products
      </Button>
    );

    if (disabled) {
      return (
        <SimpleTooltip
          content="Feature is not available on your plan"
        >
          <div>
            {button}
          </div>
        </SimpleTooltip>
      );
    }

    return button;
  }, [currentUser]);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.stub}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {activity.length === 0 ? (
        <div className={classes.stub}>
          <img src={stubIcon} alt="Recent Activity" />
          <div className={classes.stubTitle}>Let’s start creating!</div>
          <div className={classes.stubDescription}>
            Need to know where you left off or what your team is up to?
            This activity will be your guide if you ever need to know what was last done.
          </div>
          {importProductsButton}
        </div>
      ) : (
        <Table className={classes.table}>
          <TableBody>
            {activity.map((item) => {
              const dt = DateTime.fromMillis(item.createdOn);
              return (
                <TableRow key={item._id}>
                  <TableCell className={classes.shortCell}>
                    <SimpleTooltip content={dt.toFormat('yy/M/d hh:mm:ss a')}>
                      <span>
                        {dt.toRelative()}
                      </span>
                    </SimpleTooltip>
                  </TableCell>
                  <TableCell className={classes.shortCell}>
                    <SimpleTooltip content={teamMembersMap[item.userId]?.email}>
                      <span>
                        {teamMembersMap[item.userId]?.displayName || 'Unknown'}
                      </span>
                    </SimpleTooltip>
                  </TableCell>
                  <TableCell>
                    {eventTypeContentMap[item.type]?.({ event: item })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default RecentActivity;
