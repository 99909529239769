import React from 'react';
import { debounce } from 'lodash';
import { StringUtil } from '@copysmith/utils';
import { makeStyles } from '@material-ui/core';

import config from '@root/config';
import { getReadabilityScore, getReadabilityData } from '@root/utils/readability.util';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

const isDev = config.env === 'development';

const useStyles = makeStyles((theme) => ({
  wordCount: {
    '&&': {
      minWidth: 28,
      height: 24,
      padding: '0 4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12,
      cursor: 'default',
    },
  },
  tooltip: {
    fontSize: 12,
  },
  tooltipRow: {
    padding: '4px 0',
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.colors.neutrals500}`,
    },
  },
}));

const WordCountButton = ({ onCountChange, count }) => {
  const { chars, words, score } = count;
  const classes = useStyles();

  document.onselectionchange = debounce(async () => {
    try {
      const selectedText = document.getSelection().toString();
      const symbolsCount = selectedText.length;
      const wordsCount = StringUtil.countWordsString(selectedText);
      const scoreCount = await getReadabilityScore(selectedText);
      onCountChange({ chars: symbolsCount, words: wordsCount, score: scoreCount });
    } catch (e) {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.error('WordCountButton error: ', e);
      }
    }
  }, 250);

  const { text: readability } = getReadabilityData(score);
  const readabilityScore = Math.round(score * 100);

  return (
    <SimpleTooltip
      placement="top"
      content={(
        <div className={classes.tooltip}>
          <div className={classes.tooltipRow}>
            <span>Characters</span>
            <span>{chars}</span>
          </div>
          <div className={classes.tooltipRow}>
            <span>Words</span>
            <span>{words}</span>
          </div>
          <div className={classes.tooltipRow}>
            <span>Readability score</span>
            <span>{`${readabilityScore} (${readability})`}</span>
          </div>
        </div>
    )}
    >
      <span className={classes.wordCount}>
        {chars || 0}
      </span>
    </SimpleTooltip>
  );
};

export default WordCountButton;
