import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 4px 4px 8px',
    borderRadius: 50,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.colors.white,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,

    '& > svg': {
      marginLeft: 4,
      cursor: 'pointer',
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.faded,
    },
  },
}));

export default useStyles;
