import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Backdrop, Fade, Modal, Button, DialogActions, DialogContent,
} from '@material-ui/core';

import externalSourcesContext from '@root/resources/externalSources/externalSources.context';

import useStyles from './AccountSuspendedModal.styles';

const AccountSuspendedModal = ({
  modalOpen,
  setModalOpen,
  renewalDate,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    currentExternalSource: { toggles: { openBillingLinksInNewTab, logoutFromSuspendModal } },
  } = externalSourcesContext.useExternalSource();

  const button = !openBillingLinksInNewTab ? (
    <Button
      onClick={() => {
        setModalOpen(false);
        history.push('/profile/plans');
      }}
    >
      Reactivate now →
    </Button>
  ) : (
    <Link
      to={{ pathname: '/profile/plans' }}
      target="_blank"
    >
      <Button>
        Reactivate now →
      </Button>
    </Link>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={classes.small}>
          <div className={classes.smallContainer}>
            <div className={classes.title}>
              <b>Subscription Paused</b>
            </div>
            <div>
              Your subscription is currently paused and is set to reactivate on
              <span className={classes.renewalDate}>
                {' '}
                {renewalDate}
              </span>
              .
            </div>
            <DialogActions className={classes.buttons}>
              {logoutFromSuspendModal && (
                <Link
                  to="/logout"
                >
                  <Button variant="text">
                    Logout
                  </Button>
                </Link>
              )}
              {button}
            </DialogActions>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AccountSuspendedModal;
