import React from 'react';

import useStyles from './InvoicesSection.styles';

const EmptyState = () => {
  const classes = useStyles();

  return (
    <div className={classes.emptyStateContainer}>
      <div className={classes.emptyStateTitle}>
        Your invoices will be displayed here
      </div>
    </div>
  );
};

export default EmptyState;
