import React, { useEffect, useMemo, useRef } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import Tooltip from '@root/components/tooltips/SimpleTooltip';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import templatesContext from '@root/resources/templates/templates.context';
import ItemTypes from '@root/utils/dndItemTypes';
import useFileMenuItems from '@root/views/Dashboard/hooks/useFileMenuItems';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import { navToFileMeta } from '@root/views/Dashboard/helpers';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import Checkbox from '@root/components/Checkbox';
import BookmarkFileButton from '@root/views/Dashboard/components/FolderContentNew/DashListViewNew/components/BookmarkFileButton';

import useCommonStyles from './tableComponents.styles';

const getExternalSourceText = ({ link, externalSourceName }) => (
  <>
    This template is not supported in
    {' '}
    {externalSourceName}
    .
    To create, edit, or manage this type of file,&nbsp;
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      please open your Copysmith web app
    </a>
    .
  </>
);

const FileTableRow = ({
  file,
  selected,
  toggleSelected,
  isDraggable,
  cells,
  setRef,
  files,
}) => {
  const [visible, setVisible] = React.useState(!window.IntersectionObserver);
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const {
    isTemplateDisabled,
    toggles: { showExternalSourceTooltip },
  } = currentExternalSource;
  const isDisabled = isTemplateDisabled(file.templateType);
  const commonClasses = useCommonStyles();
  const [isRowHovered, setRowHovered] = React.useState(false);

  const history = useHistory();
  const { locationId } = useParams();
  const innerRef = useRef();

  const { getTemplate } = templatesContext.useTemplates();

  const { slug } = getTemplate(file.templateType);

  const fileMenu = useFileMenuItems(file);

  const { pathname = '' } = history.location;
  const uncategorizedFolder = (pathname.includes('Home_') || pathname.includes('shared_uncategorized_')) && 'Uncategorized';
  const bookmarkFolder = pathname.includes('bookmarks') && 'Bookmarks';
  const workflowFolder = pathname.includes('workflow') && 'Workflow';
  const prevLinkSelected = locationId || uncategorizedFolder || bookmarkFolder || workflowFolder || 'allFiles';
  const forceVisible = files.length < 20;

  useEffect(() => {
    setRef({
      setVisible,
      element: innerRef.current,
      fileId: file._id,
    });
  }, [innerRef.current, prevLinkSelected, files]);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.FILE_CARD,
    item: { fileId: file._id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDraggable) {
    drag(innerRef);
  }

  const renderedCells = useMemo(() => (
    cells.map(({ render, id, align }) => (
      <TableCell
        key={`${file._id}-${id}`}
        className={commonClasses.cell}
        padding="none"
        align={align}
      >
        {render ? render(file, innerRef, isDisabled) : file[id]}
      </TableCell>
    ))
  ), [file, cells, isDisabled]);

  const isTooltipShowed = isRowHovered && isDisabled && showExternalSourceTooltip;

  return (
    <TableRow
      className={clsx({
        [commonClasses.fileRow]: !isDragging,
        [commonClasses.draggingPlaceholder]: isDragging,
        [commonClasses.rowDisabled]: isDisabled,
      })}
      onClick={!isDisabled && navToFileMeta(file, history, slug, {
        prevLinkSelected,
        prevPathName: pathname,
      })}
      onMouseEnter={() => { setRowHovered(true); }}
      onMouseLeave={() => { setRowHovered(false); }}
      key={file._id}
      ref={innerRef}
    >
      {(visible || forceVisible) && (
        <>
          <TableCell
            padding="checkbox"
            className={commonClasses.cell}
          >
            {!isDragging && (
              <Checkbox
                checked={selected}
                onClick={toggleSelected}
                disabled={isDisabled}
                classes={{
                  root: commonClasses.checkbox,
                  checked: commonClasses.checked,
                }}
              />
            )}
          </TableCell>

          {renderedCells}

          {/* Bookmark cell */}
          <TableCell className={commonClasses.cell} padding="none" align="center">
            <Tooltip
              content={<ExternalSourceText getText={getExternalSourceText} />}
              wrapped
              interactive
              open={!!isTooltipShowed}
              placement="top"
            >
              <BookmarkFileButton
                isDisabled={isDisabled}
                fileId={file._id}
                liked={file.liked}
              />
            </Tooltip>
          </TableCell>

          {/* Kebab menu Cell */}
          <TableCell className={commonClasses.cell} padding="none">
            {!isDragging && (
              <KebabMenu
                isDisabled={isDisabled}
                menuItems={fileMenu}
                variant="list"
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default FileTableRow;
