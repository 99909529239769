import React from 'react';

import Button from '@root/components/buttons/Button';
import useModalState from '@root/utils/hooks/useModalState';

import InviteModal from '../../InviteModal';

const InviteButton = ({ type, children }) => {
  const [isModalOpen, openModal, closeModal] = useModalState(false);

  return (
    <>
      <Button
        onClick={openModal}
        size="medium"
        type={type}
      >
        {children || 'Invite'}
      </Button>

      {isModalOpen && (
        <InviteModal
          onClose={closeModal}
        />
      )}

    </>
  );
};

export default InviteButton;
