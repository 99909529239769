import React, { useMemo } from 'react';
import { DateTime } from 'luxon';

import billingResource from '@root/resources/billing';
import usePlanInfo from '@root/resources/billing/usePricing';
import TruncatedText from '@root/components/TruncatedText';
import { colors } from '@root/material.theme';

import useStyles from './PlanBadge.styles';

const PlanBadge = () => {
  const classes = useStyles();

  const { data: { priceId, trialEndOn, status } = {} } = billingResource.useBillingInfo();
  const { data: { currentPlan } = {} } = usePlanInfo();

  const isLtd = status === 'ltd';
  const trialEndDate = trialEndOn && DateTime.fromSeconds(trialEndOn);
  const daysLeft = trialEndDate && Math.round(trialEndDate.diffNow().as('days'));
  const showDaysLeft = priceId === 'free' && trialEndOn;

  const planColors = useMemo(() => {
    switch (currentPlan?.internalName) {
      case 'enterprise':
        return {
          backgroundColor: colors.blue200,
          color: colors.blue600,
        };

      case 'free':
        return {
          backgroundColor: colors.pink200,
          color: colors.pink600,
        };

      default:
        return {
          backgroundColor: colors.green200,
          color: colors.green600,
        };
    }
  }, [currentPlan]);

  return (
    <div className={classes.planBadge} style={{ ...planColors }}>
      {isLtd && (
        <TruncatedText className={classes.planText}>
          Lifetime
        </TruncatedText>
      )}

      {!isLtd && !showDaysLeft && currentPlan && (
        <TruncatedText className={classes.planText}>
          {`${currentPlan?.name} ${currentPlan?.nickname ? `- ${currentPlan?.nickname}` : ''}`}
        </TruncatedText>
      )}

      {!isLtd && showDaysLeft && (
        <TruncatedText className={classes.planText}>
          {daysLeft <= 0
            ? 'Trial Plan - Expired'
            : `Trial Plan - ${daysLeft} day${daysLeft > 1 ? 's' : ''} left`}
        </TruncatedText>
      )}
    </div>
  );
};

export default PlanBadge;
