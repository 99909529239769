import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import useQuery from '@root/common/useQuery';

const WORKFLOW_LOCATION_TYPE = 'workflow';

const RedirectListener = () => {
  const { dispatch } = useDashboard();
  const { locationType } = useParams();

  const query = useQuery();

  useEffect(() => {
    switch (locationType) {
      case WORKFLOW_LOCATION_TYPE:
        if (query.get('filters')) {
          dispatch({
            type: actions.SELECT_WORKFLOW,
            payload: { shouldApplyFilters: true },
          });
        }
        break;
      default:
        break;
    }
  }, [locationType, query]);

  return null;
};

export default RedirectListener;
