import React from 'react';
import { makeStyles } from '@material-ui/core';

import Button from '@root/components/buttons/Button';
import { colors } from '@root/material.theme';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    padding: '4px 24px',
    backgroundColor: colors.primary,
    borderRadius: 4,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primaryLight,
    },
  },
}));

const GoBackButton = () => {
  const classes = useStyles();

  const goBack = () => {
    window.location.href = '/';
  };

  return (
    <Button
      size="large"
      onClick={goBack}
      className={classes.buttonRoot}
    >
      Go home
    </Button>
  );
};

export default GoBackButton;
