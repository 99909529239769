import React from 'react';
import { useHistory } from 'react-router-dom';

import useStyles from './BackButton.styles';

const BackButton = ({
  backButtonCallBack,
  preventGoBack,
  backUrl,
  backButtonText = 'Back',
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
      className={classes.backButton}
      onClick={() => {
        if (backButtonCallBack) {
          backButtonCallBack();
        }

        if (!preventGoBack) {
          if (backUrl) {
            history.push(backUrl);
          } else {
            history.goBack();
          }
        }
      }}
    >
      ←
      {' '}
      {backButtonText}
    </div>
  );
};

export default BackButton;
