import { UseQueryResult, useQuery } from 'react-query';

import api from '@root/api';

const useWordsUsage = <T>(filters: any): UseQueryResult<T, Error> => (
  useQuery(
    ['usage.wordsUsage', filters],
    () => api.usage.getWordsUsage(filters),
    { refetchOnMount: 'always' },
  )
);

export default useWordsUsage;
