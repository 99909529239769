import React, { useMemo } from 'react';
import clsx from 'clsx';

import AuthContext from '@root/resources/auth/auth.context';
import dateUtil from '@root/utils/date.util';
import TruncatedText from '@root/components/TruncatedText';

import useStyles from './AccountSection.styles';

const PlanStatusBadge = ({ billingData }) => {
  const classes = useStyles();

  const { currentCompany } = AuthContext.useAuth();

  const { cancelAtPeriodEnd, currentPeriodEndOn } = billingData;

  const badgeData = useMemo(() => {
    const isSuspended = currentCompany?.suspendStart;
    const isCancelScheduled = !!cancelAtPeriodEnd;

    if (isCancelScheduled) {
      return {
        label: `Cancellation date: ${dateUtil.formatDate(currentPeriodEndOn, 'LLL d, yyyy')}`,
        className: classes.suspended,
      };
    }

    if (isSuspended) {
      return {
        label: `Suspended until ${dateUtil.formatDate(currentCompany?.suspendEnd, 'LLL d')}`,
        className: classes.suspended,
      };
    }

    switch (billingData?.status) {
      case 'customer':
        return {
          label: 'Active',
          className: classes.active,
        };
      case 'cancelled':
        return {
          label: 'Cancelled',
          className: classes.cancelled,
        };
      case 'trialExpired':
        return {
          label: 'Trial Expired',
          className: classes.cancelled,
        };
      case 'trial':
        return {
          label: 'Trial',
          className: classes.suspended,
        };
      default:
        return {
          label: '-',
        };
    }
  }, [currentCompany, billingData, classes]);

  return (
    <div className={clsx(classes.planStatusContainer, badgeData.className)}>
      <TruncatedText className={classes.badgeText}>{badgeData.label}</TruncatedText>
    </div>
  );
};

export default PlanStatusBadge;
