import { useQuery } from 'react-query';

import api from '@root/api';

export default function useListNotifications(filters) {
  return useQuery(
    ['notification.listNotifications', filters],
    () => api.notification.listNotifications(filters), {
      staleTime: 30 * 1000,
    },
  );
}
