import React from 'react';

import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import { SearchField } from '@root/components/form';

import useStyles from './SearchFilesInput.styles';

const SearchFilesInput = () => {
  const classes = useStyles();

  const { dispatch } = useDashboard();

  const updateSearchQuery = (searchQuery) => {
    if (searchQuery.trim().length > 0) {
      return dispatch({
        type: actions.SET_SEARCH_QUERY,
        payload: {
          searchQuery,
        },
      });
    }
    return dispatch({
      type: actions.SELECT_ALL_FILES,
    });
  };

  return (
    <div className={classes.root}>
      <SearchField
        classes={{ root: classes.searchField, focused: classes.focused }}
        placeholder="Search for a file"
        onChange={updateSearchQuery}
        debounce
      />
    </div>
  );
};

export default SearchFilesInput;
