import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  planSelectionRoot: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  plansWrapper: {
    display: 'flex',
    gap: 32,
  },
}));
