import { makeStyles } from '@material-ui/core';

const buttonStyles = {
  fontWeight: 600,
  borderRadius: 4,
  width: 'fit-content',
};

export default makeStyles((theme) => ({
  root: {
    color: 'grey',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  rootCenter: {
    width: '100%',
    textAlign: 'center',
  },
  generateButton: {
    ...buttonStyles,
    boxShadow: '0 1px 2px 2px rgba(83, 92, 165, 0.2)',
    marginRight: 22,
  },
  moreCreditsButton: {
    ...buttonStyles,
  },
  creditsButton: {
    fontSize: 14,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  creditsModalContent: {
    marginBottom: 22,
  },
  buttons: {
    display: 'flex',
  },
  buttonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
