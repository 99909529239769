import React from 'react';

const IndeterminateIcon = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x=".5"
      y=".5"
      width="15"
      height="15"
      rx="3.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <path fill="#fff" d="M3 7h10v2H3z" />
  </svg>
);

export default IndeterminateIcon;
