import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  copy: {
    flex: 1,

    fontSize: '1.5rem',
    textAlign: 'center',
    padding: '2.5rem',

    display: 'grid',
    placeItems: 'center',
  },
});

export default useStyles;
