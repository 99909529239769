import React from 'react';
import { Formik, Form } from 'formik';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import config from '@root/config';
import * as authService from '@root/services/auth.service';
import { InputField, FormField, PasswordField } from '@root/components/form';
import Loading from '@root/components/Loading/GlobalLoading';
import logo from '@root/assets/copysmith-logo-26.png';
import AuthContext from '@root/resources/auth/auth.context';
import fileResource from '@root/resources/file';
import { featureEnabled, featureToggles } from '@root/utils/features';
import { redirectToNewEditor } from '@root/resources/file/file.helpers';

import AuthLayout from '../Layout/AuthLayout';

import ResetPasswordSchema from './validation/resetPassword';
import useStyles from './UserMgmt.styles';

const UserMgmt = () => {
  const [requestExecuted, setRequestExecuted] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { mode } = useParams();
  const { fetchAuthData } = AuthContext.useAuth();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const { mutateAsync: createFile } = fileResource.useCreateFile();
  const isRedirectToEditorEnabled = (featureEnabled(featureToggles.newEditor)
    && featureEnabled(featureToggles.editorLoginRedirect)
  );

  React.useEffect(async () => {
    if (requestExecuted) {
      return;
    }

    if (mode === 'verify-email-change') {
      const request = async () => {
        const { success } = await authService.confirmNewEmail(token);
        setRequestExecuted(true);
        if (!success) {
          history.replace('/login', { errorMessage: 'Token is expired or invalid.' });
          return;
        }

        history.replace('/templates');
        await fetchAuthData();
      };

      request();
      return;
    }
    if (mode === 'verify-email') {
      const request = async () => {
        const { success } = await authService.verifyEmail(token);
        setRequestExecuted(true);
        if (!success) {
          history.replace('/expired', { valid: true });
          return;
        }

        history.replace('/usermgmt/success');
      };

      request();
      return;
    }
    if (mode === 'success') {
      if (isRedirectToEditorEnabled) {
        redirectToNewEditor(createFile);
      } else {
        window.location.href = config.successLoginRedirectPath;
      }
    }
  }, []);

  const restorePassword = async ({ email }) => {
    const result = await authService.restorePassword(email);
    if (result?.isBadRequest) {
      return;
    }

    history.push('/login', { successMessage: 'Check your email.' });
  };

  const resetPassword = async ({ password }) => {
    const result = await authService.resetPassword(token, password);
    if (result?.isBadRequest) {
      return;
    }

    history.push('/login', { successMessage: 'Password reset successfully.' });
  };

  const backToLogin = () => {
    history.push('/login');
  };

  if (['verify-email', 'success'].includes(mode)) {
    return <Loading />;
  }

  return (
    <AuthLayout>
      <img className={classes.image} src={logo} alt="Copysmith Logo" />
      {mode === 'reset-password' && (
        <>
          <h2 className={classes.title}>Enter a new password</h2>
          <Formik
            initialValues={{ password: '' }}
            onSubmit={resetPassword}
            validationSchema={ResetPasswordSchema}
          >
            <Form className={classes.form}>
              <FormField
                name="password"
                label="Password"
                component={PasswordField}
                required
              />
              <Button type="submit">Submit</Button>
            </Form>
          </Formik>
        </>
      )}
      {mode === 'restore-password' && (
        <>
          <h2 className={classes.title}>Enter email</h2>
          <Formik
            initialValues={{ email: location.state.email || '' }}
            onSubmit={restorePassword}
          >
            <Form className={classes.form}>
              <FormField
                name="email"
                label="Email"
                component={InputField}
                required
              />
              <div>
                <Button
                  onClick={backToLogin}
                  variant="text"
                  className={classes.backToLogin}
                >
                  Back to login
                </Button>
                <Button type="submit">Submit</Button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </AuthLayout>
  );
};

export default React.memo(UserMgmt);
