import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  step: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  disabled: {
    color: theme.palette.colors.grey,
  },
}));

export default useStyles;
