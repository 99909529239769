import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M6 11.333c.368 0 .667.299.667.667v.007a.667.667 0 0 1-1.334 0V12c0-.368.299-.667.667-.667ZM8 12c0-.368.298-.667.667-.667h6.666a.667.667 0 0 1 0 1.334H8.667A.667.667 0 0 1 8 12ZM18 11.333c.368 0 .667.299.667.667v.007a.667.667 0 0 1-1.334 0V12c0-.368.299-.667.667-.667Z" />
    </svg>
  );
};
