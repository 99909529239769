import { makeStyles } from '@material-ui/core';

import theme from '@root/material.theme';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  selectedTitle: {
    display: 'flex',
    alignItems: 'center',

    fontFamily: 'Inter, sans-serif',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '39px',

    marginBottom: '16px',
  },

  parentFolderLink: {
    cursor: 'pointer',
    '&:hover': {
      color: '#5b5b5b',
    },
  },

  shareButton: {
    fontFamily: 'Inter, sans-sefif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    padding: '4px 12px 4px 8px',
    maxHeight: '32px',

    color: theme.palette.colors.neutrals400,

    '&:hover, &:active': {
      color: theme.palette.colors.neutrals400,
      backgroundColor: theme.palette.colors.neutrals100,
    },
  },

  shareButtonStartIcon: {
    margin: 0,
  },

  shareButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  disabledShareButton: {
    color: `${theme.palette.colors.neutrals200} !important`,
    fill: theme.palette.colors.neutrals200,
  },

  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },

  verticalDivider: {
    width: '1px',
    minHeight: '100%',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '0px 16px',
  },

  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.colors.neutrals100,
    margin: '24px 0px 16px',
  },
});

export default useStyles;
