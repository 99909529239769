import { useQuery } from 'react-query';

import api from '@root/api';
import AuthContext from '@root/resources/auth/auth.context';

const getDocuments = async (filters) => {
  const { results: allDocuments } = await api.documents.listFreeFormLatest(filters);

  const docs = allDocuments.map((d) => ({
    ...d,
    // TODO: remove the id mapping
    id: d._id,
  }));

  return docs;
};

export default function useFreeFormLatestDocuments(filters) {
  const { currentUser } = AuthContext.useAuth();

  return useQuery(['documents.freeFormLatestList', filters], () => getDocuments(filters, currentUser._id));
}
