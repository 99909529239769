import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import GenerationCard from '../GenerationCard';

import OutlineItem from './components/OutlineItem';
import useStyles from './ExpandedOutlines.styles';

const ExpandedOutlines = ({ outlines, selectedSections, onSectionSelect, onContinue }) => {
  const [checkedOutlines, setCheckedOutlines] = useState(outlines.map((o) => o.outline));
  const [currentOutline, setCurrentOutline] = useState(outlines[0]);
  const classes = useStyles();

  const handleCheck = (outline) => {
    if (checkedOutlines.includes(outline)) {
      setCheckedOutlines(checkedOutlines.filter((o) => o !== outline));
    } else {
      setCheckedOutlines([...checkedOutlines, outline]);
    }
  };

  const handleContinue = () => {
    onContinue(checkedOutlines);
  };

  return (
    <div className={classes.expandedOutlinesRoot}>
      <div className={classes.leftSide}>
        <div>
          <div className={classes.title}>Select outline output</div>
          <div className={classes.description}>
            Select which points of the outline you want to include in your blog below.
            On the right choose the copy you think fits best. You can edit and tweak later.
          </div>
          <div className={classes.outlines}>
            {outlines.map((outline) => {
              const isChecked = checkedOutlines.includes(outline.outline);
              const isCurrent = outline.outline === currentOutline.outline;
              return (
                <OutlineItem
                  key={outline.outline}
                  text={outline.outline}
                  checked={isChecked}
                  selected={isCurrent}
                  onClick={() => setCurrentOutline(outline)}
                  onCheck={() => handleCheck(outline.outline)}
                />
              );
            })}
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleContinue}
            size="small"
            fullWidth
          >
            Continue to blog
          </Button>
        </div>
      </div>

      <div className={classes.rightSide}>
        <div className={classes.currentOutlineText}>{currentOutline.outline}</div>
        {currentOutline.sections.map((section, index) => {
          const isSelected = index === selectedSections[currentOutline.outline];
          const contentLength = section.length;
          return (
            <GenerationCard
              key={section}
              content={section}
              contentLength={contentLength}
              selected={isSelected}
              onClick={() => onSectionSelect(
                (state) => ({ ...state, [currentOutline.outline]: index }),
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ExpandedOutlines;
