import React from 'react';

import useStyles from './SidebarBadge.styles';

const SidebarBadge = ({ value }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      { value }
    </div>
  );
};

export default SidebarBadge;
