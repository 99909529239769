import React from 'react';
import { DashboardOutlined, ListOutlined } from '@material-ui/icons';
import clsx from 'clsx';

import Button from '@root/components/buttons/Button';
import { SelectField, SearchField } from '@root/components/form';

import { templatesCategoriesOptions, viewTypes } from '../../Community.constants';
import { useCommunityContext } from '../../context/Community.context';
import { communityActions } from '../../context/communityReducer';

import useStyles from './FilterBar.styles';

const FilterBar = () => {
  const classes = useStyles();

  const {
    state: { templatesCategory, searchQuery, viewType },
    dispatch,
  } = useCommunityContext();

  const handleTemplatesCategoryChange = (e) => {
    dispatch({ type: communityActions.CHANGE_TEMPLATES_CATEGORY, payload: e.target.value });
  };

  const handleSearchChange = (value) => {
    dispatch({ type: communityActions.SET_SEARCH_QUERY, payload: value });
  };

  const handleViewTypeChange = (value) => {
    dispatch({ type: communityActions.CHANGE_VIEW_TYPE, payload: value });
  };

  return (
    <div className={classes.filterBarContainer}>
      <section className={classes.filterBySection}>
        <SelectField
          classNames={{ root: classes.selectField }}
          options={templatesCategoriesOptions}
          value={templatesCategory}
          onChange={handleTemplatesCategoryChange}
        />

        <SearchField
          debounce
          placeholder="Search for a template"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </section>

      <section className={classes.viewBySection}>
        <Button
          variant="text"
          className={clsx(classes.iconButton, { [classes.selected]: viewType === viewTypes.LIST })}
          onClick={() => handleViewTypeChange('LIST')}
        >
          <ListOutlined />
        </Button>

        <Button
          variant="text"
          className={clsx(classes.iconButton, { [classes.selected]: viewType === viewTypes.CARD })}
          onClick={() => handleViewTypeChange('CARD')}
        >
          <DashboardOutlined />
        </Button>
      </section>
    </div>
  );
};

export default FilterBar;
