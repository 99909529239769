import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 20px',
    backgroundColor: 'rgba(79, 55, 227, 0.05)',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 6,
    color: theme.palette.colors.darkGrey,
    fontSize: 14,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: '#000',
    cursor: 'pointer',
  },
  content: {
    marginTop: 8,
  },
  rotated: {
    transform: 'rotate(-180deg)',
  },
}));

export default useStyles;
