const addFavoriteData = (item, favorites) => {
  const isFavorite = favorites ? favorites.includes(item.templateType) : false;

  return {
    ...item,
    isFavorite,
  };
};

export default addFavoriteData;
