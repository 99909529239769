import React from 'react';

import useGetProducts from '@root/resources/bigCommerce/useGetProducts';
import useUpdateProductDescription from '@root/resources/bigCommerce/useUpdateProductDescription';

import PublishToPimModal from '../PublishToPim/PublishToPimModal';

const PublishToBigCommerceModal = ({
  unmount,
  text,
}) => {
  const { mutateAsync: updateProductDescription } = useUpdateProductDescription();

  return (
    <PublishToPimModal
      useGetProducts={useGetProducts}
      updateProductDescription={updateProductDescription}
      title="Publish to BigCommerce"
      unmount={unmount}
      text={text}
      mapProduct={(product) => ({
        id: product.id,
        image: product.images[0]?.url_standard,
        sku: product.id,
        name: product.name,
        price: product.price,
      })}
    />
  );
};

export default PublishToBigCommerceModal;
