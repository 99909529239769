import { useQuery } from 'react-query';

import api from '@root/api';

export default function useAiTemplate(id) {
  const queryKey = ['aiTemplates.getById', id];

  return useQuery(
    queryKey,
    () => api.aiTemplate.getById(id),
    { keepPreviousData: true },
  );
}
