import React from 'react';
import {
  Input,
  TouchApp,
  BubbleChart,
  Palette,
  TextFields,
  EmojiEmotions,
  Create,
  // Toc,
  // SpaceBar,
} from '@material-ui/icons';

import iconLogo from '@root/assets/copysmith-logo-25.png';

import {
  Buttons,
  Fields,
  Atoms,
  Colors,
  Text,
  Logo,
  Brand,
  Graphic,
} from './variants';

export default [
  {
    name: 'Copysmith ✍️🤖',
    description: "This page is home to Copysmith's style guide. A centralized hub for all things visual — from the colors on our landing page, to the very components we use in our templates.",
    properties: [
      {
        id: 'home',
        name: '',
        iconImg: <img
          src={iconLogo}
          alt="logo"
          width="26"
        />,
        grid: 12,
        variants: Brand,
      },
    ],
  },
  {
    name: 'Style Guide',
    description: "Consistency in styling goes along way in ensuring that we can maintain the same 'Copysmith' feel across all our brand.",
    properties: [
      {
        id: 'colors',
        name: 'Colors',
        icon: Palette,
        grid: 12,
        variants: Colors,
      },
      {
        id: 'typography',
        name: 'Typography',
        icon: TextFields,
        grid: 6,
        variants: Text,
      },
      // {
      //   id: 'dimensions',
      //   name: 'Dimensions',
      //   icon: SpaceBar,
      // },
    ],
  },
  {
    name: 'Graphics',
    description: 'Copysmith is represented by a stylized circle divided into quadrants. We choose to use minimalistic vector illustrations to keep our interface clean and inviting.',
    properties: [
      {
        id: 'logo',
        name: 'Logo',
        icon: EmojiEmotions,
        grid: 6,
        variants: Logo,
      },
      {
        id: 'illustrations',
        name: 'Illustrations',
        icon: Create,
        grid: 12,
        variants: Graphic,
      },
    ],
  },
  {
    name: 'Components',
    description: "From these text fields and buttons to atoms and tables, these components  are the building blocks that help shape Copysmith's identity.",
    properties: [
      {
        id: 'forms',
        name: 'Forms',
        icon: Input,
        grid: 6,
        variants: Fields,
      },
      {
        id: 'buttons',
        name: 'Buttons',
        icon: TouchApp,
        grid: 3,
        variants: Buttons,
      },
      {
        id: 'atoms',
        name: 'Generation Atoms',
        icon: BubbleChart,
        grid: 6,
        variants: Atoms,
      },
      // {
      //   id: 'tables',
      //   name: 'Tables',
      //   icon: Toc,
      //   grid: 2,
      //   variants: Buttons,
      // },
    ],
  },

];
