import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0 8px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const FooterButton = ({
  Icon,
  onClick,
  className,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      disableRipple
      size="small"
      onClick={onClick}
      color="default"
      className={clsx(classes.button, className)}
      disabled={disabled}
    >
      <Icon />
    </IconButton>
  );
};

export default FooterButton;
