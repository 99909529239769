import React from 'react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Collapse } from '@material-ui/core';

import useModalState from '@root/utils/hooks/useModalState';
import Button from '@root/components/buttons/Button';

import useStyles from './CollapsibleSection.styles';

const CollapsibleSection = ({ Icon, buttonText, children, id }) => {
  const classes = useStyles();
  const [isOpen, , , toggle] = useModalState();

  return (
    <div className={classes.collapsibleRoot}>
      <Button
        variant="link"
        color="inherit"
        onClick={toggle}
        className={classes.button}
        id={id}
      >
        <span className={classes.buttonLeft}>
          <Icon className={classes.icon} />
          {buttonText}
        </span>
        {isOpen ? <IconChevronUp className={classes.chevron} /> : <IconChevronDown className={classes.chevron} />}
      </Button>

      <Collapse in={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsibleSection;
