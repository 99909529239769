import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import useInvites from '@root/resources/invite/useInvites';
import useBillingInfo from '@root/resources/billing/useBillingInfo';
import * as formatString from '@root/utils/formatString.util';
import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useModalState from '@root/utils/hooks/useModalState';
import { FEATURES } from '@root/views/Addons/addonsConstants';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';
import { SwitchField } from '@root/components/form';

import TeamPlanUnavailable from './components/TeamPlanUnavailable';
import TeamPlanLoading from './components/TeamPlanLoading';
import MembersList from './components/MembersListRedesign';
import DeactivatedList from './components/DeactivatedList';
import InvitesList from './components/InvitesListRedesign';
import useStyles from './TeamManagement.styles';
import InviteButton from './components/Buttons/InviteButton';

const getTooltipContent = ({ priceId }) => {
  const isGoToStarterPlanContent = [
    ...PLANS_IDS.free,
  ];

  const isGoToProPlanContent = [
    ...PLANS_IDS.ltds,
    ...PLANS_IDS.legacyStarters,
    ...PLANS_IDS.starters,
    ...PLANS_IDS.legacyBasics,
    ...PLANS_IDS.legacyGrowths,
  ];

  if (isGoToStarterPlanContent.includes(priceId)) {
    return (
      <span>
        You&apos;ve already added the maximum team members allowed by your plan type.&nbsp;
        <Link to="/profile/plans">Upgrade to the Starter plan</Link>
        &nbsp;
        to add up to 10 team members.
      </span>
    );
  }

  if (isGoToProPlanContent.includes(priceId)) {
    return (
      <span>
        You&apos;ve already added the maximum team members allowed by your plan type.&nbsp;
        <Link to="/profile/plans">Upgrade to the Professional plan</Link>
        &nbsp;
        to add up to 15 team members.
      </span>
    );
  }

  return (
    <span>
      You&apos;ve already added the maximum team members allowed by your plan type.&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href="https://form.typeform.com/to/mZmkzcNj"
      >
        Contact our team
      </a>
      {' '}
      the discuss custom plan options
    </span>
  );
};

const TeamMembersManagement = () => {
  const classes = useStyles();
  const {
    currentUser: user,
    currentCompany,
  } = AuthContext.useAuth();
  const {
    isFetching: isFetchingMembers,
    data: membersDataAll,
  } = useTeamMembers({ allMembers: true });
  const { isFetching: isFetchingInvites, data: invitesData } = useInvites();
  const {
    data: {
      addons, planTitle, priceId,
    } = { status: null, addons: {} },
  } = useBillingInfo();

  const [addSeatModalOpened, , closeAddSeatModal] = useModalState(false);
  const [isDeactivatedHidden, setDeactivatedHidden] = useState(true);

  if (isFetchingInvites || isFetchingMembers) {
    return <TeamPlanLoading />;
  }

  const hasTeamPermissions = user.permissions.teams;

  if (!hasTeamPermissions) {
    return <TeamPlanUnavailable />;
  }

  const membersData = membersDataAll.results.filter((m) => !m.deactivated);
  const currentMembersCount = membersData.length;

  const isAdminOrOwner = user.roles.admin || user.roles.owner;
  const { limits: { members, maxMembers }, memberPrice } = currentCompany?.billing;
  const totalMembersExceeded = maxMembers !== null && currentMembersCount >= maxMembers;
  const canAddMoreMembers = !totalMembersExceeded;

  const tooltipContent = getTooltipContent({ priceId });

  const newPlansProps = user.featureToggles.newPlans && {
    content: tooltipContent,
    placement: 'bottom',
    interactive: true,
    wrapped: true,
  };

  const additionalSeats = addons.additionalMembers || 0;
  const freeMembersExceeded = currentMembersCount >= members + additionalSeats;

  const addSeat = async () => {
    // eslint-disable-next-line no-console
    console.log('Add team seat');
  };

  const renderAddMembersButton = () => {
    if (canAddMoreMembers) {
      return (
        <InviteButton />
      );
    }

    return (
      <SimpleTooltip
        content={`Cannot invite more than ${members} members.`}
        placement="top"
        // eslint-disable-next-line
        {...newPlansProps}
      >
        <Button
          className={classes.addMemberButton}
          disabled
          size="medium"
        >
          Add Members
        </Button>
      </SimpleTooltip>
    );
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <span className={classes.titleText}>Team Members</span>
        {isAdminOrOwner && renderAddMembersButton()}
      </div>
      <div className={classes.infoRow}>
        <div className={classes.membersText}>
          {user.featureToggles.newPlans && !!additionalSeats
            && `Additional team seats: ${additionalSeats}`}
        </div>
      </div>
      <div className={classes.switchWrap}>
        <SwitchField
          value={!isDeactivatedHidden}
          onChange={() => setDeactivatedHidden(!isDeactivatedHidden)}
          label="Show Inactive Users"
        />
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.tableRoot}>
          <thead>
            <tr>
              <th className={clsx(classes.cell, classes.memberName, classes.headname)}>
                Name
              </th>
              <th className={clsx(classes.cell, classes.memberAccessLevel)}>Role</th>
              <th className={clsx(classes.cell, classes.memberActions)}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <MembersList />
            {!isDeactivatedHidden && (
              <DeactivatedList
                freeMembersExceeded={freeMembersExceeded}
              />
            )}
            <InvitesList
              invites={invitesData.results}
            />
          </tbody>
        </table>
      </div>
      <div className={classes.membersLimitLabel}>
        <p>
          {`${currentMembersCount}/${members} members used.`}
        </p>
        {freeMembersExceeded && memberPrice ? (
          <p>{`Each additional member will cost ${formatString.formatDollarIfInteger(memberPrice, { cents: true })} per month.`}</p>
        ) : (
          <p>Invite your team members to get started!</p>
        )}
      </div>

      {addSeatModalOpened && (
        <ConfirmationModal
          title="Activate Additional Team Seat"
          text={`You've reached the team member limit for the ${planTitle} plan. To add another team member, you will need to activate an extra seat.`}
          confirmButtonText={(
            <>
              Activate for&nbsp;
              <b>{`$${FEATURES.additionalMembers.cost} / month`}</b>
            </>
              )}
          onConfirm={addSeat}
          onClose={closeAddSeatModal}
        />
      )}
    </>
  );
};

export default TeamMembersManagement;
