import { TEMPLATE_STATUSES } from './template.constants';

const customTemplateRegex = /cus/;

const checkIsTemplateCustom = (template) => customTemplateRegex.test(template.templateType);

const checkIsTemplateLive = (template) => template.status === TEMPLATE_STATUSES.LIVE;

const checkIsTemplateSubmitted = (template) => template.status === TEMPLATE_STATUSES.SUBMITTED;

const checkIsTemplateDraft = (template) => template.status === TEMPLATE_STATUSES.DRAFT;

const checkIsTemplateDeleted = (template) => template.status === TEMPLATE_STATUSES.DELETED;

export {
  checkIsTemplateCustom,
  checkIsTemplateLive,
  checkIsTemplateSubmitted,
  checkIsTemplateDraft,
  checkIsTemplateDeleted,
};
