import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import SuccessIcon from './Success.icon';
import useStyles from './Success.styles';

const Success = () => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.replace('/');
  };

  return (
    <div className={classes.successRoot}>
      <SuccessIcon className={classes.icon} />
      <h3 className={classes.title}>
        Thanks for your order!
      </h3>
      <p>You successfully upgraded your Copysmith plan.</p>
      <p>
        We just sent your receipt to your email address,
        and the new features will be available shortly.
      </p>
      <Button
        onClick={goBack}
        className={classes.button}
      >
        Back to Copysmith
      </Button>
    </div>
  );
};

export default Success;
