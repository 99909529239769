import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  aiImageCredits: {
    alignItems: 'center',
    backgroundColor: theme.palette.colors.neutrals50,
    borderRadius: '6px',
    display: 'flex',
    height: '28px',
    padding: '0px 12px',
  },
  aiImageGrid: {
    display: 'grid',
    gap: ({ selectedImagesCount }) => (selectedImagesCount === 1 ? '0px' : '16px'),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  aiImageGridWrapper: {
    margin: 'auto',
    maxWidth: '900px',
    minWidth: '775px',
    padding: '4px 64px',
  },
  aiImageTopBar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
  },
  aiImageWrapper: {
    background: `radial-gradient(${theme.palette.colors.neutrals200} 1px, transparent 1px)`,
    backgroundSize: '10px 10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
  },
  error: {
    alignItems: 'center',
    backgroundColor: `${theme.palette.colors.pink200}33`,
    border: `1px solid ${theme.palette.colors.pink600}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: '160px',
    justifyContent: 'center',
    padding: '32px',
    width: '500px',
  },
  errorContainer: {
    backgroundColor: theme.palette.colors.white,
  },
  errorIcon: {
    color: theme.palette.colors.pink600,
    height: '40px',
    width: '40px',
  },
  errorText: {
    color: theme.palette.colors.pink600,
    textAlign: 'center',
  },
  loading: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'center',
    borderRadius: '6px',
    padding: '24px',
    backgroundColor: theme.palette.colors.white,
  },
  loadingOrError: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  loadingBar: {
    width: '400px',
  },
  noCredits: {
    color: theme.palette.colors.pink600,
    backgroundColor: theme.palette.colors.pink200,
  },
  purchaseAddOn: {
    color: `${theme.palette.colors.pink600} !important`,
    paddingLeft: '12px',
  },
}));

export default useStyles;
