import React from 'react';
import { IconPlus } from '@tabler/icons-react';

import Button from '@root/components/buttons/Button';
import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import { CREATE_MODAL_ID } from '@root/views/Dashboard/components/Modals';
import AuthContext from '@root/resources/auth/auth.context';

import useStyles from './CreateButtonNew.styles';

const CreateButtonNew = ({
  disabled,
}) => {
  const { currentUser } = AuthContext.useAuth();
  const { newFilesPage } = currentUser.featureToggles;

  const classes = useStyles({ newFilesPage });
  const { dispatch: modalsDispatch } = useGlobalModals();

  const handleCreateClick = () => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL, payload: { modalId: CREATE_MODAL_ID },
    });
  };

  return (
    <Button
      startIcon={<IconPlus className={classes.startIcon} />}
      className={classes.createNewButton}
      classes={{ startIcon: classes.startIcon }}
      onClick={handleCreateClick}
      disabled={disabled}
    >
      Create New
    </Button>
  );
};

export default CreateButtonNew;
