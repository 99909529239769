import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import AuthContext from '@root/resources/auth/auth.context';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import useCloneFile from '@root/resources/file/useCloneFile';
import Button from '@root/components/buttons/Button';
import { FormField, InputField } from '@root/components/form';
import Modal from '@root/components/modals/Modal';

import useStyles from './CloneFileModal.styles';

const validationSchema = Yup.object().shape({
  cloneTitle: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, { message: 'The filename cannot contain only spaces' })
    .required('File name is required'),
});

const CloneFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();

  const { state: { modalContent } } = useGlobalModals();
  const { mutateAsync: cloneFile } = useCloneFile();

  const originalTitle = modalContent?.file?.title;
  const fileId = modalContent?.file?._id;

  const handleSubmit = async (values, actions) => {
    const { cloneTitle } = values;
    actions.setSubmitting(true);
    await cloneFile({ fileId, cloneTitle, user: currentUser });
    closeModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      title={`Cloning file '${originalTitle}`}
      classNames={{ paper: classes.paper }}
    >
      <Formik
        initialValues={{ cloneTitle: `${originalTitle} (Copy)` }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <Form>
            <FormField
              name="cloneTitle"
              component={InputField}
              label="New name"
              required
            />

            <div className={classes.buttonsContainer}>
              <Button variant="text" onClick={closeModal}>
                Discard
              </Button>

              <Button
                type="submit"
                className={classes.confirmButton}
                disabled={!isValid}
              >
                Confirm
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CloneFileModal;
