import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useReadNotification() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ notificationId }) => api.notification.readNotification({ id: notificationId }),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries(['notification.listNotifications']);
      },
    },
  );
}
