import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from '@root/components/buttons/Button';
import { InputField, CheckboxField, FormField } from '@root/components/form';
import useCreateFolder from '@root/resources/folder/useCreateFolder';
import { actions, useDashboard } from '@root/views/Dashboard/dashboardContext';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

import PageEnum from '../NewFolderModal/PageEnumFolder';

const INITIAL_VALUES = {
  title: '',
  addPresets: true,
};

const validationSchema = yup.object({
  title: yup
    .string()
    .min(1, 'Folder name is required')
    .max(100, 'Folder name cannot exceed 100 characters')
    .required('Folder name is required'),
  addPresets: yup
    .bool(),
});

const NewProjectForm = ({
  closeModal, classes, setFolderName, setPage,
}) => {
  const { mutateAsync: createFolder, isLoading } = useCreateFolder();
  const { dispatch } = useDashboard();
  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const submit = async (values, formActions) => {
    const { title, addPresets } = values;

    if (addPresets) {
      setFolderName(title);
      setPage(PageEnum.PRESETS);
    } else {
      formActions.setSubmitting(isLoading);
      const newProject = await createFolder({ title });

      refetchOnboarding('createProject');

      dispatch({
        type: actions.SELECT_FOLDER,
        payload: {
          folderId: newProject._id,
        },
      });

      closeModal();
      eventsAggregator.publish(EVENT_NAMES.CAMPAIGN_CREATED);
    }
  };

  return (
    <div className={classes.newFolderFormRoot}>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <FormField
              name="title"
              component={InputField}
              label="Project name"
              placeholder="e.g. Candlesmith 2021"
              required
              classNames={{ root: classes.input }}
            />
            <FormField
              component={CheckboxField}
              name="addPresets"
              label="Add project settings"
              labelTooltip="Apply settings to files inside the project."
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
            />
            <div className={classes.buttonsContainer}>
              <Button
                type="submit"
                disabled={!values.title}
              >
                { values.addPresets ? 'Next' : 'Create' }
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewProjectForm;
