import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useRemovePersonalization() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.ai.removePersonalizationRule(data),
    {
      onSuccess: (response, vars) => {
        if (!response || response.isBadRequest) {
          return;
        }

        const queryKey = ['personalizationRules.list'];
        const prevRules = queryClient.getQueryData(queryKey) || [];

        queryClient.setQueryData(queryKey, () => prevRules.filter((r) => r._id !== vars.id));
      },
    },
  );
}
