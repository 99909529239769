import { HttpClient } from "../HttpClient";
import { RequestOptions } from "../RequestOptions";
import { HandlingOptions } from "../HandlingOptions";

export interface GenerateBulkOptions extends RequestOptions {
  fileId: string;
}

export interface GenerateBulkPCMOptions extends RequestOptions {
  catalogId: string;
  catalogItemIds: string[];
}

export interface GetFailedRowsOptions extends RequestOptions {
  id: string;
}
export interface GetOptions extends RequestOptions {
  id: string;
}
export interface GetCSVOptions extends RequestOptions {
  id: string;
}

export interface SampleFileOptions extends RequestOptions {
  templateType: string;
}

export interface CancelJobOptions extends RequestOptions {
  id: string;
}

export interface GetRows extends RequestOptions {
  id: string;
}

export interface UpdateRowResultsOptions extends RequestOptions {
  id: string;
  rowId: string;
  contents: { documentId: string; content: string }[];
}

export class BulkGeneration {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public uploadFile(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/bulkGeneration/file", data, options);
  }

  public validateMapping(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/bulkGeneration/mapping/validate", data, options);
  }

  public generateBulk(data: GenerateBulkOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/bulkGeneration", data, options);
  }

  public generatePCMBulk(data: GenerateBulkPCMOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post("/bulkGeneration/pcm", data, options);
  }

  public list(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/bulkGeneration", data, options);
  }

  public getFailedRows({ id, ...data }: GetFailedRowsOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/bulkGeneration/${id}/failedRows`, data, options);
  } 

  public getById({ id, ...data }: GetOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/bulkGeneration/${id}`, data, options);
  }

  public getSampleFile({ templateType, ...data }: SampleFileOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/bulkGeneration/${templateType}/sampleFile`, data, options);
  }

  public getBulkCSV({ id, ...data }: GetCSVOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/bulkGeneration/${id}/csv`, data, options);
  }

  public cancelJob({ id, ...data }: CancelJobOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.post(`/bulkGeneration/${id}/cancel`, data, options);
  }

  public getCurrentJob(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/bulkGeneration/current", data, options);
  }

  public getRows({ id, ...data }: GetRows, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get(`/bulkGeneration/${id}/rows`, data, options);
  }

  public updateRowResults({ id, rowId, ...data }: UpdateRowResultsOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.put(`/bulkGeneration/${id}/rows/${rowId}`, data, options);
  }

  public getCurrentPCMJob(data: RequestOptions, options?: HandlingOptions): Promise<Record<string, unknown>> {
    return this.client.get("/bulkGeneration/currentPCM", data, options);
  }
}
