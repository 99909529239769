import React from 'react';

import { logOutUser } from '@root/services/auth.service';

const LogOut = () => {
  logOutUser({ redirect: '/' });

  return <div />;
};

export default LogOut;
