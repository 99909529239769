import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import documentResource from '@root/resources/document';

import UpVoteSignupModal from './components/UpVoteSignupModal';

const useStyles = makeStyles((theme) => ({
  countArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  votingArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: '0.5',
  },
  icon: {
    marginRight: '0.3rem',
    cursor: 'pointer',
    zIndex: 10,
  },
  iconNotVoted: {
    color: 'lightgrey',
    marginRight: '0.3rem',
    cursor: 'pointer',
    zIndex: 10,
    '&:hover': {
      color: 'lightblue',
    },
  },
  iconUpVoted: {
    color: theme.palette.colors.blue,
  },
  iconUpContainer: {
    color: theme.palette.colors.blue,
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  iconDownVoted: {
    color: theme.palette.colors.lightRed,
  },
  iconDownContainer: {
    color: theme.palette.colors.lightRed,
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
}));

const computeVoteCounts = (votes, currentUserId) => {
  const value = {
    ups: 0,
    downs: 0,
    didUpVote: false,
    didDownVote: false,
  };
  if (!votes) {
    return value;
  }
  Object.keys(votes).forEach((userId) => {
    const voteValue = votes[userId];
    if (userId === currentUserId) {
      value.didUpVote = voteValue === 1;
      value.didDownVote = voteValue === -1;
    }
    if (voteValue === 1) {
      value.ups += 1;
    } else if (voteValue === -1) {
      value.downs += 1;
    }
  });

  return value;
};

const UpVotes = ({
  userId,
  document: {
    _id: documentId,
    votes: initialVotes,
    fileId,
  },
}) => {
  const classes = useStyles();
  const computedVotes = computeVoteCounts(initialVotes, userId);
  const { mutateAsync: vote } = documentResource.useVoteDocument();
  const [modalOpen, setModalOpen] = useState(false);

  const onUpVote = async () => {
    if (!userId) {
      setModalOpen(true);
      return;
    }

    await vote({
      _id: documentId,
      vote: computedVotes.didUpVote ? 0 : 1,
      fileId,
    });
  };

  const onDownVote = async () => {
    if (!userId) {
      setModalOpen(true);
      return;
    }

    await vote({
      _id: documentId,
      vote: computedVotes.didDownVote ? 0 : -1,
      fileId,
    });
  };

  // console.dir(JSON.stringify(computedVotes));

  return (
    <>
      <UpVoteSignupModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <div className={classes.votingArea}>
        <div className={classes.countArea}>
          <FontAwesomeIcon
            icon="arrow-up"
            className={clsx(classes.icon, {
              [classes.iconUpVoted]: computedVotes.didUpVote,
              [classes.iconNotVoted]: !computedVotes.didUpVote,
            })}
            onClick={onUpVote}
          />
          <div className={classes.iconUpContainer}>
            {computedVotes.ups}
          </div>
        </div>
        <div className={classes.countArea}>
          <FontAwesomeIcon
            icon="arrow-down"
            className={clsx(classes.icon, {
              [classes.iconDownVoted]: computedVotes.didDownVote,
              [classes.iconNotVoted]: !computedVotes.didDownVote,
            })}
            onClick={onDownVote}
          />
          <div className={classes.iconDownContainer}>
            {computedVotes.downs}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpVotes;
