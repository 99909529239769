import React from 'react';

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33325 7.33333C6.96506 7.33333 6.66659 7.63181 6.66659 8V16C6.66659 16.3682 6.96506 16.6667 7.33325 16.6667H16.6666C17.0348 16.6667 17.3333 16.3682 17.3333 16V8C17.3333 7.63181 17.0348 7.33333 16.6666 7.33333H7.33325ZM5.33325 8C5.33325 6.89543 6.22868 6 7.33325 6H16.6666C17.7712 6 18.6666 6.89543 18.6666 8V16C18.6666 17.1046 17.7712 18 16.6666 18H7.33325C6.22868 18 5.33325 17.1046 5.33325 16V8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.33332C8 8.96513 8.29848 8.66666 8.66667 8.66666H15.3333C15.7015 8.66666 16 8.96513 16 9.33332C16 9.70151 15.7015 9.99999 15.3333 9.99999H8.66667C8.29848 9.99999 8 9.70151 8 9.33332Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C8 11.6318 8.29848 11.3333 8.66667 11.3333H15.3333C15.7015 11.3333 16 11.6318 16 12C16 12.3682 15.7015 12.6667 15.3333 12.6667H8.66667C8.29848 12.6667 8 12.3682 8 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.6667C8 14.2985 8.29848 14 8.66667 14H15.3333C15.7015 14 16 14.2985 16 14.6667C16 15.0349 15.7015 15.3333 15.3333 15.3333H8.66667C8.29848 15.3333 8 15.0349 8 14.6667Z"
      />
    </svg>

  );
};
