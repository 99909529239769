import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ObjectUtil } from '@copysmith/utils';

import { useGlobalModals, actions as modalsActions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';
import CreateOption from '@root/views/Dashboard/components/Modals/CreateModal/CreateOption';
import { useDashboard } from '@root/views/Dashboard/dashboardContext';
import { USE_CASE_MODAL_ID } from '@root/views/TemplatesDashboardNew/UseCaseModal';
import AuthContext from '@root/resources/auth/auth.context';
import useFolder from '@root/resources/folder/useFolder';
import uiNotificationService from '@root/services/uiNotification.service';
import { NOTIFICATION_MODAL_ID } from '@root/views/Dashboard/components/Modals/Notification';
import useFolders from '@root/resources/folder/useFolders';
import { redirectToNewEditor } from '@root/resources/file/file.helpers';
import useCreateFile from '@root/resources/file/useCreateFile';
import useLimits from '@root/resources/billing/useLimits';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import useIntegrations from '@root/resources/integrations/useIntegrations';

import {
  EditorIllustrationUrl,
  UseCaseIllustrationUrl,
  BulkCopyIllustrationUrl,
  EditorIllustrationDisabledUrl,
  UseCaseIllustrationDisabledUrl,
} from './illustrations';
import useStyles from './CreateModal.styles';

const { insertInArrayIf: insertIf } = ObjectUtil;

export const CREATE_MODAL_ID = 'CREATE_MODAL_ID';

const CreateModal = ({ closeModal, modalOpen }) => {
  const history = useHistory();
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const { dispatch: modalsDispatch } = useGlobalModals();
  const { state: { filters: { folderId } } } = useDashboard();
  const { mutateAsync: createFile } = useCreateFile();
  const { data: folder } = useFolder(folderId);
  const { data: folders } = useFolders();
  const { data: limits = { maxBulkGenerationRows: 0 } } = useLimits();
  const { data: integrations = [] } = useIntegrations();

  const akeneoIntegration = integrations.find((integration) => integration.type === 'akeneo' && integration.status === 'active');

  const { toggles: { disableEditor }, availableCreateModalOptions } = currentExternalSource;
  const isCreateFileAllowed = useMemo(() => {
    const userFolderRoles = currentUser.roles.getFolderRoles(folder);
    return !folder || userFolderRoles.editor || userFolderRoles.owner;
  }, [folder, currentUser]);

  const selectedFolder = folderId && folders?.find((f) => f.id === folderId);
  const isSharableFolder = selectedFolder?.unifiedAcl?.length > 1;

  const handleCreateEditorClick = async () => {
    await redirectToNewEditor(createFile, { folderId }, history);
  };

  const handleCreateUseCaseClick = () => {
    if (isCreateFileAllowed) {
      modalsDispatch({
        type: modalsActions.CLOSE_MODAL, payload: { modalId: CREATE_MODAL_ID },
      });
      modalsDispatch({
        type: modalsActions.OPEN_MODAL, payload: { modalId: USE_CASE_MODAL_ID },
      });
    } else {
      uiNotificationService.showWarnMessage('not allowed');
    }
  };

  const handleCreateBulkCopyClick = () => {
    history.push('/bulk-copy/new');
  };

  const handleCreateBigCommerceBulkCopyClick = () => {
    history.push('/bigCommerce-bulk-copy/new');
  };

  const createAkeneoBulkCopyClick = () => {
    history.push('/akeneo-bulk-copy/new');
  };

  const openSharingNotificationModal = (onSubmit) => {
    modalsDispatch({
      type: modalsActions.OPEN_MODAL,
      payload: {
        modalId: NOTIFICATION_MODAL_ID,
        modalContent: {
          title: 'New shared file',
          description: 'You are about to create a new file in a shared folder. This means that it can be accessed by everyone within this folder.',
          onSubmit,
        },
      },
    });
  };

  const CREATE_OPTIONS = useMemo(() => ([
    ...insertIf(availableCreateModalOptions.includes('editor'), {
      title: 'Editor',
      description: 'Our free-form editor lets you generate content with our speciality use cases, insert images, generate AI images, optimize your content for SEO, and write/edit your own copy for professional-quality content creation all within one document. ',
      illustrationUrl: EditorIllustrationUrl,
      disabledIllustrationUrl: EditorIllustrationDisabledUrl,
      onClick: isSharableFolder
        ? () => openSharingNotificationModal(handleCreateEditorClick)
        : handleCreateEditorClick,
      disabled: !isCreateFileAllowed || disableEditor,
      disabledByHootsuite: disableEditor,
    }),
    ...insertIf(availableCreateModalOptions.includes('useCase'), {
      title: 'Use Case',
      description: "A use case is a pre-designed scenario that helps you quickly create content from templates. It's like a blueprint for your content creation process, saving you time and ensuring high-quality results.",
      illustrationUrl: UseCaseIllustrationUrl,
      disabledIllustrationUrl: UseCaseIllustrationDisabledUrl,
      onClick: isSharableFolder
        ? () => openSharingNotificationModal(handleCreateUseCaseClick)
        : handleCreateUseCaseClick,
      disabled: !isCreateFileAllowed,
    }),
    ...insertIf(availableCreateModalOptions.includes('bulkCopy'), {
      title: 'Bulk Copy',
      description: 'With a click of a button, create thousands of amazing product descriptions, online ads, social media content, and more!',
      illustrationUrl: BulkCopyIllustrationUrl,
      onClick: handleCreateBulkCopyClick,
      canBeDisabled: false,
    }),
    ...insertIf(availableCreateModalOptions.includes('bulkCopyBigCommerce'), {
      title: 'Bulk Copy',
      description: 'With a click of a button, create thousands of amazing product descriptions, online ads, social media content, and more!',
      illustrationUrl: BulkCopyIllustrationUrl,
      disabledIllustrationUrl: BulkCopyIllustrationUrl,
      onClick: handleCreateBigCommerceBulkCopyClick,
      disabled: limits.maxBulkGenerationRows === 0,
    }),
    ...insertIf(availableCreateModalOptions.includes('bulkCopyAkeneo') && akeneoIntegration, {
      title: 'Bulk Copy for Akeneo',
      description: 'Generate your product descriptions with just one click! Generate compelling content for multiple products in Akeneo and take your marketing to the next level!',
      illustrationUrl: BulkCopyIllustrationUrl,
      disabledIllustrationUrl: BulkCopyIllustrationUrl,
      onClick: createAkeneoBulkCopyClick,
      disabled: limits.maxBulkGenerationRows === 0,
    }),
  ]), [isSharableFolder, openSharingNotificationModal, handleCreateEditorClick]);

  React.useEffect(() => {
    if (modalOpen && currentUser.featureToggles.useCaseModalInstantly) {
      handleCreateUseCaseClick();
    }
  }, [currentUser, modalOpen]);

  if (modalOpen && currentUser.featureToggles.useCaseModalInstantly) {
    return null;
  }

  return (
    <Modal
      title="Choose an option to create"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={closeModal}
      closeAfterTransition
      classNames={{ paper: CREATE_OPTIONS.length === 4 ? classes.modalPaperLong : classes.modalPaper }}
    >
      <div className={classes.optionsContainer}>
        {CREATE_OPTIONS.map((option) => (
          <CreateOption
            key={option.title}
            title={option.title}
            description={option.description}
            onClick={option.onClick}
            illustrationUrl={option.illustrationUrl}
            disabledIllustrationUrl={option.disabledIllustrationUrl}
            isDisabled={option.disabled}
            disabledByHootsuite={option.disabledByHootsuite}
          />
        ))}
      </div>
    </Modal>
  );
};

export default CreateModal;
