import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawerCloseContainer: {
    position: 'absolute',
    borderBottom: `1px solid ${theme.palette.colors.grey}`,
    top: '0',
    height: '50px',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 2,
  },
  examplesWrap: {
    fontWeight: 600,
    color: 'black',
    cursor: 'pointer',
  },
  sectionContainer: {
    margin: '2rem 0',
  },
  extraMarginBottom: { marginBottom: '4rem' },
  container: {
    overflowY: 'scroll',
    height: 'calc(100% - 50px)',
    marginTop: '50px',
    padding: '0 30px 30px 30px',
  },
  outputContainer: {
    pointerEvents: 'none',
  },
  tableHeader: {
    fontSize: '0.8rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#777',
    height: '100%',
    verticalAlign: 'top',
  },
}));

export default useStyles;
