import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    gap: '1rem',
    alignItems: 'stretch',
  },
});

export default useStyles;
