import React, { useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import authContext from '@root/resources/auth/auth.context';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import api from '@root/api';
import hubspotService from '@root/services/hubspot.service';
import billingResource from '@root/resources/billing';
import SubscriptionsContext from '@root/resources/billing/subscriptions.context';
import TemplatesContext from '@root/resources/templates/templates.context';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import { PLANS_IDS, UNLIMITED_CREDITS_RANKS } from '@root/resources/billing/billingInfo.helpers';
import UpgradeModal from '@root/components/modals/UpgradeModal';
import ExternalSourceText from '@root/components/integrations/ExternalSource/ExternalSourceText';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { CANCEL_SUBSCRIPTION_MODAL_ID } from '@root/views/Dashboard/components/Modals/CancelSubscriptionModal';
import { useGlobalModals } from '@root/views/Layout/modalsContext';

const getButtonContent = (plan, currentPlan) => {
  if (currentPlan.rank === plan.rank && currentPlan.tiersSupported) {
    return plan.tier >= currentPlan.tier ? 'Upgrade' : 'Downgrade';
  }

  if (currentPlan.rank === plan.rank) {
    return `${currentPlan.period === 'monthly' ? 'Upgrade' : 'Downgrade'}`;
  }

  return plan.rank >= currentPlan.rank ? 'Upgrade' : 'Downgrade';
};

const PlanButton = ({ plan, ...props }) => {
  const history = useHistory();

  const { currentUser, currentCompany: company } = authContext.useAuth();
  const {
    setRenewSuspendedAccountModalOpen,
    setRenewModalOpen,
    currentPlan,
  } = SubscriptionsContext.useSubscriptions();
  const { onOpenModal } = useGlobalModals();
  const { templatesList } = TemplatesContext.useTemplates();

  const { data: membersData, isFetching: isMembersFetching } = useTeamMembers();
  const {
    data: userBillingInfo, isFetching: isBillingFetching, refetch: refetchBillingInfo,
  } = billingResource.useBillingInfo();

  const [modalPayload, setModalPayload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = !currentUser.roles.owner
  || isMembersFetching
  || isBillingFetching
  || isLoading
  || company.billing.limits.mode === 'enterpriseFreeTrialLimit';

  const currentMembersCount = membersData?.count;
  const customTemplatesCount = templatesList
    .filter(({ deletedOn, templateType }) => !deletedOn && templateType.startsWith('custom_'))
    .length;

  const march2023StarterOrPro = [
    ...PLANS_IDS.march2023StarterMonthly,
    ...PLANS_IDS.march2023StarterAnnual,
    ...PLANS_IDS.march2023Pros,
  ];

  const march2023Unlimited = [
    ...PLANS_IDS.march2023StarterAnnual,
    ...PLANS_IDS.march2023Pros,
  ];

  const isMigratingFromLegacyPlanToNewPlan = !march2023StarterOrPro.includes(currentPlan._id);

  const hasPrice = !!plan.amount;
  const isExactPlan = currentPlan._id === plan._id;
  const isStarter = PLANS_IDS.starters.includes(plan._id);
  const isSelfServeEnterprise = PLANS_IDS.selfServeEnterprises.includes(plan._id);
  const isLTD = PLANS_IDS.ltds.includes(currentPlan._id) && !currentPlan.productId;
  const isCurrentEnterprise = currentPlan.rank === 500;
  const isCurrentSelfServeEnterprise = currentPlan.rank === 400;
  const canRenew = userBillingInfo?.cancelAtPeriodEnd;
  const isSuspended = company.suspendStart;
  const isFreeSeatsTaken = currentMembersCount > plan.data.limits.members
  && (!isMigratingFromLegacyPlanToNewPlan || plan.rank < currentPlan.rank);

  const maxCustomTemplatesPerPlan = plan.data.limits.maxCustomTemplates;
  const isNumberOfCustomTemplatesTaken = customTemplatesCount > maxCustomTemplatesPerPlan;

  const shouldRemoveUnlimited = isStarter
    && company.billing.addons?.unlimitedCredits?.quantity > 0;

  const actionsRequired = [
    shouldRemoveUnlimited,
    isFreeSeatsTaken,
    isNumberOfCustomTemplatesTaken,
  ].some((condition) => condition);

  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();
  const {
    showExternalSourceTooltip,
    disableCancelSubscription,
    openBillingLinksInNewTab,
  } = currentExternalSource.toggles;

  const noPlan = !currentPlan || currentPlan.service || isLTD;

  const goToSelfServeEnterprisePage = () => {
    history.push({
      pathname: '/profile/plans/enterprise',
      state: {
        plan,
      },
    });
  };

  const handleContactUs = () => {
    window.open('https://info.copysmith.ai/enterprise-schedule-a-demo-form');
  };

  const openUpgradeModal = () => {
    setModalPayload({
      freeSeats: plan.data.limits.members,
      plan,
      shouldRemoveUnlimited,
      shouldRemoveCustomTemplates: isNumberOfCustomTemplatesTaken,
      customTemplatesCount,
      maxCustomTemplatesPerPlan,
      showRolloverUnavailableAlert: plan.rank < 400
      && !plan.rolloverSupported
      && currentPlan.rolloverSupported
      && PLANS_IDS.march2023StarterMonthly.includes(plan?._id),
      showUnlimitedCreditsUnavailableAlert: UNLIMITED_CREDITS_RANKS.includes(currentPlan.rank)
        && !UNLIMITED_CREDITS_RANKS.includes(plan.rank),
      doNotPromptForRemoveSeats: isMigratingFromLegacyPlanToNewPlan && plan.rank > currentPlan.rank,
      isUnlimited: march2023Unlimited.includes(plan?._id),
    });
  };

  const closeUpgradeModal = () => {
    setModalPayload(null);
  };

  const handleChangePlan = () => {
    if (plan.rank === 400) { // self-serve enterprise
      goToSelfServeEnterprisePage();
    } else {
      openUpgradeModal();
    }
  };

  const goToCheckout = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (currentUser) {
      let papCookie;

      if (window.PostAffTracker) {
        papCookie = window.PostAffTracker._getAccountId()
            + window.PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue();
      }
      if (!company.billing.customerId) {
        await api.billing.createStripeCustomer({ papCookie });
        refetchBillingInfo();
      }

      history.push('/checkout', { plan });

      setIsLoading(false);
      return;
    }

    history.push('/signup');
    setIsLoading(false);
  };

  const handleGetPlan = () => {
    if (plan.rank === 400) { // self-serve enterprise
      goToSelfServeEnterprisePage();
      return;
    }

    goToCheckout();
  };

  const handleCancelClick = () => onOpenModal(CANCEL_SUBSCRIPTION_MODAL_ID);

  const getHootsuiteText = useCallback(({ link }) => (
    <>
      Please open your
      {' '}
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        Copysmith web app
      </a>
      {' '}
      to manage your subscription.
    </>
  ), []);

  const renderButton = () => {
    if (!hasPrice) {
      return (
        <Button
          disabled={isDisabled}
          onClick={handleContactUs}
          {...props}
        >
          Contact Us
        </Button>
      );
    }

    if (isCurrentSelfServeEnterprise || isCurrentEnterprise) {
      return (
        <Button
          disabled={isDisabled}
          onClick={hubspotService.show}
          {...props}
        >
          Contact Us
        </Button>
      );
    }

    if (isExactPlan) {
      if (isSuspended) {
        return (
          <Button
            onClick={() => setRenewSuspendedAccountModalOpen(true)}
            disabled={isDisabled}
            {...props}
          >
            Reactivate
          </Button>
        );
      }
      if (canRenew) {
        return (
          <Button
            onClick={() => setRenewModalOpen(true)}
            disabled={isDisabled}
            {...props}
          >
            Renew
          </Button>
        );
      }
      return (
        <Button
          onClick={handleCancelClick}
          variant="outlined"
          disabled={isDisabled || disableCancelSubscription}
          {...props}
        >
          Cancel
        </Button>
      );
    }

    if (noPlan) {
      if (openBillingLinksInNewTab) {
        return (
          <Link
            to={{ pathname: '/profile/plans' }}
            target="_blank"
          >
            <Button
              {...props}
            >
              {isSelfServeEnterprise ? 'Purchase Enterprise Now' : `Get ${plan.name}`}
            </Button>
          </Link>
        );
      }

      return (
        <Button
          onClick={handleGetPlan}
          disabled={isDisabled}
          {...props}
        >
          {isSelfServeEnterprise ? 'Purchase Enterprise Now' : `Get ${plan.name}`}
        </Button>
      );
    }

    if (!isSuspended) {
      return (
        <Button
          onClick={handleChangePlan}
          disabled={isDisabled}
          {...props}
        >
          {getButtonContent(plan, currentPlan)}
        </Button>
      );
    }

    return null;
  };

  const showTooltip = showExternalSourceTooltip && !(noPlan && openBillingLinksInNewTab);

  return (
    <>
      {showTooltip ? (
        <SimpleTooltip
          content={<ExternalSourceText getText={getHootsuiteText} />}
          interactive
        >
          <div>{renderButton()}</div>
        </SimpleTooltip>
      )
        : renderButton()}
      {!!modalPayload && (
        <UpgradeModal
          onClose={closeUpgradeModal}
          showFirstStep={actionsRequired}
          currentPlan={currentPlan}
          {...modalPayload}
        />
      )}
    </>
  );
};

export default PlanButton;
