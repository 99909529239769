import React from 'react';
import { IconTools } from '@tabler/icons-react';

import { FormField, SelectField, SliderField } from '@root/components/form';
import { getFineTunesOptions } from '@root/views/FineTunesEngines/helpers';
import authContext from '@root/resources/auth/auth.context';
import { templates } from '@root/views/Editor/Editor.constants';

import CollapsibleSection from '../CollapsibleSection';

import useStyles from './ExtraSettings.styles';

const ExtraSettings = ({ template, disabled }) => {
  const { aiOptions: defaultValues, templateType } = template;
  const templateConfig = templates[templateType] || {};

  const classes = useStyles();
  const { currentUser } = authContext.useAuth();
  const aliEngines = getFineTunesOptions(currentUser);

  if (!defaultValues) {
    return null;
  }

  const maxTokensProps = {
    min: defaultValues.max_tokens * 0.5,
    max: defaultValues.max_tokens * 1.25,
    step: defaultValues.max_tokens * 0.25,
  };

  const aliOptions = [
    { value: 'default', label: 'None' },
    ...aliEngines,
  ];

  if (!templateConfig.aliAvailable && !templateConfig.maxLengthSettingAvailable) {
    return null;
  }

  return (
    <CollapsibleSection
      Icon={IconTools}
      buttonText="Extra settings"
      id="button-extra"
    >
      <div className={classes.content}>
        {templateConfig.aliAvailable && (
          <FormField
            component={SelectField}
            label="Ali's Expert Voice"
            name="engine"
            disabled={disabled}
            options={aliOptions}
            id="select-ali"
          />
        )}
        {templateConfig.maxLengthSettingAvailable && (
          <FormField
            valueLabelDisplay="auto"
            component={SliderField}
            disabled={disabled}
            label="Max content length"
            name="aiOptions.max_tokens"
            min={maxTokensProps.min}
            max={maxTokensProps.max}
            step={maxTokensProps.step}
            valueLabelFormat={(value) => `${Math.ceil(value * 5)}`}
            marks={[
              { value: maxTokensProps.min, label: 'Very short' },
              { value: maxTokensProps.max, label: 'Long' },
            ]}
            id="slider-aiOptions.max_tokens"
          />
        )}
      </div>
    </CollapsibleSection>
  );
};

export default ExtraSettings;
