import React from 'react';
import clsx from 'clsx';
import { ArrowDropDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropdownIndicatorFocused: {
    transform: 'rotate(180deg)',
  },
  dropdownIndicator: {
    transition: 'transform 500ms',
  },
}));

const CustomDropdownIndicator = (props) => {
  const classes = useStyles();
  const { selectProps } = props;
  return (
    <ArrowDropDown
      className={clsx(
        classes.dropdownIndicator,
        { [classes.dropdownIndicatorFocused]: selectProps.menuIsOpen },
      )}
    />
  );
};

export default CustomDropdownIndicator;
