import React from 'react';

const useCheckedDocuments = (filteredDocuments) => {
  const [checkedDocumentsIds, setCheckedDocumentsIds] = React.useState([]);

  const toggleCheckState = (docId) => () => {
    if (checkedDocumentsIds.includes(docId)) {
      setCheckedDocumentsIds(checkedDocumentsIds.filter((c) => c !== docId));
    } else {
      setCheckedDocumentsIds([...checkedDocumentsIds, docId]);
    }
  };

  const selectAll = React.useCallback(() => {
    setCheckedDocumentsIds(filteredDocuments.map((d) => d.id));
  }, [filteredDocuments]);

  const deselectAll = () => {
    setCheckedDocumentsIds([]);
  };

  const isAllSelected = filteredDocuments.length > 0
  && filteredDocuments.length === checkedDocumentsIds.length;

  return {
    selectAll,
    deselectAll,
    toggleCheckState,
    checkedDocumentsIds,
    isAllSelected,
  };
};

export default useCheckedDocuments;
