import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import useFolders from '@root/resources/folder/useFolders';
import { useDashboard, actions, selectionTypes } from '@root/views/Dashboard/dashboardContext';
import { homeFolder } from '@root/views/Dashboard/helpers';
import AuthContext from '@root/resources/auth/auth.context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    cursor: 'pointer',
    overflow: 'hidden',
    marginBottom: '0.25rem',
    alignItems: 'center',
    padding: '3px',
    height: '2rem',
  },
  newSpecialRoot: {
    padding: '12px',
    height: '40px',
    borderRadius: '4px',
  },
  newUsualRoot: {
    marginLeft: '5px',
  },
  newRootActive: {
    backgroundColor: theme.palette.colors.primaryFaded,
  },
  active: {
    '&&': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
  folderTitle: {
    fontSize: '0.875rem',
    color: '#000',
    flexGrow: '1',
    '&:hover': {
      color: '#5b5b5b',
    },
  },
  newFolderTitle: {
    fontWeight: 500,
    fontSize: '14px',
  },
  icon: {
    marginRight: '0.8rem',
    fontSize: '1.3rem',
    color: theme.palette.colors.darkGrey,
  },
  newIcon: {
    color: theme.palette.colors.black,
  },
  newEnlargedIcon: {
    fontSize: '1.5rem',
  },
  iconContainer: {
    display: 'flex',
  },
  rotateRight: {
    transform: 'rotate(-90deg)',
  },
  transition: {
    transition: 'transform 0.4s',
  },
  hidden: {
    display: 'none',
  },
  noPadding: {
    padding: '0',
  },
}));

const SpecialListItem = ({
  itemType,
  title,
  Icon,
  rotatableIcon,
  noPadding = false,
  additionalPayload,
  smallIcon,
  children,
}) => {
  const { data: folders } = useFolders();
  const {
    state: {
      selected: { type: selectedType },
    }, dispatch,
  } = useDashboard();

  const { currentUser } = AuthContext.useAuth();
  const newDesign = currentUser.featureToggles.dashboardAIRedesign2;

  const classes = useStyles();

  const isActive = itemType === selectedType;

  const handleSelectFolder = () => {
    switch (itemType) {
      case selectionTypes.BOOKMARKS:
        return dispatch({
          type: actions.SELECT_BOOKMARKS,
        });
      case selectionTypes.WORKFLOW:
        return dispatch({
          type: actions.SELECT_WORKFLOW,
          payload: additionalPayload,
        });
      case selectionTypes.ALL_FILES:
        return dispatch({
          type: actions.SELECT_ALL_FILES,
        });
      case selectionTypes.UNCATEGORIZED:
        return dispatch({
          type: actions.SELECT_UNCATEGORIZED,
          payload: {
            homeFolderId: homeFolder(folders)._id,
          },
        });
      case selectionTypes.SHARED_UNCATEGORIZED:
        return dispatch({
          type: actions.SELECT_SHARED_UNCATEGORIZED,
          payload: {
            folderIds: folders
              .filter((folder) => folder.isSharedUncategorized)
              .map(({ _id: folderId }) => folderId),
          },
        });
      default:
        return Error();
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.newSpecialRoot]: !smallIcon && newDesign,
          [classes.noPadding]: noPadding,
          [classes.newUsualRoot]: smallIcon && newDesign,
          [classes.newRootActive]: !smallIcon && newDesign && isActive,
        },
      )}
      onClick={handleSelectFolder}
    >

      <div className={classes.iconContainer}>
        <Icon classes={{
          root: clsx(
            classes.icon,
            {
              [classes.rotateRight]: rotatableIcon && !isActive,
              [classes.newEnlargedIcon]: !smallIcon && newDesign,
              [classes.active]: isActive && newDesign,
              [classes.newIcon]: newDesign,
            },
          ),
        }}
        />
      </div>

      <div className={clsx(
        classes.folderTitle, {
          [classes.active]: isActive,
          [classes.newFolderTitle]: newDesign,
        },
      )}
      >
        { title }
      </div>
      {children}
    </div>
  );
};

export default SpecialListItem;
