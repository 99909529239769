import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '24px',
  },
  selectLabel: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '3px 0px',
    marginBottom: '2px',
    color: theme.palette.colors.neutrals400,
  },
}));

export default useStyles;
