import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Lottie from 'lottie-react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import useFraseGeneration from '@root/components/integrations/Frase/resources/useFraseGeneration';
import useFraseFile from '@root/components/integrations/Frase/resources/useFraseFile';
import useFraseFileRemove from '@root/components/integrations/Frase/resources/useFraseFileRemove';
import { Fire } from '@root/components/SVGIcon';
import Button from '@root/components/buttons/Button';
import { InputField } from '@root/components/form';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';

import loaderConfig from './loader.json';
import useStyles from './SEOTab.styles';

const getClustersEntities = (clusters) => {
  return clusters
    .flatMap(({ cluster_entities: entities }) => {
      return entities.map(({ entity }) => {
        return entity;
      });
    });
};
const getClustersEntitiesCount = (clusters) => {
  return clusters
    .flatMap(({ cluster_entities: entities }) => {
      return entities.map(({ count }) => {
        return count;
      });
    }).reduce((sum, count) => sum + count, 0);
};
const escapeRegExp = (phrase) => phrase.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
const countPhraseInText = (phrase, text) => {
  const regexp = new RegExp(escapeRegExp(phrase), 'ig');

  return (text.match(regexp) || []).length;
};

const countClustersInText = (text, entities) => {
  return entities.reduce((sum, entity) => sum + countPhraseInText(entity, text), 0);
};

const getClustersUsage = (text, clusters) => {
  const entities = getClustersEntities(clusters);
  const entitiesCount = getClustersEntitiesCount(clusters);
  const cap = 25;
  const maxCount = entitiesCount < cap ? entitiesCount : cap;
  const count = countClustersInText(text, entities);
  const result = count / maxCount;
  return result > 1 ? 1 : result;
};

const SEOTab = ({ documentId, content, onInsert }) => {
  const classes = useStyles();
  const [seoSearchValue, setSeoSearchValue] = React.useState('');
  const [currentTabState, setCurrentTabState] = React.useState('form');
  const [fraseData, setFraseData] = React.useState(null);
  const { data: fraseFile } = useFraseFile(
    documentId,
  );
  const { mutate: removeFraseFile } = useFraseFileRemove();

  const itemsRef = React.useRef();
  const itemsHeight = itemsRef.current
    ? window.innerHeight - itemsRef.current.getClientRects()[0].y
    : 500;
  const clustersUsage = fraseData ? getClustersUsage(content, fraseData) : 0;
  const handleRemoveClick = () => {
    setFraseData(null);
    setCurrentTabState('form');
    removeFraseFile(documentId);
  };

  React.useEffect(() => {
    if (fraseFile) {
      setSeoSearchValue(fraseFile.content.query);
      setFraseData(fraseFile.content.clusters);
      setCurrentTabState('frase');
    }
  }, [fraseFile]);

  const handleSeoSearchValueChange = (event) => {
    setSeoSearchValue(event.target.value);
  };

  const {
    data: fraseGenerationData, mutate: getFraseGenerations,
  } = useFraseGeneration();

  React.useEffect(() => {
    if (fraseGenerationData) {
      setFraseData(fraseGenerationData);
      setCurrentTabState('frase');
    }
  }, [fraseGenerationData]);
  const [itemSearchValue, setItemSearchValue] = React.useState('');

  const handleItemSearchValueChange = (event) => {
    setItemSearchValue(event.target.value);
  };

  const handleSubmitClick = () => {
    const fraseQuery = {
      content: seoSearchValue,
      lang: 'en',
      country: 'us',
      documentId,
    };
    setCurrentTabState('loading');
    getFraseGenerations(fraseQuery);
  };

  const inputIcon = (
    <InputAdornment>
      <Fire htmlColor={seoSearchValue ? 'black' : 'rgba(196, 196, 196, 1)'} />
    </InputAdornment>
  );

  const form = (
    <div className={classes.formRoot}>
      <div className={classes.formPrompt}>Enter a search query to see recommendations.</div>
      <InputField
        placeholder="Type your search query"
        classes={{ root: classes.input, focused: classes.inputFocused }}
        startAdornment={inputIcon}
        onChange={handleSeoSearchValueChange}
      />
      <Button
        size="small"
        onClick={handleSubmitClick}
        className={classes.submitButton}
      >
        Let’s go!
      </Button>
    </div>
  );

  const loading = (
    <div className={classes.loadingRoot}>
      <div className={classes.loadingPrompt}>
        <div>Hold tight!</div>
        <div>Something is happening right now!</div>
      </div>
      <div className={classes.loader}><Lottie animationData={loaderConfig} loop /></div>
    </div>
  );

  const searchIcon = (
    <InputAdornment>
      <SearchIcon htmlColor={itemSearchValue ? 'black' : 'rgba(196, 196, 196, 1)'} />
    </InputAdornment>
  );

  const frase = (
    <div className={classes.fraseRoot}>
      <div className={classes.fraseTitle}>SEO Optimization</div>
      <div className={classes.progressRoot}>
        <div className={classes.progress}>
          <div className={clsx(
            classes.progressBar, {
              [classes.progressBarActive]: clustersUsage >= 0.2,
            },
          )}
          />
          <div className={clsx(
            classes.progressBar, {
              [classes.progressBarActive]: clustersUsage >= 0.4,
            },
          )}
          />
          <div className={clsx(
            classes.progressBar, {
              [classes.progressBarActive]: clustersUsage >= 0.6,
            },
          )}
          />
          <div className={clsx(
            classes.progressBar, {
              [classes.progressBarActive]: clustersUsage >= 0.8,
            },
          )}
          />
          <div className={clsx(
            classes.progressBar, {
              [classes.progressBarActive]: clustersUsage >= 1,
            },
          )}
          />
        </div>
        <div className={classes.progressText}>
          {(clustersUsage * 100).toFixed(0)}
          %
        </div>
      </div>

      <div className={classes.frasePhraseRoot}>
        <div className={classes.frasePhraseText}>
          <Fire size={16} />
          {seoSearchValue}
        </div>
        <CloseIcon onClick={handleRemoveClick} size={16} className={classes.frasePhraseClose} />
      </div>
      <InputField
        placeholder="Filter by keywords..."
        classes={{ root: classes.searchInput, focused: classes.inputFocused }}
        startAdornment={searchIcon}
        onChange={handleItemSearchValueChange}
        variant="standard"
      />

      <div ref={itemsRef} style={{ height: `${itemsHeight}px` }} className={classes.fraseItems}>
        {
          fraseData
          && fraseData
            .map((cluster, index) => {
              return cluster.cluster_entities
                .filter(({ entity }) => {
                  return countPhraseInText(itemSearchValue, entity);
                })
                .map(({ entity, count }) => {
                  const entityCount = countPhraseInText(entity, content);
                  return (
                    <SimpleTooltip
                      content="Click to place"
                      placement="top"
                      key={entity}
                      classes={{
                        tooltip: classes.fraseItemTooltip,
                        arrow: classes.fraseItemTooltipArrow,
                      }}
                    >
                      <button
                        type="button"
                        className={clsx(classes.fraseItem, {
                          [classes.fraseItemPartial]: entityCount > 0 && entityCount < count,
                          [classes.fraseItemFull]: entityCount >= count,
                        })}
                        onClick={() => onInsert(entity, true)}
                      >
                        <div className={clsx(classes.fraseItemCount, {
                          [classes.fraseItemCountPartial]: entityCount > 0 && entityCount < count,
                          [classes.fraseItemCountFull]: entityCount >= count,
                        })}
                        >
                          {`${entityCount} / ${count}`}
                        </div>
                        <div className={classes.fraseItemText}>
                          <div className={classes.fraseItemTextTitle}>{entity}</div>
                          <div className={classes.fraseItemTextDesc}>
                            Source:
                            {index + 1}
                          </div>
                        </div>
                      </button>
                    </SimpleTooltip>
                  );
                });
            })
        }
      </div>

    </div>
  );

  const tabState = {
    form,
    loading,
    frase,
  };

  return (
    tabState[currentTabState]
  );
};

export default SEOTab;
