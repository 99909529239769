import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

import api from '@root/api';

const useGetUsageByTemplateType = <T = any>(
  filters: any,
  queryOptions?: UseQueryOptions<any, Error, T, [string, any]>,
): UseQueryResult<T, Error> => (
    useQuery(
      ['usage.usageByTemplateType', filters],
      () => api.usage.getStatsForTemplates(filters),
      queryOptions,
    )
  );

export default useGetUsageByTemplateType;
