import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.colors.grey}`,
    overflowY: 'auto',
    '& table': {
      borderCollapse: 'collapse',
    },
    flexGrow: 1,
  },
  topActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  promptTextWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    fontSize: theme.font.size.sm,
    color: theme.palette.colors.red,
  },
}));
