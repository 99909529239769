import React, { useState } from 'react';
import clsx from 'clsx';
import { IconCopy, IconDownload, IconThumbUp, IconThumbDown, IconArrowsDiagonal2, IconArrowsDiagonalMinimize, IconFlag, IconChecks, IconPlus } from '@tabler/icons-react';
import { IconButton } from '@material-ui/core';

import { downloadAsPNG } from '@root/utils/Export/functions';
import uiNotificationService from '@root/services/uiNotification.service';
import documentImageResource from '@root/resources/documentImage';
import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import { copyImgToClipboard } from '@root/utils/browser.utils';

import useStyles from './AIImage.styles';

const AIImage = (
  { documentId,
    image,
    isExample = false,
    onActionClick,
    onImageClick,
    onExpandOrMinimizeImage,
    selected,
    visible },
) => {
  const [hover, setHover] = useState(false);
  const [addedToEditor, setAddedToEditor] = useState(false);
  const classes = useStyles({ addedToEditor, hover, selected, visible });

  const { mutateAsync: likeImageDocument } = documentImageResource.useLikeImageDocument();
  const { mutateAsync: flagImageDocument } = documentImageResource.useFlagImageDocument();
  const { mutateAsync: saveImageDocument } = documentImageResource.useSaveImageDocument();

  const actions = !isExample && [{
    type: 'copy',
    icon: IconCopy,
    tooltip: 'Copy',
  },
  {
    type: 'download',
    icon: IconDownload,
    tooltip: 'Download Image',
  },
  {
    type: 'thumbUp',
    icon: IconThumbUp,
    selected: image.ai.liked,
    tooltip: 'This image matches my prompt',
  },
  {
    type: 'thumbDown',
    icon: IconThumbDown,
    selected: image.ai.liked === false,
    tooltip: 'This image doesn’t match my prompt',
  }, {
    type: 'flag',
    icon: IconFlag,
    selected: image.ai.flagged,
    tooltip: 'Report this image as it contains biased or sensitive content',
  }];

  const onActionLocalClick = async (type) => {
    switch (type) {
      case 'copy': {
        await copyImgToClipboard(image.url);
        uiNotificationService.showSuccessMessage('Copied to clipboard');
        break;
      }
      case 'download': {
        await downloadAsPNG(`${image._id}.png`, image.url);
        break;
      }
      case 'thumbUp': {
        let liked = true;
        if (image.ai.liked) {
          liked = null;
        }
        await likeImageDocument({ documentId, documentImageId: image._id, data: { ai: { liked } } });
        onActionClick({ ...image, ...image.ai.liked = liked });
        break;
      }
      case 'thumbDown': {
        let liked = false;
        if (image.ai.liked === false) {
          liked = null;
        }
        await likeImageDocument({ documentId, documentImageId: image._id, data: { ai: { liked } } });
        onActionClick({ ...image, ...image.ai.liked = liked });
        break;
      }
      case 'flag': {
        await flagImageDocument(
          { documentId, documentImageId: image._id, data: { ai: { flagged: !image.ai.flagged } } },
        );
        if (!image.ai.flagged) {
          uiNotificationService.showSuccessMessage('Thank you for helping us keep our site safe for everyone');
        }
        onActionClick({ ...image, ...image.ai.flagged = !image.ai.flagged });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      className={clsx(classes.aiImageContainer)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
    >
      <img
        alt={image.prompt}
        className={classes.aiImage}
        height={1024}
        src={image.url || image.file}
        width={1024}
      />
      <div className={clsx((hover || addedToEditor) && visible ? classes.aiImageOverlay : classes.hidden)}>
        {isExample ? (
          <div
            className={classes.clickToAddPrompt}
            onClick={() => {
              onImageClick(image.prompt);
            }}
          >
            <div className={classes.exampleImagePrompt}>
              {image.prompt}
            </div>
            <div className={classes.exampleImageClickToTryText}>
              Click to try this prompt
            </div>
          </div>
        ) : (
          <div className={clsx(classes.toolbar, hover || classes.notVisible)}>
            <div>
              <IconButton
                className={classes.toolbarIcon}
                onClick={async () => {
                  onExpandOrMinimizeImage(selected ? null : image);
                  setHover(false);
                }}
              >
                {selected ? <IconArrowsDiagonalMinimize /> : <IconArrowsDiagonal2 />}
              </IconButton>
            </div>
            <div>
              {
              actions.map((action) => {
                const Icon = action.icon;
                return (
                  <SimpleTooltip
                    content={action.tooltip}
                    key={action.type}
                    wrapped
                  >
                    <IconButton
                      className={clsx(classes.toolbarIcon,
                        classes.rightToolbarIcon,
                        action.selected && classes.toolbarIconSelected)}
                      key={action.type}
                      onClick={async () => onActionLocalClick(action.type)}
                    >
                      <Icon />
                    </IconButton>
                  </SimpleTooltip>
                );
              })
          }
            </div>
          </div>
        )}
        {addedToEditor
          ? (
            <div className={classes.clickToAddEditor}>
              <div className={classes.imageText}>
                <IconChecks />
                {' '}
                Added to Editor file
              </div>
              <div className={classes.goBackText}>
                Go back to file to see how it looks
              </div>
            </div>
          )
          : !isExample && (
            <div
              className={clsx(classes.clickToAddEditor, classes.clickToAddEditorPadding)}
              onClick={() => {
                onImageClick(image.url);
                saveImageDocument({ documentId, documentImageId: image._id, data: { ai: { saved: true } } });
                setAddedToEditor(true);
              }}
            >
              <div className={classes.imageText}>
                <IconPlus />
                {' '}
                Click to add image to Editor
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default AIImage;
