/* eslint-disable object-property-newline */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TableContainer, TableBody, Table, CircularProgress } from '@material-ui/core';

import AuthContext from '@root/resources/auth/auth.context';
import { useDashboard, actions } from '@root/views/Dashboard/dashboardContext';
import templatesContext from '@root/resources/templates/templates.context';
import { expandCellsWithSettings, getCells, getSubfolderCells } from '@root/views/Dashboard/components/FolderContentNew/DashListViewNew/DashListViewNew.utils';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';
import ScrollFade from '@root/components/ScrollFade';
import useTeamMembers from '@root/resources/user/useTeamMembers';

import CustomTableHead from './components/CustomTableHead';
import FileTableRow from './components/FileTableRow';
import SubfolderTableRow from './components/SubfolderTableRow';
import useStyles from './DashListViewNew.styles';

const DashListView = ({
  folder,
  files = [],
  subfolders,
  isLoading,
  isDraggable,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { locationId } = useParams();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const uncategorizedFolder = pathname.includes('Home_') && 'Uncategorized';
  const bookmarkFolder = pathname.includes('bookmarks') && 'Bookmarks';
  const isWorkflow = pathname.includes('workflow') && 'Workflow';
  const selectedFolder = locationId || uncategorizedFolder || bookmarkFolder || isWorkflow || 'allFiles';

  const tableContainerRef = useRef();
  const [fileRefs, setFileRefs] = useState({ folder: selectedFolder, refs: [] });

  const { currentUser: user } = AuthContext.useAuth();
  const { data: membersDataAll } = useTeamMembers({ allMembers: true });
  const teamMembers = membersDataAll?.results;

  const hasTeamPermissions = user.permissions.teams;

  const {
    state: {
      filters: { sort, sortOrder },
      selectedFileIds,
    },
    dispatch,
  } = useDashboard();

  const { getTemplate } = templatesContext.useTemplates();

  const selectorKey = user.permissions.workflowQueue && hasTeamPermissions
    ? 'workflowQueue'
    : 'default';

  const selector = expandCellsWithSettings[selectorKey];

  const selectedCells = useMemo(() => {
    const cells = getCells({ getTemplate, user, classes, teamMembers });
    return selector(cells);
  }, [getTemplate, selector, user, teamMembers]);

  const selectedSubfolderCells = useMemo(() => {
    const subfolderCells = getSubfolderCells({ classes });

    const subfoldersSelector = expandCellsWithSettings.subfolder;

    return subfoldersSelector(subfolderCells);
  }, [selector]);

  const setSelectedFileIds = (newSelectedFileIds) => dispatch({
    type: actions.SET_SELECTED_FILES,
    payload: { selectedFileIds: newSelectedFileIds },
  });

  const handleRequestSort = (property) => {
    let newSortOrder;
    if (sort !== property) {
      newSortOrder = 'asc';
    } else {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    dispatch({
      type: actions.SET_SORT,
      payload: {
        sort: property,
        sortOrder: newSortOrder,
      },
    });
  };

  const toggleSelectedMeta = (fileId) => (event) => {
    event.stopPropagation();
    if (selectedFileIds.includes(fileId)) {
      setSelectedFileIds(selectedFileIds.filter((id) => id !== fileId));
    } else {
      setSelectedFileIds([...selectedFileIds, fileId]);
    }
  };

  const availableRowsFilter = ({ templateType }) => {
    return !currentExternalSource.isTemplateDisabled(templateType);
  };

  const availableRowsNum = files.filter(availableRowsFilter).length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedFiles = files
        .filter(availableRowsFilter)
        .map((n) => n._id);

      setSelectedFileIds(selectedFiles);
      return;
    }
    setSelectedFileIds([]);
  };

  const setFileRef = (arr) => (index) => (refData) => {
    if (!document.body.contains(refData.element)) {
      return;
    }

    arr[index] = refData;

    if (arr.length === files.length) {
      setFileRefs(() => ({ folder: selectedFolder, refs: arr }));
    }
  };

  useEffect(() => {
    if (files.length === 0) {
      setFileRefs({ folder: selectedFolder, refs: [] });
    }
  }, [files.length]);

  useEffect(() => {
    if (files.length < 20) {
      return () => {};
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        const ref = fileRefs.refs.find((r) => r && r.element === e.target);
        if (!ref) {
          return;
        }
        ref.setVisible(e.isIntersecting);
      });
    }, { root: tableContainerRef.current, threshold: 0, rootMargin: '500px 0px 500px 0px' });
    fileRefs.refs.forEach((r) => observer.observe(r.element));

    return () => {
      fileRefs.refs.forEach((r) => { observer.unobserve(r.element); });
    };
  }, [
    fileRefs.refs,
    fileRefs.folder,
  ]);

  const refsArr = [];

  const fileRows = useMemo(() => {
    return files.map((file, i) => (
      <FileTableRow
        id={file._id}
        key={`file-${file._id}`}
        file={file}
        selected={selectedFileIds.includes(file._id)}
        toggleSelected={toggleSelectedMeta(file._id)}
        isDraggable={isDraggable}
        cells={selectedCells}
        setRef={setFileRef(refsArr)(i)}
        files={files}
      />
    ));
  }, [files, selectedFileIds, teamMembers]);

  return (
    <div className={classes.root}>

      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress classes={{ root: classes.loader }} size={25} />

          <span className={classes.loadingText}>Just a few more moments and you&apos;ll be all set!</span>
        </div>
      )}

      {!isLoading && (
        <TableContainer
          innerRef={tableContainerRef}
          classes={{ root: classes.tableContainer }}
        >
          <ScrollFade withoutTop />

          <Table
            aria-label="sticky table"
            stickyHeader
            classes={{ root: classes.table }}
          >
            <CustomTableHead
              onRequestSort={handleRequestSort}
              orderBy={sort}
              order={sortOrder}
              onSelectAllClick={handleSelectAllClick}
              availableRowsNum={availableRowsNum}
              selectedFileIds={selectedFileIds}
              folder={folder}
              files={files}
              cells={selectedCells}
            />
            <TableBody>
              {subfolders.map((subfolder) => (
                <SubfolderTableRow
                  key={`subfolder-${subfolder._id}`}
                  folder={subfolder}
                  isDraggable={isDraggable}
                  cells={selectedSubfolderCells}
                />
              ))}

              {fileRows}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DashListView;
