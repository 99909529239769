import React, { useState, useEffect } from 'react';
import classNames from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

import DEPRECATEDSingleSelect from '@root/components/Interactive/DEPRECATEDSingleSelect';
import uiNotificationService from '@root/services/uiNotification.service';
import ProgressButton from '@root/components/ProgressButton';
import documentResource from '@root/resources/document';

import googleAdFile from '../resources';

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: '20rem',
    minHeight: '10rem',
  },
  modalTitleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  titleProgress: {
    marginLeft: theme.spacing(1),
  },
  details: {
    ...theme.typography.body2,
    marginBottom: theme.spacing(2),
  },
  disabled: {
    color: theme.palette.colors.grey,
  },
  button: {
    color: theme.palette.primary.main,
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: '1.125rem',
    marginBottom: '1rem',
  },
  inputHeader: {
    fontSize: '0.875rem',
    color: theme.palette.colors.darkGrey,
  },
  input: {
    marginBottom: '0.875rem',
  },
  arrow: {
    marginLeft: '2rem',
    marginTop: '0.125rem',
    fontSize: '0.875rem',
    alignSelf: 'center',
  },
  newAdGroup: {
    margin: 0,
    padding: 0,
  },
}));

const URL = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;

const validationSchema = yup.object({
  url: yup
    .string()
    .matches(URL, 'Valid URL is required (with protocol at beginning, HTTPS or HTTP)')
    .required('A URL is required (the website address you want your ads directed to)'),
});

const PublishAdDialog = ({
  documentId,
  fileId,
  onClose,
  onAddNewAdGroup,
  integrationId,
}) => {
  const { mutateAsync: publishDocument } = documentResource.usePublishDocument();
  const { mutateAsync: updateFile } = googleAdFile.useCreateOrUpdateFile();
  const { data: fileData } = googleAdFile.useFile(fileId);
  const { data: customersData, isLoading: isLoadingCustomers } = googleAdFile.useCustomers();
  const [customer, setCustomer] = useState(null);
  const [managerCustomerId, setManagerCustomerId] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [adGroup, setAdGroup] = useState(null);
  const { mutateAsync: createOrUpdateGoogleAd, isLoading: isPublishing } = googleAdFile
    .useCreateOrUpdateAd();

  const customers = customersData || [];
  const customerId = customer && customer.value;
  const campaignId = campaign && campaign.value;
  const adGroupId = adGroup && adGroup.value;
  const canPublish = customerId && campaignId && adGroupId;
  const {
    data: campaignsData,
    isLoading: isLoadingCampaigns,
    refetch: refetchCampaigns,
  } = googleAdFile
    .useCampaigns({ customerId, managerCustomerId });
  const campaigns = campaignsData || [];
  if (campaigns.length === 0 && campaign) {
    campaigns.push(campaign);
  }

  const { data: adGroupsData, isLoading: isLoadingAgGroups } = googleAdFile
    .useAdGroups({ customerId, managerCustomerId, campaignId });
  const adGroups = adGroupsData || [];
  const isLoading = isLoadingCustomers || isLoadingCampaigns || isLoadingAgGroups;

  useEffect(() => {
    if (!fileData) {
      return;
    }

    if (fileData.customerId) {
      setCustomer({
        value: fileData.customerId,
        label: fileData.customerName,
      });
      setManagerCustomerId(fileData.managerCustomerId);
    }
    if (fileData.campaignId && campaigns.length === 0) {
      const item = {
        value: fileData.campaignId,
        label: fileData.campaignName,
      };
      setCampaign(item);
    }
    if (fileData.adGroupId && adGroups.length === 0) {
      const item = {
        value: fileData.adGroupId,
        label: fileData.adGroupName,
      };
      setAdGroup(item);
    }
  }, [fileData]);

  const classes = useStyles();

  const publish = async (values) => {
    const createResult = await createOrUpdateGoogleAd({
      documentId,
      finalUrl: values.url,
      integrationId,
    });

    if (createResult.isBadRequest) {
      return;
    }

    const { isBadRequest } = await publishDocument({
      _id: documentId,
      fileId,
      data: {
        integrationId,
        externalId: createResult.adId,
      },
    });
    if (isBadRequest) {
      return;
    }

    uiNotificationService.showSuccessMessage(`Document published to ${adGroup.label} group in ${campaign.label} campaign.`, { hideAfter: 10 });
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema,
    onSubmit: publish,
  });

  const onCustomerChange = async (e) => {
    setCustomer(e);
    setCampaign(null);
    setAdGroup(null);
    const id = e.value;
    const { managerId } = customers.find((c) => c.id === id);
    setManagerCustomerId(managerId);

    await updateFile({
      fileId,
      customerId: id,
      managerCustomerId: managerId,
      customerName: e.label,
      campaignId: null,
      campaignName: null,
      adGroupId: null,
      adGroupName: null,
    });
    refetchCampaigns();
  };

  const onCampaignChange = async (e) => {
    setCampaign(e);

    await updateFile({
      fileId,
      campaignId: e.value,
      campaignName: e.label,
      adGroupId: null,
      adGroupName: null,
    });
  };

  const onAdGroupChange = async (e) => {
    setAdGroup(e);

    await updateFile({
      fileId,
      adGroupId: e.value,
      adGroupName: e.label,
    });
  };

  const openAddNewAdGroup = () => {
    onAddNewAdGroup({ customerId, managerCustomerId, campaignId });
  };

  const submitForm = async () => {
    await formik.submitForm();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className={classes.modal}
    >
      <DialogTitle>
        <div className={classes.modalTitleWrap}>
          <div>Publish to Google Ads Manager</div>
          {isLoading && <CircularProgress size={20} className={classes.titleProgress} />}
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <div className={classes.details}>
          Select your Ad Account, Ad Campaign, Ad Group and click publish to publish your google ad.
        </div>
        <div className={classes.input}>
          <span className={classes.inputHeader}>Select Ad Account</span>
          <DEPRECATEDSingleSelect
            placeholder="None selected."
            disabled={customers.length === 0}
            value={customer}
            handleChange={onCustomerChange}
            options={customers.map((c) => {
              return { label: c.name, value: c.id };
            })}
          />
        </div>
        <div className={classes.input}>
          <span className={classes.inputHeader}>Select Ad Campaign</span>
          <DEPRECATEDSingleSelect
            placeholder="None selected."
            disabled={!customerId}
            value={campaign}
            handleChange={onCampaignChange}
            options={campaigns}
          />
        </div>
        <div className={classes.input}>
          <span className={classes.inputHeader}>Select Ad Group</span>
          <DEPRECATEDSingleSelect
            placeholder="None selected."
            disabled={!campaignId}
            value={adGroup}
            handleChange={onAdGroupChange}
            options={adGroups}
          />
          <span>
            <Button
              variant="text"
              onClick={openAddNewAdGroup}
              disabled={!customerId || !campaignId}
              className={classNames(!campaignId && classes.disabled,
                classes.button,
                classes.newAdGroup)}
            >
              + Create new ad group
            </Button>
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            id="url"
            name="url"
            label="Final URL"
            value={formik.values.url}
            onChange={formik.handleChange}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={(formik.touched.url && formik.errors.url)
              || 'The URL address of the page in your website that people reach when they click your ad.'}
            variant="outlined"
            className={classes.input}
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.cancelButtonWrap}>
        <Button
          variant="text"
          className={classes.cancelButton}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <ProgressButton
          onClick={submitForm}
          disabled={!canPublish || isPublishing}
          size="small"
          isLoading={isPublishing}
        >
          Publish
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default PublishAdDialog;
