import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '@root/components/buttons/Button';
import { KeywordInputAtomField } from '@root/components/Fields';
import useCreateFolder from '@root/resources/folder/useCreateFolder';
import useUpdateFolder from '@root/resources/folder/useUpdateFolder';
import { InputField, FormField } from '@root/components/form';
import eventsAggregator, { EVENT_NAMES } from '@root/services/eventsAggregator';
import { actions, useDashboard } from '@root/views/Dashboard/dashboardContext';
import api from '@root/api';
import onboardingContext from '@root/resources/onboarding/onboarding.context';

const NewProjectPresetForm = ({
  title,
  closeModal,
  newUser,
  classes,
}) => {
  const history = useHistory();
  const [scrapedData, setScrapedData] = useState({
    companyName: null,
    companyDescription: null,
    url: null,
  });
  const [isScrapping, setIsScrapping] = useState(false);

  const { dispatch } = useDashboard();
  const { refetchOnboarding } = onboardingContext.useOnboarding();

  const { mutateAsync: createFolder } = useCreateFolder();
  const { mutateAsync: updateFolder } = useUpdateFolder();

  const submit = async ({
    audience, keywords, companyName, companyDescription, url,
  }) => {
    const newProject = await createFolder({
      title: title === '' ? 'Untitled' : title,
      parentFolderId: null,
    });

    const presetSettings = {
      audience,
      keywords,
      company: companyName,
      companyDescription,
      url,
    };

    const presetsNotEmpty = Object.values(presetSettings).some(
      (x) => x.length !== 0,
    );

    const { isBadRequest } = await updateFolder({
      folderId: newProject._id,
      update: {
        presetSettings,
        enablePrefill: presetsNotEmpty,
      },
    });

    // do not close modal if update folder request fails
    if (isBadRequest) {
      return;
    }

    refetchOnboarding('createProject');

    closeModal();

    dispatch({
      type: actions.SELECT_FOLDER,
      payload: {
        folderId: newProject._id,
      },
    });

    if (newUser) {
      history.replace(
        window.location.pathname
          + window.location.search.replace('newuser=true', ''),
      );
    }

    eventsAggregator.publish(EVENT_NAMES.CAMPAIGN_CREATED);
  };

  const initialValues = {
    companyName: scrapedData.companyName || '',
    companyDescription: scrapedData.companyDescription || '',
    audience: '',
    url: scrapedData.url || '',
    keywords: [],
  };

  const handleScrape = async (url) => {
    setIsScrapping(true);
    const scrapedUrlContents = await api.company.parse({
      url,
    });
    if (scrapedUrlContents) {
      setScrapedData({
        companyName: scrapedUrlContents.name,
        companyDescription: scrapedUrlContents.description,
        url,
      });
    }
    setIsScrapping(false);
  };

  return (
    <div className={classes.presetFormRoot}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ values }) => (
          <Form>
            <div className={classes.presetInputs}>
              <div className={classes.scrapeFieldContainer}>
                <FormField
                  name="url"
                  component={InputField}
                  label="Scrape URL"
                  labelTooltip="The URL you enter will be scraped for metadata. This data can help you automatically populate the project settings below."
                  classNames={{ root: classes.scrapeField }}
                />
                <div className={classes.scrapeButtonContainer}>
                  <Button
                    className={classes.scrapeButton}
                    disabled={values.url.trim().length === 0 || isScrapping}
                    onClick={() => handleScrape(values.url)}
                  >
                    Get data
                  </Button>
                </div>
              </div>

              <FormField
                name="companyName"
                component={InputField}
                label="Name"
                placeholder="Add company or product name"
              />
              <KeywordInputAtomField
                name="keywords"
                title="Keyword bank"
              />
              <FormField
                name="audience"
                component={InputField}
                label="Audience bank"
                placeholder="Add up to 3 audiences, e.g. Millennials, Urbanites"
              />

              <FormField
                name="companyDescription"
                component={InputField}
                multiline
                label="Description"
                placeholder="e.g. Copysmith is a AI-powered copywriting tool. We can generate anything from product descriptions to ads to landing pages."
              />
            </div>

            <div className={classes.buttonsContainer}>
              <Button
                type="submit"
                disabled={isScrapping}
              >
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewProjectPresetForm;
