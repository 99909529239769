import React from 'react';
import { Button } from '@material-ui/core';

const EditFooter = ({
  onCancelEdit,
  onSave,
}) => {
  return (
    <>
      <Button
        size="small"
        color="primary"
        style={{ marginRight: '1.25rem', marginLeft: '0.375rem' }}
        variant="outlined"
        onClick={(e) => {
          onSave(e);
          onCancelEdit(e);
        }}
      >
        Save Edits
      </Button>
      <Button
        size="small"
        onClick={onCancelEdit}
      >
        Cancel
      </Button>
    </>
  );
};

export default EditFooter;
