import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  experimentsRoot: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  loadingContainer: {
    width: '100%',
    height: '50vh',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    alignSelf: 'center',
  },
  title: {
    fontSize: 22,
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '39px',
    fontWeight: 600,
    marginBottom: 0,
  },
  subtitleBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  description: {
    marginBottom: 20,
    color: theme.palette.colors.darkGrey,
  },
  main: {
    display: 'flex',
    width: 1264,
    minHeight: 560,
    maxHeight: ({ inputsCount }) => (504 + inputsCount * 56),
    backgroundColor: theme.palette.colors.white,
    borderRadius: 8,
    border: `1px dashed ${theme.palette.colors.primary}`,
  },
  leftSide: {
    flex: 1,
    padding: '16px 24px',
    borderRight: `1px solid ${theme.palette.colors.grey}`,
    display: 'flex',
    flexDirection: 'column',
  },
  rightSide: {
    flex: 3,
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  sliders: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: 48,
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gapWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflowY: 'auto',
  },
  result: {
    padding: '16px 20px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.grey}`,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 32,
  },
  creditsCount: {
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: theme.palette.colors.primaryFaded,
    color: theme.palette.colors.primary,
    fontWeight: 500,
  },
  lowCredits: {
    backgroundColor: theme.palette.colors.errorFaded,
    color: theme.palette.colors.error,
  },
  generateButton: {
    maxWidth: 120,
    marginTop: 48,
  },
}));

export default useStyles;
