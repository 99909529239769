import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-text-plus"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 10h-14" />
      <path d="M5 6h14" />
      <path d="M14 14h-9" />
      <path d="M5 18h6" />
      <path d="M18 15v6" />
      <path d="M15 18h6" />
    </svg>
  );
};
