import React from 'react';

import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';
import onboardingContext from '@root/resources/onboarding/onboarding.context';
import { USE_CASE_MODAL_ID } from '@root/views/TemplatesDashboardNew/UseCaseModal/UseCaseModalId';

import FileName from './components/FileName';
import ProjectSelect from './components/ProjectSelect';
import Actions from './components/Actions';

export const TEMPLATE_CREATE_MODAL_ID = 'TEMPLATE_CREATE_MODAL_ID';

const onboardingModalStyle = {
  left: 0,
  right: 0,
  margin: 'auto',
  width: 'fit-content',
};

const CreateModal = () => {
  const { state: { modalContent, modalId }, dispatch } = useGlobalModals();
  const modalOpen = modalId === TEMPLATE_CREATE_MODAL_ID;

  const { skipFeatureTour, featureTourActive } = onboardingContext.useOnboarding();

  const handleModalClose = (e, reason) => {
    if (!(featureTourActive && reason === 'backdropClick')) {
      dispatch({
        type: actions.OPEN_MODAL, payload: { modalId: USE_CASE_MODAL_ID },
      });
      if (featureTourActive) {
        skipFeatureTour();
      }
    }
  };

  if (!modalContent) {
    return null;
  }

  return (
    <Modal
      open={modalOpen}
      title={`New ${modalContent.title}`}
      onClose={handleModalClose}
      id={featureTourActive ? 'feature-tour-step-2' : undefined}
      hideBackdrop={featureTourActive}
      disableEnforceFocus={featureTourActive}
      style={featureTourActive ? onboardingModalStyle : undefined}
    >
      <FileName />
      <ProjectSelect />
      <Actions
        templateType={modalContent.templateType}
        onClose={handleModalClose}
      />
    </Modal>
  );
};

export default CreateModal;
