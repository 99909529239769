import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    alignSelf: 'center',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 16,
    padding: '24px 32px',
  },
  title: {
    fontSize: 18,
    lineHeight: '39px',
    fontWeight: 600,
  },
  description: {
    color: theme.palette.colors.darkGrey,
    marginBottom: 24,
  },
  buttons: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: 8,
    marginTop: 40,
    alignSelf: 'flex-end',
  },
  uploadInfo: {

  },
  addCreditsLink: {
    color: theme.palette.colors.primary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  learnMoreLink: {
    color: theme.palette.colors.primary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  uploadInfoMeta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px',
    borderBottom: '1px solid #CCCCCC',
  },
  uploadInfoText: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
  },
  warningBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 32,
    gap: 24,
    border: `1px solid ${theme.palette.colors.warning}`,
    padding: '8px 12px',
    color: theme.palette.colors.warning,
    fontSize: 14,
    maxWidth: 600,
  },
  errorsWrap: {
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 32,
    display: 'flex',
    gap: 24,
    border: '1px solid #F1436D',
    padding: '8px 12px',
    color: theme.palette.colors.secondary,
    fontSize: 14,
    maxWidth: 600,
  },
  successWrap: {
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 32,
    display: 'flex',
    gap: 24,
    border: '1px solid #46DC00',
    padding: '8px 12px',
    color: '#46DC00',
    fontSize: 14,
    maxWidth: 600,
  },
  actionIcon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  fileActions: {
    display: 'flex',
    gap: 16,
  },
  visibilityAction: {
    color: '#523AE7',
  },
  errorsList: {
    marginBottom: '8px',
    paddingLeft: 16,
    '& li': {
      marginTop: '8px',
      listStyleType: 'disc',
    },
  },
  cta: {
    color: theme.palette.colors.neutrals600,
  },
  inlineMappingSelectRow: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  inlineMappingSelectWrapper: {
    marginTop: '22px',
  },
  inlineMappingSelect: {
    flex: 1,
  },
}));

export default useStyles;
