import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useHistory } from 'react-router-dom';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import ImagesIcon from '@root/views/UsageV2/assets/Images.icon';
import TruncatedText from '@root/components/TruncatedText';
import AuthContext from '@root/resources/auth/auth.context';

import useSectionStyles from '../Sections.styles';

import useStyles from './PlanSection.styles';

type Props = {
  imagesLeft?: number;
  isLatestRecordSelected?: boolean;
};

const Rollover: FC<Props> = ({ imagesLeft, isLatestRecordSelected }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const history = useHistory();

  const { currentUser }: { currentUser: any } = AuthContext.useAuth();

  const isOnTrial = currentUser?.onFreePlan;

  const handleBuyMoreClick = () => {
    history.push('/addons');
  };

  return (
    <article className={sectionClasses.articleContainer}>
      <div style={{ width: '100%' }}>
        <Typography classes={{ root: sectionClasses.articleTitle }}>
          Total Images

          {/* @ts-expect-error SimpleTooltip is not .ts yet */}
          <SimpleTooltip
            content="The number of remaining image generations left in this billing cycle."
            wrapped
          >
            <IconInfoCircle size={16} />
          </SimpleTooltip>
        </Typography>

        <div className={classes.valueWithButton}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <ImagesIcon />

            {/* @ts-expect-error TruncatedText is not .ts yet */}
            <TruncatedText classes={{ root: sectionClasses.articleValue }}>
              {imagesLeft || '0'}
            </TruncatedText>
          </div>

          {isLatestRecordSelected && (
            <Button
              onClick={handleBuyMoreClick}
              className={classes.buyMoreButton}
              disabled={isOnTrial}
            >
              <IconPlus />
            </Button>
          )}
        </div>
      </div>
    </article>
  );
};

export default Rollover;
