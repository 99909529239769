import { useQuery } from 'react-query';

import api from '@root/api';

const getTemplates = async (filters) => {
  return api.aiTemplate.getTemplates(filters);
};

export default function useAiTemplates(filters = {}) {
  const queryKey = ['aiTemplates.list', filters];

  return useQuery(
    queryKey,
    () => getTemplates(filters),
  );
}
