import DefaultCustomization from '@root/views/File/templateCustomizations/__defaults';

const allowedProps = [
  'Icon',
  'getWordCounts',
  'getWordCountSecondRow',
  'getPdfContent',
  'getTxtContent',
  'getCsvContent',
  'getClipboardItem',
  'DocumentEditor',
  'DocumentPreview',
  'onMoreLikeThis',
  'CustomPage',
  'CustomLayout',
  'stringifyContent',
];
const validate = (customization) => {
  Object.keys(customization).forEach((key) => {
    const hasKey = allowedProps.includes(key);
    if (!hasKey) {
      throw new Error(`Template customization key ${key} is not supported. Allowed props: ${allowedProps.join(', ')}`);
    }
  });
};

validate(DefaultCustomization);

const applyCustomization = async (template) => {
  let customization = {};
  try {
    const module = await import(`@root/views/File/templateCustomizations/${template.templateType}`);
    customization = module.default;
  // eslint-disable-next-line no-empty
  } catch (e) {}
  validate(customization);

  const res = {
    ...template,
    ...DefaultCustomization,
    ...customization,
  };

  return res;
};

const applyDefaultCustomization = (template) => {
  return {
    ...template,
    ...DefaultCustomization,
  };
};

export default {
  applyCustomization,
  applyDefaultCustomization,
};
