import React from 'react';
import { MenuItem } from '@material-ui/core';

import { SelectField } from '@root/components/form';

import useStyles from './RoleSelect.styles';

const options = [
  { value: 'admin', label: 'Admin', description: 'Can change team settings, modify billing information, and manage members.' },
  { value: 'member', label: 'Member', description: 'Can create files and generate copy.' },
];

const RoleSelect = (props) => {
  const classes = useStyles();

  const renderOption = ({ value, label, description }) => {
    return (
      <MenuItem
        value={value}
        key={value}
        className={classes.menuItem}
      >
        <div>{label}</div>
        <div className={classes.description}>
          {description}
        </div>
      </MenuItem>
    );
  };

  return (
    <SelectField
      renderOption={renderOption}
      options={options}
      classNames={{
        root: classes.select,
      }}
      {...props}
    />
  );
};

export default RoleSelect;
