import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    marginTop: 16,
    gap: 12,
    backgroundColor: theme.palette.colors.pink200,
    color: theme.palette.colors.pink600,
    borderRadius: 6,
    fontWeight: 500,
  },
}));

export default useStyles;
