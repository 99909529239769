import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import {
  IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  CircularProgress, Select, MenuItem, Tabs, Tab,
} from '@material-ui/core';
import { FileCopy, Delete } from '@material-ui/icons';

import aiTemplatesResource from '@root/resources/aiTemplate';
import AuthContext from '@root/resources/auth/auth.context';
import DEPRECATEDConfirmationModal from '@root/components/modals/ConfirmationModal/DEPRECATEDConfirmationModal';

import CheckIcon from './components/CheckIcon';
import SuperAdminLayout from './components/SuperAdminLayout';
import useStyles from './TemplatesList.styles';
import CustomTemplatesTable from './components/CustomTemplatesTable';

const ALL_TYPE_FILTERS = [
  { value: 'all', label: 'All' },
  { value: 'visibleOnUiLiveAndPublic', label: 'Visible on UI, live and public' },
  { value: 'liveAndPublic', label: 'Live and public' },
  { value: 'hiddenOnUi', label: 'Hidden on UI' },
  { value: 'createdByMe', label: 'Created by me' },
];

const ALL_GROUP_FILTERS = [
  'All groups',
  'Quick Access',
  'Brainstorming',
  'Ad',
  'Branding',
  'Product',
  'eCommerce',
  'Writing',
  'Other',
];

export const ALL_STATUS_FILTERS = [
  // { value: 'draft', label: 'Draft' }, // not allow admin to see draft templates
  { value: 'submitted', label: 'Submitted' },
  { value: 'live', label: 'Live' },
  { value: 'deleted', label: 'Deleted' },
];

const TABS = [
  { label: 'Copysmith templates', value: 'internal' },
  { label: 'Custom templates', value: 'custom' },
];

const TemplatesList = () => {
  const classes = useStyles();
  const { currentUser } = AuthContext.useAuth();
  const { data: templatesData, isLoading } = aiTemplatesResource.useAiTemplates();
  const { mutateAsync: deleteTemplate } = aiTemplatesResource.useDeleteAiTemplate();
  const history = useHistory();
  const [filters, setFilters] = useState({
    type: ALL_TYPE_FILTERS[0].value,
    group: ALL_GROUP_FILTERS[0],
    status: ALL_STATUS_FILTERS[0].value,
  });
  const [templates, setTemplates] = useState([]);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  const { tab = TABS[0].value } = useParams();

  useEffect(() => {
    if (!templatesData) {
      return;
    }

    let filteredTemplates = templatesData;
    switch (filters.type) {
      case 'liveAndPublic':
        filteredTemplates = templatesData.filter((t) => t.public && t.status === 'live');
        break;
      case 'hiddenOnUi':
        filteredTemplates = templatesData.filter((t) => t.hiddenOnUi);
        break;
      case 'visibleOnUiLiveAndPublic':
        filteredTemplates = templatesData.filter((t) => !t.hiddenOnUi && t.public && t.status === 'live');
        break;
      case 'createdByMe':
        filteredTemplates = templatesData.filter((t) => t.userId === currentUser._id);
        break;
      default:
    }
    if (filters.group && filters.group !== 'All groups') {
      filteredTemplates = filteredTemplates.filter((t) => t.group === filters.group);
    }
    setTemplates(filteredTemplates);
  }, [templatesData, filters]);

  const openNewTemplate = (templateId) => {
    history.push(`/super-admin/templates/new?copyFromId=${templateId}`);
  };

  const deleteAiTemplate = async () => {
    await deleteTemplate(templateToDelete._id);
  };

  const handleTabChange = (e, newTab) => {
    history.push(`/super-admin/templates/list/${newTab}`);
  };

  const renderTable = () => {
    if (tab === 'internal') {
      return (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Template Type</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Usage (files/docs)</TableCell>
                  <TableCell>Live</TableCell>
                  <TableCell>Public</TableCell>
                  <TableCell>Hidden on UI</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(templates || []).map((template) => {
                  return (
                    <TableRow key={template._id}>
                      <TableCell>
                        <RouterLink
                          to={`/super-admin/templates/${template._id}`}
                        >
                          {template.templateType}
                        </RouterLink>
                      </TableCell>
                      <TableCell>
                        {template.title}
                      </TableCell>
                      <TableCell>
                        {template.filesCount > 0 || template.documentsCount > 0 ? (
                          <span>{`${template.filesCount} / ${template.documentsCount}`}</span>
                        ) : (
                          <span>-</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {template.status === 'live' ? <CheckIcon /> : '-'}
                      </TableCell>
                      <TableCell>
                        {template.public ? <CheckIcon /> : '-'}
                      </TableCell>
                      <TableCell>
                        {template.hiddenOnUi ? <CheckIcon /> : '-'}
                      </TableCell>
                      <TableCell padding="none">
                        <IconButton
                          onClick={() => openNewTemplate(template._id)}
                        >
                          <FileCopy />
                        </IconButton>
                        {currentUser._id === template.userId && (
                          <IconButton
                            onClick={() => setTemplateToDelete(template)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {templates.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <span>Nothing here</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <DEPRECATEDConfirmationModal
            titleText={`Are you sure you want to delete ${templateToDelete?.title} ai template?`}
            buttonText="Delete"
            bodyText="Developers can recover this template if needed"
            modalOpen={templateToDelete}
            setModalOpen={() => setTemplateToDelete(null)}
            handleClick={async () => deleteAiTemplate()}
          />
        </>
      );
    }
    return (
      <CustomTemplatesTable filters={filters} />
    );
  };

  return (
    <SuperAdminLayout>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={tab}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        {TABS.map(({ label, value }) => (
          <Tab
            label={label}
            value={value}
            key={value}
          />
        ))}
      </Tabs>
      <div>
        <div className={classes.newRuleWrap}>
          {tab === 'internal' && (
            <>
              <Select
                className={classes.typeFilterSelect}
                size="small"
                variant="outlined"
                isSearchable={false}
                value={filters.type}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    type: e.target.value,
                  });
                }}
                placeholder="Filter"
              >
                {ALL_TYPE_FILTERS.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.groupFilterSelect}
                size="small"
                variant="outlined"
                isSearchable={false}
                value={filters.group}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    group: e.target.value,
                  });
                }}
                placeholder="Template Group"
              >
                {ALL_GROUP_FILTERS.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
          {tab === 'custom' && (
            <Select
              className={classes.statusFilterSelect}
              size="small"
              variant="outlined"
              isSearchable={false}
              value={filters.status}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status: e.target.value,
                });
              }}
              placeholder="Template Status"
            >
              {ALL_STATUS_FILTERS.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </div>
        {isLoading && <CircularProgress size={20} className={classes.titleProgress} />}
      </div>
      {renderTable()}
    </SuperAdminLayout>
  );
};

export default TemplatesList;
