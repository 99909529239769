import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  collapsibleWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  collapsed: {
    height: '36px',
  },
  collapsibleIcon: {
    position: 'absolute',
    left: '-5px',
    top: '4px',
    cursor: 'pointer',
  },
  rotated: {
    transform: 'rotate(-180deg)',
  },
}));
