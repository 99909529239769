import React from 'react';
import { isEmpty } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { IconInfoCircle } from '@tabler/icons-react';

import useListNotifications from '@root/resources/notification/useListNotifications';
import useReadNotification from '@root/resources/notification/useReadNotification';
import { NotificationBarWide } from '@root/views/Layout/components/NotificationBar/components/NotificationBarWide';
import { getTemplateByKey } from '@root/views/Layout/components/NotificationBar/notification.helper';

const NotificationBar = () => {
  const { data: notificationsResult } = useListNotifications({ showViewed: false });
  const { mutateAsync: readNotification } = useReadNotification();

  if (isEmpty(notificationsResult)) {
    return null;
  }

  const allNotifications = notificationsResult.results;

  // sort notifications in priority order [high -> low] && [latestCreated -> freshlyCreated](comes from api)
  const sortedNotification = ['high', 'medium', 'low']
    .flatMap((priority) => (
      allNotifications.filter((notification) => (
        getTemplateByKey(notification.template)?.priority === priority
      ))
    ));

  const relevantNotification = sortedNotification.find((notification) => getTemplateByKey(notification.template));

  if (!relevantNotification) {
    return null;
  }
  const displayTemplate = getTemplateByKey(relevantNotification.template);

  const onAlertClose = async () => {
    if (!relevantNotification) {
      return;
    }

    readNotification({ notificationId: relevantNotification._id });
  };

  return (
    <NotificationBarWide
      open={!!relevantNotification?.description}
      title={relevantNotification?.title}
      text={ReactHtmlParser(relevantNotification?.description)}
      type={displayTemplate.type}
      icon={<IconInfoCircle />}
      onClose={onAlertClose}
    />
  );
};

export default NotificationBar;
