import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor: theme.palette.primary.faded,
    borderRadius: 4,
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
  },
  closeIcon: {
    fontSize: 14,
    marginLeft: 4,
  },
}));

const SelectedChip = ({ onDelete, option }) => {
  const classes = useStyles();

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(option);
  };

  return (
    <div className={classes.chip}>
      {option.label}
      <Close
        className={classes.closeIcon}
        onMouseDown={handleDelete}
      />
    </div>
  );
};

export default SelectedChip;
