import React from 'react';
import clsx from 'clsx';
import { Select, MenuItem } from '@material-ui/core';

import useStyles from './BulkHistoryPagination.styles';

const BulkHistoryPagination = ({
  pagesAmount, page, setCurrentPage, generationPerPage, setGenerationPerPage,
}) => {
  const classes = useStyles();
  const pages = [];

  const setPage = (newPage) => () => {
    if (newPage < 1 || newPage > pagesAmount) return;
    setCurrentPage(newPage);
  };

  if (page === 3) {
    pages.push(1, '...');
  } else if (page > 2) {
    pages.push(1, 2, '...');
  }
  pages.push(page - 1, page, page + 1);
  if (pagesAmount - page > 1) {
    pages.push('...', pagesAmount - 1, pagesAmount);
  }

  const pagesFiltered = pages.filter((pageLink, pos, self) => {
    return self.indexOf(pageLink) === pos || (typeof pageLink !== 'number');
  }).filter((pageLink) => (typeof pageLink !== 'number') || (pageLink >= 1 && pageLink <= pagesAmount));

  return (
    <div>
      <div className={classes.paginationWrap}>
        <span
          className={clsx(classes.paginationTextLink, {
            [classes.disabled]: page === 1,
          })}
          onClick={setPage(page - 1)}
        >
          {'<-- Previous'}
        </span>
        <div className={classes.pageLinksWrap}>
          {pagesFiltered.map((pageLink) => {
            const isPageNumber = typeof pageLink === 'number';
            const onClick = isPageNumber ? setPage(pageLink) : () => { };
            return (
              <span
                className={clsx(classes.paginationItem, {
                  [classes.paginationLink]: isPageNumber,
                  [classes.activePaginationLink]: page === pageLink,
                })}
                onClick={onClick}
              >
                {pageLink}
              </span>
            );
          })}
        </div>

        <span
          className={clsx(classes.paginationTextLink, {
            [classes.disabled]: page === pagesAmount,
          })}
          onClick={setPage(page + 1)}
        >
          {'Next -->'}
        </span>
      </div>
      <div className={classes.itemPerPageWrap}>
        Results per page
        <div style={{ width: '150px' }}>
          <Select
            variant="outlined"
            className={classes.perPageSelect}
            value={generationPerPage}
            onChange={(e) => {
              setCurrentPage(1);
              setGenerationPerPage(e.target.value);
            }}
            placeholder="Filter by generator type"
          >
            <MenuItem value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>
              50
            </MenuItem>
            <MenuItem value={100}>
              100
            </MenuItem>
            <MenuItem value={10000}>
              All
            </MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default BulkHistoryPagination;
