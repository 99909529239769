import React from 'react';

import translateLanguages from './translateLanguages';
import DEPRECATEDSingleSelect from './Interactive/DEPRECATEDSingleSelect';

const DEPRECATEDTranslateSelect = ({
  label, onChange, value,
}) => (
  <DEPRECATEDSingleSelect
    options={translateLanguages}
    defaultValue={translateLanguages[0]}
    isSearchable
    components={{
      IndicatorSeparator: () => null,
    }}
    label={label}
    handleChange={onChange}
    value={value}
  />
);

export default DEPRECATEDTranslateSelect;
