import React from 'react';

import {
  LargeBodyText,
  LargeHeaderText,
  MediumHeaderText,
  SmallBodyText,
} from '@root/components/shared/text/Text';

export default [
  {
    name: 'H1 - Inter/48px',
    component: <LargeHeaderText>Lorem ipsum</LargeHeaderText>,
  },
  {
    name: 'H2 - Inter/38px',
    component: <MediumHeaderText>Lorem ipsum</MediumHeaderText>,
  },
  {
    name: 'Body - Inter/18px',
    component: (
      <LargeBodyText>
        Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Rhoncus dolor purus non enim
        praesent elementum facilisis leo vel.
      </LargeBodyText>),
  },
  {
    name: 'Body - Inter/14px',
    component: (
      <SmallBodyText>
        Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Rhoncus dolor purus non enim
        praesent elementum facilisis leo vel.
      </SmallBodyText>),
  },
];
