import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  modal: {
    position: 'relative',
    fontFamily: '"Inter", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  modalBox: {
    backgroundColor: '#fff',
    minWidth: '20rem',
    padding: '1.5rem',
    position: 'relative',
  },
  clickZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '98%',
    maxHeight: '98%',
    width: '450px',
    margin: '0 auto',
  },
  fullHeight: {
    height: '100%',
  },
  modalHeader: {
    fontSize: '1.2rem',
    marginBottom: '1rem',
    marginRight: '1.5rem',
  },
  modalText: {
    fontSize: '0.95rem',
    marginBottom: '2rem',
  },
  button: {
    textAlign: 'right',
    marginTop: '1rem',
  },

});

export default useStyles;
