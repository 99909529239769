import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useUpdateIntegration() {
  const queryClient = useQueryClient();

  return useMutation(
    (update) => api.integration.update(update.id, { data: update.data, status: update.status }),
    {
      onSuccess: (response) => {
        if (!response || response.isBadRequest) {
          return;
        }

        queryClient.invalidateQueries(['integration', response.type]);
        queryClient.invalidateQueries(['get.integrations']);
      },
    },
  );
}
