import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  startIcon: {
    width: 16,
    height: 16,
    strokeWidth: 3,
  },

  createNewButton: ({ newFilesPage }) => ({
    display: 'flex',

    color: theme.palette.colors.white,
    backgroundColor: newFilesPage ? theme.palette.colors.neutrals600 : theme.palette.primary.main,

    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    borderRadius: 6,
    padding: '6px 12px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: newFilesPage ? theme.palette.colors.neutrals400 : theme.palette.primary.dark,
    },
  }),

}));

export default useStyles;
