import { createTheme, darken, lighten } from '@material-ui/core/styles';
import { IconChevronDown } from '@tabler/icons-react';

import { colors } from './material.theme';

export const newColors = {
  ...colors,
  primary: '#0A0821',
  secondary: '#990029',
} as const;

export default createTheme({
  palette: {
    colors,
    primary: {
      main: newColors.primary,
      // @ts-expect-error extend mui theme
      faded: newColors.primaryFaded,
    },
    secondary: {
      main: newColors.secondary,
      // @ts-expect-error extend mui theme
      faded: newColors.secondaryFaded,
    },
    error: {
      main: newColors.secondary,
      // @ts-expect-error extend mui theme
      faded: newColors.errorFaded,
    },
    success: {
      main: newColors.success,
    },
    disabled: {
      main: newColors.neutrals200,
    },
    grey: {
      // @ts-expect-error extend mui theme
      dark: newColors.mediumDarkGrey,
      light: newColors.lightGrey,
      main: newColors.grey,
      pure: newColors.pureGray,
    },
    white: {
      main: newColors.white,
    },
    text: {
      primary: newColors.black,
      secondary: newColors.darkGrey,
      disabled: newColors.mediumDarkGrey,
    },
    action: {
    },
    background: {
      // @ts-expect-error extend mui theme
      contentBreaker: newColors.mediumLightGrey,
      primary: newColors.lightGrey,
      dark: newColors.black,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // @ts-expect-error extend mui theme
    useNextVariants: true,
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '58px',
      textAlign: 'left',
      '@media (min-width:780px)': {
        fontSize: '40px',
        lineHeight: '50px',
        textAlign: 'center',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '42px',
      textAlign: 'left',
      '@media (min-width:780px)': {
        fontSize: '26px',
        lineHeight: '32px',
        textAlign: 'center',
      },
    },
    h6: {
      fontSize: 18,
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiTablePagination: {
      color: 'primary',
    },
    // elevation:0 in order to resolve console warnings
    MuiPaper: {
      elevation: 0,
    },
    MuiPopover: {
      elevation: 0,
    },
    MuiDrawer: {
      elevation: 0,
    },
    MuiDialog: {
      PaperProps: {
        elevation: 0,
      },
    },
    MuiSelect: {
      IconComponent: IconChevronDown,
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: -6,
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
    MuiTabs: {
      indicatorColor: 'primary',
    },
  },
  // @ts-expect-error extend mui theme
  shadows: ['none'],
  // TODO: fix me: below properties were move from styled components
  // and do not match material ui theme style
  font: {
    family: {
      default: '"Inter", sans-serif',
      secondary: '',
    },
    size: {
      sm: '0.85rem',
      md: '1rem',
      lg: '1.25rem',
      xl: '1.5rem',
      xxl: '2rem',
    },
  },
  border: {
    radius: {
      default: '4px',
      none: '0px',
      sm: '3px',
      md: '6px',
      lg: '8px',
      full: '9999px',
    },
    default: `1px solid ${newColors.grey}`,
  },
  form: {
    input: {
      borderColor: newColors.grey,
      borderWidth: '1px',
      fontSize: '0.85rem',
      padding: '0.5rem 0.5rem 0.5rem 0.8rem',
      color: newColors.darkGrey,
      height: '48px',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: newColors.primary,
          backgroundColor: newColors.neutrals50,
        },
        '&$selected:hover': {
          backgroundColor: newColors.neutrals50,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: newColors.neutrals50,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        padding: '6px 24px',
        borderRadius: 6,
        fontSize: 14,
        fontWeight: 600,
        color: newColors.darkGrey,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: newColors.neutrals400,
        },
      },
      contained: {
        minWidth: 80,
        '&$disabled': {
          color: newColors.white,
          backgroundColor: newColors.neutrals200,
        },
      },
      outlined: {
        minWidth: 80,
        '&$disabled': {
          border: `1px solid ${newColors.neutrals200}`,
          color: newColors.neutrals300,
        },
      },
      outlinedSecondary: {
        minWidth: 80,
        '&$disabled': {
          border: `1px solid ${newColors.neutrals300}`,
          color: newColors.neutrals300,
        },
      },
      outlinedSizeSmall: {
        fontSize: 14,
        height: 30,
      },
      containedSizeSmall: {
        fontSize: 14,
        height: 30,
      },
      outlinedSizeLarge: {
        fontSize: 14,
        fontWeight: 600,
        height: 40,
      },
      containedSizeLarge: {
        fontSize: 14,
        fontWeight: 600,
        height: 40,
      },
      textPrimary: {
        '&:hover': {
          color: colors.neutrals400,
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: darken(newColors.primary, 0.2),
        },
      },
      textSecondary: {
        '&:hover': {
          color: lighten(newColors.secondary, 0.2),
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: darken(newColors.secondary, 0.2),
        },
      },
      textSizeLarge: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    MuiIconButton: {
      root: {
        width: 36,
        height: 36,
        padding: 0,
        borderRadius: 4,
      },
      sizeSmall: {
        width: 30,
        height: 30,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 6,
        '& svg': {
          marginLeft: 4,
          marginBottom: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        '& > svg': {
          marginLeft: 4,
          marginBottom: 2,
          fontSize: 16,
        },
        whiteSpace: 'nowrap',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        color: newColors.neutrals300,
      },
    },
    MuiInputBase: {
      root: {
        minHeight: 36,
      },
      input: {
        fontSize: 14,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '8.5px 11px',
      },
      multiline: {
        padding: '11px 11px',
      },
      notchedOutline: {
        borderColor: newColors.neutrals200,
      },
      root: {
        borderRadius: 6,
        backgroundColor: newColors.white,
        '&$focused': {
          '& $notchedOutline': {
            border: `1px solid ${newColors.neutrals400}`,
          },
        },
        '&:hover $notchedOutline': {
          borderColor: newColors.neutrals300,
        },
      },
      inputAdornedStart: {
        paddingLeft: 8,
      },
      adornedStart: {
        '& > svg': {
          marginRight: 8,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -9,
        marginRight: 0,
        color: newColors.darkGrey,
      },
      label: {
        fontSize: 14,
        lineHeight: '24px',
        '& > svg': {
          marginLeft: 4,
          fontSize: 16,
        },
      },
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: 56,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      colorPrimary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&$checked + $track': {
          opacity: 1,
          backgroundColor: newColors.green,
          border: `1px solid ${newColors.primary}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      switchBase: {
        color: newColors.darkGrey,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$checked': {
          transform: 'translateX(16px)',
        },
      },
      thumb: {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: '4px 0 0 3px',
      },
      track: {
        borderRadius: 50,
        height: 16,
        backgroundColor: newColors.mediumLightGrey,
        border: `1px solid ${newColors.darkGrey}`,
        opacity: 1,
      },
      input: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&&&:hover': {
          background: 'none',
        },
      },
    },
    MuiSelect: {
      root: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,

        '& > :not(:last-child)': {
          marginRight: 4,
        },

        '&&:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectMenu: {
        height: 36,
      },
      icon: {
        color: newColors.neutrals200,
      },
    },
    MuiMenu: {
      list: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        border: `1px solid ${newColors.neutrals200}`,
        padding: 4,
        gap: 4,
      },
      paper: {
        borderRadius: 6,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 4,
        paddingBottom: '4px',
        paddingTop: '4px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: newColors.black,
        backgroundColor: newColors.white,
        padding: '8px 12px',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '16px',
        border: `1px solid ${newColors.mediumDarkGrey}`,
        borderRadius: 6,
      },
      arrow: {
        color: newColors.white,
        '&:before': {
          border: `1px solid ${newColors.mediumDarkGrey}`,
        },
      },
    },
    MuiDialog: {
      paper: {
        padding: '24px 32px',
        minWidth: 500,
        borderRadius: 8,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        marginBottom: 8,
        maxWidth: 'calc(100% - 30px)',
        fontWeight: 600,
        fontSize: 18,
      },
    },
    MuiDialogContent: {
      root: {
        '&&': {
          padding: 0,
          fontSize: 14,
          overflowY: 'visible',
        },
      },
    },
    MuiDialogActions: {
      root: {
        marginTop: 16,
        padding: 0,
      },
    },
    MuiSlider: {
      root: {
        color: newColors.primary,
        height: 8,
        '&$disabled > $rail': {
          backgroundImage: 'none',
        },
      },
      thumb: {
        height: 16,
        width: 16,
        backgroundColor: newColors.white,
        border: `1px solid ${newColors.mediumDarkGrey}`,
        marginTop: -4,
        marginLeft: -8,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
        '&$disabled': {
          height: 16,
          width: 16,
          marginTop: -4,
          marginLeft: -8,
        },
        '&[style="left: 0%;"]': {
          marginLeft: 0,
        },
        '&[style="left: 100%;"]': {
          marginLeft: -16,
        },
      },
      marked: {
        marginBottom: 0,
      },
      valueLabel: {
        left: 'calc(-50% - 2px)',
      },
      track: {
        backgroundColor: 'transparent',
      },
      rail: {
        height: 8,
        borderRadius: 4,
        backgroundImage: `linear-gradient(to right, ${newColors.purple400}, ${newColors.pink400})`,
        opacity: 1,
      },
      mark: {
        display: 'none',
      },
      markLabel: {
        fontSize: 12,
        '&[style="left: 0%;"]': {
          transform: 'translateX(0%)',
        },
        '&[style="left: 100%;"]': {
          transform: 'translateX(-100%)',
        },
      },
      markLabelActive: {
        color: newColors.darkGrey,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 8,
        borderRadius: 4,
      },
      colorPrimary: {
        backgroundColor: newColors.mediumLightGrey,
      },
      bar: {
        borderRadius: 4,
      },
    },
    MuiFormGroup: {
      row: {
        gap: 16,
      },
    },
    MuiTab: {
      root: {
        '&&': {
          textTransform: 'none',
          minHeight: 0,
          minWidth: 60,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 0,
        borderBottom: `1px solid ${newColors.neutrals200}`,
      },
    },
  },
});
