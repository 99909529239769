import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  input: {
    resize: 'none',
    fontStyle: 'normal',
    borderRadius: 4,
    padding: '10px 16px',
    width: '100%',
    height: 200,
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    border: '0.5px solid #aaaaaa',
    marginBottom: 16,
    overflow: 'auto',
    backgroundColor: 'white',
  },
  label: {
    color: theme.palette.grey.pure,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
  },
  counter: {
    color: theme.palette.colors.pureGray,
    background: theme.palette.colors.mediumLightGrey,
    padding: 4,
    borderRadius: 4,
    fontSize: 14,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  creditsBadge: {
    color: theme.palette.colors.white,
    padding: 4,
    borderRadius: 4,
    fontSize: 14,
  },
}));

export default useStyles;
