import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  select: {
    minWidth: 160,
    minHeight: 28,
    height: 28,
    fontSize: 12,
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  // statuses
  needsReview: {
    backgroundColor: theme.palette.colors.pink200,
    color: theme.palette.colors.pink600,
  },
  returned: {
    backgroundColor: theme.palette.colors.yellow200,
    color: theme.palette.colors.yellow600,
  },
  approved: {
    backgroundColor: theme.palette.colors.green200,
    color: theme.palette.colors.green600,
  },
}));
