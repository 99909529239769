import React from 'react';
import { Divider } from '@material-ui/core';

import Modal from '@root/components/modals/Modal';
import { useCommunityContext } from '@root/views/Community/context/Community.context';
import { modalsActions } from '@root/views/Community/context/confirmationModalsReducer';
import FieldNumber from '@root/views/Community/components/TemplatePreviewModal/FieldNumber';

import useStyles from './TemplatePreviewModal.styles';

const TemplatePreviewModal = () => {
  const classes = useStyles();

  const {
    modalsState,

    modalsDispatch,
  } = useCommunityContext();

  const { open, template } = modalsState.previewTemplate;

  const { fields = [] } = template || {};

  const handleModalClose = () => {
    modalsDispatch({
      type: modalsActions.TOGGLE_PREVIEW_TEMPLATE_MODAL,
    });
  };

  return (
    <Modal
      open={open}
      noContentMargin
      onClose={handleModalClose}
      keepMounted
      classNames={{ paper: classes.modal, root: classes.modalRoot }}
    >
      <div className={classes.content}>
        <h3 className={classes.sectionTitle}>Required Inputs</h3>
        <p className={classes.sectionDescription}>
          These are the fields that will be required for you to input in order to generate content
          using this template.
        </p>

        <div className={classes.fields}>
          {fields.map((field, index) => (
            <div key={field?._id} className={classes.fieldContainer}>
              <FieldNumber number={index + 1} />
              <p className={classes.fieldName}>{field.name}</p>
            </div>
          ))}
        </div>

        <Divider className={classes.divider} />

        <p className={classes.generatedContentLabel}>Generated content</p>

        <div className={classes.outputExampleContent}>
          {template?.outputExample || '-'}
        </div>
      </div>
    </Modal>
  );
};

export default TemplatePreviewModal;
