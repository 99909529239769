import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import useStyles from './CarouselSlideDotIndicator.syles';

const CarouselSlideDotIndicator = ({ selected, small }) => {
  const classes = useStyles();

  return (
    selected ? (
      <FontAwesomeIcon icon="circle" className={clsx(classes.dot, classes.selected)} />
    ) : (
      <FontAwesomeIcon icon="circle" className={clsx(classes.dot, { [classes.small]: small })} />
    )
  );
};

export default CarouselSlideDotIndicator;
