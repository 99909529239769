import useUpdateCompany from './useUpdateCompany';
import useCompanies from './useCompanies';
import assignTemplate from './useAssignTemplate';
import unassignTemplate from './useUnassignTemplate';
import useGenerateReferralCode from './useGenerateReferralCode';

export default {
  useUpdateCompany,
  useCompanies,
  assignTemplate,
  unassignTemplate,
  useGenerateReferralCode,
};
