import React from 'react';

import loading from '@root/assets/loading.svg';

import useStyles from './Loading.styles';

/**
 * Basic loading screen to be used for non-generator pages loading
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = ({
  content,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.spinnerWrapper}>
      <div className={classes.spinner}>
        <img src={loading} alt="Loading" />
        <div className={classes.content}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Loading;
