import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    '&:hover': {
      background: theme.palette.primary.faded,
    },
    '&:hover $wordCount': {
      visibility: 'visible',
    },
    '&:not(:hover) $wordCount': {
      backgroundColor: 'rgba(82, 58, 231, 0.1)',
    },
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  editor: {
    width: '100%',
    '& > div > div': {
      fontSize: '14px !important',
      padding: theme.spacing(3.5, 7, 1, 7),
    },
  },
  rightSideContainerWrap: {
    width: 0,
    display: 'flex',
    alignItems: 'center',
  },
  rightSideContainer: {
    position: 'relative',
    margin: theme.spacing(0, 0, 0, 9.5),
  },
  newBadge: {
    backgroundColor: 'rgba(41, 128, 0, 0.05)',
    color: theme.palette.colors.success,
    padding: theme.spacing(0.5, 1),
    fontSize: '12px',
    borderRadius: theme.border.radius.default,
    animation: '$fade 6000ms forwards',
  },
  newBadgeWrap: {
    margin: theme.spacing(0, 0, 0, 7),
    height: 0,
  },
  '@keyframes fade': {
    '0%': {
      opacity: 1,
    },
    '16%': {
      opacity: 1,
    },
    '32%': {
      opacity: 1,
    },
    '48%': {
      opacity: 1,
    },
    '64%': {
      opacity: 1,
    },
    '80%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  readabilityPoint: {
    fontSize: 26,
    fontWeight: 600,
    color: theme.palette.primary.main,
    '&:before': {
      content: '" "',
    },
  },
  readabilityContent: {
    maxHeight: '35vh',
    overflow: 'auto',
    marginBottom: theme.spacing(7),
  },
  removeContent: {
    maxHeight: '35vh',
    overflow: 'auto',
  },
  popover: {
    marginLeft: theme.spacing(4),
    padding: theme.spacing(2, 2, 3, 2),
    width: theme.spacing(47.75),
    height: theme.spacing(38.5),
    overflow: 'hidden',
  },
  footer: {
    padding: theme.spacing(0, 7),
    margin: theme.spacing(0, 0, 1, 0),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  footerBadge: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: theme.spacing(3),
    margin: theme.spacing(0, 1, 0, 0),
    borderRadius: theme.border.radius.default,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    ...theme.typography.body2,

    '& svg': {
      height: 16,
      width: 16,
      margin: theme.spacing(0, 0.5, 0, 0),
    },
  },
  footerBadgeBlue: {
    backgroundColor: 'rgba(82, 58, 231, 0.1)',
  },
  wordCount: {
    visibility: 'hidden',
  },
  wordCountVisible: {
    visibility: 'visible',
  },
  onboardingMask: {
    position: 'absolute',
    width: 'calc(100% + 280px)',
    height: '100%',
  },
}));
