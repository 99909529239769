import React from 'react';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';
import useDeleteCatalog from '@root/resources/catalog/useDeleteCatalog';
import uiNotificationService from '@root/services/uiNotification.service';

const DeleteCatalogModal = ({ catalog, onClose }) => {
  const { mutateAsync: deleteCatalog } = useDeleteCatalog();

  const handleDeleteCatalogClick = async () => {
    const response = await deleteCatalog({ id: catalog._id });

    if (response.isBadRequest) {
      return;
    }

    uiNotificationService.showSuccessMessage('Catalog deleted.');
    onClose();
  };

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={async () => {
        await handleDeleteCatalogClick();
        onClose();
      }}
      title="Delete Catalog"
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      negative
    >
      {`Are you sure you want to delete ${catalog.title}?`}
    </ConfirmationModal>
  );
};

export default DeleteCatalogModal;
