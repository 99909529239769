import React from 'react';

import Button from '@root/components/buttons/Button';
import useDeleteFile from '@root/resources/file/useDeleteFile';
import { useGlobalModals } from '@root/views/Layout/modalsContext';
import Modal from '@root/components/modals/Modal';

import useStyles from './DeleteFileModal.styles';

const DeleteFileModal = ({ closeModal, modalOpen }) => {
  const classes = useStyles();

  const { mutateAsync: deleteFile } = useDeleteFile();
  const { state: { modalContent } } = useGlobalModals();

  const title = modalContent?.file?.title;
  const fileId = modalContent?.file?._id;

  const handleDelete = async () => {
    await deleteFile(fileId);
    closeModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      title={`Delete file '${title}'?`}
      subtitle="This action cannot be undone."
      classNames={{ paper: classes.paper }}
    >
      <div className={classes.buttonsContainer}>
        <Button variant="text" onClick={closeModal}>Discard</Button>
        <Button
          type="button"
          onClick={handleDelete}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteFileModal;
