/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbarButtonsWrap: {
    '& > span:not(:first-child), & > button': {
      borderRadius: theme.border.radius.default,
      marginTop: '0 !important',

      '& span': {
        padding: '0px 5.5px !important',
      },

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.colors.primaryFaded} !important`,
      },
    },
    // display: 'flex',
  },
  toolbarButtonSpacer: {
    width: theme.spacing(2),
  },
  alignButton: {
    marginTop: -3,
  },
}));

const TOOLBAR_NAME = 'copysmith-editor-toolbar';

const EditorToolbar = ({ className, id }) => {
  const classes = useStyles();
  return (
    <div id={id || TOOLBAR_NAME} className={clsx(classes.toolbarButtonsWrap, TOOLBAR_NAME, className || '')}>
      <select className="ql-size" defaultValue="">
        <option value="small" />
        <option value="" />
        <option value="large" />
        <option value="huge" />
      </select>
      <div className={classes.toolbarButtonSpacer} />
      <button type="button" className="ql-bold" />
      <button type="button" className="ql-italic" />
      <button type="button" className="ql-underline" />
      <select className="ql-color" />
      <select className="ql-background" />
      <div className={classes.toolbarButtonSpacer} />
      <button
        type="button"
        className="ql-list"
        value="ordered"
      />
      <button
        type="button"
        className="ql-list"
        value="bullet"
      />
      <select defaultValue="" className={clsx(classes.alignButton, 'ql-align ql-picker')}>
        <option value="" />
        <option value="center" />
        <option value="right" />
        <option value="justify" />
      </select>
      <div className={classes.toolbarButtonSpacer} />
      <button type="button" className="ql-link" />
      {/* <button type="button" className="ql-image" /> */}
    </div>
  );
};

EditorToolbar.NAME = TOOLBAR_NAME;

export default EditorToolbar;
