import React, { useMemo } from 'react';

import useTeamMembers from '@root/resources/user/useTeamMembers';
import AuthContext from '@root/resources/auth/auth.context';
import userService from '@root/resources/user/user.service';
import Avatar from '@root/components/Avatar';
import TruncatedText from '@root/components/TruncatedText';

import useStyles from './MembersList.styles';

const MembersList = () => {
  const classes = useStyles();

  const { currentUser } = AuthContext.useAuth();
  const { data: membersDataAll } = useTeamMembers({ allMembers: true });

  const membersToDisplay = useMemo(() => {
    const isMember = currentUser.roles.member;

    // filter out deactivated users and me
    const result = membersDataAll?.results?.filter(
      (member) => !member?.deactivated && member?._id !== currentUser?._id,
    );
    const me = membersDataAll?.results?.find((member) => member?._id === currentUser?._id);

    const filteredMembersByUserRole = result?.filter((member) => {
      const memberRoles = userService.getRoles(member);

      return memberRoles.includes('owner') || (isMember && memberRoles.includes('admin'));
    }) || [];

    return [me, ...filteredMembersByUserRole];
  }, [membersDataAll, currentUser]);

  return (
    <div className={classes.membersContainer}>
      {membersToDisplay?.map((member) => {
        const memberRoles = userService.getRoles(member);
        const highestRole = userService.getHighestRole(memberRoles);

        return (
          <div
            key={member?._id}
            className={classes.memberDataContainer}
          >
            <Avatar
              name={member?.displayName}
              userId={member?._id}
              size="medium"
              className={classes.avatar}
              popperEnabled={false}
              variant="square"
              oneChar={false}
            />

            <div className={classes.memberInfo}>
              <div className={classes.memberNameContainer}>
                <TruncatedText className={classes.memberName}>
                  {member?._id === currentUser?._id ? 'You' : member?.displayName}
                </TruncatedText>

                <div className={classes.memberRole}>{highestRole}</div>
              </div>

              <TruncatedText className={classes.memberEmail}>
                {member?.email}
              </TruncatedText>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MembersList;
