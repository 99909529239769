import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import theme from '@root/material.theme';

const colors = theme.palette;

const useStyles = makeStyles(() => ({
  colorBlock: {
    height: '150px',
    width: '100%',
    borderRadius: theme.border.radius.lg,
    marginBottom: '12px',
    maxWidth: '200px',
  },
  container: {
    marginRight: '24px',
  },
  boldText: {
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  lightText: {
    color: theme.palette.colors.mediumDarkGrey,
    fontSize: '0.75rem',
  },
}));

const CreateColors = ({ options }) => {
  const classes = useStyles();
  return (
    <Grid container>
      {options.map((color) => (
        <Grid
          item
          className={classes.container}
          xs={2}
        >
          <div className={classes.colorBlock} style={{ background: color.code }} />
          <Typography className={classes.boldText}>
            {color.name}
          </Typography>
          <Typography className={classes.lightText}>
            {color.code}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const PRIMARY_COLORS = [
  { name: 'Purple', code: colors.primary.main },
  { name: 'Violet', code: colors.colors.logoPurple },
  { name: 'Yellow', code: colors.colors.logoYellow },
  { name: 'Red', code: colors.colors.logoRed },
];

const SECONDARY_COLORS = [
  { name: 'Navy', code: colors.colors.navy },
  { name: 'Black', code: colors.colors.black },
  { name: 'Pink', code: colors.secondary.main },
  { name: 'Light Blue', code: colors.colors.darkBlueLighter },
  { name: 'Dark Blue', code: colors.colors.darkBlue },
];

const GREY_COLORS = [
  { name: 'Gray 100', code: colors.colors.lightGrey },
  { name: 'Gray 200', code: colors.colors.mediumLightGrey },
  { name: 'Gray 300', code: colors.colors.grey },
  { name: 'Gray 400', code: colors.colors.mediumDarkGrey },
  { name: 'Gray 500', code: colors.colors.darkGrey },
];

export default [
  {
    name: 'Primary colours',
    component: <CreateColors options={PRIMARY_COLORS} />,
  },
  {
    name: 'Accent colours',
    component: <CreateColors options={SECONDARY_COLORS} />,
  },
  {
    name: 'Shades of greys',
    component: <CreateColors options={GREY_COLORS} />,
  },

];
