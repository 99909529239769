import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useLikeDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id }) => api.documents.like(_id),
    {
      onMutate: async ({ _id, fileId }) => {
        const queryKey = ['documents.list', { fileId }];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const previousDocuments = queryClient.getQueryData(queryKey);

        if (!previousDocuments) {
          return null;
        }

        const doc = { ...previousDocuments.find((d) => d._id === _id) };

        doc.liked = !doc.liked;
        const updatedDocs = previousDocuments.map((d) => (d._id === _id ? doc : d));

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, () => updatedDocs);

        return { previousDocuments, queryKey };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(context.queryKey, context.previousDocuments);
      },
    },
  );
}
