import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
  rowSelected: {
    background: theme.palette.primary.faded,
    transition: 'all 300ms',
  },
  generationCell: {
    minWidth: 256,
  },
}));

export default useStyles;
