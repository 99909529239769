import React from 'react';

import { SearchField } from '@root/components/form';

import useStyles from './ProductSearch.styles';

const ProductSearch = ({ searchQuery, setSearchQuery }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchField
        classes={{ root: classes.searchField, focused: classes.focused }}
        placeholder="Search for a product"
        onChange={setSearchQuery}
        value={searchQuery}
        debounce
      />
    </div>
  );
};

export default ProductSearch;
