import { useQuery, useQueryClient } from 'react-query';

import api from '@root/api';
import bigCommerceService from '@root/services/bigCommerce.service';

const getProducts = async ({
  page,
  keyword,
  include,
  category,
  prevProducts,
}) => {
  const payload = bigCommerceService.getPayload();

  const products = await api.bigCommerce.getProducts({
    context: payload.sub,
    userEmail: payload.owner.email,
    userId: `${payload.owner.id}`,
    page,
    keyword,
    category,
    include,
  });

  if (page > 1) {
    return {
      ...products,
      data: [
        ...prevProducts.data,
        ...products.data,
      ],
    };
  }

  return products;
};

export default function useGetProducts({
  page = 1,
  keyword = '',
  include = [],
  category = '',
}) {
  const queryCLient = useQueryClient();
  const prevProducts = queryCLient.getQueryData(['bigCommerce.getProducts']) || { data: [] };

  return useQuery(['bigCommerce.getProducts'], () => getProducts({
    page,
    keyword,
    include,
    category: `${category}`,
    prevProducts,
  }), {
    refetchOnMount: 'always',
    keepPreviousData: false,
  });
}
