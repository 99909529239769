import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import DEPRECATEDConfirmationModal from '@root/components/modals/ConfirmationModal/DEPRECATEDConfirmationModal';
import DocumentView from '@root/components/DocumentView';
import aiTemplateResource from '@root/resources/aiTemplate';
import promptItemResource from '@root/resources/promptItem';
import { InputField } from '@root/components/form';

import EditPromptItemModal from './EditPromptItemModal';
import PromptUserFieldsForm from './PromptUserFieldsForm';
import useStyles from './ExperimentTab.styles';

const TemplateView = () => {
  const classes = useStyles();
  const params = useParams();
  const { data: template } = aiTemplateResource.useAiTemplate(params.id);
  const promptFilters = {
    templateType: template ? template.templateType : '',
  };
  const { data: promptItems, refetch: reloadPromptItems } = promptItemResource
    .usePromptItems(promptFilters, false);
  const { mutateAsync: deletePrompt } = promptItemResource.useDeletePromptItem();

  const [editPromptItem, setEditPromptItem] = React.useState(null);
  const [aiResults, setAiResults] = React.useState(null);
  const [generateFileData, setGenerateFileData] = React.useState({});
  const [promptItemToDelete, setPromptItemToDelete] = React.useState(null);

  const hasPromptItems = !promptItems || promptItems.length !== 0;

  const deletePromptItem = async () => {
    await deletePrompt({ id: promptItemToDelete._id });
    setPromptItemToDelete(null);
  };

  React.useEffect(() => {
    if (!template) {
      return;
    }
    reloadPromptItems();
  }, [template]);

  const onGenerated = (results, fileData) => {
    setAiResults(results);
    setGenerateFileData(fileData);
  };

  const promptsCount = (promptItems || []).length;
  const hasFields = template?.fields?.length > 0;

  return (
    <div>
      <ul>
        <li>Add 2-10 prompts</li>
        <li>Run generate to see results created by Open AI</li>
        <li>Adjust prompts and run again :)</li>
      </ul>
      {!hasFields && (
        <div className={classes.error}>
          Template does not have UI fields, unable to run experiment.
        </div>
      )}
      <Grid container>
        <Grid
          sm={6}
          xs={12}
          item
        >
          <div className={classes.generateArea}>
            <div className={classes.description}>
              {template && (
                <>
                  {template.instructions?.default && (
                    <InputField
                      label="instruction"
                      value={template.instructions?.default}
                      readOnly
                    />
                  )}
                  <PromptUserFieldsForm
                    template={template}
                    onGenerated={onGenerated}
                    promptsCount={promptsCount}
                  />
                </>
              )}
            </div>
            <div>
              {(aiResults || []).map((t) => {
                return (
                  <DocumentView
                    key={template.templateType}
                    document={t}
                    fileData={generateFileData}
                    options={{
                      canEdit: false,
                      canDelete: false,
                      showVote: false,
                      canLike: false,
                      canCopy: false,
                      canFlag: false,
                    }}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        {hasPromptItems && (
          <Grid
            sm={6}
            xs={12}
            item
          >
            {editPromptItem && (
              <EditPromptItemModal
                onClose={() => setEditPromptItem(null)}
                template={template}
                promptItem={editPromptItem}
              />
            )}
            <div className={classes.topActions}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => setEditPromptItem({ _id: null })}
                disabled={!hasFields}
              >
                New prompt item
              </Button>
            </div>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Prompt
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(promptItems || []).map((item) => {
                    const html = item.aiPrompt.replace(new RegExp('\\r?\\n', 'g'), '<br />');
                    return (
                      <TableRow key={item._id}>
                        <TableCell className={classes.promptTextWrap}>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: html }} />
                          <div>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => setPromptItemToDelete(item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {promptsCount === 0 && (
                    <TableRow>
                      <TableCell>
                        Nothing here yet.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

      </Grid>

      <DEPRECATEDConfirmationModal
        titleText="Are you sure you want to delete prompt item?"
        buttonText="Delete"
        bodyText="Please, make sure to keep at least 5 prompts to keep great results"
        modalOpen={promptItemToDelete}
        setModalOpen={() => setPromptItemToDelete(null)}
        handleClick={async () => deletePromptItem()}
      />
    </div>
  );
};

export default TemplateView;
