import React from 'react';

import LoginPage from '../Authentication/LogInPage';

const AkeneoConnect = () => {
  const query = new URLSearchParams(window.location.search);

  const pimUrl = query.get('pimUrl');

  return (
    <LoginPage
      returnUrlOverride={`/akeneo-connect-callback?pimUrl=${pimUrl}`}
      withPasswordRestore={false}
      title="Choose an account to connect with Akeneo"
      buttonText="Connect"
    />
  );
};

export default AkeneoConnect;
