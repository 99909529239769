import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4, 0, 0, 0),
  },
  form: {
    width: 360,
    gap: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    padding: theme.spacing(0, 0, 2.5, 0),
  },
  submit: {
    alignSelf: 'flex-start',
  },
}));

export default useStyles;
