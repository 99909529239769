import uiService from '@root/services/uiNotification.service';

const afterRequestHandler = ({ response, options = {} }) => {
  if (!response) return;
  const { errors = [], isBadRequest = false } = response;
  const { disableFirstErrorNotification = () => {} } = options;

  if (isBadRequest && !disableFirstErrorNotification(errors)) {
    const [firstError] = errors;
    Object.values(firstError).forEach((message) => {
      uiService.showErrorMessage(message);
    });
  }
};

export default afterRequestHandler;
