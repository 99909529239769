import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  InsertDriveFile,
  Error,
  CheckCircle,
  Visibility,
} from '@material-ui/icons';
import clsx from 'clsx';

import Delete from '@root/components/SVGIcon/Delete';
import useModalState from '@root/utils/hooks/useModalState';
import Button from '@root/components/buttons/Button';
import useUploadFile from '@root/resources/catalog/useUploadFile';

import PreviewModal from '../ProductUploadPreviewModal';
import ProductFileUploader from '../ProductFileUploader';

import useStyles from './ProductUploadForm.styles';

const ProductUploadForm = ({
  onSubmit,
  selectedFile,
  setSelectedFile,
}) => {
  const classes = useStyles();
  const [displayedFileMeta, setDisplayedFileMeta] = useState({});
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const { mutateAsync: uploadFile } = useUploadFile();

  const [
    isPreviewOpen,
    openPreviewModal,
    closePreviewModal,
  ] = useModalState(false);

  const dropSelectedFile = () => {
    setErrors([]);
    setDisplayedFileMeta({});
    setSelectedFile(null);
  };

  const onFileSelect = async (file) => {
    setDisplayedFileMeta({ name: file.name, size: file.size });
    setErrors([]);
    const response = await uploadFile({
      file,
      id,
    });
    if (response.isBadRequest) {
      const responseErrors = response.errors?.filter((e) => e.file) || [];

      if (responseErrors.length > 0) {
        setErrors(responseErrors);
        return;
      }

      setDisplayedFileMeta({});
      return;
    }
    setSelectedFile(response);
  };

  const errorsContent = errors.length !== 0 && (
    <div className={classes.errorsWrap}>
      <Error className={classes.errorIcon} />
      <div>
        Upload failed. The following errors were found:
        <ul className={classes.errorsList}>
          <li>
            {errors.map((e) => e && e.file).join('.')}
          </li>
        </ul>
      </div>
    </div>
  );

  const successContent = (
    <div className={classes.successWrap}>
      <CheckCircle className={classes.successIcon} />
      File successfully uploaded
    </div>
  );

  const warningContent = [
    errorsContent,
  ].find((content) => !!content);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          Upload
        </div>
        <div className={classes.fileUploadWrapper}>
          <ProductFileUploader
            onFileSelect={onFileSelect}
            dropSelectedFile={dropSelectedFile}
            file={selectedFile}
            uploadDisabled={!!selectedFile
              || (displayedFileMeta.name && errors.length === 0)}
          />
          {displayedFileMeta.name && (
            <div className={classes.uploadInfo}>
              <div className={classes.uploadInfoMeta}>
                <div className={classes.uploadInfoText}>
                  <InsertDriveFile className={classes.iconFile} />
                  <div className={classes.progressBarWrap}>
                    {displayedFileMeta.name}
                    &nbsp;&nbsp;
                    {!selectedFile && (
                      <div className={clsx(classes.progressBar, {
                        [classes.progressBarError]: errors.length,
                      })}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.fileActions}>
                  {selectedFile && (
                    <span
                      className={classes.actionIcon}
                      onClick={openPreviewModal}
                    >
                      <Visibility className={classes.visibilityAction} />
                    </span>
                  )}
                  <span
                    className={classes.actionIcon}
                    onClick={dropSelectedFile}
                  >
                    <Delete fill="#523AE7" />
                  </span>
                </div>

              </div>
              {warningContent}
              {selectedFile && successContent}
            </div>
          )}
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={onSubmit}
          disabled={!!warningContent || !selectedFile}
        >
          Next
        </Button>
      </div>
      {isPreviewOpen && (
        <PreviewModal
          title={selectedFile?.fileName}
          rows={selectedFile?.rows}
          onClose={closePreviewModal}
        />
      )}
    </div>

  );
};

export default ProductUploadForm;
