import {
  Backdrop, Fade, Modal,
  Button,
} from '@material-ui/core';
import React from 'react';

import authContext from '@root/resources/auth/auth.context';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import { NEW_USER_TOUR_ID } from '@root/views/Dashboard/components/Modals/NewUserTour';

import useStyles from './TourModal.styles';

const TourModal = ({ modalOpen }) => {
  const { dispatch } = useGlobalModals();
  const { currentUser: user } = authContext.useAuth();
  const classes = useStyles();

  const handleSkipTour = () => {
    dispatch({ type: actions.CLOSE_MODAL });
  };

  const handleAcceptTour = () => {
    dispatch({ type: actions.OPEN_MODAL, payload: { modalId: NEW_USER_TOUR_ID } });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleSkipTour}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={modalOpen}>
        <div className={classes.paper}>
          <div className={classes.paperWrapper}>
            Hey
            {' '}
            {user.firstName}
            , welcome to Copysmith!
          </div>
          <div className={classes.onBoardingText}>
            We know it might seem a bit different compared to other writing
            tools you’ve used before. We’d love to give you a brief overview of
            what we can do, help you create a file, and introduce you to our AI!
          </div>
          <div
            className={classes.skipTourWrapper}
          >
            <Button
              variant="text"
              onClick={handleSkipTour}
              className={classes.skipTourButton}
            >
              Skip Tour
            </Button>
            <Button
              style={{ marginTop: 0 }}
              onClick={handleAcceptTour}
            >
              Let&apos;s go!
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default TourModal;
