import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import useBeforeUnload from '@root/views/File/hooks/useBeforeUnload';
import WizardLayout from '@root/views/Layout/WizardLayout';
import useStyles from '@root/views/BlogKickstarter/BlogKickstarter.styles';
import newMaterialTheme from '@root/newMaterial.theme';
import useImportCatalogItems from '@root/resources/catalogItem/useImportCatalogItems';
import useInvalidateLimits from '@root/resources/billing/useInvalidateLimits';

import ProductFieldSelectionForm from './Components/ProductFieldSelectionForm';
import ProductUploadForm from './Components/ProductUploadForm';
import ProductFieldMappingForm from './Components/ProductFieldMappingForm';

const steps = ['Field selection', 'Upload', 'Field mapping'];
const previousTexts = ['Back to catalog', 'Back to field selection', 'Back to upload'];
const promptMessage = 'Are you sure you want to leave? All progress will be lost';

const UploadProductWizard = () => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fieldsSelected, setSelectedFields] = useState({
    description: false,
    descriptionKeywords: false,
    bulletPoints: false,
    bulletPointsKeywords: false,
    titleKeywords: false,
  });
  const { mutateAsync: importCatalogItems, isImportLoading } = useImportCatalogItems();
  const { invalidateLimits } = useInvalidateLimits();
  const { id } = useParams();

  const classes = useStyles({ step });

  useBeforeUnload({
    when: showPrompt,
    message: promptMessage,
  });

  const previousText = previousTexts[step];
  const handleStepChange = () => {
    if (!step) {
      history.push(`/catalog/${id}`);
    }
    setStep((currentStep) => currentStep - 1);
  };

  const handleSelectFieldsSubmit = (values) => {
    setSelectedFields(values);
    setStep(1);
  };

  const handleUploadSubmit = () => {
    setShowPrompt(true);
    setStep(2);
  };

  const handleFieldMappingSubmit = async (fileMapping) => {
    const resp = await importCatalogItems({
      catalogId: id,
      fileId: selectedFile.fileId,
      fileMapping,
    });
    setShowPrompt(false);

    if (resp.isBadRequest) {
      return;
    }

    await invalidateLimits(0);
    history.push(`/catalog/${id}`);
  };

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <WizardLayout
        steps={steps}
        step={step}
        onStepChange={handleStepChange}
        prevAvailable
        previousText={previousText}
      >
        <div className={classes.blogKickstarterRoot}>
          {step === 0 && (
            <ProductFieldSelectionForm
              fieldsSelected={fieldsSelected}
              onSubmit={handleSelectFieldsSubmit}
            />
          )}

          {step === 1 && (
            <ProductUploadForm
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              onSubmit={handleUploadSubmit}
            />
          )}

          {step === 2 && (
            <ProductFieldMappingForm
              fieldsSelected={fieldsSelected}
              selectedFile={selectedFile}
              onSubmit={handleFieldMappingSubmit}
              isImportLoading={isImportLoading}
            />
          )}
        </div>
      </WizardLayout>
    </ThemeProvider>
  );
};

export default UploadProductWizard;
