import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import billingResource from '@root/resources/billing';

import useSectionStyles from '../Sections.styles';

const RewardWords: FC = () => {
  const sectionClasses = useSectionStyles();
  const { data: billingInfo } = billingResource.useBillingInfo();
  const rewardWords = billingInfo?.rewardCredits;

  return (
    <article className={sectionClasses.articleContainer}>
      {rewardWords ? (
        <div>
          <Typography classes={{ root: sectionClasses.articleTitle }}>
            Rewarded Words
          </Typography>
          <Typography classes={{ root: sectionClasses.articleValue }}>
            {rewardWords.toLocaleString()}
          </Typography>
        </div>
      ) : (
        <span className={sectionClasses.emptyText}>
          Want free words? Learn how&nbsp;
          <Link
            to="/rewards"
            className={sectionClasses.linkText}
          >
            here
          </Link>
          &nbsp;.
        </span>
      )}
    </article>
  );
};

export default RewardWords;
