import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 12,
  },
  wordsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: 16,
  },
  tooltipIcon: {
    marginLeft: 4,
  },
  inputButton: {
    color: theme.palette.colors.neutrals400,
  },
}));

export default useStyles;
