import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import Stepper from '@root/components/Stepper';
import useProcessBulk from '@root/resources/akeneo/useProcessBulk';
import usePublishBulk from '@root/resources/akeneo/usePublishBulk';
import useGetCurrentBulkJob from '@root/resources/akeneo/useGetCurrentBulkJob';
import uiNotificationService from '@root/services/uiNotification.service';
import Loading from '@root/components/Loading/Loading';

import MainLayout from '../Layout/MainLayout';

import SelectProducts from './components/SelectProducts';
import Progress from './components/Progress';
import Result from './components/Result';
import Success from './components/Success';
import useStyles from './AkeneoBulkCopy.styles';

const steps = [
  'Select Products',
  'Process Bulk',
  'Review generations',
  'Publish',
];

const AkeneoBulkCopy = () => {
  const classes = useStyles();

  const history = useHistory();

  const [page, setPage] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    mutateAsync: processBulk,
  } = useProcessBulk();
  const {
    mutateAsync: publishBulk,
  } = usePublishBulk();
  const {
    data: currentBulkJob = {},
  } = useGetCurrentBulkJob();

  const onProductsSelect = async (productCodes) => {
    try {
      setIsLoading(true);
      const resp = await processBulk({ productCodes });
      if (resp.isBadRequest) {
        return;
      }
      setPage(1);
    } finally {
      setIsLoading(false);
    }
  };
  const onBulkDone = () => {
    setPage(2);
  };
  const onBulkFailed = () => {
    setPage(0);
    uiNotificationService.showErrorMessage('Insufficient words.');
  };

  const onPublish = async (data) => {
    const resp = await publishBulk(data);
    if (resp.isBadRequest) {
      return;
    }
    setPage(3);
  };

  const moveToStepOne = () => {
    setPage(0);
  };

  const getPage = () => {
    switch (page) {
      case 0:
        return <SelectProducts onProductsSelect={onProductsSelect} />;
      case 1:
        return <Progress onBulkDone={onBulkDone} onBulkFailed={onBulkFailed} />;
      case 2:
        return <Result onBack={moveToStepOne} onPublish={onPublish} />;
      case 3:
        return <Success onBack={moveToStepOne} />;
      default:
        return null;
    }
  };

  const getStepDescription = () => {
    switch (page) {
      case 0:
        return 'Select the products you\'d like to create product descriptions for';
      case 2:
        return 'Review, edit and publish your new product descriptions to Akeneo';
      case 1:
      case 3:
      default:
        return null;
    }
  };

  const pageView = getPage();
  const stepDescription = getStepDescription();

  React.useMemo(() => {
    if (['pending', 'inProgress'].includes(currentBulkJob.state)) {
      setPage(1);
    }
  }, [currentBulkJob]);

  if (isLoading) {
    return (
      <Loading
        content={(
          <>
            <div>Hold on a moment. A work of art is being crafted!</div>
            <Button onClick={() => history.push('/templates/my-files')}>Back to files</Button>
          </>
        )}
      />
    );
  }

  if (page === 1) {
    return pageView;
  }

  return (
    <MainLayout>
      <div className={classes.root}>
        <div className={classes.head}>
          <div className={classes.title}>
            Bulk generation
          </div>
          <Stepper
            steps={steps}
            stepIndex={page}
            forceCheck={page === 3}
          />
        </div>
        <div className={classes.description}>
          {stepDescription}
        </div>
        <div className={classes.content}>
          {pageView}
        </div>
      </div>
    </MainLayout>
  );
};

export default AkeneoBulkCopy;
