import React from 'react';
import clsx from 'clsx';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';

import { USE_CASE_MODAL_ID, NOTIFICATION_MODAL_ID } from '@root/views/Dashboard/components/Modals';
import { useGlobalModals, actions } from '@root/views/Layout/modalsContext';
import ExternalSourcesContext from '@root/resources/externalSources/externalSources.context';

import useCommonStyles from './tableComponents.styles';

const useStyles = makeStyles({
  emptyTableContainer: {
    height: '3.25rem',
    cursor: 'default',
  },
  emptyTableText: {
    cursor: 'pointer',
    color: '#aaa',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    padding: '0.25rem',
    display: 'inline-block',
  },
});

const CreateFileRow = ({ isSharableFolder }) => {
  const { dispatch } = useGlobalModals();
  const commonClasses = useCommonStyles();
  const { currentExternalSource } = ExternalSourcesContext.useExternalSource();

  const classes = useStyles();

  const handleCreateNewFile = () => dispatch({
    type: actions.OPEN_MODAL,
    payload: {
      modalId: USE_CASE_MODAL_ID,
    },
  });

  const openSharingNotificationModal = () => {
    dispatch({
      type: actions.OPEN_MODAL,
      payload: {
        modalId: NOTIFICATION_MODAL_ID,
        modalContent: {
          title: 'New shared file',
          description: 'You are about to create a new file in a shared folder. This means that it can be accessed by everyone within this folder.',
          onSubmit: handleCreateNewFile,
        },
      },
    });
  };

  // TODO: refactor to get rid of fixed number of table cells
  const {
    fixedColumn,
  } = currentExternalSource.toggles;

  return (
    <TableRow>
      <TableCell padding="checkbox" classes={{ root: clsx({ [commonClasses.fixedCell]: fixedColumn }) }} />
      <TableCell
        className={classes.emptyTableContainer}
        classes={{
          root: clsx({
            [commonClasses.fixedCell]: fixedColumn,
            [commonClasses.secondCell]: fixedColumn,
          }),
        }}
      />
      <TableCell
        className={classes.emptyTableContainer}
        classes={{
          root: clsx({
            [commonClasses.fixedCell]: fixedColumn,
            [commonClasses.thirdCell]: fixedColumn,
            [commonClasses.fixedWithoutBorder]: fixedColumn,
          }),
        }}
        padding="none"
      >
        <div
          className={classes.emptyTableText}
          onClick={isSharableFolder ? openSharingNotificationModal : handleCreateNewFile}
        >
          + Create new file
        </div>
      </TableCell>
      <TableCell className={classes.emptyTableContainer} />
      <TableCell className={classes.emptyTableContainer} />
      <TableCell className={classes.emptyTableContainer} />
      <TableCell className={classes.emptyTableContainer} />
    </TableRow>
  );
};

export default CreateFileRow;
