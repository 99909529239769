import React from 'react';
import { ThemeProvider, Typography } from '@material-ui/core';

import TransparentLoading from '@root/components/TransparentLoading';
import ProfileLayout from '@root/views/Profile/components/ProfileLayout';
import useBillingInfo from '@root/resources/billing/useBillingInfo';
import AuthContext from '@root/resources/auth/auth.context';
import usePlanInfo from '@root/resources/billing/usePricing';
import newMaterialTheme from '@root/newMaterial.theme';
import { PLANS_IDS } from '@root/resources/billing/billingInfo.helpers';

import AccountSection from './components/Sections/AccountSection';
import AddonsSection from './components/Sections/AddonsSection';
import InvoicesSection from './components/Sections/InvoicesSection';
import useStyles from './Billing.styles';
import CancelSubscription from './components/Sections/CancelSubscriptionSection';
import Overage from './components/Sections/OverageSection';

const Billing = () => {
  const { currentUser: user } = AuthContext.useAuth();
  const { data: billingInfoData = { addons: {} }, isFetching } = useBillingInfo();
  const { data: { currentPlan } = {} } = usePlanInfo();

  const classes = useStyles();

  const addons = Object.keys(billingInfoData.addons).map((addonType) => ({
    type: addonType,
    quantity: billingInfoData.addons[addonType],
  }));

  const isStarterOrPro = [
    ...PLANS_IDS.starters,
    ...PLANS_IDS.pros,
  ];

  const overagesSectionEnabled = isStarterOrPro.includes(billingInfoData?.priceId)
  && user.featureToggles.nonEnterpriseOverage;
  const isOwner = user.roles.owner;

  if (isFetching) {
    return (
      <ProfileLayout>
        <TransparentLoading />
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <ThemeProvider theme={newMaterialTheme}>
        <div>
          <Typography variant="h3" className={classes.title}>
            Billing
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Manage your plan and billing details.
          </Typography>
        </div>

        <div className={classes.divider} />

        <div className={classes.billingContent}>
          <AccountSection
            plan={currentPlan}
            billingData={billingInfoData}
          />

          {overagesSectionEnabled && (
            <Overage billingData={billingInfoData} isOwner={isOwner} />
          )}

          {!user.onTeamPlan && (
            <AddonsSection addons={addons} />
          )}

          <InvoicesSection
            pastInvoices={billingInfoData?.pastInvoices}
          />

          <div className={classes.sectionDivider} />

          <CancelSubscription />
        </div>
      </ThemeProvider>
    </ProfileLayout>
  );
};

export default Billing;
