import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    zIndex: '10000',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    position: 'absolute',
    padding: '10px 15px',
    minHeight: '30px',
    minWidth: '30px',
    borderRadius: theme.border.radius.default,
    marginRight: '0.5rem',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    transition: 'ease-in-out',
    fontSize: '16px',
    animation: 'MoveUpDown 1.5s linear infinite',
    webkitAnimation: 'MoveUpDown 1.5s linear infinite',
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '30%',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: `${theme.palette.primary.main} transparent transparent`,
      right: '100%',
      transform: 'rotate(90deg)',
    },
  },
  right: {
    '&::after': {
      right: '-1.25rem',
      transform: 'rotate(270deg)',
    },
  },
  invisible: {
    display: 'none',
  },
}));
