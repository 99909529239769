// Temporarily disable camelcase due to Landing Page fields
/* eslint-disable camelcase */
import { escapeCSV } from './functions';

export default class LandingPageExporter {
  constructor() {
    this.csv_string = '"Headline","Subheadline","Feature 1","Feature Description 1","Feature 2","Feature Description 2","Feature 3","Feature Description 3","Closing"\r\n';
  }

  addPage(
    headline,
    subheadline,
    feature_1,
    feature_description_1,
    feature_2,
    feature_description_2,
    feature_3,
    feature_description_3,
    closing,
  ) {
    this.csv_string += `"${escapeCSV(headline)}","${escapeCSV(
      subheadline,
    )}","${escapeCSV(feature_1)}","${escapeCSV(
      feature_description_1,
    )}","${escapeCSV(feature_2)}","${escapeCSV(
      feature_description_2,
    )}","${escapeCSV(feature_3)}","${escapeCSV(
      feature_description_3,
    )}","${escapeCSV(closing)}"\r\n`;
  }

  getString() {
    return this.csv_string;
  }
}
