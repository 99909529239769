import React from 'react';
import clsx from 'clsx';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btnPrimary: {
    color: 'white',
  },
  btnOutlined: {
    color: theme.palette.primary.main,
  },
}));

const ProgressButton = ({
  isLoading = false,
  children,
  ...props
}) => {
  const classes = useStyles();

  const { startIcon, btnRef, variant, ...propsToPass } = props;

  return (
    <Button
      ref={btnRef}
      variant={variant}
      startIcon={isLoading
        ? (
          <CircularProgress
            size={16}
            className={clsx({
              [classes.btnPrimary]: variant === 'contained',
              [classes.btnOutlined]: variant === 'outlined',
            })}
          />
        )
        : startIcon}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsToPass}
    >
      {children}
    </Button>
  );
};

export default ProgressButton;
