const formatDollarIfInteger = (price, { cents } = { cents: false }) => {
  if (!price) return 'Custom price';

  if (Number.isInteger(price) || (typeof price === 'string' && price.charAt(0) !== '$')) {
    return `$${cents ? (price / 100).toFixed(2) : price}`;
  }

  return price;
};

const titleForFile = (title) => {
  if (!title) {
    return 'Untitled';
  }

  return `Untitled ${title}`;
};

const truncateString = (str, n, suffix) => {
  return (str.length > n) ? `${str.substr(0, n - 1)}${suffix}` : str;
};

const idToDecimalSum = (id) => {
  if (!id) {
    return '';
  }

  return [...id].reduce((acc, current) => {
    const result = acc + current.charCodeAt(0);
    return result;
  }, 0);
};

const getUserInfoToDisplay = ({ firstName, surname, email }) => {
  if (firstName || surname) {
    return [firstName, surname].join(' ');
  }

  return email;
};

export {
  formatDollarIfInteger,
  titleForFile,
  truncateString,
  idToDecimalSum,
  getUserInfoToDisplay,
};
