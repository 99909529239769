import React, { useCallback } from 'react';

import Modal from '@root/components/modals/Modal';
import Button from '@root/components/buttons/Button';
import ProgressButton from '@root/components/ProgressButton';

import { useCreateAndExploreContext } from '../../context/CreateAndExplore.context';
import { modalsActions } from '../../context/confirmationModalsReducer';

import useStyles from './RemoveConfirmationModal.styles';

const RemoveConfirmationModal = () => {
  const classes = useStyles();

  const {
    modalsState,
    modalsDispatch,
    removeFromCommunity,
    isTemplateProcessing,
  } = useCreateAndExploreContext();

  const { open, template } = modalsState.removeFromCommunity;

  const handleModalClose = () => {
    if (isTemplateProcessing) {
      return;
    }

    modalsDispatch({
      type: modalsActions.TOGGLE_REMOVE_FROM_COMMUNITY_MODAL,
      payload: null,
    });
  };

  const handleRemoveClick = useCallback(async () => {
    if (isTemplateProcessing) {
      return;
    }

    await removeFromCommunity(template?._id);
    handleModalClose();
  }, [template, handleModalClose]);

  return (
    <Modal
      title="Remove template"
      open={open}
      onClose={handleModalClose}
      classNames={{ paper: classes.modal }}
    >
      <div className={classes.content}>
        <p className={classes.contentText}>
          Removing this template will remove it from the community but users who have it
          saved to their account will still be able to access and use the existing template as is.
          Are you sure you want to remove this template from being saved by future users?
        </p>
      </div>

      <div className={classes.buttonsContainer}>
        <Button
          variant="text"
          onClick={handleModalClose}
        >
          Cancel
        </Button>

        <ProgressButton
          variant="contained"
          onClick={handleRemoveClick}
          isLoading={isTemplateProcessing}
          size="small"
        >
          {!isTemplateProcessing && 'Remove'}
          {isTemplateProcessing && 'Loading...'}
        </ProgressButton>
      </div>
    </Modal>
  );
};

export default RemoveConfirmationModal;
