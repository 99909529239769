import React from 'react';

import womenFashion from '@root/assets/engineImages/women-fashion.png';
import womenFashionFull from '@root/assets/engineImages/women-fashion-big.jpg';
import DocumentView from '@root/components/DocumentView';

export default {
  _id: 'FT-WOMEN',
  title: "Women's Fashion",
  description: 'Ali keeps up with the latest trends in women’s fashion.  Improve engagement and create stunning product descriptions that set you apart.',
  longerDescription: ' Ali provides expert content to improve eCommerce engagement and produce stunning product descriptions that set you apart from the competition, all while helping you convert more customers into sales.',
  imageSrc: womenFashion,
  bigImg: womenFashionFull,
  sampleGenerations: [
    '• Modern, Elegant, and Functional: This top has all the features you need to make it a staple in your closet. The flattering fit and zip detail is eye-catching, while the hidden pockets add functionality. The shirttail hem means this top can be layered over a camisole or a matching jacket for endless possibilities. Featuring an animal print that makes you feel like you\'re on safari, this top is sure to be a head turner.',
    {
      content: <DocumentView
        noMargin
        key="322"
        document={{
          content: [
            'Your go-to bestie to help you dress for success. Let\'s chat!',
            'Big, bold, trendy',
            'As featured on Allure, this stunning green dress will have you looking like a sophisticated fashion editor.',
          ],
          templateType: 'facebookAd',
          liked: false,
          _id: '1',
          editing: false,
          votes: null,
        }}
        fileData={{
          companyName: 'Copysmith Boutique',
        }}
        saveEditor={false}
        options={{ showFooter: false, hideWordsBadges: true, canEdit: false }}
        onCopy={() => {}}
      />,
      noPadding: true,
    },
    <div>
      <h2 style={{ fontWeight: 500, fontSize: '24px' }}>INC Animal Print Zip-Pocket Top from Copysmith Boutique</h2>
      For those times that you want to dress the part, this animal
      print top will be your new best friend.
      A super-trendy yet still timeless print makes this top perfect for everyday wear. We love
      pairing it with a pair of white denim shorts and black pumps.
      Looking for something more casual?
      Wear it with a pair of distressed blue jeans and some sneakers.
    </div>,
  ],
};
