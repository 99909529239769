import React from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, MenuItem, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useClasses = makeStyles((theme) => ({
  dialog: {
    minHeight: `calc(100vh - ${theme.spacing(20)}px)`,
    maxHeight: `calc(100vh - ${theme.spacing(20)}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rootList: {
    flexGrow: 1,
    overflow: 'auto',
    marginLeft: theme.spacing(-2.5),
  },
  category: {
    paddingLeft: theme.spacing(2.5),
  },
  expandStub: {
    width: theme.spacing(4.5),
  },
}));

const CategoryModal = ({
  categoriesTree,
  onSelect,
  onClose,
  open,
  categories,
}) => {
  const classes = useClasses();

  const [expandedCategories, setExpandedCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setExpandedCategories([]);
      setSelectedCategories(categories);
    }
  }, [open]);

  const handleExpand = (c) => (e) => {
    e.stopPropagation();

    if (expandedCategories.includes(c.code)) {
      setExpandedCategories(expandedCategories.filter((ec) => ec !== c.code));
    } else {
      setExpandedCategories([...expandedCategories, c.code]);
    }
  };

  const handleSelect = (c) => () => {
    if (selectedCategories.includes(c.code)) {
      setSelectedCategories(selectedCategories.filter((sc) => sc !== c.code));
    } else {
      setSelectedCategories([...selectedCategories, c.code]);
    }
  };

  const handleOnSelect = () => {
    onSelect(selectedCategories);
  };

  const renderCategory = (category, visible) => {
    if (!visible) {
      return null;
    }

    return (
      <React.Fragment key={category.code}>
        <div className={classes.category}>
          <MenuItem disableRipple onClick={handleSelect(category)}>
            {(category.children || []).length !== 0 ? (
              <IconButton
                onClick={handleExpand(category)}
              >
                {expandedCategories.includes(category.code) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            ) : (
              <div className={classes.expandStub} />
            )}
            <Checkbox
              color="primary"
              checked={selectedCategories.includes(category.code)}
              onClick={handleSelect(category)}
            />
            {category.label}
          </MenuItem>
          {category.children && (
            <List>
              {category.children.map((c) => renderCategory(c, expandedCategories.includes(category.code)))}
            </List>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        Select category
      </DialogTitle>
      <DialogContent
        className={classes.dialog}
      >
        <List className={classes.rootList}>
          {categoriesTree.map((c) => renderCategory(c, true))}
        </List>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!selectedCategories}
            onClick={handleOnSelect}
          >
            Select
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryModal;
