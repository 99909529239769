import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepCircle: {
    width: 24,
    height: 24,
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.colors.primary,
    border: `1px solid ${theme.palette.colors.primary}`,
  },
  checked: {
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.colors.white,
    borderColor: 'transparent',
  },
  disabled: {
    backgroundColor: theme.palette.colors.grey,
    color: theme.palette.colors.white,
    borderColor: 'transparent',
  },
}));

export default useStyles;
