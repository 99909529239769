import React from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import AuthContext from '@root/resources/auth/auth.context';
import newApi from '@root/api/newApi';
import ProfileLayout from '@root/views/Profile/components/ProfileLayout';
import companyResource from '@root/resources/company';
import uiNotificationService from '@root/services/uiNotification.service';
import { changeEmail, restorePassword } from '@root/services/auth.service';
import { InputField, FormField, CheckboxField, SelectField } from '@root/components/form';
import Button from '@root/components/buttons/Button';
import translateLanguages from '@root/components/translateLanguages';
import { validations } from '@root/utils/validation/formValidations';

import useStyles from './EditProfile.styles';
import KeywordInput from './components/KeywordInput';

const personalFormSchema = Yup.object({
  name: validations.name,
  surname: validations.surname,
  email: validations.email,
});

const EditProfile = () => {
  const { currentUser: user, currentCompany, fetchAuthData } = AuthContext.useAuth();
  const { mutateAsync: updateCompany } = companyResource.useUpdateCompany();

  const classes = useStyles();

  const submitProfile = async (values, { setSubmitting }) => {
    try {
      const {
        // user fields
        name,
        surname,
        email,
      } = values;

      const userRequest = {
        firstName: name,
        surname,
      };

      const emailChanged = email && email !== user.email;
      if (emailChanged) {
        const { success } = await changeEmail(email);
        if (!success) {
          return;
        }
      }

      await newApi.user.update(user._id, userRequest);

      uiNotificationService.showSuccessMessage(!emailChanged ? 'Profile has been updated' : `Check your mail (${email}) to confirm email change`);
    } catch (e) {
      uiNotificationService.showErrorMessage(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const submitCompany = async (values, { setSubmitting }) => {
    const {
      url,
      company,
      defaultDescription,
      defaultKeywords: defaultKeywordsValues,
      prefillInputs,
      language,
    } = values;

    const { isBadRequest } = await updateCompany({
      name: company,
      url,
      description: defaultDescription,
      keywords: defaultKeywordsValues,
      prefillInputs: !!prefillInputs,
      language,
    });
    await fetchAuthData();
    setSubmitting(false);
    if (!isBadRequest) {
      uiNotificationService.showSuccessMessage('Company information has been updated.');
    }
  };

  const resetPassword = async () => {
    try {
      await restorePassword(user.email);
      uiNotificationService.showSuccessMessage('Check your email');
    } catch (e) {
      uiNotificationService.showErrorMessage(e.message);
    }
  };

  // initial values
  let name = '';
  let surname = '';
  const email = user.email || '';
  const company = currentCompany.company || '';
  const defaultDescription = currentCompany.defaultDescription || '';
  const url = currentCompany.companyUrl || '';
  const prefillInputs = currentCompany.prefillInputs || false;
  const defaultKeywords = currentCompany.defaultKeywords || [];
  const language = currentCompany.language || 'en';

  if (user.firstName && user.surname) {
    name = user.firstName;
    surname = user.surname;
  } else if (user.displayName) {
    const splitDisplayName = user.displayName.split(' ');
    [name, surname] = splitDisplayName;
    name = name || '';
    surname = surname || '';
  }

  return (
    <ProfileLayout>
      <div className={classes.editProfileRoot}>
        <div className={classes.title}>Personal Information</div>
        <Formik
          initialValues={{
            name,
            surname,
            email,
          }}
          validationSchema={personalFormSchema}
          onSubmit={submitProfile}
        >
          {({ isSubmitting }) => {
            return (
              <Form className={classes.form}>
                <div className={classes.nameContainer}>
                  <FormField
                    name="name"
                    label="First Name"
                    placeholder="First Name"
                    component={InputField}
                    required
                  />
                  <FormField
                    name="surname"
                    label="Last Name"
                    placeholder="Last Name"
                    component={InputField}
                    required
                  />
                </div>
                <FormField
                  label="Email"
                  name="email"
                  placeholder="Email"
                  component={InputField}
                  required
                />
                <Button
                  type="submit"
                  className={classes.smallTopSpacing}
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </Form>
            );
          }}

        </Formik>
        <Button
          className={classes.resetPasswordButton}
          variant="text"
          onClick={resetPassword}
        >
          Reset password
        </Button>
        {user.roles.owner && (
          <Formik
            initialValues={{
              company,
              url,
              defaultDescription,
              defaultKeywords,
              prefillInputs,
              language,
            }}
            onSubmit={submitCompany}
          >
            <Form className={classes.form}>
              <div className={clsx(classes.title, classes.topSpacing)}>Company Information</div>
              <FormField
                component={CheckboxField}
                name="prefillInputs"
                label="Prefill inputs by default"
              />
              <FormField
                label="Company"
                name="company"
                placeholder="Copysmith"
                component={InputField}
              />
              <FormField
                label="Company URL"
                name="url"
                placeholder="www.copysmith.ai"
                component={InputField}
              />
              <FormField
                label="Company Description"
                name="defaultDescription"
                placeholder="Copysmith uses AI to generate copy."
                multiline
                component={InputField}
              />
              <KeywordInput name="defaultKeywords" label="Tone or product keywords" />
              <FormField
                label="Default language"
                name="language"
                component={SelectField}
                options={translateLanguages}
              />
              <Button
                type="submit"
                className={classes.smallTopSpacing}
              >
                Update
              </Button>
            </Form>
          </Formik>
        )}
      </div>
    </ProfileLayout>

  );
};

export default EditProfile;
