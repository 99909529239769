import { HttpClient } from "../HttpClient";
import { HandlingOptions } from "../HandlingOptions";

export class Engine {
  private client: HttpClient;
  constructor(client: HttpClient) {
    this.client = client;
  }

  public getEngines(
    token: string | null = null,
    options?: HandlingOptions,
  ): Promise<Record<string, unknown>> {
    return this.client.get("/engines", { token }, options);
  }
}