import React, { useState } from 'react';
import {
  Link as UiLink,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Loading from '@root/components/Loading/Loading';
import useBulkGenerations from '@root/resources/bulkGenerate/useBulkGenerations';
import useTeamMembers from '@root/resources/user/useTeamMembers';
import templatesContext from '@root/resources/templates/templates.context';
import KebabMenu from '@root/components/KebabMenu/KebabMenu';
import {
  downloadAsFile,
} from '@root/utils/Export';
import api from '@root/api';
import useFolders from '@root/resources/folder/useFolders';
import useCancelJob from '@root/resources/bulkGenerate/useCancelJob';
import BulkGenerationsContext from '@root/resources/bulkGenerate/bulkGenerations.context';

import BulkCopyLayout from './components/BulkCopyLayout';
import ProgressBar from './components/ProgressBar';
import StatusBadge from './components/StatusBadge';
import BulkGenerationReportDialog from './components/BulkGenerationReportDialog';
import BulkHistoryPagination from './components/BulkHistoryPagination';
import useStyles from './BulkGenerationsHistory.styles';

const BulkGenerationsHistory = () => {
  const classes = useStyles();
  const history = useHistory();

  const { data: generations = [] } = useBulkGenerations();
  const { templates = [] } = templatesContext.useTemplates();
  const { data: { results: teamMembers = [] } = {} } = useTeamMembers();
  const { mutateAsync: cancelJob } = useCancelJob();
  const { state } = BulkGenerationsContext.useBulkGenerations();
  const { data: folders = [] } = useFolders();
  const [page, setCurrentPage] = useState(1);
  const [generationPerPage, setGenerationPerPage] = useState(25);
  const { state: { currentJob } } = BulkGenerationsContext.useBulkGenerations();

  const openFolder = (folder) => () => {
    history.push(folder.home ? `/templates/project/${folder._id}` : `/templates/folder/${folder._id}`);
  };

  const downloadFails = async ({ id, fileName }) => {
    const response = await api.bulkGeneration.getFailedRows({ id });

    if (response.isBadRequest) {
      return;
    }

    downloadAsFile(`${response}`, `${fileName.slice(0, fileName.length - 4)}_fails.csv`);
  };

  const onCancelJob = async (generation) => {
    await cancelJob({ id: generation._id, ...generation });
  };

  const flattedFolders = React.useMemo(() => folders.map((f) => {
    if (f.home) {
      return {
        _id: f._id,
        label: 'Other',
        openFolderId: f._id,
      };
    }

    if (f.parentFolderId) {
      const parentFolder = folders.find((pf) => pf._id === f.parentFolderId);

      return {
        _id: f._id,
        label: `${parentFolder.title}/${f.title}`,
        openFolderId: f._id,
      };
    }

    return {
      _id: f._id,
      label: f.title,
      openFolderId: f._id,
    };
  }), [folders]);
  const showDownloads = React.useMemo(() => generations.some((g) => (g.state === 'done' && g.failedRows?.length > 0)
    || g.state === 'failed'
    || g.state === 'inProgress'), [generations]);
  const generationsPaginated = React.useMemo(() => {
    return generations.slice((page - 1) * generationPerPage, page * generationPerPage);
  }, [
    generations,
    templates,
    showDownloads,
    teamMembers,
    flattedFolders,
    page,
    generationPerPage,
  ]);
  const getMenu = (generation) => {
    const actions = [];
    if (!['inProgress'].includes(generation.state) && generation.result.successfulRows > 0) {
      actions.push({
        title: 'Export to CSV',
        key: 'exportToCSV',
        onClick: async ({ handleClose }) => {
          handleClose();
          const response = await api.bulkGeneration.getBulkCSV({ id: generation._id });

          if (response.isBadRequest) {
            return;
          }

          downloadAsFile(`${response}`, `${generation.title}.csv`);
        },
      });
    }

    if (!['inProgress'].includes(generation.state) && generation.result.failedRows > 0) {
      actions.push({
        title: 'Download error log',
        key: 'downloadErrorLog',
        onClick: ({ handleClose }) => {
          handleClose();
          downloadFails({ id: generation._id, fileName: generation.fileName });
        },
      });
    }

    if (['pending', 'inProgress'].includes(generation.state)) {
      actions.push({
        title: 'Cancel',
        key: 'cancel',
        onClick: ({ handleClose }) => {
          handleClose();
          onCancelJob(generation);
        },
      });
    }

    return actions;
  };

  const handleTitleClick = (bulkId) => () => {
    history.push(`/bulk-copy/${bulkId}`);
  };

  const getStatusState = (generation) => {
    return generation.state;
  };

  const getStatusTooltipText = (generation) => {
    const { state: generationState, overLimitRows, result } = generation;
    const { failedRows } = result;

    const tooltipFailedText = 'Not all of your rows were successfully processed. Download error log to view job details.';
    const tooltipOverLimitText = 'Upload file contained more than the maximum allowed rows.';

    if (generationState === 'canceled') {
      return 'Not all of your rows were successfully processed.';
    }

    if (generationState === 'completed') {
      if (failedRows || overLimitRows) {
        return `${failedRows ? tooltipFailedText : ''} ${overLimitRows ? tooltipOverLimitText : ''}`;
      }
      return 'All rows successfully processed.';
    }

    return null;
  };

  const generationRows = React.useMemo(() => generationsPaginated.map((g) => {
    const template = templates[g.templateType];
    const folder = flattedFolders.find((f) => f._id === g.folderId);
    const { overLimitRows = 0 } = g;

    const isJobInProgress = currentJob?.bulkGeneration?._id === g._id;
    const menu = getMenu(g);
    const statusState = getStatusState(g);
    const statusTooltipText = getStatusTooltipText(g);

    return (
      <TableRow key={g._id}>
        <TableCell><UiLink onClick={handleTitleClick(g._id)}>{g.title}</UiLink></TableCell>
        <TableCell className={classes.typeCol}>
          <span className="mr-4"><template.Icon color="#000" /></span>
          {template.title}
        </TableCell>
        <TableCell>
          {folder && <UiLink onClick={openFolder(folder)}>{folder.label}</UiLink>}
        </TableCell>
        <TableCell>
          {isJobInProgress
            ? currentJob.bulkGeneration.result.totalRows
            : g.result.totalRows}
        </TableCell>
        <TableCell padding="none">
          <StatusBadge
            state={statusState}
            tooltipText={statusTooltipText}
            overLimitRows={overLimitRows}
          />
        </TableCell>
        <TableCell>
          <KebabMenu
            isDisabled={!menu.length}
            menuItems={menu}
            variant="list"
          />
        </TableCell>
      </TableRow>
    );
  }), [
    currentJob,
    state.jobToCancel,
    generationsPaginated,
  ]);

  if (teamMembers.length === 0 || folders.length === 0) {
    return <Loading />;
  }

  return (
    <BulkCopyLayout sidebar={{ type: 'default', page: 'history' }}>
      <BulkGenerationReportDialog />
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            Bulk History
          </Typography>
          {currentJob && (
            <ProgressBar
              job={currentJob}
            />
          )}
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  File Name
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Location
                </TableCell>
                <TableCell>
                  Rows
                </TableCell>
                <TableCell padding="none">
                  Status
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {generationRows}
            </TableBody>
          </Table>
        </TableContainer>
        <BulkHistoryPagination
          pagesAmount={Math.ceil(generations.length / generationPerPage)}
          setGenerationPerPage={setGenerationPerPage}
          generationPerPage={generationPerPage}
          page={page}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </BulkCopyLayout>
  );
};

export default BulkGenerationsHistory;
