import React from 'react';
import Box from '@material-ui/core/Box';

function TabPanel({
  children, value, index, ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: '16px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
