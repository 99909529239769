import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  circle: {
    height: 16,
    width: 16,
    background: '#4ED71E',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}));

const PublishedIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.circle} />
  );
};

export default PublishedIcon;
