import React from 'react';
import PropTypes from 'prop-types';

import google from '@root/views/Authentication/static/google.svg';
import outlook from '@root/views/Authentication/static/outlook.svg';

import useStyles from './SocialIcon.styles';

/**
 * Returns the svg for an icon
 *
 * @param {string} icon
 * @returns {svg}
 */
export const selectIcon = (icon) => {
  switch (icon) {
    case 'google':
      return google;
    case 'outlook':
      return outlook;
    default:
      throw new Error(`Unknown Icon: ${icon} in SocialIcon.`);
  }
};

const SocialIcon = ({ icon }) => {
  const socialIcon = selectIcon(icon);
  const classes = useStyles();

  return (
    <img
      className={classes.image}
      src={socialIcon}
      alt={`${icon} logo.`}
    />
  );
};

SocialIcon.propTypes = {
  icon: PropTypes.oneOf([
    'google',
    'outlook',
  ]),
};

SocialIcon.defaultProps = {
  icon: 'google',
};

export default SocialIcon;
