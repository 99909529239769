import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  communityCard: {
    position: 'relative',
    color: 'white',
    backgroundColor: 'transparent',
    backgroundSize: '100% 100%',
    border: '1px solid white',
  },
  communityIcon: {
    color: 'white',
  },
  communityEditButton: {
    color: 'white',

    '&:hover': {
      color: '#ffffffB3',
    },

  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    // gap: '8px',
  },
  communityButton: {
    backgroundColor: 'white',
    color: 'black',
    mixBlendMode: 'lighten',

    '&:hover': {
      backgroundColor: '#ffffff4D',
      color: 'white',
    },
  },
  draft: {
    border: `1px dashed ${theme.palette.colors.primary}`,
    color: theme.palette.colors.primary,
  },
  submitted: {
    border: `1px dashed ${theme.palette.colors.mediumDarkGrey}`,
    backgroundColor: theme.palette.colors.mediumLightGrey,
    color: theme.palette.colors.mediumDarkGrey,
    '&:hover': {
      backgroundColor: theme.palette.colors.mediumLightGrey,
    },
  },
}));

export default useStyles;
