import { useMutation, useQueryClient } from 'react-query';

import api from '@root/api';

export default function useCreateApiKey() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => api.apiKeys.createOrUpdate(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('apiKeys.list');
      },
      onMutate: () => {
      },
    },
  );
}
