import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dashboard: ({ newFilesPage }) => ({
    width: '100%',
    height: 'calc(100vh - 56px)',
    display: 'flex',
    padding: `${newFilesPage ? '0' : '32px 48px'}`,
    backgroundColor: theme.palette.colors.lightGrey,
  }),
}));

export default useStyles;
