import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import useUpdateCatalog from '@root/resources/catalog/useUpdateCatalog';

import useStyles from './BookmarkCatalog.styles';

const BookmarkCatalog = ({ isFavorite, catalogId }) => {
  const classes = useStyles();
  const { mutateAsync, isLoading } = useUpdateCatalog();

  const handelClick = async () => {
    await mutateAsync({ isFavorite: !isFavorite, id: catalogId });
  };

  return (
    <div className={classes.star} onClick={handelClick}>
      {isFavorite ? (
        <StarIcon className={classes.gold} />
      ) : (
        <StarBorderIcon />
      )}
    </div>
  );
};

export default BookmarkCatalog;
