import React, { FC } from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons-react';

import SimpleTooltip from '@root/components/tooltips/SimpleTooltip';
import TruncatedText from '@root/components/TruncatedText';
import useRoiStats from '@root/resources/usage/useRoiStats';

import useSectionStyles from '../Sections.styles';
import CreditRecordType from '../../UsageV2.types';

import useStyles from './WordsUsageSection.styles';

type Props = {
  creditRecord?: CreditRecordType;
  isLoading: boolean;
};

const WordsUsageSection:FC<Props> = ({ creditRecord, isLoading }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const { data: { totalWords } = {}, isFetching: isStatsFetching } = useRoiStats({
    startDate: creditRecord?.createdOn,
    endDate: creditRecord?.endDate,
  }, {
    enabled: !!creditRecord,
    refetchOnMount: true,
  });

  return (
    <Grid
      item
      xl={4}
      lg={4}
      md={4}
      sm={4}
      xs={12}
    >
      <div className={clsx(sectionClasses.sectionContainer)}>
        <Typography classes={{ root: sectionClasses.sectionTitle }}>Total word usage</Typography>

        {(isLoading || isStatsFetching) && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        {!(isLoading || isStatsFetching) && (
          <div className={sectionClasses.statsContainer}>
            <article className={sectionClasses.articleContainer} style={{ width: '100%' }}>
              <div style={{ maxWidth: '100%' }}>
                <Typography classes={{ root: sectionClasses.articleTitle }}>
                  Total words used
                  {/* @ts-expect-error SimpleTooltip is not .ts yes */}
                  <SimpleTooltip content="Total words generated this billing cycle." wrapped>
                    <IconInfoCircle size={16} />
                  </SimpleTooltip>
                </Typography>

                {/* @ts-expect-error TruncatedText is not .ts yes */}
                <TruncatedText
                  classes={{ root: sectionClasses.articleValue }}
                  style={{ maxWidth: 'unset' }}
                >
                  {totalWords?.toLocaleString()}
                </TruncatedText>
              </div>
            </article>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default WordsUsageSection;
