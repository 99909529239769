import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: 22,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  button: {
    marginTop: 24,
  },
}));
