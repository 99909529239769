import React from 'react';
import { ThemeProvider } from '@material-ui/core';

import MainLayout from '@root/views/Layout';
import newMaterialTheme from '@root/newMaterial.theme';
import { buckets } from '@root/views/TemplatesDashboardNew/filters.buckets.constant';

import Header from './Header';
import Filters from './Filters';
import TemplateList from './TemplatesList';
import Modals from './modals/Modals';
import { TemplatesDashboardProviderNew } from './TemplatesDashboard.context';
import useStyles from './TemplatesDashboard.styles';

const TemplatesDashboardNew = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={newMaterialTheme}>
      <MainLayout>
        <TemplatesDashboardProviderNew
          defaultState={{ bucket: buckets.all.id }}
        >
          <div className={classes.dashboardContainer}>
            <Header />
            <Filters />
            <TemplateList />
          </div>

          <Modals />
        </TemplatesDashboardProviderNew>
      </MainLayout>
    </ThemeProvider>
  );
};

export default TemplatesDashboardNew;
