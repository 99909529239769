import React from 'react';

import ConfirmationModal from '@root/components/modals/ConfirmationModal';

import useStyles from './CostWarning.styles';

const CostWarningModal = ({ onClose, onConfirm, rowsAvailable }) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      title="Attention"
      onConfirm={onConfirm}
      onClose={onClose}
      maxWidth="xs"
    >
      <div className={classes.description}>
        Your csv file has too many rows. Please upload a new file with a maximum of
        {' '}
        <span className={classes.rowsAvailable}>{rowsAvailable}</span>
        {' '}
        rows. If you confirm, Copysmith will process first
        {' '}
        <span className={classes.rowsAvailable}>{rowsAvailable}</span>
        {' '}
        rows from your file.
      </div>
    </ConfirmationModal>
  );
};

export default CostWarningModal;
