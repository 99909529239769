import React from 'react';
import clsx from 'clsx';

import CheckCircle from './CheckCircle.icon';
import useStyles from './GenerationCard.styles';

const GenerationCard = ({ onClick, content, contentLength, selected }) => {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={clsx(
        classes.root,
        selected && classes.selected,
      )}
    >
      {content}
      {contentLength && (
        <div className={classes.charCount}>
          {`Content: ${contentLength} char`}
        </div>
      )}
      <div className={classes.checkIcon}>
        <CheckCircle />
      </div>
    </div>
  );
};

export default GenerationCard;
