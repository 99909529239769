import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chart: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '445px',
    width: '100%',
    borderRadius: '8px',
    padding: '15px',
    backgroundColor: theme.palette.colors.white,
    boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
  },
}));

export default useStyles;
