import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { omit } from 'lodash';

import Button from '@root/components/buttons/Button';
import TruncatedText from '@root/components/TruncatedText/TruncatedText';
import ScrollFade from '@root/components/ScrollFade/ScrollFade';

import useStyles from './ProductFieldMappingForm.styles';

const ProductFieldMappingForm = ({ selectedFile, onSubmit, isImportLoading, fieldsSelected }) => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const [fieldMapping, setFieldMapping] = useState({});

  const transformedFileRows = useMemo(() => {
    const result = {};
    const [headers, ...rows] = selectedFile?.rows;

    headers.forEach((header, index) => {
      result[header] = rows.map((row) => row[index]).slice(0, 4);
    });

    return result;
  }, [selectedFile]);

  const defaultRequiredFields = [
    { field: 'SKU', label: 'SKU', name: 'externalId' },
    { field: 'Title', label: 'Title', name: 'title' },
  ];

  const optionalFields = {
    titleKeywords: { field: 'Title Keywords', label: 'Title Keywords', name: 'titleKeywords' },
    description: { field: 'Description', label: 'Description', name: 'description' },
    descriptionKeywords: { field: 'Description Keywords', label: 'Description Keywords', name: 'descriptionKeywords' },
    bulletPoints: { field: 'Feature Bullet Points', label: 'Feature Bullet Points', name: 'bulletPoints' },
    bulletPointsKeywords: { field: 'Feature Bullet Points Keywords', label: 'Feature Bullet Points Keywords', name: 'bulletPointsKeywords' },
  };

  const getSelectedFields = () => {
    if (fieldsSelected) {
      const selectedOptionalFields = Object.keys(fieldsSelected).filter((key) => fieldsSelected[key] !== false);
      const enrichedOptionalFields = selectedOptionalFields.map((field) => {
        return optionalFields[field];
      });

      return [...defaultRequiredFields, ...enrichedOptionalFields];
    }

    return defaultRequiredFields;
  };

  const requiredFields = getSelectedFields();
  const stepsCount = requiredFields.length;
  const isLastStep = currentStep === stepsCount - 1;

  const currentField = requiredFields[currentStep];
  const isNextStepDisabled = (
    !fieldMapping?.[currentField.name]
  );

  const handleGenerateClick = () => {
    onSubmit(fieldMapping);
  };

  const handlePreviousStepClick = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStepClick = () => {
    if (isLastStep) {
      onSubmit(fieldMapping);
      return;
    }

    if (currentStep < stepsCount - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleColumnClick = (columnName) => {
    const isDeselect = fieldMapping?.[currentField.name] === columnName;

    if (isDeselect) {
      setFieldMapping(omit(fieldMapping, currentField.name));
    } else {
      setFieldMapping({ ...fieldMapping, [currentField.name]: columnName });
    }
  };

  return (
    <div className={classes.mappingContainer}>
      <div className={classes.titleContainer}>
        <Typography
          className={classes.title}
        >
          {`${currentStep + 1} of ${stepsCount}: Select the column that contains ${currentField?.label}`}
        </Typography>
      </div>

      <div className={classes.columnsContainer}>
        <ScrollFade />
        {Object.entries(transformedFileRows).map(([key, value]) => {
          const isSelected = fieldMapping?.[currentField?.name] === key;
          return (
            <div
              key={key}
              className={clsx(
                classes.column,
                { [classes.selected]: isSelected },
              )}
              onClick={() => handleColumnClick(key)}
            >
              <TruncatedText
                className={classes.columnHeader}
              >
                {key}
              </TruncatedText>

              <div className={classes.valuesContainer}>
                {value.map((cell, index) => (
                  <TruncatedText
                      // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={classes.columnValue}
                    withoutTooltip
                  >
                    {cell || '<<NO DATA>>'}
                  </TruncatedText>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.buttonsContainer}>
        <Button
          variant="text"
          onClick={handlePreviousStepClick}
        >
          Back
        </Button>
        <Button
          onClick={isLastStep ? handleGenerateClick : handleNextStepClick}
          disabled={isNextStepDisabled || isImportLoading}
        >
          {isLastStep ? 'Import' : 'Next Step'}
        </Button>
      </div>
    </div>
  );
};

export default ProductFieldMappingForm;
