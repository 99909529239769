import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useFolder from '@root/resources/folder/useFolder';
import useFolders from '@root/resources/folder/useFolders';
import TemplatesContext from '@root/resources/templates/templates.context';
import ProgressButton from '@root/components/ProgressButton';
import InputField from '@root/components/form/InputField';
import fileResource from '@root/resources/file';
import aiResource from '@root/resources/ai';
import billingResource from '@root/resources/billing';
import { getDefaultClipboardItem } from '@root/resources/file/file.helpers';
import { SelectField } from '@root/components/form';
import Modal from '@root/components/modals/Modal';
import DisabledGenerateTooltip from '@root/components/tooltips/DisabledGenerateTooltip';

import { templatesAvailability } from '../createHelpers';

import Steps from './components/Steps.icon';
import useStyles from './NewFileModal.styles';

const NewFileModal = ({
  fileData = {},
  document,
  type,
  onClose,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const isCampaign = type === 'campaign';
  const modalTitle = isCampaign
    ? 'Create new campaign file'
    : 'Create new long form document';
  const modalDescription = isCampaign
    ? 'Campaign builders allow you to use your best outputs to create additional outputs of similar quality and style with our other templates.'
    : 'Use your best short-form outputs to kickstart your long-form content.';

  const { fileTitle, title, folderId, templateType: currentTemplateType } = fileData;

  const { data: folder } = useFolder(folderId);
  const { data: folders = [] } = useFolders();
  const { templates } = TemplatesContext.useTemplates();
  const { mutateAsync: createFile } = fileResource.useCreateFile();
  const { mutateAsync: generateContent } = aiResource.useGenerateContent();
  const { data: limitsData } = billingResource.useLimits();
  const { invalidateLimits } = billingResource.useInvalidateLimits();

  const creditsLeft = limitsData && limitsData.limit;

  let locations;
  if (folder.home) {
    locations = [folder];
  } else {
    const isProjectFolder = !folder.parentFolderId;
    const projectFolder = isProjectFolder
      ? folder
      : folders.find((f) => f._id === folder.parentFolderId);
    const childFolders = isProjectFolder
      ? folders.filter((f) => f.parentFolderId === folder._id)
      : folders.filter((f) => f.parentFolderId === folder.parentFolderId);
    locations = [projectFolder, ...childFolders];
  }

  const locationOptions = locations.map((f) => ({ label: f.title, value: f._id }));
  const availableTemplates = Object.values(templates)
    .filter((t) => templatesAvailability[currentTemplateType]?.includes(t.templateType));

  const templateOptions = availableTemplates
    .map((t) => ({ label: t.title, value: t.templateType }));

  const [fileName, setFileName] = useState(`${fileTitle || title}_${isCampaign ? 'Campaign' : 'long form'}`);
  const [fileLocation, setFileLocation] = useState(folderId);
  const [templateType, setTemplateType] = useState(isCampaign ? templateOptions[0]?.value : 'freeFormEditor');
  const [isGenerating, setIsGenerating] = useState(false);

  const currentTemplate = templates[currentTemplateType];
  const newTemplate = templates[templateType];

  const destinationProps = isCampaign
    ? {
      Icon: newTemplate.Icon,
      title: newTemplate.title,
    }
    : {
      icon: 'pencil-alt',
      title: 'Long form Editor',
    };

  const isCreateDisabled = !fileName || !fileLocation || !templateType;

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleFileLocationChange = (option) => {
    setFileLocation(option.target.value);
  };

  const handleTemplateChange = (option) => {
    setTemplateType(option.target.value);
  };

  const handleCreate = async () => {
    setIsGenerating(true);
    const documentContent = currentTemplate.getClipboardItem
      ? currentTemplate.getClipboardItem(document)
      : getDefaultClipboardItem(document);

    let data = { ...fileData };

    if (currentTemplateType === 'productDescription') {
      data = {
        ...data,
        companyDescription: documentContent,
        keywords: fileData.productKeywords,
      };
    }

    if (templateType === 'socialMediaCaptions') {
      data = {
        ...data,
        topic: fileData.companyDescription,
      };
    }

    const newFile = await createFile({
      title: fileName,
      folderId: fileLocation,
      templateType,
      data,
      negativeKeywordArray: fileData.negativeKeywordArray,
      campaignDocumentId: document._id,
    });

    if (newFile.isBadRequest) {
      setIsGenerating(false);
      return;
    }

    if (isCampaign) {
      await generateContent({
        fileId: newFile._id,
        type: newFile.templateType,
        ...newFile,
      });
      history.push(`/${newTemplate.slug}/${newFile._id}`);
    } else {
      history.push(`/${newTemplate.slug}/${newFile._id}`, { initialContent: documentContent });
    }
    invalidateLimits();
    setIsGenerating(false);
  };

  return (
    <Modal
      onClose={onClose}
      maxWidth={false}
      noPadding
    >
      <div className={classes.root}>
        <div className={classes.leftSide}>
          <div className={classes.title}>
            {modalTitle}
          </div>
          <div className={classes.description}>
            {modalDescription}
          </div>
          <div className={classes.form}>
            <InputField
              value={fileName}
              onChange={handleFileNameChange}
              label="1. Name new file"
              name="fileName"
              maxLength={100}
              fullWidth
            />
            <SelectField
              label="2. Select file location"
              options={locationOptions}
              onChange={handleFileLocationChange}
              value={fileLocation}
            />
            {isCampaign && (
              <SelectField
                label="3. Select new template type"
                options={templateOptions}
                onChange={handleTemplateChange}
                value={templateType}
              />
            )}
          </div>
          <div className={classes.buttons}>
            <Button
              disabled={isGenerating}
              variant="text"
              onClick={onClose}
            >
              Cancel
            </Button>
            <DisabledGenerateTooltip
              notEnoughCredits
              enabled={!creditsLeft}
            >
              <ProgressButton
                color="primary"
                variant="contained"
                disabled={isCreateDisabled || isGenerating || !creditsLeft}
                onClick={handleCreate}
                isLoading={isGenerating}
                startIcon={' '}
              >
                Create
              </ProgressButton>
            </DisabledGenerateTooltip>
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.rightSideContent}>
            <div>
              <Steps />
            </div>
            <div className={classes.templates}>
              <div>
                <div className={classes.titleHint}>Use this:</div>
                {currentTemplate.Icon && <span className="mr-2"><currentTemplate.Icon color="#000" /></span> }
                <span>{currentTemplate.title}</span>
              </div>
              <div>
                <div className={classes.titleHint}>To create this:</div>
                {destinationProps.Icon && <span className="mr-2"><destinationProps.Icon color="#000" /></span> }
                <span>{destinationProps.title}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewFileModal;
