// React Query Mutation for exporting a product to Shopify.
import { useMutation } from 'react-query';

import api from '@root/api/newApi';

const postProductToShopify = async (documentId, description) => {
  return api.shopify.updateProduct({
    documentId,
    description,
  });
};

export default function useExportToShopify() {
  return useMutation(
    ({ documentId, description }) => postProductToShopify(documentId, description),
  );
}
