import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      overflow: 'hidden',
      flex: 1,
      isolation: 'isolate',
    },
    tableContainer: {
      height: '100%',
    },
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      borderSpacing: '0px 8px',
      margin: '-8px 0px',

      '& .MuiTableSortLabel-icon': {
        opacity: 1,
      },

      [theme.breakpoints.down('md')]: {
        minWidth: 1200,
      },
    },
    templateType: {
      fontFamily: 'Inter,sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    dash: {
      color: theme.palette.colors.neutrals200,
    },
    youHighlight: {
      width: 'fit-content',
      margin: '0 auto',

      fontFamily: 'Inter,sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',

      borderRadius: '6px',
      padding: '5px 3px',

      backgroundColor: '#D3D0FB',
      color: '#140B8E',
    },

    titleCellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },

    title: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },

    colorIndicator: {
      flexShrink: 0,
      borderRadius: '6px',
      height: 32,
      width: 32,
    },

    titleText: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
    },

    createdOnText: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      color: 'grey',
    },

    sharedWithAvatar: {
      border: '1px solid #fff',
      height: '24px',
      width: '24px',

      fontSize: '14px',
    },

    assignedToAvatar: {
      width: '32px',
      height: '32px',
      margin: '0 auto',

      backgroundColor: theme.palette.colors.neutrals50,

      color: theme.palette.colors.neutrals400,
      fontFamily: 'Inter,sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
    },

    legacyTemplateContainer: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },

    disabledText: {
      color: theme.palette.colors.grey,
    },

    loaderContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
    },

    loader: {
      color: theme.palette.colors.neutrals600,
    },

    loadingText: {
      fontFamily: 'Inter,sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
    },

    extraTooltipContent: {
      maxWidth: 250,
    },
    extraTooltipTitle: {
      marginBottom: 6,
    },
    extraTooltipEmailsContainer: {
      display: 'flex',
      color: theme.palette.colors.neutrals300,
    },

    assignedToTooltipText: {
      color: theme.palette.colors.neutrals300,
    },
    assignedToTooltipName: {
      color: theme.palette.colors.white,
    },
  };
});

export default useStyles;
