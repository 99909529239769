import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  community: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflowY: 'auto',
    padding: '24px max(calc(100% - 1264px) / 2, 48px)',
  },

  communityContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    flex: 1,
  },
}));

export default useStyles;
