import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { IconPhotoUp, IconAlertCircle } from '@tabler/icons-react';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';

import api from '@root/api/newApi';
import { FormField, InputField } from '@root/components/form';
import Modal from '@root/components/modals/Modal';
import Button from '@root/components/buttons/Button';
import { checkImageByUrl } from '@root/utils/browser.utils';

import useStyles from './ImageModal.styles';

const schema = Yup.object().shape({
  url: Yup.string().test(
    'url',
    'Something went wrong. Please check your URL',
    async (url) => checkImageByUrl(url),
  ),
});

const initialValues = {
  url: '',
};

const maxSize = 5 * 1024 * 1024; // 5 MB
const acceptImagesString = 'image/jpeg,image/png,image/gif';
const imageFormatsString = 'JPG, JPEG, PNG or GIF';

const ImageModal = ({ onClose, onAddImage, documentId }) => {
  const classes = useStyles();

  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmbed = ({ url }) => {
    onAddImage(url);
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    setIsLoading(true);

    if (rejectedFiles.length > 0) {
      const isExtensionError = rejectedFiles[0].errors.some((error) => error.code === 'file-invalid-type');
      const isSizeError = rejectedFiles[0].errors.some((error) => error.code === 'file-too-large');
      if (isExtensionError) {
        setFileError(`Format is not supported.
        Please use: ${imageFormatsString}.`);
      }
      if (isSizeError) {
        setFileError(`Image is too big. Max ${maxSize / 1024 / 1024} MB `);
      }
      setIsLoading(false);
      return;
    }

    if (acceptedFiles[0]) {
      const formData = new FormData();
      formData.append('files', acceptedFiles[0]);
      const { isBadRequest, url } = await api.documents.uploadImage({ documentId, formData });
      if (!isBadRequest && url) {
        onAddImage(url);
      }
    }
    setIsLoading(false);
  };

  const { getRootProps, getInputProps, open: openFile } = useDropzone({
    onDrop,
    accept: acceptImagesString,
    maxSize,
    multiple: false,
    noClick: true,
    disabled: isLoading,
  });

  const dropzoneContent = fileError ? (
    <>
      <IconAlertCircle size={48} className={clsx(classes.icon, 'error')} />
      {fileError}
      <Button
        variant="link"
        onClick={openFile}
      >
        <u>Try again</u>
      </Button>
    </>
  ) : (
    <>
      <IconPhotoUp size={48} className={clsx(classes.icon)} />
      <span>Drag and drop files here</span>
      <span className={classes.bottomLine}>
        or&nbsp;
        <Button
          variant="link"
          onClick={openFile}
          className={classes.fileButton}
        >
          browse files
        </Button>
      </span>
    </>
  );
  return (
    <Modal
      onClose={onClose}
    >
      <Tabs
        // value={tab}
        // onChange={(e, value) => {
        //   setTab(value);
        // }}
        value="upload"
      >
        <Tab
          value="upload"
          label="Upload"
        />
        {/* <Tab
          value="dall-e"
          label="Dall-e"
        /> */}

      </Tabs>
      <div
        className={clsx(classes.dropzone, fileError && 'error')}
        {...getRootProps()}
        role={undefined}
      >
        <input {...getInputProps()} />
        { isLoading ? <CircularProgress size={40} /> : dropzoneContent}
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleEmbed}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, handleSubmit }) => {
          return (
            <Form className={classes.form}>
              <FormField
                component={InputField}
                label="Or upload from a URL"
                placeholder="Paste the image link..."
                name="url"
                className={classes.input}
                disabled={isLoading}
                endAdornment={(
                  <Button
                    onClick={handleSubmit}
                    className={classes.inputButton}
                    disabled={!values.url || isLoading}
                  >
                    Embed image
                  </Button>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ImageModal;
