import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { escapeRegExp } from 'lodash';

import TemplatesContext from '@root/resources/templates/templates.context';
import { checkIsTemplateCustom } from '@root/resources/aiTemplate/template.helpers';
import aiTemplateResource from '@root/resources/aiTemplate';
import { communityReducer, communityActions, initialState } from '@root/views/CreateAndExplore/context/createAndExploreReducer';
import {
  confirmationModalsReducer,
  initialModalsState,
  modalsActions,
} from '@root/views/CreateAndExplore/context/confirmationModalsReducer';
import uiNotificationService from '@root/services/uiNotification.service';
import AuthContext from '@root/resources/auth/auth.context';

import { tabs } from '../CreateAndExplore.constants';

const searchTemplates = (searchQuery, templatesToSearch) => {
  if (!searchQuery) {
    return templatesToSearch;
  }

  const formattedSearchQuery = escapeRegExp(searchQuery).toUpperCase();

  const searchedTemplates = templatesToSearch.filter(
    (template) => template.title?.toUpperCase().search(formattedSearchQuery) > -1
      || template.user?.firstName?.toUpperCase().search(formattedSearchQuery) > -1
      || template.user?.surname?.toUpperCase().search(formattedSearchQuery) > -1,
  );

  return searchedTemplates;
};

const CommunityContext = React.createContext();

const CreateAndExploreContextProvider = ({ children }) => {
  const history = useHistory();

  const [state, dispatch] = useReducer(communityReducer(), initialState);
  const [modalsState, modalsDispatch] = useReducer(confirmationModalsReducer(), initialModalsState);

  const {
    templatesList = [],
    loadTemplates,
    isFetching: isTemplatesFetching,
  } = TemplatesContext.useTemplates();
  const { currentUser: user } = AuthContext.useAuth();

  const isAdminOrOwner = user.roles.admin || user.roles.owner;

  const {
    data: communityTemplates = [],
    isFetching: isCommunityTemplatesFetching,
    refetch: refetchCommunityTemplates,
  } = aiTemplateResource.useCommunityTemplates();

  const {
    mutateAsync: addCommunityTemplateToLibrary,
    isLoading: isTemplateAdding,
  } = aiTemplateResource.useAddCommunityTemplate();

  const {
    mutateAsync: deleteAiTemplate,
    isLoading: isTemplateDeleting,
  } = aiTemplateResource.useDeleteAiTemplate();

  const {
    mutateAsync: shareTemplateToCommunity,
    isLoading: isTemplateSharing,
  } = aiTemplateResource.useShareTemplateToCommunity();

  const {
    mutateAsync: removeTemplateFromCommunity,
    isLoading: isTemplateRemoving,
  } = aiTemplateResource.useRemoveTemplateFromCommunity();

  const isTemplateProcessing = (
    isTemplateSharing || isTemplateRemoving || isTemplateAdding || isTemplateDeleting
  );

  const handlePreviewTemplateClick = (template) => {
    modalsDispatch({
      type: modalsActions.TOGGLE_PREVIEW_TEMPLATE_MODAL,
      payload: template,
    });
  };

  const handleUploadToCommunityClick = (event, template) => {
    event.stopPropagation();

    if (isAdminOrOwner) {
      modalsDispatch({
        type: modalsActions.TOGGLE_SHARE_TO_COMMUNITY_MODAL,
        payload: template,
      });
      return;
    }
    uiNotificationService.showWarnMessage('This action can only be performed by Administrators.');
  };

  const handleRemoveFromCommunityClick = (event, template) => {
    event.stopPropagation();

    if (isAdminOrOwner) {
      modalsDispatch({
        type: modalsActions.TOGGLE_REMOVE_FROM_COMMUNITY_MODAL,
        payload: template,
      });
      return;
    }
    uiNotificationService.showWarnMessage('This action can only be performed by Administrators.');
  };

  const handleSaveToMyAccountClick = (event, templateId) => {
    event.stopPropagation();

    modalsDispatch({
      type: modalsActions.TOGGLE_SAVE_TO_MY_ACCOUNT_MODAL,
      payload: templateId,
    });
  };

  const handleRemoveFromLibraryClick = (event, templateId) => {
    event.stopPropagation();

    modalsDispatch({
      type: modalsActions.TOGGLE_REMOVE_FROM_LIBRARY_MODAL,
      payload: templateId,
    });
  };

  const saveToMyAccount = useCallback(
    async (templateId) => {
      await addCommunityTemplateToLibrary(templateId);
    },
    [addCommunityTemplateToLibrary, loadTemplates],
  );

  const removeFromAccount = useCallback(
    async (templateId) => {
      await deleteAiTemplate(templateId, {
        onSuccess: () => {
          loadTemplates(false);

          refetchCommunityTemplates();
        },
      });

      uiNotificationService.showSuccessMessage('Success! Template removed from account.');
    },
    [deleteAiTemplate, loadTemplates],
  );

  const uploadToCommunity = useCallback(
    async ({ templateId, templateName, outputExample }) => {
      await shareTemplateToCommunity(
        { id: templateId, data: { templateName, outputExample } },
        { onSuccess: () => refetchCommunityTemplates() },
      );
    },
    [shareTemplateToCommunity],
  );

  const removeFromCommunity = useCallback(
    async (templateId) => {
      await removeTemplateFromCommunity(templateId);
    },
    [removeTemplateFromCommunity],
  );

  const templatesToShow = useMemo(() => {
    let result = [];

    switch (state.activeTab) {
      case tabs.COMMUNITY:
        result = communityTemplates?.map((communityTemplate) => ({ ...communityTemplate, community: true }));
        break;
      case tabs.CREATE:
        result = templatesList
          .filter((template) => (checkIsTemplateCustom(template) && !template?.deletedOn))
          .map((filteredTemplate) => ({ ...filteredTemplate, community: false }));
        break;
      default:
        result = [];
        break;
    }

    if (state?.searchQuery) {
      return searchTemplates(state?.searchQuery, result);
    }

    return result || [];
  }, [state, templatesList, communityTemplates]);

  // change active tab after create custom template process
  useEffect(() => {
    if (history.location.state?.afterCreation) {
      dispatch({
        type: communityActions.SET_ACTIVE_TAB,
        payload: tabs.CREATE,
      });
    }
  }, [history]);

  const value = {
    state,
    dispatch,
    modalsState,
    modalsDispatch,
    templates: templatesList,
    templatesToShow,

    isTemplateProcessing,
    isTemplatesFetching: isTemplatesFetching || isCommunityTemplatesFetching,

    uploadToCommunity,
    handleUploadToCommunityClick,

    removeFromCommunity,
    handleRemoveFromCommunityClick,

    saveToMyAccount,
    handleSaveToMyAccountClick,

    removeFromAccount,
    handleRemoveFromLibraryClick,

    handlePreviewTemplateClick,
  };

  return (
    <CommunityContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </CommunityContext.Provider>
  );
};

const useCreateAndExploreContext = () => {
  const context = React.useContext(CommunityContext);

  if (context === undefined) {
    throw new Error('useCreateAndExploreContext must be used within a CommunityContextProvider');
  }

  return context;
};

export { CreateAndExploreContextProvider, useCreateAndExploreContext };
