import React, { useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

import MainLayout from '@root/views/Layout';
import useGenerationsUsage from '@root/resources/usage/useGenerationsUsage';
import useListCreditRecords from '@root/resources/usage/useListCreditRecords';
import { SelectField } from '@root/components/form';
import TemplatesContext from '@root/resources/templates/templates.context';
import billingResource from '@root/resources/billing';

import CreditDetails from './components/CreditDetails/CreditDetails';
import UsageChart from './components/UsageChart/UsageChart';
import useStyles from './Usage.styles';
import OverageInformation from './components/OverageInformation/OverageInformation';

const Usage = () => {
  const classes = useStyles();
  const [selectedCreditRecord, setSelectedCreditRecord] = useState();
  const { templatesList } = TemplatesContext.useTemplates({ withHidden: true });
  const { data: creditRecords, isFetching: isCreditRecordsFetching } = useListCreditRecords();
  const { data: billingInfo, isFetching: isBillingInfoFetching } = billingResource.useBillingInfo();

  const creditRecord = creditRecords && creditRecords.length > 0 ? creditRecords.find((record) => {
    if (selectedCreditRecord) {
      return selectedCreditRecord === record._id;
    }
    if (creditRecords && creditRecords.length > 0) {
      return creditRecords[0]._id === record._id;
    }
    return undefined;
  }) : undefined;

  const { data: generationsUsage, isFetching: isGenerationsUsageFetching } = useGenerationsUsage(
    !!creditRecords, creditRecord ? { startDate: creditRecord.createdOn,
      endDate: creditRecord.endDate,
      timezone: DateTime.now().zoneName } : undefined,
  );

  const creditRecordOptions = isCreditRecordsFetching ? []
    : creditRecords.filter((record) => record.type !== 'migration').map((record) => ({
      label: `${DateTime.fromSeconds(record.createdOn).toFormat('LLL dd yyyy')} to ${DateTime.fromSeconds(record.endDate).toFormat('LLL dd yyyy')}`,
      value: record._id,
    }));

  return (
    <MainLayout>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.header}>
              <Typography className={classes.title} variant="h6">Usage Data</Typography>
              {isCreditRecordsFetching ? <CircularProgress size={12} />
                : (
                  <SelectField
                    onChange={(e) => setSelectedCreditRecord(e.target.value)}
                    options={creditRecordOptions}
                    value={selectedCreditRecord || creditRecords[0]._id}
                  />
                )}
            </div>
          </Grid>
          <CreditDetails
            billingInfo={billingInfo}
            creditRecord={creditRecord}
            generationsUsage={generationsUsage}
            isBillingInfoFetching={isBillingInfoFetching}
            isCreditRecordsFetching={isCreditRecordsFetching}
            isGenerationsUsageFetching={isGenerationsUsageFetching}
            creditLabel="word"
          />
          <UsageChart
            generationsUsage={generationsUsage}
            isGenerationsUsageFetching={isGenerationsUsageFetching}
            templatesList={templatesList}
          />
          <OverageInformation
            creditRecord={creditRecord}
            creditLabel="word"
          />
        </Grid>
      </div>
    </MainLayout>
  );
};

export default Usage;
